import React, { useCallback, useState } from 'react';
import { useTrainersContext } from 'src/contexts/trainers.context';
import { ITrainers } from 'src/models/trainers.model';
import { getTrainersStatus } from 'src/selectors/trainers.selector';
import { trainersActionTypes } from 'src/ducks/trainers.duck';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { getUser } from 'src/libraries/amplify.library';
import { TrainerStatus } from '../trainers-table/trainers-table.component';
import TrainerStatusUpdateView from './trainers-status-update.view';

type IProp = {
  data: ITrainers;
  empStatus: TrainerStatus;
};

const TrainersStatusUpdate: React.FC<IProp> = ({ data, empStatus }) => {
  const { state, actions } = useTrainersContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getTrainersStatus(
    state,
    trainersActionTypes.TRAINERS_DATA_UPDATE
  );

  const handleSubmit = useCallback(async () => {
    if (data && data.empId) {
      setIsOpen(false);
      const request: ITrainers = {
        ...data,
        updatedBy: getUser()?.emp_no,
        trainerStat:
          empStatus === TrainerStatus.ACTIVE
            ? TrainerStatus.INACTIVE
            : TrainerStatus.ACTIVE,
      };
      const response = await actions.updatePUT(
        data.empId as any,
        request as any
      );

      if (!response.error) {
        actions.listGET({ page: 1, limit: 0 });
        // setIsOpen(false);

        ToastSuccess(Lang.MSG_TRAINERS_UPDATED_SUCCESSFULLY);
      }
    }
  }, [actions, data, setIsOpen]);

  return (
    <TrainerStatusUpdateView
      status={empStatus}
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default TrainersStatusUpdate;
