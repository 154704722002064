export enum TransmissionOutageStatus {
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  OUT = 'OUT',
  LATE_TO_END = 'LATE_TO_END',
  LATE_TO_START = 'LATE_TO_START',
  IN_OK = 'IN_OK',
  OUT_OK = 'OUT_OK',
  IN_SERVICE_EDITABLE = 'IN_SERVICE_EDITABLE',
  IN_SERVICE = 'IN_SERVICE',
  CONFLICT_IDENTIFIED = 'CONFLICT_IDENTIFIED',
  STUDY = 'STUDY',
  SUBMITTED = 'SUBMITTED',
}

export enum TransmissionOutageStatusNonCancellable {
  CANCELLED = 'CANCELLED',
  IN_OK = 'IN_OK',
  IN_SERVICE_EDITABLE = 'IN_SERVICE_EDITABLE',
  IN_SERVICE = 'IN_SERVICE',
  IN = 'IN',
  OUT = 'OUT',
  LATE_TO_END = 'LATE_TO_END',
  OUT_OK = 'OUT_OK',
  DENIED = 'DENIED',
}

export enum TransmissionOutageStatusNonEditable {
  CANCELLED = 'CANCELLED',
  IN_OK = 'IN_OK',
  IN_SERVICE_EDITABLE = 'IN_SERVICE_EDITABLE',
  IN_SERVICE = 'IN_SERVICE',
  IN = 'IN',
}

export enum OmsStatus {
  APPROVED = 'Approved',
  SCHEDULED = 'Scheduled',
  SUBMITTED = 'Submitted',
  CHANGE_REQUEST = 'Change Request',
  IN = 'In',
  OUT = 'Out',
}

export enum OmsActions {
  SEND = 'Send',
  CANCEL = 'Cancel',
  RESUBMIT = 'Resubmit',
}

export const PrimaryOutageCauseCode = [
  'AIRCRAFT_P',
  'ANIMAL_CONTACT_P',
  'CIRCUIT_BREAKERS_OR_CIRCUIT_SWITCHES_TROUBLE_P',
  'CONTAMINATION_P',
  'FIRE_P',
  'GENERATION_MAINTENANCE_OR_TROUBLE_P',
  'LATE_NOTIFICATION_P',
  'LIGHTNING_P',
  'LINE_EQUIPMENT_TROUBLE_P',
  'NATURAL_DISASTERS_P',
  'OPERATION_ERROR_P',
  'OTHER_P',
  'OTHER_SUBSTATION_EQUIPMENT_TROUBLE_P',
  'PROTECTION_P',
  'SCHEDULED_OUTAGE_P',
  'SYSTEM_DISTURBANCE_P',
  'UNKNOWN_P',
  'UTILITY_CONTACT_P',
  'VEGETATION_P',
  'VEHICLES_P',
  'WEATHER_P',
];

export const SecondaryOutageCauseCode = [
  'ARRESTORS_S',
  'BATTERY_SYSTEMS_S',
  'BUSHINGS_S',
  'CIRCUIT_BREAKERS_OR_CIRCUIT_SWITCHES_S',
  'COMM_FACILITIES_NOT_100_PERCENT_DEDICATED_TO_CONTROL_S',
  'CONDUCTOR_SHIELD_WIRE_AND_SPLICES_S',
  'CONTROL_AND_RELAY_SYSTEM_S',
  'DIRECT_CURRENT_TRANSMISSION_COMPONENTS_S',
  'DISCONNECTS_S',
  'ENCROACHMENTS_OR_UNAUTHORIZED_ATTACHMENTS_S',
  'GUYS_AND_ANCHORS_S',
  'HARDWARE_FITTINGS_AND_ACCESSORIES_S',
  'INSULATORS_S',
  'LINE_SWITCHES_S',
  'NONE_S',
  'OTHER_S',
  'REACTIVE_DEVICES_S',
  'REGULATOR_S',
  'STATION_AUXILIARY_EQUIPMENT_S',
  'STRUCTURES_OR_FOUNDATIONS_S',
  'UNDERGROUND_TRANSMISSION_EQUIPMENT_S',
  'WORK_PROCEDURE_OR_HUMAN_ERROR_S',
];

export const omsStatus = OmsStatus;
