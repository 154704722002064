import React from 'react';
import {
  ATMButton,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMLoader,
  ATMMessage,
  ATMModal,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  SubstationCountListUploadSchema,
  ISubstationCountListFormArray,
} from 'src/models/substation-count-list.model';
import { ISubstation } from 'src/models/substation.model';
import SubstationCountUploadForm from './substation-count-upload-form.component';

type IProps = {
  key?: string;
  isOpen: boolean;
  isEnabled: boolean;
  loading: boolean;
  fileErrors: string[];
  substations?: ISubstation[];
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISubstationCountListFormArray) => void;
  handleErrors: React.Dispatch<React.SetStateAction<string[]>>;
};

const FORMAT_SAMPLES = [
  'AD,5035,100,2017',
  'TY,5015,200,2018',
  'AC,3045,300,2019',
];

const SubstationCountUploadView: React.FC<IProps> = ({
  key,
  isOpen,
  loading,
  formRef,
  isEnabled,
  fileErrors,
  substations,
  handleOpen,
  handleClick,
  handleSubmit,
  handleErrors,
  handleEnabled,
}) => {
  if (!substations) {
    return <ATMLoader active inline="centered" />;
  }
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMButton
          key={key}
          icon="upload"
          type="button"
          primary
          content={Lang.LBL_UPLOAD}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>{Lang.NTE_HEADING_ATTACHMENT_UPLOAD}</ATMModal.Header>
      <ATMModal.Content>
        <ATMForm
          ref={formRef}
          validationSchema={SubstationCountListUploadSchema}
          onSubmit={handleSubmit}
        >
          {(formProps) => {
            return (
              <ATMFormProvider {...formProps}>
                <SubstationCountUploadForm
                  fileErrors={fileErrors}
                  substations={substations}
                  handleErrors={handleErrors}
                  handleEnabled={handleEnabled}
                />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column>
              {fileErrors.length > 0 && (
                <ATMMessage
                  negative
                  header={Lang.LBL_SUB_COUNT_FILE_ERRORS}
                  content={
                    <div
                      style={{
                        maxHeight: '350px',
                        overflowY: 'auto',
                        marginTop: '1em',
                      }}
                    >
                      {fileErrors.sort().map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                  }
                />
              )}
              <div>
                <strong>{Lang.LBL_NOTES}:</strong>
                <div>{Lang.NTE_UPLOAD_ATTACHMENT}</div>
                <div>{Lang.NTE_FORMAT_UPLOAD_ATTACHMENT}</div>
                <div>
                  <u>{Lang.LBL_EXAMPLE}:</u>
                  {FORMAT_SAMPLES.map((sample, index) => (
                    <div key={index}>{sample}</div>
                  ))}
                </div>
              </div>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => {
            handleOpen(false);
            handleErrors([]);
          }}
        />
        <ATMButton
          primary
          content="Upload"
          disabled={!isEnabled || loading}
          loading={loading}
          onClick={handleClick}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SubstationCountUploadView;
