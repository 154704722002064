import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/significant-cost.service';
import { ISignificantCost } from 'src/models/significant-cost.model';

export const significantCostActionTypes = {
  SIGNIFICANT_COST_LIST_READ: 'SIGNIFICANT_COST_LIST_READ',
} as const;

export const duckActions = {
  listGET: {
    type: significantCostActionTypes.SIGNIFICANT_COST_LIST_READ,
    service: services.listGET,
  },
};

export type ISignificantCostAsync = typeof duckActions;

export interface ISignificantCostState
  extends ICommonState<typeof significantCostActionTypes> {
  data?: ISignificantCost;
  list: ISignificantCost[];
  total: number;
}

export const defaultState: ISignificantCostState = {
  status: {},
  list: [],
  total: 0,
};

const SignificantCostReducer = (
  state: ISignificantCostState,
  action: IReducerAction<ISignificantCostAsync>
): ISignificantCostState => {
  switch (action.type) {
    case significantCostActionTypes.SIGNIFICANT_COST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default SignificantCostReducer;
