import yup from 'src/libraries/validator.library';

export const TrainingHistoryShape = {
  suspensionHistoryId: yup.number(),
  empId: yup.string(),
  authTypId: yup.string(),
  suspEventId: yup.number(),
  comments: yup.string(),
  updatedAt: yup.string(),
  updatedBy: yup.string(),
};

export const TrainingHistorySchema = yup.object(TrainingHistoryShape);

export const TrainingHistoryListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(TrainingHistorySchema.clone()),
});

export type ITrainingHistory = yup.Asserts<typeof TrainingHistorySchema>;
