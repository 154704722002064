import React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMField,
  ATMInput,
  ATMTable,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import Lang from 'src/libraries/language';
import { ISubstationCountListFormArray } from 'src/models/substation-count-list.model';

type IProps = {
  defaultValues: ISubstationCountListFormArray;
  substations: string[];
};

const SubstationCountListTableForm: React.FC<IProps> = ({
  defaultValues,
  substations,
}) => {
  const { control } = useATMFormContext<ISubstationCountListFormArray>();

  const { fields } = useFieldArray<ISubstationCountListFormArray>({
    name: 'substationCounts',
    control,
  });

  return (
    <ATMTable celled>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_ID_NAME}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>
            <LabelRequired>{Lang.LBL_COUNT}</LabelRequired>
          </ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_LOAD_MV}</ATMTable.HeaderCell>
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {fields.map((__, i) => (
          <ATMTable.Row key={i}>
            <ATMTable.Cell>
              <span>
                <strong>
                  {defaultValues.substationCounts[i].substationId}
                </strong>
                {` - ${
                  substations
                    .filter(
                      (item) =>
                        item.split(' - ')[0] ===
                        defaultValues.substationCounts[i].substationId
                    )[0]
                    .split(' - ')[1]
                }
                `}
              </span>
              <ATMField
                key={`substationId_${i}`}
                as={ATMInput}
                type="hidden"
                control={control}
                name={`substationCounts[${i}].substationId`}
                className="hidden"
              />
              <ATMField
                key={`year_${i}`}
                as={ATMInput}
                type="hidden"
                control={control}
                name={`substationCounts[${i}].year`}
                className="hidden"
              />
            </ATMTable.Cell>
            <ATMTable.Cell>
              <ATMField
                key={`custCnt_${i}`}
                required
                as={ATMInput}
                control={control}
                name={`substationCounts[${i}].custCnt`}
              />
            </ATMTable.Cell>
            <ATMTable.Cell>
              <ATMField
                key={`substationLoad_${i}`}
                as={ATMInput}
                control={control}
                name={`substationCounts[${i}].substationLoad`}
              />
            </ATMTable.Cell>
          </ATMTable.Row>
        ))}
      </ATMTable.Body>
    </ATMTable>
  );
};

export default SubstationCountListTableForm;
