import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SystemChangeReducer, {
  ISystemChangeState,
  defaultState,
  duckActions,
} from 'src/ducks/system-change.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SystemChangeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISystemChangeContext = ReturnType<typeof useReducer>;

const SystemChangeContext = createContext<Partial<ISystemChangeContext>>({
  state: defaultState,
}) as React.Context<ISystemChangeContext>;

type IProps = {
  state?: Partial<ISystemChangeState>;
};

const SystemChangeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SystemChangeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SystemChangeContext.Provider>
  );
};

const useSystemChangeContext = () => useContext(SystemChangeContext);

export type IUseSystemChangeContext = ReturnType<typeof useSystemChangeContext>;

export { SystemChangeContext, SystemChangeProvider, useSystemChangeContext };
