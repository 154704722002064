import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';

type IProps = {
  draftId: number;
  trigger: React.ReactNode;
  handleSuccess?: () => void;
};

const LerRequestDeleteDraft: React.FC<IProps> = ({
  draftId,
  trigger,
  handleSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const { state, actions } = useLerRequestContext();

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DRAFT_DELETE
  );

  const handleDelete = useCallback(async () => {
    const result = await actions.draftDELETE(draftId);

    if (!result.error) {
      ToastSuccess(Lang.MSG_LER_REQUEST_DRAFT_DELETE_SUCCESS);

      if (handleSuccess) {
        handleSuccess();
      }
    }

    setOpen(false);
  }, [draftId, actions, handleSuccess, setOpen]);

  return (
    <ATMModal
      open={open}
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
    >
      <ATMModal.Header>{Lang.TTL_CONFIRM_DELETE}</ATMModal.Header>
      <ATMModal.Content>{Lang.MSG_LER_REQUEST_DRAFT_CONFIRM}</ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton type="button" secondary onClick={() => setOpen(false)}>
          {Lang.LBL_CANCEL}
        </ATMButton>
        <ATMButton
          negative
          type="button"
          onClick={handleDelete}
          loading={status.fetching}
          disabled={status.fetching}
        >
          {Lang.LBL_OKAY}
        </ATMButton>
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LerRequestDeleteDraft;
