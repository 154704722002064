import moment from 'moment';
import { orderBy } from 'lodash';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { v4 as uuid } from 'uuid';
import {
  AccessRole,
  LERRequestChangeStatus,
  LERRequestStatus,
  Op,
} from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import { getUser } from 'src/libraries/amplify.library';
import Moment from 'src/libraries/moment.library';
import {
  ILerRequest,
  ILerRequestForm,
  ILerRequestItem,
  ILerRequestOutageDate,
  ILerRequestOutageDateForm,
  IPeriod,
} from 'src/models/ler-request.model';
import {
  formatToRawEmployeeId,
  formatToZeroPaddedEmployeeId,
} from './employee.helper';

export const isLerRequestExpired = (dates: ILerRequestOutageDate[]) => {
  return Moment.max((dates || []).map((v) => Moment(v.stopTm))).isBefore(
    Moment(),
    'day'
  );
};

export const isLerRequestBeyond30Days = (dates: ILerRequestOutageDate[]) => {
  return Moment.max((dates || []).map((v) => Moment(v.stopTm)))
    .add(30, 'day')
    .isBefore(Moment(), 'day');
};

export const hasChangeRequest = (data?: ILerRequest | ILerRequestItem) => {
  if (!data) {
    return false;
  }

  return [
    LERRequestChangeStatus.Requested,
    LERRequestChangeStatus.Submitted,
  ].includes(data.changeReqStat as LERRequestChangeStatus);
};

export const revertPeriodTimeAdjustments = (
  period: IPeriod | undefined,
  switchOutMin: number | null | undefined,
  switchInMin: number | null | undefined
): IPeriod | null => {
  if (!period) return null;
  const { end, start } = period;
  if (
    (switchInMin === 0 && switchOutMin === 0) ||
    (!switchInMin && !switchOutMin)
  ) {
    return {
      start: moment(start).format('MM/DD/YYYY HH:mm') as unknown as Date,
      end: moment(end).format('MM/DD/YYYY HH:mm') as unknown as Date,
    };
  }
  const revertTime = {} as IPeriod;

  if (switchOutMin) {
    const adjustedStart = moment(start, 'MM/DD/YYYY HH:mm')
      .add(switchOutMin, 'minutes')
      .format('MM/DD/YYYY HH:mm');
    revertTime.start = adjustedStart as unknown as Date;
  }

  if (switchInMin) {
    const adjustedEnd = moment(end, 'MM/DD/YYYY HH:mm')
      .subtract(switchInMin, 'minutes')
      .format('MM/DD/YYYY HH:mm');
    revertTime.end = adjustedEnd as unknown as Date;
  }
  return revertTime;
};

export const hasEditAccess = (item?: ILerRequest | ILerRequestItem) => {
  if (!item) {
    return false;
  }

  const data = {
    outageDates: item.outageDates,
    requestStat: item.requestStat,
    requestorId: formatToRawEmployeeId(
      (item as ILerRequest).crewInformation
        ? (item as ILerRequest).crewInformation.requestorId
        : (item as ILerRequestItem).requestorId
    ),
    supvId: (item as ILerRequest).crewInformation
      ? (item as ILerRequest).crewInformation.supvId
      : (item as ILerRequestItem).supvId,
  };

  const empId = getUser()?.emp_no;

  // if (data.outageDates.length && isLerRequestExpired(data.outageDates)) {
  //   return false;
  // }
  // Prevent edit if past 30 days from the end date, all status and roles will be affected
  if (data.outageDates.length && isLerRequestBeyond30Days(data.outageDates)) {
    return false;
  }

  if (
    ([LERRequestStatus.Cancelled].includes(
      data.requestStat as LERRequestStatus
    ) === false ||
      (LERRequestStatus.Rejected === data.requestStat &&
        data.requestorId === empId)) === false
  ) {
    return false;
  }

  if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR) && data.requestorId !== empId) {
    return false;
  }

  if (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ]) &&
    data.requestorId !== empId &&
    data.supvId !== empId
  ) {
    return false;
  }

  return true;
};

/**
 * This function will merged continuous dates for calendar display
 * @param outageDates
 * @returns ILerRequestOutageDate[]
 */
export const getLerOutageDateList = (
  outageDates?: (ILerRequestOutageDate | ILerRequestOutageDateForm)[],
  renewKey = false
) => {
  if (!outageDates) {
    return [];
  }

  const list = orderBy(
    [...outageDates],
    'startTm',
    'asc'
  ) as ILerRequestOutageDate[];

  let index = 0;

  return [...list].reduce((items: ILerRequestOutageDate[], item) => {
    const prev = items[index];

    if (
      prev &&
      item.startTm &&
      item.stopTm &&
      Moment(item.startTm).toString() === Moment(prev.stopTm).toString()
    ) {
      const keys = (
        Array.isArray(prev.key) ? prev.key : [prev?.key]
      ) as string[];

      items.splice(index, 1, {
        ...prev,
        key: renewKey ? uuid() : [...keys, item?.key as string],
        startTm: Moment(prev.startTm).toDate(),
        stopTm: Moment(item.stopTm).toDate(),
      });

      return [...items];
    }

    index = items.length;

    return [
      ...items,
      {
        ...item,
        key: renewKey ? uuid() : item?.key,
      },
    ];
  }, []);
};

export const isRequestNewAndCreatedBySPVorOPE = (data?: ILerRequest) => {
  if (!data) {
    return false;
  }

  const { requestorId } = data.crewInformation;

  return (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ]) &&
    getUser()?.emp_no === requestorId &&
    data.requestStat === LERRequestStatus.Submitted
  );
};

export const isCaisoDetailsNotUpdated = (
  updatedData: ILerRequestForm,
  currentData: ILerRequest
) => {
  return (
    updatedData.outageFacility?.outgFacId ===
      currentData.outageFacility.outgFacId &&
    updatedData.outageFacility?.outgTypId ===
      currentData.outageFacility.outgTypId &&
    updatedData.outageDates
      ?.map((v) => `${v.startTm}-${v.stopTm}`)
      .toString() ===
      currentData.outageDates
        ?.map((v) => `${v.startTm}-${v.stopTm}`)
        .toString() &&
    Number(updatedData.jobInformation?.switchInMin) ===
      Number(currentData.jobInformation.switchInMin) &&
    Number(updatedData.jobInformation?.switchInMin) ===
      Number(currentData.jobInformation.switchInMin) &&
    updatedData.jobInformation?.recalTm ===
      currentData.jobInformation.recalTm &&
    updatedData.jobInformation?.wrkDesc ===
      currentData.jobInformation.wrkDesc &&
    updatedData.jobInformation?.rimsProjectId ===
      currentData.jobInformation.rimsProjectId &&
    updatedData.jobInformation?.rimsProjectPhase ===
      currentData.jobInformation.rimsProjectPhase &&
    updatedData.isoTrs?.isoWorkKindId === currentData.isoTrs.isoWorkKindId &&
    updatedData.isoTrs?.primIsoCauseCode ===
      currentData.isoTrs.primIsoCauseCode &&
    updatedData.isoTrs?.secondIsoCauseCode ===
      currentData.isoTrs.secondIsoCauseCode &&
    updatedData.isoTrs?.caisoTrs
      ?.map((v) => `${v.caisoId} - ${v.facilityModelInd}`)
      .toString() ===
      currentData.isoTrs.caisoTrs
        ?.map((v) => `${v.caisoId} - ${v.facilityModelInd}`)
        .toString() &&
    updatedData.isoTrs?.switchings
      ?.map((v) => `${v.switchId} - ${v.defaultPosInd}`)
      .toString() ===
      currentData.isoTrs.switchings
        ?.map((v) => `${v.switchId} - ${v.defaultPosInd}`)
        .toString()
  );
};

export const getLerRequestNewFilters = (
  filters: IORGDataTableQueryState['filters'] = []
) => {
  const user = getUser();

  let listFilter = [...filters];

  if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) {
    listFilter = [
      ...listFilter,
      {
        name: 'requestorId',
        value: user?.emp_no,
      },
      {
        name: 'changeReqStat',
        op: Op.eq,
        value: null,
      },
    ];
  } else if (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ])
  ) {
    const empId = formatToZeroPaddedEmployeeId(user?.emp_no ?? '');
    listFilter = [
      ...listFilter,
      {
        name: 'or',
        value: [
          { requestorId: empId },
          { supvId: empId, requestStat: LERRequestStatus.Created },
        ],
      },
    ];
  }

  if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
    listFilter = [
      ...listFilter,
      {
        name: 'requestStat',
        value: LERRequestStatus.Submitted,
      },
      {
        name: 'changeReqStat',
        op: Op.eq,
        value: null,
      },
    ];
  }

  return listFilter;
};

export const getLerRequestChangeFilters = (
  filters: IORGDataTableQueryState['filters'] = []
) => {
  const user = getUser();

  let changeFilters = [...filters].filter(
    (item) => item.name !== 'changeReqStat'
  );

  let changeStatus: LERRequestChangeStatus[] = [];

  if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) {
    changeFilters = [
      ...changeFilters,
      {
        name: 'requestorId',
        value: user?.emp_no,
      },
      {
        name: 'changeReqStat',
        op: Op.ne,
        value: null,
      },
    ];
  } else if (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ])
  ) {
    const empId = formatToZeroPaddedEmployeeId(user?.emp_no ?? '');
    changeFilters = [
      ...changeFilters,
      {
        name: 'or',
        value: [{ requestorId: empId }, { supvId: empId }],
      },
    ];
  }

  if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
    // #US 681032 Include Scheduled-ChangeRequested on the list to allow Scheduler to Approve/Reject CR
    // This should skip the Supervisor flow for CR if already scheduled.
    // Added Submitted-ChangeRequested to show who's the next group/person to take action (Action Pending By)
    // changeStatus.push(LERRequestChangeStatus.Submitted);
    const nonSubmitted = Object.values(LERRequestStatus)
      .filter((val) => typeof val === 'number')
      .filter((val) => val !== LERRequestStatus.Submitted);

    changeFilters = [
      ...changeFilters,
      {
        name: 'or',
        value: [
          ...nonSubmitted.map((val) => {
            return {
              requestStat: val,
              changeReqStat: LERRequestChangeStatus.Submitted,
            };
          }),
          {
            requestStat: LERRequestStatus.Scheduled,
            changeReqStat: LERRequestChangeStatus.Requested,
          },
          {
            requestStat: LERRequestStatus.Approved,
            changeReqStat: LERRequestChangeStatus.Requested,
          },
        ],
      },
    ];
  } else if (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ])
  ) {
    changeStatus.push(LERRequestChangeStatus.Requested);
    // #US 681032 Supervisor should not see LERs with change requests if already scheduled/approved.
    changeFilters = [
      ...changeFilters,
      {
        name: 'requestStatuses',
        op: Op.notIn,
        value: [LERRequestStatus.Scheduled, LERRequestStatus.Approved],
      },
    ];
  } else {
    changeStatus = [
      LERRequestChangeStatus.Requested,
      LERRequestChangeStatus.Submitted,
    ];
  }

  return changeStatus && changeStatus.length > 0
    ? changeFilters.reduce(
        (items, item) => [...items, item],
        [
          {
            name: 'changeReqStat',
            value: changeStatus,
          },
        ]
      )
    : changeFilters;
};

export const getLerRequestPendingCaisoFilters = (
  filters: IORGDataTableQueryState['filters'] = []
) => {
  let listFilter = [...filters];

  if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
    listFilter = [
      ...listFilter,
      {
        name: 'pendingCaiso',
        value: 'Y',
      },
    ];
  }

  return listFilter;
};
