import { commonClient, httpClient } from 'src/libraries/http.library';
import {
  EmployeeSearchPayloadSchema,
  // EmployeeUsersPayloadSchema,
  EmployeePayloadSchema,
  IEmployeeSearchForm,
  EmployeeSearchInternalPayloadSchema,
  EmployeeDetailsSchema,
  VendorSearchPayloadSchema,
  IEmployeeStateUpdate,
} from 'src/models/employee.model';

const client = httpClient();
const common = commonClient();

const services = {
  usersPOST: async (
    params: IEmployeeSearchForm,
    hrrepo?: boolean,
    vendorName?: string
  ) => {
    const defaultList: IEmployeeSearchForm = {
      eMailIds: [],
      employeeNos: [],
      firstNames: [],
    };
    const qParams = {
      ...defaultList,
      ...params,
      hrrepo: hrrepo ? 1 : undefined,
      vendorName: vendorName && vendorName !== '' ? vendorName : undefined,
    };
    const result = await common.post(
      '/employees',
      qParams,
      EmployeeSearchPayloadSchema
    );

    return result;
  },

  searchGET: async (
    name: string,
    hrrepo?: boolean,
    vendorName?: string,
    resourceStatus?: string
  ) => {
    const qParams = {
      name,
      hrrepo: hrrepo ? 1 : undefined,
      vendorName: vendorName && vendorName !== '' ? vendorName : undefined,
      resourceStatus:
        resourceStatus && resourceStatus !== '' ? resourceStatus : undefined,
    };
    return common.get('employeesByName', qParams, EmployeeSearchPayloadSchema);
  },

  searchInternalGET: async (
    name: string,
    hrrepo?: boolean,
    vendorName?: string
  ) => {
    const qParams = {
      name,
      hrrepo: hrrepo ? 1 : undefined,
      vendorName: vendorName && vendorName !== '' ? vendorName : undefined,
    };
    return client.get(
      'employees',
      qParams,
      EmployeeSearchInternalPayloadSchema
    );
  },
  searchVendorsGET: async (name: string) => {
    const qParams = {
      name,
    };
    return common.get('vendors', qParams, VendorSearchPayloadSchema);
  },

  userGET: async (emp_no: string) => {
    return common.get(`employee?emp_no=${emp_no}`, null, EmployeeDetailsSchema);
  },

  listGET: async () => {
    return client.get('/employee/temp', {}, EmployeePayloadSchema);
  },

  employeeStatePUT: async (
    tempIndicator: IEmployeeStateUpdate,
    empId?: string
  ) => {
    return client.put(
      `/employee/${empId}`,
      tempIndicator,
      EmployeeDetailsSchema
    );
  },
};

export default services;
