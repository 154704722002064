/* eslint-disable object-shorthand */
import React, { useCallback, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  ITroubleTicketAuditReportFilter,
  TroubleTicketAuditReportFilterSchema,
} from 'src/models/trouble-ticket.model';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import Moment from 'src/libraries/moment.library';
import style from './trouble-ticket-audit-report.module.scss';

type IProps = {
  data?: ITroubleTicketAuditReportFilter;
  handleSubmit: (data: ITroubleTicketAuditReportFilter) => void;
  handleNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setHasDateRange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  hasDateRange: boolean | undefined;
};

type IFormContent = {
  handleSubmit: (data: ITroubleTicketAuditReportFilter) => void;
  handleNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValue: Partial<ITroubleTicketAuditReportFilter>;
  loading: boolean;
  setHasDateRange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  hasDateRange: boolean | undefined;
};

const FormContent: React.FC<IFormContent> = ({
  handleSubmit,
  handleNoRecord,
  defaultValue,
  loading,
  setHasDateRange,
  hasDateRange,
}) => {
  const { control, reset, getValues, resetField, register } =
    useATMFormContext<ITroubleTicketAuditReportFilter>();
  const { state: workGroupState } = useWorkGroupContext();
  const { actions: troubleTicketActions } = useTroubleTicketContext();
  const [workGroup, setWorkGroup] = useState<Array<number> | undefined>(
    defaultValue.workGroups
  );
  const [selectAll, setSelectAll] = useState<boolean | undefined>(undefined);
  const [isBoth, setIsBoth] = useState<boolean | undefined>(
    defaultValue.isBoth
  );
  const [dateTime, setDateTime] = useState<Date | Date[] | null | undefined>(
    defaultValue.dateTime
  );

  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  // const [minDate, setMinDate] = useState<Date | undefined>(() => {
  //   const today = new Date();
  //   today.setFullYear(today.getFullYear() - 1);
  //   return today;
  // });

  const handleDateRange = (dateSelected: Date) => {
    const dateLimit = Moment(dateSelected).add(1, 'y');
    const dateLimitBackwards = Moment(dateSelected).add(-1, 'y');
    setMaxDate(dateLimit.isAfter(Moment()) ? new Date() : dateLimit.toDate());
    setMinDate(dateLimitBackwards.toDate());
  };

  const handleWorkGroup = useCallback(
    (id: number) => {
      let workGroupCopy: Array<number> | undefined;
      if (workGroup && workGroup.length > 0) {
        if (workGroup?.some((v) => v === id)) {
          workGroupCopy = workGroup?.splice(0, workGroup.indexOf(id));
        } else {
          workGroupCopy = [...workGroup, id];
        }
      } else {
        workGroupCopy = [id];
      }
      setWorkGroup(workGroupCopy);
      register('workGroups');
      resetField('workGroups', { defaultValue: workGroupCopy });
    },
    [workGroup, setWorkGroup]
  );

  return (
    <ATMGrid columns={2}>
      <ATMGrid.Row>
        <ATMGrid.Column width={3}>
          <ATMDatePicker
            showToday={false}
            name="dateTime"
            label={Lang.LBL_DATE_RANGE}
            type="range"
            format="MM/DD/YYYY"
            maxDate={maxDate}
            minDate={minDate}
            value={dateTime}
            required
            onChange={(_, { value }) => {
              if (Array.isArray(dateTime) && dateTime.length === 2) {
                setMaxDate(new Date());
                setMinDate(undefined);
              }
              if (!value) {
                reset({ startDate: undefined, endDate: undefined });
                setHasDateRange(false);

                setMaxDate(new Date());
                setMinDate(undefined);
              } else if (Array.isArray(value) && value.length === 1) {
                handleDateRange(value[0]);
              }
              if (Array.isArray(value) && value.length === 2) {
                register('startDate');
                register('endDate');
                resetField('startDate', { defaultValue: value[0] });
                resetField('endDate', { defaultValue: value[1] });
                setHasDateRange(true);
              } else {
                register('workGroups');
                resetField('workGroups', { defaultValue: workGroup });
                register('isBoth');
                resetField('isBoth', { defaultValue: isBoth });
                register('startDate');
                register('endDate');
                resetField('startDate', { defaultValue: undefined });
                resetField('endDate', { defaultValue: undefined });
                setHasDateRange(false);
              }
              setDateTime(value);
              return value;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            label={Lang.NTE_FILTER_CHECKBOX_BOTH_TROUBLE_TICKET}
            as={ATMCheckbox}
            name="isBoth"
            control={control}
            defaultValue={isBoth}
            onChange={([_, { checked }]) => {
              setIsBoth(checked);
              return checked;
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Column style={{ marginBottom: '0', paddingBottom: '0' }}>
        <ATMField
          as={ATMInput}
          name="workGroups"
          control={control}
          type="hidden"
          className="hidden"
          defaultValue={defaultValue.workGroups}
        />
        <ATMField
          as={ATMInput}
          name="startDate"
          control={control}
          type="hidden"
          className="hidden"
          defaultValue={defaultValue.startDate}
        />
        <ATMField
          as={ATMInput}
          name="endDate"
          control={control}
          type="hidden"
          className="hidden"
          defaultValue={defaultValue.endDate}
        />
        <span className={style.checkboxContainer}>
          <b>{Lang.LBL_WORK_GROUP}</b> :{Lang.NTE_FILTER_WORKGROUP_AUDIT_REPORT}
          <ATMCheckbox
            checked={selectAll ?? false}
            className={style.checkboxStyle}
            label={Lang.TTL_OC_LER_SELECT_ALL}
            onChange={(_, { checked }) => {
              if (checked === false || checked === undefined) {
                setWorkGroup(undefined);
                setSelectAll(false);
                register('workGroups');
                resetField('workGroups', { defaultValue: undefined });
              } else if (checked === true) {
                setWorkGroup(workGroupState.list?.map((v) => v.trbGrpId));
                setSelectAll(true);
                register('workGroups');
                resetField('workGroups', {
                  defaultValue: workGroupState.list?.map((v) => v.trbGrpId),
                });
              }
              return checked;
            }}
          />
        </span>
      </ATMGrid.Column>
      <ATMGrid.Row>
        {workGroupState.list &&
          workGroupState.list.length > 0 &&
          workGroupState.list.map((v) => {
            return (
              <ATMGrid.Column key={v.trbGrpId} width={2}>
                <span className={style.checkboxContainer}>
                  <ATMCheckbox
                    className={style.checkboxStyle}
                    checked={
                      workGroup?.some((val) => val === v.trbGrpId) ?? false
                    }
                    label={v.trbGrpNm}
                    onChange={(_, { checked }) => {
                      handleWorkGroup(v.trbGrpId);
                      return checked;
                    }}
                  />
                </span>
              </ATMGrid.Column>
            );
          })}
      </ATMGrid.Row>
      <ATMGrid.Row style={{ paddingTop: '0' }}>
        <ATMGrid.Column>
          <ATMButton
            primary
            content="Search"
            onClick={() => handleSubmit(getValues())}
            disabled={
              Object.values(getValues()).every((el) => el === undefined) ||
              !hasDateRange
            }
            loading={loading}
          />
          <ATMButton
            secondary
            content="Clear"
            disabled={Object.values(getValues()).every(
              (el) => el === undefined
            )}
            onClick={() => {
              reset({
                startDate: undefined,
                endDate: undefined,
                isBoth: false,
                dateTime: undefined,
              });
              setWorkGroup(undefined);
              handleNoRecord(true);
              setSelectAll(false);
              setDateTime(undefined);
              setIsBoth(false);
              troubleTicketActions.auditReportListCLEAR();
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const TroubleTicketAuditReportFilter: React.FC<IProps> = ({
  handleSubmit,
  handleNoRecord,
  loading,
  setHasDateRange,
  hasDateRange,
}) => {
  return (
    <div className={style.wrapper}>
      <ATMForm
        mode="onChange"
        validationSchema={TroubleTicketAuditReportFilterSchema}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <FormContent
                handleNoRecord={handleNoRecord}
                handleSubmit={handleSubmit}
                defaultValue={{
                  workGroups: [252, 254, 253, 255],
                  isBoth: true,
                }}
                loading={loading}
                setHasDateRange={setHasDateRange}
                hasDateRange={hasDateRange}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default TroubleTicketAuditReportFilter;
