import React from 'react';
import {
  ATMButton,
  ORGDataTable,
  IORGDataTableColumn,
  MOLNoData,
} from 'shared-it-appmod-ui';
import { AuthListType, EmployeeStatus } from 'src/constants';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { orderBy } from 'lodash';
import { IAuthorizedExpiredListSchema } from 'src/models/authorized-list.model';
import Lang from 'src/libraries/language';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { getEmployeeName } from 'src/helpers/employee.helper';
import style from '../../expiring-trainings.module.scss';

type IProps = {
  handleDownload: (authType: string, date: Date) => void;
  data?: IAuthorizedExpiredListSchema[];
  loading: boolean;
  dateAuthTraining?: Date;
  handleTabChange: (
    index?: any,
    callback?: ((data: URLSearchParams) => URLSearchParams) | undefined
  ) => void;
  setRequestStatus: React.Dispatch<React.SetStateAction<number>>;
};

const AuthTrainingsTable: React.FC<IProps> = ({
  handleDownload,
  data,
  loading,
  dateAuthTraining,
  handleTabChange,
  setRequestStatus,
}) => {
  const columns: IORGDataTableColumn<IAuthorizedExpiredListSchema>[] = [
    {
      index: 'employee.lastName',
      title: 'Name',
      render: (_, value) => (
        <>
          {value.employee?.lastName?.charAt(0) ===
          ('A' || 'D' || 'G' || 'J' || 'M' || 'P' || 'S' || 'V' || 'Y') ? (
            <>
              <span
                className={`${style.capitalLetterA} ${style.capitalLetterStyled}`}
              >
                {value.employee?.lastName?.charAt(0)}
              </span>{' '}
              <span className={style.nameEmployee}>
                {value.employee?.fullName ? (
                  <a
                    role="button"
                    style={{ color: 'inherit' }}
                    onClick={() => {
                      setRequestStatus(0);
                      handleTabChange(
                        `${value.empId}&tab=1&getPreviousSearch=true&requestStatus=0`
                      );
                    }}
                  >
                    {/* {value.employee?.fullName} */}
                    {getEmployeeName(value.employee, false)}
                  </a>
                ) : (
                  Lang.LBL_UNAVAILABLE
                )}
              </span>
            </>
          ) : (
            <>
              {value.employee?.lastName?.charAt(0) ===
              ('B' || 'E' || 'H' || 'K' || 'N' || 'Q' || 'T' || 'W' || 'X') ? (
                <>
                  <span
                    className={`${style.capitalLetterB} ${style.capitalLetterStyled}`}
                  >
                    {value.employee?.lastName?.charAt(0)}
                  </span>{' '}
                  <span className={style.nameEmployee}>
                    {value.employee?.fullName ? (
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          setRequestStatus(0);
                          handleTabChange(
                            `${value.empId}&tab=1&getPreviousSearch=true&requestStatus=0`
                          );
                        }}
                      >
                        {/* {value.employee?.fullName} */}
                        {getEmployeeName(value.employee, false)}
                      </a>
                    ) : (
                      Lang.LBL_UNAVAILABLE
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className={`${style.capitalLetterC} ${style.capitalLetterStyled}`}
                  >
                    {value.employee?.lastName?.charAt(0)}
                  </span>{' '}
                  <span className={style.nameEmployee}>
                    {value.employee?.fullName ? (
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          setRequestStatus(0);
                          handleTabChange(
                            `${value.empId}&tab=1&getPreviousSearch=true&requestStatus=0`
                          );
                        }}
                      >
                        {/* {value.employee?.fullName} */}
                        {getEmployeeName(value.employee, false)}
                      </a>
                    ) : (
                      Lang.LBL_UNAVAILABLE
                    )}
                  </span>
                </>
              )}
            </>
          )}
        </>
      ),
    },
    {
      index: 'trainingDate',
      title: Lang.LBL_TRAINING_DATE,
      render: (_, value) =>
        Moment(formatDate(value.trainingDate), 'MM/DD/YYYY')
          .utc()
          .format('MM/DD/YYYY'),
    },
    {
      index: 'contracShortNm',
      title: Lang.LBL_FIRM_NAME,
      render: (_, { employee }) => employee?.contracShortNm ?? '--',
    },
    {
      index: 'empId',
      title: Lang.LBL_EMPLOYEE_ID,
    },
    {
      index: 'employee.employeeStat',
      title: Lang.LBL_EMPLOYEE_STATUS,
      render: (_, { employee }) => (
        <ListStatus
          value={
            employee?.employeeStat === 'A'
              ? EmployeeStatus.ACTIVE
              : EmployeeStatus.INACTIVE
          }
        />
      ),
    },
    {
      index: 'employee.email',
      title: Lang.LBL_EMPLOYEE_EMAIL,
      render: (_, { employee }) => employee?.email?.trim(),
    },
  ];

  return (
    <ORGDataTable
      columns={columns}
      data={!data ? [] : orderBy(data, ['employee.lastName'], ['asc'])}
      noDataText={
        <MOLNoData
          icon="search"
          header="Search to view records"
          subHeader="Please select a date to see the results"
        />
      }
      sortable
      loading={loading}
      celled={false}
      counter
      rowsPerPageOptions={[50]}
    >
      {() => ({
        toolbars: {
          buttons: () => (
            <>
              <ATMButton
                key="download"
                secondary
                icon="download"
                size="tiny"
                onClick={() => {
                  handleDownload(
                    AuthListType.Training,
                    dateAuthTraining ?? new Date()
                  );
                }}
              />
            </>
          ),
        },
      })}
    </ORGDataTable>
  );
};

export default AuthTrainingsTable;
