import { Amplify, Hub } from '@aws-amplify/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import ENV from 'src/constants/env.constants';

export type IAuthUser = {
  e_mail_address?: string;
  emp_no: string;
  first_name: string;
  last_name: string;
};

const requiredEnv = {
  VITE_COGNITO_REGION: ENV.VITE_COGNITO_REGION,
  VITE_COGNITO_POOL_ID: ENV.VITE_COGNITO_POOL_ID,
  VITE_COGNITO_CLIENT_ID: ENV.VITE_COGNITO_CLIENT_ID,
  VITE_COGNITO_DOMAIN: ENV.VITE_COGNITO_DOMAIN,
  VITE_COGNITO_TOKEN_SCOPE: ENV.VITE_COGNITO_TOKEN_SCOPE,
};

Object.entries(requiredEnv).forEach(([key, value]) => {
  if (!value || (value ?? '').includes('#%{')) {
    // eslint-disable-next-line no-alert
    alert(
      `Environment Variable Error: Please configure ${key} properly in .env or .env.local file`
    );
  }
});
const cognitoRegion = ENV.VITE_COGNITO_REGION ?? '';
const cognitoDomain = ENV.VITE_COGNITO_DOMAIN ?? '';
const clientId = ENV.VITE_COGNITO_CLIENT_ID ?? '';
const redirectSignIn = window.location.origin;
const redirectSignOut = `${window.location.origin}/logout`;
const cognitoScopes = [
  ENV.VITE_COGNITO_TOKEN_SCOPE ?? '',
  'email',
  'openid',
  'profile',
];

const config = {
  Auth: {
    region: cognitoRegion,
    userPoolId: ENV.VITE_COGNITO_POOL_ID ?? 'us-west-2_TEST12345',
    userPoolWebClientId: clientId,
    cookieStorage: {
      path: '/',
      expires: '',
      domain: window.location.hostname,
      secure: true,
    },
    oauth: {
      domain: cognitoDomain,
      scope: cognitoScopes,
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  },
};

Amplify.configure(config);

const clearStorage = () => {
  localStorage.removeItem(`${clientId}_user_data`);
  localStorage.removeItem(`${clientId}_user_token`);
};

export const redirect = () => {
  const url = localStorage.getItem(`${clientId}_url`);
  const attempt = localStorage.getItem(`${clientId}_attempt`);
  // Auth.federatedSignIn({
  //   provider: CognitoHostedUIIdentityProvider.Cognito,
  // });

  if (
    (url && url.includes('unauthorized')) ||
    (attempt && Number(attempt) > 3)
  ) {
    localStorage.removeItem(`${clientId}_url`);
    localStorage.removeItem(`${clientId}_attempt`);
    window.location.href = `${window.location.origin}/logout`;
    return;
  }

  localStorage.setItem(
    `${clientId}_url`,
    window.location.href.replace(/logout.*/, '')
  );

  clearStorage();

  // Put all of our parameters together for the request
  const redirectURL = new URL(`https://${cognitoDomain}/oauth2/authorize`);

  redirectURL.searchParams.append('client_id', clientId);
  redirectURL.searchParams.append('redirect_uri', redirectSignIn);
  redirectURL.searchParams.append('response_type', 'code');
  redirectURL.searchParams.append('scope', cognitoScopes.join(' '));

  // Bypass cognito hosted UI
  window.location.href = redirectURL.toString();
};
export const logout = async (callback?: () => void) => {
  clearStorage();
  localStorage.removeItem(`${clientId}_url`);
  localStorage.removeItem(`${clientId}_attempt`);

  await Auth.signOut();

  if (callback) {
    callback();
  }
};

export const setUser = (user: IAuthUser) => {
  localStorage.setItem(`${clientId}_user_data`, JSON.stringify(user));
};
export const getUser = () => {
  const user = localStorage.getItem(`${clientId}_user_data`);

  return user ? (JSON.parse(user) as IAuthUser) : null;
};

export const getToken = () => {
  return localStorage.getItem(`${clientId}_user_token`);
};

const setToken = (token: string) => {
  localStorage.setItem(`${clientId}_user_token`, token);
};

export const refreshToken = async () => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();

    if (user) {
      const signInUserSession = await user.getSignInUserSession();

      if (signInUserSession) {
        const token = signInUserSession.getRefreshToken();

        return await new Promise<boolean>((resolve) => {
          user.refreshSession(token, (err, session) => {
            if (err) {
              logout();
              resolve(false);
            } else {
              user.setSignInUserSession(session);
              setToken(session.getAccessToken().getJwtToken());
              resolve(true);
            }
          });
        });
      }
    }

    return false;
  } catch (e: any) {
    return false;
  }
};

export const hasAuth = async () => {
  const user = await Auth.currentUserInfo();

  if (user) {
    return true;
  }

  return !!(await refreshToken());
};

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn_failure':
    case 'cognitoHostedUI_failure': {
      localStorage.setItem(
        `${clientId}_url`,
        `${window.location.origin}/logout/unauthorized`
      );
      break;
    }

    case 'signIn': {
      if (data.signInUserSession.accessToken) {
        const { jwtToken } = data.signInUserSession.accessToken;
        setToken(jwtToken);

        const attempt = localStorage.getItem(`${clientId}_attempt`);
        localStorage.setItem(
          `${clientId}_attempt`,
          (attempt ? Number(attempt) + 1 : 1).toString()
        );
      }

      if (data.signInUserSession.idToken) {
        const { payload } = data.signInUserSession.idToken;

        setUser({
          first_name: payload.given_name,
          last_name: payload.family_name,
          emp_no: payload.profile,
          e_mail_address: payload.name,
        });

        window.location.href =
          localStorage.getItem(`${clientId}_url`) || window.location.href;
      }

      break;
    }

    case 'tokenRefresh': {
      localStorage.setItem(`${clientId}_url`, window.location.href);

      break;
    }
  }
});
