import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/roles.service';
import {
  IRolesEmployee,
  IRole,
  IRolesByEmpId,
  IRoleEmployeeDetail,
  IRoleEmployeeItem,
  IRequestorReport,
} from 'src/models/roles.model';
import { setAccessRole } from 'src/libraries/access.library';
import { getUser } from 'src/libraries/amplify.library';
import { getEmployeeSort } from 'src/helpers/employee.helper';

export const rolesActionTypes = {
  ROLES_DATA_READ: 'ROLES_DATA_READ',
  ROLES_LIST_READ: 'ROLES_LIST_READ',
  ROLES_EMPLOYEE_LIST_READ: 'ROLES_EMPLOYEE_LIST_READ',
  ROLES_LIST_ALL_READ: 'ROLES_LIST_ALL_READ',
  ROLES_DATA_CREATE: 'ROLES_DATA_CREATE',
  ROLES_DATA_UPDATE: 'ROLES_DATA_UPDATE',
  ROLES_DATA_DELETE: 'ROLES_DATA_DELETE',
  ROLES_OPTIONS_LIST_READ: 'ROLES_OPTIONS_LIST_READ',
  ROLES_LIST_CLEAR_LIST: 'ROLES_LIST_CLEAR_LIST',
  ROLES_KEY_REQUESTOR_LIST_READ: 'ROLES_KEY_REQUESTOR_LIST_READ',
  ROLES_KEY_REQUESTOR_LIST_ALL_READ: 'ROLES_KEY_REQUESTOR_LIST_ALL_READ',
  ROLES_LIST_OUTAGE_READ: 'ROLES_LIST_OUTAGE_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataByEmpIdGET: {
    type: rolesActionTypes.ROLES_DATA_READ,
    service: services.dataByEmpIdGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  listGET: {
    type: rolesActionTypes.ROLES_LIST_READ,
    service: services.listGET,
  },

  listByEmpGET: {
    type: rolesActionTypes.ROLES_EMPLOYEE_LIST_READ,
    service: services.listByEmpGET,
  },

  outageListGET: {
    type: rolesActionTypes.ROLES_LIST_OUTAGE_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: rolesActionTypes.ROLES_LIST_ALL_READ,
    service: services.listGET,
  },

  createPOST: {
    type: rolesActionTypes.ROLES_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: rolesActionTypes.ROLES_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: rolesActionTypes.ROLES_DATA_DELETE,
    service: services.dataDELETE,
  },

  findAllOptionRolesGET: {
    type: rolesActionTypes.ROLES_OPTIONS_LIST_READ,
    service: services.findAllOptionRolesGET,
  },

  keyRequestorListGET: {
    type: rolesActionTypes.ROLES_KEY_REQUESTOR_LIST_READ,
    service: services.keyRequestorListGET,
  },

  keyRequestorListAllGET: {
    type: rolesActionTypes.ROLES_KEY_REQUESTOR_LIST_ALL_READ,
    service: services.keyRequestorListGET,
  },

  clearList: () => ({
    type: rolesActionTypes.ROLES_LIST_CLEAR_LIST,
  }),
};

export type IRolesAsync = typeof duckActions;

export interface IRolesState extends ICommonState<typeof rolesActionTypes> {
  data?: IRoleEmployeeDetail;
  list: IRolesEmployee[];
  adminList: IRoleEmployeeItem[];
  adminTotal: number;
  outageList: IRolesEmployee[];
  total: number;
  roles: IRole[];
  rolesByEmpId: IRolesByEmpId[];
  keyRequestor: IRequestorReport[];
  keyRequestorTotal: number;
  listAll?: IRolesEmployee[];
}

export const defaultState: IRolesState = {
  status: {},
  list: [],
  total: 0,
  roles: [],
  outageList: [],
  rolesByEmpId: [],
  keyRequestor: [],
  adminList: [],
  keyRequestorTotal: 0,
  adminTotal: 0,
};

const RolesReducer = (
  state: IRolesState,
  action: IReducerAction<IRolesAsync>
): IRolesState => {
  switch (action.type) {
    case rolesActionTypes.ROLES_DATA_READ:
    case rolesActionTypes.ROLES_DATA_UPDATE:
    case rolesActionTypes.ROLES_DATA_CREATE: {
      const user = getUser();
      if (
        action.payload &&
        user &&
        action.payload?.roles &&
        user.emp_no === action.payload.empId
      ) {
        setAccessRole(
          (action?.payload?.roles ?? []).map((item) => Number(item.roleId))
        );

        return {
          ...state,
          data: action?.payload,
          rolesByEmpId: action?.payload?.roles ?? [],
        };
      }

      return {
        ...state,
        data: action?.payload,
      };
    }

    case rolesActionTypes.ROLES_LIST_READ: {
      return {
        ...state,
        list: getEmployeeSort(action.payload?.rows ?? [], 'employee.lastName'),
        total: action.payload?.count ?? 0,
      };
    }

    case rolesActionTypes.ROLES_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        adminList: action.payload?.rows ?? [],
        adminTotal: action.payload?.count ?? 0,
      };
    }

    case rolesActionTypes.ROLES_LIST_OUTAGE_READ: {
      return {
        ...state,
        outageList: action.payload?.rows ?? [],
      };
    }

    case rolesActionTypes.ROLES_KEY_REQUESTOR_LIST_READ: {
      return {
        ...state,
        keyRequestor: action.payload?.rows ?? [],
        keyRequestorTotal: action.payload?.count ?? 0,
      };
    }

    case rolesActionTypes.ROLES_OPTIONS_LIST_READ: {
      return {
        ...state,
        roles: action?.payload?.rows ?? [],
      };
    }

    case rolesActionTypes.ROLES_LIST_ALL_READ: {
      return {
        ...state,
        listAll: action?.payload?.rows,
      };
    }

    case rolesActionTypes.ROLES_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.empId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case rolesActionTypes.ROLES_LIST_CLEAR_LIST: {
      return {
        ...state,
        adminList: [],
        adminTotal: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default RolesReducer;
