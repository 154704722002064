import React, { useCallback } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useUserGroupContext } from 'src/contexts/user-group.context';
import { userGroupActionTypes } from 'src/ducks/user-group.duck';
import { getUserGroupStatus } from 'src/selectors/user-group.selector';
import UserGroupListView from './user-group-list.view';

const UserGroupList: React.FC = () => {
  const { state, actions } = useUserGroupContext();
  const status = getUserGroupStatus(
    state,
    userGroupActionTypes.USER_GROUP_LIST_READ
  );

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      actions.listGET({ ...params, page: 1, limit: 0 });
    },
    [actions]
  );

  return (
    <UserGroupListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      total={state.total}
    />
  );
};

export default UserGroupList;
