import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContractorFirmReducer, {
  IContractorFirmState,
  defaultState,
  duckActions,
} from 'src/ducks/contractor-firm.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    ContractorFirmReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IContractorFirmContext = ReturnType<typeof useReducer>;

const ContractorFirmContext = createContext<Partial<IContractorFirmContext>>({
  state: defaultState,
}) as React.Context<IContractorFirmContext>;

type IProps = {
  state?: Partial<IContractorFirmState>;
};

const ContractorFirmProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ContractorFirmContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ContractorFirmContext.Provider>
  );
};

const useContractorFirmContext = () => useContext(ContractorFirmContext);

export type IUseContractorFirmContext = ReturnType<
  typeof useContractorFirmContext
>;

export {
  ContractorFirmContext,
  ContractorFirmProvider,
  useContractorFirmContext,
};
