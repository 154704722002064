import React, { useCallback, useEffect, useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMLabel,
  ATMDatePicker,
  IATMTabProps,
  ATMMenu,
  ATMMessage,
  ATMButton,
} from 'shared-it-appmod-ui';
import { formatDate, formatDates } from 'src/libraries/moment.library';
import { IKeyRequest } from 'src/models/key-request.model';
import history from 'src/history';
import Lang from 'src/libraries/language';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { KeyRequestStatus } from 'src/constants';
import { getEmployeeName } from 'src/helpers/employee.helper';
import KeyRequestEdit from '../key-request-edit/key-request-edit.component';
import style from '../key-request.module.scss';
import KeyRequestCard from '../key-request-card/key-request-card.component';

const columns: IORGDataTableColumn<IKeyRequest>[] = [
  {
    index: 'requestNbr',
    title: Lang.LBL_REQUEST_ID_TYPE,
    render: (_, value) => {
      if (value.requestTyp) {
        return (
          <>
            <div>
              <KeyRequestCard keyRequest={value} />{' '}
              <ATMLabel horizontal basic>
                {value.requestTyp}
              </ATMLabel>
            </div>
          </>
        );
      }
      return (
        <>
          <div>
            <KeyRequestCard keyRequest={value} />{' '}
            <ATMLabel horizontal basic>
              {Lang.LBL_UNAVAILABLE}
            </ATMLabel>
          </div>
        </>
      );
    },
  },
  {
    index: 'requestedFor', // US #508370 10/05/2023 Sorting of tables (requestedFor)
    title: Lang.LBL_REQUEST_FOR,
    render: (_, value) => getEmployeeName(value.emp),
  },
  {
    index: 'requestedBy',
    title: Lang.LBL_REQUEST_BY,
    render: (_, value) => getEmployeeName(value.requestor),
  },
  {
    index: 'stat',
    title: Lang.LBL_STATUS,
    render: (_, value) => (
      <>
        <ListStatus
          value={
            value.stat === KeyRequestStatus.APPROVED
              ? Lang.LBL_APPROVED
              : value.stat
          }
        />
      </>
    ),
  },
  {
    index: 'requestDt',
    title: Lang.LBL_REQUEST_DATE,
    render: (_, value) => formatDate(value.requestDt),
  },
  {
    index: 'action',
    title: Lang.LBL_ACTION,
    sortable: false,
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        {value.stat === KeyRequestStatus.PENDINGAPPROVAL ? (
          <KeyRequestEdit data={value} />
        ) : (
          '-'
        )}
      </>
    ),
  },
];

type IProps = {
  data: IKeyRequest[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  approvedTotal: number;
  pendingTotal: number;
  rejectedTotal: number;
  currentTab: number;
};
const currentTabTotal = (
  currentTab: number,
  pendingTotal: number,
  approvedTotal: number,
  rejectedTotal: number,
  total: number
) => {
  switch (currentTab) {
    case 1:
      return approvedTotal;
    case 2:
      return rejectedTotal;
    case 3:
      return total;
    default:
      return pendingTotal;
  }
};

const KeyRequestListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  approvedTotal,
  pendingTotal,
  rejectedTotal,
  currentTab,
}) => {
  const { state: keyRequestCreateState, actions: keyRequestCreateActions } =
    useKeyRequestContext();
  const status = getKeyRequestStatus(
    keyRequestCreateState,
    keyRequestActionTypes.KEY_REQUEST_DATA_CREATE
  );
  const statusEmployee = getKeyRequestStatus(
    keyRequestCreateState,
    keyRequestActionTypes.KEY_REQUEST_DATA_EMPLOYEE_CREATE
  );
  const [errorMsgEmployee, setErrorMsgEmployee] = useState(false);
  const [errorMsgStatus, setErrorMsgStatus] = useState(false);
  const clearError = useCallback(() => {
    keyRequestCreateActions?.clearError();
  }, [keyRequestCreateActions]);
  useEffect(() => {
    if (statusEmployee.error?.name === 'BadRequest') {
      setErrorMsgEmployee(true);
    }
  }, [statusEmployee.error]);

  useEffect(() => {
    if (errorMsgEmployee) {
      setTimeout(() => {
        setErrorMsgEmployee(false);
        clearError();
      }, 15000);
    }
  }, [errorMsgEmployee]);

  useEffect(() => {
    if (status.error?.name === 'BadRequest') {
      setErrorMsgStatus(true);
    }
  }, [status.error]);

  useEffect(() => {
    if (errorMsgStatus) {
      setTimeout(() => {
        setErrorMsgStatus(false);
        clearError();
      }, 15000);
    }
  }, [errorMsgStatus]);

  const table = (
    <ORGDataTable
      key={currentTab}
      celled={false}
      columns={columns}
      data={data}
      onChange={handleFetch}
      loading={loading}
      sortable
      total={currentTabTotal(
        Number(currentTab),
        pendingTotal,
        approvedTotal,
        rejectedTotal,
        total
      )}
      rowsPerPageOptions={[10, 25, 50, 100]}
      counter
      location={history.location}
      handleLocation={history.push}
    >
      {({ state, setState }) => ({
        toolbars: [
          () => (
            <span className={style.selectables}>
              <TableSearch
                field="empId"
                state={state}
                setState={setState}
                placeholder={Lang.LBL_KEY_HOLDER_SEARCH}
              />
              <TableSearch
                field="supvrNbr"
                state={state}
                setState={setState}
                placeholder={Lang.LBL_SUPERVISOR_MNGR_DROPDOWN}
              />
              <span className={style.dateRange}>
                <ATMDatePicker
                  type="range"
                  size="small"
                  format="MM/DD/YYYY"
                  pointing="right"
                  placeholder={Lang.LBL_REQUEST_DATE}
                  onChange={(_, val) => {
                    if (val.value === null) {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'requestDt',
                            value: undefined,
                          },
                        ],
                      });
                    } else if (val.value && !val.value[1]) {
                      // no movement
                    } else {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'requestDt',
                            value: formatDates(val.value),
                          },
                        ],
                      });
                    }
                  }}
                />
              </span>
            </span>
          ),
        ],
      })}
    </ORGDataTable>
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_PENDING_REQUEST} ({pendingTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_APPROVED} ({approvedTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="2">
          {Lang.LBL_REJECTED} ({rejectedTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="3">
          {Lang.LBL_ALL} ({total})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
  ];

  const getFormattedErrorMessage = (errorMessage) => {
    const errorMsg = errorMessage?.split(/\n/g);
    // eslint-disable-next-line no-nested-ternary
    return errorMsg?.length > 1 ? (
      <>
        <p style={{ marginTop: '0.7em' }}>{errorMsg[0]}</p>
        <p>{errorMsg[1]}</p>
      </>
    ) : errorMsg?.length ? (
      <p style={{ marginTop: '0.7em' }}>{errorMsg[0]}</p>
    ) : (
      ''
    );
  };

  return (
    <div className={style.headerTabs}>
      {errorMsgStatus && (
        <ATMMessage negative>
          {status.error.message}{' '}
          <ATMButton
            className={style.closeErrorBtn}
            icon="close"
            onClick={clearError}
          />
        </ATMMessage>
      )}

      {errorMsgEmployee && (
        <ATMMessage negative className={style.errorMessageWrapper}>
          {getFormattedErrorMessage(statusEmployee.error?.message)}{' '}
          <ATMButton
            className={style.closeErrorBtn}
            icon="close"
            onClick={() => {
              clearError();

              setErrorMsgEmployee(false);
            }}
          />
        </ATMMessage>
      )}
      <Tabs name="stat" menu={{ pointing: true }} panes={panes} />
    </div>
  );
};

export default KeyRequestListView;
