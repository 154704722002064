import { AccessRole, AccessType, AccessPermission } from 'src/constants';

export { AccessType };

export type IHasAccessType = AccessType | AccessType[];
let roleType: AccessRole[] = [];

const flattenList = (type: IHasAccessType) => {
  return (Array.isArray(type) ? type : [type]).reduce(
    (items: AccessType[], item) =>
      Array.isArray(item) ? [...items, ...flattenList(item)] : [...items, item],
    []
  );
};

export const setAccessRole = (role: AccessRole[]) => {
  roleType = role;
};

export const getIsAdmin = (role: AccessRole) => {
  const adminRole = [
    AccessRole.MCC_OUTAGE_SCHEDULER,
    AccessRole.MCC_OPERATIONAL_ENGINEER,
    AccessRole.MCC_REAL_TIME_OPERATOR,
    AccessRole.ROLES_ADMINISTRATOR,
    AccessRole.GCC_KEY_ADMINISTRATOR,
    AccessRole.GCC_SUBENTRY_LOG_UPDATE,
    AccessRole.GCC_AUTH_LIST_ADMIN,
    AccessRole.REAL_TIME_LOG_UPDATER,
    AccessRole.TROUBLE_TICKET_RESOLVER,
    AccessRole.TROUBLE_TICKET_REQUESTOR,
    AccessRole.MCC_SUBMITTING_SUPERVISOR,
    AccessRole.TROUBLE_JOB_UPDATER,
  ];
  return adminRole.includes(roleType?.filter((item) => item === role)[0]);
};

export const getAccessRole = () => {
  return roleType;
};

export const hasRole = (role: AccessRole | AccessRole[]) => {
  const roles = (Array.isArray(role) === false ? [role] : role) as AccessRole[];

  return roles.some((value) => roleType.includes(value));
};

export const hasAccess = (type: IHasAccessType) => {
  const list = flattenList(type);
  const roleList = getAccessRole();

  return Object.keys(AccessPermission).some((role) => {
    return list.every((value) => {
      if ((roleList || []).includes(Number(role) as unknown as AccessRole)) {
        return AccessPermission[role].includes(value);
      }

      return false;
    });
  });
};
