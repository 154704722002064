import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  ISubstationCountListForm,
  SubstationCountListListPayloadSchema,
  SubstationCountListDeletePayloadSchema,
  SubstationCountListFormPayloadSchema,
  SubstationCountListPayloadSchema,
} from 'src/models/substation-count-list.model';

const client = httpClient();
const endpoint = 'substation-count';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, SubstationCountListPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, SubstationCountListListPayloadSchema);
  },

  createPOST: async (data: ISubstationCountListForm[]) => {
    return client.post(`/${endpoint}`, data, SubstationCountListFormPayloadSchema);
  },

  updatePUT: async (data: ISubstationCountListForm[]) => {
    return client.put(`/${endpoint}`, data, SubstationCountListFormPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SubstationCountListDeletePayloadSchema);
  },
};

export default services;
