import { sickListActionTypes, ISickListState } from 'src/ducks/sick-list.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getSickListStatus = (
  state: ISickListState,
  action: keyof typeof sickListActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
