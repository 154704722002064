import React, { useCallback, useRef, useState } from 'react';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { IEquipmentTypeForm } from 'src/models/equipment-type.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getUser } from 'src/libraries/amplify.library';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import EquipmentTypeAddView from './equipment-type-add.view';

const EquipmentTypeAdd: React.FC = () => {
  const { state, actions } = useEquipmentTypeContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getEquipmentTypeStatus(
    state,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IEquipmentTypeForm) => {
      const response = await actions.createPOST({
        ...formData,
        updatedAt: new Date(),
        updatedBy: getUser()?.emp_no,
      });

      if (!response.error) {
        actions.listGET({ page: 1, limit: 50 });
        setIsAddOpen(false);

        ToastSuccess(
          `Equipment Type ${response.payload?.facTypNm} has been created successfully`
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <EquipmentTypeAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default EquipmentTypeAdd;
