import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import EquipmentTypeReducer, {
  IEquipmentTypeState,
  defaultState,
  duckActions,
} from 'src/ducks/equipment-type.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    EquipmentTypeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IEquipmentTypeContext = ReturnType<typeof useReducer>;

const EquipmentTypeContext = createContext<Partial<IEquipmentTypeContext>>({
  state: defaultState,
}) as React.Context<IEquipmentTypeContext>;

type IProps = {
  state?: Partial<IEquipmentTypeState>;
};

const EquipmentTypeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <EquipmentTypeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </EquipmentTypeContext.Provider>
  );
};

const useEquipmentTypeContext = () => useContext(EquipmentTypeContext);

export type IUseEquipmentTypeContext = ReturnType<
  typeof useEquipmentTypeContext
>;

export { EquipmentTypeContext, EquipmentTypeProvider, useEquipmentTypeContext };
