import React from 'react';
import ClassNames from 'classnames';
import styles from './label-required.module.scss';

type IProps = {
  left?: boolean;
};

const LabelRequired: React.FC<IProps> = ({ left = false, children }) => (
  <label className={ClassNames(left ? styles.left : styles.right)}>
    {children}
  </label>
);

export default LabelRequired;
