import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import JobCodeRuleReducer, {
  IJobCodeRuleState,
  defaultState,
  duckActions,
} from 'src/ducks/job-code-rule.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    JobCodeRuleReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IJobCodeRuleContext = ReturnType<typeof useReducer>;

const JobCodeRuleContext = createContext<Partial<IJobCodeRuleContext>>({
  state: defaultState,
}) as React.Context<IJobCodeRuleContext>;

type IProps = {
  state?: Partial<IJobCodeRuleState>;
};

const JobCodeRuleProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <JobCodeRuleContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </JobCodeRuleContext.Provider>
  );
};

const useJobCodeRuleContext = () => useContext(JobCodeRuleContext);

export type IUseJobCodeRuleContext = ReturnType<typeof useJobCodeRuleContext>;

export { JobCodeRuleContext, JobCodeRuleProvider, useJobCodeRuleContext };
