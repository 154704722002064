import React, { useCallback, useEffect } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMHeader,
  ATMDivider,
  ATMDatePicker,
  ATMDropdown,
  ATMTextArea,
  ATMMessage,
  formatTime,
  ATMTable,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Status from 'src/components/atoms/status/status.component';
import {
  NameFlag,
  PrimaryOutageCauseCode,
  SecondaryOutageCauseCode,
} from 'src/constants';
import { useOmsRequestContext } from 'src/contexts/oms-request.context';
import { useIsoWorkKindContext } from 'src/contexts/iso-work-kind.context';
import Lang from 'src/libraries/language';
import Moment, {
  format24hTime,
  formatDate,
  formatTimeOnly,
  format24hDateTime,
} from 'src/libraries/moment.library';
import {
  IOmsRequest,
  IOmsRequestForm,
  OmsRequestFormSchema,
} from 'src/models/oms-request.model';
import moment from 'moment';
import style from './oms-request-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IOmsRequest>;
  handleSubmit: (data: IOmsRequestForm) => void;
  handleEnable: (value: boolean) => void;
  readOnly: boolean;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
};

const OmsRequestForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  readOnly,
  setStartTime,
  setEndTime,
}) => {
  const { state: isoWorkKindState, actions: isoWorkKindActions } =
    useIsoWorkKindContext();
  const { state } = useOmsRequestContext();

  const handleStartTime = useCallback(
    (sTime) => {
      setStartTime(sTime);
    },
    [setStartTime]
  );

  const handleEndTime = useCallback(
    (eTime) => {
      setEndTime(eTime);
    },
    [setEndTime]
  );

  useEffect(() => {
    handleStartTime(format24hTime(defaultValues?.estimatedPeriod?.start));
    handleEndTime(format24hTime(defaultValues?.estimatedPeriod?.end));
    if (isoWorkKindState.list.length === 0) {
      isoWorkKindActions.listGET();
    }
  }, []);

  const withOMSSwitches = () => {
    const results: any[] = [];
    defaultValues?.Equipments?.map((item) => {
      item?.OutagedEquipment?.Switch.map((switchItem) => {
        results.push(switchItem);
      });
    });
    return results.length > 0;
  };

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues}
      validationSchema={OmsRequestFormSchema}
      className={style.omsForm}
    >
      {({ control, formState: { errors, isDirty }, setError, setValue }) => {
        handleEnable(isDirty);

        return (
          <>
            {state.caisoError && (
              <div
                style={{
                  marginBottom: '2rem',
                  marginLeft: '1rem',
                  marginRight: '1rem',
                }}
              >
                <ATMMessage negative>
                  <ATMHeader as="h3">{state.caisoError.type}</ATMHeader>
                  <span>{state.caisoError.message}</span>
                </ATMMessage>
              </div>
            )}
            <ATMGrid className={style.readOnlyHeader}>
              <ATMGrid.Row className={style.subHeader}>
                <ATMGrid.Column width={2}>
                  {defaultValues?.outageType}
                </ATMGrid.Column>
                <ATMGrid.Column width={1} className={style.subHeaderBrd}>
                  {/* isFinalApprovalReq if YES FAR else FAN */}
                  {defaultValues?.isFinalApprovalReq === 'YES'
                    ? Lang.LBL_FAR
                    : Lang.LBL_FAN}
                </ATMGrid.Column>
                <ATMGrid.Column width={2}>
                  {/* outageStatus */}
                  <Status value={defaultValues?.outageStatus}>
                    {defaultValues?.outageStatus ?? '--'}
                  </Status>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
            <ATMGrid className={style.readOnlyContent}>
              <ATMGrid.Row>
                <ATMGrid.Column width={15}>
                  <ATMHeader as="h3" content="OMS Equipment" />
                  <ATMTable>
                    <ATMTable.Header>
                      <ATMTable.Row>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_ISO}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_TR_NAME}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_TR_ID}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_TYPE}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_SWITCH_MODELING_COMPLETE}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OC_LER_ISO_USE_FACILITY_MODEL}
                        </ATMTable.HeaderCell>
                      </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                      {defaultValues?.Equipments?.length ? (
                        defaultValues?.Equipments?.map((item, i) => {
                          const trID = item?.Names.filter((val) =>
                            val?.NameType.name === NameFlag.PARTICIPANTID
                              ? (val.name as any)
                              : ''
                          );
                          const trName = item?.Names.filter((val) =>
                            val?.NameType.name === NameFlag.PARTICIPANTNAME
                              ? (val.name as any)
                              : ''
                          );
                          return (
                            <ATMTable.Row key={i}>
                              <ATMTable.Cell>
                                {(item.name as any) ?? '--'}
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                {trName.length ? trName[0].name : '--'}
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                {trID.length ? trID[0].name : '--'}
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                {(item.PSRType?.type as any) ?? '--'}
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                {(item.OutagedEquipment
                                  ?.switchModelingComplete as any) ?? '--'}
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                {(item.OutagedEquipment
                                  ?.useFacilityOutageDefinition as any) ?? '--'}
                              </ATMTable.Cell>
                            </ATMTable.Row>
                          );
                        })
                      ) : (
                        <ATMTable.Row textAlign="center">
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                          <ATMTable.Cell>
                            {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                          </ATMTable.Cell>
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                        </ATMTable.Row>
                      )}
                    </ATMTable.Body>
                  </ATMTable>
                </ATMGrid.Column>
              </ATMGrid.Row>

              <ATMGrid.Row>
                <ATMGrid.Column width={15}>
                  <ATMHeader as="h3" content="OMS Switches" />
                  <ATMTable>
                    <ATMTable.Header>
                      <ATMTable.Row>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_ISO_Name}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_TR_NAME}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_TR_ID}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_STATE}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_START_DATE_TIME}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>
                          {Lang.LBL_OMS_SWITCHES_END_DATE_TIME}
                        </ATMTable.HeaderCell>
                      </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                      {defaultValues?.Equipments?.length &&
                      withOMSSwitches() ? (
                        defaultValues?.Equipments?.map((item, _) => {
                          let result;
                          const results: any[] = [];
                          item?.OutagedEquipment?.Switch.map(
                            (switchItem, i) => {
                              const startDate =
                                switchItem.SwitchingOperations.filter(
                                  (val) => val.newStatePeriod.start
                                );
                              const endDate =
                                switchItem.SwitchingOperations.filter(
                                  (val) => val.newStatePeriod.end
                                );
                              const newState =
                                switchItem?.SwitchingOperations.filter(
                                  (val) => val.newState
                                );
                              const trName = switchItem?.Names.filter((val) =>
                                val?.NameType.name === NameFlag.PARTICIPANTNAME
                                  ? (val.name as any)
                                  : ''
                              );
                              const trID = switchItem?.Names.filter((val) =>
                                val?.NameType.name === NameFlag.PARTICIPANTID
                                  ? (val.name as any)
                                  : ''
                              );
                              const newStateCapitalized =
                                newState.length &&
                                newState[0].newState.replace(
                                  /^./,
                                  newState[0].newState[0].toUpperCase()
                                );
                              result = (
                                <ATMTable.Row key={i}>
                                  <ATMTable.Cell>
                                    {switchItem.name ?? '--'}
                                  </ATMTable.Cell>
                                  <ATMTable.Cell>
                                    {trName.length ? trName[0].name : '--'}
                                  </ATMTable.Cell>
                                  <ATMTable.Cell>
                                    {trID.length ? trID[0].name : '--'}
                                  </ATMTable.Cell>
                                  <ATMTable.Cell>
                                    {newState.length
                                      ? (newStateCapitalized as any)
                                      : '--'}
                                  </ATMTable.Cell>
                                  <ATMTable.Cell>
                                    {format24hDateTime(
                                      moment(
                                        startDate[0].newStatePeriod.start
                                      ).toDate()
                                    ) ?? '--'}
                                  </ATMTable.Cell>
                                  <ATMTable.Cell>
                                    {format24hDateTime(
                                      moment(
                                        endDate[0].newStatePeriod.end
                                      ).toDate()
                                    ) ?? '--'}
                                  </ATMTable.Cell>
                                </ATMTable.Row>
                              );
                              results.push(result);
                            }
                          );
                          return results?.map((val) => val);
                        })
                      ) : (
                        <ATMTable.Row textAlign="center">
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                          <ATMTable.Cell>
                            {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                          </ATMTable.Cell>
                          <ATMTable.Cell />
                          <ATMTable.Cell />
                        </ATMTable.Row>
                      )}
                    </ATMTable.Body>
                  </ATMTable>
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMDivider />
            </ATMGrid>
            {readOnly ? (
              <ATMGrid className={style.readOnlyContent}>
                <ATMHeader as="h3" content="OMS Outage" />
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_BEGIN_DATE}>
                      <b>{formatDate(defaultValues?.estimatedPeriod?.start)}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_TIME_HH}>
                      <b>
                        {formatTimeOnly(defaultValues?.estimatedPeriod?.start)}
                      </b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_END_DATE}>
                      <b>{formatDate(defaultValues?.estimatedPeriod?.end)}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_TIME_HH}>
                      <b>
                        {formatTimeOnly(defaultValues?.estimatedPeriod?.end)}
                      </b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_EMERGENCY_RETURN_TIME}>
                      <b>{`${defaultValues?.emergencyReturnTime} ${defaultValues?.emergencyReturnTimeType}`}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_PARTICIPANT_OUTAGE_ID}>
                      <b>{defaultValues?.mktOrgOutageID}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_NATURE_OF_WORK}>
                      <b>{defaultValues?.Work?.workKind}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_RAS_SPS_OOS}>
                      <b>{defaultValues?.outOfServiceRASSPS ? 'Yes' : 'No'}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_RAS_SPS_RR}>
                      <b>{defaultValues?.redundancyRASSPS ? 'Yes' : 'No'}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_PROTECTION_ZONE}>
                      <b>{defaultValues?.protectionZone ? 'Yes' : 'No'}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_EMS_ICCP_OUTAGES}>
                      <b>{defaultValues?.outageEMSICCP ? 'Yes' : 'No'}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_RIMS_PROJECT_ID}>
                      <b>{defaultValues?.RIMSProjectID}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_RIMS_PROJECT_PHASE}>
                      <b>
                        {defaultValues?.RIMSProjectPhase &&
                        defaultValues?.RIMSProjectPhase !== ''
                          ? defaultValues?.RIMSProjectPhase
                          : '--'}
                      </b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_PRIMARY_OUTAGE_CAUSE_CODE}>
                      <b>
                        {defaultValues?.OutageCause?.map((item, index) => {
                          if (index === 0) {
                            return item.code;
                          }
                          return '--';
                        }).toString()}
                      </b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_SECONDARY_OUTAGE_CAUSE_CODE}>
                      <b>
                        {defaultValues?.OutageCause?.map((item, index) => {
                          if (index === 1) {
                            return item.code;
                          }
                          return '--';
                        }).toString()}
                      </b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_SHORT_DESCRIPTION}>
                      <b>{defaultValues?.description}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row className={style.readOnlyTitle}>
                  <ATMGrid.Column width={5}>
                    <FieldReadOnly label={Lang.LBL_NOTES}>
                      <b>{defaultValues?.comment}</b>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </ATMGrid>
            ) : (
              // editable
              <ATMGrid>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_BEGIN_DATE}
                      as={ATMDatePicker}
                      name="estimatedPeriod.start"
                      control={control}
                      error={
                        errors.estimatedPeriod && errors.estimatedPeriod.start
                      }
                      defaultValue={
                        defaultValues?.estimatedPeriod
                          ? Moment(
                              defaultValues?.estimatedPeriod.start
                            ).toDate()
                          : null
                      }
                      value={
                        defaultValues?.estimatedPeriod
                          ? Moment(
                              defaultValues?.estimatedPeriod.start
                            ).toDate()
                          : null
                      }
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      clearable
                      as={ATMInput}
                      label={Lang.LBL_TIME_HH}
                      name="estimatedPeriod.startTime"
                      control={control}
                      error={
                        errors.estimatedPeriod &&
                        errors.estimatedPeriod.startTime
                      }
                      defaultValue={
                        format24hTime(defaultValues?.estimatedPeriod?.start) ??
                        ''
                      }
                      value={
                        format24hTime(defaultValues?.estimatedPeriod?.start) ??
                        ''
                      }
                      icon="clock"
                      maxLength={5}
                      placeholder="Time"
                      onChange={([_, { value }]) => {
                        const formattedTime = formatTime(value);
                        if (value.length === 5) {
                          const hour = Number(value.split(':')[0]);
                          const min = Number(value.split(':')[1]);
                          if (!(hour > 23 || min > 59)) {
                            handleEnable(true);
                            handleStartTime(value);
                          } else {
                            setError('estimatedPeriod.startTime', {
                              message: 'Invalid Time. Format: hh:mm',
                            });
                          }
                        }
                        return formattedTime;
                      }}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_END_DATE}
                      as={ATMDatePicker}
                      name="estimatedPeriod.end"
                      control={control}
                      error={
                        errors.estimatedPeriod && errors.estimatedPeriod.end
                      }
                      defaultValue={
                        defaultValues?.estimatedPeriod
                          ? Moment(defaultValues?.estimatedPeriod.end).toDate()
                          : null
                      }
                      value={
                        defaultValues?.estimatedPeriod
                          ? Moment(defaultValues?.estimatedPeriod.end).toDate()
                          : null
                      }
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      clearable
                      as={ATMInput}
                      label={Lang.LBL_TIME_HH}
                      name="estimatedPeriod.endTime"
                      control={control}
                      error={
                        errors.estimatedPeriod && errors.estimatedPeriod.endTime
                      }
                      defaultValue={
                        format24hTime(defaultValues?.estimatedPeriod?.end) ?? ''
                      }
                      value={
                        format24hTime(defaultValues?.estimatedPeriod?.end) ?? ''
                      }
                      icon="clock"
                      maxLength={5}
                      placeholder="Time"
                      onChange={([_, { value }]) => {
                        const formattedTime = formatTime(value);
                        if (value.length === 5) {
                          const hour = Number(value.split(':')[0]);
                          const min = Number(value.split(':')[1]);
                          if (!(hour > 23 || min > 59)) {
                            handleEnable(true);
                            handleEndTime(value);
                          } else {
                            setError('estimatedPeriod.endTime', {
                              message: 'Invalid Time. Format: hh:mm',
                            });
                          }
                        }
                        return formattedTime;
                      }}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_EMERGENCY_RETURN_TIME}
                      as={ATMInput}
                      type="number"
                      name="emergencyReturnTime"
                      defaultValue={defaultValues?.emergencyReturnTime ?? ''}
                      control={control}
                      error={errors.emergencyReturnTime}
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_PARTICIPANT_OUTAGE_ID}
                      as={ATMInput}
                      name="mktOrgOutageID"
                      defaultValue={defaultValues?.mktOrgOutageID}
                      control={control}
                      error={errors.mktOrgOutageID}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_NATURE_OF_WORK}
                      as={ATMDropdown}
                      name="Work.workKind"
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={defaultValues?.Work?.workKind}
                      control={control}
                      error={errors.Work && errors.Work?.workKind}
                      selection
                      size="small"
                      clearable
                      search
                      options={isoWorkKindState.list.map((value) => ({
                        key: value.isoWorkKindId,
                        value: value.isoWorkKindId,
                        text: value.description
                          .split('_')
                          .map(
                            (string) =>
                              string.charAt(0) + string.slice(1).toLowerCase()
                          )
                          .join(' '),
                      }))}
                      onChange={([_, { value }]) => value}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_RAS_SPS_OOS}
                      as={ATMDropdown}
                      name="outOfServiceRASSPSTemp"
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={
                        defaultValues?.outOfServiceRASSPS ? 'Y' : 'N'
                      }
                      control={control}
                      error={errors.outOfServiceRASSPS}
                      selection
                      size="small"
                      clearable
                      options={[
                        {
                          key: 'yes',
                          value: 'Y',
                          text: 'Yes',
                        },
                        {
                          key: 'no',
                          value: 'N',
                          text: 'No',
                        },
                      ]}
                      onChange={([_, { value }]) => {
                        setValue('outOfServiceRASSPS', value === 'Y');
                        return value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_RAS_SPS_RR}
                      as={ATMDropdown}
                      name="redundancyRASSPSTemp"
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={defaultValues?.redundancyRASSPS ? 'Y' : 'N'}
                      control={control}
                      error={errors.redundancyRASSPS}
                      selection
                      size="small"
                      clearable
                      options={[
                        {
                          key: 'yes',
                          value: 'Y',
                          text: 'Yes',
                        },
                        {
                          key: 'no',
                          value: 'N',
                          text: 'No',
                        },
                      ]}
                      onChange={([_, { value }]) => {
                        setValue('redundancyRASSPS', value === 'Y');
                        return value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_PROTECTION_ZONE}
                      as={ATMDropdown}
                      name="protectionZoneTemp"
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={defaultValues?.protectionZone ? 'Y' : 'N'}
                      control={control}
                      error={errors.protectionZone}
                      selection
                      size="small"
                      clearable
                      options={[
                        {
                          key: 'yes',
                          value: 'Y',
                          text: 'Yes',
                        },
                        {
                          key: 'no',
                          value: 'N',
                          text: 'No',
                        },
                      ]}
                      onChange={([_, { value }]) => {
                        setValue('protectionZone', value === 'Y');
                        return value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_EMS_ICCP_OUTAGES}
                      as={ATMDropdown}
                      name="outageEMSICCPTemp"
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={defaultValues?.outageEMSICCP ? 'Y' : 'N'}
                      control={control}
                      error={errors.outageEMSICCP}
                      selection
                      size="small"
                      clearable
                      options={[
                        {
                          key: 'yes',
                          value: 'Y',
                          text: 'Yes',
                        },
                        {
                          key: 'no',
                          value: 'N',
                          text: 'No',
                        },
                      ]}
                      onChange={([_, { value }]) => {
                        setValue('outageEMSICCP', value === 'Y');
                        return value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_RIMS_PROJECT_ID}
                      as={ATMInput}
                      type="number"
                      name="RIMSProjectID"
                      defaultValue={defaultValues?.RIMSProjectID ?? ''}
                      control={control}
                      error={errors.RIMSProjectID}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      label={Lang.LBL_RIMS_PROJECT_PHASE}
                      as={ATMInput}
                      type="number"
                      name="RIMSProjectPhase"
                      defaultValue={defaultValues?.RIMSProjectPhase ?? ''}
                      control={control}
                      error={errors.RIMSProjectPhase}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      as={ATMDropdown}
                      label={Lang.LBL_PRIMARY_OUTAGE_CAUSE_CODE}
                      name={
                        defaultValues?.OutageCause &&
                        defaultValues?.OutageCause.length > 0
                          ? `OutageCause[${defaultValues?.OutageCause.findIndex(
                              (cause) => cause.code.endsWith('_P')
                            )}].code`
                          : 'OutageCause[0].code'
                      }
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={
                        defaultValues?.OutageCause &&
                        defaultValues?.OutageCause.length > 0
                          ? defaultValues?.OutageCause.find((cause) =>
                              cause.code.endsWith('_P')
                            )?.code
                          : undefined
                      }
                      control={control}
                      selection
                      size="small"
                      clearable
                      options={PrimaryOutageCauseCode.map((code) => ({
                        key: code,
                        value: code,
                        text: code.slice(0, -2).split('_').join(' '),
                      }))}
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5} style={{ paddingTop: '2em' }}>
                    <span>{Lang.LBL_REQUIRED_FORCED_OUTAGE}</span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={5}>
                    <ATMField
                      as={ATMDropdown}
                      label={Lang.LBL_SECONDARY_OUTAGE_CAUSE_CODE}
                      name={
                        defaultValues?.OutageCause &&
                        defaultValues?.OutageCause.length > 0
                          ? `OutageCause[${defaultValues?.OutageCause.findIndex(
                              (cause) => cause.code.endsWith('_S')
                            )}].code`
                          : 'OutageCause[1].code'
                      }
                      placeholder={Lang.LBL_SELECT}
                      defaultValue={
                        defaultValues?.OutageCause &&
                        defaultValues?.OutageCause.length > 0
                          ? defaultValues?.OutageCause.find((cause) =>
                              cause.code.endsWith('_S')
                            )?.code
                          : undefined
                      }
                      control={control}
                      selection
                      size="small"
                      clearable
                      options={SecondaryOutageCauseCode.map((code) => ({
                        key: code,
                        value: code,
                        text: code.slice(0, -2).split('_').join(' '),
                      }))}
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={5} style={{ paddingTop: '2em' }}>
                    <span>{Lang.LBL_REQUIRED_FORCED_OUTAGE}</span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={10}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_SHORT_DESCRIPTION}
                      name="description"
                      defaultValue={defaultValues?.description}
                      control={control}
                      error={errors.description}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={10}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_NOTES}
                      name="comment"
                      defaultValue={defaultValues?.comment}
                      control={control}
                      error={errors.comment}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                {/* <ATMGrid.Row>
                  <ATMGrid.Column width={15}>
                    <ATMHeader as="h3" content="OMS Switches" />
                    <ATMTable>
                      <ATMTable.Header>
                        <ATMTable.Row>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_ISO_Name}
                          </ATMTable.HeaderCell>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_TR_NAME}
                          </ATMTable.HeaderCell>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_TR_ID}
                          </ATMTable.HeaderCell>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_STATE}
                          </ATMTable.HeaderCell>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_START_DATE_TIME}
                          </ATMTable.HeaderCell>
                          <ATMTable.HeaderCell>
                            {Lang.LBL_OMS_SWITCHES_END_DATE_TIME}
                          </ATMTable.HeaderCell>
                        </ATMTable.Row>
                      </ATMTable.Header>
                      <ATMTable.Body>
                        {defaultValues?.Equipments?.length ? (
                          defaultValues?.Equipments?.map((item, _) => {
                            item?.OutagedEquipment?.Switch.map((itemVal, i) => (
                              <ATMTable.Row key={i}>
                                <ATMTable.Cell>{itemVal.name}</ATMTable.Cell>
                                <ATMTable.Cell>{itemVal.name}</ATMTable.Cell>
                                <ATMTable.Cell>{itemVal.mRID}</ATMTable.Cell>
                                <ATMTable.Cell>
                                  {itemVal.SwitchingOperations.map(
                                    (val) => val.newState
                                  )}
                                </ATMTable.Cell>
                                <ATMTable.Cell>
                                  {itemVal.SwitchingOperations.map(
                                    (val) => val.newStatePeriod.start
                                  )}
                                </ATMTable.Cell>
                                <ATMTable.Cell>
                                  {itemVal.SwitchingOperations.map(
                                    (val) => val.newStatePeriod.end
                                  )}
                                </ATMTable.Cell>
                              </ATMTable.Row>
                            ));
                          })
                        ) : (
                          <ATMTable.Row textAlign="center">
                            <ATMTable.Cell />
                            <ATMTable.Cell />
                            <ATMTable.Cell />
                            <ATMTable.Cell>
                              {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                            </ATMTable.Cell>
                            <ATMTable.Cell />
                            <ATMTable.Cell />
                          </ATMTable.Row>
                        )}
                      </ATMTable.Body>
                    </ATMTable>
                  </ATMGrid.Column>
                </ATMGrid.Row> */}
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="Equipments"
                  defaultValue={defaultValues?.Equipments}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="OperatingParticipant.Person"
                  defaultValue={defaultValues?.OperatingParticipant?.Person}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="OperatingParticipant.mRID"
                  defaultValue={defaultValues?.OperatingParticipant?.mRID}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="OperatingParticipant.mRID"
                  defaultValue={defaultValues?.OperatingParticipant?.mRID}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="emergencyReturnTimeType"
                  defaultValue={defaultValues?.emergencyReturnTimeType}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="mRID"
                  defaultValue={defaultValues?.mRID}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="operationalFlag"
                  defaultValue={defaultValues?.operationalFlag}
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="outageCoordinationLongTermPlanning"
                  defaultValue={
                    defaultValues?.outageCoordinationLongTermPlanning
                  }
                  control={control}
                />
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="OutageChangeRequest.action"
                  defaultValue="UPDATE"
                  control={control}
                />
              </ATMGrid>
            )}
          </>
        );
      }}
    </ATMForm>
  );
};

export default OmsRequestForm;
