import React, { useCallback, useRef, useState } from 'react';
import { useATMFormContext } from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import {
  IFacilityForm,
  IFacilityFormValidation,
  IUserGrp,
} from 'src/models/facility.model';
import { IUserGroup } from 'src/models/user-group.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import FacilityNotifApprovalsItemEditView from './facility-notif-approvals-item-edit.view';

type IProps = {
  userGroup: IUserGroup[];
  defaultValues: Partial<IUserGrp>;
  setUserGroups: React.Dispatch<React.SetStateAction<IUserGrp[]>>;
};

const FacilityNotifApprovalsItemEdit: React.FC<IProps> = ({
  userGroup,
  defaultValues,
  setUserGroups,
}) => {
  const { control, getValues } = useATMFormContext<IFacilityFormValidation>();
  const { state, actions } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const addStatus = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_UPDATE
  ).fetching;

  const handleSave = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IUserGrp) => {
      const values = getValues();
      const newFormData = {
        ...values,
        userGrps: [
          ...(values.userGrps?.filter(
            ({ userGrpId }) => userGrpId !== formData.userGrpId
          ) ?? []),
          formData,
        ],
      };
      setUserGroups(newFormData.userGrps as IUserGrp[]);
      const result = await actions.updatePUT(
        newFormData.outgFacId ?? 0,
        newFormData as IFacilityForm
      );
      if (result.payload) {
        ToastSuccess(
          Lang.formatString(Lang.MSG_USER_GROUP_EDIT, formData.userGrpNm)
        );
      } else {
        ToastError(result.error.message);
      }
      setIsOpen(!isOpen);
    },
    [actions, isOpen, control, setIsOpen, setUserGroups, getValues]
  );

  return (
    <FacilityNotifApprovalsItemEditView
      isOpen={isOpen}
      formRef={formRef}
      isDirty={isDirty}
      loading={addStatus}
      userGroup={userGroup}
      defaultValues={defaultValues}
      handleOpen={setIsOpen}
      handleSave={handleSave}
      handleEnable={setIsDirty}
      handleSubmit={handleSubmit}
    />
  );
};

export default FacilityNotifApprovalsItemEdit;
