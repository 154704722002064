import React, { useCallback, useEffect, useState } from 'react';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { useCancelledReportContext } from 'src/contexts/cancelled-report.context';
import { cancelledReportActionTypes } from 'src/ducks/cancelled-report.duck';
import { getCancelledReportStatus } from 'src/selectors/cancelled-report.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { checkValue } from 'src/libraries/common.library';
import {
  ToastError,
  ToastWarning,
} from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import CancelledReportListView from './cancelled-report-list.view';

const CancelledReportList: React.FC = () => {
  const { state, actions } = useCancelledReportContext();
  const {
    state: { employeeList },
    actions: lerRequestActions,
  } = useLerRequestContext();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<Record<any, any>>({});
  const [showNoRecord, setShowNoRecord] = useState<boolean>(false);
  const loading = getCancelledReportStatus(
    state,
    cancelledReportActionTypes.CANCELLED_REPORT_LIST_READ
  ).fetching;

  useEffect(() => {
    if (!employeeList.length) {
      lerRequestActions.employeeListGET();
    }
  }, [employeeList, lerRequestActions]);

  const handleFetch = useCallback(async () => {
    if (queryParams.cnclBy === '') {
      delete queryParams.cnclBy;
    }
    if (queryParams.supvId === '') {
      delete queryParams.supvId;
    }
    if (queryParams.requestorId === '') {
      delete queryParams.requestorId;
    }
    if (queryParams.dateRange == null) {
      delete queryParams.dateRange;
    }
    const filters: any[] = Object.entries(queryParams).map(([key, value]) => {
      return { name: key, value };
    });
    if (Object.keys(queryParams).length === 0) {
      ToastWarning('Please, select some search criteria');
    } else {
      const params = { page: 1, limit: 0, filters };
      const response = await actions.listGET(params);
      if (!response.error && response.payload?.rows?.length === 0) {
        setShowNoRecord(true);
      } else if (response.error) {
        ToastError('Error retrieving Cancelled Report records.');
      }
    }
  }, [actions, queryParams]);

  const handleDownload = useCallback(() => {
    setDownloading(true);
    if (state.list) {
      const { list } = state;

      const items = list.map((value) => [
        checkValue(value.requestId ?? '--'),
        checkValue(value.requestor?.fullName ?? '--'),
        checkValue(value.supervisor?.fullName ?? '--'),
        checkValue(value?.cancelNote?.noteTxt),
        checkValue(value.cancelType?.cancelTypeDesc ?? '--'),
        checkValue(value?.chngRsn ?? '--'),
        checkValue(value?.cancelor?.fullName ?? '--'),
        checkValue(Moment(value?.cnclAt).format('YYYY-MM-DD HH:mm') ?? '--'),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_LER,
          Lang.LBL_REQUESTOR,
          Lang.LBL_REQUESTOR_SUPERVISOR,
          Lang.LBL_CANCELLATION_NOTE,
          Lang.LBL_CANCEL_TYPE,
          Lang.LBL_REASON,
          Lang.LBL_CANCEL_BY,
          Lang.LBL_CANCEL_DATE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Cancelled_Report_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  }, [actions, state, setDownloading]);

  return (
    <CancelledReportListView
      data={state.list}
      loading={loading}
      queryParams={queryParams}
      handleFetch={handleFetch}
      handleQueryParams={setQueryParams}
      handleDownload={handleDownload}
      downloading={downloading}
      showNoRecord={showNoRecord}
      setShowNoRecord={setShowNoRecord}
    />
  );
};

export default CancelledReportList;
