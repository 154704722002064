import yup from 'src/libraries/validator.library';
import { EmployeeSimpleShape } from './employee.model';

export const TsoOssEmployeeShape = {
  empId: yup.string().required(),
  userType: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  email: yup.string().nullable(),
};

export const TsoOssEmployeeSchema = yup.object(TsoOssEmployeeShape);

export const TsoOssEmployeeFormSchema = yup.object({
  empId: yup.string().required(),
  userType: yup.string().required(),
});

export const TsoOssEmployeeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(TsoOssEmployeeSchema.clone()),
});

export const TsoOssEmployeePayloadSchema = yup.object({
  ...TsoOssEmployeeShape,
  emp: yup.object(EmployeeSimpleShape).nullable(),
});

export const TsoOssEmployeeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ITsoOssEmployee = yup.Asserts<typeof TsoOssEmployeeSchema>;
export type ITsoOssEmployeeForm = yup.Asserts<typeof TsoOssEmployeeFormSchema>;
