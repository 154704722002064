import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import AuthorizedListReducer, {
  IAuthorizedListState,
  defaultState,
  duckActions,
} from 'src/ducks/authorized-list.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    AuthorizedListReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IAuthorizedListContext = ReturnType<typeof useReducer>;

const AuthorizedListContext = createContext<Partial<IAuthorizedListContext>>({
  state: defaultState,
}) as React.Context<IAuthorizedListContext>;

type IProps = {
  state?: Partial<IAuthorizedListState>;
};

const AuthorizedListProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <AuthorizedListContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </AuthorizedListContext.Provider>
  );
};

const useAuthorizedListContext = () => useContext(AuthorizedListContext);

export type IUseAuthorizedListContext = ReturnType<typeof useAuthorizedListContext>;

export { AuthorizedListContext, AuthorizedListProvider, useAuthorizedListContext };
