import { unitOfTime } from 'moment-timezone';
import { findIndex } from 'lodash';
import {
  dateAdd,
  dateCeil,
  dateDiff,
  dateMax,
  dateMin,
  dateStartOf,
  IMoment,
} from './moment.library';

export type ICalendarEvent<T = any> = {
  data?: T;
  title:
    | ((event: ICalendarEvent, week: IMoment[]) => React.ReactNode)
    | React.ReactNode;
  resourceId?: string | number;
  start: IMoment;
  end: IMoment;
  desc?: string;
  allDay?: boolean;
};

export type IEventSegment<T = ICalendarEvent> = {
  event: T;
  span: number;
  left: number;
  right: number;
};

export const endOfRange = ({
  dateRange,
  unit = 'day',
}: {
  dateRange: IMoment[];
  unit?: unitOfTime.Base;
}) => {
  return {
    first: dateRange[0],
    last: dateAdd(dateRange[dateRange.length - 1], 1, unit),
  };
};

export const eventSegments = <
  T extends {
    start: IMoment;
    end: IMoment;
  }
>(
  event: T,
  range: IMoment[]
): IEventSegment<T> => {
  const { first, last } = endOfRange({ dateRange: range });

  const slots = dateDiff(first, last, 'day');
  const start = dateMax(dateStartOf(event.start, 'day'), first);
  const end = dateMin(dateCeil(event.end, 'day'), last);

  const padding = findIndex(range, (x) => x.isSame(start, 'date'));
  let span = dateDiff(start, end, 'day');

  span = Math.min(span, slots);
  span = Math.max(span, 1);

  return {
    event,
    span,
    left: padding + 1,
    right: Math.max(padding + span, 1),
  };
};

export const segmentOverlap = (seg, otherSegs) => {
  return otherSegs.some(
    (otherSeg) => otherSeg.left <= seg.right && otherSeg.right >= seg.left
  );
};

export const eventLevels = (rowSegments: IEventSegment[], limit = Infinity) => {
  let i;
  let j;
  let segment;
  const levels: IEventSegment[][] = [];
  const extra: IEventSegment[] = [];

  for (i = 0; i < rowSegments.length; i++) {
    segment = rowSegments[i];

    for (j = 0; j < levels.length; j++) {
      if (!segmentOverlap(segment, levels[j])) break;
    }

    if (j >= limit) {
      extra.push(segment);
    } else {
      (levels[j] || (levels[j] = [])).push(segment);
    }
  }

  for (i = 0; i < levels.length; i++) {
    levels[i].sort((a, b) => a.left - b.left);
  }

  return { levels, extra };
};

export const sortEvents = (
  { start: aStart, end: aEnd, allDay: aAllDay = false }: ICalendarEvent,
  { start: bStart, end: bEnd, allDay: bAllDay = false }: ICalendarEvent
) => {
  const startSort = +dateStartOf(aStart, 'day') - +dateStartOf(bStart, 'day');

  const durA = dateDiff(aStart, dateCeil(aEnd, 'day'), 'day');
  const durB = dateDiff(bStart, dateCeil(bEnd, 'day'), 'day');

  return (
    startSort ||
    Math.max(durB, 1) - Math.max(durA, 1) ||
    +bAllDay - +aAllDay ||
    +aStart - +bStart ||
    +aEnd - +bEnd
  );
};
