import React, { useCallback, useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { useOcPendingActionReportContext } from 'src/contexts/oc-pending-action-report.context';
import { ocPendingActionReportActionTypes } from 'src/ducks/oc-pending-action-report.duck';
import Lang from 'src/libraries/language';
import { IOcPendingActionReport } from 'src/models/oc-pending-action-report.model';
import { getOcPendingActionReportStatus } from 'src/selectors/oc-pending-action-report.selector';
import style from '../oc-pending-action-report.module.scss';

type IProps = {
  data: IOcPendingActionReport;
  queryParams: Record<any, any>;
  pagination: Record<any, any>;
};

const OcPendingActionReportDelete: React.FC<IProps> = ({
  data,
  queryParams,
  pagination,
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { state, actions } = useOcPendingActionReportContext();
  const status = getOcPendingActionReportStatus(
    state,
    ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    const response = await actions.dataDELETE(data.notesId);

    if (!response.error && response.payload?.success === 1) {
      setIsDeleteOpen(false);
      ToastSuccess(
        `Notes Record successfully deleted for LER ${data?.requestId}`
      );
      const getResponse = await actions.listGET({
        ...pagination,
        ...queryParams,
      });
      if (
        getResponse.payload &&
        getResponse.payload.count > 0 &&
        getResponse.payload.rows?.length === 0
      ) {
        actions.listGET({
          ...pagination,
          ...queryParams,
          page: pagination.page - 1,
        });
      }
    } else {
      ToastError(`Error in deleting Notes Record for LER ${data?.requestId}`);
    }
  }, [data, actions, setIsDeleteOpen]);

  return (
    <ATMModal
      open={isDeleteOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          content={Lang.LBL_DELETE_NOTE}
          icon="trash"
          onClick={() => setIsDeleteOpen(!isDeleteOpen)}
        />
      }
      header={Lang.formatString(
        Lang.TTL_DELETE_OUTAGE_REPORT_NOTE,
        String(data.requestId)
      )}
      content={
        <div className={style.editContent}>
          <span>{Lang.MSG_CONFIRM_DELETE_NOTE}</span>
          <br />
          <br />
          <i>{`"${data.dailyOutageReportNotes}"`}</i>
        </div>
      }
      actions={
        <div className={style.actionBtns}>
          <ATMButton
            basic
            color="blue"
            content={Lang.LBL_CANCEL}
            onClick={() => setIsDeleteOpen(false)}
          />
          <ATMButton
            negative
            content={Lang.LBL_DELETE}
            onClick={() => {
              if (!status.fetching) {
                handleSubmit();
              }
            }}
            loading={status.fetching}
          />
        </div>
      }
    />
  );
};

export default OcPendingActionReportDelete;
