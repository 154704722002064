/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
} from 'shared-it-appmod-ui';
import { ToastWarning } from 'src/components/atoms/toaster/toaster.component';
import { troubleTicketStatus } from 'src/constants';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { getTroubleTicketParams } from 'src/selectors/trouble-ticket.selector';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import TroubleTicketListAllView from './trouble-ticket-all-list.view';

type IProps = {
  loading: boolean;
  setDataEdit: React.Dispatch<ITroubleTicketList>;
  setView: React.Dispatch<any>;
  handleFetch: (
    params: IORGDataTableQueryState,
    options: IORGDataTableUpdateOptions,
    searchTicketId?: boolean
  ) => void;
  setIsFirstLoad: (param: boolean) => void;
  total: number;
  troubleTotal: number;
  scheduledTotal: number;
  currentTab: number;
  completeWorkList: number[];
  isFirstLoad: boolean;
};

const TroubleTicketListAll: React.FC<IProps> = ({
  loading,
  setDataEdit,
  setView,
  handleFetch,
  setIsFirstLoad,
  troubleTotal,
  scheduledTotal,
  currentTab,
  isFirstLoad,
}) => {
  const { state: troubleTicketState, actions } = useTroubleTicketContext();
  const { state: workGroupState, actions: workGroupActions } =
    useWorkGroupContext();
  const [dateState, setDateState] = useState<any>();
  const [workGroups, setWorkGroups] = useState<number[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showNotRecord, setShowNotRecord] = useState<boolean>(false);
  const [state, setState] = useState<any>();
  const [counter, setCounter] = useState<number>(0);
  const [hasDateRange, setHasDateRange] = useState<boolean | undefined>(
    undefined
  );

  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    workGroupActions.listGET({ limit: 0, page: 1 });
    setWorkGroups([...workGroups, 252, 254, 253, 255]);
  }, [workGroupActions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    actions.listCLEAR();
  }, [actions]);

  const handleCleanData = useCallback(() => {
    actions.listCLEAR();
  }, []);
  const handleSubmit = useCallback(
    async (data, options?: IORGDataTableUpdateOptions) => {
      if (options && options.action === 'clear') {
        setCounter((count) => count + 1);
      }
      if (data) {
        const filterData = data;
        // data.limit === 0
        //   ? ({} as any)
        //   : { page: 1, limit: 50, filters: data.filters };

        const params = data.filters
          ? await getTroubleTicketParams(filterData, currentTab)
          : undefined;
        if (!data.filters) {
          actions?.listCLEAR();
        }
        const isHasDateRange = data.filters?.find((v) => v.name === 'beginDt');
        if (isHasDateRange) {
          setHasDateRange(true);
        } else if (data.filters && !isFirstLoad) {
          setHasDateRange(false);
        } else if (!data.filters) {
          setHasDateRange(undefined);
        }
        const response =
          isFirstLoad || !data.filters
            ? undefined
            : await actions?.listGET(params);
        if (response?.error || response?.payload?.rows?.length === 0) {
          ToastWarning('No Results found for the search criteria');
          setShowNotRecord(true);
        }
      }
    },
    [actions, setCounter, isFirstLoad]
  );

  const handleEmployeeName = (fullname, firstname, lastname) => {
    let employeeName;
    const fullName = checkValue(fullname);
    const firstName = checkValue(firstname);
    const lastName = checkValue(lastname);

    const firstAndLastname = `${lastName}${firstName}`;
    if (firstAndLastname.length) {
      if (
        firstAndLastname.search(/undefined/gi) === -1 &&
        firstAndLastname.search(/null/gi) === -1
      ) {
        employeeName = `${lastName}, ${firstName}`;
      } else {
        employeeName = Lang.LBL_UNAVAILABLE;
      }
    } else if (
      fullName.search(/undefined/gi) === -1 &&
      fullName.search(/null/gi) === -1
    ) {
      employeeName = fullName;
    } else {
      employeeName = Lang.LBL_UNAVAILABLE;
    }

    return employeeName.length ? employeeName : Lang.LBL_UNAVAILABLE;
  };

  const handleDownload = useCallback(
    async (data) => {
      const params = await getTroubleTicketParams(data, currentTab);
      const result = await actions.listAllGET({ ...params, page: 1, limit: 0 });
      if (result.payload?.rows) {
        const list = result.payload.rows;

        const items = list.map((value) => [
          checkValue(value.troubleTicketId),
          checkValue(troubleTicketStatus[value.ticketStatus.toUpperCase()]),
          checkValue(value.requestTyp),
          checkValue(value.beginDt ? formatDate(value.beginDt) : '--'),
          checkValue(value.dueDt ? formatDate(value.dueDt) : '--'),
          checkValue(value.workGroup?.trbGrpNm),
          checkValue(
            value.substationId && value.substation
              ? value.substation.name
              : value.outgFacId && value.line
              ? value.line.outgFacNm
              : '--'
          ),
          checkValue(
            value.troubleEquipmentTypeId
              ? value.troubleEquipmentType?.name
              : '--'
          ),
          checkValue(value.ticketDesc),
          checkValue(value.ticketPrio),
          checkValue(
            handleEmployeeName(
              value.submittedByEmployee.fullName,
              value.submittedByEmployee.firstName,
              value.submittedByEmployee.lastName
            ) === Lang.LBL_UNAVAILABLE
              ? '--'
              : handleEmployeeName(
                  value.submittedByEmployee.fullName,
                  value.submittedByEmployee.firstName,
                  value.submittedByEmployee.lastName
                )
          ),
        ]);

        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_TROUBLE_TICKET_ID,
            Lang.LBL_TROUBLE_TICKET_STATUS,
            Lang.LBL_TROUBLE_TICKET_TYPE,
            Lang.LBL_BEGIN_DATE,
            Lang.LBL_DUE_DATE,
            Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
            Lang.LBL_TROUBLE_TICKET_FAC_SUBS,
            Lang.LBL_EQUIPMENT_TYPE,
            Lang.LBL_TROUBLE_TICKET_DESCRIPTION,
            Lang.LBL_PRIORITY,
            Lang.LBL_CREATED_BY,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', cleanSpecialCharacterData(exportData));
        link.setAttribute(
          'download',
          `MCC_Trouble_Ticket_Search_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, troubleTicketState]
  );

  return (
    <TroubleTicketListAllView
      data={troubleTicketState.list}
      loading={loading}
      handleFetch={handleSubmit}
      handleSearch={handleFetch}
      total={troubleTicketState.total}
      troubleTotal={troubleTotal}
      scheduledTotal={scheduledTotal}
      currentTab={currentTab}
      setDataEdit={setDataEdit}
      setView={setView}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      formRef={formRef}
      handleDownload={handleDownload}
      completeWorkList={workGroupState.list.map((item) => item.trbGrpId)}
      workGroups={workGroups}
      setWorkGroups={setWorkGroups}
      handleCleanData={handleCleanData}
      setDateState={setDateState}
      dateState={dateState}
      showNotRecord={showNotRecord}
      setShowNotRecord={setShowNotRecord}
      showAll={showAll}
      setShowAll={setShowAll}
      setState={setState}
      setIsFirstLoad={setIsFirstLoad}
      state={state}
      counter={counter}
      hasDateRange={hasDateRange}
    />
  );
};

export default TroubleTicketListAll;
