import React, { useCallback, useEffect, useRef, useState } from 'react';
import Moment from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import { useOmsRequestContext } from 'src/contexts/oms-request.context';
import { omsRequestActionTypes } from 'src/ducks/oms-request.duck';
import {
  IOmsRequestForm,
  IOmsRequestUpdatePayload,
} from 'src/models/oms-request.model';
import { getOmsRequestStatus } from 'src/selectors/oms-request.selector';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { LERNotificationStatus } from 'src/constants';
import OmsRequestEditView from './oms-request-edit.view';

const OmsRequestEdit: React.FC = () => {
  const { state, actions } = useOmsRequestContext();
  const { params: currentTab, handleChange: handleTabChange } =
    useLocationParams(['action', 'omsId']);
  const omsId = Number(currentTab.omsId);
  const { action } = currentTab;
  const formRef = useRef<HTMLFormElement>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  const isEditOpen =
    (action === 'edit' || action === 'popOut') && (omsId !== 0 || omsId !== 0);

  const status = getOmsRequestStatus(
    state,
    omsRequestActionTypes.OMS_REQUEST_DATA_UPDATE
  );

  const dataLoading = getOmsRequestStatus(
    state,
    omsRequestActionTypes.OMS_REQUEST_DATA_READ
  ).fetching;

  const handleOpen = useCallback(
    (value?: string, id?: number) => {
      handleTabChange(value && id ? { action: value, omsId: id } : undefined);
    },
    [handleTabChange]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IOmsRequestForm) => {
      const newData: IOmsRequestUpdatePayload = {
        transmissionOutageChangeNew: {
          ...formData,
          estimatedPeriod: {
            end: Moment(
              `${Moment(formData.estimatedPeriod.end).format(
                'MM/DD/YYYY'
              )} ${endTime}`,
              'MM/DD/YYYY HH:mm'
            ).toDate(),
            start: Moment(
              `${Moment(formData.estimatedPeriod.start).format(
                'MM/DD/YYYY'
              )} ${startTime}`,
              'MM/DD/YYYY HH:mm'
            ).toDate(),
          },
        },
        transmissionOutageChangePrevious: {
          description: state.data?.description as string,
          comment: state.data?.comment as string,
          outageEMSICCP: state.data?.outageEMSICCP,
          outOfServiceRASSPS: state.data?.outOfServiceRASSPS,
          protectionZone: state.data?.protectionZone,
          redundancyRASSPS: state.data?.redundancyRASSPS,
          emergencyReturnTime: state.data?.emergencyReturnTime,
          emergencyReturnTimeType: state.data?.emergencyReturnTimeType,
          Work: {
            workKind: state.data?.Work.workKind as string,
          },
          mktOrgOutageID: state.data?.mktOrgOutageID as string,
          OutageCause: state.data?.OutageCause,
          RIMSProjectID: state.data?.RIMSProjectID,
          RIMSProjectPhase: state.data?.RIMSProjectPhase,
          estimatedPeriod: {
            end: state.data?.estimatedPeriod.end as Date,
            start: state.data?.estimatedPeriod.start as Date,
          },
        },
      };

      if (
        formData.OutageCause &&
        newData.transmissionOutageChangeNew.OutageCause
      ) {
        if (formData.OutageCause[0].code && formData.OutageCause[1].code) {
          newData.transmissionOutageChangeNew.OutageCause[0].code =
            formData.OutageCause[0].code;
          newData.transmissionOutageChangeNew.OutageCause[1].code =
            formData.OutageCause[1].code;
        } else {
          newData.transmissionOutageChangeNew.OutageCause = undefined;
        }
      }

      const response = await actions.updatePUT(Number(omsId ?? 0), newData);

      if (response.payload) {
        handleOpen();

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_OMS_EDIT_SUCCESSFUL,
            currentTab.omsId ?? ''
          ).toString()
        );
        actions.setCaisioError(undefined);
        actions.listGET({
          page: 1,
          limit: 0,
          action: 'getOmsRequests',
          filters: [
            {
              name: 'date',
              value: Moment(
                sessionStorage.getItem(`${Lang.TTL_OMS_REQUEST}_Date`)
              ).toDate(),
            },
          ],
        });
      } else {
        actions.setCaisioError({
          type: LERNotificationStatus.REJECTED,
          message: response.error.map((v, i) => <p key={i}>{v.description}</p>),
        });
      }
    },
    [actions, handleOpen, startTime, endTime]
  );

  const handleReset = useCallback(() => {
    handleOpen();
  }, [handleOpen]);

  useEffect(() => {
    const fetchData = async () => {
      actions.setCaisioError(undefined);
      const result = await actions.dataGET(Number(currentTab.omsId ?? '0'));
      if (result.error) {
        handleOpen();
      }
    };
    if (isEditOpen) {
      fetchData();
    }
  }, [omsId]);

  return (
    <OmsRequestEditView
      isDirty={isDirty}
      formRef={formRef}
      defaultValues={state.data}
      // data={data}
      loading={status.fetching}
      dataLoading={dataLoading}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={handleOpen}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      isEditable={currentTab.action !== 'popOut'}
      handleReset={handleReset}
      setIsDirty={setIsDirty}
      omsId={omsId}
    />
  );
};

export default OmsRequestEdit;
