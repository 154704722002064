import React, { useCallback } from 'react';
import { ATMField, ATMInput } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { createDateTime } from 'src/libraries/moment.library';
import styles from './input-format.module.scss';
import LabelRequired from '../label-required/label-required.component';

type IProp = {
  label?: any;
  name?: any;
  control: any;
  error: any;
  icon?: any;
  pattern?: any;
  style?: any;
  maxLength?: any;
  inputValue: any;
  defaultValue?: any;
  errorMessage?: any;
  noMargin?: boolean;
  dateSelected?: any;
  resetValue?: any;
  contextType?: string;
  disabled?: boolean;
  isStartTime?: boolean;
  enableRequired?: boolean;
  setInputValue: (param: any) => void;
  setDateSelected?: (param: any) => void;
  callback?: (param: any) => void;
  reset?: () => void | undefined;
  setValue: (name: any, value: any) => void;
  setError: (name: any, value: any) => void;
  handleEnable?: (param: any) => void;
  register: React.Dispatch<React.SetStateAction<any>>;
  clearErrors: React.Dispatch<React.SetStateAction<any>>;
};

export enum Pattern {
  HOUR_TIME_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ as any,
}

export const format = (mask, number) => {
  const s = `${number}`;
  let r = '';
  for (let im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r +=
      mask.charAt(im) === 'X'.toLowerCase() ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
};
export const TimeInput: React.FC<IProp> = ({
  name,
  disabled,
  noMargin,
  maxLength,
  inputValue,
  dateSelected,
  errorMessage,
  enableRequired,
  callback,
  setInputValue,
  setDateSelected,
  setValue,
  setError,
  register,
  clearErrors,
}) => {
  const handleInput = useCallback(
    (param: any) => {
      const value = param ? param.target.value : undefined;
      if (!value && enableRequired) {
        setImmediate(() => {
          register(name);
          setError(name, {
            message: errorMessage || 'This field is required',
          });
          setValue(name, undefined);
        });
      }
      if (value && value.length) {
        if (value.match(/^[A-Za-z]*$/g)) {
          setImmediate(() => {
            register(name);
            setError(name, {
              message: errorMessage || 'Invalid Time',
            });
          });
        }
      } else {
        clearErrors(name);
      }
      if (value && value.length >= 4) {
        if (value && value.length) {
          let time = value;
          if (value.match(/\d{4}/g)) {
            time = value.match(/\d{2}/g).join(':');
          }
          setInputValue(time);
          if (time.match(Pattern.HOUR_TIME_PATTERN)) {
            let date;
            if (dateSelected && setDateSelected) {
              date = createDateTime(dateSelected, time).toDate();
              setDateSelected(date);
            }
            setInputValue(time);
            setValue(name, time);
            clearErrors(name);
          } else {
            setValue(name, '');
            setImmediate(() => {
              register(name);
              setError(name, {
                message: errorMessage || 'Invalid Time',
              });
            });
          }
        }
        return false;
      }
      return true;
    },
    [inputValue, dateSelected, setInputValue, callback, setDateSelected]
  );
  return (
    <>
      <span className={noMargin ? styles.noMargin : styles.defaultStyle}>
        <ATMInput
          key={name}
          value={inputValue}
          onKeyUp={(e) => handleInput(e)}
          onChange={(e) => {
            const { value } = e.target;
            if (!value) {
              setValue(name, undefined);
            }
            if (value.length && callback) {
              callback(true);
            }
            if (!value && enableRequired) {
              setImmediate(() => {
                register(name);
                setError(name, {
                  message: errorMessage || 'This field is required',
                });
              });
              setImmediate(() => {
                register(name);
                setError(name, {
                  message: errorMessage || 'This field is required',
                });
              });
            }
            if (!value && !enableRequired) {
              clearErrors(name);
            }
            if (!value) {
              handleInput(undefined);
            }
          }}
          placeholder="hh:mm"
          maxLength={maxLength}
          clearable
          name={name}
          disabled={disabled}
        />
      </span>
    </>
  );
};

export const TimeHourMinuteInput: React.FC<IProp> = ({
  label,
  error,
  name,
  control,
  contextType,
  dateSelected,
  inputValue,
  noMargin = false,
  isStartTime = true,
  disabled = false,
  enableRequired = true,
  reset,
  setInputValue,
  setDateSelected,
  callback,
  setValue,
  setError,
  register,
  clearErrors,
}) => {
  return (
    <ATMField
      as={TimeInput}
      name={name}
      className={noMargin ? styles.noMargin : styles.defaultStyle}
      label={
        enableRequired ? (
          <span className={noMargin ? styles.noMargin : styles.defaultStyle}>
            <LabelRequired>{label || Lang.LBL_TIME}</LabelRequired>
          </span>
        ) : (
          label || Lang.LBL_TIME
        )
      }
      clearable
      noMargin={noMargin}
      control={control}
      maxLength={5}
      enableRequired={enableRequired}
      error={error}
      defaultValue={inputValue}
      value={inputValue}
      dateSelected={dateSelected}
      inputValue={inputValue}
      isStartTime={isStartTime}
      contextType={contextType}
      disabled={disabled}
      reset={reset}
      clearErrors={clearErrors}
      setValue={setValue}
      setError={setError}
      register={register}
      callback={callback}
      setDateSelected={setDateSelected}
      setInputValue={setInputValue}
    />
  );
};
