import { httpClient } from 'src/libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  IEquipmentTypeForm,
  EquipmentTypeListPayloadSchema,
  EquipmentTypePayloadSchema,
  EquipmentTypeDeletePayloadSchema,
} from 'src/models/equipment-type.model';

const client = httpClient();
const endpoint = 'facility-type';

const services = {
  dataGET: async (id?: number) => {
    return client.get(`/${endpoint}/${id}`, {}, EquipmentTypePayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    // Whenever the API has the LER's integration ready, change this to read it.

    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, EquipmentTypeListPayloadSchema);
  },

  createPOST: async (data: IEquipmentTypeForm) => {
    return client.post(`/${endpoint}`, data, EquipmentTypePayloadSchema);
  },

  updatePUT: async (id?: number, data?: IEquipmentTypeForm) => {
    return client.put(`/${endpoint}/${id}`, data, EquipmentTypePayloadSchema);
  },

  dataDELETE: async (id?: number) => {
    // Whenever the API has the LER's integration ready, change this to read it.

    return client.delete(
      `/${endpoint}/${id}`,
      EquipmentTypeDeletePayloadSchema
    );
  },
};

export default services;
