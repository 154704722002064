import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMCheckbox,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMTable,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import Lang from 'src/libraries/language';
import { IUserGrpForm, UserGrpFormSchema } from 'src/models/facility.model';
import { IUserGroup } from 'src/models/user-group.model';

type IProps = {
  edit?: boolean;
  userGroup: IUserGroup[];
  formRef: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IUserGrpForm>;
  handleEnable: (data) => void;
  handleSubmit: (data) => void;
};

const transform = (string: string): string => string.replace(' ', '_');

type IContentProps = {
  defaultValues?: Partial<IUserGrpForm>;
  userGroup: IUserGroup[];
  edit: boolean;
};

const Content: React.FC<IContentProps> = ({
  defaultValues,
  userGroup,
  edit,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useATMFormContext<IUserGrpForm>();

  const { state: outageTypeState } = useOutageTypeContext();
  const [notificationEmail, setNotificationEmail] = useState(
    defaultValues?.notificationEmail ?? []
  );
  const [approvalEmail, setApprovalEmail] = useState(
    defaultValues?.approvalEmail ?? []
  );
  const [flags, setFlags] = useState([
    ...(defaultValues?.notificationEmail?.map(
      (item) => `${transform(item ?? '')}_notif`
    ) ?? []),
    ...(defaultValues?.approvalEmail?.map(
      (item) => `${transform(item ?? '')}_app`
    ) ?? []),
  ]);

  const pushToArray = (item, state, setState) => {
    const array: string[] = state.filter(
      (data) => data !== item && data !== ''
    );
    array.push(item);
    setState(array);
    return array;
  };

  const removeToArray = (item, state, setState) => {
    const array: string[] = state.filter(
      (data) => data !== item && data !== ''
    );
    setState(array);
    return array;
  };

  const onChange = useCallback(
    (item: string, value: string, checked?: boolean, approval?: boolean) => {
      if (!flags.includes(value)) {
        const flagsArr = flags.filter(
          (data) => data !== `${transform(item)}${approval ? '_notif' : '_app'}`
        );
        flagsArr.push(String(value));
        setFlags(flagsArr);
      } else {
        const flagsArr = flags.filter((data) => data !== value);
        setFlags(flagsArr);
      }
      if (approval) {
        setValue(
          'approvalEmail',
          checked
            ? pushToArray(item, approvalEmail, setApprovalEmail)
            : removeToArray(item, approvalEmail, setApprovalEmail)
        );
        setValue(
          'notificationEmail',
          removeToArray(item, notificationEmail, setNotificationEmail)
        );
      } else {
        setValue(
          'notificationEmail',
          checked
            ? pushToArray(item, notificationEmail, setNotificationEmail)
            : removeToArray(item, notificationEmail, setNotificationEmail)
        );
        setValue(
          'approvalEmail',
          removeToArray(item, approvalEmail, setApprovalEmail)
        );
      }
    },
    [
      setValue,
      flags,
      notificationEmail,
      approvalEmail,
      setNotificationEmail,
      setApprovalEmail,
    ]
  );

  return (
    <ATMGrid>
      {!edit && (
        <ATMGrid.Row columns={3}>
          <ATMGrid.Column>
            <ATMField
              required
              selection
              as={ATMDropdown}
              name="userGrpNm"
              control={control}
              errors={errors.userGrpNm}
              label={Lang.LBL_GROUP_NAME}
              placeholder={Lang.LBL_SELECT}
              options={userGroup.map((item) => ({
                key: item.userGrpId,
                value: item.userGrpNm,
                text: item.userGrpNm,
              }))}
              onChange={([_, { value }]) => {
                const group = userGroup.find((v) => v.userGrpNm === value);

                if (group) {
                  setValue('userGrpId', group.userGrpId);
                }

                return value;
              }}
              search
              selectOnBlur={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column />
          <ATMGrid.Column />
        </ATMGrid.Row>
      )}
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMTable definition>
            <ATMTable.Header>
              <ATMTable.HeaderCell />
              <ATMTable.HeaderCell>
                {Lang.LBL_NOTIFICATION_EMAIL}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>
                {Lang.LBL_APPROVAL_EMAIL}
              </ATMTable.HeaderCell>
            </ATMTable.Header>
            <ATMTable.Body>
              {outageTypeState.list.map((item, i) => {
                return (
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell>{item.outageTypeDesc}</ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMCheckbox
                        value={`${transform(item.outageTypeDesc)}_notif`}
                        checked={flags.includes(
                          `${transform(item.outageTypeDesc)}_notif`
                        )}
                        onChange={(_, { value, checked }) =>
                          onChange(item.outageTypeDesc, String(value), checked)
                        }
                      />
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMCheckbox
                        value={`${transform(item.outageTypeDesc)}_app`}
                        checked={flags.includes(
                          `${transform(item.outageTypeDesc)}_app`
                        )}
                        onChange={(_, { value, checked }) =>
                          onChange(
                            item.outageTypeDesc,
                            String(value),
                            checked,
                            true
                          )
                        }
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                );
              })}
              <ATMTable.Row>
                <ATMTable.Cell />
                <ATMTable.Cell />
                <ATMTable.Cell />
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const FacilityNotifApprovalsItemForm: React.FC<IProps> = ({
  edit = false,
  formRef,
  userGroup,
  defaultValues,
  handleEnable,
  handleSubmit,
}) => {
  const { actions } = useOutageTypeContext();

  useEffect(() => {
    actions.activeListGET();
  }, [actions]);

  return (
    <div className="form-container">
      <ATMForm
        ref={formRef}
        onSubmit={handleSubmit}
        mode="onChange"
        defaultValues={
          {
            ...defaultValues,
            userGroup: defaultValues?.userGrpNm
              ? userGroup
                  .filter(
                    ({ userGrpNm }) => userGrpNm === defaultValues?.userGrpNm
                  )
                  .map(({ userGrpEmail }) => userGrpEmail)[0]
              : undefined,

            userGrpId: defaultValues?.userGrpNm
              ? userGroup
                  .filter(
                    ({ userGrpNm }) => userGrpNm === defaultValues?.userGrpNm
                  )
                  .map(({ userGrpId }) => userGrpId)[0]
              : undefined,
          } as any
        }
        validationSchema={UserGrpFormSchema}
      >
        {(props) => {
          handleEnable(edit || props.formState.isDirty);

          return (
            <ATMFormProvider {...props}>
              <Content
                defaultValues={defaultValues}
                userGroup={userGroup}
                edit={edit}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default FacilityNotifApprovalsItemForm;
