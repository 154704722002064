export enum KeyRequestStatus {
  APPROVED = 'Active',
  PENDINGAPPROVAL = 'Pending',
  REJECTED = 'Rejected',
}

export enum KeyRequestTab {
  PendingApproval,
  Approved,
  Rejected,
  All,
}

export enum KeyType {
  KEY_40 = '40 Key',
  KEY_30 = '30 Key',
}

export const keyRequestStatus = KeyRequestStatus;
export const keyRequestTab = KeyRequestTab;
export const keyType = KeyType;
