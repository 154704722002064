import yup from 'src/libraries/validator.library';

export const ForwardTicketShape = {
  id: yup.number().required(),
  name: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};

export const ForwardTicketSchema = yup.object(ForwardTicketShape);

export const ForwardTicketFormSchema = yup.object({
  name: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const ForwardTicketListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(ForwardTicketSchema.clone()),
});

export const ForwardTicketPayloadSchema = yup.object({
  forwardTicket: ForwardTicketSchema.clone(),
});

export const ForwardTicketDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IForwardTicket = yup.Asserts<typeof ForwardTicketSchema>;
export type IForwardTicketForm = yup.Asserts<typeof ForwardTicketFormSchema>;
