import React, { useState } from 'react';
import { ATMDivider, ATMPopover } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import style from './substation-entry-log-card.module.scss';

type IProps = {
  substationEntry: ISubstationEntry;
};

const SubstationEntryCard: React.FC<IProps> = ({ substationEntry }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          <u>
            <a
              className={style.popLink}
              role="button"
              onClick={() => setIsOpen(true)}
            >
              <b>{`${substationEntry?.substation.substationId} - ${substationEntry?.substation.name}`}</b>
            </a>
          </u>
        }
        position="right center"
        on="click"
      >
        <ATMPopover.Header>
          <span className={style.overlayHeader}>
            {substationEntry?.substation.name}
          </span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          <>
            <div className={style.overlayContent}>
              <FieldReadOnly label="Address">
                {substationEntry?.substation.addr1}
              </FieldReadOnly>
              <FieldReadOnly label="Phone">
                {substationEntry?.substation.phnNbr}
              </FieldReadOnly>
              <FieldReadOnly label="T Bros">
                {substationEntry?.substation.tbros}
              </FieldReadOnly>
            </div>
          </>
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default SubstationEntryCard;
