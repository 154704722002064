import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/oc-pending-action-report.service';
import { IOcPendingActionReport } from 'src/models/oc-pending-action-report.model';

export const ocPendingActionReportActionTypes = {
  OC_PENDING_ACTION_REPORT_DATA_READ: 'OC_PENDING_ACTION_REPORT_DATA_READ',
  OC_PENDING_ACTION_REPORT_LIST_READ: 'OC_PENDING_ACTION_REPORT_LIST_READ',
  OC_PENDING_ACTION_REPORT_DATA_UPDATE: 'OC_PENDING_ACTION_REPORT_DATA_UPDATE',
  OC_PENDING_ACTION_REPORT_DATA_DELETE: 'OC_PENDING_ACTION_REPORT_DATA_DELETE',
  OC_PENDING_ACTION_REPORT_DATA_SET: 'OC_PENDING_ACTION_REPORT_DATA_SET',
  OC_PENDING_ACTION_REPORT_LIST_CLEAR: 'OC_PENDING_ACTION_REPORT_LIST_CLEAR'
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_LIST_READ,
    service: services.listGET,
  },

  updatePUT: {
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (ocPendingActionReport: IOcPendingActionReport) => ({
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_SET,
    payload: {
      ocPendingActionReport,
    },
  }),
  clearList: () => ({
    type: ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_LIST_CLEAR
  })
};

export type IOcPendingActionReportAsync = typeof duckActions;

export interface IOcPendingActionReportState
  extends ICommonState<typeof ocPendingActionReportActionTypes> {
  data?: IOcPendingActionReport;
  deleteState?: number;
  list: IOcPendingActionReport[];
  total: number;
}

export const defaultState: IOcPendingActionReportState = {
  status: {},
  list: [],
  total: 0,
};

const OcPendingActionReportReducer = (
  state: IOcPendingActionReportState,
  action: IReducerAction<IOcPendingActionReportAsync>
): IOcPendingActionReportState => {
  switch (action.type) {
    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_SET:
    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_READ:
    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_UPDATE: {
      return {
        ...state,
        data: action.payload?.ocPendingActionReport,
      };
    }

    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_DELETE: {
      return { ...state, deleteState: action.payload?.success };
    }

    case ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_LIST_CLEAR: {
      return {
        ...state, list: [], total: 0
      };
    }

    default: {
      return state;
    }
  }
};

export default OcPendingActionReportReducer;
