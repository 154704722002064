import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  RequestStatusListPayloadSchema,
} from 'src/models/request-status.model';

const client = httpClient();
const endpoint = 'request-status';

const services = {
  listGET: async (params?: IORGDataTableQueryState) => {
    return client.get(`/${endpoint}`, params, RequestStatusListPayloadSchema);
  },

};

export default services;
