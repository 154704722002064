import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import { substationEntryActionTypes } from 'src/ducks/substation-entry.duck';
import {
  ISubstationEntry,
  ISubstationEntryForm,
} from 'src/models/substation-entry.model';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import { getSubstationEntryStatus } from 'src/selectors/substation-entry.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { fileActionTypes } from 'src/ducks/file.duck';
import { getFileStatus } from 'src/selectors/file.selector';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { createDateTime } from 'src/libraries/moment.library';
import moment from 'moment';
import { getUser } from 'src/libraries/amplify.library';
import SubstationEntryLogEditView from './substation-entry-log-edit.view';

type IProp = {
  data: ISubstationEntry;
  handleSuccess: () => void;
};

const SubstationEntryLogEdit: React.FC<IProp> = ({ data, handleSuccess }) => {
  const { state, actions } = useSubstationEntryContext();

  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isFileCached, setIsFileCached] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getSubstationEntryStatus(
    state,
    substationEntryActionTypes.SUBSTATION_ENTRY_DATA_UPDATE
  );
  const fileStatus = getFileStatus(
    fileState,
    fileActionTypes.FILE_SHAREPOINT_UPLOAD
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    if (isEditOpen && !isFileCached) {
      if (fileState.filename_list?.length) {
        fileAction.deleteAllStashedFiles();
      }
    }
  }, [isFileCached, isEditOpen]);

  const handleFileUploadSubmit = useCallback(
    async (responseData: ISubstationEntry) => {
      // const requestor = `${responseData?.entryEmployee?.firstName ?? ''}  ${
      //   responseData?.entryEmployee?.lastName ?? ''
      // }`;
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.substationEntryLogId) || '',
        parentFolder: responseData?.substationEntryLogId as any,
        requestId: responseData?.substationEntryLogId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.SUBSTATION_ENTRY_TYPE,
        setting: settingState,
        withParentFolder: false,
      });
    },
    [fileState, settingState]
  );

  const handleSubmit = useCallback(
    async (formData: ISubstationEntryForm) => {
      const inDateTime = createDateTime(
        moment(formData.inDateTime).toDate(),
        formData.inTime as any
      ).toDate();
      let outDateTime: any = null;
      if (formData?.outDateTime && formData?.outTime) {
        outDateTime = createDateTime(
          formData.outDateTime,
          formData.outTime
        ).toDate();
      }
      const response = await actions.updatePUT(data.substationEntryLogId, {
        ...formData,
        inDateTime,
        outDateTime,
      });
      if (response.payload) {
        handleFileUploadSubmit(response.payload);
        handleSuccess();
        const substationName = response.payload.substation.name
          .toString()
          .toLowerCase();
        ToastSuccess(
          `Substation ${
            response.payload.substation.name.length
              ? `${
                  substationName.charAt(0).toUpperCase() +
                  substationName.slice(1)
                }`
              : ''
          } updated successfully`
        );
        fileAction.deleteAllStashedFiles();
        setIsFileCached(false);
        setIsEditOpen(false);
      } else {
        fileAction.deleteAllStashedFiles();
        ToastError(response.error.message ?? 'Failed to update substation');
      }
    },
    [data, actions, setIsEditOpen, handleSuccess, fileState, fileAction]
  );

  const clearError = useCallback(() => {
    actions?.clearError();
  }, [actions]);

  return (
    <AccessComponent type={AccessType.SUBSTATION_ENTRY_LOG_UPDATE}>
      <SubstationEntryLogEditView
        formRef={formRef}
        defaultValues={data}
        loading={status.fetching || fileStatus.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        isOpen={isEditOpen}
        handleOpen={setIsEditOpen}
        errorHandling={status || fileStatus}
        clearError={clearError}
        setIsFileCached={setIsFileCached}
        isFileCached={isFileCached}
      />
    </AccessComponent>
  );
};

export default SubstationEntryLogEdit;
