import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/contractor-firm.service';
import { IContractorFirm } from 'src/models/contractor-firm.model';

export const contractorFirmActionTypes = {
  CONTRACTOR_FIRM_DATA_READ: 'CONTRACTOR_FIRM_DATA_READ',
  CONTRACTOR_FIRM_LIST_READ: 'CONTRACTOR_FIRM_LIST_READ',
  CONTRACTOR_FIRM_LIST_DOWNLOAD: 'CONTRACTOR_FIRM_LIST_DOWNLOAD',
  CONTRACTOR_FIRM_DATA_CREATE: 'CONTRACTOR_FIRM_DATA_CREATE',
  CONTRACTOR_FIRM_DATA_UPDATE: 'CONTRACTOR_FIRM_DATA_UPDATE',
  CONTRACTOR_FIRM_DATA_DELETE: 'CONTRACTOR_FIRM_DATA_DELETE',
  CONTRACTOR_FIRM_DATA_SET: 'CONTRACTOR_FIRM_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ,
    service: services.listGET,
  },

  downloadListGET: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_DOWNLOAD,
    service: services.listGET,
  },

  createPOST: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_UPDATE,
    service: services.updatePUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataDELETE: {
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_DELETE,
    service: services.dataDELETE,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  // This is a sync action
  setData: (contractorFirm: IContractorFirm) => ({
    type: contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_SET,
    payload: contractorFirm,
  }),
};

export type IContractorFirmAsync = typeof duckActions;

export interface IContractorFirmState
  extends ICommonState<typeof contractorFirmActionTypes> {
  data?: IContractorFirm;
  list: IContractorFirm[];
  total: number;
}

export const defaultState: IContractorFirmState = {
  status: {},
  list: [],
  total: 0,
};

const ContractorFirmReducer = (
  state: IContractorFirmState,
  action: IReducerAction<IContractorFirmAsync>
): IContractorFirmState => {
  switch (action.type) {
    case contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_SET:
    case contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_READ:
    case contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_UPDATE:
    case contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_DELETE: {
      if (action.params) {
        const [contracShortNm] = action.params;
        const list = state.list.filter(
          (value) => value.contracShortNm !== contracShortNm
        );

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default ContractorFirmReducer;
