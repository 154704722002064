import React, { useCallback, useState } from 'react';
import { useATMFormContext } from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import {
  IFacilityForm,
  IFacilityFormValidation,
  IUserGrpForm,
} from 'src/models/facility.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import FacilityNotifApprovalsItemDeleteView from './facility-notif-approvals-item-delete.view';

type IProps = {
  defaultValues: Partial<IUserGrpForm>;
};

const FacilityNotifApprovalsItemDelete: React.FC<IProps> = ({
  defaultValues,
}) => {
  const { control, getValues } = useATMFormContext<IFacilityFormValidation>();
  const { state, actions } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);
  const addStatus = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_UPDATE
  ).fetching;

  const handleSubmit = useCallback(async () => {
    const values = getValues();

    const newFormData = {
      ...values,
      userGrps: [
        ...(values.userGrps?.filter(
          ({ userGrpId }) => userGrpId !== defaultValues.userGrpId
        ) ?? []),
      ],
    };
    const result = await actions.updatePUT(
      newFormData.outgFacId ?? 0,
      newFormData as IFacilityForm
    );
    if (result.payload) {
      ToastSuccess(
        Lang.formatString(
          Lang.MSG_USER_GROUP_DELETE,
          defaultValues.userGrpNm ?? '--'
        )
      );
      actions.dataGET(newFormData.outgFacId ?? 0);
    } else {
      ToastError(result.error?.message);
    }
    setIsOpen(!isOpen);
  }, [actions, isOpen, control, setIsOpen, getValues]);

  return (
    <FacilityNotifApprovalsItemDeleteView
      isOpen={isOpen}
      loading={addStatus}
      defaultValues={defaultValues}
      handleOpen={setIsOpen}
      handleSave={handleSubmit}
    />
  );
};

export default FacilityNotifApprovalsItemDelete;
