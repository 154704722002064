import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContractorReducer, {
  IContractorState,
  defaultState,
  duckActions,
} from 'src/ducks/contractor.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    ContractorReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IContractorContext = ReturnType<typeof useReducer>;

const ContractorContext = createContext<Partial<IContractorContext>>({
  state: defaultState,
}) as React.Context<IContractorContext>;

type IProps = {
  state?: Partial<IContractorState>;
};

const ContractorProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ContractorContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ContractorContext.Provider>
  );
};

const useContractorContext = () => useContext(ContractorContext);

export type IUseContractorContext = ReturnType<typeof useContractorContext>;

export { ContractorContext, ContractorProvider, useContractorContext };
