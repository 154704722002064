import classNames from 'classnames';
import React, { useCallback } from 'react';
import {
  ATMButton,
  ATMIcon,
  ATMPopover,
  ATMSelect,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Status from 'src/components/atoms/status/status.component';
import { SubstationTimeStatus } from 'src/constants';
import { getEmployeeName } from 'src/helpers/employee.helper';
import history from 'src/history';
import Lang from 'src/libraries/language';
import moment, {
  format24hTime,
  formatTimeOnly,
} from 'src/libraries/moment.library';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import SubstationEntryLogAdd from '../../substation-entry-log-add/substation-entry-log-add.component';
import SubstationEntryCard from '../../substation-entry-log-card/substation-entry-log-card.component';
import SubstationEntryLogCheckout from '../../substation-entry-log-checkout/substation-entry-log-checkout.component';
import SubstationEntryLogEdit from '../../substation-entry-log-edit/substation-entry-log-edit.component';
import SubstationEntryEmployeeCard from '../../substation-entry-log-employee-card/substation-entry-log-employee-card.component';
import styles from './substation-entry-log-list-today.module.scss';

export const handleTimeStatus = (
  inDateTime: Date,
  outDateTime?: Date | null
) => {
  const duration = outDateTime
    ? moment.duration(moment(outDateTime).diff(inDateTime))
    : moment.duration(moment().diff(inDateTime));
  const checkHours = Math.floor(duration.asHours());
  let hours = `(${checkHours} hrs)`;
  let language;
  let color;

  if (outDateTime === null) {
    if (checkHours > 24) {
      language = SubstationTimeStatus.NOT_CHECKOUT_WITHIN_THE_DAY;
      color = 'in_more_24hs';
    } else if (checkHours < 24) {
      if (!moment(inDateTime).isSame(moment(), 'day')) {
        language = SubstationTimeStatus.PAST_MIDNIGHT;
        hours = `(Midnight - ${checkHours} hrs)`;
        color = 'in_pass_midnight';
      } else {
        language = SubstationTimeStatus.LESS_THAN_A_DAY;
        color = 'in';
      }
    }
  } else {
    language = SubstationTimeStatus.OUT;
    color = 'out';
  }
  return { language, hours, color };
};

type IProps = {
  data: ISubstationEntry[];
  today: number;
  loading: boolean;
  downloading: boolean;
  handleDownload: (all: boolean) => void;
  handleFetch: (
    params: Partial<IORGDataTableQueryState>,
    all?: boolean
  ) => void;
  originalData: ISubstationEntry[];
  filteredBySubstation: ISubstationEntry[];
};

const SubstationEntryLogListToday: React.FC<IProps> = ({
  data,
  loading,
  downloading,
  handleDownload,
  handleFetch,
  originalData,
  filteredBySubstation,
}) => {
  const handleChange = useCallback(
    (values, isAll = false) => {
      handleFetch(
        { ...values, limit: 0 },
        typeof isAll !== 'boolean' ? false : isAll
      );
    },
    [handleFetch]
  );

  const columns: IORGDataTableColumn<ISubstationEntry>[] = [
    {
      index: 'substation.name',
      title: 'Substation',
      cellClass: (_, value) => {
        return classNames(
          styles.borderLeft,
          styles[handleTimeStatus(value.inDateTime, value.outDateTime).color]
        );
      },
      render: (_, value) => {
        return (
          <>
            <SubstationEntryCard substationEntry={value} />
          </>
        );
      },
    },
    {
      index: 'employee.lastName',
      title: 'Employee Name and Visitors',
      render: (_, value) => {
        return (
          <div style={{ position: 'relative' }}>
            <ul className={styles.nameCell}>
              <li
                style={{
                  width: '230px',
                  lineHeight: '1em',
                }}
              >
                <SubstationEntryEmployeeCard substationEntry={value} />
              </li>
              <li>
                <Status
                  timeStatus
                  value="time"
                  languageHours={handleTimeStatus(
                    value.inDateTime,
                    value.outDateTime
                  )}
                />
              </li>

              {value.fileAttachedFlag ||
              (value.visitors && value.visitors !== '') ? (
                <li>
                  <ATMPopover
                    trigger={<ATMIcon name="add user" color="grey" />}
                    content={
                      value.fileAttachedFlag
                        ? Lang.MSG_ADDITIONAL_VISITORS
                        : value.visitors
                    }
                    basic
                  />
                </li>
              ) : undefined}
            </ul>
          </div>
        );
      },
    },
    {
      index: 'inDateTime',
      title: 'Time In',
      render: (value) => {
        return (
          <>
            <ATMIcon color="grey" name="sign-out" />
            <span>{formatTimeOnly(value)}</span>
          </>
        );
      },
    },
    {
      index: 'outDateTime',
      title: 'Time Out',
      render: (_, value) => {
        return (
          <>
            {value.outDateTime !== null ? (
              <>
                <ATMIcon
                  color="grey"
                  name="sign-out"
                  className={styles.rotate}
                />
                {formatTimeOnly(value.outDateTime)}
              </>
            ) : (
              <SubstationEntryLogCheckout
                id={value.substationEntryLogId}
                handleSuccess={() => handleChange({ page: 1 }, true)}
              />
            )}
          </>
        );
      },
    },
    {
      index: 'entryReason.description',
      title: 'Activity',
    },
    {
      index: 'comment',
      title: 'Purpose',
    },
    {
      index: 'updatedByEmployee.fullName',
      title: 'Updated By',
    },
    {
      index: 'action',
      sortable: false,
      title: 'Action',
      width: '120px',
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, value, index) => (
        <SubstationEntryLogEdit
          key={index}
          data={value}
          handleSuccess={() => handleChange({ page: 1 }, true)}
        />
      ),
    },
  ];

  return (
    <>
      <SubstationEntryLogAdd
        handleSuccess={() => handleChange({ page: 1 }, true)}
      />
      <div className={styles.substationLogSection} style={{ marginTop: '1em' }}>
        <ORGDataTable
          columns={columns}
          data={data}
          loading={loading}
          onChange={handleChange}
          sortable
          showPagination={false}
          celled={false}
          location={history.location}
          handleLocation={history.push}
        >
          {() => ({
            toolbars: {
              substationId: ({ value, setValue }) => {
                return (
                  <ATMSelect
                    placeholder={Lang.LBL_SUBSTATION}
                    value={value}
                    options={Array.from(
                      new Map(
                        originalData
                          .map(({ substation }) => substation)
                          .map((item) => [item.substationId, item])
                      ).values()
                    )
                      .map((item) => ({
                        key: item.substationId,
                        value: item.substationId,
                        text: item.name,
                      }))
                      .sort((a, b) => (a.text < b.text ? -1 : 1))}
                    onChange={(_, { value: id }) => {
                      setValue(id);
                    }}
                    search
                    selection
                    clearable
                    selectOnBlur={false}
                  />
                );
              },
              empId: ({ value, setValue }) => {
                return (
                  <ATMSelect
                    placeholder={Lang.LBL_NAME}
                    value={value}
                    options={Array.from(
                      new Map(
                        filteredBySubstation
                          .map(({ employee }) => employee)
                          .map((item) => [item?.empId, item])
                      ).values()
                    )
                      .map((item) => ({
                        key: item?.empId,
                        value: item?.empId,
                        text: getEmployeeName(item),
                      }))
                      .sort((a, b) => (a.text < b.text ? -1 : 1))}
                    onChange={(_, { value: id }) => {
                      setValue(id);
                    }}
                    search
                    selection
                    clearable
                    selectOnBlur={false}
                  />
                );
              },
              text: () => (
                <span
                  style={{ position: 'absolute', top: '10px', left: '35%' }}
                >
                  {Lang.formatString(
                    Lang.NTE_SUBSTATION_ENTRY_LOG_TODAY,
                    format24hTime(new Date()) ?? ''
                  )}
                </span>
              ),
            },
          })}
        </ORGDataTable>
      </div>
      <span className={styles.selectables}>
        <ATMButton
          key={Lang.LBL_DOWNLOAD}
          secondary
          icon="download"
          size="tiny"
          content={Lang.LBL_DOWNLOAD}
          onClick={() => handleDownload(false)}
          loading={downloading}
          disabled={downloading}
        />
      </span>
    </>
  );
};

export default SubstationEntryLogListToday;
