import {
  isoWorkKindActionTypes,
  IIsoWorkKindState,
} from 'src/ducks/iso-work-kind.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getIsoWorkKindStatus = (
  state: IIsoWorkKindState,
  action: keyof typeof isoWorkKindActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
