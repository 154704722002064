import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import history from 'src/history';

type IProp = {
  id?: string;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  empName?: string;
  rightPanel?: boolean;
};

const RolesDelete: React.FC<IProp> = ({
  id,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
  empName,
  rightPanel,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      rightPanel ? (
        <ATMButton
          content="Drop all roles"
          type="button"
          onClick={() => handleOpen(true)}
          noBorder
        />
      ) : (
        <ATMDropdown.Item
          content="Drop all roles"
          onClick={() => handleOpen(true)}
        />
      )
    }
  >
    <ATMModal.Header>Delete Roles</ATMModal.Header>
    <ATMModal.Content>
      <p>
        Are you sure you want to drop all roles for{' '}
        <strong>
          {empName} / {id}
        </strong>
        ?
      </p>
      <br />
      <p>All the assigned roles will be deleted</p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton
        secondary
        type="button"
        content="Cancel"
        onClick={() => handleOpen(false)}
      />
      <ATMButton
        negative
        content="Yes, Delete"
        onClick={() => {
          handleSubmit();
          history.push('/roles');
        }}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default RolesDelete;
