import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import styles from './filter-checkbox.component.module.scss';

type IProps = {
  name: string;
  content: string;
  outageReqStatusList: (workGrpNm: number, checked: boolean) => void;
  valueId?: number;
  value: boolean;
};

const RequestStatusCheckbox: React.FC<IProps> = ({
  valueId,
  outageReqStatusList,
  content,
  name,
  value,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value, setIsChecked]);

  return (
    <ATMButton
      name={name}
      content={content}
      type="button"
      icon={isChecked && 'check'}
      labelPosition={isChecked ? 'left' : undefined}
      className={classNames('icon', styles.checkButton, {
        [styles.checked]: isChecked,
      })}
      onClick={() => {
        setIsChecked((val) => {
          outageReqStatusList(Number(valueId ?? 0), !val);

          return !val;
        });
      }}
    />
  );
};

export default React.memo(RequestStatusCheckbox);
