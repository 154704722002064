import React from 'react';
import {
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import {
  IEquipmentTypeForm,
  EquipmentTypeFormSchema,
} from 'src/models/equipment-type.model';
import style from './equipment-type-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IEquipmentTypeForm>;
  handleSubmit: (data: IEquipmentTypeForm) => void;
  handleEnable: (value: boolean) => void;
};

const EquipmentTypeForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues as IEquipmentTypeForm}
      validationSchema={EquipmentTypeFormSchema}
    >
      {({ control, formState: { errors, isDirty } }) => {
        handleEnable(isDirty);
        return (
          <>
            <ATMGrid>
              <ATMGrid.Row>
                <ATMGrid.Column width={8}>
                  {!defaultValues ? (
                    <ATMField
                      label={
                        <span className={style.label}>
                          {Lang.LBL_EQUIPMENT_TYPE_NAME}
                        </span>
                      }
                      name="facTypNm"
                      control={control}
                      error={errors.facTypNm}
                      as={ATMInput}
                    />
                  ) : (
                    <FieldReadOnly label={Lang.LBL_EQUIPMENT_TYPE_NAME}>
                      <ATMField
                        as={ATMInput}
                        name="facTypNm"
                        control={control}
                        type="hidden"
                        className="hidden"
                      />
                      <p>{defaultValues.facTypNm}</p>
                    </FieldReadOnly>
                  )}
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMField
                    label={
                      <span className={style.label}>
                        {Lang.LBL_EQUIPMENT_TYPE_DESCRIPTION}
                      </span>
                    }
                    name="facTypDesc"
                    control={control}
                    error={errors.facTypDesc}
                    as={ATMTextArea}
                    maxLength={50}
                    style={{
                      minHeight: '10em',
                      maxHeight: '10em',
                    }}
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          </>
        );
      }}
    </ATMForm>
  );
};

export default EquipmentTypeForm;
