import React, { useState } from 'react';
import { SemanticTEXTALIGNMENTS } from 'semantic-ui-react';
import { ATMButton, ATMInput } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { AccessRole, AccessType, LERRequestPage } from 'src/constants';
import {
  IDailyReport,
  IDailyReportVoltageGroup,
  IETS,
  IRadialized,
} from 'src/models/daily-reports.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { ISystemChange } from 'src/models/system-change.model';
import { IOmsRequestSubmitRefreshResponse } from 'src/models/oms-request.model';
import LocalPanel from 'src/components/organisms/local-panel/local-panel.component';
import { hasRole } from 'src/libraries/access.library';
import FacilityList from '../../facility/facility-list/facility-list.component';
import EquipmentTypeList from '../../equipment-type/equipment-type-list/equipment-type-list.component';
import SubstationList from '../../substation/substation-list/substation-list.component';
import UserGroupList from '../../user-group/user-group-list/user-group-list.component';
import UserGroupAdd from '../../user-group/user-group-add/user-group-add.component';
import SubstationCountListList from '../../substation-count/substation-count-list/substation-count-list.component';
import SubstationCountUpload from '../../substation-count/substation-count-upload/substation-count-upload.component';
import SubstationAdd from '../../substation/substation-add/substation-add.component';
import EquipmentTypeAdd from '../../equipment-type/equipment-type-add/equipment-type-add.component';
import LERRequestList from '../../ler-request/ler-request-list/ler-request-list.component';
import OmsRequestList from '../../oms-request/oms-request-list/oms-request-list.component';
import OmsRequestAdd from '../../oms-request/oms-request-add/oms-request-add.component';
import OutagesList from '../../outage/outages-list/outages-list.component';
import LERRequestAdd from '../../ler-request/ler-request-add/ler-request-add.component';
import CancelledReportList from '../../cancelled-report/cancelled-report-list.component';
import OcPendingActionReportList from '../../oc-pending-action-report/oc-pending-action-report-list/oc-pending-action-report-list.component';
import RejectedReport from '../../rejected-report/rejected-report.component';
import TNAReport from '../../tna-report/tna-report.component';
import OMSAudit from '../../oms-audit/oms-audit.component';
import DailyReports from '../../daily-reports/daily-reports.component';
import DailyReportsDateNavigation from '../../daily-reports/daily-reports-date-navigation/daily-reports-date-navigation.component';
import OutagePlanningSettings from '../../outage-planning-settings/outage-planning-settings.component';
import DailyReportsViewDetails from '../../daily-reports/daily-reports-view-details/daily-reports-view-details.component';
import SystemChangeList from '../../system-change/system-change-list/system-change-list.component';
import CategoryList from '../../category/category-list/category-list.component';
import CategoryAdd from '../../category/category-add/category-add.component';
import styles from './outage-planning.component.module.scss';

const OutagePanel: React.FC = () => {
  const [dailyReportsLERs, setDailyReportsLERs] = useState<IDailyReport[]>([]);
  const [facilityActions, setFacilityActions] = useState({
    isNew: false,
    isDetails: false,
    isEdit: false,
  });
  const { handleChange } = useLocationParams([LERRequestPage.View]);
  const [dailyReports, setDailyReports] = useState<
    IDailyReportVoltageGroup[] | IRadialized[] | IETS[] | ISystemChange[]
  >();
  const [checkboxes, setCheckboxes] = useState<string[]>([]);
  const [omsResponse, setOmsResponse] = useState<
    IOmsRequestSubmitRefreshResponse[]
  >([]);
  const [reportDate, setReportDate] = useState<Date>(new Date());
  const [overviewEmail, setOverviewEmail] = useState<JSX.Element>();
  const [selectAllFlag, setSelectAllFlag] = useState<boolean | undefined>(
    false
  );
  const [excludeFlag, setExcludeFlag] = useState<boolean | undefined>(false);
  const [startingEndingFlag, setStartingEndingFlag] = useState<
    boolean | undefined
  >(false);
  const [checkType, setCheckType] = useState<string | undefined>(undefined);

  const actionBtnFacility = () => {
    if (Object.values(facilityActions).includes(true)) {
      return [];
    }

    return [
      <ATMButton
        key="newFacility"
        primary
        size="small"
        icon="plus"
        floated="right"
        content={Lang.LBL_NEW}
        onClick={() =>
          setFacilityActions({
            isNew: true,
            isDetails: false,
            isEdit: false,
          })
        }
      />,
    ];
  };

  if (dailyReportsLERs.length > 0) {
    return (
      <DailyReportsViewDetails
        data={dailyReportsLERs}
        setDailyReportsLERs={setDailyReportsLERs}
        date={reportDate}
      />
    );
  }

  const panes = [
    {
      id: 'ler_request',
      title: Lang.TTL_NEW_LER,
      render: <LERRequestList />,
      actions: [
        <LERRequestAdd
          key="add"
          trigger={
            <ATMButton
              key="add"
              primary
              icon="plus"
              content={Lang.LBL_LER_CREATE}
            />
          }
        />,
      ],
      permissions: [AccessType.LER_READ],
    },
    {
      title: Lang.TTL_OUTAGES,
      render: <OutagesList />,
      actions: [
        <LERRequestAdd
          key="add"
          trigger={
            <ATMButton
              key="add"
              primary
              icon="plus"
              content={Lang.LBL_LER_CREATE}
            />
          }
        />,
      ],
      permissions: [AccessType.OUTAGES_READ],
    },
    {
      title: Lang.TTL_OMS_REQUEST,
      render: (
        <OmsRequestList
          omsResponse={omsResponse}
          setCheckboxes={setCheckboxes}
          checkboxes={checkboxes}
        />
      ),
      actions: [
        <OmsRequestAdd
          key={`add_${checkboxes.length}`}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          handleOmsResponse={setOmsResponse}
        />,
      ],
      permissions: [AccessType.OMS_READ],
    },
    {
      title: Lang.TTL_REPORTS,
      expand: true,
      isDefault: hasRole(AccessRole.MCC_REAL_TIME_OPERATOR),
      subMenus: [
        {
          title: Lang.TTL_TNA_REPORT,
          render: <TNAReport />,
          permissions: [AccessType.TNA_REPORT_READ],
        },
        {
          title: Lang.TTL_OMS_AUDIT,
          render: <OMSAudit />,
          permissions: [AccessType.OMS_AUDIT_REPORT_READ],
        },

        {
          title: Lang.TTL_DAILY_REPORTS,
          isDefault: hasRole(AccessRole.MCC_REAL_TIME_OPERATOR),
          render: (
            <DailyReports
              reportDate={reportDate}
              dailyReports={dailyReports ?? []}
              setDailyReportsLERs={setDailyReportsLERs}
              setDailyReports={setDailyReports}
              setOverviewEmail={setOverviewEmail}
              setSelectAllFlag={setSelectAllFlag}
              selectAllFlag={selectAllFlag}
              setExcludeFlag={setExcludeFlag}
              excludeFlag={excludeFlag}
              setStartingEndingFlag={setStartingEndingFlag}
              startingEndingFlag={startingEndingFlag}
              setCheckType={setCheckType}
              checkType={checkType}
            />
          ),
          actions: [
            <DailyReportsDateNavigation
              key="date-navigation"
              reportDate={reportDate}
              setReportDate={setReportDate}
              setDailyReports={setDailyReports}
              overviewEmail={overviewEmail}
            />,
          ],
          actionsPosition: 'center' as SemanticTEXTALIGNMENTS,
          permissions: [AccessType.DAILY_REPORT_READ],
        },
        {
          title: Lang.TTL_CANCELLED_REPORT,
          render: <CancelledReportList />,
          permissions: [AccessType.CANCELLED_REPORT_READ],
        },
        {
          title: Lang.TTL_OC_PENDING_ACTION_REPORT,
          render: <OcPendingActionReportList />,
          permissions: [AccessType.OC_PENDING_REPORT_READ],
        },
        {
          title: Lang.TTL_REJECTED_REPORT,
          render: <RejectedReport />,
          permissions: [AccessType.REJECTED_REPORT_READ],
        },
        {
          title: Lang.TTL_SYSTEM_CHANGE,
          render: <SystemChangeList />,
          permissions: [AccessType.REPORT_SYSTEM_CHANGE_STATUS_READ],
        },
      ],
      isOpen: true,
    },
    {
      title: Lang.TTL_SETTINGS,
      subMenus: [
        {
          title: Lang.TTL_FACILITIES,
          render: (
            <FacilityList
              facilityActions={facilityActions}
              setFacilityActions={setFacilityActions}
            />
          ),
          actions: actionBtnFacility(),
          permissions: [AccessType.FACILITIES_READ],
        },
        {
          title: Lang.TTL_EQUIPMENT_TYPE,
          render: <EquipmentTypeList />,
          actions: [<EquipmentTypeAdd key="add" />],
          permissions: [AccessType.EQUIPMENT_READ],
        },
        {
          title: Lang.TTL_SUBSTATIONS,
          render: <SubstationList />,
          actions: [<SubstationAdd key="add" />],
          permissions: [AccessType.SUBSTATIONS_READ],
        },
        {
          title: Lang.TTL_SUBSTATION_CUSTOMER_COUNTS,
          render: <SubstationCountListList />,
          actions: [<SubstationCountUpload key="add" />],
          permissions: [AccessType.SUBSTATION_COUNT_READ],
        },
        {
          title: Lang.TTL_GROUPS,
          render: <UserGroupList />,
          actions: [<UserGroupAdd key="add" />],
          permissions: [AccessType.USER_GROUPS_READ],
        },
        {
          title: Lang.TTL_CATEGORIES,
          render: <CategoryList />,
          actions: [<CategoryAdd key="add" />],
          permissions: [AccessType.SHORT_DESCRIPTION_CATEGORIES_READ],
        },
        {
          title: Lang.TTL_OUTAGE_PLANNING_SETTINGS,
          render: <OutagePlanningSettings />,
          permissions: [AccessType.GENERAL_SETTINGS_READ],
        },
      ],
    },
  ];

  const actionPanes = [
    {
      className: styles.searchContainer,
      render: (
        <div>
          <ATMInput
            name="test"
            label="LER"
            size="mini"
            fluid
            className={styles.searchInput}
            transparent
            clearable
            placeholder="Search..."
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                handleChange({ [LERRequestPage.View]: e.target.value });
              }
            }}
          />
        </div>
      ),
      // permissions: [AccessType.LER_READ],
    },
  ];

  return <LocalPanel name="tab" panes={panes} actionPanes={actionPanes} />;
};

export default OutagePanel;
