import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TrainingHistoryReducer, {
  ITrainingHistoryState,
  defaultState,
  duckActions,
} from 'src/ducks/training-history.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TrainingHistoryReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITrainingHistoryContext = ReturnType<typeof useReducer>;

const TrainingHistoryContext = createContext<Partial<ITrainingHistoryContext>>({
  state: defaultState,
}) as React.Context<ITrainingHistoryContext>;

type IProps = {
  state?: Partial<ITrainingHistoryState>;
};

const TrainingHistoryProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TrainingHistoryContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TrainingHistoryContext.Provider>
  );
};

const useTrainingHistoryContext = () => useContext(TrainingHistoryContext);

export type IUseTrainingHistoryContext = ReturnType<
  typeof useTrainingHistoryContext
>;

export {
  TrainingHistoryContext,
  TrainingHistoryProvider,
  useTrainingHistoryContext,
};
