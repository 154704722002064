import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import OmsAuditReportReducer, {
  IOmsAuditReportState,
  defaultState,
  duckActions,
} from 'src/ducks/oms-audit-report.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    OmsAuditReportReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IOmsAuditReportContext = ReturnType<typeof useReducer>;

const OmsAuditReportContext = createContext<Partial<IOmsAuditReportContext>>({
  state: defaultState,
}) as React.Context<IOmsAuditReportContext>;

type IProps = {
  state?: Partial<IOmsAuditReportState>;
};

const OmsAuditReportProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <OmsAuditReportContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </OmsAuditReportContext.Provider>
  );
};

const useOmsAuditReportContext = () => useContext(OmsAuditReportContext);

export type IUseOmsAuditReportContext = ReturnType<
  typeof useOmsAuditReportContext
>;

export {
  OmsAuditReportContext,
  OmsAuditReportProvider,
  useOmsAuditReportContext,
};
