import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import OmsRequestReducer, {
  IOmsRequestState,
  defaultState,
  duckActions,
} from 'src/ducks/oms-request.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    OmsRequestReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IOmsRequestContext = ReturnType<typeof useReducer>;

const OmsRequestContext = createContext<Partial<IOmsRequestContext>>({
  state: defaultState,
}) as React.Context<IOmsRequestContext>;

type IProps = {
  state?: Partial<IOmsRequestState>;
};

const OmsRequestProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <OmsRequestContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </OmsRequestContext.Provider>
  );
};

const useOmsRequestContext = () => useContext(OmsRequestContext);

export type IUseOmsRequestContext = ReturnType<typeof useOmsRequestContext>;

export { OmsRequestContext, OmsRequestProvider, useOmsRequestContext };
