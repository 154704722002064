import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  ATMDropdown,
  ATMSegment,
  ATMDivider,
} from 'shared-it-appmod-ui';
import { AccessRole } from 'src/constants/access.constant';
import { getIsAdmin } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ISickList } from 'src/models/sick-list.model';
import SickListAdd from '../sick-list-add/sick-list-add.component';
import SickListDelete from '../sick-list-delete/sick-list-delete.component';
import SickListEdit from '../sick-list-edit/sick-list-edit.component';
import style from '../sick-list.module.scss';

type IProps = {
  data: ISickList[];
  loading: boolean;
};

const SickListListView: React.FC<IProps> = ({ data, loading }) => {
  return (
    <ATMSegment loading={loading}>
      <ATMGrid.Row>
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle" width={10}>
            <ATMHeader style={{ fontSize: '1em' }}>
              {Lang.formatString(Lang.TTL_SICK_LIST, data.length)}
            </ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column
            textAlign="right"
            className={style.addButton}
            width={6}
          >
            {getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) && (
              <SickListAdd types={data} />
            )}
          </ATMGrid.Column>
        </ATMGrid>
      </ATMGrid.Row>

      {data.map((value) => {
        return (
          <div id="list" key={value.sickEmpEntryId}>
            <ATMDivider />
            <ATMGrid.Row className={style.rowStyle}>
              <ATMGrid>
                <ATMGrid.Column width={12}>
                  <span className={style.userTxt}>
                    {value.empSick.emp?.lastName && value.empSick.emp?.firstName
                      ? `${value.empSick.emp?.lastName}, ${value.empSick.emp?.firstName}`
                      : '--'}
                  </span>
                  <span className={style.userTypeTxt}>
                    {value.empSick.userType?.toUpperCase()} User
                  </span>
                </ATMGrid.Column>
                <ATMGrid.Column width={4} className={style.dropdownButtons}>
                  {getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) && (
                    <ATMDropdown
                      size="small"
                      // className={style.manageDropdown}
                      icon="ellipsis horizontal"
                    >
                      <ATMDropdown.Menu>
                        <SickListEdit data={value} />
                        <SickListDelete data={value} />
                      </ATMDropdown.Menu>
                    </ATMDropdown>
                  )}
                </ATMGrid.Column>
              </ATMGrid>
            </ATMGrid.Row>

            <ATMGrid.Row className={style.rowDesStyle}>
              <ATMGrid>
                <ATMGrid.Column verticalAlign="middle">
                  <span>{value.reason.sickTypeDesc}</span>
                </ATMGrid.Column>
              </ATMGrid>
            </ATMGrid.Row>
          </div>
        );
      })}
    </ATMSegment>
  );
};

export default SickListListView;
