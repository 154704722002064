import yup from 'src/libraries/validator.library';
import { EmployeeInternalSchema } from './employee.model';
import { NoteSchema } from './note.model';
import { UserGroupShape } from './user-group.model';

export const DailyOutageNotesSchema = yup.object({
  notesId: yup.number(),
  notes: yup.string().required(),
  isComplete: yup.boolean().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const EmailGroupAuthShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  groupId: yup.number().required(),
  emailType: yup.string().required(),
  status: yup.string().nullable(),
  statusUpdatedBy: yup.string().nullable(),
  statusUpdatedOn: yup.date().nullable(),
  comments: yup.string().nullable(),
  emailSentAt: yup.date().nullable(),
  emailResentAt: yup.date().nullable(),
  emailBody: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const AuthStatementShape = {
  authStmnt: yup.string().nullable(),
  cancelNote: NoteSchema.optionalContent(),
  dailyNotes: yup.array().of(DailyOutageNotesSchema.clone()).nullable(),
  emailGroups: yup
    .array()
    .of(
      yup.object({
        ...EmailGroupAuthShape,
        statusEmp: EmployeeInternalSchema.clone().nullable(),
        userGroup: yup.object(UserGroupShape),
      })
    )
    .nullable(),
};

export const InformationShape = {
  authInfo: yup.object(AuthStatementShape),
};

export const AuthInfosSchema = yup.object(InformationShape);
export const AuthStatementSchema = yup.object(AuthStatementShape);
export const AuthStatementFormSchema = yup.object({
  ...AuthStatementShape,
});

export const AuthInfoPayloadSchema = yup.object({
  info: AuthInfosSchema.clone(),
});

export type IAuthInfo = yup.Asserts<typeof AuthInfosSchema>;
export type IAuthStatement = yup.Asserts<typeof AuthStatementSchema>;
export type IAuthStatementForm = yup.Asserts<typeof AuthStatementFormSchema>;
export type IDailyOutageNotes = yup.Asserts<typeof DailyOutageNotesSchema>;
