import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SubCategoryReducer, {
  ISubCategoryState,
  defaultState,
  duckActions,
} from 'src/ducks/sub-category.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SubCategoryReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISubCategoryContext = ReturnType<typeof useReducer>;

const SubCategoryContext = createContext<Partial<ISubCategoryContext>>({
  state: defaultState,
}) as React.Context<ISubCategoryContext>;

type IProps = {
  state?: Partial<ISubCategoryState>;
};

const SubCategoryProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SubCategoryContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  );
};

const useSubCategoryContext = () => useContext(SubCategoryContext);

export type IUseSubCategoryContext = ReturnType<typeof useSubCategoryContext>;

export { SubCategoryContext, SubCategoryProvider, useSubCategoryContext };
