import React, { useState, useCallback } from 'react';
import {
  ATMGrid,
  ORGDataTable,
  IORGDataTableColumn,
  ATMIcon,
  ATMDropdown,
  MOLNoData,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IEmployeeRoleFilter, IRoleEmployeeItem } from 'src/models/roles.model';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { RoleEmpRoleStatus } from 'src/constants';
import RolesCard from '../roles-card/roles-card.component';
import RolesFilters from '../roles-filters/roles-filters.component';
import RolesDelete from '../roles-delete/roles-delete.component';
import RolesAdd from '../roles-add/roles-add.component';
import style from './role-list.module.scss';

type IProps = {
  data: IRoleEmployeeItem[];
  loading: boolean;
  handleSubmit: (data: Partial<IEmployeeRoleFilter>) => void;
  showSearch: boolean;
  handleShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  handleRolesListExcelDownload: () => void;
  handleRolesListPDFDownload: () => void;
  handleRolesListEmailsExcelDownload: () => void;
  setFilterState: React.Dispatch<
    React.SetStateAction<Partial<IEmployeeRoleFilter>>
  >;
  filterState: Partial<IEmployeeRoleFilter>;
  dLStatus: boolean;
};

const RolesListView: React.FC<IProps> = ({
  data,
  loading,
  handleSubmit,
  showSearch,
  handleRolesListExcelDownload,
  handleRolesListPDFDownload,
  handleRolesListEmailsExcelDownload,
  setFilterState,
  filterState,
  dLStatus,
  handleShowSearch,
}) => {
  const [onClose, setOnClose] = useState(false);
  const openRightPanel = useCallback(() => {
    setOnClose((val) => !val);
  }, [setOnClose]);

  const columns: IORGDataTableColumn<IRoleEmployeeItem>[] = [
    {
      index: 'name',
      title: `${Lang.LBL_NAME_ID}`,
      render: (_, value) => {
        return (
          <RolesCard
            data={value}
            openRightPanel={openRightPanel}
            onClose={onClose}
            setFilterState={setFilterState}
            filterState={filterState}
          />
        );
      },
    },
    {
      index: 'email',
      title: `${Lang.LBL_ROLE_EMAIL}`,
      render: (value) => <a className={style.linkStyle}>{value}</a>,
    },
    {
      index: 'employeeStat',
      title: `${Lang.LBL_ROLE_HR_STATUS}`,
      render: (value) => <ListStatus value={value} label />,
    },
    {
      index: 'role.roleStatus',
      title: `${Lang.LBL_ROLE_STATUS}`,
      render: (value) => (
        <ListStatus
          value={value ? RoleEmpRoleStatus.ACTIVE : RoleEmpRoleStatus.INACTIVE}
          label
        />
      ),
    },
    {
      index: 'title',
      title: `${Lang.LBL_ROLE_TITLE}`,
      render: (value) => <p>{value}</p>,
    },
    {
      index: 'roles',
      title: `${Lang.LBL_ROLES_TITLES}`,
      render: (_, value) => {
        return (
          <div className={style.rolesList}>
            {(value.roles || []).map((element) => {
              return (
                <div key={element.roleId} className={style.rolesStyle}>
                  {element?.roleName}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      index: 'action',
      title: `${Lang.LBL_ACTION}`,
      width: '120px',
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, value) =>
        value.role.roleStatus ? (
          <>
            <ATMDropdown size="small" key="" icon="ellipsis horizontal">
              <ATMDropdown.Menu>
                <RolesDelete
                  id={value.empId || ''}
                  empName={value.fullName || ''}
                  handleSuccess={() => {
                    handleSubmit(filterState);
                  }}
                />
              </ATMDropdown.Menu>
            </ATMDropdown>
          </>
        ) : (
          <span />
        ),
    },
  ];
  return (
    <>
      <ATMGrid>
        <ATMGrid.Row verticalAlign="middle" stretched>
          <ATMGrid.Column>
            <div className={style.headerFlex}>
              <RolesAdd
                onClose={onClose}
                setOnClose={openRightPanel}
                setFilterState={setFilterState}
              />
            </div>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>

      <div>
        <RolesFilters
          handleSubmit={handleSubmit}
          handleShowSearch={handleShowSearch}
        />
        <ATMGrid.Column textAlign="right">
          <ATMDropdown
            className={style.downloadBtn}
            button
            size="small"
            trigger={<span>{Lang.LBL_DOWNLOAD}</span>}
            disabled={!showSearch || dLStatus || (data && data.length === 0)}
            loading={dLStatus}
          >
            <ATMDropdown.Menu>
              <ATMDropdown.Item
                content={
                  <span>
                    <ATMIcon name="mail" />
                    {Lang.LBL_DOWNLOAD_EMAIL_LIST}
                  </span>
                }
                onClick={() => handleRolesListEmailsExcelDownload()}
              />
              <ATMDropdown.Item
                content={
                  <span>
                    <ATMIcon name="file excel outline" />
                    {Lang.LBL_DOWNLOAD_EXCEL}
                  </span>
                }
                onClick={() => handleRolesListExcelDownload()}
              />
              <ATMDropdown.Item
                content={
                  <span>
                    <ATMIcon name="file pdf outline" />
                    {Lang.LBL_DOWNLOAD_PDF}
                  </span>
                }
                onClick={() => handleRolesListPDFDownload()}
              />
            </ATMDropdown.Menu>
          </ATMDropdown>
        </ATMGrid.Column>
        <ORGDataTable
          key={Object.values(filterState).toString()}
          columns={columns}
          data={data}
          loading={loading}
          className={style.notCelledTable}
          noDataText={
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          }
        />
      </div>
    </>
  );
};

export default RolesListView;
