import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactHTMLTableToExcel from 'react-html-table-to-excel-3';
import Moment, {
  dateInEventRange,
  IMoment,
} from 'src/libraries/moment.library';
import { IOutageDateCalendar } from 'src/models/calendar-outage.model';
import Lang from 'src/libraries/language';
import { outageTypeColors } from 'src/constants/outage-calendar.constant';
import { ICalendarEvent } from 'src/libraries/calendar.library';
import {
  ICalendarResource,
  getOutageCalendarEvents,
} from 'src/helpers/outage-dates.helper';
import { ATMButton } from 'shared-it-appmod-ui';
import { Portal } from 'semantic-ui-react';
import styles from './calendar-timeline.module.scss';

type ICalendarTimeline = {
  date?: IMoment | IMoment[];
  events: ICalendarEvent<IOutageDateCalendar>[];
  resources: ICalendarResource[];
};

const CalendarTimelineDownload: React.FC<ICalendarTimeline> = ({
  events,
  date,
  resources,
}) => {
  const iconRef = useRef<ReactHTMLTableToExcel>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    Array.isArray(date) ? Moment(date[0]) : Moment().startOf('month')
  );
  const [endDate, setEndDate] = useState(
    Array.isArray(date)
      ? Moment(date[1]).clone()
      : startDate.clone().add(startDate.clone().endOf('month').date(), 'day')
  );

  useEffect(() => {
    setStartDate(
      Array.isArray(date) ? Moment(date[0]) : Moment().startOf('month')
    );
    setEndDate(
      Array.isArray(date)
        ? Moment(date[1]).clone()
        : startDate.clone().add(startDate.clone().endOf('month').date(), 'day')
    );
  }, [date, setStartDate, setEndDate]);

  const calendar: IMoment[] = [];
  const tempDate = startDate.clone().subtract(1, 'day');
  const size = 25;

  while (tempDate.isBefore(endDate, 'day')) {
    calendar.push(tempDate.add(1, 'day').clone());
  }

  const totalMonths = endDate.diff(startDate, 'month') + 1;
  const calendarMonths = useMemo(
    () => calendar.map((val) => val.format('MMMM-YYYY')),
    [calendar]
  );

  const list = useMemo(
    () => getOutageCalendarEvents(resources, events, calendar),
    [events, resources, calendar]
  );

  useEffect(() => {
    if (isOpen && iconRef.current) {
      iconRef.current.handleDownload();
    }
  }, [isOpen]);

  return (
    <>
      <ReactHTMLTableToExcel
        ref={iconRef}
        className="hidden"
        table="table-to-xls"
        filename={`viewOutagePlan_${Moment().format('YYYYMMDD')}`}
        sheet="outagePlan"
      />
      <Portal
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={
          <ATMButton
            type="button"
            className="ui button secondary icon"
            icon="download"
          />
        }
      >
        <div className="hidden">
          <table
            id="table-to-xls"
            style={{ width: '100%', border: 'black 1px solid' }}
          >
            <thead>
              <tr>
                <th rowSpan={4}>
                  <b>KV</b>
                </th>
                <td rowSpan={4}>
                  <b>Job Location</b>
                  <br />
                  <span>
                    <small>BES Facility (red), Special Concerns (bold)</small>
                  </span>
                </td>
              </tr>
              <>
                <tr>
                  {Array(totalMonths)
                    .fill(null)
                    .map((_, index) => {
                      const month = startDate.clone().add(index, 'month');

                      return (
                        <th
                          key={index}
                          colSpan={
                            calendarMonths.filter(
                              (v) => v === month.format('MMMM-YYYY')
                            ).length
                          }
                          className={styles.borderRight}
                          align="left"
                        >
                          <div className={styles.monthYear}>
                            {month.format('MMMM YYYY')}
                          </div>
                        </th>
                      );
                    })}
                </tr>
                <tr>
                  {calendar.map((v) => (
                    <th
                      key={v.format('MM-DD')}
                      style={{ height: size }}
                      className={classNames(styles.day, {
                        [styles.cellDivider]: v.isSame(
                          v.clone().endOf('month'),
                          'day'
                        ),
                      })}
                    >
                      {v.format('D')}
                    </th>
                  ))}
                </tr>
                <tr>
                  {calendar.map((v) => (
                    <th
                      key={v.format('MM-DD')}
                      style={{ height: size }}
                      className={classNames({
                        [styles.cellDivider]: v.isSame(
                          v.clone().endOf('month'),
                          'day'
                        ),
                      })}
                    >
                      {calendar.length > 7
                        ? v.format('ddd').charAt(0).toUpperCase()
                        : v.format('dddd')}
                    </th>
                  ))}
                </tr>
              </>
            </thead>
            <tbody>
              {list.map((value, index) => {
                return (
                  <tr key={`${value.resourceId}_${index}`}>
                    <td
                      colSpan={!value.parentId ? 2 + calendar.length : 1}
                      style={{ border: 'black 1px solid' }}
                    >
                      {`${!value.parentId ? value.title : value.subTitle} KV`}
                    </td>
                    {value.parentId && (
                      <>
                        <td
                          style={{
                            border: 'black 1px solid',
                            color: `${value.besInd ? 'red' : ''}`,
                          }}
                        >
                          {value.specialConcerns ? (
                            <strong>{value.title}</strong>
                          ) : (
                            value.title
                          )}
                        </td>

                        {calendar.map((v, k) => {
                          const items = (value.levels || []).map((val, key) => {
                            return (
                              <table
                                key={`${k}_${key}`}
                                style={{ border: 'none' }}
                              >
                                <tbody>
                                  {val
                                    .filter(({ event }) =>
                                      dateInEventRange({
                                        event: {
                                          start: event.start,
                                          end: event.end,
                                        },
                                        range: {
                                          start: v.clone().startOf('day'),
                                          end: v.clone().endOf('day'),
                                        },
                                      })
                                    )
                                    .map(({ event }, i) => (
                                      <tr
                                        key={`${k}_${key}_${i}_${event.data?.requestId}`}
                                        style={{
                                          borderBottom: 'black 1px solid',
                                        }}
                                      >
                                        <td
                                          style={{
                                            textAlign: 'center',
                                            backgroundColor:
                                              event.data?.requestFacility
                                                ?.outgTypId &&
                                              outageTypeColors[
                                                event.data?.requestFacility
                                                  .outgTypId
                                              ],
                                          }}
                                        >
                                          {
                                            Lang.LER_REQUEST_STATUS_CODE[
                                              event.data?.status
                                                ?.outgReqStatusId ?? 0
                                            ]
                                          }
                                          {event.data?.significantCostInd ===
                                          'Y' ? (
                                            <sup>$</sup>
                                          ) : (
                                            <span />
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            );
                          });

                          if (items.length) {
                            return (
                              <td
                                key={`${v.format('YYYY-MM-DD')}_${k}`}
                                style={{
                                  border: 'black 1px solid',
                                  textAlign: 'center',
                                  fontSize: '0.8em',
                                  lineHeight: '1.5rem',
                                }}
                              >
                                {items}
                              </td>
                            );
                          }

                          return (
                            <td
                              style={{ border: 'black 1px solid' }}
                              key={`${v.format('YYYY-MM-DD')}_${k}`}
                            />
                          );
                        })}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Portal>
    </>
  );
};

export default CalendarTimelineDownload;
