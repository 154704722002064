import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import {
  ATMButton,
  ATMHeader,
  ATMGrid,
  ATMTable,
  ATMSelect,
  ATMField,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import FacilityDelete from '../facility-delete/facility-delete.component';
import styles from './facility-details.module.scss';

type IProps = {
  loading: boolean;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
};

const FacilityDetailsView: React.FC<IProps> = ({
  loading,
  setFacilityActions,
}) => {
  const { state } = useFacilityContext();

  const defaultValues = state.data;

  const sections = [
    {
      key: 'All Facilities',
      content: 'All Facilities >',
      link: true,
      onClick: () =>
        setFacilityActions({
          isNew: false,
          isDetails: false,
          isEdit: false,
        }),
    },
  ];
  return (
    <>
      <div className={styles.header}>
        <div className={styles.breadCrumbStyle}>
          <Breadcrumb icon="right angle" size="tiny" sections={sections} />
          <ATMHeader as="h1" style={{ marginTop: '0.5rem', paddingTop: 0 }}>
            {defaultValues?.outgFacNm}
          </ATMHeader>
        </div>
        <div className={styles.actionButtons}>
          <ATMButton
            secondary
            content={Lang.LBL_GO_BACK}
            onClick={() =>
              setFacilityActions({
                isNew: false,
                isDetails: false,
                isEdit: false,
              })
            }
          />
          <ATMButton
            primary
            content={Lang.LBL_EDIT}
            onClick={() =>
              setFacilityActions({
                isNew: false,
                isDetails: false,
                isEdit: true,
              })
            }
            loading={loading}
          />
          <FacilityDelete
            data={defaultValues}
            setFacilityActions={setFacilityActions}
          />
        </div>
      </div>

      <>
        <ATMGrid columns={4}>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_FACILITY_NAME}>
                <p>{defaultValues?.outgFacNm}</p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_FACILITY_STATUS}>
                <p>{defaultValues?.stat}</p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_VOLTAGE}>
                <p>{defaultValues?.volt?.voltNm}</p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_BES_FACILITY}>
                <p>
                  {defaultValues && defaultValues.besInd === 'Y' ? 'Yes' : 'No'}
                </p>
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_OFF_SYSTEM}>
                <p>
                  {defaultValues && defaultValues.offSysInd === 'Y'
                    ? 'Yes'
                    : 'No'}
                </p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_FACILITY_TYPE}>
                <p>{defaultValues?.facTyp?.facTypNm}</p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              <FieldReadOnly label={Lang.TTL_SUBSTATIONS}>
                {/* To be Modify */}
                <ATMField
                  as={ATMSelect}
                  name="substations"
                  selection
                  clearable
                  fluid
                  disabled
                  multiple
                  defaultValue={defaultValues?.substations}
                  placeholder="Select"
                  selectOnBlur={false}
                  options={defaultValues?.substations?.map((value) => ({
                    key: value.substationId,
                    value,
                    text: value.name,
                  }))}
                  onChange={([_, { value }]) => value}
                />
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_SPECIAL_CONCERNS}>
                <p>
                  {defaultValues?.spclOpsConcern
                    ? defaultValues?.spclOpsConcern
                    : '--'}
                </p>
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column width={8}>
              <FieldReadOnly label={Lang.LBL_BLACK_START}>
                <ATMField
                  as={ATMSelect}
                  name="blackstarts"
                  placeholder="Select"
                  selection
                  clearable
                  fluid
                  multiple
                  disabled
                  selectOnBlur={false}
                  defaultValue={defaultValues?.blackstarts}
                  options={defaultValues?.blackstarts?.map((value) => ({
                    key: value.blackstartId,
                    value,
                    text: value.blackstartValue,
                  }))}
                  onChange={([_, { value }]) => value}
                />
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
        <div style={{ marginTop: '70px' }}>
          <ATMHeader as="h3">{Lang.LBL_SWITCH_TR}</ATMHeader>
          {defaultValues?.isoSwitchings &&
          defaultValues?.isoSwitchings?.length > 0 ? (
            <ATMTable style={{ width: '50%' }}>
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.HeaderCell width={6}>
                    {Lang.LBL_SWITCH_TR}
                  </ATMTable.HeaderCell>
                  <ATMTable.HeaderCell textAlign="left" width={2}>
                    {Lang.LBL_DEFAULT}
                  </ATMTable.HeaderCell>
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {defaultValues?.isoSwitchings?.map((value) => (
                  <ATMTable.Row key={value.isoSwitchingId}>
                    <ATMTable.Cell>{value.isoSwitchingId}</ATMTable.Cell>
                    <ATMTable.Cell>
                      {value.defaultFlag ? 'Open' : 'Closed'}
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))}
              </ATMTable.Body>
            </ATMTable>
          ) : (
            <EmptyNotification content="" />
          )}
        </div>
        <ATMHeader as="h3">{Lang.TTL_CAISO_TR_IDS}</ATMHeader>
        {defaultValues?.isoTrs && defaultValues?.isoTrs?.length > 0 ? (
          <ATMTable style={{ width: '50%' }}>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell width={2}>
                  {Lang.TTL_CAISO_TR_IDS}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell textAlign="left" width={2}>
                  {Lang.LBL_DEFAULT}
                </ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {defaultValues?.isoTrs?.map((value) => (
                <ATMTable.Row key={value.isoTrId}>
                  <ATMTable.Cell>{value.isoTrId}</ATMTable.Cell>
                </ATMTable.Row>
              ))}
            </ATMTable.Body>
          </ATMTable>
        ) : (
          <EmptyNotification content="" />
        )}
        <ATMHeader as="h3">{Lang.TTL_EMS_IDS}</ATMHeader>
        {defaultValues?.emsFacs && defaultValues?.emsFacs?.length > 0 ? (
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell>{Lang.LBL_EMS_ID}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_TOP}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_STATION}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_BUS_NAME}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_EQUIPMENT_NAME}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_EQUIPMENT_TYPE}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_POSITION}</ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {defaultValues?.emsFacs?.map((value) => (
                <ATMTable.Row key={value.emsFacId}>
                  <ATMTable.Cell>{value.emsFacId}</ATMTable.Cell>
                  <ATMTable.Cell>{value.top}</ATMTable.Cell>
                  <ATMTable.Cell>{value.station}</ATMTable.Cell>
                  <ATMTable.Cell>{value.busNm}</ATMTable.Cell>
                  <ATMTable.Cell>{value.equipmentNm}</ATMTable.Cell>
                  <ATMTable.Cell>{value.equipmentTyp}</ATMTable.Cell>
                  <ATMTable.Cell>
                    {value.defaultFlag ? 'Open' : 'Closed'}
                  </ATMTable.Cell>
                </ATMTable.Row>
              ))}
            </ATMTable.Body>
          </ATMTable>
        ) : (
          <EmptyNotification content="" />
        )}
        <ATMHeader as="h3">
          {Lang.TTL_NOTIFICATION_BLOCK_SWITCHING_INFORMATION}
        </ATMHeader>
        {defaultValues?.switchings && defaultValues?.switchings?.length > 0 ? (
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell width={6}>
                  {Lang.LBL_SWITCH_DESCRIPTION}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell width={4}>
                  {Lang.LBL_POLE_ID}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_SUB_DISTRICT_ID}
                </ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {/* No Data??? */}
              {defaultValues &&
                defaultValues?.switchings.map((value) => (
                  <ATMTable.Row key={value.poleId}>
                    <ATMTable.Cell>{value.description}</ATMTable.Cell>
                    <ATMTable.Cell>{value.poleId}</ATMTable.Cell>
                    <ATMTable.Cell>{value.subDistId}</ATMTable.Cell>
                  </ATMTable.Row>
                ))}
            </ATMTable.Body>
          </ATMTable>
        ) : (
          <EmptyNotification content="" />
        )}
        <ATMHeader as="h3">
          {Lang.TTL_NOTIFICATION_BLOCK_NOTIFICATION_APPROVALS}
        </ATMHeader>
        {defaultValues?.userGrps && defaultValues?.userGrps.length > 0 ? (
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell width={6}>
                  {Lang.LBL_GROUP_NAME}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell width={4}>
                  {Lang.LBL_GROUP_EMAIL}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_DETAILS}</ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {defaultValues.userGrps.map((value, index) => (
                <ATMTable.Row key={index}>
                  <ATMTable.Cell>{value.userGrpNm}</ATMTable.Cell>
                  <ATMTable.Cell>{value.userGrpEmail}</ATMTable.Cell>
                  <ATMTable.Cell>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {value.userGrpMembers
                      ? // eslint-disable-next-line no-nested-ternary
                        value.userGrpMembers.length < 2
                        ? `${value.userGrpMembers[0]?.firstName} ${value.userGrpMembers[0]?.lastName}`
                        : value.userGrpMembers.length < 3
                        ? `${value.userGrpMembers[0]?.firstName} ${value.userGrpMembers[0]?.lastName}, ${value.userGrpMembers[1]?.firstName} ${value.userGrpMembers[1]?.lastName}`
                        : `${value.userGrpMembers[0]?.firstName} ${
                            value.userGrpMembers[0]?.lastName
                          }, ${value.userGrpMembers[1]?.firstName} ${
                            value.userGrpMembers[1]?.lastName
                          }, + ${value.userGrpMembers?.length - 2} More`
                      : '--'}
                  </ATMTable.Cell>
                </ATMTable.Row>
              ))}
            </ATMTable.Body>
          </ATMTable>
        ) : (
          <EmptyNotification content="" />
        )}
      </>

      <div className={styles.actionButtons}>
        <ATMButton
          secondary
          content="Go Back"
          onClick={() =>
            setFacilityActions({
              isNew: false,
              isDetails: false,
              isEdit: false,
            })
          }
        />
        <ATMButton
          primary
          content="Edit"
          onClick={() =>
            setFacilityActions({
              isNew: false,
              isDetails: false,
              isEdit: true,
            })
          }
          loading={loading}
        />
        <FacilityDelete
          data={defaultValues}
          setFacilityActions={setFacilityActions}
        />
      </div>
    </>
  );
};

export default FacilityDetailsView;
