import yup from 'src/libraries/validator.library';

export const RequestStatusShape = {
  requestStatusId: yup.number().required(),
  name: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const RequestStatusSchema = yup.object(RequestStatusShape);

export const RequestStatusListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RequestStatusSchema.clone()),
});

export const RequestStatusPayloadSchema = yup.object({
  requestStatus: RequestStatusSchema.clone(),
});

export type IRequestStatus = yup.Asserts<typeof RequestStatusSchema>;
