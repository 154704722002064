import React, { useCallback, useRef, useState } from 'react';
import { useUserGroupContext } from 'src/contexts/user-group.context';
import { userGroupActionTypes } from 'src/ducks/user-group.duck';
import { IUserGroup, IUserGroupForm } from 'src/models/user-group.model';
import { getUserGroupStatus } from 'src/selectors/user-group.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import UserGroupEditView from './user-group-edit.view';

type IProp = {
  data: IUserGroup;
};

const UserGroupEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useUserGroupContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getUserGroupStatus(
    state,
    userGroupActionTypes.USER_GROUP_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IUserGroupForm) => {
      const response = await actions.updatePUT(data.userGrpId, {
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsEditOpen(false);

        ToastSuccess(
          Lang.formatString(Lang.MSG_USER_GROUP_EDIT, formData.userGrpNm)
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <UserGroupEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default UserGroupEdit;
