import React from 'react';
import { ATMGrid } from 'shared-it-appmod-ui';
import { AccessType, LERRequestFormStep, LERRequestWidth } from 'src/constants';
import { hasAccess } from 'src/libraries/access.library';
import { ILerRequest } from 'src/models/ler-request.model';
import LERRequestAuthorization from '../ler-request-authorization/ler-request-authorization.component';
import LERRequestCrewInformation from '../ler-request-crew-information/ler-request-crew-information.component';
import LERRequestIsoDetail from '../ler-request-iso/ler-request-iso-detail/ler-request-iso-detail.component';
import LERRequestJobInformation from '../ler-request-job-information/ler-request-job-information.component';
import LERRequestPanel from '../ler-request-panel/ler-request-panel.component';
import styles from './ler-request-preview.module.scss';

type IProps = {
  data: ILerRequest;
  handleStep: (step: LERRequestFormStep) => void;
};

const LERRequestPreview: React.FC<IProps> = ({ data, handleStep }) => {
  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <div className={styles.container}>
          <LERRequestJobInformation
            data={data[LERRequestFormStep.JOB_INFORMATION]}
            handleEdit={() => handleStep(LERRequestFormStep.JOB_INFORMATION)}
            isView
          />

          <LERRequestCrewInformation
            data={data[LERRequestFormStep.CREW_INFORMATION]}
            handleEdit={() => handleStep(LERRequestFormStep.CREW_INFORMATION)}
          />

          {hasAccess(AccessType.OUTAGE_REQUEST_AUTH_STATEMENT) && (
            <LERRequestAuthorization
              data={data}
              handleEdit={() =>
                handleStep(LERRequestFormStep.AUTHORIZATION_STATEMENT)
              }
            />
          )}

          {hasAccess(AccessType.OUTAGE_REQUEST_ISO) && (
            <LERRequestIsoDetail
              data={data}
              handleEdit={() => handleStep(LERRequestFormStep.ISO)}
            />
          )}
        </div>
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <LERRequestPanel data={data} handleEdit={handleStep} />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestPreview;
