import React, { useCallback } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';

const ContractorFirmDownload: React.FC = () => {
  const { state, actions } = useContractorFirmContext();
  const status = getContractorFirmStatus(
    state,
    contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_DOWNLOAD
  );

  const handleDownload = useCallback(async () => {
    const result = await actions.downloadListGET({
      limit: 0,
    });

    if (result.payload?.rows) {
      const list = result.payload.rows;
      const items = list.map((value) => [
        checkValue(value.contracShortNm),
        checkValue(value.fullName),
        checkValue(value.contactName),
        checkValue(value.areaCode),
        checkValue(value.phoneNumber),
      ]);

      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME,
          Lang.LBL_CONTRACTOR_FIRM_FULL_NAME,
          Lang.LBL_CONTRACTOR_FIRM_CONTACT,
          Lang.LBL_CONTRACTOR_FIRM_AREA_CODE,
          Lang.LBL_CONTRACTOR_FIRM_PHONE_NUMBER,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Contractor_Firm_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state]);

  return (
    <ATMButton
      type="button"
      key="download"
      secondary
      content="Download"
      onClick={handleDownload}
      loading={status.fetching}
    />
  );
};

export default ContractorFirmDownload;
