import moment from 'moment';
import React, { useState } from 'react';
import {
  ATMGrid,
  ATMSegment,
  ATMHeader,
  ATMIcon,
  ATMButton,
} from 'shared-it-appmod-ui';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { AccessRole } from 'src/constants';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import history from 'src/history';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IRolesEmployee } from 'src/models/roles.model';
import {
  ITroubleTicketPayload,
  ITroubleTicket,
  TroubleTicketFormSchema,
} from 'src/models/trouble-ticket.model';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import ForwardTicket from '../../forward-ticket/forward-ticket.component';
import { ViewType } from '../trouble-ticket-document/trouble-ticket-document-details.component';
import TroubleTicketForm from '../trouble-ticket-form/trouble-ticket-form.component';
import styles from '../trouble-ticket.module.scss';

type IProps = {
  defaultValues: ITroubleTicketPayload;
  handleClick: () => void;
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: ITroubleTicket) => void;
  assignToEmp: IRolesEmployee[];
  statusEmployeeRole: boolean;
};

const TroubleTicketEditView: React.FC<IProps> = ({
  defaultValues,
  formRef,
  handleClick,
  handleSubmit,
  assignToEmp,
  statusEmployeeRole,
}) => {
  const isViewMode =
    hasRole([
      AccessRole.TROUBLE_TICKET_RESOLVER,
      AccessRole.TROUBLE_TICKET_REQUESTOR,
    ]) === false;
  const [isDirty, setIsDirty] = useState(false);
  const { state } = useTroubleTicketContext();

  const updateStatus = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE
  );

  return (
    <div className="admin-container">
      <ATMSegment className={`applySegment ${styles.segmentStyled}`}>
        <ATMGrid>
          <ATMGrid.Column width={8}>
            <ATMHeader className={styles.headerIcon}>
              <ATMIcon
                circular
                size="small"
                color="blue"
                name="list"
                style={{ fontSize: '0.8em' }}
              />
              <span style={{ marginLeft: '0.5rem' }}>
                {Lang.LBL_TROUBLE_TICKET_ID}. {defaultValues?.troubleTicketId}
              </span>
              <span
                style={{
                  fontSize: '1.1rem',
                  marginLeft: '0.5rem',
                }}
              >
                <span className={styles.listStatus}>
                  <ListStatus
                    value={defaultValues?.ticketStatus}
                    label
                    inline
                  />
                </span>
              </span>
            </ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column width={8} textAlign="right">
            {defaultValues?.ticketStatus === 'Closed' ? (
              <ATMButton
                content={
                  defaultValues.etrDt ? Lang.LBL_REACTIVATE : Lang.LBL_REOPEN
                }
                primary
                onClick={() => {
                  if (
                    moment(new Date()).diff(
                      moment(defaultValues.closedDt),
                      'days'
                    ) > 7
                  ) {
                    ToastError(
                      "The Trouble Ticket is already past 7 days. It can't be reactivated."
                    );
                  } else {
                    handleClick();
                  }
                }}
                loading={updateStatus.fetching}
                disabled={updateStatus.fetching}
              />
            ) : (
              <ATMButton
                disabled={!isDirty || updateStatus.fetching}
                content={Lang.LBL_SAVE_UPDATES}
                primary
                loading={updateStatus.fetching}
                onClick={handleClick}
              />
            )}
            <ATMButton
              content={Lang.LBL_GO_BACK}
              secondary
              onClick={() => {
                history.goBack();
              }}
              icon="arrow left"
              size="small"
              style={history.length === 1 ? { display: 'none' } : {}}
            />
            {defaultValues?.ticketStatus === 'Active' ? (
              <ATMButton
                content={<ForwardTicket data={defaultValues} />}
                secondary
                size="small"
              />
            ) : undefined}
          </ATMGrid.Column>
        </ATMGrid>
      </ATMSegment>
      <TroubleTicketForm
        view={false}
        action="edit"
        defaultValues={{
          ...defaultValues,
          commentsResolution: ' ',
        }}
        formRef={formRef}
        handleSubmit={handleSubmit}
        handleEnable={(value) => setIsDirty(value)}
        validationSchema={TroubleTicketFormSchema}
        assignToEmp={assignToEmp}
        statusEmployeeRole={statusEmployeeRole}
        isViewMode={isViewMode}
        viewType={ViewType.EDIT}
      />
    </div>
  );
};

export default TroubleTicketEditView;
