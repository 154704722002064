import {
  contractorFirmActionTypes,
  IContractorFirmState,
} from 'src/ducks/contractor-firm.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getContractorFirmStatus = (
  state: IContractorFirmState,
  action: keyof typeof contractorFirmActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
