import React, { useCallback, useRef, useState } from 'react';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { workGroupActionTypes } from 'src/ducks/work-group.duck';
import { IWorkGroupForm } from 'src/models/work-group.model';
import { getWorkGroupStatus } from 'src/selectors/work-group.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import WorkGroupAddView from './work-group-add.view';

const WorkGroupAdd: React.FC = () => {
  const { state, actions } = useWorkGroupContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getWorkGroupStatus(
    state,
    workGroupActionTypes.WORK_GROUP_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IWorkGroupForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsAddOpen(false);

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_WORK_GROUP_ADD,
            response.payload?.trbGrpNm ?? ''
          )
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <WorkGroupAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default WorkGroupAdd;
