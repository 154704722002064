import React, { useEffect, useState } from 'react';
import { Label } from 'semantic-ui-react';
import {
  ATMCheckbox,
  ATMDivider,
  ATMSelect,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMTextArea,
  useATMFormContext,
  ATMForm,
} from 'shared-it-appmod-ui';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  AccessRole,
  AuthorizationType,
  FacilityType,
  LERRequestWidth,
} from 'src/constants';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useUserGroupContext } from 'src/contexts/user-group.context';
import { getStartEngineerList } from 'src/helpers/employee.helper';
import { hasRole } from 'src/libraries/access.library';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import { IFileSharePoint } from 'src/models/file.model';
import {
  ILerRequestForm,
  ILerRequestRadializedSubstation,
} from 'src/models/ler-request.model';
import LerRequestAddDocumentsDetail from '../../ler-request-documents/ler-request-add-documents-detail.component';
import LerRequestDocumentsDetail from '../../ler-request-documents/ler-request-documents-detail.component';
import LERRequestPanel from '../../ler-request-panel/ler-request-panel.component';
import LERRequestJobInformationDescription from './ler-request-job-information-description/ler-request-job-information-description.component';
import style from './ler-request-job-information-form.module.scss';

type IProps = {
  data?: IFileSharePoint[];
  isEdit?: boolean;
  id?: number;
  isSubmitted?: boolean;
  viewType?: string;
  setIsFileCached?: (params: boolean) => void;
  setIsUpdateDone?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdateDone?: boolean;
};

const boolOptions = [
  { key: 'yes', text: 'Yes', value: true },
  { key: 'no', text: 'No', value: false },
];

const LERRequestJobInformationForm: React.FC<Partial<IProps>> = ({
  isEdit = false,
  id,
  isSubmitted = false,
  viewType,
  setIsFileCached,
  isUpdateDone,
  setIsUpdateDone,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useATMFormContext<ILerRequestForm>();

  const [radializedSubs, setRadializedSubs] = useState(
    (
      (getValues('jobInformation.radializedSubs') ||
        []) as unknown as ILerRequestRadializedSubstation[]
    ).map((v) => v.substationId)
  );

  const [contingencyPlan, setContingencyPlan] = useState<boolean | undefined>(
    getValues('jobInformation.contingencyPlanInd') as boolean
  );

  const [isSharepointError, setIsSharepointError] = useState<boolean>(false);

  const { actions: userGroupAction } = useUserGroupContext();

  useEffect(() => {
    userGroupAction.startEngineerListGET();
  }, [userGroupAction]);

  const facilityType = watch('outageFacility.substationId')
    ? FacilityType.Station
    : FacilityType.Line;
  const outgTypId = watch('outageFacility.outgTypId') as AuthorizationType;
  const outgFacId = watch('outageFacility.outgFacId');

  const { state: substationState, actions: substationActions } =
    useSubstationContext();

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <div className={style.formContent}>
          <ATMGrid columns="equal">
            <ATMGrid.Row>
              <ATMGrid.Column>
                <Label attached="bottom">
                  <ATMHeader as="h2" content={Lang.TTL_JOB_INFO} />
                </Label>
              </ATMGrid.Column>
            </ATMGrid.Row>
            {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
              <>
                <ATMGrid.Row columns="2">
                  <ATMGrid.Column width={4}>
                    <ATMField
                      as={ATMInput}
                      type="number"
                      name="jobInformation.switchOutMin"
                      control={control}
                      label={Lang.LBL_SWITCH_OUT}
                      error={errors.jobInformation?.switchOutMin}
                      readOnly={outgFacId === 18365}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <ATMField
                      as={ATMInput}
                      type="number"
                      name="jobInformation.switchInMin"
                      control={control}
                      label={Lang.LBL_SWITCH_IN}
                      error={errors.jobInformation?.switchInMin}
                      readOnly={outgFacId === 18365}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>

                <ATMDivider className="jobInformation.divWidth" />
              </>
            ) : (
              <>
                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="jobInformation.switchOutMin"
                  control={control}
                />

                <ATMField
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  name="jobInformation.switchInMin"
                  control={control}
                />
              </>
            )}

            <ATMGrid.Row>
              <ATMGrid.Column tablet={16} largeScreen={12}>
                <ATMHeader as="h2" content={Lang.LBL_LER_DOCUMENTS} />
                <FileUploader
                  icon="upload"
                  onSuccess={() => {
                    if (setIsFileCached) {
                      setIsFileCached(true);
                    }
                  }}
                  text={Lang.LBL_UPLOAD}
                  successMessage="Your file(s) has been cached, but will not be uploaded until the LER has been Created/Submitted"
                  errorMessage="Failed to cache file(s)"
                  displayRemoveButton={false}
                  hasSharePointError={isSharepointError}
                  popOverPostition="right center"
                  popOverStyle={{ color: 'red', width: '200px' }}
                />
                <LerRequestAddDocumentsDetail tab="jobInformation" />
                {isEdit ? (
                  <>
                    <br /> <br />
                    <LerRequestDocumentsDetail
                      isEdit={isEdit}
                      isSubmitted={isSubmitted}
                      id={id}
                      viewType={viewType}
                      isUpdateDone={isUpdateDone}
                      setIsUpdateDone={setIsUpdateDone}
                      setIsSharepointError={setIsSharepointError}
                    />
                  </>
                ) : (
                  ''
                )}
                <ATMDivider className="jobInformation.divWidth" />
              </ATMGrid.Column>
            </ATMGrid.Row>

            <ATMGrid.Row>
              <ATMGrid.Column tablet={16} largeScreen={12}>
                <LERRequestJobInformationDescription />
              </ATMGrid.Column>
            </ATMGrid.Row>

            <ATMGrid.Row columns="2">
              <ATMGrid.Column tablet={8} largeScreen={6}>
                <ATMField
                  control={control}
                  as={ATMInput}
                  type="number"
                  name="jobInformation.woNbr"
                  label={Lang.LBL_WORK_ORD_NO}
                  error={errors.jobInformation?.woNbr}
                />
              </ATMGrid.Column>
              <ATMGrid.Column tablet={8} largeScreen={6}>
                <ATMField
                  control={control}
                  as={ATMInput}
                  name="jobInformation.projectId"
                  label={Lang.LBL_PROJECT_ID}
                  error={errors.jobInformation?.projectId}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column tablet={16} largeScreen={12}>
                <ATMField
                  control={control}
                  as={ATMTextArea}
                  name="jobInformation.wrkDesc"
                  label={<LabelRequired>{Lang.LBL_WORK_DESC}</LabelRequired>}
                  error={errors.jobInformation?.wrkDesc}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column tablet={16} largeScreen={12}>
                <ATMField
                  control={control}
                  as={ATMTextArea}
                  name="jobInformation.jobLoc"
                  label={
                    facilityType === FacilityType.Line ? (
                      <LabelRequired>{Lang.LBL_JOB_LOC}</LabelRequired>
                    ) : (
                      Lang.LBL_JOB_LOC
                    )
                  }
                  error={errors.jobInformation?.jobLoc}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
          <ATMGrid columns={2}>
            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMTextArea}
                name="jobInformation.jobNotes"
                label={Lang.LBL_JOB_NOTES}
                error={errors.jobInformation?.jobNotes}
              />
            </ATMGrid.Column>
            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMTextArea}
                name="jobInformation.isolationPoints"
                label={Lang.LBL_ISOLATION_POINTS}
                error={errors.jobInformation?.isolationPoints}
              />
            </ATMGrid.Column>

            {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
              <>
                <ATMGrid.Column tablet={8} largeScreen={6}>
                  <ATMField
                    control={control}
                    as={ATMInput}
                    name="jobInformation.radializedSubs"
                    type="hidden"
                    className="hidden"
                  />
                  <ATMForm.Field>
                    <label>{Lang.LBL_RADIALIZED_SUBS}</label>
                    <ATMSelect
                      options={substationState.list.map((value) => ({
                        key: value.substationId,
                        value: value.substationId,
                        text: value.name,
                      }))}
                      search
                      fluid
                      multiple
                      value={radializedSubs}
                      onChange={(_, { value }) => {
                        const defaultValue =
                          getValues('jobInformation')?.radializedSubs;

                        const list = value.map((substationId) => {
                          const exist = (defaultValue || []).find(
                            (v) => v.substationId === substationId
                          );

                          if (exist) {
                            return exist;
                          }

                          return {
                            substationId,
                          };
                        });

                        setValue('jobInformation.radializedSubs', list);
                        setRadializedSubs(value);
                      }}
                      clearable
                    />
                  </ATMForm.Field>
                </ATMGrid.Column>

                <ATMGrid.Column tablet={8} largeScreen={6}>
                  <ATMField
                    as={ATMCheckbox}
                    control={control}
                    name="jobInformation.splitSaidiCalcInd"
                    style={{ marginTop: '0.5rem' }}
                    label={Lang.LBL_SPLIT_SAIDI_CAL}
                    error={errors.jobInformation?.splitSaidiCalcInd}
                    onChange={([_, { checked }]) => checked}
                  />
                </ATMGrid.Column>
              </>
            ) : (
              <ATMGrid.Column tablet={8} largeScreen={6}>
                <ATMField
                  as={ATMInput}
                  control={control}
                  name="jobInformation.radializedSubs"
                  type="hidden"
                  className="hidden"
                />

                <ATMField
                  as={ATMInput}
                  control={control}
                  name="jobInformation.splitSaidiCalcInd"
                  type="hidden"
                  className="hidden"
                />
              </ATMGrid.Column>
            )}

            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMInput}
                type="number"
                name="jobInformation.rimsProjectId"
                label={
                  hasRole([
                    AccessRole.MCC_OUTAGE_REQUESTOR,
                    AccessRole.MCC_SUBMITTING_SUPERVISOR,
                    AccessRole.MCC_OPERATIONAL_ENGINEER,
                  ]) &&
                  getStartEngineerList().find(
                    (v) => v.empId === getUser()?.emp_no
                  ) ? (
                    <LabelRequired>{Lang.LBL_RIMS_PROJECT_ID}</LabelRequired>
                  ) : (
                    Lang.LBL_RIMS_PROJECT_ID
                  )
                }
                error={errors.jobInformation?.rimsProjectId}
              />
            </ATMGrid.Column>
            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMInput}
                type="number"
                name="jobInformation.rimsProjectPhase"
                label={Lang.LBL_RIMS_PROJECT_PHASE}
                error={errors.jobInformation?.rimsProjectPhase}
              />
            </ATMGrid.Column>
            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMInput}
                name="jobInformation.facTbsCoord"
                label={Lang.LBL_TB_COORDINATE}
                error={errors.jobInformation?.facTbsCoord}
              />
            </ATMGrid.Column>
            {[AuthorizationType.Hold].includes(outgTypId) && (
              <ATMGrid.Column tablet={8} largeScreen={6}>
                <ATMField
                  control={control}
                  as={ATMSelect}
                  name="jobInformation.clsdGrndDsconctInd"
                  options={boolOptions}
                  fluid
                  label={
                    outgTypId === AuthorizationType.Hold ? (
                      <LabelRequired>{Lang.LBL_GROUND_STATEMENT}</LabelRequired>
                    ) : (
                      Lang.LBL_GROUND_STATEMENT
                    )
                  }
                  error={errors.jobInformation?.clsdGrndDsconctInd}
                  onChange={([_, { value }]) => value}
                  selectOnBlur={false}
                  clearable
                />
              </ATMGrid.Column>
            )}
            <ATMGrid.Column tablet={8} largeScreen={6}>
              <ATMField
                control={control}
                as={ATMInput}
                name="jobInformation.recalTm"
                placeholder="Hours"
                type="number"
                label={
                  outgTypId !== AuthorizationType.InfoOnly ? (
                    <LabelRequired>{Lang.LBL_RECALL_TIME}</LabelRequired>
                  ) : (
                    Lang.LBL_RECALL_TIME
                  )
                }
                error={errors.jobInformation?.recalTm}
                onChange={([_, { value }]) => {
                  setImmediate(() =>
                    setValue(
                      'jobInformation.contingencyPlanInd',
                      Number(value) < 5 ? undefined : contingencyPlan,
                      {
                        shouldValidate: Number(value) < 5,
                      }
                    )
                  );
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column
              tablet={8}
              largeScreen={6}
              className={style.contingency}
            >
              <ATMField
                as={ATMCheckbox}
                control={control}
                name="jobInformation.contingencyPlanInd"
                style={{ marginTop: '0.5rem' }}
                label={Lang.LBL_CONTINGENCY_PLAN_DEVELOPED}
                error={errors.jobInformation?.contingencyPlanInd}
                onChange={([_, { checked }]) => {
                  setContingencyPlan(checked);
                  return checked;
                }}
                disabled={!(Number(watch('jobInformation.recalTm')) >= 5)}
              />
            </ATMGrid.Column>
            {[
              AuthorizationType.Hold,
              AuthorizationType.OkStartProgram,
            ].includes(outgTypId) && (
              <ATMGrid.Column tablet={8} largeScreen={6}>
                <ATMField
                  control={control}
                  as={ATMSelect}
                  name="jobInformation.testVoltageApplied"
                  options={boolOptions}
                  fluid
                  label={
                    [
                      AuthorizationType.OkStartProgram,
                      AuthorizationType.Hold,
                    ].includes(outgTypId) ? (
                      <LabelRequired>{Lang.LBL_TEST_VOLTAGE}</LabelRequired>
                    ) : (
                      Lang.LBL_TEST_VOLTAGE
                    )
                  }
                  error={errors.jobInformation?.testVoltageApplied}
                  onChange={([_, { value }]) => value}
                  selectOnBlur={false}
                  clearable
                />
              </ATMGrid.Column>
            )}
          </ATMGrid>
          <ATMGrid>
            <ATMGrid.Row columns="2">
              <ATMGrid.Column width={8}>
                <ATMField
                  control={control}
                  as={ATMCheckbox}
                  name="jobInformation.inclmntWthrInd"
                  label={Lang.LBL_WORK_PERMISSION}
                  error={errors.jobInformation?.inclmntWthrInd}
                  onChange={([_, { checked }]) => checked}
                  inline
                />
                <ATMField
                  control={control}
                  as={ATMCheckbox}
                  name="jobInformation.phsReqInd"
                  label={Lang.LBL_PHASING_REQ}
                  error={errors.jobInformation?.phsReqInd}
                  onChange={([_, { checked }]) => checked}
                  inline
                />
                <ATMField
                  control={control}
                  as={ATMCheckbox}
                  name="jobInformation.affectsRasSpsInd"
                  label={Lang.LBL_RAS_SPS}
                  error={errors.jobInformation?.affectsRasSpsInd}
                  onChange={([_, { checked }]) => checked}
                  inline
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <LERRequestPanel data={getValues()} />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestJobInformationForm;
