import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import KeyRequestReducer, {
  IKeyRequestState,
  defaultState,
  duckActions,
} from 'src/ducks/key-request.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    KeyRequestReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IKeyRequestContext = ReturnType<typeof useReducer>;

const KeyRequestContext = createContext<Partial<IKeyRequestContext>>({
  state: defaultState,
}) as React.Context<IKeyRequestContext>;

type IProps = {
  state?: Partial<IKeyRequestState>;
};

const KeyRequestProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <KeyRequestContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </KeyRequestContext.Provider>
  );
};

const useKeyRequestContext = () => useContext(KeyRequestContext);

export type IUseKeyRequestContext = ReturnType<typeof useKeyRequestContext>;

export { KeyRequestContext, KeyRequestProvider, useKeyRequestContext };
