import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/system-change.service';
import { ISystemChange } from 'src/models/system-change.model';

export const systemChangeActionTypes = {
  SYSTEM_CHANGE_DATA_READ: 'SYSTEM_CHANGE_DATA_READ',
  SYSTEM_CHANGE_LIST_READ: 'SYSTEM_CHANGE_LIST_READ',
  SYSTEM_CHANGE_FIND_WORK_DESC: 'SYSTEM_CHANGE_FIND_WORK_DESC',
  SYSTEM_CHANGE_START_DATE_FLAG_SET: 'SYSTEM_CHANGE_START_DATE_FLAG_SET',
  SYSTEM_CHANGE_COMPLETE_UPDATE: 'SYSTEM_CHANGE_COMPLETE_UPDATE',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: systemChangeActionTypes.SYSTEM_CHANGE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ,
    service: services.listGET,
  },

  dataPUT: {
    type: systemChangeActionTypes.SYSTEM_CHANGE_COMPLETE_UPDATE,
    service: services.dataPUT,
  },

  setList: (rows = []) => ({
    type: systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ,
    payload: {
      rows,
      count: rows.length,
    },
  }),
  findWorkDesc: (workDesc: string) => ({
    type: systemChangeActionTypes.SYSTEM_CHANGE_FIND_WORK_DESC,
    payload: workDesc,
  }),
  setStartDateFlag: (startDateFlag: boolean) => ({
    type: systemChangeActionTypes.SYSTEM_CHANGE_START_DATE_FLAG_SET,
    payload: startDateFlag,
  }),
};

export type ISystemChangeAsync = typeof duckActions;

export interface ISystemChangeState
  extends ICommonState<typeof systemChangeActionTypes> {
  data?: ISystemChange;
  list: ISystemChange[];
  matchedWorkDescList: ISystemChange[];
  total: number;
  startDateFlag: boolean;
}

export const defaultState: ISystemChangeState = {
  status: {},
  list: [],
  matchedWorkDescList: [],
  total: 0,
  startDateFlag: false,
};

const SystemChangeReducer = (
  state: ISystemChangeState,
  action: IReducerAction<ISystemChangeAsync>
): ISystemChangeState => {
  switch (action.type) {
    case systemChangeActionTypes.SYSTEM_CHANGE_DATA_READ: {
      return {
        ...state,
        data: action.payload?.systemChange,
      };
    }

    case systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
        matchedWorkDescList: action.payload?.rows ?? [],
      };
    }

    case systemChangeActionTypes.SYSTEM_CHANGE_FIND_WORK_DESC: {
      const keyword = action?.payload ?? '';
      let matchedList: any[] = [];
      if (keyword) {
        const regex = new RegExp(keyword, 'gi');
        matchedList = state.list.filter(
          (val) => val.wrkDesc?.toLowerCase()?.search(regex) !== -1
        );
      }

      return {
        ...state,
        matchedWorkDescList: keyword?.length > 0 ? matchedList : state.list,
      };
    }

    case systemChangeActionTypes.SYSTEM_CHANGE_START_DATE_FLAG_SET: {
      return {
        ...state,
        startDateFlag: action.payload ?? false,
      };
    }

    case systemChangeActionTypes.SYSTEM_CHANGE_COMPLETE_UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default SystemChangeReducer;
