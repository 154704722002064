import { AuthorizationType } from './ler-request.constant';

export const outageTypeColors = {
  [AuthorizationType.Clearance]: '#008a00',
  [AuthorizationType.Hold]: '#ffc4c4',
  [AuthorizationType.HotLineOrder]: '#ff7722',
  [AuthorizationType.OkOrNear]: '#ff0d0d',
  [AuthorizationType.Ok]: '#ff7777',
  [AuthorizationType.Unavailable]: '#a7a9ac',
  [AuthorizationType.OkStartProgram]: '#faf305',
  [AuthorizationType.InfoOnly]: '#0000cc',
  [AuthorizationType.LimitedClearance]: '#b0ffb0',
  [AuthorizationType.External]: '#9f9fff',
  [AuthorizationType.InterCoClearance]: '#00df00',
  [AuthorizationType.Restricted]: '#ab54d6',
};
