import React, { useCallback } from 'react';
import { OmsActions } from 'src/constants';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { useOmsRequestContext } from 'src/contexts/oms-request.context';
import { omsRequestActionTypes } from 'src/ducks/oms-request.duck';
import { getOmsRequestStatus } from 'src/selectors/oms-request.selector';
import { IOmsRequestSubmitRefreshResponse } from 'src/models/oms-request.model';
import OmsRequestAddView from './oms-request-add.view';

type IProp = {
  checkboxes: string[];
  setCheckboxes: React.Dispatch<React.SetStateAction<string[]>>;
  handleOmsResponse: React.Dispatch<
    React.SetStateAction<IOmsRequestSubmitRefreshResponse[]>
  >;
};

const OmsRequestAdd: React.FC<IProp> = ({
  checkboxes,
  handleOmsResponse,
  setCheckboxes,
}) => {
  const { state, actions } = useOmsRequestContext();

  const status = getOmsRequestStatus(
    state,
    omsRequestActionTypes.OMS_REQUEST_DATA_SEND
  );

  const handleSubmit = useCallback(
    async (ids) => {
      let cancellationOmsIds: [] = [];
      let submissionLerIds: [] = [];
      if (ids.length > 0) {
        cancellationOmsIds = ids
          .filter((item) => item.includes(OmsActions.CANCEL))
          .map((item) => {
            const splitItem = item.split('_');
            return Number(splitItem[2]);
          });
        submissionLerIds = ids
          .filter(
            (item) =>
              item.includes(OmsActions.SEND) ||
              item.includes(OmsActions.RESUBMIT)
          )
          .map((item) => {
            const splitItem = item.split('_');
            const submitObj = {
              id: Number(splitItem[2]),
              startTm: Moment(splitItem[3]).toDate(),
              stopTm: Moment(splitItem[4]).toDate(),
            };
            return submitObj;
          });
      }
      const response = await actions.sendPOST({
        cancellationOmsIds,
        submissionLerIds,
        withdrawalOmsIds: undefined,
      });

      if (response.payload) {
        handleOmsResponse(response.payload);
        setCheckboxes([]);
        actions.listGET(
          sessionStorage.getItem(`${Lang.TTL_OMS_REQUEST}_Date`)
            ? {
                page: 1,
                limit: 0,
                action: 'getOmsRequests',
                filters: [
                  {
                    name: 'date',
                    value: new Date(
                      sessionStorage.getItem(`${Lang.TTL_OMS_REQUEST}_Date`) ??
                        new Date()
                    ),
                  },
                ],
              }
            : {
                page: 1,
                limit: 0,
                action: 'getOmsRequests',
                filters: [
                  {
                    name: 'date',
                    value: new Date(),
                  },
                ],
              }
        );
      }
    },
    [actions]
  );

  return (
    <OmsRequestAddView
      loading={status.fetching}
      checkboxes={checkboxes}
      handleSubmit={handleSubmit}
    />
  );
};

export default OmsRequestAdd;
