import React, { useCallback } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMInput,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import Lang from 'src/libraries/language';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import { IFacilityFormValidation } from 'src/models/facility.model';

const NotificationTableCaiso: React.FC = () => {
  const { control } = useATMFormContext<IFacilityFormValidation>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'isoTrs',
  });

  const handleAddIso = useCallback(() => {
    append({ isoTrId: '', outgFacId: undefined } as any);
  }, [append]);
  return (
    <div style={{ width: '100%' }}>
      {fields.length > 0 ? (
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell width={6}>
                {Lang.LBL_CAISO}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((item, i) => (
              <ATMTable.Row key={item.id}>
                <ATMTable.Cell width={6}>
                  <ATMField
                    as={ATMInput}
                    name={`isoTrs[${i}].isoTrId`}
                    control={control}
                    placeholder="Enter"
                  />
                </ATMTable.Cell>
                <ATMTable.Cell textAlign="right">
                  <ATMButton
                    secondary
                    negative
                    icon="trash alternate outline"
                    type="button"
                    onClick={() => remove(i)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
          <ATMTable.Footer>
            <ATMTable.Cell colSpan={3}>
              <ATMButton
                secondary
                className="borderless"
                icon="plus"
                content="Add More"
                onClick={handleAddIso}
              />
            </ATMTable.Cell>
          </ATMTable.Footer>
        </ATMTable>
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              icon="plus"
              secondary
              content="Add"
              onClick={handleAddIso}
            />
          }
        />
      )}
    </div>
  );
};

export default NotificationTableCaiso;
