import React, { useCallback, useRef, useState } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useOmsAuditReportContext } from 'src/contexts/oms-audit-report.context';
import { omsAuditReportActionTypes } from 'src/ducks/oms-audit-report.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getOmsAuditReportStatus } from 'src/selectors/oms-audit-report.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import OMSAuditView from './oms-audit.view';

const OMSAudit: React.FC = () => {
  const { state, actions } = useOmsAuditReportContext();
  const [queryParams, setQueryParams] = useState<Record<any, any>>({});
  const [showNoRecord, setShowNoRecord] = useState<boolean>(false);
  const loading = getOmsAuditReportStatus(
    state,
    omsAuditReportActionTypes.OMS_AUDIT_REPORT_LIST_READ
  ).fetching;

  const queryParamRef = useRef<Record<any, any>>({});
  queryParamRef.current = queryParams;

  const handleSearch = useCallback(async () => {
    const response = await actions.listGET(queryParamRef.current);
    if (!response.error && response.payload?.rows?.length === 0) {
      setShowNoRecord(true);
    } else if (response.error) {
      ToastError('Error retrieving OMS Audit records.');
    }
  }, [actions]);

  const handleFetch = useCallback(
    async (params: IORGDataTableQueryState) => {
      const response = await actions.listGET({
        ...params,
        ...queryParamRef.current,
      });

      if (!response.error && response.payload?.rows?.length === 0) {
        setShowNoRecord(true);
      } else if (response.error) {
        ToastError('Error retrieving OMS Audit records.');
      }
    },
    [actions]
  );

  const handleDownload = useCallback(async () => {
    const result = await actions.listGET({
      ...queryParamRef.current,
      page: 1,
      limit: 0,
    });
    if (result.payload?.rows) {
      const list = result.payload.rows;

      const items = list.map((value) => [
        checkValue(`${Moment(value.updatedAt).format('M/DD/YYYY HH:mm')} `),
        checkValue(value.updatedByFullName),
        checkValue(value.requestId),
        checkValue(value.omsOutageId),
        checkValue(value.auditEventDesc),
        checkValue(value.parsedAuditValue),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_UPDATE_DATE,
          Lang.LBL_UPDATER,
          Lang.LBL_LER,
          Lang.LBL_OMS_OUTAGE_ID,
          Lang.LBL_EVENT,
          Lang.LBL_EVENT_VALUE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute('download', `omsAuditReport.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state, queryParams]);

  return (
    <OMSAuditView
      data={state.list}
      loading={loading}
      total={state.total}
      queryParams={queryParams}
      handleQueryParams={setQueryParams}
      handleDownload={handleDownload}
      handleFetch={handleFetch}
      handleSearch={handleSearch}
      showNoRecord={showNoRecord}
      setShowNoRecord={setShowNoRecord}
    />
  );
};

export default OMSAudit;
