import React, { useCallback, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { checkValue, checkValueArr } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import FacilityListView from './facility-list.view';

type IProps = {
  facilityActions: {
    isNew: boolean;
    isDetails: boolean;
    isEdit: boolean;
  };
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
};

const FacilityList: React.FC<IProps> = ({
  facilityActions,
  setFacilityActions,
}) => {
  const { state, actions } = useFacilityContext();

  const status = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_LIST_READ
  );
  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      actions.listGET(params);
    },
    [actions]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const result = await actions.listGET(params);
      if (result.payload?.rows) {
        const list = result.payload.rows;

        const items = list.map((value) => [
          checkValue(value.outgFacNm),
          checkValue(value.voltId),
          checkValue(value.stat),
          checkValueArr(value.substations, 'name'),
          checkValue(value?.facTyp?.facTypNm),
          checkValue(
            value?.blackstarts?.map((val) => val.blackstartValue).toString()
          ),
          value.besInd === 'Y' ? Lang.LBL_YES : Lang.LBL_NO,
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_FACILITY_NAME,
            Lang.LBL_VOLTAGE,
            Lang.LBL_STATUS,
            Lang.LBL_SUBSTATION,
            Lang.LBL_FACILITY_TYPE,
            Lang.LBL_BLACK_START,
            Lang.LBL_BES,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Facility_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  useEffect(() => {
    actions.filterGET();
  }, [actions]);

  return (
    <FacilityListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      facilityActions={facilityActions}
      setFacilityActions={setFacilityActions}
      filters={state.filters}
      handleDownload={handleDownload}
      total={state.total}
    />
  );
};

export default FacilityList;
