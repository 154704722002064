import React from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMLoader,
  ATMModal
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IOmsRequest, IOmsRequestForm } from 'src/models/oms-request.model';
import OmsRequestForm from '../oms-request-form/oms-request-form.component';
import style from './oms-request-edit.module.scss';

type IProps = {
  defaultValues?: IOmsRequest;
  // data: IOmsData;
  omsId: number;
  isOpen: boolean;
  isDirty: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleReset: () => void;
  handleOpen: (type?: string, id?: number) => void;
  handleSubmit: (data: IOmsRequestForm) => void;
  isEditable: boolean;
  dataLoading: boolean;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  trigger?: React.ReactNode;
};

const OmsRequestEditView: React.FC<IProps> = ({
  defaultValues,
  // data,
  isDirty,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleReset,
  handleSubmit,
  isEditable,
  dataLoading,
  setStartTime,
  setEndTime,
  setIsDirty,
  trigger,
  omsId
}) => {
  return (
    <ATMModal
      open={isOpen}
      size={isEditable ? 'large' : 'small'}
      className={style.omsModal}
      trigger={trigger}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <ATMModal.Header>
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column width={11}>
              <span>{Lang.LBL_OMS_OUTAGE_ID} {omsId}</span>
            </ATMGrid.Column>

            <ATMGrid.Column width={5} className={style.actionBtn}>
              <ATMButton
                id="open-data"
                type="button"
                size="small"
                icon="external alternate"
                color="grey"
                onClick={() => {
                  window.open(
                    `outage-planning?tab=2&action=popOut&omsId=${omsId}`,
                    '_blank'
                  );
                }}
              />
              <ATMButton
                icon="close"
                color="grey"
                onClick={() => handleOpen()}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMModal.Header>
      <ATMModal.Content scrolling>
        {dataLoading ? (
          <ATMLoader active inline="centered" />
        ) : (
          <>
          <OmsRequestForm
            defaultValues={defaultValues}
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            readOnly={!isEditable}
            // data={data}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
          />
          </>
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        {!isEditable ? (
          <ATMButton secondary content="Close" onClick={() => handleOpen()} />
        ) : (
          <>
            <ATMButton
              secondary
              content="Cancel"
              onClick={handleReset}
            />
            <ATMButton
              primary
              content="Save"
              disabled={!isDirty || loading}
              onClick={handleClick}
              loading={loading}
            />
          </>
        )}
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default OmsRequestEditView;
