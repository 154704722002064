import React, { useCallback, useRef, useState } from 'react';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { tsoOssEmployeeActionTypes } from 'src/ducks/tso-oss-employee.duck';
import {
  ITsoOssEmployee,
  ITsoOssEmployeeForm,
} from 'src/models/tso-oss-employee.model';
import { getTsoOssEmployeeStatus } from 'src/selectors/tso-oss-employee.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import TsoOssEmployeeEditView from './tso-oss-employee-edit.view';

type IProp = {
  data: ITsoOssEmployee;
};

const TsoOssEmployeeEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useTsoOssEmployeeContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getTsoOssEmployeeStatus(
    state,
    tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITsoOssEmployeeForm) => {
      const response = await actions.updatePUT(data.empId, {
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsEditOpen(false);

        ToastSuccess(
          `User ${data.fullName ?? '--'}/${data.empId} role has been updated`
        );
      } else {
        ToastError(response.error?.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <TsoOssEmployeeEditView
      formRef={formRef}
      defaultValues={{
        ...data,
        userType: data.userType.toLowerCase(),
      }}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default TsoOssEmployeeEdit;
