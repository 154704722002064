import React, { useCallback } from 'react';
import html2pdf from 'html2pdf.js';
import { ATMButton } from 'shared-it-appmod-ui';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { dailyReportsActionTypes } from 'src/ducks/daily-reports.duck';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getDailyReportsStatus } from 'src/selectors/daily-reports.selector';
import moment from 'moment';

type IProps = {
  date: Date;
};

const PDFOverview: React.FC<IProps> = ({ date }) => {
  const {
    state,
    state: { overviewList },
  } = useDailyReportsContext();

  const { fetching } = getDailyReportsStatus(
    state,
    dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST
  );

  const handlePrint = useCallback(() => {
    const table = document.getElementById('overview-table');
    const cover = new DOMParser().parseFromString(
      `<div id="element-to-print">
      <span style="text-align:center; page-break-after: always;" id="cover"> 
        <h2>Transmission Outage Requests</h2>
        <h3>${moment(date, 'YYYY-MM-DD HH:mm:ss')
          .format('dddd')
          .substring(0, 3)} ${moment(date).format('MM/DD/YYYY')}</h3>
      </span>
        ${table?.outerHTML}
      </div>`,
      'text/html'
    );
    const element = cover.getElementById('element-to-print');

    const options = {
      margin: 1,
      filename: `MCC_Transmission_Outages_${Moment().format('YYYYMMDD')}.pdf`,
      html2canvas: { scale: 2 },
      pagebreak: { mode: 'avoid-all' },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'p' },
    };
    html2pdf()
      .set(options)
      .from(element)
      .outputPdf()
      .get('pdf')
      .then((pdfObj) => {
        pdfObj.autoPrint();
        window.open(pdfObj.output('bloburl'), '_blank');
      });
  }, [date]);

  return (
    <ATMButton
      secondary
      key="email-version"
      disabled={fetching || overviewList.length === 0}
      loading={fetching}
      content={Lang.LBL_EMAIL_VERSION}
      onClick={handlePrint}
    />
  );
};

export default PDFOverview;
