import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import IsoWorkKindReducer, {
  IIsoWorkKindState,
  defaultState,
  duckActions,
} from 'src/ducks/iso-work-kind.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    IsoWorkKindReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IIsoWorkKindContext = ReturnType<typeof useReducer>;

const IsoWorkKindContext = createContext<Partial<IIsoWorkKindContext>>({
  state: defaultState,
}) as React.Context<IIsoWorkKindContext>;

type IProps = {
  state?: Partial<IIsoWorkKindState>;
};

const IsoWorkKindProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <IsoWorkKindContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </IsoWorkKindContext.Provider>
  );
};

const useIsoWorkKindContext = () => useContext(IsoWorkKindContext);

export type IUseIsoWorkKindContext = ReturnType<typeof useIsoWorkKindContext>;

export { IsoWorkKindContext, IsoWorkKindProvider, useIsoWorkKindContext };
