import React, { useCallback, useState } from 'react';
import { useFacilityContext } from 'src/contexts/facility.context';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import { IFacility } from 'src/models/facility.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import FacilityDeleteView from './facility-delete.view';

type IProp = {
  data?: IFacility | null;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
};

const FacilityDelete: React.FC<IProp> = ({ data, setFacilityActions }) => {
  const { state, actions } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    const response = await actions.dataDELETE(data?.outgFacId ?? 0);

    if (!response.error) {
      actions.listGET();
      setIsOpen(false);

      setFacilityActions({
        isNew: false,
        isDetails: false,
        isEdit: false,
      });

      ToastSuccess(
        Lang.formatString(Lang.MSG_DELETE_FACILITY, data?.outgFacNm ?? '--')
      );
    } else {
      ToastError('Deleted Error!');
    }
  }, [actions, data]);

  return (
    <FacilityDeleteView
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
      isInUse={data?.inUse}
    />
  );
};

export default FacilityDelete;
