import { httpClient } from 'src/libraries/http.library';
import {
  IIsoWorkKindForm,
  IsoWorkKindListPayloadSchema,
  IsoWorkKindDeletePayloadSchema,
  IsoWorkKindPayloadSchema,
} from 'src/models/iso-work-kind.model';

const client = httpClient();
const endpoint = 'iso-work-kind';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, IsoWorkKindPayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, IsoWorkKindListPayloadSchema);
  },

  createPOST: async (data: IIsoWorkKindForm) => {
    return client.post(`/${endpoint}`, data, IsoWorkKindPayloadSchema);
  },

  updatePUT: async (id: string, data: IIsoWorkKindForm) => {
    return client.put(`/${endpoint}/${id}`, data, IsoWorkKindPayloadSchema);
  },

  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, IsoWorkKindDeletePayloadSchema);
  },
};

export default services;
