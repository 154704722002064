/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  ATMModal,
  ATMButton,
  ATMDropdown,
  ATMTable,
  ATMHeader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { useSettingContext } from 'src/contexts/setting.context';
import { IKeyRequestEmployeeIssueValidationForm } from 'src/models/key-request.model';
import { IEmployee } from 'src/models/employee.model';
import { SettingName } from 'src/constants';
import { getSetting } from 'src/selectors/setting.selector';
import KeyRequestEmployeeForm from './key-request-employee-form.component';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IKeyRequestEmployeeIssueValidationForm) => void;
  employee?: IEmployee;
  handleSearch: (id) => void;
  isDirtyEmployee: boolean;
  setIsDirtyEmployee: React.Dispatch<React.SetStateAction<boolean>>;
  isRequestor: boolean;
  handleDropdown?: (data) => void;
};
const KeyRequestEmployeeView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  employee,
  handleOpen,
  handleClick,
  handleSubmit,
  handleSearch,
  isRequestor,
  handleDropdown,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [noticeURL, setNoticeURL] = useState<string | null>('');
  const { state: settingState } = useSettingContext();
  useEffect(() => {
    if (settingState.list.length > 0) {
      const settingNoticeURL = getSetting(
        SettingName.KEY_REQUEST_URL,
        settingState.list
      );
      setNoticeURL(settingNoticeURL?.value || null);
    }
  }, [settingState.list, getSetting]);
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        isRequestor ? (
          <ATMButton
            primary
            content="Request Key for Employee"
            onClick={() => handleOpen(true)}
          />
        ) : (
          <ATMDropdown.Item
            content="Request Key for Employee"
            onClick={() => handleOpen(true)}
          />
        )
      }
    >
      <ATMModal.Header>{Lang.TTL_KEY_EMPLOYEE_REQUEST}</ATMModal.Header>
      <ATMModal.Content style={{ marginBottom: '10px' }}>
        <KeyRequestEmployeeForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          employee={employee}
          handleSearch={handleSearch}
          isDirtyEmployee={isDirty}
          setIsDirtyEmployee={setIsDirty}
        />
      </ATMModal.Content>
      <ATMModal.Content>
        <ATMHeader as="h4" content={Lang.MSG_FOLLOWING_TRAINING} />
        <ATMTable textAlign="center">
          <ATMTable.Header>
            <ATMTable.HeaderCell>
              {Lang.LBL_KEY_REQUEST_CONTRACTOR_KEY_TYPE}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_SUBSTATION_ENTRY_TRAINING}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_AUTH_TRAINING}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_SUBSTATION_SWITCHING}
            </ATMTable.HeaderCell>
          </ATMTable.Header>
          <ATMTable.Body>
            <ATMTable.Row>
              {Lang.MSG_SCHLAGE_30.map((text) => (
                <ATMTable.Cell key={text}>{text}</ATMTable.Cell>
              ))}
            </ATMTable.Row>
            <ATMTable.Row>
              {Lang.MSG_SCHLAGE_40.map((text) => (
                <ATMTable.Cell key={text}>{text}</ATMTable.Cell>
              ))}
            </ATMTable.Row>
          </ATMTable.Body>
        </ATMTable>
        {noticeURL && (
          <small>
            <strong style={{ color: 'red' }}>{Lang.LBL_NOTICE} </strong>
            <span
              dangerouslySetInnerHTML={{
                __html: noticeURL,
              }}
            />
          </small>
        )}
        <ATMTable textAlign="center">
          <ATMTable.Header>
            <ATMTable.HeaderCell>
              {Lang.LBL_KEY_REQUEST_CONTRACTOR_KEY_TYPE}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_ISSUED_BY}</ATMTable.HeaderCell>
          </ATMTable.Header>
          <ATMTable.Body>
            <ATMTable.Row>
              {Lang.MSG_KEY_TYPE.map((text) => (
                <ATMTable.Cell key={text}>{text}</ATMTable.Cell>
              ))}
            </ATMTable.Row>
            <ATMTable.Row>
              {Lang.MSG_ISSUED_BY.map((text) => (
                <ATMTable.Cell key={text}>{text}</ATMTable.Cell>
              ))}
            </ATMTable.Row>
          </ATMTable.Body>
        </ATMTable>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => {
            handleOpen(false);
            if (handleDropdown) {
              handleDropdown(false);
            }
          }}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={() => {
            handleClick();
          }}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default KeyRequestEmployeeView;
