/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMHeader,
  ATMTextArea,
  ATMDropdown,
  ATMTable,
  ATMCheckbox,
  ATMFormProvider,
  useATMFormContext,
  ATMDatePicker,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import Lang from 'src/libraries/language';
import {
  ITroubleJobs,
  ITroubleJobsForm,
  TroubleJobsFormSchema,
} from 'src/models/trouble-jobs.model';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import moment from 'moment';
import { getUser } from 'src/libraries/amplify.library';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import Moment, {
  createDateTime,
  DATE_TIME_FORMAT,
  format24hDateTime,
} from 'src/libraries/moment.library';
import {
  TroubleJobSortStatus,
  TROUBLE_JOB_ALARM_EQUIPMENT,
  TROUBLE_JOB_ALARM_STATION,
} from 'src/constants';
import { TimeHourMinuteInput } from 'src/components/atoms/input/time-input-format.component';
import TroubleJobsEtsDispatch from '../trouble-jobs-ets-dispatch/trouble-jobs-ets-dispatch.component';
import ETSFormDispatchElement from './ets-form-dispatch-item/ets-form-dispatch-item.component';
import styles from './trouble-jobs-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ITroubleJobs>;
  handleSubmit: (data: ITroubleJobsForm) => void;
  handleEnable: (value: boolean) => void;
  setBeginDateSelected: (beginDate: Date | undefined) => void;
  setEndDateSelected: (endDate: Date | undefined) => void;
  beginDateSelected: Date;
  endDateSelected: Date;
};

type IFormContent = {
  defaultValues?: Partial<ITroubleJobs>;
  beginDateSelected: Date;
  endDateSelected: Date;
  setIsUpdated: (param: boolean) => void;
  setBeginDateSelected: (beginDate: Date | undefined) => void;
  setEndDateSelected: (endDate: Date | undefined) => void;
  handleEnable?: (value: boolean) => void;
};

enum CheckType {
  Send = 'sent',
  Cancel = 'cancel',
}

const FormContent: React.FC<IFormContent> = ({
  defaultValues,
  setBeginDateSelected,
  setEndDateSelected,
  setIsUpdated,
}) => {
  const dateTimeFormat = 'MM-DD-YYYY';
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    resetField,
    register,
    setError,
    clearErrors,
  } = useATMFormContext<ITroubleJobsForm>();
  const { state: troubleJobState, actions: troubleJobActions } =
    useTroubleJobsContext();
  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { state: realTimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const voltStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_VOLT_SUBS_READ
  );
  const equipmentStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_EQUIPMENT_READ
  );
  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );
  const realTimeLogStatus = getRealTimeLogStatus(
    realTimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );
  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );

  const [startDate, setStartDate] = useState<string | Date | undefined>(
    (defaultValues?.beginDate && format24hDateTime(defaultValues?.beginDate)) ??
      new Date()
  );

  const [endDate, setEndDate] = useState<string | Date | undefined>(
    (defaultValues?.endDate && format24hDateTime(defaultValues?.endDate)) ??
      undefined
  );

  const [startTime, setStartTime] = useState<string | Date | undefined>(
    defaultValues?.beginDate
      ? Moment(defaultValues?.beginDate).format(DATE_TIME_FORMAT)
      : Moment(new Date()).format(DATE_TIME_FORMAT)
  );

  const [endTime, setEndTime] = useState(
    defaultValues?.endDate
      ? Moment(defaultValues?.endDate).format(DATE_TIME_FORMAT)
      : undefined
  );

  const [equipmentType, setEquipmentType] = useState<number | undefined>(() => {
    const equipType = getValues('facility.facTyp.facTypId');
    setValue('equipmentType', equipType);
    return equipType;
  });
  const [substationId, setSubstationId] = useState<string | undefined>(
    getValues('substationId') as string
  );
  const [voltId, setVoltId] = useState<number | undefined>(
    getValues('voltId') as number
  );
  const [jobStatus, setJobStatus] = useState<string | undefined>('Open');
  const [isDirty, setIsDirty] = useState(false);
  const [isOpenEts, setIsOpenEts] = useState(false);
  const [volt, setVolt] = useState();
  const [isEditFacilityInfo] = useState(false);
  const [primaryCause, setPrimaryCause] = useState(true);
  const [primaryCauseEdit, setPrimaryCauseEdit] = useState(
    defaultValues?.primIsoOutgTypeId
  );
  const [isSendAll, setIsSendAll] = useState<boolean | undefined>(false);
  const [isCancelAll, setIsCancelAll] = useState<boolean | undefined>(false);
  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>(
    defaultValues && defaultValues.outgFacId && !defaultValues.substationId
      ? Lang.FACILITY_TYPE[1]
      : defaultValues && defaultValues.substationId && defaultValues.outgFacId
      ? Lang.FACILITY_TYPE[2]
      : ''
  );

  const { fields, update } = useFieldArray({
    name: 'sortResponses',
    control,
  });

  const handleUpdateSortResponse = useCallback(
    (id, value, sortTime, sent, cancel) => {
      update(id, {
        ...value,
        sortTime,
        sent,
        cancel,
      });
    },
    [update]
  );

  const handleOptionsVolt = useCallback((equipment: any) => {
    facilityActions.voltCLEAR();
    facilityActions.equipmentCLEAR();
    if (getValues().substationId && equipment !== '') {
      facilityActions.voltSubstationGET(
        getValues().substationId ?? '',
        equipment
      );
    } else {
      resetField('voltId', {
        defaultValue: undefined,
      });
      resetField('outgFacId', {
        defaultValue: undefined,
      });
    }
  }, []);
  const handleOptionsEquipment = useCallback(
    (voltNum: any) => {
      facilityActions?.equipmentCLEAR();

      if (getValues().substationId && equipmentType && voltNum) {
        facilityActions?.equipmentGET(
          equipmentType,
          voltNum ?? volt,
          undefined,
          getValues().substationId ?? ''
        );
      } else {
        resetField('outgFacId', {
          defaultValue: undefined,
        });
        // facilityActions?.equipmentCLEAR();
      }
    },
    [equipmentType]
  );

  useEffect(() => {
    if (fields.length) {
      let sent = false;
      const filtered = fields.filter(
        (value) =>
          (value.sortStat?.toUpperCase() !== TroubleJobSortStatus.COMPLETE ||
            value.sortStat?.toUpperCase() !== TroubleJobSortStatus.CANCELED ||
            value.sortStat?.toUpperCase() !==
              TroubleJobSortStatus.CANCELSENT) &&
          value.sortTime
      );

      if (filtered.length > 0) {
        sent = filtered.every((v) => v.sent);
      }

      const cancel = fields
        .filter(
          (value) =>
            value.sortStat?.toUpperCase() !== TroubleJobSortStatus.COMPLETE ||
            value.sortStat?.toUpperCase() !== TroubleJobSortStatus.CANCELED ||
            value.sortStat?.toUpperCase() !== TroubleJobSortStatus.CANCELSENT
        )
        .every((v) => v.cancel);

      setIsSendAll(sent);
      setIsCancelAll(cancel);
    }
  }, [fields]);

  const handleCheckAll = useCallback(
    (type: CheckType, checked?: boolean) => {
      fields.forEach((value, i) => {
        if (
          value.sortStat?.toUpperCase() !== TroubleJobSortStatus.COMPLETE ||
          value.sortStat?.toUpperCase() !== TroubleJobSortStatus.CANCELED ||
          value.sortStat?.toUpperCase() !== TroubleJobSortStatus.CANCELSENT
        ) {
          update(i, {
            ...value,
            sent: value.sortTime && type === CheckType.Send ? checked : false,
            cancel: type === CheckType.Cancel && checked,
          });
        }
      });
    },
    [fields, update]
  );

  useEffect(() => {
    facilityActions?.voltCLEAR();
    facilityActions?.equipmentCLEAR();
    if (defaultValues && defaultValues?.substationId) {
      facilityActions?.voltSubstationGET(
        defaultValues.substationId,
        defaultValues.facility?.facTyp?.facTypId ?? 21
      );
      facilityActions?.equipmentGET(
        defaultValues.facility?.facTyp?.facTypId ?? 2,
        defaultValues.facility?.volt?.voltId ?? 0,
        undefined,
        defaultValues.substationId
      );
    }
  }, []);
  useEffect(() => {
    substationActions.listSubstationCreateGET({
      limit: 0,
      page: 1,
    });

    realtimeLogActions.lineGET();
  }, [substationActions, realtimeLogActions]);
  // }, [equipmentTypeActions, substationActions, realtimeLogActions]);

  useEffect(() => {
    setVoltId(undefined);

    if (substationId) {
      equipmentTypeActions.listGET({
        limit: 0,
        page: 1,
        filters: [{ name: 'substationId', value: substationId }],
      });
    }
  }, [substationId, setVoltId, equipmentTypeActions]);

  useEffect(() => {
    if (equipmentType && substationId) {
      facilityActions.voltSubstationGET(substationId, equipmentType);
    }
  }, [equipmentType, substationId, facilityActions]);

  useEffect(() => {
    if (equipmentType && substationId && voltId) {
      facilityActions.equipmentGET(
        equipmentType,
        voltId,
        undefined,
        substationId
      );
    }
  }, [equipmentType, substationId, voltId, facilityActions]);

  useEffect(() => {
    troubleJobActions?.clearDateTimeHistory();
  }, []);

  return (
    <ATMGrid>
      {defaultValues ? (
        <>
          <ATMGrid.Row style={{ paddingTop: 0 }}>
            <ATMField
              control={control}
              as={ATMInput}
              name="onEdit"
              defaultValue="true"
              type="hidden"
              className="hidden"
            />
            <ATMField
              control={control}
              as={ATMInput}
              name="submittedBy"
              defaultValue={defaultValues.submittedBy}
              type="hidden"
              className="hidden"
            />
            <ATMGrid.Column width={16} style={{ marginBottom: '1em' }}>
              <ATMHeader as="h3">
                {Lang.LBL_TROUBLE_JOB_FACILITY_INFO}
                {/* Do not allow edit of facility
                <ATMButton
                  type="button"
                  icon="edit"
                  onClick={() => setIsEditFacilityInfo(!isEditFacilityInfo)}
                  hidden
                /> */}
              </ATMHeader>
            </ATMGrid.Column>
            {!isEditFacilityInfo ? (
              <>
                <ATMGrid.Column width={4}>
                  <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
                    <b>
                      {defaultValues &&
                      defaultValues.outgFacId &&
                      !defaultValues.substationId
                        ? `Line - ${defaultValues.facility?.outgFacNm}`
                        : `Substation - ${defaultValues.substation?.name}`}{' '}
                    </b>
                  </FieldReadOnly>
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  <FieldReadOnly label={Lang.LBL_EQUIPMENT_TYPE}>
                    <b>
                      {' '}
                      {defaultValues &&
                      defaultValues.outgFacId &&
                      !defaultValues.substationId
                        ? '-'
                        : defaultValues.facility?.facTyp?.facTypNm}{' '}
                    </b>
                  </FieldReadOnly>
                  <ATMField
                    type="hidden"
                    className="hidden"
                    as={ATMInput}
                    name="equipmentType"
                    control={control}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  <FieldReadOnly label={Lang.LBL_VOLTAGE}>
                    <b>
                      {defaultValues &&
                      defaultValues.outgFacId &&
                      !defaultValues.substationId
                        ? '-'
                        : defaultValues.facility?.volt?.voltNm}{' '}
                    </b>
                  </FieldReadOnly>
                  <ATMField
                    type="hidden"
                    className="hidden"
                    as={ATMInput}
                    name="voltId"
                    control={control}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  <FieldReadOnly label={Lang.LBL_EQUIPMENT}>
                    <b>
                      {' '}
                      {defaultValues &&
                      defaultValues.outgFacId &&
                      !defaultValues.substationId
                        ? '-'
                        : defaultValues.facility?.outgFacNm}{' '}
                    </b>
                  </FieldReadOnly>
                  <ATMField
                    type="hidden"
                    className="hidden"
                    as={ATMInput}
                    name="outgFacId"
                    control={control}
                  />
                </ATMGrid.Column>
              </>
            ) : (
              // End Of !isEditFacilityInfo
              // CODE BELOW IS UNUSED because the EDIT button is now hidden. We don't allow edit of facility in trouble job.
              <>
                <ATMGrid.Column width={6}>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    label={
                      <LabelRequired>
                        {Lang.LBL_LINE_SUBSTATION_SELECT}
                      </LabelRequired>
                    }
                    name="lineSubstation"
                    placeholder="Select"
                    size="small"
                    value={lineSubstationSelect}
                    selectOnBlur={false}
                    options={Object.values(Lang.FACILITY_TYPE).map((value) => ({
                      key: value,
                      value,
                      text: value,
                    }))}
                    onChange={
                      ((_, { value }) => {
                        equipmentTypeActions.listCLEAR();
                        facilityActions.voltCLEAR();
                        facilityActions.equipmentCLEAR();

                        resetField('substationId', {
                          defaultValue: undefined,
                        });
                        resetField('outgFacId', {
                          defaultValue: undefined,
                        });
                        resetField('equipmentType', {
                          defaultValue: undefined,
                        });
                        resetField('voltId', {
                          defaultValue: undefined,
                        });

                        setSubstationId(undefined);
                        setEquipmentType(undefined);
                        setVoltId(undefined);

                        if (!value) {
                          // setValue('substationId', undefined);
                          // resetField('substationId', {
                          //   defaultValue: undefined,
                          // });
                          setSubstationId('');

                          clearErrors('substationId');
                          clearErrors('equipmentType');
                          clearErrors('voltId');
                          clearErrors('outgFacId');
                        }
                        setLineSubstationSelect(value);
                        if (defaultValues && !defaultValues.substationId) {
                          setEquipmentType(undefined);
                          setValue('equipmentType', 0);
                        }
                        setIsDirty(true);

                        return value;
                      }) as any
                    }
                    clearable
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={6}>
                  {lineSubstationSelect === Lang.FACILITY_TYPE[1] ? (
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name="outgFacId"
                      label={<label>&nbsp;</label>}
                      placeholder=""
                      size="small"
                      search
                      error={errors.outgFacId}
                      disabled={!defaultValues ? !isDirty : false}
                      options={realTimeLogState.line.map((value) => {
                        return {
                          key: value.outgFacId,
                          value: value.outgFacId,
                          text: value.outgFacNm,
                        };
                      })}
                      onChange={([_, { value }]) => {
                        return value;
                      }}
                      control={control}
                      clearable
                      selectOnBlur={false}
                    />
                  ) : (
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name="substationId"
                      label={<label>&nbsp;</label>}
                      placeholder=""
                      size="small"
                      search
                      defaultValue={defaultValues.substationId}
                      disabled={!lineSubstationSelect}
                      options={substationState.listForCreate.map((value) => ({
                        key: value.substationId,
                        value: value.substationId,
                        text: value.name,
                      }))}
                      onChange={([_, { value }]) => {
                        equipmentTypeActions.listCLEAR();
                        facilityActions.voltCLEAR();
                        facilityActions.equipmentCLEAR();

                        setSubstationId(value);
                        setEquipmentType(undefined);
                        setVoltId(undefined);

                        resetField('outgFacId', {
                          defaultValue: undefined,
                        });
                        resetField('equipmentType', {
                          defaultValue: undefined,
                        });
                        resetField('voltId', {
                          defaultValue: undefined,
                        });

                        if (!value) {
                          clearErrors('equipmentType');
                          clearErrors('voltId');
                          clearErrors('outgFacId');
                        }

                        return value;
                      }}
                      control={control}
                      clearable
                      error={errors.substationId}
                      selectOnBlur={false}
                    />
                  )}
                </ATMGrid.Column>
                {lineSubstationSelect === Lang.FACILITY_TYPE[2] && (
                  <>
                    <ATMGrid.Column
                      width={6}
                      style={{ marginBottom: '1em', marginTop: '1em' }}
                    >
                      <ATMField
                        label={Lang.LBL_EQUIPMENT_TYPE}
                        as={ATMDropdown}
                        selection
                        clearable
                        name="equipmentType"
                        loading={equipmentTypeStatus.fetching}
                        defaultValue={
                          defaultValues.substationId
                            ? defaultValues.facility?.facTyp?.facTypId
                            : 0
                        }
                        options={equipmentTypeState.list.map((value) => ({
                          key: value.facTypId,
                          value: value.facTypId,
                          text: value.facTypNm,
                        }))}
                        control={control}
                        disabled={
                          !getValues('substationId') ||
                          equipmentTypeStatus.fetching
                        }
                        onChange={([_, { value }]) => {
                          setEquipmentType(value);
                          setVoltId(undefined);

                          handleOptionsVolt(
                            value
                            // control as unknown as UseFormReturn
                          );

                          if (!value) {
                            clearErrors('voltId');
                            clearErrors('outgFacId');
                          }

                          return value;
                        }}
                        search
                        selectOnBlur={false}
                        error={errors.equipmentType}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column
                      width={6}
                      style={{ marginBottom: '1em', marginTop: '1em' }}
                    >
                      <ATMField
                        label={Lang.LBL_VOLTAGE}
                        as={ATMDropdown}
                        selection
                        control={control}
                        name="voltId"
                        loading={voltStatus.fetching}
                        options={facilityState.volt?.map((val) => ({
                          key: val.voltId,
                          value: val.voltId,
                          text: val.name,
                        }))}
                        disabled={
                          !getValues('substationId') ||
                          !getValues('equipmentType') ||
                          voltStatus.fetching
                        }
                        onChange={([_, { value }]) => {
                          // facilityActions.equipmentCLEAR();

                          setVolt(value);

                          handleOptionsEquipment(
                            value
                            // control as unknown as UseFormReturn
                          );
                          if (!value) {
                            clearErrors('outgFacId');
                          }
                          return value;
                        }}
                        defaultValue={defaultValues.facility?.volt?.voltId}
                        clearable
                        selectOnBlur={false}
                        error={errors.voltId}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column width={6}>
                      <ATMField
                        label={Lang.LBL_EQUIPMENT}
                        as={ATMDropdown}
                        selection
                        name="outgFacId"
                        search
                        control={control}
                        loading={equipmentStatus.fetching}
                        options={
                          lineSubstationSelect === Lang.FACILITY_TYPE[2]
                            ? facilityState.equipment?.map((val) => ({
                                key: val.outgFacId,
                                value: val.outgFacId,
                                text: val.outgFacNm,
                              }))
                            : []
                        }
                        disabled={
                          !getValues('substationId') ||
                          !getValues('equipmentType') ||
                          !getValues('voltId') ||
                          equipmentStatus.fetching
                        }
                        onChange={([_, { value }]) => value}
                        clearable
                        defaultValue={defaultValues.outgFacId}
                        selectOnBlur={false}
                        error={errors.outgFacId}
                      />
                    </ATMGrid.Column>
                  </>
                )}
              </>
            )}
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={16} style={{ marginBottom: '1em' }}>
              <ATMHeader as="h3">{Lang.LBL_TROUBLE_JOB_INFO}</ATMHeader>
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              <ATMField
                as={ATMDropdown}
                selection
                label={Lang.LBL_TROUBLE_JOB_STATUS}
                name="jobStatus"
                placeholder="Select"
                size="small"
                options={[
                  ...Array.from(Object.keys(Lang.TROUBLE_JOB_STATUS))
                    .filter((elem) => elem !== 'Developing')
                    .map((val) => {
                      return {
                        key: val,
                        value: val,
                        text: Lang.TROUBLE_JOB_STATUS[val],
                      };
                    }),
                ]}
                onChange={([_, { value }]) => {
                  setJobStatus(value);
                  clearErrors('endDate');
                  return value;
                }}
                control={control}
                error={errors.jobStatus}
                clearable
                selectOnBlur={false}
                disabled={defaultValues.jobStatus === 'Closed'}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={4}>
              <ATMField
                as={ATMDatePicker}
                label={
                  <LabelRequired>
                    {Lang.LBL_TROUBLE_JOB_BEGIN_DATE}
                  </LabelRequired>
                }
                size="small"
                dateFormat="MM-DD-YYYY"
                isDateTimePicker
                dateTimePlaceHolder="Select"
                clearable
                minDate={
                  (defaultValues?.beginDate &&
                    moment(defaultValues?.beginDate).startOf('day').toDate()) ??
                  moment(new Date(), dateTimeFormat).startOf('day').toDate()
                }
                control={control}
                name="beginDate"
                defaultValue={
                  (defaultValues?.beginDate &&
                    format24hDateTime(defaultValues?.beginDate)) ??
                  undefined
                }
                error={errors.beginDate}
                value={startDate}
                disabled={defaultValues.jobStatus === 'Closed'}
                onChange={([_, val]) => {
                  if (val.value && startTime) {
                    const date = createDateTime(
                      val.value,
                      startTime as string
                    ).toDate();
                    setStartDate(date);
                    setBeginDateSelected(date);
                  }
                  if (!val.value) {
                    setStartDate(undefined);
                    setBeginDateSelected(undefined);
                    setStartTime(undefined);
                  }
                  if (val.value) {
                    return moment(val.value, dateTimeFormat).toDate();
                  }
                  return undefined;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={3}>
              <TimeHourMinuteInput
                name="startTime"
                noMargin
                inputValue={startTime}
                control={control}
                dateSelected={startDate}
                enableRequired
                error={errors.startTime}
                setDateSelected={setStartDate}
                callback={setIsUpdated}
                setInputValue={setStartTime}
                setValue={setValue}
                setError={setError}
                register={register}
                clearErrors={clearErrors}
                disabled={defaultValues.jobStatus === 'Closed'}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={4}>
              <ATMField
                as={ATMDatePicker}
                label={
                  jobStatus === 'Closed' ? (
                    <LabelRequired>
                      {Lang.LBL_TROUBLE_JOB_BEGIN_DATE}
                    </LabelRequired>
                  ) : (
                    Lang.LBL_TROUBLE_JOB_END_DATE
                  )
                }
                size="small"
                dateFormat="MM-DD-YYYY"
                isDateTimePicker
                clearable
                dateTimePlaceHolder="Select"
                minDate={Moment(defaultValues.beginDate)
                  .startOf('day')
                  .toDate()}
                control={control}
                name="endDate"
                defaultValue={
                  defaultValues?.endDate &&
                  moment(defaultValues?.endDate, dateTimeFormat)
                    .utc(false)
                    .toDate()
                }
                error={errors.endDate}
                value={endDate}
                disabled={defaultValues.jobStatus === 'Closed'}
                onChange={([_, val]) => {
                  if (val.value && endTime) {
                    const date = createDateTime(val.value, endTime).toDate();
                    setEndDate(date as any);
                    setEndDateSelected(date);
                  }
                  if (!endTime) {
                    const date = Moment(val.value).toDate() as unknown as Date;
                    setEndDate(date as any);
                    setEndDateSelected(date);
                  }
                  if (!val.value) {
                    setEndDate(undefined);
                    setEndDateSelected(undefined);
                    setEndTime(undefined);
                  }
                  if (val.value) {
                    return moment(val.value, dateTimeFormat).toDate();
                  }
                  return undefined;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={3}>
              <TimeHourMinuteInput
                name="endTime"
                setInputValue={setEndTime}
                inputValue={endTime}
                setDateSelected={setEndDate}
                dateSelected={endDate}
                error={errors.endTime}
                noMargin
                isStartTime={false}
                enableRequired={
                  jobStatus === 'Closed' || endDate ? true : false
                }
                callback={setIsUpdated}
                control={control}
                setValue={setValue}
                setError={setError}
                register={register}
                clearErrors={clearErrors}
                disabled={defaultValues.jobStatus === 'Closed'}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={4}>
              <ATMField
                label={Lang.LBL_TROUBLE_JOB_PRIMARY}
                as={ATMDropdown}
                name="primIsoOutgTypeId"
                selection
                options={troubleJobState.outageType
                  ?.filter((elem) => elem.isoOutgTypeLevel === 'P')
                  .map((value) => {
                    return {
                      key: value.isoOutageTypeId,
                      value: value.isoOutageTypeId,
                      text: value.isoOutgTypeDesc,
                    };
                  })}
                control={control}
                onChange={([_, { value }]) => {
                  setPrimaryCauseEdit(value);
                  if (!value) {
                    setValue('secondIsoOutgTypeId', null);
                  }
                  return value === '' ? null : value;
                }}
                clearable
                search
                selectOnBlur={false}
                // error={errors.primaryCause}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              {primaryCauseEdit ? (
                <ATMField
                  label={Lang.LBL_TROUBLE_JOB_SECONDARY}
                  as={ATMDropdown}
                  selection
                  name="secondIsoOutgTypeId"
                  options={troubleJobState.outageType
                    ?.filter((elem) => elem.isoOutgTypeLevel === 'S')
                    .map((value) => {
                      return {
                        key: value.isoOutageTypeId,
                        value: value.isoOutageTypeId,
                        text: value.isoOutgTypeDesc,
                      };
                    })}
                  control={control}
                  onChange={([_, { value }]) => {
                    return value === '' ? null : value;
                  }}
                  clearable
                  search
                  selectOnBlur={false}
                  // error={errors.secondaryCause}
                />
              ) : (
                <ATMField
                  label={Lang.LBL_TROUBLE_JOB_SECONDARY}
                  as={ATMDropdown}
                  selection
                  name="secondIsoOutgTypeId"
                  disabled
                  clearable
                  options={troubleJobState.outageType
                    ?.filter((elem) => elem.isoOutgTypeLevel === 'S')
                    .map((value) => {
                      return {
                        key: value.isoOutageTypeId,
                        value: value.isoOutageTypeId,
                        text: value.isoOutgTypeDesc,
                      };
                    })}
                  // error={errors.secondaryCause}
                />
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={12}>
              <ATMField
                label={
                  <LabelRequired>{Lang.LBL_TROUBLE_JOB_DESC}</LabelRequired>
                }
                required
                as={ATMTextArea}
                name="jobDescription"
                maxLength={1000}
                control={control}
                error={errors.jobDescription}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={16}>
              <ATMHeader as="h3">{Lang.LBL_TROUBLE_JOB_PARTICIPANTS}</ATMHeader>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={16}>
              <ATMTable>
                <ATMTable.Header>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_CREATED_BY}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_CLOSED_BY}
                    </ATMTable.HeaderCell>
                  </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                  <ATMTable.Row>
                    <ATMTable.Cell>
                      {defaultValues.submitted &&
                      defaultValues.submitted.lastName
                        ? `${defaultValues.submitted?.lastName}, ${defaultValues.submitted?.firstName}`
                        : '-'}
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      {defaultValues.closed && defaultValues.closed.lastName
                        ? `${defaultValues.closed?.lastName}, ${defaultValues.closed?.firstName}`
                        : '-'}
                    </ATMTable.Cell>
                  </ATMTable.Row>
                </ATMTable.Body>
              </ATMTable>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={12}>
              <ATMHeader as="h3">{Lang.LBL_TROUBLE_JOB_ETS_DISPATCH}</ATMHeader>
            </ATMGrid.Column>
            <ATMGrid.Column width={4} style={{ textAlign: 'right' }}>
              <TroubleJobsEtsDispatch
                isOpenEts={isOpenEts}
                setIsOpenEts={setIsOpenEts}
                defaultValues={defaultValues}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>

          <ATMGrid.Row>
            <ATMGrid.Column width={16}>
              {fields && (
                <ATMGrid>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={16}>
                      <ATMTable>
                        <ATMTable.Header>
                          <ATMTable.Row>
                            <ATMTable.HeaderCell content={Lang.LBL_DATE} />
                            <ATMTable.HeaderCell />
                            <ATMTable.HeaderCell />
                            <ATMTable.HeaderCell content={Lang.LBL_TIME} />
                            <ATMTable.HeaderCell
                              content={Lang.LBL_TL_SUB}
                              width="12"
                            />
                            <ATMTable.HeaderCell content={Lang.LBL_SUB_POLE} />
                            <ATMTable.HeaderCell content={Lang.LBL_AREA} />
                            <ATMTable.HeaderCell content={Lang.LBL_REMARKS} />
                            <ATMTable.HeaderCell content={Lang.LBL_ORDER_NUM} />
                            <ATMTable.HeaderCell content={Lang.LBL_STATUS} />
                            <ATMTable.HeaderCell>
                              <div className={styles.selectAllCheckbox}>
                                <span>{Lang.LBL_SEND}</span>
                                <ATMCheckbox
                                  checked={isSendAll}
                                  onChange={(_, { checked }) => {
                                    handleCheckAll(CheckType.Send, checked);
                                  }}
                                />
                              </div>
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                              <div className={styles.selectAllCheckbox}>
                                <span>{Lang.LBL_CANCEL}</span>
                                <ATMCheckbox
                                  checked={isCancelAll}
                                  onChange={(_, { checked }) => {
                                    handleCheckAll(CheckType.Cancel, checked);
                                  }}
                                />
                              </div>
                            </ATMTable.HeaderCell>
                          </ATMTable.Row>
                        </ATMTable.Header>
                        <ATMTable.Body>
                          {fields
                            .filter(
                              (val) =>
                                !(
                                  defaultValues?.substation?.substationId ===
                                    TROUBLE_JOB_ALARM_STATION &&
                                  defaultValues?.facility?.outgFacNm ===
                                    TROUBLE_JOB_ALARM_EQUIPMENT &&
                                  val.outBack === 'BACK'
                                )
                            )
                            .map((val, index) => {
                              return (
                                <ATMTable.Row key={index}>
                                  <ETSFormDispatchElement
                                    data={val}
                                    index={index}
                                    setCancelAll={() => setIsCancelAll(false)}
                                    setSentAll={() => setIsSendAll(false)}
                                    handleAllSentCancel={() =>
                                      setIsCancelAll(false)
                                    }
                                    handleUpdateSortResponse={
                                      handleUpdateSortResponse
                                    }
                                  />
                                </ATMTable.Row>
                              );
                            })}
                        </ATMTable.Body>
                      </ATMTable>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                </ATMGrid>
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </>
      ) : (
        // End of defaultValues (true)
        <>
          <ATMGrid.Row>
            <ATMField
              control={control}
              as={ATMInput}
              name="submittedBy"
              defaultValue={getUser()?.emp_no}
              type="hidden"
              className="hidden"
            />
            <ATMField
              control={control}
              as={ATMInput}
              name="jobStatus"
              defaultValue="Open"
              type="hidden"
              className="hidden"
            />
            <ATMGrid.Column width={16} style={{ marginBottom: '1em' }}>
              <ATMHeader as="h3">
                {Lang.LBL_TROUBLE_JOB_FACILITY_INFO}
              </ATMHeader>
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <ATMField
                as={ATMDropdown}
                selection
                label={
                  <LabelRequired>
                    {Lang.LBL_LINE_SUBSTATION_SELECT}
                  </LabelRequired>
                }
                name="facilityType"
                placeholder="Select"
                size="small"
                options={Object.values(Lang.FACILITY_TYPE).map((value) => ({
                  key: value,
                  value,
                  text: value,
                }))}
                onChange={
                  ((_, { value }) => {
                    equipmentTypeActions.listCLEAR();
                    facilityActions.voltCLEAR();
                    facilityActions.equipmentCLEAR();

                    resetField('substationId', {
                      defaultValue: undefined,
                    });
                    resetField('outgFacId', {
                      defaultValue: undefined,
                    });
                    resetField('equipmentType', {
                      defaultValue: undefined,
                    });
                    resetField('voltId', {
                      defaultValue: undefined,
                    });

                    setSubstationId(undefined);
                    setEquipmentType(undefined);
                    setVoltId(undefined);

                    setLineSubstationSelect(value);
                    setIsDirty(true);
                    if (!value) {
                      clearErrors('substationId');
                      clearErrors('equipmentType');
                      clearErrors('voltId');
                      clearErrors('outgFacId');
                    }
                    return value;
                  }) as any
                }
                clearable
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              {lineSubstationSelect === Lang.FACILITY_TYPE[1] ? (
                <ATMField
                  as={ATMDropdown}
                  selection
                  name="outgFacId"
                  label={<label>&nbsp;</label>}
                  placeholder=""
                  size="small"
                  error={errors.outgFacId}
                  disabled={
                    (!realTimeLogState.line.length &&
                      realTimeLogStatus.fetching) ||
                    !lineSubstationSelect
                  }
                  loading={
                    !realTimeLogState.line.length && realTimeLogStatus.fetching
                  }
                  options={realTimeLogState.line.map((value) => {
                    return {
                      key: value.outgFacId,
                      value: value.outgFacId,
                      text: value.outgFacNm,
                    };
                  })}
                  onChange={([_, { value }]) => {
                    return value;
                  }}
                  control={control}
                  clearable
                  search
                  selectOnBlur={false}
                />
              ) : (
                <ATMField
                  as={ATMDropdown}
                  selection
                  name="substationId"
                  label={<label>&nbsp;</label>}
                  placeholder=""
                  size="small"
                  disabled={
                    (!substationState.listForCreate.length &&
                      substationStatus.fetching) ||
                    !lineSubstationSelect
                  }
                  loading={
                    !substationState.listForCreate.length &&
                    substationStatus.fetching
                  }
                  options={substationState.listForCreate.map((value) => ({
                    key: value.substationId,
                    value: value.substationId,
                    text: value.name,
                  }))}
                  onChange={([_, { value }]) => {
                    equipmentTypeActions.listCLEAR();
                    facilityActions.voltCLEAR();
                    facilityActions.equipmentCLEAR();

                    setSubstationId(value);
                    setVoltId(undefined);
                    setEquipmentType(undefined);

                    resetField('outgFacId', {
                      defaultValue: undefined,
                    });
                    resetField('equipmentType', {
                      defaultValue: undefined,
                    });
                    resetField('voltId', {
                      defaultValue: undefined,
                    });

                    if (!value) {
                      clearErrors('equipmentType');
                      clearErrors('voltId');
                      clearErrors('outgFacId');
                    }

                    return value;
                  }}
                  control={control}
                  clearable
                  search
                  error={errors.substationId}
                  selectOnBlur={false}
                />
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
          {lineSubstationSelect === Lang.FACILITY_TYPE[2] && (
            <>
              <ATMGrid.Row>
                <ATMGrid.Column width={6}>
                  <ATMField
                    label={Lang.LBL_EQUIPMENT_TYPE}
                    as={ATMDropdown}
                    selection
                    clearable
                    name="equipmentType"
                    options={equipmentTypeState.list.map((value) => ({
                      key: value.facTypId,
                      value: value.facTypId,
                      text: value.facTypNm,
                    }))}
                    control={control}
                    selectOnBlur={false}
                    disabled={
                      equipmentTypeStatus.fetching ||
                      !lineSubstationSelect ||
                      !substationId
                    }
                    loading={equipmentTypeStatus.fetching}
                    onChange={([_, { value }]) => {
                      facilityActions.voltCLEAR();
                      facilityActions.equipmentCLEAR();
                      setEquipmentType(value);
                      setVoltId(undefined);

                      resetField('outgFacId', {
                        defaultValue: undefined,
                      });
                      resetField('voltId', {
                        defaultValue: undefined,
                      });

                      if (!value) {
                        clearErrors('voltId');
                        clearErrors('outgFacId');
                      }
                      return value;
                    }}
                    error={errors.equipmentType}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={6}>
                  <ATMField
                    label={Lang.LBL_VOLTAGE}
                    as={ATMDropdown}
                    selection
                    control={control}
                    name="voltId"
                    required
                    loading={voltStatus.fetching}
                    options={facilityState.volt?.map((val) => ({
                      key: val.voltId,
                      value: val.voltId,
                      text: val.name,
                    }))}
                    disabled={
                      voltStatus.fetching ||
                      !lineSubstationSelect ||
                      !substationId ||
                      !equipmentType
                    }
                    onChange={([_, { value }]) => {
                      facilityActions.equipmentCLEAR();
                      setVoltId(value);

                      resetField('outgFacId', {
                        defaultValue: undefined,
                      });

                      if (!value) {
                        clearErrors('outgFacId');
                      }
                      return value;
                    }}
                    search
                    clearable
                    selectOnBlur={false}
                    error={errors.voltId}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column width={6}>
                  <ATMField
                    label={Lang.LBL_EQUIPMENT}
                    as={ATMDropdown}
                    selection
                    name="outgFacId"
                    control={control}
                    loading={equipmentStatus.fetching}
                    options={facilityState.equipment?.map((val) => ({
                      key: val.outgFacId,
                      value: val.outgFacId,
                      text: val.outgFacNm,
                    }))}
                    selectOnBlur={false}
                    disabled={equipmentStatus.fetching || !voltId}
                    onChange={([_, { value }]) => {
                      return value;
                    }}
                    clearable
                    search
                    required
                    error={errors.outgFacId}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </>
          )}
          <ATMGrid.Row>
            <ATMGrid.Column width={16} style={{ marginBottom: '1em' }}>
              <ATMHeader as="h3">{Lang.LBL_TROUBLE_JOB_INFO}</ATMHeader>
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <ATMField
                as={ATMDatePicker}
                label={
                  <LabelRequired>
                    {Lang.LBL_TROUBLE_JOB_BEGIN_DATE}
                  </LabelRequired>
                }
                size="small"
                dateFormat="MM-DD-YYYY"
                isDateTimePicker
                dateTimePlaceHolder="Select"
                clearable
                control={control}
                defaultValue={moment(new Date(), dateTimeFormat)
                  .startOf('day')
                  .toDate()}
                name="beginDate"
                error={errors.beginDate}
                onChange={([_, val]) => {
                  if (val.value && startTime) {
                    const date = createDateTime(
                      val.value,
                      startTime as string
                    ).toDate();
                    setStartDate(date);
                    setBeginDateSelected(date);
                  }
                  if (val.value) {
                    return moment(val.value, dateTimeFormat).toDate();
                  }
                  return undefined;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <TimeHourMinuteInput
                name="startTime"
                setInputValue={setStartTime}
                inputValue={startTime}
                setDateSelected={setStartDate}
                dateSelected={startDate}
                enableRequired
                error={errors.startTime}
                noMargin={false}
                callback={setIsUpdated}
                control={control}
                setValue={setValue}
                setError={setError}
                register={register}
                clearErrors={clearErrors}
                // isDisabled={startDate ? false : true}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={6}>
              <ATMField
                label={Lang.LBL_TROUBLE_JOB_PRIMARY}
                as={ATMDropdown}
                name="primIsoOutgTypeId"
                control={control}
                selection
                clearable
                options={troubleJobState.outageType
                  ?.filter((elem) => elem.isoOutgTypeLevel === 'P')
                  .map((value) => {
                    return {
                      key: value.isoOutageTypeId,
                      value: value.isoOutageTypeId,
                      text: value.isoOutgTypeDesc,
                    };
                  })}
                onChange={([_, { value }]) => {
                  if (!value) {
                    setPrimaryCause(true);
                    setValue('secondIsoOutgTypeId', null);
                  } else {
                    setPrimaryCause(false);
                  }
                  return value === '' ? null : value;
                }}
                search
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <ATMField
                label={Lang.LBL_TROUBLE_JOB_SECONDARY}
                as={ATMDropdown}
                name="secondIsoOutgTypeId"
                selection
                control={control}
                disabled={primaryCause}
                options={troubleJobState.outageType
                  ?.filter((elem) => elem.isoOutgTypeLevel === 'S')
                  .map((value) => {
                    return {
                      key: value.isoOutageTypeId,
                      value: value.isoOutageTypeId,
                      text: value.isoOutgTypeDesc,
                    };
                  })}
                onChange={([_, { value }]) => {
                  return value === '' ? null : value;
                }}
                clearable
                search
                selectOnBlur={false}
                // error={errors.name}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={defaultValues === undefined ? 12 : 8}>
              <ATMField
                label={
                  <LabelRequired>{Lang.LBL_TROUBLE_JOB_DESC}</LabelRequired>
                }
                as={ATMTextArea}
                name="jobDescription"
                control={control}
                error={errors.jobDescription}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </>
      )}
    </ATMGrid>
  );
};

const TroubleJobsForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  beginDateSelected,
  endDateSelected,
  handleSubmit,
  handleEnable,
  setBeginDateSelected,
  setEndDateSelected,
}) => {
  const [isUpdated, setIsUpdated] = useState(false);
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={TroubleJobsFormSchema.cast(defaultValues)}
      validationSchema={TroubleJobsFormSchema}
    >
      {(formProps) => {
        const { formState } = formProps;

        handleEnable(formState.isDirty || isUpdated);

        return (
          <ATMFormProvider {...formProps}>
            <FormContent
              defaultValues={defaultValues}
              setBeginDateSelected={setBeginDateSelected}
              setEndDateSelected={setEndDateSelected}
              beginDateSelected={beginDateSelected}
              endDateSelected={endDateSelected}
              setIsUpdated={setIsUpdated}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default TroubleJobsForm;
