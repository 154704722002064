import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/authorized-list.service';
import {
  IAuthorizedListEmployee,
  IAuthorizedExpiredListSchema,
  IAuthorizedHistoryAdd,
  IAuthorizedList,
  IAuthorizedSubstation,
  IAuthorizedSuspensionHistory,
  IAuthorizedUserDetailsList,
} from 'src/models/authorized-list.model';
import { getEmployeeSort } from 'src/helpers/employee.helper';

export const authorizedListActionTypes = {
  AUTHORIZED_LIST_DATA_READ: 'AUTHORIZED_LIST_DATA_READ',
  AUTHORIZED_LIST_DATA_BY_EMP_ID_READ: 'AUTHORIZED_LIST_DATA_BY_EMP_ID_READ',
  AUTHORIZED_LIST_LIST_READ: 'AUTHORIZED_LIST_LIST_READ',
  AUTHORIZED_LIST_EXPIRED_TRAININGS_READ:
    'AUTHORIZED_LIST_EXPIRED_TRAININGS_READ',
  AUTHORIZED_LIST_CREW_READ: 'AUTHORIZED_LIST_CREW_READ',
  AUTHORIZED_LIST_DATA_CREATE: 'AUTHORIZED_LIST_DATA_CREATE',
  AUTHORIZED_LIST_DATA_UPDATE: 'AUTHORIZED_LIST_DATA_UPDATE',
  AUTHORIZED_LIST_DATA_DELETE: 'AUTHORIZED_LIST_DATA_DELETE',
  AUTHORIZED_LIST_DATA_SET: 'AUTHORIZED_LIST_DATA_SET',
  AUTHORIZED_LIST_ALL_READ: 'AUTHORIZED_LIST_ALL_READ',
  AUTHORIZED_LIST_ALL_USER_READ: 'AUTHORIZED_LIST_ALL_USER_READ',
  AUTHORIZED_DETAILS_DATA_READ: 'AUTHORIZED_DETAILS_DATA_READ',
  AUTHORIZED_DETAILS_CREATE: 'AUTHORIZED_DETAILS_CREATE',
  AUTHORIZED_DETAILS_UPDATE: 'AUTHORIZED_DETAILS_UPDATE',
  AUTHORIZED_LIST_CLEAR_LIST: 'AUTHORIZED_LIST_CLEAR_LIST',
  AUTHORIZED_SUBSTATION_LIST: 'AUTHORIZED_SUBSTATION_LIST',
  AUTHORIZED_SUSPENSION_LIST_HISTORY: 'AUTHORIZED_SUSPENSION_LIST_HISTORY',
  AUTHORIZED_SUSPENSION_LIST_HISTORY_CREATE:
    'AUTHORIZED_SUSPENSION_LIST_HISTORY_CREATE',
  AUTHORIZED_SUSPENSION_LIST_HISTORY_UPDATE:
    'AUTHORIZED_SUSPENSION_LIST_HISTORY_UPDATE',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_READ,
    service: services.dataGET,
  },

  empDataGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_BY_EMP_ID_READ,
    service: services.empDataGET,
  },

  listGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_LIST_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_ALL_READ,
    service: services.listUserGET,
  },

  listExpiringTrainingGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_EXPIRED_TRAININGS_READ,
    service: services.listExpiringTrainingGET,
  },

  listCrewGET: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_CREW_READ,
    service: services.listCrewGET,
  },

  detailsGET: {
    type: authorizedListActionTypes.AUTHORIZED_DETAILS_DATA_READ,
    service: services.detailsGET,
  },

  createPOST: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_DELETE,
    service: services.dataDELETE,
  },

  substationEntryListGET: {
    type: authorizedListActionTypes.AUTHORIZED_SUBSTATION_LIST,
    service: services.substationEntryListGET,
  },

  suspensionHistoryListGET: {
    type: authorizedListActionTypes.AUTHORIZED_SUSPENSION_LIST_HISTORY,
    service: services.suspensionHistoryListGET,
  },

  suspensionHistoryListPOST: {
    type: authorizedListActionTypes.AUTHORIZED_SUSPENSION_LIST_HISTORY_CREATE,
    service: services.suspensionHistoryListPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  // This is a sync action
  setData: (authorizedList?: Partial<IAuthorizedList>) => ({
    type: authorizedListActionTypes.AUTHORIZED_LIST_DATA_SET,
    payload: {
      authorizedList,
    },
  }),

  clearList: () => ({
    type: authorizedListActionTypes.AUTHORIZED_LIST_CLEAR_LIST,
  }),
};

export type IAuthorizedListAsync = typeof duckActions;

export interface IAuthorizedListState
  extends ICommonState<typeof authorizedListActionTypes> {
  data?: IAuthorizedList;
  list: IAuthorizedList[];
  allList: IAuthorizedListEmployee[];
  substationEntryList: IAuthorizedSubstation[];
  listExpired: IAuthorizedExpiredListSchema[];
  total: number;
  crewList: IAuthorizedExpiredListSchema[];
  crewTotal: number;
  employees: number;
  contractors: number;
  history?: IAuthorizedUserDetailsList;
  tempData?: Partial<IAuthorizedList>;
  substationEntryCount: number;
  suspensionList: IAuthorizedSuspensionHistory[];
  suspensionAddList?: Partial<IAuthorizedHistoryAdd>;
}

export const defaultState: IAuthorizedListState = {
  status: {},
  list: [],
  allList: [],
  listExpired: [],
  crewList: [],
  crewTotal: 0,
  tempData: {},
  substationEntryList: [],
  substationEntryCount: 0,
  total: 0,
  employees: 0,
  contractors: 0,
  suspensionList: [],
  suspensionAddList: {},
};

const AuthorizedListReducer = (
  state: IAuthorizedListState,
  action: IReducerAction<IAuthorizedListAsync>
): IAuthorizedListState => {
  switch (action.type) {
    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_SET: {
      return {
        ...state,
        tempData: action.payload?.authorizedList,
      };
    }
    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_BY_EMP_ID_READ:
    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_READ:
    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_UPDATE:
    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_SUSPENSION_LIST_HISTORY_CREATE: {
      return {
        ...state,
        suspensionAddList: action.payload,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_ALL_READ: {
      return {
        ...state,
        allList: action.payload?.rows ?? [],
      };
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_EXPIRED_TRAININGS_READ: {
      return {
        ...state,
        listExpired: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_CREW_READ: {
      return {
        ...state,
        crewList: action.payload?.rows ?? [],
        crewTotal: action.payload?.count ?? 0,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_DETAILS_DATA_READ: {
      return {
        ...state,
        history: action.payload,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.id !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case authorizedListActionTypes.AUTHORIZED_LIST_CLEAR_LIST: {
      return {
        ...state,
        list: [],
      };
    }

    case authorizedListActionTypes.AUTHORIZED_SUBSTATION_LIST: {
      return {
        ...state,
        substationEntryList: getEmployeeSort(
          action.payload?.rows ?? [],
          'employee.lastName'
        ),
        substationEntryCount: action.payload?.count ?? 0,
      };
    }

    case authorizedListActionTypes.AUTHORIZED_SUSPENSION_LIST_HISTORY: {
      return {
        ...state,
        suspensionList: action.payload?.rows ?? [],
      };
    }

    default: {
      return state;
    }
  }
};

export default AuthorizedListReducer;
