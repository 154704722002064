import { outageTypeActionTypes, IOutageTypeState } from 'src/ducks/outage-type.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getOutageTypeStatus = (
  state: IOutageTypeState,
  action: keyof typeof outageTypeActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
