import yup from 'src/libraries/validator.library';

const SharepointResponseShape = {
  uploadFileResponse: yup.array().of(yup.string()).required(),
  mccListResponse: yup.string().required(),
  createFolderResponse: yup.boolean().required(),
  deleteDocumentResponse: yup.boolean().required(),
  cachedToken: yup.string().required(),
};

const SharepointRequestShape = {
  accessToken: yup.string().required(),
  parentFolder: yup.string().required(),
  folderName: yup.string().required(),
  documentLibrary: yup.string().required(),
  documentId: yup.string().required(),
  createdBy: yup.string().required(),
  fileId: yup.string().required(),
  fileName: yup.string().required(),
};

const SharepointRequestDataShape = {
  ...SharepointRequestShape,
  requestor: yup.string().required(),
  requestId: yup.number().required(),
};

const SharepointPropertyShape = {
  dbNameEquivalent: yup.string().required(),
  documentLibraryId: yup.string().required(),
  documentLibraryName: yup.string().required(),
};

const MccListDataShape = {
  xmlns: yup.string().required(),
  listName: yup.string().required(),
  baseUrl: yup.string().required(),
  documentLibrary: yup.string().required(),
  parentDirectory: yup.string().required(),
  sourceDirectory: yup.string().required(),
  accessToken: yup.string().required(),
  includeMandatoryColumns: yup.string().required(),
  includeAttachmentUrls: yup.string().required(),
  attributeName: yup.string().required(),
  attributeNameId: yup.string().required(),
  dateInUtc: yup.string().required(),
  rowLimit: yup.string().required(),
  webID: yup.string().required(),
};

const UploadDataShape = {
  xmlns: yup.string().required(),
  createdBy: yup.string().required(),
  documentLibrary: yup.string().required(),
  parentDirectory: yup.string().required(),
  sourceFolder: yup.string().required(),
  accessToken: yup.string().required(),
  fileName: yup.string().required(),
  fileStream: yup.string().required(),
  displayName: yup.string().required(),
  internalName: yup.string().required(),
  type: yup.string().required(),
};

const CreateFolderDataShape = {
  xmlns: yup.string().required(),
  parentDirectory: yup.string().required(),
  folderName: yup.string().required(),
  accessToken: yup.string().required(),
  documentLibrary: yup.string().required(),
};

const DeleteDocumentShape = {
  xmlns: yup.string().required(),
  listName: yup.string().required(),
  onError: yup.string().required(),
  cmd: yup.string().required(),
  id: yup.string().required(),
  fieldNameRef: yup.string().required(),
  fieldNameId: yup.string().required(),
  fieldNameRefValue: yup.string().required(),
  fieldNameIdValue: yup.string().required(),
  accessToken: yup.string().required(),
};

const AccessTokenPayloadShape = {
  accessToken: yup.string().required(),
  tokenType: yup.string().required(),
  expiresIn: yup.number().required(),
  scope: yup.string().required(),
};
const UploadedFileDataShape = {
  fileName: yup.string().required(),
  uploadedDate: yup.string().required(),
  uploadedBy: yup.string().required(),
  fileId: yup.string().required(),
};

export const AccessTokenPayloadSchema = yup
  .object(AccessTokenPayloadShape)
  .camelCase();

export const SharepointResponseSchema = yup.object(SharepointResponseShape);
export const SharepointRequestSchema = yup.object(SharepointRequestShape);
export const SharepointRequestDataSchema = yup.object(
  SharepointRequestDataShape
);
export const SharepointPropertySchema = yup.object(SharepointPropertyShape);
export const MccListDataSchema = yup.object(MccListDataShape);
export const UploadDataSchema = yup.object(UploadDataShape);
export const CreateFolderSchema = yup.object(CreateFolderDataShape);
export const UploadedFileDataSchema = yup.object(UploadedFileDataShape);
export const DeleteDocumentSchema = yup.object(DeleteDocumentShape);

export type ISharepointResponse = yup.Asserts<typeof SharepointResponseSchema>;
export type ISharepointRequest = yup.Asserts<typeof SharepointRequestSchema>;
export type ISharepointRequestData = yup.Asserts<
  typeof SharepointRequestDataSchema
>;
export type ISharepointAccessToken = yup.Asserts<
  typeof AccessTokenPayloadSchema
>;
export type ISharepointProperty = yup.Asserts<typeof SharepointPropertySchema>;
export type IMccListData = yup.Asserts<typeof MccListDataSchema>;
export type IUploadData = yup.Asserts<typeof UploadDataSchema>;
export type ICreateFolderData = yup.Asserts<typeof CreateFolderSchema>;
export type IUploadedFileData = yup.Asserts<typeof UploadedFileDataSchema>;
export type IDeleteDocumentData = yup.Asserts<typeof DeleteDocumentSchema>;
