import QueryString from 'qs';
import { Location } from 'history';

export const getURLString = (
  location: Location<unknown>,
  data: Record<string, any>
) => {
  let path = location.pathname;

  if (data && Object.keys(data).length >= 1) {
    const urlReadyQuery = QueryString.stringify(data);
    path += `?${urlReadyQuery}`;
  }

  return path;
};

export const getURLParams = (location: Location<unknown>) => {
  return QueryString.parse(location?.search || '', {
    ignoreQueryPrefix: true,
  });
};
