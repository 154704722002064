import yup from 'src/libraries/validator.library';

export const SignificantCostShape = {
  significantCostId: yup.number().required(),
  description: yup.string().required(),
  emailGroup: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const SignificantCostSchema = yup.object(SignificantCostShape);

export const SignificantCostFormSchema = yup.object({
  description: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const SignificantCostListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SignificantCostSchema.clone()),
});

export const SignificantCostPayloadSchema = yup.object({
  significantCost: SignificantCostSchema.clone(),
});

export const SignificantCostDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISignificantCost = yup.Asserts<typeof SignificantCostSchema>;
export type ISignificantCostForm = yup.Asserts<
  typeof SignificantCostFormSchema
>;
