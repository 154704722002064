import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IUserGrpForm } from 'src/models/facility.model';
import { IUserGroup } from 'src/models/user-group.model';
import FacilityNotifApprovalsItemForm from '../facility-notif-approvals-item-form/facility-notif-approvals-item-form.component';
import style from '../../facility-notification-items.module.scss';

type IProps = {
  isOpen: boolean;
  isDirty: boolean;
  loading: boolean;
  userGroup: IUserGroup[];
  defaultValues?: Partial<IUserGrpForm>;
  formRef: React.RefObject<HTMLFormElement>;
  handleSave: () => void;
  handleOpen: (data) => void;
  handleEnable: (data) => void;
  handleSubmit: (data) => void;
};

const FacilityNotifApprovalsItemEditView: React.FC<IProps> = ({
  isOpen,
  formRef,
  isDirty,
  loading,
  userGroup,
  defaultValues,
  handleOpen,
  handleSave,
  handleEnable,
  handleSubmit,
}) => {
  const actionBtns = (
    <div className={style.actionBtns}>
      <ATMButton
        secondary
        type="button"
        content={Lang.LBL_CANCEL}
        onClick={() => handleOpen(!isOpen)}
      />
      <ATMButton
        primary
        type="button"
        content={Lang.LBL_SAVE}
        loading={loading}
        disabled={!isDirty || loading}
        onClick={handleSave}
      />
    </div>
  );
  return (
    <ATMModal
      size="small"
      open={isOpen}
      trigger={
        <ATMDropdown.Item
          icon="edit"
          text={Lang.LBL_EDIT}
          onClick={() => handleOpen(!isOpen)}
        />
      }
      header={Lang.formatString(
        Lang.LBL_EDIT_GROUP,
        defaultValues?.userGrpNm ?? '--'
      )}
      content={
        <div className={style.content}>
          <FacilityNotifApprovalsItemForm
            edit
            formRef={formRef}
            userGroup={userGroup}
            defaultValues={defaultValues}
            handleEnable={handleEnable}
            handleSubmit={handleSubmit}
          />
        </div>
      }
      actions={actionBtns}
    />
  );
};

export default FacilityNotifApprovalsItemEditView;
