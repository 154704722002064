import { AxiosRequestConfig } from 'axios';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  MapOfOutageByVoltSchema,
} from 'src/models/calendar-outage.model';

const client = httpClient();
const endpoint = 'outage-date-calendar';

const services = {
  listGET: async (
    data?: Partial<IORGDataTableQueryState>,
    config: AxiosRequestConfig = {}
  ) => {
    return client.get(`/${endpoint}`, data, MapOfOutageByVoltSchema, config);
  },
};

export default services;
