import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TnaReportReducer, {
  ITnaReportState,
  defaultState,
  duckActions,
} from 'src/ducks/tna-report.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TnaReportReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITnaReportContext = ReturnType<typeof useReducer>;

const TnaReportContext = createContext<Partial<ITnaReportContext>>({
  state: defaultState,
}) as React.Context<ITnaReportContext>;

type IProps = {
  state?: Partial<ITnaReportState>;
};

const TnaReportProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TnaReportContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TnaReportContext.Provider>
  );
};

const useTnaReportContext = () => useContext(TnaReportContext);

export type IUseTnaReportContext = ReturnType<typeof useTnaReportContext>;

export { TnaReportContext, TnaReportProvider, useTnaReportContext };
