import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SwitchingReducer, {
  ISwitchingState,
  defaultState,
  duckActions,
} from 'src/ducks/switching.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SwitchingReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISwitchingContext = ReturnType<typeof useReducer>;

const SwitchingContext = createContext<Partial<ISwitchingContext>>({
  state: defaultState,
}) as React.Context<ISwitchingContext>;

type IProps = {
  state?: Partial<ISwitchingState>;
};

const SwitchingProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SwitchingContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SwitchingContext.Provider>
  );
};

const useSwitchingContext = () => useContext(SwitchingContext);

export type IUseSwitchingContext = ReturnType<typeof useSwitchingContext>;

export { SwitchingContext, SwitchingProvider, useSwitchingContext };
