/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { debounce } from 'lodash';
import {
  ATMButton,
  ATMModal,
  ATMGrid,
  ATMTable,
  ATMField,
  ATMDropdown,
  ATMInput,
  ATMCheckbox,
  useATMFormContext,
  ATMForm,
  ATMFormProvider,
  ATMDatePicker,
  formatTime,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import {
  AccessRole,
  TROUBLE_JOB_ALARM_STATION,
  TROUBLE_JOB_ALARM_EQUIPMENT,
} from 'src/constants';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useSwitchingContext } from 'src/contexts/switching.context';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { switchingActionTypes } from 'src/ducks/switching.duck';
import { getIsAdmin } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IRealTimeLogLine } from 'src/models/real-time-log.model';
import { ISubstation } from 'src/models/substation.model';
import {
  EtsFormDispatchSchema,
  IEtsFormDispatch,
  ISortResponse,
  ISwitching,
} from 'src/models/switching.model';
import { ITroubleJobs } from 'src/models/trouble-jobs.model';
import { getSwitchingStatus } from 'src/selectors/switching.selector';
import Moment, { createDateTime, format24hTime } from 'src/libraries/moment.library';
import {
  HourTimePattern,
  restrictAlphabetsAndSpecialChars,
} from 'src/components/atoms/input/time-input-switching-format.component';
import style from './trouble-jobs-ets-dispatch.module.scss';

type IProps = {
  defaultValues?: Partial<ITroubleJobs>;
  isOpenEts: boolean;
  setIsOpenEts: React.Dispatch<React.SetStateAction<boolean>>;
};

type IPropsContent = {
  defaultValues?: Partial<ITroubleJobs>;
  isOpenEts: boolean;
  setIsOpenEts: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: () => void;
};

const FormContent: React.FC<IPropsContent> = ({
  defaultValues,
  handleClick,
  isOpenEts,
  setIsOpenEts,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useATMFormContext<IEtsFormDispatch>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'etsDispatch',
  });
  const { state: realTimeLogState } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: switchingState } = useSwitchingContext();

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  const loading = getSwitchingStatus(
    switchingState,
    switchingActionTypes.SWITCHING_REQUEST_DATA_CREATE_TROUBLE_JOB
  );
  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>('');
  const [etsId, setEtsId] = useState<string>(''); // 2nd dropdown;
  const [isDirty, setIsDirty] = useState(false);
  const lineSubstationOptions = [
    {
      key: 1,
      text: 'Switch',
      value: 'switch',
    },
    {
      key: 2,
      text: 'Tie Line',
      value: 'tieLine',
    },
    {
      key: 3,
      text: 'Substation',
      value: 'substation',
    },
  ];

  const switchOptions = switchingState.listDropdown.map((val, i) => {
    return {
      key: `${val.description}_${i}`,
      text: val.description,
      value: val.description,
    };
  });

  const lineOptions = realTimeLogState.line
    .filter((val) => val.outgFacNm.includes('TL'))
    .map((val, i) => {
      return {
        key: `${val.outgFacNm}_${i}`,
        text: val.outgFacNm,
        value: val.outgFacId,
      };
    });

  const substationOptions = substationState.list.map((val, i) => {
    return {
      key: `${val.name}_${i}`,
      text: val.name,
      value: val.substationId,
    };
  });

  const handleAddTieLine = useCallback(
    (id: string) => {
      const facilityInfo: IRealTimeLogLine[] = realTimeLogState.line?.filter(
        (val) => val.outgFacId === Number(id)
      );
      if (facilityInfo && facilityInfo.length > 0) {
        facilityInfo[0].substations?.map((val: ISubstation) => {
          append({
            tlSub: `${
              facilityInfo[0].outgFacNm.includes('(')
                ? facilityInfo[0].outgFacNm.split('(')[0].replace(' ', '')
                : facilityInfo[0].outgFacNm.split('at')[0].replace(' ', '')
            } ${val.substationId}`.replace(/\s\s+/g, ' '),
            subPole: val.name,
            subDistId: val.subDistId ?? '',
            outgFacId: Number(id),
            poleId: null,
            substationId: val.substationId,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            back: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            switchId: null,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
          });
        });
      }
    },
    [append, control, etsId]
  );

  const handleAddSwitch = useCallback(
    (id: string) => {
      const swicthInfo: ISwitching[] = switchingState.list?.filter(
        (val) => val.description === id
      );
      if (swicthInfo && swicthInfo.length > 0) {
        swicthInfo.map((val: ISwitching) => {
          append({
            tlSub: `${val.description}`,
            subPole: `POLE${val.poleId}`,
            subDistId: val.subDistId ?? '',
            outgFacId: val.outgFacId,
            poleId: val.poleId,
            substationId: null,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            back: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            switchId: val.switchId,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
          });
        });
      }
    },
    [append, control, etsId]
  );

  const handleAddSubstation = useCallback(
    (id: string) => {
      const substationInfo: ISubstation[] = substationState.list?.filter(
        (val) => val.substationId === id
      );
      if (substationInfo && substationInfo.length > 0) {
        substationInfo.map((val: ISubstation) => {
          append({
            tlSub: `${val.substationId}`,
            subPole: val.name,
            subDistId: val.subDistId ?? '',
            outgFacId: null,
            poleId: null,
            substationId: id,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            back: {
              byAt: 'AT',
              sortTime: undefined as any,
            },
            switchId: null,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
          });
        });
      }
    },
    [append, control, etsId]
  );

  const handleUpdate = useCallback(
    (index, values) => {
      update(index, {
        ...values,
      });
    },
    [update]
  );

  // Group sort responses by type[Tie Line, Substation, Switch]
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // eslint-disable-next-line no-param-reassign
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  useEffect(() => {
    remove();
    setLineSubstationSelect('');
    setEtsId('');
    setIsDirty(false);

    // Setting default from selected line/station
    if (defaultValues?.outgFacId) {
      handleAddTieLine(defaultValues.outgFacId.toString());
    }
    if (defaultValues?.substationId) {
      handleAddSubstation(defaultValues.substationId);
    }

    // Setting default from already save switching request
    if (defaultValues?.sortResponses) {
      const sortGroupBy = Object.entries(
        groupBy(defaultValues?.sortResponses, 'tlSub')
      ).map(([_, val]) => val) as ISortResponse[];
      sortGroupBy.map((val) => {
        let exists = false;
        if (val) {
          if (val[0].outgFacId && val[0].substationId) {
            exists = fields.some(
              (field) => field.outgFacId === defaultValues.outgFacId
            );
            return !exists ? handleAddTieLine(val[0].outgFacId.toString()) : '';
          }
          if (val[0].substationId && val[0].outgFacId === null) {
            exists = fields.some(
              (field) =>
                field.substationId === defaultValues.substationId &&
                field.outgFacId === null
            );
            return !exists ? handleAddSubstation(val[0].substationId) : '';
          }
          return handleAddSwitch(val[0].tlSub);
        }
        return '';
      });
    }
  }, [isOpenEts, setIsOpenEts]);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  const handleEmptyValue = useCallback(
    (index, timeValue, record: any, isOutTime = false) => {
      if (!timeValue || timeValue?.length < 1) {
        if (isOutTime) {
          clearErrors(`etsDispatch.[${index}].outDate` as any);
        } else {
          clearErrors(`etsDispatch.[${index}].backDate` as any);
        }
        update(index, {
          ...record,
          outTime: isOutTime ? undefined : record.outTime,
          backTime: !isOutTime ? undefined : record.backTime,
        });
      }
    },
    [update, clearErrors]
  );

  const handleUpdateDebounce = useCallback(
    debounce((index, timeValue, value, isOutTime = false) => {
      handleEmptyValue(
        index,
        timeValue,
        {
          ...value,
        },
        isOutTime
      );
    }, 50),
    [handleEmptyValue]
  );

  const hasErrorsBackTime = useCallback(
    (index) => {
      let hasError = false;
      if (
        errors?.etsDispatch?.[index]?.backTime?.message === 'Invalid Time' ||
        errors?.etsDispatch?.[index]?.backTime?.message === 'Please enter time'
      ) {
        hasError = true;
      }
      return hasError;
    },
    [errors]
  );

  const hasErrorsOutTime = useCallback(
    (index) => {
      let hasError = false;
      if (
        errors?.etsDispatch?.[index]?.outTime?.message === 'Invalid Time' ||
        errors?.etsDispatch?.[index]?.outTime?.message === 'Please enter time'
      ) {
        hasError = true;
      }
      return hasError;
    },
    [errors]
  );

  return getIsAdmin(AccessRole.TROUBLE_JOB_UPDATER) &&
    defaultValues &&
    defaultValues.jobStatus !== 'Closed' ? (
    <ATMModal
      open={isOpenEts}
      size="large"
      trigger={
        <ATMButton
          content="Add Switching Requests"
          onClick={() => setIsOpenEts(true)}
          secondary
          size="small"
          type="button"
        />
      }
    >
      <ATMModal.Header as="h1">{Lang.TTL_ETS_DISPATCH}</ATMModal.Header>
      <ATMModal.Content
        style={{ marginBottom: '100px', height: '70vh', overflowY: 'auto' }}
      >
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column width={5}>
              <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
                {defaultValues?.substationId && defaultValues.outgFacId
                  ? `${Lang.FACILITY_TYPE[2]} - ${defaultValues?.substation?.name}`
                  : `${Lang.FACILITY_TYPE[1]} - ${defaultValues?.facility?.outgFacNm}`}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              <FieldReadOnly label={Lang.LBL_KV}>
                {defaultValues?.facility?.volt?.voltNm}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              <FieldReadOnly label={Lang.LBL_EQUIPMENT}>
                {defaultValues?.substationId && defaultValues.outgFacId
                  ? defaultValues?.facility?.outgFacNm
                  : ''}
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>

          <ATMGrid.Row style={{ background: '#f1f2f3' }}>
            <ATMGrid.Column width={3}>
              <ATMField
                as={ATMDropdown}
                selection
                size="small"
                name="lineSubstation"
                clearable
                value={lineSubstationSelect}
                options={lineSubstationOptions}
                onChange={
                  ((_, { value }) => {
                    if (!value) {
                      setEtsId('');
                      setLineSubstationSelect('');
                    } else {
                      setLineSubstationSelect(value);
                    }
                    return value;
                  }) as any
                }
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={3}>
              {lineSubstationSelect === 'switch' && (
                <ATMField
                  as={ATMDropdown}
                  selection
                  size="small"
                  name="lineSubstation"
                  options={switchOptions}
                  onChange={
                    ((_, { value }) => {
                      if (value) {
                        setEtsId(value);
                      } else setEtsId('');
                      return value;
                    }) as any
                  }
                  clearable
                  disabled={!lineSubstationSelect}
                  selectOnBlur={false}
                  search
                />
              )}
              {lineSubstationSelect === 'tieLine' && (
                <ATMField
                  as={ATMDropdown}
                  selection
                  size="small"
                  name="lineSubstation"
                  options={lineOptions}
                  onChange={
                    ((_, { value }) => {
                      if (value) {
                        setEtsId(value);
                      } else setEtsId('');
                      return value;
                    }) as any
                  }
                  clearable
                  disabled={!lineSubstationSelect}
                  selectOnBlur={false}
                  search
                />
              )}{' '}
              {lineSubstationSelect === 'substation' && (
                <ATMField
                  as={ATMDropdown}
                  selection
                  size="small"
                  name="lineSubstation"
                  options={substationOptions}
                  onChange={
                    ((_, { value }) => {
                      if (value) {
                        setEtsId(value);
                      } else setEtsId('');
                      return value;
                    }) as any
                  }
                  clearable
                  selectOnBlur={false}
                  search
                />
              )}
              {lineSubstationSelect === '' && (
                <ATMField
                  as={ATMDropdown}
                  selection
                  size="small"
                  name="lineSubstation"
                  disabled
                />
              )}
            </ATMGrid.Column>
            <ATMGrid.Column width={2}>
              <ATMButton
                type="button"
                content="Add"
                secondary
                disabled={!lineSubstationSelect || !etsId}
                onClick={() => {
                  if (lineSubstationSelect === 'tieLine') {
                    handleAddTieLine(etsId);
                    setLineSubstationSelect('');
                    setEtsId('');
                  } else if (lineSubstationSelect === 'switch') {
                    handleAddSwitch(etsId);
                    setLineSubstationSelect('');
                    setEtsId('');
                  } else if (lineSubstationSelect === 'substation') {
                    handleAddSubstation(etsId);
                    setLineSubstationSelect('');
                    setEtsId('');
                  }
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMTable celled>
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.HeaderCell content={' '} />
                  <ATMTable.HeaderCell content={Lang.LBL_ETS} />
                  <ATMTable.HeaderCell content={Lang.LBL_OUT} />
                  <ATMTable.HeaderCell content={Lang.LBL_DATE} />
                  <ATMTable.HeaderCell content={Lang.LBL_TIME} />
                  <ATMTable.HeaderCell content={Lang.LBL_BACK} />
                  <ATMTable.HeaderCell content={Lang.LBL_DATE} />
                  <ATMTable.HeaderCell content={Lang.LBL_TIME} />
                  <ATMTable.HeaderCell content={Lang.LBL_TL_SUB} />
                  <ATMTable.HeaderCell content={Lang.LBL_SUB_POLE} />
                  <ATMTable.HeaderCell content={Lang.LBL_AREA} />
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {fields && fields.length > 0
                  ? fields.map((val, i) => {
                      return (
                        <ATMTable.Row key={val.id}>
                          {/* hidden */}
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].tlSub`}
                            defaultValue={val.tlSub}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].subPole`}
                            defaultValue={val.subPole}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].subDistId`}
                            defaultValue={val.subDistId}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].outgFacId`}
                            defaultValue={val.outgFacId}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].switchId`}
                            defaultValue={val.switchId}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].substationId`}
                            defaultValue={val.substationId}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`etsDispatch[${i}].poleId`}
                            defaultValue={val.poleId}
                            type="hidden"
                            className="hidden"
                          />
                          <ATMTable.Cell className={style.etsInput}>
                            <ATMField
                              as={ATMCheckbox}
                              name={`etsDispatch[${i}].isChecked`}
                              control={control}
                              defaultValue={val.isChecked}
                              onChange={([_, { checked }]) => {
                                if (
                                  checked === true &&
                                  getValues().etsDispatch
                                ) {
                                  update(i, {
                                    ...val,
                                    isChecked: checked,
                                  });
                                  setIsDirty(true);
                                }
                                return checked;
                              }}
                              className={
                                !val.subDistId || !val.tlSub || !val.subPole
                                  ? 'hidden'
                                  : ''
                              }
                            />
                          </ATMTable.Cell>
                          <ATMTable.Cell className={style.etsInput}>
                            <ATMField
                              as={ATMInput}
                              name={`etsDispatch[${i}].etmNbr`}
                              control={control}
                              defaultValue={val.etmNbr}
                            />
                          </ATMTable.Cell>
                          <ATMTable.Cell className={style.outDateTime}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <ATMField
                                as={ATMDropdown}
                                selection
                                name={`etsDispatch[${i}].out.byAt`}
                                onChange={([_, { value }]) => value}
                                options={[
                                  { key: 'AT', value: 'AT', text: 'AT' },
                                  { key: 'BY', value: 'BY', text: 'BY' },
                                ]}
                                control={control}
                                defaultValue={val.out.byAt}
                                error={errors.etsDispatch?.[i]?.out?.byAt}
                              />
                            </div>
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            <div style={{ display: 'flex' }}>
                              <ATMDatePicker
                                size="small"
                                format="MM-DD-YYYY"
                                placeholder="MM/DD/YYYY"
                                className={style.fieldDatePicker}
                                name={`etsDispatch[${i}].outDate`}
                                value={
                                  getValues()?.etsDispatch?.[i]?.outDate ??
                                  undefined
                                }
                                onChange={(_, { value }) => {
                                  if (value) {
                                    clearErrors(
                                      `etsDispatch.[${i}].outDate` as any
                                    );
                                    const timeVal = format24hTime(
                                      getValues()?.etsDispatch?.[i]?.out
                                        ?.sortTime
                                    ) as any;
                                    if (timeVal) {
                                      update(i, {
                                        ...val,
                                        outDate: Moment(
                                          value as unknown as any
                                        ).toDate(),
                                        out: {
                                          byAt: val.out.byAt,
                                          sortTime: createDateTime(
                                            Moment(value as unknown as any),
                                            timeVal
                                          ).toDate(),
                                        },
                                      });
                                    } else {
                                      const sortDateTimeValue =
                                        val.outTime &&
                                        val.outTime.match(
                                          HourTimePattern.HOUR_TIME_PATTERN as any
                                        ) &&
                                        val.outTime;
                                      update(i, {
                                        ...val,
                                        outDate: Moment(
                                          value as unknown as any
                                        ).toDate(),
                                        out: {
                                          byAt: val.out.byAt,
                                          sortTime:
                                            ((sortDateTimeValue &&
                                              createDateTime(
                                                value as any,
                                                sortDateTimeValue
                                              ).toDate()) as any) ?? undefined,
                                        },
                                      });
                                    }
                                  } else {
                                    clearErrors(
                                      `etsDispatch.[${i}].outDate` as any
                                    );
                                    if (!val.outTime) {
                                      clearErrors(
                                        `etsDispatch[${i}].outTime` as any
                                      );
                                    }
                                    handleUpdate(i, {
                                      ...val,
                                      outDate: value,
                                    });
                                  }
                                  return value;
                                }}
                                clearable
                              />
                            </div>
                            <span>
                              <ATMField
                                control={control}
                                as={ATMInput}
                                name={`etsDispatch[${i}].outDate`}
                                defaultValue=""
                                type="hidden"
                                error={errors?.etsDispatch?.[i]?.outDate}
                                className={style.timeError}
                              />
                            </span>
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            <div style={{ display: 'flex' }}>
                              <ATMInput
                                className={style.fieldTimePicker}
                                autoComplete="off"
                                maxLength={5}
                                placeholder="hh:mm"
                                name={`etsDispatch.[${i}].outTime`}
                                control={control}
                                value={
                                  getValues()?.etsDispatch?.[i]?.outTime ??
                                  undefined
                                }
                                onChange={(_, { value }) => {
                                  const timeValue =
                                    value && value.length > 3
                                      ? formatTime(
                                          restrictAlphabetsAndSpecialChars(
                                            value
                                          )
                                        )
                                      : restrictAlphabetsAndSpecialChars(value);
                                  if (timeValue) {
                                    if (timeValue.length > 4) {
                                      handleTimeUpdate(
                                        timeValue,
                                        `etsDispatch.[${i}].outTime`
                                      );
                                      if (
                                        timeValue.match(
                                          HourTimePattern.HOUR_TIME_PATTERN as any
                                        )
                                      ) {
                                        clearErrors(
                                          `etsDispatch[${i}].outTime` as any
                                        );
                                        const date =
                                          getValues()?.etsDispatch?.[i]?.out
                                            ?.sortTime;
                                        if (date) {
                                          update(i, {
                                            ...val,
                                            outTime: timeValue,
                                            out: {
                                              byAt: val.out.byAt,
                                              sortTime: createDateTime(
                                                Moment(date as unknown as any),
                                                timeValue
                                              ).toDate(),
                                            },
                                          });
                                        } else {
                                          update(i, {
                                            ...val,
                                            outTime: timeValue,
                                            out: {
                                              byAt: val.out.byAt,
                                              sortTime:
                                                ((val.outDate &&
                                                  createDateTime(
                                                    Moment(
                                                      val.outDate as unknown as any
                                                    ).toDate(),
                                                    timeValue
                                                  ).toDate()) as any) ??
                                                undefined,
                                            },
                                          });
                                        }
                                      } else {
                                        update(i, {
                                          ...val,
                                          outTime: timeValue,
                                        });
                                        setError(
                                          `etsDispatch[${i}].outTime` as any,
                                          {
                                            message: 'Invalid Time',
                                          }
                                        );
                                      }
                                    }
                                  } else {
                                    clearErrors(
                                      `etsDispatch[${i}].outTime` as any
                                    );
                                  }
                                  handleUpdateDebounce(
                                    i,
                                    timeValue,
                                    {
                                      ...val,
                                      outTime: timeValue,
                                    },
                                    true
                                  );

                                  return timeValue;
                                }}
                                error={hasErrorsOutTime(i)}
                                clearable
                              />
                            </div>
                            <span>
                              <ATMField
                                control={control}
                                as={ATMInput}
                                name={`etsDispatch.[${i}].outTime`}
                                defaultValue=""
                                type="hidden"
                                error={errors?.etsDispatch?.[i]?.outTime}
                                className={style.timeError}
                              />
                            </span>
                          </ATMTable.Cell>
                          <ATMTable.Cell className={style.backDateTime}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <ATMField
                                as={ATMDropdown}
                                selection
                                name={`etsDispatch[${i}].back.byAt`}
                                options={[
                                  { key: 'AT', value: 'AT', text: 'AT' },
                                  { key: 'BY', value: 'BY', text: 'BY' },
                                ]}
                                onChange={([_, { value }]) => value}
                                control={control}
                                error={errors.etsDispatch?.[i]?.back?.byAt}
                                defaultValue={val.back.byAt ?? 'AT'}
                                disabled={
                                  defaultValues?.substation?.substationId ===
                                    TROUBLE_JOB_ALARM_STATION &&
                                  defaultValues?.facility?.outgFacNm ===
                                    TROUBLE_JOB_ALARM_EQUIPMENT
                                }
                              />
                            </div>
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <ATMDatePicker
                                size="small"
                                format="MM-DD-YYYY"
                                placeholder="MM/DD/YYYY"
                                className={style.fieldDatePicker}
                                name={`etsDispatch[${i}].backDate`}
                                value={
                                  getValues()?.etsDispatch?.[i]?.backDate ??
                                  undefined
                                }
                                onChange={(_, { value }) => {
                                  if (value) {
                                    clearErrors(
                                      `etsDispatch.[${i}].backDate` as any
                                    );
                                    const timeVal = format24hTime(
                                      getValues()?.etsDispatch?.[i]?.back
                                        ?.sortTime
                                    ) as any;
                                    if (timeVal) {
                                      update(i, {
                                        ...val,
                                        backDate: Moment(
                                          value as unknown as any
                                        ).toDate(),
                                        back: {
                                          byAt: val.back.byAt,
                                          sortTime: createDateTime(
                                            Moment(value as unknown as any),
                                            timeVal
                                          ).toDate(),
                                        },
                                      });
                                    } else {
                                      const sortDateTimeValue =
                                        val.backTime &&
                                        val.backTime.match(
                                          HourTimePattern.HOUR_TIME_PATTERN as any
                                        ) &&
                                        val.backTime;
                                      update(i, {
                                        ...val,
                                        backDate: Moment(
                                          value as unknown as any
                                        ).toDate(),
                                        back: {
                                          byAt: val.back.byAt,
                                          sortTime:
                                            ((sortDateTimeValue &&
                                              createDateTime(
                                                Moment(value as any).toDate(),
                                                sortDateTimeValue
                                              ).toDate()) as Date) ?? undefined,
                                        },
                                      });
                                    }
                                  } else {
                                    clearErrors(
                                      `etsDispatch.[${i}].backDate` as any
                                    );
                                    if (!val.backTime) {
                                      clearErrors(
                                        `etsDispatch.[${i}].backTime` as any
                                      );
                                    }
                                    update(i, {
                                      ...val,
                                      backDate: value,
                                    });
                                  }

                                  return value;
                                }}
                                disabled={
                                  defaultValues?.substation?.substationId ===
                                    TROUBLE_JOB_ALARM_STATION &&
                                  defaultValues?.facility?.outgFacNm ===
                                    TROUBLE_JOB_ALARM_EQUIPMENT
                                }
                                clearable
                              />
                            </div>
                            <span>
                              <ATMField
                                control={control}
                                as={ATMInput}
                                name={`etsDispatch[${i}].backDate`}
                                defaultValue=""
                                type="hidden"
                                error={errors?.etsDispatch?.[i]?.backDate}
                                className={style.timeError}
                              />
                            </span>
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            <div style={{ display: 'flex' }}>
                              <ATMInput
                                className={style.fieldTimePicker}
                                autoComplete="off"
                                maxLength={5}
                                placeholder="hh:mm"
                                control={control}
                                name={`etsDispatch[${i}].backTime`}
                                value={
                                  getValues()?.etsDispatch?.[i]?.backTime ??
                                  undefined
                                }
                                onChange={(_, { value }) => {
                                  const timeValue =
                                    value && value.length > 3
                                      ? formatTime(
                                          restrictAlphabetsAndSpecialChars(
                                            value
                                          )
                                        )
                                      : restrictAlphabetsAndSpecialChars(value);
                                  if (timeValue) {
                                    if (timeValue.length > 4) {
                                      handleTimeUpdate(
                                        timeValue,
                                        `etsDispatch[${i}].backTime`
                                      );
                                      if (
                                        timeValue.match(
                                          HourTimePattern.HOUR_TIME_PATTERN as any
                                        )
                                      ) {
                                        clearErrors(
                                          `etsDispatch.[${i}].backTime` as any
                                        );
                                        const date =
                                          getValues()?.etsDispatch?.[i]?.back
                                            ?.sortTime;
                                        if (date) {
                                          update(i, {
                                            ...val,
                                            backTime: timeValue,
                                            back: {
                                              byAt: val.back.byAt,
                                              sortTime: createDateTime(
                                                Moment(date),
                                                timeValue
                                              ).toDate(),
                                            },
                                          });
                                        } else {
                                          update(i, {
                                            ...val,
                                            backTime: timeValue,
                                            back: {
                                              byAt: val.back.byAt,
                                              sortTime:
                                                ((val.backDate &&
                                                  createDateTime(
                                                    Moment(
                                                      val.backDate as unknown as any
                                                    ).toDate(),
                                                    timeValue
                                                  ).toDate()) as any) ??
                                                undefined,
                                            },
                                          });
                                        }
                                      } else {
                                        update(i, {
                                          ...val,
                                          backTime: timeValue,
                                        });
                                        setError(
                                          `etsDispatch.[${i}].backTime` as any,
                                          {
                                            message: 'Invalid Time',
                                          }
                                        );
                                      }
                                    }
                                  } else {
                                    setImmediate(() => {
                                      clearErrors(
                                        `etsDispatch.[${i}].backTime` as any
                                      );
                                    });
                                  }
                                  handleUpdateDebounce(
                                    i,
                                    timeValue,
                                    {
                                      ...val,
                                      backTime: timeValue,
                                    },
                                    false
                                  );
                                  return timeValue;
                                }}
                                error={hasErrorsBackTime(i)}
                                clearable
                              />
                            </div>
                            <span>
                              <ATMField
                                control={control}
                                as={ATMInput}
                                name={`etsDispatch[${i}].backTime`}
                                defaultValue=""
                                type="hidden"
                                error={errors?.etsDispatch?.[i]?.backTime}
                                className={style.timeError}
                              />
                            </span>
                          </ATMTable.Cell>
                          <ATMTable.Cell>{val.tlSub}</ATMTable.Cell>
                          <ATMTable.Cell>{val.subPole}</ATMTable.Cell>
                          <ATMTable.Cell>{val.subDistId}</ATMTable.Cell>
                        </ATMTable.Row>
                      );
                    })
                  : ''}
              </ATMTable.Body>
            </ATMTable>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => setIsOpenEts(false)}
          type="button"
        />
        <ATMButton
          primary
          content="Add Switching Request"
          disabled={loading.fetching || !isDirty}
          onClick={handleClick}
          loading={loading.fetching}
          type="button"
        />
      </ATMModal.Actions>
    </ATMModal>
  ) : null;
};

const TroubleJobsEtsDispatch: React.FC<IProps> = ({
  defaultValues,
  //   handleFetch,
  isOpenEts,
  setIsOpenEts,
}) => {
  const { actions } = useSwitchingContext();
  const { actions: troubleJobActions } = useTroubleJobsContext();
  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IEtsFormDispatch) => {
      const insertData = formData.etsDispatch.filter((val) => val.isChecked);
      await actions.createPOST(defaultValues?.troubleJobId ?? 0, insertData);
      troubleJobActions.dataGET(defaultValues?.troubleJobId ?? 0);
      setIsOpenEts(false);
    },
    [actions, setIsOpenEts, troubleJobActions]
  );
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      validationSchema={EtsFormDispatchSchema}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <FormContent
              defaultValues={defaultValues}
              handleClick={handleClick}
              isOpenEts={isOpenEts}
              setIsOpenEts={setIsOpenEts}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default TroubleJobsEtsDispatch;
