import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { IRealTimeLogEntryForm } from 'src/models/real-time-log.model';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { formatDateTime } from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import { useSubstationContext } from 'src/contexts/substation.context';
import RealTimeLogAddView from './reat-time-log-add.view';

type IProp = {
  data?: Partial<IRealTimeLogEntryForm>;
  currentTab?: number;
};

const RealTimeLogAdd: React.FC<IProp> = ({ currentTab }) => {
  const { state, actions } = useRealTimeLogContext();
  const { actions: equipmentTypeActions } = useEquipmentTypeContext();
  const { state: substationState, actions: substationActions } = useSubstationContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  // const [isForcedOutageSubmitted, setIsForcedOutageSubmitted] = useState(false);
  // const [isForcedOutageConfirmOpen, setIsForcedOutageConfirmOpen] =
  //   useState(false);
  const [tripOnEdit, setTripOnEdit] = useState<{
    index: number;
    logSub: string;
    tripsTargets: string;
  } | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE
  );

  useEffect(() => {
    if (!isAddOpen && currentTab === 0) {
      history.push('/real-time-log?realTimeLogStatus=0');
    }
  }, [isAddOpen]);

  useEffect(() => {
    if (
      isAddOpen &&
      (!substationState.listForCreate ||
        substationState.listForCreate.length === 0)
    ) {
      substationActions.listSubstationCreateGET({ limit: 0, page: 1 });
    }

    if (isAddOpen && (!state.line || state.line?.length === 0)) {
      // console.log('Logging: real-time add fetch line');
       actions.lineCreateGET();
    }
  }, [isAddOpen, substationActions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IRealTimeLogEntryForm) => {
      const updatedForm: IRealTimeLogEntryForm = formData;
      if (formData.logCategory !== RealTimeLogCategory.FORCEDOUTAGE) {
        updatedForm.logDtTmFrcdOutOcurred = null;
        updatedForm.logDtTmFrcdOutRestored = null;
        updatedForm.logTmFrcdOutOccurred = null;
        updatedForm.logTmFrcdOutRestored = null;
      } else {
        if (updatedForm.logDtTmFrcdOutOcurred) {
          updatedForm.logDtTmFrcdOutOcurred = new Date(
            formData.logDtTmFrcdOutOcurred?.getFullYear() as number,
            formData.logDtTmFrcdOutOcurred?.getMonth() as number,
            formData.logDtTmFrcdOutOcurred?.getDate() as number,
            Number(formData.logTmFrcdOutOccurred?.split(':')[0]),
            Number(formData.logTmFrcdOutOccurred?.split(':')[1])
          );
        }

        if (updatedForm.logDtTmFrcdOutRestored) {
          updatedForm.logDtTmFrcdOutRestored = new Date(
            formData.logDtTmFrcdOutRestored?.getFullYear() as number,
            formData.logDtTmFrcdOutRestored?.getMonth() as number,
            formData.logDtTmFrcdOutRestored?.getDate() as number,
            Number(formData.logTmFrcdOutRestored?.split(':')[0]),
            Number(formData.logTmFrcdOutRestored?.split(':')[1])
          );
        }
      }

      updatedForm.logDtTm = new Date(
        formData.logDtTm.getFullYear(),
        formData.logDtTm.getMonth(),
        formData.logDtTm.getDate(),
        Number(formData.logTm.split(':')[0]),
        Number(formData.logTm.split(':')[1])
      );

      if (tripOnEdit) {
        updatedForm.forcedLog[tripOnEdit.index].logSub = tripOnEdit.logSub;
        updatedForm.forcedLog[tripOnEdit.index].tripsTargets =
          tripOnEdit.tripsTargets;
      }

      setTripOnEdit(null);

      const response = await actions.createPOST({
        ...updatedForm,
        logDtTm: formatDateTime(updatedForm.logDtTm) as unknown as Date,
      });

      if (!response.error) {
        ToastSuccess(Lang.MSG_REAL_TIME_LOG_ADD);
        // if (formData.logCategory === RealTimeLogCategory.FORCEDOUTAGE) {
        //   setIsForcedOutageSubmitted(true);
        //   setIsForcedOutageConfirmOpen(true);
        // } else {
        setIsAddOpen(false);

        // Always stay on Today tab when adding a new entry regardless of the log entry date/time
        history.push('/real-time-log?realTimeLogStatus=0');
        // }
      } else {
        ToastError(
          'Error creating Real Time Log. Please contact your system administrator.'
        );
      }
    },
    [actions, setIsAddOpen, setTripOnEdit]
  );

  // const handleForcedOutageEmail = useCallback(
  //   async (logId: number) => {
  //     const response = await actions.sendEmail(logId, false);

  //     if (!response.error) {
  //       setIsForcedOutageConfirmOpen(false);
  //       setIsAddOpen(false);
  //       ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SENT);
  //     } else {
  //       ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ERROR);
  //     }
  //   },
  //   [actions, setIsForcedOutageConfirmOpen, setIsAddOpen]
  // );

  // const handleSendLater = useCallback(
  //   (logId: number) => {
  //     setIsForcedOutageConfirmOpen(false);
  //     setIsAddOpen(false);
  //     // call backend to update of skipped email
  //     // const response;
  //     // if (!response.error) {
  //     //   ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SENT);
  //     // } else {
  //     //   ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ERROR);
  //     // }
  //   },
  //   [setIsForcedOutageConfirmOpen, setIsAddOpen]
  // );

  useEffect(() => {
    equipmentTypeActions?.listGET({ limit: 0, page: 1 });
  }, []);

  return (
    <RealTimeLogAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isAddOpen={isAddOpen}
      data={state.data}
      handleOpen={setIsAddOpen}
      setTripOnEdit={setTripOnEdit}
      // isForcedOutageSubmitted={isForcedOutageSubmitted}
      // isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
      // setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
      // handleForcedOutageEmail={handleForcedOutageEmail}
      // handleSendLater={handleSendLater}
    />
  );
};

export default RealTimeLogAdd;
