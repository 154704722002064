import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SickListReducer, {
  ISickListState,
  defaultState,
  duckActions,
} from 'src/ducks/sick-list.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SickListReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISickListContext = ReturnType<typeof useReducer>;

const SickListContext = createContext<Partial<ISickListContext>>({
  state: defaultState,
}) as React.Context<ISickListContext>;

type IProps = {
  state?: Partial<ISickListState>;
};

const SickListProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SickListContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SickListContext.Provider>
  );
};

const useSickListContext = () => useContext(SickListContext);

export type IUseSickListContext = ReturnType<typeof useSickListContext>;

export { SickListContext, SickListProvider, useSickListContext };
