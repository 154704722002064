import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/sick-list.service';
import { ISickList, ISickTypes } from 'src/models/sick-list.model';

export const sickListActionTypes = {
  SICK_LIST_DATA_READ: 'SICK_LIST_DATA_READ',
  SICK_LIST_LIST_READ: 'SICK_LIST_LIST_READ',
  SICK_LIST_DATA_CREATE: 'SICK_LIST_DATA_CREATE',
  SICK_LIST_DATA_UPDATE: 'SICK_LIST_DATA_UPDATE',
  SICK_LIST_DATA_DELETE: 'SICK_LIST_DATA_DELETE',
  SICK_LIST_DATA_CLONE: 'SICK_LIST_DATA_CLONE',
  SICK_LIST_DATA_SET: 'SICK_LIST_DATA_SET',
  SICK_TYPES_LIST_READ: 'SICK_TYPES_LIST_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: sickListActionTypes.SICK_LIST_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: sickListActionTypes.SICK_LIST_LIST_READ,
    service: services.listGET,
  },

  sickTypesGET: {
    type: sickListActionTypes.SICK_TYPES_LIST_READ,
    service: services.sickTypesGET,
  },

  createPOST: {
    type: sickListActionTypes.SICK_LIST_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: sickListActionTypes.SICK_LIST_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: sickListActionTypes.SICK_LIST_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (sickList: ISickList) => ({
    type: sickListActionTypes.SICK_LIST_DATA_SET,
    payload: sickList,
  }),
};

export type ISickListAsync = typeof duckActions;

export interface ISickListState
  extends ICommonState<typeof sickListActionTypes> {
  data?: ISickList;
  list: ISickList[];
  dataSickTypes?: ISickTypes;
  typeList: ISickTypes[];
  total: number;
}

export const defaultState: ISickListState = {
  status: {},
  list: [],
  typeList: [],
  total: 0,
};

const SickListReducer = (
  state: ISickListState,
  action: IReducerAction<ISickListAsync>
): ISickListState => {
  switch (action.type) {
    case sickListActionTypes.SICK_LIST_DATA_SET:
    case sickListActionTypes.SICK_LIST_DATA_READ:
    case sickListActionTypes.SICK_LIST_DATA_UPDATE:
    case sickListActionTypes.SICK_LIST_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case sickListActionTypes.SICK_LIST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case sickListActionTypes.SICK_TYPES_LIST_READ: {
      return {
        ...state,
        typeList: action.payload?.rows ?? [],
      };
    }

    case sickListActionTypes.SICK_LIST_DATA_DELETE: {
      if (action.params) {
        const list = state.list.filter((value) => value.empId);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default SickListReducer;
