import yup from 'src/libraries/validator.library';
import { UseFormReturn } from 'react-hook-form';

export const EmployeeRoleShape = {
  empId: yup.string().required(),
  roleId: yup.number(),
  roleStatus: yup.boolean().nullable(),
  updatedBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
};

export const EmployeeRoleSchema = yup.object(EmployeeRoleShape);

export const EmployeeSimpleShape = {
  lastName: yup.string().nullable(), // nullable for the meantime it should be required when HRIT has all the employee details
  firstName: yup.string().nullable(), // nullable for the meantime it should be required when HRIT has all the employee details
  middleName: yup.string().nullable(), // nullable for the meantime it should be required when HRIT has all the employee details
  empId: yup.string().nullable(),
  email: yup.string().nullable(),
  mailstopId: yup.string(),
};

export const RoleEmployeeSimpleSchema = yup.object(EmployeeSimpleShape);

export const RoleShape = {
  roleId: yup.string().required(),
  roleName: yup.string(),
  contentAccess: yup.string(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const RoleSchema = yup.object(RoleShape);

export const RoleEmployeeShape = {
  ...EmployeeRoleShape,
  employee: yup.object({
    ...EmployeeSimpleShape,
    middleName: yup.string().nullable(),
    fullName: yup.string().nullable(),
    title: yup.string().nullable(),
    employeeStat: yup.string(),
    cellAreaCode: yup.string().nullable(),
    cellPhoneNumber: yup.string().nullable(),
    workAreaCode: yup.string().nullable(),
    workPhoneNumber: yup.string().nullable(),
    mgrFirstname: yup.string().nullable(),
    mgrEmpno: yup.string().nullable(),
    mgrLastName: yup.string().nullable(),
    mailstopId: yup.string().nullable(),
    orgCd: yup.string().nullable(),
    mailLoc: yup.string().nullable(),
    supervisorNumber: yup.string().nullable(),
    roles: yup.array().of(
      yup.object({
        ...RoleShape,
        roleId: yup.string(),
        roleName: yup.string(),
        contentAccess: yup.string(),
        updatedBy: yup.string(),
        updatedAt: yup.date(),
      })
    ),
  }),
  role: yup.object({
    ...RoleShape,
    roleId: yup.string(),
    roleName: yup.string(),
    contentAccess: yup.string(),
    updatedBy: yup.string(),
    updatedAt: yup.date(),
  }),
};

export const RequestorReportShape = {
  empId: yup.string().required(),
  roleId: yup.number(),
  roleStatus: yup.boolean().nullable(),
  employee: yup.object({
    email: yup.string().nullable(),
    middleName: yup.string().nullable(),
    fullName: yup.string().nullable(),
    title: yup.string().nullable(),
    employeeStat: yup.string(),
    workPhoneNumber: yup.string().nullable(),
    supervisorNumber: yup.string().nullable(),
  }),
  role: yup.object({
    roleName: yup.string(),
    contentAccess: yup.string(),
  }),
};

export const RolesEmployeeSchema = yup.object(RoleEmployeeShape);
export const RequestorReportSchema = yup.object(RequestorReportShape);

export const RoleEmployeeItemSchema = yup.object({
  ...EmployeeSimpleShape,
  empId: yup.string().required(),
  middleName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  title: yup.string().nullable(),
  employeeStat: yup.string(),
  cellAreaCode: yup.string().nullable(),
  cellPhoneNumber: yup.string().nullable(),
  workAreaCode: yup.string().nullable(),
  workPhoneNumber: yup.string().nullable(),
  mgrFirstname: yup.string().nullable(),
  mgrEmpno: yup.string().nullable(),
  mgrLastName: yup.string().nullable(),
  mailstopId: yup.string().nullable(),
  orgCd: yup.string().nullable(),
  mailLoc: yup.string().nullable(),
  supervisorNumber: yup.string().nullable(),
  role: yup.object({
    roleStatus: yup.boolean(),
  }),
  roles: yup.array().of(
    yup.object({
      ...RoleShape,
      roleId: yup.string(),
      roleName: yup.string(),
      contentAccess: yup.string(),
      updatedBy: yup.string(),
      updatedAt: yup.date(),
    })
  ),
});

export const RoleEmployeeItemListSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RoleEmployeeItemSchema.clone()),
});

export const RolesPreFormSchema = yup.object({
  ...EmployeeRoleShape,
  roleId: yup.number().nullable(),
  rolesId: yup.object({
    authList: yup.number().nullable(),
    keys: yup.number().nullable(),
    outagePlanning: yup.number().nullable(),
    realTimeLog: yup.number().nullable(),
    roles: yup.number().nullable(),
    substationEntryLog: yup.number().nullable(),
    troubleJob: yup.number().nullable(),
    troubleTicket: yup.number().nullable(),
  }),
  updatedBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
});

export const RolesFormSchema = yup.object({
  ...EmployeeRoleShape,
  roleId: yup.number().nullable(),
  rolesId: yup.array().of(yup.number().required()).required(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
});

export const RolesAddEditSchema = {
  roleId: yup.number().nullable(),
  empId: yup.string().required(),
  roleStatus: yup.boolean().nullable(),
  rolesId: yup.array().of(yup.number().required()),
  updatedBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
};

export const RolesByEmpIdShape = {
  roleId: yup.number().required(),
  roleStatus: yup.boolean().nullable(),
  role: yup.object({
    contentAccess: yup.string(),
  }),
};

export const RolesByEmpIdSchema = yup.object(RolesByEmpIdShape);

export const RolesByEmpIdPayload = yup.array().of(RolesByEmpIdSchema.clone());

export const RoleEmployeeDetailSchema = yup.object({
  empId: yup.string().required(),
  roleStatus: yup.boolean(),
  roles: yup.array().of(RolesByEmpIdSchema).required(),
});

export const EmployeeRoleFilterShape = {
  empId: yup.string().nullable(),
  name: yup.string().nullable(),
  roleId: yup.number().nullable(),
  roleIds: yup.array().of(yup.number()),
  roleStatus: yup.boolean().nullable(),
  employeeStat: yup.string().nullable(),
};

export const EmployeeRoleFilterSchema = yup.object(EmployeeRoleFilterShape);

export const RolesListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RolesEmployeeSchema.clone()),
});

export const RequestorReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RequestorReportSchema.clone()),
});

export const RolesPayloadSchema = yup.object({
  roles: RolesEmployeeSchema.clone(),
});

export const RolesOptionsPayloadSchema = yup.object({
  rows: yup.array().of(RoleSchema.clone()),
});

export const RolesDeletePayloadSchema = yup.object({
  success: yup.boolean(),
});
export const RoleAssigmentNumberSchema = yup.object({
  authList: yup.number().nullable(),
  keys: yup.number().nullable(),
  outagePlanning: yup.number().nullable(),
  realTimeLog: yup.number().nullable(),
  roles: yup.number().nullable(),
  substationEntryLog: yup.number().nullable(),
  troubleJob: yup.number().nullable(),
  troubleTicket: yup.number().nullable(),
});

export type IRolesPreForm = yup.Asserts<typeof RolesPreFormSchema>;
export type IRolesForm = yup.Asserts<typeof RolesFormSchema>;
export type IRolesEmployee = yup.Asserts<typeof RolesEmployeeSchema>;
export type IRequestorReport = yup.Asserts<typeof RequestorReportSchema>;
export type IRoleEmployeeItem = yup.Asserts<typeof RoleEmployeeItemSchema>;
export type IRolesByEmpId = yup.Asserts<typeof RolesByEmpIdSchema>;
export type IEmployeeRole = yup.Asserts<typeof EmployeeRoleSchema>;
export type IRole = yup.Asserts<typeof RoleSchema>;
export type IEmployeeRoleFilter = yup.Asserts<typeof EmployeeRoleFilterSchema>;
export type IRoleEmployeeDetail = yup.Asserts<typeof RoleEmployeeDetailSchema>;
export type IRoleAssigmentNumber = yup.Asserts<
  typeof RoleAssigmentNumberSchema
>;
export type IRoleEmployeeSimple = yup.Asserts<typeof RoleEmployeeSimpleSchema>;
export type IRolesListSearchMethods = Pick<
  UseFormReturn<IEmployeeRoleFilter>,
  'control' | 'formState'
> &
  Partial<
    Pick<
      UseFormReturn<IEmployeeRoleFilter>,
      'setError' | 'clearErrors' | 'formState' | 'reset' | 'watch' | 'register'
    >
  >;
