import React, { useState } from 'react';
import {
  ATMButton,
  ATMForm,
  ATMLabel,
  ATMGrid,
  ATMInput,
  ATMDatePicker,
  ATMField,
} from 'shared-it-appmod-ui';
import { useOmsAuditReportContext } from 'src/contexts/oms-audit-report.context';
import Lang from 'src/libraries/language';
import { OMSAuditReportFilterSchema } from 'src/models/oms-audit-report.model';
import style from './oms-audit-filters.module.scss';

type IProps = {
  queryParams: Record<any, any>;
  total: number;
  loading: boolean;
  handleQueryParams: (params: Record<any, any>) => void;
  handleSearch: () => void;
  handleDownload: () => void;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const OMSAuditFilters: React.FC<IProps> = ({
  queryParams,
  loading,
  handleQueryParams,
  handleSearch,
  handleDownload,
  setShowNoRecord,
}) => {
  const { actions } = useOmsAuditReportContext();
  const [requestIdState, setRequestIdState] = useState('');
  const [omsOutageIdState, setOmsOutageIdState] = useState('');
  const [fromDateState, setFromDateState] = useState('');
  const [toDateState, setToDateState] = useState('');

  return (
    <div className={style.filterContainer}>
      <ATMForm mode="onChange" validationSchema={OMSAuditReportFilterSchema}>
        {({ control, clearErrors, reset }) => {
          return (
            <ATMGrid columns={5} className={style.filters}>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_LER}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMInput}
                    name="requestId"
                    placeholder={Lang.LBL_ENTER}
                    onChange={([_, { value }]) => {
                      handleQueryParams({
                        ...queryParams,
                        requestId: value,
                      });
                      setRequestIdState(value);
                      return value;
                    }}
                    value={requestIdState || ''}
                    control={control}
                    autoComplete="off"
                    clearable
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_OMS_OUTAGE_ID}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMInput}
                    name="omsOutageId"
                    placeholder={Lang.LBL_ENTER}
                    onChange={([_, { value }]) => {
                      handleQueryParams({
                        ...queryParams,
                        omsOutageId: value,
                      });
                      setOmsOutageIdState(value);
                      return value;
                    }}
                    value={omsOutageIdState || ''}
                    control={control}
                    autoComplete="off"
                    clearable
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_DATE_FROM}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMDatePicker}
                    name="fromDate"
                    type="basic"
                    placeholder={Lang.LBL_SELECT}
                    format="MM/DD/YYYY"
                    maxDate={toDateState ? new Date(toDateState) : null}
                    onChange={([_, { value }]) => {
                      const from = value
                        ? value.toLocaleString().split(',')[0]
                        : '';
                      handleQueryParams({
                        ...queryParams,
                        fromDate: from,
                      });
                      setFromDateState(value);
                      return value;
                    }}
                    value={fromDateState || ''}
                    control={control}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_DATE_TO}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMDatePicker}
                    name="toDate"
                    type="basic"
                    placeholder={Lang.LBL_SELECT}
                    format="MM/DD/YYYY"
                    minDate={fromDateState ? new Date(fromDateState) : null}
                    onChange={([_, { value }]) => {
                      const to = value
                        ? value.toLocaleString().split(',')[0]
                        : '';
                      handleQueryParams({
                        ...queryParams,
                        toDate: to,
                      });
                      setToDateState(value);
                      return value;
                    }}
                    value={toDateState || ''}
                    control={control}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row style={{ paddingTop: '0' }}>
                <ATMGrid.Column width={7}>
                  <div>
                    <ATMButton
                      primary
                      content={Lang.LBL_SEARCH}
                      onClick={() => {
                        setShowNoRecord(false);
                        handleSearch();
                      }}
                      loading={loading}
                    />
                    <ATMButton
                      secondary
                      content={Lang.LBL_CLEAR_SEARCH}
                      onClick={() => {
                        if (reset) {
                          actions.clearList();
                          setRequestIdState('');
                          setOmsOutageIdState('');
                          setFromDateState('');
                          setToDateState('');
                          reset({
                            requestId: '',
                            omsOutageId: '',
                            fromDate: '',
                            toDate: '',
                          });
                          setImmediate(() => {
                            clearErrors();
                          });
                          handleQueryParams({
                            ...queryParams,
                            requestId: '',
                            omsOutageId: '',
                            fromDate: '',
                            toDate: '',
                          });
                          setShowNoRecord(false);
                        }
                      }}
                      // disabled={!formState.isDirty}
                    />
                    <ATMButton
                      key="download"
                      secondary
                      size="small"
                      icon="download"
                      content={Lang.LBL_DOWNLOAD}
                      onClick={() => handleDownload()}
                      // disabled={loading || total === 0}
                    />
                  </div>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default OMSAuditFilters;
