export default {
  NTE_PAGE_NOT_FOUND: 'Page not found.',
  NTE_PAGE_ACCESS_DENIED:
    'You have not been assigned a user role within MCC application. Please contact Help Desk (858-654-6300) if you require access to MCC application. Ask the request be sent to assignment group: SRE-MISSION-CONTROL-CENTER-APP for processing.',
  NTE_PAGE_ACCESS_DENIED_HEADER:
    'You have not been assigned a user role within MCC application',
  NTE_GROUP_MEMBER: 'Shows only the members who were added in this system',
  NTE_UPLOAD_ATTACHMENT: 'File data should be comma (,) delimited.',
  NTE_FORMAT_UPLOAD_ATTACHMENT:
    'Format: [Substation ID],[Customer Count],[Load],[Year]',
  NTE_HEADING_ATTACHMENT_UPLOAD: 'Upload Costumer Counts File',
  NTE_OC_LER_REQUEST_COSTS:
    'Subject to the ISO 30-Day CRR rule for outages > 24 hrs duration',
  NTE_OC_LER_REQUEST_ISO:
    'Subject to the ISO 30-Day CRR rule for outages > 24 hrs duration',
  NTE_SUBSTATION_VISITORS: 'Additional visitors entered the Substation',
  NTE_NO_DATA: 'No data found.',
  NTE_SUBJECT_TO_ISO_RULE: 'Subject to ISO rule.',
  NTE_REAL_TIME_LOG_TODAY: 'The logs below are current as of {0}',
  NTE_LIST_CURRENT_UPDATE:
    'The List below is current as of {0} and will refresh every 15 minutes',
  NTE_NO_DATA_OVERVIEW:
    'Please select or enter the search criteria and click on the search button to see the results.',
  NTE_TEST_VOLTAGE: 'Test Voltage will be applied',
  NTE_INCLEMENT_WEATHER: 'If allowed, proceed work during inclement weather.',
  NTE_DOWNLOAD_FILE_LER:
    '*Note: Click the filename to download or view the file.',

  NTE_FILTER_CHECKBOX_BOTH_TROUBLE_TICKET:
    'Include all open and active tickets regardless of the date range.',
  NTE_FILTER_WORKGROUP_AUDIT_REPORT: '(Select at least one)',
  NTE_SUBSTATION_ENTRY_LOG_TODAY:
    'The List below is current as of {0} and will refresh every 15 minutes',
};
