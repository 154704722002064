import React, { useCallback } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useJobCodeRuleContext } from 'src/contexts/job-code-rule.context';
import { jobCodeRuleActionTypes } from 'src/ducks/job-code-rule.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import {
  getJobCodeRuleParams,
  getJobCodeRuleStatus,
} from 'src/selectors/job-code-rule.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import useStatusTab from 'src/hooks/status-tab.hook';
import JobCodeRuleListView from './job-code-rule-list.view';

const JobCodeRuleList: React.FC = () => {
  const { state, actions } = useJobCodeRuleContext();
  const { currentTab } = useStatusTab('jobStatus');
  const status = getJobCodeRuleStatus(
    state,
    jobCodeRuleActionTypes.JOB_CODE_RULE_LIST_READ
  );

  const downloadStatus = getJobCodeRuleStatus(
    state,
    jobCodeRuleActionTypes.JOB_CODE_RULE_LIST_ALL_READ
  );

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      const data = getJobCodeRuleParams(params, currentTab);
      actions.listGET(data);
    },
    [actions, currentTab]
  );

  const handleDownload = useCallback(async () => {
    const data = getJobCodeRuleParams({ limit: 0, page: 1 }, currentTab);
    const result = await actions.listAllGET(data);
    if (result.payload?.rows) {
      const list = result.payload.rows;

      const items = list.map((value) => [
        checkValue(value.jobCd),
        checkValue(value.jobTitle ?? '-'),
        checkValue(value.key30 ? 'Y' : 'N'),
        checkValue(value.key40 ? 'Y' : 'N'),
        checkValue(value.auth ? 'Y' : 'N'),
        checkValue(value.subEntry ? 'Y' : 'N'),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_JOB_CODE_RULE_CODE,
          Lang.LBL_JOB_CODE_RULE_TITLE,
          `${Lang.LBL_JOB_CODE_RULE_THIRTY_KEY}?`,
          `${Lang.LBL_JOB_CODE_RULE_FOURTY_KEY}?`,
          `${Lang.LBL_JOB_CODE_RULE_AUTH_TRAIN}?`,
          `${Lang.LBL_JOB_CODE_RULE_SUB_ENTRY}?`,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Job_Code_Key_Rule_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state]);

  return (
    <JobCodeRuleListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      total={state.total}
      handleDownload={handleDownload}
      loadingDL={downloadStatus.fetching}
    />
  );
};

export default JobCodeRuleList;
