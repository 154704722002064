import React from 'react';
import {
  ATMSegment,
  ATMGrid,
  ATMLabel,
  ATMDatePicker,
  ATMButton,
  IORGDataTableColumn,
  ORGDataTable,
  ATMIcon,
  MOLNoData,
} from 'shared-it-appmod-ui';
import { useRejectedReportContext } from 'src/contexts/rejected-report.context';
import Lang from 'src/libraries/language';
import {
  IRejectedSummaryReport,
  IRejectedSummaryReportDataSchema,
} from 'src/models/rejected-report.model';
import style from '../rejected-report.module.scss';

type IProps = {
  loading: boolean;
  data: IRejectedSummaryReport;
  queryParams: Record<any, any>;
  handleFetch: (clear?: boolean) => void;
  handleQueryParams: (params: Record<any, any>) => void;
  handleDownload: () => void;
  handleSummaryDetailsDownload: (date) => void;
  downloading: boolean;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

export const formatSummaryData = (data: IRejectedSummaryReport) => {
  const summaryData = Object.entries<IRejectedSummaryReport>(data).map(
    ([key, value]) => {
      return {
        date: key,
        submitted: 0,
        moreThanOneRej: 0,
        rejections: 0,
        lers: [],
        ...value,
      };
    }
  );
  return summaryData;
};

const RejectedReportSummary: React.FC<IProps> = ({
  data,
  loading,
  queryParams,
  handleFetch,
  handleQueryParams,
  handleDownload,
  downloading,
  handleSummaryDetailsDownload,
  showNoRecord,
  setShowNoRecord,
}) => {
  const { actions } = useRejectedReportContext();
  const expandedRowTable = (expandData: IRejectedSummaryReportDataSchema) => {
    return (
      <ATMSegment attached>
        <ATMGrid className={style.expandableContent}>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <strong>{Lang.LBL_LER}</strong>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <strong>{Lang.LBL_FACILITY_NAME}</strong>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <strong>{Lang.LBL_REASON_OF_REJECTION}</strong>
            </ATMGrid.Column>
          </ATMGrid.Row>
          {expandData.lers?.map((item, i) => (
            <ATMGrid.Row key={i}>
              <ATMGrid.Column>{item?.requestId}</ATMGrid.Column>
              <ATMGrid.Column>
                {item?.outage?.outageFacility?.facility?.outgFacNm}
              </ATMGrid.Column>
              <ATMGrid.Column>{item?.rejectionReason}</ATMGrid.Column>
            </ATMGrid.Row>
          ))}
        </ATMGrid>
      </ATMSegment>
    );
  };

  const customColumns: IORGDataTableColumn<IRejectedSummaryReport>[] = [
    {
      title: Lang.LBL_DATE,
      index: 'date',
    },
    {
      title: Lang.LBL_NUM_LER_SUBMITTED,
      index: 'submitted',
    },
    {
      title: Lang.LBL_LER_REJECTION,
      index: 'moreThanOneRej',
    },
    {
      title: Lang.LBL_NUM_REJECTION,
      index: 'rejections',
    },
    {
      title: Lang.LBL_ACTIONS,
      index: 'lers',
      sortable: false,
      render: (_, value, __, { isExpand, setIsExpand }) => (
        <a role="button" onClick={() => setIsExpand(!isExpand)}>
          {`${Lang.LBL_DETAILS} `}
          {isExpand ? (
            <ATMIcon name="chevron up" />
          ) : (
            <ATMIcon name="chevron down" />
          )}
        </a>
      ),
    },
    {
      index: 'download',
      sortable: false,
      title: '',
      render: (_, value: Record<any, any>) => {
        return (
          <ATMButton
            secondary
            icon="download"
            className={style.download}
            onClick={() => handleSummaryDetailsDownload(value.date)}
            loading={downloading}
          />
        );
      },
    },
  ];
  const summaryData = formatSummaryData(data);
  return (
    <>
      <ATMSegment className={style.bgColor}>
        <ATMGrid>
          <ATMGrid.Row columns={5} className={style.filters}>
            <ATMGrid.Column>
              <ATMLabel
                className={style.filterLbl}
                basic
                content={Lang.LBL_DATE_RANGE}
              />
              <ATMDatePicker
                type="range"
                placeholder={Lang.LBL_SELECT}
                format="MM/DD/YYYY"
                value={queryParams.dateRange}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    dateRange: value,
                  });
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column />
            <ATMGrid.Column />
            <ATMGrid.Column />
            <ATMGrid.Column />
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMButton
                primary
                content={Lang.LBL_SEARCH}
                onClick={() => {
                  setShowNoRecord(false);
                  handleFetch();
                }}
                disabled={Object.keys(queryParams).length === 0}
              />
              <ATMButton
                secondary
                type="button"
                content={Lang.LBL_CLEAR_SEARCH}
                onClick={() => {
                  setShowNoRecord(false);
                  actions?.summaryListClear();
                  handleQueryParams({
                    ...queryParams,
                    dateRange: '',
                  });
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
      {summaryData.length > 0 && (
        <ATMButton
          secondary
          icon="download"
          className={style.download}
          onClick={handleDownload}
          loading={downloading}
        />
      )}
      <ORGDataTable
        sortable
        className={style.tableHeaders}
        data={summaryData}
        celled={false}
        loading={loading}
        columns={customColumns}
        expandableRowsComponent={expandedRowTable}
        noDataText={
          !showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={style.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'bottom',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      />
    </>
  );
};

export default RejectedReportSummary;
