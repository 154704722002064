import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/suspension-list.service';
import { ISuspensionList } from 'src/models/suspension-list.model';

export const suspensionListActionTypes = {
  SUSPENSION_LIST_DATA_READ: 'SUSPENSION_LIST_DATA_READ',
  SUSPENSION_LIST_LIST_READ: 'SUSPENSION_LIST_LIST_READ',
  SUSPENSION_LIST_DATA_CREATE: 'SUSPENSION_LIST_DATA_CREATE',
  SUSPENSION_LIST_DATA_UPDATE: 'SUSPENSION_LIST_DATA_UPDATE',
  SUSPENSION_LIST_DATA_DELETE: 'SUSPENSION_LIST_DATA_DELETE',
  SUSPENSION_LIST_DATA_SET: 'SUSPENSION_LIST_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue

  createPOST: {
    type: suspensionListActionTypes.SUSPENSION_LIST_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataDELETE: {
    type: suspensionListActionTypes.SUSPENSION_LIST_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (suspensionList: ISuspensionList) => ({
    type: suspensionListActionTypes.SUSPENSION_LIST_DATA_SET,
    payload: suspensionList
  }),
};

export type ISuspensionListAsync = typeof duckActions;

export interface ISuspensionListState
  extends ICommonState<typeof suspensionListActionTypes> {
  data?: ISuspensionList;
  list: ISuspensionList[];
  total: number;
}

export const defaultState: ISuspensionListState = {
  status: {},
  list: [],
  total: 0,
};

const SuspensionListReducer = (
  state: ISuspensionListState,
  action: IReducerAction<ISuspensionListAsync>
): ISuspensionListState => {
  switch (action.type) {
    case suspensionListActionTypes.SUSPENSION_LIST_DATA_SET:
    case suspensionListActionTypes.SUSPENSION_LIST_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case suspensionListActionTypes.SUSPENSION_LIST_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.authListId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default SuspensionListReducer;
