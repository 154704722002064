import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/equipment-type.service';
import { IEquipmentType } from 'src/models/equipment-type.model';

export const equipmentTypeActionTypes = {
  EQUIPMENT_TYPE_DATA_READ: 'EQUIPMENT_TYPE_DATA_READ',
  EQUIPMENT_TYPE_LIST_READ: 'EQUIPMENT_TYPE_LIST_READ',
  EQUIPMENT_TYPE_DATA_CREATE: 'EQUIPMENT_TYPE_DATA_CREATE',
  EQUIPMENT_TYPE_DATA_UPDATE: 'EQUIPMENT_TYPE_DATA_UPDATE',
  EQUIPMENT_TYPE_DATA_DELETE: 'EQUIPMENT_TYPE_DATA_DELETE',
  EQUIPMENT_TYPE_DATA_SET: 'EQUIPMENT_TYPE_DATA_SET',
  EQUIPMENT_TYPE_FILTER_ALL_READ: 'EQUIPMENT_TYPE_FILTER_ALL_READ',
  EQUIPMENT_TYPE_LIST_ALL_READ: 'EQUIPMENT_TYPE_LIST_ALL_READ',
  EQUIPMENT_TYPE_CLEAR: 'EQUIPMENT_TYPE_CLEAR',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_DELETE,
    service: services.dataDELETE,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  filterGET: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_FILTER_ALL_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_ALL_READ,
    service: services.listGET,
  },

  listCLEAR: () => ({
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_CLEAR,
  }),

  // This is a sync action
  setData: (equipmentType: IEquipmentType) => ({
    type: equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_SET,
    payload: equipmentType,
  }),
};

export type IEquipmentTypeAsync = typeof duckActions;

export interface IEquipmentTypeState
  extends ICommonState<typeof equipmentTypeActionTypes> {
  data?: IEquipmentType;
  list: IEquipmentType[];
  allList: IEquipmentType[];
  total: number;
  filter: IEquipmentType[];
}

export const defaultState: IEquipmentTypeState = {
  status: {},
  list: [],
  allList: [],
  total: 0,
  filter: [],
};

const EquipmentTypeReducer = (
  state: IEquipmentTypeState,
  action: IReducerAction<IEquipmentTypeAsync>
): IEquipmentTypeState => {
  switch (action.type) {
    case equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_SET:
    case equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_READ:
    case equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_UPDATE:
    case equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.facTypId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case equipmentTypeActionTypes.EQUIPMENT_TYPE_FILTER_ALL_READ: {
      return {
        ...state,
        filter: action.payload?.rows ?? [],
      };
    }

    case equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_ALL_READ: {
      return {
        ...state,
        allList: action.payload?.rows ?? [],
      };
    }

    case equipmentTypeActionTypes.EQUIPMENT_TYPE_CLEAR: {
      return {
        ...state,
        list: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default EquipmentTypeReducer;
