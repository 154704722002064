/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMSelect,
  ATMHeader,
  ATMTextArea,
  ATMDatePicker,
  ATMIcon,
  ATMSegment,
  ATMTable,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useTroubleEquipmentTypeContext } from 'src/contexts/trouble-equipment-type.context';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import { IRolesEmployee } from 'src/models/roles.model';
import {
  ITroubleTicketDocument,
  ITroubleTicket,
  ITroubleTicketPayload,
  ITroubleTicketForm,
} from 'src/models/trouble-ticket.model';
// import { checkValue } from 'src/libraries/common.library';
import { useRolesContext } from 'src/contexts/roles.context';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { format24hDateTime } from 'src/libraries/moment.library';
import { getEmployeeName } from 'src/helpers/employee.helper';
import style from '../trouble-ticket.module.scss';
import TroubleTicketDocumentDetail, {
  ViewType,
} from '../trouble-ticket-document/trouble-ticket-document-details.component';

type IProp = {
  action: string;
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ITroubleTicketPayload>;
  handleSubmit: (data: ITroubleTicket) => void;
  handleEnable: (value: boolean) => void;
  validationSchema: any;
  fileInfoList?: ITroubleTicketDocument[];
  assignToEmp: IRolesEmployee[];
  statusEmployeeRole: boolean;
  isViewMode?: boolean;
  viewType: ViewType;
  view: any;
};

type IFormContent = {
  action: string;
  defaultValues?: Partial<ITroubleTicketPayload>;
  fileInfoList?: ITroubleTicketDocument[];
  assignToEmp: IRolesEmployee[];
  statusEmployeeRole: boolean;
  isViewMode?: boolean;
  view: any;
  viewType: ViewType;
};

const FormContent: React.FC<IFormContent> = ({
  action,
  defaultValues,
  statusEmployeeRole,
  isViewMode = false,
  view,
  viewType,
}) => {
  const {
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useATMFormContext<ITroubleTicketForm>();
  const [facSub, setFacSub] = useState('');
  const [status, setStatus] = useState(defaultValues?.ticketStatus ?? '');
  const { state: troubleTicketState } = useTroubleTicketContext();
  const [workGroupValue, setWorkGroupValue] = useState(0);
  const { state: workGroupState, actions: workGroupActions } =
    useWorkGroupContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: realtimeLogState, actions: realTimeLogActions } =
    useRealTimeLogContext();
  const {
    state: troubleEquipmentTypeState,
    actions: troubleEquipmentTypeActions,
  } = useTroubleEquipmentTypeContext();
  const { state: employeeRolesState, actions: employeeRolesActions } =
    useRolesContext();

  useEffect(() => {
    if (!workGroupState?.list?.length) {
      workGroupActions?.listGET({ limit: 0, page: 1 });
    }
    if (!troubleEquipmentTypeState?.list?.length) {
      troubleEquipmentTypeActions?.listGET();
    }
    if (!substationState?.list?.length) {
      substationActions?.listGET({ limit: 0, page: 1 });
    }
    if (!realtimeLogState?.line?.length) {
      realTimeLogActions?.lineGET();
    }
    if (!employeeRolesState?.list?.length) {
      employeeRolesActions?.listGET({
        page: 1,
        limit: 0,
        filters: [
          {
            name: 'roleIds',
            value: [14, 15],
          },
        ],
      });
    }
  }, [
    workGroupActions,
    troubleEquipmentTypeActions,
    substationActions,
    realTimeLogActions,
    employeeRolesActions,
  ]);

  // Mock options
  const optFacSub = ['Line', 'Substation'].map((value) => {
    return {
      key: value.toLowerCase(),
      value: value.toLowerCase(),
      text: value,
    };
  });

  const optTTicketType = ['Trouble', 'Scheduled'].map((value) => {
    return {
      key: value,
      value,
      text: value,
    };
  });

  const optPriority = ['Normal', 'High', 'Emergency'].map((value) => {
    return {
      key: value,
      value,
      text: value.charAt(0).toUpperCase() + value.slice(1),
    };
  });

  let optStatus;
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    action === 'create'
      ? (optStatus = ['Open', 'Closed'].map((value) => {
          return {
            key: value,
            value,
            text: value.charAt(0).toUpperCase() + value.slice(1),
          };
        }))
      : (optStatus = ['Open', 'Active', 'Closed'].map((value) => {
          return {
            key: value,
            value,
            text: value,
          };
        }));
  }
  useEffect(() => {
    if (defaultValues && defaultValues.outgFacId) {
      setFacSub('line');
    } else if (defaultValues && defaultValues.substationId) {
      setFacSub('substation');
    }

    if (defaultValues && defaultValues.troubleGroupId) {
      setWorkGroupValue(defaultValues.troubleGroupId);
    }
  }, []);

  return (
    <ATMGrid>
      <ATMGrid.Column width={10} style={{ paddingRight: 0 }}>
        <ATMGrid className={style.mainForm}>
          <ATMGrid.Row>
            <ATMGrid.Column style={{ width: '100%' }}>
              <ATMSegment className="applySegment">
                <ATMHeader className={style.headerSegment}>
                  <h4>{Lang.LBL_FACILITY_INFORMATION}</h4>
                </ATMHeader>
                <ATMGrid style={{ width: '100%' }}>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={5}>
                      {defaultValues?.ticketStatus === 'Closed' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
                            <p>
                              {defaultValues?.line?.outgFacNm
                                ? `Line - ${defaultValues?.line?.outgFacNm}`
                                : `Substation - ${defaultValues?.substation?.name}`}
                            </p>
                          </FieldReadOnly>
                        </>
                      ) : defaultValues?.ticketStatus ? (
                        <>
                          <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
                            <p>
                              {defaultValues?.line?.outgFacNm
                                ? `Line - ${defaultValues?.line?.outgFacNm}`
                                : `Substation - ${defaultValues?.substation?.name}`}
                            </p>
                          </FieldReadOnly>
                          <ATMField
                            name="facSub"
                            control={control}
                            defaultValue="noFacSub"
                            as={ATMInput}
                            type="hidden"
                            className="hidden"
                          />
                        </>
                      ) : (
                        <ATMField
                          label={
                            <LabelRequired>
                              {Lang.LBL_LINE_SUBSTATION}
                            </LabelRequired>
                          }
                          name="facSub"
                          control={control}
                          placeholder="Select"
                          as={ATMSelect}
                          clearable
                          search
                          options={optFacSub}
                          defaultValue={
                            defaultValues && defaultValues.outgFacId
                              ? 'line'
                              : defaultValues && defaultValues.substationId
                              ? 'substation'
                              : undefined
                          }
                          onChange={([_, { value }]) => {
                            setFacSub(value);
                            return value;
                          }}
                          error={errors.facSub}
                          disabled={isViewMode}
                          selectOnBlur={false}
                        />
                      )}
                    </ATMGrid.Column>
                    {!defaultValues?.ticketStatus ||
                    defaultValues?.ticketStatus === 'Open' ? (
                      <ATMGrid.Column width={5}>
                        {facSub === 'line' ? (
                          <ATMField
                            key={`outgFacId_${realtimeLogState.line.length}`}
                            name="outgFacId"
                            placeholder="Select"
                            as={ATMSelect}
                            search
                            defaultValue={defaultValues?.outgFacId}
                            label={<label>&nbsp;</label>}
                            control={control}
                            error={errors.outgFacId}
                            options={realtimeLogState.line.map((value) => ({
                              key: value.outgFacId,
                              value: value.outgFacId,
                              text: value.outgFacNm,
                            }))}
                            onChange={([_, { value }]) => value}
                            disabled={isViewMode}
                            selectOnBlur={false}
                          />
                        ) : facSub === 'substation' ? (
                          // eslint-disable-next-line react/jsx-indent
                          <ATMField
                            name="substationId"
                            placeholder="Select"
                            search
                            clearable
                            as={ATMSelect}
                            defaultValue={defaultValues?.substationId}
                            label={<label>&nbsp;</label>}
                            control={control}
                            error={errors.substationId}
                            options={substationState.list.map((value) => ({
                              key: value.substationId,
                              value: value.substationId,
                              text: value.name,
                            }))}
                            onChange={([_, { value }]) => value}
                            disabled={isViewMode}
                            selectOnBlur={false}
                          />
                        ) : (
                          <ATMField
                            name=""
                            placeholder="Select"
                            as={ATMSelect}
                            disabled
                            label={<label>&nbsp;</label>}
                            control={control}
                            error={errors.substationId}
                          />
                        )}
                      </ATMGrid.Column>
                    ) : undefined}
                    <ATMGrid.Column width={5}>
                      {defaultValues?.ticketStatus === 'Closed' ? (
                        <>
                          <FieldReadOnly label={Lang.LBL_EQUIPMENT_TYPE}>
                            <p>
                              {defaultValues?.troubleEquipmentType?.name ?? '-'}
                            </p>
                          </FieldReadOnly>
                          <ATMField
                            name="troubleEquipmentTypeId"
                            control={control}
                            defaultValue={defaultValues?.troubleEquipmentTypeId}
                            as={ATMInput}
                            type="hidden"
                            className="hidden"
                          />
                        </>
                      ) : (
                        <ATMField
                          label={
                            <LabelRequired>
                              {Lang.LBL_EQUIPMENT_TYPE}{' '}
                            </LabelRequired>
                          }
                          required
                          name="troubleEquipmentTypeId"
                          placeholder="Select"
                          disabled={
                            ((!defaultValues?.outgFacId ||
                              !defaultValues?.substationId) &&
                              facSub === '') ||
                            isViewMode
                          }
                          search
                          clearable
                          defaultValue={defaultValues?.troubleEquipmentTypeId}
                          as={ATMSelect}
                          control={control}
                          error={errors.troubleEquipmentTypeId}
                          options={
                            facSub === 'line'
                              ? troubleEquipmentTypeState.list
                                  .filter((value) => value.lineInd === true)
                                  .map((value) => ({
                                    key: value.id,
                                    value: value.id,
                                    text: value.name,
                                  }))
                              : troubleEquipmentTypeState.list
                                  .filter(
                                    (value) => value.substationInd === true
                                  )
                                  .map((value) => ({
                                    key: value.id,
                                    value: value.id,
                                    text: value.name,
                                  }))
                          }
                          onChange={([_, { value }]) =>
                            value === '' ? undefined : value
                          }
                          selectOnBlur={false}
                        />
                      )}
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row columns="equal">
                    <ATMGrid columns={12}>
                      <ATMHeader style={{ marginBottom: '0' }}>
                        <h4>{Lang.LBL_INFORMATION_REPORTED}</h4>
                      </ATMHeader>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_WORK_GROUP}>
                                <p>
                                  {defaultValues?.workGroup?.trbGrpNm ?? '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="troubleGroupId"
                                control={control}
                                defaultValue={defaultValues?.troubleGroupId}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              label={
                                <LabelRequired>
                                  {Lang.LBL_WORK_GROUP}
                                </LabelRequired>
                              }
                              name="troubleGroupId"
                              placeholder="Select"
                              search
                              clearable
                              as={ATMSelect}
                              control={control}
                              error={errors.troubleGroupId}
                              options={workGroupState.list
                                .filter(
                                  (elemt) => elemt.trbGrpNm.slice(0, 1) !== 'z'
                                )
                                .map((value) => ({
                                  key: value.trbGrpId,
                                  value: value.trbGrpId,
                                  text: value.trbGrpNm,
                                }))}
                              onChange={([_, { value }]) => {
                                setWorkGroupValue(
                                  value === '' ? undefined : value
                                );
                                return value === '' ? undefined : value;
                              }}
                              disabled={isViewMode}
                              selectOnBlur={false}
                            />
                          )}
                        </ATMGrid.Column>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_SUB_GROUP}>
                                <p>
                                  {defaultValues?.subGroup?.subGrpNm ?? '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="subGroupId"
                                control={control}
                                defaultValue={defaultValues?.subGroupId}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              label={Lang.LBL_SUB_GROUP}
                              name="subGroupId"
                              placeholder="Select"
                              search
                              clearable
                              as={ATMSelect}
                              disabled={!workGroupValue || isViewMode}
                              control={control}
                              error={errors.subGroupId}
                              options={workGroupState.list
                                .find(
                                  (value) => value.trbGrpId === workGroupValue
                                )
                                ?.subGrp?.map((value) => ({
                                  key: value.subGrpId,
                                  value: value.subGrpId,
                                  text: value.subGrpNm,
                                }))}
                              onChange={([_, { value }]) =>
                                value === '' ? undefined : value
                              }
                              selectOnBlur={false}
                            />
                          )}
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_REQUEST_TYPE}>
                                <p style={{ textTransform: 'capitalize' }}>
                                  {defaultValues?.requestTyp ?? '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="requestTyp"
                                control={control}
                                defaultValue={defaultValues?.requestTyp}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              label={
                                <LabelRequired>
                                  {Lang.LBL_REQUEST_TYPE}
                                </LabelRequired>
                              }
                              name="requestTyp"
                              placeholder="Select"
                              search
                              clearable
                              as={ATMSelect}
                              control={control}
                              error={errors.requestTyp}
                              options={optTTicketType}
                              onChange={([_, { value }]) =>
                                value === '' ? undefined : value
                              }
                              disabled={isViewMode}
                              selectOnBlur={false}
                            />
                          )}
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_BEGIN_DATE}>
                                <p>
                                  {defaultValues?.beginDt
                                    ? moment(defaultValues?.beginDt).format(
                                        'MM/DD/YYYY'
                                      )
                                    : '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="beginDt"
                                control={control}
                                defaultValue={defaultValues?.beginDt}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              as={ATMDatePicker}
                              name="beginDt"
                              label={
                                <LabelRequired>
                                  {Lang.LBL_BEGIN_DATE}
                                </LabelRequired>
                              }
                              type="basic"
                              format="MM/DD/YYYY"
                              placeholder="Select"
                              pointing="left"
                              control={control}
                              error={errors.beginDt}
                              onChange={([_, val]) => {
                                return val.value;
                              }}
                              disabled={isViewMode}
                            />
                          )}
                        </ATMGrid.Column>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_DUE_DATE}>
                                <p>
                                  {defaultValues?.dueDt
                                    ? moment(defaultValues?.dueDt).format(
                                        'MM/DD/YYYY'
                                      )
                                    : '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="dueDt"
                                control={control}
                                defaultValue={defaultValues?.dueDt}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              as={ATMDatePicker}
                              name="dueDt"
                              label={Lang.LBL_DUE_DATE}
                              type="basic"
                              format="MM/DD/YYYY"
                              placeholder="Select"
                              pointing="left"
                              control={control}
                              error={errors.dueDt}
                              onChange={([_, val]) => {
                                return val.value;
                              }}
                              disabled={isViewMode}
                            />
                          )}
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={5}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_PRIORITY}>
                                <p style={{ textTransform: 'capitalize' }}>
                                  {defaultValues?.ticketPrio ?? '--'}
                                </p>
                              </FieldReadOnly>
                              <ATMField
                                name="ticketPrio"
                                control={control}
                                defaultValue={defaultValues?.ticketPrio}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              label={
                                <LabelRequired>
                                  {Lang.LBL_PRIORITY}
                                </LabelRequired>
                              }
                              name="ticketPrio"
                              placeholder="Select"
                              search
                              as={ATMSelect}
                              control={control}
                              error={errors.ticketPrio}
                              defaultValue={
                                defaultValues
                                  ? defaultValues?.ticketPrio
                                  : 'Normal'
                              }
                              options={optPriority}
                              onChange={([_, { value }]) => value}
                              disabled={isViewMode}
                            />
                          )}
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={10}>
                          {defaultValues?.ticketStatus &&
                          defaultValues?.ticketStatus !== 'Open' ? (
                            <>
                              <FieldReadOnly label={Lang.LBL_DESCRIPTION}>
                                <p>{defaultValues?.ticketDesc ?? '-'}</p>
                              </FieldReadOnly>
                              <ATMField
                                name="ticketDesc"
                                control={control}
                                defaultValue={defaultValues?.ticketDesc}
                                as={ATMInput}
                                type="hidden"
                                className="hidden"
                              />
                            </>
                          ) : (
                            <ATMField
                              label={
                                <LabelRequired>
                                  {Lang.LBL_DESCRIPTION}
                                </LabelRequired>
                              }
                              name="ticketDesc"
                              placeholder="Enter"
                              as={ATMTextArea}
                              maxLength={1000}
                              control={control}
                              error={errors.ticketDesc}
                              disabled={isViewMode}
                            />
                          )}
                        </ATMGrid.Column>
                        {/* hidden field */}
                        <ATMField
                          name="submittedBy"
                          control={control}
                          defaultValue={
                            defaultValues
                              ? defaultValues.submittedBy
                              : getUser()?.emp_no
                          }
                          as={ATMInput}
                          type="hidden"
                          className="hidden"
                        />
                      </ATMGrid.Row>
                    </ATMGrid>
                  </ATMGrid.Row>
                </ATMGrid>
              </ATMSegment>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row style={{ paddingTop: 0 }}>
            <ATMGrid.Column>
              <TroubleTicketDocumentDetail
                isViewMode={isViewMode}
                viewType={viewType}
                enableRemoveButton
                id={
                  defaultValues?.troubleTicketId ??
                  troubleTicketState?.data?.troubleTicketId
                }
                view={view}
                status={
                  (defaultValues?.ticketStatus as any) ??
                  (troubleTicketState?.data?.ticketStatus as any)
                }
                responsePayload={defaultValues as any}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMGrid.Column>
      {/* Facility Information Grid */}
      <ATMGrid.Column width={6}>
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMSegment className={style.statCommentPadding}>
                <ATMHeader className={style.headerSegment}>
                  <h4>{Lang.LBL_TICKET_STATUS}</h4>
                </ATMHeader>
                <ATMGrid>
                  {defaultValues?.ticketStatus === 'Closed' ? (
                    <ATMGrid.Row>
                      <ATMGrid.Column width={8}>
                        <FieldReadOnly label={Lang.LBL_STATUS}>
                          <p style={{ textTransform: 'capitalize' }}>
                            {defaultValues?.ticketStatus}
                          </p>
                        </FieldReadOnly>
                        <ATMField
                          name="ticketStatus"
                          control={control}
                          as={ATMInput}
                          type="hidden"
                          className="hidden"
                          onSubmit={
                            defaultValues.etrDt
                              ? setValue('ticketStatus', 'Active')
                              : setValue('ticketStatus', 'Open')
                          }
                        />
                        {/* {defaultValues.etrDt && (
                        <ATMField
                          name="activatedBy"
                          control={control}
                          as={ATMInput}
                          type="hidden"
                          className="hidden"
                          onSubmit={setValue('activatedBy', getUser()?.emp_no)}
                        />
                      )} */}
                        <ATMField
                          name="prevStatus"
                          defaultValue={defaultValues?.ticketStatus}
                          control={control}
                          as={ATMInput}
                          type="hidden"
                          className="hidden"
                        />
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  ) : (
                    <ATMGrid.Row>
                      <ATMGrid.Column width={8}>
                        <ATMField
                          label={
                            <LabelRequired>{Lang.LBL_STATUS}</LabelRequired>
                          }
                          name="ticketStatus"
                          placeholder="Select"
                          defaultValue={
                            defaultValues ? defaultValues?.ticketStatus : 'Open'
                          }
                          as={ATMSelect}
                          clearable
                          control={control}
                          error={errors.ticketStatus}
                          options={optStatus}
                          onChange={([_, { value }]) => {
                            setStatus(value);
                            clearErrors();

                            if (value === 'Open') {
                              setValue('etrDt', null);
                            } else if (
                              (value === 'Active' || value === 'Closed') &&
                              defaultValues?.etrDt
                            ) {
                              setValue('etrDt', defaultValues?.etrDt);
                            }

                            return value;
                          }}
                          disabled={isViewMode}
                        />
                        <ATMField
                          name="prevStatus"
                          defaultValue={defaultValues?.ticketStatus}
                          control={control}
                          as={ATMInput}
                          type="hidden"
                          className="hidden"
                        />
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  )}
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      {defaultValues?.ticketStatus === 'Closed' ? (
                        <>
                          <FieldReadOnly label={Lang.LBL_ASSIGNED_TO}>
                            <p>
                              {defaultValues &&
                              defaultValues.assignedTo &&
                              defaultValues.assignedToEmployee
                                ? getEmployeeName(
                                    defaultValues.assignedToEmployee,
                                    false
                                  )
                                : '--'}
                            </p>
                          </FieldReadOnly>
                          <ATMField
                            name="assignedTo"
                            control={control}
                            as={ATMInput}
                            type="hidden"
                            className="hidden"
                          />
                        </>
                      ) : (
                        <ATMField
                          key={defaultValues?.assignedTo}
                          label={Lang.LBL_ASSIGNED_TO}
                          as={ATMSelect}
                          clearable
                          search
                          loading={statusEmployeeRole}
                          name="assignedTo"
                          control={control}
                          onChange={([_, { value }]) => value}
                          options={employeeRolesState.list.map((value) => ({
                            key: value.empId,
                            value: value.empId,
                            text: getEmployeeName(value.employee),
                          }))}
                          error={errors.assignedTo}
                          placeholder="Select"
                          disabled={isViewMode}
                          selectOnBlur={false}
                        />
                      )}
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  {action !== 'create' ? (
                    defaultValues?.ticketStatus !== 'Closed' ? (
                      <ATMGrid.Row>
                        <ATMGrid.Column>
                          <ATMField
                            as={ATMDatePicker}
                            name="etrDt"
                            width={8}
                            label={
                              status === 'Active' ||
                              (status === 'Closed' && defaultValues?.etrDt) ? (
                                <LabelRequired>{Lang.LBL_ETR}</LabelRequired>
                              ) : (
                                Lang.LBL_ETR
                              )
                            }
                            type="basic"
                            format="MM/DD/YYYY"
                            placeholder="Select"
                            pointing="left"
                            control={control}
                            disabled={
                              status === 'Open' ||
                              (status === 'Closed' &&
                                defaultValues?.ticketStatus !== 'Active') ||
                              isViewMode
                            }
                            error={errors?.etrDt}
                            onChange={([_, val]) => {
                              return val.value;
                            }}
                            // style={{ width: '100%' }}
                          />
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                    ) : (
                      <>
                        <ATMGrid.Row>
                          <ATMGrid.Column width={6}>
                            <FieldReadOnly label={Lang.LBL_ETR}>
                              <p>
                                {defaultValues?.etrDt
                                  ? moment(defaultValues?.etrDt).format(
                                      'MM/DD/YYYY'
                                    )
                                  : '--'}
                              </p>
                            </FieldReadOnly>
                            <ATMField
                              name="etrDt"
                              control={control}
                              defaultValue={
                                defaultValues?.etrDt
                                  ? moment(defaultValues?.etrDt).format(
                                      'MM/DD/YYYY'
                                    )
                                  : null
                              }
                              as={ATMInput}
                              type="hidden"
                              className="hidden"
                            />
                            <ATMField
                              name="closedDt"
                              control={control}
                              as={ATMInput}
                              type="hidden"
                              className="hidden"
                              style={{ width: '100%' }}
                              onSubmit={setValue(
                                'closedDt',
                                (defaultValues?.etrDt
                                  ? moment(defaultValues?.etrDt).format(
                                      'MM/DD/YYYY'
                                    )
                                  : null) as unknown as Date
                              )}
                              disabled={isViewMode}
                            />
                          </ATMGrid.Column>
                        </ATMGrid.Row>
                      </>
                    )
                  ) : undefined}
                  {status === 'Closed' ? (
                    defaultValues?.ticketStatus !== 'Closed' ? (
                      <ATMGrid.Row>
                        <ATMGrid.Column>
                          <ATMField
                            as={ATMDatePicker}
                            name="closedDt"
                            required
                            width={8}
                            label={
                              <LabelRequired>
                                {Lang.LBL_CLOSED_DATE}
                              </LabelRequired>
                            }
                            type="basic"
                            format="MM/DD/YYYY"
                            placeholder="Select"
                            pointing="left"
                            control={control}
                            error={errors.closedDt}
                            onChange={([_, val]) => {
                              return val.value;
                            }}
                            disabled={isViewMode}
                          />
                          <ATMField
                            name="closedBy"
                            control={control}
                            as={ATMInput}
                            type="hidden"
                            className="hidden"
                            onSubmit={setValue('closedBy', getUser()?.emp_no)}
                          />
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                    ) : (
                      <>
                        <ATMGrid.Row>
                          <ATMGrid.Column width={6}>
                            <FieldReadOnly label={Lang.LBL_CLOSED_DATE}>
                              <p>
                                {defaultValues?.closedDt
                                  ? moment(defaultValues?.closedDt).format(
                                      'MM/DD/YYYY'
                                    )
                                  : '--'}
                              </p>
                            </FieldReadOnly>
                            <ATMField
                              name="closedDt"
                              control={control}
                              as={ATMInput}
                              type="hidden"
                              className="hidden"
                              onSubmit={setValue('closedDt', null)}
                            />
                            <ATMField
                              name="closedBy"
                              control={control}
                              as={ATMInput}
                              type="hidden"
                              className="hidden"
                              onSubmit={setValue('closedBy', null)}
                            />
                            {/* <ATMField
                            name="activatedBy"
                            control={control}
                            as={ATMInput}
                            type="hidden"
                            className="hidden"
                            onSubmit={
                              defaultValues.etrDt
                                ? setValue('activatedBy', getUser()?.emp_no)
                                : setValue('activatedBy', null)
                            }
                          /> */}
                            <ATMField
                              name="facSub"
                              control={control}
                              defaultValue="FacSubno"
                              as={ATMInput}
                              type="hidden"
                              className="hidden"
                            />
                          </ATMGrid.Column>
                        </ATMGrid.Row>
                      </>
                    )
                  ) : undefined}
                  {defaultValues || (status !== '' && status !== 'Open') ? (
                    <>
                      <ATMGrid.Row>
                        <ATMGrid.Column width={16}>
                          <ATMField
                            required
                            label={
                              <LabelRequired>
                                {Lang.LBL_COMMENTS_RESOLUTION}
                              </LabelRequired>
                            }
                            name="commentsResolution"
                            placeholder={Lang.LBL_ENTER}
                            as={ATMTextArea}
                            maxLength={3000}
                            control={control}
                            error={errors.commentsResolution}
                            disabled={isViewMode}
                          />
                        </ATMGrid.Column>
                        <ATMGrid.Column
                          width={16}
                          style={{ marginTop: '1.5em' }}
                        >
                          {action === 'edit' ? (
                            <ATMSegment className="applySegment">
                              <ATMHeader className={style.headerSegment}>
                                <h4>{Lang.LBL_PARTICIPANTS}</h4>
                              </ATMHeader>
                              <ATMTable celled>
                                <ATMTable.Header>
                                  <ATMTable.Row>
                                    <ATMTable.HeaderCell>
                                      {Lang.LBL_CREATED_BY}
                                    </ATMTable.HeaderCell>
                                    <ATMTable.HeaderCell>
                                      {Lang.LBL_ACTIVATED_BY}
                                    </ATMTable.HeaderCell>
                                    <ATMTable.HeaderCell>
                                      {Lang.LBL_CLOSED_BY}
                                    </ATMTable.HeaderCell>
                                  </ATMTable.Row>
                                </ATMTable.Header>
                                <ATMTable.Body>
                                  <ATMTable.Row>
                                    <ATMTable.Cell>
                                      {defaultValues &&
                                      defaultValues?.submittedBy ? (
                                        defaultValues?.submittedByEmployee &&
                                        defaultValues?.submittedByEmployee
                                          .lastName ? (
                                          `${defaultValues?.submittedByEmployee.lastName}, ${defaultValues?.submittedByEmployee.firstName}`
                                        ) : (
                                          <>Unavailable</>
                                        )
                                      ) : (
                                        <>&nbsp;</>
                                      )}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                      {defaultValues &&
                                      defaultValues?.activatedBy ? (
                                        defaultValues?.activatedByEmployee &&
                                        defaultValues?.activatedByEmployee
                                          .lastName ? (
                                          `${defaultValues?.activatedByEmployee.lastName}, ${defaultValues?.activatedByEmployee.firstName}`
                                        ) : (
                                          <>Unavailable</>
                                        )
                                      ) : (
                                        <>&nbsp;</>
                                      )}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                      {defaultValues &&
                                      defaultValues?.closedBy ? (
                                        defaultValues?.closedByEmployee &&
                                        defaultValues?.closedByEmployee
                                          .lastName ? (
                                          `${defaultValues?.closedByEmployee.lastName}, ${defaultValues?.closedByEmployee.firstName}`
                                        ) : (
                                          <>Unavailable</>
                                        )
                                      ) : (
                                        <>&nbsp;</>
                                      )}
                                    </ATMTable.Cell>
                                  </ATMTable.Row>
                                </ATMTable.Body>
                              </ATMTable>
                            </ATMSegment>
                          ) : undefined}
                        </ATMGrid.Column>
                      </ATMGrid.Row>
                    </>
                  ) : undefined}
                  {status === 'Active' ? (
                    <ATMField
                      name="activatedBy"
                      control={control}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                      onSubmit={
                        defaultValues?.ticketStatus !== 'Active'
                          ? setValue('activatedBy', getUser()?.emp_no)
                          : setValue('activatedBy', defaultValues?.activatedBy)
                      }
                    />
                  ) : status === 'Open' ? (
                    <ATMField
                      name="activatedBy"
                      control={control}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                      onSubmit={setValue('activatedBy', null)}
                    />
                  ) : status === 'Closed' ? (
                    <ATMField
                      name="activatedBy"
                      control={control}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                      onSubmit={
                        defaultValues?.ticketStatus !== 'Closed'
                          ? setValue('activatedBy', defaultValues?.activatedBy)
                          : defaultValues?.etrDt
                          ? setValue('activatedBy', getUser()?.emp_no)
                          : setValue('activatedBy', null)
                      }
                    />
                  ) : undefined}
                </ATMGrid>
                <>
                  {/* Ticket card */}
                  {defaultValues?.comments?.map((list, i) => (
                    <ATMSegment
                      key={`comment_${i}`}
                      className={style.ticketStatusCard}
                    >
                      <ATMIcon
                        circular
                        name="user"
                        size="small"
                        color="grey"
                        className={style.userIcon}
                      />
                      <div className={style.content}>
                        <span className={style.userName}>{list.name}</span>
                        <p className={style.mtopNone}>
                          {/* {list.userType} User &#8226;{' '} */}
                          {format24hDateTime(list.updatedAt)}
                        </p>
                        <p>{list.noteTxt}</p>
                      </div>
                    </ATMSegment>
                  ))}
                </>
              </ATMSegment>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMGrid.Column>
    </ATMGrid>
  );
};

const TroubleTicketForm: React.FC<IProp> = ({
  action,
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  validationSchema,
  fileInfoList: handleUploadedFileInfo,
  assignToEmp,
  statusEmployeeRole,
  isViewMode = false,
  view,
  viewType,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {(formProps) => {
        handleEnable(formProps.formState.isDirty);
        return (
          <ATMFormProvider {...formProps}>
            <FormContent
              action={action}
              defaultValues={defaultValues}
              fileInfoList={handleUploadedFileInfo}
              assignToEmp={assignToEmp}
              statusEmployeeRole={statusEmployeeRole}
              isViewMode={isViewMode}
              view={view}
              viewType={viewType}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default TroubleTicketForm;
