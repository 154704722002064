import yup from 'src/libraries/validator.library';

export const IsoWorkKindShape = {
  isoWorkKindId: yup.string().required(),
  description: yup.string().required(),
};

export const IsoWorkKindSchema = yup.object(IsoWorkKindShape);

export const IsoWorkKindFormSchema = yup.object({
  description: yup.string().required().default(''),
});

export const IsoWorkKindListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(IsoWorkKindSchema.clone()),
});

export const IsoWorkKindPayloadSchema = yup.object({
  isoWorkKind: IsoWorkKindSchema.clone(),
});

export const IsoWorkKindDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IIsoWorkKind = yup.Asserts<typeof IsoWorkKindSchema>;
export type IIsoWorkKindForm = yup.Asserts<typeof IsoWorkKindFormSchema>;
