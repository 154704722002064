import React, { useMemo, useState } from 'react';
import { groupBy } from 'lodash';
import {
  ATMSegment,
  ATMIcon,
  ATMAccordion,
  ATMButton,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment, {
  format24hDateTime,
  format24hTime,
} from 'src/libraries/moment.library';
import { ILerRequestOutageDate } from 'src/models/ler-request.model';
import { sortEvents } from 'src/libraries/calendar.library';
import { getLerOutageDateList } from 'src/helpers/ler-request.helper';
import { LERRequestStatus } from 'src/constants/ler-request.constant';
import styles from './ler-request-outage-date.module.scss';

type IProps = {
  data?: ILerRequestOutageDate[];
  handleDelete?: (key: string) => void;
  handleEdit?: () => void;
};

const LERRequestOutageDate: React.FC<IProps> = ({
  data = [],
  handleDelete,
  handleEdit,
}) => {
  const [active, setActive] = useState(true);

  const outageDateList = useMemo(() => getLerOutageDateList(data), [data]);

  if (!outageDateList.length) {
    return null;
  }

  const checkValidDatesForDeletion = (
    date: Date,
    statusValue: LERRequestStatus
  ): boolean => {
    let isValidForDeletion = true;
    // LER can still be edited within the next 30 days from the max end date, therefore dates should be allowed to be deleted
    // if they are not yet final
    // if (
    //   ![
    //     LERRequestStatus.Scheduled,
    //     LERRequestStatus.Submitted,
    //     LERRequestStatus.Created,
    //     LERRequestStatus.Study,
    //   ].includes(statusValue)
    // ) {
    if (
      Moment(date)
        .startOf('day')
        .isBefore(Moment(new Date()).startOf('day').toDate())
    ) {
      isValidForDeletion = false;
    }
    if (
      !Moment(date)
        .startOf('day')
        .isBefore(Moment(new Date()).startOf('day').toDate()) &&
      (statusValue === LERRequestStatus.In ||
        statusValue === LERRequestStatus.Out)
    ) {
      isValidForDeletion = false;
    }
    // }
    // else if (isLerRequestBeyond30Days(outageDateList)) {
    //   isValidForDeletion = false;
    // }

    return isValidForDeletion;
  };

  return (
    <ATMAccordion fluid>
      <ATMAccordion.Title
        active={active}
        index={0}
        onClick={() => setActive((val) => !val)}
      >
        <ATMIcon name="angle down" />
        {Lang.formatString(Lang.TTL_SELECTED_DATES, data.length)}

        {handleEdit && (
          <a className={styles.edit} onClick={handleEdit} role="button">
            <ATMIcon name="edit" />
            {Lang.LBL_EDIT}
          </a>
        )}
      </ATMAccordion.Title>
      <ATMAccordion.Content active={active} className={styles.content}>
        {Object.entries(
          groupBy(outageDateList, (val) => Moment(val.startTm).format('YYYYMM'))
        ).map(([date, list]: any, key) => {
          return (
            <React.Fragment key={Moment(date, 'YYYYMM').format('MM_YYYY')}>
              <ATMSegment vertical>
                <strong className={styles.title}>
                  {Moment(date, 'YYYYMM').format('MMMM YYYY')}
                </strong>
              </ATMSegment>
              <ATMSegment vertical>
                {list
                  .sort((a, b) =>
                    sortEvents(
                      {
                        ...a,
                        start: Moment(a.startTm),
                        end: Moment(a.stopTm),
                      },
                      {
                        ...b,
                        start: Moment(b.startTm),
                        end: Moment(b.stopTm),
                      }
                    )
                  )
                  .map((val: ILerRequestOutageDate, index) => {
                    return (
                      <div
                        key={`${key}_${index}`}
                        className={styles.selectedDate}
                      >
                        <div className={styles.dateContent}>
                          <div className={styles.day}>
                            {Moment(val.startTm).format('D')}
                            <span>{Moment(val.startTm).format('ddd')}</span>
                          </div>
                          <div className={styles.time}>
                            <span>{Lang.LBL_STARTS}</span>
                            <time>{format24hTime(val.startTm)}</time>
                          </div>
                          <div className={styles.time}>
                            <span>{Lang.LBL_ENDS}</span>
                            <time>
                              {Moment(val.startTm).isSame(
                                Moment(val.stopTm),
                                'date'
                              )
                                ? format24hTime(val.stopTm)
                                : format24hDateTime(val.stopTm)}
                            </time>
                          </div>
                        </div>

                        {checkValidDatesForDeletion(
                          Moment(val.startTm).toDate(),
                          val.reqStatId as LERRequestStatus
                        ) &&
                        handleDelete &&
                        val.key ? (
                          <div>
                            <ATMButton
                              icon="trash"
                              type="button"
                              onClick={() => handleDelete(val.key as string)}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </ATMSegment>
            </React.Fragment>
          );
        })}
      </ATMAccordion.Content>
    </ATMAccordion>
  );
};

export default LERRequestOutageDate;
