import yup from 'src/libraries/validator.library';

export const EquipmentTypeShape = {
  facTypId: yup.number().required(),
  facTypNm: yup.string().required(),
  facTypDesc: yup.string().required(),
  // facTypLerAssoc: yup.boolean().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const EquipmentTypeSchema = yup.object(EquipmentTypeShape);

export const EquipmentTypeFormSchema = yup.object({
  facTypNm: yup.string().required(),
  facTypDesc: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const EquipmentTypeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EquipmentTypeSchema.clone()),
});

export const EquipmentTypePayloadSchema = EquipmentTypeSchema.defined();

export const EquipmentTypeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IEquipmentType = yup.Asserts<typeof EquipmentTypeSchema>;
export type IEquipmentTypeForm = yup.Asserts<typeof EquipmentTypeFormSchema>;
