import {
  entryReasonActionTypes,
  IEntryReasonState,
} from 'src/ducks/entry-reason.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getEntryReasonStatus = (
  state: IEntryReasonState,
  action: keyof typeof entryReasonActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
