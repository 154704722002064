import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ATMButton, ATMGrid, ATMLoader, ATMSegment } from 'shared-it-appmod-ui';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';
import history from 'src/history';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import SystemChangeItem from 'src/components/organisms/system-change-item/system-change-item.component';
import { getSystemChangeStatus } from 'src/selectors/system-change.selector';
import SystemChangePDF, {
  SystemChangeActionType,
} from 'src/components/organisms/pdf-system-change/pdf-system-change.component';
import Error404 from '../../error/error-404/error-404.component';
import styles from './system-change-detail.module.scss';

const SystemChangeDetail: React.FC = () => {
  const { state, actions } = useSystemChangeContext();
  const status = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ
  );
  const { id } = useParams<{ id: string }>();
  const date = Moment(id, 'YYYYMMDD');

  const list = useMemo(
    () => state.list.filter((v) => Moment(v.inServiceDate).isSame(date, 'day')),
    [state.list]
  );

  useEffect(() => {
    actions.listGET({
      filters: [
        {
          name: 'dateRange',
          value: [date.startOf('day'), date.endOf('day')],
        },
      ],
    });
  }, [actions]);

  if (status.fetching) {
    return <ATMLoader active />;
  }

  if (!list.length) {
    return <Error404 />;
  }

  return (
    <div className="admin-container">
      <ATMSegment>
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle" />
          <ATMGrid.Column textAlign="right">
            <SystemChangePDF
              date={date}
              type={SystemChangeActionType.Download}
            />
            <SystemChangePDF date={date} type={SystemChangeActionType.Print} />
            <ATMButton
              className={styles.back}
              type="button"
              secondary
              onClick={() => history.goBack()}
              icon="arrow left"
              content={Lang.LBL_GO_BACK}
            />
          </ATMGrid.Column>
        </ATMGrid>
        {list.map((value, key) => (
          <SystemChangeItem key={key} data={value} />
        ))}
      </ATMSegment>
    </div>
  );
};

export default SystemChangeDetail;
