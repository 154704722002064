import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/oms-request.service';
import { IOmsData, IOmsRequest } from 'src/models/oms-request.model';
import Lang from 'src/libraries/language';

export const omsRequestActionTypes = {
  OMS_REQUEST_DATA_READ: 'OMS_REQUEST_DATA_READ',
  OMS_REQUEST_LIST_READ: 'OMS_REQUEST_LIST_READ',
  OMS_REQUEST_DATA_SEND: 'OMS_REQUEST_DATA_SEND',
  OMS_REQUEST_DATA_UPDATE: 'OMS_REQUEST_DATA_UPDATE',
  OMS_REQUEST_DATA_DELETE: 'OMS_REQUEST_DATA_DELETE',
  OMS_REQUEST_DATA_SET: 'OMS_REQUEST_DATA_SET',
  OMS_REQUEST_CAISO_ERROR_SET: 'OMS_REQUEST_CAISO_ERROR_SET',
  OMS_REQUEST_DATA_ACKNOWLEDGE: 'OMS_REQUEST_DATA_ACKNOWLEDGE',
  OMS_REQUEST_LIST_SET: 'OMS_REQUEST_LIST_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: omsRequestActionTypes.OMS_REQUEST_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: omsRequestActionTypes.OMS_REQUEST_LIST_READ,
    service: services.listGET,
    meta: {
      error: (error) => {
        return error.status === 504
          ? Lang.MSG_CAISO_COMMUNICATION_FAILED_RETRIEVE
          : undefined;
      },
    },
  },

  sendPOST: {
    type: omsRequestActionTypes.OMS_REQUEST_DATA_SEND,
    service: services.sendPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: omsRequestActionTypes.OMS_REQUEST_DATA_UPDATE,
    service: services.updatePUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataDELETE: {
    type: omsRequestActionTypes.OMS_REQUEST_DATA_DELETE,
    service: services.dataDELETE,
  },

  acknowledgePUT: {
    type: omsRequestActionTypes.OMS_REQUEST_DATA_ACKNOWLEDGE,
    service: services.acknowledgePUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  // This is a sync action
  clearList: () => ({
    type: omsRequestActionTypes.OMS_REQUEST_LIST_SET,
  }),
  setData: (omsRequest: IOmsRequest) => ({
    type: omsRequestActionTypes.OMS_REQUEST_DATA_SET,
    payload: omsRequest,
  }),
  setCaisioError: (error: { type: string; message: any } | undefined) => ({
    type: omsRequestActionTypes.OMS_REQUEST_CAISO_ERROR_SET,
    payload: error,
  }),
};

export type IOmsRequestAsync = typeof duckActions;

export interface IOmsRequestState
  extends ICommonState<typeof omsRequestActionTypes> {
  data?: IOmsRequest;
  list: IOmsData[];
  total: number;
  caisoError?: { type: string; message: any };
}

export const defaultState: IOmsRequestState = {
  status: {},
  list: [],
  total: 0,
};

const OmsRequestReducer = (
  state: IOmsRequestState,
  action: IReducerAction<IOmsRequestAsync>
): IOmsRequestState => {
  switch (action.type) {
    case omsRequestActionTypes.OMS_REQUEST_DATA_SET:
    case omsRequestActionTypes.OMS_REQUEST_DATA_READ: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case omsRequestActionTypes.OMS_REQUEST_LIST_SET: {
      return {
        ...state,
        list: [],
      };
    }

    case omsRequestActionTypes.OMS_REQUEST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.data ?? [],
        status: {
          ...state.status,
          [omsRequestActionTypes.OMS_REQUEST_LIST_READ]: {
            fetching: false,
            error: action.payload?.error ?? null,
          },
        },
      };
    }

    case omsRequestActionTypes.OMS_REQUEST_CAISO_ERROR_SET: {
      return {
        ...state,
        caisoError: action.payload ?? undefined,
      };
    }

    case omsRequestActionTypes.OMS_REQUEST_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.lerId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default OmsRequestReducer;
