/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import { UseFormReturn } from 'react-hook-form';
import yup from 'src/libraries/validator.library';

export const ITroubleTicketDocumentSchema = yup.object({
  id: yup.number(),
  file_name: yup.string(),
  uploaded_date: yup.date(),
  uploaded_by: yup.string(),
  file: yup.string(),
});

export const ITroubleTicketStatusSchema = yup.object({
  id: yup.number(),
  updated_by: yup.string(),
  updated_at: yup.date(),
  comments: yup.string(),
  employee_type: yup.string(),
  status: yup.string(),
});

const TroubleTicketEmployeeSchema = yup.object({
  fullName: yup.string(),
  firstName: yup.string(),
  middleName: yup.string().nullable(),
  lastName: yup.string(),
  empId: yup.string(),
});

export const TroubleTicketShape = {
  troubleTicketId: yup.number().required(),
  outgFacId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  troubleEquipmentTypeId: yup.number().required(),
  ticketStatus: yup.string().required(),
  beginDt: yup.date().required(),
  troubleGroupId: yup.number().required(),
  commentsResolution: yup.string().nullable(),
  etrDt: yup.date().nullable(),
  ticketDesc: yup.string().required(),
  ticketPrio: yup.string().required(),
  submittedBy: yup.string().required(),
  activatedBy: yup.string().nullable(),
  closedBy: yup.string().nullable(),
  closedDt: yup.date().nullable(),
  dueDt: yup.date().nullable(),
  requestTyp: yup.string().required(),
  updatedBy: yup.string().required(),
  updatedAt: yup.date().required(),
  subGroupId: yup.number().nullable(),
  assignedTo: yup.string().nullable(),
  emailResult: yup.string().nullable(),
};

export const TroubleTicketDetailShape = {
  ...TroubleTicketShape,
  submittedByEmployee: TroubleTicketEmployeeSchema.clone(),
  activatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  updatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  closedByEmployee: TroubleTicketEmployeeSchema.clone(),
};

export const TroubleTicketRequestPayloadShape = {
  troubleTicketId: yup.number().required(),
  outgFacId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  troubleEquipmentTypeId: yup.number().required(),
  ticketStatus: yup.string().required(),
  beginDt: yup.date().required(),
  troubleGroupId: yup.number().required(),
  commentsResolution: yup.string().nullable(),
  etrDt: yup.date().nullable(),
  ticketDesc: yup.string().required(),
  ticketPrio: yup.string().required(),
  submittedBy: yup.string().required(),
  activatedBy: yup.string().nullable(),
  closedBy: yup.string().nullable(),
  closedDt: yup.date().nullable(),
  dueDt: yup.date().nullable(),
  requestTyp: yup.string().required(),
  updatedBy: yup.string().required(),
  updatedAt: yup.date().required(),
  subGroupId: yup.number().nullable(),
  assignedTo: yup.string().nullable(),
};

export const TroubleTicketPayloadShape = {
  ...TroubleTicketShape,
  line: yup
    .object({
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
    })
    .nullable(),
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
  troubleEquipmentType: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .nullable(),
  workGroup: yup
    .object({
      trbGrpId: yup.string().required(),
      trbGrpNm: yup.string().required(),
    })
    .nullable(),
  subGroup: yup
    .object({
      subGrpId: yup.string().required(),
      subGrpNm: yup.string().required(),
    })
    .nullable(),
  comments: yup
    .array()
    .of(
      yup.object({
        noteId: yup.number().required(),
        noteTxt: yup.string().required(),
        // updatedBy: yup.string().required(),
        updatedAt: yup.date().required(),
        // userType: yup.string().required(),
        name: yup.string().nullable(),
        status: yup.string().nullable(),
      })
    )
    .nullable(),
  submittedByEmployee: TroubleTicketEmployeeSchema.clone(),
  activatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  updatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  closedByEmployee: TroubleTicketEmployeeSchema.clone(),
  assignedToEmployee: TroubleTicketEmployeeSchema.clone(),
  // trouble_ticket_status: yup
  //   .array()
  //   .of(ITroubleTicketStatusSchema.clone())
  //   .required(),
  // trouble_ticket_documents: yup
  //   .array()
  //   .of(ITroubleTicketDocumentSchema.clone())
  //   .required(),
};

export const TroubleTicketListPayloadShape = {
  ...TroubleTicketShape,
  line: yup
    .object({
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
    })
    .nullable(),
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
  troubleEquipmentType: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .nullable(),
  workGroup: yup
    .object({
      trbGrpId: yup.string().required(),
      trbGrpNm: yup.string().required(),
    })
    .nullable(),
  subGroup: yup
    .object({
      subGrpId: yup.string().required(),
      subGrpNm: yup.string().required(),
    })
    .nullable(),
  // assignee: yup
  //   .object({
  //     email: yup.string(),
  //     name: yup.string(),
  //   })
  //   .nullable(),
  // activator: yup
  //   .object({
  //     name: yup.string(),
  //   })
  //   .nullable(),
  // closer: yup
  //   .object({
  //     name: yup.string(),
  //   })
  //   .nullable(),
  // submitter: yup
  //   .object({
  //     name: yup.string(),
  //   })
  //   .nullable(),
  submittedByEmployee: TroubleTicketEmployeeSchema.clone(),
  activatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  updatedByEmployee: TroubleTicketEmployeeSchema.clone(),
  closedByEmployee: TroubleTicketEmployeeSchema.clone(),
  assignedToEmployee: TroubleTicketEmployeeSchema.clone(),
  comments: yup
    .array()
    .of(
      yup.object({
        noteId: yup.number().required(),
        noteTxt: yup.string().required(),
        // updatedBy: yup.string().required(),
        updatedAt: yup.date().required(),
        // userType: yup.string().required(),
        name: yup.string().nullable(),
        status: yup.string().nullable(),
      })
    )
    .nullable(),
};

export const TroubleTicketSchema = yup.object(TroubleTicketDetailShape);
export const TroubleTicketRequestPayloadSchema = yup.object(
  TroubleTicketRequestPayloadShape
);
export const TroubleTicketPayloadSchema = yup.object(TroubleTicketPayloadShape);
export const TroubleTicketListSchema = yup.object(
  TroubleTicketListPayloadShape
);

export const TroubleTicketFormSchema = yup.object({
  ...TroubleTicketShape,
  facSub: yup.string().required(),
  troubleTicketId: yup.number(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
  troubleEquipmentTypeId: yup.number().required().when('facSub', {
    is: 'forwardFacSub',
    then: yup.number().required(),
  }),
  subGroupId: yup.number().nullable(),
  outgFacId: yup.number().nullable().when('facSub', {
    is: 'line',
    then: yup.number().required(),
  }),
  substationId: yup.string().nullable().when('facSub', {
    is: 'substation',
    then: yup.string().required(),
  }),
  closedDt: yup
    .date()
    .nullable()
    .when('ticketStatus', {
      is: 'Closed',
      then: yup.date().required().typeError('This field is required'),
    }),
  etrDt: yup
    .date()
    .nullable()
    .when(['ticketStatus', 'prevStatus'], {
      is: (ticketStatus, prevStatus) =>
        ticketStatus === 'Active' ||
        (ticketStatus === 'Closed' && prevStatus === 'Active'),
      then: yup.date().required().typeError('This field is required'),
    }),
  prevStatus: yup.string(),
  commentsResolution: yup
    .string()
    .nullable()
    .when(['ticketStatus', 'prevStatus', 'facSub'], {
      is: (val, prevStatus, facSub) =>
        val !== 'Open' ||
        (prevStatus === 'Active' && val === 'Open') ||
        facSub === 'forwardFacSub',
      then: yup
        .string()
        .required()
        .min(3, 'This field is required')
        .ensure()
        .test('Only Empty?', 'Cannot be empty characters', function (value) {
          const isValid = value.split(' ').join('').length !== 0;
          return isValid;
        }),
    })
    .when(['ticketStatus', 'prevStatus'], {
      is: (val, prevStatus) => prevStatus === 'Closed' && val === 'Open',
      then: yup
        .string()
        .required()
        .min(3, 'This field is required')
        .ensure()
        .test('Only Empty?', 'Cannot be empty characters', function (value) {
          const isValid = value.split(' ').join('').length !== 0;
          return isValid;
        }),
    }),
  dueDt: yup
    .date()
    .nullable()
    .when(
      'beginDt',
      (beginDt, schema) =>
        beginDt && schema.min(beginDt, 'Due Date cannot be before Begin Date')
    ),
});

export const TroubleTicketCreateFormSchema = yup.object({
  ...TroubleTicketShape,
  facSub: yup.string().required(),
  troubleTicketId: yup.number(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
  outgFacId: yup.number().nullable().when('facSub', {
    is: 'line',
    then: yup.number().required(),
  }),
  substationId: yup.string().nullable().when('facSub', {
    is: 'substation',
    then: yup.string().required(),
  }),
  closedDt: yup.date().nullable().when('ticketStatus', {
    is: 'Closed',
    then: yup.date().required(),
  }),
  etrDt: yup.date().nullable().when('facSub', {
    is: 'noFacSub',
    then: yup.date().required(),
  }),
  commentsResolution: yup.string().nullable().when('ticketStatus', {
    is: 'Closed',
    then: yup.string().required(),
  }),
  dueDt: yup
    .date()
    .nullable()
    .when(
      'beginDt',
      (beginDt, schema) =>
        beginDt && schema.min(beginDt, 'Due Date cannot be before Begin Date')
    ),
});

export const TroubleTicketForwardSchema = yup.object({
  troubleTicketId: yup.number(),
  outgFacId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  line: yup
    .object({
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
    })
    .nullable(),
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
});

export const TroubleTicketAllFiltersShape = {
  troubleTicketId: yup.number(),
  outgFacId: yup.number(),
  substationId: yup.string(),
  troubleEquipmentTypeId: yup.number(),
  ticketStatus: yup.string(),
  beginDt: yup.array().of(yup.date()),
  requestTyp: yup.string(),
  ticketDesc: yup.string(),
  ticketPrio: yup.string(),
  commentsResolution: yup.string(),
  dueDt: yup.array().of(yup.date()),
  workGroups: yup.array().of(yup.number()),
  etrDt: yup.array().of(yup.date()),
};

export const TroubleTicketAllFiltersSchema = yup.object(
  TroubleTicketAllFiltersShape
);

export const TroubleTicketListPayloadSchema = yup.object({
  count: yup.object({
    all: yup.number(),
    trouble: yup.number(),
    scheduled: yup.number(),
  }),
  rows: yup.array().of(TroubleTicketListSchema.clone()),
});

export const TroubleTicketAuditListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(TroubleTicketListSchema.clone()),
});

export const TroubleTicketAuditReportFilterSchema = yup.object({
  startDate: yup.date(),
  endDate: yup.date(),
  isBoth: yup.boolean(),
  workGroups: yup.array().of(yup.number().required()),
  dateTime: yup.array(yup.date().required()),
});

export const TroubleTicketDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ITroubleTicketDocument = yup.Asserts<
  typeof ITroubleTicketDocumentSchema
>;

export type ITroubleTicketStatus = yup.Asserts<
  typeof ITroubleTicketStatusSchema
>;
export type ITroubleTicket = yup.Asserts<
  typeof TroubleTicketRequestPayloadSchema
>;
export type ITroubleTicketResponsePayload = yup.Asserts<
  typeof TroubleTicketSchema
>;
export type ITroubleTicketPayload = yup.Asserts<
  typeof TroubleTicketPayloadSchema
>;
export type ITroubleTicketList = yup.Asserts<typeof TroubleTicketListSchema>;
export type ITroubleTicketForm = yup.Asserts<typeof TroubleTicketFormSchema>;

export type ITroubleTicketAllFilters = yup.Asserts<
  typeof TroubleTicketAllFiltersSchema
>;

export type ITroubleTicketAuditReportFilter = yup.Asserts<
  typeof TroubleTicketAuditReportFilterSchema
>;

export type ITroubleTicketAllFilterstMethods = Pick<
  UseFormReturn<ITroubleTicketAllFilters>,
  'control' | 'formState'
> &
  Partial<
    Pick<
      UseFormReturn<ITroubleTicketAllFilters>,
      'setError' | 'clearErrors' | 'formState' | 'reset' | 'watch' | 'register'
    >
  >;
export type ITroubleTicketListPayload = yup.Asserts<
  typeof TroubleTicketListPayloadSchema
>;
