import {
  systemChangeActionTypes,
  ISystemChangeState,
} from 'src/ducks/system-change.duck';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import { ISystemChange } from 'src/models/system-change.model';

export const getSystemChangeStatus = (
  state: ISystemChangeState,
  action: keyof typeof systemChangeActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getSystemChangeHTML = (data: ISystemChange[] = []) => {
  return `<div id="element-to-print">
    ${data.map(
      (item, i) =>
        `<div id="page${i}">
      <div>Grid Ops System Changes (LER# ${item.requestId})</div>
      <table style="table-layout:fixed;">
        <tr>
          <td style="width:300px">In Service Date </td> <td>${Moment(
            item.inServiceDate
          ).format('ddd, MM/DD/YYYY')}</td>
        </tr>
        
        <tr><td>&nbsp;</td></tr>
        <tr>
          <td style="width:300px">______________System Change</td> <td></td>
        </tr>
        <tr><td>&nbsp;</td></tr>

        <tr>
        <td style="width:300px">${Lang.LBL_FACILITY} </td> <td>${
          item.outageFacility?.facility?.outgFacNm ?? '-'
        }${
          item.outageFacility?.substation
            ? ` @ ${item.outageFacility?.substation?.name}`
            : ''
        }</td>
      </tr>
        <tr>
          <td style="width:300px">${Lang.LBL_WORK_DESC}</td> <td>${
          item.wrkDesc
        }</td>
        </tr>
        <tr>
          <td style="width:300px">${Lang.LBL_COMMENTS}</td> <td>${
          item.sysChngSummary?.noteTxt
        }</td>
        </tr>
        <tr>
          <td colspan="2">Date Systems Change Completed by: ______________ On ___/___/______</td>
        </tr>
    
        </table> 
        <hr>
      </div>`
    )}</div>`;
};
