import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMModal,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import Access from 'src/components/atoms/access/access.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  AccessRole,
  AccessType,
  LERRequestAction,
  LERRequestChangeStatus,
  LERRequestStatus,
  TransmissionOutageStatusNonCancellable,
} from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
// import { hasChangeRequest } from 'src/helpers/ler-request.helper';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  LerRequestRejectFormSchema,
} from 'src/models/ler-request.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';

type IProps = {
  data: ILerRequest;
  trigger?: React.ReactNode;
  handleSuccess?: () => void;
  isChangeRequest?: boolean;
  isRejectAfterSchedule?: boolean;
  printLoader?: boolean;
};

const LerRequestReject: React.FC<IProps> = ({
  data,
  trigger,
  handleSuccess,
  isChangeRequest = false,
  isRejectAfterSchedule = false,
  printLoader = false,
}) => {
  const { state, actions } = useLerRequestContext();
  const { state: facilityState } = useFacilityContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_REJECT
  );
  const changeStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE
  );
  const updateStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_UPDATE
  );
  const loadingFacilityGroups = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ
  );

  const fetchData = async () => {
    if (state.caisoList.length === 0) {
      await actions.dataCaisoGET(data.requestId);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData) => {
      let result;

      if (isChangeRequest) {
        result = await actions.crStatusPUT(
          data.requestId,
          LERRequestAction.RejectChangeRequest,
          formData.rejectionReason
        );
      } else {
        result = await actions.rejectPUT(data.requestId, formData);
      }

      if (result.payload) {
        setIsOpen(false);
        if (isRejectAfterSchedule) {
          ToastSuccess(Lang.MSG_LER_REQUEST_REJECT_SCHED_SUCCESS);
        } else {
          ToastSuccess(Lang.MSG_LER_REQUEST_REJECT_CR_SUCCESS);
        }

        if (result.payload?.outageEmail) {
          if (result.payload?.outageEmail?.successOutageEmail?.length) {
            result.payload?.outageEmail?.successOutageEmail.map(
              (emailOutage) => {
                ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
              }
            );
          }
          if (result.payload?.outageEmail?.failedOutageEmail?.length) {
            result.payload?.outageEmail?.failedOutageEmail.map(
              (emailOutage) => {
                ToastError(emailOutage?.message ?? 'Failed to send email');
              }
            );
          }
        }

        if (
          isRejectAfterSchedule &&
          result.payload.requestStat === LERRequestStatus.Rejected
        ) {
          const omsListForCancellation = state.caisoList.filter(
            (item) =>
              !Object.values(TransmissionOutageStatusNonCancellable).includes(
                item.outageStatus as TransmissionOutageStatusNonCancellable
              )
          );

          if (omsListForCancellation.length > 0) {
            actions.setForApproval(true);
            actions.setIsFromReject(true);
            actions.setIsFromUpdate(false);
          }
        }

        if (handleSuccess) {
          handleSuccess();
        }
      }
    },
    [
      data,
      isChangeRequest,
      actions,
      handleSuccess,
      setIsOpen,
      isRejectAfterSchedule,
      state,
    ]
  );

  const loading =
    status.fetching ||
    changeStatus.fetching ||
    updateStatus.fetching ||
    loadingFacilityGroups.fetching;

  const isAllowed = useMemo(() => {
    if (isChangeRequest) {
      // #US 681032 Allow Scheduler to Approve and Reject Change Request even if
      // on Change Requested state given that the LER has been initially Scheudled/Approved.
      // Remove Supervisor flow of approval of CR if LER is already scheduled/approved.
      return (
        (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
          (data.changeReqStat === LERRequestChangeStatus.Submitted ||
            ((data.requestStat === LERRequestStatus.Scheduled ||
              data.requestStat === LERRequestStatus.Approved) &&
              data.changeReqStat === LERRequestChangeStatus.Requested))) ||
        (hasRole(AccessRole.MCC_SUBMITTING_SUPERVISOR) &&
          ![LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
            data.requestStat as LERRequestStatus
          ) &&
          data.changeReqStat === LERRequestChangeStatus.Requested)
      );
    }

    return (
      (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
        [LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
          data.requestStat as LERRequestStatus
        ) &&
        isRejectAfterSchedule) ||
      (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
        [LERRequestStatus.Submitted, LERRequestStatus.Study].includes(
          data.requestStat as LERRequestStatus
        )) ||
      (hasRole([
        AccessRole.MCC_SUBMITTING_SUPERVISOR,
        AccessRole.MCC_OPERATIONAL_ENGINEER,
      ]) &&
        data.requestStat === LERRequestStatus.Created)
    );
  }, [isChangeRequest, isRejectAfterSchedule, data]);

  return (
    <Access
      type={
        isChangeRequest
          ? AccessType.OUTAGE_REQUEST_CHANGE_REJECT
          : AccessType.OUTAGE_REQUEST_REJECT
      }
      when={isAllowed}
    >
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={
          trigger || (
            <ATMButton
              negative
              type="button"
              content={Lang.LBL_REJECT}
              disabled={loading || printLoader}
              loading={loading || printLoader}
            />
          )
        }
      >
        <ATMModal.Header>
          {isChangeRequest
            ? Lang.TTL_OUTAGE_REQUEST_CHANGE_REJECT
            : Lang.TTL_OUTAGE_REQUEST_REJECT}
        </ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_LER_REQUEST_CONFIRM_REJECT}</p>

          <ATMForm
            ref={formRef}
            onSubmit={handleSubmit}
            mode="onChange"
            validationSchema={LerRequestRejectFormSchema}
          >
            {({ control, formState: { errors } }) => (
              <>
                <ATMField
                  name="rejectionReason"
                  label={Lang.LBL_REASON_OF_REJECTION}
                  control={control}
                  as={ATMTextArea}
                  error={errors.rejectionReason}
                  charLimit={1000}
                  charCount
                />
              </>
            )}
          </ATMForm>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
            type="button"
          />
          <ATMButton
            type="button"
            negative
            content={Lang.LBL_REJECT_REQUEST}
            onClick={handleClick}
            disabled={loading}
            loading={loading}
          />
        </ATMModal.Actions>
      </ATMModal>
    </Access>
  );
};

export default LerRequestReject;
