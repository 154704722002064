import React from 'react';
import { ATMButton, ATMHeader } from 'shared-it-appmod-ui';
import { redirect } from 'src/libraries/amplify.library';

const Logout: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <ATMHeader>Logout</ATMHeader>
      <p>You are now logged out!</p>
      <ATMButton onClick={redirect}>Login</ATMButton>
    </div>
  );
};

export default Logout;
