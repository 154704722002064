import { set } from 'lodash';
import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/substation-count-list.service';
import { ISubstationCountList } from 'src/models/substation-count-list.model';

export const substationCountListActionTypes = {
  SUBSTATION_COUNT_LIST_DATA_READ: 'SUBSTATION_COUNT_LIST_DATA_READ',
  SUBSTATION_COUNT_LIST_LIST_READ: 'SUBSTATION_COUNT_LIST_LIST_READ',
  SUBSTATION_COUNT_LIST_DATA_CREATE: 'SUBSTATION_COUNT_LIST_DATA_CREATE',
  SUBSTATION_COUNT_LIST_DATA_UPDATE: 'SUBSTATION_COUNT_LIST_DATA_UPDATE',
  SUBSTATION_COUNT_LIST_DATA_DELETE: 'SUBSTATION_COUNT_LIST_DATA_DELETE',
  SUBSTATION_COUNT_LIST_DATA_SET: 'SUBSTATION_COUNT_LIST_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (substationCountList: ISubstationCountList) => ({
    type: substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_SET,
    payload: {
      substationCountList,
    },
  }),
};

export type ISubstationCountListAsync = typeof duckActions;

export interface ISubstationCountListState
  extends ICommonState<typeof substationCountListActionTypes> {
  data?: ISubstationCountList;
  list: ISubstationCountList[];
  total: number;
}

export const defaultState: ISubstationCountListState = {
  status: {},
  list: [],
  total: 0,
};

const SubstationCountListReducer = (
  state: ISubstationCountListState,
  action: IReducerAction<ISubstationCountListAsync>
): ISubstationCountListState => {
  switch (action.type) {
    case substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_SET:
    case substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_READ: {
      return {
        ...state,
        data: action.payload?.substationCountList,
      };
    }

    case substationCountListActionTypes.SUBSTATION_COUNT_LIST_LIST_READ: {
      return {
        ...state,
        list: action.payload ?? [],
      };
    }

    case substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_UPDATE: {
      // This is only for single edit
      if (action.payload && action.payload.length === 1) {
        const data = action.payload[0];
        const dataList = [...state.list];
        const dataIndex = dataList.findIndex((v) => v.year === data.year);

        const index = (state.list[dataIndex]?.substationCounts ?? []).findIndex(
          (v) => v.year === data.year && v.substationId === data.substationId
        );

        if (index > -1) {
          const dataSet = set(
            dataList[dataIndex],
            `substationCounts[${index}]`,
            {
              ...dataList[dataIndex].substationCounts[index],
              ...data,
            }
          );

          dataList.splice(dataIndex, 1, dataSet);

          return {
            ...state,
            list: dataList,
          };
        }
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default SubstationCountListReducer;
