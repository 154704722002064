import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/key-request.service';
import { IKeyRequest } from 'src/models/key-request.model';

export const keyRequestActionTypes = {
  KEY_REQUEST_DATA_READ: 'KEY_REQUEST_DATA_READ',
  KEY_REQUEST_LIST_READ: 'KEY_REQUEST_LIST_READ',
  KEY_REQUEST_LIST_BY_EMP_ID_READ: 'KEY_REQUEST_LIST_BY_EMP_ID_READ',
  KEY_REQUEST_DATA_CREATE: 'KEY_REQUEST_DATA_CREATE',
  KEY_REQUEST_DATA_UPDATE: 'KEY_REQUEST_DATA_UPDATE',
  KEY_REQUEST_DATA_REJECT: 'KEY_REQUEST_DATA_REJECT',
  KEY_REQUEST_DATA_APPROVE: 'KEY_REQUEST_DATA_APPROVE',
  KEY_REQUEST_DATA_SET: 'KEY_REQUEST_DATA_SET',
  KEY_REQUEST_VALIDATE_READ: 'KEY_REQUEST_VALIDATE_READ',
  KEY_REQUEST_CLEAR_ERROR: 'KEY_REQUEST_CLEAR_ERROR',
  KEY_REQUEST_DATA_EMPLOYEE_CREATE: 'KEY_REQUEST_DATA_EMPLOYEE_CREATE',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: keyRequestActionTypes.KEY_REQUEST_LIST_READ,
    service: services.listGET,
  },

  listByEmpIdGET: {
    type: keyRequestActionTypes.KEY_REQUEST_LIST_BY_EMP_ID_READ,
    service: services.listByEmpIdGET,
  },

  createPOST: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  createEmployeePOST: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_EMPLOYEE_CREATE,
    service: services.createEmployeePOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_UPDATE,
    service: services.updatePUT,
  },

  rejectPUT: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_REJECT,
    service: services.rejectPUT,
  },

  approvePUT: {
    type: keyRequestActionTypes.KEY_REQUEST_DATA_APPROVE,
    service: services.approvePUT,
  },

  validateGET: {
    type: keyRequestActionTypes.KEY_REQUEST_VALIDATE_READ,
    service: services.validateGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  // This is a sync action
  setData: (keyRequest: IKeyRequest) => ({
    type: keyRequestActionTypes.KEY_REQUEST_DATA_SET,
    payload: keyRequest,
  }),

  clearError: () => ({
    type: keyRequestActionTypes.KEY_REQUEST_CLEAR_ERROR,
  }),
};

export type IKeyRequestAsync = typeof duckActions;

export interface IKeyRequestState
  extends ICommonState<typeof keyRequestActionTypes> {
  data?: IKeyRequest;
  list: IKeyRequest[];
  total: number;
  approvedTotal: number;
  pendingTotal: number;
  rejectedTotal: number;
  listByEmpId: IKeyRequest[];
  totalListByEmpId: number;
  approvedListByEmpId: number;
  pendingListByEmpId: number;
  rejectedListByEmpId: number;
}

export const defaultState: IKeyRequestState = {
  status: {},
  list: [],
  total: 0,
  approvedTotal: 0,
  pendingTotal: 0,
  rejectedTotal: 0,
  listByEmpId: [],
  totalListByEmpId: 0,
  approvedListByEmpId: 0,
  pendingListByEmpId: 0,
  rejectedListByEmpId: 0,
};

const KeyRequestReducer = (
  state: IKeyRequestState,
  action: IReducerAction<IKeyRequestAsync>
): IKeyRequestState => {
  switch (action.type) {
    case keyRequestActionTypes.KEY_REQUEST_VALIDATE_READ: {
      return state;
    }
    case keyRequestActionTypes.KEY_REQUEST_DATA_SET:
    case keyRequestActionTypes.KEY_REQUEST_DATA_READ: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case keyRequestActionTypes.KEY_REQUEST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count.all ?? 0,
        approvedTotal: action.payload?.count.approved ?? 0,
        pendingTotal: action.payload?.count.pending ?? 0,
        rejectedTotal: action.payload?.count.rejected ?? 0,
      };
    }

    case keyRequestActionTypes.KEY_REQUEST_LIST_BY_EMP_ID_READ: {
      return {
        ...state,
        listByEmpId: action.payload?.rows ?? [],
        totalListByEmpId: action.payload?.count.all ?? 0,
        approvedListByEmpId: action.payload?.count.approved ?? 0,
        pendingListByEmpId: action.payload?.count.pending ?? 0,
        rejectedListByEmpId: action.payload?.count.rejected ?? 0,
      };
    }

    case keyRequestActionTypes.KEY_REQUEST_CLEAR_ERROR: {
      return {
        ...state,
        status: {},
      };
    }

    default: {
      return state;
    }
  }
};

export default KeyRequestReducer;
