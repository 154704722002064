import React, { useCallback, useMemo, useState } from 'react';
import {
  ATMGrid,
  ATMIcon,
  ATMDropdown,
  ATMPopover,
  ATMButton,
  ATMInput,
  ATMTable,
  ATMLoader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  ISubstationCountList,
  ISubstationCountListForm,
} from 'src/models/substation-count-list.model';
import {
  getSubstationCounts,
  getSubstations,
  getTransformedData,
} from 'src/selectors/substation-count-list.selector';
import SubstationCountEdit from '../substation-count-edit/substation-count-edit.component';
import styles from './substation-count-list.module.scss';

export const PopoverRender = ({ data, handleClose, handleSave, loading }) => {
  const [inputCount, setInputCount] = useState(data.custCnt ?? '');
  const [inputLoad, setInputLoad] = useState(data.substationLoad ?? '');

  return (
    <ATMGrid verticalAlign="middle" className={styles.customPopup}>
      <ATMGrid.Row className={styles.inputPopup}>
        <div className={`${styles.customLabel} ui label label`}>
          {`${Lang.LBL_COUNT}:`}
        </div>
        <ATMInput
          type="number"
          value={inputCount}
          onChange={(e) => setInputCount(e.target.value)}
        />
      </ATMGrid.Row>
      <ATMGrid.Row className={styles.inputPopup}>
        <div className={`${styles.customLabel} ui label label`}>
          {`${Lang.LBL_LOAD}:`}
        </div>
        <ATMInput
          className={styles.customDisplay}
          label="MV"
          labelPosition="right"
          type="number"
          value={inputLoad}
          onChange={(e) => setInputLoad(e.target.value)}
        />
      </ATMGrid.Row>
      <ATMGrid columns="equal" className={styles.buttonsPopup}>
        <ATMButton content="Cancel" secondary onClick={handleClose} />
        <ATMButton
          content="Save"
          primary
          disabled={loading}
          loading={loading}
          onClick={() => {
            handleSave([
              {
                custCnt: inputCount,
                substationLoad: inputLoad,
                substationId: data.substationId,
                year: data.year,
              },
            ]);
          }}
        />
      </ATMGrid>
    </ATMGrid>
  );
};

type IProps = {
  data: ISubstationCountList[];
  loading: boolean;
  loadingDL: boolean;
  editLoading: boolean;
  handleSave: (data: ISubstationCountListForm[]) => Promise<void>;
  handleFetch: (data) => void;
  handleDownload: (list: Record<any, any>[], years: string[]) => void;
  params: Record<any, any>;
  sortDirection?: 'ascending' | 'descending' | undefined;
  sortedColumn: string | null;
  handleSorting: (column) => void;
};

type IPropsSubstationCountListItem = {
  item: any;
  years: string[];
  handleSave: (data: ISubstationCountListForm[]) => Promise<void>;
};

const SubstationCountListItem: React.FC<IPropsSubstationCountListItem> = ({
  item,
  years,
  handleSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [closed, setClosed] = useState(false);
  const [showIcon, setShowIcon] = useState('');

  const id = item.substation.split(' - ')[0];
  const name = item.substation.split(' - ')[1];

  const handleClose = useCallback(() => {
    setClosed(false);
    setShowIcon('');
  }, [setClosed, setShowIcon]);

  const handleSubmit = useCallback(
    async (data) => {
      setLoading(true);
      await handleSave(data);

      setLoading(false);
      handleClose();
    },
    [handleSave, setLoading, handleClose]
  );

  return (
    <ATMTable.Row>
      <ATMTable.Cell>
        <strong>{id}</strong>
        {` - ${name}`}
      </ATMTable.Cell>
      {years.map((year, i) => {
        return (
          <React.Fragment key={i}>
            <ATMTable.Cell
              onMouseEnter={() => setShowIcon(`${item.substation}_${year}`)}
              onMouseLeave={() => setShowIcon('')}
            >
              {item[year]?.custCnt ?? '--'}
            </ATMTable.Cell>
            <ATMTable.Cell
              onMouseEnter={() => setShowIcon(`${item.substation}_${year}`)}
              onMouseLeave={() => setShowIcon('')}
            >
              {item[year]?.substationLoad ?? '--'}
              {showIcon === `${item.substation}_${year}` && (
                <ATMPopover
                  position="right center"
                  className={styles.popover}
                  trigger={
                    <ATMIcon
                      color="grey"
                      name={loading ? 'circle notch' : 'pencil'}
                      loading={loading}
                      className={styles.editIcon}
                    />
                  }
                  content={
                    <PopoverRender
                      handleClose={handleClose}
                      data={{
                        custCnt: item[year]?.custCnt,
                        substationLoad:
                          item[year]?.substationLoad === '--'
                            ? 0
                            : item[year]?.substationLoad,
                        substationId: item.substation.split(' - ')[0],
                        year,
                      }}
                      handleSave={handleSubmit}
                      loading={loading}
                    />
                  }
                  on="click"
                  open={closed}
                  onOpen={() => setClosed(true)}
                  onClose={handleClose}
                />
              )}
            </ATMTable.Cell>
          </React.Fragment>
        );
      })}
    </ATMTable.Row>
  );
};

const SubstationCountListListView: React.FC<IProps> = ({
  data,
  loading,
  handleSave,
  handleFetch,
  handleDownload,
  loadingDL,
  params,
  sortDirection,
  handleSorting,
  sortedColumn,
}) => {
  const years = useMemo(() => data.map(({ year }) => year), [data]);
  const substations = getSubstations(data);
  const listData = getSubstationCounts(data, years, substations);

  const tableFooter = (
    <ATMTable.Header fixedColumns="first">
      <ATMTable.Row>
        <ATMTable.HeaderCell>
          <strong>TOTAL COUNT</strong>
        </ATMTable.HeaderCell>
        {data.map((item, index) => {
          return (
            <>
              <ATMTable.HeaderCell key={index} textAlign="center">
                {item.total.toLocaleString()}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell textAlign="center"> </ATMTable.HeaderCell>
            </>
          );
        })}
      </ATMTable.Row>
    </ATMTable.Header>
  );

  return (
    <ATMGrid className={styles.containerContent}>
      <ATMGrid.Row>
        <ATMGrid.Column textAlign="right">
          <ATMDropdown
            selection
            clearable
            placeholder={Lang.LBL_SUBSTATIONS}
            options={substations.map((station) => ({
              key: station,
              value: station.split(' - ')[0],
              text: station,
            }))}
            selectOnBlur={false}
            onChange={(_, { value }) => {
              if (value !== '') {
                handleFetch({
                  ...params,
                  filters: [
                    {
                      name: 'substationId',
                      value,
                    },
                  ],
                });
              } else {
                handleFetch({ ...params, page: 1 });
              }
              return value;
            }}
          />
          <ATMButton
            key="download"
            secondary
            size="small"
            icon="download"
            floated="right"
            content={Lang.LBL_DOWNLOAD}
            onClick={() => handleDownload(listData, years)}
            loading={loadingDL}
            disabled={loading}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        {loading ? (
          <ATMLoader active inline="centered" />
        ) : (
          <ATMGrid.Column>
            <span>
              {Lang.formatString(Lang.MSG_SUB_COUNT, listData.length)}
            </span>
            <div>
              <ATMTable celled stickyHeader fixedColumns="first" sortable>
                <ATMTable.Header>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell
                      rowspan="2"
                      sorted={
                        sortedColumn === 'substationId'
                          ? sortDirection
                          : undefined
                      }
                      onClick={() => {
                        handleSorting('substationId');
                      }}
                    >
                      {Lang.LBL_ID_NAME}
                    </ATMTable.HeaderCell>
                    {years.map((year) => (
                      <ATMTable.HeaderCell
                        key={`year_${year}`}
                        colspan="2"
                        textAlign="center"
                        width={2}
                      >
                        {year}
                        <SubstationCountEdit
                          data={getTransformedData(data, year)}
                          year={Number(year)}
                          substations={substations}
                        />
                      </ATMTable.HeaderCell>
                    ))}
                  </ATMTable.Row>
                  <ATMTable.Row>
                    {years.map((year) => (
                      <>
                        <ATMTable.HeaderCell
                          key={`count_${year}`}
                          textAlign="center"
                          singleLine
                          width={1}
                          sorted={
                            sortedColumn === 'custCnt'
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => {
                            handleSorting('custCnt');
                          }}
                        >
                          {Lang.LBL_COUNT}
                        </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell
                          key={`load_${year}`}
                          textAlign="center"
                          singleLine
                          width={1}
                          sorted={
                            sortedColumn === 'substationLoad'
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => {
                            handleSorting('substationLoad');
                          }}
                        >
                          {Lang.LBL_LOAD_MV}
                        </ATMTable.HeaderCell>
                      </>
                    ))}
                  </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                  {listData.map((item, index) => {
                    return (
                      <SubstationCountListItem
                        key={index}
                        item={item}
                        years={years}
                        handleSave={handleSave}
                      />
                    );
                  })}
                </ATMTable.Body>
                {tableFooter}
              </ATMTable>
            </div>
          </ATMGrid.Column>
        )}
      </ATMGrid.Row>
    </ATMGrid>
  );
};

export default SubstationCountListListView;
