import {
  workGroupActionTypes,
  IWorkGroupState,
} from 'src/ducks/work-group.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getWorkGroupStatus = (
  state: IWorkGroupState,
  action: keyof typeof workGroupActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
