import React, { useCallback } from 'react';
import { ATMMenu, ATMTab } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { AccessType, hasAccess } from 'src/libraries/access.library';
import {
  IDailyReport,
  IDailyReportVoltageGroup,
  IETS,
  IRadialized,
} from 'src/models/daily-reports.model';
import { DailyReportViewTab } from 'src/constants/daily-reports.constant';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { ISystemChange } from 'src/models/system-change.model';
import DailyReportOverviewTable from './daily-reports-overview/daily-reports-overview.component';
import ETSTable from './daily-reports-ets/daily-reports-ets.component';
import RadializedSubstationsTable from './daily-reports-radialized-substations/daily-reports-radialized-substations.component';
import styles from './daily-reports.module.scss';
import DailyReportTabs from './daily-reports-tab/daily-reports-tab.component';
import DailyReportSystemChangeDetail from './daily-report-system-changes/daily-report-system-changes-detail.component';

type IProps = {
  reportDate: Date;
  dailyReports?:
    | IDailyReportVoltageGroup[]
    | IRadialized[]
    | IETS[]
    | ISystemChange[];
  setOverviewEmail: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  setDailyReportsLERs: React.Dispatch<React.SetStateAction<IDailyReport[]>>;
  setDailyReports: React.Dispatch<
    React.SetStateAction<
      | IDailyReportVoltageGroup[]
      | IRadialized[]
      | IETS[]
      | ISystemChange[]
      | undefined
    >
  >;
  setSelectAllFlag: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  selectAllFlag: boolean | undefined;
  setExcludeFlag: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  excludeFlag: boolean | undefined;
  setStartingEndingFlag: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  startingEndingFlag: boolean | undefined;
  setCheckType: React.Dispatch<React.SetStateAction<string | undefined>>;
  checkType: string | undefined;
};

// return (
//   <DailyReportsView
//     reportDate={reportDate}
//     dailyReports={dailyReports}
//     setDailyReportsLERs={setDailyReportsLERs}
//     setDailyReports={setDailyReports}
//     setOverviewEmail={setOverviewEmail}
//     setSelectAllFlag={setSelectAllFlag}
//     selectAllFlag={selectAllFlag}
//     setExcludeFlag={setExcludeFlag}
//     excludeFlag={excludeFlag}
//     setStartingEndingFlag={setStartingEndingFlag}
//     startingEndingFlag={startingEndingFlag}
//     setCheckType={setCheckType}
//     checkType={checkType}
//   />
// );

const DailyReports: React.FC<IProps> = ({
  reportDate,
  dailyReports,
  setDailyReportsLERs,
  setDailyReports,
  setOverviewEmail,
  setSelectAllFlag,
  selectAllFlag,
  setExcludeFlag,
  excludeFlag,
  setStartingEndingFlag,
  startingEndingFlag,
  setCheckType,
  checkType,
}) => {
  const { state, actions } = useDailyReportsContext();

  const getDailyReportByType = useCallback(
    (type: DailyReportViewTab) => {
      switch (type) {
        case DailyReportViewTab.ETS: {
          actions.stashViewType(DailyReportViewTab.ETS);
          break;
        }
        case DailyReportViewTab.RadializedSubstations: {
          actions.stashViewType(DailyReportViewTab.RadializedSubstations);
          break;
        }
        case DailyReportViewTab.SystemChanges: {
          actions.stashViewType(DailyReportViewTab.SystemChanges);
          break;
        }
        case DailyReportViewTab.Overview: {
          actions.stashViewType(DailyReportViewTab.Overview);
          break;
        }
      }
    },
    [actions, state]
  );
  const panes = [
    {
      menuItem: (
        <ATMMenu.Item
          key={Lang.LBL_OVERVIEW}
          onClick={() => getDailyReportByType(DailyReportViewTab.Overview)}
        >
          {Lang.LBL_OVERVIEW}
        </ATMMenu.Item>
      ),
      // eslint-disable-next-line react/jsx-no-undef
      render: () => (
        <ATMTab.Pane attached={false}>
          <DailyReportOverviewTable
            setOverviewEmail={setOverviewEmail}
            reportDate={reportDate}
            reportData={dailyReports as any}
            setDailyReportsLERs={setDailyReportsLERs}
            setDailyReports={setDailyReports}
            setSelectAllFlag={setSelectAllFlag}
            selectAllFlag={selectAllFlag}
            setExcludeFlag={setExcludeFlag}
            excludeFlag={excludeFlag}
            setStartingEndingFlag={setStartingEndingFlag}
            startingEndingFlag={startingEndingFlag}
            setCheckType={setCheckType}
            checkType={checkType}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  if (hasAccess(AccessType.DAILY_ETS_REPORT_READ)) {
    panes.push({
      menuItem: (
        <ATMMenu.Item
          key={Lang.LBL_ETS}
          onClick={() => getDailyReportByType(DailyReportViewTab.ETS)}
        >
          {Lang.LBL_ETS}
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <ETSTable />
        </ATMTab.Pane>
      ),
    });
  }

  if (hasAccess(AccessType.DAILY_RADIALIZED_SUBSTATION_READ)) {
    panes.push({
      menuItem: (
        <ATMMenu.Item
          key={Lang.LBL_RADIALIZED_SUBSTATIONS}
          onClick={() =>
            getDailyReportByType(DailyReportViewTab.RadializedSubstations)
          }
        >
          {Lang.LBL_RADIALIZED_SUBSTATIONS}
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <RadializedSubstationsTable data={dailyReports as any} />
        </ATMTab.Pane>
      ),
    });
  }

  if (hasAccess(AccessType.REPORT_SYSTEM_CHANGE_STATUS_READ)) {
    panes.push({
      menuItem: (
        <ATMMenu.Item
          key={Lang.LBL_DAILY_REPORT_SYSTEM_CHANGES}
          onClick={() => getDailyReportByType(DailyReportViewTab.SystemChanges)}
        >
          {Lang.LBL_DAILY_REPORT_SYSTEM_CHANGES}
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <DailyReportSystemChangeDetail />
        </ATMTab.Pane>
      ),
    });
  }

  return (
    <>
      <div className={styles.proTabs}>
        <DailyReportTabs
          name="dailyReports"
          menu={{ attached: false }}
          panes={panes}
        />
      </div>
    </>
  );
};

export default DailyReports;
