import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  IUserGroupForm,
  UserGroupDeletePayloadSchema,
  UserGroupListPayloadSchema,
  UserGroupPayloadSchema,
} from 'src/models/user-group.model';

const client = httpClient();
const endpoint = 'user-group';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, UserGroupPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, UserGroupListPayloadSchema);
  },

  startEngineerListGET: async () => {
    return client.get(
      `/${endpoint}`,
      {
        page: 1,
        limit: 0,
        filters: [
          {
            name: 'userGrpNm',
            value: 'Start Engineer',
          },
        ],
      },
      UserGroupListPayloadSchema
    );
  },

  createPOST: async (data: IUserGroupForm) => {
    return client.post(`/${endpoint}`, data, UserGroupPayloadSchema);
  },

  updatePUT: async (id: number, data: IUserGroupForm) => {
    return client.put(`/${endpoint}/${id}`, data, UserGroupPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, UserGroupDeletePayloadSchema);
  },
};

export default services;
