export enum IssuedKeyStatus {
  ACTIVE = 'Active',
  LOST = 'Lost',
  DAMAGED = 'Damaged',
  NOTRETURNED = 'Not Returned',
  PENDINGRETURN = 'Pending Return',
  RETURNED = 'Returned',
  PENDING = 'Pending',
}

export enum KeyHolderType {
  EMPLOYEE = 'Employee',
  CONTRACTOR = 'Contractor',
}

export enum IssuedKeyTab {
  All,
  Active,
  Damaged,
  Lost,
  Returned,
  PendingReturn,
  NotReturned,
}

export const issuedKeyStatus = IssuedKeyStatus;
export const keyHolderType = KeyHolderType;
export const issuedKeyTab = IssuedKeyTab;
