import {
  IEmployee,
  IEmployeeSearch,
  IEmployeeInternal,
  IVendor,
} from 'src/models/employee.model';
import { employeeActionTypes, IEmployeeState } from 'src/ducks/employee.duck';
import { getEmployeeName } from 'src/helpers/employee.helper';

export const getEmployeeStatus = (
  state: IEmployeeState,
  action: keyof typeof employeeActionTypes
) =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getEmployeeOptions = (
  data: Partial<IEmployee>[],
  valueField: keyof IEmployee = 'emp_id'
) =>
  data.map((value) => ({
    key: value.emp_id,
    text: getEmployeeName({
      empId: value.emp_id,
      firstName: value.first_name,
      lastName: value.last_name,
      middleName: value.middle_name,
    }),
    value: value[valueField],
  }));

export const getVendorOptions = (data: Partial<IVendor>) =>
  data.map((val) => ({
    key: val,
    value: val,
    text: val,
  }));

export const getParsedEmployee = (employee: IEmployeeSearch): IEmployee => ({
  emp_id: employee.EMPNO ?? '--',
  first_name: employee.FIRST_NAME,
  last_name: employee.LAST_NAME,
  e_mail_address: employee.E_MAIL_ADDRESS,
  job_title: employee.JOB_TITLE ?? '--',
  company_name: employee.COMPANY_NAME ?? '--',
  dept_org_desc: employee.DEPT_ORG_DESC ?? '--',
  work_phone_1: employee.WORK_PHONE_1 ?? '--',
  cell_phone: employee.CELL_PHONE ?? '--',
  contactType: undefined,
  contactType_id: undefined,
  lan_user_id: undefined,
  middle_name: undefined,
  company_id: employee.COMPANY_ID,
  vendor_name: undefined,
  mgr_first_name: employee.MGR_FIRST_NAME,
  mgr_empno: employee.MGR_EMPNO,
  mgr_last_name: employee.MGR_LAST_NAME,
  mailstop_id: employee.MAILSTOP_ID,
  job_code: employee.JOB_CODE,
});

export const getParsedEmployeeInternal = (
  employee: IEmployeeInternal
): IEmployee => ({
  emp_id: employee.empno ?? '',
  first_name: employee.firstName ?? '',
  last_name: employee.lastName ?? '',
  middle_name: employee.middleName,
  e_mail_address: employee.emailAddress ?? '',
  company_name: employee.companyName ?? '',
  dept_org_desc: employee.departmentName ?? '',
  work_phone_1: employee.phoneNumber ?? '--',
  cell_phone: employee.cellPhone ?? '--',
  contactType: undefined,
  contactType_id: undefined,
  lan_user_id: undefined,
  job_title: undefined,
  company_id: undefined,
  vendor_name: undefined,
  mgr_first_name: employee.mgrFirstName ?? '',
  mgr_empno: employee.mgrEmpno ?? '',
  mgr_last_name: employee.lastName ?? '',
  mailstop_id: employee.mailstopId ?? '',
  job_code: employee.jobCode ?? '',
});

export const addDoubleZeroEmpId = (empId: string) => {
  if (empId.length === 5) {
    return `00${empId}`;
  }
  if (empId.length === 6) {
    return `0${empId}`;
  }
  return empId;
};

export const removeDoubleZeroEmpId = (empId: string) => {
  return empId.replace(/^00/gm, '');
};
