import React, { useEffect, useState } from 'react';
import { ATMDivider, ATMLoader, ATMPopover } from 'shared-it-appmod-ui';
import { IEmployeeRoleFilter, IRoleEmployeeItem } from 'src/models/roles.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useRolesContext } from 'src/contexts/roles.context';
import { getRolesStatus } from 'src/selectors/roles.selector';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import { getEmployeeName } from 'src/helpers/employee.helper';
import styles from './roles-card.module.scss';
import RolesEdit from '../roles-edit/roles-edit.component';

type IProps = {
  data: IRoleEmployeeItem;
  onClose: boolean;
  openRightPanel: () => void;
  setFilterState: React.Dispatch<
    React.SetStateAction<Partial<IEmployeeRoleFilter>>
  >;
  filterState: Partial<IEmployeeRoleFilter>;
};

const RolesCard: React.FC<IProps> = ({
  data,
  onClose,
  openRightPanel,
  filterState,
}) => {
  const { state, actions } = useRolesContext();

  const status = getRolesStatus(state, rolesActionTypes.ROLES_DATA_READ);

  const [isOpen, setIsOpen] = useState(false);
  const title = getEmployeeName(data);

  useEffect(() => {
    if (isOpen) {
      actions.dataByEmpIdGET(data.empId ?? '');
    }
  }, [isOpen]);

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        open={isOpen}
        closeOnDocumentClick={!onClose}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          <u>
            <a
              role="button"
              className={styles.nameLink}
              onClick={() => setIsOpen(true)}
            >
              {title}
            </a>
          </u>
        }
        position="right center"
        on="click"
      >
        <ATMPopover.Header>
          <span className={styles.overlayHeader}>{title}</span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          {status.fetching ? (
            <ATMLoader active inline="centered" />
          ) : (
            <>
              <div className={styles.overlayContent}>
                <FieldReadOnly label="Cell Phone">
                  {data?.cellPhoneNumber == null ? '--' : data?.cellPhoneNumber}
                </FieldReadOnly>
                <FieldReadOnly label="Work Phone">
                  {data?.workPhoneNumber == null ? '--' : data?.workPhoneNumber}
                </FieldReadOnly>
                <FieldReadOnly label="Manager Name">
                  {data?.supervisorNumber}
                </FieldReadOnly>
                <FieldReadOnly label="Manager Mail Stop">
                  {data?.mailLoc}
                </FieldReadOnly>
                <FieldReadOnly label="Org.Code">{data?.orgCd}</FieldReadOnly>
              </div>
              <div className={styles.actionButtons}>
                <RolesEdit
                  data={data}
                  setOnClose={openRightPanel}
                  onClose={onClose}
                  filterState={filterState}
                  setIsOpenCard={setIsOpen}
                />
              </div>
            </>
          )}
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default RolesCard;
