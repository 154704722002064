import React, { useCallback, useState } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useSubstationCountListContext } from 'src/contexts/substation-count-list.context';
import { substationCountListActionTypes } from 'src/ducks/substation-count-list.duck';
import Lang from 'src/libraries/language';
import { ISubstationCountListFormArray } from 'src/models/substation-count-list.model';
import { getSubstationCountListStatus } from 'src/selectors/substation-count-list.selector';
import SubstationCountView from './substation-count-edit.view';

type IProp = {
  data: ISubstationCountListFormArray;
  year: number;
  substations: string[];
};

const SubstationCountEdit: React.FC<IProp> = ({ data, year, substations }) => {
  const { state, actions } = useSubstationCountListContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const loading = getSubstationCountListStatus(
    state,
    substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_UPDATE
  ).fetching;

  const handleSubmit = useCallback(
    async (formData: ISubstationCountListFormArray) => {
      const response = await actions.updatePUT(formData.substationCounts);
      if (!response.error) {
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_SUB_COUNT_UPDATE_SUCCESS,
            Lang.LBL_SUBSTATIONS,
            formData.substationCounts[0].year
          )
        );
        actions.listGET();
        setIsEditOpen(false);
      } else {
        ToastError(response.error);
      }
    },
    [actions]
  );

  return (
    <SubstationCountView
      defaultValues={data}
      loading={loading}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      year={year}
      substations={substations}
    />
  );
};

export default SubstationCountEdit;
