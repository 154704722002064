import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TroubleJobsReducer, {
  ITroubleJobsState,
  defaultState,
  duckActions,
} from 'src/ducks/trouble-jobs.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TroubleJobsReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITroubleJobsContext = ReturnType<typeof useReducer>;

const TroubleJobsContext = createContext<Partial<ITroubleJobsContext>>({
  state: defaultState,
}) as React.Context<ITroubleJobsContext>;

type IProps = {
  state?: Partial<ITroubleJobsState>;
};

const TroubleJobsProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TroubleJobsContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TroubleJobsContext.Provider>
  );
};

const useTroubleJobsContext = () => useContext(TroubleJobsContext);

export type IUseTroubleJobsContext = ReturnType<typeof useTroubleJobsContext>;

export { TroubleJobsContext, TroubleJobsProvider, useTroubleJobsContext };
