import React, { useCallback, useState } from 'react';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { IWorkGroup } from 'src/models/work-group.model';
import { getWorkGroupStatus } from 'src/selectors/work-group.selector';
import { workGroupActionTypes } from 'src/ducks/work-group.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import WorkGroupDeleteView from './work-group-delete.view';

type IProp = {
  data: IWorkGroup;
};

const WorkGroupDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useWorkGroupContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getWorkGroupStatus(
    state,
    workGroupActionTypes.WORK_GROUP_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.trbGrpId) {
      const response = await actions.dataDELETE(data.trbGrpId);

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        ToastSuccess(
          Lang.formatString(Lang.MSG_WORK_GROUP_DELETE, data.trbGrpNm)
        );
      } else {
        ToastError(response.error.message);
      }
      setIsOpen(false);
    }
  }, [actions, data]);

  return (
    <WorkGroupDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default WorkGroupDelete;
