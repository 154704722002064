/* eslint-disable no-bitwise */
/* eslint-disable react/no-danger */
import React, { useMemo, useState } from 'react';
import {
  ATMCheckbox,
  ATMIcon,
  ATMModal,
  ATMPopover,
} from 'shared-it-appmod-ui';
import { SettingName } from 'src/constants';
import { useSettingContext } from 'src/contexts/setting.context';
import { getSettingValue } from 'src/selectors/setting.selector';
import {
  getLocalStorageItem,
  removeLocalStrongItem,
  setLocalStorageItem,
} from 'src/helpers/storage.helper';
import { IAuthUser } from 'src/libraries/amplify.library';
import styles from './global-message.module.scss';

const hashCode = (value) => {
  let hash = 0;

  if (value.length !== 0) {
    for (let i = 0; i < value.length; i++) {
      const chr: number = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
  }

  return `popup_${hash}`;
};

type IProp = {
  user: IAuthUser;
};

const GlobalMessage: React.FC<IProp> = ({ user }) => {
  const { state } = useSettingContext();
  const text =
    getSettingValue(SettingName.POPUP_LANDING_TEXT, state.list) || '';
  const hash = hashCode(text.slice(0, 100));
  const cache = useMemo(() => getLocalStorageItem(hash) || false, [hash]);
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(cache);
  const isDisplay =
    getSettingValue(SettingName.POPUP_LANDING_DISPLAY, state.list) === 'YES' &&
    !cache;

  if (!isDisplay) {
    return null;
  }

  return (
    <ATMModal
      className={styles.wrapper}
      open={isOpen}
      closeOnDimmerClick
      dimmer="inverted"
      closeOnEscape
    >
      <ATMPopover
        content={
          <ATMCheckbox
            className={styles.checkbox}
            checked={isChecked}
            label="Do not show again"
            onChange={(_, { checked }) => {
              setIsChecked(checked);

              if (checked) {
                setLocalStorageItem(hash, checked);
              } else {
                removeLocalStrongItem(hash);
              }
            }}
          />
        }
        trigger={
          <ATMIcon
            name="close"
            onClick={() => setIsOpen(false)}
            color="black"
          />
        }
        hoverable
      />
      <div
        dangerouslySetInnerHTML={{
          __html: text
            .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
            .replace(/\$User/gi, user.first_name),
        }}
      />
    </ATMModal>
  );
};

export default GlobalMessage;
