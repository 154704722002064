import yup from 'src/libraries/validator.library';

export const TroubleEquipmentTypeShape = {
  id: yup.number().required(),
  name: yup.string().required(),
  lineInd: yup.boolean(),
  substationInd: yup.boolean(),
  updatedBy: yup.string().required(),
  updatedAt: yup.date().required(),
};

export const TroubleEquipmentTypeSchema = yup.object(TroubleEquipmentTypeShape);

export const TroubleEquipmentTypeFormSchema = yup.object({
  ...TroubleEquipmentTypeShape,
  id: yup.number(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
});

export const TroubleEquipmentTypeFilterShape = {
  lineInd: yup.string(),
  substationInd: yup.boolean(),
};

export const TroubleEquipmentTypeFilterSchema = yup.object(
  TroubleEquipmentTypeFilterShape
);

export const TroubleEquipmentTypeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(TroubleEquipmentTypeSchema.clone()),
});

// export const TroubleEquipmentTypePayloadSchema = yup.object({
//   troubleEquipmentType: TroubleEquipmentTypeSchema.clone(),
// });

export const TroubleEquipmentTypeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ITroubleEquipmentType = yup.Asserts<
  typeof TroubleEquipmentTypeSchema
>;
export type ITroubleEquipmentTypeForm = yup.Asserts<
  typeof TroubleEquipmentTypeFormSchema
>;
export type ITroubleEquipmentTypeFilter = Partial<
  yup.Asserts<typeof TroubleEquipmentTypeFilterSchema>
>;
