import React, { useCallback, useRef, useState } from 'react';
import { useJobCodeRuleContext } from 'src/contexts/job-code-rule.context';
import { jobCodeRuleActionTypes } from 'src/ducks/job-code-rule.duck';
import { IJobCodeRule, IJobCodeRuleForm } from 'src/models/job-code-rule.model';
import { getJobCodeRuleStatus } from 'src/selectors/job-code-rule.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import history from 'src/history';
import JobCodeRuleEditView from './job-code-rule-edit.view';

type IProp = {
  data: IJobCodeRule;
};

const JobCodeRuleEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useJobCodeRuleContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getJobCodeRuleStatus(
    state,
    jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IJobCodeRuleForm) => {
      const response = await actions.updatePUT(data.jobCd, {
        ...formData,
        // updatedBy: 'John Doe',
      });

      if (!response.error) {
        setIsEditOpen(false);
        ToastSuccess(
          `Key Rule/s updated successfully for Job code ${data.jobCd}`
        );
        history.push('/keys?page=1&limit=50&tab=3');
      } else {
        ToastError('Error updating Job Key Rule.');
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <JobCodeRuleEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default JobCodeRuleEdit;
