import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ATMGrid, ATMIcon } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import ConfirmationContainer from 'src/components/atoms/confirmation/confirmation.component';
import {
  IKeyRequest,
  IKeyRequestValidationForm,
  IKeyRequestForm,
  KeyRequestFormSchema,
  IKeyRequestPayload,
} from 'src/models/key-request.model';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { getSharepointEnvironmentProperty } from 'src/helpers/sharepoint-service.helper';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import {
  ISharepointProperty,
  ISharepointRequestData,
} from 'src/models/sharepoint-service.model';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import { getUser } from 'src/libraries/amplify.library';
import KeyRequestContractorView from './key-request-contractor.view';
import styles from '../key-request.module.scss';

type IProp = {
  data?: Partial<IKeyRequest>;
};

const KeyRequestContractor: React.FC<IProp> = () => {
  const { state, actions } = useKeyRequestContext();
  const { actions: contractFirmActions } = useContractorFirmContext();
  const { state: settingState } = useSettingContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirm, setConfirm] = useState<IKeyRequest>();
  const formRef = useRef<HTMLFormElement>(null);
  const token = useRef<string>('');
  const setting = useRef<ISharepointProperty>();
  const { state: fileState } = useFileContext();
  const { submitUpload, getCachedAccessToken } = useSharepoint();

  useEffect(() => {
    contractFirmActions?.listGET({ limit: 0, page: 1 });
  }, []);

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_CREATE
  );

  const dataGetStatus = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_READ
  );

  useEffect(() => {
    const fetchSharepointProp = async () => {
      const sharepointProp = await getSharepointEnvironmentProperty(
        settingState.list,
        SharePointApplicationType.KEY_REQUEST_TYPE
      );
      setting.current = sharepointProp;
    };
    if (isOpen) {
      fetchSharepointProp();
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getCachedAccessToken(new Date());

      if (response.length) {
        token.current = response;
      }
    };
    if (isOpen) {
      fetchToken();
    }
  }, [actions, token, isOpen]);

  const handleFileUploadSubmit = useCallback(
    async (responseData: IKeyRequestPayload) => {
      // const requestor = `${responseData?.requestor?.firstName} ${responseData?.requestor?.lastName}`;
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName:
          JSON.stringify(responseData?.keyRequestDetail?.requestNbr) || '',
        parentFolder:
          JSON.stringify(responseData?.keyRequestDetail?.requestNbr) ?? '',
        requestId: responseData?.keyRequestDetail?.requestNbr,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.KEY_REQUEST_TYPE,
        setting: settingState,
        withParentFolder: false,
      });
    },
    [fileState, settingState]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IKeyRequestValidationForm) => {
      const response = await actions.createPOST(
        KeyRequestFormSchema.cast(formData) as IKeyRequestForm
      );

      if (!response.error) {
        if (response.payload) {
          const insertedData = await actions.dataGET(
            response.payload.requestNbr
          );
          setConfirm(insertedData.payload);
          handleFileUploadSubmit(insertedData.payload as IKeyRequestPayload);
          setIsOpen(false);
          setIsConfirmOpen(true);
        }
      } else {
        ToastError('Error!');
      }
    },
    [actions, setIsOpen, fileState, handleFileUploadSubmit, setIsConfirmOpen]
  );

  const printContent = `<table style="font-family: Calibri; margin: 0 auto; width: 1260px;">
  <thead style="font-size: 36px;">
    <tr style="height: 100px">
      <th colspan="4">Confirmation - Key Request for Contractors</th>
    </tr>
  </thead>
  <tbody style="font-size: 24px;">
    <tr>
      <td colspan="4" style="color: green;">&#10004; The following Key(s) has been successfully generated:</td>
    </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Contact Employee ID</td>
        <td style="width:33%">Firm Name</td>
        <td style="width:33%">Key Type</td>
      </tr>
      <tr>
      <td style="width:33%">${confirm && confirm.emp.lastName}, ${
    confirm && confirm.emp.firstName
  }/${confirm && confirm.emp.empId}</td>
        <td style="width:33%">${
          confirm &&
          confirm.keyRequestDetail.keyDetail &&
          confirm.keyRequestDetail.keyDetail[0].contrShortNm
            ? confirm.keyRequestDetail.keyDetail[0].contrShortNm
            : '-'
        }</td>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.keyTypId
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Number of Keys</td>
        <td style="width:33%">Starting Key</td>
      </tr>
      <tr>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.noOfKeys
        }</td>
        <td style="width:33%">${
          confirm &&
          confirm.keyRequestDetail.keyNbr &&
          convertKeyNumberToFiveDigits(confirm.keyRequestDetail.keyNbr)
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td>Issuance Reason</td>
      </tr>
      <tr>
        <td colspan="4">${
          confirm &&
          confirm.keyRequestDetail.keyDetail &&
          confirm.keyRequestDetail.keyDetail[0].reason
            ? confirm.keyRequestDetail.keyDetail[0].reason
            : '-'
        }</td>
      </tr>
  </tbody>
</table>`;

  const modalContent = (
    <>
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <p>
              <ATMIcon color="green" name="check circle" />{' '}
              <span className={styles.successMsg}>
                The following Key(s) has been successfully generated:
              </span>
            </p>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Contact Employee ID">
                <p>
                  {confirm.emp.lastName}, {confirm.emp.firstName}/
                  {confirm.emp.empId}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Firm Name">
                {/* Get from HR Repo */}
                <p>
                  {confirm.keyRequestDetail.keyDetail &&
                    confirm.keyRequestDetail.keyDetail[0].contrShortNm}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Type">
                <p>{confirm.keyRequestDetail.keyTypId}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Number of Keys">
                <p>{confirm.keyRequestDetail.noOfKeys}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Starting Key">
                <p>
                  {confirm &&
                    confirm.keyRequestDetail.keyNbr &&
                    convertKeyNumberToFiveDigits(
                      confirm.keyRequestDetail.keyNbr
                    )}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column>
            {confirm && (
              <FieldReadOnly label="Issuance Reason">
                <p>
                  {confirm.keyRequestDetail.keyDetail &&
                  confirm.keyRequestDetail.keyDetail[0].reason
                    ? confirm.keyRequestDetail.keyDetail[0].reason
                    : '-'}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <>
      {confirm && (
        <ConfirmationContainer
          content={modalContent}
          printContent={printContent}
          header="Key Request for Contractors"
          isOpen={isConfirmOpen}
          loading={dataGetStatus.fetching}
          handleOpen={setIsConfirmOpen}
          url="/keys?stat=1"
        />
      )}
      <KeyRequestContractorView
        formRef={formRef}
        loading={status.fetching || dataGetStatus.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        handleOpen={setIsOpen}
      />
    </>
  );
};

export default KeyRequestContractor;
