import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ForwardTicketReducer, {
  IForwardTicketState,
  defaultState,
  duckActions,
} from 'src/ducks/forward-ticket.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    ForwardTicketReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IForwardTicketContext = ReturnType<typeof useReducer>;

const ForwardTicketContext = createContext<Partial<IForwardTicketContext>>({
  state: defaultState,
}) as React.Context<IForwardTicketContext>;

type IProps = {
  state?: Partial<IForwardTicketState>;
};

const ForwardTicketProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ForwardTicketContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ForwardTicketContext.Provider>
  );
};

const useForwardTicketContext = () => useContext(ForwardTicketContext);

export type IUseForwardTicketContext = ReturnType<
  typeof useForwardTicketContext
>;

export { ForwardTicketContext, ForwardTicketProvider, useForwardTicketContext };
