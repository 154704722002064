/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMSegment,
  ATMHeader,
  ATMDivider,
  ATMGrid,
  ATMTable,
  ATMLoader,
} from 'shared-it-appmod-ui';
import Status from 'src/components/atoms/status/status.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
  getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import { IDailyReport } from 'src/models/daily-reports.model';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import styles from '../daily-reports-view-details.module.scss';

type IProps = {
  data: IDailyReport;
};

const DailyReportsViewDetail: React.FC<IProps> = ({ data }) => {
  const { state } = useLerRequestContext();

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_RELATED_OUTAGES_READ
  );

  return (
    <ATMSegment>
      <ATMHeader as="h2" content={`LER ${data.requestId}`} />
      <ATMDivider />
      <ATMHeader as="h3" content="Job Info" />
      <ATMGrid columns="2" className={styles.infoPad}>
        <ATMGrid.Column>
          <ATMTable columns="2" className={styles.lerDetail}>
            <ATMTable.Body>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Work Order #:</ATMTable.Cell>
                <ATMTable.Cell>{data.woNbr ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Voltage:</ATMTable.Cell>
                <ATMTable.Cell>{data.voltNm ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Facilities:</ATMTable.Cell>
                <ATMTable.Cell>{data.outgFacNm ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5} verticalAlign="top">
                  Work Description:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.wrkDesc
                    ? data.wrkDesc.split('\n').map((item, i) => {
                        return (
                          <span key={i}>
                            <span>{item}</span>
                            <br />
                          </span>
                        );
                      })
                    : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>ETR:</ATMTable.Cell>
                <ATMTable.Cell>{data.etr ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5} verticalAlign="top">
                  Job Location:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.jobLoc
                    ? data.jobLoc.split('\n').map((item, i) => {
                        return (
                          <span key={i}>
                            <span>{item}</span>
                            <br />
                          </span>
                        );
                      })
                    : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>TB Coord:</ATMTable.Cell>
                <ATMTable.Cell>{data.facTbsCoord ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>
                  {Lang.LBL_GROUND_STATEMENT}:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.clsdGrndDsconctInd ? Lang.LBL_YES : Lang.LBL_NO}
                </ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>
                  {Lang.LBL_TEST_VOLTAGE}:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.testVoltageApplied ? Lang.LBL_YES : Lang.LBL_NO}
                </ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>
                  {Lang.LBL_SYSTEM_CHANGE}:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data?.sysChngInd
                    ? `System changes are required on ${formatDate(
                        data.sysChngLastDate
                      )}`
                    : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMTable columns="2" className={styles.lerDetail}>
            <ATMTable.Body>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Authorization Type:</ATMTable.Cell>
                <ATMTable.Cell>{data.outageTypeDesc ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5} verticalAlign="top">
                  Operation Notes:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.authStmnt
                    ? data.repeatJob === true &&
                      !data.authStmnt.toLowerCase().includes('repeat job')
                      ? data.authStmnt.split('\n').map((item, i) => {
                          return (
                            <span key={i}>
                              {i === 0 && (
                                <>
                                  <span>Repeat Job</span>
                                  <br />
                                </>
                              )}
                              <span>{item}</span>
                              <br />
                            </span>
                          );
                        })
                      : data.authStmnt.split('\n').map((item, i) => {
                          return (
                            <span key={i}>
                              <span>{item}</span>
                              <br />
                            </span>
                          );
                        })
                    : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5} verticalAlign="top">
                  Daily Outage Report Notes:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.dailyNotes && data.dailyNotes.length > 0 ? (
                    <ATMTable columns={2} className={styles.lerDetail}>
                      {data.dailyNotes.map((dailyNote, i) => {
                        return (
                          <ATMTable.Row key={i}>
                            <ATMTable.Cell
                              style={{ width: '2%' }}
                              verticalAlign="top"
                            >
                              {i + 1}:
                            </ATMTable.Cell>
                            <ATMTable.Cell>
                              {/* {dailyNote.split('\n').join(' | ')}
                               */}
                              {dailyNote.split('\n').map((item, j) => {
                                return (
                                  <span key={`${i}_${j}`}>
                                    <span>{item}</span>
                                    <br />
                                  </span>
                                );
                              })}
                            </ATMTable.Cell>
                          </ATMTable.Row>
                        );
                      })}
                    </ATMTable>
                  ) : (
                    '-'
                  )}
                </ATMTable.Cell>
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
      <ATMHeader as="h3" content="Related LERs" />
      <div className={styles.infoPad}>
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>{`${Lang.LBL_LER}#`}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>
                {Lang.LBL_FACILITY_INFO_KV}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_FACILITY}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>
                {Lang.LBL_FACILITY_INFO_AUTHORIZATION}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_DATES}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_STATUS}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {state.relatedOutagesList && !loading.fetching ? (
              state.relatedOutagesList
                .filter((val) => val.grpId === data.grpId)
                .map((val, index) => {
                  if (val.requestId !== data.requestId) {
                    return (
                      <ATMTable.Row key={index}>
                        <ATMTable.Cell>{val.requestId}</ATMTable.Cell>
                        <ATMTable.Cell>
                          {val.outageFacility?.facility?.volt?.voltNm}
                        </ATMTable.Cell>
                        <ATMTable.Cell>
                          {val.outageFacility?.facility?.outgFacNm}
                        </ATMTable.Cell>
                        <ATMTable.Cell>
                          {val.outageFacility?.outageType?.outageTypeDesc}
                        </ATMTable.Cell>
                        <ATMTable.Cell>{`${getLerRequestStartDate(
                          val.outageDates as any
                        )} - ${getLerRequestEndDate(
                          val.outageDates as any
                        )}`}</ATMTable.Cell>
                        <ATMTable.Cell>
                          <Status
                            language={Lang.LER_REQUEST_STATUS}
                            value={val.requestStat}
                            label
                          />
                        </ATMTable.Cell>
                      </ATMTable.Row>
                    );
                  }
                  return '';
                })
            ) : (
              <ATMTable.Row>
                <ATMTable.Cell colspan="6">
                  {' '}
                  <ATMLoader active inline="centered" />{' '}
                </ATMTable.Cell>
              </ATMTable.Row>
            )}
          </ATMTable.Body>
        </ATMTable>
      </div>
      <ATMHeader as="h3" content="Isolation Points" />
      <ATMGrid columns={2} className={styles.infoPad}>
        <ATMGrid.Column>
          <ATMTable columns="2" className={styles.lerDetail}>
            <ATMTable.Body>
              <ATMTable.Row>
                <ATMTable.Cell width={5} verticalAlign="top">
                  Isolation Points:
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {data.isolationPoints
                    ? data.isolationPoints.split('\n').map((item, i) => {
                        return (
                          <span key={i}>
                            <span>{item}</span>
                            <br />
                          </span>
                        );
                      })
                    : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
      <ATMHeader as="h3" content="Outage Information" />
      <ATMGrid columns="2" className={styles.infoPad}>
        <ATMGrid.Column>
          <ATMTable columns="2" className={styles.lerDetail}>
            <ATMTable.Body>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Start (ISO/Crew):</ATMTable.Cell>
                <ATMTable.Cell>{data.startTmTxt ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Stop (ISO/Crew):</ATMTable.Cell>
                <ATMTable.Cell>{data.stopTmTxt ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Recall Time:</ATMTable.Cell>
                <ATMTable.Cell>
                  {data.recalTm ? `${data.recalTm} Hr` : '-'}
                </ATMTable.Cell>
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
      <ATMHeader as="h3" content="Contact Info" />
      <ATMGrid columns="2" className={styles.infoPad}>
        <ATMGrid.Column>
          <ATMTable columns="2" className={styles.lerDetail}>
            <ATMTable.Body>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Crew Name:</ATMTable.Cell>
                <ATMTable.Cell>{data.crewName ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Crew Contact #:</ATMTable.Cell>
                <ATMTable.Cell>{data.crewCellNbr ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Requestor:</ATMTable.Cell>
                <ATMTable.Cell>{data.requestorName ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Requestor #:</ATMTable.Cell>
                <ATMTable.Cell>{data.requestorCellNbr ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>Field Check By:</ATMTable.Cell>
                <ATMTable.Cell>{data.fldckBy ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
              <ATMTable.Row>
                <ATMTable.Cell width={5}>GF/CA:</ATMTable.Cell>
                <ATMTable.Cell>{data.genlFormnCtrctAdmin ?? '-'}</ATMTable.Cell>
              </ATMTable.Row>
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
      <ATMHeader as="h3" content="Switching Info" />
      <div style={{ width: '85%' }}>
        <ATMGrid columns="2">
          <ATMGrid.Column className={styles.infoPad}>
            <ATMTable columns="2" className={styles.lerDetail} textAlign="left">
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.Cell width={1}>
                    <h4>Location</h4>
                  </ATMTable.Cell>
                  <ATMTable.Cell width={1}>
                    <h4>Time</h4>
                  </ATMTable.Cell>
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {data.sortResponses &&
                  data.sortResponses
                    .sort((a, b) => (a?.sortTime as any) - (b?.sortTime as any))
                    .map((val) => {
                      return (
                        <>
                          <ATMTable.Row>
                            <ATMTable.Cell>
                              {val.substationId ?? val.tlSub ?? ''}
                            </ATMTable.Cell>
                            <ATMTable.Cell>
                              {format24hTime(val.sortTime) ?? ''}
                            </ATMTable.Cell>
                          </ATMTable.Row>
                        </>
                      );
                    })}
              </ATMTable.Body>
            </ATMTable>
          </ATMGrid.Column>
        </ATMGrid>
      </div>
    </ATMSegment>
  );
};

export default DailyReportsViewDetail;
