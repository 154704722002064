import { IsoCauseLevel } from 'src/constants';
import { isoCauseActionTypes, IIsoCauseState } from 'src/ducks/iso-cause.duck';
import { IStatus } from 'src/libraries/thunk.library';
import { IIsoCause } from 'src/models/iso-cause.model';

export const getIsoCauseStatus = (
  state: IIsoCauseState,
  action: keyof typeof isoCauseActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getIsoCauseByLevel = (data: IIsoCause[], level: IsoCauseLevel) =>
  data.filter((v) => v.level === level);
