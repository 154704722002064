import React from 'react';
import {
  ATMCheckbox,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import SpecialConcern from 'src/components/molecules/special-concern/special-concern.component';
import { LERRequestFormStep } from 'src/constants';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';

const LERRequestSystemForm: React.FC = () => {
  const { control, watch } = useATMFormContext<ILerRequestForm>();
  const outgFacId = watch('outageFacility.outgFacId');

  return (
    <>
      <SpecialConcern outgFacId={outgFacId} />
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <ATMHeader as="h3"> {Lang.TTL_OC_LER_SYS_CHANGE}</ATMHeader>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row>
          <ATMGrid.Column width={7}>
            <ATMField
              as={ATMInput}
              type="hidden"
              className="hidden"
              control={control}
              name={`${LERRequestFormStep.SYSTEM_CHANGE}.sysChngSummary.noteId`}
            />
            <ATMField
              control={control}
              label={Lang.LBL_COMMENTS}
              name={`${LERRequestFormStep.SYSTEM_CHANGE}.sysChngSummary.noteTxt`}
              as={ATMTextArea}
              placeholder="Enter"
              style={{
                minHeight: '6em',
                maxHeight: '6em',
              }}
              onChange={([_, { value }]) => value}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <ATMField
              control={control}
              name={`${LERRequestFormStep.SYSTEM_CHANGE}.sysChngInd`}
              label={Lang.LBL_OC_LER_SYSTEM_CHANGE}
              as={ATMCheckbox}
              onChange={([_, { checked }]) => checked}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
};

export default LERRequestSystemForm;
