import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMLabel,
} from 'shared-it-appmod-ui';
import { ITrainers } from 'src/models/trainers.model';
import { removeDoubleZeroEmpId } from 'src/selectors/employee.selector';
import Lang from 'src/libraries/language';
import history from 'src/history';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import { getEmployeeName } from 'src/helpers/employee.helper';
import { useTrainersContext } from 'src/contexts/trainers.context';
import TrainersStatusUpdate from '../trainers-status-update/trainers-status-update.component';
import style from '../trainers.module.scss';

export enum TrainerStatus {
  INACTIVE = 'I',
  ACTIVE = 'A',
}

const columns: IORGDataTableColumn<ITrainers>[] = [
  {
    index: 'trainer.fullName',
    title: Lang.LBL_TRAINER_NAME,
    render: (_, value) => {
      if (value.trainer.lastName) {
        return getEmployeeName(value.trainer, false);
      }
      return (
        <>
          <div>
            <ATMLabel horizontal basic>
              {Lang.LBL_UNAVAILABLE}
            </ATMLabel>
          </div>
        </>
      );
    },
  },
  {
    index: 'empId',
    title: Lang.LBL_EMPLOYEE_NUMBER,
    render: (_, value) =>
      removeDoubleZeroEmpId(value.empId ?? '').length < 5
        ? value.empId
        : removeDoubleZeroEmpId(value.empId ?? ''),
  },
  {
    index: 'trainer.email',
    title: Lang.LBL_EMPLOYEE_EMAIL,
    render: (_, value) => value.trainer.email,
  },
  {
    index: 'action',
    title: 'Action',
    width: '70px',
    sortable: false,
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <div>
        {/* <ATMDropdown size="small" icon="ellipsis horizontal">
          <ATMDropdown.Menu> */}
        <TrainersStatusUpdate
          data={value}
          empStatus={value.trainerStat as any}
        />
        {/* </ATMDropdown.Menu>
        </ATMDropdown> */}
      </div>
    ),
  },
];

type IProps = {
  data: ITrainers[];
  loading: boolean;
  status: TrainerStatus;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (
    params: IORGDataTableQueryState,
    trainingStatus: string
  ) => void;
};

const TrainersListTable: React.FC<IProps> = ({
  data,
  status = TrainerStatus.ACTIVE,
  loading,
  handleFetch,
  handleDownload,
}) => {
  const { state: dataState } = useTrainersContext();
  return (
    <div className={style.mainContainerPosition}>
      <div className={style.marginTop}>
        <ORGDataTable
          columns={columns}
          data={dataState?.list
            .filter((elem) => elem.trainer.lastName !== null)
            ?.filter((val) => val.trainerStat === status)}
          // data={data.filter((val) => val.trainerStat === status)}
          celled={false}
          counter
          sortable
          onChange={handleFetch}
          loading={loading}
          showPagination={false}
          location={history.location}
          handleLocation={history.push}
        >
          {({ state, setState }) => ({
            toolbars: {
              search: () => (
                <TableSearch
                  field="name"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_EMPLOYEE_NAME}
                />
              ),
              button: () => (
                <ATMButton
                  type="button"
                  key="download"
                  secondary
                  icon="download"
                  size="tiny"
                  disabled={
                    !data.filter((val) => val.trainerStat === status).length
                  }
                  onClick={() => {
                    handleDownload(
                      {
                        ...state,
                        page: 1,
                        limit: 0,
                      },
                      status
                    );
                  }}
                />
              ),
            },
          })}
        </ORGDataTable>
      </div>
    </div>
  );
};

export default TrainersListTable;
