import { REGEX_PHONE } from 'shared-it-appmod-ui';
import {
  AccessRole,
  AuthorizationType,
  LERRequestFormStep,
} from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { EmployeeInternalSchema } from './employee.model';

export const CrewInfoShape = {
  crewId: yup.string().nullable(),
  crewCellNbr: yup.string().nullable(),
  crewPagerNbr: yup.string().nullable(),
  crewTruckNbr: yup.string().nullable(),
  requestorId: yup.string().required(),
  authorized: yup.boolean().nullable(),
  requestorEmail: yup.string().nullable(),
  requestorContact: yup.string().nullable(),
  supvId: yup.string().nullable(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  fldckBy: yup.string().nullable(),
  requestor: EmployeeInternalSchema.clone().nullable(),
  crew: EmployeeInternalSchema.clone().nullable(),
  supervisor: EmployeeInternalSchema.clone().nullable(),
};
export const RequestorInfoShape = {
  id: yup.number().required(),
  email: yup.string().nullable(),
  phoneNum: yup.string().required(),
  contactNum: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};
export const GFCAInfoShape = {
  id: yup.number().required(),
  GFCA: yup.string().required(),
  fldckBy: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};
export const InformationShape = {
  crewInfo: yup.object(CrewInfoShape),
  requestorInfo: yup.object(RequestorInfoShape),
  gfCaInfo: yup.object(GFCAInfoShape),
};

export const CrewInfosSchema = yup.object(InformationShape);
export const CrewInfoSchema = yup.object(CrewInfoShape).transform((value) => ({
  ...value,
  requestorEmail: value.requestor?.eMailAddress,
}));

const infoOnlyTest = (value, values) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: [__, { value: allValue }],
  } = values;

  if (
    allValue[LERRequestFormStep.OUTAGE_FACILITY].outgTypId ===
      AuthorizationType.InfoOnly &&
    !value
  ) {
    return true;
  }

  return !!value;
};

export const CrewInfoSchemaForm = yup.object({
  ...CrewInfoShape,
  crewId: yup
    .string()
    .test('isRequired', Lang.MSG_ERROR_REQUIRED, infoOnlyTest),
  crewCellNbr: yup
    .string()
    .nullable()
    .matches(REGEX_PHONE, Lang.MSG_ERROR_CONTACT_NUMBER)
    .test('isRequired', Lang.MSG_ERROR_REQUIRED, infoOnlyTest),
  requestorContact: yup
    .string()
    .matches(REGEX_PHONE, Lang.MSG_ERROR_CONTACT_NUMBER)
    .nullable(),
  requestorId: yup.string().required(),
  supvId: yup
    .string()
    .nullable()
    .when('requestorId', {
      is: () => hasRole(AccessRole.MCC_OUTAGE_REQUESTOR),
      then: yup.string().required(),
    }),
  fldckBy: yup
    .string()
    .nullable()
    .test('required', Lang.MSG_ERROR_REQUIRED, (value, values) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        from: [_, { value: allValue }],
      } = values as any;

      if (
        allValue[LERRequestFormStep.OUTAGE_FACILITY] &&
        !allValue[LERRequestFormStep.OUTAGE_FACILITY].substationId &&
        !value
      ) {
        return false;
      }

      return true;
    }),
  requestor: EmployeeInternalSchema.clone().nullable(),
});
export const RequestorInfoSchema = yup.object(RequestorInfoShape);
export const GFCAInfoSchema = yup.object(GFCAInfoShape);

export const CrewInfoPayloadSchema = yup.object({
  info: CrewInfosSchema.clone(),
});

export type ICrewInfos = yup.Asserts<typeof CrewInfosSchema>;
export type ICrewInfo = yup.Asserts<typeof CrewInfoSchema>;
export type IRequestorInfo = yup.Asserts<typeof RequestorInfoSchema>;
export type IGFCAInfo = yup.Asserts<typeof GFCAInfoSchema>;
