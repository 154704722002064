import React, { useEffect } from 'react';
// import { ATMButton } from 'shared-it-appmod-ui';
import LocalPanel from 'src/components/organisms/local-panel/local-panel.component';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { AccessRole, AccessType } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { subCategoryActionTypes } from 'src/ducks/sub-category.duck';
import { getSubCategoryStatus } from 'src/selectors/sub-category.selector';
import { useSubCategoryContext } from 'src/contexts/sub-category.context';
import AuthorizedListAdd from '../../authorized-list/authorized-list-add/authorized-list-add.component';
import AuthorizedMembersList from '../../authorized-list/authorized-members-list/authorized-members-list.component';
import ContractorFirmAdd from '../../contractor-firm/contractor-firm-add/contractor-firm-add.component';
import ContractorFirmDownload from '../../contractor-firm/contractor-firm-download/contractor-firm-download.component';
import ContractorFirmList from '../../contractor-firm/contractor-firm-list/contractor-firm-list.component';
import ContractorAdd from '../../contractor/contractor-add/contractor-add.component';
import ContractorList from '../../contractor/contractor-list/contractor-list.component';
import ExpiringTrainingsList from '../../expiring-trainings/expiring-trainings-list/expiring-trainings-list.component';
import TrainersList from '../../trainers/trainers-list/trainers-list.component';
import AuthSettings from '../../auth-settings/auth-settings.component';

const AuthList: React.FC = () => {
  const { state: subCategoryState, actions: subCategoryActions } =
    useSubCategoryContext();
  const subCategoryStatus = getSubCategoryStatus(
    subCategoryState,
    subCategoryActionTypes.SUB_CATEGORY_LIST_READ
  ).fetching;
  useEffect(() => {
    subCategoryActions?.listGET();
  }, [subCategoryActions]);

  const { searchParams: params } = useLocationParams(['id']);

  const panes = getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN)
    ? [
        {
          title: Lang.TTL_AUTHORIZED_MEMBERS,
          render: <AuthorizedMembersList />,
          actions: !params.toString().includes('id=')
            ? [
                <AuthorizedListAdd
                  fromList
                  key="add"
                  subCategoryList={subCategoryState.list}
                  subCategoryStatus={subCategoryStatus}
                />,
              ]
            : undefined,
          permissions: [AccessType.AUTH_LIST],
        },
        {
          title: Lang.TTL_EXPIRING_TRAININGS,
          render: <ExpiringTrainingsList />,
          permissions: [AccessType.AUTH_LIST],
        },
        {
          title: Lang.TTL_FIRMS,
          render: <ContractorFirmList />,
          actions: [
            <>
              <ContractorFirmDownload />
              <ContractorFirmAdd key="add" />
            </>,
          ],
          permissions: [AccessType.AUTH_LIST],
        },
        {
          title: Lang.TTL_TRAINERS,
          render: <TrainersList />,
          permissions: [AccessType.AUTH_LIST],
        },
        {
          title: Lang.TTL_CONTRACTORS,
          render: <ContractorList />,
          actions: [<ContractorAdd key="add" />],
          permissions: [AccessType.AUTH_LIST],
        },
        {
          title: Lang.TTL_AUTH_SETTINGS,
          render: <AuthSettings />,
          permissions: [AccessType.AUTH_LIST],
        },
      ]
    : [
        {
          title: Lang.TTL_AUTHORIZED_MEMBERS,
          render: <AuthorizedMembersList />,
          permissions: [AccessType.AUTH_LIST],
        },
      ];
  return <LocalPanel name="tab" panes={panes} />;
};

export default AuthList;
