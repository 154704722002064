import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { commonClient, httpClient } from 'src/libraries/http.library';
import {
  IJobCodeRuleForm,
  JobCodeRuleDeletePayloadSchema,
  JobCodeRuleListPayloadSchema,
  // JobCodeRuleDeletePayloadSchema,
  JobCodeRulePayloadSchema,
  JobTitlesSearchPayloadSchema,
} from 'src/models/job-code-rule.model';
// import { jobCodeRuleMock } from 'src/models/mocks/job-code-rule.mock';
// import { Asserts } from 'yup';

const client = httpClient();
const common = commonClient();
const endpoint = 'job-key-rule';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, JobCodeRulePayloadSchema);
  },

  // listGET: async () => {
  // return client.get(`/${endpoint}`, {}, JobCodeRuleListPayloadSchema);
  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, JobCodeRuleListPayloadSchema);
  },

  createPOST: async (data: IJobCodeRuleForm) => {
    return client.post(`/${endpoint}`, data, JobCodeRulePayloadSchema);
  },

  updatePUT: async (id: string, data: IJobCodeRuleForm) => {
    return client.put(`/${endpoint}/${id}`, data, JobCodeRulePayloadSchema);
  },

  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, JobCodeRuleDeletePayloadSchema);
    // return new Promise((resolve) => {
    //   resolve({ success: true, id });
    // });
  },

  searchGET: async (jobTitle: string, jobStatus: string) => {
    const qParams = {
      jobTitle,
      jobStatus,
    };
    return common.get('jobTitles', qParams, JobTitlesSearchPayloadSchema);
  },
};

export default services;
