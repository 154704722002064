import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Access, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { LERRequestAction, LERRequestStatus } from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';

type IProp = {
  data: any;
  trigger: React.ReactNode;
  handleSuccess?: () => Promise<void> | void;
};

const LerRequestSchedule: React.FC<IProp> = ({
  data,
  trigger,
  handleSuccess,
}) => {
  const { state, actions } = useLerRequestContext();
  const { state: facilityState } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE
  );

  const loadingFacilityGroups = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ
  );

  const handleSubmit = useCallback(async () => {
    if (facilityState.lerUserGrp && facilityState.lerUserGrp.length !== 0) {
      actions.setForApproval(true);
      actions.setIsFromReject(false);
      actions.setIsFromUpdate(false);
      actions.setIsFromPendingCaiso(false);

      // if (handleSuccess) {
      //   await handleSuccess();
      // }

      setIsOpen(false);
    } else {
      const result = await actions.statusPUT(
        data.requestId,
        LERRequestAction.Schedule
      );
      if (result.payload) {
        if (result.payload?.outageEmail) {
          if (result.payload?.outageEmail?.successOutageEmail?.length) {
            result.payload?.outageEmail?.successOutageEmail.map(
              (emailOutage) => {
                ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
              }
            );
          }
          if (result.payload?.outageEmail?.failedOutageEmail?.length) {
            result.payload?.outageEmail?.failedOutageEmail.map(
              (emailOutage) => {
                ToastError(emailOutage?.message ?? 'Failed to send email');
              }
            );
          }
        }
      }
      if (!result.error) {
        ToastSuccess(Lang.MSG_LER_REQUEST_SCHEDULE_SUCCESS);

        if (result.payload?.requestStat === LERRequestStatus.Scheduled) {
          actions.setForApproval(true);
          actions.setIsFromReject(false);
          actions.setIsFromUpdate(false);
          actions.setIsFromPendingCaiso(false);
        }

        if (handleSuccess) {
          await handleSuccess();
        }

        setIsOpen(false);
      }
    }
  }, [actions, data, handleSuccess, facilityState]);

  return (
    <Access
      type={AccessType.OUTAGE_REQUEST_SCHEDULE}
      when={data.requestStat === LERRequestStatus.Approved}
    >
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={trigger}
      >
        <ATMModal.Header>{Lang.TTL_OUTAGE_REQUEST_SCHEDULE}</ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_LER_REQUEST_CONFIRM_SCHEDULE}</p>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            type="button"
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
          />
          <ATMButton
            type="button"
            primary
            content={Lang.LBL_SCHEDULE}
            onClick={handleSubmit}
            disabled={status.fetching || loadingFacilityGroups.fetching}
            loading={status.fetching || loadingFacilityGroups.fetching}
          />
        </ATMModal.Actions>
      </ATMModal>
    </Access>
  );
};

export default LerRequestSchedule;
