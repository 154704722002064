import React from 'react';
import { ATMField, ATMForm, ATMGrid } from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import { ITrainersForm, TrainersFormSchema } from 'src/models/trainers.model';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ITrainersForm>;
  handleSubmit: (data: ITrainersForm) => void;
  handleEnable: (value: boolean) => void;
};

const TrainersForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => (
  <ATMForm
    ref={formRef}
    onSubmit={handleSubmit}
    mode="onChange"
    defaultValues={TrainersFormSchema.cast(defaultValues)}
    validationSchema={TrainersFormSchema}
  >
    {({ control, formState: { errors, isDirty } }) => {
      handleEnable(isDirty);

      return (
        <ATMGrid>
          <ATMGrid.Row columns="equal">
            <ATMGrid.Column>
              <div className="field" style={{ marginBottom: 20 }}>
                <ATMField
                  as={DropdownEmployee}
                  name="empId"
                  label="Employee/Contractor"
                  placeholder="Enter"
                  control={control}
                  error={errors.empId}
                  clearable
                  style={{ display: 'block', marginRight: '1em' }}
                  onChange={([_, { value }]) => value}
                />
              </div>
            </ATMGrid.Column>
            <ATMGrid.Column />
          </ATMGrid.Row>
        </ATMGrid>
      );
    }}
  </ATMForm>
);

export default TrainersForm;
