import React, { useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDropdown,
  ATMTextArea,
  ATMHeader,
  ATMFormProvider,
} from 'shared-it-appmod-ui';
import {
  IFacilityForm,
  FacilityFormValidationSchema,
  IFacility,
  IUserGrp,
} from 'src/models/facility.model';
import Lang from 'src/libraries/language';
import { useFacilityContext } from 'src/contexts/facility.context';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import NotificationTableCaiso from '../facility-notifications-tables/facility-notif-table-caiso.component';
import NotificationTableEMS from '../facility-notifications-tables/facility-notif-table-ems.component';
import NotificationTableSwitching from '../facility-notifications-tables/facility-notif-table-switching.component';
import NotificationTableApprovals from '../facility-notifications-tables/facility-notif-table-approvals.component';
import NotificationTableSwitchTrs from '../facility-notifications-tables/facility-notif-table-switchTR.component';
import style from './facility-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IFacility>;
  handleSubmit: (data: IFacilityForm) => void;
  handleEnable: (value: boolean) => void;
  isInUse?: boolean;
};

const FacilityForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  isInUse,
}) => {
  const { state } = useFacilityContext();
  const [userGroups, setUserGroups] = useState<IUserGrp[]>(
    defaultValues?.userGrps ?? []
  );

  return (
    <ATMForm
      ref={formRef}
      onSubmit={(formData) => {
        handleSubmit({
          ...formData,
          userGrps: userGroups,
        });
      }}
      mode="onChange"
      defaultValues={FacilityFormValidationSchema.cast({
        ...defaultValues,
        substationIds: (defaultValues?.substations || [])
          .map((v) => v.substationId)
          .filter(Boolean),
        blackstartIds: (defaultValues?.blackstarts || [])
          .map((v) => v.blackstartId)
          .filter(Boolean),
        blackstartId:
          defaultValues?.blackstarts && defaultValues?.blackstarts.length > 0
            ? defaultValues?.blackstarts[0].blackstartId
            : null,
        spclOpsConcern: defaultValues?.spclOpsConcern ?? '',
      })}
      validationSchema={FacilityFormValidationSchema}
    >
      {(formProps) => {
        const {
          control,
          formState: { errors, isDirty },
          setValue,
        } = formProps;
        handleEnable(isDirty);

        return (
          <ATMFormProvider {...formProps}>
            <ATMGrid columns={4}>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMField
                    name="blackstartIds"
                    control={control}
                    as={ATMInput}
                    type="hidden"
                    className="hidden"
                  />
                  <ATMField
                    name="inUse"
                    control={control}
                    as={ATMInput}
                    type="hidden"
                    className="hidden"
                  />

                  {defaultValues?.outgFacNm ? (
                    <>
                      <FieldReadOnly label={Lang.LBL_FACILITY_NAME}>
                        {defaultValues.outgFacNm}
                      </FieldReadOnly>
                      {/* Hidden Form */}
                      <ATMField
                        name="outgFacNm"
                        control={control}
                        defaultValue={defaultValues?.outgFacNm}
                        as={ATMInput}
                        type="hidden"
                        className="hidden"
                      />
                    </>
                  ) : (
                    <ATMField
                      label={Lang.LBL_FACILITY_NAME}
                      as={ATMInput}
                      name="outgFacNm"
                      maxLength={100}
                      control={control}
                      error={errors.outgFacNm}
                      required
                      placeholder="Enter"
                      onChange={([_, { value }]) => {
                        return value;
                      }}
                    />
                  )}
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    required
                    as={ATMDropdown}
                    control={control}
                    name="stat"
                    label={Lang.LBL_FACILITY_STATUS}
                    error={errors.stat}
                    defaultValue={defaultValues ? defaultValues.stat : 'Active'}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder="Select"
                    options={[
                      ...Array.from(new Set(state.filters.stats)).map(
                        (val, index) => {
                          return {
                            key: index,
                            value: val,
                            text: Lang.STATUS[val],
                          };
                        }
                      ),
                    ]}
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    required
                    as={ATMDropdown}
                    control={control}
                    name="voltId"
                    label={Lang.LBL_VOLTAGE}
                    error={errors.voltId}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder="Select"
                    options={[
                      ...Array.from(new Set(state.filters.volts)).map(
                        (val, index) => {
                          return {
                            key: index,
                            value: val.voltId,
                            text: val.name,
                          };
                        }
                      ),
                    ]}
                    onChange={([_, { value }]) => (!value ? undefined : value)}
                    search
                    disabled={isInUse}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    required
                    as={ATMDropdown}
                    control={control}
                    name="besInd"
                    label={Lang.LBL_BES_FACILITY}
                    error={errors.besInd}
                    defaultValue="N"
                    selection
                    clearable
                    placeholder="Select"
                    selectOnBlur={false}
                    options={[
                      { val: 'Y', text: 'Yes' },
                      { val: 'N', text: 'No' },
                    ].map((value, index) => ({
                      key: index,
                      value: value.val,
                      text: value.text,
                    }))}
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMField
                    required
                    as={ATMDropdown}
                    control={control}
                    name="offSysInd"
                    label={Lang.LBL_OFF_SYSTEM}
                    error={errors.offSysInd}
                    placeholder="Select"
                    defaultValue="N"
                    selection
                    clearable
                    selectOnBlur={false}
                    options={[
                      { val: 'Y', text: 'Yes' },
                      { val: 'N', text: 'No' },
                    ].map((value, index) => ({
                      key: index,
                      value: value.val,
                      text: value.text,
                    }))}
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    required
                    as={ATMDropdown}
                    control={control}
                    name="facTypId"
                    label={Lang.LBL_FACILITY_TYPE}
                    error={errors.facTypId}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder="Select"
                    options={[
                      ...Array.from(new Set(state.filters.facTyps)).map(
                        (val) => {
                          return {
                            value: val.facTypId,
                            text: val.name,
                          };
                        }
                      ),
                    ]}
                    onChange={([_, { value }]) => (!value ? undefined : value)}
                    search
                    disabled={isInUse}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name="substationIds"
                    label={Lang.TTL_SUBSTATIONS}
                    error={errors.substationIds}
                    selection
                    clearable
                    fluid
                    multiple
                    placeholder="Select"
                    selectOnBlur={false}
                    options={
                      state.filters.substations &&
                      state.filters.substations.map((val) => {
                        return {
                          value: val.substationId,
                          text: val.name,
                        };
                      })
                    }
                    onChange={([_, { value }]) => {
                      return value;
                    }}
                    search
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMField
                    label={Lang.LBL_SPECIAL_CONCERNS}
                    as={ATMTextArea}
                    name="spclOpsConcern"
                    maxLength={300}
                    control={control}
                    error={errors.spclOpsConcern}
                    placeholder="Enter"
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={8}>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name="blackstartId"
                    label={Lang.LBL_BLACK_START}
                    error={errors.blackstartId}
                    selection
                    clearable
                    fluid
                    placeholder="Select"
                    selectOnBlur={false}
                    options={state.filters.blackstarts?.map((val) => {
                      return {
                        value: val.blackstartId,
                        text: val.blackstartValue,
                      };
                    })}
                    onChange={([_, { value }]) => {
                      let newValue = value;
                      if (value) {
                        setValue('blackstartIds', [value]);
                      } else {
                        newValue = null;
                        setValue('blackstartId', null);
                        setValue('blackstartIds', []);
                      }

                      return newValue;
                    }}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
            <ATMHeader as="h3">{Lang.LBL_SWITCH_TR}</ATMHeader>
            <NotificationTableSwitchTrs />
            <ATMHeader as="h3">{Lang.TTL_CAISO_TR_IDS}</ATMHeader>
            <NotificationTableCaiso />
            <ATMHeader as="h3">{Lang.TTL_EMS_IDS}</ATMHeader>
            <div className={style.longTableOverflow}>
              <NotificationTableEMS />
            </div>
            <ATMHeader as="h3">
              {Lang.TTL_NOTIFICATION_BLOCK_SWITCHING_INFORMATION}
            </ATMHeader>
            <div className={style.longTable}>
              <NotificationTableSwitching />
            </div>
            <ATMHeader as="h3">
              {Lang.TTL_NOTIFICATION_BLOCK_NOTIFICATION_APPROVALS}
            </ATMHeader>
            <NotificationTableApprovals setUserGroups={setUserGroups} />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default FacilityForm;
