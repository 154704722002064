/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  // ATMMessage,
  // ATMIcon,
  IORGDataTableQueryState,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { AuthType } from 'src/constants';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import history from 'src/history';
import {
  IAuthorizedListSearch,
  AuthorizedListSearchSchema,
} from 'src/models/authorized-list.model';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import style from './authorized-list-search.module.scss';

type IProps = {
  loading: boolean;
  filterValues: React.MutableRefObject<Record<any, any>>;
  formRef?: React.RefObject<HTMLFormElement>;
  data?: IAuthorizedListSearch;
  handleSubmit: (data: Partial<IORGDataTableQueryState>) => void;
  handleClick: () => void;
};

export const authorizedFields = [
  'empId',
  'supervisorNumber',
  'ccCd',
  'contracShortNm',
  'authTypId',
];

type IFormContent = {
  filterValues: React.MutableRefObject<Record<any, any>>;
  loading: boolean;
  handleClick: () => void;
};

const FormContent: React.FC<IFormContent> = ({
  filterValues,
  loading,
  handleClick,
}) => {
  const {
    control,
    formState: { errors },
    reset,
    clearErrors,
    watch,
    getValues,
  } = useATMFormContext<IAuthorizedListSearch>();
  const { actions } = useAuthorizedListContext();
  const { state: firmState, actions: firmActions } = useContractorFirmContext();

  const firmLoading = getContractorFirmStatus(
    firmState,
    contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ
  ).fetching;

  useEffect(() => {
    firmActions.listGET({ limit: 0, page: 1 });
  }, [firmActions]);

  const fields = watch(
    authorizedFields.slice(0, -1) as (keyof IAuthorizedListSearch)[]
  );

  const handleReset = useCallback(() => {
    filterValues.current = {
      page: 1,
    };
    history.push('/auth-list?tab=0');
  }, [filterValues]);

  const isDirty = Object.values(watch()).filter(Boolean).length > 0;

  return (
    <ATMGrid className={style.marginBotom}>
      <ATMGrid.Row className={style.rowBottom}>
        <ATMGrid.Column width={4}>
          <ATMField
            as={ATMInput}
            name="name"
            label="Employee Name/ID"
            placeholder="Enter"
            control={control}
            error={errors.name}
            clearable
            disabled={!!watch('authTypId')}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4} style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMDropdown}
            name="contracShortNm"
            label="Firm Name"
            placeholder="Select"
            selection
            disabled={!!watch('authTypId')}
            control={control}
            error={errors.contracShortNm}
            style={{ display: 'block', marginRight: '1em' }}
            loading={firmLoading}
            options={firmState.list
              .filter((elem) => {
                return elem.fullName !== null;
              })
              .map((item) => ({
                key: item.empId,
                value: item.contracShortNm,
                text: item.contracShortNm,
              }))}
            onChange={([_, { value }]) => value}
            selectOnBlur={false}
            clearable
            search
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4} style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMDropdown}
            name="authTypId"
            label="Training Type"
            placeholder="Select"
            selection
            disabled={fields.filter(Boolean).length > 0}
            control={control}
            error={errors.authTypId}
            style={{ display: 'block', marginRight: '1em' }}
            options={Array.from(Object.entries(AuthType)).map((item, i) => ({
              key: i,
              value: item[1],
              text: item[1],
            }))}
            onChange={([_, { value }]) => value}
            selectOnBlur={false}
            search
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4} style={{ paddingLeft: '0' }}>
          <ATMField
            as={DropdownEmployee}
            name="supervisorNumber"
            label="Manager Name"
            placeholder="Enter"
            control={control}
            error={errors.supervisorNumber}
            clearable
            search
            selectedValue={getValues('supervisorNumber')}
            style={{ display: 'block', marginRight: '1em' }}
            onChange={([_, { value }]) => value}
            selectOnBlur={false}
            disabled={!!watch('authTypId')}
            searchQuery={undefined}
          />
          {/* <ATMMessage size="tiny" info>
            <p>
              <ATMIcon circular inverted color="blue" name="info" /> For MCTC
              use
            </p>
          </ATMMessage> */}
        </ATMGrid.Column>
        {/* <ATMGrid.Column width={3} style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMInput}
            name="ccCd"
            label="Cost Center"
            placeholder="Enter"
            control={control}
            error={errors.ccCd}
            disabled={!!watch('authTypId')}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column> */}
      </ATMGrid.Row>
      <ATMGrid.Row className={style.buttonsTop}>
        <ATMGrid.Column>
          <ATMButton
            primary
            loading={loading}
            content="Search"
            onClick={handleClick}
            disabled={!isDirty || loading}
          />
          <ATMButton
            secondary
            content="Clear Fields"
            type="button"
            disabled={!isDirty || loading}
            style={{ marginLeft: '1em' }}
            onClick={() => {
              handleReset();
              reset({
                contracShortNm: '',
                empId: '',
                name: '',
                supervisorNumber: '',
                ccCd: '',
                authTypId: '',
              });
              setImmediate(() => {
                clearErrors();
                actions.clearList();
              });
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const AuthorizedListSearch: React.FC<IProps> = ({
  loading,
  formRef,
  filterValues,
  handleSubmit,
  handleClick,
}) => {
  return (
    <div className={style.filterContainer}>
      <ATMForm
        ref={formRef}
        onSubmit={(formData) => {
          const filters = Object.entries(formData)
            .map(([name, value]) => ({
              name,
              value,
            }))
            .filter(({ value }) => value !== undefined && value !== '');

          handleSubmit({ page: 1, limit: 50, filters });
        }}
        defaultValues={(filterValues?.current?.filters || []).reduce(
          (items, item) => ({ ...items, [item.name]: item.value }),
          {}
        )}
        mode="onChange"
        validationSchema={AuthorizedListSearchSchema}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <FormContent
                filterValues={filterValues}
                handleClick={handleClick}
                loading={loading}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default AuthorizedListSearch;
