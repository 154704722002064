import React, { useCallback, useRef, useState } from 'react';
import { useJobCodeRuleContext } from 'src/contexts/job-code-rule.context';
import { jobCodeRuleActionTypes } from 'src/ducks/job-code-rule.duck';
import { IJobCodeRuleForm } from 'src/models/job-code-rule.model';
import { getJobCodeRuleStatus } from 'src/selectors/job-code-rule.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import history from 'src/history';
import JobCodeRuleAddView from './job-code-rule-add.view';

const JobCodeRuleAdd: React.FC = () => {
  const { state, actions } = useJobCodeRuleContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getJobCodeRuleStatus(
    state,
    jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IJobCodeRuleForm) => {
      if (
        !formData.auth &&
        !formData.subEntry &&
        !formData.key30 &&
        !formData.key40
      ) {
        ToastError(
          'Please choose a key rules or a training rules before saving!'
        ); // Temp message
      } else {
        const response = await actions.createPOST({
          ...formData,
          // updatedBy: 'John Doe',
        });

        if (!response.error) {
          setIsAddOpen(false);

          ToastSuccess(
            `Successfully added Job code ${response.payload?.jobCd} and its Rules`
          );
          history.push('/keys?page=1&limit=50&tab=3');
        } else {
          ToastError(response.error.message);
        }
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <JobCodeRuleAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default JobCodeRuleAdd;
