import React, { useEffect } from 'react';
import { ATMSegment, ATMHeader, MOLNoData } from 'shared-it-appmod-ui';
import { SettingName } from 'src/constants';
import { useSettingContext } from 'src/contexts/setting.context';
import { settingActionTypes } from 'src/ducks/setting.duck';
import { getSettingStatus } from 'src/selectors/setting.selector';
import Lang from 'src/libraries/language';
import style from './error.module.scss';

const Error403: React.FC = () => {
  const { state: settingState, actions: settingActions } = useSettingContext();

  const loading = getSettingStatus(
    settingState,
    settingActionTypes.SETTING_LIST_READ
  ).fetching;

  useEffect(() => {
    const fetchData = async () => {
      await settingActions?.listGET();
    };

    fetchData();
  }, [settingActions]);
  if (loading) {
    return null;
  }
  return (
    <div className={style.wrapper}>
      <ATMSegment className={style.segment}>
        <ATMHeader as="h1" content="Welcome to Mission Control Center" />
        <div style={{ width: '80%', margin: '100px auto' }}>
          <MOLNoData icon="info" header={Lang.NTE_PAGE_ACCESS_DENIED_HEADER} />
          <p style={{ textAlign: 'center', fontSize: '12px' }}>
            {settingState.list
              .find(
                (v) =>
                  v.name === SettingName.TEXT_FOR_UNAUTHORIZED_USERS_FOR_MCC_APP
              )
              ?.value?.split('.')
              .filter(Boolean)
              .map((i) => (
                <>
                  {i.trim()}
                  <br />
                </>
              ))}
          </p>
        </div>
        <div className={style.version}>
          <ATMHeader as="h3" size="small" content="v10.29" />
          <ATMHeader
            as="h3"
            size="small"
            content="MCC is managed by Electric Grid Operations."
          />
          <ATMHeader
            as="p"
            size="small"
            content="This internal web site, including all content posted hereon,belongs to SDG&E, and is reserved solely for use by its employees and designated representatives. Any person or entity accessing this site without permission or any use of the posted content without consent, is strictly prohibited. SDG&E will take appropriate actions to identify or prevent any improper use."
          />
        </div>
      </ATMSegment>
    </div>
  );
};

export default Error403;
