import React, { useCallback, useState, useEffect } from 'react';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import { useLocation } from 'react-router-dom';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { IAuthorizedExpiredListSchema } from 'src/models/authorized-list.model';
import { AuthListType } from 'src/constants';
import ExpiringTrainingsListView from './expiring-trainings-list.view';

const ExpiringTrainingsList: React.FC = () => {
  const { search } = useLocation();
  const { state, actions } = useAuthorizedListContext();
  const [selectedTrainingsTab, setSelectedTrainingTab] = useState(0);
  const [authorizationType, setAuthorizationType] = useState('');
  const [data, setData] = useState<IAuthorizedExpiredListSchema[]>([]);
  const [selectedDateSubsEntry, setSelectedDateSubsEntry] = useState<
    Date | Date[] | null | undefined
  >();
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_EXPIRED_TRAININGS_READ
  );

  useEffect(() => {
    setData([]);
    setSelectedDateSubsEntry(undefined);
    setSelectedTrainingTab(
      search.indexOf('&') === -1
        ? 0
        : Number(search.slice(search.indexOf('&'), search.length).slice(-1))
    );
    setAuthorizationType(
      search.indexOf('&') === -1 ||
        Number(search.slice(search.indexOf('&'), search.length).slice(-1)) === 0
        ? AuthListType.Training
        : AuthListType.SubEntry
    );
    if (!search.includes('getPreviousSearch')) {
      sessionStorage.removeItem('authDate');
      sessionStorage.removeItem('subDate');
    }
  }, [
    search,
    selectedTrainingsTab,
    setData,
    setSelectedDateSubsEntry,
    setSelectedTrainingTab,
    setAuthorizationType,
  ]);

  const handleFetch = useCallback(
    async (authType, date) => {
      const response = await actions.listExpiringTrainingGET(authType, date);
      if (
        response.payload &&
        response.payload.rows &&
        response.payload.rows?.length > 0
      ) {
        setData(response.payload.rows ?? []);
      } else {
        setData([]);
      }
    },
    [actions, setData]
  );

  const handleDownload = useCallback(
    async (authType, date) => {
      const result = await actions.listExpiringTrainingGET(authType, date);
      if (result.payload?.rows) {
        const list = result.payload.rows;

        const items = list.map((value) => [
          checkValue(value.employee?.fullName),
          checkValue(formatDate(value.trainingDate)),
          checkValue(value.contracShortNm),
          checkValue(value.empId),
          checkValue(
            value.employee?.employeeStat?.toUpperCase() === 'A'
              ? 'Active'
              : 'Inactive'
          ),
          checkValue(value.employee?.email),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_FULLNAME,
            Lang.LBL_TRAINING_DATE,
            Lang.LBL_FIRM_NAME,
            Lang.LBL_EMPLOYEE_ID,
            Lang.LBL_EMPLOYEE_STATUS,
            Lang.LBL_EMAIL,
          ])
        );
        let year;
        if (sessionStorage.getItem('authDate')) {
          year = sessionStorage.getItem('authDate');
        }
        if (sessionStorage.getItem('subDate')) {
          year = Moment(
            new Date(String(sessionStorage.getItem('subDate')))
          ).format('YYYY');
        }
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `Expiring_Trainings_in_${year}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  useEffect(() => {
    if (search.includes('getPreviousSearch=true')) {
      if (search.includes('requestStatus=0')) {
        sessionStorage.removeItem('subDate');
        if (sessionStorage.getItem('authDate')) {
          handleFetch(
            AuthListType.Training,
            new Date(Number(sessionStorage.getItem('authDate')), 11, 31)
          );
        }
      }
      if (search.includes('requestStatus=1')) {
        sessionStorage.removeItem('authDate');
        if (sessionStorage.getItem('subDate')) {
          handleFetch(
            AuthListType.SubEntry,
            new Date(String(sessionStorage.getItem('subDate')))
          );
        }
      }
    } else {
      sessionStorage.removeItem('authDate');
      sessionStorage.removeItem('subDate');
      setData([]);
    }
  }, [search, setData]);

  return (
    <ExpiringTrainingsListView
      data={data}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
      authorizationType={authorizationType}
      selectedTrainingsTab={selectedTrainingsTab}
      selectedDateSubsEntry={selectedDateSubsEntry}
      setSelectedDateSubsEntry={setSelectedDateSubsEntry}
    />
  );
};

export default ExpiringTrainingsList;
