import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/tna-report.service';
import { ITnaReport } from 'src/models/tna-report.model';

export const tnaReportActionTypes = {
  TNA_REPORT_DATA_READ: 'TNA_REPORT_DATA_READ',
  TNA_REPORT_LIST_READ: 'TNA_REPORT_LIST_READ',
  TNA_REPORT_DATA_SET: 'TNA_REPORT_DATA_SET',
  TNA_REPORT_DATA_CLEAR: 'TNA_REPORT_DATA_CLEAR',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  listGET: {
    type: tnaReportActionTypes.TNA_REPORT_LIST_READ,
    service: services.listGET,
  },

  // This is a sync action
  setData: (tnaReport: ITnaReport) => ({
    type: tnaReportActionTypes.TNA_REPORT_DATA_SET,
    payload: {
      tnaReport,
    },
  }),
  clearList: () => ({
    type: tnaReportActionTypes.TNA_REPORT_DATA_CLEAR,
  }),
};

export type ITnaReportAsync = typeof duckActions;

export interface ITnaReportState
  extends ICommonState<typeof tnaReportActionTypes> {
  data?: ITnaReport;
  list: ITnaReport[];
  total: number;
}

export const defaultState: ITnaReportState = {
  status: {},
  list: [],
  total: 0,
};

const TnaReportReducer = (
  state: ITnaReportState,
  action: IReducerAction<ITnaReportAsync>
): ITnaReportState => {
  switch (action.type) {
    case tnaReportActionTypes.TNA_REPORT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case tnaReportActionTypes.TNA_REPORT_DATA_CLEAR: {
      return {
        ...state,
        list: [],
        total: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default TnaReportReducer;
