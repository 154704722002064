import yup from 'src/libraries/validator.library';

export const RealTimeLogNotesShape = {
  noteId: yup.number().required(),
  logId: yup.number().required(),
  changeLogNote: yup.string().required(),
  updatedBy: yup.string().required(),
  updatedAt: yup.date().required(),
  emp: yup.object({
    lastName: yup.string(),
    firstName: yup.string(),
    empId: yup.string(),
  }),
};

export const RealTimeLogNotesPayloadShape = {
  logId: yup.number().required(),
  noteId: yup.number().required(),
  changeLogNote: yup.string().required(),
};

// export const RealTimeLogNotesEntryFormSchema = yup.object(
//   RealTimeLogNotesShape
// );
// export const RealTimeLogNotesPayloadEntryFormSchema = yup.object(
//   RealTimeLogNotesPayloadShape
// );
// export const RealTimeLogNotesPayloadSchema = yup.object(RealTimeLogNotesShape);
// export const RealTimeLogNotesSchema = yup.object(RealTimeLogNotesShape);
// export type IRealTimeLogNotes = yup.Asserts<typeof RealTimeLogNotesSchema>;

// export type IRealTimeLogNotesEntryForm = yup.Asserts<
//   typeof RealTimeLogNotesEntryFormSchema
// >;

// export type IRealTimeLogNotesPayloadEntryForm = yup.Asserts<
//   typeof RealTimeLogNotesPayloadEntryFormSchema
// >;
