import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import IssuedKeyReducer, {
  IIssuedKeyState,
  defaultState,
  duckActions,
} from 'src/ducks/issued-key.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    IssuedKeyReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IIssuedKeyContext = ReturnType<typeof useReducer>;

const IssuedKeyContext = createContext<Partial<IIssuedKeyContext>>({
  state: defaultState,
}) as React.Context<IIssuedKeyContext>;

type IProps = {
  state?: Partial<IIssuedKeyState>;
};

const IssuedKeyProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <IssuedKeyContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </IssuedKeyContext.Provider>
  );
};

const useIssuedKeyContext = () => useContext(IssuedKeyContext);

export type IUseIssuedKeyContext = ReturnType<typeof useIssuedKeyContext>;

export { IssuedKeyContext, IssuedKeyProvider, useIssuedKeyContext };
