import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import UserGroupReducer, {
  IUserGroupState,
  defaultState,
  duckActions,
} from 'src/ducks/user-group.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    UserGroupReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IUserGroupContext = ReturnType<typeof useReducer>;

const UserGroupContext = createContext<Partial<IUserGroupContext>>({
  state: defaultState,
}) as React.Context<IUserGroupContext>;

type IProps = {
  state?: Partial<IUserGroupState>;
};

const UserGroupProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <UserGroupContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </UserGroupContext.Provider>
  );
};

const useUserGroupContext = () => useContext(UserGroupContext);

export type IUseUserGroupContext = ReturnType<typeof useUserGroupContext>;

export { UserGroupContext, UserGroupProvider, useUserGroupContext };
