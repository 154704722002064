import React, { useCallback, useState } from 'react';
import { useUserGroupContext } from 'src/contexts/user-group.context';
import { IUserGroup } from 'src/models/user-group.model';
import { getUserGroupStatus } from 'src/selectors/user-group.selector';
import { userGroupActionTypes } from 'src/ducks/user-group.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import UserGroupDeleteView from './user-group-delete.view';

type IProp = {
  data: IUserGroup;
};

const UserGroupDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useUserGroupContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getUserGroupStatus(
    state,
    userGroupActionTypes.USER_GROUP_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.userGrpId) {
      const response = await actions.dataDELETE(data.userGrpId);

      if (!response.error) {
        ToastSuccess(
          Lang.formatString(Lang.MSG_USER_GROUP_DELETE, data.userGrpNm)
        );
      } else {
        ToastError(response.error.message);
      }
      setIsOpen(false);
    }
  }, [actions, data]);

  return (
    <UserGroupDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default UserGroupDelete;
