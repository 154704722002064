import React, { useCallback, useRef, useState } from 'react';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { IAuthorizedListSuspensionForm } from 'src/models/authorized-list.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { addDoubleZeroEmpId } from 'src/selectors/employee.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useSuspensionListContext } from 'src/contexts/suspension-list.context';
import AuthorizedListSuspensionLiftView from './authorized-list-suspension-lift.view';

type IProp = {
  data?: IAuthorizedListSuspensionForm;
  authId: number;
  empName?: string | null;
};

const AuthorizedListSuspensionLift: React.FC<IProp> = ({
  data,
  authId,
  empName,
}) => {
  const { params: currentTab } = useLocationParams(['id']);
  const { actions } = useAuthorizedListContext();
  const { actions: suspensionListActions } = useSuspensionListContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IAuthorizedListSuspensionForm) => {
      setLoading(true);
      const responseLiftSuspension = await suspensionListActions.dataDELETE(
        authId
      );
      const responseSuspensionHistory = await actions.suspensionHistoryListPOST(
        { ...formData }
      );
      if (!responseLiftSuspension.error && !responseSuspensionHistory.error) {
        const param = {
          filters: [
            {
              name: 'empId',
              value: addDoubleZeroEmpId(data?.empId ?? ''),
            },
          ],
        };
        await actions.suspensionHistoryListGET(param);
        setIsAddOpen(false);

        ToastSuccess('Suspension has been successfully lifted');
        // history.push(`/auth-list?id=${currentTab.id}`);
        await actions.empDataGET(currentTab.id);
      } else {
        ToastError(responseLiftSuspension.error.message);
      }
      setLoading(false);
    },
    [data, actions, setIsAddOpen, setLoading]
  );

  return (
    <AuthorizedListSuspensionLiftView
      formRef={formRef}
      loading={loading}
      defaultValues={data}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
      authId={authId}
      empName={empName}
    />
  );
};

export default AuthorizedListSuspensionLift;
