import {
  substationActionTypes,
  ISubstationState,
} from 'src/ducks/substation.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getSubstationStatus = (
  state: ISubstationState,
  action: keyof typeof substationActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
