export enum TroubleTicketStatus {
  OPEN = 'Open',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
}

export enum TroubleTicketPriority {
  EMERGENCY = 'Emergency',
  HIGH = 'High',
  NORMAL = 'Normal',
}

export enum TroubleTicketRequestType {
  TROUBLE = 'Trouble',
  SCHEDULED = 'Scheduled',
}

export enum TroubleTicketTab {
  Trouble,
  Scheduled,
  All,
}

export enum TroubleTicketViewType {
  Trouble = 'Trouble',
  Scheduled = 'Scheduled',
  All = 'All',
}

export const troubleTicketStatus = TroubleTicketStatus;
export const troubleTicketPriority = TroubleTicketPriority;
export const troubleTicketTab = TroubleTicketTab;
export const troubleTicketType = TroubleTicketRequestType;
