import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import LerRequestReducer, {
  ILerRequestState,
  defaultState,
  duckActions,
} from 'src/ducks/ler-request.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    LerRequestReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ILerRequestContext = ReturnType<typeof useReducer>;

const LerRequestContext = createContext<Partial<ILerRequestContext>>({
  state: defaultState,
}) as React.Context<ILerRequestContext>;

type IProps = {
  state?: Partial<ILerRequestState>;
};

const LerRequestProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <LerRequestContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </LerRequestContext.Provider>
  );
};

const useLerRequestContext = () => useContext(LerRequestContext);

export type IUseLerRequestContext = ReturnType<typeof useLerRequestContext>;

export { LerRequestContext, LerRequestProvider, useLerRequestContext };
