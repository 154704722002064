import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/outage-type.service';
import { IOutageType } from 'src/models/outage-type.model';

export const outageTypeActionTypes = {
  OUTAGE_TYPE_DATA_READ: 'OUTAGE_TYPE_DATA_READ',
  OUTAGE_TYPE_LIST_READ: 'OUTAGE_TYPE_LIST_READ',
  OUTAGE_TYPE_ACTIVE_LIST_READ: 'OUTAGE_TYPE_ACTIVE_LIST_READ',
  OUTAGE_TYPE_DATA_CREATE: 'OUTAGE_TYPE_DATA_CREATE',
  OUTAGE_TYPE_DATA_UPDATE: 'OUTAGE_TYPE_DATA_UPDATE',
  OUTAGE_TYPE_DATA_DELETE: 'OUTAGE_TYPE_DATA_DELETE',
  OUTAGE_TYPE_DATA_SET: 'OUTAGE_TYPE_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: outageTypeActionTypes.OUTAGE_TYPE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: outageTypeActionTypes.OUTAGE_TYPE_LIST_READ,
    service: services.listGET,
  },

  activeListGET: {
    type: outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ,
    service: services.activeListGET,
  },

  createPOST: {
    type: outageTypeActionTypes.OUTAGE_TYPE_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: outageTypeActionTypes.OUTAGE_TYPE_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: outageTypeActionTypes.OUTAGE_TYPE_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (outageType: IOutageType) => ({
    type: outageTypeActionTypes.OUTAGE_TYPE_DATA_SET,
    payload: {
      outageType,
    },
  }),
};

export type IOutageTypeAsync = typeof duckActions;

export interface IOutageTypeState
  extends ICommonState<typeof outageTypeActionTypes> {
  data?: IOutageType;
  list: IOutageType[];
  total: number;
}

export const defaultState: IOutageTypeState = {
  status: {},
  list: [],
  total: 0,
};

const OutageTypeReducer = (
  state: IOutageTypeState,
  action: IReducerAction<IOutageTypeAsync>
): IOutageTypeState => {
  switch (action.type) {
    case outageTypeActionTypes.OUTAGE_TYPE_DATA_SET:
    case outageTypeActionTypes.OUTAGE_TYPE_DATA_READ:
    case outageTypeActionTypes.OUTAGE_TYPE_DATA_UPDATE:
    case outageTypeActionTypes.OUTAGE_TYPE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.outageType,
      };
    }

    case outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ:
    case outageTypeActionTypes.OUTAGE_TYPE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case outageTypeActionTypes.OUTAGE_TYPE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.outageTypeId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default OutageTypeReducer;
