import React from 'react';
import { Link } from 'react-router-dom';
import {
  IORGDataTableColumn,
  MOLInfoText,
  ORGDataTable,
  ATMDropdown,
  IORGDataTableQueryState,
  MOLNoData,
  ATMIcon,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import {
  IOcPendingActionReport,
} from 'src/models/oc-pending-action-report.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import OcPendingActionReportDelete from '../oc-pending-action-report-delete/oc-pending-action-report-delete.component';
import OcPendingActionReportEdit from '../oc-pending-action-report-edit/oc-pending-action-report-edit.component';
import OCPendingActionsReportFilters from '../oc-pending-action-report-filters/oc-pending-action-report-filters.component';
import styles from '../oc-pending-action-report.module.scss';

type IProps = {
  loading: boolean;
  data: IOcPendingActionReport[];
  total: number;
  queryParams: Record<any, any>;
  pagination: Record<any, any>;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleQueryParams: (params: Record<any, any>) => void;
  handleSearch: () => void;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const OcPendingActionReportListView: React.FC<IProps> = ({
  data,
  loading,
  total,
  queryParams,
  pagination,
  handleFetch,
  handleQueryParams,
  handleSearch,
  showNoRecord,
  setShowNoRecord,
}) => {
  const { getUrl } = useLocationParams(['lerEdit']);
  const customColumns: IORGDataTableColumn<IOcPendingActionReport>[] = [
    {
      title: Lang.LBL_LER,
      index: 'requestId',
      render: (_, value) => (
        <Link to={getUrl({ lerEdit: value.requestId })}>{value.requestId}</Link>
      ),
    },
    {
      title: Lang.LBL_START_DATE,
      index: 'startDate',
      render: (_, value) => Moment(value.startDate).format('M/DD/YYYY'),
    },
    {
      title: Lang.LBL_STOP_DATE,
      index: 'stopDate',
      render: (_, value) => Moment(value.stopDate).format('M/DD/YYYY'),
    },
    {
      title: Lang.LBL_KV,
      index: 'voltNm',
      render: (_, value) => value.voltNm ?? '',
    },
    {
      title: Lang.LBL_FACILITY_NAME,
      index: 'outgFacNm',
      render: (_, value) =>
        value.subtationNm
          ? `${value.outgFacNm} @ ${value.subtationNm}`
          : value.outgFacNm,
    },
    {
      title: Lang.LBL_AUTH,
      index: 'outageTypeDesc',
    },
    {
      title: Lang.LBL_DAILY_OUTAGE_REPORT_NOTES,
      index: 'dailyOutageReportNotes',
      width: '30%',
      render: (_, value) => (
        <MOLInfoText
          transparent
          limit={50}
          content={value.dailyOutageReportNotes ?? ''}
        />
      ),
    },
    {
      title: Lang.LBL_COMPLETED,
      index: 'completed',
    },
    {
      title: Lang.LBL_ACTIONS,
      index: 'actions',
      render: (_, value) => (
        <ATMDropdown
          pointing
          icon="ellipsis horizontal"
          className="link item right top"
          closeOnChange
          closeOnBlur
        >
          <ATMDropdown.Menu>
            <OcPendingActionReportEdit
              data={value}
              queryParams={queryParams}
              pagination={pagination}
            />
            <OcPendingActionReportDelete
              data={value}
              queryParams={queryParams}
              pagination={pagination}
            />
          </ATMDropdown.Menu>
        </ATMDropdown>
      ),
    },
  ];
  return (
    <>
      <OCPendingActionsReportFilters
        queryParams={queryParams}
        handleQueryParams={handleQueryParams}
        handleSearch={handleSearch}
        setShowNoRecord={setShowNoRecord}
        loading={loading}
      />
      <ORGDataTable
        data={data}
        celled={false}
        loading={loading}
        columns={customColumns}
        total={total}
        sortable
        onChange={handleFetch}
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        noDataText={
          !showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={styles.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'bottom',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      />
    </>
  );
};

export default OcPendingActionReportListView;
