import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';

export const SubGroupShape = {
  subGrpId: yup.number().required(),
  subGrpNm: yup.string().required(),
  trbGrpId: yup.number().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const WorkGroupShape = {
  trbGrpId: yup.number().required(),
  trbGrpNm: yup.string().required(),
  emailDistribution: yup.array().of(yup.string()).required(),
  subGrp: yup.array().of(yup.object(SubGroupShape)).required().default([]),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const WorkGroupSchema = yup.object(WorkGroupShape);
export const SubGroupSchema = yup.object(SubGroupShape);

export const MailSchema = yup.object({
  mail: yup.string().required(),
});

export const MailListSchema = yup.object({
  mailListArray: yup.array().of(MailSchema.clone()).required(),
});

export const WorkGroupFormSchema = yup.object({
  trbGrpId: yup.number(),
  trbGrpNm: yup.string().required(Lang.MSG_WORK_GROUP_NAME_ERROR),
  emailDistribution: yup
    .array()
    .of(yup.string().email())
    .required(Lang.MSG_WORK_GROUP_EMAIL_ERROR),
  subGrp: yup
    .array()
    .of(
      yup.object({
        ...SubGroupShape,
        trbGrpId: yup.number(),
      })
    )
    .required()
    .default([]),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const WorkGroupListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(WorkGroupSchema.clone()),
});

export const WorkMailsListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(MailSchema.clone()),
});

export const WorkGroupPayloadSchema = WorkGroupSchema;

export const WorkGroupDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const WorkSubGroupValidateSchema = yup.object({
  validate: yup.boolean().required(),
});

export type IWorkGroup = yup.Asserts<typeof WorkGroupSchema>;
export type IWorkGroupForm = yup.Asserts<typeof WorkGroupFormSchema>;
export type IMailList = yup.Asserts<typeof MailSchema>;
export type ISubGroup = yup.Asserts<typeof SubGroupSchema>;
