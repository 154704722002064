/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMModal,
  ATMGrid,
  ATMTable,
  ATMField,
  ATMDropdown,
  ATMInput,
  ATMCheckbox,
  useATMFormContext,
  ATMForm,
  ATMFormProvider,
  ATMTimePicker,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useSwitchingContext } from 'src/contexts/switching.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import Moment, { format24hTime } from 'src/libraries/moment.library';
import {
  ILerRequest,
  ILerRequestEtsFormDispatchForm,
  ILerRequestForm,
  LerRequestEtsFormDispatchFormSchema,
} from 'src/models/ler-request.model';
import { IRealTimeLogLine } from 'src/models/real-time-log.model';
import { ISubstation } from 'src/models/substation.model';
import {
  IEtsFormDispatch,
  ISortResponse,
  ISwitching,
} from 'src/models/switching.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { orderBy } from 'lodash';
import { getSwitchingStatus } from 'src/selectors/switching.selector';
import { switchingActionTypes } from 'src/ducks/switching.duck';
import styles from './ler-request-switching-ets.module.scss';

type IContentProps = {
  defaultValues: ILerRequest;
  handleEnable: (value: boolean) => void;
};

const FormContent: React.FC<IContentProps> = ({
  defaultValues,
  handleEnable,
}) => {
  const {
    control,
    formState: { errors },
  } = useATMFormContext<IEtsFormDispatch>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'etsDispatch',
  });
  const { state: realTimeLogState } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: switchingState } = useSwitchingContext();
  const switchStatus = getSwitchingStatus(
    switchingState,
    switchingActionTypes.SWITCHING_LIST_READ
  );

  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>('');
  const [etsId, setEtsId] = useState<string>('');

  useEffect(() => {
    handleEnable(fields.some((val) => val.isChecked));
  }, [fields, handleEnable]);

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  const lineSubstationOptions = [
    {
      key: 1,
      text: 'Switch',
      value: 'switch',
    },
    {
      key: 2,
      text: 'Tie Line',
      value: 'tieLine',
    },
    {
      key: 3,
      text: 'Substation',
      value: 'substation',
    },
  ];

  const switchOptions = switchingState.listDropdown.map((val, i) => {
    return {
      key: `${val.description}_${i}`,
      text: val.description,
      value: val.description,
    };
  });

  const lineOptions = realTimeLogState.line
    .filter((val) => val.outgFacNm.includes('TL'))
    .map((val, i) => {
      return {
        key: `${val.outgFacNm}_${i}`,
        text: val.outgFacNm,
        value: val.outgFacId,
      };
    });

  const substationOptions = substationState.list.map((val, i) => {
    return {
      key: `${val.substationId}_${i}`,
      text: val.name,
      value: val.substationId,
    };
  });

  const handleAddTieLine = useCallback(
    (id: number) => {
      const facilityInfo: IRealTimeLogLine[] = realTimeLogState.line?.filter(
        (val) => val.outgFacId === id
      );
      if (facilityInfo && facilityInfo.length > 0) {
        const facilityData = facilityInfo[0].substations?.map(
          (val: ISubstation) => {
            return {
              tlSub: `${
                facilityInfo[0].outgFacNm.includes('(')
                  ? facilityInfo[0].outgFacNm.split('(')[0].replace(' ', '')
                  : facilityInfo[0].outgFacNm.split('at')[0].replace(' ', '')
              } ${val.substationId}`.replace(/\s\s+/g, ' '),
              subPole: val.name,
              subDistId: val.subDistId ?? '',
              outgFacId: id,
              poleId: null,
              substationId: val.substationId,
              etmNbr: 1,
              out: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              back: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              switchId: null,
              isChecked: false,
              backDate: undefined,
              backTime: undefined,
              outDate: undefined,
              outTime: undefined,
            };
          }
        );
        if (facilityData && facilityData.length > 0) {
          append(facilityData);
        }
      }
    },
    [append, control, etsId]
  );

  const handleAddSwitch = useCallback(
    (id: string) => {
      const switchInfo: ISwitching[] = switchingState.list?.filter(
        (val) => val.description === id
      );
      if (switchInfo && switchInfo.length > 0) {
        const switchData = switchInfo.map((val: ISwitching) => {
          return {
            tlSub: `${val.description}`,
            subPole: `POLE${val.poleId}`,
            subDistId: val.subDistId ?? '',
            outgFacId: val.outgFacId,
            poleId: val.poleId,
            substationId: null,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: val.switchId,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
          };
        });
        if (switchData && switchData.length > 0) {
          append(switchData);
        }
      }
    },
    [append, control, etsId]
  );

  const handleAddSubstation = useCallback(
    (id: string) => {
      const substationInfo: ISubstation[] = substationState.list?.filter(
        (val) => val.substationId === id
      );
      if (substationInfo && substationInfo.length > 0) {
        const substationData = substationInfo.map((val: ISubstation) => {
          return {
            tlSub: `${val.substationId}`,
            subPole: val.name,
            subDistId: val.subDistId ?? '',
            outgFacId: null,
            poleId: null,
            substationId: id,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: null,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
          };
        });
        if (substationData && substationData.length > 0) {
          append(substationData);
        }
      }
    },
    [append, control, etsId]
  );

  // Group sort responses by type[Tie Line, Substation, Switch]
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // eslint-disable-next-line no-param-reassign
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  useEffect(() => {
    remove();
    setLineSubstationSelect('');
    setEtsId('');

    // Setting default from selected line/station
    if (defaultValues.outageFacility.outgFacId) {
      handleAddTieLine(defaultValues.outageFacility.outgFacId);
    }
    if (defaultValues.outageFacility.substationId) {
      handleAddSubstation(defaultValues.outageFacility.substationId);
    }

    // Setting default from already save switching request
    if (defaultValues.sortResponses) {
      const sortGroupBy = Object.entries(
        groupBy(defaultValues.sortResponses, 'tlSub')
      ).map(([_, val]) => val) as ISortResponse[];
      sortGroupBy.map((val) => {
        let exists = false;
        if (val) {
          if (val[0].outgFacId && val[0].substationId) {
            exists = fields.some(
              (field) =>
                field.outgFacId === defaultValues.outageFacility.outgFacId
            );
            return !exists ? handleAddTieLine(val[0].outgFacId.toString()) : '';
          }
          if (val[0].substationId && val[0].outgFacId === null) {
            exists = fields.some(
              (field) =>
                field.substationId ===
                  defaultValues.outageFacility.substationId &&
                field.outgFacId === null
            );
            return !exists ? handleAddSubstation(val[0].substationId) : '';
          }
          return handleAddSwitch(val[0].tlSub);
        }
        return '';
      });
    }
  }, []);

  return (
    <ATMGrid>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
            {defaultValues.outageFacility.substationId
              ? `${Lang.FACILITY_TYPE[2]} - ${
                  defaultValues.outageFacility.substation?.name ??
                  defaultValues.outageFacility.substationId
                }`
              : `${Lang.FACILITY_TYPE[1]} - ${defaultValues.outageFacility.facility?.outgFacNm}`}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_KV}>
            {defaultValues.outageFacility?.voltage?.voltNm ??
              defaultValues.outageFacility?.voltId}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_EQUIPMENT}>
            {defaultValues.outageFacility.substationId
              ? defaultValues.outageFacility?.facility?.outgFacNm ??
                defaultValues.outageFacility.outgFacId
              : ''}
          </FieldReadOnly>
        </ATMGrid.Column>
      </ATMGrid.Row>

      <ATMGrid.Row style={{ background: '#f1f2f3' }}>
        <ATMGrid.Column width={3}>
          <ATMField
            as={ATMDropdown}
            selection
            size="small"
            name="lineSubstation"
            defaultValues={lineSubstationSelect}
            options={lineSubstationOptions}
            onChange={
              ((_, { value }) => {
                if (!value) {
                  setEtsId('');
                  setLineSubstationSelect('');
                } else {
                  setLineSubstationSelect(value);
                }
                return value;
              }) as any
            }
            selectOnBlur={false}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3}>
          {lineSubstationSelect === 'switch' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={switchOptions}
              loading={switchStatus.fetching}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
              search
            />
          )}
          {lineSubstationSelect === 'tieLine' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={lineOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
              search
            />
          )}{' '}
          {lineSubstationSelect === 'substation' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={substationOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              search
            />
          )}
          {lineSubstationSelect === '' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              disabled
            />
          )}
        </ATMGrid.Column>
        <ATMGrid.Column width={2}>
          <ATMButton
            type="button"
            content="Add"
            secondary
            disabled={!lineSubstationSelect || !etsId}
            onClick={() => {
              if (lineSubstationSelect === 'tieLine') {
                handleAddTieLine(Number(etsId));
              } else if (lineSubstationSelect === 'switch') {
                handleAddSwitch(etsId);
              } else if (lineSubstationSelect === 'substation') {
                handleAddSubstation(etsId);
              }
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMTable celled>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell content={' '} />
              <ATMTable.HeaderCell content={Lang.LBL_ETS} />
              <ATMTable.HeaderCell content={Lang.LBL_OUT} />
              <ATMTable.HeaderCell content={Lang.LBL_DATE_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_BACK} />
              <ATMTable.HeaderCell content={Lang.LBL_DATE_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_TL_SUB} />
              <ATMTable.HeaderCell content={Lang.LBL_SUB_POLE} />
              <ATMTable.HeaderCell content={Lang.LBL_AREA} />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((val, i) => (
              <ATMTable.Row key={val.id}>
                {/* hidden */}
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].tlSub`}
                  defaultValue={val.tlSub}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subPole`}
                  defaultValue={val.subPole}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subDistId`}
                  defaultValue={val.subDistId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].outgFacId`}
                  defaultValue={val.outgFacId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].switchId`}
                  defaultValue={val.switchId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].substationId`}
                  defaultValue={val.substationId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].poleId`}
                  defaultValue={val.poleId}
                  type="hidden"
                  className="hidden"
                />
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMCheckbox}
                    name={`etsDispatch[${i}].isChecked`}
                    control={control}
                    defaultValue={val.isChecked}
                    onChange={([_, { checked }]) => {
                      update(i, {
                        ...val,
                        isChecked: checked,
                      });
                      return checked;
                    }}
                    className={
                      !val.subDistId || !val.tlSub || !val.subPole
                        ? 'hidden'
                        : ''
                    }
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMInput}
                    name={`etsDispatch[${i}].etmNbr`}
                    control={control}
                    defaultValue={val.etmNbr}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].out.byAt`}
                      onChange={([_, { value }]) => value}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      control={control}
                      defaultValue={val.out.byAt}
                      error={errors.etsDispatch?.[i]?.out?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMTimePicker}
                      size="small"
                      is24hourformat
                      control={control}
                      name={`etsDispatch[${i}].out.sortTime`}
                      defaultValue={format24hTime(val.out.sortTime)}
                      error={errors.etsDispatch?.[i]?.out?.sortTime}
                      onChange={([_, { value }]) => {
                        if (value) {
                          const [hour, minute] = value.split(':');
                          return Moment().set({
                            hour,
                            minute,
                          });
                        }

                        return value;
                      }}
                      disabled
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].back.byAt`}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      onChange={([_, { value }]) => value}
                      control={control}
                      defaultValue={val.back.byAt ?? 'AT'}
                      error={errors.etsDispatch?.[i]?.back?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMTimePicker}
                      size="small"
                      is24hourformat
                      control={control}
                      name={`etsDispatch[${i}].back.sortTime`}
                      defaultValue={format24hTime(val.back.sortTime)}
                      error={errors.etsDispatch?.[i]?.back?.sortTime}
                      onChange={([_, { value }]) => {
                        if (value) {
                          const [hour, minute] = value.split(':');
                          return Moment().set({
                            hour,
                            minute,
                          });
                        }

                        return value;
                      }}
                      disabled
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell>{val.tlSub}</ATMTable.Cell>
                <ATMTable.Cell>{val.subPole}</ATMTable.Cell>
                <ATMTable.Cell>{val.subDistId}</ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
        </ATMTable>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

type IProps = {
  defaultValues?: ILerRequest;
};

const LERRequestSwitchingETS: React.FC<IProps> = ({ defaultValues }) => {
  const {
    state: { data },
  } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const { state, actions } = useLerRequestContext();
  const { getValues, setValue } = useATMFormContext<ILerRequestForm>();
  const formRef = useRef<HTMLFormElement>(null);
  const { state: switchingState, actions: switchingActions } =
    useSwitchingContext();

  useEffect(() => {
    if (!switchingState.list.length) {
      switchingActions.listGET({ limit: 0, page: 1 });
    }
  }, [switchingActions]);

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SORT_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ILerRequestEtsFormDispatchForm) => {
      if (data?.requestId) {
        const insertData = formData.etsDispatch.filter((val) => val.isChecked);

        if (insertData.length) {
          const result = await actions.createSwitchPOST(
            data?.requestId,
            insertData
          );

          if (result.payload) {
            const sortResponseList: ISortResponse[] = [];
            result.payload?.map((val) => {
              sortResponseList.push({
                ...val,
                date: val.sortTime,
                time: format24hTime(val.sortTime),
              });
            });
            setIsOpen(false);
            setValue(
              'sortResponses',
              orderBy(
                [...(getValues('sortResponses') as []), ...sortResponseList],
                ['sortTime', 'outBack'],
                ['asc', 'desc']
              )
            );
            actions?.dataGET(data?.requestId);
          }
        }
      }
    },
    [actions, data, setIsOpen, setValue, getValues]
  );

  if (!data) {
    return null;
  }

  return (
    <ATMModal
      open={isOpen}
      size="large"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <ATMButton
          content="Add Switching Requests"
          secondary
          size="small"
          type="button"
        />
      }
    >
      <ATMModal.Header as="h2">{Lang.TTL_ETS_DISPATCH}</ATMModal.Header>
      <ATMModal.Content
        style={{ marginBottom: '100px', height: '70vh', overflowY: 'auto' }}
      >
        <ATMForm
          ref={formRef}
          onSubmit={handleSubmit}
          mode="onChange"
          validationSchema={LerRequestEtsFormDispatchFormSchema}
        >
          {(formProps) => {
            return (
              <ATMFormProvider {...formProps}>
                <FormContent
                  defaultValues={
                    defaultValues || (getValues() as unknown as ILerRequest)
                  }
                  handleEnable={(value) => setIsEnabled(value)}
                />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => setIsOpen(false)}
          type="button"
        />
        <ATMButton
          primary
          content="Add Switching Request"
          onClick={handleClick}
          type="button"
          loading={loading.fetching}
          disabled={!isEnabled || loading.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LERRequestSwitchingETS;
