import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TrainersReducer, {
  ITrainersState,
  defaultState,
  duckActions,
} from 'src/ducks/trainers.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TrainersReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITrainersContext = ReturnType<typeof useReducer>;

const TrainersContext = createContext<Partial<ITrainersContext>>({
  state: defaultState,
}) as React.Context<ITrainersContext>;

type IProps = {
  state?: Partial<ITrainersState>;
};

const TrainersProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TrainersContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TrainersContext.Provider>
  );
};

const useTrainersContext = () => useContext(TrainersContext);

export type IUseTrainersContext = ReturnType<typeof useTrainersContext>;

export { TrainersContext, TrainersProvider, useTrainersContext };
