import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMButton,
  ATMHeader,
  ATMIcon,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import history from 'src/history';
import { getEmployeeName } from 'src/helpers/employee.helper';
import Lang from 'src/libraries/language';
import Moment, { format24hDateTime } from 'src/libraries/moment.library';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import SubstationEntryLogSearch from '../../substation-entry-log-search/substation-entry-log-search.component';
import styles from '../substation-entry-log-list.module.scss';

const columns: IORGDataTableColumn<ISubstationEntry>[] = [
  {
    index: 'substation.name',
    title: Lang.LBL_SUBSTATION,
    render: (_, value) =>
      `${value.substation.substationId} - ${value.substation.name}`,
  },
  {
    index: 'employee.fullName',
    title: Lang.LBL_EMPLOYEE_NAME_VISITORS,
    render: (_, value) =>
      getEmployeeName(
        value.employee ?? {
          empId: value.empId,
        }
      ),
  },
  {
    index: 'inDateTime',
    title: Lang.LBL_IN_DATE,
    render: (value) => {
      return (
        <>
          <span>{format24hDateTime(value)}</span>
        </>
      );
    },
  },
  {
    index: 'status',
    title: Lang.LBL_STATUS,
    sortable: false,
  },
  {
    index: 'outDateTime',
    title: Lang.LBL_OUT_DATE,
    render: (_, value) => {
      return (
        <>
          {value.outDateTime ? (
            <span>{format24hDateTime(value.outDateTime)}</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    index: 'entryReason.description',
    title: Lang.LBL_ACTIVITY,
  },
  {
    index: 'comment',
    title: Lang.LBL_SUBSTATION_PURPOSE,
    sortable: false,
  },
  {
    index: 'visitors',
    title: Lang.LBL_VISITORS,
  },
  {
    index: 'entryEmpId',
    title: Lang.LBL_CHECKED_IN_BY,
    render: (_, value) =>
      value.entryEmpId ? `${getEmployeeName(value.entryEmployee)}` : null,
  },
  {
    index: 'exitEmpId',
    title: Lang.LBL_CHECKED_OUT_BY,
    render: (_, value) =>
      value.exitEmpId ? `${getEmployeeName(value.exitEmployee)}` : null,
  },
];

type IProps = {
  data: ISubstationEntry[];
  total: number;
  loading: boolean;
  downloading: boolean;
  handleFetch: (data, all?: boolean) => void;
  handleDownload: (all: boolean) => void;
  timer?: any;
};

const SubstationEntryLogListSearch: React.FC<IProps> = ({
  data,
  loading,
  downloading,
  total,
  handleFetch,
  handleDownload,
  timer,
}) => {
  const [showTableAll, setShowTableAll] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [searchDate, setSearchDate] = useState<string[]>([]);

  useEffect(() => {
    if (timer && timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  const handleSearch = useCallback(
    (params) => {
      if (isSearch) {
        const filters = Object.entries(params).map(([name, value]) => ({
          name,
          value,
        }));

        handleFetch(
          {
            filters,
          },
          false
        );

        setShowTableAll(true);
      }
    },
    [handleFetch, setShowTableAll, isSearch, setIsSearch]
  );

  return (
    <div>
      <div>
        <SubstationEntryLogSearch
          handleSearch={handleSearch}
          handleClearTable={() => setShowTableAll(false)}
          setIsSearch={setIsSearch}
          loadingList={loading}
          setSearchDate={(value) => setSearchDate(value)}
          setShowAll={(value) => setShowAll(value)}
          showAll={showAll}
        />
      </div>
      {!showTableAll ? (
        <div className={styles.searchContainer}>
          <ATMIcon
            circular
            name="search"
            size="large"
            className={styles.searchIcon}
          />
          <ATMHeader
            as="h3"
            content={Lang.LBL_VIEW_RECORDS}
            className={styles.title}
          />
          <p className={styles.subtitle}>{Lang.LBL_SEARCH_ENTRY}</p>
        </div>
      ) : (
        <div>
          <ORGDataTable
            columns={columns}
            data={data}
            sortable
            loading={loading}
            showPagination={false}
            celled={false}
            toolbar="true"
            counter
            location={history.location}
            handleLocation={history.push}
            total={total}
            onChange={(values) => {
              data.sort((a, b) => {
                let recordA;
                let recordB;
                if (values.sort === 'substation.name') {
                  recordA = `${a.substation.substationId} - ${a.substation.name}`;
                  recordB = `${b.substation.substationId} - ${b.substation.name}`;
                } else if (values.sort === 'employee.fullName') {
                  recordA = getEmployeeName(
                    a.employee ?? {
                      empId: a.empId,
                    }
                  );
                  recordB = getEmployeeName(
                    b.employee ?? {
                      empId: b.empId,
                    }
                  );
                } else if (values.sort === 'inDateTime') {
                  recordA = a.inDateTime;
                  recordB = b.inDateTime;
                } else if (values.sort === 'outDateTime') {
                  recordA = a.outDateTime;
                  recordB = b.outDateTime;
                } else if (values.sort === 'entryReason.description') {
                  recordA = a.entryReason.description;
                  recordB = b.entryReason.description;
                } else if (values.sort === 'entryEmpId') {
                  recordA = a.entryEmpId
                    ? `${getEmployeeName(a.entryEmployee)}`
                    : 'ZZZZZZ';
                  recordA = recordA === 'Unavailable' ? 'ZZZZZZ' : recordA;
                  recordB = b.entryEmpId
                    ? `${getEmployeeName(b.entryEmployee)}`
                    : 'ZZZZZZ';
                  recordB = recordB === 'Unavailable' ? 'ZZZZZZ' : recordB;
                } else if (values.sort === 'exitEmpId') {
                  recordA = a.exitEmpId
                    ? `${getEmployeeName(a.exitEmployee)}`
                    : 'ZZZZZZ';
                  recordA = recordA === 'Unavailable' ? 'ZZZZZZ' : recordA;
                  recordB = b.exitEmpId
                    ? `${getEmployeeName(b.exitEmployee)}`
                    : 'ZZZZZZ';
                  recordB = recordB === 'Unavailable' ? 'ZZZZZZ' : recordB;
                }

                if (values.order === 'ascending') {
                  return recordA > recordB ? 1 : -1;
                }
                return recordA > recordB ? -1 : 1;
              });
            }}
          >
            {() => ({
              toolbars: {
                buttons: () => (
                  <div>
                    <ATMButton
                      key="download"
                      secondary
                      icon="download"
                      floated="right"
                      content={Lang.LBL_DOWNLOAD}
                      onClick={() => handleDownload(true)}
                      loading={downloading}
                      disabled={downloading}
                    />
                  </div>
                ),
              },
            })}
          </ORGDataTable>

          {showAll && total > 50 && (
            <ATMButton
              secondary
              onClick={() => {
                handleFetch(
                  {
                    page: 1,
                    limit: 0,
                    filters: [
                      {
                        name: 'startDate',
                        value: Moment(searchDate[0])
                          .startOf('day')
                          .format('YYYY-MM-DD'),
                      },
                      {
                        name: 'endDate',
                        value: Moment(searchDate[1] ?? searchDate[0])
                          .startOf('day')
                          .format('YYYY-MM-DD'),
                      },
                    ],
                  },
                  false
                );
                setShowAll(!showAll);
              }}
              content="Show All"
              style={{ display: 'block', width: '100%', marginTop: '2em' }}
              type="button"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SubstationEntryLogListSearch;
