import React from 'react';
import { ATMGrid, ATMHeader, ATMIcon, ATMSegment } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { AccessRole } from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import LerRequestRelatedLer from '../ler-request-related-ler/ler-request-related-ler.component';
import LERRequestAuthorizationNotification from './ler-request-authorization-notification/ler-request-authorization-notification.component';
import styles from './ler-request-authorization.module.scss';
import LERRejectionNotes from '../ler-request-list/ler-request-rejection-notes/ler-request-rejection-notes.component';

type IProps = {
  data?: ILerRequest;
  handleEdit?: () => void;
};

const LERRequestAuthorization: React.FC<IProps> = ({ data, handleEdit }) => {
  if (!data) {
    return null;
  }

  const header = handleEdit ? (
    <ATMHeader as="h2" attached="top" block>
      {Lang.TTL_AUTHORIZATION_INFORMATION}
      {handleEdit && (
        <a className={styles.edit} onClick={handleEdit} role="button">
          <ATMIcon name="edit" />
          {Lang.LBL_EDIT}
        </a>
      )}
    </ATMHeader>
  ) : (
    <>
      <span>
        <ATMIcon circular name="tasks" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_AUTHORIZATION_INFORMATION}
        className={styles.header}
      />
    </>
  );

  const content = (
    <>
      <ATMGrid columns={1}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_OPERATING_NOTE}>
              {data.authorizationStatement?.authStmnt}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_CANCELLATION_NOTE}>
              {data.authorizationStatement?.cancelNote?.noteTxt}
            </FieldReadOnly>
          </ATMGrid.Column>
          {data?.rejectNotes?.length ? (
            <ATMGrid.Row>
              <ATMGrid.Column>
                <div
                  style={{
                    marginLeft: '2em !important',
                    width: '100% !important',
                  }}
                >
                  <LERRejectionNotes data={data} />
                </div>
              </ATMGrid.Column>
            </ATMGrid.Row>
          ) : (
            ''
          )}

          <ATMGrid.Column>
            <ATMHeader
              className={styles.subHeader}
              as="h2"
              content={Lang.TTL_DAILY_OUTAGE_REPORTS}
            />
          </ATMGrid.Column>
          {(data.authorizationStatement?.dailyNotes || []).map((value, key) => (
            <ATMGrid.Column key={key}>
              <FieldReadOnly label={Lang.LBL_DAILY_OUTAGE_REPORT_NOTE}>
                {value.notes}
              </FieldReadOnly>
            </ATMGrid.Column>
          ))}
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <div>
      {header}
      {handleEdit ? (
        <ATMSegment attached="bottom">{content}</ATMSegment>
      ) : (
        <>
          {content}
          {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && (
            <>
              <LERRequestAuthorizationNotification data={data} />
              <LerRequestRelatedLer
                grpId={data.grpId ?? null}
                requestId={data.requestId}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LERRequestAuthorization;
