import React, { useState } from 'react';
import {
  ATMButton,
  ATMModal,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ISubstation } from 'src/models/substation.model';
import { IFacility } from 'src/models/facility.model';

const columns: IORGDataTableColumn<ISubstation>[] = [
  {
    index: 'empId',
    title: Lang.LBL_SUBSTATIONS,
    render: (_, value) => {
      return `${value.substationId} - ${value.name}`;
    },
  },
];

type IProps = {
  data: IFacility;
};

const FacilitySubstationGroupModal: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <u>
          {' '}
          <a
            style={{ color: '#5D5D5D' }}
            role="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >{`+${data.substations && data.substations.length - 1}`}</a>{' '}
        </u>
      }
    >
      <ATMModal.Header>
        {data.outgFacNm} - {Lang.LBL_SUBSTATION}
      </ATMModal.Header>
      <ATMModal.Content>
        {data.substations && (
          <ORGDataTable
            columns={columns}
            data={data.substations}
            showPagination
          />
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          primary
          content="Close"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default FacilitySubstationGroupModal;
