import React, { useCallback, useRef, useState } from 'react';
import { useSubstationContext } from 'src/contexts/substation.context';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { ISubstationForm } from 'src/models/substation.model';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import SubstationAddView from './substation-add.view';

const SubstationAdd: React.FC = () => {
  const { state, actions } = useSubstationContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ISubstationForm) => {
      const response = await actions.createPOST({
        ...formData,
        updatedAt: new Date(),
        updatedBy: getUser()?.emp_no,
      });
      if (!response.error && response.payload) {
        actions.listGET({ limit: 50, page: 1 });
        setIsAddOpen(false);

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_SUCCESS_ADD_SUBSTATION,
            response.payload?.name
          )
        );
      } else {
        ToastError(response.error?.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <SubstationAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default SubstationAdd;
