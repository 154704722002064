import QueryString from 'qs';
import history from 'src/history';
import ReactDOM from 'react-dom/server';

export const checkValue = (value: any): string => {
  if (value !== null && value !== undefined) {
    return value.toString();
  }
  return '';
};

export const checkValueArr = (value: any, name: string): string => {
  if (value) {
    return value.map((item) => item[name]).toString();
  }
  return '';
};

export const getUrlParams = (search?: string): Record<string, any> =>
  QueryString.parse(search ?? history.location.search, {
    ignoreQueryPrefix: true,
  });

export const getUrl = (data: Record<string, any>, path?: string) => {
  const params = getUrlParams();

  const urlReadyQuery = QueryString.stringify({
    ...params,
    ...data,
  });

  return `${path ?? history.location.pathname}?${urlReadyQuery}`;
};

export const getComponentText = (element) => {
  if (typeof element === 'string') {
    return element;
  }

  return ReactDOM.renderToStaticMarkup(element).replace(/(<([^>]+)>)/gi, '');
};
