export enum LERRequestFormStep {
  OUTAGE_FACILITY = 'outageFacility',
  OUTAGE_DATE = 'outageDates',
  JOB_INFORMATION = 'jobInformation',
  CREW_INFORMATION = 'crewInformation',
  AUTHORIZATION_STATEMENT = 'authorizationStatement',
  ISO = 'isoTrs',
  SWITCHING = 'sortResponses',
  SYSTEM_CHANGE = 'system',
  COST = 'significantCost',
  REVIEW = 'review',
}
export enum LERRequestDetailMenu {
  OUTAGE_DATE = 0,
  JOB_INFORMATION = 1,
  CREW_INFORMATION = 2,
  AUTHORIZATION_STATEMENT = 3,
  ISO = 4,
  SWITCHING = 5,
  SYSTEM_CHANGE = 6,
  COST = 7,
  CHANGE_REQUEST = 8,
}
export enum LERRequestWidth {
  LEFT = 11,
  RIGHT = 5,
}
export const lerRequestFormStep = LERRequestFormStep;
export const lerRequestWidth = LERRequestWidth;
export enum OutageType {
  OkStartProgram = 'OK - Start Program',
  HotLine = 'Hot Line Order',
  Info = 'Info Only',
  InterCo = 'InterCo Clearance',
  External = 'External',
  Hold = 'Hold',
  Restricted = 'Restricted',
  Unavailable = 'Unavailable',
}
export enum AuthorizationType {
  Ok = 3,
  HotLineOrder = 12,
  LimitedClearance = 5,
  OkOrNear = 1,
  Clearance = 4,
  Hold = 2,
  InfoOnly = 6,
  Restricted = 10,
  Unavailable = 11,
  InterCoClearance = 8,
  OkStartProgram = 13,
  External = 7,
}

export enum LERRequestStatus {
  Submitted = 1,
  Cancelled = 5,
  Scheduled = 9,
  Pending = 10,
  Created = 12,
  Rejected = 13,
  Approved = 14,
  Completed = 15,
  Out = 16,
  In = 17,
  Study = 18,
  Reviewed = 11,
}

export enum LERRequestAction {
  Create = 'create',
  Update = 'update',
  Approve = 'approve',
  Withdraw = 'withdraw',
  Reject = 'reject',
  Cancel = 'cancel',
  Schedule = 'schedule',
  Study = 'study',
  Submit = 'submit',
  WithdrawChangeRequest = 'withdraw cr',
  ApproveChangeRequest = 'approve cr',
  RejectChangeRequest = 'reject cr',
  CancelChangeRequest = 'cancel cr',
  RejectCancelChangeRequest = 'reject cancel cr',
}

export enum LERRequestChangeStatus {
  Requested = 'Change Requested',
  Submitted = 'Change Submitted',
  Approved = 'Change Approved',
  Rejected = 'Change Rejected',
}

export enum LERNotificationStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}

export const FAILED_CAISO_MSG =
  'Failed to submit to CAISO this time. You may Flag for Follow Up to send it later.';

export enum LEREmailType {
  NOTIFICATION = 'Notification',
  APPROVAL = 'Approval',
}

export enum LERRequestPage {
  Edit = 'lerEdit',
  View = 'lerView',
  Clone = 'lerClone',
  Version = 'version',
}

export enum LEREmailNotifyContent {
  EmailHeader = '*** This email was generated by MCC (Mission Control Center) System ***',
  EmailSubject = 'SDG&E Work Notification for [LER# requestId] - ',
  MoreDateDetails = 'Please see more dates in the LER.',
  AuthorizationTypeField = 'Authorization Type',
  DateOccurences = 'Next 10 Occurrences',
  Line = 'Line',
  Equipment = 'Equipment',
  Station = 'Station',
  Voltage = 'Voltage',
}

export enum LEREmailField {
  FacilityInfo = '[Facility Information]',
  CREW_OUTAGE_DATES = '[Crew Start/End]',
  OUTAGE_DATES = '[Outage Start/End]',
  ShortDescription = '[Short Description]',
  WorkDescription = '[Work Description]',
}

export enum SystemChangeTasks {
  SYSTEM_CHANGE_ON_TO_ON = 'Email System Change Tasks Updated',
  SYSTEM_CHANGE_OFF_TO_ON = 'Email System Change Tasks New',
  SYSTEM_CHANGE_ON_TO_OFF = 'Email System Change Tasks Cancelled',
}

export enum SystemChangeTasksHeader {
  SYSTEM_CHANGE_OFF_TO_ON = "You turned on the 'System Change' option. Send out System Change email?",
  SYSTEM_CHANGE_ON_TO_OFF = "You turned off the 'System Change' option. Send out email that System Change is cancelled for this LER?",
  SYSTEM_CHANGE_ON_TO_ON = 'Send email to System Change DL that there are changes to this LER?',
}

export enum NameFlag {
  PARTICIPANTID = 'PARTICIPANTID',
  PARTICIPANTNAME = 'PARTICIPANTNAME',
}

export enum LerRequestActionOMS {
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL',
}

export enum LerRequestStatusOMS {
  PENDING = 'PENDING',
  CANCEL = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  SUBMITTED = 'SUBMITTED',
}

export enum RejectionType {
  OutageRequest = 'OR',
  ChangeRequest = 'CR',
}
