import { httpClient } from 'src/libraries/http.library';
import {
  ISickListForm,
  SickListListPayloadSchema,
  SickListDeletePayloadSchema,
  SickListPayloadSchema,
  SickTypesListPayloadSchema,
} from 'src/models/sick-list.model';

const client = httpClient();
const endpoint = 'sick-list';
const endpointSickType = 'sick-type';

const services = {
  dataGET: async (id: number | undefined) => {
    return client.get(`/${endpoint}/${id}`, {}, SickListPayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, SickListListPayloadSchema);
  },

  sickTypesGET: async () => {
    return client.get(`/${endpointSickType}`, {}, SickTypesListPayloadSchema);
  },

  createPOST: async (data: ISickListForm) => {
    return client.post(`/${endpoint}`, data, SickListPayloadSchema);
  },

  updatePUT: async (id: number | undefined, data: ISickListForm) => {
    return client.put(`/${endpoint}/${id}`, data, SickListPayloadSchema);
  },

  dataDELETE: async (id: number | undefined) => {
    return client.delete(`/${endpoint}/${id}`, SickListDeletePayloadSchema);
  },
};

export default services;
