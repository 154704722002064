import { facilityActionTypes, IFacilityState } from 'src/ducks/facility.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getFacilityStatus = (
  state: IFacilityState,
  action: keyof typeof facilityActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
