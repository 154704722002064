import React, { useCallback, useRef, useEffect } from 'react';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import { substationEntryActionTypes } from 'src/ducks/substation-entry.duck';
import {
  ISubstationEntry,
  ISubstationEntryAddForm,
} from 'src/models/substation-entry.model';
import { getSubstationEntryStatus } from 'src/selectors/substation-entry.selector';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { fileActionTypes } from 'src/ducks/file.duck';
import { getFileStatus } from 'src/selectors/file.selector';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { getUser } from 'src/libraries/amplify.library';
import SubstationEntryLogAddView from './substation-entry-log-add.view';

type IProps = {
  handleSuccess: () => void;
};

const SubstationEntryLogAdd: React.FC<IProps> = ({ handleSuccess }) => {
  const { state, actions } = useSubstationEntryContext();
  const { state: authState, actions: authActions } = useAuthorizedListContext();
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();

  const formRef = useRef<HTMLFormElement>(null);

  const status = getSubstationEntryStatus(
    state,
    substationEntryActionTypes.SUBSTATION_ENTRY_DATA_CREATE
  );
  const fileStatus = getFileStatus(
    fileState,
    fileActionTypes.FILE_SHAREPOINT_UPLOAD
  );
  const authStatus = getAuthorizedListStatus(
    authState,
    authorizedListActionTypes.AUTHORIZED_SUBSTATION_LIST
  ).fetching;

  useEffect(() => {
    authActions.substationEntryListGET();
  }, [authActions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleFileUploadSubmit = useCallback(
    async (responseData: ISubstationEntry) => {
      // const requestor = `${responseData?.entryEmployee?.firstName ?? ''}  ${
      //   responseData?.entryEmployee?.lastName ?? ''
      // }`;
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.substationEntryLogId) || '',
        parentFolder: responseData?.substationEntryLogId as any,
        requestId: responseData?.substationEntryLogId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.SUBSTATION_ENTRY_TYPE,
        setting: settingState,
        withParentFolder: false,
      });
    },
    [fileState, settingState]
  );

  const handleSubmit = useCallback(
    async (formData: ISubstationEntryAddForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (response.payload) {
        handleFileUploadSubmit(response.payload);
        ToastSuccess('Substation entry added successfully!');
        handleSuccess();
      } else {
        fileAction.deleteAllStashedFiles();
        ToastError('Failed to add substation entry!');
      }
    },
    [actions, handleSuccess, fileState, fileAction]
  );

  return (
    <AccessComponent type={AccessType.SUBSTATION_ENTRY_LOG_UPDATE}>
      <SubstationEntryLogAddView
        formRef={formRef}
        loading={status.fetching || fileStatus.fetching}
        authLoading={authStatus}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        employees={authState.substationEntryList}
      />
    </AccessComponent>
  );
};

export default SubstationEntryLogAdd;
