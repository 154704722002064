import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import VoltReducer, {
  IVoltState,
  defaultState,
  duckActions,
} from 'src/ducks/volt.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    VoltReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IVoltContext = ReturnType<typeof useReducer>;

const VoltContext = createContext<Partial<IVoltContext>>({
  state: defaultState,
}) as React.Context<IVoltContext>;

type IProps = {
  state?: Partial<IVoltState>;
};

const VoltProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <VoltContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </VoltContext.Provider>
  );
};

const useVoltContext = () => useContext(VoltContext);

export type IUseVoltContext = ReturnType<typeof useVoltContext>;

export { VoltContext, VoltProvider, useVoltContext };
