import yup from 'src/libraries/validator.library';

export const OutageTypeShape = {
  outageTypeId: yup.number().required(),
  outageTypeDesc: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  displayOrder: yup.number(),
  outageStatus: yup.string(),
};

export const OutageTypeSchema = yup.object(OutageTypeShape);

export const OutageTypeFormSchema = yup.object({
  name: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const OutageTypeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(OutageTypeSchema.clone()),
});

export const OutageTypePayloadSchema = yup.object({
  outageType: OutageTypeSchema.clone(),
});

export const OutageTypeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IOutageType = yup.Asserts<typeof OutageTypeSchema>;
export type IOutageTypeForm = yup.Asserts<typeof OutageTypeFormSchema>;
