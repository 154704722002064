import React, { useCallback, useState } from 'react';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import {
  IShortDescription,
  IShortDescriptionCategory,
} from 'src/models/short-description.model';
import { getShortDescriptionStatus } from 'src/selectors/short-description.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import CategoryDeleteView from './category-delete.view';

type IProp = {
  data: IShortDescription & IShortDescriptionCategory;
  isDesc?: boolean;
  handleEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CategoryDelete: React.FC<IProp> = ({
  data,
  isDesc = false,
  handleEditOpen,
}) => {
  const { state, actions } = useShortDescriptionContext();
  const [isOpen, setIsOpen] = useState(false);

  const statusShortDesc = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_DELETE
  );

  const statusCategory = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (isDesc) {
      const isOnlyOne = state.listItems.find(
        (val) =>
          val.catId === data.catId &&
          val.descriptions.every((v) => v.descId === data.descId)
      );

      if (isOnlyOne) {
        setIsOpen(false);
        ToastError(Lang.MSG_SHORT_DESCRIPTION_REQUIRED);
      } else {
        const response = await actions.deleteShortDesc(data.descId);

        if (!response.error) {
          actions.listCategoriesAndDescGET();
          setIsOpen(false);
          if (handleEditOpen) {
            handleEditOpen(false);
          }

          ToastSuccess(Lang.MSG_SHORT_DESCRIPTION_CATEGORY_DELETE_SUCCESS);
        } else {
          ToastError(response.error.message);
        }
      }
    } else {
      const response = await actions.deleteShortDescCategory(data.catId);

      if (!response.error) {
        actions.listCategoriesAndDescGET();
        setIsOpen(false);
        if (handleEditOpen) {
          handleEditOpen(false);
        }

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_SHORT_DESCRIPTION_DELETE_SUCCESS,
            data.shortDesc
          )
        );
      } else {
        ToastError(response.error.message);
      }
    }
  }, [actions, data]);

  return (
    <CategoryDeleteView
      data={data}
      isOpen={isOpen}
      loading={statusShortDesc.fetching || statusCategory.fetching}
      handleOpen={setIsOpen}
      isDesc={isDesc}
      handleSubmit={handleSubmit}
    />
  );
};

export default CategoryDelete;
