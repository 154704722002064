import {
  tsoOssEmployeeActionTypes,
  ITsoOssEmployeeState,
} from 'src/ducks/tso-oss-employee.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getTsoOssEmployeeStatus = (
  state: ITsoOssEmployeeState,
  action: keyof typeof tsoOssEmployeeActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
