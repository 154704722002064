/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  ATMSegment,
  ATMHeader,
  ATMMenu,
  ATMGrid,
  ATMContainer,
  ORGDataTable,
  ATMIcon,
  IORGDataTableColumn,
  ATMButton,
  ATMLoader,
} from 'shared-it-appmod-ui';
import { Breadcrumb } from 'semantic-ui-react';
import history from 'src/history';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import { AccessRole, AuthListType, SuspensionStatus } from 'src/constants';
import Moment from 'src/libraries/moment.library';
import {
  IAuthorizedList,
  IAuthorizedListSuspensionForm,
} from 'src/models/authorized-list.model';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import {
  getTrainingSet,
  getNearestTrainingDate,
} from 'src/selectors/authorized-list.selector';
import { ISubCategory } from 'src/models/sub-category.model';
import { getUser } from 'src/libraries/amplify.library';
import { getIsAdmin } from 'src/libraries/access.library';
import AuthorizedListSuspension from '../authorized-list-suspension/authorized-list-suspension-list/authorized-list-suspension-list.component';
import AuthorizedListAdd from '../authorized-list-add/authorized-list-add.component';
import AuthorizedListEdit from '../authorized-list-edit/authorized-list-edit.component';
import AuthorizedListSuspensionLift from '../authorized-list-suspension/authorized-list-suspension-lift/authorized-list-suspension-lift.component';
import AuthorizedListSuspensionSuspend from '../authorized-list-suspension/authorized-list-suspension-suspend/authorized-list-suspension-suspended.component';
import TrainingHistoryList from '../training-history/training-history.component';
import styles from './auth-list-details.module.scss';

type IProp = {
  titleMainBreadcrumb: string;
  data: IAuthorizedList;
  loading: boolean;
  handleTabChange: (
    index?: any,
    callback?: ((data: URLSearchParams) => URLSearchParams) | undefined
  ) => void;
  subCategoryList: ISubCategory[];
  handleEmployeeStat: () => void;
  loadingEmployeeStat: boolean;
  subCategoryStatus: boolean;
  suspensionLoading?: boolean;
  breadcrumbPath?: string;
};

const AuthListDetailsView: React.FC<IProp> = ({
  data,
  suspensionLoading,
  subCategoryStatus,
  titleMainBreadcrumb,
  subCategoryList,
  handleEmployeeStat,
  loadingEmployeeStat,
  breadcrumbPath,
}) => {
  const [activeItem, setActiveItem] = useState('Details');
  const sections = [
    {
      key: 'authList',
      content: titleMainBreadcrumb,
      link: true,
      onClick: () => {
        if (breadcrumbPath) {
          history.push(breadcrumbPath);
        } else {
          history.goBack();
        }
      },
    },
    { key: 'empty', content: '', link: false },
  ];

  let trainingGroup;
  if (data && data.trainings && data.trainings.length > 0) {
    const authTraining = getTrainingSet(data.trainings, AuthListType.Training);
    const fieldSwitching = getTrainingSet(
      data.trainings,
      AuthListType.FieldSwitching
    );
    const substationSwitching = getTrainingSet(
      data.trainings,
      AuthListType.SubSwitching
    );
    const substationEntry = getTrainingSet(
      data.trainings,
      AuthListType.SubEntry
    );
    trainingGroup = [
      {
        authTraining: authTraining.filter(
          (item) =>
            item.trainingDate &&
            String(item.trainingDate) ===
              String(getNearestTrainingDate(authTraining))
        )[0],
        fieldSwitching: fieldSwitching.filter(
          (item) =>
            item.trainingDate &&
            String(item.trainingDate) ===
              String(getNearestTrainingDate(fieldSwitching))
        )[0],
        substationSwitching: substationSwitching.filter(
          (item) =>
            item.trainingDate &&
            String(item.trainingDate) ===
              String(getNearestTrainingDate(substationSwitching))
        )[0],
        substationEntry: substationEntry.filter(
          (item) =>
            item.trainingDate &&
            String(item.trainingDate) ===
              String(getNearestTrainingDate(substationEntry))
        )[0],
      },
    ];
  }
  const transformData = (trainingItem, trainingType) => {
    const suspensionDetail: IAuthorizedListSuspensionForm = {
      empId: data?.empId ?? '--',
      authTypId: trainingType ?? '--',
      comments: undefined,
      updatedAt: Moment().utc().toDate(),
      updatedBy: getUser()?.emp_no,
      authListId: trainingItem?.authListId ?? null,
      eventTime: Moment().utc().toDate(),
      event: trainingItem?.event ?? '--',
      suspEventId: trainingItem?.suspEventId ?? undefined,
    };
    return suspensionDetail;
  };
  const breadcrumbStatus = (
    status: string,
    trainingType: string,
    arrType: string
  ) => {
    if (!getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN)) {
      return null;
    }
    switch (status) {
      case 'Suspended':
        return (
          <Breadcrumb
            divider=" | "
            size="small"
            sections={[
              {
                key: 'modify_1',
                content: (
                  <AuthorizedListEdit
                    data={data}
                    entryView
                    entryText={trainingType}
                    subCategoryList={subCategoryList}
                    trainingData={trainingGroup && trainingGroup[0][arrType]}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
                link: true,
              },
              {
                key: 'add_1',
                content: (
                  <AuthorizedListAdd
                    key="AuthorizedListAdd_1"
                    addEntryLink
                    entryText={trainingType}
                    data={data}
                    subCategoryList={subCategoryList}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
                link: true,
              },
              {
                key: 'liftSuspension_1',
                content: (
                  <AuthorizedListSuspensionLift
                    data={transformData(
                      trainingGroup && trainingGroup[0][arrType],
                      trainingType
                    )}
                    empName={data?.fullName}
                    authId={
                      trainingGroup && trainingGroup[0][arrType]?.authListId
                    }
                  />
                ),
                link: true,
              },
            ]}
          />
        );
      case 'Authorized':
        return (
          <Breadcrumb
            divider=" | "
            size="small"
            sections={[
              {
                key: 'modify_2',
                link: true,
                content: (
                  <AuthorizedListEdit
                    data={data}
                    entryView
                    entryText={trainingType}
                    subCategoryList={subCategoryList}
                    trainingData={
                      trainingGroup &&
                      trainingGroup[0] &&
                      trainingGroup[0][arrType]
                    }
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
              },
              {
                key: 'add_2',
                link: true,
                content: (
                  <AuthorizedListAdd
                    key="AuthorizedListAdd_2"
                    addEntryLink
                    entryText={trainingType}
                    data={data}
                    subCategoryList={subCategoryList}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
              },
              {
                key: 'suspend_2',
                content: (
                  <AuthorizedListSuspensionSuspend
                    data={transformData(
                      trainingGroup && trainingGroup[0][arrType],
                      trainingType
                    )}
                    empName={data?.fullName}
                    authId={
                      trainingGroup && trainingGroup[0][arrType]?.authListId
                    }
                  />
                ),
                link: true,
              },
            ]}
          />
        );
      case 'Expired':
        return (
          <Breadcrumb
            divider=" | "
            size="small"
            sections={[
              {
                key: 'modify_3',
                content: (
                  <AuthorizedListEdit
                    data={data}
                    entryView
                    entryText={trainingType}
                    subCategoryList={subCategoryList}
                    trainingData={trainingGroup && trainingGroup[0][arrType]}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
                link: true,
              },
              {
                key: 'add_3',
                content: (
                  <AuthorizedListAdd
                    key="AuthorizedListAdd_3"
                    addEntryLink
                    entryText={trainingType}
                    data={data}
                    subCategoryList={subCategoryList}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
                link: true,
              },
            ]}
          />
        );
      case 'Not Authorized':
        return (
          <Breadcrumb
            divider=" | "
            size="small"
            sections={[
              {
                key: 'add_4',
                content: (
                  <AuthorizedListAdd
                    key="AuthorizedListAdd_4"
                    addEntryLink
                    entryText={trainingType}
                    data={data}
                    subCategoryList={subCategoryList}
                    subCategoryStatus={subCategoryStatus}
                  />
                ),
                link: true,
              },
            ]}
          />
        );
      default:
        return <span />;
    }
  };

  const columns: IORGDataTableColumn<any>[] = [
    {
      index: 'authTraining',
      title: Lang.LBL_AUTHORIZATION_TRAINING,
      width: '15%',
      render: (_, { authTraining }) => (
        <ATMGrid className={styles.expandedDetail}>
          <ATMGrid.Column className={`${styles.borderA}`}>
            <div className={styles.keyHistoryFlex}>
              <ATMIcon
                name="circle"
                color={
                  !authTraining || authTraining.testFailInd
                    ? 'pink'
                    : Moment(authTraining.expiryDate, 'YYYY-MM-DD').isBefore(
                        Moment().utc()
                      )
                    ? 'red'
                    : authTraining.suspendFlag
                    ? 'pink'
                    : 'green'
                }
                size="small"
                className={styles.sempraSmallMarginTop}
              />
              <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
                {!authTraining || authTraining.testFailInd
                  ? SuspensionStatus.NOT_AUTHORIZED
                  : Moment(authTraining.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                  ? SuspensionStatus.EXPIRED
                  : authTraining.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED}
              </ATMHeader>
            </div>
            {!authTraining ? (
              <div
                className={styles.expandContainer}
                style={{ textAlign: 'left' }}
              >
                <p>
                  {breadcrumbStatus(
                    !authTraining || authTraining.testFailInd
                      ? SuspensionStatus.NOT_AUTHORIZED
                      : Moment(authTraining?.expiryDate, 'YYYY-MM-DD').isBefore(
                          Moment().utc()
                        )
                      ? SuspensionStatus.EXPIRED
                      : authTraining?.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.Training,
                    'authTraining'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.expandContainer}>
                <p>
                  <span>{Lang.LBL_TRN_DATE}:</span>{' '}
                  {Moment(authTraining?.trainingDate).format('MM/DD/YYYY')}
                </p>
                <p>
                  <span>{Lang.LBL_TRN_EXPIRY}:</span>{' '}
                  {authTraining?.expiryDate &&
                  Moment(authTraining?.expiryDate, 'YYYY-MM-DD').format(
                    'YYYY'
                  ) !== '9999'
                    ? Moment(authTraining?.expiryDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY'
                      )
                    : '--'}
                </p>
                <p>
                  <span>{Lang.LBL_FIRM}:</span>{' '}
                  {authTraining?.contracShortNm ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TRAINER}:</span>{' '}
                  {authTraining?.trainerName ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_SUBCATEGORY}:</span>{' '}
                  {authTraining.subcategory?.description ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TEST_FAIL}:</span>{' '}
                  {authTraining.testFailInd ? 'Yes' : 'No'}
                </p>
                <p>
                  {breadcrumbStatus(
                    Moment(authTraining.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                      ? SuspensionStatus.EXPIRED
                      : authTraining.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.Training,
                    'authTraining'
                  )}
                </p>
              </div>
            )}
          </ATMGrid.Column>
        </ATMGrid>
      ),
    },
    {
      index: 'substationEntry',
      title: Lang.LBL_SUBSTATION_ENTRY,
      width: '15%',
      render: (_, { substationEntry }) => (
        <ATMGrid className={styles.expandedDetail}>
          <ATMGrid.Column className={`${styles.borderA}`}>
            <div className={styles.keyHistoryFlex}>
              <ATMIcon
                name="circle"
                color={
                  !substationEntry || substationEntry.testFailInd
                    ? 'pink'
                    : Moment(substationEntry.expiryDate, 'YYYY-MM-DD').isBefore(
                        Moment().utc()
                      )
                    ? 'red'
                    : substationEntry.suspendFlag
                    ? 'pink'
                    : 'green'
                }
                size="small"
                className={styles.sempraSmallMarginTop}
              />
              <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
                {!substationEntry || substationEntry.testFailInd
                  ? SuspensionStatus.NOT_AUTHORIZED
                  : Moment(substationEntry.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                  ? SuspensionStatus.EXPIRED
                  : substationEntry.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED}
              </ATMHeader>
            </div>
            {!substationEntry ? (
              <div
                className={styles.expandContainer}
                style={{ textAlign: 'left' }}
              >
                <p>
                  {breadcrumbStatus(
                    !substationEntry || substationEntry.testFailInd
                      ? SuspensionStatus.NOT_AUTHORIZED
                      : Moment(
                          substationEntry.expiryDate,
                          'YYYY-MM-DD'
                        ).isBefore(Moment().utc())
                      ? SuspensionStatus.EXPIRED
                      : substationEntry.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.SubEntry,
                    'substationEntry'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.expandContainer}>
                <p>
                  <span>{Lang.LBL_TRN_DATE}:</span>{' '}
                  {Moment(substationEntry.trainingDate).format('MM/DD/YYYY')}
                </p>
                <p>
                  <span>{Lang.LBL_TRN_EXPIRY}:</span>{' '}
                  {substationEntry.expiryDate &&
                  Moment(substationEntry?.expiryDate, 'YYYY-MM-DD').format(
                    'YYYY'
                  ) !== '9999'
                    ? Moment(substationEntry.expiryDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY'
                      )
                    : '--'}
                </p>
                <p>
                  <span>{Lang.LBL_FIRM}:</span>{' '}
                  {substationEntry.contracShortNm ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TRAINER}:</span>{' '}
                  {substationEntry.trainerName ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_SUBCATEGORY}:</span>{' '}
                  {substationEntry.subcategory?.description ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TEST_FAIL}:</span>{' '}
                  {substationEntry.testFailInd ? 'Yes' : 'No'}
                </p>
                <p>
                  {breadcrumbStatus(
                    Moment(substationEntry.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                      ? SuspensionStatus.EXPIRED
                      : substationEntry.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.SubEntry,
                    'substationEntry'
                  )}
                </p>
              </div>
            )}
          </ATMGrid.Column>
        </ATMGrid>
      ),
    },
    {
      index: 'substationSwitching',
      title: Lang.LBL_SUBSTATION_SWITCHING,
      width: '15%',
      render: (_, { substationSwitching }) => (
        <ATMGrid className={styles.expandedDetail}>
          <ATMGrid.Column className={`${styles.borderA}`}>
            <div className={styles.keyHistoryFlex}>
              <ATMIcon
                name="circle"
                color={
                  !substationSwitching || substationSwitching.testFailInd
                    ? 'pink'
                    : Moment(
                        substationSwitching.expiryDate,
                        'YYYY-MM-DD'
                      ).isBefore(Moment().utc())
                    ? 'red'
                    : substationSwitching.suspendFlag
                    ? 'pink'
                    : 'green'
                }
                size="small"
                className={styles.sempraSmallMarginTop}
              />
              <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
                {!substationSwitching || substationSwitching.testFailInd
                  ? SuspensionStatus.NOT_AUTHORIZED
                  : Moment(
                      substationSwitching.expiryDate,
                      'YYYY-MM-DD'
                    ).isBefore(Moment().utc())
                  ? SuspensionStatus.EXPIRED
                  : substationSwitching.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED}
              </ATMHeader>
            </div>
            {!substationSwitching ? (
              <div
                className={styles.expandContainer}
                style={{ textAlign: 'left' }}
              >
                <p>
                  {breadcrumbStatus(
                    !substationSwitching || substationSwitching.testFailInd
                      ? SuspensionStatus.NOT_AUTHORIZED
                      : Moment(
                          substationSwitching.expiryDate,
                          'YYYY-MM-DD'
                        ).isBefore(Moment().utc())
                      ? SuspensionStatus.EXPIRED
                      : substationSwitching.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.SubSwitching,
                    'substationSwitching'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.expandContainer}>
                <p>
                  <span>{Lang.LBL_TRN_DATE}:</span>{' '}
                  {Moment(substationSwitching.trainingDate).format(
                    'MM/DD/YYYY'
                  )}
                </p>
                <p>
                  <span>{Lang.LBL_TRN_EXPIRY}:</span>{' '}
                  {substationSwitching.expiryDate &&
                  Moment(substationSwitching?.expiryDate, 'YYYY-MM-DD').format(
                    'YYYY'
                  ) !== '9999'
                    ? Moment(
                        substationSwitching.expiryDate,
                        'YYYY-MM-DD'
                      ).format('MM/DD/YYYY')
                    : '--'}
                </p>
                <p>
                  <span>{Lang.LBL_FIRM}:</span>{' '}
                  {substationSwitching.contracShortNm ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TRAINER}:</span>{' '}
                  {substationSwitching.trainerName ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_SUBCATEGORY}:</span>{' '}
                  {substationSwitching.subcategory?.description ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TEST_FAIL}:</span>{' '}
                  {substationSwitching.testFailInd ? 'Yes' : 'No'}
                </p>
                <p>
                  {breadcrumbStatus(
                    Moment(
                      substationSwitching.expiryDate,
                      'YYYY-MM-DD'
                    ).isBefore(Moment().utc())
                      ? SuspensionStatus.EXPIRED
                      : substationSwitching.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.SubSwitching,
                    'substationSwitching'
                  )}
                </p>
              </div>
            )}
          </ATMGrid.Column>
        </ATMGrid>
      ),
    },
    {
      index: 'fieldSwitching',
      title: Lang.LBL_FIELD_LINE_SWITCHING,
      width: '15%',
      render: (_, { fieldSwitching }) => (
        <ATMGrid className={styles.expandedDetail}>
          <ATMGrid.Column className={`${styles.borderA}`}>
            <div className={styles.keyHistoryFlex}>
              <ATMIcon
                name="circle"
                color={
                  !fieldSwitching || fieldSwitching.testFailInd
                    ? 'pink'
                    : Moment(fieldSwitching.expiryDate, 'YYYY-MM-DD').isBefore(
                        Moment().utc()
                      )
                    ? 'red'
                    : fieldSwitching.suspendFlag
                    ? 'pink'
                    : 'green'
                }
                size="small"
                className={styles.sempraSmallMarginTop}
              />
              <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
                {!fieldSwitching || fieldSwitching.testFailInd
                  ? SuspensionStatus.NOT_AUTHORIZED
                  : Moment(fieldSwitching.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                  ? SuspensionStatus.EXPIRED
                  : fieldSwitching.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED}
              </ATMHeader>
            </div>
            {!fieldSwitching ? (
              <div
                className={styles.expandContainer}
                style={{ textAlign: 'left' }}
              >
                <p>
                  {breadcrumbStatus(
                    !fieldSwitching || fieldSwitching.testFailInd
                      ? SuspensionStatus.NOT_AUTHORIZED
                      : Moment(
                          fieldSwitching.expiryDate,
                          'YYYY-MM-DD'
                        ).isBefore(Moment().utc())
                      ? SuspensionStatus.EXPIRED
                      : fieldSwitching.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.FieldSwitching,
                    'fieldSwitching'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.expandContainer}>
                <p>
                  <span>{Lang.LBL_TRN_DATE}:</span>{' '}
                  {Moment(fieldSwitching.trainingDate).format('MM/DD/YYYY')}
                </p>
                <p>
                  <span>{Lang.LBL_TRN_EXPIRY}:</span>{' '}
                  {fieldSwitching.expiryDate &&
                  Moment(fieldSwitching?.expiryDate, 'YYYY-MM-DD').format(
                    'YYYY'
                  ) !== '9999'
                    ? Moment(fieldSwitching.expiryDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY'
                      )
                    : '--'}
                </p>
                <p>
                  <span>{Lang.LBL_FIRM}:</span>{' '}
                  {fieldSwitching.contracShortNm ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TRAINER}:</span>{' '}
                  {fieldSwitching.trainerName ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_SUBCATEGORY}:</span>{' '}
                  {fieldSwitching.subcategory?.description ?? '--'}
                </p>
                <p>
                  <span>{Lang.LBL_TEST_FAIL}:</span>{' '}
                  {fieldSwitching.testFailInd ? 'Yes' : 'No'}
                </p>
                <p>
                  {breadcrumbStatus(
                    Moment(fieldSwitching.expiryDate, 'YYYY-MM-DD').isBefore(
                      Moment().utc()
                    )
                      ? SuspensionStatus.EXPIRED
                      : fieldSwitching.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : SuspensionStatus.AUTHORIZED,
                    AuthListType.FieldSwitching,
                    'fieldSwitching'
                  )}
                </p>
              </div>
            )}
          </ATMGrid.Column>
        </ATMGrid>
      ),
    },
  ];
  return (
    <div>
      <ATMSegment style={{ paddingBottom: 0 }}>
        <div className={styles.headerBar}>
          <div className={styles.flex}>
            <div>
              <Breadcrumb icon="right angle" size="tiny" sections={sections} />
              <div className={styles.flex}>
                <ATMHeader className={styles.headerText} as="h2">
                  {`${data?.fullName} / ${data?.empId}`}
                </ATMHeader>
                {suspensionLoading ? (
                  <ATMLoader active size="small" inline />
                ) : data?.employeeStat && data.employeeStat === 'A' ? (
                  <ListStatus value={data.employeeStat} />
                ) : data?.employeeStat && data.tempActiveInd === 'Y' ? (
                  data?.empId && data.empId.slice(0, 2) === '99' ? (
                    <ListStatus value={data.employeeStat} />
                  ) : (
                    <ListStatus value="Active" />
                  )
                ) : (
                  getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (
                    <ATMButton
                      style={{ paddingBottom: 8 }}
                      content="Mark HR Status as Active"
                      secondary
                      size="small"
                      onClick={() => handleEmployeeStat()}
                      loading={loadingEmployeeStat}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttonBar}>
            {getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (
              <AuthorizedListAdd
                key="AuthorizedListAdd_5"
                data={data}
                subCategoryList={subCategoryList}
                subCategoryStatus={subCategoryStatus}
              />
            )}
          </div>
        </div>
        <div className={styles.recordsMenuContainer}>
          <ATMMenu pointing secondary className={styles.recordsMenu}>
            <ATMMenu.Item
              name="Details"
              active={activeItem === 'Details'}
              onClick={() => setActiveItem('Details')}
            />
            <ATMMenu.Item
              name="Trainings"
              active={activeItem === 'Trainings'}
              onClick={() => setActiveItem('Trainings')}
            />
            <ATMMenu.Item
              name="Suspension History"
              active={activeItem === 'Suspension History'}
              onClick={() => setActiveItem('Suspension History')}
            />
          </ATMMenu>
        </div>
      </ATMSegment>

      {activeItem === 'Details' && (
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column
              className={styles.noPaddingRight}
              computer={12}
              tab={12}
              mobile={16}
            >
              <ATMSegment basic className={styles.noPaddingAndMarginBottom}>
                <ATMContainer>
                  <ATMGrid className={styles.noPaddingAndMarginBottom}>
                    <ATMGrid.Row
                      className={styles.noPaddingAndMarginBottom}
                      columns={4}
                    >
                      <ATMGrid.Column>
                        <FieldReadOnly label={Lang.LBL_EMPLOYEE_CONTRACTOR}>
                          <div className={styles.flex}>
                            {data?.empId && data.empId.slice(0, 2) === '99' ? (
                              <p className={styles.fieldText}>
                                {Lang.LBL_CONTRACTOR}
                              </p>
                            ) : (
                              <p className={styles.fieldText}>
                                {Lang.LBL_EMPLOYEE}
                              </p>
                            )}
                            {suspensionLoading ? (
                              <ATMLoader active size="small" inline />
                            ) : data?.employeeStat &&
                              data.employeeStat === 'A' ? (
                              <ListStatus value={data.employeeStat} />
                            ) : data?.employeeStat &&
                              data.tempActiveInd === 'Y' ? (
                              data?.empId && data.empId.slice(0, 2) === '99' ? (
                                <ListStatus value={data?.employeeStat} />
                              ) : (
                                <ListStatus value="Active" />
                              )
                            ) : (
                              <ListStatus value={data?.employeeStat} />
                            )}
                          </div>
                        </FieldReadOnly>
                      </ATMGrid.Column>
                      <ATMGrid.Column>
                        <FieldReadOnly label={Lang.LBL_MANAGER}>
                          {`${data?.supervisor?.lastName ?? '--'}, ${
                            data?.supervisor?.firstName ?? '--'
                          }` ?? '--'}
                        </FieldReadOnly>
                      </ATMGrid.Column>
                      <ATMGrid.Column>
                        <FieldReadOnly label={Lang.LBL_COST_CENTER}>
                          {data?.ccCd ?? '--'}
                        </FieldReadOnly>
                      </ATMGrid.Column>
                      <ATMGrid.Column>
                        <FieldReadOnly label={Lang.LBL_FIRM_NAME}>
                          {data?.contracShortNm ?? '--'}
                        </FieldReadOnly>
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  </ATMGrid>
                </ATMContainer>
              </ATMSegment>
            </ATMGrid.Column>
          </ATMGrid.Row>

          <ATMGrid.Row className={styles.noMarginTop}>
            <ATMGrid.Column>
              <ATMSegment basic>
                <ATMHeader as="h3">
                  {Lang.LBL_CURRENT_AUTHORIZATION_STATUS}
                </ATMHeader>
                <ORGDataTable
                  loading={suspensionLoading}
                  data={trainingGroup ?? []}
                  columns={columns}
                  celled={false}
                  style={{ marginRight: '10px' }}
                />
              </ATMSegment>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      )}

      {activeItem === 'Trainings' && (
        <TrainingHistoryList
          data={data}
          trainingData={trainingGroup}
          subCategoryList={subCategoryList}
          subCategoryStatus={subCategoryStatus}
        />
      )}

      {activeItem === 'Suspension History' && (
        <AuthorizedListSuspension data={data} current={trainingGroup} />
      )}
    </div>
  );
};

export default AuthListDetailsView;
