import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import OutageTypeReducer, {
  IOutageTypeState,
  defaultState,
  duckActions,
} from 'src/ducks/outage-type.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    OutageTypeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IOutageTypeContext = ReturnType<typeof useReducer>;

const OutageTypeContext = createContext<Partial<IOutageTypeContext>>({
  state: defaultState,
}) as React.Context<IOutageTypeContext>;

type IProps = {
  state?: Partial<IOutageTypeState>;
};

const OutageTypeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <OutageTypeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </OutageTypeContext.Provider>
  );
};

const useOutageTypeContext = () => useContext(OutageTypeContext);

export type IUseOutageTypeContext = ReturnType<typeof useOutageTypeContext>;

export { OutageTypeContext, OutageTypeProvider, useOutageTypeContext };
