import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
// import { useSickListContext } from 'src/contexts/sick-list.context';
import Lang from 'src/libraries/language';
// import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import { ISickListForm } from 'src/models/sick-list.model';
// import { getSickListStatus } from 'src/selectors/sick-list.selector';
import SickListForm from '../sick-list-form/sick-list-form.component';
import style from '../sick-list.module.scss';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISickListForm) => void;
  sickTypes: any;
};

const SickListAddView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMButton
          secondary
          size="small"
          floated="right"
          content="Add"
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>Add Sick Entry</ATMModal.Header>
      <ATMModal.Content className={style.paddingBottom}>
        <SickListForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SUBMIT}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SickListAddView;
