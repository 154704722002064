import React, { useCallback, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMGrid,
  ATMHeader,
  ATMModal,
  ATMSegment,
  MOLReadonlyField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment, { formatDateTime } from 'src/libraries/moment.library';
import {
  ISystemChange,
  IsystemChangeCompleteForm,
} from 'src/models/system-change.model';
import { useRolesContext } from 'src/contexts/roles.context';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';
import { getSystemChangeStatus } from 'src/selectors/system-change.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import styles from './system-change-item.module.scss';

type IProps = {
  data: ISystemChange;
};

const SystemChangeItem: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean | null | undefined>(
    data.isComplete ?? false
  );

  const [completionSetBy, setCompletionSetBy] = useState<
    string | null | undefined
  >(data.completionSetBy);
  const [completedOn, setCompletedOn] = useState<Date | null | undefined>(
    data.completedOn
  );

  const { actions, state } = useSystemChangeContext();
  const systemChangeStatus = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_COMPLETE_UPDATE
  );

  const { state: rolesState } = useRolesContext();

  const handleSystemChangeCompletion = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
    },
    [setIsChecked]
  );

  const handleClick = useCallback(async () => {
    // const dateFilter =
    //   (getLocalStorageItem(LocalStorageId.DAILY_REPORT) as Date) || new Date();

    const sysChangeCompleteForm: IsystemChangeCompleteForm = {
      // dateFilter,
      completedOn: null, // just default this to null because backend will set this.
      completionSetBy: isChecked ? rolesState.data?.empId : null,
      isComplete: isChecked,
    };

    const result = await actions.dataPUT(data.requestId, sysChangeCompleteForm);
    if (result.error) {
      ToastError(
        'Failed to update system change completion. Please contact your administrator.'
      );
      setIsChecked(!isChecked);
    } else {
      ToastSuccess('Successfully updated system change completion.');
      setCompletionSetBy(result.payload?.completionSetBy);
      setCompletedOn(result.payload?.completedOn);
      setIsChecked(result.payload?.isComplete);
    }

    setIsOpen(false);
  }, [isChecked, setIsOpen, setCompletionSetBy, setCompletedOn, setIsChecked]);

  return (
    <div>
      <ATMHeader attached="top" className={styles.labelRight}>
        Grid Ops System Changes (LER {data.requestId})
      </ATMHeader>
      <ATMSegment attached>
        <ATMGrid columns={2}>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <MOLReadonlyField
                label="In Service Date"
                value={Moment(data.inServiceDate).format('ddd, MM/DD/YYYY')}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_FACILITY}
                value={`${data.outageFacility?.facility?.outgFacNm ?? '-'}${
                  data.outageFacility?.substation
                    ? ` @ ${data.outageFacility?.substation?.name}`
                    : ''
                }`}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <div className={styles.removeLabel}>
                <MOLReadonlyField
                  label=""
                  value={<strong>System Change</strong>}
                />
              </div>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_WORK_DESC}
                value={data.wrkDesc}
              />
            </ATMGrid.Column>
            <ATMGrid.Column />
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_COMMENTS}
                value={data.sysChngSummary?.noteTxt}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              {completedOn ? (
                <MOLReadonlyField
                  label="Date Systems Change Completed By"
                  value={
                    <>
                      <u>
                        &nbsp;&nbsp;&nbsp;{`${completionSetBy}`}
                        &nbsp;&nbsp;&nbsp;
                      </u>{' '}
                      in{' '}
                      <u>
                        &nbsp;&nbsp;&nbsp;{`${formatDateTime(completedOn)}`}
                        &nbsp;&nbsp;&nbsp;
                      </u>
                    </>
                  }
                />
              ) : (
                <MOLReadonlyField
                  label="Date Systems Change Completed By"
                  value="______________________________ on ____/____/________"
                />
              )}
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMModal
                open={isOpen}
                size="tiny"
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                trigger={
                  <ATMCheckbox
                    name="isComplete"
                    label={Lang.LBL_SYSTEM_CHANGE_COMPLETE}
                    checked={isChecked ?? false}
                    onChange={(__, { checked }) => {
                      handleSystemChangeCompletion(checked ?? false);
                      return checked;
                    }}
                  />
                }
              >
                <ATMModal.Header>
                  {isChecked
                    ? Lang.TTL_SYSTEM_CHANGE_COMPLETION_SET
                    : Lang.TTL_SYSTEM_CHANGE_COMPLETION_CLEAR}
                </ATMModal.Header>
                <ATMModal.Content>
                  {isChecked ? (
                    <p>
                      {Lang.formatString(
                        Lang.MSG_SYSTEM_CHANGE_CONFIRM_COMPLETION,
                        data.requestId
                      )}
                    </p>
                  ) : (
                    <p>
                      {Lang.formatString(
                        Lang.MSG_SYSTEM_CHANGE_CANCEL_COMPLETION,
                        data.requestId
                      )}
                    </p>
                  )}
                </ATMModal.Content>
                <ATMModal.Actions>
                  <ATMButton
                    secondary
                    content={Lang.LBL_CANCEL}
                    onClick={() => {
                      setIsOpen(false);
                      setIsChecked(!isChecked);
                    }}
                    type="button"
                  />
                  <ATMButton
                    type="button"
                    positive
                    content={Lang.LBL_YES}
                    onClick={handleClick}
                    loading={systemChangeStatus.fetching}
                  />
                </ATMModal.Actions>
              </ATMModal>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
    </div>
  );
};

export default SystemChangeItem;
