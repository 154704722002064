import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { SubstationSchema } from './substation.model';
import { UserMemberSchema } from './user-group.model';
import { VoltSchema } from './volt.model';

export const FacTypShape = {
  facTypId: yup.number(),
  facTypNm: yup.string(),
  facTypDesc: yup.string().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const IsoTrShape = {
  isoTrId: yup.string().required(),
  outgFacId: yup.number().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const IsoSwitchingsShape = {
  isoSwitchingId: yup.string().required(),
  outgFacId: yup.number().required(),
  defaultFlag: yup.boolean().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const EmsFacShape = {
  emsFacId: yup.string().required(),
  outgFacId: yup.number().required(),
  defaultFlag: yup.boolean().required(),
  top: yup.string().required(),
  station: yup.string().required(),
  busNm: yup.string().required(),
  equipmentNm: yup.string().required(),
  equipmentTyp: yup.number().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const SwitchingShape = {
  switchId: yup.number().required(),
  description: yup.string().required(),
  outgFacId: yup.number().required(),
  poleId: yup.string(),
  subDistId: yup.string(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const BlackstartShape = {
  blackstartId: yup.number().required(),
  blackstartValue: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const UserGrpShape = {
  approvalEmail: yup.array().of(yup.string()),
  notificationEmail: yup.array().of(yup.string()),
  userGrpId: yup.number().required(),
  emailType: yup.string(),
  authType: yup.array().of(yup.string()),
  userGrpNm: yup.string().required(),
  userGrpEmail: yup.string().nullable(),
  userGrpMembers: yup.array().of(UserMemberSchema).required().default([]),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const UserGrpFormShape = {
  userGrpId: yup.number(),
  userGrpNm: yup.string().required(),
  userGrpEmail: yup.string().nullable(),
  approvalEmail: yup.array().of(yup.string()),
  notificationEmail: yup.array().of(yup.string()),
};

export const FacTypeSchema = yup.object(FacTypShape);
export const IsoTrSchema = yup.object(IsoTrShape);
export const IsoSwitchingSchema = yup.object(IsoSwitchingsShape);
export const EmsFacSchema = yup.object(EmsFacShape);
export const BlackStartSchema = yup.object(BlackstartShape);
export const UserGrpSchema = yup.object(UserGrpShape);
export const UserGrpFormSchema = yup.object(UserGrpFormShape);
export const SwitchingSchema = yup.object(SwitchingShape);

export const FacilityMinShape = {
  outgFacId: yup.number().required(),
  outgFacNm: yup.string().required(),
  voltId: yup.number().required(),
  facTypId: yup.number().required(),
  facTblsCoord: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  besInd: yup.string().required(),
  endDt: yup.date().nullable(),
  isoFacId: yup.number().nullable(),
  stat: yup.string().required(),
  offSysInd: yup.string().required(),
  spclOpsConcern: yup.string().nullable(),
  inUse: yup.boolean().nullable(),
};

export const FacilityShape = {
  ...FacilityMinShape,
  isoTrs: yup.array(IsoTrSchema).required().default([]),
  isoSwitchings: yup.array(IsoSwitchingSchema).required().default([]),
  emsFacs: yup.array(EmsFacSchema).required().default([]),
  userGrps: yup.array(UserGrpSchema).required().default([]),
  switchings: yup.array(SwitchingSchema).required().default([]),
  blackstarts: yup.array(BlackStartSchema).required().default([]),
  substations: yup.array(SubstationSchema).required().default([]),
  volt: VoltSchema.nullable(),
  facTyp: FacTypeSchema.nullable(),
};

export const FacilitySchema = yup.object(FacilityShape);

export const FacilityFormSchema = yup.object({
  ...FacilityMinShape,
  outgFacId: yup.number(),
  isoSwitchings: yup
    .array()
    .of(
      yup.object({
        isoSwitchingId: yup.string().required(),
        defaultFlag: yup.boolean().required(),
      })
    )
    .nullable(),
  isoTrs: yup
    .array()
    .of(
      yup.object({
        isoTrId: yup.string().required(),
      })
    )
    .nullable(),
  emsFacs: yup
    .array()
    .of(
      yup.object({
        emsFacId: yup.string().required(),
        defaultFlag: yup.boolean().required(),
        top: yup.string().required(),
        station: yup.string().required(),
        busNm: yup.string().required(),
        equipmentNm: yup.string().required(),
        equipmentTyp: yup.number().required(),
      })
    )
    .nullable(),
  switchings: yup
    .array()
    .of(
      yup.object({
        description: yup.string().required(),
        poleId: yup.string(),
        subDistId: yup.string(),
      })
    )
    .nullable(),
  userGrps: yup.array().of(yup.object(UserGrpFormShape)).nullable(),
  substationIds: yup.array().of(yup.string()).nullable(),
  blackstartIds: yup.array().of(yup.number()).nullable(),
});

export const FacilityFormValidationSchema = yup.object({
  ...FacilityMinShape,
  outgFacNm: yup
    .string()
    .required()
    .test('outgFacNm', Lang.MSG_FACILITY_NAME_ERROR_LENGTH, (value) => {
      if (value === undefined) {
        return true;
      }
      return value?.length >= 5;
    }),
  outgFacId: yup.number(),
  isoSwitchings: yup
    .array()
    .of(
      yup.object({
        isoSwitchingId: yup.string().required(),
        defaultFlag: yup.boolean().required(),
      })
    )
    .nullable(),
  isoTrs: yup
    .array()
    .of(
      yup.object({
        isoTrId: yup.string().required(),
      })
    )
    .nullable(),
  emsFacs: yup
    .array()
    .of(
      yup.object({
        emsFacId: yup.string().required(),
        defaultFlag: yup.boolean().required(),
        top: yup.string().required(),
        station: yup.string().required(),
        busNm: yup.string().required(),
        equipmentNm: yup.string().required(),
        equipmentTyp: yup.number().required(),
      })
    )
    .nullable(),
  switchings: yup
    .array()
    .of(
      yup.object({
        description: yup.string().required(),
        poleId: yup.string(),
        subDistId: yup.string(),
      })
    )
    .nullable(),
  userGrps: yup.array().of(yup.object(UserGrpFormShape)).nullable(),
  substationIds: yup.array().of(yup.string()).nullable(),
  blackstartIds: yup.array().of(yup.number()).nullable(),
  blackstartId: yup.number().nullable(),
});

export const FacilityListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(FacilitySchema.clone()),
});

export const FacilityDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const FacilityFilterOptionsShape = {
  substations: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required(),
        substationId: yup.string().required(),
      })
    )
    .required(),
  volts: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required(),
        voltId: yup.number().required(),
      })
    )
    .required(),
  facTyps: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required(),
        facTypId: yup.number().required(),
      })
    )
    .required(),
  blackstarts: yup.array().of(
    yup.object({
      blackstartId: yup.number().required(),
      blackstartValue: yup.string().required(),
    })
  ),
  stats: yup.array().of(yup.string().required()).required(),
};

export const FacilityFilterSchema = yup.object(FacilityFilterOptionsShape);

export const FacilityVoltShape = {
  name: yup.string().required(),
  voltId: yup.number().required(),
};

export const FacilityVoltSchema = yup.object(FacilityVoltShape);
export const FacilityVoltListPayloadSchema = yup
  .array()
  .of(FacilityVoltSchema)
  .defined();

export const FacilityEquipmentSchema = yup.object({
  outgFacNm: yup.string().required(),
  outgFacId: yup.number().required(),
  spclOpsConcern: yup.string().nullable(),
});

export const FacilityEquipmentPayloadSchema = yup
  .array()
  .of(FacilityEquipmentSchema);

export type IFacility = yup.Asserts<typeof FacilitySchema>;
export type IFacilityForm = yup.Asserts<typeof FacilityFormSchema>;
export type IVolt = yup.Asserts<typeof VoltSchema>;
export type IFacType = yup.Asserts<typeof FacTypeSchema>;
export type IIsoTr = yup.Asserts<typeof IsoTrSchema>;
export type ISwitchTr = yup.Asserts<typeof IsoSwitchingSchema>;
export type IEmsFac = yup.Asserts<typeof EmsFacSchema>;
export type ISwitching = yup.Asserts<typeof SwitchingSchema>;
export type IUserGrp = yup.Asserts<typeof UserGrpSchema>;
export type IUserGrpForm = yup.Asserts<typeof UserGrpFormSchema>;
export type IBlackStart = yup.Asserts<typeof BlackStartSchema>;
export type IFacilityFilter = yup.Asserts<typeof FacilityFilterSchema>;
export type IFacilityVolt = yup.Asserts<typeof FacilityVoltSchema>;
export type IFacilityEquipment = yup.Asserts<typeof FacilityEquipmentSchema>;
export type IFacilityFormValidation = yup.Asserts<
  typeof FacilityFormValidationSchema
>;
