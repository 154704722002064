import React, { useEffect } from 'react';
import { ATMIcon, ATMLabel, ATMPopover } from 'shared-it-appmod-ui';
import { AccessRole } from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import styles from './queue-summary.module.scss';

const QueueSummary: React.FC = () => {
  const { state, actions } = useLerRequestContext();

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_COUNT_FAILED_SUBMITTAL_LIST_READ
  );

  useEffect(() => {
    actions.countFailedSubmittalGET();
  }, [actions]);

  return hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
    <ATMPopover
      basic
      hoverable
      trigger={
        <div>
          <ATMLabel color="orange" key="info1">
            <ATMIcon name="align justify" />
            {state.countFailedList.transmissionOutage.basicQueueMsgCount}
          </ATMLabel>
          <ATMLabel color="orange" key="info2">
            <ATMIcon name="exclamation triangle" />
            {state.countFailedList.transmissionOutage.deadQueueMsgCount}
          </ATMLabel>
          <ATMLabel color="yellow" key="info3">
            <ATMIcon name="exchange" />
            {state.countFailedList.switchingRequest.basicQueueMsgCount}
          </ATMLabel>
          <ATMLabel color="yellow" key="info4">
            <ATMIcon name="exclamation triangle" />
            {state.countFailedList.switchingRequest.deadQueueMsgCount}
          </ATMLabel>
        </div>
      }
    >
      <ATMPopover.Header>
        Queue Summary{' '}
        <ATMIcon
          loading={status.fetching}
          name="refresh"
          onClick={() => actions.countFailedSubmittalGET()}
        />
      </ATMPopover.Header>
      <ATMPopover.Content>
        <ul className={styles.list}>
          <li>
            <label>{Lang.LBL_CAISO_PENDING_SUBMITTAL}</label>
            {state.countFailedList.transmissionOutage.basicQueueMsgCount}
          </li>
          <li>
            <label>{Lang.LBL_CAISO_FAILED_SUBMITTAL}</label>
            {state.countFailedList.transmissionOutage.deadQueueMsgCount}
          </li>
          <li>
            <label>{Lang.LBL_SORT_PENDING_SUBMITTAL}</label>
            {state.countFailedList.switchingRequest.basicQueueMsgCount}
          </li>
          <li>
            <label>{Lang.LBL_SORT_FAILED_SUBMITTAL}</label>
            {state.countFailedList.switchingRequest.deadQueueMsgCount}
          </li>
        </ul>
      </ATMPopover.Content>
    </ATMPopover>
  ) : null;
};

export default QueueSummary;
