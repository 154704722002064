import React, { useCallback, useState } from 'react';
import {
  ATMCheckbox,
  ATMHeader,
  ATMIcon,
  IORGDataTableColumn,
  MOLInfoText,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import { LERRequestFormStep } from 'src/constants';
import Lang from 'src/libraries/language';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { ILerRequest } from 'src/models/ler-request.model';
import { ISortResponse } from 'src/models/switching.model';
import styles from '../../ler-request.module.scss';
import {
  SortStatus,
  isSortStatusComplete,
} from '../ler-request-switching-form/ler-request-switching-form.component';

type IProps = {
  // Change to Switching Interface once ready.
  data: ILerRequest;
};

const LERRequestSwitchingDetail: React.FC<IProps> = ({ data }) => {
  const [showComplete, setShowComplete] = useState(false);
  const handleSwitching = useCallback(
    (showAll: boolean) => {
      if (showAll) {
        setShowComplete(showAll);
      } else {
        setShowComplete(false);
      }
    },
    [showComplete, setShowComplete]
  );

  if (!data) {
    return null;
  }

  const columns: IORGDataTableColumn<ISortResponse>[] = [
    {
      title: Lang.LBL_DATE,
      index: 'sortTime',
      render: (value) => formatDate(value),
    },
    {
      title: Lang.LBL_TIME,
      index: 'outBack',
      render: (value, values) => (
        <div>
          <span>{value === 'OUT' ? `${value}  AT` : `${value} AT`}</span>{' '}
          {format24hTime(values.sortTime)}
        </div>
      ),
    },
    {
      title: Lang.LBL_SUB_POLE,
      index: 'tlSub',
    },
    {
      title: Lang.LBL_TL_SUB,
      index: 'subPole',
    },
    {
      title: Lang.LBL_AREA,
      index: 'subDistId',
    },
    {
      title: Lang.LBL_REMARKS,
      index: 'dspchRem',
      // render: (value) => <div className="cellText">{value}</div>,
      render: (value) => (
        <MOLInfoText transparent limit={5} content={value || ''} />
      ),
    },
    {
      title: Lang.LBL_ORDER_NUM,
      index: 'sortId',
    },
    {
      title: Lang.LBL_STATUS,
      index: 'sortStat',
      render: (_, values, _index) => values?.sortStat?.toUpperCase(),
    },
  ];

  const list = data[LERRequestFormStep.SWITCHING] ?? [];
  const checkSortStatus = () => {
    const sortStatValues = Object.values(SortStatus);
    const withCompleteStatus = list.filter((v) =>
      sortStatValues
        ?.toString()
        .toUpperCase()
        .includes(v.sortStat?.toUpperCase() as unknown as SortStatus)
    );
    return withCompleteStatus.length > 0;
  };
  return (
    <div>
      <span>
        <ATMIcon circular name="random" />
      </span>
      <ATMHeader
        as="h1"
        content={Lang.TTL_SWITCHING}
        className={styles.headerDoc}
      />
      <ATMHeader as="h2" content={Lang.TTL_ETS_DISPATCH} />
      <ORGDataTable
        columns={columns}
        data={
          showComplete
            ? list
            : list?.filter(
                (val) => !isSortStatusComplete(val?.sortStat as string)
              )
        }
        counter
        rowsPerPageOptions={[20]}
        toolbars={[
          () => {
            if (list && list.length && checkSortStatus()) {
              return (
                <ATMCheckbox
                  label={Lang.LBL_SHOW_COMPLETED}
                  toggle
                  style={{ float: 'right', marginBottom: '-.2em' }}
                  onChange={(__, { checked }) => {
                    handleSwitching(checked ?? false);
                  }}
                />
              );
            }
            return '';
          },
        ]}
      />
    </div>
  );
};

export default LERRequestSwitchingDetail;
