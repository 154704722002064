import { httpClient } from 'src/libraries/http.library';
import {
  RejectedOverviewReportListPayloadSchema,
  RejectedSummaryReportSchema,
} from 'src/models/rejected-report.model';

const client = httpClient();
const endpoint = 'outage-request';

const services = {
  overviewListGET: async (data?: Record<string, any>) => {
    const params: Record<string, any> = {
      ...data,
      action: 'reportReject',
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(
      `/${endpoint}`,
      params,
      RejectedOverviewReportListPayloadSchema
    );
  },
  summaryListGET: async (data?: Record<string, any>) => {
    const params: Record<string, any> = {
      ...data,
      action: 'reportRejectSummary',
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, RejectedSummaryReportSchema);
  },
};

export default services;
