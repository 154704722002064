import React, { useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
  ATMModal,
  ATMButton,
} from 'shared-it-appmod-ui';
import { ISubGroup, IWorkGroup } from 'src/models/work-group.model';
import Lang from 'src/libraries/language';
import { AccessRole } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import WorkGroupDelete from '../work-group-delete/work-group-delete.component';
import WorkGroupEdit from '../work-group-edit/work-group-edit.component';

const columnsEmails: IORGDataTableColumn<Array<string>>[] = [
  {
    index: 'mail',
    title: Lang.LBL_EMAILS,
  },
];

const MailDistributionModal = ({ email, grpName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mail] = useState(
    email.map((val, i) => {
      return {
        mail: val,
        index: i,
      };
    })
  );

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <u>
          {' '}
          <a
            style={{ color: '#5D5D5D' }}
            role="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >{`+${email.length - 2}`}</a>{' '}
        </u>
      }
    >
      <ATMModal.Header>
        {grpName} - {Lang.LBL_EMAIL_DISTRIBUTION}
      </ATMModal.Header>
      <ATMModal.Content>
        <ORGDataTable columns={columnsEmails} data={mail} showPagination />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          primary
          content="Close"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

const columnsSubGrp: IORGDataTableColumn<ISubGroup>[] = [
  {
    index: 'subGrpNm',
    title: Lang.LBL_WORK_GROUP_SUBGROUP_NAME,
  },
];

const SubGroupModal = ({ subGrp, grpName }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <u>
          {' '}
          <a
            style={{ color: '#5D5D5D' }}
            role="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >{`+${subGrp.length - 1}`}</a>{' '}
        </u>
      }
    >
      <ATMModal.Header>
        {grpName} - {Lang.LBL_SUB_GROUP}
      </ATMModal.Header>
      <ATMModal.Content>
        <ORGDataTable columns={columnsSubGrp} data={subGrp} showPagination />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          primary
          content="Close"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

const columns: IORGDataTableColumn<IWorkGroup>[] = [
  {
    index: 'trbGrpNm',
    title: Lang.LBL_GROUP_NAME,
  },
  {
    index: 'emailDistribution',
    title: Lang.LBL_WORK_GROUP_MAIL,
    render: (_, { emailDistribution, trbGrpNm }) => {
      if (!emailDistribution?.length) {
        return String.fromCharCode(8211);
      }

      if (emailDistribution.length > 2) {
        return (
          <span>
            {emailDistribution && emailDistribution[0]}; {emailDistribution[1]};{' '}
            <MailDistributionModal
              email={emailDistribution}
              grpName={trbGrpNm}
            />
          </span>
        );
      }

      return (
        <span>
          {emailDistribution && emailDistribution[0]};
          {emailDistribution[1] && `; ${emailDistribution[1]}`}
        </span>
      );
    },
  },
  {
    index: 'subGrps',
    sortable: false,
    title: Lang.LBL_WORK_GROUP_SUBGROUPS,
    render: (_, { subGrp, trbGrpNm }) => {
      if (!subGrp?.length) {
        return String.fromCharCode(8211);
      }

      if (subGrp.length > 1) {
        return (
          <span>
            {subGrp && subGrp[0].subGrpNm},{' '}
            <u>
              {' '}
              <SubGroupModal grpName={trbGrpNm} subGrp={subGrp} />
            </u>
          </span>
        );
      }

      return subGrp && subGrp[0].subGrpNm;
    },
  },
  {
    index: 'action',
    sortable: false,
    title: Lang.LBL_ACTION,
    width: '120px',
    headerProps: {
      textAlign: 'left',
    },
    cellProps: {
      textAlign: 'left',
    },
    render: (_, value) => getIsAdmin(AccessRole.TROUBLE_TICKET_REQUESTOR) && (
      <>
        <ATMDropdown
          pointing
          size="small"
          key=""
          icon="ellipsis horizontal"
          className="link item right top"
        >
          <ATMDropdown.Menu>
            <WorkGroupEdit data={value} />
            <WorkGroupDelete data={value} />
          </ATMDropdown.Menu>
        </ATMDropdown>
      </>
    ),
  },
];

type IProps = {
  data: IWorkGroup[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
};

const WorkGroupListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
}) => (
  <div className="admin-content">
    <ORGDataTable
      columns={columns}
      data={data}
      onChange={handleFetch}
      loading={loading}
      counter
      celled={false}
      sortable
      rowsPerPageOptions={[50, 100]}
      defaultRowsPerPage={50}
    />
  </div>
);

export default WorkGroupListView;
