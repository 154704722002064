import { httpClient } from 'src/libraries/http.library';
import {
  IOutageTypeForm,
  OutageTypeListPayloadSchema,
  OutageTypeDeletePayloadSchema,
  OutageTypePayloadSchema,
} from 'src/models/outage-type.model';

const client = httpClient();
const endpoint = 'outage-type';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, OutageTypePayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, OutageTypeListPayloadSchema);
  },

  activeListGET: async () => {
    return client.get(`/${endpoint}`, {}, OutageTypeListPayloadSchema);
  },

  createPOST: async (data: IOutageTypeForm) => {
    return client.post(`/${endpoint}`, data, OutageTypePayloadSchema);
  },

  updatePUT: async (id: number, data: IOutageTypeForm) => {
    return client.put(`/${endpoint}/${id}`, data, OutageTypePayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, OutageTypeDeletePayloadSchema);
  },
};

export default services;
