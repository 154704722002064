import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import { checkUndefinedOrNullValue } from 'src/helpers/key-request.helper';
import Lang from 'src/libraries/language';
import { IKeyRequest, IKeyRequestForm } from 'src/models/key-request.model';
import KeyRequestDelete from '../key-request-delete/key-request-delete.component';
import KeyRequestForm from '../key-request-form/key-request-form.component';
import style from './key-request-edit.module.scss';

type IProps = {
  defaultValues: IKeyRequest;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IKeyRequestForm) => void;
};

const KeyRequestEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMButton
          className={style.actionBtn}
          content={Lang.LBL_TAKE_ACTION}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>
        {Lang.TTL_KEY_REQUESTS_EDIT}
        {` ${checkUndefinedOrNullValue(
          `${defaultValues.emp.lastName}, ${defaultValues.emp.firstName}`,
          Lang.LBL_UNAVAILABLE
        )}${
          defaultValues?.empId.length
            ? checkUndefinedOrNullValue(
                `/${formatToRawEmployeeId(defaultValues?.empId)}`,
                ''
              )
            : ''
        }`}
      </ATMModal.Header>
      <ATMModal.Content>
        <KeyRequestForm
          defaultValues={defaultValues}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <KeyRequestDelete handleOpen={handleOpen} data={defaultValues} />
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_APPROVE_SAVE}
          disabled={!isDirty || loading}
          loading={loading}
          onClick={handleClick}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default KeyRequestEditView;
