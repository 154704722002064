import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import EntryReasonReducer, {
  IEntryReasonState,
  defaultState,
  duckActions,
} from 'src/ducks/entry-reason.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    EntryReasonReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IEntryReasonContext = ReturnType<typeof useReducer>;

const EntryReasonContext = createContext<Partial<IEntryReasonContext>>({
  state: defaultState,
}) as React.Context<IEntryReasonContext>;

type IProps = {
  state?: Partial<IEntryReasonState>;
};

const EntryReasonProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <EntryReasonContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </EntryReasonContext.Provider>
  );
};

const useEntryReasonContext = () => useContext(EntryReasonContext);

export type IUseEntryReasonContext = ReturnType<typeof useEntryReasonContext>;

export { EntryReasonContext, EntryReasonProvider, useEntryReasonContext };
