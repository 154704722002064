import React from 'react';
import { ATMPopover } from 'shared-it-appmod-ui';

type IProps = {
  list?: (string | undefined)[];
  content: React.ReactNode;
};

const PopoverMore: React.FC<IProps> = ({ list = [], content }) => {
  if (!list.length) {
    return null;
  }

  if (list.length > 1) {
    return (
      <span>
        {`${list[0]}...`}{' '}
        <ATMPopover
          on="click"
          content={content}
          pinned
          trigger={<a role="button">{`+${list.length - 1} more`}</a>}
        />
      </span>
    );
  }

  return <span>{list[0]}</span>;
};

export default PopoverMore;
