import React, { useState } from 'react';
import { ATMDivider, ATMPopover } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { AuthType, SuspensionStatus } from 'src/constants';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { getEmployeeName } from 'src/helpers/employee.helper';
import SubstationEntryEmployeeInfo from '../substation-entry-log-employee-info/substation-entry-log-employee-info.component';
import style from './substation-entry-log-employee-card.module.scss';

type IProps = {
  substationEntry: ISubstationEntry;
};

const SubstationEntryEmployeeCard: React.FC<IProps> = ({ substationEntry }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEmpOpen, setIsEmpOpen] = useState(false);

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeOnDocumentClick={!isEmpOpen}
        trigger={
          <a
            className={style.popLink}
            role="button"
            id="employee"
            onClick={() => setIsOpen(true)}
          >
            {`${getEmployeeName(substationEntry?.employee)}`}
          </a>
        }
        position="right center"
        on="click"
      >
        <ATMPopover.Header>
          <span className={style.overlayHeader}>
            {`${getEmployeeName(substationEntry?.employee)}`}
          </span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          <>
            <div className={style.overlayContent}>
              <FieldReadOnly label="Phone Number">
                {substationEntry?.employee?.cellPhoneNumber}
              </FieldReadOnly>
              <FieldReadOnly label="Authorization Training">
                {formatDate(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.AUTH_TRAINING
                  )[0]?.expiryDate
                ) ?? '--'}
                {Moment(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.AUTH_TRAINING
                  )[0]?.expiryDate,
                  'YYYY-MM-DD'
                ).isBefore(Moment().utc()) && (
                  <span
                    style={{ color: 'red' }}
                  >{`- (${SuspensionStatus.EXPIRED})`}</span>
                )}
              </FieldReadOnly>
              <FieldReadOnly label="Field/Line Switching">
                {formatDate(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.FIELD_SWITCHING
                  )[0]?.expiryDate
                ) ?? '--'}
                {Moment(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.FIELD_SWITCHING
                  )[0]?.expiryDate,
                  'YYYY-MM-DD'
                ).isBefore(Moment().utc()) && (
                  <span
                    style={{ color: 'red' }}
                  >{`- (${SuspensionStatus.EXPIRED})`}</span>
                )}
              </FieldReadOnly>
              <FieldReadOnly label="Substation Switching">
                {formatDate(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.SUB_SWITCHING
                  )[0]?.expiryDate
                ) ?? '--'}
                {Moment(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.SUB_SWITCHING
                  )[0]?.expiryDate,
                  'YYYY-MM-DD'
                ).isBefore(Moment().utc()) && (
                  <span
                    style={{ color: 'red' }}
                  >{`- (${SuspensionStatus.EXPIRED})`}</span>
                )}
              </FieldReadOnly>
              <FieldReadOnly label="Substation Entry">
                {formatDate(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.SUB_ENTRY
                  )[0]?.expiryDate
                ) ?? '--'}
                {Moment(
                  substationEntry?.employee?.trainings?.filter(
                    (item) => item.authTypId === AuthType.SUB_ENTRY
                  )[0]?.expiryDate,
                  'YYYY-MM-DD'
                ).isBefore(Moment().utc()) && (
                  <span
                    style={{ color: 'red' }}
                  >{`- (${SuspensionStatus.EXPIRED})`}</span>
                )}
              </FieldReadOnly>
            </div>
            <div className={style.actionButtons}>
              <SubstationEntryEmployeeInfo
                substationEntry={substationEntry}
                isEmpOpen={isEmpOpen}
                setIsEmpOpen={setIsEmpOpen}
              />
            </div>
          </>
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default SubstationEntryEmployeeCard;
