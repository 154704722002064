import {
  lerRequestActionTypes,
  ILerRequestState,
} from 'src/ducks/ler-request.duck';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import { ILerRequestOutageDate } from 'src/models/ler-request.model';

export const getLerRequestStatus = (
  state: Partial<ILerRequestState>,
  action: keyof typeof lerRequestActionTypes
): IStatus =>
  (state.status && state.status[action]) || {
    fetching: false,
    error: null,
  };

export const getLerRequestStartDate = (dates: ILerRequestOutageDate[]) =>
  formatDate(Moment.min(dates.map((v) => Moment(v.startTm))));

export const getLerRequestEndDate = (dates: ILerRequestOutageDate[]) =>
  formatDate(Moment.max(dates.map((v) => Moment(v.stopTm))));

export const compareValues = (
  value: Array<string | number | null | undefined>
) =>
  value.every((element) => element === null) ||
  value.every((element) => element === undefined)
    ? true
    : value[0] === value[1];

export const getKeysObject = (data: any): Array<string> => {
  const list = Object.keys(data);
  list.forEach((val) => {
    // && !Array.isArray(data[val])
    if (typeof data[val] === 'object') {
      const newList = Object.keys(data[val]);
      newList.forEach((v) => list.push(`${val}.${v}`));
    }
    // if (Object.prototype.toString.call(data[val]) === '[object Object]') {
    //   const newList = Object.keys(data[val]);
    //   newList.forEach((v) => list.push((`${val}.${v}`)));
    // } else if (Object.prototype.toString.call(data[val]) === '[object Array]') {
    //     if (data[val].length > 0) {
    //     const newList = Object.keys(data[val][0]);
    //       newList.forEach((v) => list.push((`${val}.${v}`)));
    //     }
    // }
  });
  return list;
};
