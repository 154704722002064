import React, { useCallback, useRef, useState } from 'react';
import { useSickListContext } from 'src/contexts/sick-list.context';
import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import { ISickList, ISickListForm } from 'src/models/sick-list.model';
import { getSickListStatus } from 'src/selectors/sick-list.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import SickListEditView from './sick-list-edit.view';

type IProp = {
  data: ISickList;
};

const SickListEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useSickListContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getSickListStatus(
    state,
    sickListActionTypes.SICK_LIST_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ISickListForm) => {
      const response = await actions.updatePUT(data.sickEmpEntryId, {
        ...formData,
      });

      if (!response.error) {
        actions.listGET();
        setIsEditOpen(false);

        ToastSuccess(`Sick Entry Edited Successfully for Employee ${data.empId}`);
      } else {
        ToastError(response.error?.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <SickListEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default SickListEdit;
