import React, { useCallback, useRef, useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ISubstationCountListFormArray } from 'src/models/substation-count-list.model';
import SubstationCountForm from '../substation-count-form/substation-count-form.component';

type IProps = {
  defaultValues: ISubstationCountListFormArray;
  isOpen: boolean;
  loading: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISubstationCountListFormArray) => void;
  year: number;
  substations: string[];
};

const SubstationCountView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  handleOpen,
  handleSubmit,
  year,
  substations,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={<ATMButton icon="pencil" onClick={() => handleOpen(true)} />}
      style={{ width: '600px' }}
    >
      <ATMModal.Header>
        {Lang.formatString(Lang.TTL_SUBSTATION_COUNT_EDIT, year)}
      </ATMModal.Header>
      <ATMModal.Content scrolling>
        <SubstationCountForm
          formRef={formRef}
          defaultValues={defaultValues}
          handleEnable={(value) => setIsDirty(value)}
          handleSubmit={handleSubmit}
          year={year}
          substations={substations}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SAVE}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SubstationCountView;
