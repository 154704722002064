/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMDropdown,
  ATMTextArea,
  ATMInput,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import Lang from 'src/libraries/language';
import { ITroubleEquipmentType } from 'src/models/trouble-equipment-type.model';
import {
  ITroubleTicketForm,
  ITroubleTicketList,
  TroubleTicketFormSchema,
} from 'src/models/trouble-ticket.model';
import { IWorkGroup } from 'src/models/work-group.model';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ITroubleTicketList>;
  equipment: ITroubleEquipmentType[];
  workGroup: IWorkGroup[];
  handleSubmit: (data: ITroubleTicketForm) => void;
  handleEnable: (value: boolean) => void;
};

const ForwardTicketForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  equipment,
  workGroup,
  handleSubmit,
  handleEnable,
}) => {
  const [isFull, setIsFull] = useState(false);

  const handleChange = (bool: boolean) => {
    if (bool) {
      setIsFull(bool);
    }
  };

  const { state: workGroupState } = useWorkGroupContext();
  const [workGroupValue, setWorkGroupValue] = useState(0);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    defaultValues && defaultValues.troubleGroupId
      ? setWorkGroupValue(defaultValues.troubleGroupId)
      : undefined;
  }, []);

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      defaultValues={TroubleTicketFormSchema.cast({
        ...defaultValues,
        commentsResolution: '',
        ticketStatus: 'Open',
        activatedBy: null,
      })}
      mode="onChange"
      validationSchema={TroubleTicketFormSchema}
    >
      {({ control, formState: { errors }, getValues }) => {
        handleEnable(isFull);

        return (
          <ATMGrid>
            {/* hidden form */}
            <ATMField
              name="troubleTicketId"
              control={control}
              defaultValue={defaultValues?.troubleTicketId}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMGrid.Row columns="2">
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_LINE}>
                  {defaultValues
                    ? defaultValues.substationId && defaultValues.substation
                      ? defaultValues.substation.name
                      : defaultValues.outgFacId && defaultValues.line
                      ? defaultValues.line.outgFacNm
                      : undefined
                    : undefined}
                </FieldReadOnly>
                {defaultValues ? (
                  defaultValues.substationId && defaultValues.substation ? (
                    <ATMField
                      name="substationId"
                      control={control}
                      defaultValue={defaultValues.substationId}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                    />
                  ) : defaultValues.outgFacId && defaultValues.line ? (
                    <ATMField
                      name="outgFacId"
                      control={control}
                      defaultValue={defaultValues.outgFacId}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                    />
                  ) : undefined
                ) : undefined}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="troubleEquipmentTypeId"
                  defaultValue={defaultValues?.troubleEquipmentTypeId}
                  label={<LabelRequired>Equipment Type</LabelRequired>}
                  error={errors.troubleEquipmentTypeId}
                  selection
                  fluid
                  placeholder="Select"
                  options={equipment.map((value) => {
                    return { value: value.id, text: value.name, key: value.id };
                  })}
                  onChange={([_, { value }]) => {
                    // handleChange(
                    //   Object.values(getValues()).every((el) => el !== undefined)
                    // );
                    return value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="2">
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="troubleGroupId"
                  label={<LabelRequired>Forward To</LabelRequired>}
                  error={errors.troubleGroupId}
                  selection
                  fluid
                  placeholder="Select"
                  options={workGroup
                    .filter((elemt) => elemt.trbGrpNm.slice(0, 1) !== 'z')
                    .map((value) => {
                      return {
                        value: value.trbGrpId,
                        text: value.trbGrpNm,
                        key: value.trbGrpId,
                      };
                    })}
                  onChange={([_, { value }]) => {
                    handleChange(
                      Object.values(getValues()).every((el) => el !== undefined)
                    );
                    setWorkGroupValue(value);
                    return value;
                  }}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="subGroupId"
                  label="Sub Group"
                  error={errors.subGroupId}
                  selection
                  fluid
                  disabled={!workGroupValue}
                  placeholder="Select"
                  options={workGroupState.list
                    .find((value) => value.trbGrpId === workGroupValue)
                    ?.subGrp?.map((value) => ({
                      key: value.subGrpId,
                      value: value.subGrpId,
                      text: value.subGrpNm,
                    }))}
                  onChange={([_, { value }]) => {
                    handleChange(
                      Object.values(getValues()).every((el) => el !== undefined)
                    );
                    return value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column>
                <ATMField
                  label={<LabelRequired>Comments/Resolution</LabelRequired>}
                  as={ATMTextArea}
                  name="commentsResolution"
                  maxLength={3000}
                  control={control}
                  error={errors.commentsResolution}
                  onChange={([_, { value }]) => {
                    handleChange(
                      Object.values(getValues()).every((el) => el !== undefined)
                    );
                    if (value === '') {
                      return undefined;
                    }
                    return value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            {/* hidden fields */}
            <ATMField
              name="ticketStatus"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="beginDt"
              control={control}
              as={ATMInput}
              defaultValue={defaultValues?.beginDt}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestTyp"
              control={control}
              as={ATMInput}
              defaultValue={defaultValues?.requestTyp}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="submittedBy"
              control={control}
              as={ATMInput}
              defaultValue={defaultValues?.submittedBy}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="ticketDesc"
              control={control}
              as={ATMInput}
              defaultValue={defaultValues?.ticketDesc}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="ticketPrio"
              control={control}
              as={ATMInput}
              defaultValue={defaultValues?.ticketPrio}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="etrDt"
              control={control}
              as={ATMInput}
              defaultValue={null}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="facSub"
              control={control}
              as={ATMInput}
              defaultValue="forwardFacSub"
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="activatedBy"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default ForwardTicketForm;
