import React, { useCallback, useState, useEffect } from 'react';
import { IATMDropdownProps } from 'shared-it-appmod-ui';
import { debounce } from 'lodash';
import { useJobCodeRuleContext } from 'src/contexts/job-code-rule.context';
import {
  getJobCodeOptions,
  getParsedJob,
} from 'src/selectors/job-code-rule.selector';
import { IJobTitles } from 'src/models/job-code-rule.model';
import DropdownJobCodeView from './dropdown-job-code.view';

export type IProps = Omit<IATMDropdownProps, 'value'> & {
  value?: Required<IJobTitles['jobCd'] | IJobTitles['jobCd'][]>;
  onLoad?: (data: IJobTitles | IJobTitles[]) => void;
  filter?: (data: IJobTitles[]) => IJobTitles[];
  vendorName?: string;
};

const DropdownJobCode: React.FC<IProps> = ({
  onChange,
  valueField = 'jobCd', // This will allow you to select what field you want to be the value
  onLoad,
  filter,
  vendorName,
  ...props
}) => {
  const { multiple, value: propsValue } = props;
  const { actions } = useJobCodeRuleContext();

  const [options, setOptions] = useState<Partial<IJobTitles>[]>([]);
  const [selected, setSelected] = useState<Partial<IJobTitles>[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<IProps['value']>(
    propsValue || multiple ? [] : undefined
  );

  // Clear all options if new vendor
  useEffect(() => {
    setOptions([]);
    setSelected([]);
  }, [setOptions, setSelected]);

  const handleSearch = useCallback(
    debounce(async (keyword: string) => {
      setLoading(true);
      let list: IJobTitles[] = [];

      const result = await actions.searchGET(keyword, 'A');

      if (result && result.payload && result.payload.recordCount > 0) {
        list = (result.payload.data ?? []).map((JobCode) =>
          getParsedJob(JobCode)
        );

        if (typeof filter === 'function') {
          list = filter(list);
        }
      }

      if (list.length) {
        setSelected((items) => {
          setOptions([...items, ...list]);

          return items;
        });
      }

      setLoading(false);

      return list;
    }, 300),
    [actions, filter, setLoading, setSelected, setOptions]
  );

  const handleChange = useCallback(
    (e, data) => {
      let values;

      if (multiple) {
        values = data.value.map((id) =>
          options.find((v) => v[valueField] === id)
        );

        setSelected(values);
      } else {
        values = options.find((v) => v[valueField] === data.value);

        if (values) {
          setSelected([values]);
        }
      }

      setValue(data.value);

      if (onChange) {
        onChange(e, data, values);
      }
    },
    [setValue, setSelected, onChange, multiple, options, valueField]
  );

  const optionList = [...selected, ...options].filter(Boolean);

  return (
    <DropdownJobCodeView
      {...props}
      loading={loading}
      onChange={handleChange}
      defaultValue={value}
      value={value}
      onSearchChange={(e) => {
        const keyword = e.target.value.trim();

        if (keyword) {
          handleSearch(keyword);
        }
      }}
      options={getJobCodeOptions(
        Array.from(new Set(optionList.map((a) => a.jobCd))).map((id) =>
          optionList.find((a) => a.jobCd === id)
        ) as Partial<IJobTitles>[],
        valueField
      )}
    />
  );
};

export default DropdownJobCode;
