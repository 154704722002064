import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/training-history.service';
import { ITrainingHistory } from 'src/models/training-history.model';

export const trainingHistoryActionTypes = {
  TRAINING_HISTORY_LIST: 'TRAINING_HISTORY_LIST',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  listGET: {
    type: trainingHistoryActionTypes.TRAINING_HISTORY_LIST,
    service: services.listGET,
  },
};

export type ITrainingHistoryAsync = typeof duckActions;

export interface ITrainingHistoryState
  extends ICommonState<typeof trainingHistoryActionTypes> {
  data?: ITrainingHistory;
  list: ITrainingHistory[];
  total: number;
}

export const defaultState: ITrainingHistoryState = {
  status: {},
  list: [],
  total: 0,
};

const TrainingHistoryReducer = (
  state: ITrainingHistoryState,
  action: IReducerAction<ITrainingHistoryAsync>
): ITrainingHistoryState => {
  switch (action.type) {
    case trainingHistoryActionTypes.TRAINING_HISTORY_LIST: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    default: {
      return state;
    }
  }
};

export default TrainingHistoryReducer;
