import React, { useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  // ATMDropdown,
  ATMField,
  ATMRadio,
  ATMMessage,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
  // ATMButton,
} from 'shared-it-appmod-ui';
import DropdownJobCode from 'src/components/atoms/dropdown-job-code/dropdown-job-code.component';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useJobCodeRuleContext } from 'src/contexts/job-code-rule.context';
import Lang from 'src/libraries/language';
import {
  IJobCodeRule,
  IJobCodeRuleForm,
  JobCodeRuleFormSchema,
} from 'src/models/job-code-rule.model';
import { JobCodeStatus } from 'src/constants/job-code-rule.contants';
import styles from '../job-code-rule-edit/job-code-rule-edit.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IJobCodeRule>;
  handleSubmit: (data: IJobCodeRuleForm) => void;
  handleEnable: (value: boolean) => void;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

type IFormContent = {
  defaultValues?: Partial<IJobCodeRule>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormContent: React.FC<IFormContent> = ({ defaultValues, setIsError }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useATMFormContext<IJobCodeRuleForm>();

  const key30 = watch('key30');
  const key40 = watch('key40');
  const auth = watch('auth');
  const subEntry = watch('subEntry');
  const jobCodeStat = watch('jobCdStat');

  const { state } = useJobCodeRuleContext();
  const [error, setError] = useState(false);
  const [values, setValues] = useState<IJobCodeRule>();

  const checkJobCode = (jobCd: string) => {
    if (state.list.filter((val) => val.jobCd.trim() === jobCd).length > 0) {
      setError(true);
      setIsError(true);
      setValues(state.list.filter((val) => val.jobCd.trim() === jobCd)[0]);
    } else {
      setError(false);
      setIsError(false);
      setValues(undefined);
    }
  };

  return (
    <ATMGrid>
      {error ? (
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column>
            <ATMMessage negative>
              Key Rules Already Exists for the Job Code. Use Edit.{' '}
              {/* <ATMButton
                className={styles.closeErrorBtn}
                icon="close"
                type="button"
                onClick={clearError}
              /> */}
            </ATMMessage>
          </ATMGrid.Column>
        </ATMGrid.Row>
      ) : undefined}
      <ATMGrid.Row columns="2">
        <ATMGrid.Column>
          {defaultValues ? (
            <>
              <FieldReadOnly label={Lang.LBL_JOB_CODE_RULE_CODE_TITLE}>
                {defaultValues?.jobCd} - {defaultValues?.jobTitle}
              </FieldReadOnly>
              <ATMField
                name="jobCd"
                control={control}
                defaultValue={defaultValues.jobCd}
                as={ATMInput}
                type="hidden"
                className="hidden"
              />
            </>
          ) : (
            <ATMField
              as={DropdownJobCode}
              error={errors.jobCd}
              control={control}
              name="jobCd"
              selectOnBlur={false}
              label={
                <LabelRequired>
                  {Lang.LBL_JOB_CODE_RULE_CODE_TITLE}
                </LabelRequired>
              }
              onChange={([_, { value }]) => {
                checkJobCode(value);
                return value;
              }}
            />
          )}
        </ATMGrid.Column>
      </ATMGrid.Row>
      <span className={styles.label}>{Lang.LBL_JOB_CODE_RULE_KEY_RULES}</span>
      <ATMGrid.Row columns="4">
        <ATMGrid.Column className={styles.radio}>
          <>
            {values ? (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_THIRTY_KEY}
                  name="key30"
                  as={ATMRadio}
                  checked={values?.key30 === true}
                  toggle
                  disabled
                />
                <span>{values?.key30 ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            ) : (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_THIRTY_KEY}
                  name="key30"
                  as={ATMRadio}
                  defaultChecked={defaultValues?.key30 === true}
                  toggle
                  control={control}
                  error={errors.key30}
                  onChange={([_, { checked }]) => {
                    setValue('key30', checked);
                    return checked;
                  }}
                />
                <span>{key30 ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            )}
          </>
        </ATMGrid.Column>
        <ATMGrid.Column className={styles.radio}>
          <>
            {values ? (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_FOURTY_KEY}
                  name="key40"
                  as={ATMRadio}
                  checked={values?.key40 === true}
                  toggle
                  disabled
                />
                <span>{values?.key40 ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            ) : (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_FOURTY_KEY}
                  name="key40"
                  as={ATMRadio}
                  defaultChecked={defaultValues?.key40 === true}
                  toggle
                  control={control}
                  error={errors.key40}
                  onChange={([_, { checked }]) => {
                    setValue('key40', checked);
                    return checked;
                  }}
                />
                <span>{key40 ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            )}
          </>
        </ATMGrid.Column>
      </ATMGrid.Row>
      <span className={styles.label}>{Lang.LBL_JOB_CODE_RULE_TRAIN_RULES}</span>
      <ATMGrid.Row columns="4">
        <ATMGrid.Column className={styles.radio}>
          <>
            {values ? (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_AUTH_TRAIN}
                  name="auth"
                  as={ATMRadio}
                  checked={values?.auth === true}
                  disabled
                  toggle
                />
                <span>{values?.auth ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            ) : (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_AUTH_TRAIN}
                  name="auth"
                  as={ATMRadio}
                  defaultChecked={defaultValues?.auth === true}
                  toggle
                  control={control}
                  error={errors.auth}
                  onChange={([_, { checked }]) => {
                    return checked;
                  }}
                />
                <span>{auth ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            )}
          </>
        </ATMGrid.Column>
        <ATMGrid.Column className={styles.radio}>
          <>
            {values ? (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_SUB_ENTRY}
                  name="subEntry"
                  as={ATMRadio}
                  checked={values?.subEntry === true}
                  disabled
                  toggle
                />
                <span>{values?.subEntry ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            ) : (
              <>
                <ATMField
                  label={Lang.LBL_JOB_CODE_RULE_SUB_ENTRY}
                  name="subEntry"
                  as={ATMRadio}
                  defaultChecked={defaultValues?.subEntry === true}
                  toggle
                  control={control}
                  error={errors.subEntry}
                  onChange={([_, { checked }]) => {
                    return checked;
                  }}
                />
                <span>{subEntry ? Lang.LBL_YES : Lang.LBL_NO}</span>
              </>
            )}
          </>
        </ATMGrid.Column>
      </ATMGrid.Row>
      {/* US #635144 Job Code Deactivate */}
      {defaultValues && (
        <>
          <span className={styles.label}>{Lang.LBL_JOB_CODE_STATUS}</span>
          <ATMGrid.Row columns="2">
            <ATMGrid.Column className={styles.radio}>
              <>
                <ATMField
                  name="jobCdStat"
                  as={ATMRadio}
                  defaultChecked={
                    defaultValues?.jobCdStat === JobCodeStatus.ACTIVE
                  }
                  toggle
                  control={control}
                  error={errors.jobCdStat}
                  onChange={([_, { checked }]) => {
                    return checked
                      ? JobCodeStatus.ACTIVE
                      : JobCodeStatus.INACTIVE;
                  }}
                />
                <span>{jobCodeStat ? Lang.LBL_ACTIVE : Lang.LBL_INACTIVE}</span>
              </>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </>
      )}
    </ATMGrid>
  );
};

const JobCodeRuleForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  setIsError,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues}
      validationSchema={JobCodeRuleFormSchema}
    >
      {(formProps) => {
        const {
          formState: { isDirty },
        } = formProps;

        handleEnable(isDirty);

        return (
          <ATMFormProvider {...formProps}>
            <FormContent
              defaultValues={defaultValues}
              setIsError={setIsError}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default JobCodeRuleForm;
