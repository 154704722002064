import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IWorkGroup } from 'src/models/work-group.model';

type IProp = {
  data: IWorkGroup;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const WorkGroupDelete: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMDropdown.Item
        icon={{
          name: 'trash alternate',
        }}
        content={Lang.LBL_DELETE}
        onClick={() => handleOpen(true)}
      />
    }
  >
    <ATMModal.Header>Delete WorkGroup</ATMModal.Header>
    <ATMModal.Content>
      <p>
        {'Are you sure you want to delete '}
        <strong>{data.trbGrpNm}</strong>?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary content="Cancel" onClick={() => handleOpen(false)} />
      <ATMButton
        negative
        content="Yes, Delete"
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default WorkGroupDelete;
