import React from 'react';
import {
  ATMGrid,
  ATMDropdown,
  ATMDatePicker,
  ATMInput,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useTroubleEquipmentTypeContext } from 'src/contexts/trouble-equipment-type.context';
import Moment from 'src/libraries/moment.library';
import { useRolesContext } from 'src/contexts/roles.context';
import { getEmployeeName } from 'src/helpers/employee.helper';
import styles from '../trouble-ticket.module.scss';

type IProps = {
  setIsLineSubstation: (param: string) => void;
  values: Record<string, any>;
  setValue: (name: string, value: any) => void;
  lineSubstation: any;
  setIsIdSearch?: (param: boolean) => void;
  counter: number;
  setIsFirstLoad: (param: boolean) => void;
};

const TroubleTicketListSearchFilter: React.FC<IProps> = ({
  setIsLineSubstation,
  setValue,
  setIsFirstLoad,
  values,
  lineSubstation,
  counter,
}) => {
  const { state: realtimeLogState } = useRealTimeLogContext();
  const { state: substationState } = useSubstationContext();
  const { state: troubleEquipmentTypeState } = useTroubleEquipmentTypeContext();
  const { state: employeeRolesState } = useRolesContext();

  return (
    <ATMGrid>
      <ATMGrid.Row columns={3}>
        <ATMGrid.Column style={{ display: 'flex' }}>
          <span className={styles.selectables}>
            <label className={styles.filterLbl}>
              {Lang.LBL_LINE_SUBSTATION}
            </label>
            <ATMDropdown
              value={values.lineSubstation ?? undefined}
              key={values.lineSubstation ?? undefined}
              style={{ width: '100%' }}
              size="small"
              name="lineSubstation"
              selection
              clearable
              placeholder="Select"
              options={[
                {
                  key: 'line',
                  value: 'line',
                  text: 'Line',
                },
                {
                  key: 'substation',
                  value: 'substation',
                  text: 'Substation',
                },
              ]}
              selectOnBlur={false}
              onChange={(_, val) => {
                setIsFirstLoad(false);
                if (val.value) {
                  setIsLineSubstation(val.value.toString());
                  setValue('lineSubstation', val.value.toString());
                } else {
                  setIsLineSubstation('');
                }
                return val.value;
              }}
            />
          </span>

          <span className={styles.selectables}>
            <label className={styles.filterLbl} />
            <ATMDropdown
              style={{ marginTop: '1.4rem', width: '100%' }}
              size="small"
              name={lineSubstation === 'line' ? 'outgFacId' : 'substationId'}
              selection
              clearable
              placeholder="Select"
              value={
                lineSubstation === 'line'
                  ? Number(values.outgFacId)
                  : values.substationId
              }
              options={
                lineSubstation === 'line'
                  ? realtimeLogState.line.map((value) => ({
                      key: value.outgFacId,
                      value: value.outgFacId,
                      text: value.outgFacNm,
                    }))
                  : substationState.list.map((value) => ({
                      key: value.substationId,
                      value: value.substationId,
                      text: value.name,
                    }))
              }
              selectOnBlur={false}
              search
              onChange={(_, val) => {
                setIsFirstLoad(false);
                setValue(
                  lineSubstation === 'line' ? 'outgFacId' : 'substationId',
                  val.value
                );
                return val.value;
              }}
              disabled={!lineSubstation}
            />
          </span>
        </ATMGrid.Column>
        <ATMGrid.Column style={{ display: 'flex' }}>
          <span className={styles.selectables}>
            <label className={styles.filterLbl}>
              {Lang.LBL_EQUIPMENT_TYPE}
            </label>
            <ATMDropdown
              style={{ width: '100%' }}
              size="small"
              name="troubleEquipmentTypeId"
              selection
              clearable
              search
              placeholder="Select"
              value={Number(values.troubleEquipmentTypeId) ?? undefined}
              key={values.troubleEquipmentTypeId ?? undefined}
              options={troubleEquipmentTypeState.list.map((value) => ({
                key: value.id,
                value: value.id,
                text: value.name,
              }))}
              selectOnBlur={false}
              onChange={(_, val) => {
                setIsFirstLoad(false);
                setValue('troubleEquipmentTypeId', val.value);
                return val.value;
              }}
            />
          </span>
          <span className={styles.selectables}>
            <label className={styles.filterLbl}>{Lang.LBL_ASSIGNED_TO}</label>
            <ATMDropdown
              style={{ width: '100%', marginLeft: '.5em' }}
              size="small"
              name="assignedTo"
              key={values.assignedTo ?? undefined}
              selection
              clearable
              search
              placeholder="Select"
              value={values.assignedTo ?? undefined}
              options={employeeRolesState.list.map((value) => ({
                key: value.empId,
                value: value.empId,
                text: getEmployeeName(value.employee),
              }))}
              selectOnBlur={false}
              onChange={(_, val) => {
                setIsFirstLoad(false);
                setValue('assignedTo', val.value);
                return val.value;
              }}
            />
          </span>
          <span className={styles.datePicker}>
            <ATMDatePicker
              label={<label className={styles.filterLbl}>Due Date Range</label>}
              value={
                values.dueDt !== undefined &&
                (typeof values.dueDt[0] === 'string' ||
                  typeof values.dueDt[1] === 'string') &&
                values.dueDt.map((item) => new Date(item))
              }
              key={counter}
              type="range"
              size="small"
              name="dueDt"
              format="MM/DD/YYYY"
              pointing="left"
              placeholder="Date Range"
              onChange={(_, { value }) => {
                setIsFirstLoad(false);
                if (value === null) {
                  setValue('dueDt', undefined);
                } else if (value && (value as Date[]).length === 2) {
                  const [start, end] = value as Date[];
                  const rangeValue = [
                    Moment(start).startOf('day').toDate(),
                    Moment(end).endOf('day').toDate(),
                  ];
                  setValue('dueDt', rangeValue);
                }
              }}
            />
          </span>
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns={3}>
        <ATMGrid.Column style={{ display: 'flex' }} stretched>
          <span
            className={styles.description}
            style={{ width: '100%', marginBottom: '1em' }}
          >
            <label className={styles.filterLbl}>{Lang.LBL_DESCRIPTION}</label>
            <ATMInput
              field="ticketDesc"
              value={values.ticketDesc ?? undefined}
              clearable
              style={{
                width: '100%',
                height: 'inherit',
                paddingRight: 0,
              }}
              placeholder="Enter"
              onChange={(e) => {
                setIsFirstLoad(false);
                setValue('ticketDesc', e.target.value);
              }}
            />
          </span>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <span
            className={styles.description}
            style={{ width: '100%', float: 'right' }}
          >
            <label className={styles.filterLbl}>
              {Lang.LBL_COMMENTS_AND_RESOLUTION}
            </label>
            <ATMInput
              field="commentsResolution"
              value={values.commentsResolution ?? undefined}
              clearable
              placeholder="Enter"
              style={{ width: '100%' }}
              onChange={(e) => {
                setIsFirstLoad(false);
                setValue('commentsResolution', e.target.value);
              }}
            />
          </span>
        </ATMGrid.Column>
        <ATMGrid.Column />
      </ATMGrid.Row>
    </ATMGrid>
  );
};

export default TroubleTicketListSearchFilter;
