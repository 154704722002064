import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SuspensionListReducer, {
  ISuspensionListState,
  defaultState,
  duckActions,
} from 'src/ducks/suspension-list.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SuspensionListReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISuspensionListContext = ReturnType<typeof useReducer>;

const SuspensionListContext = createContext<Partial<ISuspensionListContext>>({
  state: defaultState,
}) as React.Context<ISuspensionListContext>;

type IProps = {
  state?: Partial<ISuspensionListState>;
};

const SuspensionListProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SuspensionListContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SuspensionListContext.Provider>
  );
};

const useSuspensionListContext = () => useContext(SuspensionListContext);

export type IUseSuspensionListContext = ReturnType<
  typeof useSuspensionListContext
>;

export {
  SuspensionListContext,
  SuspensionListProvider,
  useSuspensionListContext,
};
