import { useCallback } from 'react';
import ENV from 'src/constants/env.constants';

const useReducerLogger = (reducer) =>
  useCallback(
    (state, action) => {
      const next = reducer(state, action);

      if (
        ['prod', 'production'].includes(ENV.VITE_ENVIRONMENT as string) ===
          false &&
        !ENV.VITE_JEST_WORKER_ID
      ) {
        // eslint-disable-next-line no-console
        console.log(
          '%cPrevious State:',
          'color: #9E9E9E; font-weight: 700;',
          state
        );

        // eslint-disable-next-line no-console
        console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);

        // eslint-disable-next-line no-console
        console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', next);
      }

      return next;
    },
    [reducer]
  );

export default useReducerLogger;
