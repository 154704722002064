import { httpClient } from 'src/libraries/http.library';
import {
  TnaReportListPayloadSchema,
} from 'src/models/tna-report.model';

const client = httpClient();
const endpoint = 'outage-request';

const services = {
  listGET: async (data?: Record<string, any>) => {
    const params: Record<string, any> = {
      ...data,
      action: 'reportTna',
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, TnaReportListPayloadSchema);
  },
};

export default services;
