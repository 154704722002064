import React from 'react';
import { Link } from 'react-router-dom';
import Lang from 'src/libraries/language';

const Error404: React.FC = () => {
  return (
    <div>
      <h2>{Lang.TTL_PAGE_NOT_FOUND}</h2>
      <p>{Lang.NTE_PAGE_NOT_FOUND}</p>
      <Link to="/">Click to return home</Link>
    </div>
  );
};

export default Error404;
