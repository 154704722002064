import { httpClient } from 'src/libraries/http.library';
import {
  IIsoCauseForm,
  IsoCauseListPayloadSchema,
  IsoCauseDeletePayloadSchema,
  IsoCausePayloadSchema,
} from 'src/models/iso-cause.model';

const client = httpClient();
const endpoint = 'iso-cause';

const services = {
  dataGET: async (code: string) => {
    return client.get(`/${endpoint}/${code}`, {}, IsoCausePayloadSchema);
  },

  listGET: async () => {
    return client.get(
      `/${endpoint}`,
      {
        limit: 0,
      },
      IsoCauseListPayloadSchema
    );
  },

  createPOST: async (data: IIsoCauseForm) => {
    return client.post(`/${endpoint}`, data, IsoCausePayloadSchema);
  },

  updatePUT: async (code: string, data: IIsoCauseForm) => {
    return client.put(`/${endpoint}/${code}`, data, IsoCausePayloadSchema);
  },

  dataDELETE: async (code: string) => {
    return client.delete(`/${endpoint}/${code}`, IsoCauseDeletePayloadSchema);
  },
};

export default services;
