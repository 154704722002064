import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMIcon,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { IJobCodeRule } from 'src/models/job-code-rule.model';
import JobCodeRuleEdit from '../job-code-rule-edit/job-code-rule-edit.component';
import style from '../job-code-rule.module.scss';

// US #508370 10/02/2023 - Sorting of tables (jobCode and jobTitle only)
const columns: IORGDataTableColumn<IJobCodeRule>[] = [
  {
    index: 'jobCd',
    title: Lang.LBL_JOB_CODE_RULE_CODE,
    width: '150px',
  },
  {
    index: 'jobTitle',
    title: Lang.LBL_JOB_CODE_RULE_TITLE,
    width: '400px',
  },
  {
    index: 'key30',
    title: Lang.LBL_JOB_CODE_RULE_THIRTY_KEY,
    sortable: false,
    width: '155px',
    render: (_, value) =>
      value.key30 ? (
        <ATMIcon name="check circle" size="large" color="green" />
      ) : (
        ''
      ),
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
  },
  {
    index: 'key40',
    title: Lang.LBL_JOB_CODE_RULE_FOURTY_KEY,
    sortable: false,
    width: '155px',
    render: (_, value) =>
      value.key40 ? (
        <ATMIcon name="check circle" size="large" color="green" />
      ) : (
        ''
      ),
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
  },
  {
    index: 'auth',
    title: Lang.LBL_JOB_CODE_RULE_AUTH_TRAIN,
    sortable: false,
    width: '155px',
    render: (_, value) =>
      value.auth ? (
        <ATMIcon name="check circle" size="large" color="green" />
      ) : (
        ''
      ),
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
  },
  {
    index: 'subEntry',
    title: Lang.LBL_JOB_CODE_RULE_SUB_ENTRY,
    sortable: false,
    width: '155px',
    render: (_, value) =>
      value.subEntry ? (
        <ATMIcon name="check circle" size="large" color="green" />
      ) : (
        ''
      ),
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
  },
  {
    index: 'action',
    title: Lang.LBL_ACTION,
    sortable: false,
    width: '80px',
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <JobCodeRuleEdit data={value} />
      </>
    ),
  },
];

type IProps = {
  data: IJobCodeRule[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: () => void;
  total: number;
  loadingDL: boolean;
};

const JobKeyRulesListTable: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  handleDownload,
  total,
  loadingDL,
}) => {
  return (
    <div>
      <ORGDataTable
        className={style.icon}
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        celled={false}
        rowsPerPageOptions={[50, 100]}
        total={total}
        location={history.location}
        handleLocation={history.push}
        counter
        sortable
      >
        {() => ({
          toolbars: {
            buttons: () => (
              <ATMButton
                key="download"
                primary
                icon="download"
                floated="right"
                onClick={() => {
                  handleDownload();
                }}
                loading={loadingDL}
                disabled={loadingDL}
                content={Lang.LBL_DOWNLOAD}
              />
            ),
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default JobKeyRulesListTable;
