import React, { useEffect, useMemo, useState } from 'react';
import {
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMInput,
  ATMLoader,
  ATMSelect,
  formatPhoneNumber,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { orderBy } from 'lodash';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  AccessRole,
  AuthorizationType,
  AuthType,
  FacilityType,
  LERRequestWidth,
} from 'src/constants';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { useRolesContext } from 'src/contexts/roles.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import {
  formatToRawEmployeeId,
  getEmployeeName,
} from 'src/helpers/employee.helper';
import { hasRole } from 'src/libraries/access.library';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import LERRequestPanel from '../../ler-request-panel/ler-request-panel.component';

const LERRequestCrewInformationForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
    resetField,
  } = useATMFormContext<ILerRequestForm>();
  const data = getValues();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const requestorId = watch('crewInformation.requestorId');

  const { state, actions } = useAuthorizedListContext();
  const {
    state: { outageList },
    actions: rolesActions,
  } = useRolesContext();

  const crewStatus = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_CREW_READ
  );

  const authorizedStatus = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_BY_EMP_ID_READ
  );

  const isRequestor = hasRole(AccessRole.MCC_OUTAGE_REQUESTOR);

  useEffect(() => {
    actions.listCrewGET(
      Moment()
        .set({
          date: 31,
          month: 11,
          year: new Date().getFullYear() - 2,
        })
        .toDate()
    );
  }, [actions]);

  useEffect(() => {
    setIsAuthorized(false);

    const fetch = async () => {
      const result = await actions.empDataGET(requestorId);

      if (result.payload) {
        const { payload } = result;

        const exist = (payload.trainings || []).some(
          (v) =>
            v.authTypId === AuthType.AUTH_TRAINING &&
            Moment().isBefore(Moment(v.expiryDate, 'YYYY-MM-DD'), 'day') &&
            !v.suspendFlag &&
            !v.testFailInd
        );

        setIsAuthorized(exist);
      }
    };

    if (requestorId) {
      fetch();
    }
  }, [requestorId, actions, setIsAuthorized]);

  useEffect(() => {
    rolesActions.outageListGET({
      page: 1,
      limit: 0,
      filters: [
        {
          name: 'roleIds',
          value: [
            AccessRole.MCC_SUBMITTING_SUPERVISOR,
            AccessRole.MCC_OUTAGE_REQUESTOR,
            AccessRole.MCC_OUTAGE_SCHEDULER,
            AccessRole.MCC_OPERATIONAL_ENGINEER,
          ],
        },
        {
          name: 'roleStatus',
          value: true,
        },
      ],
    });
  }, [rolesActions]);

  const supervisorList = useMemo(
    () =>
      outageList.filter(
        (v) =>
          v.roleId === AccessRole.MCC_SUBMITTING_SUPERVISOR ||
          v.roleId === AccessRole.MCC_OPERATIONAL_ENGINEER
      ),
    [outageList]
  );

  const facilityType = watch('outageFacility.substationId')
    ? FacilityType.Station
    : FacilityType.Line;

  const outgTypId = watch('outageFacility.outgTypId');

  return (
    <ATMGrid divided>
      <ATMGrid.Column
        width={LERRequestWidth.LEFT}
        className="panel-full-height"
      >
        <ATMHeader
          as="h2"
          content={Lang.TTL_CREW_INFO}
          className="section-header"
        />
        <ATMGrid columns={2}>
          <ATMGrid.Column width={8}>
            <ATMField
              key={`crew_${state.crewList.length}`}
              as={ATMSelect}
              control={control}
              name="crewInformation.crewId"
              label={
                outgTypId === AuthorizationType.InfoOnly ? (
                  Lang.LBL_CREW_INFO_NAME
                ) : (
                  <LabelRequired>{Lang.LBL_CREW_INFO_NAME}</LabelRequired>
                )
              }
              error={errors.crewInformation?.crewId}
              onChange={([_, { value }]) => {
                if (value) {
                  const id = formatToRawEmployeeId(value) as string;
                  const user = state.crewList.find((v) =>
                    formatToRawEmployeeId(v.empId).includes(id)
                  );

                  if (user && user.employee?.workPhoneNumber) {
                    const phoneNumber = `${user.employee?.cellAreaCode}${user.employee.cellPhoneNumber}`;

                    if (phoneNumber.length >= 10) {
                      resetField('crewInformation.crewCellNbr', {
                        defaultValue: formatPhoneNumber(phoneNumber),
                      });
                    }
                  } else {
                    resetField('crewInformation.crewCellNbr', {
                      defaultValue: '',
                    });
                  }
                }

                return (value || '').toString();
              }}
              options={orderBy(
                state.crewList.map((value, key) => ({
                  key,
                  value: formatToRawEmployeeId(value.empId).toString(),
                  text: getEmployeeName({
                    ...(value.employee || {}),
                    empId: value.empId,
                  }),
                })),
                'text',
                'asc'
              )}
              search
              selectOnBlur={false}
              loading={!state.crewList.length && crewStatus.fetching}
              clearable
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={8}>
            <ATMField
              label={
                outgTypId === AuthorizationType.InfoOnly ? (
                  Lang.LBL_INFO_CELLPHONE_NO
                ) : (
                  <LabelRequired>{Lang.LBL_INFO_CELLPHONE_NO}</LabelRequired>
                )
              }
              name="crewInformation.crewCellNbr"
              control={control}
              error={errors.crewInformation?.crewCellNbr}
              as={ATMInput}
              isPhone
              onChange={([_, { value }]) => value}
            />
          </ATMGrid.Column>

          <ATMGrid.Column width={8}>
            <ATMField
              as={ATMInput}
              control={control}
              name="crewInformation.crewPagerNbr"
              label={Lang.LBL_CREW_INFO_PAGER}
              error={errors.crewInformation?.crewPagerNbr}
              fluid
              selectOnBlur={false}
              maxLength={30}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={8}>
            <ATMField
              label={Lang.LBL_CREW_INFO_TRUCK}
              name="crewInformation.crewTruckNbr"
              control={control}
              error={errors.crewInformation?.crewTruckNbr}
              as={ATMInput}
              maxLength={30}
            />
          </ATMGrid.Column>
        </ATMGrid>

        <ATMHeader
          as="h2"
          className="section-header"
          content={Lang.TTL_REQUESTOR_INFO}
        />
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column width={5}>
              {isRequestor ? (
                <>
                  <ATMField
                    as={ATMInput}
                    control={control}
                    name="crewInformation.requestorId"
                    type="hidden"
                    className="hidden"
                    defaultValue={getUser()?.emp_no}
                  />
                  <FieldReadOnly label={Lang.LBL_REQUESTOR_NAME}>
                    {`${getUser()?.first_name} ${getUser()?.last_name}`}
                  </FieldReadOnly>
                </>
              ) : (
                <ATMField
                  as={ATMSelect}
                  control={control}
                  name="crewInformation.requestorId"
                  label={Lang.LBL_REQUESTOR_NAME}
                  error={errors.crewInformation?.requestorId}
                  selection
                  fluid
                  search
                  clearable
                  title={
                    isAuthorized ? Lang.LBL_AUTHORIZED : Lang.LBL_UNAUTHORIZED
                  }
                  options={outageList.map((value) => ({
                    key: value.empId,
                    value: value.empId,
                    text: getEmployeeName({
                      ...(value.employee || {}),
                      empId: value.empId,
                    }),
                  }))}
                  defaultValue={getUser()?.emp_no}
                  onChange={([_, { value }]: any) => {
                    const user = outageList.find((v) => v.empId === value);

                    if (user) {
                      setValue(
                        'crewInformation.requestorEmail',
                        user.employee?.email ?? undefined
                      );

                      const phoneNumber = `${user.employee.cellAreaCode}${user.employee.cellPhoneNumber}`;

                      if (phoneNumber.length >= 10) {
                        resetField('crewInformation.requestorContact', {
                          defaultValue: formatPhoneNumber(phoneNumber),
                        });
                      }
                    } else {
                      setValue('crewInformation.requestorEmail', undefined);
                      resetField('crewInformation.requestorContact', {
                        defaultValue: undefined,
                      });
                    }

                    return value;
                  }}
                  selectOnBlur={false}
                />
              )}
            </ATMGrid.Column>
            <ATMGrid.Column
              width={3}
              verticalAlign="middle"
              style={{ marginTop: '1.5rem', marginLeft: '0' }}
            >
              {authorizedStatus.fetching ? (
                <ATMLoader active inline size="tiny" />
              ) : (
                <span>
                  <ATMIcon
                    name={isAuthorized ? 'check' : 'close'}
                    color={isAuthorized ? 'green' : 'red'}
                    loading={authorizedStatus.fetching}
                  />
                  {isAuthorized ? Lang.LBL_AUTHORIZED : Lang.LBL_UNAUTHORIZED}
                </span>
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row columns="1">
            <ATMGrid.Column width={8}>
              <ATMField
                as={ATMInput}
                control={control}
                name="crewInformation.requestorEmail"
                type="hidden"
                className="hidden"
              />

              <FieldReadOnly label={Lang.LBL_REQUESTOR_EMAIL}>
                {watch('crewInformation.requestorId') === getUser()?.emp_no
                  ? getUser()?.e_mail_address
                  : (watch('crewInformation.requestorEmail') as string)}
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row columns="equal">
            <ATMGrid.Column width={8}>
              <ATMField
                label={Lang.LBL_INFO_CELLPHONE_NO}
                name="crewInformation.requestorContact"
                control={control}
                error={errors.crewInformation?.requestorContact}
                as={ATMInput}
                isPhone
                onChange={([_, { value }]) => value}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={8}>
              <ATMField
                as={ATMSelect}
                control={control}
                label={
                  isRequestor ? (
                    <LabelRequired>
                      {Lang.LBL_REQUESTOR_SUPERVISOR}
                    </LabelRequired>
                  ) : (
                    Lang.LBL_REQUESTOR_SUPERVISOR
                  )
                }
                name="crewInformation.supvId"
                options={orderBy(
                  supervisorList.map((value) => ({
                    key: value.empId,
                    value: value.empId,
                    text: value.employee.fullName || value.empId,
                  })),
                  'text',
                  'asc'
                )}
                onChange={([_, { value }]) => value}
                error={errors.crewInformation?.supvId}
                selectOnBlur={false}
                search
                clearable
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>

        <ATMHeader
          as="h2"
          content={Lang.TTL_GF_CA_INFO}
          className="section-header"
        />

        <ATMGrid>
          <ATMGrid.Row columns="equal">
            <ATMGrid.Column width={8}>
              <ATMField
                label={Lang.LBL_GF_CA}
                name="crewInformation.genlFormnCtrctAdmin"
                control={control}
                error={errors.crewInformation?.genlFormnCtrctAdmin}
                as={ATMInput}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={8}>
              <ATMField
                label={
                  facilityType === FacilityType.Line ? (
                    <LabelRequired>{Lang.LBL_GF_CA_CHECK_BY}</LabelRequired>
                  ) : (
                    Lang.LBL_GF_CA_CHECK_BY
                  )
                }
                name="crewInformation.fldckBy"
                control={control}
                error={errors.crewInformation?.fldckBy}
                as={ATMInput}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <LERRequestPanel data={data} />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestCrewInformationForm;
