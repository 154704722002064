import React, { useCallback } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMInput,
  ATMRadio,
  ATMDropdown,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import Lang from 'src/libraries/language';
import { useFacilityContext } from 'src/contexts/facility.context';
import { IFacilityFormValidation } from 'src/models/facility.model';
import styles from './facility-notification-items.module.scss';

const NotificationTableEMS: React.FC = () => {
  const { control, watch } = useATMFormContext<IFacilityFormValidation>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emsFacs',
  });

  const { state } = useFacilityContext();

  const handleAddEms = useCallback(() => {
    append({
      emsFacId: '',
      top: '',
      station: '',
      busNm: '',
      equipmentNm: '',
      equipmentTyp: undefined,
      defaultFlag: false,
      outgFacId: undefined,
    } as any);
  }, [append]);

  return (
    <>
      {fields.length > 0 ? (
        <ATMTable stackable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>{Lang.LBL_EMS_ID}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_TOP}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_STATION}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_BUS_NAME}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>
                {Lang.LBL_EQUIPMENT_NAME}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>
                {Lang.LBL_EQUIPMENT_TYPE}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_POSITION}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((item, i) => (
              <ATMTable.Row key={item.id} className={styles.emsFields}>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMInput}
                    name={`emsFacs[${i}].emsFacId`}
                    control={control}
                    placeholder="Enter"
                    className={styles.emsId}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMInput}
                    name={`emsFacs[${i}].top`}
                    control={control}
                    placeholder="Enter"
                    className={styles.top}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMInput}
                    name={`emsFacs[${i}].station`}
                    control={control}
                    placeholder="Enter"
                    className={styles.station}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMInput}
                    name={`emsFacs[${i}].busNm`}
                    control={control}
                    placeholder="Enter"
                    className={styles.busName}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMInput}
                    name={`emsFacs[${i}].equipmentNm`}
                    control={control}
                    placeholder="Enter"
                    className={styles.equipmentName}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name={`emsFacs[${i}].equipmentTyp`}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder="Select"
                    options={[
                      ...Array.from(new Set(state.filters.facTyps)).map(
                        (val) => {
                          return {
                            key: val.facTypId,
                            value: val.facTypId,
                            text: val.name,
                          };
                        }
                      ),
                    ]}
                    onChange={([_, { value }]) => value}
                    search
                    className={styles.equipmentType}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <div className={styles.notificationToggle}>
                    <div className={styles.radioButton}>
                      <ATMField
                        name={`emsFacs[${i}].defaultFlag`}
                        as={ATMRadio}
                        defaultChecked={item.defaultFlag || false}
                        toggle
                        control={control}
                        onChange={([_, { checked }]) => checked}
                      />
                    </div>
                    <div style={{ marginLeft: '4px' }}>
                      {watch(`emsFacs[${i}].defaultFlag` as any)
                        ? 'Open'
                        : 'Close'}
                    </div>
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell textAlign="right">
                  <ATMButton
                    icon="trash alternate outline red"
                    type="button"
                    onClick={() => remove(i)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
          <ATMTable.Footer>
            <ATMTable.Cell colSpan={3}>
              <ATMButton
                secondary
                className="borderless"
                icon="plus"
                onClick={handleAddEms}
                content="Add More"
              />
            </ATMTable.Cell>
          </ATMTable.Footer>
        </ATMTable>
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              icon="plus"
              secondary
              content="Add"
              onClick={handleAddEms}
            />
          }
        />
      )}
    </>
  );
};

export default NotificationTableEMS;
