import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { SemanticTEXTALIGNMENTS } from 'semantic-ui-react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMMenu,
  ATMResponsive,
  ATMSegment,
} from 'shared-it-appmod-ui';
import classNames from 'classnames';
import { AccessType } from 'src/constants';
import useLocationTab from 'src/hooks/location-tab.hook';
import { hasAccess } from 'src/libraries/access.library';
import styles from './local-panel.module.scss';

type IPane = {
  id?: string;
  title: React.ReactNode;
  expand?: boolean;
  render?: React.ReactNode;
  actions?: React.ReactNode[];
  permissions?: AccessType[];
  actionsPosition?: SemanticTEXTALIGNMENTS | undefined;
  subMenus?: Omit<IPane, 'subMenus'>[];
  isOpen?: boolean;
  isDefault?: boolean;
};

type IProps = {
  name?: string;
  path?: string;
  noTitleTabs?: string[];
  panes: IPane[];
  actionPanes?: {
    title?: React.ReactNode;
    render: React.ReactNode;
    actions?: React.ReactNode[];
    permissions?: AccessType[];
    className?: string;
    actionsPosition?: SemanticTEXTALIGNMENTS | undefined;
    tabs?: {
      title: React.ReactNode;
      render: () => React.ReactNode;
      active: boolean;
      onClose?: () => void;
      permissions: AccessType[];
    }[];
  }[];
  handleHomeClick?: () => void;
};

const MenuItem: React.FC<{
  value: IProps['panes'][number];
  index: string;
  current: string;
  handleTabChange: (index: string) => void;
}> = ({ value, index, current, handleTabChange }) => {
  const [open, setIsOpen] = useState(
    value.isOpen === true ? value.isOpen : () => current.split('_')[0] === index
  );

  if (value.subMenus) {
    return (
      <ATMMenu.Item
        className={classNames(styles.subMenu, {
          [styles.open]: open,
        })}
      >
        <ATMMenu.Header onClick={() => setIsOpen((v) => !v)}>
          {value.title}
          <ATMIcon name={`angle ${open ? 'down' : 'right'}` as any} />
        </ATMMenu.Header>

        {open && (
          <ATMMenu.Menu>
            {value.subMenus.map((v, i) => (
              <ATMMenu.Item
                key={i}
                name={i.toString()}
                content={v.title?.toString()}
                active={current === `${index}_${i}`}
                onClick={() => handleTabChange(`${index}_${i}`)}
              />
            ))}
          </ATMMenu.Menu>
        )}
      </ATMMenu.Item>
    );
  }

  return (
    <ATMMenu.Item
      key={index}
      name={index.toString()}
      content={value.title?.toString()}
      active={current === index}
      onClick={() => handleTabChange(index)}
    />
  );
};

const DesktopContainer: React.FC<IProps> = ({
  noTitleTabs,
  name = 'tab',
  path,
  panes = [],
  actionPanes = [],
}) => {
  const location = useLocation();
  const { currentTab, handleTabChange } = useLocationTab(name, path);
  const [ready, setReady] = useState(false);

  // Get menus that has access
  const menus = useMemo(
    () =>
      panes.reduce((items: IPane[], value) => {
        const item = {
          ...value,
        };

        if (item.subMenus) {
          item.subMenus = item.subMenus.filter(
            (v) => !v.permissions || hasAccess(v.permissions)
          );

          if (!item.subMenus.length) {
            return items;
          }
        }

        if (!item.permissions || hasAccess(item.permissions)) {
          return [...items, item];
        }

        return items;
      }, []),
    [panes]
  );

  useEffect(() => {
    const list = menus.filter(
      (value) => !value.permissions || hasAccess(value.permissions)
    );
    // Setting default first tab that user has access
    const index = list.findIndex((v) => v.isDefault);

    if (index > 0) {
      if (list[index].subMenus) {
        const hasDefaultIndex = (list[index].subMenus || []).findIndex(
          (v) => v.isDefault
        );

        if (hasDefaultIndex > 0) {
          const params = new URLSearchParams(location.search);
          params.delete(name);

          const pathParams = params.toString();
          handleTabChange(
            `${index}_${hasDefaultIndex}${pathParams ? `&${pathParams}` : ''}`
          );

          setImmediate(() => {
            setReady(true);
          });

          return;
        }
      }

      handleTabChange(index);
    }

    setReady(true);
  }, [name, setReady]);

  const current = get(
    menus,
    String(currentTab)
      .split('_')
      .map((v) => `[${v}]`)
      .join('subMenus')
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <ATMMenu vertical collapsible>
          {actionPanes.map((value, index) =>
            !value?.permissions || hasAccess(value?.permissions) ? (
              <ATMMenu.Item
                key={index}
                name={index.toString()}
                active={false}
                className={value.className}
              >
                {value.render}
              </ATMMenu.Item>
            ) : undefined
          )}

          {menus.map((value, index) =>
            !value.permissions || hasAccess(value.permissions) ? (
              <MenuItem
                key={index}
                value={value}
                index={index.toString()}
                current={String(currentTab)}
                handleTabChange={handleTabChange}
              />
            ) : undefined
          )}
        </ATMMenu>
      </div>
      <ATMSegment>
        {menus.length && current ? (
          <>
            <div className={styles.header}>
              <div className={styles.title}>
                <ATMHeader>
                  {noTitleTabs?.some((title) => title === current.title)
                    ? ''
                    : current.title}
                </ATMHeader>
              </div>
              <div className={styles.actions}>{current.actions}</div>
            </div>
            <div className={styles.content}>{current.render}</div>
          </>
        ) : null}
      </ATMSegment>
    </div>
  );
};

const MobileContainer: React.FC<IProps> = ({ name = 'tab', panes = [] }) => {
  const { currentTab, handleTabChange } = useLocationTab(name);
  const [activeMenu, setActiveMenu] = useState(true);

  return (
    <div className={styles.container}>
      {activeMenu && (
        <ATMMenu vertical fluid>
          {panes.map((value, index) =>
            !value.permissions || hasAccess(value.permissions) ? (
              <ATMMenu.Item
                key={index}
                name={value.title?.toString()}
                content={value.title}
                active={Number(currentTab) === index}
                onClick={() => {
                  setActiveMenu(false);
                  handleTabChange(index);
                }}
              />
            ) : undefined
          )}
        </ATMMenu>
      )}

      {!activeMenu && (
        <div>
          <ATMButton
            secondary
            content="Back"
            icon="left arrow"
            onClick={() => setActiveMenu(true)}
          />
          <ATMSegment>
            {panes.length && panes[currentTab] ? (
              <>
                <ATMGrid columns={2}>
                  <ATMGrid.Column verticalAlign="middle">
                    <ATMHeader>{panes[currentTab].title}</ATMHeader>
                  </ATMGrid.Column>
                  <ATMGrid.Column
                    textAlign={panes[currentTab].actionsPosition ?? 'right'}
                  >
                    {panes[currentTab].actions}
                  </ATMGrid.Column>
                </ATMGrid>
                <div className={styles.content}>{panes[currentTab].render}</div>
              </>
            ) : null}
          </ATMSegment>
        </div>
      )}
    </div>
  );
};

const LocalPanel: React.FC<IProps> = (props) => {
  return (
    <>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props} />
      </ATMResponsive>

      <ATMResponsive media="mobile">
        <MobileContainer {...props} />
      </ATMResponsive>
    </>
  );
};

export default LocalPanel;
