import { httpClient } from 'src/libraries/http.library';
import { SignificantCostListPayloadSchema } from 'src/models/significant-cost.model';

const client = httpClient();
const endpoint = 'significant-cost';

const services = {
  listGET: async () => {
    return client.get(`/${endpoint}`, {}, SignificantCostListPayloadSchema);
  },
};

export default services;
