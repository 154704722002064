import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { ITrainersForm } from 'src/models/trainers.model';
import TrainersForm from '../trainers-form/trainers-form.component';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ITrainersForm) => void;
};

const TrainersAddView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMButton
          primary
          size="small"
          icon="plus"
          floated="right"
          content="New Trainer"
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>Add Trainer</ATMModal.Header>
      <ATMModal.Content>
        <TrainersForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Add"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default TrainersAddView;
