import React, { useEffect } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMSelect,
  ATMInput,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useSickListContext } from 'src/contexts/sick-list.context';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import { tsoOssEmployeeActionTypes } from 'src/ducks/tso-oss-employee.duck';
import Lang from 'src/libraries/language';
import {
  ISickList,
  ISickListForm,
  SickListFormSchema,
} from 'src/models/sick-list.model';
import { getSickListStatus } from 'src/selectors/sick-list.selector';
import { getTsoOssEmployeeStatus } from 'src/selectors/tso-oss-employee.selector';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ISickList>;
  handleSubmit: (data: ISickListForm) => void;
  handleEnable: (value: boolean) => void;
};

const SickListForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  const { state, actions } = useTsoOssEmployeeContext();
  const { state: sickListState, actions: sickListActions } =
    useSickListContext();
  const status = getTsoOssEmployeeStatus(
    state,
    tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_LIST_READ
  );
  const sickStatus = getSickListStatus(
    sickListState,
    sickListActionTypes.SICK_TYPES_LIST_READ
  );

  useEffect(() => {
    if (!defaultValues?.empId) {
      actions?.listGET({ limit: 0, page: 1 });
    }

    sickListActions.sickTypesGET();
  }, [defaultValues, actions, sickListActions]);

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={SickListFormSchema.cast(defaultValues)}
      validationSchema={SickListFormSchema}
    >
      {({ control, formState: { errors, isDirty } }) => {
        handleEnable(isDirty);
        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                {defaultValues?.empId ? (
                  <>
                    <FieldReadOnly label={Lang.LBL_EMPLOYEE}>
                      {defaultValues?.empSick?.emp?.lastName
                        ? `${defaultValues?.empSick?.emp?.lastName}, ${defaultValues?.empSick?.emp?.firstName} / `
                        : '-- / '}
                      {defaultValues?.empId}
                    </FieldReadOnly>
                    <ATMField
                      name="empId"
                      control={control}
                      defaultValue={defaultValues?.empId}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                    />
                  </>
                ) : (
                  <ATMField
                    label={Lang.LBL_EMPLOYEE}
                    name="empId"
                    placeholder="Select"
                    as={ATMSelect}
                    control={control}
                    error={errors.empId}
                    required
                    options={state.list.map((value) => ({
                      key: value.empId,
                      value: value.empId,
                      text: `${
                        value.fullName ?? 'Unavailable'
                      } / ${value.userType.toUpperCase()}`,
                    }))}
                    onChange={([_, { value }]) => {
                      return value;
                    }}
                    loading={status.fetching}
                    disabled={status.fetching}
                    selectOnBlur={false}
                    search
                    clearable
                  />
                )}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label="Reason"
                  name="sickTypeId"
                  placeholder="Select"
                  as={ATMSelect}
                  control={control}
                  error={errors.sickTypeId}
                  required
                  options={sickListState.typeList.map((value) => ({
                    key: value.sickTypeId,
                    value: value.sickTypeId,
                    text: value.sickTypeDesc,
                  }))}
                  onChange={([_, { value }]) => {
                    return value;
                  }}
                  loading={sickStatus.fetching}
                  disabled={sickStatus.fetching}
                  selectOnBlur={false}
                  clearable
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default SickListForm;
