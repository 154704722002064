import { KeyType } from 'src/constants/key-request.constant';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';

export const KeyDetailShape = {
  keyNbr: yup.number().nullable(),
  empId: yup.string().nullable(),
  issDt: yup.date().nullable(),
  contrShortNm: yup.string().nullable(),
  stat: yup.string().nullable(),
  returnDt: yup.date().nullable(),
  sdgeContactId: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
  reason: yup.string().nullable(),
  recipContrId: yup.string().nullable(),
};

export const EmployeeShapeKeyReq = {
  empId: yup.string().nullable(),
  lastName: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  email: yup.string().nullable(),
  title: yup.string().nullable(),
};

export const KeyRequestDetailShape = {
  keyNbr: yup.number().nullable(),
  keyTypId: yup.string().required(),
  supvrNbr: yup.string().nullable(),
  noOfKeys: yup.number().nullable(),
  approvalEmail: yup.boolean().nullable(),
  keyAdminMailLoc: yup.string().nullable().defined(),
  requestNbr: yup.number().required(),
  replId: yup.number().nullable(),
  changeDt: yup.date().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  keyDetail: yup.array().of(yup.object(KeyDetailShape)).default([]),
  supvr: yup.object(EmployeeShapeKeyReq).when('supvrNbr', {
    is: null,
    then: yup.object(EmployeeShapeKeyReq).notRequired(),
    otherwise: yup.object(EmployeeShapeKeyReq).nullable(),
  }),
};

export const KeyRequestDetailSchema = yup.object(KeyRequestDetailShape);
export const KeyDetailSchema = yup.object(KeyDetailShape);
export const EmployeeShapeKeyReqSchema = yup.object(EmployeeShapeKeyReq);

export const KeyRequestShape = {
  requestNbr: yup.number().required(),
  requestorTyp: yup.string().nullable(),
  requestTyp: yup.string().nullable(),
  requestedBy: yup.string().nullable(),
  requestDt: yup.date().required(),
  empId: yup.string().required(),
  stat: yup.string().nullable(),
  changeDt: yup.date().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  keyRequestDetail: yup.object(KeyRequestDetailShape),
  emp: yup.object({
    ...EmployeeShapeKeyReq,
    middleName: yup.string().nullable(),
    email: yup.string().nullable(),
    jobCd: yup.string().nullable(),
  }),
  requestor: yup.object({
    ...EmployeeShapeKeyReq,
    middleName: yup.string().nullable(),
    email: yup.string().nullable(),
  }),
};

export const KeyRequestMinShape = {
  requestNbr: yup.number().required(),
  requestorTyp: yup.string().nullable(),
  requestTyp: yup.string().nullable(),
  requestedBy: yup.string().required(),
  requestDt: yup.date().required(),
  empId: yup.string().required(),
  stat: yup.string().nullable(),
  changeDt: yup.date().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const KeyRequestFormSchema = yup.object({
  ...KeyRequestShape,
  requestNbr: yup.number(),
  requestDt: yup.date(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  stat: yup.string().nullable(),
  keyRequestDetail: yup
    .object({
      keyNbr: yup
        .number()
        .nullable()
        .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
        .required()
        .test(
          'keyNbr',
          Lang.MSG_KEY_NUMBER_ERROR_LENGTH,
          (value) => value?.toString().length === 5
        )
        .when('keyTypId', {
          is: KeyType.KEY_30,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_30_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '30' ||
                value?.toString().slice(0, 2) === '31'
            ),
        })
        .when('keyTypId', {
          is: KeyType.KEY_40,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_40_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '40' ||
                value?.toString().slice(0, 2) === '41'
            ),
        }),
      keyTypId: yup.string().required(),
      noOfKeys: yup.number().nullable(),
      approvalEmail: yup.boolean().nullable().default(false),
      supvrNbr: yup.string().nullable(),
      keyAdminMailLoc: yup.string().nullable().default(''),
      keyDetail: yup
        .object({
          ...KeyDetailShape,
        })
        .nullable(),
    })
    .required(),
  emp: yup.object({
    jobCd: yup.string().nullable(),
  }),
});

export const KeyRequestValidationFormShape = {
  ...KeyRequestShape,
  requestNbr: yup.number(),
  requestDt: yup.date(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  stat: yup.string().nullable(),
  keyRequestDetail: yup
    .object({
      keyTypId: yup.string().required(),
      keyNbr: yup
        .number()
        .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
        .required()
        .test(
          'keyNbr',
          Lang.MSG_KEY_NUMBER_ERROR_LENGTH,
          (value) => value?.toString().length === 5
        )
        .when('keyTypId', {
          is: KeyType.KEY_30,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_30_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '30' ||
                value?.toString().slice(0, 2) === '31'
            ),
        })
        .when('keyTypId', {
          is: KeyType.KEY_40,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_40_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '40' ||
                value?.toString().slice(0, 2) === '41'
            ),
        }),
      noOfKeys: yup
        .number()
        .typeError(Lang.MSG_NUMBER_OF_KEYS_MIN_MAX)
        .required()
        .min(1, Lang.MSG_NUMBER_OF_KEYS_MIN_MAX)
        .max(50, Lang.MSG_NUMBER_OF_KEYS_MIN_MAX),
      approvalEmail: yup.boolean().required().default(false),
      supvrNbr: yup.string().nullable(),
      keyAdminMailLoc: yup.string().nullable().default(null),
      keyDetail: yup.object({
        reason: yup.string().nullable().default(null),
        firmName: yup.string().required(),
      }),
    })
    .required(),
  empId: yup.string().required(),
  emp: yup.object({
    jobCd: yup.string().nullable(),
  }),
  requestor: yup.object({}).nullable(),
};

export const KeyRequestValidationFormSchema = yup.object(
  KeyRequestValidationFormShape
);

export const KeyRequestEmployeeIssueValidationFormSchema = yup.object({
  ...KeyRequestValidationFormShape,
  requestTyp: yup.string().required(),
  keyRequestDetail: yup
    .object({
      keyTypId: yup.string().required(),
      keyNbr: yup
        .number()
        .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
        .required()
        .test(
          'keyNbr',
          Lang.MSG_KEY_NUMBER_ERROR_LENGTH,
          (value) => value?.toString().length === 5
        )
        .when('keyTypId', {
          is: KeyType.KEY_30,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_30_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '30' ||
                value?.toString().slice(0, 2) === '31'
            ),
        })
        .when('keyTypId', {
          is: KeyType.KEY_40,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_40_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '40' ||
                value?.toString().slice(0, 2) === '41'
            ),
        }),
      noOfKeys: yup.number().required().default(1),
      approvalEmail: yup.boolean().required().default(false),
      supvrNbr: yup.string().nullable(),
      keyAdminMailLoc: yup.string().nullable().default(null),
      keyDetail: yup.object({
        reason: yup.string().nullable().default(null),
        firmName: yup.string().nullable().default(null),
      }),
    })
    .required(),
});

export const KeyRequestDemandIssueValidationFormSchema = yup.object({
  ...KeyRequestValidationFormShape,
  keyRequestDetail: yup
    .object({
      keyTypId: yup.string().required(),
      keyNbr: yup
        .number()
        .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
        .required()
        .test(
          'keyNbr',
          Lang.MSG_KEY_NUMBER_ERROR_LENGTH,
          (value) => value?.toString().length === 5
        )
        .when('keyTypId', {
          is: KeyType.KEY_30,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_30_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '30' ||
                value?.toString().slice(0, 2) === '31'
            ),
        })
        .when('keyTypId', {
          is: KeyType.KEY_40,
          then: yup
            .number()
            .typeError(Lang.MSG_KEY_NUMBER_NUMERIC)
            .test(
              'keyNbr',
              Lang.MSG_KEY_NUMBER_ERROR_40_KEY,
              (value) =>
                value?.toString().slice(0, 2) === '40' ||
                value?.toString().slice(0, 2) === '41'
            ),
        }),
      noOfKeys: yup.number().required().default(1),
      approvalEmail: yup.boolean().required().default(false),
      supvrNbr: yup.string().nullable(),
      keyAdminMailLoc: yup.string().nullable().default(null),
      keyDetail: yup.object({
        reason: yup.string().required(),
        firmName: yup.string().nullable().default(null),
      }),
    })
    .required(),
});

export const KeyRequestSchema = yup.object(KeyRequestShape);
export const KeyRequestMinSchema = yup.object(KeyRequestMinShape);

export const KeyRequestListPayloadSchema = yup.object({
  count: yup.object({
    approved: yup.number(),
    pending: yup.number(),
    rejected: yup.number(),
    all: yup.number(),
  }),
  rows: yup.array().of(KeyRequestSchema.clone()),
});

export const KeyRequestPayloadSchema = KeyRequestSchema.defined();

export const KeyRequestDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const KeyRequestPostPayloadSchema = yup.object({
  requestNbr: yup.number().required(),
  requestorTyp: yup.string().nullable(),
  requestTyp: yup.string().nullable(),
  requestedBy: yup.string().required(),
  requestDt: yup.date().required(),
  empId: yup.string().required(),
  stat: yup.string().nullable(),
  changeDt: yup.date().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
});

export const KeyRequestEmployeePostPayloadSchema = yup.object({
  keyRequest: KeyRequestPostPayloadSchema.clone(),
  infoMessage: yup.string().nullable(),
});

export const KeyRequestValidatePayloadSchema = yup.object({
  message: yup.string().nullable(),
});

export type IKeyDetail = yup.Asserts<typeof KeyDetailSchema>;
export type IEmployeeShapeKeyReq = yup.Asserts<
  typeof EmployeeShapeKeyReqSchema
>;
export type IKeyRequestDetail = yup.Asserts<typeof KeyRequestDetailSchema>;
export type IKeyRequest = yup.Asserts<typeof KeyRequestSchema>;
export type IKeyRequestMin = yup.Asserts<typeof KeyRequestMinSchema>;
export type IKeyRequestForm = yup.Asserts<typeof KeyRequestFormSchema>;
export type IKeyRequestValidationForm = yup.Asserts<
  typeof KeyRequestValidationFormSchema
>;
export type IKeyRequestEmployeeIssueValidationForm = yup.Asserts<
  typeof KeyRequestEmployeeIssueValidationFormSchema
>;

export type IKeyRequestDemandIssueValidationForm = yup.Asserts<
  typeof KeyRequestDemandIssueValidationFormSchema
>;

export type IKeyRequestEmployeePayload = yup.Asserts<
  typeof KeyRequestEmployeePostPayloadSchema
>;
export type IKeyRequestPayload = yup.Asserts<typeof KeyRequestPayloadSchema>;
