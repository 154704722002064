import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/sub-category.service';
import { ISubCategory } from 'src/models/sub-category.model';

export const subCategoryActionTypes = {
  SUB_CATEGORY_DATA_READ: 'SUB_CATEGORY_DATA_READ',
  SUB_CATEGORY_LIST_READ: 'SUB_CATEGORY_LIST_READ',
  SUB_CATEGORY_DATA_CREATE: 'SUB_CATEGORY_DATA_CREATE',
  SUB_CATEGORY_DATA_UPDATE: 'SUB_CATEGORY_DATA_UPDATE',
  SUB_CATEGORY_DATA_DELETE: 'SUB_CATEGORY_DATA_DELETE',
  SUB_CATEGORY_DATA_SET: 'SUB_CATEGORY_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  // dataGET: {
  //   type: subCategoryActionTypes.SUB_CATEGORY_DATA_READ,
  //   service: services.dataGET,
  // },

  listGET: {
    type: subCategoryActionTypes.SUB_CATEGORY_LIST_READ,
    service: services.listGET,
  },

  // createPOST: {
  //   type: subCategoryActionTypes.SUB_CATEGORY_DATA_CREATE,
  //   service: services.createPOST,
  //   meta: {
  //     error: false, // Overrides default error handler if you want to have custom error message
  //   },
  // },

  // updatePUT: {
  //   type: subCategoryActionTypes.SUB_CATEGORY_DATA_UPDATE,
  //   service: services.updatePUT,
  // },

  // dataDELETE: {
  //   type: subCategoryActionTypes.SUB_CATEGORY_DATA_DELETE,
  //   service: services.dataDELETE,
  // },

  // This is a sync action
  // setData: (subCategory: ISubCategory) => ({
  //   type: subCategoryActionTypes.SUB_CATEGORY_DATA_SET,
  //   payload: {
  //     subCategory
  //   },
  // }),
};

export type ISubCategoryAsync = typeof duckActions;

export interface ISubCategoryState
  extends ICommonState<typeof subCategoryActionTypes> {
  data?: ISubCategory;
  list: ISubCategory[];
  total: number;
}

export const defaultState: ISubCategoryState = {
  status: {},
  list: [],
  total: 0,
};

const SubCategoryReducer = (
  state: ISubCategoryState,
  action: IReducerAction<ISubCategoryAsync>
): ISubCategoryState => {
  switch (action.type) {
    // case subCategoryActionTypes.SUB_CATEGORY_DATA_SET:
    //   case subCategoryActionTypes.SUB_CATEGORY_DATA_UPDATE:
    //     case subCategoryActionTypes.SUB_CATEGORY_DATA_CREATE: {
    //   case subCategoryActionTypes.SUB_CATEGORY_DATA_READ:
    //   return {
    //     ...state,
    //     data: action.payload?.subCategory,
    //   };
    // }

    case subCategoryActionTypes.SUB_CATEGORY_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    // case subCategoryActionTypes.SUB_CATEGORY_DATA_DELETE: {
    //   if (action.params) {
    //     const [id] = action.params;
    //     const list = state.list.filter((value) => value.id !== id);

    //     return {
    //       ...state,
    //       data: undefined,
    //       total: state.total - (state.list.length - list.length),
    //       list,
    //     };
    //   }

    //   return state;
    // }

    default: {
      return state;
    }
  }
};

export default SubCategoryReducer;
