/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  ORGDataTable,
  ATMDropdown,
  ATMDatePicker,
  ATMButton,
  IORGDataTableQueryState,
  IORGDataTableColumn,
  ATMInput,
  MOLInfoText,
  ATMIcon,
  MOLNoData,
  ATMPopover,
} from 'shared-it-appmod-ui';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import history from 'src/history';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ITroubleJobs } from 'src/models/trouble-jobs.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { getTroubleJobsStatus } from 'src/selectors/trouble-jobs.selector';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import TroubleJobsEdit from '../../trouble-jobs-edit/trouble-jobs-edit.component';
import styles from '../trouble-jobs.module.scss';

type IProps = {
  dataAll: ITroubleJobs[];
  loading: boolean;
  showStatus: boolean;
  handleFetchAll: (state: IORGDataTableQueryState) => void;
  total: number;
  currentTab: number;
  handleDownload: (state: IORGDataTableQueryState) => void;
  showNotRecord: boolean;
  setShowNotRecord: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAll: boolean;
  handleCleanData: () => void;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
};

const TroubleJobsListAllView: React.FC<IProps> = ({
  loading,
  total,
  showStatus,
  handleDownload,
  handleFetchAll,
  setShowNotRecord,
  showNotRecord,
  handleCleanData,
  dataAll,
  setTotal,
  setShowAll,
  showAll,
}) => {
  const { state: troubleJobState } = useTroubleJobsContext();
  const [state, setState] = useState<Record<any, any>>({});
  const status = getTroubleJobsStatus(
    troubleJobState,
    troubleJobsActionTypes.TROUBLE_JOBS_DATA_READ
  );
  const { handleChange } = useLocationParams(['id']);
  const statusDropdown = 'All Statuses';
  const statusDropdownKey = 'All_Statuses';
  const statusOption = [
    {
      key: 1,
      text: 'Open',
      value: 'Open',
    },
    {
      key: 2,
      text: 'Closed',
      value: 'Closed',
    },
  ];

  const columnsAll: IORGDataTableColumn<ITroubleJobs>[] = [
    {
      index: 'troubleJobId',
      title: 'Trouble Job',
      render: (_, value) => (
        <>
          <u>
            <a
              role="button"
              onClick={() => {
                handleChange({
                  id: value.troubleJobId,
                });
              }}
            >
              <b>{value.troubleJobId}</b>
            </a>
          </u>
          <MOLInfoText transparent limit={150} content={value.jobDescription} />
        </>
      ),
    },
    {
      index: 'jobStatus',
      title: 'Status',
      render: (_, value) => {
        return <ListStatus value={value.jobStatus} label />;
      },
    },
    {
      index: 'beginDate',
      title: 'Begin Date',
      render: (_, value) => formatDate(value.beginDate),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => formatDate(value.endDate),
    },
    {
      index: 'facility.outgFacNm',
      title: 'Facility Name',
    },
    {
      index: 'substation.name',
      title: 'Substation',
    },
    {
      index: 'primary.isoOutgTypeDesc',
      title: 'Primary Cause',
    },
    {
      index: 'secondary.isoOutgTypeDesc',
      title: 'Secondary Cause',
    },
    {
      index: 'submittedBy',
      title: 'Created By',
    },
  ];
  return (
    <>
      <div className="admin-content">
        <TroubleJobsEdit
          dataStatus={status.fetching}
          filterState={state as IORGDataTableQueryState}
        />
        <ORGDataTable
          celled={false}
          columns={columnsAll}
          data={dataAll}
          showPagination={false}
          // onChange={handleFetchAll}
          loading={loading}
          defaultRowsPerPage={50}
          // total={total}
          sortable
          noDataText={
            !showNotRecord ? (
              <MOLNoData
                icon="search"
                header="Search to view records"
                subHeader="Please select or enter the search criteria and click on the search button to see the results."
              />
            ) : (
              <div className={styles.noData}>
                <p>
                  <ATMIcon
                    circular
                    color="grey"
                    inverted
                    name="info"
                    size="small"
                    style={{
                      display: 'inline-block',
                      marginRight: '0.8em',
                      verticalAlign: 'bottom',
                    }}
                  />
                  {Lang.MSG_EMPTY_FILTER}
                </p>
              </div>
            )
          }
          addParams={[
            'jobDescription',
            'troubleJobId',
            'jobStatus',
            'outFacNm',
            'beginDate',
          ]}
          counter
        >
          {() => ({
            toolbars: {
              jobDescription: () => (
                <span className={styles.selectables}>
                  <ATMInput
                    field="jobDescription"
                    name="jobDescription"
                    placeholder={Lang.LBL_DESCRIPTION}
                    value={
                      state?.filters?.some(
                        (elem) => elem.name === 'jobDescription'
                      )
                        ? state?.filters.filter(
                            (elem) => elem.name === 'jobDescription'
                          )[0].value
                        : ''
                    }
                    onChange={(e) => {
                      if (e.target.value === '' || null) {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'jobDescription'
                            ) ?? []),
                          ],
                        });
                      } else {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'jobDescription'
                            ) ?? []),
                            {
                              name: 'jobDescription',
                              value: e.target.value,
                            },
                          ],
                        });
                      }
                    }}
                    clearable
                  />
                </span>
              ),
              troubleJobId: () => (
                <span className={styles.selectables}>
                  <ATMInput
                    field="troubleJobId"
                    name="troubleJobId"
                    placeholder={Lang.LBL_JOB_NUMBER}
                    value={
                      state?.filters?.some(
                        (elem) => elem.name === 'troubleJobId'
                      )
                        ? state?.filters.filter(
                            (elem) => elem.name === 'troubleJobId'
                          )[0].value
                        : ''
                    }
                    onChange={(e) => {
                      if (e.target.value === '' || null) {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'troubleJobId'
                            ) ?? []),
                          ],
                        });
                      } else {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'troubleJobId'
                            ) ?? []),
                            {
                              name: 'troubleJobId',
                              value: parseInt(e.target.value, 10),
                            },
                          ],
                        });
                      }
                    }}
                    clearable
                  />
                </span>
              ),
              jobStatus: () => (
                <span className={styles.selectables}>
                  {showStatus && (
                    <ATMDropdown
                      size="small"
                      selection
                      field="jobStatus"
                      value={
                        state?.filters?.some(
                          (elem) => elem.name === 'jobStatus'
                        )
                          ? state?.filters.filter(
                              (elem) => elem.name === 'jobStatus'
                            )[0].value
                          : ''
                      }
                      // defaultValue={statusDropdown}
                      placeholder={Lang.LBL_STATUS}
                      clearable
                      selectOnBlur={false}
                      options={[
                        {
                          key: statusDropdownKey,
                          value: statusDropdown,
                          text: statusDropdown,
                        },
                        ...statusOption,
                      ]}
                      onChange={(_, val) => {
                        if (val.value) {
                          setState({
                            ...state,
                            filters: [
                              ...(state?.filters?.filter(
                                (el) => el.name !== 'jobStatus'
                              ) ?? []),
                              {
                                name: 'jobStatus',
                                value:
                                  val.value === 'All Statuses'
                                    ? undefined
                                    : val.value,
                              },
                            ],
                          });
                        } else {
                          setState({
                            ...state,
                            filters: [
                              ...(state?.filters?.filter(
                                (el) => el.name !== 'jobStatus'
                              ) ?? []),
                              {
                                name: 'jobStatus',
                                value: undefined,
                              },
                            ],
                          });
                        }
                        return val.value;
                      }}
                    />
                  )}
                </span>
              ),
              outgFacNm: () => (
                <span className={styles.selectables}>
                  <ATMInput
                    field="outgFacNm"
                    name="outgFacNm"
                    placeholder={Lang.LBL_FACILITY_NAME}
                    value={
                      state?.filters?.some((elem) => elem.name === 'outgFacNm')
                        ? state?.filters.filter(
                            (elem) => elem.name === 'outgFacNm'
                          )[0].value
                        : ''
                    }
                    onChange={(e) => {
                      if (e.target.value === '' || null) {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'outgFacNm'
                            ) ?? []),
                          ],
                        });
                      } else {
                        setState({
                          ...state,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'outgFacNm'
                            ) ?? []),
                            {
                              name: 'outgFacNm',
                              value: e.target.value,
                            },
                          ],
                        });
                      }
                    }}
                    clearable
                  />
                </span>
              ),
              beginDate: () => (
                <span className={styles.dateRange}>
                  <ATMDatePicker
                    type="range"
                    size="small"
                    format="MM/DD/YYYY"
                    pointing="right"
                    value={
                      state?.filters?.some((elem) => elem.name === 'beginDate')
                        ? state?.filters.filter(
                            (elem) => elem.name === 'beginDate'
                          )[0].value
                        : ''
                    }
                    placeholder={Lang.LBL_DATE_RANGE}
                    onChange={(_, { value }) => {
                      if (value === null) {
                        setState({
                          ...state,
                          page: 1,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'beginDate'
                            ) ?? []),
                          ],
                        });
                      } else if (value && (value as Date[]).length === 2) {
                        const [start, end] = value as Date[];
                        const rangeValue = [
                          Moment(start).startOf('day').toDate(),
                          Moment(end).endOf('day').toDate(),
                        ];

                        setState({
                          ...state,
                          page: 1,
                          filters: [
                            ...(state?.filters?.filter(
                              (el) => el.name !== 'beginDate'
                            ) ?? []),
                            {
                              name: 'beginDate',
                              value: rangeValue,
                            },
                          ],
                        });
                      }
                    }}
                  />
                </span>
              ),
              buttons: () => (
                <>
                  <span>
                    {' '}
                    <ATMButton
                      primary
                      onClick={() => {
                        handleFetchAll(state as IORGDataTableQueryState);
                        setShowNotRecord(false);
                        setShowAll(true);
                      }}
                      content="Search"
                    />{' '}
                  </span>
                  <span>
                    {' '}
                    <ATMButton
                      secondary
                      type="button"
                      onClick={() => {
                        setState({});
                        handleCleanData();
                        setShowAll(false);
                        setTotal(0);
                        setShowNotRecord(false);
                        history.push('/trouble-jobs?troubleJobsStatus=1');
                      }}
                      size="small"
                      content="Clear Filters"
                    />{' '}
                  </span>
                  <span>
                    <ATMPopover
                      position="bottom left"
                      content="Download"
                      on="hover"
                      trigger={
                        <ATMButton
                          key="download"
                          secondary
                          size="tiny"
                          icon="download"
                          onClick={() => {
                            handleDownload({
                              ...state,
                              page: 1,
                              limit: 0,
                            } as IORGDataTableQueryState);
                          }}
                        />
                      }
                    />
                  </span>
                </>
              ),
            },
          })}
        </ORGDataTable>
      </div>
      {showAll ? (
        <span
          className={showAll && total > 50 ? styles.showBtn : styles.hiddenEdit}
        >
          <ATMButton
            type="button"
            onClick={() => {
              handleFetchAll({
                ...state,
                limit: 0,
              } as IORGDataTableQueryState);
              setShowAll(!showAll);
            }}
            content="Show All Entries"
          />
        </span>
      ) : (
        <span />
      )}
    </>
  );
};

export default TroubleJobsListAllView;
