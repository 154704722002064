import React, { useCallback, useEffect, useState } from 'react';
import { pickBy, identity } from 'lodash';
import html2pdf from 'html2pdf.js';
import { useRolesContext } from 'src/contexts/roles.context';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import {
  getRolesStatus,
  getRolesListForm,
  getRolesParams,
} from 'src/selectors/roles.selector';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { IEmployeeRoleFilter } from 'src/models/roles.model';
import { checkValue } from 'src/libraries/common.library';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { RoleFilterLabel } from 'src/constants';
import RolesListView from './roles-list.view';

const RolesList: React.FC = () => {
  const { state, actions } = useRolesContext();
  const status = getRolesStatus(
    state,
    rolesActionTypes.ROLES_EMPLOYEE_LIST_READ
  );

  const [dLStatus, setDlStatus] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [filterState, setFilterState] = useState<Partial<IEmployeeRoleFilter>>(
    {}
  );

  useEffect(() => {
    actions.clearList();
  }, [actions]);

  const handleSubmit = useCallback(
    async (data: Partial<IEmployeeRoleFilter>) => {
      setFilterState(data);
      const params = getRolesParams({ limit: 0, page: 1 }, data);
      await actions.listByEmpGET(params);
      setShowTable(true);
    },
    [actions, setFilterState]
  );

  const handleRolesListExcelDownload = useCallback(() => {
    const filterBy = Object.keys(pickBy(filterState, identity))
      .map((v) => RoleFilterLabel[v.toUpperCase()])
      ?.toString()
      ?.replaceAll(',', '_');
    setDlStatus(true);
    if (state.adminList && state.adminList.length > 0) {
      const { adminList } = state;

      const items = adminList.map((value) => [
        checkValue(value.fullName ? value.fullName : value.empId),
        checkValue(value.email),
        checkValue(value.employeeStat ? 'Active' : 'Inactive'),
        checkValue(value.role.roleStatus ? 'Active' : 'Inactive'),
        checkValue(value.title),
        checkValue((value.roles || []).map((v) => v.roleName).join(', ')),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_NAME_ID,
          Lang.LBL_ROLE_EMAIL,
          Lang.LBL_ROLE_HR_STATUS,
          Lang.LBL_ROLE_STATUS,
          Lang.LBL_ROLE_TITLE,
          Lang.LBL_ROLES_TITLES,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Roles_List_Employee_By_${filterBy}_${Moment().format(
          'YYYYMMDD'
        )}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDlStatus(false);
  }, [state, filterState]);

  const handleRolesListEmailsExcelDownload = useCallback(() => {
    const filterBy = Object.keys(pickBy(filterState, identity))
      .map((v) => RoleFilterLabel[v.toUpperCase()])
      ?.toString()
      ?.replaceAll(',', '_');
    setDlStatus(true);
    if (state.adminList && state.adminList.length > 0) {
      const { adminList } = state;

      const items = adminList.map((value) => [
        checkValue(value.fullName ? value.fullName : value.empId),
        checkValue(value.email),
        checkValue(value.employeeStat ? 'Active' : 'Inactive'),
        checkValue(value.role.roleStatus ? 'Active' : 'Inactive'),
        checkValue(value.title),
        checkValue((value.roles || []).map((v) => v.roleName).join(', ')),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_NAME_ID,
          Lang.LBL_ROLE_EMAIL,
          Lang.LBL_ROLE_HR_STATUS,
          Lang.LBL_ROLE_STATUS,
          Lang.LBL_ROLE_TITLE,
          Lang.LBL_ROLES_TITLES,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Roles_List_Employee_By_${filterBy}_${Moment().format(
          'YYYYMMDD'
        )}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDlStatus(false);
  }, [state, filterState]);

  const handleRolesListPDFDownload = useCallback(() => {
    const filterBy = Object.keys(pickBy(filterState, identity))
      .map((v) => RoleFilterLabel[v.toUpperCase()])
      ?.toString()
      ?.replaceAll(',', '_');
    setDlStatus(true);
    if (state.adminList.length) {
      const form = getRolesListForm(state.adminList);
      const rolesListForm = new DOMParser().parseFromString(form, 'text/html');
      const element = rolesListForm.getElementById('element-to-print');
      const options = {
        margin: [4, 4, 5, 5],
        filename: `MCC_Roles_List_By_${filterBy}_${Moment().format(
          'YYYYMMDD'
        )}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all' },
      };
      html2pdf(element, options);
    }
    setDlStatus(false);
  }, [state, filterState]);

  useEffect(() => {
    return () => {
      actions.clearList();
    };
  }, [actions]);

  return (
    <RolesListView
      data={state.adminList}
      loading={status.fetching}
      handleSubmit={handleSubmit}
      showSearch={showTable}
      handleRolesListExcelDownload={handleRolesListExcelDownload}
      handleRolesListPDFDownload={handleRolesListPDFDownload}
      handleRolesListEmailsExcelDownload={handleRolesListEmailsExcelDownload}
      setFilterState={setFilterState}
      filterState={filterState}
      dLStatus={dLStatus}
      handleShowSearch={setShowTable}
    />
  );
};

export default RolesList;
