import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/tso-oss-employee.service';
import { ITsoOssEmployee } from 'src/models/tso-oss-employee.model';

export const tsoOssEmployeeActionTypes = {
  TSO_OSS_EMPLOYEE_DATA_READ: 'TSO_OSS_EMPLOYEE_DATA_READ',
  TSO_OSS_EMPLOYEE_LIST_READ: 'TSO_OSS_EMPLOYEE_LIST_READ',
  TSO_OSS_EMPLOYEE_DATA_CREATE: 'TSO_OSS_EMPLOYEE_DATA_CREATE',
  TSO_OSS_EMPLOYEE_DATA_UPDATE: 'TSO_OSS_EMPLOYEE_DATA_UPDATE',
  TSO_OSS_EMPLOYEE_DATA_DELETE: 'TSO_OSS_EMPLOYEE_DATA_DELETE',
  TSO_OSS_EMPLOYEE_DATA_SET: 'TSO_OSS_EMPLOYEE_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_DELETE,
    service: services.dataDELETE,
    meta: { error: false },
  },

  // This is a sync action
  setData: (tsoOssEmployee: ITsoOssEmployee) => ({
    type: tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_SET,
    payload: tsoOssEmployee,
  }),
};

export type ITsoOssEmployeeAsync = typeof duckActions;

export interface ITsoOssEmployeeState
  extends ICommonState<typeof tsoOssEmployeeActionTypes> {
  data?: ITsoOssEmployee;
  list: ITsoOssEmployee[];
  total: number;
}

export const defaultState: ITsoOssEmployeeState = {
  status: {},
  list: [],
  total: 0,
};

const TsoOssEmployeeReducer = (
  state: ITsoOssEmployeeState,
  action: IReducerAction<ITsoOssEmployeeAsync>
): ITsoOssEmployeeState => {
  switch (action.type) {
    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_SET:
    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_READ:
    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_UPDATE:
    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.empId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default TsoOssEmployeeReducer;
