import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import { ITroubleEquipmentType } from 'src/models/trouble-equipment-type.model';
import {
  ITroubleTicketForm,
  ITroubleTicketList,
} from 'src/models/trouble-ticket.model';
import { IWorkGroup } from 'src/models/work-group.model';
import ForwardTicketForm from './forward-ticket-form.component/forward-ticket-form.component';

type IProps = {
  data: ITroubleTicketList;
  equipment: ITroubleEquipmentType[];
  workGroup: IWorkGroup[];
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ITroubleTicketForm) => void;
};

const ForwardTicketView: React.FC<IProps> = ({
  data,
  equipment,
  workGroup,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const equipmentList = equipment.filter((val) => {
    if (data.outgFacId && val.lineInd === true) {
      return val;
    }
    if (data.substationId && val.substationInd === true) {
      return val;
    }
    return undefined;
  });

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMDropdown.Item
          icon="paper plane"
          text="Forward"
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>
        Forward Ticket - ID. {data.troubleTicketId}
      </ATMModal.Header>
      <ATMModal.Content scrolling>
        <ForwardTicketForm
          defaultValues={data}
          equipment={equipmentList}
          workGroup={workGroup}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default ForwardTicketView;
