import React from 'react';
import ClassNames from 'classnames';
import { ATMSegment, ATMHeader, ATMIcon, ATMGrid } from 'shared-it-appmod-ui';
import { IEmployee } from 'src/models/employee.model';
import Lang from 'src/libraries/language';
import FieldReadOnly from '../field/field-readonly/field-readonly.component';
import styles from './block-employee.module.scss';

type IProps = {
  data: Partial<IEmployee>;
  className?: string;
};

const BlockEmployee: React.FC<IProps> = ({ data, className }) => (
  <ATMSegment color="blue" className={ClassNames(styles.container, className)}>
    <ATMHeader size="small">
      <ATMIcon name="user circle" color="blue" />
      <ATMHeader.Content>{`${data.first_name} ${data.last_name}`}</ATMHeader.Content>
    </ATMHeader>
    <ATMGrid stackable columns={2}>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_EMPLOYEE_NUMBER}>
            {data.emp_id}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_EMPLOYEE_DEPARTMENT}>
            {data.dept_org_desc}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_EMPLOYEE_JOB_TITLE}>
            {data.job_title}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_EMPLOYEE_COMPANY}>
            {data.company_name}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_EMAIL}>
            {data.e_mail_address}
          </FieldReadOnly>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  </ATMSegment>
);

export default BlockEmployee;
