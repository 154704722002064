import React from 'react';
import classNames from 'classnames';
import { Step, StepProps, StepGroupProps } from 'semantic-ui-react';
import styles from './step.module.scss';

type IProps = StepGroupProps;

const ATMStep: React.FC<IProps> = ({ className, items, ...props }) => {
  return (
    <Step.Group className={classNames(styles.container, className)} {...props}>
      {(items ?? []).map((itemProps, key) => (
        <Step key={key} {...(itemProps as StepProps)} />
      ))}
    </Step.Group>
  );
};

export default ATMStep;
