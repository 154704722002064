import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/request-status.service';
import { IRequestStatus } from 'src/models/request-status.model';

export const requestStatusActionTypes = {
  REQUEST_STATUS_LIST_READ: 'REQUEST_STATUS_LIST_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  listGET: {
    type: requestStatusActionTypes.REQUEST_STATUS_LIST_READ,
    service: services.listGET,
  },
};

export type IRequestStatusAsync = typeof duckActions;

export interface IRequestStatusState extends ICommonState<typeof requestStatusActionTypes> {
  data?: IRequestStatus;
  list: IRequestStatus[];
  total: number;
}

export const defaultState: IRequestStatusState = {
  status: {},
  list: [],
  total: 0,
};

const RequestStatusReducer = (
  state: IRequestStatusState,
  action: IReducerAction<IRequestStatusAsync>
): IRequestStatusState => {
  switch (action.type) {
    case requestStatusActionTypes.REQUEST_STATUS_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    default: {
      return state;
    }
  }
};

export default RequestStatusReducer;
