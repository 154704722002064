import { httpClient } from 'src/libraries/http.library';
import {
  IForwardTicketForm,
  ForwardTicketListPayloadSchema,
  ForwardTicketDeletePayloadSchema,
  ForwardTicketPayloadSchema,
} from 'src/models/forward-ticket.model';

const client = httpClient();
const endpoint = 'forwardTicket';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, ForwardTicketPayloadSchema);
  },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, ForwardTicketListPayloadSchema);
  },

  createPOST: async (data: IForwardTicketForm) => {
    return client.post(`/${endpoint}`, data, ForwardTicketPayloadSchema);
  },

  updatePUT: async (id: number, data: IForwardTicketForm) => {
    return client.put(`/${endpoint}/${id}`, data, ForwardTicketPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}`,
      ForwardTicketDeletePayloadSchema
    );
  },
};

export default services;
