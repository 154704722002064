import yup from 'src/libraries/validator.library';

export const SubCategoryShape = {
  authSubcategoryId: yup.number().required(),
  description: yup.string().required(),
  authType: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const SubCategorySchema = yup.object(SubCategoryShape);

export const SubCategoryFormSchema = yup.object({
  name: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const SubCategoryListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SubCategorySchema.clone()),
});

export const SubCategoryPayloadSchema = yup.object({
  subCategory: SubCategorySchema.clone(),
});

export const SubCategoryDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISubCategory = yup.Asserts<typeof SubCategorySchema>;
export type ISubCategoryForm = yup.Asserts<typeof SubCategoryFormSchema>;
