/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  ATMGrid,
  ORGDataTable,
  IORGDataTableColumn,
  ATMIcon,
  ATMButton,
  MOLNoData,
  IORGDataTableQueryState,
  ATMCheckbox,
} from 'shared-it-appmod-ui';
import useLocationTab from 'src/hooks/location-tab.hook';
import Moment from 'src/libraries/moment.library';
import { AuthListType, SuspensionStatus } from 'src/constants';
import Lang from 'src/libraries/language';
import { IAuthorizedList } from 'src/models/authorized-list.model';
import {
  getTrainingSet,
  getNearestTrainingDate,
} from 'src/selectors/authorized-list.selector';
import history from 'src/history';
import { getEmployeeName } from 'src/helpers/employee.helper';
import AuthListDetails from '../authorized-list-details/auth-list-details.component';
import AuthorizedListSearch from '../authorized-list-search/authorized-list-search.component';
import style from '../authorized-list.module.scss';

type IProps = {
  data: IAuthorizedList[];
  noData: string;
  loading: boolean;
  showInactive: boolean;
  filterValues: React.MutableRefObject<Record<any, any>>;
  handleSubmit: (data: Partial<IORGDataTableQueryState>) => void;
  handleClick: () => void;
  handleDownload: (data: IAuthorizedList[]) => void;
  setShowInactive: (showAll: boolean) => void;
};

const AuthorizedMembersListView: React.FC<IProps> = ({
  data,
  noData,
  loading,
  filterValues,
  showInactive,
  handleClick,
  handleSubmit,
  handleDownload,
  setShowInactive,
}) => {
  const { currentTab, handleTabChange } = useLocationTab('id');
  const [authList, setAuthList] = useState<IAuthorizedList>();

  const withInactiveEmployees = () => {
    let withInactiveEmp = false;
    for (let x = 0; x < data.length; x++) {
      if (data[x].employeeStat === 'I') {
        withInactiveEmp = true;
        break;
      }
    }
    return withInactiveEmp;
  };

  const handleInactiveEmployee = useCallback(
    (showAll: boolean) => {
      if (showAll) {
        setShowInactive(showAll);
      } else {
        setShowInactive(false);
      }
    },
    [showInactive, setShowInactive]
  );

  const columns: IORGDataTableColumn<IAuthorizedList>[] = [
    {
      index: 'fullName',
      title: Lang.LBL_EMPLOYEE_NAME_INFO,
      width: '16%',
      render: (_, value, __, { isExpand }) => {
        return (
          <>
            {value.fullName &&
            value.fullName.charAt(0) ===
              ('A' || 'D' || 'G' || 'J' || 'M' || 'P' || 'S' || 'V' || 'Y') ? (
              <>
                <span
                  className={`${style.capitalLetterA} ${style.capitalLetterStyled}`}
                >
                  {value.fullName.charAt(0)}
                </span>{' '}
                <span
                  className={
                    isExpand ? style.nameEmployeeExpanded : style.nameEmployee
                  }
                >
                  <a
                    role="button"
                    style={{ color: 'inherit' }}
                    onClick={() => {
                      handleTabChange(`${value.empId}&tab=0`);
                      setAuthList(value);
                    }}
                  >
                    {getEmployeeName(value)}
                  </a>
                </span>
                {isExpand ? (
                  <ATMGrid
                    className={`${style.expandContainer} ${style.borderLeft} ${style.borderA}`}
                  >
                    <p>
                      <span>{Lang.LBL_COST_CENTER}:</span> {value.ccCd ?? '--'}
                    </p>
                    <p>
                      <span>{Lang.LBL_MANAGER}:</span>{' '}
                      {`${value.supervisor?.lastName ?? '--'}, ${
                        value.supervisor?.firstName ?? '--'
                      }` ?? '--'}
                    </p>
                    <p>
                      <span>{Lang.LBL_FIRM_NAME}:</span>{' '}
                      {value.contracShortNm ?? '--'}
                    </p>
                    <p>
                      <a
                        role="button"
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=0`);
                          setAuthList(value);
                        }}
                      >
                        {Lang.LBL_MORE_DETAILS}
                      </a>
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {value.fullName &&
                value.fullName.charAt(0) ===
                  ('B' ||
                    'E' ||
                    'H' ||
                    'K' ||
                    'N' ||
                    'Q' ||
                    'T' ||
                    'W' ||
                    'X') ? (
                  <>
                    <span
                      className={`${style.capitalLetterB} ${style.capitalLetterStyled}`}
                    >
                      {value.fullName.charAt(0)}
                    </span>{' '}
                    <span
                      className={
                        isExpand
                          ? style.nameEmployeeExpanded
                          : style.nameEmployee
                      }
                    >
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=0`);
                          setAuthList(value);
                        }}
                      >
                        {getEmployeeName(value)}
                      </a>
                    </span>
                    {isExpand ? (
                      <ATMGrid
                        className={`${style.expandContainer} ${style.borderLeft} ${style.borderB}`}
                      >
                        <p>
                          <span>{Lang.LBL_COST_CENTER}:</span>{' '}
                          {value.ccCd ?? '--'}
                        </p>
                        <p>
                          <span>{Lang.LBL_MANAGER}:</span>{' '}
                          {`${value.supervisor?.lastName ?? '--'}, ${
                            value.supervisor?.firstName ?? '--'
                          }` ?? '--'}
                        </p>
                        <p>
                          <span>{Lang.LBL_FIRM_NAME}:</span>{' '}
                          {value.contracShortNm ?? '--'}
                        </p>
                        <p>
                          <a
                            role="button"
                            onClick={() => {
                              handleTabChange(`${value.empId}&tab=0`);
                              setAuthList(value);
                            }}
                          >
                            {Lang.LBL_MORE_DETAILS}
                          </a>
                        </p>
                      </ATMGrid>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <span
                      className={`${style.capitalLetterC} ${style.capitalLetterStyled}`}
                    >
                      {value.fullName && value.fullName.charAt(0)}
                    </span>{' '}
                    <span
                      className={
                        isExpand
                          ? style.nameEmployeeExpanded
                          : style.nameEmployee
                      }
                    >
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=0`);
                          setAuthList(value);
                        }}
                      >
                        {getEmployeeName(value)}
                      </a>
                    </span>
                    {isExpand ? (
                      <ATMGrid
                        className={`${style.expandContainer} ${style.borderLeft} ${style.borderC}`}
                      >
                        <p>
                          <span>{Lang.LBL_COST_CENTER}:</span>{' '}
                          {value.ccCd ?? '--'}
                        </p>
                        <p>
                          <span>{Lang.LBL_MANAGER}:</span>{' '}
                          {`${value.supervisor?.lastName ?? '--'}, ${
                            value.supervisor?.firstName ?? '--'
                          }` ?? '--'}
                        </p>
                        <p>
                          <span>{Lang.LBL_FIRM_NAME}:</span>{' '}
                          {value.contracShortNm ?? '--'}
                        </p>
                        <p>
                          <a
                            role="button"
                            onClick={() => {
                              handleTabChange(`${value.empId}&tab=0`);
                              setAuthList(value);
                            }}
                          >
                            {Lang.LBL_MORE_DETAILS}
                          </a>
                        </p>
                      </ATMGrid>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      index: 'employeeStat',
      title: Lang.LBL_EMPLOYEE_STATUS,
      sortable: false,
      render: (value) => (value === 'A' ? Lang.LBL_ACTIVE : Lang.LBL_INACTIVE),
    },
    {
      index: 'contracShortNm',
      title: Lang.LBL_EMPLOYEE_CONTRACTOR,
      width: '14%',
      render: (_, value, __, { isExpand }) => (
        <>
          {value.empId && value.empId.slice(0, 2) === '99' ? (
            <>
              <ATMIcon
                name="circle"
                style={{ color: '#91E5B5' }}
                className={style.iconStatus}
                size="small"
              />
              {Lang.LBL_CONTRACTOR}
              {value.contracShortNm && `, ${value.contracShortNm ?? '--'}`}
            </>
          ) : (
            <>
              <ATMIcon
                name="circle"
                size="small"
                style={{ color: '#FF8E8E' }}
                className={style.iconStatus}
              />
              {Lang.LBL_EMPLOYEE}
              {value.contracShortNm && `, ${value.contracShortNm ?? '--'}`}
            </>
          )}
          {isExpand ? <span style={{ height: '9em', display: 'block' }} /> : ''}
        </>
      ),
    },
    {
      index: 'authTraining',
      sortable: false,
      title: <p>{Lang.LBL_AUTHORIZATION_TRAINING}</p>,
      render: (_, value, __, { isExpand }) => {
        if (value.trainings && value.trainings.length > 0) {
          const trainingSet = getTrainingSet(
            value.trainings,
            AuthListType.Training
          );
          const latestTraining = trainingSet.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(trainingSet))
          )[0];

          if (latestTraining) {
            const isExpired = Moment(
              latestTraining.expiryDate,
              'YYYY-MM-DD'
            ).isBefore(Moment().utc());
            return (
              <>
                {isExpired ? (
                  <b className={style.blackText}>{SuspensionStatus.EXPIRED}</b>
                ) : (
                  <b
                    className={
                      latestTraining.suspendFlag || latestTraining.testFailInd
                        ? style.redText
                        : style.greenText
                    }
                  >
                    {latestTraining.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : latestTraining.testFailInd
                      ? 'Failed Test'
                      : !isExpired &&
                        latestTraining.testFailInd === false &&
                        latestTraining.suspendFlag === false
                      ? 'Active'
                      : 'NO'}
                  </b>
                )}
                {isExpand ? (
                  <ATMGrid className={style.expandContainer}>
                    <p>
                      {Moment(latestTraining.trainingDate)
                        .utc()
                        .format('MM/DD/YYYY')}
                    </p>
                    <p>{latestTraining.trainerName}</p>
                    <p>
                      {latestTraining.subcategory
                        ? latestTraining.subcategory.description
                        : '--'}
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            );
          }
        }
        return 'No';
      },
    },
    {
      index: 'substationEntry',
      sortable: false,
      title: <p>{Lang.LBL_SUBSTATION_ENTRY}</p>,
      render: (_, value, __, { isExpand }) => {
        if (value.trainings && value.trainings.length > 0) {
          const trainingSet = getTrainingSet(
            value.trainings,
            AuthListType.SubEntry
          );
          const latestTraining = trainingSet.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(trainingSet))
          )[0];

          if (latestTraining) {
            const isExpired = Moment(
              latestTraining.expiryDate,
              'YYYY-MM-DD'
            ).isBefore(Moment().utc());
            return (
              <>
                {isExpired ? (
                  <b className={style.blackText}>{SuspensionStatus.EXPIRED}</b>
                ) : (
                  <b
                    className={
                      latestTraining.suspendFlag || latestTraining.testFailInd
                        ? style.redText
                        : style.greenText
                    }
                  >
                    {latestTraining.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : latestTraining.testFailInd
                      ? 'Failed Test'
                      : !isExpired &&
                        latestTraining.testFailInd === false &&
                        latestTraining.suspendFlag === false
                      ? 'Active'
                      : 'NO'}
                  </b>
                )}
                {isExpand ? (
                  <ATMGrid className={style.expandContainer}>
                    <p>
                      {Moment(latestTraining.trainingDate)
                        .utc()
                        .format('MM/DD/YYYY')}
                    </p>
                    <p>{latestTraining.trainerName}</p>
                    <p>
                      {latestTraining.subcategory
                        ? latestTraining.subcategory.description
                        : '--'}
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            );
          }
        }
        return 'No';
      },
    },

    {
      index: 'substationSwitching',
      sortable: false,
      title: <p> {Lang.LBL_SUBSTATION_SWITCHING}</p>,
      render: (_, value, __, { isExpand }) => {
        if (value.trainings && value.trainings.length > 0) {
          const trainingSet = getTrainingSet(
            value.trainings,
            AuthListType.SubSwitching
          );
          const latestTraining = trainingSet.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(trainingSet))
          )[0];

          if (latestTraining) {
            const isExpired = Moment(
              latestTraining.expiryDate,
              'YYYY-MM-DD'
            ).isBefore(Moment().utc());
            return (
              <>
                {isExpired ? (
                  <b className={style.blackText}>{SuspensionStatus.EXPIRED}</b>
                ) : (
                  <b
                    className={
                      latestTraining.suspendFlag || latestTraining.testFailInd
                        ? style.redText
                        : style.greenText
                    }
                  >
                    {latestTraining.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : latestTraining.testFailInd
                      ? 'Failed Test'
                      : !isExpired &&
                        latestTraining.testFailInd === false &&
                        latestTraining.suspendFlag === false
                      ? 'Active'
                      : 'No'}
                  </b>
                )}
                {isExpand ? (
                  <ATMGrid className={style.expandContainer}>
                    <p>
                      {Moment(latestTraining.trainingDate)
                        .utc()
                        .format('MM/DD/YYYY')}
                    </p>
                    <p>{latestTraining.trainerName}</p>
                    <p>
                      {latestTraining.subcategory
                        ? latestTraining.subcategory.description
                        : '--'}
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            );
          }
        }
        return 'No';
      },
    },
    {
      index: 'lineSwitching',
      sortable: false,
      title: <p>{Lang.LBL_FIELD_LINE_SWITCHING}</p>,
      render: (_, value, __, { isExpand }) => {
        if (value.trainings && value.trainings.length > 0) {
          const trainingSet = getTrainingSet(
            value.trainings,
            AuthListType.FieldSwitching
          );
          const latestTraining = trainingSet.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(trainingSet))
          )[0];

          if (latestTraining) {
            const isExpired = Moment(
              latestTraining.expiryDate,
              'YYYY-MM-DD'
            ).isBefore(Moment().utc());
            return (
              <>
                {isExpired ? (
                  <b className={style.blackText}>{SuspensionStatus.EXPIRED}</b>
                ) : (
                  <b
                    className={
                      latestTraining.suspendFlag || latestTraining.testFailInd
                        ? style.redText
                        : style.greenText
                    }
                  >
                    {latestTraining.suspendFlag
                      ? SuspensionStatus.SUSPENDED
                      : latestTraining.testFailInd
                      ? 'Failed Test'
                      : !isExpired &&
                        latestTraining.testFailInd === false &&
                        latestTraining.suspendFlag === false
                      ? 'Active'
                      : 'NO'}
                  </b>
                )}
                {isExpand ? (
                  <ATMGrid className={style.expandContainer}>
                    <p>
                      {Moment(latestTraining.trainingDate)
                        .utc()
                        .format('MM/DD/YYYY')}
                    </p>
                    <p>{latestTraining.trainerName}</p>
                    <p>
                      {latestTraining.subcategory
                        ? latestTraining.subcategory.description
                        : '--'}
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            );
          }
        }
        return 'No';
      },
    },
    {
      index: 'action',
      title: '',
      sortable: false,
      width: '50px',
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, _value, __, { isExpand, setIsExpand }) => (
        <div style={{ display: 'flex' }}>
          {isExpand ? (
            <div style={{ height: '10em', display: 'block' }}>
              <ATMButton
                icon="angle up"
                onClick={() => setIsExpand(!isExpand)}
              />
            </div>
          ) : (
            <>
              <ATMButton
                icon="angle down"
                onClick={() => setIsExpand(!isExpand)}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  if (currentTab) {
    return (
      <>
        <AuthListDetails
          data={authList}
          filterValues={filterValues}
          titleMainBreadcrumb="Authorized List"
          handleTabChange={handleTabChange}
        />
      </>
    );
  }

  return (
    <div>
      <AuthorizedListSearch
        loading={loading}
        filterValues={filterValues}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
      />
      <ORGDataTable
        columns={columns}
        data={
          showInactive ? data : data.filter((val) => val.employeeStat === 'A')
        }
        noDataText={
          <MOLNoData
            icon="search"
            header={noData}
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
        counter
        sortable
        loading={loading}
        rowsPerPageOptions={[50]}
        celled={false}
        onChange={() => {
          window.scrollTo(0, 0);
        }}
        defaultState={{
          page: filterValues.current.page || 1,
          limit: 50,
        }}
        location={history.location}
        handleLocation={history.push}
      >
        {() => ({
          toolbars: [
            () => (
              <ATMButton
                secondary
                type="button"
                as="a"
                href="/auth-list/employees"
                target="_blank"
                content="Full Authorized List"
                size="tiny"
              />
            ),
            () => (
              <ATMButton
                secondary
                type="button"
                icon="download"
                size="tiny"
                disabled={!data || data.length === 0}
                onClick={() => handleDownload(data)}
              />
            ),
            () => {
              return data?.length && withInactiveEmployees() ? (
                <ATMCheckbox
                  toggle
                  label="Show Inactive"
                  style={{ marginTop: '.4em' }}
                  disabled={!data.length}
                  onChange={(__, { checked }) => {
                    handleInactiveEmployee(checked ?? false);
                    return checked;
                  }}
                />
              ) : (
                ''
              );
            },
          ],
        })}
      </ORGDataTable>
    </div>
  );
};

export default AuthorizedMembersListView;
