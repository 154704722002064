import React from 'react';
import { ATMMenu, ATMTab } from 'shared-it-appmod-ui';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import {
  IRejectedOverviewReport,
  IRejectedSummaryReport,
} from 'src/models/rejected-report.model';
import RejectedReportOverview from './rejected-overview-report/rejected-overview-report.component';
import RejectedReportSummary from './rejected-summary-report/rejected-summary-report.component';
import style from './rejected-report.module.scss';

type IProps = {
  overview: IRejectedOverviewReport[];
  summary: IRejectedSummaryReport;
  overviewLoading: boolean;
  summaryLoading: boolean;
  overviewParams: Record<any, any>;
  summaryParams: Record<any, any>;
  setOverviewParams: (data) => void;
  setSummaryParams: (data) => void;
  handleOverviewFetch: (clear?: boolean) => void;
  handleSummaryFetch: (clear?: boolean) => void;
  handleOverviewDownload: () => void;
  handleSummaryDownload: () => void;
  handleSummaryDetailsDownload: (date) => void;
  downloading: boolean;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const RejectedReportView: React.FC<IProps> = ({
  overview,
  summary,
  overviewLoading,
  summaryLoading,
  overviewParams,
  summaryParams,
  setOverviewParams,
  setSummaryParams,
  handleOverviewFetch,
  handleSummaryFetch,
  handleOverviewDownload,
  handleSummaryDownload,
  handleSummaryDetailsDownload,
  downloading,
  showNoRecord,
  setShowNoRecord,
}) => {
  const panes = [
    {
      menuItem: (
        <ATMMenu.Item key={Lang.LBL_OVERVIEW}>{Lang.LBL_OVERVIEW}</ATMMenu.Item>
      ),
      // eslint-disable-next-line react/jsx-no-undef
      render: () => (
        <ATMTab.Pane attached={false}>
          <RejectedReportOverview
            data={overview}
            loading={overviewLoading}
            queryParams={overviewParams}
            handleFetch={handleOverviewFetch}
            handleQueryParams={setOverviewParams}
            handleDownload={handleOverviewDownload}
            downloading={downloading}
            showNoRecord={showNoRecord}
            setShowNoRecord={setShowNoRecord}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key={Lang.LBL_SUMMARY}>{Lang.LBL_SUMMARY}</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <RejectedReportSummary
            data={summary}
            loading={summaryLoading}
            queryParams={summaryParams}
            handleFetch={handleSummaryFetch}
            handleQueryParams={setSummaryParams}
            handleDownload={handleSummaryDownload}
            handleSummaryDetailsDownload={handleSummaryDetailsDownload}
            downloading={downloading}
            showNoRecord={showNoRecord}
            setShowNoRecord={setShowNoRecord}
          />
        </ATMTab.Pane>
      ),
    },
  ];
  return (
    <>
      <div className={style.proTabs}>
        <Tabs name="rejectedReport" menu={{ attached: false }} panes={panes} />
      </div>
    </>
  );
};

export default RejectedReportView;
