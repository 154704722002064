import React, { useCallback, useRef, useState } from 'react';
import { useSickListContext } from 'src/contexts/sick-list.context';
import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import { ISickListForm } from 'src/models/sick-list.model';
import { getSickListStatus } from 'src/selectors/sick-list.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import SickListAddView from './sick-list-add.view';

type IProps = {
  types: any;
};

const SickListAdd: React.FC<IProps> = ({ types }) => {
  const { state, actions } = useSickListContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getSickListStatus(
    state,
    sickListActionTypes.SICK_LIST_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    // Compare if the employeesRegistered has this ID doesn't let to add
    async (formData: ISickListForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listGET();
        setIsAddOpen(false);

        ToastSuccess(
          `Sick Entry has been added for the User ${response.payload?.empId}`
        );
      } else {
        ToastError(response.error?.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <SickListAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
      sickTypes={types}
    />
  );
};

export default SickListAdd;
