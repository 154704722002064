import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import RejectedReportReducer, {
  IRejectedReportState,
  defaultState,
  duckActions,
} from 'src/ducks/rejected-report.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    RejectedReportReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IRejectedReportContext = ReturnType<typeof useReducer>;

const RejectedReportContext = createContext<Partial<IRejectedReportContext>>({
  state: defaultState,
}) as React.Context<IRejectedReportContext>;

type IProps = {
  state?: Partial<IRejectedReportState>;
};

const RejectedReportProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <RejectedReportContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RejectedReportContext.Provider>
  );
};

const useRejectedReportContext = () => useContext(RejectedReportContext);

export type IUseRejectedReportContext = ReturnType<
  typeof useRejectedReportContext
>;

export {
  RejectedReportContext,
  RejectedReportProvider,
  useRejectedReportContext,
};
