import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import RealTimeLogReducer, {
  IRealTimeLogState,
  defaultState,
  duckActions,
} from 'src/ducks/real-time-log.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    RealTimeLogReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IRealTimeLogContext = ReturnType<typeof useReducer>;

const RealTimeLogContext = createContext<Partial<IRealTimeLogContext>>({
  state: defaultState,
}) as React.Context<IRealTimeLogContext>;

type IProps = {
  state?: Partial<IRealTimeLogState>;
};

const RealTimeLogProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <RealTimeLogContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RealTimeLogContext.Provider>
  );
};

const useRealTimeLogContext = () => useContext(RealTimeLogContext);

export type IUseRealTimeLogContext = ReturnType<typeof useRealTimeLogContext>;

export { RealTimeLogContext, RealTimeLogProvider, useRealTimeLogContext };
