import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/cancelled-report.service';
import { ICancelledReport } from 'src/models/cancelled-report.model';

export const cancelledReportActionTypes = {
  CANCELLED_REPORT_DATA_READ: 'CANCELLED_REPORT_DATA_READ',
  CANCELLED_REPORT_LIST_READ: 'CANCELLED_REPORT_LIST_READ',
  CANCELLED_REPORT_DATA_SET: 'CANCELLED_REPORT_DATA_SET',
  CANCELLED_REPORT_LIST_CLEAR: 'CANCELLED_REPORT_LIST_CLEAR',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: cancelledReportActionTypes.CANCELLED_REPORT_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: cancelledReportActionTypes.CANCELLED_REPORT_LIST_READ,
    service: services.listGET,
  },

  // This is a sync action
  setData: (cancelledReport: ICancelledReport) => ({
    type: cancelledReportActionTypes.CANCELLED_REPORT_DATA_SET,
    payload: {
      cancelledReport,
    },
  }),
  clearList: () => ({
    type: cancelledReportActionTypes.CANCELLED_REPORT_LIST_CLEAR,
  }),
};

export type ICancelledReportAsync = typeof duckActions;

export interface ICancelledReportState
  extends ICommonState<typeof cancelledReportActionTypes> {
  data?: ICancelledReport;
  list: ICancelledReport[];
  total: number;
}

export const defaultState: ICancelledReportState = {
  status: {},
  list: [],
  total: 0,
};

const CancelledReportReducer = (
  state: ICancelledReportState,
  action: IReducerAction<ICancelledReportAsync>
): ICancelledReportState => {
  switch (action.type) {
    case cancelledReportActionTypes.CANCELLED_REPORT_DATA_SET:
    case cancelledReportActionTypes.CANCELLED_REPORT_DATA_READ: {
      return {
        ...state,
        data: action.payload?.cancelledReport,
      };
    }

    case cancelledReportActionTypes.CANCELLED_REPORT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case cancelledReportActionTypes.CANCELLED_REPORT_LIST_CLEAR: {
      return { ...state, list: [], total: 0 };
    }

    default: {
      return state;
    }
  }
};

export default CancelledReportReducer;
