import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import { ILerRequestEmailGroup } from 'src/models/ler-request.model';

type IProp = {
  data: ILerRequestEmailGroup;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  grpNm: string;
};

const LERUserGroupDeleteView: React.FC<IProp> = ({
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
  grpNm,
}) => {
  return (
    <ATMModal
      open={isOpen}
      size="tiny"
      trigger={
        <ATMDropdown.Item
          content="Remove Group"
          icon="file excel outline"
          onClick={() => {
            handleOpen(!isOpen);
          }}
        />
      }
    >
      <ATMModal.Header>Delete Email Group</ATMModal.Header>
      <ATMModal.Content>
        <p>
          {'Are you sure you want to delete '}
          <strong>{grpNm}</strong>?
        </p>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          negative
          id="confirmDelete"
          content="Yes, Delete"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LERUserGroupDeleteView;
