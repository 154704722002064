import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import OutagesReducer, {
  IOutagesState,
  defaultState,
  duckActions,
} from 'src/ducks/outages.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    OutagesReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IOutagesContext = ReturnType<typeof useReducer>;

const OutagesContext = createContext<Partial<IOutagesContext>>({
  state: defaultState,
}) as React.Context<IOutagesContext>;

type IProps = {
  state?: Partial<IOutagesState>;
};

const OutagesProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <OutagesContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </OutagesContext.Provider>
  );
};

const useOutagesContext = () => useContext(OutagesContext);

export type IUseOutagesContext = ReturnType<typeof useOutagesContext>;

export { OutagesContext, OutagesProvider, useOutagesContext };
