import { httpClient } from 'src/libraries/http.library';
import {
  // ISubCategoryForm,
  SubCategoryListPayloadSchema,
  // SubCategoryDeletePayloadSchema,
  // SubCategoryPayloadSchema,
} from 'src/models/sub-category.model';

const client = httpClient();
const endpoint = 'auth-subcategory';

const services = {
  // dataGET: async (id: number) => {
  //   return client.get(`/${endpoint}/${id}`, {}, SubCategoryPayloadSchema);
  // },

  listGET: async () => {
    return client.get(`/${endpoint}`, {}, SubCategoryListPayloadSchema);
  },

  // createPOST: async (data: ISubCategoryForm) => {
  //   return client.post(`/${endpoint}`, data, SubCategoryPayloadSchema);
  // },

  // updatePUT: async (id: number, data: ISubCategoryForm) => {
  //   return client.put(`/${endpoint}/${id}`, data, SubCategoryPayloadSchema);
  // },

  // dataDELETE: async (id: number) => {
  //   return client.delete(`/${endpoint}/${id}`, SubCategoryDeletePayloadSchema);
  // },
};

export default services;
