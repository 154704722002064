import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMSelect,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { substationEntryActionTypes } from 'src/ducks/substation-entry.duck';
import { getEmployeeName } from 'src/helpers/employee.helper';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import {
  ISubstationEntryEmployee,
  ISubstationEntrySearch,
  SubstationEntryLogSearchSchema,
} from 'src/models/substation-entry.model';
import { ISubstation } from 'src/models/substation.model';
import { orderBy } from 'lodash';
import { getSubstationEntryStatus } from 'src/selectors/substation-entry.selector';
import style from './substation-entry-log-search.module.scss';

type IProps = {
  data?: ISubstationEntrySearch;
  handleSearch: (data) => void;
  handleClearTable: () => void;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  loadingList: boolean;
  setSearchDate: (data) => void;
  setShowAll: (data) => void;
  showAll: boolean;
};

type IFormContent = {
  enable: boolean;
  loading: boolean;
  handleEnable: React.Dispatch<React.SetStateAction<boolean>>;
  employees: ISubstationEntryEmployee[];
  substations: ISubstation[];
  handleClick: () => void;
  handleDate: (dates: Date[]) => void;
  handleClearTable: () => void;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  loadingList: boolean;
  setShowAll: (data) => void;
  showAll: boolean;
};

const FormContent: React.FC<IFormContent> = ({
  enable,
  loading,
  employees,
  substations,
  handleClick,
  handleDate,
  handleClearTable,
  setIsSearch,
  loadingList,
  handleEnable,
  setShowAll,
  showAll,
}) => {
  const { actions } = useSubstationEntryContext();
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useATMFormContext<ISubstationEntrySearch>();

  const dateTime = watch('dateTime');

  return (
    <ATMGrid>
      <ATMGrid.Row columns={5}>
        <ATMGrid.Column className={style.datepicker}>
          <ATMField
            required
            as={ATMDatePicker}
            showToday={false}
            name="dateTime"
            label={Lang.LBL_DATE_RANGE}
            type="range"
            format="MM/DD/YYYY"
            maxDate={new Date()}
            control={control}
            error={errors.dateTime}
            onChange={([_, { value }]) => {
              setIsSearch(false);
              if (!value) {
                reset({ substationId: '', empId: '' });
                setValue('substationId', undefined);
                setValue('empId', undefined);
                handleClearTable();
                handleEnable(false);
                actions?.clearTotal();
              }
              if (value && value.length === 2) {
                handleDate(value);
              }
              return value;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="empId"
            label={Lang.LBL_NAME}
            as={ATMSelect}
            control={control}
            placeholder={Lang.LBL_SELECT}
            options={orderBy(
              employees.map((value) => ({
                key: value.empId,
                value: value.empId,
                text: value?.contracShortNm
                  ? `${getEmployeeName(value, false)} / ${value.contracShortNm}`
                  : getEmployeeName(value),
              })),
              'text',
              'asc'
            )}
            onChange={([_, { value }]) => value}
            search
            selection
            clearable
            selectOnBlur={false}
            disabled={!enable}
            loading={loading}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMSelect}
            name="substationId"
            label={Lang.LBL_SUBSTATION}
            clearable
            selection
            search
            control={control}
            placeholder={Lang.LBL_SELECT}
            error={errors.substationId}
            style={{ display: 'block', marginRight: '1em' }}
            options={substations.map((val) => ({
              value: val.substationId,
              text: val.name,
              key: val.substationId,
            }))}
            onChange={([_, { value }]) => value}
            disabled={!enable}
            loading={loading}
            selectOnBlur={false}
          />
        </ATMGrid.Column>
        <ATMGrid.Column />
        <ATMGrid.Column />
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMButton
            primary
            content="Search"
            onClick={() => handleClick()}
            disabled={!(dateTime && dateTime.length > 1) || loadingList}
            loading={loadingList}
          />
          <ATMButton
            secondary
            content="Clear Fields"
            type="button"
            style={{ marginLeft: '1em' }}
            onClick={() => {
              setIsSearch(false);
              setShowAll(!showAll);
              reset({ dateTime: undefined, substationId: '', empId: '' });
              handleClearTable();
              actions?.clearTotal();
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const SubstationEntryLogSearch: React.FC<IProps> = ({
  handleSearch,
  handleClearTable,
  setIsSearch,
  loadingList,
  setSearchDate,
  setShowAll,
  showAll,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [enable, setEnable] = useState(false);
  const { state, actions } = useSubstationEntryContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const loading = getSubstationEntryStatus(
    state,
    substationEntryActionTypes.SUBSTATION_ENTRY_USER_LIST
  );

  useEffect(() => {
    if (!substationState.list.length && !loading.fetching) {
      substationActions.listGET({ limit: 0, page: 1 });
    }
  }, [substationState.list, loading.fetching, substationActions]);

  const handleDate = useCallback(async (value) => {
    setSearchDate(value);
    const result = await actions.userListGET({
      filters: [
        {
          name: 'startDate',
          value: Moment(value[0]).utc().add(1, 'day').startOf('day').toDate(),
        },
        {
          name: 'endDate',
          value: Moment(value[1] ?? value[0])
            .utc()
            .add(1, 'day')
            .startOf('day')
            .toDate(),
        },
      ],
    });

    if (!result.error) {
      setEnable(true);
      return;
    }

    setEnable(false);
  }, []);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      setIsSearch(true);
      formRef.current.handleSubmit();
    }
  }, [formRef, setIsSearch]);

  useEffect(() => {
    actions?.clearTotal();
    actions?.clearAllList();
  }, []);

  return (
    <div className={style.filterContainer}>
      <ATMForm
        ref={formRef}
        onSubmit={(params) => {
          let filters;
          filters = {
            startDate: Moment(params.dateTime[0])
              .startOf('day')
              .format('YYYY-MM-DD'),
            endDate: Moment(params.dateTime[1] ?? params.dateTime[0])
              .startOf('day')
              .format('YYYY-MM-DD'),
          };
          if (params.empId !== undefined) {
            filters = {
              ...filters,
              empId: params.empId,
            };
          }
          if (params.substationId !== undefined) {
            filters = {
              ...filters,
              substationId: params.substationId,
            };
          }
          handleSearch(filters);
        }}
        validationSchema={SubstationEntryLogSearchSchema}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <FormContent
                {...props}
                enable={enable}
                loading={loading.fetching}
                handleClick={() => {
                  handleClick();
                  setShowAll(true);
                }}
                handleDate={handleDate}
                employees={state.employeeList}
                substations={substationState.list}
                handleClearTable={handleClearTable}
                setIsSearch={setIsSearch}
                loadingList={loadingList}
                handleEnable={setEnable}
                setShowAll={(value) => setShowAll(value)}
                showAll={showAll}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default SubstationEntryLogSearch;
