import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/trouble-equipment-type.service';
import { ITroubleEquipmentType } from 'src/models/trouble-equipment-type.model';

export const troubleEquipmentTypeActionTypes = {
  // TROUBLE_EQUIPMENT_TYPE_DATA_READ: 'TROUBLE_EQUIPMENT_TYPE_DATA_READ',
  TROUBLE_EQUIPMENT_TYPE_LIST_READ: 'TROUBLE_EQUIPMENT_TYPE_LIST_READ',
  // TROUBLE_EQUIPMENT_TYPE_DATA_CREATE: 'TROUBLE_EQUIPMENT_TYPE_DATA_CREATE',
  // TROUBLE_EQUIPMENT_TYPE_DATA_UPDATE: 'TROUBLE_EQUIPMENT_TYPE_DATA_UPDATE',
  // TROUBLE_EQUIPMENT_TYPE_DATA_DELETE: 'TROUBLE_EQUIPMENT_TYPE_DATA_DELETE',
  TROUBLE_EQUIPMENT_TYPE_DATA_SET: 'TROUBLE_EQUIPMENT_TYPE_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  // dataGET: {
  //   type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_READ,
  //   service: services.dataGET,
  // },

  listGET: {
    type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_LIST_READ,
    service: services.listGET,
  },

  // createPOST: {
  //   type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_CREATE,
  //   service: services.createPOST,
  //   meta: {
  //     error: false, // Overrides default error handler if you want to have custom error message
  //   },
  // },

  // updatePUT: {
  //   type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_UPDATE,
  //   service: services.updatePUT,
  // },

  // dataDELETE: {
  //   type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_DELETE,
  //   service: services.dataDELETE,
  // },

  // This is a sync action
  setData: (troubleEquipmentType: ITroubleEquipmentType) => ({
    type: troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_SET,
    payload: troubleEquipmentType,
  }),
};

export type ITroubleEquipmentTypeAsync = typeof duckActions;

export interface ITroubleEquipmentTypeState
  extends ICommonState<typeof troubleEquipmentTypeActionTypes> {
  data?: ITroubleEquipmentType;
  list: ITroubleEquipmentType[];
  total: number;
}

export const defaultState: ITroubleEquipmentTypeState = {
  status: {},
  list: [],
  total: 0,
};

const TroubleEquipmentTypeReducer = (
  state: ITroubleEquipmentTypeState,
  action: IReducerAction<ITroubleEquipmentTypeAsync>
): ITroubleEquipmentTypeState => {
  switch (action.type) {
    case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_SET: {
      // case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_READ:
      // case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_UPDATE:
      // case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    // case troubleEquipmentTypeActionTypes.TROUBLE_EQUIPMENT_TYPE_DATA_DELETE: {
    //   if (action.params) {
    //     const [id] = action.params;
    //     const list = state.list.filter((value) => value.id !== id);

    //     return {
    //       ...state,
    //       data: undefined,
    //       total: state.total - (state.list.length - list.length),
    //       list,
    //     };
    //   }

    //   return state;
    // }

    default: {
      return state;
    }
  }
};

export default TroubleEquipmentTypeReducer;
