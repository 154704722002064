import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { omit } from 'lodash';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { ATMGrid, useATMFormContext, ATMHeader } from 'shared-it-appmod-ui';
import Calendar, {
  ICalendarEvent,
} from 'src/components/organisms/calendar/calendar.component';
import Moment, { IMoment, overlaps } from 'src/libraries/moment.library';
import {
  ILerRequestOutageFacilityListForm,
  ILerRequestForm,
  ILerRequestOutageDateForm,
  ILerRequest,
} from 'src/models/ler-request.model';
import Lang from 'src/libraries/language';
import { LERRequestFormStep, LERRequestWidth } from 'src/constants';
import { useSettingConfig } from 'src/hooks/setting.hook';
import { getLerOutageDateList } from 'src/helpers/ler-request.helper';
import LERRequestOutageDateForm, {
  parseEvent,
} from '../ler-request-outage-date-form/ler-request-outage-date-form.component';
import LERRequestPanel from '../../ler-request-panel/ler-request-panel.component';

type IProps = {
  isEdit?: boolean;
};

const getExactTime = (data: ILerRequestOutageDateForm & { id?: string }) => ({
  ...data,
  startTm: Moment(data.startTm)
    .set({
      second: 0,
      milliseconds: 0,
    })
    .toDate(),
  stopTm: Moment(data.stopTm)
    .set({
      second: 0,
      milliseconds: 0,
    })
    .toDate(),
});

const LERRequestOutageDateCalendar: React.FC<IProps> = ({ isEdit = false }) => {
  const { control, getValues, watch } = useATMFormContext<ILerRequestForm>();
  const dataValues = getValues();
  const [, setCache] = useState<Record<string, ILerRequestOutageDateForm[]>>(
    {}
  );
  const { fields, append, remove } = useFieldArray({
    name: 'outageDates',
    control,
  });
  const facilityBesInd = watch('outageFacility.besInd');
  const { minOutageDate: minDate } = useSettingConfig(facilityBesInd);

  const handleAdd = useCallback(
    (formData: ILerRequestOutageFacilityListForm, callback?: () => void) => {
      const outageDateList = (formData.outageDatelist || []).reduce(
        (items: ILerRequestOutageDateForm[], item) => {
          if (item.startTm && item.stopTm) {
            if (!Moment(item.stopTm).isSame(Moment(item.startTm), 'day')) {
              const list: ILerRequestOutageDateForm[] = [];

              const startDate = Moment(item.startTm);

              while (startDate.isBefore(Moment(item.stopTm), 'day')) {
                list.push(
                  getExactTime({
                    ...item,
                    id: uuid(),
                    key: uuid(),
                    startTm: startDate.clone().toDate(),
                    stopTm: startDate
                      .add(1, 'day')
                      .startOf('day')
                      .clone()
                      .toDate(),
                  })
                );
              }

              list.push(
                getExactTime({
                  ...item,
                  id: uuid(),
                  key: uuid(),
                  startTm: startDate.startOf('day').toDate(),
                })
              );

              return [...items, ...list];
            }

            return [...items, getExactTime(item)];
          }

          return items;
        },
        []
      );

      if (outageDateList.length) {
        append(outageDateList);
      }

      if (callback) {
        callback();
      }
    },
    [append]
  );

  // This will handle the duplicate keys in fields that being created when updating and adding events
  useEffect(() => {
    const list: number[] = [];

    fields.forEach((val, index) => {
      if (
        fields.findIndex(
          (v, i) => v.key === val.key && index !== i && !list.includes(i)
        ) >= 0
      ) {
        list.push(index);
      }
      /** Commented out to fix the deletion of dates when gets updated by a non-scheduler */
      // else if (
      //   val.startTm &&
      //   !isEdit &&
      //   Moment(val.startTm).isBefore(minDate) &&
      //   Moment(val.startTm).isAfter(Moment())
      // ) {
      //   list.push(index);
      // }
    });

    if (list.length) {
      remove(list);
    }
  }, [fields, isEdit, remove, minDate]);

  const handleDelete = useCallback(
    (keys: string | string[]) => {
      const deleteList = !Array.isArray(keys) ? [keys] : keys;

      remove(
        deleteList
          .flat()
          .map((key) => fields.findIndex((v) => v.key === key))
          .filter((v) => v > -1)
      );
    },
    [fields, remove]
  );

  const handleCopy = useCallback(
    (selected: IMoment | undefined, copy: IMoment, data: ICalendarEvent[]) => {
      if (!selected) {
        setCache({});
        return;
      }

      const diff = copy.diff(selected, 'day');

      if (data.length) {
        const list = data.map(
          (value) =>
            ({
              key: uuid(),
              startTm: value.start.clone().add(diff, 'day').toDate(),
              stopTm: value.end.clone().add(diff, 'day').toDate(),
              status: undefined,
              type: undefined,
              reqStatId: undefined,
              allDay: !!value.allDay,
              updatedAt: undefined,
              updatedBy: undefined,
            } as ILerRequestOutageDateForm)
        );

        // Removes all overlaps with the current outage dates
        const items = list.filter(
          (val) =>
            !overlaps(
              [...(fields || []), val].map((v) => [
                Moment(v.startTm),
                Moment(v.stopTm),
              ])
            )
        );

        if (items.length) {
          setCache((values) => ({
            ...values,
            [copy.format('YYYYMMDD')]: items,
          }));

          handleAdd({
            outageDatelist: items,
          });
        }
      } else {
        let deleteList: string[] = [];

        setCache((values) => {
          if (values[copy.format('YYYYMMDD')]) {
            deleteList = values[copy.format('YYYYMMDD')].map(
              (v) => v.key as string
            );
          }

          return omit(values, [copy.format('YYYYMMDD')]);
        });

        handleDelete(deleteList);
      }
    },
    [handleDelete, setCache, fields]
  );

  const handleCopyWeek = useCallback(
    (range: IMoment[] | undefined, events) => {
      if (!range || (range && !range.length)) {
        setCache({});
        return;
      }

      const copy = range[0];

      if (events.length) {
        const list: ILerRequestOutageDateForm[] = [];
        range.forEach((day) => {
          events.forEach((value) => {
            if (day.format('ddd') === value.start.format('ddd')) {
              const diff = day.isoWeek() - value.start.isoWeek();

              list.push({
                key: uuid(),
                startTm: value.start.clone().add(diff, 'week').toDate(),
                stopTm: value.end.clone().add(diff, 'week').toDate(),
                allDay: !!value.allDay,
                reqStatId: undefined,
                updatedAt: undefined,
                updatedBy: undefined,
              });
            }
          });
        });

        if (list.length) {
          setCache((values) => ({
            ...values,
            [copy.format('YYYYWW')]: list,
          }));

          handleAdd({
            outageDatelist: list,
          });
        }
      } else {
        let deleteList: string[] = [];

        setCache((values) => {
          if (copy && values[copy.format('YYYYWW')]) {
            deleteList = values[copy.format('YYYYWW')].map(
              (v) => v.key as string
            );
          }

          return omit(values, copy.format('YYYYWW'));
        });
        handleDelete(deleteList);
      }
    },
    [handleDelete, setCache, handleAdd]
  );

  const outageList = useMemo(
    () => getLerOutageDateList(fields).map((v) => parseEvent(v)),
    [fields]
  );

  let selectDate = fields.length
    ? Moment.min(fields.map((v) => Moment(v.startTm)))
    : undefined;

  if (selectDate && selectDate.isSameOrBefore(Moment())) {
    selectDate = Moment();
  }

  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <ATMHeader as="h2" content={`${Lang.TTL_SCHEDULE_OUTAGE_DATES} (${Lang.TTL_CREW_TIME})`} />
        <Calendar
          minDate={minDate}
          date={selectDate}
          data={outageList}
          visible={2}
          render={({ date, setClose }) => {
            return (
              <LERRequestOutageDateForm
                fields={fields}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                onClose={setClose}
                date={date}
                multiple={false}
                facilityBesInd={facilityBesInd}
              />
            );
          }}
          handleCopyDay={handleCopy}
          handleCopyWeek={handleCopyWeek}
        />
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <LERRequestPanel
          data={
            {
              ...dataValues,
              [LERRequestFormStep.OUTAGE_DATE]: fields,
            } as unknown as ILerRequest
          }
          handleDelete={handleDelete}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestOutageDateCalendar;
