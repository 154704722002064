import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
// import { ILerRequest, ILerRequestItem } from 'src/models/ler-request.model';
import { hasChangeRequest } from 'src/helpers/ler-request.helper';
import Access, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import { AccessRole, LERRequestStatus } from 'src/constants';
import { getUser } from 'src/libraries/amplify.library';
import { hasRole } from 'src/libraries/access.library';

type IProp = {
  title?: string;
  data: any;
  trigger: React.ReactNode;
  handleSuccess?: () => Promise<void> | void;
};

const LERRequestDelete: React.FC<IProp> = ({
  title = Lang.TTL_OUTAGE_REQUEST_DELETE,
  data,
  trigger,
  handleSuccess,
}) => {
  const { state, actions } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);
  const isChangeRequest = hasChangeRequest(data);
  const requestorId = data.crewInformation
    ? data.crewInformation?.requestorId
    : data.requestor?.empno;

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_DELETE
  );

  const changeStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CHANGE_DELETE
  );

  const handleSubmit = useCallback(async () => {
    let response;

    if (isChangeRequest) {
      response = await actions.changeDELETE(data.requestId);
    } else {
      response = await actions.dataDELETE(data.requestId);
    }

    if (!response.error) {
      ToastSuccess(
        isChangeRequest
          ? Lang.MSG_LER_REQUEST_DELETE_CHANGE_SUCCESS
          : Lang.MSG_LER_REQUEST_DELETE_SUCCESS
      );

      if (handleSuccess) {
        await handleSuccess();
      }

      setIsOpen(false);
    }
  }, [actions, data, isChangeRequest, handleSuccess]);

  return (
    <Access
      type={AccessType.OUTAGE_REQUEST_WITHDRAW}
      when={
        isChangeRequest ||
        (data.requestStat === LERRequestStatus.Created &&
          hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) ||
        (data.requestStat === LERRequestStatus.Submitted &&
          hasRole([
            AccessRole.MCC_SUBMITTING_SUPERVISOR,
            AccessRole.MCC_OPERATIONAL_ENGINEER,
          ]) &&
          requestorId === getUser()?.emp_no)
      }
    >
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={trigger}
      >
        <ATMModal.Header>{title}</ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_LER_REQUEST_CONFIRM_DELETE}</p>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            type="button"
            secondary
            content="Cancel"
            onClick={() => setIsOpen(false)}
          />
          <ATMButton
            type="button"
            negative
            content={Lang.LBL_CONFIRM}
            onClick={handleSubmit}
            disabled={status.fetching || changeStatus.fetching}
            loading={status.fetching || changeStatus.fetching}
          />
        </ATMModal.Actions>
      </ATMModal>
    </Access>
  );
};

export default LERRequestDelete;
