import { httpClient } from 'src/libraries/http.library';
import {
  // ITroubleEquipmentTypeForm,
  TroubleEquipmentTypeListPayloadSchema,
  // TroubleEquipmentTypeDeletePayloadSchema,
  // TroubleEquipmentTypePayloadSchema,
} from 'src/models/trouble-equipment-type.model';

const client = httpClient();
const endpoint = 'trouble-equipment-type';

const services = {
  // dataGET: async (id: number) => {
  //   return client.get(`/${endpoint}/${id}`, {}, TroubleEquipmentTypePayloadSchema);
  // },

  listGET: async () => {
    return client.get(
      `/${endpoint}`,
      { page: 1, limit: 0 },
      TroubleEquipmentTypeListPayloadSchema
    );
  },

  // createPOST: async (data: ITroubleEquipmentTypeForm) => {
  //   return client.post(`/${endpoint}`, data, TroubleEquipmentTypePayloadSchema);
  // },

  // updatePUT: async (id: number, data: ITroubleEquipmentTypeForm) => {
  //   return client.put(`/${endpoint}/${id}`, data, TroubleEquipmentTypePayloadSchema);
  // },

  // dataDELETE: async (id: number) => {
  //   return client.delete(`/${endpoint}/${id}`, TroubleEquipmentTypeDeletePayloadSchema);
  // },
};

export default services;
