import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { useContractorContext } from 'src/contexts/contractor.context';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import { contractorActionTypes } from 'src/ducks/contractor.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import { getContractorStatus } from 'src/selectors/contractor.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import ContractorListView from './contractor-list.view';

const ContractorList: React.FC = () => {
  const { search } = useLocation();
  const { state, actions } = useContractorContext();
  const [noData, setNoData] = useState<string>('Search to view records');
  const { state: contractorFirmState, actions: contractorFirmActions } =
    useContractorFirmContext();
  const status = getContractorStatus(
    state,
    contractorActionTypes.CONTRACTOR_LIST_READ
  );
  const firmsStatus = getContractorFirmStatus(
    contractorFirmState,
    contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ
  );
  useEffect(() => {
    contractorFirmActions.listGET({ page: 1, limit: 0 });
    actions.clearList();
    if (!search.includes('getPreviousSearch=true')) {
      sessionStorage.removeItem('contractors');
    }
  }, [search, contractorFirmActions, actions]);

  const handleFetch = useCallback(
    async (params) => {
      if (params?.filters !== undefined) {
        let response;
        const foundFullName = params.filters.find(
          (data) => data.name === 'fullName'
        );
        if (foundFullName === undefined) {
          response = await actions.listGET({ ...params, page: 1, limit: 0 });
          sessionStorage.setItem(
            'contractors',
            JSON.stringify({ ...params, page: 1, limit: 0 })
          );
        } else {
          response = await actions.listGET({
            action: 'search',
            ...params,
            limit: 0,
          });
          sessionStorage.setItem(
            'contractors',
            JSON.stringify({ action: 'search', ...params, limit: 0 })
          );
        }
        if (response.payload?.rows?.length === 0) {
          setNoData('No Records Found for the search criteria');
        }
      } else {
        actions.clearList();
        setNoData('Search to view records');
      }
    },
    [actions]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const result = await actions.listGET(params);
      if (result.payload?.rows) {
        const list = result.payload.rows;
        const items = list.map((value) => [
          checkValue(value.empId),
          checkValue(value.fullName),
          checkValue(value.contractorFirm?.fullName),
          checkValue(value.email),
          checkValue(value.employeeStat === 'I' ? 'Inactive' : 'Active'),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_EMPLOYEE_ID,
            Lang.LBL_EMPLOYEE_NAME,
            Lang.LBL_FIRM_NAME,
            Lang.LBL_EMAIL,
            Lang.LBL_STATUS,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Contractors_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  useEffect(() => {
    if (sessionStorage.getItem('contractors')) {
      handleFetch(JSON.parse(sessionStorage.getItem('contractors') ?? ''));
    }
  }, [search]);

  return (
    <ContractorListView
      data={state.list}
      noData={noData}
      loading={status.fetching}
      handleFetch={handleFetch}
      firms={contractorFirmState.list}
      handleDownload={handleDownload}
      loadingFirms={firmsStatus.fetching}
    />
  );
};

export default ContractorList;
