import yup from 'src/libraries/validator.library';

export const OmsAuditReportItemShape = {
  omsAuditId: yup.number().required(),
  auditEventId: yup.number().required(),
  auditEventDesc: yup.string().required(),
  auditValue: yup.string().nullable(),
  requestId: yup.number().nullable(),
  omsOutageId: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  contracShortNm: yup.string().nullable(),
  parsedAuditValue: yup.string().nullable(),
  updatedByFullName: yup.string().nullable(),
};

export const OMSAuditReportFilterShape = {
  requestId: yup.string().nullable(),
  omsOutageId: yup.string().nullable(),
  fromDate: yup.string().nullable(),
  toDate: yup.string().nullable(),
};

export const OmsAuditReportItemSchema = yup.object(OmsAuditReportItemShape);

export const OmsAuditReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(OmsAuditReportItemSchema.clone()),
});

export const OmsAuditReportPayloadSchema = yup.object({
  omsAuditReport: OmsAuditReportItemSchema.clone(),
});

export const OMSAuditReportFilterSchema = yup.object(OMSAuditReportFilterShape);

export type IOmsAuditReportItem = yup.Asserts<typeof OmsAuditReportItemSchema>;
