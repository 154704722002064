import React from 'react';
import { isOutageDateContinuous } from 'src/helpers/outage-dates.helper';
import { ILerRequest, ILerRequestItem } from 'src/models/ler-request.model';

type IProps = {
  day: number;
  data: ILerRequestItem | ILerRequest;
};

const OutageDay: React.FC<IProps> = ({ day, data }) => {
  const isContinuous = isOutageDateContinuous(data.outageDates);

  if (isContinuous) {
    if (data.outageFacility.facility?.spclOpsConcern) {
      return <strong>C{day}</strong>;
    }

    return <span>C{day}</span>;
  }

  return <span>{day}</span>;
};

export default OutageDay;
