import React, { useState } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { ATMButton, ATMHeader } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IFacility, IFacilityForm } from 'src/models/facility.model';
import FacilityForm from '../facility-form/facility-form.component';
import FacilityDelete from '../facility-delete/facility-delete.component';
import styles from './facility-edit.module.scss';

type IProps = {
  defaultValues?: IFacility;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  clearData: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IFacilityForm) => void;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
  isInUse: boolean;
};

const FacilityEditView: React.FC<IProps> = ({
  defaultValues,
  loading,
  formRef,
  setFacilityActions,
  handleClick,
  handleSubmit,
  clearData,
  isInUse,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const sections = [
    {
      key: 'All Facilities',
      content: 'All Facilities >',
      link: true,
      onClick: () => {
        setFacilityActions({
          isNew: false,
          isDetails: false,
          isEdit: false,
        });
        clearData();
      },
    },
  ];

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.breadCrumbStyle}>
          <Breadcrumb icon="right angle" size="tiny" sections={sections} />
          <ATMHeader as="h1" style={{ marginTop: '0.5rem', paddingTop: 0 }}>
            {Lang.formatString(
              Lang.TTL_FACILITIES_EDIT,
              defaultValues ? defaultValues?.outgFacNm : ''
            )}
          </ATMHeader>
        </div>
        <div className={styles.actionButtons}>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => {
              setFacilityActions({
                isNew: false,
                isDetails: false,
                isEdit: false,
              });
              clearData();
            }}
          />
          <ATMButton
            primary
            content={Lang.LBL_SAVE}
            disabled={!isDirty || loading}
            onClick={() => {
              handleClick();
            }}
            loading={loading}
          />
          <FacilityDelete
            data={defaultValues}
            setFacilityActions={setFacilityActions}
          />
        </div>
      </div>
      <FacilityForm
        defaultValues={defaultValues}
        formRef={formRef}
        handleSubmit={handleSubmit}
        handleEnable={(value) => setIsDirty(value)}
        isInUse={isInUse}
      />
      <div className={styles.actionButtons}>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => {
            setFacilityActions({
              isNew: false,
              isDetails: false,
              isEdit: false,
            });
            clearData();
          }}
        />
        <ATMButton
          primary
          content={Lang.LBL_SAVE}
          disabled={!isDirty || loading}
          onClick={() => {
            handleClick();
          }}
          loading={loading}
        />

        <FacilityDelete
          data={defaultValues}
          setFacilityActions={setFacilityActions}
        />
      </div>
    </div>
  );
};

export default FacilityEditView;
