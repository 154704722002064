import yup from 'src/libraries/validator.library';

export const CancelledReportShape = {
  requestId: yup.number().nullable(),
  requestorId: yup.string().nullable(),
  supvId: yup.string().nullable(),
  cnclNoteId: yup.string().nullable(),
  cnclTypId: yup.string().nullable(),
  chngRsn: yup.string().nullable(),
  cnclBy: yup.string().nullable(),
  cnclAt: yup.string().nullable(),
  cancelNote: yup
    .object({
      noteId: yup.number().required(),
      noteTxt: yup.string().required(),
    })
    .nullable(),
  cancelType: yup
    .object({
      cancelTypeId: yup.string().nullable(),
      cancelTypeDesc: yup.string().nullable(),
    })
    .nullable(),
  requestor: yup
    .object({
      fullName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
      empId: yup.string().nullable(),
    })
    .nullable(),
  supervisor: yup
    .object({
      fullName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
      empId: yup.string().nullable(),
    })
    .nullable(),
  cancelor: yup
    .object({
      fullName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
      empId: yup.string().nullable(),
    })
    .nullable(),
};

export const CancelledReportSchema = yup.object(CancelledReportShape);

export const CancelledReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(CancelledReportSchema.clone()),
});

export const CancelledReportPayloadSchema = yup.object({
  cancelledReport: CancelledReportSchema.clone(),
});

export const CancelledReportDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ICancelledReport = yup.Asserts<typeof CancelledReportSchema>;
