import yup from 'src/libraries/validator.library';

export const IsoCauseShape = {
  isoCauseCode: yup.string().required(),
  description: yup.string().required(),
  level: yup.string().oneOf(['P', 'S']).required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const IsoCauseSchema = yup.object(IsoCauseShape);

export const IsoCauseFormSchema = yup.object({
  description: yup.string().required().default(''),
  level: yup.string().oneOf(['P', 'S']).required().default(''),
});

export const IsoCauseListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(IsoCauseSchema.clone()),
});

export const IsoCausePayloadSchema = yup.object({
  isoCause: IsoCauseSchema.clone(),
});

export const IsoCauseDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IIsoCause = yup.Asserts<typeof IsoCauseSchema>;
export type IIsoCauseForm = yup.Asserts<typeof IsoCauseFormSchema>;
