import React, { useEffect, useState } from 'react';
import { ATMHeader, ATMGrid } from 'shared-it-appmod-ui';
import { useFacilityContext } from 'src/contexts/facility.context';
import { ILerRequest } from 'src/models/ler-request.model';
import UserGroupAddEmail from './user-group-add-email/user-group-add-email.component';
import UserGroupListNotification from './user-group-list-notification/user-group-list.component';
import styles from '../ler-request-authorization.module.scss';

type IProps = {
  data?: ILerRequest;
};

const LERRequestAuthorizationNotification: React.FC<IProps> = ({ data }) => {
  const { state, actions } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (data) {
      if (
        !state.lerUserGrp ||
        state.outgFacId !== data.outageFacility.outgFacId
      ) {
        (async () => {
          await actions?.userGrpDetails(data.outageFacility.outgFacId ?? 0);
        })();
      }
    }
  }, [actions]);

  return (
    <>
      <ATMGrid columns="equal">
        <ATMGrid.Row>
          <ATMGrid.Column>
            <ATMHeader
              className={styles.subHeader}
              as="h2"
              content="Approval/Notifications Email"
            />
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right">
            <div
              style={
                data?.authorizationStatement.emailGroups &&
                data.authorizationStatement.emailGroups.length === 0
                  ? { display: 'none' }
                  : {}
              }
            >
              <UserGroupAddEmail
                isOpen={isOpen}
                handleOpen={setIsOpen}
                data={data}
              />
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column width={16} style={{ marginTop: '1rem' }}>
            <UserGroupListNotification data={data} />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
};

export default LERRequestAuthorizationNotification;
