import { fileActionTypes, IFileState } from 'src/ducks/file.duck';
import { IStatus } from 'src/libraries/thunk.library';

interface exportFormat {
  exportData: string;
  format: string;
}

export const getFileStatus = (
  state: IFileState,
  action: keyof typeof fileActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

// This is old Excel format xls
export const convertToExcel = (data: string): exportFormat => {
  return {
    exportData: `data:application/vnd.ms-excel, ${encodeURIComponent(data)}`,
    format: 'xls',
  };
};

// Default delimiter for CSV is Pipe '|'
export const convertToCSV = (
  data: string,
  preferredSep?: string // Your preferred separator
): exportFormat => {
  const delimiter = preferredSep ?? '|';
  const newTable = new DOMParser()
    .parseFromString(data, 'text/html')
    .querySelector('table') as HTMLTableElement;
  const csvData: string[] = [];
  const rows = newTable.getElementsByTagName('tr');
  for (let i = 0; i < rows.length; i++) {
    const cols = rows[i].querySelectorAll('td,th');
    const csvRow: string[] = [];
    for (let j = 0; j < cols.length; j++) {
      const colData = cols[j].innerHTML.replaceAll('\n', ' ').trim();
      csvRow.push(colData);
    }
    csvData.push(csvRow.join(delimiter).replaceAll('\n', ' '));
  }
  const finalCSV = csvData.join('\n');

  return {
    exportData: `data:text/csv, ${encodeURIComponent(
      `sep=${delimiter}\n${finalCSV}`
    )}`,
    format: 'csv',
  };
};

export const buildExportData = (
  data: (string | null | number | undefined | Record<any, any>)[][],
  title?: string[],
  doubleHeader?: boolean,
  subHeader?: string[][]
): string => {
  let table = '<table border="1" cellspacing="1" cellpadding="2"  >';
  if (title?.length) {
    if (doubleHeader && subHeader?.length) {
      table += '<tr>';
      title.forEach((hdr, index) => {
        table += `<th colspan="2" style="font-weight:bold">${hdr}</th>`;
        if (index >= 2) {
          table += '<th colspan="2" style="font-weight:bold"></th>';
        }
      });
      table += '</tr>';
      table += '<tr>';
      table += '<th colspan="2" style="font-weight:bold"></th>';
      table += '<th colspan="2" style="font-weight:bold"></th>';
      subHeader.forEach((hdr) => {
        table += `<th style="font-weight:bold">${hdr[0]}</th>`;
        table += `<th style="font-weight:bold">${hdr[1]}</th>`;
      });
      table += '</tr>';
    } else {
      table += '<tr>';
      title.forEach((hdr) => {
        table += `<th style="font-weight:bold">${hdr}</th>`;
      });
      table += '</tr>';
    }
  }

  if (data?.length) {
    if (doubleHeader && subHeader?.length) {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value, index) => {
          if (index === 0 || index === 1) {
            table += `<td colspan="2" style="text-align:left">${value}</td>`;
          } else {
            table += `<td style="text-align:left">${value}</td>`;
          }
        });
        table += '</tr>';
      });
    } else {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value) => {
          table += `<td style="text-align:left">${value}</td>`;
        });
        table += '</tr>';
      });
    }
  }

  table += '</table>';

  return table;
};

export const buildExportDataWithTableTitle = (
  data: (string | null | number | undefined | Record<any, any>)[][],
  title?: string[],
  employeeInformation?: string | null,
  doubleHeader?: boolean,
  subHeader?: string[][]
) => {
  let table = '<table border="1" cellspacing="1" cellpadding="2"  >';
  if (employeeInformation) {
    table += '<tr>';
    table += `<th colspan=${title?.length}>${employeeInformation}</th>`;
    table += '</tr>';
  }
  if (title?.length) {
    if (doubleHeader && subHeader?.length) {
      table += '<tr>';
      title.forEach((hdr) => {
        table += `<th colspan="2">${hdr}</th>`;
      });
      table += '</tr>';
      table += '<tr>';
      table += '<th colspan="2"></th>';
      table += '<th colspan="2"></th>';
      subHeader.forEach((hdr) => {
        table += `<th>${hdr[0]}</th>`;
        table += `<th>${hdr[1]}</th>`;
      });
      table += '</tr>';
    } else {
      table += '<tr>';
      title.forEach((hdr) => {
        table += `<th>${hdr}</th>`;
      });
      table += '</tr>';
    }
  }

  if (data?.length) {
    if (doubleHeader && subHeader?.length) {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value, index) => {
          if (index === 0 || index === 1) {
            table += `<td colspan="2">${value}</td>`;
          } else {
            table += `<td>${value}</td>`;
          }
        });
        table += '</tr>';
      });
    } else {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value) => {
          table += `<td>${value}</td>`;
        });
        table += '</tr>';
      });
    }
  }

  table += '</table>';

  return table;
};

// US #628758 new report style
export const buildExportDataForRealTimeLog = (
  data: (string | null | number | undefined | Record<any, any>)[][],
  title?: string[],
  doubleHeader?: boolean,
  subHeader?: string[][]
): string => {
  let table = '<table border="1" cellspacing="1" cellpadding="2"><tbody>';
  if (title?.length) {
    if (doubleHeader && subHeader?.length) {
      table += '<tr>';
      title.forEach((hdr, index) => {
        table += `<th colspan="2" style="font-weight:bold">${hdr}</th>`;
        if (index >= 2) {
          table += '<th colspan="2" style="font-weight:bold"></th>';
        }
      });
      table += '</tr>';
      table += '<tr>';
      table += '<th colspan="2" style="font-weight:bold"></th>';
      table += '<th colspan="2" style="font-weight:bold"></th>';
      subHeader.forEach((hdr) => {
        table += `<th style="font-weight:bold">${hdr[0]}</th>`;
        table += `<th style="font-weight:bold">${hdr[1]}</th>`;
      });
      table += '</tr>';
    } else {
      table += '<tr>';
      title.forEach((hdr) => {
        if (hdr === 'Description') {
          // BUmali this is a work around because width just wont work when rendered in excel.
          table += '<th style="font-weight:bold;">';
          let i = 1;
          while (i < 25) {
            table += '&nbsp;';
            i++;
          }
          table += `${hdr}`;
          i = 1;
          while (i < 25) {
            table += '&nbsp;';
            i++;
          }
          table += '</th>';
        } else {
          table += `<th style="font-weight:bold;">${hdr}</th>`;
        }
      });
      table += '</tr>';
    }
  }

  if (data?.length) {
    if (doubleHeader && subHeader?.length) {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value, index) => {
          if (index === 0 || index === 1) {
            table += `<td colspan="2" style="text-align:left">${value}</td>`;
          } else {
            table += `<td style="text-align:left">${value}</td>`;
          }
        });
        table += '</tr>';
      });
    } else {
      data.forEach((row) => {
        table += '<tr>';
        row.forEach((value) => {
          table += `<td style="text-align:left; vertical-align:top">${value}</td>`;
        });
        table += '</tr>';
      });
    }
  }

  table += '</tbody></table>';

  return table;
};
