import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useOmsRequestContext } from 'src/contexts/oms-request.context';
import { omsRequestActionTypes } from 'src/ducks/oms-request.duck';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import {
  IOmsData,
  IOmsListPage,
  IOmsRequestSubmitRefreshResponse,
} from 'src/models/oms-request.model';
import { getOmsRequestStatus } from 'src/selectors/oms-request.selector';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import OmsRequestListView from './oms-request-list.view';

type IProp = {
  omsResponse: IOmsRequestSubmitRefreshResponse[];
  setCheckboxes: React.Dispatch<React.SetStateAction<string[]>>;
  checkboxes: string[];
};

const OmsRequestList: React.FC<IProp> = ({
  omsResponse,
  setCheckboxes,
  checkboxes,
}) => {
  const { state, actions } = useOmsRequestContext();
  const [isOpen, setIsOpen] = useState<string>('');
  const [hasError, setHasError] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [savedParams, setSavedParams] = useState<IORGDataTableQueryState>();
  const {
    params: { outageDates },
  } = useLocationParams(['outageDates']);

  const status = getOmsRequestStatus(
    state,
    omsRequestActionTypes.OMS_REQUEST_LIST_READ
  );
  const acknowledging = getOmsRequestStatus(
    state,
    omsRequestActionTypes.OMS_REQUEST_DATA_ACKNOWLEDGE
  ).fetching;

  const handleFetch = useCallback(
    debounce((params: IORGDataTableQueryState) => {
      const fetch = async () => {
        sessionStorage.setItem(
          `${Lang.TTL_OMS_REQUEST}_Date`,
          outageDates
            ? params.date ?? new Date(outageDates)
            : params.date ?? new Date()
        );
        const { payload } = await actions.listGET({
          ...params,
          page: 1,
          limit: 0,
        });
        if (payload) {
          setSavedParams({ ...params, page: 1, limit: 0 });

          if (payload.error) {
            ToastError(payload.error);
          }
        } else {
          actions.clearList();
        }

        setCheckboxes([]);
        setHasError([]);
        setErrorMsg([]);
      };
      setImmediate(() => {
        fetch();
      });
    }, 1000),
    [actions, setCheckboxes, setHasError, setErrorMsg, setSavedParams]
  );

  const handleAcknowledge = useCallback(
    async (ackId) => {
      const response = await actions.acknowledgePUT(ackId);

      if (response.payload) {
        if (response.payload.message !== '') {
          ToastSuccess(`${ackId} - ${response.payload.message}`);
          setHasError([...hasError, ackId]);
          setErrorMsg([...errorMsg, `${ackId}_${response.payload.message}`]);
        } else {
          setHasError(hasError.filter((item) => item !== ackId));
          setErrorMsg(errorMsg.filter((item) => !item.includes(ackId)));
        }
        actions.listGET(savedParams);
      } else {
        ToastError(response.error.message);
        setHasError([]);
        setErrorMsg([]);
      }
    },
    [savedParams, actions, hasError, errorMsg, setHasError, setErrorMsg]
  );

  const handleCheckbox = useCallback(
    (checkboxName, checked = false) => {
      setCheckboxes((values) => {
        let array: string[] = [];
        if (!checked) {
          array = values.filter((item) => item !== checkboxName);
        } else {
          array = [...values];
          array.push(checkboxName);
        }
        return array;
      });
    },
    [setCheckboxes]
  );

  const mapData = (
    data: IOmsData[],
    results: IOmsRequestSubmitRefreshResponse[]
  ): IOmsListPage[] => {
    const newData: IOmsListPage[] = data.map((item): IOmsListPage => {
      const caisoResponse = results.filter(
        (result) => result.Outage.mktOrgOutageID === String(item.lerId)
      );
      const omsNotes =
        item.omsComparator.notes
          ?.replace('*** WEB NOTES ***', '')
          .replace('*** SDGE NOTES ***\n', '')
          .trim() ?? null;
      const lerNotes = item.lerComparator.notes?.trim() ?? null;
      return {
        ...item,
        omsComparator: {
          ...item.omsComparator,
          notes: omsNotes,
        },
        lerComparator: {
          ...item.lerComparator,
          notes: lerNotes,
        },
        caisoResponse: caisoResponse.length > 0 ? caisoResponse : undefined,
      };
    });
    return newData;
  };
  useEffect(() => {
    return () => {
      if (
        Moment().format('YYYY-MM-DD') ===
        Moment(sessionStorage.getItem(`${Lang.TTL_OMS_REQUEST}_Date`)).format(
          'YYYY-MM-DD'
        )
      ) {
        sessionStorage.removeItem(`${Lang.TTL_OMS_REQUEST}_Date`);
      }
    };
  }, []);

  return (
    <OmsRequestListView
      data={mapData(state.list, omsResponse)}
      isOpen={isOpen}
      omsResponse={omsResponse}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleCheckbox={handleCheckbox}
      setIsOpen={setIsOpen}
      handleAcknowledge={handleAcknowledge}
      acknowledging={acknowledging}
      hasError={hasError}
      errorMsg={errorMsg}
      checkboxes={checkboxes}
      outageDate={outageDates ? new Date(outageDates) : new Date()}
    />
  );
};

export default OmsRequestList;
