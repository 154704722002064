import React, { useRef, useState } from 'react';
import { ATMModal, ATMButton } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import RealTimeLogForm from 'src/components/pages/real-time-log/real-time-log-form/real-time-log-form.component';
import {
  IRealTimeLogEntryForm,
  IRealTimeLog,
} from 'src/models/real-time-log.model';

type IProps = {
  isAddOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IRealTimeLogEntryForm) => void;
  data?: IRealTimeLog;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
  // isForcedOutageSubmitted: boolean;
  // isForcedOutageConfirmOpen: boolean;
  // setIsForcedOutageConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // handleForcedOutageEmail: (logId: number) => void;
  // handleSendLater: (logId: number) => void;
};

const RealTimeLogAddView: React.FC<IProps> = ({
  isAddOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
  data,
  setTripOnEdit,
  // isForcedOutageSubmitted,
  // isForcedOutageConfirmOpen,
  // setIsForcedOutageConfirmOpen,
  // handleForcedOutageEmail,
  // handleSendLater,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const resetRef = useRef<any>();
  if (resetRef.current && loading === true) {
    resetRef.current();
  }

  return (
    <ATMModal
      open={isAddOpen}
      size="small"
      trigger={
        <ATMButton
          content="Add entry"
          onClick={() => handleOpen(true)}
          primary
          style={{ margin: 0 }}
        />
      }
    >
      <ATMModal.Header>{Lang.TTL_NEW_ENTRY_LOG}</ATMModal.Header>
      <ATMModal.Content style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <div>
          <RealTimeLogForm
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            defaultValues={{
              logUserType: data?.logUserType,
            }}
            setTripOnEdit={setTripOnEdit}
            // isForcedOutageSubmitted={isForcedOutageSubmitted}
            // isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
            // setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
            // handleForcedOutageEmail={handleForcedOutageEmail}
            // handleSendLater={handleSendLater}
            // logId={data?.logId}
          />
        </div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogAddView;
