import {
  equipmentTypeActionTypes,
  IEquipmentTypeState,
} from 'src/ducks/equipment-type.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getEquipmentTypeStatus = (
  state: IEquipmentTypeState,
  action: keyof typeof equipmentTypeActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
