import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'src/history';

const useStatusTab = (name) => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const currentTab = Number(params.get(name)) ?? 0;

  const handleTabChange = useCallback(
    (index, callback?: (data: URLSearchParams) => URLSearchParams) => {
      params.delete('page');

      if (callback) {
        params = callback(params);
      }

      if (index !== undefined) {
        params.set(name, index);
      } else {
        params.delete(name);
      }

      const url = `${location.pathname}?${params.toString()}`;
      history.push(url);
    },
    [location]
  );

  return { currentTab, pathname: location.pathname, params, handleTabChange };
};

export const useStatusTabV2 = (name, path?: string) => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const currentTab = Number(params.get(name)) ?? 0;
  const pathname = path || location.pathname;

  const handleTabChange = useCallback(
    (index?: any, callback?: (data: URLSearchParams) => URLSearchParams) => {
      if (callback) {
        params = callback(params);
      }

      const url = index ? `${pathname}?${name}=${index}` : `${pathname}`;
      history.push(url);

      setImmediate(() => {
        window.scrollTo(0, 0);
      });
    },
    [location]
  );

  return { currentTab, pathname: location.pathname, params, handleTabChange };
};

export default useStatusTab;
