import React, { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMButton,
  ATMTable,
  useATMFormContext,
  ATMFormProvider,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import {
  IShortDescription,
  IShortDescriptionCategory,
  IShortDescriptionCategoryForm,
  IShortDescriptionCategoryList,
  ShortDescriptionCategoryFormSchema,
} from 'src/models/short-description.model';
import CategoryDelete from '../category-delete/category-delete.component';
import style from './category-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IShortDescriptionCategoryList>;
  handleSubmit: (data: IShortDescriptionCategoryForm) => void;
  handleEnable: (value: boolean) => void;
  handleEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormContent: React.FC<{
  handleEnable: (value: boolean) => void;
  handleEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ handleEnable, handleEditOpen }) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useATMFormContext<IShortDescriptionCategoryList>();

  const [desc, setDesc] = useState<string | undefined>(undefined);

  const { fields, append, remove } = useFieldArray({
    name: 'descriptions',
    control,
  });

  const handleAdd = useCallback(() => {
    const descExists = getValues().descriptions?.find((description) => {
      return description.shortDesc === desc?.trim();
    });
    const list = getValues().descriptions;
    if (descExists === undefined) {
      remove();
      list?.map((val) => {
        append(val);
      });
      append({
        catId: getValues().catId,
        descId: 0,
        shortDesc: desc?.trim() ?? '',
        updatedAt: undefined,
        updatedBy: undefined,
      });
      setDesc(undefined);
      handleEnable(true);
    } else {
      ToastError(
        Lang.formatString(
          Lang.MSG_SHORT_DESCRIPTION_CATEGORY_EXISTS,
          desc ?? ''
        )
      );
    }
  }, [fields, append, desc, remove, setDesc]);
  return (
    <>
      <ATMGrid.Row columns="equal">
        <ATMGrid.Column>
          <ATMField
            as={ATMInput}
            name="catId"
            control={control}
            type="hidden"
            className="hidden"
          />
          <ATMField
            label={<LabelRequired>{Lang.LBL_CATEGORY_NAME}</LabelRequired>}
            as={ATMInput}
            name="shortDescCat"
            control={control}
            error={errors.shortDescCat}
            onChange={([_, { value }]) => (value === '' ? undefined : value)}
          />
        </ATMGrid.Column>
        <ATMGrid.Column />
      </ATMGrid.Row>
      <ATMGrid.Row columns="equal">
        <ATMGrid.Column width={8}>
          <ATMField
            label={Lang.LBL_DESCRIPTION_NAME}
            key={`${fields.length}`}
            as={ATMInput}
            name="desc"
            error={errors.descriptions}
            onChange={
              ((_, { value }) => {
                setDesc(value);
                return value === '' ? undefined : value;
              }) as any
            }
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={2}>
          <ATMButton
            type="button"
            style={{ marginTop: '1.5em' }}
            size="small"
            secondary
            content={Lang.LBL_ADD}
            onClick={handleAdd}
            disabled={!desc}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns="1">
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>{Lang.LBL_DESCRIPTIONS}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_ACTIONS}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.length === 0 ? (
              <ATMTable.Row>
                <ATMTable.Cell colspan="2">
                  <div className={style.noDataTable}>
                    {Lang.LBL_NO_DESCRIPTION_ADDED}
                  </div>
                </ATMTable.Cell>
              </ATMTable.Row>
            ) : (
              fields &&
              fields.length > 0 &&
              fields.map((item, i) => (
                <ATMTable.Row key={item.id}>
                  <ATMTable.Cell width="15">
                    <ATMField
                      as={ATMInput}
                      control={control}
                      name={`descriptions[${i}].descId`}
                      defaultValue={item.descId}
                      type="hidden"
                      className="hidden"
                    />
                    <ATMField
                      as={ATMInput}
                      control={control}
                      name={`descriptions[${i}].catId`}
                      defaultValue={item.catId}
                      type="hidden"
                      className="hidden"
                    />
                    <ATMField
                      as={ATMInput}
                      control={control}
                      name={`descriptions[${i}].shortDesc`}
                      defaultValue={item.shortDesc}
                      type="hidden"
                      className="hidden"
                    />
                    {item.shortDesc}
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    {item.descId === 0 || !item.descId ? (
                      <ATMButton
                        icon="trash alternate outline"
                        onClick={() => remove(i)}
                        type="button"
                      />
                    ) : (
                      <CategoryDelete
                        data={
                          {
                            ...item,
                            ...getValues(),
                          } as unknown as IShortDescription &
                            IShortDescriptionCategory
                        }
                        isDesc
                        handleEditOpen={handleEditOpen}
                      />
                    )}
                  </ATMTable.Cell>
                </ATMTable.Row>
              ))
            )}
          </ATMTable.Body>
        </ATMTable>
      </ATMGrid.Row>
    </>
  );
};

const CategoryForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  handleEditOpen,
}) => (
  <ATMForm
    ref={formRef}
    onSubmit={handleSubmit}
    mode="onChange"
    defaultValues={defaultValues}
    validationSchema={ShortDescriptionCategoryFormSchema}
  >
    {(props) => {
      if (defaultValues) {
        handleEnable(props.formState.isDirty);
      }
      return (
        <ATMGrid>
          <ATMFormProvider {...props}>
            <FormContent
              handleEnable={handleEnable}
              handleEditOpen={handleEditOpen}
            />
          </ATMFormProvider>
        </ATMGrid>
      );
    }}
  </ATMForm>
);

export default CategoryForm;
