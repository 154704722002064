import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TroubleTicketReducer, {
  ITroubleTicketState,
  defaultState,
  duckActions,
} from 'src/ducks/trouble-ticket.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TroubleTicketReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITroubleTicketContext = ReturnType<typeof useReducer>;

const TroubleTicketContext = createContext<Partial<ITroubleTicketContext>>({
  state: defaultState,
}) as React.Context<ITroubleTicketContext>;

type IProps = {
  state?: Partial<ITroubleTicketState>;
};

const TroubleTicketProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TroubleTicketContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TroubleTicketContext.Provider>
  );
};

const useTroubleTicketContext = () => useContext(TroubleTicketContext);

export type IUseTroubleTicketContext = ReturnType<
  typeof useTroubleTicketContext
>;

export { TroubleTicketContext, TroubleTicketProvider, useTroubleTicketContext };
