import React, { useState } from 'react';
import {
  ATMSegment,
  ATMGrid,
  ATMMessage,
  ATMIcon,
  ATMHeader,
  ATMTable,
  ATMField,
  ATMLabel,
  ATMTextArea,
  ATMButton,
} from 'shared-it-appmod-ui';
import { Breadcrumb } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import style from './email-change-request-link-page.module.scss';

const emailSubheaderSections = [
  {
    key: 'modify',
    content: '2324 Colorado River - Palo Verde',
    link: false,
  },
  {
    key: 'add',
    content: <ATMLabel color="blue">Submitted</ATMLabel>,
    link: false,
  },
  {
    key: 'modify',
    content: 'OK - Start Progress',
    link: false,
  },
];

const EmailChangeRequestLinkPageView: React.FC = () => {
  const [approvedStatus, setApprovedStatus] = useState(false);
  return (
    <>
      <ATMSegment className={style.emailHeaderTitle}>
        <h4>LER 29303</h4>
        <Breadcrumb
          divider=" | "
          size="small"
          sections={emailSubheaderSections}
        />
      </ATMSegment>
      <ATMSegment className={style.mainSegment}>
        <ATMGrid columns={2}>
          <ATMGrid.Row>
            <ATMGrid.Column width={10} className={style.divider}>
              <div>
                <ATMMessage color="blue">
                  Please review the LER and <b>Approve</b> or <b>Reject</b> in
                  the section below
                </ATMMessage>
                <ATMHeader className={style.headerIcon}>
                  <span className={style.titleMargin}>Change Request View</span>
                  <ATMGrid columns={1} className={style.tableMargin}>
                    <ATMGrid.Row>
                      <ATMGrid.Column width={16}>
                        <ATMTable className={style.documentSection}>
                          <ATMTable.Header>
                            <ATMTable.Row>
                              <ATMTable.HeaderCell width={6}>
                                Fields
                              </ATMTable.HeaderCell>
                              <ATMTable.HeaderCell textAlign="left" width={5}>
                                Version 3 (Current Version)
                              </ATMTable.HeaderCell>
                              <ATMTable.HeaderCell textAlign="left" width={5}>
                                Change Request
                              </ATMTable.HeaderCell>
                            </ATMTable.Row>
                          </ATMTable.Header>
                          <ATMTable.Body>
                            <ATMTable.Row>
                              <ATMTable.Cell textAlign="right">
                                <p className={style.titleCellPharagraph}>
                                  <b>Facility Information</b>
                                </p>
                                <p className={style.subtitleCellPharagraph}>
                                  Otage Type
                                </p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionPharagraph}>OK</p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionPharagraph}>Info</p>
                              </ATMTable.Cell>
                            </ATMTable.Row>
                            <ATMTable.Row>
                              <ATMTable.Cell textAlign="right">
                                <p className={style.titleCell1Pharagraph}>
                                  <b>Job Information</b>
                                </p>
                                <p className={style.subtitleCell1Pharagraph}>
                                  Short Description
                                </p>
                                <p className={style.subtitle2Cell1Pharagraph}>
                                  Radialized Substations
                                </p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionPharagraph1}>
                                  Description 1
                                </p>
                                <p className={style.versionPharagraph11}>
                                  <span>ALPINE</span> <span>BORREGO</span>
                                </p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionPharagraph1}>
                                  Description 1
                                </p>
                                <p className={style.versionPharagraph11}>
                                  <span>ALPINE</span> <span>BORREGO</span>{' '}
                                  <span>AVOCADO</span>
                                </p>
                              </ATMTable.Cell>
                            </ATMTable.Row>
                            <ATMTable.Row>
                              <ATMTable.Cell textAlign="right">
                                <p className={style.titleCell2Pharagraph}>
                                  <b>Outages Dates</b>
                                </p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionTitleDates}>
                                  <span>Date</span> <span>Starts</span>{' '}
                                  <span>Ends</span>
                                </p>
                                <p className={style.dates}>
                                  <span>3/10/2022</span> <span>8:00</span>{' '}
                                  <span>15:00</span>
                                </p>
                                <p className={style.dates}>
                                  <span>3/10/2022</span> <span>8:00</span>{' '}
                                  <span>15:00</span>
                                </p>
                                <p className={style.dates}>
                                  <span>3/10/2022</span> <span>8:00</span>{' '}
                                  <span>15:00</span>
                                </p>
                              </ATMTable.Cell>
                              <ATMTable.Cell>
                                <p className={style.versionTitleDates}>
                                  <span>Date</span> <span>Starts</span>{' '}
                                  <span>Ends</span>
                                </p>
                                <p className={style.dates}>
                                  <span>2/12/2022</span> <span>0:00</span>{' '}
                                  <span>24:00</span>
                                </p>
                                <p className={style.dates}>
                                  <span>2/16/2022</span> <span>0:00</span>{' '}
                                  <span>15:00</span>
                                </p>
                                <p className={style.dates}>
                                  <span>2/16/2022</span> <span>0:00</span>{' '}
                                  <span>15:00</span>
                                </p>
                              </ATMTable.Cell>
                            </ATMTable.Row>
                          </ATMTable.Body>
                        </ATMTable>
                      </ATMGrid.Column>
                      {!approvedStatus ? (
                        <ATMGrid.Column
                          width={10}
                          className={style.tableMargin}
                        >
                          <ATMField
                            as={ATMTextArea}
                            label={Lang.LBL_COMMENTS}
                            placeholder="Enter"
                            name="comment"
                          />
                          <ATMButton
                            secondary
                            negative
                            content="Reject Request"
                          />
                          <ATMButton
                            color="green"
                            content="Accept Request"
                            onClick={() => setApprovedStatus(true)}
                          />
                        </ATMGrid.Column>
                      ) : (
                        ''
                      )}
                    </ATMGrid.Row>
                  </ATMGrid>
                </ATMHeader>
              </div>
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <div className={style.facilitySectionMargin}>
                <ATMHeader className={style.headerIcon}>
                  <ATMIcon
                    circular
                    size="small"
                    color="blue"
                    name="building outline"
                    className={style.iconSize}
                  />
                  <span className={style.titleMargin}>
                    {Lang.TTL_FACILITY_INFORMATION}
                  </span>
                </ATMHeader>
                <ATMGrid columns={2} className={style.gridMargin}>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_FACILITY_TYPE}
                      </p>
                      <span className={style.bolder}>Line</span>
                    </ATMGrid.Column>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_KV}
                      </p>
                      <span className={style.bolder}>138</span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_EQUIPMENT}
                      </p>
                      <span className={style.bolder}>Electronic Device</span>
                    </ATMGrid.Column>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_LINE_STATION}
                      </p>
                      <span className={style.bolder}>
                        2324 Colorado River - Palo Verde
                      </span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_AUTHORIZATION}
                      </p>
                      <span className={style.bolder}>OK - Start Program</span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                </ATMGrid>
                <hr />
              </div>
              <div>
                <ATMHeader className={style.headerIcon}>
                  <ATMIcon
                    circular
                    size="small"
                    color="blue"
                    name="file outline"
                    className={style.iconSize}
                  />
                  <span className={style.titleMargin}>
                    {Lang.TTL_DOCUMENTS}
                  </span>
                </ATMHeader>
                <ATMTable className={style.documentSection}>
                  <ATMTable.Header>
                    <ATMTable.Row>
                      <ATMTable.HeaderCell width={6}>Name</ATMTable.HeaderCell>
                      <ATMTable.HeaderCell textAlign="left" width={4}>
                        Uploaded By
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell textAlign="left" width={2}>
                        Action
                      </ATMTable.HeaderCell>
                    </ATMTable.Row>
                  </ATMTable.Header>
                  <ATMTable.Body>
                    <ATMTable.Row>
                      <ATMTable.Cell>Technical spec.doc</ATMTable.Cell>
                      <ATMTable.Cell>Black, Bill</ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMIcon name="download" />
                      </ATMTable.Cell>
                    </ATMTable.Row>
                    <ATMTable.Row>
                      <ATMTable.Cell>Technical spec_2.doc</ATMTable.Cell>
                      <ATMTable.Cell>Black, Bill</ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMIcon name="download" />
                      </ATMTable.Cell>
                    </ATMTable.Row>
                  </ATMTable.Body>
                </ATMTable>
                <hr />
              </div>
              {/* Validate state on integration to approve the status of the request */}
              {approvedStatus ? (
                <div className={style.approvalStatus}>
                  <ATMHeader className={style.headerIcon}>
                    <ATMIcon
                      circular
                      size="small"
                      color="blue"
                      name="tasks"
                      className={style.iconSize}
                    />
                    <span className={style.titleMargin}>
                      {Lang.TTL_APPROVAL_STATUS}
                    </span>
                  </ATMHeader>
                  <ATMGrid columns={3} className={style.gridRowSpace}>
                    <ATMGrid.Row>
                      <ATMGrid.Column width={4}>
                        <p className={style.columnPSpace}>
                          {Lang.LBL_APPROVAL_STATUS_APPROVER}
                        </p>
                        <span>Wooden, Victor</span>
                      </ATMGrid.Column>
                      <ATMGrid.Column width={4}>
                        <p className={style.columnPSpace}>
                          {Lang.LBL_APPROVAL_STATUS_STATUS}
                        </p>
                        <span className={style.approvedColumn}>
                          <ATMLabel
                            circular
                            color="green"
                            inline
                            empty
                            className={style.approvedLabel}
                          />
                          Approved
                        </span>
                        <span className={style.approvedDateMargin}>
                          02/05/2021
                        </span>
                      </ATMGrid.Column>
                      <ATMGrid.Column width={4}>
                        <p className={style.columnPSpace}>
                          {Lang.LBL_APPROVAL_STATUS_DATE_REQUESTED}
                        </p>
                        <span>02/03/2021</span>
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row>
                      <ATMGrid.Column width={16}>
                        <p className={style.columnPSpace}>
                          {Lang.LBL_APPROVAL_STATUS_COMMENTS}
                        </p>
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </span>
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  </ATMGrid>
                </div>
              ) : (
                ''
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
    </>
  );
};

export default EmailChangeRequestLinkPageView;
