/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';
import {
  ATMButton,
  ATMHeader,
  ATMIcon,
  ATMLabel,
  ATMLoader,
  ATMTable,
} from 'shared-it-appmod-ui';
import {
  AccessRole,
  LERRequestStatus,
  LerRequestStatusOMS,
} from 'src/constants';
import {
  getLerOutageDateList,
  revertPeriodTimeAdjustments,
} from 'src/helpers/ler-request.helper';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import Moment, { format24hTime } from 'src/libraries/moment.library';
import Empty from 'src/components/atoms/empty/empty.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import styles from './ler-request-outage-date-detail.module.scss';

type IProps = {
  id: number;
};

const LerRequestOutageDateDetail: React.FC<IProps> = ({ id }) => {
  const {
    state: { data, caisoList, ...state },
    actions,
  } = useLerRequestContext();

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CAISO_READ
  );

  const fetchCAISOData = useCallback(
    throttle(async () => {
      if (
        data &&
        (data.requestStat === LERRequestStatus.Approved ||
          data.requestStat === LERRequestStatus.Scheduled ||
          data.requestStat === LERRequestStatus.Completed) &&
        (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ||
          hasRole(AccessRole.MCC_REAL_TIME_OPERATOR)) &&
        !status.fetching
      ) {
        await actions.dataCaisoGET(id);
      }
    }, 5000),
    [data, id, status, actions]
  );

  useEffect(() => {
    fetchCAISOData();
  }, []);

  return (
    <>
      <span>
        <ATMIcon circular name="calendar outline" />
      </span>
      <ATMHeader
        as="h2"
        content={`${Lang.TTL_OUTAGE_DATES} (${Lang.TTL_CREW_TIME})`}
        // content={customOutageDateHeading(Lang.TTL_OUTAGE_DATES)}
        className={styles.header}
      />
      <ATMButton
        className={styles.refresh}
        type="button"
        secondary
        content="Refresh"
        onClick={() => fetchCAISOData()}
        disabled={status.fetching}
        loading={status.fetching}
      />
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_STARTS}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_ENDS}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_LER_STATUS}</ATMTable.HeaderCell>
            {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && (
              <>
                <ATMTable.HeaderCell>{Lang.LBL_OMS_STATUS}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_OMS_ID}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_RECONCILE_STATUS}
                </ATMTable.HeaderCell>
              </>
            )}
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data && data.outageDates.length > 0 ? (
            getLerOutageDateList(data.outageDates || []).map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>
                  {Moment(item.startTm).format('MM/DD/YYYY')}
                  <span className={styles.time}>
                    {format24hTime(item.startTm)}
                  </span>
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {Moment(item.stopTm).format('MM/DD/YYYY')}
                  <span className={styles.time}>
                    {format24hTime(item.stopTm)}
                  </span>
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMLabel className={styles.labelBlue}>
                    {Lang.LER_REQUEST_STATUS[item.reqStatId ?? 0]}
                  </ATMLabel>
                </ATMTable.Cell>
                {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && (
                  <>
                    <ATMTable.Cell>
                      <ATMLabel className={styles.labelBlue}>
                        {caisoList && caisoList.length > 0
                          ? caisoList
                              .filter(
                                (val) =>
                                  val.OutageChangeRequest.action !== 'CANCEL'
                              )
                              .find((val) => {
                                return (
                                  Moment(
                                    revertPeriodTimeAdjustments(
                                      val.estimatedPeriod,
                                      data.jobInformation.switchOutMin,
                                      data.jobInformation.switchInMin
                                    )?.start
                                  )
                                    .startOf('day')
                                    .toString() ===
                                  Moment(item.startTm).startOf('day').toString()
                                );
                              })?.OutageChangeRequest.status ===
                            LerRequestStatusOMS.PENDING
                            ? `${
                                caisoList
                                  .filter(
                                    (val) =>
                                      val.OutageChangeRequest.action !==
                                      'CANCEL'
                                  )
                                  .find((val) => {
                                    return (
                                      Moment(
                                        revertPeriodTimeAdjustments(
                                          val.estimatedPeriod,
                                          data.jobInformation.switchOutMin,
                                          data.jobInformation.switchInMin
                                        )?.start
                                      )
                                        .startOf('day')
                                        .toString() ===
                                      Moment(item.startTm)
                                        .startOf('day')
                                        .toString()
                                    );
                                  })?.OutageChangeRequest.status
                              } - ${
                                caisoList
                                  .filter(
                                    (val) =>
                                      val.OutageChangeRequest.action !==
                                      'CANCEL'
                                  )
                                  .find((val) => {
                                    return (
                                      Moment(
                                        revertPeriodTimeAdjustments(
                                          val.estimatedPeriod,
                                          data.jobInformation.switchOutMin,
                                          data.jobInformation.switchInMin
                                        )?.start
                                      )
                                        .startOf('day')
                                        .toString() ===
                                      Moment(item.startTm)
                                        .startOf('day')
                                        .toString()
                                    );
                                  })?.outageStatus
                              }`
                            : caisoList
                                .filter(
                                  (val) =>
                                    val.OutageChangeRequest.action !== 'CANCEL'
                                )
                                .find((val) => {
                                  return (
                                    Moment(
                                      revertPeriodTimeAdjustments(
                                        val.estimatedPeriod,
                                        data.jobInformation.switchOutMin,
                                        data.jobInformation.switchInMin
                                      )?.start
                                    )
                                      .startOf('day')
                                      .toString() ===
                                    Moment(item.startTm)
                                      .startOf('day')
                                      .toString()
                                  );
                                })?.outageStatus ?? Lang.LBL_UNAVAILABLE
                          : Lang.LBL_UNAVAILABLE}
                      </ATMLabel>
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      {caisoList && caisoList.length > 0 ? (
                        caisoList
                          .filter(
                            (val) => val.OutageChangeRequest.action !== 'CANCEL'
                          )
                          .find((val) => {
                            return (
                              Moment(
                                revertPeriodTimeAdjustments(
                                  val.estimatedPeriod,
                                  data.jobInformation.switchOutMin,
                                  data.jobInformation.switchInMin
                                )?.start
                              )
                                .startOf('day')
                                .toString() ===
                              Moment(item.startTm).startOf('day').toString()
                            );
                          })?.mRID ?? Lang.LBL_UNAVAILABLE
                      ) : status.fetching ? (
                        <ATMLoader inline="centered" active size="small" />
                      ) : (
                        Lang.LBL_UNAVAILABLE
                      )}
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMIcon name="check circle" size="large" color="olive" />
                      <a
                        role="button"
                        className={styles.underlined}
                        onClick={() =>
                          window.open(
                            `/outage-planning?tab=2&outageDates=${Moment(
                              item.startTm
                            ).format('MM/DD/YYYY')}`
                          )
                        }
                      >
                        {Lang.LBL_VIEW_DETAIL}
                      </a>
                    </ATMTable.Cell>
                  </>
                )}
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell
                colspan={hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? 6 : 3}
              >
                <Empty />
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );
};

export default LerRequestOutageDateDetail;
