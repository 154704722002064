import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal, ATMPopover } from 'shared-it-appmod-ui';
import Access from 'src/components/atoms/access/access.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  AccessRole,
  AccessType,
  LERRequestAction,
  LERRequestChangeStatus,
  LERRequestPage,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { isLerRequestExpired } from 'src/helpers/ler-request.helper';
// import { hasChangeRequest } from 'src/helpers/ler-request.helper';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';

type IProps = {
  data: ILerRequest;
  trigger?: React.ReactNode;
  handleSuccess?: () => void;
  // handlePostApproval?: () => void;
  isChangeRequest?: boolean;
  printLoader?: boolean;
};

const LerRequestApprove: React.FC<IProps> = ({
  data,
  trigger,
  handleSuccess,
  isChangeRequest = false,
  printLoader = false,
}) => {
  const { state, actions } = useLerRequestContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { handleChange } = useLocationParams([LERRequestPage.View]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  // const isChangeRequest = hasChangeRequest(data);

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE
  );
  const changeStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE
  );
  const updateStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_UPDATE
  );

  const handleApproveCR = useCallback(async () => {
    const result = await actions.crStatusPUT(
      data.requestId,
      LERRequestAction.ApproveChangeRequest
    );

    if (result.payload) {
      if (result.payload?.outageEmail) {
        if (result.payload?.outageEmail?.successOutageEmail?.length) {
          result.payload?.outageEmail?.successOutageEmail.map((emailOutage) => {
            ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
          });
        }
        if (result.payload?.outageEmail?.failedOutageEmail?.length) {
          result.payload?.outageEmail?.failedOutageEmail.map((emailOutage) => {
            ToastError(emailOutage?.message ?? 'Failed to send email');
          });
        }
      }
      if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
        ToastSuccess(Lang.MSG_LER_REQUEST_APPROVE_CR_SCHEDULER_SUCCESS);
      } else if (
        hasRole([
          AccessRole.MCC_SUBMITTING_SUPERVISOR,
          AccessRole.MCC_OPERATIONAL_ENGINEER,
        ])
      ) {
        ToastSuccess(Lang.MSG_LER_REQUEST_APPROVE_CR_SUPERVISOR_SUCCESS);
      }

      if (
        (result.payload.changeReqStat === LERRequestChangeStatus.Approved ||
          result.payload.changeReqStat === null) &&
        (result.payload.requestStat === LERRequestStatus.Approved ||
          result.payload.requestStat === LERRequestStatus.Scheduled)
      ) {
        if (
          !facilityState.lerUserGrp ||
          facilityState.outgFacId !== result.payload?.outageFacility?.outgFacId
        ) {
          await facilityActions.userGrpDetails(
            result.payload?.outageFacility?.outgFacId ?? 0
          );
        }
        setIsOpen(false);
        setImmediate(() => {
          actions.setForApproval(true);
          actions.setIsFromReject(false);
          actions.setIsFromPendingCaiso(false);
          // consider this as true because there is an update made if this is a change request
          actions.setIsFromUpdate(true);
        });
      } else {
        setIsOpen(false);
        handleChange();
      }
    }
  }, [data, actions, setIsOpen]);

  const handleApprove = useCallback(async () => {
    const result = await actions.statusPUT(
      data.requestId,
      hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
        ? LERRequestAction.Approve
        : LERRequestAction.Submit
    );

    if (result.payload) {
      setIsConfirm(false);
      ToastSuccess(Lang.MSG_LER_REQUEST_APPROVE_SUCCESS);
      if (result.payload?.outageEmail) {
        if (result.payload?.outageEmail?.successOutageEmail?.length) {
          result.payload?.outageEmail?.successOutageEmail.map((emailOutage) => {
            ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
          });
        }
        if (result.payload?.outageEmail?.failedOutageEmail?.length) {
          result.payload?.outageEmail?.failedOutageEmail.map((emailOutage) => {
            ToastError(emailOutage?.message ?? 'Failed to send email');
          });
        }
      }

      if (
        !facilityState.lerUserGrp ||
        facilityState.outgFacId !== result.payload?.outageFacility?.outgFacId
      ) {
        await facilityActions.userGrpDetails(
          result.payload?.outageFacility?.outgFacId ?? 0
        );
      }
      if (result.payload?.requestStat === LERRequestStatus.Approved) {
        setImmediate(() => {
          actions.setForApproval(true);
          actions.setIsFromReject(false);
          actions.setIsFromUpdate(false);
          actions.setIsFromPendingCaiso(false);
        });
      }

      if (handleSuccess) {
        handleSuccess();
      }
    }
  }, [setIsConfirm, data, actions, handleSuccess]);

  const loading =
    status.fetching || changeStatus.fetching || updateStatus.fetching;

  if (isChangeRequest) {
    // #US 681032 Allow Scheduler to Approve and Reject Change Request even if
    // on Change Requested state given that the LER has been initially Scheudled/Approved.
    // Remove Supervisor flow of approval of CR if LER is already scheduled/approved.
    return (
      <Access
        type={AccessType.OUTAGE_REQUEST_CHANGE_APPROVE}
        when={
          (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
            (data.changeReqStat === LERRequestChangeStatus.Submitted ||
              ((data.requestStat === LERRequestStatus.Scheduled ||
                data.requestStat === LERRequestStatus.Approved) &&
                data.changeReqStat === LERRequestChangeStatus.Requested))) ||
          (hasRole(AccessRole.MCC_SUBMITTING_SUPERVISOR) &&
            ![LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
              data.requestStat as LERRequestStatus
            ) &&
            data.changeReqStat === LERRequestChangeStatus.Requested)
        }
      >
        <ATMModal
          open={isOpen}
          size="tiny"
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          trigger={
            trigger || (
              <ATMButton
                positive
                type="button"
                content={Lang.LBL_APPROVE}
                disabled={loading || printLoader}
                loading={loading || printLoader}
              />
            )
          }
        >
          <ATMModal.Header>
            {isChangeRequest
              ? Lang.TTL_OUTAGE_REQUEST_CHANGE_APPROVE
              : Lang.TTL_OUTAGE_REQUEST_APPROVE}
          </ATMModal.Header>
          <ATMModal.Content>
            <p>{Lang.MSG_LER_REQUEST_CONFIRM_APPROVE}</p>
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              type="button"
              secondary
              content="Cancel"
              onClick={() => setIsOpen(false)}
            />
            <ATMButton
              type="button"
              primary
              content={Lang.LBL_APPROVE_REQUEST}
              onClick={handleApproveCR}
              disabled={loading}
              loading={loading}
            />
          </ATMModal.Actions>
        </ATMModal>
      </Access>
    );
  }

  const disabled =
    hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && !data.isoTrs.isoWorkKindId;

  return (
    <>
      <Access
        type={AccessType.OUTAGE_REQUEST_APPROVE}
        when={
          ((hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
            [LERRequestStatus.Submitted, LERRequestStatus.Study].includes(
              data.requestStat as LERRequestStatus
            )) ||
            (hasRole([
              AccessRole.MCC_SUBMITTING_SUPERVISOR,
              AccessRole.MCC_OPERATIONAL_ENGINEER,
            ]) &&
              data.requestStat === LERRequestStatus.Created)) &&
          isLerRequestExpired(data.outageDates) === false
        }
      >
        <>
          <Confirm
            open={isConfirm}
            size="small"
            onConfirm={handleApprove}
            onOpen={() => setIsConfirm(true)}
            onCancel={() => setIsConfirm(false)}
            trigger={
              trigger ||
              (disabled ? (
                <ATMPopover
                  content="Please complete the required fields"
                  size="mini"
                  trigger={
                    <div>
                      <ATMButton
                        positive
                        type="button"
                        content={Lang.LBL_APPROVE}
                        disabled
                        loading={loading}
                      />
                    </div>
                  }
                />
              ) : (
                <ATMButton
                  positive
                  type="button"
                  content={Lang.LBL_APPROVE}
                  disabled={loading}
                  loading={loading}
                />
              ))
            }
            header={Lang.TTL_LER_REQUEST_APPROVE}
            content={Lang.MSG_LER_REQUEST_APPROVE_CONFIRMATION}
            loading={status.fetching}
          />
        </>
      </Access>
    </>
  );
};

export default LerRequestApprove;
