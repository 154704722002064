import React, { useCallback } from 'react';
import html2pdf from 'html2pdf.js';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import Lang from 'src/libraries/language';
import Moment, { IMoment } from 'src/libraries/moment.library';
import {
  getSystemChangeHTML,
  getSystemChangeStatus,
} from 'src/selectors/system-change.selector';
import { ATMButton } from 'shared-it-appmod-ui';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';

export enum SystemChangeActionType {
  Print = 'print',
  Download = 'download',
}

type IProps = {
  date?: IMoment | Date;
  type?: string;
};

const SystemChangePDF: React.FC<IProps> = ({
  date,
  type = SystemChangeActionType.Print,
}) => {
  const { state } = useSystemChangeContext();
  const status = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ
  );

  const list = date
    ? state.list.filter((v) =>
        Moment(v.inServiceDate).isSame(Moment(date), 'day')
      )
    : state.list;

  const handleDownload = useCallback(() => {
    const form = getSystemChangeHTML(list);

    const lerDetailsForm = new DOMParser().parseFromString(form, 'text/html');
    const element = lerDetailsForm.getElementById('element-to-print');
    const options = {
      margin: 0.5,
      filename: `MCC_System_Change_${Moment().format('YYYYMMDD')}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      pagebreak: {
        mode: 'avoid-all',
        before: list.map((_, i) => `#page${i + 1}`),
      },
    };
    html2pdf(element, options);
  }, [list]);

  const handlePrint = useCallback(() => {
    const form = getSystemChangeHTML(list);
    const lerDetailsForm = new DOMParser().parseFromString(form, 'text/html');
    const element = lerDetailsForm.getElementById('element-to-print');
    const options = {
      margin: 0.5,
      filename: `MCC_System_Change_${Moment().format('YYYYMMDD')}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      pagebreak: {
        mode: 'avoid-all',
        before: list.map((_, i) => `#page${i + 1}`),
      },
    };
    html2pdf()
      .set(options)
      .from(element)
      .outputPdf()
      .get('pdf')
      .then((pdfObj) => {
        pdfObj.autoPrint();
        window.open(pdfObj.output('bloburl'), '_blank');
      });
  }, [list]);

  return (
    <ATMButton
      content={
        type === SystemChangeActionType.Download
          ? Lang.LBL_SAVE_AS_PDF
          : Lang.LBL_PRINT
      }
      secondary
      onClick={
        type === SystemChangeActionType.Download ? handleDownload : handlePrint
      }
      disabled={status.fetching}
      loading={status.fetching}
    />
  );
};

export default SystemChangePDF;
