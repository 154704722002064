import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  IORGDataTableQueryState,
  IATMTabProps,
  ATMMenu,
  ATMTab,
  ATMButton,
} from 'shared-it-appmod-ui';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import { getIsAdmin } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants';
import { ITroubleJobs } from 'src/models/trouble-jobs.model';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import { getTroubleJobsStatus } from 'src/selectors/trouble-jobs.selector';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import TroubleJobsAdd from '../trouble-jobs-add/trouble-jobs-add.component';
import TroubleJobListTables from './trouble-jobs-list-tables.component';
import TroubleJobsAllList from './trouble-job-list-all/trouble-job-list-all.component';
import styles from './trouble-jobs.module.scss';

type IProps = {
  data: ITroubleJobs[];
  loading: boolean;
  showStatus: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  openTotal: number;
  currentTab: number;
  openAddPanel: () => void;
  isOpenAdd: boolean;
  setIsOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddStationAlarm: () => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
  totalAll: number;
  setTotalAll: React.Dispatch<React.SetStateAction<number>>;
};

const TroubleJobsListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  openTotal,
  currentTab,
  openAddPanel,
  isOpenAdd,
  setIsOpenAdd,
  handleAddStationAlarm,
  showStatus,
  handleDownload,
  setTotalAll,
}) => {
  const { state: troubleJobState } = useTroubleJobsContext();

  const status = getTroubleJobsStatus(
    troubleJobState,
    troubleJobsActionTypes.TROUBLE_JOBS_DATA_ALARM_CREATE
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">Open ({troubleJobState.openTotal})</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <TroubleJobListTables
            data={data}
            handleFetch={handleFetch}
            handleDownload={handleDownload}
            total={troubleJobState.total}
            openTotal={openTotal}
            loading={loading}
            currentTab={currentTab}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_ALL} ({troubleJobState.total})
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <TroubleJobsAllList
            handleDownload={handleDownload}
            openTotal={openTotal}
            showStatus={showStatus}
            loading={loading}
            currentTab={currentTab}
            total={troubleJobState.total}
            setTotal={setTotalAll}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  return (
    <div className="admin-container">
      <div className="admin-list-container">
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle">
            <ATMHeader>{Lang.LBL_TROUBLE_JOB}</ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right" className={styles.listBtn}>
            {getIsAdmin(AccessRole.TROUBLE_JOB_UPDATER) && (
              <>
                <ATMButton
                  secondary
                  size="small"
                  icon="plus"
                  floated="right"
                  content={Lang.LBL_CREATE_STATION_ALARM_TROUBLE_JOB}
                  onClick={handleAddStationAlarm}
                  loading={status.fetching}
                />
                <TroubleJobsAdd
                  isOpenAdd={isOpenAdd}
                  openAddPanel={openAddPanel}
                  setIsOpenAdd={setIsOpenAdd}
                />
              </>
            )}
          </ATMGrid.Column>
        </ATMGrid>
        <div className={styles.headerTabs}>
          <Tabs
            name="troubleJobsStatus"
            menu={{ pointing: true }}
            panes={panes}
            renderActiveOnly
            isClean
          />
        </div>
      </div>
    </div>
  );
};

export default TroubleJobsListView;
