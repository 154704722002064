import { requestStatusActionTypes, IRequestStatusState } from 'src/ducks/request-status.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getRequestStatusStatus = (
  state: IRequestStatusState,
  action: keyof typeof requestStatusActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
