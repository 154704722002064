import React, { useCallback, useEffect, useState } from 'react';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import useLocationTab from 'src/hooks/location-tab.hook';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  getTroubleJobParams,
  getTroubleJobsStatus,
} from 'src/selectors/trouble-jobs.selector';
import TroubleJobsListAllView from './trouble-job-list-all.view';

type IProps = {
  loading: boolean;
  showStatus: boolean;
  openTotal: number;
  currentTab: number;
  handleDownload: (params: IORGDataTableQueryState) => void;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
};

const TroubleJobsAllList: React.FC<IProps> = ({
  total,
  setTotal,
  handleDownload,
}) => {
  const { state, actions } = useTroubleJobsContext();
  const [showNotRecord, setShowNotRecord] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { currentTab } = useLocationTab('troubleJobsStatus');
  const status = getTroubleJobsStatus(
    state,
    troubleJobsActionTypes.TROUBLE_JOB_LIST_ALL_READ
  );

  useEffect(() => {
    actions.listCLEAR();
    return () => {
      actions.listCLEAR();
      setTotal(0);
    };
  }, []);

  const handleCleanData = useCallback(() => {
    actions.listCLEAR();
  }, [actions]);

  const handleTotal = (
    statusJob: string,
    totalElem: number,
    openElem: number
  ) => {
    switch (statusJob) {
      case 'Open':
        setTotal(openElem);
        break;
      case 'Closed':
        setTotal(totalElem - openElem);
        break;
      default:
        setTotal(totalElem);
        break;
    }
  };

  const handleFetchAll = useCallback(
    async (params) => {
      const data = await getTroubleJobParams(
        params?.limit === 0
          ? { ...params }
          : { page: 1, limit: 50, filters: params.filters },
        currentTab
      );
      const response = await actions?.listAllGET(data);
      if (response.error || response.payload?.rows?.length === 0) {
        setShowNotRecord(true);
      }
      const totalElem = (await response.payload?.count.all) ?? 0;
      const openElem = (await response.payload?.count.open) ?? 0;
      const jobStatusFilter =
        (params.filters &&
          params?.filters?.some((elem) => elem.name === 'jobStatus') &&
          params.filters.find((elem) => elem.name === 'jobStatus').value ===
            'Open') ||
        (params.filters &&
          params?.filters?.some((elem) => elem.name === 'jobStatus') &&
          params.filters.find((elem) => elem.name === 'jobStatus').value ===
            'Closed')
          ? params.filters.find((elem) => elem.name === 'jobStatus').value
          : '';
      handleTotal(jobStatusFilter, totalElem, openElem);
    },
    [actions]
  );

  return (
    <TroubleJobsListAllView
      dataAll={state.listAll}
      loading={status.fetching}
      total={total}
      setTotal={setTotal}
      currentTab={currentTab}
      showStatus={Number(currentTab) === 1}
      handleDownload={handleDownload}
      handleFetchAll={handleFetchAll}
      showNotRecord={showNotRecord}
      setShowNotRecord={setShowNotRecord}
      handleCleanData={handleCleanData}
      showAll={showAll}
      setShowAll={setShowAll}
    />
  );
};

export default TroubleJobsAllList;
