/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { ATMLoader, ORGHeader } from 'shared-it-appmod-ui';
import ENV from 'src/constants/env.constants';
import { useRolesContext } from 'src/contexts/roles.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { hasAccess } from 'src/libraries/access.library';
import {
  getUser,
  logout,
  hasAuth,
  redirect,
} from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import { routes } from 'src/router';
import { useLocation } from 'react-router-dom';
import history from 'src/history';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import LerRequestEditComponent from 'src/components/pages/ler-request/ler-request-edit/ler-request-edit.component';
import LerRequestDetailsComponent from 'src/components/pages/ler-request/ler-request-detail/ler-request-detail.component';
import LERRequestClone from 'src/components/pages/ler-request/ler-request-clone/ler-request-clone.component';
import LERRequestApproval from 'src/components/pages/ler-request/ler-request-approval/ler-request-approval.component';
import { httpCancelAll } from 'src/libraries/http.library';
import GlobalMessage from 'src/components/organisms/global-message/global-message.component';
import TickerMessage from 'src/components/organisms/ticker-message/ticker-message.component';

const TIME_LIMIT_MINUTES = 720;
const ALERT_REFRESH_MINUTES = 5;

const monitor = debounce(() => {
  logout();
}, TIME_LIMIT_MINUTES * 60 * 1000);

const PrivateTemplate: React.FC = ({ children }) => {
  const location = useLocation();
  const alertTimer = useRef<NodeJS.Timeout>();
  const { actions } = useRolesContext();
  const { actions: settingActions } = useSettingContext();
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState<any>();

  const { state: lerRequestState } = useLerRequestContext();

  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await hasAuth();
      const userData = getUser();

      if (!isAuth || !userData) {
        redirect();
      } else {
        setUser(userData);
      }
    };

    checkAuth();
  }, [setUser]);

  const handleSetting = useCallback(async () => {
    await settingActions.listGET();

    alertTimer.current = setTimeout(() => {
      handleSetting();
    }, ALERT_REFRESH_MINUTES * 60 * 1000);
  }, [settingActions]);

  useEffect(() => {
    return () => {
      if (alertTimer.current) {
        clearTimeout(alertTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      const result = await actions.dataByEmpIdGET(user.emp_no);
      if (
        result.payload &&
        (result.payload?.roles ?? []).length > 0 &&
        (result.payload?.roles.every((v) => v.roleStatus !== false) ||
          result.payload.roleStatus === true)
      ) {
        await handleSetting();
        setReady(!ready);
      } else {
        window.location.href = `${window.location.origin}/logout/unauthorized`;
      }
    };

    if (user) {
      checkUser();
    }
  }, [actions, user, handleSetting]);

  useEffect(() => {
    let block: () => void;

    if (ready) {
      window.addEventListener('mousemove', monitor);
      // We will trigger mouse move event on load
      window.dispatchEvent(new Event('mousemove'));

      block = history.block(() => {
        httpCancelAll();
      });
    }

    return () => {
      window.removeEventListener('mousemove', monitor);
      if (block) {
        block();
      }
    };
  }, [ready]);

  if (!ready) {
    return <ATMLoader active>{Lang.LBL_LOADING_REPORT_INFORMATION}</ATMLoader>;
  }

  return (
    <div>
      <ORGHeader
        location={location}
        handleLocation={(url) => history.push(url)}
        application={Lang.TTL_APPLICATION}
        environment={ENV.VITE_ENVIRONMENT}
        menus={
          window.location.pathname === '/email' ||
          window.location.pathname === '/email-change-request'
            ? []
            : routes
                .filter((val) => val.label)
                .filter(
                  (val) =>
                    val.permission !== undefined
                      ? hasAccess(val.permission)
                      : true // Removes menu items that has no access
                )
                .map((route) => ({
                  name: route.label,
                  to: route.path,
                }))
        }
        user={
          user?.first_name
            ? {
                username: `${user.first_name}`,
              }
            : undefined
        }
        helpLinkUrl={`https://userguide.${ENV.VITE_COGNITO_DOMAIN?.replace(
          'cognito.',
          ''
        )}/docs/welcome`}
        onLogout={() =>
          logout(() => {
            window.location.reload();
          })
        }
      >
        <TickerMessage />

        {lerRequestState.forApproval && (
          <LERRequestApproval
            requestId={lerRequestState.data?.requestId ?? 0}
            isOpen={lerRequestState.forApproval}
            isFromUpdate={lerRequestState.isFromUpdate}
            isFromReject={lerRequestState.isFromReject ?? false}
            isFromPendingCaiso={lerRequestState.isFromPendingCaiso ?? false}
            hasNoCaisoUpdate={
              lerRequestState.isFromUpdate
                ? lerRequestState.hasNoCaisoUpdate
                : undefined
            }
          />
        )}

        {children}
      </ORGHeader>

      <GlobalMessage user={user} />
      {/* You will always import these component if you intent to use it in this page */}
      <LERRequestClone />
      <LerRequestEditComponent />
      <LerRequestDetailsComponent />
    </div>
  );
};

export default PrivateTemplate;
