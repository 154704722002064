import { UseFormReturn } from 'react-hook-form';
import yup from 'src/libraries/validator.library';
import { createDateTime } from 'src/libraries/moment.library';
import { TestContext } from 'yup';
import moment from 'moment';
import { handleTimeStatus } from 'src/components/pages/substation-entry-log/substation-entry-log-list/substation-entry-log-list-today/substation-entry-log-list-today.component';
import { SubstationTimeStatus } from 'src/constants';
import { EmployeeAddonSchema } from './employee.model';

export const EntryReasonShape = {
  entryReasonId: yup.number().required(),
  description: yup.string(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const checkDate = (_value, context: TestContext) => {
  const {
    path,
    parent: { inTime, outTime, allDay, inDateTime, outDateTime },
  } = context;
  let start;
  let end;
  if (inDateTime && inTime) {
    start = createDateTime(inDateTime, inTime);
  }
  if (outDateTime && outTime) {
    end = createDateTime(outDateTime, outTime);
  }
  if (allDay) {
    return true;
  }

  if (!end && outTime) {
    if (path.includes('outDateTime')) {
      return context.createError({
        message: 'This field is required',
      });
    }
  }

  if (outDateTime && !outTime) {
    if (path.includes('outTime')) {
      return context.createError({
        message: 'This field is required',
      });
    }
  }

  if (!outDateTime && !outTime) {
    if (
      handleTimeStatus(inDateTime ?? new Date(), outDateTime).language ===
      SubstationTimeStatus.OUT
    ) {
      if (path.includes('outTime')) {
        return context.createError({
          message: 'This field is required',
        });
      }
    }
  }

  if (!outDateTime && !outTime) {
    if (
      handleTimeStatus(inDateTime ?? new Date(), outDateTime).language ===
      SubstationTimeStatus.OUT
    ) {
      if (path.includes('outDateTime')) {
        return context.createError({
          message: 'This field is required',
        });
      }
    }
  }

  if (end && start) {
    if (path.includes('outDateTime') && start.isAfter(end)) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        return true;
      }
      return context.createError({
        message: 'Must be greater than the start date',
      });
    }
  }

  // if (end && start) {
  //   if (path.includes('inDateTime') && end.isBefore(start)) {
  //     if (moment(start).toDate().getDate() !== moment(end).toDate().getDate()) {
  //       return context.createError({
  //         message: 'Must be less than the end date',
  //       });
  //     }
  //   }
  // }

  if (end && start) {
    if (path.includes('outTime') && end.isBefore(start)) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        if (moment(end).toDate().getTime() < moment(start).toDate().getTime()) {
          return context.createError({
            message: 'Must be greater than the start time',
          });
        }
      }
      return true;
    }
  }
  if (end && start) {
    if (start.isValid() && end.isValid()) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        if (
          moment(start).toDate().getTime() === moment(end).toDate().getTime()
        ) {
          if (path.includes('outTime')) {
            return context.createError({
              message: 'Must be greater than the start time',
            });
          }
        }
        return true;
      }
      return start.isBefore(end);
    }
  }

  return true;
};

export const EntryReasonSchema = yup.object(EntryReasonShape);

export const SubstationEntryShape = {
  comment: yup.string().nullable(),
  empId: yup.string(),
  employee: yup
    .object({
      fullName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      empId: yup.string(),
      email: yup.string().nullable(),
      title: yup.string(),
      cellAreaCode: yup.string().nullable(),
      cellPhoneNumber: yup.string().nullable(),
      contracShortNm: yup.string().nullable(),
      trainings: yup.array().of(
        yup.object({
          authTypId: yup.string(),
          expiryDate: yup.string().nullable(),
          suspendFlag: yup.boolean(),
          testFailInd: yup.boolean(),
        })
      ),
    })
    .nullable(),
  entryDateTime: yup.date().required(),
  entryEmpId: yup.string(),
  entryEmployee: yup.object({
    empId: yup.string(),
    firstName: yup.string(),
    fullName: yup.string().nullable(),
    lastName: yup.string(),
    middleName: yup.string().nullable(),
  }),
  entryReason: yup.object({
    entryReasonId: yup.number(),
    description: yup.string(),
  }),
  entryReasonId: yup.number(),
  exitEmpId: yup.string().nullable(),
  exitEmployee: yup
    .object({
      empId: yup.string(),
      firstName: yup.string(),
      fullName: yup.string(),
      lastName: yup.string(),
      middleName: yup.string().nullable(),
    })
    .nullable(),
  fileAttachedFlag: yup.boolean().nullable(),
  inDateTime: yup.date().required(),
  modelFlag: yup.string().nullable(),
  outDateTime: yup.date().nullable(),
  status: yup.string(),
  substation: yup
    .object({
      name: yup.string().required(),
      addr1: yup.string().nullable(),
      phnNbr: yup.string().nullable(),
      substationId: yup.string(),
      tbros: yup.string().nullable(),
    })
    .required(),
  substationEntryLogId: yup.number().required(),
  substationId: yup.string(),
  updatedByEmployee: yup.object({
    empId: yup.string(),
    firstName: yup.string(),
    fullName: yup.string(),
    lastName: yup.string(),
    middleName: yup.string().nullable(),
  }),
  updatedAt: yup.date().required(),
  updatedBy: yup.string(),
  visitors: yup.string().nullable(),
};
export const SubstationEntrySchema = yup.object(SubstationEntryShape);
export const SubstationEntryAddFormSchema = yup.object({
  substationEntryLogId: yup.number(),
  empId: yup.string().required(),
  comment: yup.string().max(40),
  status: yup.string().required(),
  visitors: yup.string().max(3000).nullable(),
  entryReasonId: yup.number().required().typeError('This field is required'),
  substationId: yup.string().required(),
  fileAttachedFlag: yup.boolean().nullable(),
  inDateTime: yup.date().when('substationEntryLogId', {
    is: (value) => value,
    then: yup.date().required(),
  }),
  inTime: yup.string().when('substationEntryLogId', {
    is: (value) => value,
    then: yup.string().required(),
  }),
});
export const SubstationEntryFormSchema = yup.object({
  substationEntryLogId: yup.number(),
  empId: yup.string().required(),
  comment: yup.string().max(40),
  status: yup.string().required(),
  visitors: yup.string().max(3000).nullable(),
  entryReasonId: yup.number().required().typeError('This field is required'),
  substationId: yup.string().required(),
  fileAttachedFlag: yup.boolean().nullable(),
  inDateTime: yup.date().when('substationEntryLogId', {
    is: (value) => value,
    then: yup.date().required(),
  }),
  inTime: yup.string().when('substationEntryLogId', {
    is: (value) => value,
    then: yup.string().required(),
  }),
  outDateTime: yup
    .date()
    .nullable()
    .default(undefined)
    .test('isValid', 'Invalid End Date', checkDate),
  outTime: yup
    .string()
    .nullable()
    .default(undefined)
    .test('isValid', 'Invalid End Time', checkDate),
});
export const SubstationEntryLogSearchSchema = yup.object({
  dateTime: yup.array().of(yup.date()).required(),
  empId: yup.string(),
  substationId: yup.string(),
});
export const SubstationEntryListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SubstationEntrySchema.clone()),
});
export const SubstationEntryEmployeeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EmployeeAddonSchema.clone()),
});
export const SubstationEntryDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISubstationEntry = yup.Asserts<typeof SubstationEntrySchema>;
export type ISubstationEntryForm = yup.Asserts<
  typeof SubstationEntryFormSchema
>;
export type ISubstationEntryAddForm = yup.Asserts<
  typeof SubstationEntryAddFormSchema
>;
export type ISubstationEntrySearch = yup.Asserts<
  typeof SubstationEntryLogSearchSchema
>;

export type ISubstationEntryEmployee = yup.Asserts<typeof EmployeeAddonSchema>;

export type ISubstationEntryLogSearchMethods = Pick<
  UseFormReturn<ISubstationEntrySearch>,
  'control' | 'formState'
> &
  Partial<
    Pick<
      UseFormReturn<ISubstationEntrySearch>,
      'setError' | 'clearErrors' | 'formState' | 'reset' | 'watch' | 'register'
    >
  >;
export type IEntryReason = yup.Asserts<typeof EntryReasonSchema>;
