/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import {
  ATMButton,
  ATMDivider,
  ATMLoader,
  ATMPopover,
} from 'shared-it-appmod-ui';
import { IIssuedKey } from 'src/models/issued-key.model';
import { formatDate, formatDateTz } from 'src/libraries/moment.library';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useIssuedKeyContext } from 'src/contexts/issued-key.context';
import Lang from 'src/libraries/language';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { getIssuedKeyStatus } from 'src/selectors/issued-key.selector';
import { issuedKeyActionTypes } from 'src/ducks/issued-key.duck';
import { KeyHolderType } from 'src/constants';
import {
  checkUndefinedOrNullValue,
  convertKeyNumberToFiveDigits,
} from 'src/helpers/key-request.helper';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import styles from './issued-key-card.module.scss';
import KeyDetails from '../key-details/key-details.component';

type IProps = {
  issuedKey: IIssuedKey;
  holderType: string;
};

const IssuedKeyCard: React.FC<IProps> = ({ issuedKey, holderType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [onClose, setOnClose] = useState(false);
  const { state, actions } = useIssuedKeyContext();

  const status = getIssuedKeyStatus(
    state,
    issuedKeyActionTypes.ISSUED_KEY_DATA_READ
  );
  const { actions: employeeActions } = useEmployeeContext();

  useEffect(() => {
    if (isOpen) {
      actions?.dataGET(issuedKey.keyNbr);
      if (issuedKey.keyDetail?.emp?.empId) {
        employeeActions?.searchGET(issuedKey.keyDetail?.emp?.empId);
      }
    }
  }, [setIsOpen, isOpen, actions, employeeActions]);

  const openRightPanel = useCallback(() => {
    setOnClose((val) => !val);
  }, [setOnClose]);

  const formatEmployeeName = (data: IIssuedKey) => {
    let formattedName = '';
    if (data.keyDetail && data.keyDetail.emp) {
      formattedName = `${checkUndefinedOrNullValue(
        `${data.keyDetail.emp?.lastName}, ${data.keyDetail.emp?.firstName}`,
        Lang.LBL_UNAVAILABLE
      )}${
        data.keyDetail.emp.empId?.length
          ? checkUndefinedOrNullValue(
              `/${formatToRawEmployeeId(data.keyDetail.emp.empId)}`,
              ''
            )
          : ''
      }`;
    }
    return formattedName;
  };

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        open={isOpen}
        closeOnDocumentClick={!onClose}
        onClose={() => {
          setIsOpen(false);
          // setOnClose(false);
        }}
        trigger={
          <u>
            <a
              style={{ color: 'rgba(0,0,0,0.65)' }}
              role="button"
              onClick={() => setIsOpen(true)}
            >
              {convertKeyNumberToFiveDigits(issuedKey.keyNbr)}
            </a>
          </u>
        }
        position="right center"
        on="click"
      >
        <ATMPopover.Header>
          <span className={styles.overlayHeader}>Key Information</span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          {status.fetching ? (
            <ATMLoader active inline="centered" />
          ) : holderType === KeyHolderType.EMPLOYEE ? (
            <>
              <div className={styles.overlayContent}>
                <FieldReadOnly label="Issue Request">
                  {/* Name of Employee/ID */}
                  {formatEmployeeName(issuedKey)}
                </FieldReadOnly>
                <FieldReadOnly label="Requested Date">
                  {issuedKey.keyDetail && issuedKey.keyDetail.issDt
                    ? formatDateTz(issuedKey.keyDetail.issDt)
                    : ''}
                </FieldReadOnly>
                <FieldReadOnly label="Reason">
                  {issuedKey.keyDetail && issuedKey.keyDetail.reason
                    ? issuedKey.keyDetail.reason
                    : ''}
                </FieldReadOnly>
              </div>
              <div className={styles.actionButtons}>
                <ATMButton
                  secondary
                  content="View Details"
                  size="mini"
                  onClick={openRightPanel}
                />
              </div>
              {state.data && (
                <KeyDetails
                  onClose={onClose}
                  setOnClose={openRightPanel}
                  issuedKeyData={state.data}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.overlayContent}>
                <FieldReadOnly label="Key Issued to">
                  {/* Name of Employee/ID */}
                  {formatEmployeeName(issuedKey)}
                </FieldReadOnly>
                <FieldReadOnly label="Firm Name">
                  {state.data &&
                    state.data.keyDetails &&
                    state.data.keyDetails[0].contrShortNm}
                </FieldReadOnly>
                <FieldReadOnly label={Lang.LBL_KEY_TYPE}>
                  {state.data && state.data.keyTypId}
                </FieldReadOnly>
                <FieldReadOnly label="Starting Key Number">
                  {state.data &&
                    state.data.keyDetails?.[0]?.keyNbr &&
                    convertKeyNumberToFiveDigits(
                      state.data.keyDetails?.[0]?.keyNbr
                    )}
                </FieldReadOnly>
                <FieldReadOnly label="Number of Keys">
                  {state.data &&
                    state.data.keyDetails?.[0]?.keyRequestDetail?.[0]?.noOfKeys}
                </FieldReadOnly>
                <FieldReadOnly label="Issue Date">
                  {state.data && formatDate(state.data.updatedAt)}
                </FieldReadOnly>
              </div>
              <div className={styles.actionButtons}>
                <ATMButton
                  secondary
                  content="View Details"
                  size="mini"
                  onClick={openRightPanel}
                />
              </div>
              {state.data && (
                <KeyDetails
                  onClose={onClose}
                  setOnClose={openRightPanel}
                  issuedKeyData={state.data}
                />
              )}
            </>
          )}
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default IssuedKeyCard;
