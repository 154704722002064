import { httpClient } from 'src/libraries/http.library';
import {
  ISettingForm,
  SettingListPayloadSchema,
  SettingDeletePayloadSchema,
  SettingPayloadSchema,
  SettingSchema,
} from 'src/models/setting.model';

const client = httpClient();
const endpoint = 'setting';

const services = {
  dataGET: async (name: string) => {
    return client.get(
      `/${endpoint}/${name}`,
      { page: 1, limit: 0 },
      SettingPayloadSchema
    );
  },

  listGET: async () => {
    return client.get(
      `/${endpoint}`,
      { page: 1, limit: 0 },
      SettingListPayloadSchema
    );
  },

  listByModuleGET: async (module: string) => {
    return client.get(
      `/${endpoint}`,
      { module, page: 1, limit: 0 },
      SettingListPayloadSchema
    );
  },

  createPOST: async (data: ISettingForm) => {
    return client.post(`/${endpoint}`, data, SettingPayloadSchema);
  },

  updatePUT: async (name: string, data: ISettingForm) => {
    return client.put(`/${endpoint}/${name}`, data, SettingSchema);
  },

  dataDELETE: async (name: string) => {
    return client.delete(`/${endpoint}/${name}`, SettingDeletePayloadSchema);
  },
};

export default services;
