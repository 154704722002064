import yup from 'src/libraries/validator.library';

export const SettingShape = {
  name: yup.string().required(),
  value: yup.string(),
  description: yup.string().required(),
  module: yup.string().required(),
  category: yup.string().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const SettingSchema = yup.object(SettingShape);

export const SettingFormSchema = yup.object({
  name: yup.string().required().default(''),
  value: yup.string(),
  category: yup.string().nullable(),
  description: yup.string().required(),
  module: yup.string().required(),
});

export const SettingListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SettingSchema.clone()),
});

export const SettingPayloadSchema = yup.object({
  setting: SettingSchema.clone(),
});

export const SettingDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISetting = yup.Asserts<typeof SettingSchema>;
export type ISettingForm = yup.Asserts<typeof SettingFormSchema>;
