import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  ATMSegment,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import ATMStep from 'src/components/atoms/step/step.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { FacilityType, LERRequestFormStep } from 'src/constants';
import Lang from 'src/libraries/language';
import {
  LerRequestFormSchema,
  ILerRequest,
  ILerRequestForm,
} from 'src/models/ler-request.model';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import { hasAccess } from 'src/libraries/access.library';
import FormError from 'src/components/atoms/form-error/form-error.component';
import { getUser } from 'src/libraries/amplify.library';
import LERRequestAuthorization from '../ler-request-authorization/ler-request-authorization-form/ler-request-authorization-form.component';
import LERRequestCrewInformationForm from '../ler-request-crew-information/ler-request-crew-information-form/ler-request-crew-information-form.component';
import LERRequestJobInformationForm from '../ler-request-job-information/ler-request-job-information-form/ler-request-job-information-form.component';
import LERRequestOutageDateCalendar from '../ler-request-outage-date/ler-request-outage-date-calendar/ler-request-outage-date-calendar.component';
import LERRequestOutageFacilityForm from '../ler-request-outage-facility/ler-request-outage-facility-form/ler-request-outage-facility-form.component';
import LerRequestConfirmUnfinished from '../ler-request-confirm-unfinished/ler-request-confirm-unfinished.component';
import LERRequestPreview from '../ler-request-preview/ler-request-preview.component';
import styles from './ler-request-form.module.scss';
import LERRequestISO from '../ler-request-iso/ler-request-iso-form/ler-request-iso-form.component';
import LerRequestDeleteDraft from '../ler-request-delete/ler-request-delete-draft/ler-request-delete-draft.component';

type IContentProps = {
  header: React.ReactNode;
  formRef: React.RefObject<HTMLFormElement>;
  defaultValues?: ILerRequest;
  draftId?: number;
  loading: boolean;
  isSubmitted?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<ILerRequestForm>) => Promise<void>;
};

const stepList = [
  {
    name: LERRequestFormStep.OUTAGE_FACILITY,
    access: AccessType.OUTAGE_REQUEST_FACILITY,
  },
  {
    name: LERRequestFormStep.OUTAGE_DATE,
    access: AccessType.OUTAGE_REQUEST_DATE,
  },
  {
    name: LERRequestFormStep.JOB_INFORMATION,
    access: AccessType.OUTAGE_REQUEST_JOB_INFORMATION,
  },
  {
    name: LERRequestFormStep.CREW_INFORMATION,
    access: AccessType.OUTAGE_REQUEST_CREW_INFORMATION,
  },
  {
    name: LERRequestFormStep.AUTHORIZATION_STATEMENT,
    access: AccessType.OUTAGE_REQUEST_AUTH_STATEMENT,
  },
  {
    name: LERRequestFormStep.ISO,
    access: AccessType.OUTAGE_REQUEST_ISO,
  },
  {
    name: LERRequestFormStep.REVIEW,
  },
];

const scrollToTop = () => {
  const element = document.getElementById('form-error');
  if (element) {
    element.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }
};

const Content: React.FC<IContentProps> = ({
  header,
  formRef,
  defaultValues,
  loading,
  draftId,
  handleClose,
  handleSave,
}) => {
  const {
    control,
    trigger,
    getValues,
    formState: { isValid, isDirty, errors },
    setValue,
    reset,
  } = useATMFormContext<ILerRequestForm>();

  const [isConfirm, setIsConfirm] = useState(false);

  const [step, setStep] = useState<LERRequestFormStep>(
    (getValues('step') as LERRequestFormStep) ||
      LERRequestFormStep.OUTAGE_FACILITY
  );

  const accessList = stepList.filter(
    (v) => !v.access || (v.access && hasAccess(v.access))
  );

  const [completedList, setCompletedList] = useState(
    [...accessList].reduce(
      (items, item, index) => ({
        ...items,
        [item.name]: accessList.findIndex((v) => v.name === step) >= index,
      }),
      {}
    )
  );

  useEffect(() => {
    setValue('step', step, {
      shouldValidate: true,
    });
  }, [step, setValue]);

  useEffect(() => {
    setCompletedList((values) => {
      const list = {
        ...values,
        [step]: isValid,
      };

      if (!Object.values(list).every(Boolean)) {
        return {
          ...list,
          [LERRequestFormStep.REVIEW]: false,
        };
      }

      return list;
    });
  }, [isValid, step, setCompletedList]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
      setIsConfirm(false);
    }
  }, [formRef]);

  return (
    <div>
      <div className={styles.header}>
        <ATMSegment attached>
          <ATMGrid columns={2}>
            <ATMGrid.Column verticalAlign="middle">{header}</ATMGrid.Column>
            <ATMGrid.Column textAlign="right">
              <ul className={styles.right}>
                <li>
                  <div className="action-buttons">
                    {step !== LERRequestFormStep.OUTAGE_FACILITY && (
                      <ATMButton
                        secondary
                        icon="chevron left"
                        content="Previous"
                        type="button"
                        onClick={() => {
                          scrollToTop();
                          setStep(
                            accessList[
                              accessList.findIndex((val) => val.name === step) -
                                1
                            ].name
                          );
                        }}
                      />
                    )}

                    {accessList[
                      accessList.findIndex((val) => val.name === step) + 1
                    ] && (
                      <ATMButton
                        secondary
                        icon="chevron right"
                        labelPosition="right"
                        content="Next"
                        className={styles.btnFont}
                        type="button"
                        onClick={() => {
                          scrollToTop();
                          if (!isValid) {
                            trigger();
                            ToastError(
                              step === LERRequestFormStep.OUTAGE_DATE
                                ? Lang.MSG_LER_REQUEST_FORM_OUTAGE_DATE_ERROR
                                : Lang.MSG_LER_REQUEST_FORM_STEP_ERROR
                            );

                            scrollToTop();
                          } else {
                            setStep(
                              accessList[
                                accessList.findIndex(
                                  (val) => val.name === step
                                ) + 1
                              ].name
                            );
                          }
                        }}
                      />
                    )}

                    {handleSave && isDirty && (
                      <ATMButton
                        type="button"
                        primary
                        loading={loading}
                        disabled={loading}
                        onClick={async () => {
                          const values = { ...getValues() };

                          await handleSave(values);
                          reset(values);
                        }}
                      >
                        {Lang.LBL_SAVE}
                      </ATMButton>
                    )}

                    {step === LERRequestFormStep.REVIEW && (
                      <Confirm
                        open={isConfirm}
                        size="small"
                        onConfirm={handleClick}
                        onOpen={() => setIsConfirm(true)}
                        onCancel={() => setIsConfirm(false)}
                        trigger={
                          <ATMButton
                            type="button"
                            primary
                            disabled={loading}
                            loading={loading}
                          >
                            {Lang.LBL_SUBMIT}
                          </ATMButton>
                        }
                        loading={loading}
                        header={Lang.LBL_SUBMIT_LER}
                        content={Lang.MSG_LER_CREATE_CONFIRMATION}
                      />
                    )}

                    {defaultValues && (
                      <ATMDropdown
                        pointing
                        size="small"
                        button
                        icon="ellipsis horizontal"
                        className={styles.ellipsisButton}
                      >
                        <ATMDropdown.Menu>
                          {draftId && (
                            <LerRequestDeleteDraft
                              draftId={draftId}
                              trigger={
                                <ATMDropdown.Item
                                  content={Lang.LBL_DELETE_DRAFT}
                                  icon="delete"
                                />
                              }
                              handleSuccess={handleClose}
                            />
                          )}
                        </ATMDropdown.Menu>
                      </ATMDropdown>
                    )}
                  </div>
                </li>

                <li>
                  <div className="action-buttons">
                    <ATMButton
                      type="button"
                      color="grey"
                      basic
                      icon="external alternate"
                    />

                    {handleClose && (
                      <LerRequestConfirmUnfinished onConfirm={handleClose} />
                    )}
                  </div>
                </li>
              </ul>
            </ATMGrid.Column>
          </ATMGrid>
        </ATMSegment>
        <ATMStep
          attached="bottom"
          className={styles.step}
          items={[
            ...accessList.map((val, index) => {
              const completed = completedList[val.name];

              return {
                title: `${index + 1}. ${Lang.LER_REQUEST_STEP[val.name]}`,
                icon: completed ? 'check circle' : undefined,
                active: step === val.name,
                completed,
                onClick:
                  completed ||
                  (accessList[index - 1] &&
                    completedList[accessList[index - 1].name])
                    ? () => setStep(val.name)
                    : undefined,
              };
            }),
          ]}
        />
      </div>

      <div className="admin-container">
        <div id="form-error">
          <FormError
            errors={errors}
            dictionary="FORM_LER_REQUEST"
            render={(field, message, temp) => {
              let title = temp;

              if (
                field === 'outgFacId' &&
                getValues().outageFacility?.facilityType ===
                  FacilityType.Station
              ) {
                title = Lang.LBL_EQUIPMENT;
              }

              return (
                <>
                  <strong>{title}: </strong>
                  {message}
                </>
              );
            }}
          />
        </div>

        <ATMField
          name="step"
          as={ATMInput}
          control={control}
          value={step}
          type="hidden"
          className="hidden"
        />
        <div
          className={
            step !== LERRequestFormStep.OUTAGE_FACILITY ? 'hidden' : undefined
          }
        >
          <LERRequestOutageFacilityForm />
        </div>
        <div
          className={
            step !== LERRequestFormStep.OUTAGE_DATE ? 'hidden' : undefined
          }
        >
          <LERRequestOutageDateCalendar />
        </div>
        <div
          className={
            step !== LERRequestFormStep.JOB_INFORMATION ? 'hidden' : undefined
          }
        >
          <LERRequestJobInformationForm />
        </div>
        <div
          className={
            step !== LERRequestFormStep.CREW_INFORMATION ? 'hidden' : undefined
          }
        >
          <LERRequestCrewInformationForm />
        </div>
        <AccessComponent type={AccessType.OUTAGE_REQUEST_AUTH_STATEMENT}>
          <div
            className={
              step !== LERRequestFormStep.AUTHORIZATION_STATEMENT
                ? 'hidden'
                : undefined
            }
          >
            <LERRequestAuthorization />
          </div>
        </AccessComponent>
        <AccessComponent type={AccessType.OUTAGE_REQUEST_ISO}>
          <div
            className={step !== LERRequestFormStep.ISO ? 'hidden' : undefined}
          >
            <LERRequestISO data={defaultValues} />
          </div>
        </AccessComponent>
        {step === LERRequestFormStep.REVIEW && (
          <LERRequestPreview
            data={getValues() as unknown as ILerRequest}
            handleStep={(value) => setStep(value)}
          />
        )}
      </div>
    </div>
  );
};

type IProps = {
  header: React.ReactNode;
  defaultValues?: ILerRequest;
  loading?: boolean;
  draftId?: number;
  isSubmitted?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<ILerRequestForm>) => Promise<void>;
  handleSubmit: (data: ILerRequestForm) => void;
  isClone?: boolean;
};

const LERRequestForm: React.FC<IProps> = ({
  header,
  defaultValues,
  loading = false,
  draftId,
  handleClose,
  handleSave,
  handleSubmit,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const user = getUser();

  return (
    <div>
      <ATMForm
        ref={formRef}
        defaultValues={
          {
            ...defaultValues,
            crewInformation: {
              ...defaultValues?.crewInformation,
              requestorId:
                defaultValues?.crewInformation.requestorId || user?.emp_no,
              requestorEmail:
                defaultValues?.crewInformation.requestorEmail ||
                defaultValues?.crewInformation.requestorId === user?.emp_no
                  ? user?.e_mail_address
                  : undefined,
            },
          } as unknown as ILerRequestForm
        }
        validationSchema={LerRequestFormSchema}
        mode="onChange"
        onSubmit={(_, { getValues }) => {
          handleSubmit(getValues());
        }}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <Content
                header={header}
                formRef={formRef}
                defaultValues={defaultValues}
                loading={loading}
                draftId={draftId}
                handleClose={handleClose}
                handleSave={handleSave}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default LERRequestForm;
