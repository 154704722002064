import yup from 'src/libraries/validator.library';
import { EmployeeShapeKeyReq } from './key-request.model';

export const ManagerShape = {
  id: yup.number().required(),
  name: yup.string().required(),
};

export const KeyRequestDetailShape = {
  keyAdminMailLoc: yup.string().nullable(),
  noOfKeys: yup.number().nullable(),
};

export const IssuedKeyDetailShape = {
  keyNbr: yup.number().required(),
  empId: yup.string().required(),
  issDt: yup.date().required(),
  contrShortNm: yup.string().nullable(),
  stat: yup.string().required(),
  returnDt: yup.date().nullable(),
  sdgeContactId: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  reason: yup.string().nullable(),
  recipContrId: yup.string().nullable(),
  keyRequestDetail: yup
    .array()
    .of(yup.object(KeyRequestDetailShape).nullable()),
  emp: yup
    .object({
      ...EmployeeShapeKeyReq,
      middleName: yup.string().nullable(),
      email: yup.string().nullable(),
      title: yup.string().nullable(),
      supvr: yup
        .object({
          ...EmployeeShapeKeyReq,
          middleName: yup.string().nullable(),
          email: yup.string().nullable(),
        })
        .nullable(),
    })
    .nullable(),
};

export const IssuedKeyDetailSchema = yup.object(IssuedKeyDetailShape);

export const IssuedKeyShape = {
  keyNbr: yup.number().required(),
  keyTypId: yup.string().required(),
  stat: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  keyDetails: yup.array().of(IssuedKeyDetailSchema).nullable(),
  keyDetail: yup.lazy((value) => {
    if (value === undefined) {
      return yup.mixed<IIssuedKeyDetail>().notRequired();
    }
    return yup.object(IssuedKeyDetailShape);
  }),
};

export const IssuedKeySchema = yup.object(IssuedKeyShape);

export const IssuedKeyFormSchema = yup.object({
  keyNbr: yup.number().required(),
  keyTypId: yup.string().required(),
  stat: yup.string().nullable(),
  reason: yup.string().required(),
  // keyDetails: yup.array().of(IssuedKeyDetailSchema),
});

export const IssuedKeyListPayloadSchema = yup.object({
  count: yup.object({
    active: yup.number(),
    lost: yup.number(),
    damaged: yup.number(),
    notReturned: yup.number(),
    pendingReturn: yup.number(),
    returned: yup.number(),
    all: yup.number(),
  }),
  rows: yup.array().of(IssuedKeySchema.clone()),
});

export const IssuedKeyPayloadSchema = IssuedKeySchema;

export const IssuedKeyDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IIssuedKey = yup.Asserts<typeof IssuedKeySchema>;
export type IIssuedKeyDetail = yup.Asserts<typeof IssuedKeyDetailSchema>;
export type IIssuedKeyForm = yup.Asserts<typeof IssuedKeyFormSchema>;
