import React from 'react';
import { ATMModal, ATMButton } from 'shared-it-appmod-ui';
import { ConfirmProps } from 'semantic-ui-react';
import Lang from 'src/libraries/language';

type IConfirm = ConfirmProps & {
  loading?: boolean;
};

const Confirm: React.FC<IConfirm> = ({
  loading = false,
  header,
  content,
  onConfirm,
  onCancel,
  ...props
}) => (
  <ATMModal
    {...(props as any)}
    closeOnDimmerClick={false}
    onClose={onCancel as any}
  >
    {header && <ATMModal.Header>{header}</ATMModal.Header>}
    <ATMModal.Content>{content ?? 'Are you sure?'}</ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary onClick={onCancel as any}>
        {Lang.LBL_CANCEL}
      </ATMButton>
      <ATMButton
        primary
        onClick={onConfirm as any}
        loading={loading}
        disabled={loading}
      >
        {props.confirmButton ?? Lang.LBL_OKAY}
      </ATMButton>
    </ATMModal.Actions>
  </ATMModal>
);

export default Confirm;
