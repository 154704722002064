import React, { useCallback, useRef, useState } from 'react';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { IAuthorizedListSuspensionForm } from 'src/models/authorized-list.model';
import { useSuspensionListContext } from 'src/contexts/suspension-list.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { addDoubleZeroEmpId } from 'src/selectors/employee.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import AuthorizedListSuspensionSuspendView from './authorized-list-suspension-suspend.view';

type IProp = {
  data?: IAuthorizedListSuspensionForm;
  authId: number;
  empName?: string | null;
};

const AuthorizedListSuspensionSuspend: React.FC<IProp> = ({
  data,
  authId,
  empName,
}) => {
  const { params: currentTab } = useLocationParams(['id']);
  const { actions } = useAuthorizedListContext();
  const { actions: suspensionListActions } = useSuspensionListContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IAuthorizedListSuspensionForm) => {
      setLoading(true);
      const responseSuspensionList = await suspensionListActions.createPOST({
        empId: formData.empId,
        authTypId: formData.authTypId,
        authListId: authId,
      });

      const responseSuspensionHistory = await actions.suspensionHistoryListPOST(
        {
          ...formData,
        }
      );

      if (!responseSuspensionList.error && !responseSuspensionHistory.error) {
        const param = {
          filters: [
            {
              name: 'empId',
              value: addDoubleZeroEmpId(data?.empId ?? ''),
            },
          ],
        };
        actions.suspensionHistoryListGET(param);
        setIsEditOpen(false);
        ToastSuccess('Suspension has been added');
        actions.empDataGET(currentTab.id);
      } else {
        ToastError(responseSuspensionList.error.message);
      }
      setLoading(false);
    },
    [data, actions, setIsEditOpen, setLoading]
  );

  return (
    <AuthorizedListSuspensionSuspendView
      formRef={formRef}
      defaultValues={data}
      loading={loading}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      authListId={authId}
      suspend
      empName={empName}
    />
  );
};

export default AuthorizedListSuspensionSuspend;
