import React, { useEffect, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import Lang from 'src/libraries/language';
import {
  IUserGroupForm,
  UserGroupFormSchema,
} from 'src/models/user-group.model';
import UserGroupMemberForm from '../user-group-member-form/user-group-member-form.component';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IUserGroupForm>;
  handleSubmit: (data: IUserGroupForm) => void;
  handleEnable: (value: boolean) => void;
};

const FormContent: React.FC<{
  defaultValues?: Partial<IUserGroupForm>;
  handleEnable: (value: boolean) => void;
}> = ({ defaultValues, handleEnable }) => {
  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
  } = useATMFormContext<IUserGroupForm>();

  const [errorMember, setErrorMember] = useState(false);

  useEffect(() => {
    handleEnable(false);
  }, []);

  return (
    <>
      <ATMGrid>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={8}>
            {!defaultValues?.userGrpNm ? (
              <ATMField
                label={<LabelRequired>{Lang.LBL_GROUP_NAME}</LabelRequired>}
                name="userGrpNm"
                control={control}
                error={errors.userGrpNm}
                as={ATMInput}
                placeholder="Enter"
              />
            ) : (
              <>
                <FieldReadOnly label={Lang.LBL_GROUP_NAME} invert>
                  {defaultValues?.userGrpNm}
                </FieldReadOnly>
                <ATMField
                  name="userGrpNm"
                  control={control}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                />
              </>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={8}>
            <ATMField
              label={Lang.LBL_GROUP_EMAIL}
              name="userGrpEmail"
              control={control}
              error={errors.userGrpEmail}
              as={ATMInput}
              placeholder="Enter"
              onChange={([_, { value }]) => {
                handleEnable(true);
                if (
                  (value === '' || value === null) &&
                  (getValues().userGrpMembers?.length === 0 ||
                    getValues().userGrpMembers === undefined)
                ) {
                  setError('userGrpMembers', {
                    message: Lang.MSG_USER_GROUP_MEMBER_ERROR,
                  });
                } else {
                  clearErrors('userGrpMembers');
                  setErrorMember(false);
                }
                return value;
              }}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
        <UserGroupMemberForm
          handleEnable={handleEnable}
          defaultValues={defaultValues}
          handleErrorMember={setErrorMember}
          errorMember={errorMember}
        />
      </ATMGrid>
    </>
  );
};

const UserGroupForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={UserGroupFormSchema.cast(defaultValues)}
      validationSchema={UserGroupFormSchema}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <FormContent
              defaultValues={defaultValues}
              handleEnable={handleEnable}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default UserGroupForm;
