import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMPopover,
  ATMIcon,
} from 'shared-it-appmod-ui';
import { IUserGroup } from 'src/models/user-group.model';
import Lang from 'src/libraries/language';
import history from 'src/history';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import UserGroupEdit from '../user-group-edit/user-group-edit.component';
import UserGroupMemberModal from '../user-group-member/user-group-member.component';

const columns: IORGDataTableColumn<IUserGroup>[] = [
  {
    index: 'userGrpNm',
    title: Lang.LBL_GROUP_NAME,
    render: (_, value) => (
      <span style={{ color: '#009bda' }}>
        {' '}
        <b> {value.userGrpNm} </b>{' '}
      </span>
    ),
  },
  {
    index: 'userGrpEmail',
    sortable: false,
    title: Lang.LBL_GROUP_EMAIL,
    render: (_, value) =>
      value.userGrpEmail ? value.userGrpEmail : String.fromCharCode(8211),
  },
  {
    index: 'userGrpMembers',
    sortable: false,
    title: (
      <>
        {' '}
        {Lang.LBL_GROUP_MEMBERS}{' '}
        <ATMPopover
          trigger={<ATMIcon name="info circle" color="grey" />}
          content={Lang.NTE_GROUP_MEMBER}
          position="top center"
          inverted
          size="small"
        />
      </>
    ),
    render: (_, value) => {
      if (!value.userGrpMembers?.length) {
        return String.fromCharCode(8211);
      }
      return (
        <span>
          {value.userGrpMembers && value.userGrpMembers[0].firstName}{' '}
          {value.userGrpMembers && value.userGrpMembers[0].lastName}
          {value.userGrpMembers.length > 1 ? (
            <span>
              , <UserGroupMemberModal data={value} />{' '}
            </span>
          ) : (
            ''
          )}
        </span>
      );
    },
  },
  {
    index: 'action',
    sortable: false,
    title: Lang.LBL_ACTION,
    width: '80px',
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <UserGroupEdit data={value} />
      </>
    ),
  },
];

type IProps = {
  data: IUserGroup[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
};

const UserGroupListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
}) => {
  return (
    <div className="admin-content">
      <ORGDataTable
        sortable
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        counter
        celled={false}
        total={total}
        showPagination={false}
        location={history.location}
        handleLocation={history.push}
      >
        {({ state, setState }) => ({
          toolbars: {
            search: () => (
              <TableSearch
                field="userGrpNm"
                state={state}
                setState={setState}
                placeholder={Lang.LBL_NAME_SEARCH}
              />
            ),
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default UserGroupListView;
