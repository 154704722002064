import React from 'react';
import {
  IORGDataTableQueryState,
  IATMTabProps,
  ATMMenu,
  ATMTab,
} from 'shared-it-appmod-ui';
import { IJobCodeRule } from 'src/models/job-code-rule.model';
import Lang from 'src/libraries/language';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import JobKeyRulesListTable from '../job-code-rule-list-table/job-code-rule-list-table.component';
import style from '../job-code-rule.module.scss';

type IProps = {
  data: IJobCodeRule[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  handleDownload: () => void;
  loadingDL: boolean;
  // currentTab: number;
};

const JobCodeRuleListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  loadingDL,
  handleDownload,
}) => {
  const panes: IATMTabProps['panes'] = [
    {
      menuItem: <ATMMenu.Item key="0">{Lang.LBL_ACTIVE_JOB_CODE}</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <JobKeyRulesListTable
            data={data}
            handleDownload={handleDownload}
            handleFetch={handleFetch}
            loading={loading}
            total={total}
            loadingDL={loadingDL}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">{Lang.LBL_INACTIVE_JOB_CODE}</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <JobKeyRulesListTable
            data={data}
            handleDownload={handleDownload}
            handleFetch={handleFetch}
            loading={loading}
            total={total}
            loadingDL={loadingDL}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  return (
    <div className={style.mainContainerPosition}>
      <div className={style.headerTabs}>
        <Tabs
          className={style.table}
          name="jobStatus"
          menu={{ pointing: true }}
          panes={panes}
          activeIndex={0}
        />
      </div>
    </div>
  );
};

export default JobCodeRuleListView;
