import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IKeyRequest } from 'src/models/key-request.model';
import style from './key-request-delete.module.scss';

type IProp = {
  data: IKeyRequest;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const KeyRequestDelete: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMButton
        className={style.deleteBtn}
        color="red"
        floated="left"
        content={Lang.LBL_REQUEST_REJECT}
        onClick={() => handleOpen(true)}
      />
    }
  >
    <ATMModal.Header>{Lang.TTL_KEY_REQUESTS_DELETE}</ATMModal.Header>
    <ATMModal.Content>
      <p>
        Are you sure you want to reject key request for{' '}
        <strong>
          {data.emp.lastName}, {data.emp.firstName}/{data.empId}
        </strong>
        ?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton
        secondary
        content={Lang.LBL_CANCEL}
        onClick={() => handleOpen(false)}
      />
      <ATMButton
        negative
        content={Lang.LBL_YES}
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default KeyRequestDelete;
