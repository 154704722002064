/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
  IATMTabProps,
  ATMMenu,
  ATMHeader,
  ATMIcon,
  ATMLabel,
  ATMButton,
  ATMMessage,
} from 'shared-it-appmod-ui';
import { formatDate } from 'src/libraries/moment.library';
import { IKeyRequest } from 'src/models/key-request.model';
import Lang from 'src/libraries/language';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import {
  KeyHolderType,
  KeyRequestStatus,
  RoleEmpHRStatus,
} from 'src/constants';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import styles from './key-request-requestor-view.module.scss';
import KeyRequestCardRequestor from '../key-request-card-requestor/key-request-card-requestor.component';

type IProps = {
  data: IKeyRequest[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  approvedTotal: number;
  pendingTotal: number;
  rejectedTotal: number;
  currentTab: number;
};

const currentTabTotal = (
  currentTab,
  approvedTotal: number,
  pendingTotal: number,
  rejectedTotal: number,
  total: number
) => {
  switch (currentTab) {
    case 1:
      return approvedTotal;
    case 2:
      return rejectedTotal;
    case 3:
      return total;
    default:
      return pendingTotal;
  }
};

const MyKeyRequestView: React.FC<IProps> = ({
  data,
  handleFetch,
  loading,
  total,
  approvedTotal,
  pendingTotal,
  rejectedTotal,
  currentTab,
}) => {
  const { state: keyRequestCreateState, actions: keyRequestCreateActions } =
    useKeyRequestContext();
  const status = getKeyRequestStatus(
    keyRequestCreateState,
    keyRequestActionTypes.KEY_REQUEST_DATA_CREATE
  );
  const statusEmployee = getKeyRequestStatus(
    keyRequestCreateState,
    keyRequestActionTypes.KEY_REQUEST_DATA_EMPLOYEE_CREATE
  );
  const [errorMsgEmployee, setErrorMsgEmployee] = useState(false);
  const [errorMsgStatus, setErrorMsgStatus] = useState(false);
  const clearError = useCallback(() => {
    keyRequestCreateActions?.clearError();
  }, [keyRequestCreateActions]);

  const columns: IORGDataTableColumn<IKeyRequest>[] = [
    {
      index: 'requestNbr',
      title: Lang.LBL_REQUEST_ID,
      render: (_, value) => <KeyRequestCardRequestor keyRequest={value} />,
    },
    {
      index: 'stat',
      title: Lang.LBL_REQUEST_STATUS,
      render: (_, value) => (
        <>
          <ListStatus
            value={
              value.stat === KeyRequestStatus.APPROVED
                ? Lang.LBL_APPROVED
                : value.stat
            }
          />
        </>
      ),
    },
    {
      index: 'keyRequestDetail.keyNbr',
      title: Lang.LBL_KEY_NUMBER,
      render: (_, value) =>
        value.keyRequestDetail && value.keyRequestDetail.keyNbr
          ? value.keyRequestDetail.keyNbr === 0
            ? Lang.LBL_UNAVAILABLE
            : `${convertKeyNumberToFiveDigits(value.keyRequestDetail.keyNbr)}`
          : '-',
    },
    {
      index: 'keyRequestDetail.keyDetail',
      title: Lang.LBL_KEY_STATUS,
      render: (_, value) =>
        value.keyRequestDetail?.keyDetail?.[0]?.stat ? (
          <ATMLabel horizontal basic>
            {value.keyRequestDetail?.keyDetail?.[0]?.stat}
          </ATMLabel>
        ) : (
          '-'
        ),
    },
    {
      index: 'emp.title',
      title: Lang.LBL_KEY_HOLDER_TYPE,
      render: (_, value) => (
        <div className={styles.keyHistoryFlex}>
          <ATMIcon
            name="circle"
            color="green"
            size="small"
            className={styles.marginTopSmall}
          />
          <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
            {value.emp.title}
          </ATMHeader>
        </div>
      ),
    },
    {
      index: 'emp',
      title: Lang.LBL_REQUEST_FOR,
      render: (_, value) => (
        <div>
          {value.emp.title === KeyHolderType.CONTRACTOR ? (
            <>
              <ATMIcon name="building" size="small" />
              <span>
                {' '}
                {`${value.emp.lastName}, ${value.emp.firstName}`}/
                {value.emp.empId}
              </span>
            </>
          ) : (
            <>
              <ATMIcon name="user" size="small" />
              <span>
                {' '}
                {`${value.emp.lastName}, ${value.emp.firstName}`}/
                {value.emp.empId}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      index: 'issDt',
      title: Lang.LBL_KEY_STATUS_DATE,
      render: (_, value: IKeyRequest) =>
        value.keyRequestDetail.keyDetail &&
        value.keyRequestDetail.keyDetail?.length > 0 &&
        value.stat !== KeyRequestStatus.PENDINGAPPROVAL
          ? formatDate(value.keyRequestDetail.keyDetail[0].issDt)
          : '',
    },
  ];
  useEffect(() => {
    if (statusEmployee.error?.name === 'BadRequest') {
      setErrorMsgEmployee(true);
    }
  }, [statusEmployee.error]);

  useEffect(() => {
    if (errorMsgEmployee) {
      setTimeout(() => {
        setErrorMsgEmployee(false);
        clearError();
      }, 15000);
    }
  }, [errorMsgEmployee]);

  useEffect(() => {
    if (status.error?.name === 'BadRequest') {
      setErrorMsgStatus(true);
    }
  }, [status.error]);

  useEffect(() => {
    if (errorMsgStatus) {
      setTimeout(() => {
        setErrorMsgStatus(false);
        clearError();
      }, 3000);
    }
  }, [errorMsgStatus]);
  const table = (
    <div className="admin-content">
      <ORGDataTable
        celled={false}
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        total={currentTabTotal(
          currentTab,
          approvedTotal,
          pendingTotal,
          rejectedTotal,
          total
        )}
        rowsPerPageOptions={[10, 25, 50, 100]}
        counter
      >
        {({ state, setState }) => ({
          toolbars: [
            () => (
              <span className={styles.selectables}>
                <TableSearch
                  field="keyNbr"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_KEY_NUMBER}
                />
                <TableSearch
                  field="empId"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_KEY_HOLDER_SEARCH}
                />

                <ATMDropdown
                  selection
                  clearable
                  placeholder="Key Type"
                  options={[
                    ...Array.from(Object.keys(Lang.KEY_TYPE)).map((val) => {
                      return {
                        key: val,
                        value: val,
                        text: Lang.KEY_TYPE[val],
                      };
                    }),
                  ]}
                  onChange={(_, val) => {
                    if (val.value) {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'keyTyp',
                            value: val.value,
                          },
                        ],
                      });
                    } else {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'keyTyp',
                            value: undefined,
                          },
                        ],
                      });
                    }
                    return val.value;
                  }}
                />

                <ATMDropdown
                  size="small"
                  selection
                  placeholder={Lang.LBL_EMPLOYEE_STATUS}
                  options={Object.entries(RoleEmpHRStatus)
                    .map(([key, value]) => ({
                      key,
                      value,
                      text: key,
                    }))
                    .filter((val) => val.value === 'I')}
                  onChange={(_, { value }) => {
                    if (value) {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'empStat',
                            value,
                          },
                        ],
                      });
                    } else {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          {
                            name: 'empStat',
                            value: undefined,
                          },
                        ],
                      });
                    }
                  }}
                  clearable
                />
              </span>
            ),
          ],
        })}
      </ORGDataTable>
    </div>
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_PENDING_REQUEST} ({pendingTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_APPROVED} ({approvedTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="2">
          {Lang.LBL_REJECTED} ({rejectedTotal})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="3">
          {Lang.LBL_ALL} ({total})
        </ATMMenu.Item>
      ),
      render: () => <div>{table}</div>,
    },
  ];

  return (
    <div className={styles.content}>
      {' '}
      <div className={styles.headerTabs}>
        {errorMsgStatus && (
          <ATMMessage negative>
            {status.error.message}{' '}
            <ATMButton
              className={styles.closeErrorBtn}
              icon="close"
              onClick={clearError}
            />
          </ATMMessage>
        )}

        {errorMsgEmployee && (
          <ATMMessage negative>
            {statusEmployee.error.message}{' '}
            <ATMButton
              className={styles.closeErrorBtn}
              icon="close"
              onClick={clearError}
            />
          </ATMMessage>
        )}
        <Tabs name="stat" menu={{ pointing: true }} panes={panes} />
      </div>
    </div>
  );
};

export default MyKeyRequestView;
