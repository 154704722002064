/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMSelect,
  ATMDropdown,
  ATMSegment,
  ATMButton,
  ATMMessage,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import {
  IKeyRequestValidationForm,
  KeyRequestValidationFormSchema,
} from 'src/models/key-request.model';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { addDoubleZeroEmpId } from 'src/selectors/employee.selector';
import { useFileContext } from 'src/contexts/file.context';
import style from '../key-request.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IKeyRequestValidationForm) => void;
  handleEnable: (value: boolean) => void;
};

const KeyRequestContractorForm: React.FC<IProp> = ({
  formRef,
  handleSubmit,
  handleEnable,
}) => {
  const [key, setKey] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    valid: false,
    message: '',
  });
  const { state, actions } = useKeyRequestContext();
  const { state: contractFirmState } = useContractorFirmContext();
  const { state: fileState } = useFileContext();

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_VALIDATE_READ
  );

  useEffect(() => {
    if (key.length === 0) {
      handleEnable(false);
      setShowMessage(false);
    }
  }, [key]);

  const handleFile = () => {};

  const handleKeyStatus = useCallback(
    async (control, getValues) => {
      const result = await actions?.validateGET(
        getValues('keyRequestDetail.keyNbr'),
        getValues('keyRequestDetail.noOfKeys')
      );

      if (!result.error) {
        handleEnable(true);
      }

      setShowMessage(true);
      setValidationMessage({
        valid: !result.error,
        message: result.payload
          ? result.payload?.message
          : result.error.message,
      });
    },
    [actions, showMessage, validationMessage]
  );

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      validationSchema={KeyRequestValidationFormSchema}
    >
      {({
        control,
        formState: { errors },
        getValues,
        setValue,
        register,
        watch,
      }) => {
        register('empId');
        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column width="6">
                <ATMField
                  as={DropdownEmployee}
                  control={control}
                  name="empInfo"
                  label={Lang.LBL_KEY_REQUEST_CONTRACTOR_EMP_ID}
                  onChange={([_, { value }]) => {
                    setValue('empId', addDoubleZeroEmpId(value));
                    return value;
                  }}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width="5">
                <ATMField
                  label={Lang.LBL_KEY_REQUEST_CONTRACTOR_FIRM_NAME}
                  name="keyRequestDetail.keyDetail.firmName"
                  placeholder="Select"
                  as={ATMSelect}
                  control={control}
                  error={
                    errors.keyRequestDetail &&
                    errors.keyRequestDetail.keyDetail &&
                    errors.keyRequestDetail.keyDetail.firmName
                  }
                  required
                  options={contractFirmState.list.map((value) => ({
                    key: value.contracShortNm,
                    value: value.contracShortNm,
                    text: value.contracShortNm,
                  }))}
                  onChange={([_, { value }]) => value}
                  selectOnBlur={false}
                  search
                  clearable
                />
              </ATMGrid.Column>
              <ATMGrid.Column width="5">
                <ATMField
                  placeholder="Select"
                  name="keyRequestDetail.keyTypId"
                  label={Lang.LBL_KEY_REQUEST_CONTRACTOR_KEY_TYPE}
                  as={ATMDropdown}
                  control={control}
                  error={errors.keyRequestDetail?.keyTypId}
                  required
                  selection
                  clearable
                  selectOnBlur={false}
                  options={[
                    ...Array.from(Object.values(Lang.KEY_TYPE)).map((val) => {
                      return {
                        key: val,
                        value: val,
                        text: Lang.KEY_TYPE[val],
                      };
                    }),
                  ]}
                  onChange={([_, { value }]) => {
                    setKey(value);
                    return value;
                  }}
                  search
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            {key ? (
              <>
                <ATMGrid.Row>
                  <ATMGrid.Column>
                    <ATMSegment
                      className={key.length !== 0 ? 'applySegment' : ''}
                    >
                      <h3>{key}</h3>
                      <ATMGrid>
                        <ATMGrid.Row columns="equal" verticalAlign="bottom">
                          <ATMGrid.Column width="6">
                            <ATMField
                              as={ATMInput}
                              label={Lang.LBL_KEY_REQUEST_CONTRACTOR_KEY_NO}
                              name="keyRequestDetail.keyNbr"
                              type="number"
                              control={control}
                              error={
                                errors.keyRequestDetail &&
                                errors.keyRequestDetail.keyNbr
                              }
                            />
                          </ATMGrid.Column>
                          <ATMGrid.Column width="5">
                            <ATMField
                              as={ATMInput}
                              label={Lang.LBL_KEY_REQUEST_CONTRACTOR_NO_KEY}
                              name="keyRequestDetail.noOfKeys"
                              type="number"
                              control={control}
                              error={
                                errors.keyRequestDetail &&
                                errors.keyRequestDetail.noOfKeys
                              }
                            />
                          </ATMGrid.Column>
                          <ATMGrid.Column>
                            <ATMField
                              as={ATMButton}
                              type="button"
                              name="validateKey"
                              content={
                                Lang.LBL_KEY_REQUEST_CONTRACTOR_VALIDATE_BTN
                              }
                              disabled={
                                watch('keyRequestDetail.noOfKeys') &&
                                watch('keyRequestDetail.keyNbr')
                                  ? errors.keyRequestDetail?.noOfKeys ||
                                    errors.keyRequestDetail?.keyNbr
                                  : !watch('keyRequestDetail.noOfKeys') ||
                                    !watch('keyRequestDetail.keyNbr')
                              }
                              secondary
                              onClick={() =>
                                handleKeyStatus(control, getValues)
                              }
                              loading={status.fetching}
                              id="validate"
                            />
                          </ATMGrid.Column>
                        </ATMGrid.Row>
                        <ATMGrid.Row columns="equal">
                          <ATMGrid.Column width="16">
                            {showMessage ? (
                              validationMessage.valid ? (
                                <ATMMessage positive>
                                  <h4>{Lang.MSG_VALID_KEY_NUMBERS}</h4>
                                  <p>{validationMessage.message}</p>
                                  {validationMessage.valid}
                                </ATMMessage>
                              ) : (
                                <ATMMessage negative>
                                  <h4>{Lang.MSG_INVALID_KEY_NUMBERS}</h4>
                                  <p>{validationMessage.message}</p>
                                  {validationMessage.valid}
                                </ATMMessage>
                              )
                            ) : (
                              ''
                            )}
                          </ATMGrid.Column>
                        </ATMGrid.Row>
                      </ATMGrid>
                    </ATMSegment>
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </>
            ) : null}
            <ATMGrid.Row columns="equal">
              <ATMField
                name="requestTyp"
                control={control}
                defaultValue="New Key"
                as={ATMInput}
                type="hidden"
                className="hidden"
              />
              <ATMField
                name="requestorTyp"
                control={control}
                defaultValue="C"
                as={ATMInput}
                type="hidden"
                className="hidden"
              />
              <ATMField
                name="requestedBy"
                control={control}
                defaultValue={getUser()?.emp_no}
                as={ATMInput}
                type="hidden"
                className="hidden"
              />
              <ATMField
                name="keyRequestDetail.approvalEmail"
                control={control}
                defaultValue={false}
                as={ATMInput}
                type="hidden"
                className="hidden"
              />
              <ATMGrid.Column>
                <label className={style.uploadBtn}>
                  {Lang.LBL_ATTACH_APPROVER_EMAIL}
                </label>
                <FileUploader
                  icon="upload"
                  onSuccess={() => {
                    setValue('keyRequestDetail.approvalEmail', true);
                    handleFile();
                  }}
                  text={Lang.LBL_UPLOAD}
                  successMessage="Your file(s) has been cached, but will not be uploaded until the Key Request is Created/Submitted"
                  errorMessage="Failed to cache file(s)"
                  hasSharePointError={fileState?.hasSharepointError}
                  popOverPostition="bottom left"
                  popOverStyle={{ color: 'red', width: '250px' }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default KeyRequestContractorForm;
