import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/trouble-jobs.service';
import { IIsoOutageType, ITroubleJobs } from 'src/models/trouble-jobs.model';

export const troubleJobsActionTypes = {
  TROUBLE_JOBS_DATA_READ: 'TROUBLE_JOBS_DATA_READ',
  TROUBLE_JOBS_LIST_READ: 'TROUBLE_JOBS_LIST_READ',
  TROUBLE_JOBS_DATA_CREATE: 'TROUBLE_JOBS_DATA_CREATE',
  TROUBLE_JOBS_DATA_ALARM_CREATE: 'TROUBLE_JOBS_DATA_ALARM_CREATE',
  TROUBLE_JOBS_DATA_UPDATE: 'TROUBLE_JOBS_DATA_UPDATE',
  TROUBLE_JOBS_DATA_DELETE: 'TROUBLE_JOBS_DATA_DELETE',
  TROUBLE_JOBS_DATA_SET: 'TROUBLE_JOBS_DATA_SET',
  ISO_OUTAGE_TYPE_LIST_READ: 'ISO_OUTAGE_TYPE_LIST_READ',
  TROUBLE_JOB_LISTCLEAR: 'TROUBLE_JOB_LISTCLEAR',
  TROUBLE_JOB_LIST_ALL_READ: 'TROUBLE_JOB_LIST_ALL_READ',
  TROUBLE_JOB_STASH_BEGIN_DATE: 'TROUBLE_JOB_STASH_BEGIN_DATE',
  TROUBLE_JOB_STASH_BEGIN_TIME: 'TROUBLE_JOB_STASH_BEGIN_TIME',
  TROUBLE_JOB_STASH_END_DATE: 'TROUBLE_JOB_STASH_END_DATE',
  TROUBLE_JOB_STASH_END_TIME: 'TROUBLE_JOB_STASH_END_TIME',
  TROUBLE_JOB_CLEAR_STASH_DATE_TIME: 'TROUBLE_JOB_CLEAR_STASH_DATE_TIME',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_LIST_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: troubleJobsActionTypes.TROUBLE_JOB_LIST_ALL_READ,
    service: services.listGET,
  },

  outageGET: {
    type: troubleJobsActionTypes.ISO_OUTAGE_TYPE_LIST_READ,
    service: services.outageGET,
  },

  createPOST: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  createStationAlarmPOST: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_ALARM_CREATE,
    service: services.createStationAlarmPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_UPDATE,
    service: services.updatePUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataDELETE: {
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (troubleJobs?: ITroubleJobs) => ({
    type: troubleJobsActionTypes.TROUBLE_JOBS_DATA_SET,
    payload: troubleJobs,
  }),
  listCLEAR: () => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_LISTCLEAR,
  }),
  stashBeginDate: (year: any, month: any, day: any) => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_STASH_BEGIN_DATE,
    payload: new Date(year, month, day),
  }),
  stashBeginTime: (hour: any, min: any) => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_STASH_BEGIN_TIME,
    payload: [hour, min],
  }),
  stashEndDate: (year: any, month: any, day: any) => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_STASH_END_DATE,
    payload: new Date(year, month, day),
  }),
  stashEndTime: (hour: any, min: any) => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_STASH_END_TIME,
    payload: [hour, min],
  }),
  clearDateTimeHistory: () => ({
    type: troubleJobsActionTypes.TROUBLE_JOB_CLEAR_STASH_DATE_TIME,
  }),
};

export type ITroubleJobsAsync = typeof duckActions;

export interface ITroubleJobsState
  extends ICommonState<typeof troubleJobsActionTypes> {
  data?: ITroubleJobs;
  list: ITroubleJobs[];
  listAll: ITroubleJobs[];
  total: number;
  openTotal: number;
  closedTotal: number;
  outageType: IIsoOutageType;
  beginDate: Date | undefined;
  endDate: Date | undefined;
  startTime: any[];
  endTime: any[];
}

export const defaultState: ITroubleJobsState = {
  status: {},
  list: [],
  listAll: [],
  total: 0,
  openTotal: 0,
  closedTotal: 0,
  outageType: [],
  beginDate: undefined,
  endDate: undefined,
  startTime: [],
  endTime: [],
};

const TroubleJobsReducer = (
  state: ITroubleJobsState,
  action: IReducerAction<ITroubleJobsAsync>
): ITroubleJobsState => {
  switch (action.type) {
    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_SET:
    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_READ:
    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_UPDATE:
    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_ALARM_CREATE:
    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOBS_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        // total: action.payload?.count.all ?? 0,
        openTotal: action.payload?.count.open ?? 0,
        closedTotal: action.payload?.count.closed ?? 0,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_LIST_ALL_READ: {
      return {
        ...state,
        listAll: action.payload?.rows ?? [],
        total: action.payload?.count.all ?? 0,
        // openTotal: action.payload?.count.open ?? 0,
        closedTotal: action.payload?.count.closed ?? 0,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOBS_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.troubleJobId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case troubleJobsActionTypes.ISO_OUTAGE_TYPE_LIST_READ: {
      return {
        ...state,
        outageType: action.payload,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_STASH_BEGIN_DATE: {
      return {
        ...state,
        beginDate: action.payload,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_STASH_END_DATE: {
      return {
        ...state,
        endDate: action.payload,
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_STASH_BEGIN_TIME: {
      return {
        ...state,
        startTime: action.payload ?? [],
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_STASH_END_TIME: {
      return {
        ...state,
        endTime: action.payload ?? [],
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_CLEAR_STASH_DATE_TIME: {
      return {
        ...state,
        beginDate: undefined,
        endDate: undefined,
        endTime: [],
        startTime: [],
      };
    }

    case troubleJobsActionTypes.TROUBLE_JOB_LISTCLEAR: {
      return {
        ...state,
        list: [],
        listAll: [],
        total: 0,
        // openTotal: 0,
        closedTotal: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default TroubleJobsReducer;
