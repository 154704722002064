export enum DailyReportViewTab {
  ETS = 'ETS',
  RadializedSubstations = 'Radialized Substations',
  Overview = 'Overview',
  SystemChanges = 'System Changes',
}

export enum TransmissionOutageStatus {
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  OUT = 'OUT',
  LATE_TO_END = 'LATE_TO_END',
  LATE_TO_START = 'LATE_TO_START',
  IN_OK = 'IN_OK',
  OUT_OK = 'OUT_OK',
  IN_SERVICE_EDITABLE = 'IN_SERVICE_EDITABLE',
  IN_SERVICE = 'IN_SERVICE',
  CONFLICT_IDENTIFIED = 'CONFLICT_IDENTIFIED',
  STUDY = 'STUDY',
  SUBMITTED = 'SUBMITTED',
}
