import React from 'react';
import { AccessType } from 'src/constants';
import { hasAccess } from 'src/libraries/access.library';

type IProps = {
  type: AccessType | AccessType[];
  projectId?: number;
  bypass?: boolean;
  when?: boolean;
};

const AccessComponent: React.FC<React.PropsWithChildren<IProps>> = ({
  type,
  when = true,
  children,
}) => {
  if (hasAccess(type) && when) {
    return <>{children}</>;
  }

  return null;
};

export { AccessType };

const Access = React.memo(AccessComponent);

export default Access;
