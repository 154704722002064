import React, { useCallback, useEffect } from 'react';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import useLocationTab from 'src/hooks/location-tab.hook';
import {
  getKeyRequestStatus,
  getKeyRequestParams,
} from 'src/selectors/key-request.selector';
import KeyRequestListView from './key-request-list.view';

const KeyRequestList: React.FC = () => {
  const { state, actions } = useKeyRequestContext();
  const { currentTab } = useLocationTab('stat');
  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_LIST_READ
  );

  useEffect(() => {
    return () => {
      actions.clearError();
    };
  }, [actions]);

  const handleFetch = useCallback(
    (params) => {
      const data = getKeyRequestParams(params, currentTab);
      actions?.listGET(data);
    },
    [actions, currentTab]
  );
  return (
    <KeyRequestListView
      data={state.list}
      total={state.total}
      loading={status.fetching}
      handleFetch={handleFetch}
      approvedTotal={state.approvedTotal}
      pendingTotal={state.pendingTotal}
      rejectedTotal={state.rejectedTotal}
      currentTab={currentTab}
    />
  );
};

export default KeyRequestList;
