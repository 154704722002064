import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  ocPendingActionReportActionTypes,
  IOcPendingActionReportState,
} from 'src/ducks/oc-pending-action-report.duck';
import Moment, { formatDateTime } from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';

export const getOcPendingActionReportStatus = (
  state: IOcPendingActionReportState,
  action: keyof typeof ocPendingActionReportActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getOcPendingActionReportParams = (
  params: Partial<IORGDataTableQueryState>
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;

  if (data.filters) {
    const requestId = data.filters?.filter((val) => val.name === 'requestId');

    const fromDate = data.filters?.filter((val) => val.name === 'fromDate');
    const toDate = data.filters?.filter((val) => val.name === 'toDate');

    if (requestId && requestId.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'requestId');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'requestId',
          value: requestId?.[0].value,
        },
      ];
    }

    if (fromDate && fromDate?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'fromDate');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'fromDate',
          value: formatDateTime(
            Moment(fromDate[0].value).startOf('day').toDate()
          ),
        },
      ];
    }

    if (toDate && toDate?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'toDate');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'toDate',
          value: formatDateTime(Moment(toDate[0].value).endOf('day').toDate()),
        },
      ];
    }
  }

  return data;
};
