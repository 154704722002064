import React, { useEffect } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  ATMDropdown,
  ATMLabel,
  MOLInfoText,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import { IUserGrp } from 'src/models/facility.model';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useUserGroupContext } from 'src/contexts/user-group.context';
import FacilityNotifApprovalsItemAdd from './facility-notif-approvals-item/facility-notif-approvals-item-add/facility-notif-approvals-item-add.component';
import FacilityNotifApprovalsItemEdit from './facility-notif-approvals-item/facility-notif-approvals-item-edit/facility-notif-approvals-item-edit.component';
import FacilityNotifApprovalsItemDelete from './facility-notif-approvals-item/facility-notif-approvals-item-delete/facility-notif-approvals-item-delete.component';

interface IProps {
  setUserGroups: React.Dispatch<React.SetStateAction<IUserGrp[]>>;
}
const NotificationTableApprovals: React.FC<IProps> = ({ setUserGroups }) => {
  const {
    state: { data },
  } = useFacilityContext();
  const { state, actions } = useUserGroupContext();

  useEffect(() => {
    actions?.listGET({ limit: 0 });
  }, [actions]);

  const columns: IORGDataTableColumn<IUserGrp>[] = [
    {
      index: 'userGrpNm',
      title: Lang.LBL_GROUP_NAME,
    },
    {
      index: 'notificationEmail',
      title: Lang.LBL_NOTIFICATION_EMAIL,
      render: (_, value) => {
        return value.notificationEmail?.map((item) =>
          item && item !== '' ? (
            <ATMLabel
              content={item}
              key={item}
              style={{ marginBottom: '.5em' }}
            />
          ) : (
            '--'
          )
        );
      },
    },
    {
      index: 'approvalEmail',
      title: Lang.LBL_APPROVAL_EMAIL,
      render: (_, value) => {
        return value.approvalEmail?.map((item) =>
          item && item !== '' ? (
            <ATMLabel
              content={item}
              key={item}
              style={{ marginBottom: '.5em' }}
            />
          ) : (
            '--'
          )
        );
      },
    },
    {
      index: 'userGrpEmail',
      title: Lang.LBL_EMAILS_MEMBERS,
      render: (_, { userGrpEmail, userGrpMembers }) => {
        const memberEmails = userGrpMembers
          ?.map((item) => item.emailAdd)
          .filter((item) => item !== undefined);
        return (
          <MOLInfoText
            transparent
            limit={30}
            content={
              userGrpEmail ??
              (memberEmails !== undefined && memberEmails.length !== 0
                ? memberEmails.join(', ')
                : '--')
            }
          />
        );
      },
      width: '35%',
    },
    {
      index: 'action',
      title: Lang.LBL_ACTION,
      render: (_, value) => (
        <ATMDropdown icon="ellipsis horizontal">
          <ATMDropdown.Menu>
            <FacilityNotifApprovalsItemEdit
              defaultValues={value}
              userGroup={state.list}
              setUserGroups={setUserGroups}
            />
            <FacilityNotifApprovalsItemDelete defaultValues={value} />
          </ATMDropdown.Menu>
        </ATMDropdown>
      ),
      width: '5%',
    },
  ];

  const userGrps = (data && data?.userGrps) ?? [];

  return (
    <>
      {userGrps.length > 0 ? (
        <>
          <ORGDataTable columns={columns} data={userGrps} celled={false} />
          <FacilityNotifApprovalsItemAdd addMore userGroup={state.list} />
        </>
      ) : (
        <EmptyNotification
          content={<FacilityNotifApprovalsItemAdd userGroup={state.list} />}
        />
      )}
    </>
  );
};

export default NotificationTableApprovals;
