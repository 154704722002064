import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import IsoCauseReducer, {
  IIsoCauseState,
  defaultState,
  duckActions,
} from 'src/ducks/iso-cause.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    IsoCauseReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IIsoCauseContext = ReturnType<typeof useReducer>;

const IsoCauseContext = createContext<Partial<IIsoCauseContext>>({
  state: defaultState,
}) as React.Context<IIsoCauseContext>;

type IProps = {
  state?: Partial<IIsoCauseState>;
};

const IsoCauseProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <IsoCauseContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </IsoCauseContext.Provider>
  );
};

const useIsoCauseContext = () => useContext(IsoCauseContext);

export type IUseIsoCauseContext = ReturnType<typeof useIsoCauseContext>;

export { IsoCauseContext, IsoCauseProvider, useIsoCauseContext };
