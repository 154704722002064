import React, { useCallback } from 'react';
import { useSubstationContext } from 'src/contexts/substation.context';
import { ISubstation } from 'src/models/substation.model';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { substationActionTypes } from 'src/ducks/substation.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import history from 'src/history';
import SubstationDeleteView from './substation-delete.view';

type IProp = {
  data: ISubstation;
  isOpen: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubstationDelete: React.FC<IProp> = ({
  data,
  isOpen,
  handleOpen,
  handleOpenEdit,
}) => {
  const { state, actions } = useSubstationContext();

  const status = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.substationId) {
      const response = await actions.dataDELETE(data.substationId);

      if (!response.error) {
        actions.listGET();
        handleOpen(false);
        handleOpenEdit(false);
        ToastSuccess(
          Lang.formatString(Lang.MSG_SUCCESS_DELETE_SUBSTATION, data.name)
        );
        history.push(history.location.search);
      } else if (
        response.error.message ===
        'Substation has LER(s) associated - Cannot Delete'
      ) {
        ToastError('Substation has LER(s) associated - Cannot Delete');
        handleOpen(false);
        handleOpenEdit(false);
        history.push(history.location.search);
      } else {
        ToastError(response.error.message);
        handleOpen(false);
        handleOpenEdit(false);
        history.push(history.location.search);
      }
    }
  }, [actions, data]);

  return (
    <SubstationDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={handleOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default SubstationDelete;
