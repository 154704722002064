import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IUserGroup } from 'src/models/user-group.model';

type IProp = {
  data: IUserGroup;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const UserGroupDeleteView: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMButton
        style={{ float: 'left' }}
        basic
        color="red"
        content={Lang.LBL_DELETE}
        id="delete"
        onClick={() => handleOpen(true)}
      />
    }
  >
    <ATMModal.Header>{Lang.TTL_USER_GROUP_DELETE}</ATMModal.Header>
    <ATMModal.Content>
      <p>
        {'Are you sure you want to delete '}
        <strong>{data.userGrpNm}</strong>?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary content="Cancel" onClick={() => handleOpen(false)} />
      <ATMButton
        negative
        content="Yes, Delete"
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
        id="confirmDelete"
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default UserGroupDeleteView;
