import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IShortDescription,
  IShortDescriptionCategory,
} from 'src/models/short-description.model';

type IProp = {
  data: IShortDescription & IShortDescriptionCategory;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesc?: boolean;
  handleSubmit: () => void;
};

const CategoryDelete: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  isDesc,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      isDesc ? (
        <ATMButton
          icon="trash alternate outline"
          onClick={() => handleOpen(true)}
          type="button"
        />
      ) : (
        <ATMButton
          style={{ float: 'left' }}
          basic
          color="red"
          content={Lang.LBL_DELETE}
          onClick={() => handleOpen(true)}
          type="button"
        />
      )
    }
  >
    <ATMModal.Header>
      {isDesc ? Lang.TTL_DESCRIPTION_DELETE : Lang.TTL_CATEGORY_DELETE}
    </ATMModal.Header>
    <ATMModal.Content>
      <p>
        {'Are you sure you want to delete '}
        <strong>{isDesc ? data.shortDesc : data.shortDescCat}</strong>?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary content="Cancel" onClick={() => handleOpen(false)} />
      <ATMButton
        negative
        content="Yes, Delete"
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default CategoryDelete;
