import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import EmployeeReducer, {
  IEmployeeState,
  defaultState,
  duckActions,
} from 'src/ducks/employee.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    EmployeeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IEmployeeContext = ReturnType<typeof useReducer>;

const EmployeeContext = createContext<Partial<IEmployeeContext>>({
  state: defaultState,
}) as React.Context<IEmployeeContext>;

type IProps = {
  state?: Partial<IEmployeeState>;
};

const EmployeeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <EmployeeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

const useEmployeeContext = () => useContext(EmployeeContext);

export type IUseEmployeeContext = ReturnType<typeof useEmployeeContext>;

export { EmployeeContext, EmployeeProvider, useEmployeeContext };
