import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { TroubleJobStatus, troubleJobTab } from 'src/constants';
import {
  troubleJobsActionTypes,
  ITroubleJobsState,
} from 'src/ducks/trouble-jobs.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getTroubleJobsStatus = (
  state: ITroubleJobsState,
  action: keyof typeof troubleJobsActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getTroubleJobParams = (
  params: IORGDataTableQueryState,
  currentTab: number
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;

  if (keyword) {
    data.filters = [
      ...(data.filters ?? []),
      {
        name: 'id',
        value: keyword,
      },
    ];
  }
  switch (+currentTab) {
    case troubleJobTab.Open: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'jobStatus',
          value: TroubleJobStatus.OPEN,
        },
      ];
      break;
    }

    case troubleJobTab.All: {
      data.filters = [...(data.filters ?? [])];
      break;
    }

    default: {
      break;
    }
  }

  return data;
};
