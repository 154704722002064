import React from 'react';
import {
  ATMDropdown,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
} from 'shared-it-appmod-ui';
import { ISubstation } from 'src/models/substation.model';
import Lang from 'src/libraries/language';
import styles from 'src/components/pages/substation/substation-list/substation-list.module.scss';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import history from 'src/history';
import SubstationEdit from '../substation-edit/substation-edit.component';
// import SubstationDelete from '../substation-delete/substation-delete.component';

const columns: IORGDataTableColumn<ISubstation>[] = [
  {
    index: 'name',
    title: 'ID-Name',
    render: (_, value) => (
      <>
        {' '}
        <b>{value.substationId}</b> - {value.name}{' '}
      </>
    ),
  },
  {
    index: 'addr1',
    sortable: true,
    title: 'Location Address',
  },
  {
    index: 'city',
    sortable: true,
    title: 'City',
  },
  {
    index: 'phnNbr',
    title: 'Phone Number',
    render: (_, value) => {
      if (!value.areaCd && value.phnNbr) {
        return value.phnNbr.replace(/^(\d{3})(\d{4})/, '$1-$2');
      }
      if (value.areaCd && value.phnNbr) {
        let phoneNum = value.phnNbr;
        if (phoneNum.indexOf('-') === -1) {
          phoneNum = phoneNum.replace(/^(\d{3})(\d{4})/, '$1-$2');
          return value.areaCd
            ?.concat(phoneNum)
            .replace(/^(\d{3})(\d{3})/, '$1-$2');
        }
        const arCdPhone = value.areaCd?.concat(phoneNum);
        return arCdPhone.replace(/^(\d{3})(\d{3})/, '$1-$2');
      }
      return '–';
    },
  },
  {
    index: 'subDistId',
    title: 'Districts',
  },
  {
    index: 'action',
    title: 'Action',
    width: '120px',
    sortable: false,
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <SubstationEdit data={value} details={false} />
        {/* <SubstationDelete data={value} /> */}
      </>
    ),
  },
];

type IProps = {
  data: ISubstation[];
  total: number;
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
  filter: ISubstation[];
};

const SubstationListView: React.FC<IProps> = ({
  data,
  total,
  loading,
  handleFetch,
  handleDownload,
  filter,
}) => {
  return (
    <>
      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={data}
          onChange={handleFetch}
          loading={loading}
          counter
          celled={false}
          rowsPerPageOptions={[10, 25, 50, 100]}
          total={total}
          location={history.location}
          handleLocation={history.push}
          sortable
        >
          {({ state, setState }) => ({
            toolbars: {
              subDistId: ({ value, setValue }) => (
                <span className={styles.selectables}>
                  <ATMDropdown
                    placeholder={Lang.LBL_DISTRICTS}
                    selection
                    size="small"
                    search
                    clearable
                    options={[
                      ...Array.from(
                        new Set(
                          filter.map((item) => item.subDistId).filter((x) => x)
                        )
                      ).map((val) => {
                        return {
                          key: val ?? '',
                          value: val ?? '',
                          text: val ?? '',
                        };
                      }),
                    ]}
                    value={value}
                    onChange={(_, val) => {
                      setValue(val.value);
                      return val.value;
                    }}
                    selectOnBlur={false}
                  />
                </span>
              ),
              search: () => (
                <TableSearch
                  field="name"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_NAME_SEARCH}
                />
              ),
              buttons: () => (
                <div>
                  <ATMButton
                    key="download"
                    secondary
                    size="tiny"
                    icon="download"
                    onClick={() => {
                      handleDownload({
                        ...state,
                        page: 1,
                        limit: total,
                      });
                    }}
                  />
                </div>
              ),
            },
          })}
        </ORGDataTable>
      </div>
    </>
  );
};

export default SubstationListView;
