import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  IsystemChangeCompleteForm,
  SystemChangeListPayloadSchema,
  SystemChangePayloadSchema,
  SystemChangeSchema,
} from 'src/models/system-change.model';

const client = httpClient();
const endpoint = 'daily-report';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, SystemChangePayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    return client.get(
      `/${endpoint}?action=SYSCHANGE`,
      data,
      SystemChangeListPayloadSchema
    );
  },

  dataPUT: async (id: number, data: Partial<IsystemChangeCompleteForm>) => {
    return client.put(
      `/${endpoint}/${id}?action=complete-system-change`,
      data,
      SystemChangeSchema
    );
  },
};

export default services;
