import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ShortDescriptionReducer, {
  IShortDescriptionState,
  defaultState,
  duckActions,
} from 'src/ducks/short-description.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    ShortDescriptionReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IShortDescriptionContext = ReturnType<typeof useReducer>;

const ShortDescriptionContext = createContext<
  Partial<IShortDescriptionContext>
>({
  state: defaultState,
}) as React.Context<IShortDescriptionContext>;

type IProps = {
  state?: Partial<IShortDescriptionState>;
};

const ShortDescriptionProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ShortDescriptionContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ShortDescriptionContext.Provider>
  );
};

const useShortDescriptionContext = () => useContext(ShortDescriptionContext);

export type IUseShortDescriptionContext = ReturnType<
  typeof useShortDescriptionContext
>;

export {
  ShortDescriptionContext,
  ShortDescriptionProvider,
  useShortDescriptionContext,
};
