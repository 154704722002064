import { getUser } from 'src/libraries/amplify.library';

export enum LocalStorageId {
  TROUBLE_TICKET = 'trouble_ticket_list',
  DAILY_REPORT = 'Daily_Report',
  DAILY_REPORT_ETS = 'ETS_Date',
  DAILY_REPORT_RADIALIZED_SUBSTATION = 'Radialized_Substation_Date',
  DAILY_REPORT_OVERVIEW = 'Overview_Date',
  DAILY_REPORT_SYSTEM_CHANGES = 'System_Changes_Date',
  DAILY_REPORT_CURRENT_TAB = 'Current_Tab',
  OUTAGE_CALENDAR_FILTERS = 'outage_calendar_filters',
}

export const setLocalStorageItem = (
  key: string,
  value: any,
  minutes?: number
) => {
  const user = getUser();

  const item: {
    value: any;
    expiry?: number;
  } = {
    value,
  };

  if (minutes && minutes > 0) {
    const expiry = minutes * 60000;
    item.expiry = new Date().getTime() + expiry;
  } else if (minutes && minutes <= 0) {
    // eslint-disable-next-line no-console
    console.error(
      `Local Storage Error: You've set ${minutes} in minutes, which is invalid number when enabling expiration`
    );

    return;
  }
  localStorage.setItem(`${user?.emp_no}_${key}`, JSON.stringify(item));
};

export const getLocalStorageItem = (key: string, defaultReturn = null) => {
  const user = getUser();
  const params = localStorage.getItem(`${user?.emp_no}_${key}`);

  if (params) {
    const item = JSON.parse(params);
    if (item.expiry && new Date().getTime() > item.expiry) {
      localStorage.removeItem(`${user?.emp_no}_${key}`);
      return null;
    }

    return item.value;
  }

  return defaultReturn;
};

export const removeLocalStrongItem = (key) => {
  const user = getUser();

  localStorage.removeItem(`${user?.emp_no}_${key}`);
};

export const clearLocalStorage = () => {
  const user = getUser();

  Object.values(LocalStorageId).map((key) =>
    localStorage.removeItem(`${user?.emp_no}_${key}`)
  );
};
