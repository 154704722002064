import {
  authorizedListActionTypes,
  IAuthorizedListState,
} from 'src/ducks/authorized-list.duck';
import Moment from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import { IAuthorizedListTraining } from 'src/models/authorized-list.model';

export const getAuthorizedListStatus = (
  state: IAuthorizedListState,
  action: keyof typeof authorizedListActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getTrainingSet = (
  data: IAuthorizedListTraining[],
  authTypeId: string
): IAuthorizedListTraining[] => {
  return data.filter((item) => item.authTypId === authTypeId);
};

export const getNearestTrainingDate = (
  data: IAuthorizedListTraining[]
): Date => {
  return Moment.max(data.map((item) => Moment(item.trainingDate))).toDate();
};
