import { trainersActionTypes, ITrainersState } from 'src/ducks/trainers.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getTrainersStatus = (
  state: ITrainersState,
  action: keyof typeof trainersActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
