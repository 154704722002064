import {
  substationEntryActionTypes,
  ISubstationEntryState,
} from 'src/ducks/substation-entry.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getSubstationEntryStatus = (
  state: ISubstationEntryState,
  action: keyof typeof substationEntryActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
