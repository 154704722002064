import React from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import Lang from 'src/libraries/language';
import {
  IKeyRequestEmployeeIssueValidationForm,
  KeyRequestEmployeeIssueValidationFormSchema,
} from 'src/models/key-request.model';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { addDoubleZeroEmpId } from 'src/selectors/employee.selector';
import { getUser } from 'src/libraries/amplify.library';
import { IEmployee } from 'src/models/employee.model';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  employee?: IEmployee;
  handleSubmit: (data: IKeyRequestEmployeeIssueValidationForm) => void;
  handleEnable: (value: boolean) => void;
  handleSearch: (id) => void;
  isDirtyEmployee: boolean;
  setIsDirtyEmployee: React.Dispatch<React.SetStateAction<boolean>>;
};
const rndNumFor30 = Number(
  '30'.concat(Math.floor(100 + Math.random() * 900).toString())
);
const rndNumFor40 = Number(
  '40'.concat(Math.floor(100 + Math.random() * 900).toString())
);
const KeyRequestEmployeeForm: React.FC<IProp> = ({
  formRef,
  employee,
  handleSubmit,
  handleEnable,
  handleSearch,
  isDirtyEmployee,
  setIsDirtyEmployee,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={(data) => {
        const empId = getUser()?.emp_no;
        const firstName = getUser()?.first_name;
        const lastName = getUser()?.last_name;

        handleSubmit({
          ...data,
          emp: { jobCd: employee?.job_code },
          requestor: { empId, lastName, firstName },
          requestedBy: `${lastName}, ${firstName} /  ${empId}`,
          keyRequestDetail: {
            ...data.keyRequestDetail,
            noOfKeys: 1,
            approvalEmail: false,
            supvrNbr: employee?.mgr_empno,
          },
        });
      }}
      mode="onChange"
      validationSchema={KeyRequestEmployeeIssueValidationFormSchema}
    >
      {({ control, formState: { errors, isDirty }, setValue }) => {
        handleEnable(isDirty);

        return (
          <>
            {/* hidden form */}
            <ATMField
              as={ATMInput}
              name="empId"
              type="hidden"
              className="hidden"
              control={control}
            />
            <ATMField
              name="requestorTyp"
              control={control}
              defaultValue="E"
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyNbr"
              control={control}
              defaultValue="E"
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyDetail.firmName"
              control={control}
              defaultValue=" "
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMGrid columns={2}>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_KEY_REQUESTER}>
                    <p>
                      {getUser()?.last_name}, {getUser()?.first_name} /{' '}
                      {getUser()?.emp_no}
                    </p>
                  </FieldReadOnly>
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    label={
                      <LabelRequired>
                        {Lang.LBL_KEY_HOLDER_EMPLOYEE_ID}
                      </LabelRequired>
                    }
                    as={DropdownEmployee}
                    name="empInfo"
                    control={control}
                    onChange={([_, { value }]) => {
                      handleSearch(value);
                      setIsDirtyEmployee(true);
                      setValue('empId', addDoubleZeroEmpId(value));
                      return value;
                    }}
                    error={errors.empId}
                    placeholder="Enter"
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              {isDirtyEmployee && employee && (
                <ATMGrid.Row>
                  <ATMGrid.Column>
                    <FieldReadOnly label={Lang.LBL_KEY_SUPERVISOR_MANAGER_ID}>
                      <p>
                        {`${employee.mgr_last_name}, ${employee.mgr_first_name} / ${employee.mgr_empno}`}
                      </p>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                  <ATMGrid.Column>
                    <FieldReadOnly label={Lang.LBL_KEY_ADMIN_MAIL_LOCATION}>
                      <p>{`${employee.mailstop_id}`}</p>
                    </FieldReadOnly>
                  </ATMGrid.Column>
                </ATMGrid.Row>
              )}
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name="keyRequestDetail.keyTypId"
                    label={<LabelRequired>{Lang.LBL_KEY_TYPE}</LabelRequired>}
                    error={errors.keyRequestDetail?.keyTypId}
                    placeholder="Select"
                    selection
                    clearable
                    required
                    selectOnBlur={false}
                    options={[
                      ...Array.from(Object.values(Lang.KEY_TYPE)).map((val) => {
                        return {
                          key: val,
                          value: val,
                          text: Lang.KEY_TYPE[val],
                        };
                      }),
                    ]}
                    onChange={([_, { value }]) => {
                      if (value === '30 Key') {
                        setValue('keyRequestDetail.keyNbr', rndNumFor30);
                      }
                      if (value === '40 Key') {
                        setValue('keyRequestDetail.keyNbr', rndNumFor40);
                      }
                      return value;
                    }}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name="requestTyp"
                    label={Lang.LBL_KEY_REQUEST_TYPE}
                    error={errors.requestTyp}
                    placeholder="Select"
                    selection
                    clearable
                    required
                    selectOnBlur={false}
                    options={['New Key', 'Replace Damaged', 'Replace Lost'].map(
                      (value) => ({
                        key: value,
                        value,
                        text: value,
                      })
                    )}
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          </>
        );
      }}
    </ATMForm>
  );
};

export default KeyRequestEmployeeForm;
