import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SubstationCountListReducer, {
  ISubstationCountListState,
  defaultState,
  duckActions,
} from 'src/ducks/substation-count-list.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SubstationCountListReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISubstationCountListContext = ReturnType<typeof useReducer>;

const SubstationCountListContext = createContext<Partial<ISubstationCountListContext>>({
  state: defaultState,
}) as React.Context<ISubstationCountListContext>;

type IProps = {
  state?: Partial<ISubstationCountListState>;
};

const SubstationCountListProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SubstationCountListContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SubstationCountListContext.Provider>
  );
};

const useSubstationCountListContext = () => useContext(SubstationCountListContext);

export type IUseSubstationCountListContext = ReturnType<typeof useSubstationCountListContext>;

export { SubstationCountListContext, SubstationCountListProvider, useSubstationCountListContext };
