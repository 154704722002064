import React, { useEffect, useCallback, useLayoutEffect } from 'react';
import { useSettingContext } from 'src/contexts/setting.context';
import { getSettingStatus } from 'src/selectors/setting.selector';
import { settingActionTypes } from 'src/ducks/setting.duck';
import { SettingModule } from 'src/constants';
import AuthSettingsView from './auth-settings.view';

const AuthSettings: React.FC = () => {
  const { state, actions } = useSettingContext();
  const status = getSettingStatus(
    state,
    settingActionTypes.SETTING_MODULE_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listByModuleGET(SettingModule.AuthList);
  }, [actions]);

  useLayoutEffect(() => {
    actions.clearList();
    actions.listByModuleGET(SettingModule.AuthList);
  }, [actions]);

  useEffect(() => {
    handleFetch();
  }, [actions]);

  const handleDownload = () => {};
  return (
    <AuthSettingsView
      data={state.moduleList}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
    />
  );
};

export default AuthSettings;
