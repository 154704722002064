import { httpClient } from 'src/libraries/http.library';
// import { troubleJobsMock } from 'src/models/mocks/trouble-jobs.mock';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  ITroubleJobsForm,
  TroubleJobsListPayloadSchema,
  TroubleJobsDeletePayloadSchema,
  TroubleJobsPayloadSchema,
  IsoOutageTypeSchema,
} from 'src/models/trouble-jobs.model';
// import { Asserts } from 'yup';

const client = httpClient();
const endpoint = 'trouble-job';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, TroubleJobsPayloadSchema);
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, TroubleJobsListPayloadSchema);
  },

  createPOST: async (data: ITroubleJobsForm) => {
    return client.post(`/${endpoint}`, data, TroubleJobsPayloadSchema);
  },

  createStationAlarmPOST: async () => {
    return client.post(
      `/${endpoint}?action=stationAlarm`,
      {},
      TroubleJobsPayloadSchema
    );
  },

  updatePUT: async (id: number, data: ITroubleJobsForm) => {
    return client.put(`/${endpoint}/${id}`, data, TroubleJobsPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, TroubleJobsDeletePayloadSchema);
  },

  outageGET: async () => {
    return client.get(
      `/${endpoint}?action=outageType`,
      {},
      IsoOutageTypeSchema
    );
  },
};

export default services;
