import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import CalendarOutageReducer, {
  ICalendarOutageState,
  defaultState,
  duckActions,
} from 'src/ducks/calendar-outage.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    CalendarOutageReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ICalendarOutageContext = ReturnType<typeof useReducer>;

const CalendarOutageContext = createContext<Partial<ICalendarOutageContext>>({
  state: defaultState,
}) as React.Context<ICalendarOutageContext>;

type IProps = {
  state?: Partial<ICalendarOutageState>;
};

const CalendarOutageProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <CalendarOutageContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </CalendarOutageContext.Provider>
  );
};

const useCalendarOutageContext = () => useContext(CalendarOutageContext);

export type IUseCalendarOutageContext = ReturnType<
  typeof useCalendarOutageContext
>;

export {
  CalendarOutageContext,
  CalendarOutageProvider,
  useCalendarOutageContext,
};
