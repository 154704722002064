import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import { orderBy } from 'lodash';
import services from 'src/services/real-time-log.service';
import {
  IRealTimeLog,
  IRealTimeLogLine,
  IEmployeesRealTimeLog,
} from 'src/models/real-time-log.model';

export const realTimeLogActionTypes = {
  REAL_TIME_LOG_DATA_READ: 'REAL_TIME_LOG_DATA_READ',
  REAL_TIME_LOG_LIST_READ: 'REAL_TIME_LOG_LIST_READ',
  REAL_TIME_LOG_EMPLOYEE_LIST_READ: 'REAL_TIME_LOG_EMPLOYEE_LIST_READ',
  REAL_TIME_LOG_DATA_CREATE: 'REAL_TIME_LOG_DATA_CREATE',
  REAL_TIME_LOG_DATA_UPDATE: 'REAL_TIME_LOG_DATA_UPDATE',
  REAL_TIME_LOG_DATA_DELETE: 'REAL_TIME_LOG_DATA_DELETE',
  REAL_TIME_LOG_CLEAR_LIST: 'REAL_TIME_LOG_CLEAR_LIST',
  REAL_TIME_LOG_DATA_SET: 'REAL_TIME_LOG_DATA_SET',
  REAL_TIME_LOG_LINE_READ: 'REAL_TIME_LOG_LINE_READ',
  REAL_TIME_LOG_LINE_CREATE: 'REAL_TIME_LOG_LINE_CREATE',
  REAL_TIME_LOG_MAILER: 'REAL_TIME_LOG_MAILER',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue

  lineGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ,
    service: services.lineGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  lineCreateGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LINE_CREATE,
    service: services.lineGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LIST_READ,
    service: services.listGET,
  },

  employeeListGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ,
    service: services.employeeListGET,
  },

  createPOST: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_DELETE,
    service: services.dataDELETE,
  },

  sendEmail: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_MAILER,
    service: services.sendEmail,
  },

  clearList: () => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_LIST,
  }),

  // This is a sync action
  setData: (realTimeLog: IRealTimeLog) => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_SET,
    payload: realTimeLog,
  }),
};

export type IRealTimeLogAsync = typeof duckActions;

export interface IRealTimeLogState
  extends ICommonState<typeof realTimeLogActionTypes> {
  data?: IRealTimeLog;
  list: IRealTimeLog[];
  employeeList: IEmployeesRealTimeLog[];
  total: number;
  all: number;
  today: number;
  watchList: number;
  line: IRealTimeLogLine[];
  lineCreateList: IRealTimeLogLine[];
  mail: string;
}

export const defaultState: IRealTimeLogState = {
  status: {},
  list: [],
  employeeList: [],
  total: 0,
  all: 0,
  today: 0,
  watchList: 0,
  line: [],
  lineCreateList: [],
  mail: '',
};

const RealTimeLogReducer = (
  state: IRealTimeLogState,
  action: IReducerAction<IRealTimeLogAsync>
): IRealTimeLogState => {
  switch (action.type) {
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_SET:
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_READ:
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE:
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ: {
      return {
        ...state,
        line: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
        lineCreateList: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LINE_CREATE: {
      return {
        ...state,
        lineCreateList: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        all: action.payload?.count.all ?? 0,
        today: action.payload?.count.today ?? 0,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        employeeList: action.payload?.rows ?? [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.logId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_LIST: {
      return {
        ...state,
        list: [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_MAILER: {
      return {
        ...state,
        mail: action.payload?.message ?? '',
      };
    }

    default: {
      return state;
    }
  }
};

export default RealTimeLogReducer;
