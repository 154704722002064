/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  AuthListType,
  EmployeeStatus,
  SuspensionStatus,
  YesNo,
} from 'src/constants';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { IAuthorizedList } from 'src/models/authorized-list.model';
import {
  getAuthorizedListStatus,
  getNearestTrainingDate,
  getTrainingSet,
} from 'src/selectors/authorized-list.selector';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { getURLParams, getURLString } from 'src/helpers/location.helper';
import history from 'src/history';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import AuthorizedListListView from './authorized-members-list.view';
import { authorizedFields } from '../authorized-list-search/authorized-list-search.component';

const AuthorizedListList: React.FC = () => {
  const { state, actions } = useAuthorizedListContext();
  const [noData, setNoData] = useState<string>('Search to view records');
  const [showInactive, setShowInactive] = useState(false);
  const [cache, setCache] = useState<Record<string, any>>(
    getURLParams(history.location)
  );
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_LIST_READ
  );
  const formRef = useRef<HTMLFormElement>(null);
  const params = getURLParams(history.location);

  useEffect(() => {
    actions.clearList();
  }, [actions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (data: Partial<IORGDataTableQueryState>) => {
      setCache({ ...data });
      const path = getURLString(history.location, data);
      history.push(path);

      if (data.filters) {
        const filters = data.filters.reduce((items, item) => {
          if (item.name === 'name') {
            return [
              ...items,
              {
                ...item,
                name: Number.isNaN(Number(String(item.value).slice(-4)))
                  ? 'name'
                  : 'empId',
              },
            ];
          }

          return [...items, item];
        }, [] as any[]);

        // eslint-disable-next-line no-param-reassign
        data.filters = filters;
      }

      const result = await actions?.listGET(data);
      if (result.payload?.rows?.length === 0) {
        setNoData('No Records Found for the search criteria');
      }
    },
    [actions, setNoData, setCache]
  );

  useEffect(() => {
    if (
      params.filters &&
      params.filters
        .map((v) => v.name)
        .filter((v) => authorizedFields.includes(v)).length
    ) {
      handleSubmit(params);
    }
  }, [handleSubmit]);

  const handleDownload = useCallback(
    (data: IAuthorizedList[]) => {
      if (data) {
        const list = showInactive
          ? data
          : data.filter((val) => val.employeeStat === 'A');
        const items = list.map((value) => {
          const authTraining = getTrainingSet(
            value.trainings ?? [],
            AuthListType.Training
          );
          const fieldSwitching = getTrainingSet(
            value.trainings ?? [],
            AuthListType.FieldSwitching
          );
          const substationSwitching = getTrainingSet(
            value.trainings ?? [],
            AuthListType.SubSwitching
          );
          const substationEntry = getTrainingSet(
            value.trainings ?? [],
            AuthListType.SubEntry
          );
          const latestAuthTraining = authTraining.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(authTraining))
          )[0];
          const latestFieldSwitching = fieldSwitching.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(fieldSwitching))
          )[0];
          const latestSubSwitching = substationSwitching.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(substationSwitching))
          )[0];
          const latestSubEntry = substationEntry.filter(
            (item) =>
              item.trainingDate &&
              String(item.trainingDate) ===
                String(getNearestTrainingDate(substationEntry))
          )[0];
          return [
            checkValue(`${value.fullName}/${value.empId}`),
            checkValue(
              value.employeeStat === 'A' ? Lang.LBL_ACTIVE : Lang.LBL_INACTIVE
            ),
            checkValue(
              !latestAuthTraining
                ? YesNo.NO
                : Moment(latestAuthTraining.expiryDate, 'YYYY-MM-DD').isBefore(
                    Moment().utc()
                  )
                ? `${SuspensionStatus.EXPIRED} ${
                    latestAuthTraining.subcategory &&
                    latestAuthTraining.subcategory.description
                      ? `[${latestAuthTraining.subcategory.description}]`
                      : ''
                  }`
                : latestAuthTraining.suspendFlag
                ? `${SuspensionStatus.SUSPENDED} ${
                    latestAuthTraining.subcategory &&
                    latestAuthTraining.subcategory.description
                      ? `[${latestAuthTraining.subcategory.description}]`
                      : ''
                  }`
                : `(${Moment(
                    latestAuthTraining.trainingDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')}) ${
                    latestAuthTraining.subcategory &&
                    latestAuthTraining.subcategory.description
                      ? `[${latestAuthTraining.subcategory.description}]`
                      : ''
                  }`
            ),
            checkValue(
              !latestSubSwitching
                ? YesNo.NO
                : Moment(latestSubSwitching.expiryDate, 'YYYY-MM-DD').isBefore(
                    Moment().utc()
                  )
                ? `${SuspensionStatus.EXPIRED} (${Moment(
                    latestSubSwitching.expiryDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')}) ${
                    latestSubSwitching.subcategory &&
                    latestSubSwitching.subcategory.description
                      ? `[${latestSubSwitching.subcategory.description}]`
                      : ''
                  }`
                : latestSubSwitching.suspendFlag
                ? `${SuspensionStatus.SUSPENDED} ${
                    latestSubSwitching.subcategory &&
                    latestSubSwitching.subcategory.description
                      ? `[${latestSubSwitching.subcategory.description}]`
                      : ''
                  }`
                : `(${Moment(
                    latestAuthTraining.trainingDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')})`
            ),
            checkValue(
              !latestSubEntry
                ? YesNo.NO
                : Moment(latestSubEntry.expiryDate, 'YYYY-MM-DD').isBefore(
                    Moment().utc()
                  )
                ? `${SuspensionStatus.EXPIRED} (${Moment(
                    latestSubEntry.expiryDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')}) ${
                    latestSubEntry.subcategory &&
                    latestSubEntry.subcategory.description
                      ? `[${latestSubEntry.subcategory.description}]`
                      : ''
                  }`
                : latestSubEntry.suspendFlag
                ? `${SuspensionStatus.SUSPENDED} ${
                    latestSubEntry.subcategory &&
                    latestSubEntry.subcategory.description
                      ? `[${latestSubEntry.subcategory.description}]`
                      : ''
                  }`
                : `${EmployeeStatus.ACTIVE} ${
                    latestSubEntry.subcategory &&
                    latestSubEntry.subcategory.description
                      ? `[${latestSubEntry.subcategory.description}]`
                      : ''
                  }`
            ),
            checkValue(
              !latestFieldSwitching
                ? YesNo.NO
                : Moment(
                    latestFieldSwitching.expiryDate,
                    'YYYY-MM-DD'
                  ).isBefore(Moment().utc())
                ? `${SuspensionStatus.EXPIRED} (${Moment(
                    latestFieldSwitching.expiryDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')}) ${
                    latestFieldSwitching.subcategory &&
                    latestFieldSwitching.subcategory.description
                      ? `[${latestFieldSwitching.subcategory.description}]`
                      : ''
                  }`
                : latestFieldSwitching.suspendFlag
                ? `${SuspensionStatus.SUSPENDED} ${
                    latestFieldSwitching.subcategory &&
                    latestFieldSwitching.subcategory.description
                      ? `[${latestFieldSwitching.subcategory.description}]`
                      : ''
                  }`
                : `(${Moment(
                    latestAuthTraining.trainingDate,
                    'YYYY-MM-DD'
                  ).format('MM/DD/YYYY')})`
            ),
          ];
        });
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_GROUP_EMP_NAME,
            Lang.LBL_EMPLOYEE_STATUS,
            Lang.LBL_AUTHORIZATION_TRAINING,
            Lang.LBL_SUBSTATION_SWITCHING,
            Lang.LBL_SUBSTATION_ENTRY,
            Lang.LBL_FIELD_LINE_SWITCHING,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_AuthorizedMembers_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state, showInactive]
  );

  return (
    <AuthorizedListListView
      data={state.list}
      noData={noData}
      filterValues={{
        current: cache,
      }}
      showInactive={showInactive}
      setShowInactive={setShowInactive}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      handleDownload={handleDownload}
    />
  );
};

export default AuthorizedListList;
