import React from 'react';
import { ATMIcon } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './empty.module.scss';

type IProps = {
  content?: React.ReactNode;
};

const Empty: React.FC<IProps> = ({ content }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.noYards}>
        <ATMIcon
          circular
          color="grey"
          inverted
          name="info"
          size="small"
          style={{
            display: 'inline-block',
            marginBottom: '0.8em',
            marginTop: '3em',
            verticalAlign: 'center',
          }}
        />
        <p>{Lang.NTE_NO_DATA}</p>
        {content && <div>{content}</div>}
      </div>
    </div>
  );
};

export default Empty;
