import yup from 'src/libraries/validator.library';

export const SubstationShape = {
  substationId: yup.string().required(),
  name: yup.string().required(),
  addr1: yup.string().nullable(),
  addr2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zipCd: yup.string().nullable(),
  gps: yup.string().nullable(),
  areaCd: yup.string().nullable(),
  phnNbr: yup.string().nullable(),
  modemFlg: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  subDistId: yup.string().nullable(),
  tbros: yup.string().nullable(),
  custCnt: yup.number().nullable(),
  loadMw: yup.number().nullable(),
};

export const SubstationSchema = yup.object(SubstationShape);

export const SubstationFormSchema = yup.object({
  substationId: yup.string().required().max(4),
  name: yup.string().required().max(40),
  addr1: yup.string().nullable().max(70),
  addr2: yup.string().nullable().max(25),
  city: yup.string().nullable().max(18),
  state: yup.string().nullable().max(2),
  zipCd: yup.string().nullable().max(5),
  gps: yup.string().nullable().max(40),
  areaCd: yup.string().nullable().max(3),
  phnNbr: yup.string().nullable().max(8),
  modemFlg: yup.string().nullable().max(1),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  subDistId: yup.string().nullable().max(15),
  tbros: yup.string().nullable().max(15),
  custCnt: yup.number().nullable(),
  loadMw: yup.number().nullable(),
});

export const SubstationListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SubstationSchema.clone()),
});

export const SubstationPayloadSchema = SubstationSchema.defined();

export const SubstationDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISubstation = yup.Asserts<typeof SubstationSchema>;
export type ISubstationForm = yup.Asserts<typeof SubstationFormSchema>;
