import yup from 'src/libraries/validator.library';

export const SuspensionListShape = {
  empId: yup.string().required(),
  authTypId: yup.string(),
  authListId: yup.number(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const SuspensionListSchema = yup.object(SuspensionListShape);

export const SuspensionListFormSchema = yup.object({
  empId: yup.string().required(),
  authTypId: yup.string(),
  authListId: yup.number(),
});

export const SuspensionListListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SuspensionListSchema.clone()),
});

export const SuspensionListPayloadSchema = SuspensionListSchema.defined();

export const SuspensionListDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISuspensionList = yup.Asserts<typeof SuspensionListSchema>;
export type ISuspensionListForm = yup.Asserts<typeof SuspensionListFormSchema>;
