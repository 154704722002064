import React, { useCallback, useRef, useState } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import Lang from 'src/libraries/language';
import { IShortDescriptionCategoryForm } from 'src/models/short-description.model';
import { getShortDescriptionStatus } from 'src/selectors/short-description.selector';
import CategoryAddView from './category-add.view';

const CategoryAdd: React.FC = () => {
  const { state, actions } = useShortDescriptionContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IShortDescriptionCategoryForm) => {
      const response = await actions.createCategoryAndDescsPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listCategoriesAndDescGET();
        setIsAddOpen(false);

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_SHORT_DESCRIPTION_CATEGORY_ADD_SUCCESS,
            formData.shortDescCat
          )
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <CategoryAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default CategoryAdd;
