import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIssuedKeyContext } from 'src/contexts/issued-key.context';
import { issuedKeyActionTypes } from 'src/ducks/issued-key.duck';
import { IIssuedKeyForm } from 'src/models/issued-key.model';
import { getIssuedKeyStatus } from 'src/selectors/issued-key.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { getEmployeeStatus } from 'src/selectors/employee.selector';
import { employeeActionTypes } from 'src/ducks/employee.duck';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import IssuedKeyEditView from './issued-key-edit.view';

type IProp = {
  data: number;
  empId: string;
  isKeyRequest: boolean;
};

const IssuedKeyEdit: React.FC<IProp> = ({ data, empId, isKeyRequest }) => {
  const { state, actions } = useIssuedKeyContext();
  const { actions: keyRequestActions } = useKeyRequestContext();
  const { state: employeeState, actions: employeeActions } =
    useEmployeeContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const statusKeyDetails = getIssuedKeyStatus(
    state,
    issuedKeyActionTypes.ISSUED_KEY_DATA_READ
  );

  const statusEmployee = getEmployeeStatus(
    employeeState,
    employeeActionTypes.EMPLOYEE_SEARCH_FETCH
  );

  const status = getIssuedKeyStatus(
    state,
    issuedKeyActionTypes.ISSUED_KEY_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IIssuedKeyForm) => {
      const response = await actions.updatePUT(data, {
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 50, page: 1 });
        keyRequestActions.listByEmpIdGET({ limit: 50, page: 1 });
        setIsEditOpen(false);

        ToastSuccess(
          `Key ${convertKeyNumberToFiveDigits(data)} has been dispositioned`
        );
      } else {
        ToastError('Error!');
      }
    },
    [data, actions, setIsEditOpen]
  );

  useEffect(() => {
    if (isEditOpen) {
      actions?.dataGET(data);
      employeeActions?.searchGET(empId);
    }
  }, [isEditOpen]);

  return (
    <IssuedKeyEditView
      formRef={formRef}
      defaultValues={state.data ?? {}}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      statusEmployee={statusEmployee.fetching}
      statusKeyDetails={statusKeyDetails.fetching}
      isKeyRequest={isKeyRequest}
    />
  );
};

export default IssuedKeyEdit;
