import yup from 'src/libraries/validator.library';

export const RejectedOverviewReportShape = {
  rejectId: yup.number().nullable(),
  requestId: yup.number().nullable(),
  rejectionReason: yup.string().nullable(),
  rejectorId: yup.string().nullable(),
  rejectionDate: yup.string().nullable(),
  outage: yup.object({
    requestId: yup.number().nullable(),
    requestorId: yup.string().nullable(),
    supvId: yup.string().nullable(),
    requestor: yup
      .object({
        fullName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        firstName: yup.string().nullable(),
        middleName: yup.string().nullable(),
        empId: yup.string().nullable(),
      })
      .nullable(),
    supervisor: yup
      .object({
        fullName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        firstName: yup.string().nullable(),
        middleName: yup.string().nullable(),
        empId: yup.string().nullable(),
      })
      .nullable(),
  }),
  rejector: yup
    .object({
      fullName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
      empId: yup.string().nullable(),
    })
    .nullable(),
};

export const RejectedSummaryReportSchema = yup.lazy((items: any) =>
  yup.object(
    Object.keys(items).reduce(
      (newMap, key) => ({
        ...newMap,
        [key]: yup.object({
          submitted: yup.number().nullable(),
          moreThanOneRej: yup.number().nullable(),
          rejections: yup.number().nullable(),
          lers: yup.array().of(
            yup
              .object({
                requestId: yup.number().nullable(),
                rejectionReason: yup.string().nullable(),
                rejectionDate: yup.string().nullable(),
                outage: yup
                  .object({
                    requestId: yup.number().nullable(),
                    outageFacility: yup
                      .object({
                        outgFacId: yup.number().nullable(),
                        facility: yup
                          .object({
                            outgFacNm: yup.string().nullable(),
                          })
                          .nullable(),
                      })
                      .nullable(),
                  })
                  .nullable(),
              })
              .nullable()
          ),
        }),
      }),
      {}
    )
  )
);

export const RejectedSummaryReportDataShape = {
  date: yup.string(),
  submitted: yup.number(),
  moreThanOneRej: yup.number(),
  rejections: yup.number(),
  lers: yup.array().of(
    yup
      .object({
        requestId: yup.number().nullable(),
        rejectionReason: yup.string().nullable(),
        rejectionDate: yup.string().nullable(),
        outage: yup
          .object({
            requestId: yup.number().nullable(),
            outageFacility: yup
              .object({
                outgFacId: yup.number().nullable(),
                facility: yup
                  .object({
                    outgFacNm: yup.string().nullable(),
                  })
                  .nullable(),
              })
              .nullable(),
          })
          .nullable(),
      })
      .nullable()
  ),
};

export const RejectedOverviewReportSchema = yup.object(
  RejectedOverviewReportShape
);

export const RejectedOverviewReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RejectedOverviewReportSchema.clone()),
});
// export const RejectedSummaryReportListPayloadSchema = yup.mixed();
export const RejectedSummaryReportListPayloadSchema =
  RejectedSummaryReportSchema;

export const RejectedSummaryReportDataSchema = yup.object(
  RejectedSummaryReportDataShape
);

export type IRejectedOverviewReport = yup.Asserts<
  typeof RejectedOverviewReportSchema
>;
export type IRejectedSummaryReport = yup.Asserts<
  typeof RejectedSummaryReportSchema
>;
export type IRejectedSummaryReportDataSchema = yup.Asserts<
  typeof RejectedSummaryReportDataSchema
>;
