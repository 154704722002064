import yup from 'src/libraries/validator.library';

export const TrainersShape = {
  empId: yup.string(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  trainer: yup.object({
    email: yup.string().email().nullable(),
    empId: yup.string().nullable(),
    firstName: yup.string().nullable(),
    fullName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    middleName: yup.string().nullable(),
  }),
  trainerStat: yup.string(),
};

export const TrainersSchema = yup.object(TrainersShape);

export const TrainersFormSchema = yup.object({
  empId: yup.string(),
  updatedBy: yup.string().nullable(),
  updatedAt: yup.date(),
  createdBy: yup.string().nullable(),
  trainerStat: yup.string(),
});

export const TrainersListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(TrainersSchema.clone()),
});

export const TrainersPayloadSchema = yup.object({
  trainers: TrainersSchema.clone(),
});

export const TrainersDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ITrainers = yup.Asserts<typeof TrainersSchema>;
export type ITrainersForm = yup.Asserts<typeof TrainersFormSchema>;
