import React, { useCallback } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMInput,
  ATMRadio,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import Lang from 'src/libraries/language';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import { ILerRequestForm } from 'src/models/ler-request.model';
import styles from './ler-request-iso.module.scss';

const ISOSwitchTable: React.FC = () => {
  const { control, watch } = useATMFormContext<ILerRequestForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'isoTrs.switchings',
  });

  const handleAddSwitch = useCallback(
    (switchId?: string) => {
      if (switchId) {
        append({
          switchId,
          defaultPosInd: true,
          updatedAt: undefined,
          requestId: undefined,
          updatedBy: undefined,
          version_no: undefined,
        });
      } else {
        append({
          switchId: '',
          defaultPosInd: true,
          updatedAt: undefined,
          requestId: undefined,
          updatedBy: undefined,
          version_no: undefined,
        });
      }
    },
    [append]
  );

  return (
    <>
      {fields.length > 0 ? (
        <ATMTable stackable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell width={4}>
                {Lang.LBL_SWITCH_ID}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell textAlign="left" width={2}>
                {Lang.LBL_POSITION}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell textAlign="right">
                {Lang.LBL_ACTION}
              </ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((item, i) => (
              <ATMTable.Row key={item.id}>
                <ATMTable.Cell width="5">
                  <ATMField
                    as={ATMInput}
                    name={`isoTrs.switchings[${i}].switchId`}
                    control={control}
                    placeholder="Enter"
                    defaultValue={item.switchId}
                    onChange={([_, { value }]) => {
                      return value;
                    }}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell verticalAlign="middle">
                  <div className={styles.notificationToggle}>
                    <div className={styles.radioButton}>
                      <ATMField
                        name={`isoTrs.switchings[${i}].defaultPosInd`}
                        as={ATMRadio}
                        defaultChecked={item.defaultPosInd}
                        toggle
                        width={6}
                        control={control}
                        style={{ marginTop: '1em' }}
                        onChange={([_, { checked }]) => checked}
                        defaultValue={item.defaultPosInd}
                      />
                      <div
                        className={styles.labelCheck}
                        style={{ marginTop: '1em' }}
                      >
                        {watch(`isoTrs.switchings[${i}].defaultPosInd` as any)
                          ? 'Open'
                          : 'Close'}
                      </div>
                    </div>
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell textAlign="right">
                  <ATMButton
                    icon="trash alternate outline red"
                    type="button"
                    color="red"
                    onClick={() => remove(i)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
          <ATMTable.Footer>
            <ATMTable.Cell colSpan={3}>
              <ATMButton
                secondary
                className="borderless"
                icon="plus"
                content="Add More"
                onClick={() => handleAddSwitch()}
                type="button"
              />
            </ATMTable.Cell>
          </ATMTable.Footer>
        </ATMTable>
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              icon="plus"
              secondary
              content="Add"
              onClick={() => handleAddSwitch()}
              type="button"
            />
          }
        />
      )}
    </>
  );
};

export default ISOSwitchTable;
