import React, { useCallback, useEffect } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMInput,
  ATMRadio,
  ATMDropdown,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { IEmsFac } from 'src/models/facility.model';
import styles from './ler-request-iso.module.scss';

const ISOEMSATable: React.FC = () => {
  const { control, watch } = useATMFormContext<ILerRequestForm>();

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();

  useEffect(() => {
    equipmentTypeActions.listAllGET({
      limit: 0,
      page: 1,
    });
  }, [equipmentTypeActions]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'isoTrs.emsDatas',
  });

  const handleAddEms = useCallback(
    (data?: IEmsFac) => {
      if (data) {
        append({
          emsId: data.emsFacId,
          top: data.top,
          station: data.station,
          busName: data.busNm,
          equipmentName: data.equipmentNm,
          equipmentType: data.equipmentTyp,
          position: data.defaultFlag,
          requestId: undefined,
          updatedAt: undefined,
          updatedBy: undefined,
          version_no: undefined,
          facTyp: null,
        });
      } else {
        append({
          emsId: '',
          top: '',
          station: '',
          busName: '',
          equipmentName: '',
          equipmentType: undefined,
          position: true,
          requestId: undefined,
          updatedAt: undefined,
          updatedBy: undefined,
          version_no: undefined,
          facTyp: null,
        });
      }
    },
    [append]
  );

  return (
    <>
      {fields.length > 0 ? (
        <span>
          <ATMTable stackable className={styles.horizotalScroll}>
            <colgroup>
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="5%" />
              <col width="5%" />
            </colgroup>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell>{Lang.LBL_EMS_ID}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_TOP}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_STATION}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_BUS_NAME}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_EQUIPMENT_NAME}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_EQUIPMENT_TYPE}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_POSITION}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell />
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {fields.map((item, i) => (
                <ATMTable.Row key={item.id}>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMInput}
                      name={`isoTrs.emsDatas[${i}].emsId`}
                      control={control}
                      placeholder="Enter"
                      defaultValue={item.emsId}
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMInput}
                      name={`isoTrs.emsDatas[${i}].top`}
                      control={control}
                      placeholder="Enter"
                      defaultValue={item.top}
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMInput}
                      name={`isoTrs.emsDatas[${i}].station`}
                      control={control}
                      placeholder="Enter"
                      defaultValue={item.station}
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMInput}
                      name={`isoTrs.emsDatas[${i}].busName`}
                      control={control}
                      placeholder="Enter"
                      defaultValue={item.busName}
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMInput}
                      name={`isoTrs.emsDatas[${i}].equipmentName`}
                      control={control}
                      placeholder="Enter"
                      defaultValue={item.equipmentName}
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMField
                      as={ATMDropdown}
                      name={`isoTrs.emsDatas[${i}].equipmentType`}
                      control={control}
                      selection
                      clearable
                      selectOnBlur={false}
                      options={equipmentTypeState.allList
                        // .filter(
                        //   (val) =>
                        //     val.facTypNm === 'Switch' ||
                        //     val.facTypNm === 'Circuit Breaker'
                        // )
                        .map((value) => ({
                          key: value.facTypId,
                          value: value.facTypId,
                          text: value.facTypNm,
                        }))}
                      onChange={([_, { value }]) => value}
                      placeholder="Select"
                      defaultValue={item.equipmentType}
                      search
                      fluid
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <div className={styles.notificationToggle}>
                      <div className={styles.radioButton}>
                        <ATMField
                          name={`isoTrs.emsDatas[${i}].position`}
                          as={ATMRadio}
                          defaultChecked={item.position}
                          toggle
                          width={6}
                          control={control}
                          onChange={([_, { checked }]) => checked}
                        />
                        <div className={styles.labelCheck}>
                          {watch(`isoTrs.emsDatas[${i}].position` as any)
                            ? 'Open'
                            : 'Close'}
                        </div>
                      </div>
                    </div>
                  </ATMTable.Cell>
                  <ATMTable.Cell textAlign="right">
                    <ATMButton
                      icon="trash alternate outline red"
                      type="button"
                      onClick={() => remove(i)}
                    />
                  </ATMTable.Cell>
                </ATMTable.Row>
              ))}
            </ATMTable.Body>
            <ATMTable.Footer>
              <ATMTable.Cell colSpan={3}>
                <ATMButton
                  secondary
                  className="borderless"
                  icon="plus"
                  onClick={() => handleAddEms()}
                  content="Add More"
                  type="button"
                />
              </ATMTable.Cell>
            </ATMTable.Footer>
          </ATMTable>
        </span>
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              icon="plus"
              secondary
              content="Add"
              onClick={() => handleAddEms()}
              type="button"
            />
          }
        />
      )}
    </>
  );
};

export default ISOEMSATable;
