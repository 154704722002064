import React, { useEffect, useState } from 'react';
import { ATMButton, ATMTable } from 'shared-it-appmod-ui';
import { LERRequestFormStep } from 'src/constants';
import { useFileContext } from 'src/contexts/file.context';
import Lang from 'src/libraries/language';

export type IProps = {
  tab: string;
};

const LerRequestAddDocumentsDetail: React.FC<IProps> = ({ tab }) => {
  const { actions, state } = useFileContext();
  const [files, setFiles] = useState<any[]>([]);
  useEffect(() => {
    setFiles(state.filename_list ?? []);
  }, [state.stash]);
  const jobInfoTable = (
    <>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {files.length > 0 ? (
            files.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMButton
                    type="button"
                    icon="close"
                    onClick={() => actions.removeFileFromStash(item)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );
  const reviewTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {files?.length ? (
          files?.map((item, i) => (
            <ATMTable.Row key={i}>
              <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell>
            </ATMTable.Row>
          ))
        ) : (
          <ATMTable.Row textAlign="center">
            <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
          </ATMTable.Row>
        )}
      </ATMTable.Body>
    </ATMTable>
  );
  const getDocumentDetails = () => {
    if (tab === LERRequestFormStep.REVIEW) {
      return reviewTable;
    }
    return files.length ? jobInfoTable : '';
  };
  return <>{getDocumentDetails()}</>;
};

export default LerRequestAddDocumentsDetail;
