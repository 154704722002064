export enum FacilityStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  FUTURE = 'Future',
}

export enum FacilityType {
  Line = 1,
  Station = 2,
}

export const facilityStatus = FacilityStatus;
export const facilityType = FacilityType;
