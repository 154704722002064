import React, { useCallback } from 'react';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import useLocationTab from 'src/hooks/location-tab.hook';
import {
  getKeyRequestStatus,
  getKeyRequestParams,
} from 'src/selectors/key-request.selector';
import MyKeyRequestView from './key-request-requestor-view.view';

const MyKeyRequest: React.FC = () => {
  const { state, actions } = useKeyRequestContext();
  const { currentTab } = useLocationTab('stat');
  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_LIST_BY_EMP_ID_READ
  );

  const handleFetch = useCallback(
    (params) => {
      const data = getKeyRequestParams(params, currentTab);
      actions?.listByEmpIdGET(data);
    },
    [actions, currentTab]
  );

  return (
    <MyKeyRequestView
      data={state.listByEmpId}
      total={state.totalListByEmpId}
      loading={status.fetching}
      handleFetch={handleFetch}
      approvedTotal={state.approvedListByEmpId}
      pendingTotal={state.pendingListByEmpId}
      rejectedTotal={state.rejectedListByEmpId}
      currentTab={currentTab}
    />
  );
};

export default MyKeyRequest;
