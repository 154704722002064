import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMLabel,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import history from 'src/history';
import Lang from 'src/libraries/language';
import { IShortDescriptionCategoryList } from 'src/models/short-description.model';
import CategoryEdit from '../category-edit/category-edit.component';
// import CategoryDelete from '../category-delete/category-delete.component';
import styles from './category-list.module.scss';

const columns: IORGDataTableColumn<IShortDescriptionCategoryList>[] = [
  {
    index: 'catId',
    title: Lang.LBL_CATEGORY_NAME,
    render: (_, value) => value.shortDescCat,
    width: '20%',
  },
  {
    index: 'shortDescCat',
    title: Lang.LBL_DESCRIPTIONS,
    render: (_, value) =>
      value.descriptions?.map((val, i) => {
        return (
          <ATMLabel className={styles.bgLabelDesc} key={i} basic>
            {val.shortDesc}
          </ATMLabel>
        );
      }),
  },
  {
    index: 'action',
    title: 'Action',
    width: '120px',
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <ATMDropdown size="small" icon="ellipsis horizontal">
          <ATMDropdown.Menu>
            <CategoryEdit data={value} />
          </ATMDropdown.Menu>
        </ATMDropdown>
      </>
    ),
  },
];

type IProps = {
  data: IShortDescriptionCategoryList[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
};

const CategoryListView: React.FC<IProps> = ({ data, loading, handleFetch }) => (
  <div className="admin-content">
    <ORGDataTable
      columns={columns}
      data={data}
      onChange={handleFetch}
      loading={loading}
      showPagination={false}
      celled={false}
      location={history.location}
      handleLocation={history.push}
    />
  </div>
);

export default CategoryListView;
