import { rolesActionTypes, IRolesState } from 'src/ducks/roles.duck';
import { IStatus } from 'src/libraries/thunk.library';
import Lang from 'src/libraries/language';
import { IEmployeeRoleFilter, IRoleEmployeeItem } from 'src/models/roles.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { RoleEmpRoleStatus } from 'src/constants';

export const getRolesStatus = (
  state: IRolesState,
  action: keyof typeof rolesActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const groupBy = (key) => (array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {}
  );

export const joinHypenAreaCdTelephone = (areaCd, telephone) => {
  if (!areaCd && telephone) {
    return telephone.replace(/^(\d{3})(\d{4})/, '$1-$2');
  }
  if (areaCd && telephone) {
    let phoneNum = telephone;
    if (phoneNum.indexOf('-') === -1) {
      phoneNum = phoneNum.replace(/^(\d{3})(\d{4})/, '$1-$2');
      return areaCd.concat(phoneNum).replace(/^(\d{3})(\d{3})/, '$1-$2');
    }
    const arCdPhone = areaCd.concat(phoneNum);
    return arCdPhone.replace(/^(\d{3})(\d{3})/, '$1-$2');
  }
  return '–';
};

export const getRolesListForm = (data: IRoleEmployeeItem[]): string => {
  return `<div id="element-to-print">
          <div style="border:none;font-family: Calibri; font-size: 1.3rem; font-weight: bold; text-align: center; margin-bottom: 1rem; margin-top: 1rem; ">
            <span>${Lang.LBL_ROLES}</span>
          </div>
          <table style="font-size: 14px;
          border-collapse: separate;
          font-size: 12px;
          line-height: 24px;
          margin: 30px auto;
          text-align: left;
          width: 600px;">
            <tbody>
                <tr style="border: 1px solid #000; font-weight: 800;">
                  <tr style="font-weight: 800;">
                    <td style="border: 1px solid #000;">${Lang.LBL_NAME_ID}</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ROLE_EMAIL
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ROLE_HR_STATUS
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ROLE_STATUS
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ROLE_TITLE
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ROLES_TITLES
                    }</td>
                  </tr>
                  ${
                    data &&
                    data
                      .map((item) => {
                        if (item.lastName && item.firstName) {
                          return `<tr>
                          <td style="border: 1px solid #000;">
                            <span>${item.lastName}, ${item.firstName} / ${
                            item.empId
                          }</span>
                          </td>
                          <td style="border: 1px solid #000;">
                          <span> ${item.email} </span>
                          </td>
                          <td style="border: 1px solid #000;">
                          <span> ${
                            item.employeeStat === 'A' ? 'Active' : 'Inactive'
                          } </span>
                        </td>
                        <td style="border: 1px solid #000;">
                        <span> ${
                          item.role.roleStatus ? 'Active' : 'Inactive'
                        } </span>
                      </td>
                      <td style="border: 1px solid #000;">
                      <span> ${item.title} </span>
                    </td>
                    <td style="border: 1px solid #000;">
                    <span> ${(item.roles || [])
                      .map((element) => {
                        return element.roleName;
                      })
                      .join()} </span>
                  </td>
                        
                        </tr>
                          `;
                        }
                        return `<tr>
                          <td style="border: 1px solid #000;">
                            <span>${item.empId}</span>
                          </td>
                          <td style="border: 1px solid #000;">
                          <span> ${item.email} </span>
                          </td>
                          <td style="border: 1px solid #000;">
                          <span> ${
                            item.employeeStat === 'A' ? 'Active' : 'Inactive'
                          } </span>
                        </td>
                        <td style="border: 1px solid #000;">
                        <span> ${
                          item.role.roleStatus ? 'Active' : 'Inactive'
                        } </span>
                      </td>
                      <td style="border: 1px solid #000;">
                      <span> ${item.title} </span>
                    </td>
                    <td style="border: 1px solid #000;">
                    <span> ${(item.roles || [])
                      .map((element) => {
                        return element.roleName;
                      })
                      .join()} </span>
                  </td>
                        
                        </tr>
                          `;
                      })
                      .join('')
                  }
          </table>
          </div>`;
};

export const getRolesParams = (
  params: IORGDataTableQueryState,
  data?: Partial<IEmployeeRoleFilter>
): Partial<IORGDataTableQueryState> => {
  const parameters = data
    ? {
        ...params,
        filters: Object.entries(data)
          .filter(([_, value]) => value)
          .map(([key, value]) => {
            if (key === 'empId') {
              return {
                // eslint-disable-next-line no-restricted-globals
                name:
                  key === 'empId' &&
                  Number.isNaN(Number(String(value).slice(-4)))
                    ? 'name'
                    : 'empId',
                value,
              };
            }
            if (key === 'roleId') {
              return {
                name: 'roleId',
                value: Number(value),
              };
            }

            if (key === 'roleStatus') {
              return {
                name: 'roleStatus',
                value: value === RoleEmpRoleStatus.ACTIVE,
              };
            }
            return {
              name: key,
              value,
            };
          }),
      }
    : { ...params };

  return parameters;
};
