import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SettingReducer, {
  ISettingState,
  defaultState,
  duckActions,
} from 'src/ducks/setting.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SettingReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISettingContext = ReturnType<typeof useReducer>;

const SettingContext = createContext<Partial<ISettingContext>>({
  state: defaultState,
}) as React.Context<ISettingContext>;

type IProps = {
  state?: Partial<ISettingState>;
};

const SettingProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SettingContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};

const useSettingContext = () => useContext(SettingContext);

export type IUseSettingContext = ReturnType<typeof useSettingContext>;

export { SettingContext, SettingProvider, useSettingContext };
