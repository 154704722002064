import React, { useEffect } from 'react';
import { ATMMessage } from 'shared-it-appmod-ui';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import Lang from 'src/libraries/language';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';

type IProps = {
  outgFacId?: number;
};

const SpecialConcern: React.FC<IProps> = ({ outgFacId }) => {
  const { state, actions } = useRealTimeLogContext();
  const { state: facilityState } = useFacilityContext();
  const { equipment } = facilityState;
  const { line } = state;

  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  useEffect(() => {
    if (!line.length && status.fetching === false && !status.error) {
      actions.lineGET();
    }
  }, [actions, line, status]);

  const facility = line.find((v) => v.outgFacId === outgFacId) ?? equipment.find((v) => v.outgFacId === outgFacId);

  if (!facility || !outgFacId || (facility && !facility?.spclOpsConcern)) {
    return null;
  }

  return (
    <ATMMessage color="yellow">
      <h5 style={{ marginBottom: '0.5em' }}>
        {Lang.LBL_SPECIAL_CONCERNS} for {facility?.outgFacNm}
      </h5>
      <span>{facility?.spclOpsConcern}</span>
    </ATMMessage>
  );
};

export default SpecialConcern;
