import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { IKeyRequest, IKeyRequestForm } from 'src/models/key-request.model';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { ATMGrid, ATMIcon } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import ConfirmationContainer from 'src/components/atoms/confirmation/confirmation.component';
import { getUser } from 'src/libraries/amplify.library';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import KeyRequestEditView from './key-request-edit.view';
import styles from '../key-request.module.scss';

type IProp = {
  data: IKeyRequest;
};

const KeyRequestEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useKeyRequestContext();
  const { state: employeeState, actions: employeeActions } =
    useEmployeeContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirm, setConfirm] = useState<IKeyRequest>();
  const formRef = useRef<HTMLFormElement>(null);

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_APPROVE
  );

  const dataGetStatus = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_READ
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IKeyRequestForm) => {
      const response = await actions.approvePUT(data.requestNbr, {
        ...formData,
        updatedBy: getUser()?.emp_no,
      });

      if (!response.error) {
        // actions.listGET();
        if (response.payload) {
          const insertedData = await actions.dataGET(
            response.payload?.requestNbr
          );
          setConfirm(insertedData.payload);
          setIsEditOpen(false);
          setIsConfirmOpen(true);
        }
      } else {
        ToastError('Error!');
      }
    },
    [data, actions, setIsEditOpen, setIsConfirmOpen]
  );

  useEffect(() => {
    if (isEditOpen) {
      employeeActions?.searchGET(data.empId.slice(2, data.empId.length));
    }
  }, [isEditOpen, employeeActions]);

  const printContent = `<table style="font-family: Calibri; margin: 0 auto;">
  <thead style="font-size: 36px;">
    <tr style="height: 100px">
      <th colspan="4">Confirmation - ${`Key Request for ${
        confirm && confirm.emp.lastName
      }, ${confirm && confirm.emp.lastName}/${confirm && confirm.empId}`}</th>
    </tr>
  </thead>
  <tbody style="font-size: 24px;">
    <tr>
      <td colspan="4" style="color: green;">&#10004; The following Key(s) has been successfully generated:</td>
    </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Key Holder</td>
        <td style="width:33%">Supervisor/Manager</td>
        <td style="width:33%">Key Key Number</td>
      </tr>
      <tr>
        <td style="width:33%">${confirm && confirm.emp.lastName}, ${
    confirm && confirm.emp.firstName
  }/${confirm && confirm.empId}</td>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.supvr?.lastName
        }, ${confirm && confirm.keyRequestDetail.supvr?.firstName}/${
    confirm && confirm.keyRequestDetail.supvrNbr
  }</td>
        <td style="width:33%">${
          confirm &&
          confirm.keyRequestDetail.keyNbr &&
          convertKeyNumberToFiveDigits(confirm.keyRequestDetail.keyNbr)
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Key Admin Location</td>
        <td style="width:33%">Key Type</td>
      </tr>
      <tr>
        <td style="width:33%">${employeeState.list?.[0]?.mailstop_id}</td>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.keyTypId
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td>Issuance Reason</td>
      </tr>
      <tr>
        <td colspan="4">${
          confirm &&
          confirm.keyRequestDetail.keyDetail &&
          confirm.keyRequestDetail.keyDetail[0].reason
        }</td>
      </tr>
  </tbody>
</table>`;

  const modalContent = (
    <>
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <p>
              <ATMIcon color="green" name="check circle" />{' '}
              <span className={styles.successMsg}>
                The following Key(s) has been successfully generated:
              </span>
            </p>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Holder">
                <p>
                  {confirm.emp.lastName}, {confirm.emp.firstName}/
                  {confirm.emp.empId}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Supervisor/Manager">
                <p>
                  {confirm.keyRequestDetail.supvr
                    ? `${confirm.keyRequestDetail.supvr.lastName}, ${confirm.keyRequestDetail.supvr.firstName}/${confirm.keyRequestDetail.supvrNbr}`
                    : '-'}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && confirm.keyRequestDetail.keyNbr && (
              <FieldReadOnly label="Key Number">
                <p>
                  {convertKeyNumberToFiveDigits(
                    confirm.keyRequestDetail.keyNbr
                  )}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Admin Location">
                <p>{employeeState.list?.[0]?.mailstop_id}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Type">
                <p>{confirm.keyRequestDetail.keyTypId}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column>
            {confirm && (
              <FieldReadOnly label="Issuance Reason">
                <p>
                  {confirm.keyRequestDetail.keyDetail &&
                    confirm.keyRequestDetail.keyDetail[0].reason}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <>
      {confirm && (
        <ConfirmationContainer
          content={modalContent}
          printContent={printContent}
          header={`Key Request for ${confirm.emp.lastName}, ${confirm.emp.firstName}/${confirm.emp.empId}`}
          isOpen={isConfirmOpen}
          loading={dataGetStatus.fetching}
          handleOpen={setIsConfirmOpen}
          url="/keys?stat=1"
        />
      )}
      <KeyRequestEditView
        formRef={formRef}
        defaultValues={data}
        loading={status.fetching || dataGetStatus.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        isOpen={isEditOpen}
        handleOpen={setIsEditOpen}
      />
    </>
  );
};

export default KeyRequestEdit;
