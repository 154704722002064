import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMButton,
  ATMCheckbox,
  ATMDatePicker,
  ATMPopover,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import history from 'src/history';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ISystemChange } from 'src/models/system-change.model';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { getSystemChangeStatus } from 'src/selectors/system-change.selector';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { LERRequestPage } from 'src/constants';
import style from './system-change-list.module.scss';

const defaultValue = [
  Moment().startOf('day').toDate(),
  Moment().endOf('day').toDate(),
];

const SystemChangeList: React.FC = () => {
  const { state, actions } = useSystemChangeContext();
  const [filter, setFilter] = useState<string>();
  const [initialize, setInitialize] = useState(false);

  const status = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ
  );
  const { getUrl } = useLocationParams([
    LERRequestPage.View,
    LERRequestPage.Edit,
  ]);

  const handleFetch = useCallback(
    async ({ filters }) => {
      const cache = JSON.stringify(filters);

      if (!filters) {
        await actions.setList([]);
        setFilter(undefined);

        if (!initialize) {
          setInitialize(true);
          await actions.listGET({
            filters: [
              {
                name: 'dateRange',
                value: defaultValue,
              },
            ],
          });
        }
      } else if (cache !== filter) {
        const dateRangeFilter = (filters as []).find(
          (item: any) => item.name === 'dateRange'
        );

        const finalFilters = !dateRangeFilter
          ? [...filters, { name: 'dateRange', value: defaultValue }]
          : [...filters];

        await actions.listGET({ filters: finalFilters });
        setFilter(cache);
      }
    },
    [actions, filter, initialize, setInitialize, setFilter]
  );

  const columns: IORGDataTableColumn<ISystemChange>[] = [
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (value) => (
        <Link to={getUrl({ [LERRequestPage.View]: value })}>{value}</Link>
      ),
    },
    {
      index: 'outageFacility.facility.outgFacNm',
      title: Lang.LBL_FACILITY,
      render: (value, values) => {
        return `${(value || '').trim()}${
          values.outageFacility?.substation
            ? ` (${values.outageFacility?.substation.name})`
            : ''
        }`;
      },
    },
    {
      index: 'wrkDesc',
      title: Lang.LBL_WORK_DESC,
      width: '600px',
      render: (value) => (
        <div className={style.inlineWrkDesc}>
          <p>{value}</p>
        </div>
      ),
    },
    {
      index: 'sysChngSummary.noteTxt',
      sortable: false,
      title: Lang.LBL_SYSTEM_CHANGE_SUMMARY,
      width: '100px',
      render: (_, value) => {
        if (
          value.sysChngSummary?.noteTxt &&
          value.sysChngSummary?.noteTxt.length > 70
        ) {
          return (
            <div className={style.inlineWrkDesc}>
              <p className={style.systemSummaryNote}>
                {value.sysChngSummary?.noteTxt}
              </p>
              <ATMPopover
                trigger={<a className={style.underlined}>{Lang.LBL_MORE}</a>}
                on="click"
                content={value.sysChngSummary?.noteTxt}
                inverted
              />
            </div>
          );
        }

        return <>{value.sysChngSummary?.noteTxt}</>;
      },
    },
    {
      index: 'date',
      title: state.startDateFlag
        ? Lang.LBL_START_DATE_SYSCHG
        : Lang.LBL_IN_SERVICE_DATE,
      render: (_, value) => {
        if (state.startDateFlag) {
          return (
            <>
              {formatDate(
                state.startDateFlag ? value.startDate : value.inServiceDate
              )}
            </>
          );
        }
        return (
          <a
            className={style.underlined}
            onClick={() =>
              history.push(
                `/outage-planning/system/${Moment(
                  state.startDateFlag ? value.startDate : value.inServiceDate
                ).format('YYYYMMDD')}`
              )
            }
            role="button"
          >
            {formatDate(
              state.startDateFlag ? value.startDate : value.inServiceDate
            )}
          </a>
        );
      },
    },
  ];

  const handleDownload = useCallback(() => {
    if (state.list) {
      const { list } = state;

      const items = list.map((value) => [
        checkValue(value.requestId ?? '--'),
        checkValue(
          `${(value.outageFacility?.facility?.outgFacNm || '').trim()}${
            value.outageFacility?.substation
              ? ` (${value.outageFacility?.substation.name})`
              : ''
          }`.trim() ?? '--'
        ),
        checkValue(value.wrkDesc ?? '--'),
        checkValue(value.sysChngSummary?.noteTxt ?? '--'),
        checkValue(
          formatDate(
            state.startDateFlag ? value.startDate : value.inServiceDate
          )
        ),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_REQUEST_ID,
          Lang.LBL_FACILITY,
          Lang.LBL_WORK_DESC,
          Lang.LBL_SYSTEM_CHANGE_SUMMARY,
          state.startDateFlag
            ? Lang.LBL_START_DATE_SYSCHG
            : Lang.LBL_IN_SERVICE_DATE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_System_Change_Report_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state]);

  return (
    <>
      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={state.list}
          sortable
          loading={status.fetching}
          onChange={handleFetch}
          location={history.location}
          handleLocation={history.push}
          counter
        >
          {() => ({
            toolbars: {
              startDateFlag: ({ value, setValue }) => {
                return (
                  <ATMCheckbox
                    toggle
                    name="startDateFlag"
                    label="Use Start Date"
                    placeholder="Use Start Date"
                    className={style.toggleCheckbox0Margin}
                    value={value ?? 'false'}
                    onChange={(__, { checked }) => {
                      setImmediate(() => {
                        setValue(checked);
                        actions.setStartDateFlag(checked ?? false);
                      });
                    }}
                  />
                );
              },
              dateRange: ({ value, setValue }) => {
                return (
                  <ATMDatePicker
                    type="range"
                    name="dateRange"
                    pointing="right"
                    placeholder={Lang.LBL_DATE_RANGE}
                    format="MM/DD/YYYY"
                    date={new Date()}
                    value={value ? value.map((v) => new Date(v)) : defaultValue}
                    onChange={(_, val) => {
                      if (val.value && (val.value as Date[]).length === 2) {
                        setValue([
                          Moment(val.value[0]).startOf('day').toDate(),
                          Moment(val.value[1]).endOf('day').toDate(),
                        ]);
                      } else if (!val.value) {
                        setValue(undefined);
                      }
                    }}
                  />
                );
              },
              download: () => (
                <ATMButton
                  key="download"
                  secondary
                  icon="download"
                  size="tiny"
                  onClick={handleDownload}
                  loading={status.fetching}
                  disabled={status.fetching || state.list.length === 0}
                />
              ),
            },
          })}
        </ORGDataTable>
      </div>
    </>
  );
};

export default SystemChangeList;
