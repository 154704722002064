import React, { useCallback, useRef, useState } from 'react';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { workGroupActionTypes } from 'src/ducks/work-group.duck';
import { IWorkGroup, IWorkGroupForm } from 'src/models/work-group.model';
import { getWorkGroupStatus } from 'src/selectors/work-group.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import WorkGroupEditView from './work-group-edit.view';

type IProp = {
  data: IWorkGroup;
};

const WorkGroupEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useWorkGroupContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getWorkGroupStatus(
    state,
    workGroupActionTypes.WORK_GROUP_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IWorkGroupForm) => {
      const response = await actions.updatePUT(data.trbGrpId, {
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsEditOpen(false);
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_WORK_GROUP_EDIT,
            response.payload?.trbGrpNm ?? ''
          )
        );
      } else {
        ToastError('Error!');
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <WorkGroupEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default WorkGroupEdit;
