import {
  shortDescriptionActionTypes,
  IShortDescriptionState,
} from 'src/ducks/short-description.duck';
import { IStatus } from 'src/libraries/thunk.library';
import { IShortDescription } from 'src/models/short-description.model';

export const getShortDescriptionStatus = (
  state: IShortDescriptionState,
  action: keyof typeof shortDescriptionActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getShortDescriptionList = (
  id: number,
  data: IShortDescription[]
) => data.filter((value) => value.catId === id);
