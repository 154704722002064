import { AxiosRequestConfig } from 'axios';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  OmsRequestPayloadSchema,
  OmsRequestDataSchema,
  OmsRequestListPayloadSchema,
  OmsRequestDeletePayloadSchema,
  IOmsRequestSubmitRefresh,
  OmsRequestSubmitRefreshResponsePayloadSchema,
  OmsOutageWarningPayloadSchema,
  IOmsRequestUpdatePayload,
} from 'src/models/oms-request.model';

const client = httpClient();
const endpoint = 'transmission-outage';
const outageWarningEndpoint = 'outage-warning';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, OmsRequestDataSchema);
  },

  listGET: async (
    data?: Partial<IORGDataTableQueryState>,
    config: AxiosRequestConfig = {}
  ) => {
    return client.get(
      `/${endpoint}`,
      data,
      OmsRequestListPayloadSchema,
      config
    );
  },

  sendPOST: async (data: IOmsRequestSubmitRefresh, onFailure?: boolean) => {
    return client.post(
      `/${endpoint}?action=bulk-submit-and-cancel&throwOnFailure=${
        onFailure ? 'true' : 'false'
      }`,
      data,
      OmsRequestSubmitRefreshResponsePayloadSchema
    );
  },

  updatePUT: async (id: number, data: IOmsRequestUpdatePayload) => {
    return client.put(`/${endpoint}/${id}`, data, OmsRequestPayloadSchema);
  },

  acknowledgePUT: async (omsId: number) => {
    return client.put(
      `/${outageWarningEndpoint}/${omsId}?action=acknowledge`,
      {},
      OmsOutageWarningPayloadSchema
    );
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, OmsRequestDeletePayloadSchema);
  },
};

export default services;
