import React, { useCallback } from 'react';
import { get } from 'lodash';
import html2pdf from 'html2pdf.js';
import Lang from 'src/libraries/language';
import Moment, { IMoment } from 'src/libraries/moment.library';
import { ATMButton, ATMTable } from 'shared-it-appmod-ui';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { getDailyReportsStatus } from 'src/selectors/daily-reports.selector';
import { dailyReportsActionTypes } from 'src/ducks/daily-reports.duck';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { dailyReportETSColumns } from 'src/components/pages/daily-reports/daily-reports-ets/daily-reports-ets.component';
import classNames from 'classnames';
import styles from './pdf-ets-schedule.module.scss';

type IProps = {
  date?: IMoment | Date;
};

const ETSSchedulePDF: React.FC<IProps> = ({ date }) => {
  const { state } = useDailyReportsContext();
  const status = getDailyReportsStatus(
    state,
    dailyReportsActionTypes.DAILY_REPORTS_ETS_LIST
  );

  const handlePrint = useCallback(() => {
    const fields = dailyReportETSColumns.map((v) => [v.index, v.render]);
    let previousLer = 0;
    let previousOutBack = '';
    const table = (
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            {dailyReportETSColumns.map((value, key) => (
              <ATMTable.HeaderCell key={`header_${key}`}>
                {value.title}
              </ATMTable.HeaderCell>
            ))}
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {state.etsList.map((value, key) => {
            const row = (
              <ATMTable.Row key={`row_${key}`}>
                {fields.map(([index, render], k) => {
                  const isValid =
                    previousLer === value.requestId &&
                    previousOutBack === value.outBack;

                  let content = get(value, index as string);

                  if (isValid && (index === 'works' || index === 'wo')) {
                    content = '';
                  } else if (index !== 'location') {
                    content = (render as any)(
                      get(value, index as string),
                      value
                    );
                  }

                  return (
                    <ATMTable.Cell
                      key={`col_${key}_${k}`}
                      className={classNames({
                        [styles.noBorders]: isValid,
                      })}
                    >
                      {content}
                    </ATMTable.Cell>
                  );
                })}
              </ATMTable.Row>
            );
            previousLer = value.requestId;
            previousOutBack = value.outBack;

            return row;
          })}
        </ATMTable.Body>
      </ATMTable>
    );

    const cover = new DOMParser().parseFromString(
      `<div id="element-to-print">
      <span style="text-align:center; page-break-after: always;" id="cover"> 
        <h2>ETS Schedule</h2>
        <h3>${moment(date, 'YYYY-MM-DD HH:mm:ss')
          .format('dddd')
          .substring(0, 3)} ${moment(date).format('MM/DD/YYYY')}</h3>
      </span>
        ${ReactDOMServer.renderToStaticMarkup(table)}
      </div>`,
      'text/html'
    );
    const element = cover.getElementById('element-to-print');

    const options = {
      margin: 1,
      filename: `MCC_ETS_Schedule_${Moment().format('YYYYMMDD')}.pdf`,
      html2canvas: { scale: 2 },
      pagebreak: { mode: 'avoid-all' },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'p' },
    };
    html2pdf()
      .set(options)
      .from(element)
      .outputPdf()
      .get('pdf')
      .then((pdfObj) => {
        pdfObj.autoPrint();
        window.open(pdfObj.output('bloburl'), '_blank');
      });
  }, [state.etsList]);

  return (
    <ATMButton
      type="button"
      content={Lang.LBL_PRINT}
      secondary
      onClick={handlePrint}
      disabled={status.fetching}
      loading={status.fetching}
    />
  );
};

export default ETSSchedulePDF;
