import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  TroubleTicketTab,
  TroubleTicketRequestType,
} from 'src/constants/trouble-ticket.constant';
import {
  troubleTicketActionTypes,
  ITroubleTicketState,
} from 'src/ducks/trouble-ticket.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getTroubleTicketStatus = (
  state: ITroubleTicketState,
  action: keyof typeof troubleTicketActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getTroubleTicketParams = (
  params: IORGDataTableQueryState,
  currentTab: number
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;
  if (keyword) {
    data.filters = [
      ...(data.filters ?? []),
      {
        name: 'id',
        value: keyword,
      },
    ];
  }

  switch (+currentTab) {
    case TroubleTicketTab.Trouble: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'requestTyp',
          value: TroubleTicketRequestType.TROUBLE,
        },
      ];
      break;
    }

    case TroubleTicketTab.Scheduled: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'requestTyp',
          value: TroubleTicketRequestType.SCHEDULED,
        },
      ];
      break;
    }

    // case TroubleTicketTab.All: {
    //   data.filters = [
    //     ...(data.filters ?? []),
    //     {
    //       name: 'requestTyp',
    //       value: undefined,
    //     },
    //   ];
    //   break;
    // }

    default: {
      break;
    }
  }

  return data;
};
