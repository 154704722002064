import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSelect,
  ATMTable,
  useATMFormContext,
  ATMFormProvider,
  ATMInput,
} from 'shared-it-appmod-ui';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { LERRequestFormStep } from 'src/constants';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import Lang from 'src/libraries/language';
import {
  ILerRequestForm,
  ILerRequestShortDescriptionFrom,
  LerRequestShortDescriptionFormSchema,
} from 'src/models/ler-request.model';
import {
  getShortDescriptionList,
  getShortDescriptionStatus,
} from 'src/selectors/short-description.selector';

const FormContent: React.FC<{
  formRef?: React.RefObject<HTMLFormElement>;
}> = ({ formRef }) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
    setValue,
  } = useATMFormContext<ILerRequestShortDescriptionFrom>();
  const { state } = useShortDescriptionContext();
  const loading = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_LIST_READ
  );

  const [category, setCategory] = useState<number>();

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    reset({
      lerShortDescCatId: '',
      lerShortDescId: '',
    } as any);
  }, [reset, isSubmitSuccessful]);

  return (
    <ATMGrid columns={3}>
      <ATMGrid.Column>
        <ATMField
          as={ATMSelect}
          name="lerShortDescCatId"
          control={control}
          error={errors.lerShortDescCatId}
          placeholder={Lang.LBL_SELECT}
          clearable
          search
          options={state.categories.map((value) => ({
            key: value.catId,
            value: value.catId,
            text: value.shortDescCat,
          }))}
          onChange={([_, { value }]) => {
            setCategory(value);
            setValue('lerShortDescId', undefined as unknown as number);
            return value;
          }}
          loading={loading.fetching}
          disabled={loading.fetching}
        />
      </ATMGrid.Column>
      <ATMGrid.Column>
        <ATMField
          as={ATMSelect}
          name="lerShortDescId"
          control={control}
          error={errors.lerShortDescId}
          placeholder={Lang.LBL_SELECT}
          clearable
          search
          options={getShortDescriptionList(category || 0, state.list).map(
            (value) => ({
              key: value.descId,
              value: value.descId,
              text: value.shortDesc,
            })
          )}
          onChange={([_, { value }]) => value}
          disabled={!category || loading.fetching}
          loading={loading.fetching}
        />
      </ATMGrid.Column>
      <ATMGrid.Column>
        <ATMButton
          type="button"
          secondary
          icon="plus"
          content={Lang.LBL_ADD}
          onClick={handleClick}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

const LERRequestJobInformationDescription: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { control } = useATMFormContext<ILerRequestForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobInformation.shortDescriptions',
  });

  const { state, actions } = useShortDescriptionContext();

  useEffect(() => {
    actions.listGET();
  }, [actions]);

  const handleSubmit = useCallback(
    (data) => {
      const isExist = fields.find(
        (val) =>
          val.lerShortDescCatId === data.lerShortDescCatId &&
          val.lerShortDescId === data.lerShortDescId
      );

      if (!isExist) {
        append({
          ...data,
          seqNo: fields.length + 1,
        });
      } else {
        ToastError(Lang.MSG_LER_REQUEST_SHORT_DESC_EXIST_ERROR);
      }
    },
    [append, fields]
  );

  return (
    <div>
      <ATMHeader as="h2" content={Lang.TTL_JOB_INFO} />
      <ATMForm
        ref={formRef}
        validationSchema={LerRequestShortDescriptionFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ATMFormProvider {...props}>
            <FormContent formRef={formRef} />
          </ATMFormProvider>
        )}
      </ATMForm>

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_NO}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell colSpan={2}>
              {Lang.LBL_SHORT_DESCRIPTION}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {fields.length ? (
            fields.map((value, index) => {
              const category = state.categories.find(
                (v) => v.catId === value.lerShortDescCatId
              );
              const description = state.list.find(
                (v) => v.descId === value.lerShortDescId
              );

              return (
                <ATMTable.Row key={value.id}>
                  <ATMTable.Cell>
                    <ATMField
                      name={`${LERRequestFormStep.JOB_INFORMATION}.shortDescriptions[${index}].seqNo`}
                      control={control}
                      defaultValue={value?.seqNo ?? index}
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                    />
                    {value?.seqNo || index + 1}
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    {value?.lerShortDescCatId && (
                      <ATMField
                        name={`${LERRequestFormStep.JOB_INFORMATION}.shortDescriptions[${index}].lerShortDescCatId`}
                        control={control}
                        defaultValue={value?.lerShortDescCatId}
                        as={ATMInput}
                        type="hidden"
                        className="hidden"
                      />
                    )}

                    {category?.shortDescCat}
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    {value?.lerShortDescId && (
                      <ATMField
                        name={`${LERRequestFormStep.JOB_INFORMATION}.shortDescriptions[${index}].lerShortDescId`}
                        control={control}
                        defaultValue={value?.lerShortDescId}
                        as={ATMInput}
                        type="hidden"
                        className="hidden"
                      />
                    )}
                    {description?.shortDesc}
                  </ATMTable.Cell>
                  <ATMTable.Cell>
                    <ATMIcon name="trash" onClick={() => remove(index)} />
                  </ATMTable.Cell>
                </ATMTable.Row>
              );
            })
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={4} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </div>
  );
};

export default LERRequestJobInformationDescription;
