import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/issued-key.service';
import { IIssuedKey } from 'src/models/issued-key.model';

export const issuedKeyActionTypes = {
  ISSUED_KEY_DATA_READ: 'ISSUED_KEY_DATA_READ',
  ISSUED_KEY_LIST_READ: 'ISSUED_KEY_LIST_READ',
  ISSUED_KEY_DATA_CREATE: 'ISSUED_KEY_DATA_CREATE',
  ISSUED_KEY_DATA_UPDATE: 'ISSUED_KEY_DATA_UPDATE',
  ISSUED_KEY_DATA_DELETE: 'ISSUED_KEY_DATA_DELETE',
  ISSUED_KEY_DATA_SET: 'ISSUED_KEY_DATA_SET',
  ISSUED_KEY_LIST_ALL_READ: 'ISSUED_KEY_LIST_ALL_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: issuedKeyActionTypes.ISSUED_KEY_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: issuedKeyActionTypes.ISSUED_KEY_LIST_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: issuedKeyActionTypes.ISSUED_KEY_LIST_ALL_READ,
    service: services.listGET,
  },

  createPOST: {
    type: issuedKeyActionTypes.ISSUED_KEY_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: issuedKeyActionTypes.ISSUED_KEY_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: issuedKeyActionTypes.ISSUED_KEY_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (issuedKey: IIssuedKey) => ({
    type: issuedKeyActionTypes.ISSUED_KEY_DATA_SET,
    payload: issuedKey,
  }),
};

export type IIssuedKeyAsync = typeof duckActions;

export interface IIssuedKeyState
  extends ICommonState<typeof issuedKeyActionTypes> {
  data?: IIssuedKey;
  list: IIssuedKey[];
  total: number;
  activeTotal: number;
  lostTotal: number;
  damagedTotal: number;
  notReturnedTotal: number;
  pendingTotal: number;
  returnedTotal: number;
}

export const defaultState: IIssuedKeyState = {
  status: {},
  list: [],
  total: 0,
  activeTotal: 0,
  lostTotal: 0,
  damagedTotal: 0,
  notReturnedTotal: 0,
  pendingTotal: 0,
  returnedTotal: 0,
};

const IssuedKeyReducer = (
  state: IIssuedKeyState,
  action: IReducerAction<IIssuedKeyAsync>
): IIssuedKeyState => {
  switch (action.type) {
    case issuedKeyActionTypes.ISSUED_KEY_DATA_SET:
    case issuedKeyActionTypes.ISSUED_KEY_DATA_READ:
    case issuedKeyActionTypes.ISSUED_KEY_DATA_UPDATE:
    case issuedKeyActionTypes.ISSUED_KEY_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case issuedKeyActionTypes.ISSUED_KEY_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count.all ?? 0,
        activeTotal: action.payload?.count.active ?? 0,
        lostTotal: action.payload?.count.lost ?? 0,
        damagedTotal: action.payload?.count.damaged ?? 0,
        notReturnedTotal: action.payload?.count.notReturned ?? 0,
        pendingTotal: action.payload?.count.pendingReturn ?? 0,
        returnedTotal: action.payload?.count.returned ?? 0,
      };
    }

    case issuedKeyActionTypes.ISSUED_KEY_DATA_DELETE: {
      if (action.params) {
        const [key] = action.params;
        const list = state.list.filter((value) => value.keyNbr !== key);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default IssuedKeyReducer;
