import React from 'react';
import classNames from 'classnames';
import Moment, { IMoment } from 'src/libraries/moment.library';
import styles from './calendar-event.module.scss';

export type ICalendarEvent = {
  title:
    | ((
        event: ICalendarEvent,
        week: IMoment[],
        span: number
      ) => React.ReactNode)
    | React.ReactNode;
  start: IMoment;
  end: IMoment;
  desc?: string;
  allDay?: boolean;
};

export type IEventSegment<T = ICalendarEvent> = {
  event: T;
  span: number;
  left: number;
  right: number;
};

const SpanContent: React.FC<{
  slots: number;
  len: number;
  children?: React.ReactNode;
  className?: string;
}> = ({ slots, len, children, className }) => {
  const percent = `${(Math.abs(len) / slots) * 100}%`;

  return (
    <div
      className={classNames(className || styles.rowSegment)}
      style={{
        WebkitFlexBasis: percent,
        flexBasis: percent,
        maxWidth: percent,
      }}
    >
      {children ? <div className={styles.eventContent}>{children}</div> : ' '}
    </div>
  );
};

export const continuesPrior = (start, first) => {
  const mStart = Moment(start);
  const mFirst = Moment(first);
  return mStart.isBefore(mFirst, 'day');
};

export const continuesAfter = (end, last) => {
  const mEnd = Moment(end);
  const mLast = Moment(last);
  return mLast.isBefore(mEnd, 'day');
};

const EventContent: React.FC<{
  event: ICalendarEvent;
  week: IMoment[];
  span: number;
  hitList: IMoment[];
  handleClick?: (date: IMoment) => void;
}> = ({ event, week, span, hitList, handleClick }) => {
  return (
    <div
      className={classNames(
        styles.event,
        continuesPrior(event.start, week[0]) && 'continues-prior',
        continuesAfter(event.end, week[week.length - 1]) && 'continues-after'
      )}
    >
      {handleClick && (
        <div className={styles.hitBox}>
          {hitList.map((v, k) => {
            const percent = `${100 / Math.abs(hitList.length)}%`;

            return (
              <span
                key={k}
                style={{
                  width: percent,
                }}
                role="button"
                onClick={() => handleClick(v)}
              />
            );
          })}
        </div>
      )}
      {typeof event.title === 'function'
        ? event.title(event, week, span)
        : event.title}
    </div>
  );
};

type IEventRowProps = {
  dayRange: IMoment[];
  events: IEventSegment[];
  content?: React.ReactNode;
  className?: string;
  handleClick?: (date: IMoment) => void;
};

const CalendarEvent: React.FC<IEventRowProps> = ({
  dayRange,
  events,
  content,
  className,
  handleClick,
}) => {
  let lastEnd = 1;

  return (
    <div className={styles.wrapper}>
      {events.reduce(
        (row: React.ReactNode[], { event, left, right, span }, index) => {
          const id = `${event.start.format('YYYYMMDD')}_${index}`;
          const gap = left - lastEnd;

          if (gap) {
            row.push(
              <SpanContent
                key={`${id}_gap`}
                className={className}
                slots={dayRange.length}
                len={gap}
              />
            );
          }

          row.push(
            <SpanContent
              key={id}
              className={className}
              slots={dayRange.length}
              len={span}
            >
              {content || (
                <EventContent
                  event={event}
                  week={dayRange}
                  span={span}
                  hitList={[...dayRange].slice(left - 1, left + span - 1)}
                  handleClick={handleClick}
                />
              )}
            </SpanContent>
          );

          lastEnd = right + 1;

          return row;
        },
        []
      )}
    </div>
  );
};

export default React.memo(CalendarEvent);
