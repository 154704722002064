/* eslint-disable no-unneeded-ternary */
import classNames from 'classnames';
import React, { useState } from 'react';
import { List } from 'semantic-ui-react';
import { ATMCheckbox } from 'shared-it-appmod-ui';
import styles from './filter-checkbox.component.module.scss';

type IProps = {
  name: string;
  content: string;
  handleFacilityTypeId: (facilityTypeId: number, checked: boolean) => void;
  valueId: number;
  filters: any;
};

const FacilityTypeIdCheckbox: React.FC<IProps> = ({
  valueId,
  handleFacilityTypeId,
  content,
  name,
  filters,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    () => !!filters.facTypId.includes(Number(valueId))
  );

  return (
    <>
      <List.Item className={styles.checkboxListStatus}>
        <ATMCheckbox
          name={name}
          label={content}
          className={classNames(styles.checkboxStatus)}
          checked={isChecked}
          onChange={(_, { checked }) => {
            setIsChecked(checked || false);
            if (checked !== undefined) {
              handleFacilityTypeId(valueId, checked);
            }
            return checked;
          }}
        />
      </List.Item>
    </>
  );
};

export default FacilityTypeIdCheckbox;
