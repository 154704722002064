import React, { useCallback, useRef, useState } from 'react';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { IEquipmentTypeForm } from 'src/models/equipment-type.model';
import { IFacType } from 'src/models/facility.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getUser } from 'src/libraries/amplify.library';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import EquipmentTypeEditView from './equipment-type-edit.view';

type IProp = {
  data: IFacType;
};

const EquipmentTypeEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useEquipmentTypeContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getEquipmentTypeStatus(
    state,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IEquipmentTypeForm) => {
      const response = await actions.updatePUT(data?.facTypId, {
        ...formData,
        updatedBy: getUser()?.emp_no,
      });

      if (!response.error) {
        actions.listGET({ page: 1, limit: 50 });
        setIsEditOpen(false);

        ToastSuccess(
          `Equipment Type ${data.facTypNm} has been modified successfully`
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <EquipmentTypeEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default EquipmentTypeEdit;
