import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'src/libraries/moment.library';
import {
  ATMSegment,
  ATMGrid,
  ATMLabel,
  ATMDatePicker,
  ATMButton,
  IORGDataTableColumn,
  ORGDataTable,
  MOLNoData,
  ATMPopover,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { useTnaReportContext } from 'src/contexts/tna-report.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { ITnaReport } from 'src/models/tna-report.model';
import style from './tna-report-list.module.scss';

type IProps = {
  loading: boolean;
  data: ITnaReport[];
  queryParams: Record<any, any>;
  handleFetch: () => void;
  handleQueryParams: (params: Record<any, any>) => void;
  handleDownload: () => void;
  downloading: boolean;
};

const TNAReportView: React.FC<IProps> = ({
  data,
  loading,
  queryParams,
  handleFetch,
  handleQueryParams,
  handleDownload,
  downloading,
}) => {
  const { getUrl } = useLocationParams(['lerEdit']);
  const { actions } = useTnaReportContext();
  const customColumns: IORGDataTableColumn<ITnaReport>[] = [
    {
      title: Lang.LBL_LER,
      index: 'requestFacility.requestId',
      render: (_, value) => (
        <Link
          to={getUrl({
            lerEdit: value?.requestFacility?.requestId,
          })}
        >
          {value?.requestFacility?.requestId}
        </Link>
      ),
    },
    {
      title: Lang.LBL_TNA_STATUS,
      index: 'status.statDescription',
      render: (_, value) => value?.status?.statDescription,
    },
    {
      title: Lang.LBL_TNA_EQUIPMENT_TYPE,
      index: 'requestFacility.facility.facTyp.facTypNm',
      render: (_, value) => value?.requestFacility?.facility?.facTyp?.facTypNm,
    },
    {
      title: Lang.LBL_TNA_VOLTAGE,
      index: 'requestFacility.facility.volt.voltNm',
      render: (_, value) =>
        `${value?.requestFacility?.facility?.volt?.voltNm}kv`,
    },
    {
      title: Lang.LBL_TNA_EQUIPMENT,
      index: 'requestFacility.facility.outgFacNm',
      render: (_, value) =>
        value?.requestFacility?.facility?.facTypId !==
          Lang.FACILITY_TYPE_ID_BACKEND.LINE &&
        value?.requestFacility?.substationId
          ? `${value?.requestFacility?.facility?.outgFacNm} @ ${value?.requestFacility?.substation?.name}`
          : value?.requestFacility?.facility?.outgFacNm,
    },
    {
      title: Lang.LBL_TNA_RECALL_TIME,
      index: 'requestFacility.outageRequest.recalTm',
      render: (_, value) => value?.requestFacility?.outageRequest?.recalTm,
    },
    {
      title: Lang.LBL_TNA_START_DATE,
      index: 'startTm',
      render: (_, value) => Moment(value.startTm).format('MM/DD/YYYY'),
    },
    {
      title: Lang.LBL_TNA_START_TIME,
      index: 'startTmTxt',
      render: (_, value) => value?.startTmTxt,
    },
    {
      title: Lang.LBL_TNA_END_DATE,
      index: 'stopTm',
      render: (_, value) => Moment(value.stopTm).format('MM/DD/YYYY'),
    },
    {
      title: Lang.LBL_TNA_END_TIME,
      index: 'stopTmText',
      render: (_, value) => value?.stopTmText,
    },
    {
      title: Lang.LBL_TNA_DAYS,
      index: 'days',
      render: (_, value) => value?.days,
    },
    {
      title: Lang.LBL_TNA_AUTH_TYPE,
      index: 'requestFacility.outageType.outageTypeDesc',
      render: (_, value) => value?.requestFacility?.outageType?.outageTypeDesc,
    },
    {
      title: Lang.LBL_TNA_WORK_DESCRIPTION,
      index: 'requestFacility.outageRequest.wrkDesc',
      render: (_, value) => value?.requestFacility?.outageRequest?.wrkDesc,
    },
    {
      title: Lang.LBL_TNA_NATURE_OF_WORK,
      index: 'requestFacility.outageRequest.isoWorkKindId',
      render: (_, value) =>
        value?.requestFacility?.outageRequest?.isoWorkKindId,
    },
  ];
  return (
    <>
      <ATMSegment className={style.bgColor}>
        <ATMGrid>
          <ATMGrid.Row columns={5} className={style.filters}>
            <ATMGrid.Column>
              <ATMLabel
                className={style.filterLbl}
                basic
                content={Lang.LBL_DATE}
              />
              <ATMDatePicker
                name="dateRange"
                placeholder={Lang.LBL_SELECT}
                format="MM/DD/YYYY"
                value={
                  queryParams?.dateRange ? queryParams?.dateRange[0] : undefined
                }
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    dateRange: [value],
                  });
                  return value;
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMButton
                primary
                content={Lang.LBL_SEARCH}
                onClick={() => handleFetch()}
              />
              <ATMButton
                secondary
                content={Lang.LBL_CLEAR_SEARCH}
                onClick={() => {
                  handleQueryParams({});
                  actions?.clearList();
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
      <ORGDataTable
        sortable
        data={data}
        celled={false}
        loading={loading}
        columns={customColumns}
        enableScroll
        // fixedColumns="last"
        noDataText={
          <MOLNoData
            icon="search"
            header="Search to view records"
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
      >
        {() => ({
          toolbars: [
            () => (
              <ATMPopover
                position="bottom left"
                content="Download"
                on="hover"
                trigger={
                  <ATMButton
                    secondary
                    icon="download"
                    onClick={handleDownload}
                    loading={downloading}
                    disabled={!data || data.length === 0}
                  />
                }
              />
            ),
          ],
        })}
      </ORGDataTable>
    </>
  );
};

export default TNAReportView;
