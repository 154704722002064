import React, { useEffect, useCallback } from 'react';
import { useSettingContext } from 'src/contexts/setting.context';
import { getSettingStatus } from 'src/selectors/setting.selector';
import { settingActionTypes } from 'src/ducks/setting.duck';
import { SettingModule } from 'src/constants';
import AlertSettingsView from './alert-settings.view';

const AlertSettings: React.FC = () => {
  const { state, actions } = useSettingContext();
  const status = getSettingStatus(
    state,
    settingActionTypes.SETTING_MODULE_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listByModuleGET(SettingModule.Roles);
  }, [actions]);

  useEffect(() => {
    handleFetch();
  }, [actions]);
  const handleDownload = () => {};
  return (
    <>
      <pre style={{ paddingBottom: '.5rem' }}>
        Alert message appears on landing page and header of each page in{' '}
        <span style={{ color: 'red' }}>RED</span> color.
      </pre>
      <AlertSettingsView
        data={state.moduleList}
        loading={status.fetching}
        handleFetch={handleFetch}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default AlertSettings;
