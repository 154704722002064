import React, { useState } from 'react';
import {
  ATMButton,
  ATMModal,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IUserGroup, IUserGroupMembers } from 'src/models/user-group.model';

const columns: IORGDataTableColumn<IUserGroupMembers>[] = [
  {
    index: 'empId',
    title: Lang.LBL_GROUP_MEMBERS,
    render: (_, value) => {
      return `${value.empId} - ${value.firstName} ${value.lastName}`;
    },
  },
];

type IProps = {
  data: IUserGroup;
};

const UserGroupMemberModal: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <u>
          {' '}
          <a
            style={{ color: '#5D5D5D' }}
            role="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >{`+${
            data.userGrpMembers && data.userGrpMembers.length - 1
          }`}</a>{' '}
        </u>
      }
    >
      <ATMModal.Header>
        {data.userGrpNm} - {Lang.LBL_GROUP_MEMBERS}
      </ATMModal.Header>
      <ATMModal.Content>
        {data.userGrpMembers && (
          <ORGDataTable
            columns={columns}
            data={data.userGrpMembers}
            showPagination
          />
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          primary
          content="Close"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default UserGroupMemberModal;
