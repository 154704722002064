import React, { useCallback, useRef, useState } from 'react';
import { useSettingContext } from 'src/contexts/setting.context';
import { settingActionTypes } from 'src/ducks/setting.duck';
import {
  ISetting,
  SettingFormSchema,
  ISettingForm,
} from 'src/models/setting.model';
import { getSettingStatus } from 'src/selectors/setting.selector';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMTextArea,
  ATMModal,
  ATMInput,
  ATMRadio,
} from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { SettingName } from 'src/constants';
import style from './auth-settings.module.scss';

type IProp = {
  data: ISetting;
  handleFetch: () => void;
};

const SettingsEdit: React.FC<IProp> = ({ data, handleFetch }) => {
  const { state, actions } = useSettingContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getSettingStatus(
    state,
    settingActionTypes.SETTING_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ISettingForm) => {
      const response = await actions.updatePUT(data.name, formData);
      if (response) {
        setIsEditOpen(false);
        handleFetch();
        ToastSuccess('Settings has been successfully edited!');
      } else {
        ToastError(
          'Error in updating settings. Contact your System Administrator.'
        );
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <ATMModal
      open={isEditOpen}
      size="small"
      trigger={
        <a
          role="button"
          className={style.underlined}
          onClick={() => setIsEditOpen(!isEditOpen)}
        >
          {Lang.LBL_EDIT}
        </a>
      }
      header={Lang.TTL_EDIT_SETTINGS}
      content={
        <div className={style.addContent}>
          <ATMForm
            ref={formRef}
            mode="onChange"
            onSubmit={handleSubmit}
            validationSchema={SettingFormSchema}
            defaultValues={{
              value: data.value,
            }}
          >
            {(props) => {
              const { control, formState, setValue, watch } = props;
              setIsDirty(formState.isDirty);
              return (
                <ATMGrid>
                  {/* US #597730 implement toggle button for boolean-based settings */}
                  {[
                    SettingName.FLAG_FOR_OUTAGE,
                    SettingName.ALLOW_OMS_QUERY_FROM_DAILY_OUTAGE_REPORT,
                    SettingName.LOG_OMS_OUTAGE_DATA,
                    SettingName.POPUP_LANDING_DISPLAY,
                    SettingName.USE_NEW_SORT_SCHEMA,
                    SettingName.ENFORCE_BES_SUBMITTAL_WINDOW,
                  ].includes(data.name as SettingName) ? (
                    <ATMGrid.Row columns={2}>
                      <ATMGrid.Column>
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={data?.name}
                          name="name"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={data?.module}
                          name="module"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={
                            data?.category === null ? '' : data?.category
                          }
                          name="category"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          label={
                            <div className={style.marginBottom}>
                              {Lang.LBL_PROPERTY}
                            </div>
                          }
                          defaultValue={data?.description}
                          name="description"
                          readOnly
                          onChange={([_, { value }]) => value}
                        />
                      </ATMGrid.Column>
                      {[SettingName.FLAG_FOR_OUTAGE].includes(
                        data.name as SettingName
                      ) ? (
                        <ATMGrid.Column verticalAlign="middle">
                          <ATMField
                            as={ATMRadio}
                            control={control}
                            defaultChecked={data.value === 'TRUE'}
                            label={
                              <div className={style.marginBottomToggle}>
                                {Lang.LBL_VALUE}:
                                {watch('value') === 'TRUE' ? ' True' : ' False'}
                              </div>
                            }
                            name="valueToggle"
                            toggle
                            onChange={([_, { checked }]) => {
                              if (checked) {
                                setValue('value', 'TRUE');
                              } else {
                                setValue('value', 'FALSE');
                              }
                              return checked;
                            }}
                          />
                        </ATMGrid.Column>
                      ) : (
                        <ATMGrid.Column verticalAlign="middle">
                          <ATMField
                            as={ATMRadio}
                            control={control}
                            defaultChecked={data.value === 'YES'}
                            label={
                              <div className={style.marginBottomToggle}>
                                {Lang.LBL_VALUE}:
                                {watch('value') === 'YES' ? ' Yes' : ' No'}
                              </div>
                            }
                            name="valueToggle"
                            toggle
                            onChange={([_, { checked }]) => {
                              if (checked) {
                                setValue('value', 'YES');
                              } else {
                                setValue('value', 'NO');
                              }
                              return checked;
                            }}
                          />
                        </ATMGrid.Column>
                      )}
                    </ATMGrid.Row>
                  ) : (
                    <ATMGrid.Row columns={1}>
                      <ATMGrid.Column>
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={data?.name}
                          name="name"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={data?.module}
                          name="module"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          defaultValue={
                            data?.category === null ? '' : data?.category
                          }
                          name="category"
                          type="hidden"
                          className="hidden"
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMInput}
                          control={control}
                          label={
                            <div className={style.marginBottom}>
                              {Lang.LBL_PROPERTY}
                            </div>
                          }
                          defaultValue={data?.description}
                          name="description"
                          readOnly
                          onChange={([_, { value }]) => value}
                        />
                        <ATMField
                          as={ATMTextArea}
                          maxLength={3000}
                          control={control}
                          label={
                            <div className={style.marginBottom}>
                              {Lang.LBL_VALUES}
                            </div>
                          }
                          name="value"
                        />
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  )}
                </ATMGrid>
              );
            }}
          </ATMForm>
        </div>
      }
      actions={
        <div className={style.actionBtns}>
          <ATMButton
            className={style.marginRight}
            basic
            color="blue"
            disabled={status.fetching}
            content={Lang.LBL_CANCEL}
            onClick={() => setIsEditOpen(!isEditOpen)}
          />
          <ATMButton
            primary
            disabled={!isDirty}
            content={Lang.LBL_SAVE}
            loading={status.fetching}
            onClick={() => handleClick()}
          />
        </div>
      }
    />
  );
};

export default SettingsEdit;
