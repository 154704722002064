import React, { useCallback, useEffect, useState } from 'react';
import {
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
} from 'shared-it-appmod-ui';
import { useRolesContext } from 'src/contexts/roles.context';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import { formatEmployeeName } from 'src/helpers/key-request.helper';
import useLocationTab from 'src/hooks/location-tab.hook';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getRolesStatus } from 'src/selectors/roles.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import KeyRequestorReportView from './key-request-report.view';

const KeyRequestorReport: React.FC = () => {
  const { state, actions } = useRolesContext();
  const [loading, setLoading] = useState(false);
  const { currentTab } = useLocationTab('requestor-report');

  const dlStatus = getRolesStatus(
    state,
    rolesActionTypes.ROLES_KEY_REQUESTOR_LIST_ALL_READ
  );

  const handleFetch = useCallback(
    async (
      params: IORGDataTableQueryState,
      options?: IORGDataTableUpdateOptions
    ) => {
      if (
        !options ||
        (options && options.action) ||
        !state.keyRequestor.length
      ) {
        setLoading(true);
      }

      await actions.keyRequestorListGET({ ...params });

      setLoading(false);
    },
    [actions, currentTab, setLoading]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const result = await actions.keyRequestorListAllGET(params);
      if (result.payload?.rows) {
        const list = result.payload.rows;

        const items = list.map((value) => [
          checkValue(value.empId.toString()),
          checkValue(
            formatEmployeeName(
              '',
              '',
              `${value.employee.fullName}`,
              '',
              true,
              Lang.LBL_UNAVAILABLE
            )
          ),
          checkValue(value && value.role.roleName),
          checkValue(value.employee.title),
          checkValue(value.employee.workPhoneNumber ?? '--'),
          checkValue(value.employee.email ?? '--'),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_EMPLOYEE_ID,
            Lang.LBL_EMPLOYEE_NAME,
            Lang.LBL_EMPLOYEE_ROLE,
            Lang.LBL_EMPLOYEE_TITLE,
            Lang.LBL_EMPLOYEE_PHONE,
            Lang.LBL_EMPLOYEE_EMAIL,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Requestor_Report_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  useEffect(() => {
    actions?.findAllOptionRolesGET();
  }, []);

  return (
    <KeyRequestorReportView
      data={state.keyRequestor}
      loading={loading}
      handleFetch={handleFetch}
      total={state.keyRequestorTotal}
      dlLoading={dlStatus.fetching}
      handleDownload={handleDownload}
    />
  );
};

export default KeyRequestorReport;
