import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import history from 'src/history';

type IProps = {
  content: any;
  printContent: string;
  header: string;
  isOpen: boolean;
  loading: boolean;
  // handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
};

export function printDiv(printcontent: string) {
  const w = window.open();
  w?.document.write(printcontent);
  w?.focus();
  w?.document.close();
  w?.print();
  w?.close();
}

const ConfirmationContainer: React.FC<IProps> = ({
  content,
  printContent,
  header,
  isOpen,
  loading,
  handleOpen,
  url,
  // handleClick,
}) => {
  return (
    <ATMModal open={isOpen} size="small">
      <ATMModal.Header>Confirmation - {header}</ATMModal.Header>
      <ATMModal.Content>{content}</ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          icon="print"
          content="Print"
          disabled={loading}
          onClick={() => printDiv(printContent)}
          loading={loading}
        />
        <ATMButton
          id="close"
          primary
          content="Close"
          onClick={() => {
            handleOpen(false);
            if (url) {
              if (`?${url?.split('?')[1]}` === history.location.search) {
                history.go(0);
              } else {
                history.push(url);
              }
            }
          }}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default ConfirmationContainer;
