import React, { useCallback, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useSubstationContext } from 'src/contexts/substation.context';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import SubstationListView from './substation-list.view';

const SubstationList: React.FC = () => {
  const { state, actions } = useSubstationContext();
  const status = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_LIST_READ
  );

  useEffect(() => {
    actions.filterGET({ limit: 0, page: 1 });
  }, [actions]);

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      actions.listGET(params);
    },
    [actions]
  );
  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const result = await actions.listAllGET(params);
      if (result.payload?.rows) {
        const list = result.payload.rows;
        const items = list.map((value) => [
          checkValue(`${value.substationId} - ${value.name}`),
          checkValue(value.addr1),
          checkValue(value.city),
          checkValue(value.phnNbr),
          checkValue(value.subDistId),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            'ID-Name',
            'Location Address',
            Lang.LBL_CITY,
            Lang.LBL_EMPLOYEE_PHONE,
            Lang.LBL_DISTRICTS,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Substation_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  return (
    <SubstationListView
      data={state.list}
      total={state.total}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
      filter={state.filter}
    />
  );
};

export default SubstationList;
