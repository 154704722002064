import React, { useCallback, useRef, useState } from 'react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMModal,
} from 'shared-it-appmod-ui';
import BlockEmployee from 'src/components/atoms/block-employee/block-employee.component';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { AccessType, hasAccess } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IEmployee, IEmployeeInternal } from 'src/models/employee.model';
import { LerRequestAssignToSchema } from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import style from '../ler-request.module.scss';

type IProps = {
  requestId: number;
  employee?: IEmployeeInternal;
  handleSuccess?: () => void;
};

const LERRequestAssignScheduler: React.FC<IProps> = ({
  requestId,
  employee: data,
  handleSuccess,
}) => {
  const { state: employeeState } = useEmployeeContext();
  const { state: lerRequestState, actions } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);
  const [employee, setEmployee] = useState<IEmployee>();
  const [dirty, setDirty] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const assignStatus = getLerRequestStatus(
    lerRequestState,
    lerRequestActionTypes.LER_REQUEST_ASSIGN_SCHEDULER
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(async () => {
    const result = await actions.assignPUT(requestId, employee?.emp_id);

    if (result.payload) {
      ToastSuccess('Assigned Successfully');
      setIsOpen(false);

      if (handleSuccess) {
        handleSuccess();
      }
    }
  }, [actions, employee, setIsOpen, handleSuccess]);

  const handleSearch = useCallback(
    (id) => {
      const user = employeeState.list.find((value) => value.emp_id === id);

      setEmployee(user);
      return id;
    },
    [employeeState, setEmployee]
  );

  if (hasAccess(AccessType.OUTAGE_REQUEST_ASSIGN_SCHEDULER) === false) {
    return (
      <span>
        {data ? `${data.firstName} ${data.lastName}` : Lang.LBL_UNASSIGNED}
      </span>
    );
  }

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <a role="button" className={style.underlined}>
          {data ? `${data.firstName} ${data.lastName}` : Lang.LBL_UNASSIGNED}
        </a>
      }
      header={Lang.TTL_ASSIGN_LER}
      content={
        <div className={style.addContent}>
          <ATMForm
            ref={formRef}
            mode="onChange"
            onSubmit={handleSubmit}
            validationSchema={LerRequestAssignToSchema}
            defaultValues={{
              empId: data?.empno,
              requestId,
            }}
          >
            {(props) => {
              const {
                control,
                formState: { errors, isDirty },
              } = props;
              setDirty(isDirty);

              return (
                <ATMGrid>
                  <ATMGrid.Row columns={3}>
                    <ATMGrid.Column>
                      <ATMField
                        as={ATMInput}
                        type="hidden"
                        control={control}
                        className="hidden"
                        name="requestId"
                        onChange={([_, { value }]) => value}
                      />
                      <ATMField
                        search
                        name="empId"
                        control={control}
                        as={DropdownEmployee}
                        error={errors?.empId}
                        label={Lang.LBL_ASSIGNEE}
                        onChange={([_, { value }]) => handleSearch(value)}
                        onLoad={(user) => setEmployee(user)}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column />
                    <ATMGrid.Column />
                  </ATMGrid.Row>
                  {employee && (
                    <ATMGrid.Row>
                      <ATMGrid.Column>
                        <BlockEmployee data={employee} />
                      </ATMGrid.Column>
                    </ATMGrid.Row>
                  )}
                </ATMGrid>
              );
            }}
          </ATMForm>
        </div>
      }
      actions={
        <div className={style.actionBtns}>
          <ATMButton
            basic
            color="blue"
            content={Lang.LBL_CANCEL}
            type="button"
            onClick={() => {
              setIsOpen(false);
              setEmployee(undefined);
            }}
          />
          <ATMButton
            primary
            disabled={!dirty || assignStatus.fetching}
            content={Lang.LBL_SAVE}
            loading={assignStatus.fetching}
            type="button"
            onClick={handleClick}
          />
        </div>
      }
    />
  );
};

export default LERRequestAssignScheduler;
