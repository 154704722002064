import React, { useCallback, useState } from 'react';
import { useRolesContext } from 'src/contexts/roles.context';
import { getRolesStatus } from 'src/selectors/roles.selector';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { getUser } from 'src/libraries/amplify.library';
import history from 'src/history';
import RolesDeleteView from './roles-delete.view';

type IProp = {
  id?: string;
  rightPanel?: boolean;
  empName?: string;
  setOnClose?: () => void;
  handleSuccess?: () => void;
  handleShowSearch?: (id) => void;
};

const RolesDelete: React.FC<IProp> = ({
  id,
  rightPanel,
  empName,
  setOnClose,
  handleSuccess,
}) => {
  const { state, actions } = useRolesContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getRolesStatus(state, rolesActionTypes.ROLES_DATA_DELETE);
  const handleSubmit = useCallback(async () => {
    if (id) {
      const user = getUser();
      const response = await actions.dataDELETE(id.toString());

      if (!response.error) {
        setIsOpen(false);
        if (setOnClose) {
          setOnClose();
        }
        const name = empName || id;
        ToastSuccess(
          `Successfully delete all the roles for the employee ${name}`
        );

        if (handleSuccess) {
          handleSuccess();
        } else {
          actions.clearList();
        }

        if (user && user.emp_no === id) {
          history.push('/logout/unauthorized');
        }
      } else {
        ToastError(response.error.message);
      }
    }
  }, [actions, id]);

  return (
    <RolesDeleteView
      id={id}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
      empName={empName}
      rightPanel={rightPanel}
    />
  );
};

export default RolesDelete;
