/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMInput,
  ATMCheckbox,
  useATMFormContext,
  ATMLoader,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import Lang from 'src/libraries/language/index';
import { LERRequestFormStep } from 'src/constants';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';

const ISOEquipmentTable: React.FC = () => {
  const {
    state: { lerDetails, ...facilityState },
  } = useFacilityContext();
  const { control } = useATMFormContext<ILerRequestForm>();

  const {
    state: { data, ...lerState },
  } = useLerRequestContext();

  const facilityDetailsStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_LER_DETAILS_READ
  );
  const lerDetailsStatus = getLerRequestStatus(
    lerState,
    lerRequestActionTypes.LER_REQUEST_DATA_READ
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'isoTrs.caisoTrs',
  });

  const handleAddIso = useCallback(
    (caisoId?: string) => {
      if (caisoId) {
        append({
          caisoId,
          facilityModelInd: false,
          requestId: undefined,
          updatedAt: undefined,
          updatedBy: undefined,
          version_no: undefined,
        });
      } else {
        append({
          caisoId: '',
          facilityModelInd: false,
          requestId: undefined,
          updatedAt: undefined,
          updatedBy: undefined,
          version_no: undefined,
        });
      }
    },
    [append]
  );

  return (
    <>
      {fields.length > 0 ? (
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell colSpan={2}>
                {Lang.LBL_LER_ISO_CAISO_EQUIP}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell textAlign="center">
                {Lang.LBL_ACTION}
              </ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((item, i) => (
              <ATMTable.Row key={item.id}>
                <ATMTable.Cell width="4">
                  <ATMField
                    as={ATMInput}
                    name={`${[LERRequestFormStep.ISO]}.caisoTrs[${i}].caisoId`}
                    control={control}
                    placeholder="Enter"
                    defaultValue={item.caisoId}
                    onChange={([_, { value }]) => {
                      return value;
                    }}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell verticalAlign="middle" width="4">
                  <ATMField
                    as={ATMCheckbox}
                    name={`${[
                      LERRequestFormStep.ISO,
                    ]}.caisoTrs[${i}].facilityModelInd`}
                    label={Lang.LBL_OC_LER_ISO_USE_FACILITY_MODEL}
                    control={control}
                    inline
                    style={{ marginTop: '1em' }}
                    onChange={([_, { checked }]) => checked}
                    defaultChecked={item.facilityModelInd}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell textAlign="center" width={2}>
                  <ATMButton
                    icon="trash alternate outline red"
                    type="button"
                    onClick={() => remove(i)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
          <ATMTable.Footer>
            <ATMTable.Cell colSpan={3}>
              <ATMButton
                secondary
                className="borderless"
                icon="plus"
                content="Add More"
                onClick={() => handleAddIso()}
                type="button"
              />
            </ATMTable.Cell>
          </ATMTable.Footer>
        </ATMTable>
      ) : facilityDetailsStatus.fetching || lerDetailsStatus.fetching ? (
        <ATMLoader active inline="centered" />
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              icon="plus"
              secondary
              content="Add"
              onClick={() => handleAddIso()}
              type="button"
            />
          }
        />
      )}
    </>
  );
};

export default ISOEquipmentTable;
