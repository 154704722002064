import React, { useState } from 'react';
import { ATMButton, ATMDivider, ATMPopover } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IFacility } from 'src/models/facility.model';
import styles from './facility-card.module.scss';

type IProps = {
  facility: IFacility;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
  setSelectedFacility: React.Dispatch<React.SetStateAction<number>>;
};

const FacilityCard: React.FC<IProps> = ({
  facility,
  setFacilityActions,
  setSelectedFacility,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ATMPopover
      className={styles.popup}
      style={{ zIndex: isOpen ? 100 : 0 }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={
        <u>
          <a
            style={{ color: '#009bda' }}
            role="button"
            onClick={() => setIsOpen(true)}
          >
            <b>{facility.outgFacNm}</b>
          </a>{' '}
        </u>
      }
      position="right center"
      on="click"
    >
      <ATMPopover.Header>
        <div className={styles.overlayHeader}>
          <h5>
            <small>{Lang.LBL_FACILITY}</small>
            {facility.outgFacNm}
          </h5>
        </div>
      </ATMPopover.Header>
      <ATMPopover.Content>
        <>
          <div className={styles.overlayContent}>
            <h5>
              <small>{Lang.LBL_CAISO}</small>
              {facility.isoTrs
                ? facility.isoTrs
                    ?.map((val) => {
                      return val.isoTrId;
                    })
                    .toString()
                : String.fromCharCode(8211)}
            </h5>
            <h5>
              <small>{Lang.LBL_EMS}</small>
              {facility.emsFacs
                ? facility.emsFacs
                    ?.map((val) => {
                      return val.emsFacId;
                    })
                    .toString()
                : String.fromCharCode(8211)}
            </h5>
            <h5>
              <small>{Lang.LBL_SPECIAL_CONCERNS}</small>
              {facility.spclOpsConcern}
            </h5>
          </div>
          <div className={styles.actionButtons}>
            <ATMDivider />
            <ATMButton
              id="edit"
              secondary
              content="Edit"
              size="mini"
              onClick={() => {
                setSelectedFacility(facility.outgFacId);
                setFacilityActions({
                  isNew: false,
                  isDetails: false,
                  isEdit: true,
                });
              }}
            />
            <ATMButton
              id="details"
              secondary
              content={Lang.LBL_MORE_DETAILS}
              size="mini"
              onClick={() => {
                setSelectedFacility(facility.outgFacId);
                setFacilityActions({
                  isNew: false,
                  isDetails: true,
                  isEdit: false,
                });
              }}
            />
          </div>
        </>
      </ATMPopover.Content>
    </ATMPopover>
  );
};

export default FacilityCard;
