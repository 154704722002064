import React, { useEffect } from 'react';
import { ATMCheckbox, ATMHeader, ATMIcon, ATMTable } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useSignificantCostContext } from 'src/contexts/significant-cost.context';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import styles from '../ler-request.module.scss';

type IProps = {
  // Change to Cost Interface once ready.
  data?: ILerRequest;
};

const LERRequestCost: React.FC<IProps> = ({ data }) => {
  const { state, actions } = useSignificantCostContext();

  useEffect(() => {
    actions.listGET();
  }, [actions]);

  const fields = data?.significantCost?.significantCostTasks ?? [];

  const header = (
    <>
      <span>
        <ATMIcon circular name="tags" />
      </span>
      <ATMHeader
        as="h1"
        content={Lang.TTL_COSTS}
        className={styles.headerDoc}
      />

      <FieldReadOnly
        label={Lang.LBL_COMMENTS}
        value={data?.significantCost?.significantCostNote?.noteTxt}
      />

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>
              {Lang.LBL_DISTRIBUTION_LIST}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_SAVE}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_SEND_EMAIL}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {state.list.map((value) => (
            <ATMTable.Row key={value.significantCostId}>
              <ATMTable.Cell>{value.description}</ATMTable.Cell>
              <ATMTable.Cell>
                <ATMCheckbox
                  checked={
                    fields.findIndex(
                      (v) => v.significantCostId === value.significantCostId
                    ) >= 0
                  }
                  disabled
                />
              </ATMTable.Cell>
              <ATMTable.Cell>
                <ATMCheckbox
                  checked={
                    fields.findIndex(
                      (v) => v.significantCostId === value.significantCostId
                    ) >= 0
                  }
                  disabled
                />
              </ATMTable.Cell>
            </ATMTable.Row>
          ))}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  return <div>{header}</div>;
};

export default LERRequestCost;
