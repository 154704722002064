import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import ContractorFirmDelete from '../contractor-firm-delete/contractor-firm-delete.component';
import ContractorFirmEdit from '../contractor-firm-edit/contractor-firm-edit.component';

const columns: IORGDataTableColumn<IContractorFirm>[] = [
  {
    index: 'contracShortNm',
    title: Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME,
  },
  {
    index: 'fullName',
    title: Lang.LBL_CONTRACTOR_FIRM_FULL_NAME,
  },
  {
    index: 'contactName',
    title: Lang.LBL_CONTRACTOR_FIRM_CONTACT,
  },
  {
    index: 'areaCode',
    title: Lang.LBL_CONTRACTOR_FIRM_AREA_CODE,
  },
  {
    index: 'phoneNumber',
    title: Lang.LBL_CONTRACTOR_FIRM_PHONE_NUMBER,
  },
  {
    index: 'action',
    sortable: false,
    title: '',
    width: '2%',
    cellProps: {
      textAlign: 'right',
    },
    render: (_, value) => {
      return (
        <div>
          <ATMDropdown size="small" icon="ellipsis horizontal">
            <ATMDropdown.Menu>
              <ContractorFirmEdit data={value} />
              <ContractorFirmDelete data={value} />
            </ATMDropdown.Menu>
          </ATMDropdown>
        </div>
      );
    },
  },
];

type IProps = {
  data: IContractorFirm[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
};

const ContractorFirmListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
}) => {
  return (
    <ORGDataTable
      columns={columns}
      sortable
      data={data}
      onChange={handleFetch}
      loading={loading}
      celled={false}
      showPagination={false}
      counter
    />
  );
};

export default ContractorFirmListView;
