import moment from 'moment';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  RealTimeLogLinePayloadSchema,
  IRealTimeLogEntryForm,
  RealTimeLogListPayloadSchema,
  RealTimeLogDeletePayloadSchema,
  RealTimeLogPayloadSchema,
  EmployeesRealTimeLogListPayloadSchema,
  RealTimeLogMailerSchema,
  IRealTimeLogAttachment,
} from 'src/models/real-time-log.model';

const client = httpClient();
const endpoint = 'real-time-log';
const employeesEndpoint = 'real-time-log?action=employees';

const services = {
  dataGET: async (logId: number) => {
    return client.get(`/${endpoint}/${logId}`, {}, RealTimeLogPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, RealTimeLogListPayloadSchema);
  },

  employeeListGET: async () => {
    return client.get(
      `/${employeesEndpoint}`,
      {},
      EmployeesRealTimeLogListPayloadSchema
    );
  },

  createPOST: async (data: IRealTimeLogEntryForm) => {
    if (data.logDtTm) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTm = moment(data.logDtTm).utc(false) as unknown as Date;
    }
    if (data.logDtTmFrcdOutOcurred) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTmFrcdOutOcurred = moment(data.logDtTmFrcdOutOcurred).utc(
        false
      ) as unknown as Date;
    }
    if (data.logDtTmFrcdOutRestored) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTmFrcdOutRestored = moment(data.logDtTmFrcdOutRestored).utc(
        false
      ) as unknown as Date;
    }

    return client.post(`/${endpoint}`, data, RealTimeLogPayloadSchema);
  },

  updatePUT: async (logId: number, data: IRealTimeLogEntryForm) => {
    return client.put(`/${endpoint}/${logId}`, data, RealTimeLogPayloadSchema);
  },

  dataDELETE: async (logId: number) => {
    return client.delete(
      `/${endpoint}/${logId}`,
      RealTimeLogDeletePayloadSchema
    );
  },

  lineGET: async () => {
    return client.get(
      `/${endpoint}?action=line`,
      {},
      RealTimeLogLinePayloadSchema
    );
  },

  sendEmail: async (logId: number, attachments: IRealTimeLogAttachment) => {
    return client.post(`/${endpoint}?action=sendEmail`, { logId, attachments }, RealTimeLogMailerSchema);
  }
};

export default services;
