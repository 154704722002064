/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize, flattenDeep, isEqual } from 'lodash';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMDatePicker,
  ATMLabel,
  ATMCheckbox,
  ATMIcon,
  ATMPopover,
  ATMGrid,
  ATMModal,
  ATMHeader,
  ATMTable,
  ATMLoader,
} from 'shared-it-appmod-ui';
import { LERRequestStatus, OmsActions } from 'src/constants';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Lang from 'src/libraries/language';
import Moment, {
  formatDate,
  formatDateHour,
} from 'src/libraries/moment.library';
import {
  IOmsListPage,
  IOmsData,
  IOmsRequestSubmitRefreshResponse,
} from 'src/models/oms-request.model';
import OmsRequestEdit from '../oms-request-edit/oms-request-edit.component';
import styles from '../oms-request-list.module.scss';

type IProps = {
  data: IOmsListPage[];
  isOpen: string;
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleCheckbox: (data: string, checked?: boolean) => void;
  omsResponse: IOmsRequestSubmitRefreshResponse[];
  setIsOpen: React.Dispatch<React.SetStateAction<string>>;
  handleAcknowledge: (data: number) => void;
  acknowledging: boolean;
  errorMsg: string[];
  hasError: string[];
  checkboxes: string[];
  outageDate?: Date;
};

type INavigationProps = {
  handleFetch: (params: IORGDataTableQueryState) => void;
  outageDate?: Date;
};

const OMSRequestDateNavigation: React.FC<INavigationProps> = ({
  handleFetch,
  outageDate,
}) => {
  const { handleChange: handleOutageDate } = useLocationParams(['outageDates']);
  const [date, setDate] = useState(outageDate);
  return (
    <div style={{ display: 'inline-flex', marginBottom: '0.5rem' }}>
      <ATMButton
        secondary
        icon="left angle"
        content="Previous Day"
        onClick={() => {
          handleOutageDate();
          handleFetch({
            page: 1,
            limit: 0,
            date: Moment(date).subtract(1, 'day').toDate(),
            action: 'getOmsRequests',
            filters: [
              {
                name: 'date',
                value: Moment(date).subtract(1, 'day').toDate(),
              },
            ],
          });
          setDate(Moment(date).subtract(1, 'day').toDate());
        }}
      />

      <ATMDatePicker
        key={Moment(date ?? outageDate).format('MM/DD/YYYY')}
        format="MM/DD/YYYY"
        placeholder={Moment(date ?? outageDate).format('MM/DD/YYYY')}
        pointing="left"
        className={styles.placeholder}
        onChange={(_, { value }) => {
          handleOutageDate();
          if (value) {
            handleFetch({
              page: 1,
              limit: 0,
              date: value,
              action: 'getOmsRequests',
              filters: [
                {
                  name: 'date',
                  value,
                },
              ],
            });
            setDate(Moment(value.toString()).toDate());
          } else {
            handleFetch({
              page: 1,
              limit: 0,
              date: outageDate,
              action: 'getOmsRequests',
              filters: [
                {
                  name: 'date',
                  value: outageDate,
                },
              ],
            });
            setDate(outageDate);
          }
          return value;
        }}
      />
      <ATMButton
        secondary
        icon="right angle"
        content="Next Day"
        className={styles.nextBtn}
        onClick={() => {
          handleOutageDate();
          handleFetch({
            page: 1,
            limit: 0,
            date: Moment(date).add(1, 'day').toDate(),
            action: 'getOmsRequests',
            filters: [
              {
                name: 'date',
                value: Moment(date).add(1, 'day').toDate(),
              },
            ],
          });
          setDate(Moment(date).add(1, 'day').toDate());
        }}
      />
    </div>
  );
};

const ComparisonRow: React.FC<{
  title: string;
  field: keyof IOmsData['lerComparator'];
  data: IOmsData;
  when?: boolean;
  render?: (field: string, type: string, value: any) => React.ReactNode;
}> = ({ title, field, data, render, when }) => {
  const isNotEqual =
    typeof when === 'boolean'
      ? when
      : !isEqual(
          typeof data.lerComparator[field] === 'string'
            ? (data.lerComparator[field] as string).replace(/\r?\n|\r|\s/g, '')
            : data.lerComparator[field],
          typeof data.omsComparator[field] === 'string'
            ? (data.omsComparator[field] as string).replace(/\r?\n|\r|\s/g, '')
            : data.omsComparator[field]
        );

  return (
    <tr>
      <td>
        <strong>{title}</strong>
      </td>
      {['lerComparator', 'omsComparator'].map((item) => (
        <td key={`${item}_${field}`}>
          <p
            className={classNames(styles.wrapText, {
              [styles.notEqual]: isNotEqual,
            })}
          >
            {render
              ? render(field, item, data[item][field])
              : data[item][field]?.toString() || '--'}
          </p>
        </td>
      ))}
    </tr>
  );
};

const OmsRequestListView: React.FC<IProps> = ({
  data,
  isOpen,
  loading,
  handleFetch,
  handleCheckbox,
  setIsOpen,
  handleAcknowledge,
  acknowledging,
  hasError,
  checkboxes,
  outageDate = new Date(),
}) => {
  const { getUrl } = useLocationParams(['lerEdit', 'lerView']);
  const { handleChange: handleTabChange } = useLocationParams([
    'action',
    'omsId',
  ]);
  const ExpandableContentData = (expandableData: IOmsData) => {
    const dataComparisons = () => {
      return (
        <>
          <ComparisonRow
            title={Lang.LBL_PLANNED_DATE_TIME}
            field="startTm"
            data={expandableData}
            when={
              `${
                formatDateHour(expandableData.lerComparator.startTm) ?? '--'
              } - ${
                formatDateHour(expandableData.lerComparator.stopTm) ?? '--'
              }` !==
              `${
                formatDateHour(expandableData.omsComparator.startTm) ?? '--'
              } - ${
                formatDateHour(expandableData.omsComparator.stopTm) ?? '--'
              }`
            }
            render={(_, type) => (
              <span>{`${
                formatDateHour(expandableData[type].startTm) ?? '--'
              } - ${
                formatDateHour(expandableData[type].stopTm) ?? '--'
              }`}</span>
            )}
          />

          <ComparisonRow
            title={Lang.LBL_TRANSMISSION_REGISTRY_IDS}
            field="transmissionRegistryIds"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_NATURE_OF_WORK}
            field="natureOfWork"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_EMERGENCY_RETURN}
            field="emergencyReturnTime"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_SHORT_DESCRIPTION}
            field="shortDescription"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_JOB_INFO_NOTES}
            field="notes"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_OC_LER_ISO_USE_FACILITY_MODEL}
            field="useFacilityModel"
            data={expandableData}
          />

          <ComparisonRow
            title={Lang.LBL_RAS_SPS_OOS}
            field="rasspsOutOfService"
            data={expandableData}
            when={
              !expandableData.lerComparator.rasspsOutOfService?.toString() &&
              !expandableData.omsComparator.rasspsOutOfService?.toString() &&
              expandableData.lerComparator.rasspsOutOfService?.toString() !==
                expandableData.omsComparator.rasspsOutOfService?.toString()
            }
          />

          <ComparisonRow
            title={Lang.LBL_RAS_SPS_RR}
            field="rasspsReducedRedundancy"
            data={expandableData}
            when={
              !expandableData.lerComparator.rasspsReducedRedundancy?.toString() &&
              !expandableData.omsComparator.rasspsReducedRedundancy?.toString() &&
              expandableData.lerComparator.rasspsReducedRedundancy?.toString() !==
                expandableData.omsComparator.rasspsReducedRedundancy?.toString()
            }
          />

          <ComparisonRow
            title={Lang.LBL_PROTECTION_ZONE}
            field="protectionZone"
            data={expandableData}
            when={
              !expandableData.lerComparator.protectionZone?.toString() &&
              !expandableData.omsComparator.protectionZone?.toString() &&
              expandableData.lerComparator.protectionZone?.toString() !==
                expandableData.omsComparator.protectionZone?.toString()
            }
          />
        </>
      );
    };
    return (
      <ATMGrid>
        <ATMGrid.Row
          style={
            expandableData.outageWarnings.length > 0
              ? { paddingBottom: '0px' }
              : undefined
          }
        >
          <ATMGrid.Column>
            <table className={styles.table}>
              <colgroup>
                <col width="20%" />
                <col width="40%" />
                <col width="40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                          borderRight: '1px solid gray',
                          paddingRight: '0.5rem',
                        }}
                      >
                        {`${Lang.LBL_LER} ${expandableData.lerId}`}
                      </h4>{' '}
                      <ATMButton
                        as={Link}
                        icon="edit"
                        content="Edit"
                        noBorder
                        style={{ marginLeft: '0.5rem' }}
                        to={getUrl({
                          lerEdit: expandableData.lerId || 0,
                        })}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                          borderRight: '1px solid gray',
                          paddingRight: '0.5rem',
                        }}
                      >
                        {`${Lang.LBL_OMS_OUTAGE} (#${
                          expandableData.omsId ?? '--'
                        } / ${expandableData.omsStatus ?? '--'})`}
                      </h4>{' '}
                      <ATMButton
                        icon="edit"
                        content="Edit"
                        noBorder
                        style={{ marginLeft: '0.5rem' }}
                        onClick={() => {
                          handleTabChange({
                            action: 'edit',
                            omsId: expandableData.omsId,
                          });
                        }}
                        disabled={!expandableData.omsId}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>{dataComparisons()}</tbody>
            </table>
          </ATMGrid.Column>
        </ATMGrid.Row>
        {expandableData.outageWarnings &&
        expandableData.outageWarnings.length > 0 ? (
          <ATMGrid.Row className={styles.outageWarnings}>
            <ATMGrid.Column>
              <div className={styles.outageMessage}>
                <ATMHeader as="h2">{Lang.LBL_OUTAGE_WARNINGS}</ATMHeader>
                <ATMTable>
                  <ATMTable.Header>
                    <ATMTable.HeaderCell width={2}>
                      {Lang.LBL_WARNING_ID}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell width={2}>
                      {Lang.LBL_WARNING_TYPE}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell width={8}>
                      {Lang.LBL_WARNING_TEXT}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell width={3}>
                      {Lang.LBL_ACTION}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell width={1} />
                  </ATMTable.Header>
                  <ATMTable.Body>
                    {expandableData.outageWarnings.map((item) => (
                      <>
                        <ATMTable.Cell
                          className={
                            hasError.includes(item.mRID ?? '')
                              ? styles.warningError
                              : ''
                          }
                        >
                          {item.mRID}
                        </ATMTable.Cell>
                        <ATMTable.Cell
                          className={
                            hasError.includes(item.mRID ?? '')
                              ? styles.warningError
                              : ''
                          }
                        >
                          {item.type}
                        </ATMTable.Cell>
                        <ATMTable.Cell
                          className={
                            hasError.includes(item.mRID ?? '')
                              ? styles.warningError
                              : ''
                          }
                        >
                          {item.description}
                        </ATMTable.Cell>
                        <ATMTable.Cell
                          className={
                            hasError.includes(item.mRID ?? '')
                              ? styles.warningError
                              : ''
                          }
                        >
                          <a
                            role="button"
                            onClick={() => handleAcknowledge(Number(item.mRID))}
                          >
                            {acknowledging ? (
                              <ATMLoader active inline size="small" />
                            ) : (
                              <strong>{Lang.LBL_ACKNOWLEDGE_WARNING}</strong>
                            )}
                          </a>
                        </ATMTable.Cell>
                        {/* <ATMTable.Cell className={hasError.includes(item.mRID ?? '') ? styles.warningError : ''}>
                          <ATMPopover
                            trigger={
                              <ATMIcon size="large" name="exclamation triangle" color="red" />
                            }
                            content={errorMsg.filter((value) => value.includes(item.mRID ?? ''))[0]}
                            position="left center"
                            className={styles.warningErrorPop}
                          />
                        </ATMTable.Cell> */}
                      </>
                    ))}
                  </ATMTable.Body>
                </ATMTable>
              </div>
            </ATMGrid.Column>
          </ATMGrid.Row>
        ) : null}
      </ATMGrid>
    );
  };

  const columns: IORGDataTableColumn<IOmsListPage>[] = [
    {
      index: 'lerId',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_LER}</td>
        </div>
      ),
      cellClass: (_, value) => {
        if (value.caisoResponse) {
          if (
            value.caisoResponse.filter(
              ({ Event }) => Event.result === 'Failure'
            ) &&
            value.caisoResponse.filter(
              ({ Event }) => Event.result === 'Failure'
            ).length > 0
          ) {
            return classNames(styles.cellError, styles.firstChild);
          }

          return classNames(styles.cellSuccess, styles.firstChild);
        }
        return classNames();
      },
      render: (value) => {
        return (
          <Link
            className={styles.underlined}
            to={getUrl({
              lerEdit: value || 0,
            })}
          >
            {value}
          </Link>
        );
      },
    },
    {
      index: 'kv',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_KV}</td>
        </div>
      ),
      width: '15px',
      cellProps: {
        textAlign: 'left',
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return <p> {value ?? '--'} </p>;
      },
    },
    {
      index: 'facilityName',
      title: (
        <div style={{ display: 'block' }}>
          <p
            style={{ textAlign: 'right', margin: '0 0 1rem 0', height: '2rem' }}
          >
            {' '}
            {Lang.LBL_LER}{' '}
          </p>
          <td>{Lang.LBL_FACILITY_NAME}</td>
        </div>
      ),
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return <p> {value ?? '--'} </p>;
      },
    },
    {
      index: 'startTm',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_START_TIME}</td>
        </div>
      ),
      headerProps: {
        textAlign: 'right',
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return <p>{formatDate(value) ?? '--'}</p>;
      },
    },
    {
      index: 'stopTm',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_STOP_TIME}</td>
        </div>
      ),
      cellProps: {
        textAlign: 'left',
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return <p>{formatDate(value) ?? '--'}</p>;
      },
    },
    {
      index: 'lerStatus',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_LER_STATUS}</td>
        </div>
      ),
      width: '60px',
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return LERRequestStatus[value] ? (
          <ATMLabel className={styles.greenLabel}>
            {LERRequestStatus[value]}
          </ATMLabel>
        ) : (
          '--'
        );
      },
    },
    {
      index: 'omsStatus',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_OMS_STATUS}</td>
        </div>
      ),
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError, styles.borderLeft);
        }
        return classNames(styles.borderLeft);
      },
      headerProps: {
        className: styles.borderLeft,
      },
      render: (_, value) => {
        return value.omsStatus ? (
          <ATMLabel className={styles.greenLabel}>
            {capitalize(value.omsStatus)}
          </ATMLabel>
        ) : (
          '--'
        );
      },
    },
    {
      index: 'omsId',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <p>{Lang.LBL_OUTAGE_ID}</p>
        </div>
      ),
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (_, value) => {
        return value.omsId ? (
          <a
            className={styles.popLink}
            role="button"
            onClick={() => {
              handleTabChange({ action: 'edit', omsId: value.omsId });
            }}
          >
            {value.omsId}
          </a>
        ) : (
          '--'
        );
      },
    },
    {
      index: 'farFan',
      title: (
        <div style={{ display: 'block' }}>
          <p
            style={{ textAlign: 'left', margin: '0 0 1rem 0', height: '2rem' }}
          >
            {' '}
            {Lang.LBL_OMS}{' '}
          </p>
          <td>{Lang.LBL_FARFAN}</td>
        </div>
      ),
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (value) => {
        return <p> {value ?? '--'} </p>;
      },
    },
    {
      index: 'reconcileStatus',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_RECONCILE_STATUS}</td>
        </div>
      ),
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (_, value, __, { isExpand, setIsExpand }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isExpand ? (
            <>
              <span
                style={{
                  width: '40%',
                  textAlign: 'right',
                  marginRight: '0.5rem',
                }}
              >
                {value.reconcileStatus.isOutageWarning && (
                  <ATMPopover
                    trigger={
                      <ATMIcon size="large" name="info circle" color="yellow" />
                    }
                    content="Outage Warnings"
                    position="top center"
                  />
                )}
                {value.reconcileStatus.isLerOmsMismatch && (
                  <ATMPopover
                    trigger={
                      <ATMIcon size="large" name="times circle" color="red" />
                    }
                    content="OMS & LER Mismatch"
                    position="top center"
                  />
                )}
                {value.reconcileStatus.isChangeRequestPending && (
                  <ATMPopover
                    trigger={<ATMIcon size="large" name="clock" color="blue" />}
                    content="Change Request Pending"
                    position="top center"
                  />
                )}
              </span>
              <span style={{ color: '#0E92CCCC' }}>
                {Lang.LBL_DETAILS}{' '}
                <ATMButton
                  icon="angle down"
                  className={styles.iconBlue}
                  onClick={() => setIsExpand(!isExpand)}
                />
              </span>
            </>
          ) : (
            <>
              <span
                style={{
                  width: '40%',
                  textAlign: 'right',
                  marginRight: '0.5rem',
                }}
              >
                {value.reconcileStatus.isOutageWarning && (
                  <ATMPopover
                    trigger={
                      <ATMIcon size="large" name="info circle" color="yellow" />
                    }
                    content="Outage Warnings"
                    position="top center"
                  />
                )}
                {value.reconcileStatus.isLerOmsMismatch && (
                  <ATMPopover
                    trigger={
                      <ATMIcon size="large" name="times circle" color="red" />
                    }
                    content="OMS & LER Mismatch"
                    position="top center"
                  />
                )}
                {value.reconcileStatus.isChangeRequestPending && (
                  <ATMPopover
                    trigger={<ATMIcon size="large" name="clock" color="blue" />}
                    content="Change Request Pending"
                    position="top center"
                  />
                )}
              </span>
              <span style={{ color: '#0E92CCCC' }}>
                {Lang.LBL_DETAILS}{' '}
                <ATMButton
                  icon="angle up"
                  className={styles.iconBlue}
                  onClick={() => setIsExpand(!isExpand)}
                />
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      index: 'action2',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_SEND}</td>
        </div>
      ),
      headerProps: {
        textAlign: 'center',
        className: styles.borderLeft,
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError, styles.borderLeft);
        }
        return classNames(styles.borderLeft);
      },
      render: (_, _value) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ATMCheckbox
            disabled={!_value.allowedActions.send}
            checked={checkboxes.includes(
              `chk_${OmsActions.SEND}_${_value.lerId}_${_value.startTm}_${_value.stopTm}`
            )}
            style={{ margin: 'auto 0.5rem' }}
            onChange={(__, { checked }) => {
              handleCheckbox(
                `chk_${OmsActions.SEND}_${_value.lerId}_${_value.startTm}_${_value.stopTm}`,
                checked
              );
              return checked;
            }}
          />
        </div>
      ),
    },
    {
      index: 'action3',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_CNCL}</td>
        </div>
      ),
      headerProps: {
        textAlign: 'center',
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (_, _value) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ATMCheckbox
            checked={checkboxes.includes(
              `chk_${OmsActions.CANCEL}_${_value.omsId}_${_value.startTm}_${_value.stopTm}`
            )}
            disabled={!_value.allowedActions.cancel}
            style={{ margin: 'auto 0.5rem' }}
            onChange={(__, { checked }) => {
              handleCheckbox(
                `chk_${OmsActions.CANCEL}_${_value.omsId}_${_value.startTm}_${_value.stopTm}`,
                checked
              );
              return checked;
            }}
          />
        </div>
      ),
    },
    {
      index: 'action4',
      title: (
        <div style={{ display: 'block' }}>
          <p style={{ margin: '0 0 1rem 0', height: '2rem' }}> </p>
          <td>{Lang.LBL_RSUB}</td>
        </div>
      ),
      headerProps: {
        textAlign: 'center',
      },
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError);
        }
        return classNames();
      },
      render: (_, _value) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ATMCheckbox
            disabled={!_value.allowedActions.resubmit}
            checked={checkboxes.includes(
              `chk_${OmsActions.RESUBMIT}_${_value.lerId}_${_value.startTm}_${_value.stopTm}`
            )}
            style={{ margin: 'auto 0.5rem' }}
            onChange={(__, { checked }) => {
              handleCheckbox(
                `chk_${OmsActions.RESUBMIT}_${_value.lerId}_${_value.startTm}_${_value.stopTm}`,
                checked
              );
              return checked;
            }}
          />
        </div>
      ),
    },
    {
      index: 'action5',
      title: '',
      cellClass: (_, value) => {
        if (
          value.caisoResponse?.filter(
            ({ Event }) => Event.result === 'Failure'
          ) &&
          value.caisoResponse?.filter(({ Event }) => Event.result === 'Failure')
            .length > 0
        ) {
          return classNames(styles.cellError, styles.borderLeft);
        }
        return classNames(styles.borderLeft);
      },
      headerProps: {
        className: styles.borderLeft,
      },
      render: (_, _value) => {
        const rowName = `${_value.lerId}_${_value.omsId ?? '--'}`;
        return _value.caisoResponse ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {_value.caisoResponse?.filter(
              ({ Event }) => Event.result === 'Failure'
            ) &&
            _value.caisoResponse?.filter(
              ({ Event }) => Event.result === 'Failure'
            ).length > 0 ? (
              <ATMModal
                open={isOpen === rowName}
                trigger={
                  <a role="button" onClick={() => setIsOpen(rowName)}>
                    <ATMIcon
                      size="large"
                      name="exclamation triangle"
                      color="red"
                    />
                  </a>
                }
              >
                <ATMModal.Header>{Lang.LBL_ERROR_STATUS}</ATMModal.Header>
                <ATMModal.Content scrolling>
                  <strong>{Lang.LBL_OVERALL_SUBMITTAL_STATUS_FAILURE}</strong>
                  <br />
                  <br />
                  {Array.from(
                    new Set(
                      flattenDeep(
                        _value.caisoResponse
                          ?.filter(({ Event }) => Event.result === 'Failure')
                          .map((val) =>
                            val.Outage.OutageValidation.OutageResult?.map(
                              ({ description }) => description
                            )
                          )
                      )
                    )
                  ).map((val, index) => (
                    <div key={index}>
                      {Lang.formatString(
                        Lang.MSG_OMS_CAISO_RESPONSE_STATUS,
                        String(index + 1),
                        String(_value.lerId),
                        val ?? '--'
                      )}
                    </div>
                  ))}
                </ATMModal.Content>
                <ATMModal.Actions textAlign="right">
                  <ATMButton
                    secondary
                    content={Lang.LBL_CLOSE}
                    onClick={() => setIsOpen('')}
                  />
                </ATMModal.Actions>
              </ATMModal>
            ) : (
              <ATMModal
                open={isOpen === rowName}
                trigger={
                  <a role="button" onClick={() => setIsOpen(rowName)}>
                    <ATMIcon size="large" name="check" color="green" />
                  </a>
                }
              >
                <ATMModal.Header>{Lang.LBL_SUCCESS_STATUS}</ATMModal.Header>
                <ATMModal.Content>
                  <strong>{Lang.LBL_OVERALL_SUBMITTAL_STATUS_SUCCESS}</strong>
                  <br />
                  <br />
                  {Array.from(
                    new Set(
                      flattenDeep(
                        _value.caisoResponse
                          ?.filter(({ Event }) => Event.result === 'Success')
                          .map((val) =>
                            val.Outage.OutageValidation.OutageResult?.map(
                              ({ description }) => description
                            )
                          )
                      )
                    )
                  ).map((val, index) => (
                    <div key={index}>
                      {Lang.formatString(
                        Lang.MSG_OMS_CAISO_RESPONSE_STATUS,
                        String(index + 1),
                        String(_value.lerId),
                        val ?? '--'
                      )}
                    </div>
                  ))}
                </ATMModal.Content>
                <ATMModal.Actions textAlign="right">
                  <ATMButton
                    secondary
                    content={Lang.LBL_CLOSE}
                    onClick={() => setIsOpen('')}
                  />
                </ATMModal.Actions>
              </ATMModal>
            )}
          </div>
        ) : (
          ''
        );
      },
    },
  ];

  useEffect(() => {
    handleFetch({
      page: 1,
      limit: 0,
      date: outageDate,
      action: 'getOmsRequests',
      filters: [
        {
          name: 'date',
          value: outageDate,
        },
      ],
    });
  }, []);

  return (
    <>
      <OmsRequestEdit />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <ATMIcon size="large" name="times circle" color="red" />
            <>OMS & LER Mismatch&nbsp;&nbsp;</>
            <ATMIcon size="large" name="clock" color="blue" />
            <>Change Request Pending&nbsp;&nbsp;</>
            <ATMIcon size="large" name="info circle" color="yellow" />
            <>Outage Warnings</>
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right">
            <OMSRequestDateNavigation
              outageDate={outageDate}
              handleFetch={handleFetch}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
      <ORGDataTable
        counter
        data={data}
        celled={false}
        columns={columns}
        loading={loading}
        // onChange={handleFetch}
        defaultRowsPerPage={100}
        addParams={['date', 'action']}
        expandableRowsComponent={ExpandableContentData}
      />
    </>
  );
};

export default OmsRequestListView;
