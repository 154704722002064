import React from 'react';
import { Link } from 'react-router-dom';
import {
  ORGDataTable,
  IORGDataTableColumn,
  MOLNoData,
  ATMIcon,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { IOmsAuditReportItem } from 'src/models/oms-audit-report.model';
import OMSAuditFilters from './oms-audit-filters/oms-audit-filters.component';
import styles from './oms-audit.module.scss';

type IProps = {
  data: IOmsAuditReportItem[];
  loading: boolean;
  total: number;
  queryParams: Record<any, any>;
  handleQueryParams: (params: Record<any, any>) => void;
  handleDownload: () => void;
  handleSearch: () => void;
  handleFetch: (params: IORGDataTableQueryState) => void;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const OMSAuditView: React.FC<IProps> = ({
  data,
  loading,
  total,
  queryParams,
  handleQueryParams,
  handleDownload,
  handleSearch,
  handleFetch,
  showNoRecord,
  setShowNoRecord,
}) => {
  const { getUrl } = useLocationParams(['lerEdit']);
  const customColumns: IORGDataTableColumn<IOmsAuditReportItem>[] = [
    {
      title: Lang.LBL_UPDATE_DATE,
      index: 'updatedAt',
      render: (_, value) =>
        Moment(value.updatedAt).format('M/DD/YYYY HH:mm:ss'),
    },
    {
      title: Lang.LBL_UPDATER,
      index: 'updatedByFullName',
    },
    {
      title: Lang.LBL_LER,
      index: 'requestId',
      render: (_, value) => (
        <Link to={getUrl({ lerEdit: value.requestId })}>{value.requestId}</Link>
      ),
    },
    {
      title: Lang.LBL_OMS_OUTAGE_ID,
      index: 'omsOutageId',
      render: (_, value) => value.omsOutageId ?? '',
    },
    {
      title: Lang.LBL_EVENT,
      index: 'auditEventDesc',
    },
    {
      title: Lang.LBL_EVENT_VALUE,
      index: 'parsedAuditValue',
    },
  ];

  return (
    <div>
      <OMSAuditFilters
        queryParams={queryParams}
        total={total}
        loading={loading}
        handleQueryParams={handleQueryParams}
        handleSearch={handleSearch}
        handleDownload={handleDownload}
        setShowNoRecord={setShowNoRecord}
      />
      <ORGDataTable
        data={data}
        columns={customColumns}
        celled={false}
        loading={loading}
        total={total}
        onChange={handleFetch}
        noDataText={
          !showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={styles.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'bottom',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      />
    </div>
  );
};

export default OMSAuditView;
