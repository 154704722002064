import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import {
  IContractorFirm,
  IContractorFirmForm,
} from 'src/models/contractor-firm.model';
import ContractorFirmForm from '../contractor-firm-form/contractor-firm-form.component';
import style from '../contractor-firm.module.scss';

type IProps = {
  defaultValues: IContractorFirm;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IContractorFirmForm) => void;
};

const ContractorFirmEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          icon="edit"
          content="Edit"
          className={style.manageDropdown}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>Edit Contractor Firm</ATMModal.Header>
      <ATMModal.Content scrolling>
        <ContractorFirmForm
          defaultValues={defaultValues}
          formRef={formRef}
          handleSubmit={handleSubmit}
          isEdit
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default ContractorFirmEditView;
