import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useSubstationCountListContext } from 'src/contexts/substation-count-list.context';
import { substationCountListActionTypes } from 'src/ducks/substation-count-list.duck';
import Lang from 'src/libraries/language';
import { ISubstationCountListFormArray } from 'src/models/substation-count-list.model';
import { getSubstationCountListStatus } from 'src/selectors/substation-count-list.selector';
import SubstationCountUploadView from './substation-count-upload.view';

type IProp = {
  key?: string;
};

const SubstationCountUpload: React.FC<IProp> = ({ key }) => {
  const { state, actions } = useSubstationCountListContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const loading = getSubstationCountListStatus(
    state,
    substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_UPDATE
  ).fetching;

  useEffect(() => {
    substationActions.listGET({ limit: 0 });
  }, [substationActions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ISubstationCountListFormArray) => {
      const response = await actions.updatePUT(formData.substationCounts);
      if (!response.error) {
        ToastSuccess(Lang.MSG_BATCH_UPLOAD_SUCCESS);
        actions.listGET();
        setIsEditOpen(false);
      } else {
        ToastError(response.error);
      }
    },
    [actions]
  );

  return (
    <SubstationCountUploadView
      key={key}
      formRef={formRef}
      loading={loading}
      isOpen={isEditOpen}
      isEnabled={isEnabled}
      fileErrors={fileErrors}
      handleClick={handleClick}
      handleOpen={setIsEditOpen}
      handleSubmit={handleSubmit}
      handleErrors={setFileErrors}
      handleEnabled={setIsEnabled}
      substations={substationState.list}
    />
  );
};

export default SubstationCountUpload;
