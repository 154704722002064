import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/iso-cause.service';
import { IIsoCause } from 'src/models/iso-cause.model';
import { getIsoCauseByLevel } from 'src/selectors/iso-cause.selector';
import { IsoCauseLevel } from 'src/constants';

export const isoCauseActionTypes = {
  ISO_CAUSE_DATA_READ: 'ISO_CAUSE_DATA_READ',
  ISO_CAUSE_LIST_READ: 'ISO_CAUSE_LIST_READ',
  ISO_CAUSE_DATA_CREATE: 'ISO_CAUSE_DATA_CREATE',
  ISO_CAUSE_DATA_UPDATE: 'ISO_CAUSE_DATA_UPDATE',
  ISO_CAUSE_DATA_DELETE: 'ISO_CAUSE_DATA_DELETE',
  ISO_CAUSE_DATA_SET: 'ISO_CAUSE_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: isoCauseActionTypes.ISO_CAUSE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: isoCauseActionTypes.ISO_CAUSE_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: isoCauseActionTypes.ISO_CAUSE_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: isoCauseActionTypes.ISO_CAUSE_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: isoCauseActionTypes.ISO_CAUSE_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (isoCause: IIsoCause) => ({
    type: isoCauseActionTypes.ISO_CAUSE_DATA_SET,
    payload: {
      isoCause,
    },
  }),
};

export type IIsoCauseAsync = typeof duckActions;

export interface IIsoCauseState
  extends ICommonState<typeof isoCauseActionTypes> {
  data?: IIsoCause;
  primaryList: IIsoCause[];
  secondaryList: IIsoCause[];
  total: number;
}

export const defaultState: IIsoCauseState = {
  status: {},
  primaryList: [],
  secondaryList: [],
  total: 0,
};

const IsoCauseReducer = (
  state: IIsoCauseState,
  action: IReducerAction<IIsoCauseAsync>
): IIsoCauseState => {
  switch (action.type) {
    case isoCauseActionTypes.ISO_CAUSE_DATA_SET:
    case isoCauseActionTypes.ISO_CAUSE_DATA_READ:
    case isoCauseActionTypes.ISO_CAUSE_DATA_UPDATE:
    case isoCauseActionTypes.ISO_CAUSE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.isoCause,
      };
    }

    case isoCauseActionTypes.ISO_CAUSE_LIST_READ: {
      return {
        ...state,
        primaryList: getIsoCauseByLevel(
          action.payload?.rows ?? [],
          IsoCauseLevel.Primary
        ),
        secondaryList: getIsoCauseByLevel(
          action.payload?.rows ?? [],
          IsoCauseLevel.Secondary
        ),
      };
    }

    case isoCauseActionTypes.ISO_CAUSE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const primaryList = state.primaryList.filter(
          (value) => value.isoCauseCode !== id
        );

        return {
          ...state,
          data: undefined,
          primaryList,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default IsoCauseReducer;
