import yup from 'src/libraries/validator.library';
import { LerRequestOutageDateSchema } from './ler-request.model';
import { NoteSchema } from './note.model';
import { RequestStatusSchema } from './request-status.model';
import { SubstationSchema } from './substation.model';

export const SystemChangeShape = {
  requestId: yup.number().required(),
  sysChngInd: yup.boolean().nullable(),
  requestStat: yup.number().nullable(),
  sysChngSummaryId: yup.number().nullable(),
  wrkDesc: yup.string().nullable(),
  requestStatus: RequestStatusSchema.clone(),
  outageFacility: yup
    .object({
      facTypId: yup.number(),
      outgFacId: yup.number().required(),
      outageType: yup
        .object({
          outageTypeDesc: yup.string().nullable(),
          outageTypeId: yup.number().nullable(),
        })
        .nullable(),
      facility: yup
        .object({
          outgFacId: yup.number().nullable(),
          facTypId: yup.number().nullable(),
          outgFacNm: yup.string().nullable(),
          facTyp: yup
            .object({
              facTypId: yup.number().nullable(),
              facTypNm: yup.string().nullable(),
            })
            .nullable(),
        })
        .nullable(),
      substationId: yup.string().nullable(),
      substation: SubstationSchema.nullable(),
      voltId: yup.number().nullable(),
      equipmentId: yup.number().nullable(),
      outgTypId: yup.number().required(),
    })
    .nullable(),
  sysChngSummary: NoteSchema.optionalContent(),
  outageDates: yup.array().of(LerRequestOutageDateSchema.clone()).required(),
  inServiceDate: yup.date(),
  startDate: yup.date(),
  completedBy: yup.string().nullable(),
  completedOn: yup.date().nullable(),
  completionSetBy: yup.string().nullable(),
  isComplete: yup.boolean().nullable(),
};

export const SystemChangeCompleteFormShape = {
  completedOn: yup.date().nullable(),
  completionSetBy: yup.string().nullable(),
  isComplete: yup.boolean().nullable(),
};

export const SystemChangeSchema = yup.object(SystemChangeShape);
export const SystemChangeCompleteFormSchema = yup.object(
  SystemChangeCompleteFormShape
);

export const SystemChangeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SystemChangeSchema.clone()),
});

export const SystemChangePayloadSchema = yup.object({
  systemChange: SystemChangeSchema.clone(),
});

export const SystemChangeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IsystemChangeCompleteForm = yup.Asserts<
  typeof SystemChangeCompleteFormSchema
>;

export type ISystemChange = yup.Asserts<typeof SystemChangeSchema>;
