import React, { useEffect, useState } from 'react';
import {
  IORGDataTableColumn,
  IORGDataTableQueryState,
  MOLNoData,
  ORGDataTable,
  MOLInfoText,
  ATMIcon,
  ATMButton,
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  IORGDataTableUpdateOptions,
  ATMPopover,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import { orderBy } from 'lodash';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import history from 'src/history';
import styles from '../../trouble-ticket.module.scss';
import CommentsModal from '../../trouble-ticket-comments-modal/trouble-ticket-comments-modal.component';
import TroubleTicketCard from '../../trouble-ticket-card/trouble-ticket-card.component';
import TroubleTicketListSearchFilter from '../trouble-ticket-list-search-filter.component';

type IProps = {
  data: ITroubleTicketList[];
  loading: boolean;
  handleCleanData: () => void;
  handleFetch: (params: IORGDataTableQueryState) => void;
  setDataEdit: React.Dispatch<ITroubleTicketList>;
  setView: React.Dispatch<any>;
  total: number;
  troubleTotal: number;
  scheduledTotal: number;
  currentTab: number;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleSubmit: (data: any) => void;
  handleSearch: (
    params: IORGDataTableQueryState,
    options: IORGDataTableUpdateOptions,
    searchTicketId?: boolean
  ) => void;
  handleDownload: (data: any) => void;
  completeWorkList: number[];
  workGroups: number[];
  setWorkGroups: React.Dispatch<React.SetStateAction<number[]>>;
  setDateState: React.Dispatch<any>;
  dateState: any;
  showNotRecord: boolean;
  setShowNotRecord: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAll: boolean;
  setState: (param: any) => void;
  state: any;
  counter: number;
  setIsFirstLoad: (param: boolean) => void;
  hasDateRange: boolean | undefined;
};

const TroubleTicketListAllView: React.FC<IProps> = ({
  data,
  loading,
  handleDownload,
  handleFetch,
  handleCleanData,
  setIsFirstLoad,
  currentTab,
  showNotRecord,
  counter,
  total,
  hasDateRange,
}) => {
  const [isCommentsOpen, setIsCommentsOpen] = useState<number>();
  const [lineSubstation, setIsLineSubstation] = useState<string>();
  const { state: troubleTicketState } = useTroubleTicketContext();
  const { state: workGroupState } = useWorkGroupContext();

  const downloadStatus = getTroubleTicketStatus(
    troubleTicketState,
    troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ
  );

  const searchStatus = getTroubleTicketStatus(
    troubleTicketState,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_READ
  );

  const statusDropdown = 'All Statuses';
  const ticketTypeDropdown = 'All Ticket Type';
  const ticketTypeDropdownKey = 'All_Ticket_Type';

  useEffect(() => {
    handleCleanData();
  }, []);

  const statusOption = Object.keys(Lang.TROUBLE_TICKET_STATUS).map((val) => {
    return {
      text: Lang.TROUBLE_TICKET_STATUS[val],
      value: val,
      key: val,
    };
  });

  const ticketTypeOption = Object.keys(Lang.TROUBLE_TICKET_TYPE).map((val) => {
    return {
      text: Lang.TROUBLE_TICKET_TYPE[val],
      value: val,
      key: val,
    };
  });

  const allColumns: IORGDataTableColumn<ITroubleTicketList>[] = [
    {
      index: 'troubleTicketId',
      title: 'Ticket ID/Desc',
      width: '200px',
      render: (_, value) => (
        <>
          <TroubleTicketCard troubleTicket={value} fromSearch />
          <span className={styles.prioMargin}>
            <ListStatus value={value.ticketPrio} />
          </span>
          <MOLInfoText transparent limit={50} content={value.ticketDesc} />
        </>
      ),
    },
    {
      index: 'requestTyp',
      title: 'Ticket Type',
      render: (_, value) => value.requestTyp,
    },
    {
      index: 'ticketStatus',
      title: 'Status',
      render: (_, value) => <ListStatus value={value.ticketStatus} label />,
    },
    {
      index: 'beginDt',
      title: 'Begin Date',
      render: (_, value) => formatDate(value.beginDt),
    },
    {
      index: 'dueDt',
      title: 'Due Date',
      render: (_, value) => formatDate(value.dueDt),
    },
    {
      index: 'closedDt',
      title: 'Closed Date',
      render: (_, value) => formatDate(value.closedDt),
    },
    {
      index: 'comments',
      title: 'Comments and Resolution',
      width: '180px',
      sortable: false,
      render: (_, value) => {
        if (value.comments) {
          if (value.comments.length < 2) {
            return (
              <MOLInfoText
                transparent
                limit={40}
                content={value.comments[0]?.noteTxt ?? ''}
              />
            );
          }
          if (value.comments.length < 3) {
            return (
              <MOLInfoText
                transparent
                limit={40}
                content={
                  `${value.comments[0]?.noteTxt} • ${value.comments[1]?.noteTxt}` ??
                  ''
                }
              />
            );
          }
          return (
            <>
              <MOLInfoText
                transparent
                limit={40}
                content={
                  `${value.comments[0]?.noteTxt} • ${value.comments[1]?.noteTxt}...` ??
                  ''
                }
              />
              <CommentsModal
                data={value}
                isOpen={isCommentsOpen === value.troubleTicketId}
                handleOpen={setIsCommentsOpen}
              />
            </>
          );
        }
        return '--';
      },
    },
    {
      index: 'workGroup.trbGrpNm',
      title: 'Work Group',
    },
    {
      index: 'facilitySubstation',
      title: 'Facility/Substation',
      sortable: false,
      render: (_, value) => {
        if (value.substationId && value.substation) {
          return value.substation.name;
        }
        if (value.outgFacId && value.line) {
          return value.line.outgFacNm;
        }
        return '--';
      },
    },
    {
      index: 'troubleEquipmentType.name',
      title: 'Equip Type',
    },
  ];

  return (
    <>
      <div className="admin-content">
        <ORGDataTable
          counter
          key={currentTab}
          celled={false}
          columns={allColumns}
          onChange={handleFetch}
          data={data}
          showPagination
          defaultRowsPerPage={100}
          rowsPerPageOptions={[50, 100, 500]}
          loading={loading || searchStatus.fetching}
          filteredBy
          sortable
          total={total}
          location={history.location}
          handleLocation={history.push}
          noDataText={
            !showNotRecord ? (
              <MOLNoData
                icon="search"
                header="Search to view records"
                subHeader="Please select or enter the search criteria and click on the search button to see the results."
              />
            ) : (
              <div className={styles.noData}>
                <p>
                  <ATMIcon
                    circular
                    color="grey"
                    inverted
                    name="info"
                    size="small"
                    style={{
                      display: 'inline-block',
                      marginRight: '0.8em',
                      verticalAlign: 'bottom',
                    }}
                  />
                  {Lang.MSG_EMPTY_FILTER}
                </p>
              </div>
            )
          }
        >
          {({ state: stateValue, setState: setStateValue }) => ({
            toolbars: {
              ticketStatus: ({ value, setValue }) => (
                <ATMDropdown
                  size="small"
                  name="ticketStatus"
                  selection
                  clearable
                  value={value}
                  placeholder="Status"
                  options={[
                    {
                      key: 'Both',
                      value: 'Both',
                      text: statusDropdown,
                    },
                    ...statusOption,
                  ]}
                  onChange={(_, val) => {
                    setValue(val.value);
                    setIsFirstLoad(false);
                    if (val.value === '' || null) {
                      setStateValue({
                        ...stateValue,
                        page: 1,
                        filters: [
                          ...(stateValue?.filters?.filter(
                            (el) => el.name !== 'ticketStatus'
                          ) ?? []),
                        ],
                      } as IORGDataTableQueryState);
                    } else {
                      setStateValue({
                        ...stateValue,
                        page: 1,
                        filters: [
                          ...(stateValue?.filters?.filter(
                            (el) => el.name !== 'ticketStatus'
                          ) ?? []),
                          {
                            name: 'ticketStatus',
                            value: val.value,
                          },
                        ],
                      } as IORGDataTableQueryState);
                    }
                    if (val.value === '' || null) {
                      return undefined;
                    }
                    return val.value;
                  }}
                  selectOnBlur={false}
                />
              ),
              requestTyp: ({ value, setValue }) => (
                <ATMField
                  as={ATMDropdown}
                  size="small"
                  name="requestTyp"
                  selection
                  clearable
                  value={value}
                  placeholder="Ticket Type"
                  options={[
                    {
                      key: ticketTypeDropdownKey,
                      value: 'Both',
                      text: ticketTypeDropdown,
                    },
                    ...ticketTypeOption,
                  ]}
                  onChange={(_, val) => {
                    setValue(val.value);
                    setIsFirstLoad(false);
                    return val.value;
                  }}
                  selectOnBlur={false}
                />
              ),
              workGroups: ({ value, setValue }) => {
                return (
                  <ATMField
                    as={ATMDropdown}
                    style={{ width: '100%' }}
                    size="small"
                    name="workGroups"
                    selection
                    clearable
                    search
                    multiple
                    placeholder="Work Group"
                    value={value ? [...value] : []}
                    options={orderBy(
                      workGroupState.list.map((val) => ({
                        key: val.trbGrpId.toString(),
                        value: val.trbGrpId.toString(),
                        text: val.trbGrpNm,
                        sorttext: val.trbGrpNm.toLowerCase(),
                      })),
                      ['sorttext'],
                      ['asc']
                    )}
                    onChange={(_, val) => {
                      setIsFirstLoad(false);
                      setValue(val.value);
                      return val.value;
                    }}
                    selectOnBlur={false}
                  />
                );
              },
              beginDt: ({ value, setValue }) => {
                return (
                  <span className={styles.dateRange}>
                    <ATMField
                      as={ATMDatePicker}
                      name="beginDt"
                      type="range"
                      size="small"
                      format="MM/DD/YYYY"
                      pointing="right"
                      placeholder="Begin Date Range"
                      value={
                        value && [
                          Moment(value[0]).startOf('day').toDate(),
                          Moment(value[1]).endOf('day').toDate(),
                        ]
                      }
                      onChange={(_, val) => {
                        setIsFirstLoad(false);
                        if (val.value === null) {
                          setValue(undefined);
                        } else if (
                          val.value &&
                          (val.value as Date[]).length === 2
                        ) {
                          const [start, end] = val.value as Date[];
                          const rangeValue = [
                            Moment(start).startOf('day').toDate(),
                            Moment(end).endOf('day').toDate(),
                          ];
                          setValue(rangeValue);
                        }
                        return val.value;
                      }}
                    />
                  </span>
                );
              },
              buttons: () =>
                !hasDateRange ? (
                  <ATMPopover
                    trigger={
                      <ATMButton
                        key="download"
                        secondary
                        size="tiny"
                        icon="download"
                      />
                    }
                    content={Lang.MSG_TROUBLE_TICKET_DOWNLOAD_NOTE}
                    position="top center"
                  />
                ) : (
                  <ATMPopover
                    position="bottom left"
                    content="Download"
                    on="hover"
                    trigger={
                      <ATMButton
                        key="download"
                        secondary
                        size="tiny"
                        icon="download"
                        loading={downloadStatus.fetching}
                        onClick={() => {
                          handleDownload({
                            ...stateValue,
                          });
                        }}
                      />
                    }
                  />
                ),
            },
            filters: ({ values, setValue }) => {
              return (
                <TroubleTicketListSearchFilter
                  lineSubstation={lineSubstation}
                  setIsLineSubstation={setIsLineSubstation}
                  setValue={setValue}
                  setIsFirstLoad={setIsFirstLoad}
                  values={values}
                  counter={counter}
                />
              );
            },
          })}
        </ORGDataTable>
      </div>
      {/* {showAll ? (
        <span
          className={showAll && total > 50 ? styles.showBtn : styles.hiddenEdit}
        >
          <ATMButton
            type="button"
            onClick={() => {
              handleFetch({
                ...(state as IORGDataTableQueryState),
                limit: 0,
              } as IORGDataTableQueryState);
              setShowAll(!showAll);
            }}
            content="Show All Entries"
          />
        </span>
      ) : (
        <span />
      )} */}
    </>
  );
};

export default TroubleTicketListAllView;
