import React from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';

type IProps = {
  loading: boolean;
  checkboxes: string[];
  handleSubmit: (data: string[]) => void;
};

const OmsRequestAddView: React.FC<IProps> = ({
  loading,
  checkboxes,
  handleSubmit,
}) => {
  return (
    <ATMButton
      primary
      floated="right"
      content={Lang.LBL_SEND_TO_OMS_REFRESH}
      disabled={checkboxes.length === 0 || loading}
      loading={loading}
      onClick={() => handleSubmit(checkboxes)}
    />
  );
};

export default OmsRequestAddView;
