import React, { useCallback, useState } from 'react';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { useTnaReportContext } from 'src/contexts/tna-report.context';
import { tnaReportActionTypes } from 'src/ducks/tna-report.duck';
import { ToastWarning } from 'src/components/atoms/toaster/toaster.component';
import { getTnaReportStatus } from 'src/selectors/tna-report.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { checkValue } from 'src/libraries/common.library';
import TNAReportView from './tna-report.view';

const TNAReport: React.FC = () => {
  const { state, actions } = useTnaReportContext();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<Record<any, any>>({});
  const loading = getTnaReportStatus(
    state,
    tnaReportActionTypes.TNA_REPORT_LIST_READ
  ).fetching;

  const handleFetch = useCallback(async () => {
    const filters: any[] = [];
    const params = { page: 1, limit: 0, filters };
    params.filters.push({
      name: 'dateRange',
      value: queryParams.dateRange ? queryParams.dateRange : [new Date()],
    });
    const response = await actions.listGET(params);
    if (response.error || response.payload?.rows?.length === 0) {
      ToastWarning('No Results found for the search criteria');
    }
  }, [actions, queryParams]);

  const handleDownload = useCallback(() => {
    setDownloading(true);
    if (state.list) {
      const { list } = state;

      const items = list.map((value) => [
        checkValue(value?.requestFacility?.requestId ?? '--'),
        checkValue(value?.status?.statDescription ?? '--'),
        checkValue(value?.requestFacility?.facility?.facTyp?.facTypNm ?? '--'),
        checkValue(
          `${value?.requestFacility?.facility?.volt?.voltNm}kv` ?? '--'
        ),
        checkValue(
          (value?.requestFacility?.facility?.facTypId !==
            Lang.FACILITY_TYPE_ID_BACKEND.LINE &&
          value?.requestFacility?.substationId
            ? `${value?.requestFacility?.facility?.outgFacNm} @ ${value?.requestFacility?.substation?.name}`
            : value?.requestFacility?.facility?.outgFacNm) ?? '--'
        ),
        checkValue(value?.requestFacility?.outageRequest?.recalTm ?? '--'),
        checkValue(Moment(value.startTm).format('MM/DD/YYYY') ?? '--'),
        checkValue(Moment(value.stopTm).format('MM/DD/YYYY') ?? '--'),
        checkValue(value?.startTmTxt ?? '--'),
        checkValue(value?.stopTmText ?? '--'),
        checkValue(value?.days ?? '--'),
        checkValue(value?.requestFacility?.outageType?.outageTypeDesc ?? '--'),
        checkValue(value?.requestFacility?.outageRequest?.wrkDesc ?? '--'),
        checkValue(
          value?.requestFacility?.outageRequest?.isoWorkKindId ?? '--'
        ),
        checkValue(
          value?.requestFacility?.facility?.blackstart?.blackstartValue ?? '--'
        ),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_LER,
          Lang.LBL_TNA_STATUS,
          Lang.LBL_TNA_EQUIPMENT_TYPE,
          Lang.LBL_TNA_VOLTAGE,
          Lang.LBL_TNA_EQUIPMENT,
          Lang.LBL_TNA_RECALL_TIME,
          Lang.LBL_TNA_START_DATE,
          Lang.LBL_TNA_END_DATE,
          Lang.LBL_TNA_START_TIME,
          Lang.LBL_TNA_END_TIME,
          Lang.LBL_TNA_DAYS,
          Lang.LBL_TNA_AUTH_TYPE,
          Lang.LBL_TNA_WORK_DESCRIPTION,
          Lang.LBL_TNA_NATURE_OF_WORK,
          Lang.LBL_BLACK_START_PATH,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_TNA_Report_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  }, [actions, state, setDownloading]);

  return (
    <TNAReportView
      data={state.list}
      loading={loading}
      queryParams={queryParams}
      handleFetch={handleFetch}
      handleQueryParams={setQueryParams}
      handleDownload={handleDownload}
      downloading={downloading}
    />
  );
};

export default TNAReport;
