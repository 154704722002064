/* eslint-disable no-nested-ternary */
import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/ler-request.service';
import {
  ICaiso,
  ILerRequest,
  ILerRequestDraft,
  ILerRequestEmailGroup,
  ILerRequestEmployee,
  ILerRequestItem,
  ILerRequestRelatedLerItem,
  IQueryMessageCount,
  ITransmissionOutage,
} from 'src/models/ler-request.model';
import { LERRequestFormStep } from 'src/constants';
import {
  getLerOutageDateList,
  hasChangeRequest,
} from 'src/helpers/ler-request.helper';
import { orderBy } from 'lodash';
import Lang from 'src/libraries/language';
import { getEmployeeSort } from 'src/helpers/employee.helper';
import { ISortResponse } from 'src/models/switching.model';
import Moment, { format24hTime } from 'src/libraries/moment.library';

export const lerRequestActionTypes = {
  LER_REQUEST_DATA_READ: 'LER_REQUEST_DATA_READ',
  LER_REQUEST_LIST_READ: 'LER_REQUEST_LIST_READ',
  LER_REQUEST_SEARCH_READ: 'LER_REQUEST_SEARCH_READ',
  LER_REQUEST_ITEM_LIST_READ: 'LER_REQUEST_ITEM_LIST_READ',
  LER_REQUEST_OUTAGE_LIST_READ: 'LER_REQUEST_OUTAGE_LIST_READ',
  LER_REQUEST_OUTAGE_LIST_DOWNLOAD_READ:
    'LER_REQUEST_OUTAGE_LIST_DOWNLOAD_READ',
  LER_REQUEST_CHANGE_LIST_READ: 'LER_REQUEST_CHANGE_LIST_READ',
  LER_REQUEST_DATA_CREATE: 'LER_REQUEST_DATA_CREATE',
  LER_REQUEST_DRAFT_LIST: 'LER_REQUEST_DRAFT_LIST',
  LER_REQUEST_DRAFT_CREATE: 'LER_REQUEST_DRAFT_CREATE',
  LER_REQUEST_DRAFT_UPDATE: 'LER_REQUEST_DRAFT_UPDATE',
  LER_REQUEST_DATA_UPDATE: 'LER_REQUEST_DATA_UPDATE',
  LER_REQUEST_DATA_DELETE: 'LER_REQUEST_DATA_DELETE',
  LER_REQUEST_CHANGE_DELETE: 'LER_REQUEST_CHANGE_DELETE',
  LER_REQUEST_DRAFT_DELETE: 'LER_REQUEST_DRAFT_DELETE',
  LER_REQUEST_DATA_SET: 'LER_REQUEST_DATA_SET',
  LER_REQUEST_DRAFT_SET: 'LER_REQUEST_DRAFT_SET',
  LER_REQUEST_STATUS_UPDATE: 'LER_REQUEST_STATUS_UPDATE',
  LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE:
    'LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE',
  LER_REQUEST_REJECT: 'LER_REQUEST_REJECT',
  LER_REQUEST_CHANGE_REQUEST_READ: 'LER_REQUEST_CHANGE_REQUEST_READ',
  LER_REQUEST_CHANGE_REQUEST_LIST: 'LER_REQUEST_CHANGE_REQUEST_LIST',
  LER_REQUEST_CHANGE_REQUEST_CANCEL: 'LER_REQUEST_CHANGE_REQUEST_CANCEL',
  LER_REQUEST_CANCEL: 'LER_REQUEST_CANCEL',
  LER_REQUEST_VERSION_READ: 'LER_REQUEST_VERSION_READ',
  LER_REQUEST_VERSION_SELECTED_READ: 'LER_REQUEST_VERSION_SELECTED_READ',
  LER_REQUEST_SEND_CAISO_CREATE: 'LER_REQUEST_SEND_CAISO_CREATE',
  LER_REQUEST_SEND_CAISO_UPDATE: 'LER_REQUEST_SEND_CAISO_UPDATE',
  LER_REQUEST_SET_CAISO_ERROR: 'LER_REQUEST_SET_CAISO_ERROR',
  LER_REQUEST_CAISO_READ: 'LER_REQUEST_CAISO_READ',
  LER_REQUEST_CAISO_RECONCILE_CREATE: 'LER_REQUEST_CAISO_RECONCILE_CREATE',
  LER_REQUEST_COUNT_READ: 'LER_REQUEST_COUNT_READ',
  LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE:
    'LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE',
  LER_REQUEST_RESEND_SINGLE_EMAIL_UPDATE:
    'LER_REQUEST_RESEND_SINGLE_EMAIL_UPDATE',
  LER_REQUEST_RESEND_MULTIPLE_EMAIL_UPDATE:
    'LER_REQUEST_RESEND_MULTIPLE_EMAIL_UPDATE',
  LER_REQUEST_EMAIL_USER_GROUP_DELETE: 'LER_REQUEST_EMAIL_USER_GROUP_DELETE',
  LER_REQUEST_EMAIL_USER_GROUP_READ: 'LER_REQUEST_EMAIL_USER_GROUP_READ',
  LER_REQUEST_EMAIL_STATUS_UPDATE: 'LER_REQUEST_EMAIL_STATUS_UPDATE',
  LER_REQUEST_SORT_CREATE: 'LER_REQUEST_SORT_CREATE',
  LER_REQUEST_ASSIGN_SCHEDULER: 'LER_REQUEST_ASSIGN_SCHEDULER',
  LER_REQUEST_GRP_ID_LIST_READ: 'LER_REQUEST_GRP_ID_LIST_READ',
  LER_REQUEST_ADD_RELATED_LER_UPDATE: 'LER_REQUEST_ADD_RELATED_LER_UPDATE',
  LER_REQUEST_REMOVE_RELATED_LER_UPDATE:
    'LER_REQUEST_REMOVE_RELATED_LER_UPDATE',
  LER_REQUEST_SYSTEM_CHANGE_TASKS_EMAIL:
    'LER_REQUEST_SYSTEM_CHANGE_TASKS_EMAIL',
  LER_REQUEST_SYSTEM_CHANGE_TASKS: 'LER_REQUEST_SYSTEM_CHANGE_TASKS',
  LER_REQUEST_DATA_UPDATE_SWITCHING: 'LER_REQUEST_DATA_UPDATE_SWITCHING',
  LER_REQUEST_FOR_APPROVAL_SET: 'LER_REQUEST_FOR_APPROVAL_SET',
  LER_REQUEST_IS_FROM_UPDATE_SET: 'LER_REQUEST_IS_FROM_UPDATE_SET',
  LER_REQUEST_EMPLOYEE_LIST_READ: 'LER_REQUEST_EMPLOYEE_LIST_READ',
  LER_REQUEST_COUNT_FAILED_SUBMITTAL_LIST_READ:
    'LER_REQUEST_COUNT_FAILED_SUBMITTAL_LIST_READ',
  LER_REQUEST_PREVIOUS_DATA: 'LER_REQUEST_PREVIOUS_DATA',
  LER_REQUEST_IS_FROM_REJECT_SET: 'LER_REQUEST_IS_FROM_REJECT_SET',
  LER_REQUEST_HAS_NO_CAISO_UPDATE: 'LER_REQUEST_HAS_NO_CAISO_UPDATE',
  LER_REQUEST_HAS_PENDING_CAISO: 'LER_REQUEST_HAS_PENDING_CAISO',
  LER_REQUEST_IS_FROM_PENDING_CAISO_SET:
    'LER_REQUEST_IS_FROM_PENDING_CAISO_SET',
  LER_REQUEST_RELATED_OUTAGES_READ: 'LER_REQUEST_RELATED_OUTAGES_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: lerRequestActionTypes.LER_REQUEST_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: lerRequestActionTypes.LER_REQUEST_LIST_READ,
    service: services.listGET,
  },

  employeeListGET: {
    type: lerRequestActionTypes.LER_REQUEST_EMPLOYEE_LIST_READ,
    service: services.employeeListGET,
  },

  searchGET: {
    type: lerRequestActionTypes.LER_REQUEST_SEARCH_READ,
    service: services.searchGET,
  },

  listItemGET: {
    type: lerRequestActionTypes.LER_REQUEST_ITEM_LIST_READ,
    service: services.listItemGET,
  },

  listOutageGET: {
    type: lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_READ,
    service: services.listItemGET,
  },

  listOutageDownloadGET: {
    type: lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_DOWNLOAD_READ,
    service: services.listItemSearchGET,
  },

  listChangeGET: {
    type: lerRequestActionTypes.LER_REQUEST_CHANGE_LIST_READ,
    service: services.listItemGET,
  },

  draftListGET: {
    type: lerRequestActionTypes.LER_REQUEST_DRAFT_LIST,
    service: services.draftListGET,
  },

  pendingCaisoGET: {
    type: lerRequestActionTypes.LER_REQUEST_HAS_PENDING_CAISO,
    service: services.listItemGET,
  },

  createPOST: {
    type: lerRequestActionTypes.LER_REQUEST_DATA_CREATE,
    service: services.createPOST,
  },

  draftPOST: {
    type: lerRequestActionTypes.LER_REQUEST_DRAFT_CREATE,
    service: services.draftPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  draftPUT: {
    type: lerRequestActionTypes.LER_REQUEST_DRAFT_UPDATE,
    service: services.draftPUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: lerRequestActionTypes.LER_REQUEST_DATA_UPDATE,
    service: services.updatePUT,
  },

  switchingUpdatePUT: {
    type: lerRequestActionTypes.LER_REQUEST_DATA_UPDATE_SWITCHING,
    service: services.switchingUpdatePUT,
  },

  dataDELETE: {
    type: lerRequestActionTypes.LER_REQUEST_DATA_DELETE,
    service: services.dataDELETE,
  },

  changeDELETE: {
    type: lerRequestActionTypes.LER_REQUEST_CHANGE_DELETE,
    service: services.changeDELETE,
  },

  draftDELETE: {
    type: lerRequestActionTypes.LER_REQUEST_DRAFT_DELETE,
    service: services.draftDELETE,
  },

  cancelPUT: {
    type: lerRequestActionTypes.LER_REQUEST_CANCEL,
    service: services.cancelPUT,
  },

  statusPUT: {
    type: lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE,
    service: services.statusPUT,
  },

  crStatusPUT: {
    type: lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE,
    service: services.crStatusPUT,
  },

  rejectPUT: {
    type: lerRequestActionTypes.LER_REQUEST_REJECT,
    service: services.rejectPUT,
  },

  changeRequestGET: {
    type: lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_READ,
    service: services.changeRequestGET,
  },

  listChangeRequestGET: {
    type: lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_LIST,
    service: services.listChangeRequestGET,
  },

  versionGET: {
    type: lerRequestActionTypes.LER_REQUEST_VERSION_READ,
    service: services.versionGET,
  },

  versionSelectedGET: {
    type: lerRequestActionTypes.LER_REQUEST_VERSION_SELECTED_READ,
    service: services.versionSelectedGET,
  },

  sendCaisoPOST: {
    type: lerRequestActionTypes.LER_REQUEST_SEND_CAISO_CREATE,
    service: services.sendCaisoPOST,
    meta: {
      info: (error) => {
        return error.status === 504
          ? Lang.MSG_CAISO_COMMUNICATION_FAILED_SUBMIT
          : undefined;
      },
    },
  },

  autoReconcilePOST: {
    type: lerRequestActionTypes.LER_REQUEST_CAISO_RECONCILE_CREATE,
    service: services.autoReconcilePOST,
    meta: {
      info: (error) => {
        return error.status === 504
          ? Lang.MSG_CAISO_COMMUNICATION_FAILED_SUBMIT
          : undefined;
      },
    },
  },

  dataCaisoGET: {
    type: lerRequestActionTypes.LER_REQUEST_CAISO_READ,
    service: services.dataCaisoGET,
    meta: {
      error: (error) => {
        const errorText = (
          error && error.data && error.data.message
            ? error.data.message
            : error.statusText || error.message || 'Error'
        ).toString();

        if (error.status === 504) {
          return Lang.MSG_CAISO_COMMUNICATION_FAILED_RETRIEVE;
        }

        return errorText.includes('RUNTIME') ? errorText : undefined;
      },
    },
  },

  countGET: {
    type: lerRequestActionTypes.LER_REQUEST_COUNT_READ,
    service: services.countGET,
  },

  sendMultipleEmailPOST: {
    type: lerRequestActionTypes.LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE,
    service: services.sendMultipleEmailPOST,
  },

  resendSingleEmailPOST: {
    type: lerRequestActionTypes.LER_REQUEST_RESEND_SINGLE_EMAIL_UPDATE,
    service: services.resendSingleEmailPOST,
  },

  resendMultipleEmailPOST: {
    type: lerRequestActionTypes.LER_REQUEST_RESEND_MULTIPLE_EMAIL_UPDATE,
    service: services.resendMultipleEmailPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  emailUserGrpDELETE: {
    type: lerRequestActionTypes.LER_REQUEST_EMAIL_USER_GROUP_DELETE,
    service: services.emailUserGrpDELETE,
  },

  groupDataGET: {
    type: lerRequestActionTypes.LER_REQUEST_EMAIL_USER_GROUP_READ,
    service: services.groupDataGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  approveRejectEmailPUT: {
    type: lerRequestActionTypes.LER_REQUEST_EMAIL_STATUS_UPDATE,
    service: services.approveRejectEmailPUT,
  },

  createSwitchPOST: {
    type: lerRequestActionTypes.LER_REQUEST_SORT_CREATE,
    service: services.createSwitchPOST,
  },

  assignPUT: {
    type: lerRequestActionTypes.LER_REQUEST_ASSIGN_SCHEDULER,
    service: services.assignPUT,
  },

  grpIdListGET: {
    type: lerRequestActionTypes.LER_REQUEST_GRP_ID_LIST_READ,
    service: services.listItemGET,
  },

  relatedOutagesListGET: {
    type: lerRequestActionTypes.LER_REQUEST_RELATED_OUTAGES_READ,
    service: services.relatedOutagesListGET,
  },

  addRelatedLerPUT: {
    type: lerRequestActionTypes.LER_REQUEST_ADD_RELATED_LER_UPDATE,
    service: services.addRelatedLerPUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  removeRelatedLerPUT: {
    type: lerRequestActionTypes.LER_REQUEST_REMOVE_RELATED_LER_UPDATE,
    service: services.removeRelatedLerPUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  sendSystemChangeTasksEmailPOST: {
    type: lerRequestActionTypes.LER_REQUEST_SYSTEM_CHANGE_TASKS,
    service: services.sendSystemChangeTasksPOST,
  },

  countFailedSubmittalGET: {
    type: lerRequestActionTypes.LER_REQUEST_COUNT_FAILED_SUBMITTAL_LIST_READ,
    service: services.countFailedSubmittalGET,
  },

  // This is a sync action
  setData: (lerRequest: ILerRequest) => ({
    type: lerRequestActionTypes.LER_REQUEST_DATA_SET,
    payload: lerRequest,
  }),

  setForApproval: (forApproval: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_FOR_APPROVAL_SET,
    payload: forApproval,
  }),

  setIsFromUpdate: (isFromUpdate: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_IS_FROM_UPDATE_SET,
    payload: isFromUpdate,
  }),

  setIsFromReject: (isFromReject: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_IS_FROM_REJECT_SET,
    payload: isFromReject,
  }),

  setHasNoCaisoUpdate: (hasNoCaisoUpdate: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_HAS_NO_CAISO_UPDATE,
    payload: hasNoCaisoUpdate,
  }),

  setCaisoError: (caisoError: any) => ({
    type: lerRequestActionTypes.LER_REQUEST_SET_CAISO_ERROR,
    payload: caisoError,
  }),

  setDraft: (lerRequest?: ILerRequestDraft) => ({
    type: lerRequestActionTypes.LER_REQUEST_DRAFT_SET,
    payload: lerRequest,
  }),
  setOutageList: (rows: ILerRequestItem[] = []) => ({
    type: lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_READ,
    payload: {
      rows,
      count: rows.length,
    },
  }),

  setGrpIdList: (rows: ILerRequestItem[] = []) => ({
    type: lerRequestActionTypes.LER_REQUEST_GRP_ID_LIST_READ,
    payload: {
      rows,
      count: rows.length,
    },
  }),

  setCaisoList: (caisoList: ITransmissionOutage[] = []) => ({
    type: lerRequestActionTypes.LER_REQUEST_CAISO_READ,
    payload: caisoList,
  }),

  sendSystemChangeTaskEmail: (param: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_SYSTEM_CHANGE_TASKS_EMAIL,
    payload: {
      enableSystemChangeTasks: param,
    },
  }),

  setPreviousData: (previousLer: ILerRequest) => ({
    type: lerRequestActionTypes.LER_REQUEST_PREVIOUS_DATA,
    payload: previousLer,
  }),

  setIsFromPendingCaiso: (isFromPendingCaiso: boolean) => ({
    type: lerRequestActionTypes.LER_REQUEST_IS_FROM_PENDING_CAISO_SET,
    payload: isFromPendingCaiso,
  }),
};

export type ILerRequestAsync = typeof duckActions;

export interface ILerRequestState
  extends ICommonState<typeof lerRequestActionTypes> {
  data?: ILerRequest;

  list: ILerRequest[];
  total: number;
  newList: ILerRequestItem[];
  newTotal: number;
  changeList: ILerRequestItem[];
  changeTotal: number;
  draftList: ILerRequestDraft[];
  draftTotal: number;
  pendingCaisoTotal: number;
  pendingCaisoList: ILerRequestItem[];

  outageList: ILerRequestItem[];
  outageTotal: number;
  openCRStatus: number;
  forApproval: boolean;
  changeRequest?: ILerRequest;
  draft?: ILerRequestDraft;

  version?: ILerRequestItem[];
  versionSelected?: ILerRequest[];

  caisoData?: ICaiso;
  caisoList: ITransmissionOutage[];
  emailGroup?: ILerRequestEmailGroup;
  grpIdList?: ILerRequestItem[];
  relatedOutagesList?: ILerRequestRelatedLerItem[];
  enableSystemChangeTasks: boolean;
  previousData?: ILerRequest;
  caisoError?: any;
  isFromUpdate?: boolean;
  isFromReject?: boolean;
  isFromPendingCaiso?: boolean;
  employeeList: ILerRequestEmployee[];
  countFailedList: IQueryMessageCount;
  hasNoCaisoUpdate?: boolean;
}

export const defaultState: ILerRequestState = {
  status: {},
  list: [],
  total: 0,
  openCRStatus: 0,
  draftList: [],
  relatedOutagesList: [],
  draftTotal: 0,
  pendingCaisoTotal: 0,
  pendingCaisoList: [],
  newList: [],
  newTotal: 0,
  changeList: [],
  changeTotal: 0,
  outageList: [],
  outageTotal: 0,
  enableSystemChangeTasks: false,
  forApproval: false,
  employeeList: [],
  countFailedList: {
    switchingRequest: {
      basicQueueMsgCount: 0,
      deadQueueMsgCount: 0,
    },
    transmissionOutage: {
      basicQueueMsgCount: 0,
      deadQueueMsgCount: 0,
    },
  },
  caisoList: [],
};

const LerRequestReducer = (
  state: ILerRequestState,
  action: IReducerAction<ILerRequestAsync>
): ILerRequestState => {
  switch (action.type) {
    case lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_STATUS_UPDATE:
    case lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE:
    case lerRequestActionTypes.LER_REQUEST_REJECT:
    case lerRequestActionTypes.LER_REQUEST_CANCEL:
    case lerRequestActionTypes.LER_REQUEST_DATA_READ: {
      let sortedData;
      if (action.payload) {
        const sortResponseList: ISortResponse[] = [];
        action.payload.sortResponses?.map((val) => {
          sortResponseList.push({
            ...val,
            date: val.sortTime,
            time: format24hTime(val.sortTime),
          });
        });
        sortedData = {
          ...action.payload,
          sortResponses: orderBy(
            sortResponseList,
            ['sortTime', 'outBack'],
            ['asc', 'desc']
          ),
        };
      }
      return {
        ...state,
        data: sortedData,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_DATA_SET:
    case lerRequestActionTypes.LER_REQUEST_DATA_UPDATE:
    case lerRequestActionTypes.LER_REQUEST_DATA_UPDATE_SWITCHING:
    case lerRequestActionTypes.LER_REQUEST_DATA_CREATE: {
      if (action.payload && hasChangeRequest(action.payload)) {
        const { requestId } = action.payload;
        const data = state.newList.find((v) => v.requestId === requestId);

        if (data) {
          const newList = state.newList.filter(
            (v) => v.requestId !== requestId
          );

          return {
            ...state,
            data: action.payload,
            newList,
            newTotal: state.newTotal - 1,
            changeList: [data, ...state.changeList],
            changeTotal: state.changeTotal + 1,
          };
        }
      }

      let sortedData;
      if (action.payload) {
        const sortResponseList: ISortResponse[] = [];
        action.payload.sortResponses?.map((val) => {
          sortResponseList.push({
            ...val,
            date: val.sortTime,
            time: format24hTime(val.sortTime),
          });
        });
        sortedData = {
          ...action.payload,
          sortResponses: orderBy(
            sortResponseList,
            ['sortTime', 'outBack'],
            ['asc', 'desc']
          ),
        };
      }

      return {
        ...state,
        data: sortedData,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_READ: {
      return {
        ...state,
        changeRequest: action.payload
          ? {
              ...action.payload,
              outageDates: getLerOutageDateList(
                action.payload.outageDates || []
              ),
              jobInformation: {
                ...action.payload.jobInformation,
                radializedSubs:
                  action.payload.jobInformation.radializedSubs &&
                  action.payload.jobInformation.radializedSubs.length > 0
                    ? orderBy(
                        action.payload.jobInformation.radializedSubs,
                        ['substationId'],
                        ['asc']
                      )
                    : [],
              },
            }
          : undefined,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        // total: action.payload?.count.all ?? 0,
        // openCRStatus: action.payload?.count.open ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_ITEM_LIST_READ: {
      return {
        ...state,
        newList: action.payload?.rows ?? [],
        newTotal: action.payload?.count ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_CHANGE_LIST_READ: {
      return {
        ...state,
        changeList: action.payload?.rows ?? [],
        changeTotal: action.payload?.count ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_READ: {
      return {
        ...state,
        outageList: action.payload?.rows ?? [],
        outageTotal: action.payload?.count ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_DRAFT_SET:
    case lerRequestActionTypes.LER_REQUEST_DRAFT_CREATE:
    case lerRequestActionTypes.LER_REQUEST_DRAFT_UPDATE: {
      if (action.payload) {
        const draftList = state.draftList.map((value) => {
          if (
            value.outageRequestDraftId === action.payload?.outageRequestDraftId
          ) {
            return action.payload;
          }

          return value;
        });

        return {
          ...state,
          draftList,
          draft: action.payload,
        };
      }

      return {
        ...state,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_DRAFT_LIST: {
      return {
        ...state,
        draftList: action.payload?.rows ?? [],
        draftTotal: action.payload?.count ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_HAS_PENDING_CAISO: {
      return {
        ...state,
        pendingCaisoList: action.payload?.rows ?? [],
        pendingCaisoTotal: action.payload?.count ?? 0,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_DRAFT_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const draftList = state.draftList.filter(
          (value) => value.outageRequestDraftId !== id
        );

        return {
          ...state,
          draft: undefined,
          draftList,
          draftTotal: state.draftTotal - 1,
        };
      }

      return state;
    }

    case lerRequestActionTypes.LER_REQUEST_SYSTEM_CHANGE_TASKS_EMAIL: {
      return {
        ...state,
        enableSystemChangeTasks:
          action.payload?.enableSystemChangeTasks ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_VERSION_READ: {
      // cnd : if current version exist in history remove data
      let payload = action.payload?.rows;
      const versions = action.payload?.rows?.map((val) => val.version_no);
      if (
        state.data?.version_no &&
        versions?.includes(state.data?.version_no)
      ) {
        payload = action.payload?.rows?.filter(
          (v) => v.version_no !== state.data?.version_no
        );
      }

      const data = {
        ...state.data,
        requestor: state.data?.crewInformation.requestor,
        days: state.data?.outageDates ? state.data?.outageDates.length : 0,
      } as unknown as ILerRequestItem;
      return {
        ...state,
        version:
          data && payload
            ? orderBy([data, ...payload], ['version_no'], ['asc'])
            : [],
      };
    }
    case lerRequestActionTypes.LER_REQUEST_VERSION_SELECTED_READ: {
      const prodReleaseV1019 = '10/13/2023 00:00:00'; // US #605811 10/02/2023 Date Modified on Version History
      // cnd : if current version exist in history remove data
      let payload = action.payload?.rows?.map((val) => {
        const outageDates = getLerOutageDateList(val.outageDates || []);
        return {
          ...val,
          outageDates,
          jobInformation: {
            ...val.jobInformation,
            radializedSubs:
              val.jobInformation.radializedSubs &&
              val.jobInformation.radializedSubs.length > 0
                ? orderBy(
                    val.jobInformation.radializedSubs,
                    ['substationId'],
                    ['asc']
                  )
                : [],
          },
          // US #605811 10/02/2023 Date Modified on Version History
          updatedAt:
            val.updatedAt &&
            Moment(val.updatedAt).isBefore(Moment(prodReleaseV1019))
              ? ''
              : val.updatedAt,
        };
      });
      const versionsList = action.payload?.rows?.map((val) => val.version_no);
      if (
        state.data?.version_no &&
        versionsList?.includes(state.data?.version_no)
      ) {
        payload = payload?.filter(
          (v) => v.version_no !== state.data?.version_no
        );
      }

      const versions = action.params && action.params[1];
      const data = versions?.includes(state.data?.version_no?.toString() ?? '0')
        ? ({
            ...state.data,
            requestor: state.data?.crewInformation.requestor,
            outageDates: getLerOutageDateList(state.data?.outageDates || []),
            jobInformation: state.data?.jobInformation
              ? {
                  ...state.data.jobInformation,
                  radializedSubs:
                    state.data.jobInformation.radializedSubs &&
                    state.data.jobInformation.radializedSubs.length > 0
                      ? orderBy(
                          state.data.jobInformation.radializedSubs,
                          ['substationId'],
                          ['asc']
                        )
                      : [],
                }
              : {},
            // US #605811 10/02/2023 Date Modified on Version History
            updatedAt:
              state.data?.updatedAt &&
              Moment(state.data?.updatedAt).isBefore(Moment(prodReleaseV1019))
                ? ''
                : state.data?.updatedAt,
          } as unknown as ILerRequest)
        : undefined;
      return {
        ...state,
        versionSelected:
          data === undefined && payload
            ? orderBy([...payload], ['version_no'], ['asc'])
            : data && payload
            ? orderBy([data, ...payload], ['version_no'], ['asc'])
            : [],
      };
    }

    case lerRequestActionTypes.LER_REQUEST_CAISO_RECONCILE_CREATE:
    case lerRequestActionTypes.LER_REQUEST_SEND_CAISO_CREATE: {
      return {
        ...state,
        caisoData: action.payload,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_CAISO_READ: {
      return {
        ...state,
        caisoList: action.payload ?? [],
      };
    }

    case lerRequestActionTypes.LER_REQUEST_COUNT_READ: {
      return {
        ...state,
        // If we already have values to these totals, we ignore the count API response
        newTotal: state.newTotal || (action.payload?.newTotal ?? 0),
        changeTotal: state.changeTotal || (action.payload?.changeTotal ?? 0),
        draftTotal: state.draftTotal || (action.payload?.draftTotal ?? 0),
        pendingCaisoTotal:
          state.pendingCaisoTotal || (action.payload?.pendingCaisoTotal ?? 0),
      };
    }

    case lerRequestActionTypes.LER_REQUEST_DATA_DELETE: {
      if (action.params) {
        const [requestId] = action.params;

        return {
          ...state,
          data: undefined,
          newList: state.newList.filter((v) => v.requestId !== requestId),
          newTotal: state.newTotal - 1,
        };
      }

      return state;
    }

    case lerRequestActionTypes.LER_REQUEST_CHANGE_DELETE: {
      if (action.params) {
        const [requestId] = action.params;

        const data = state.changeList.find((v) => v.requestId === requestId);

        if (data) {
          return {
            ...state,
            changeList: state.changeList.filter(
              (v) => v.requestId !== requestId
            ),
            changeTotal: state.changeTotal - 1,
            newList: [data, ...state.newList],
            newTotal: state.newTotal + 1,
          };
        }
      }

      return state;
    }

    case lerRequestActionTypes.LER_REQUEST_EMAIL_USER_GROUP_READ: {
      return {
        ...state,
        emailGroup: action.payload,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_EMAIL_STATUS_UPDATE: {
      return {
        ...state,
        emailGroup: action.payload,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_SET_CAISO_ERROR: {
      return {
        ...state,
        caisoError: action.payload,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_SORT_CREATE: {
      if (state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            [LERRequestFormStep.SWITCHING]: action.payload ?? [],
          },
        };
      }

      return {
        ...state,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_GRP_ID_LIST_READ: {
      const relatedLers = action.payload?.rows?.filter(
        (val) => val.requestId !== state.data?.requestId
      );
      return {
        ...state,
        grpIdList: relatedLers ?? [],
      };
    }

    case lerRequestActionTypes.LER_REQUEST_FOR_APPROVAL_SET: {
      return {
        ...state,
        forApproval: action.payload ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_IS_FROM_UPDATE_SET: {
      return {
        ...state,
        isFromUpdate: action.payload ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_IS_FROM_REJECT_SET: {
      return {
        ...state,
        isFromReject: action.payload ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_IS_FROM_PENDING_CAISO_SET: {
      return {
        ...state,
        isFromPendingCaiso: action.payload ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_HAS_NO_CAISO_UPDATE: {
      return {
        ...state,
        hasNoCaisoUpdate: action.payload ?? false,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        employeeList: getEmployeeSort(action.payload ?? []),
      };
    }

    case lerRequestActionTypes.LER_REQUEST_COUNT_FAILED_SUBMITTAL_LIST_READ: {
      return {
        ...state,
        countFailedList: action.payload ?? state.countFailedList,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_PREVIOUS_DATA: {
      let sortedData;
      if (action.payload) {
        const sortResponseList: ISortResponse[] = [];
        action.payload.sortResponses?.map((val) => {
          sortResponseList.push({
            ...val,
            date: val.sortTime,
            time: format24hTime(val.sortTime),
          });
        });
        sortedData = {
          ...action.payload,
          sortResponses: orderBy(
            sortResponseList,
            ['sortTime', 'outBack'],
            ['asc', 'desc']
          ),
        };
      }
      return {
        ...state,
        previousData: sortedData,
      };
    }

    case lerRequestActionTypes.LER_REQUEST_RELATED_OUTAGES_READ: {
      return {
        ...state,
        relatedOutagesList: action.payload?.rows,
      };
    }

    default: {
      return state;
    }
  }
};

export default LerRequestReducer;
