export enum RealTimeLogCategory {
  FORCEDOUTAGE = 'Forced Outage',
  NIGHTSHIFT = 'Nightshift Checklist',
  TSOSTAFFING = 'TSO Staffing',
  REALTIMEREQUEST = 'Real-Time Request',
  RCOPERATING = 'RC Operating Instruction',
  BAOPERATING = 'BA Operating Instruction',
  EXTASSISTANCE = 'External Assistance Request',
  CHANGECONTROL = 'Change Control Center',
  RCBANOTIF = 'RC/BA Notification',
  INTFREQEXCUR = 'Int./Freq. Excursion',
  FIELDNOTIF = 'Field Notification',
  GENOPERATING = 'Gen. Operating Instruction',
  EMERGENCYEVENT = 'Emergency Event',
  COMMFAILURE = 'Comm. Failure',
  COMPTOOLS = 'Computer Tools Issues',
  WILDFIRE = 'Wildfire',
  CONTROLEQUIPOUTAGE = '30+min Unplanned Telemetering/Control Equip Outage',
}

export const RealTimeLogCategoryText: Record<string, string> = {
  [RealTimeLogCategory.FORCEDOUTAGE]:
    'Groups Notified:\nRAS Operations:\nOther Tripped Equipment:\nEvent Description/Field Reports:',
  [RealTimeLogCategory.NIGHTSHIFT]:
    'The TSO nightshift checklist has been completed. [All tasks were completed. No significant changes or impacts].',
  [RealTimeLogCategory.TSOSTAFFING]:
    'The following days were evaluated for TSO staffing. [XX/XX - Jobs, XX/XX - Jobs, XX/XX - Jobs, XX/XX - Jobs]. There [were/were not] shifts added. [Shifts added]',
  [RealTimeLogCategory.REALTIMEREQUEST]:
    '[REQUESTOR NAME] requested a real-time [AUTHORIZATION] on [EQUIPMENT] for an expected outage duration of [XXXX-XXXX]. The reason for this real-time request is [REASON]. This request has been approved by the OSS.',
  [RealTimeLogCategory.RCOPERATING]:
    'The RC operator, [FIRST/LAST NAME], has issued an operating instruction to SDG&E. The operating instruction is for SDG&E to [OPERATING INSTRUCTION]. The purpose of the operating instruction is [PURPOSE]. SDG&E responded that it is [ABLE/UNABLE] to comply with the operating instruction. [SDG&E is unable to comply with the operating instruction due to physical limitation or a violation of safety, equipment, regulatory, or statutory requirements].',
  [RealTimeLogCategory.BAOPERATING]:
    'The BA operator, [FIRST/LAST NAME], has issued an operating instruction to SDG&E. The operating instruction is for SDG&E to [OPERATING INSTRUCTION]. The purpose of the operating instruction is [PURPOSE]. SDG&E responded that it is [ABLE/UNABLE] to comply with the operating instruction. [SDG&E is unable to comply with the operating instruction due to physical limitation or a violation of safety, equipment, regulatory, or statutory requirements].',
  [RealTimeLogCategory.EXTASSISTANCE]:
    '[FIRST/LAST NAME] from [ENTITY NAME] requested assistance from SDG&E. SDG&E responded that it is [ABLE/UNABLE] to provide assistance. [SDG&E is unable to comply with the operating instruction due to physical limitation or a violation of safety, equipment, regulatory, or statutory requirements].',
  [RealTimeLogCategory.CHANGECONTROL]:
    '[Planned/Unplanned] transfer of control center from [MCC/BCC] to [MCC/BCC] has been completed. Niether control center was operational for [XX] minutes. [Operations plans to return to MCC on XX/XX at 0600].',
  [RealTimeLogCategory.RCBANOTIF]: 'Name:\nEntity:\nNotification:\nResponse:',
  [RealTimeLogCategory.INTFREQEXCUR]: 'Name:\nEntity:\nEvent Description:',
  [RealTimeLogCategory.FIELDNOTIF]:
    'Name:\nLocation:\nGroups Notified:\nEvent Description:',
  [RealTimeLogCategory.GENOPERATING]:
    'SDG&E gave an operating instruction to [FIRST/LAST NAME] from [GENERATOR/UTILITY] to [OPERATING INSTRUCTION]. The purpose of this operating instruction was to [PURPOSE].',
  [RealTimeLogCategory.EMERGENCYEVENT]:
    'Event Description:\nSDG&E Procedure:\nEntities Notified:\nIf manual load shed or UFLS load shed, provide detail of load shed event, including MW shed:',
  [RealTimeLogCategory.COMMFAILURE]: 'Entities Notified:\nEvent Description:',
  [RealTimeLogCategory.COMPTOOLS]:
    'Entities Notified:\nGroups Notified:\nEvent Description:',
  [RealTimeLogCategory.WILDFIRE]:
    'Wildfire Name:\nFacility Impacted:\nEvent Description:',
  [RealTimeLogCategory.CONTROLEQUIPOUTAGE]:
    'Description of event:\nImpacted Entity(ies) Notified (RC / BA / TOP):',
};

export enum RealTimeLogTab {
  Today,
  All,
}

export const realTimeLogStatus = RealTimeLogCategory;
export const realTimeLogTab = RealTimeLogTab;
