import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import RequestStatusReducer, {
  IRequestStatusState,
  defaultState,
  duckActions,
} from 'src/ducks/request-status.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    RequestStatusReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IRequestStatusContext = ReturnType<typeof useReducer>;

const RequestStatusContext = createContext<Partial<IRequestStatusContext>>({
  state: defaultState,
}) as React.Context<IRequestStatusContext>;

type IProps = {
  state?: Partial<IRequestStatusState>;
};

const RequestStatusProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <RequestStatusContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RequestStatusContext.Provider>
  );
};

const useRequestStatusContext = () => useContext(RequestStatusContext);

export type IUseRequestStatusContext = ReturnType<typeof useRequestStatusContext>;

export { RequestStatusContext, RequestStatusProvider, useRequestStatusContext };
