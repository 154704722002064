import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { ITroubleJobsForm } from 'src/models/trouble-jobs.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import useLocationTab from 'src/hooks/location-tab.hook';
import { ATMLoader, IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { ISortResponse } from 'src/models/switching.model';
import moment from 'moment';
import Moment, { createDateTime } from 'src/libraries/moment.library';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import {
  getTroubleJobParams,
  getTroubleJobsStatus,
} from 'src/selectors/trouble-jobs.selector';
import TroubleJobsEditView from './trouble-jobs-edit.view';

type IProps = {
  dataStatus: boolean;
  filterState?: IORGDataTableQueryState;
};

const TroubleJobsEdit: React.FC<IProps> = ({ dataStatus, filterState }) => {
  const { state, actions } = useTroubleJobsContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { currentTab, handleTabChange } = useLocationTab('id');
  const { handleTabChange: habldeStatusChange, currentTab: currentStatusTab } =
    useLocationTab('troubleJobsStatus');
  const [isOpen, setIsOpen] = useState(false);
  const status = getTroubleJobsStatus(
    state,
    troubleJobsActionTypes.TROUBLE_JOBS_LIST_READ
  );
  const statusElement = getTroubleJobsStatus(
    state,
    troubleJobsActionTypes.TROUBLE_JOBS_DATA_UPDATE
  );
  const setIsOpenEdit = useCallback(
    (value?: number) => {
      if (!value) {
        setIsOpen((val) => !val);
        habldeStatusChange(sessionStorage.getItem('currentTabStatus'));
      } else {
        setIsOpen((val) => !val);
        handleTabChange(value);
      }
    },
    [handleTabChange, setIsOpen]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const [beginDateSelected, setBeginDateSelected] = useState<Date | undefined>(
    undefined
  );
  const [endDateSelected, setEndDateSelected] = useState<Date | undefined>(
    undefined
  );
  const handleSubmit = useCallback(
    async (formData: ITroubleJobsForm) => {
      const beginDate = createDateTime(
        formData.beginDate,
        formData.startTime
      ).toDate();
      let endDate: any = null;
      if (formData?.endDate && formData?.endTime) {
        endDate = createDateTime(formData.endDate, formData.endTime).toDate();
      }
      const formatData = formData.sortResponses
        ?.map((val: ISortResponse) => {
          let cancellation;
          if (val.sent) {
            cancellation = false;
          }
          if (val.cancel) {
            cancellation = true;
          }
          return {
            ...val,
            cancellation,
            dspchRem: !val.dspchRem ? ' ' : val.dspchRem,
          };
        })
        .filter((val) => val.cancellation !== undefined)
        .map((val) => {
          const dateTime = moment(val.sortTime)
            .format('YYYY-MM-DD HH:mm')
            .toString();
          const sortDate = Moment(dateTime, 'YYYY-MM-DD HH:mm').utc(
            false
          ) as unknown as Date;
          return { ...val, sortTime: sortDate };
        });
      const response = await actions.updatePUT(Number(currentTab), {
        ...formData,
        beginDate,
        endDate,
        sortResponses: formatData,
      });
      const data = await getTroubleJobParams(
        filterState?.limit === 0
          ? { ...filterState }
          : { page: 1, limit: 50, filters: filterState?.filters },
        currentStatusTab
      );
      if (!response.error) {
        ToastSuccess(Lang.MSG_TROUBLE_JOB_EDIT);
        actions.dataGET(Number(currentTab));
        actions.listGET(data);
        actions.clearDateTimeHistory();
      } else {
        actions.clearDateTimeHistory();
        ToastError(
          `Error on updating trouble job or sending/cancelling swithching data to SORT: 
          ${response.error.message}`
        );
      }
      // setIsOpenEdit(response.payload?.troubleJobId);
    },
    [
      actions,
      state,
      setIsOpenEdit,
      beginDateSelected,
      endDateSelected,
      setBeginDateSelected,
      setEndDateSelected,
    ]
  );

  useEffect(() => {
    if (Number(currentTab) !== 0) {
      actions.dataGET(Number(currentTab));
    }
  }, [currentTab, actions]);

  if (!state.data || !currentTab) {
    return null;
  }
  if (dataStatus) {
    return <ATMLoader active size="massive" inline="centered" />;
  }
  return (
    <TroubleJobsEditView
      formRef={formRef}
      defaultValues={state.data}
      loading={status.fetching}
      loadingElement={statusElement.fetching}
      beginDateSelected={beginDateSelected || ('' as any)}
      endDateSelected={endDateSelected || ('' as any)}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpenEdit={isOpen}
      setIsOpenEdit={setIsOpenEdit}
      setBeginDateSelected={setBeginDateSelected}
      setEndDateSelected={setEndDateSelected}
    />
  );
};

export default TroubleJobsEdit;
