import React from 'react';
import { get } from 'lodash';
import { useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMCheckbox,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMTable,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { AccessRole, LERRequestPage, LERRequestStatus, LERRequestWidth } from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import LERRequestPanel from '../../ler-request-panel/ler-request-panel.component';
import LERRequestAuthorizationNotification from '../ler-request-authorization-notification/ler-request-authorization-notification.component';
import LerRequestRelatedLer from '../../ler-request-related-ler/ler-request-related-ler.component';
import LERRejectionNotes from '../../ler-request-list/ler-request-rejection-notes/ler-request-rejection-notes.component';

type IProps = {
  id?: number;
  isEdit?: boolean;
};
const LERRequestAuthorizationForm: React.FC<IProps> = ({
  id,
  isEdit = false,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useATMFormContext<ILerRequestForm>();
  const { state } = useLerRequestContext();
  const { params: currentTab } = useLocationParams([
    LERRequestPage.Clone,
  ]);
  const data = getValues();

  const { fields, append, remove } = useFieldArray({
    name: 'authorizationStatement.dailyNotes',
    control,
  });

  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <ATMHeader as="h2" content={Lang.TTL_AUTHORIZATION_INFORMATION} />
        <ATMGrid columns="equal">
          <ATMGrid.Row columns="1">
            <ATMGrid.Column width={8}>
              <ATMField
                as={ATMTextArea}
                control={control}
                name="authorizationStatement.authStmnt"
                label={Lang.LBL_OPERATING_NOTE}
                error={errors.authorizationStatement?.authStmnt}
                placeholder={Lang.LBL_ENTER}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column width={8}>
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                control={control}
                name="authorizationStatement.cancelNote.noteId"
              />
              <ATMField
                as={ATMTextArea}
                control={control}
                name="authorizationStatement.cancelNote.noteTxt"
                label={Lang.LBL_CANCELLATION_NOTE}
                error={errors.authorizationStatement?.cancelNote?.noteTxt}
                placeholder={Lang.LBL_ENTER}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          {state.data?.rejectNotes?.length && !currentTab?.lerClone ? (
            <ATMGrid.Row>
              <ATMGrid.Column width={8}>
                <LERRejectionNotes data={state.data} fromForm />
              </ATMGrid.Column>
            </ATMGrid.Row>
          ) : (
            ''
          )}

          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMTable celled>
                <ATMTable.Header>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_DAILY_OUTAGE_REPORT_NOTE}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell>{Lang.LBL_STATUS}</ATMTable.HeaderCell>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_ACTIONS}
                    </ATMTable.HeaderCell>
                  </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                  {fields.map((e, i) => (
                    <ATMTable.Row key={e.id}>
                      <ATMTable.Cell>
                        <ATMField
                          key={`notes_${e.id}`}
                          as={ATMTextArea}
                          control={control}
                          name={`authorizationStatement.dailyNotes[${i}].notes`}
                          error={get(
                            errors,
                            `authorizationStatement.dailyNotes[${i}].notes`
                          )}
                        />
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMField
                          key={`complete_${e.id}`}
                          control={control}
                          name={`authorizationStatement.dailyNotes[${i}].isComplete`}
                          defaultChecked={e.isComplete || false}
                          as={ATMCheckbox}
                          label={Lang.LBL_MARK_COMPLETED}
                          inline
                          onChange={([_, { checked }]) => checked}
                        />
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMButton
                          icon="trash"
                          type="button"
                          onClick={() => remove(i)}
                        />
                      </ATMTable.Cell>
                    </ATMTable.Row>
                  ))}
                </ATMTable.Body>
                <ATMTable.Footer>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell colSpan={3}>
                      <ATMButton
                        secondary
                        type="button"
                        content={Lang.LBL_ADD_NOTE}
                        onClick={() => append({} as any)}
                      />
                    </ATMTable.HeaderCell>
                  </ATMTable.Row>
                </ATMTable.Footer>
              </ATMTable>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row style={!isEdit ? { display: 'none' } : {}}>
            <ATMGrid.Column>
              {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
                (state.data?.requestStat === LERRequestStatus.Approved ||
                  state.data?.requestStat === LERRequestStatus.Scheduled ||
                  state.data?.requestStat === LERRequestStatus.Study) &&
                state.data?.requestId === id && (
                  <LERRequestAuthorizationNotification data={state.data} />
                )}
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row style={!isEdit ? { display: 'none' } : {}}>
            <ATMGrid.Column>
              {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
                state.data?.requestId === id && (
                  <LerRequestRelatedLer
                    grpId={state.data?.grpId ?? null}
                    requestId={state.data?.requestId ?? 0}
                  />
                )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <LERRequestPanel data={data} />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestAuthorizationForm;
