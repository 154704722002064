import React, { useCallback } from 'react';
import { useIssuedKeyContext } from 'src/contexts/issued-key.context';
import Moment from 'src/libraries/moment.library';
import html2pdf from 'html2pdf.js';
import { IIssuedKey } from 'src/models/issued-key.model';
import { getKeyDetailsForm } from 'src/selectors/issued-key.selector';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import KeyDetailsView from './key-details.view';

type IProp = {
  onClose: boolean;
  setOnClose: () => void;
  issuedKeyData: IIssuedKey;
};

const KeyDetails: React.FC<IProp> = ({
  setOnClose,
  issuedKeyData,
  onClose,
}) => {
  const { state } = useIssuedKeyContext();

  const handleDownload = useCallback(() => {
    if (state.data) {
      const form = getKeyDetailsForm(state.data);
      const width = 850;
      const height = document.body.scrollHeight;
      const keyDetailsForm = new DOMParser().parseFromString(form, 'text/html');
      const element = keyDetailsForm.getElementById('element-to-print');
      const options = {
        margin: 5,
        filename: `MCC_${convertKeyNumberToFiveDigits(
          state.data.keyNbr
        )}_Key_History_${Moment().format('YYYYMMDD')}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'px', format: [width, height], orientation: 'portrait' },
      };
      html2pdf(element, options);
    }
  }, [state]);

  return (
    <KeyDetailsView
      onClose={onClose}
      setOnClose={setOnClose}
      issuedKeyData={state.data === undefined ? issuedKeyData : state.data}
      handleDownload={handleDownload}
    />
  );
};

export default KeyDetails;
