import React, { useCallback, useEffect, useState } from 'react';
import { ATMHeader, ATMLabel, MOLRightPanel } from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  AccessRole,
  AccessType,
  LERRequestPage,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import {
  ILerRequestDraft,
  ILerRequestForm,
  ILerRequest,
} from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import Access from 'src/components/atoms/access/access.component';
import { getUser } from 'src/libraries/amplify.library';
import LERRequestForm from '../ler-request-form/ler-request-form.component';
import styles from './ler-request-add.module.scss';

type IContentProps = {
  data?: Partial<ILerRequestDraft>;
  setClose: () => void;
};

const Content: React.FC<IContentProps> = ({ data, setClose }) => {
  const { handleChange } = useLocationParams([
    LERRequestPage.View,
    LERRequestPage.Clone,
  ]);
  const [draft, setDraft] = useState<Partial<ILerRequestDraft> | undefined>(
    data
  );
  const [isLoading, setIsLoading] = useState(false);
  const { state, actions } = useLerRequestContext();
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();
  const draftStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DRAFT_CREATE
  );
  const draftUpdateStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DRAFT_UPDATE
  );
  const createStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_CREATE
  );

  // To clear the current draft if component unmount
  useEffect(() => {
    return () => {
      actions.setDraft();
    };
  }, [actions]);

  const removeUploadHistory = useCallback(() => {
    if (fileState.filename_list?.length) {
      fileAction.deleteAllStashedFiles();
    }
  }, [fileState, fileAction]);

  const handleFileUploadSubmit = useCallback(
    async (responseData: ILerRequest) => {
      // const requestor = `${
      //   responseData?.crewInformation.requestor?.firstName ?? ''
      // }  ${responseData?.crewInformation.requestor?.lastName ?? ''}`;
      let uploader = '';
      if (responseData?.updatedByEmployee) {
        uploader = `${responseData.updatedByEmployee?.firstName} ${responseData.updatedByEmployee?.lastName}`;
      } else {
        uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      }
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.requestId) || '',
        parentFolder: responseData?.requestId as any,
        requestId: responseData?.requestId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.LER_TYPE,
        setting: settingState,
        withParentFolder: true,
      });
    },
    [fileState, settingState]
  );

  const handleSave = useCallback(
    async (formData: Partial<ILerRequestForm>) => {
      if (draft && draft.outageRequestDraftId) {
        const result = await actions.draftPUT(
          draft.outageRequestDraftId,
          formData
        );
        if (!result.error) {
          setDraft(result.payload);
          ToastSuccess(Lang.MSG_LER_REQUEST_SAVE_SUCCESS);
        }
      } else {
        const result = await actions.draftPOST(formData);
        if (!result.error) {
          setDraft(result.payload);
          ToastSuccess(Lang.MSG_LER_REQUEST_SAVE_SUCCESS);
          actions.draftListGET();
        }
      }
    },
    [actions, draft, setDraft]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      setIsLoading(true);
      const result = await actions.createPOST(formData);
      if (result.payload) {
        await handleFileUploadSubmit(result.payload);
        removeUploadHistory();
      }
      if (result.error) {
        removeUploadHistory();
      }

      if (draft && draft.outageRequestDraftId) {
        await actions.draftDELETE(draft.outageRequestDraftId);
      }

      if (!result.error) {
        setClose();
        handleChange({
          [LERRequestPage.View]: result.payload?.requestId,
          [LERRequestPage.Clone]: undefined,
        });
        ToastSuccess(Lang.MSG_LER_REQUEST_CREATE_SUCCESS);

        if (
          result.payload?.requestStat === LERRequestStatus.Approved &&
          hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
        ) {
          actions.setForApproval(true);
          // facilityActions.userGrpDetails(
          //   result.payload?.outageFacility?.outgFacId ?? 0
          // );
          // if (!facilityState.lerUserGrp) {
          //   console.log('UserGrp not triggered');
          //   facilityActions.userGrpDetails(
          //     result.payload?.outageFacility?.outgFacId ?? 0
          //   );
          // }
        }

        if (result.payload?.outageEmail) {
          if (result.payload?.outageEmail?.successOutageEmail?.length) {
            result.payload?.outageEmail?.successOutageEmail.map(
              (emailOutage) => {
                ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
              }
            );
          }
          if (result.payload?.outageEmail?.failedOutageEmail?.length) {
            result.payload?.outageEmail?.failedOutageEmail.map(
              (emailOutage) => {
                ToastError(emailOutage?.message ?? 'Failed to send email');
              }
            );
          }
        }
      }
      setIsLoading(false);
    },
    [actions, draft, setClose, setIsLoading, fileState, fileAction]
  );

  return (
    <LERRequestForm
      header={
        <ul>
          <li>
            <ATMHeader>
              {draft
                ? Lang.formatString(
                    Lang.TTL_LER_TITLE,
                    draft?.outageRequestDraftId ?? ''
                  )
                : Lang.TTL_NEW_LER_REQUEST}
            </ATMHeader>
          </li>
          <li>
            <ATMLabel className={styles.labelDraft}>{Lang.TTL_DRAFT}</ATMLabel>
          </li>
        </ul>
      }
      defaultValues={draft?.content ? draft?.content : undefined}
      handleClose={() => {
        setClose();
        fileAction.deleteAllStashedFiles();
      }}
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      loading={
        draftStatus.fetching ||
        draftUpdateStatus.fetching ||
        createStatus.fetching ||
        isLoading
      }
      draftId={draft?.outageRequestDraftId}
    />
  );
};

type IProps = {
  data?: Partial<ILerRequestDraft>;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?: () => void;
};

const LERRequestAdd: React.FC<IProps> = ({ data, open, trigger, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, setIsOpen]);

  return (
    <Access type={AccessType.OUTAGE_CREATE}>
      <MOLRightPanel
        id="ler-form"
        isOpen={open || isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        trigger={trigger}
        className={styles.floatingPanel}
        width="calc(100vw - 200px)"
        closeOnDimmerClick={false}
      >
        {(open || isOpen) && <Content data={data} setClose={handleClose} />}
      </MOLRightPanel>
    </Access>
  );
};

export default LERRequestAdd;
