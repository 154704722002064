import React, { useCallback, useEffect } from 'react';
import { ATMLoader } from 'shared-it-appmod-ui';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { useSubCategoryContext } from 'src/contexts/sub-category.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { employeeActionTypes } from 'src/ducks/employee.duck';
import { subCategoryActionTypes } from 'src/ducks/sub-category.duck';
import history from 'src/history';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { IAuthorizedList } from 'src/models/authorized-list.model';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import { getEmployeeStatus } from 'src/selectors/employee.selector';
import { getSubCategoryStatus } from 'src/selectors/sub-category.selector';
import AuthListDetailsView from './auth-list-details.view';

type IProp = {
  data?: IAuthorizedList;
  handleTabChange: (
    index?: any,
    callback?: ((data: URLSearchParams) => URLSearchParams) | undefined
  ) => void;
  titleMainBreadcrumb: string;
  breadcrumbPath?: string;
  filterValues?: React.MutableRefObject<Record<any, any>>;
};

const AuthListDetails: React.FC<IProp> = ({
  // data,
  handleTabChange,
  titleMainBreadcrumb,
  breadcrumbPath = '',
}) => {
  const { params: currentTab } = useLocationParams(['id']);
  const { state, actions } = useAuthorizedListContext();
  const { state: subCategoryState, actions: subCategoryActions } =
    useSubCategoryContext();
  const { state: employeeState, actions: employeeActions } =
    useEmployeeContext();
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_READ
  );
  const authStatus = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_BY_EMP_ID_READ
  );
  const employeeStatus = getEmployeeStatus(
    employeeState,
    employeeActionTypes.EMPLOYEE_STAT_UPDATE
  );
  const subCategoryStatus = getSubCategoryStatus(
    subCategoryState,
    subCategoryActionTypes.SUB_CATEGORY_LIST_READ
  ).fetching;

  useEffect(() => {
    subCategoryActions?.listGET();
    actions.empDataGET(currentTab.id);
  }, [currentTab.id, subCategoryActions, actions]);

  const handleEmployeeStat = useCallback(async () => {
    const tempIndicatorData = { tempActiveInd: 'Y' };
    const response = await employeeActions.employeeStatePUT(
      tempIndicatorData,
      currentTab.id
    );
    if (!response.error) {
      subCategoryActions?.listGET();
      ToastSuccess('Employee has been activated');
      actions.empDataGET(currentTab.id);
      history.push(`/auth-list?id=${currentTab.id}`);
    } else {
      ToastError(response.error.message);
    }
  }, [actions, employeeActions, subCategoryActions]);

  if (!state.data) {
    return <ATMLoader active />;
  }

  return (
    <AuthListDetailsView
      titleMainBreadcrumb={titleMainBreadcrumb}
      data={state.data}
      loading={state.data.empId !== currentTab.id && status.fetching}
      suspensionLoading={authStatus.fetching}
      handleTabChange={handleTabChange}
      subCategoryList={subCategoryState.list}
      subCategoryStatus={subCategoryStatus}
      loadingEmployeeStat={employeeStatus.fetching}
      handleEmployeeStat={handleEmployeeStat}
      breadcrumbPath={breadcrumbPath}
    />
  );
};

export default AuthListDetails;
