import React, { useState } from 'react';
import {
  ATMGrid,
  ATMSegment,
  ATMHeader,
  ATMIcon,
  ATMButton,
} from 'shared-it-appmod-ui';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import Lang from 'src/libraries/language';
import { IRolesEmployee } from 'src/models/roles.model';
import {
  ITroubleTicket,
  TroubleTicketCreateFormSchema,
} from 'src/models/trouble-ticket.model';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import { ViewType } from '../trouble-ticket-document/trouble-ticket-document-details.component';
import TroubleTicketForm from '../trouble-ticket-form/trouble-ticket-form.component';
import styles from '../trouble-ticket.module.scss';

type IProps = {
  handleClick: () => void;
  setView: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ITroubleTicket) => void;
  assignToEmp: IRolesEmployee[];
  statusEmployeeRole: boolean;
  view: any;
};

const TroubleTicketAddView: React.FC<IProps> = ({
  formRef,
  setView,
  handleClick,
  handleSubmit,
  assignToEmp,
  statusEmployeeRole,
  view,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { state } = useTroubleTicketContext();

  const createStatus = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_CREATE
  );
  return (
    <>
      <ATMGrid.Column width={16}>
        <ATMSegment className={`applySegment ${styles.segmentStyled}`}>
          <ATMGrid.Row>
            <ATMGrid>
              <ATMGrid.Column width={8} verticalAlign="middle">
                <ATMHeader className={styles.headerIcon}>
                  <ATMIcon
                    circular
                    size="small"
                    color="blue"
                    name="list"
                    style={{
                      fontSize: '0.8em',
                    }}
                  />
                  {Lang.TTL_ADD_TICKET}
                </ATMHeader>
              </ATMGrid.Column>
              <ATMGrid.Column
                width={8}
                textAlign="right"
                verticalAlign="middle"
              >
                <ATMButton
                  disabled={!isDirty || createStatus.fetching}
                  content={Lang.LBL_SUBMIT}
                  primary
                  loading={createStatus.fetching}
                  onClick={handleClick}
                />
                <ATMButton
                  content={Lang.LBL_GO_BACK}
                  secondary
                  onClick={() => setView('')}
                  icon="arrow left"
                  size="small"
                />
              </ATMGrid.Column>
            </ATMGrid>
          </ATMGrid.Row>
        </ATMSegment>
      </ATMGrid.Column>
      <TroubleTicketForm
        action="create"
        formRef={formRef}
        handleSubmit={handleSubmit}
        handleEnable={(value) => setIsDirty(value)}
        validationSchema={TroubleTicketCreateFormSchema}
        assignToEmp={assignToEmp}
        statusEmployeeRole={statusEmployeeRole}
        view={view}
        viewType={ViewType.CREATE}
      />
    </>
  );
};

export default TroubleTicketAddView;
