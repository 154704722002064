import React from 'react';
import { LERRequestFormStep } from 'src/constants';
import {
  ILerRequest,
  ILerRequestForm,
  ILerRequestOutageDate,
  ILerRequestOutageFacility,
} from 'src/models/ler-request.model';
import LERRequestOutageDate from '../ler-request-outage-date/ler-request-outage-date.component';
import LERRequestOutageFacility from '../ler-request-outage-facility/ler-request-outage-facility.component';

type IProps = {
  data: Partial<ILerRequest | ILerRequestForm>;
  handleEdit?: (step: LERRequestFormStep) => void;
  handleDelete?: (key: string) => void;
};

const LERRequestPanel: React.FC<IProps> = ({
  data,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      <LERRequestOutageFacility
        data={data.outageFacility as ILerRequestOutageFacility}
        handleEdit={
          handleEdit
            ? () => handleEdit(LERRequestFormStep.OUTAGE_FACILITY)
            : undefined
        }
      />
      <LERRequestOutageDate
        data={data[LERRequestFormStep.OUTAGE_DATE] as ILerRequestOutageDate[]}
        handleDelete={handleDelete}
        handleEdit={
          handleEdit
            ? () => handleEdit(LERRequestFormStep.OUTAGE_DATE)
            : undefined
        }
      />
    </>
  );
};

export default LERRequestPanel;
