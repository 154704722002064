import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  TsoOssEmployeeFormSchema,
  ITsoOssEmployee,
  ITsoOssEmployeeForm,
} from 'src/models/tso-oss-employee.model';
import TsoOssEmployeeForm from '../tso-oss-employee-form/tso-oss-employee-form.component';

type IProps = {
  defaultValues: ITsoOssEmployee;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ITsoOssEmployeeForm) => void;
};

const TsoOssEmployeeEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          icon={{
            name: 'edit',
          }}
          content={Lang.LBL_EDIT}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>
        {Lang.formatString(
          Lang.TTL_TSO_OSS_EMPLOYEE_EDIT,
          `${defaultValues.empId} - ${defaultValues.fullName ?? '--'}`
        )}
        </ATMModal.Header>
      <ATMModal.Content scrolling>
        <TsoOssEmployeeForm
          defaultValues={TsoOssEmployeeFormSchema.cast(defaultValues)}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          id="close"
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SUBMIT}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default TsoOssEmployeeEditView;
