import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  JobCodeRuleFormSchema,
  IJobCodeRule,
  IJobCodeRuleForm,
} from 'src/models/job-code-rule.model';
import JobCodeRuleForm from '../job-code-rule-form/job-code-rule-form.component';

type IProps = {
  defaultValues: IJobCodeRule;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IJobCodeRuleForm) => void;
};

const JobCodeRuleEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [, setIsError] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={<ATMButton icon="edit" onClick={() => handleOpen(true)} />}
    >
      <ATMModal.Header>
        {Lang.formatString(Lang.TTL_JOB_CODE_RULE_EDIT, defaultValues.jobCd)}
      </ATMModal.Header>
      <ATMModal.Content scrolling>
        <JobCodeRuleForm
          defaultValues={JobCodeRuleFormSchema.cast(defaultValues)}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          setIsError={setIsError}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SAVE}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default JobCodeRuleEditView;
