import { httpClient } from 'src/libraries/http.library';
import {
  ISuspensionListForm,
  SuspensionListDeletePayloadSchema,
  SuspensionListPayloadSchema,
} from 'src/models/suspension-list.model';

const client = httpClient();
const endpoint = 'suspension-list';

const services = {
  createPOST: async (data: ISuspensionListForm) => {
    return client.post(`/${endpoint}`, data, SuspensionListPayloadSchema);
  },

  dataDELETE: async (id?: number) => {
    return client.delete(
      `/${endpoint}/${id}`,
      SuspensionListDeletePayloadSchema
    );
  },
};

export default services;
