import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SubstationEntryReducer, {
  ISubstationEntryState,
  defaultState,
  duckActions,
} from 'src/ducks/substation-entry.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SubstationEntryReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISubstationEntryContext = ReturnType<typeof useReducer>;

const SubstationEntryContext = createContext<Partial<ISubstationEntryContext>>({
  state: defaultState,
}) as React.Context<ISubstationEntryContext>;

type IProps = {
  state?: Partial<ISubstationEntryState>;
};

const SubstationEntryProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SubstationEntryContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SubstationEntryContext.Provider>
  );
};

const useSubstationEntryContext = () => useContext(SubstationEntryContext);

export type IUseSubstationEntryContext = ReturnType<
  typeof useSubstationEntryContext
>;

export {
  SubstationEntryContext,
  SubstationEntryProvider,
  useSubstationEntryContext,
};
