import {
  calendarOutageActionTypes,
  ICalendarOutageState,
} from 'src/ducks/calendar-outage.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getCalendarOutageStatus = (
  state: Partial<ICalendarOutageState>,
  action: keyof typeof calendarOutageActionTypes
): IStatus =>
  (state.status && state.status[action]) ?? {
    fetching: false,
    error: null,
  };
