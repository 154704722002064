import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
// import { workGroupMock } from 'src/models/mocks/work-group.mock';
import {
  IWorkGroupForm,
  WorkGroupListPayloadSchema,
  WorkGroupDeletePayloadSchema,
  WorkGroupPayloadSchema,
  WorkSubGroupValidateSchema,
} from 'src/models/work-group.model';
// import { Asserts } from 'yup';

const client = httpClient();
const endpoint = 'work-group';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, WorkGroupPayloadSchema);
  },

  listGET: async (params: IORGDataTableQueryState) => {
    return client.get(`/${endpoint}`, params, WorkGroupListPayloadSchema);
  },

  createPOST: async (data: IWorkGroupForm) => {
    const body = {
      ...data,
      emailDistribution: data.emailDistribution.toString().replace(/,/g, '; '),
    };
    return client.post(`/${endpoint}`, body, WorkGroupPayloadSchema);
  },

  updatePUT: async (id: number, data: IWorkGroupForm) => {
    const body = {
      ...data,
      emailDistribution: data.emailDistribution.toString().replace(/,/g, '; '),
    };
    return client.put(`/${endpoint}/${id}`, body, WorkGroupPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, WorkGroupDeletePayloadSchema);
  },

  validateSubGrp: async (subGrpId: number, trbGrpId: number) => {
    return client.get(
      `/${endpoint}?action=validateSubGrp`,
      { subGrpId, trbGrpId },
      WorkSubGroupValidateSchema
    );
  },
};

export default services;
