import { useEffect } from 'react';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { facilityActionTypes, IFacilityState } from 'src/ducks/facility.duck';
import { outageTypeActionTypes } from 'src/ducks/outage-type.duck';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { ILerRequest } from 'src/models/ler-request.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getOutageTypeStatus } from 'src/selectors/outage-type.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';

export const useOutageFacility = (data?: ILerRequest) => {
  const { state: outageTypeState, actions: outageTypeActions } =
    useOutageTypeContext();
  const { list: outageTypes } = outageTypeState;
  const outageTypeStatus = getOutageTypeStatus(
    outageTypeState,
    outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ
  );

  const {
    state: { equipment: equipments, volt: volts, ...facilityState },
    actions: facilityActions,
  } = useFacilityContext();

  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { line: lines } = realtimeLogState;
  const realtimeLogStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { list: equipmentTypes } = equipmentTypeState;
  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { list: substations } = substationState;
  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_READ
  );

  useEffect(() => {
    if (
      !outageTypes.length &&
      !outageTypeStatus.fetching &&
      !outageTypeStatus.error
    ) {
      outageTypeActions.activeListGET();
    }
  }, [outageTypeActions, outageTypes, outageTypeStatus]);

  useEffect(() => {
    if (
      !lines.length &&
      !realtimeLogStatus.fetching &&
      !realtimeLogStatus.error
    ) {
      realtimeLogActions.lineGET();
    }
  }, [realtimeLogActions, lines, realtimeLogStatus]);

  useEffect(() => {
    if (
      !substations.length &&
      !substationStatus.fetching &&
      !substationStatus.error
    ) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions, substations, substationStatus]);

  useEffect(() => {
    if (
      !equipmentTypes.length &&
      !equipmentTypeStatus.fetching &&
      !equipmentTypeStatus.error
    ) {
      equipmentTypeActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [equipmentTypeActions, equipmentTypes, equipmentTypeStatus]);

  useEffect(() => {
    // We only fetch these API if in view mode
    if (
      data &&
      data.outageFacility?.facTypId &&
      data.outageFacility?.substationId &&
      data.outageFacility?.voltId &&
      data.outageFacility?.outgFacId &&
      !volts.length &&
      !equipments.length
    ) {
      const equipmentStatus = getFacilityStatus(
        facilityState as IFacilityState,
        facilityActionTypes.FACILITY_EQUIPMENT_READ
      );

      const voltStatus = getFacilityStatus(
        facilityState as IFacilityState,
        facilityActionTypes.FACILITY_VOLT_SUBS_READ
      );

      if (!equipmentStatus.fetching && !voltStatus.fetching) {
        facilityActions.voltSubstationGET(
          data.outageFacility?.substationId,
          data.outageFacility?.facTypId
        );
        facilityActions.equipmentGET(
          data.outageFacility?.facTypId,
          data.outageFacility?.voltId,
          undefined,
          data.outageFacility?.substationId
        );
      }
    }
  }, [
    data,
    volts,
    equipments,
    substationActions,
    facilityActions,
    equipmentTypeActions,
  ]);

  return {
    substations,
    lines,
    outageTypes,
    equipments,
    volts,
    equipmentTypes,
  };
};
