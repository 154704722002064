import React from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMSelect,
  ATMTextArea,
  IORGDataTableColumn,
  ORGDataTable,
  ATMButton,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  ISubstationForm,
  SubstationFormSchema,
} from 'src/models/substation.model';
import history from 'src/history';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import style from '../../real-time-log/real-time-log-list/real-time-log-list.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ISubstationForm>;
  handleSubmit: (data: ISubstationForm) => void;
  handleEnable: (value: boolean) => void;
  details?: boolean;
};
// Mock example, integration missing
const columns: IORGDataTableColumn<any>[] = [
  {
    index: 'document',
    title: 'Uploaded List',
    render: (value) => {
      return (
        <div
          style={{
            fontWeight: 'bold',
            color: '#012957',
            textDecoration: 'underline',
          }}
        >
          {value}
        </div>
      );
    },
  },
  {
    index: 'actions',
    title: 'actions',
    width: '10%',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_, value) => {
      return (
        <ATMButton
          icon="trash alternate outline"
          type="button"
          style={{ padding: '0' }}
          // onClick={() => remove(value.i)}
        />
      );
    },
  },
];

const SubstationEntryForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  details,
}) => {
  // Mock example, integration missing
  const VisitorsList = [
    { document: 'Test Case.doc' },
    { document: 'Test Case1.doc' },
  ];

  const handleFile = () => {};

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues as ISubstationForm}
      validationSchema={SubstationFormSchema}
    >
      {({ control, formState: { errors, isDirty } }) => {
        handleEnable(isDirty);

        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column width={6}>
                {defaultValues ? (
                  <>
                    <FieldReadOnly label={Lang.LBL_SUBSTATION_NAME_EDITVIEW}>
                      {defaultValues.name}
                    </FieldReadOnly>
                    <ATMField
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                      name="name"
                      control={control}
                      // error={errors.substationId}
                    />
                  </>
                ) : (
                  <ATMField
                    as={ATMInput}
                    label={
                      <LabelRequired>{Lang.LBL_SUBSTATION_ID}</LabelRequired>
                    }
                    name="substationId"
                    control={control}
                    error={errors.substationId}
                  />
                )}
              </ATMGrid.Column>
              <ATMGrid.Column width={6}>
                {defaultValues ? (
                  <>
                    <FieldReadOnly label={Lang.LBL_ID}>
                      {defaultValues.substationId}
                    </FieldReadOnly>
                    <ATMField
                      as={ATMInput}
                      type="hidden"
                      className="hidden"
                      name="substationId"
                      control={control}
                      // error={errors.substationId}
                    />
                  </>
                ) : (
                  <ATMField
                    as={ATMInput}
                    label={
                      <LabelRequired>{Lang.LBL_SUBSTATION_NAME}</LabelRequired>
                    }
                    name="name"
                    control={control}
                    error={errors.name}
                  />
                )}
              </ATMGrid.Column>
            </ATMGrid.Row>
            {!details ? (
              <>
                <ATMGrid.Row>
                  <ATMGrid.Column width={6}>
                    <ATMField
                      as={ATMSelect}
                      label={Lang.LBL_SUBSTATION_ACTIVITY}
                      name="addr1"
                      control={control}
                      error={errors.addr1}
                      options={[
                        'Maintenance',
                        'Restore',
                        'Others activities',
                      ].map((value) => ({
                        key: value,
                        value,
                        text: value,
                      }))}
                      onChange={([_, { value }]) => value}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={6}>
                    <ATMField
                      as={ATMInput}
                      label={Lang.LBL_SUBSTATION_TIME_IN}
                      name="updatedAt"
                      control={control}
                      error={errors.updatedAt}
                      icon="clock outline"
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={6}>
                    <ATMField
                      as={ATMInput}
                      label={Lang.LBL_SUBSTATION_TIME_ON}
                      name="updatedAt"
                      control={control}
                      error={errors.updatedAt}
                      icon="clock outline"
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={12}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_SUBSTATION_PURPOSE}
                      name="zipCd"
                      control={control}
                      error={errors.zipCd}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={12}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_SUBSTATION_VISITORS}
                      name="phnNbr"
                      control={control}
                      error={errors.phnNbr}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  {/* Integration missing to adapt with Visitors List key property */}
                  {defaultValues?.phnNbr ? (
                    <ATMGrid.Column width={12}>
                      <FieldReadOnly
                        label={Lang.LBL_SUBSTATION_VISITORS_UPLOADED_LIST}
                      >
                        <ORGDataTable
                          columns={columns}
                          data={VisitorsList}
                          sortable
                          filteredBy
                          celled={false}
                          location={history.location}
                          handleLocation={history.push}
                          showPagination={false}
                          className={style.tableRealTime}
                        />
                      </FieldReadOnly>
                      <FileUploader
                        // file={file}
                        onSuccess={handleFile}
                        icon="upload"
                        text={Lang.LBL_ADD}
                        // error={get(errors, 'file.fileName.message')}
                      />
                    </ATMGrid.Column>
                  ) : (
                    <ATMGrid.Column width={4}>
                      <FileUploader
                        // file={file}
                        onSuccess={handleFile}
                        icon="upload"
                        text={Lang.LBL_SUBSTATION_UPLOAD_VISITORS}
                        // error={get(errors, 'file.fileName.message')}
                      />
                    </ATMGrid.Column>
                  )}
                </ATMGrid.Row>
              </>
            ) : (
              <>
                <ATMGrid.Row>
                  <ATMGrid.Column width={12}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_SUBSTATION_ADDRESS}
                      name="addr1"
                      control={control}
                      error={errors.addr1}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={6}>
                    <ATMField
                      as={ATMInput}
                      label={Lang.LBL_SUBSTATION_PHONE_CODE}
                      name="areaCd"
                      control={control}
                      error={errors.areaCd}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={6}>
                    <ATMField
                      as={ATMInput}
                      label={Lang.LBL_SUBSTATION_PHONE}
                      name="phnNbr"
                      // defaultValue={}
                      control={control}
                      error={errors.phnNbr}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </>
            )}
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default SubstationEntryForm;
