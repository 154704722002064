/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { LERRequestStatus } from 'src/constants';
import {
  dailyReportsActionTypes,
  IDailyReportsState,
} from 'src/ducks/daily-reports.duck';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import { IDailyReport, ILerReport } from 'src/models/daily-reports.model';
import { ILerRequestRelatedLerItem } from 'src/models/ler-request.model';
import Lang from 'src/libraries/language';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
} from './ler-request.selector';

export const getDailyReportsStatus = (
  state: IDailyReportsState,
  action: keyof typeof dailyReportsActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getLerDetailsForm = (
  data: IDailyReport[],
  date: Date,
  relatedLer?: ILerRequestRelatedLerItem[]
): string => {
  return `${data
    .map((item, i) => {
      let operationNotes = item.authStmnt
        ? item.authStmnt.replace(/(?:\r\n|\r|\n)/g, '<br />')
        : '';
      operationNotes =
        item.repeatJob === true &&
        !operationNotes.toLowerCase().includes('repeat job')
          ? `Repeat Job ${operationNotes ? '<br />' : ''}${operationNotes}`
          : operationNotes;

      return `<div id="page${i}">
    <h3> LER# ${item.requestId} </h3>  
    <h4> ${moment(date, 'YYYY-MM-DD HH:mm:ss')
      .format('dddd')
      .substring(0, 3)} ${moment(date).format('MM/DD/YYYY')} </h4>    
    <hr>
    <p> <b> Job Info </b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%;"> <b>Work Order#</b> </td> <td> ${
        item.woNbr ?? ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Voltage</b> </td> <td> ${
        item.voltNm
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Facilities</b> </td> <td> ${
        item.outgFacNm
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Work Description</b> </td> <td> ${
        item.wrkDesc ? item.wrkDesc.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>ETR</b> </td> <td>  ${
        item.etr ?? ''
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Job Location</b> </td> <td>  ${
        item.jobLoc ? item.jobLoc.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Authorization Type</b> </td> <td>  ${
        item.outageTypeDesc
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Requires Grounding Statement</b> </td> <td style="vertical-align:top">  ${
        item.clsdGrndDsconctInd ? Lang.LBL_YES : Lang.LBL_NO
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Test Voltage to be Applied</b> </td> <td>  ${
        item.testVoltageApplied ? Lang.LBL_YES : Lang.LBL_NO
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>System Changes</b> </td> <td>  ${
        item?.sysChngInd
          ? `System changes are required on ${formatDate(item.sysChngLastDate)}`
          : ''
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Operation Notes</b> </td> <td>  ${operationNotes}
      <tr> <td style="text-align:right; vertical-align:top"> <b>Related LERs</b> </td> <td>
      <table style="width:23% font-size:11px; margin-top: -3px;">
      <tr>
      ${
        relatedLer &&
        relatedLer.filter((v) => v.grpId === item.grpId).length > 0
          ? `<th style="vertical-align:top">${' '}</th>
          <th style="vertical-align:top">  LER#  </th> 
          <th style="vertical-align:top">  KV  </th> 
          <th style="vertical-align:top">  Facility  </th> 
          <th style="vertical-align:top">  Authorization  </th> 
          <th style="vertical-align:top">  Dates  </th> 
          <th style="vertical-align:top">  Status  </th>`
          : '<td style="vertical-align:top"> (None) </td>'
      } </tr>
      ${
        relatedLer &&
        relatedLer
          .filter((v) => v.grpId === item.grpId)
          .map((v) => {
            if (v.requestId !== item.requestId) {
              return `<tr>
              <td>${' '}</td>
              <td style="padding-right: .9em">${v.requestId}</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.facility?.volt?.voltNm ?? ''
              } kV</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.facility?.outgFacNm
              }</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.outageType?.outageTypeDesc
              }</td>
              <td style="padding-right: .9em">${getLerRequestStartDate(
                v?.outageDates as any
              )} - ${getLerRequestEndDate(v?.outageDates as any)}</td><td>${
                LERRequestStatus[`${v.requestStat}`]
              }</td>
              </tr>`;
            }
            return '';
          })
          .join('')
      }
      </table>
      <tr> <td style="text-align:right; width: 23%;"> <b>TB Coord</b> </td> <td>  ${
        item.facTbsCoord ?? ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Daily Outage Report Notes</b> </td> <td style="font-size:10px;">  ${
        item.dailyNotes?.map((dailyNote) => dailyNote).join(' | ') ?? ''
      } </td> </tr>
      </table> 
    <hr>
    <p> <b> Isolation Points </b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%; vertical-align:top;"> <b>Isolation Points</b> </td> <td> ${
        item.isolationPoints
          ? item.isolationPoints.replace(/(?:\r\n|\r|\n)/g, '<br />')
          : ''
      } </td> </tr>
    </table>
    <hr>
    <p> <b> Outage Info</b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
    <tr> <td style="text-align:right;"> <b>Start (ISO/Crew) </b> </td> <td> ${
      item.startTmTxt
    } </td> </tr>
    <tr> <td style="text-align:right;"> <b>Stop (Crew/ISO)</b> </td> <td> ${
      item.stopTmTxt
    } </td> </tr>
    <tr> <td style="text-align:right;"> <b>Recall Time</b> </td> <td> ${
      item.recalTm ?? '-'
    } Hr </td> </tr>
    <tr> <td style="text-align:right;"> <b>ISO Status</b> </td> ${
      item.omsId
        ? `<td> ${
            item.farFan
              ? item.farFan === 'FAN'
                ? item.farFan
                : `<b>${item.farFan}</b>`
              : ''
          }/${item.omsId ?? ''} [${item.outageStatus ?? ''}] </td>`
        : '<td> </td>'
    } </tr>
  </table>
    <hr>
    <p> <b> Contact Info</b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%;"> <b>Crew Name</b> </td> <td> ${
        item.crewName ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Crew Contact#</b> </td> <td> ${
        item.crewCellNbr ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Requestor</b> </td> <td> ${
        item.requestorName ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Requestor #</b> </td> <td>  ${
        item.requestorCellNbr ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Field Check By</b> </td> <td>  ${
        item.fldckBy ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>GF/CA</b> </td> <td>  ${
        item.genlFormnCtrctAdmin ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Accepted By</b> </td> <td>  ${
        item.acceptorName ?? ''
      } ${item.acceptorCellNbr ?? ''}</td> </tr>
    </table>    
    ${
      item.sortResponses
        ? `<br><br><hr>        
      <p> <b> Switching Info</b> </p>
      <hr style="border: 2px solid black;">
      <table style="table-layout:fixed; font-size:11px;">
        <tr> <td style="width: 80%"> <b>Location</b> </td> <td> <b> Time </b> </td> </tr>
        ${
          item.sortResponses &&
          item.sortResponses
            .sort((a, b) => (a?.sortTime as any) - (b?.sortTime as any))
            .map(
              (v) =>
                `<tr> <td> ${v.substationId ?? v.tlSub ?? ''} </td> <td> ${
                  format24hTime(v.sortTime) ?? ''
                } </td> </tr>`
            )
            .join('')
        }
      </table>`
        : ''
    }
    </div>`;
    })
    .join('')}`;
};

export const getLerReportFormat = (
  data: ILerReport[],
  date: Date,
  relatedLer?: ILerRequestRelatedLerItem[]
): string => {
  return `${data
    .map((item, i) => {
      let operationNotes = item.authStmnt
        ? item.authStmnt.replace(/(?:\r\n|\r|\n)/g, '<br />')
        : '';
      operationNotes =
        item.repeatJob === true &&
        !operationNotes.toLowerCase().includes('repeat job')
          ? `Repeat Job ${operationNotes ? '<br />' : ''}${operationNotes}`
          : operationNotes;

      return `<div id="page${i}">
    <h3> LER# ${item.requestId} </h3>  
    <h4> ${moment(date, 'YYYY-MM-DD HH:mm:ss')
      .format('dddd')
      .substring(0, 3)} ${moment(date).format('MM/DD/YYYY')} </h4>    
    <hr>
    <p> <b> Job Info </b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%;"> <b>Work Order#</b> </td> <td> ${
        item.woNbr ?? ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Voltage</b> </td> <td> ${
        item.voltNm
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Facilities</b> </td> <td> ${
        item.outgFacNm
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Work Description</b> </td> <td> ${
        item.wrkDesc ? item.wrkDesc.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>ETR</b> </td> <td>  ${
        item.etr ?? ''
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Job Location</b> </td> <td>  ${
        item.jobLoc ? item.jobLoc.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Authorization Type</b> </td> <td>  ${
        item.outageTypeDesc
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Requires Grounding Statement</b> </td> <td style="vertical-align:top">  ${
        item.clsdGrndDsconctInd ? Lang.LBL_YES : Lang.LBL_NO
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Test Voltage to be Applied</b> </td> <td>  ${
        item.testVoltageApplied ? Lang.LBL_YES : Lang.LBL_NO
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>System Changes</b> </td> <td>  ${
        item?.sysChngInd
          ? `System changes are required on ${formatDate(item.sysChngLastDate)}`
          : ''
      }</td></tr>
      <tr> <td style="text-align:right; vertical-align:top"> <b>Operation Notes</b> </td> <td>  ${operationNotes}
      <tr> <td style="text-align:right; vertical-align:top"> <b>Related LERs</b> </td> <td>
      <table style="width:23% font-size:11px; margin-top: -3px;">
      <tr>
      ${
        relatedLer &&
        relatedLer.filter((v) => v.grpId === item.grpId).length > 0
          ? `<th style="vertical-align:top">${' '}</th>
          <th style="vertical-align:top">  LER#  </th> 
          <th style="vertical-align:top">  KV  </th> 
          <th style="vertical-align:top">  Facility  </th> 
          <th style="vertical-align:top">  Authorization  </th> 
          <th style="vertical-align:top">  Dates  </th> 
          <th style="vertical-align:top">  Status  </th>`
          : '<td style="vertical-align:top"> (None) </td>'
      } </tr>
      ${
        relatedLer &&
        relatedLer
          .filter((v) => v.grpId === item.grpId)
          .map((v) => {
            if (v.requestId !== item.requestId) {
              return `<tr>
              <td>${' '}</td>
              <td style="padding-right: .9em">${v.requestId}</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.facility?.volt?.voltNm ?? ''
              } kV</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.facility?.outgFacNm
              }</td>
              <td style="padding-right: .9em">${
                v.outageFacility?.outageType?.outageTypeDesc
              }</td>
              <td style="padding-right: .9em">${getLerRequestStartDate(
                v?.outageDates as any
              )} - ${getLerRequestEndDate(v?.outageDates as any)}</td><td>${
                LERRequestStatus[`${v.requestStat}`]
              }</td>
              </tr>`;
            }
            return '';
          })
          .join('')
      }
      </table>
      <tr> <td style="text-align:right; width: 23%;"> <b>TB Coord</b> </td> <td>  ${
        item.facTbsCoord ?? ''
      }</td></tr>
      <tr> <td style="text-align:right;"> <b>Daily Outage Report Notes</b> </td> <td style="font-size:10px;">  ${
        item.dailyNotes?.map((dailyNote) => dailyNote).join(' | ') ?? ''
      } </td> </tr>
      </table> 
    <hr>
    <p> <b> Isolation Points </b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%; vertical-align:top;"> <b>Isolation Points</b> </td> <td> ${
        item.isolationPoints
          ? item.isolationPoints.replace(/(?:\r\n|\r|\n)/g, '<br />')
          : ''
      } </td> </tr>
    </table>
    <hr>
    <p> <b> Outage Info</b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
    <tr> <td style="text-align:right;"> <b>Start (ISO/Crew) </b> </td> <td> ${
      item.startTmTxt
    } </td> </tr>
    <tr> <td style="text-align:right;"> <b>Stop (Crew/ISO)</b> </td> <td> ${
      item.stopTmTxt
    } </td> </tr>
    <tr> <td style="text-align:right;"> <b>Recall Time</b> </td> <td> ${
      item.recalTm ?? '-'
    } Hr </td> </tr>
    <tr> <td style="text-align:right;"> <b>ISO Status</b> </td> ${
      item.omsId
        ? `<td> ${
            item.farFan
              ? item.farFan === 'FAN'
                ? item.farFan
                : `<b>${item.farFan}</b>`
              : ''
          }/${item.omsId ?? ''} [${item.outageStatus ?? ''}] </td>`
        : '<td> </td>'
    } </tr>
  </table>
    <hr>
    <p> <b> Contact Info</b> </p>
    <hr style="border: 2px solid black;">
    <table style="table-layout:fixed; font-size:11px;">
      <tr> <td style="text-align:right; width: 23%;"> <b>Crew Name</b> </td> <td> ${
        item.crewName ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Crew Contact#</b> </td> <td> ${
        item.crewCellNbr ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Requestor</b> </td> <td> ${
        item.requestorName ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Requestor #</b> </td> <td>  ${
        item.requestorCellNbr ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Field Check By</b> </td> <td>  ${
        item.fldckBy ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>GF/CA</b> </td> <td>  ${
        item.genlFormnCtrctAdmin ?? ''
      }</td> </tr>
      <tr> <td style="text-align:right;"> <b>Accepted By</b> </td> <td>  ${
        item.acceptorName ?? ''
      } ${item.acceptorCellNbr ?? ''}</td> </tr>
    </table>    
    ${
      item.sortResponses
        ? `<br><br><hr>        
      <p> <b> Switching Info</b> </p>
      <hr style="border: 2px solid black;">
      <table style="table-layout:fixed; font-size:11px;">
        <tr> <td style="width: 80%"> <b>Location</b> </td> <td> <b> Time </b> </td> </tr>
        ${
          item.sortResponses &&
          item.sortResponses
            .sort((a, b) => (a?.sortTime as any) - (b?.sortTime as any))
            .map(
              (v) =>
                `<tr> <td> ${v.substationId ?? v.tlSub ?? ''} </td> <td> ${
                  format24hTime(v.sortTime) ?? ''
                } </td> </tr>`
            )
            .join('')
        }
      </table>`
        : ''
    }
    </div>`;
    })
    .join('')}`;
};
