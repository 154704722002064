import { httpClient } from 'src/libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
// import {
//   troubleTicketMock,
//   // troubleTicketPayloadMock,
// } from 'src/models/mocks/trouble-ticket.mock';
import {
  ITroubleTicket,
  ITroubleTicketForm,
  TroubleTicketAuditListPayloadSchema,
  TroubleTicketListPayloadSchema,
  // TroubleTicketDeletePayloadSchema,
  TroubleTicketPayloadSchema,
  TroubleTicketSchema,
} from 'src/models/trouble-ticket.model';

const client = httpClient();
const endpoint = 'trouble-ticket';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, TroubleTicketPayloadSchema);
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    // return client.get(`/${endpoint}`, {}, TroubleTicketListPayloadSchema);
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, TroubleTicketListPayloadSchema);
  },

  listDataGET: async (data: any = {}) => {
    // return client.get(`/${endpoint}`, {}, TroubleTicketListPayloadSchema);
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, TroubleTicketListPayloadSchema);
  },

  createPOST: async (data: ITroubleTicket) => {
    return client.post(`/${endpoint}`, data, TroubleTicketSchema);
  },

  updatePUT: async (id: number, data: ITroubleTicket) => {
    return client.put(`/${endpoint}/${id}`, data, TroubleTicketPayloadSchema);
  },

  updateForwardPUT: async (id: number, data: ITroubleTicketForm) => {
    return client.put(
      `/${endpoint}/${id}?action=forward`,
      data,
      TroubleTicketPayloadSchema
    );
  },

  dataDELETE: async (id: number) => {
    // return client.delete(
    //   `/${endpoint}/${id}`,
    //   TroubleTicketDeletePayloadSchema
    // );
    return new Promise((resolve) => {
      resolve({ success: true, id });
    });
  },

  auditReportListGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    // return client.get(`/${endpoint}`, {}, TroubleTicketListPayloadSchema);
    const params: Record<string, any> = {
      ...data,
    };
    return client.get(
      `/${endpoint}?action=auditReport`,
      params,
      TroubleTicketAuditListPayloadSchema
    );
  },
};

export default services;
