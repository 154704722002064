import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { useFacilityContext } from 'src/contexts/facility.context';
import Lang from 'src/libraries/language';

type IProp = {
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  isInUse: boolean | null | undefined;
};

const FacilityDelete: React.FC<IProp> = ({
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
  isInUse,
}) => {
  const { state } = useFacilityContext();

  const facility = state.data;

  return (
    <ATMModal
      open={isOpen}
      size="tiny"
      trigger={
        <ATMButton
          secondary
          content={Lang.LBL_DELETE}
          onClick={() => handleOpen(true)}
          disabled={isInUse ?? false}
        />
      }
    >
      <ATMModal.Header>{Lang.TTL_FACILITIES_DELETE}</ATMModal.Header>
      <ATMModal.Content>
        <p>
          {'Are you sure you want to delete '}
          <strong>{facility?.outgFacNm}</strong>?
        </p>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          negative
          content={Lang.LBL_YES_DELETE}
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default FacilityDelete;
