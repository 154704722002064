import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  IIssuedKeyForm,
  IssuedKeyListPayloadSchema,
  IssuedKeyPayloadSchema,
} from 'src/models/issued-key.model';
import { issuedKeyMock } from 'src/models/mocks/issued-key.mock';
import { Asserts } from 'yup';

const client = httpClient();
const endpoint = 'key';

const services = {
  dataGET: async (key: number) => {
    return client.get(`/${endpoint}/${key}`, {}, IssuedKeyPayloadSchema);
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, IssuedKeyListPayloadSchema);
  },

  createPOST: async (data: IIssuedKeyForm) => {
    return new Promise<Asserts<typeof IssuedKeyPayloadSchema>>((resolve) => {
      const list = { ...data, ...issuedKeyMock() };
      resolve({ ...list });
    });
  },

  updatePUT: async (id: number, data: IIssuedKeyForm) => {
    return client.put(`/${endpoint}/${id}`, data, IssuedKeyPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    // return client.delete(`/${endpoint}/${id}`, IssuedKeyDeletePayloadSchema);
    return new Promise((resolve) => {
      resolve({ success: true, id });
    });
  },
};

export default services;
