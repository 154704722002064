import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IShortDescription,
  IShortDescriptionCategory,
  IShortDescriptionCategoryForm,
  IShortDescriptionCategoryList,
} from 'src/models/short-description.model';
import CategoryDelete from '../category-delete/category-delete.component';
import CategoryForm from '../category-form/category-form.component';

type IProps = {
  defaultValues: IShortDescriptionCategoryList;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IShortDescriptionCategoryForm) => void;
};

const CategoryEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          icon="edit"
          content={Lang.LBL_EDIT}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>{Lang.LBL_EDIT_DESCRIPTION}</ATMModal.Header>
      <ATMModal.Content scrolling>
        <CategoryForm
          defaultValues={defaultValues}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          handleEditOpen={handleOpen}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <CategoryDelete
          handleEditOpen={handleOpen}
          data={
            {
              ...defaultValues,
              ...defaultValues.descriptions[0],
            } as IShortDescription & IShortDescriptionCategory
          }
        />
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default CategoryEditView;
