import React, { useCallback, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { List } from 'semantic-ui-react';
import {
  ATMButton,
  ATMCheckbox,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMInput,
  ATMModal,
  ATMSelect,
  ATMTable,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import PopoverMore from 'src/components/atoms/popover-more/popover-more.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { LEREmailType } from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  ILerRequestEmailNotification,
  LerRequestEmailNotificationSchema,
} from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import styles from '../../ler-request-authorization.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (formData: ILerRequestEmailNotification) => void;
  notification: ILerRequestEmailNotification;
  data?: ILerRequest;
};

type FormProp = {
  notification: ILerRequestEmailNotification;
};

const FormContent: React.FC<FormProp> = ({ notification }) => {
  const { control } = useATMFormContext<ILerRequestEmailNotification>();
  const { state: facilityState } = useFacilityContext();

  const emailExists = notification.emailGroups.map((val) => val.groupId);

  const groupOptions =
    (facilityState.lerUserGrp &&
      facilityState.lerUserGrp
        .map((val) => {
          return {
            key: val.userGrpId,
            text: val.userGrpNm,
            value: val.userGrpId,
          };
        })
        .filter((val) => !emailExists.includes(val.value))) ??
    [];

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emailGroups',
  });

  const handleAddmore = useCallback(() => {
    append({
      groupId: undefined,
      grpNm: '',
      emailType: '',
      userGroup: null,
      isSend: true,
    });
  }, [append]);

  const handleSeletedGroup = useCallback(
    (groupId: number, index: number) => {
      remove(index);
      const item =
        facilityState.lerUserGrp &&
        facilityState.lerUserGrp.filter((val) => val.userGrpId === groupId)[0];
      append({
        groupId: item?.userGrpId,
        grpNm: item?.userGrpNm,
        emailType: LEREmailType.NOTIFICATION,
        userGroup: {
          userGrpEmail: item?.userGrpEmail,
          userGroupMembers:
            item?.userGrpMembers?.map((val) => {
              return {
                empId: val.empId ?? '',
                emailAdd: val.emailAdd ?? 'EAves@scgcontractor.com',
              };
            }) ?? [],
        },
        isSend: true,
      });
    },
    [append, remove, control]
  );
  return (
    <div>
      <ATMField
        label={Lang.LBL_EMAIL_BODY}
        as={ATMTextArea}
        control={control}
        name="emailBody"
        defaultValue={notification.emailBody}
      />
      <ATMField
        as={ATMInput}
        name="requestId"
        control={control}
        type="hidden"
        className="hidden"
        defaultValue={notification.requestId}
      />
      <ATMField
        as={ATMInput}
        name="version_no"
        control={control}
        type="hidden"
        className="hidden"
        defaultValue={notification.version_no}
      />

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_GROUP_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_EMAILS}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell className={styles.emailHeader}>
              {Lang.LBL_SEND_EMAIL}
            </ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {notification.emailGroups?.map((group, index) => (
            <ATMTable.Row key={index}>
              <ATMTable.Cell>{group.grpNm}</ATMTable.Cell>
              <ATMTable.Cell>
                <PopoverMore
                  list={group.userGroup?.userGroupMembers?.map(
                    (val) => val.emailAdd ?? ''
                  )}
                  content={
                    <List bulleted>
                      {group.userGroup?.userGroupMembers?.map((val, key) => (
                        <List.Item key={key}>{val.emailAdd}</List.Item>
                      ))}
                    </List>
                  }
                />
              </ATMTable.Cell>
              <ATMTable.Cell>
                <div className={styles.emailAction}>
                  <ATMField
                    as={ATMSelect}
                    name={`emailGroups[${index}].emailType`}
                    clearable
                    disabled
                    defaultValue={group.emailType}
                    options={Object.entries(LEREmailType).map(([_, value]) => ({
                      key: value,
                      text: Lang.LER_EMAIL_TYPE[value],
                      value,
                    }))}
                  />
                </div>
              </ATMTable.Cell>
            </ATMTable.Row>
          ))}
          {fields?.map((item, index) => {
            return (
              <ATMTable.Row key={item.id}>
                <ATMTable.Cell>
                  <ATMField
                    as={ATMSelect}
                    name={`emailGroups[${index}].groupId`}
                    control={control}
                    options={groupOptions}
                    clearable
                    onChange={([_, { value }]) => {
                      handleSeletedGroup(value, index);
                      return value;
                    }}
                    defaultValue={item.groupId}
                  />
                  <ATMField
                    as={ATMInput}
                    name={`emailGroups[${index}].grpNm`}
                    control={control}
                    className="hidden"
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {!item.groupId ? (
                    ''
                  ) : (
                    <>
                      <ATMField
                        as={ATMInput}
                        name={`emailGroups[${index}].userGroup.userGroupMembers`}
                        control={control}
                        type="hidden"
                        className="hidden"
                        defaultValue={item.userGroup?.userGroupMembers}
                      />
                      <ATMField
                        as={ATMInput}
                        name={`emailGroups[${index}].userGroup.userGrpEmail`}
                        control={control}
                        type="hidden"
                        className="hidden"
                        defaultValue={item.userGroup?.userGrpEmail}
                      />
                      <PopoverMore
                        list={item.userGroup?.userGroupMembers?.map(
                          (val) => val.emailAdd ?? ''
                        )}
                        content={
                          <List bulleted>
                            {item.userGroup?.userGroupMembers?.map(
                              (val, key) => (
                                <List.Item key={key}>{val.emailAdd}</List.Item>
                              )
                            )}
                          </List>
                        }
                      />
                    </>
                  )}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {item.emailType ? (
                    <div className={styles.emailAction}>
                      <ATMField
                        as={ATMCheckbox}
                        name={`emailGroups[${index}].isSend`}
                        control={control}
                        onChange={([_, { checked }]) => {
                          return checked;
                        }}
                        className="hidden"
                        defaultValue={item.isSend}
                      />
                      <ATMField
                        as={ATMSelect}
                        name={`emailGroups[${index}].emailType`}
                        control={control}
                        options={Object.entries(LEREmailType).map(
                          ([_, value]) => ({
                            key: value,
                            text: Lang.LER_EMAIL_TYPE[value],
                            value,
                          })
                        )}
                        clearable
                        onChange={([_, { value }]) => value}
                        defaultValue={item.emailType}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </ATMTable.Cell>
              </ATMTable.Row>
            );
          })}
          <ATMTable.Row>
            <ATMTable.Cell colspan="3">
              <ATMButton
                small
                secondary
                icon="plus"
                content={Lang.LBL_ADD_MORE}
                className={styles.addMoreBtn}
                onClick={handleAddmore}
                type="button"
              />
            </ATMTable.Cell>
          </ATMTable.Row>
        </ATMTable.Body>
      </ATMTable>
    </div>
  );
};

const EmailForm: React.FC<IProp> = ({
  formRef,
  handleSubmit,
  notification,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      validationSchema={LerRequestEmailNotificationSchema}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <FormContent notification={notification} />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

type IProps = {
  isOpen: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: ILerRequest;
};
const UserGroupAddEmail: React.FC<IProps> = ({ isOpen, handleOpen, data }) => {
  const { state, actions } = useLerRequestContext();

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE
  );

  const formRef = useRef<HTMLFormElement>(null);
  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ILerRequestEmailNotification) => {
      const response = await actions.sendMultipleEmailPOST(formData);
      if (!response.error) {
        ToastSuccess(Lang.MSG_EMAIL_GROUP_SEND_MULTIPLE_SUCCESS);
        handleOpen(false);
        actions.dataGET(formData.requestId);
      } else {
        ToastError(response.error.message);
      }
    },
    [actions]
  );
  const notification: ILerRequestEmailNotification = {
    requestId: data?.requestId ?? 0,
    version_no: data?.version_no ?? 1,
    emailBody:
      data?.authorizationStatement?.emailGroups &&
      data?.authorizationStatement?.emailGroups[0]?.emailBody,
    emailGroups:
      data?.authorizationStatement?.emailGroups?.map((val) => {
        return {
          groupId: val.groupId,
          grpNm: val.userGroup.userGrpNm,
          emailType: val.emailType,
          userGroup: {
            userGrpEmail: val.userGroup.userGrpEmail,
            userGroupMembers:
              val.userGroup.userGrpMembers?.map((item) => {
                return {
                  empId: item.empId ?? '',
                  emailAdd: item.emailAdd ?? '',
                };
              }) ?? [],
          },
          isSend: false,
        };
      }) ?? [],
  };

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMButton
          secondary
          size="small"
          floated="right"
          content={Lang.LBL_ADD_EMAILS}
          onClick={() => handleOpen(true)}
          type="button"
        />
      }
    >
      <ATMModal.Header>{Lang.LBL_SEND_EMAIL}</ATMModal.Header>
      <ATMModal.Content scrolling>
        <EmailForm
          handleSubmit={handleSubmit}
          notification={notification}
          formRef={formRef}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
          type="button"
        />
        <ATMButton
          primary
          content={Lang.LBL_SEND_EMAIL}
          disabled={loading.fetching}
          onClick={handleClick}
          loading={loading.fetching}
          id="save"
          type="button"
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default UserGroupAddEmail;
