import yup from 'src/libraries/validator.library';
import { EmployeeShapeKeyReq } from './key-request.model';

export const EmpSickSchema = {
  userType: yup.string(),
  emp: yup
    .object({
      ...EmployeeShapeKeyReq,
      middleName: yup.string().nullable(),
      email: yup.string().nullable(),
    })
    .nullable(),
};

export const ReasonSchema = {
  sickTypeDesc: yup.string(),
};

export const SickListShape = {
  empId: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string(),
  sickEmpEntryId: yup.number(),
  sickTypeId: yup.number().required(),
  empSick: yup.object(EmpSickSchema),
  reason: yup.object(ReasonSchema),
};

export const SickTypesShape = {
  sickTypeId: yup.number().required(),
  sickTypeDesc: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const SickListSchema = yup.object(SickListShape);

export const SickTypesSchema = yup.object(SickTypesShape);

export const SickListFormSchema = yup.object({
  empId: yup.string().required(),
  updatedAt: yup.date(),
  sickTypeId: yup.number().required(),
});

export const SickTypesListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SickTypesSchema.clone()),
});

export const SickListListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SickListSchema.clone()),
});

export const SickListPayloadSchema = SickListSchema.defined();

export const SickTypesPayloadSchema = yup.object({
  sickTypes: SickTypesSchema.clone(),
});

export const SickListDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ISickList = yup.Asserts<typeof SickListSchema>;
export type ISickTypes = yup.Asserts<typeof SickTypesSchema>;
export type ISickListForm = yup.Asserts<typeof SickListFormSchema>;
