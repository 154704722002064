import { httpClient } from 'src/libraries/http.library';
import { TrainingHistoryListPayloadSchema } from 'src/models/training-history.model';

const client = httpClient();
const endpoint = 'auth-list';

const services = {
  listGET: async (employeeId: number) => {
    return client.get(
      `/${endpoint}`,
      { employeeId },
      TrainingHistoryListPayloadSchema
    );
  },
};

export default services;
