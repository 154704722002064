import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import styles from './ler-request-list-pending-caiso.module.scss';

type IProps = {
  data: ILerRequest;
  handleFetch: () => void;
};

const PendingCaisoRemoveFromList: React.FC<IProps> = ({
  data,
  handleFetch,
}) => {
  const { actions } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handlePendingCaiso = useCallback(
    async (requestId: any, pending: any) => {
      setIsOpen(false);
      const result = await actions.updatePUT(requestId, {
        pendingCaiso: `${pending}`,
      } as any);
      if (result?.payload) {
        ToastSuccess(
          `LER ${data.requestId} has been successfully removed from Pending CAISO list`
        );
        handleFetch();
      }
      if (result?.error) {
        ToastError(
          `Failed to remove LER ${data.requestId} from pending CAISO list`
        );
      }
    },
    [actions, setIsOpen]
  );

  return (
    <>
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={
          <ATMButton
            className={styles.actionBtn}
            content="Remove"
            disabled={isDisabled}
          />
        }
      >
        <ATMModal.Header>Remove LER from Pending CAISO List</ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_PENDING_CAISO_REMOVED_FROM_LIST}</p>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
            type="button"
          />
          <ATMButton
            type="button"
            primary
            content={Lang.LBL_CONFIRM}
            onClick={() => {
              setIsDisabled(true);
              handlePendingCaiso(data.requestId, 'N');
            }}
          />
        </ATMModal.Actions>
      </ATMModal>
    </>
  );
};

export default PendingCaisoRemoveFromList;
