import React, { useEffect, useState, useCallback } from 'react';
import { ATMButton, ATMHeader, ATMTable } from 'shared-it-appmod-ui';
import FileDelete from 'src/components/atoms/file-delete/FileDelete';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import {
  SharePointSubmitRequestType,
  useSharepoint,
} from 'src/hooks/file.hook';
import Lang from 'src/libraries/language';
import { IFileSharePoint } from 'src/models/file.model';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';

type IProps = {
  data?: IFileSharePoint[];
  isEdit?: boolean;
  id?: number;
  isSubmitted?: boolean;
  enableRemoveButton?: boolean;
  viewType?: string;
  onDelete?: (data) => void;
};

const SubstationEntryLogDocumentsDetail: React.FC<IProps> = ({
  isEdit = false,
  id,
  isSubmitted = false,
  enableRemoveButton = false,
  viewType,
  onDelete,
}) => {
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { fetchMccList } = useSharepoint();
  const [mccListFile, setMccListFile] = useState<IFileSharePoint[]>([]);

  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    setFiles(fileState.filename_list ?? []);
  }, [fileState.stash]);

  const getMccList = useCallback(async () => {
    let request: SharePointSubmitRequestType = {} as any;
    const requestData: ISharepointRequestData = {
      accessToken: '',
      createdBy: '',
      documentId: '',
      fileId: '',
      fileName: '',
      folderName: JSON.stringify(id) || '',
      parentFolder: id as any,
      requestId: id as any,
      requestor: '',
      documentLibrary: '',
    };
    request = {
      applicationType: SharePointApplicationType.SUBSTATION_ENTRY_TYPE,
      requestData,
      setMccListFile,
      withParentFolder: false,
      setting: settingState,
      files: fileState.stash,
    };
    await fetchMccList(request);
  }, [settingState, fetchMccList, setMccListFile]);

  useEffect(() => {
    if (viewType === 'edit') {
      getMccList();
    }
  }, [id]);

  useEffect(() => {
    if (isSubmitted && viewType === 'update') {
      getMccList();
    }
  }, [isSubmitted]);

  const docsTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
          {isEdit ? (
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          ) : (
            ''
          )}
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {mccListFile.length ? (
          mccListFile?.map((item, i) => (
            <ATMTable.Row key={i}>
              <ATMTable.Cell>
                <>
                  <a
                    key={item.fileUrl as any}
                    href={item.fileUrl as any}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {item.fileName}
                  </a>
                </>
              </ATMTable.Cell>
              {isEdit ? (
                <ATMTable.Cell>
                  <FileDelete
                    successMessage="Visitor file deleted successfully"
                    errorMessage="Failed to delete visitor file"
                    file={item}
                    displayConfirmation
                    handleSuccess={() => {
                      getMccList();
                      if (onDelete) {
                        onDelete(mccListFile);
                      }
                    }}
                  />
                </ATMTable.Cell>
              ) : (
                ''
              )}
            </ATMTable.Row>
          ))
        ) : (
          <>
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
            </ATMTable.Row>
          </>
        )}
      </ATMTable.Body>
    </ATMTable>
  );

  const reviewTable = (
    <>
      {files?.length && !enableRemoveButton ? (
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {files?.length
              ? files?.map((item, i) => (
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMButton
                        type="button"
                        icon="close"
                        onClick={() => {
                          fileAction.removeFileFromStash(item);
                          if (onDelete) {
                            onDelete(files);
                          }
                        }}
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              : ''}
          </ATMTable.Body>
        </ATMTable>
      ) : (
        ''
      )}
      {fileState.filename_list?.length && enableRemoveButton
        ? fileState.filename_list?.map((item, i) => (
            <span key={i}>
              <ATMButton
                type="button"
                icon="close"
                onClick={() => {
                  fileAction.removeFileFromStash(item);
                  if (onDelete) {
                    onDelete(fileState.filename_list);
                  }
                }}
              />
              {item}
            </span>
          ))
        : ''}
    </>
  );

  return (
    <>
      {isEdit ? <ATMHeader as="h1" content="Visitor's Uploaded List" /> : ''}
      {isEdit ? docsTable : reviewTable}
    </>
  );
};

export default SubstationEntryLogDocumentsDetail;
