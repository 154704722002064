import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { ATMInput, IORGDataTableQueryState } from 'shared-it-appmod-ui';
import style from './table-search.module.scss';

type IProps = {
  field: string;
  placeholder?: string;
  state: IORGDataTableQueryState;
  setState: (params: IORGDataTableQueryState) => void;
  rounded?: boolean;
  isEnter?: boolean;
  compact?: boolean;
};

const TableSearch: React.FC<IProps> = ({
  field,
  placeholder,
  state,
  setState,
  rounded,
  isEnter = false,
  compact = false,
}) => {
  const [value, setValue] = useState(state.name ?? '');

  useEffect(() => {
    setValue(state.name ?? '');
  }, [state.name]);

  const handleClear = useCallback(() => {
    setValue('');

    setState({
      ...state,
      page: 1,
      filters: (state.filters ?? []).filter((val) => val.name !== field),
    });
  }, [state, setValue, setState]);

  const handleKeyUp = useCallback(
    debounce((text) => {
      if (typeof text === 'string' && text.trim().length) {
        setState({
          ...state,
          page: 1,
          filters: [
            ...(state.filters ?? []).filter((val) => val.name !== field),
            {
              name: field,
              value: text,
            },
          ],
        });
      } else {
        handleClear();
      }
    }, 500),
    [field, state, setState, handleClear]
  );

  const hasValue = !!value.trim().length;

  const class1 = rounded ? style.roundedStyle : '';
  const class2 = compact ? style.smallerStyle : '';
  const customClass = `${class1} ${class2}`.trim();

  return (
    <ATMInput
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyUp={(e) => {
        if (isEnter) {
          if (e.keyCode === 13) {
            handleKeyUp(e.target.value);
          }
        } else {
          handleKeyUp(e.target.value);
        }
      }}
      icon={{
        name: hasValue ? 'close' : 'search',
        link: hasValue,
        onClick: hasValue ? handleClear : undefined,
      }}
      size="small"
      className={customClass}
    />
  );
};

export default TableSearch;
