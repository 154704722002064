import { httpClient } from 'src/libraries/http.library';
import { CancelTypeListPayloadSchema } from 'src/models/cancel-type.model';

const client = httpClient();
const endpoint = 'cancel-type';

const services = {
  listGET: async () => {
    return client.get(`/${endpoint}`, {}, CancelTypeListPayloadSchema);
  },
};

export default services;
