import { contractorActionTypes, IContractorState } from 'src/ducks/contractor.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getContractorStatus = (
  state: IContractorState,
  action: keyof typeof contractorActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
