import React, { useCallback, useState } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMSegment,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { useSubstationContext } from 'src/contexts/substation.context';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { ISubstation } from 'src/models/substation.model';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import SubstationEdit from '../../substation/substation-edit/substation-edit.component';

const columns: IORGDataTableColumn<ISubstation>[] = [
  {
    index: 'substationId',
    title: 'ID',
  },
  {
    index: 'name',
    title: 'Name',
  },
  {
    index: 'addr1',
    title: 'Address',
  },
  {
    index: 'gps',
    title: 'GPS',
  },
  {
    index: 'tbros',
    title: 'Thomas Bros',
  },
  {
    index: 'city',
    title: 'City',
  },
  {
    index: 'phnNbr',
    title: 'Phone',
    render: (_, value) => {
      if (!value.areaCd && value.phnNbr) {
        return value.phnNbr.replace(/^(\d{3})(\d{4})/, '$1-$2');
      }
      if (value.areaCd && value.phnNbr) {
        let phoneNum = value.phnNbr;
        if (phoneNum.indexOf('-') === -1) {
          phoneNum = phoneNum.replace(/^(\d{3})(\d{4})/, '$1-$2');
          return value.areaCd
            ?.concat(phoneNum)
            .replace(/^(\d{3})(\d{3})/, '$1-$2');
        }
        const arCdPhone = value.areaCd?.concat(phoneNum);
        return arCdPhone.replace(/^(\d{3})(\d{3})/, '$1-$2');
      }
      return '–';
    },
  },
  {
    index: 'action',
    sortable: false,
    title: 'Action',
    width: '120px',
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <SubstationEdit details data={value} />
      </>
    ),
  },
];

const SubstationEntryLogStation: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { state, actions } = useSubstationContext();
  const status = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_LIST_READ
  );

  const handleFetch = useCallback(
    async (params: IORGDataTableQueryState) => {
      await actions.listGET({ ...params, limit: 0, page: 1 });
      setIsInitialized(true);
    },
    [actions, setIsInitialized]
  );

  return (
    <div className="admin-container">
      <div>
        <Breadcrumb
          icon="right angle"
          size="tiny"
          style={{ textDecoration: 'underline' }}
          sections={[
            {
              content: (
                <div
                  onClick={() => history.push('/substation-entry-log')}
                  role="link"
                  style={{
                    textDecoration: 'underline',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  {Lang.TTL_SUBSTATION_ENTRY_LOG}
                </div>
              ),
              link: true,
            },
            {
              content: Lang.LBL_SUBSTATION_LIST,
            },
          ]}
        />
      </div>
      <ATMSegment>
        <ATMGrid columns={2} style={{ marginBottom: '1rem' }}>
          <ATMGrid.Column verticalAlign="middle">
            <ATMHeader>{Lang.LBL_SUBSTATION_DETAILS}</ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right">
            <ATMButton
              type="button"
              secondary
              content={Lang.LBL_GO_BACK}
              onClick={() => history.goBack()}
              icon="arrow left"
            />
          </ATMGrid.Column>
        </ATMGrid>
        <ORGDataTable
          columns={columns}
          data={state.list}
          onChange={handleFetch}
          counter
          loading={!(state.list.length && !isInitialized) && status.fetching}
          celled={false}
          sortable
          total={state.total}
          showPagination={false}
          location={history.location}
          handleLocation={history.push}
        >
          {({ state: filterState, setState }) => ({
            toolbars: {
              search: () => (
                <TableSearch
                  field="name"
                  state={filterState}
                  setState={setState}
                  placeholder={Lang.LBL_NAME_SEARCH}
                />
              ),
            },
          })}
        </ORGDataTable>
      </ATMSegment>
    </div>
  );
};

export default SubstationEntryLogStation;
