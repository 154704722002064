import React from 'react';
import { ATMDropdown } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IProps } from './dropdown-job-code.component';

const DropdownJobCodeView: React.FC<IProps> = (props) => (
  <ATMDropdown
    fluid
    selection
    search={(options) => options}
    icon="search"
    searchQuery={undefined}
    placeholder={Lang.LBL_ENTER}
    {...props}
  />
);

export default DropdownJobCodeView;
