export enum TroubleJobStatus {
  DEVELOPING = 'Developing',
  CLOSED = 'Closed',
  OPEN = 'Open',
}

export enum TroubleJobSortStatus {
  OPEN = 'OPEN',
  SENT = 'SENT',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  CANCELSENT = 'CANCELSENT',
}

export enum TroubleJobTab {
  Open,
  All,
}

export const TROUBLE_JOB_ALARM_STATION = 'MCC';
export const TROUBLE_JOB_ALARM_EQUIPMENT = 'Station Alarm';

export const troubleJobTab = TroubleJobTab;
