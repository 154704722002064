import React from 'react';
import LocalPanel from 'src/components/organisms/local-panel/local-panel.component';
import Lang from 'src/libraries/language';
import { AccessType } from 'src/constants';
import GeneralSettingsView from './general-settings/general-settings.component';
import RolesList from './roles/roles-list/roles-list.component';
import AlertSettings from './alert-settings/alert-settings.component';
import MccSharepoint from './mcc-sharepoint/mcc-sharepoint.component';

export const panes = [
  {
    title: Lang.LBL_ROLES,
    render: <RolesList />,
    permissions: [AccessType.ROLES],
  },
  {
    title: Lang.TTL_GENERAL_SETTINGS,
    render: <GeneralSettingsView />,
    permissions: [AccessType.ROLES],
  },
  {
    title: Lang.TTL_ALERT_SETTINGS,
    render: <AlertSettings />,
    permissions: [AccessType.ROLES],
  },
  {
    title: Lang.TTL_MCC_SHAREPOINT,
    render: <MccSharepoint />,
    permissions: [AccessType.ROLES],
  },
];

const RolesSettings: React.FC = () => {
  return <LocalPanel name="tab" panes={panes} />;
};

export default RolesSettings;
