import React, { useState } from 'react';
import { ATMDivider, ATMPopover } from 'shared-it-appmod-ui';
import { IKeyRequest } from 'src/models/key-request.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
// import Lang from 'src/libraries/language';
import Lang from 'src/libraries/language';
import styles from './key-request-card-requestor.module.scss';

type IProps = {
  keyRequest: IKeyRequest;
};

const KeyRequestCardRequestor: React.FC<IProps> = ({ keyRequest }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          <u>
            <a
              className={styles.popLink}
              role="button"
              onClick={() => {
                if (keyRequest.keyRequestDetail.keyNbr !== 0) {
                  setIsOpen(true);
                } else {
                  setIsOpen(false);
                }
              }}
            >
              {keyRequest.requestNbr}
            </a>
          </u>
        }
        position="right center"
        on="click"
      >
        <ATMPopover.Header>
          <span className={styles.overlayHeader}>
            {Lang.TTL_KEY_REQUEST_DETAILS}
          </span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          <>
            <div className={styles.overlayContent}>
              <FieldReadOnly label={Lang.LBL_MANAGER}>
                {keyRequest.keyRequestDetail.supvr
                  ? `${keyRequest.keyRequestDetail.supvr.lastName}, ${keyRequest.keyRequestDetail.supvr.firstName}/${keyRequest.keyRequestDetail.supvr.empId}`
                  : '-'}
              </FieldReadOnly>
              <FieldReadOnly label={Lang.LBL_ISSUANCE_REASON}>
                {keyRequest.keyRequestDetail.keyDetail &&
                keyRequest.keyRequestDetail.keyDetail.length > 0 &&
                keyRequest.keyRequestDetail.keyDetail[0].reason
                  ? keyRequest.keyRequestDetail.keyDetail[0].reason
                  : '-'}
              </FieldReadOnly>
            </div>
          </>
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default KeyRequestCardRequestor;
