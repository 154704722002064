import React, { useCallback } from 'react';
import {
  ATMGrid,
  ATMHeader,
  IORGDataTableQueryState,
  ATMButton,
  ATMMenu,
  ATMMessage,
  IATMTabProps,
} from 'shared-it-appmod-ui';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import Lang from 'src/libraries/language';
import { getSubstationEntryStatus } from 'src/selectors/substation-entry.selector';
import { substationEntryActionTypes } from 'src/ducks/substation-entry.duck';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import { AccessType } from 'src/constants';
import history from 'src/history';
import AccessComponent from 'src/components/atoms/access/access.component';
import style from './substation-entry-log-list.module.scss';
import SubstationEntryLogListSearch from './substation-entry-log-list-search/substation-entry-log-list-search.component';
import SubstationEntryLogListToday from './substation-entry-log-list-today/substation-entry-log-list-today.component';

type IProps = {
  data: ISubstationEntry[];
  all: ISubstationEntry[];
  total: number;
  loading: boolean;
  today: number;
  currentTab: number;
  downloading: boolean;
  handleDownload: (all: boolean) => void;
  handleFetch: (
    params: Partial<IORGDataTableQueryState>,
    all?: boolean
  ) => Promise<void>;
  originalData: ISubstationEntry[];
  filteredBySubstation: ISubstationEntry[];
  timer?: any;
};

const SubstationEntryLogListView: React.FC<IProps> = ({
  data,
  all,
  total,
  loading,
  downloading,
  handleFetch,
  today,
  handleDownload,
  currentTab,
  originalData,
  filteredBySubstation,
  timer,
}) => {
  const {
    state: substationsEntryCreateState,
    actions: substationsEntryCreateActions,
  } = useSubstationEntryContext();
  const status = getSubstationEntryStatus(
    substationsEntryCreateState,
    substationEntryActionTypes.SUBSTATION_ENTRY_DATA_CREATE
  );

  const clearError = useCallback(() => {
    substationsEntryCreateActions?.clearError();
  }, [substationsEntryCreateActions]);

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_TODAYS_LOG} ({today})
        </ATMMenu.Item>
      ),
      render: () => {
        return (
          <SubstationEntryLogListToday
            data={data}
            downloading={downloading}
            loading={loading}
            today={today}
            handleFetch={handleFetch}
            handleDownload={handleDownload}
            originalData={originalData}
            filteredBySubstation={filteredBySubstation}
          />
        );
      },
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_SEARCH} {Number(currentTab) === 1 && `(${total})`}
        </ATMMenu.Item>
      ),
      render: () => {
        return (
          <SubstationEntryLogListSearch
            data={all}
            handleFetch={handleFetch}
            loading={loading}
            total={total}
            handleDownload={handleDownload}
            downloading={downloading}
            timer={timer}
          />
        );
      },
    },
  ];

  return (
    <div className="admin-container">
      <ATMGrid>
        {status.error && status.error.name === 'BadRequest' && (
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMMessage negative onDismiss={clearError}>
                {status.error.message}
              </ATMMessage>
            </ATMGrid.Column>
          </ATMGrid.Row>
        )}
        <ATMGrid.Row verticalAlign="middle" columns="equal">
          <ATMGrid.Column>
            <ATMHeader as="h1">{Lang.TTL_SUBSTATION_ENTRY_LOG}</ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column style={{ textAlign: 'right' }}>
            <ATMButton
              id="open-grid-ops"
              type="button"
              size="small"
              icon="external alternate"
              secondary
              content={Lang.LBL_GRID_OPS_HOME}
              as="a"
              href="https://gridops.sdge.com/gc/DOCS/Ops_Docs.asp"
              target="_blank"
              style={{ marginRight: '.5em' }}
            />
            <AccessComponent type={AccessType.SUBSTATION_ENTRY_LOG_UPDATE}>
              <ATMButton
                id="open-substation-list"
                type="button"
                secondary
                content={Lang.LBL_SUBSTATION_LIST}
                onClick={() => history.push('/substation-entry-log/substation')}
                style={{ marginRight: '0' }}
              />
            </AccessComponent>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row style={{ paddingTop: '0' }}>
          <ATMGrid.Column>
            <div className="admin-content">
              <div className={style.headerTabs}>
                <Tabs
                  name="substationEntry"
                  menu={{ pointing: true }}
                  panes={panes}
                  renderActiveOnly
                />
              </div>
            </div>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </div>
  );
};

export default SubstationEntryLogListView;
