import { REGEX_PHONE } from 'shared-it-appmod-ui';
import yup from 'src/libraries/validator.library';

export const ContractorFirmShape = {
  contracShortNm: yup.string().required(),
  fullName: yup.string().nullable(),
  contactName: yup.string().nullable(),
  areaCode: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  empId: yup.string().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const ContractorFirmSchema = yup.object(ContractorFirmShape);

export const ContractorFirmFormSchema = yup.object({
  contracShortNm: yup.string().max(30).trim().required(),
  fullName: yup.string().nullable().trim().max(60).required(),
  contactName: yup.string().max(35).trim().nullable(),
  areaCode: yup.string().trim().nullable(),
  phoneNumber: yup.string().trim().nullable(),
  contactNumber: yup
    .string()
    .trim()
    .matches(REGEX_PHONE, 'Phone number must contain 10 - 11 numbers')
    .nullable(),
  empId: yup.string().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
});

export const ContractorFirmListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(ContractorFirmSchema.clone()),
});

export const ContractorFirmPayloadSchema = ContractorFirmSchema;

export const ContractorFirmDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IContractorFirm = yup.Asserts<typeof ContractorFirmSchema>;
export type IContractorFirmForm = yup.Asserts<typeof ContractorFirmFormSchema>;
