/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { ITroubleTicketAuditReportFilter } from 'src/models/trouble-ticket.model';
import { troubleTicketStatus } from 'src/constants';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { convertToCSV, buildExportData } from 'src/selectors/file.selector';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import TroubleTicketAuditReportListView from './trouble-ticket-audit-report-list.view';

const TroubleTicketAuditReportList: React.FC = () => {
  const { state, actions } = useTroubleTicketContext();
  const [showNoRecord, setShowNoRecord] = useState(true);
  const status = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_AUDIT_LIST_READ
  );

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      if (params.filters) {
        actions?.auditReportListGET(params);
      }
    },
    [actions]
  );

  const handleSubmit = useCallback(
    (data: ITroubleTicketAuditReportFilter) => {
      const filters: any[] = Object.entries(data)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => {
          return { name: key, value };
        });
      if (filters) {
        setShowNoRecord(false);
        handleFetch({ limit: 0, page: 1, filters });
      } else {
        setShowNoRecord(true);
      }
    },
    [handleFetch, setShowNoRecord, showNoRecord]
  );

  useEffect(() => {
    actions?.auditReportListCLEAR();
  }, []);

  const handleEmployeeName = (fullname, firstname, lastname) => {
    let employeeName;
    const fullName = checkValue(fullname);
    const firstName = checkValue(firstname);
    const lastName = checkValue(lastname);

    const firstAndLastname = `${lastName}${firstName}`;
    if (firstAndLastname.length) {
      if (
        firstAndLastname.search(/undefined/gi) === -1 &&
        firstAndLastname.search(/null/gi) === -1
      ) {
        employeeName = `${lastName}, ${firstName}`;
      } else {
        employeeName = Lang.LBL_UNAVAILABLE;
      }
    } else if (
      fullName.search(/undefined/gi) === -1 &&
      fullName.search(/null/gi) === -1
    ) {
      employeeName = fullName;
    } else {
      employeeName = Lang.LBL_UNAVAILABLE;
    }

    return employeeName.length ? employeeName : Lang.LBL_UNAVAILABLE;
  };

  const handleDownload = useCallback(() => {
    if (state.auditList) {
      const items = state.auditList.map((value) => [
        checkValue(value.troubleTicketId),
        checkValue(troubleTicketStatus[value.ticketStatus.toUpperCase()]),
        checkValue(value.beginDt ? formatDate(value.beginDt) : '--'),
        checkValue(
          handleEmployeeName(
            value.submittedByEmployee.fullName,
            value.submittedByEmployee.firstName,
            value.submittedByEmployee.lastName
          ) === Lang.LBL_UNAVAILABLE
            ? '--'
            : handleEmployeeName(
                value.submittedByEmployee.fullName,
                value.submittedByEmployee.firstName,
                value.submittedByEmployee.lastName
              )
        ),
        checkValue(value.dueDt ? formatDate(value.dueDt) : '--'),
        checkValue(
          handleEmployeeName(
            value.activatedByEmployee.fullName,
            value.activatedByEmployee.firstName,
            value.activatedByEmployee.lastName
          ) === Lang.LBL_UNAVAILABLE
            ? '--'
            : handleEmployeeName(
                value.activatedByEmployee.fullName,
                value.activatedByEmployee.firstName,
                value.activatedByEmployee.lastName
              )
        ),
        checkValue(value.closedDt ? formatDate(value.closedDt) : '--'),
        checkValue(
          handleEmployeeName(
            value.closedByEmployee.fullName,
            value.closedByEmployee.firstName,
            value.closedByEmployee.lastName
          ) === Lang.LBL_UNAVAILABLE
            ? '--'
            : handleEmployeeName(
                value.closedByEmployee.fullName,
                value.closedByEmployee.firstName,
                value.closedByEmployee.lastName
              )
        ),
        checkValue(value.ticketDesc),
        checkValue(
          value.comments
            ? value.comments
                .map(
                  (comment) =>
                    `${formatDate(comment.updatedAt)} - ${comment.noteTxt}`
                )
                .join('; ')
            : '--'
        ),
        checkValue(value.ticketPrio),
        checkValue(value.requestTyp),
        checkValue(value.workGroup?.trbGrpNm),
        checkValue(
          value.troubleEquipmentTypeId ? value.troubleEquipmentType?.name : '--'
        ),
        checkValue(
          value.substation && value.substationId ? value.substation?.name : '--'
        ),
        checkValue(
          value.line && value.outgFacId ? value.line?.outgFacNm : '--'
        ),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_TROUBLE_TICKET_ID,
          Lang.LBL_TROUBLE_TICKET_STATUS,
          Lang.LBL_BEGIN_DATE,
          Lang.LBL_CREATED_BY,
          Lang.LBL_DUE_DATE,
          Lang.LBL_ACTIVATED_BY,
          Lang.LBL_CLOSED_DATE,
          Lang.LBL_CLOSED_BY,
          Lang.LBL_TROUBLE_TICKET_DESCRIPTION,
          Lang.LBL_COMMENTS_AND_RESOLUTION,
          Lang.LBL_PRIORITY,
          Lang.LBL_TROUBLE_TICKET_TYPE,
          Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
          Lang.LBL_EQUIPMENT_TYPE,
          Lang.LBL_SUBSTATION,
          Lang.LBL_LINE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', cleanSpecialCharacterData(exportData));
      link.setAttribute(
        'download',
        `MCC_Trouble_Ticket_Audit_Report_${Moment().format(
          'YYYYMMDD'
        )}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    // }, [actions, reportfilters]);
  }, [state]);

  return (
    <TroubleTicketAuditReportListView
      data={state.auditList}
      loading={status.fetching}
      total={state.auditListTotal}
      showNoRecord={showNoRecord}
      handleSubmit={handleSubmit}
      handleNoRecord={setShowNoRecord}
      handleDownload={handleDownload}
    />
  );
};

export default TroubleTicketAuditReportList;
