import yup from 'src/libraries/validator.library';

export const SubstationCountListShape = {
  year: yup.string().required(),
  total: yup.number().required(),
  substationCounts: yup
    .array()
    .of(
      yup
        .object({
          custCnt: yup.number().required(),
          substation: yup
            .object({
              name: yup.string().required(),
            })
            .nullable(),
          substationId: yup.string().required(),
          substationLoad: yup.number().nullable(),
          updatedAt: yup.date().required(),
          updatedBy: yup.string().required(),
          year: yup.string().required(),
        })
        .required()
    )
    .required(),
};

export const SubstationCountListSchema = yup.object(SubstationCountListShape);

export const SubstationCountListFormSchema = yup.object({
  custCnt: yup.number().required(),
  substationId: yup.string().required(),
  substationLoad: yup.number().nullable(),
  year: yup.string().required(),
});

export const SubstationCountListFormArraySchema = yup.object({
  substationCounts: yup
    .array()
    .of(SubstationCountListFormSchema.clone())
    .required(),
});

export const SubstationCountListUploadSchema =
  SubstationCountListFormArraySchema.clone();

export const SubstationCountListListSchema = yup
  .array()
  .of(SubstationCountListUploadSchema);

export const SubstationCountListListPayloadSchema = yup
  .array()
  .of(SubstationCountListSchema.clone());

export const SubstationCountListPayloadSchema = yup.object({
  substationCountList: SubstationCountListSchema.clone(),
});

export const SubstationCountListDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const SubstationCountListFormPayloadSchema = yup
  .array()
  .of(SubstationCountListFormSchema.clone());

export type ISubstationCountList = yup.Asserts<
  typeof SubstationCountListSchema
>;
export type ISubstationCountListForm = yup.Asserts<
  typeof SubstationCountListFormSchema
>;
export type ISubstationCountListFormArray = yup.Asserts<
  typeof SubstationCountListFormArraySchema
>;
