import React, { useEffect, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Message, MessageProps } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import EventManager from 'src/libraries/event.library';
import styles from './toaster.module.scss';

export enum TOASTER_EVENT {
  SHOW,
  CLEAR,
}

const eventManager = new EventManager<TOASTER_EVENT>();

export enum TOASTER_TYPE {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

type IToastOptions = {
  delay?: number;
  position?: ITostPosition;
};

type IToast = MessageProps & {
  type?: TOASTER_TYPE;
  options?: IToastOptions;
};

const Toast = ({ type = TOASTER_TYPE.ERROR, ...params }: IToast) => {
  eventManager.emit(TOASTER_EVENT.SHOW, {
    ...params,
    type,
  });
};

export enum ITostPosition {
  TopLeft = 'top left',
  TopRight = 'top right',
  BottomLeft = 'bottom left',
  BottomRight = 'bottom right',
}

type Formatted = number | string | JSX.Element;

const ToastSuccess = (
  content: Formatted | Formatted[],
  options?: IToastOptions
) => {
  eventManager.emit(TOASTER_EVENT.SHOW, {
    type: TOASTER_TYPE.SUCCESS,
    header: Lang.TTL_TOAST_SUCCESS,
    content,
    options,
  });
};

const ToastError = (
  content: Formatted | Formatted[],
  options?: IToastOptions
) => {
  eventManager.emit(TOASTER_EVENT.SHOW, {
    type: TOASTER_TYPE.ERROR,
    header: Lang.TTL_TOAST_ERROR,
    content,
    options,
  });
};

const ToastInfo = (
  content: Formatted | Formatted[],
  options?: IToastOptions
) => {
  eventManager.emit(TOASTER_EVENT.SHOW, {
    type: TOASTER_TYPE.INFO,
    header: Lang.TTL_TOAST_INFO,
    content,
    options,
  });
};

const ToastWarning = (
  content: Formatted | Formatted[],
  options?: IToastOptions
) => {
  eventManager.emit(TOASTER_EVENT.SHOW, {
    type: TOASTER_TYPE.WARNING,
    header: Lang.TTL_TOAST_WARNING,
    content,
    options,
  });
};

const ToastClear = () => {
  eventManager.emit(TOASTER_EVENT.CLEAR);
};

export { Toast, ToastSuccess, ToastError, ToastInfo, ToastWarning, ToastClear };

type IToastList = {
  [k in string]: IToast;
};

export interface IProps {
  delay?: number;
}

const ToasterContainer: React.FC<IProps> = ({ delay = 5000 }) => {
  const outs = useRef({});
  const [toasts, setToasts] = useState<IToastList>({});
  const [shown, setShown] = useState<Record<string, IToastOptions>>({});
  const toastRef = useRef(toasts);
  // Always set the toastRef with the current values
  toastRef.current = toasts;

  const handleRemove = useCallback(
    (id) => {
      setToasts((values) => {
        const items = { ...values };

        delete items[id];

        return items;
      });
    },
    [setToasts]
  );

  // We will reset the timer for the toast that's being duplicated
  const handleTimer = useCallback(
    (id: string, options?: IToastOptions) => {
      if (outs.current[id]) {
        clearTimeout(outs.current[id]);
      }

      const timer = setTimeout(() => {
        handleRemove(id);
      }, options?.delay || delay);

      outs.current = { ...outs.current, [id]: timer };
    },
    [delay, handleRemove]
  );

  const handleEvent = useCallback(
    ({ id, ...value }) => {
      const key = id || `ID_${Math.random().toString(36).substr(2, 9)}`;

      if (!toastRef.current[key]) {
        setShown((items) => ({ ...items, [key]: value.options }));
        setToasts((items) => ({ ...items, [key]: value }));
      } else {
        handleTimer(key, value.options);
      }
    },
    [setToasts, setShown, handleTimer]
  );

  // Listen to event changes
  useEffect(() => {
    eventManager.on(TOASTER_EVENT.SHOW, handleEvent);
    eventManager.on(TOASTER_EVENT.CLEAR, () => {
      setShown({});
      setToasts({});
    });
  }, []);

  const handleMouseOver = useCallback((id: string) => {
    if (outs.current[id]) {
      clearTimeout(outs.current[id]);

      delete outs.current[id];
    }
  }, []);

  useEffect(() => {
    Object.entries(shown).forEach(([key, options]) =>
      handleTimer(key, options)
    );

    // Always empty shown items
    if (Object.keys(shown).length) {
      setShown({});
    }
  }, [shown, setShown, handleTimer]);

  const chunk = Object.entries(toasts).reduce(
    (items, [key, item]) => {
      if (item.options?.position) {
        return {
          ...items,
          [item.options?.position]: [
            ...items[item.options?.position],
            { key, ...item },
          ],
        };
      }

      return {
        ...items,
        [ITostPosition.TopRight]: [
          ...items[ITostPosition.TopRight],
          { key, ...item },
        ],
      };
    },
    {
      [ITostPosition.TopLeft]: [],
      [ITostPosition.TopRight]: [],
      [ITostPosition.BottomLeft]: [],
      [ITostPosition.BottomRight]: [],
    } as Record<ITostPosition, (IToast & { key: string })[]>
  );

  if (!Object.keys(toasts).length) {
    return null;
  }

  return (
    <>
      {Object.entries(chunk).map(([index, items]) => (
        <ul
          key={index}
          className={classNames(styles.container, {
            [styles.topLeft]: index === ITostPosition.TopLeft,
            [styles.topRight]: index === ITostPosition.TopRight,
            [styles.bottomLeft]: index === ITostPosition.BottomLeft,
            [styles.bottomRight]: index === ITostPosition.BottomRight,
          })}
        >
          {items.map(({ key, options, ...value }) => {
            const messageProps: MessageProps = { ...value };

            if (value.type === TOASTER_TYPE.ERROR) {
              messageProps.icon = 'times circle';
              messageProps.error = true;
            } else if (value.type === TOASTER_TYPE.INFO) {
              messageProps.icon = 'question circle';
              messageProps.info = true;
            } else if (value.type === TOASTER_TYPE.WARNING) {
              messageProps.icon = 'exclamation circle';
              messageProps.warning = true;
            } else if (value.type === TOASTER_TYPE.SUCCESS) {
              messageProps.icon = 'check circle';
              messageProps.success = true;
            }

            return (
              <li
                key={`toast_${key}`}
                onMouseEnter={() => handleMouseOver(key)}
                onMouseLeave={() => handleTimer(key, value.options)}
              >
                <Message
                  {...messageProps}
                  size="tiny"
                  onDismiss={() => handleRemove(key)}
                />
              </li>
            );
          })}
        </ul>
      ))}
    </>
  );
};

export default ToasterContainer;
