import React from 'react';
import { ATMButton, ATMDivider, ATMGrid, ATMModal } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { AuthType, SuspensionStatus } from 'src/constants';
import { getEmployeeName } from 'src/helpers/employee.helper';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import { removeDoubleZeroEmpId } from 'src/selectors/employee.selector';
import style from './substation-entry-log-employee-info.module.scss';

type IProps = {
  isEmpOpen: boolean;
  setIsEmpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  substationEntry: ISubstationEntry;
};

const SubstationEntryEmployeeInfoView: React.FC<IProps> = ({
  isEmpOpen,
  setIsEmpOpen,
  substationEntry,
}) => {
  return (
    <ATMModal
      open={isEmpOpen}
      size="small"
      onClose={() => setIsEmpOpen(false)}
      trigger={
        <ATMButton
          secondary
          content="More Information"
          size="mini"
          onClick={() => setIsEmpOpen(true)}
          id="more-info"
        />
      }
    >
      <ATMModal.Header>Employee Information</ATMModal.Header>
      <ATMModal.Content scrolling>
        <div className={style.overlayContent}>
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <FieldReadOnly label="Name">
                  {getEmployeeName(substationEntry?.employee)}
                </FieldReadOnly>
                <FieldReadOnly label="Phone Number">
                  {substationEntry?.employee?.cellPhoneNumber}
                </FieldReadOnly>
                <FieldReadOnly label="Email Address">
                  {substationEntry?.employee?.email}
                </FieldReadOnly>
                <FieldReadOnly label="Contract Firm">
                  {substationEntry?.employee?.contracShortNm}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label="ID">
                  {substationEntry?.empId
                    ? removeDoubleZeroEmpId(substationEntry?.empId)
                    : substationEntry?.empId}
                </FieldReadOnly>
                <FieldReadOnly label="Title">
                  {substationEntry?.employee?.title}
                </FieldReadOnly>
              </ATMGrid.Column>
            </ATMGrid.Row>

            <ATMDivider />
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <FieldReadOnly label="Authorization Training">
                  {formatDate(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.AUTH_TRAINING
                    )[0]?.expiryDate
                  ) ?? '--'}
                  {Moment(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.AUTH_TRAINING
                    )[0]?.expiryDate,
                    'YYYY-MM-DD'
                  ).isBefore(Moment().utc()) && (
                    <span
                      style={{ color: 'red' }}
                    >{`- (${SuspensionStatus.EXPIRED})`}</span>
                  )}
                </FieldReadOnly>
                <FieldReadOnly label="Field/Line Switching">
                  {formatDate(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.FIELD_SWITCHING
                    )[0]?.expiryDate
                  ) ?? '--'}
                  {Moment(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.FIELD_SWITCHING
                    )[0]?.expiryDate,
                    'YYYY-MM-DD'
                  ).isBefore(Moment().utc()) && (
                    <span
                      style={{ color: 'red' }}
                    >{`- (${SuspensionStatus.EXPIRED})`}</span>
                  )}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label="Substation Switching">
                  {formatDate(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.SUB_SWITCHING
                    )[0]?.expiryDate
                  ) ?? '--'}
                  {Moment(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.SUB_SWITCHING
                    )[0]?.expiryDate,
                    'YYYY-MM-DD'
                  ).isBefore(Moment().utc()) && (
                    <span
                      style={{ color: 'red' }}
                    >{`- (${SuspensionStatus.EXPIRED})`}</span>
                  )}
                </FieldReadOnly>
                <FieldReadOnly label="Substation Entry">
                  {formatDate(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.SUB_ENTRY
                    )[0]?.expiryDate
                  ) ?? '--'}
                  {Moment(
                    substationEntry?.employee?.trainings?.filter(
                      (item) => item.authTypId === AuthType.SUB_ENTRY
                    )[0]?.expiryDate,
                    'YYYY-MM-DD'
                  ).isBefore(Moment().utc()) && (
                    <span
                      style={{ color: 'red' }}
                    >{`- (${SuspensionStatus.EXPIRED})`}</span>
                  )}
                </FieldReadOnly>
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          primary
          content="Close"
          onClick={() => setIsEmpOpen(false)}
          id="close"
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SubstationEntryEmployeeInfoView;
