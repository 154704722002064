import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMSegment,
  ATMGrid,
  ATMLabel,
  ATMDatePicker,
  ATMDropdown,
  ATMButton,
  IORGDataTableColumn,
  MOLInfoText,
  ORGDataTable,
  MOLNoData,
  ATMIcon,
} from 'shared-it-appmod-ui';
import { useCancelledReportContext } from 'src/contexts/cancelled-report.context';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { ICancelledReport } from 'src/models/cancelled-report.model';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { getEmployeeName } from 'src/helpers/employee.helper';
import style from './cancelled-report-list.module.scss';

type IProps = {
  loading: boolean;
  data: ICancelledReport[];
  queryParams: Record<any, any>;
  handleFetch: () => void;
  handleQueryParams: (params: Record<any, any>) => void;
  handleDownload: () => void;
  downloading: boolean;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const CancelledReportListView: React.FC<IProps> = ({
  data,
  loading,
  queryParams,
  handleFetch,
  handleQueryParams,
  handleDownload,
  downloading,
  showNoRecord,
  setShowNoRecord,
}) => {
  const { getUrl } = useLocationParams(['lerView']);
  const { actions } = useCancelledReportContext();
  const { state: lerRequestState } = useLerRequestContext();
  const statusEmployeeRole = getLerRequestStatus(
    lerRequestState,
    lerRequestActionTypes.LER_REQUEST_EMPLOYEE_LIST_READ
  ).fetching;

  const [requestorOptions, supervisorOptions, cancelledByOptions] =
    useMemo(() => {
      const requestors = lerRequestState.employeeList
        .filter((v) => v.type === 'requestor')
        .map((value) => ({
          key: `requestor_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      const supervisors = lerRequestState.employeeList
        .filter((v) => v.type === 'supervisor')
        .map((value) => ({
          key: `supervisor_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      const cancelers = lerRequestState.employeeList
        .filter((v) => v.type === 'canceler')
        .map((value) => ({
          key: `cancelers_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      return [requestors, supervisors, cancelers];
    }, [lerRequestState.employeeList]);

  const customColumns: IORGDataTableColumn<ICancelledReport>[] = [
    {
      title: Lang.LBL_LER,
      index: 'requestId',
      render: (_, value) => (
        <Link
          to={getUrl({
            lerView: value.requestId || 0,
          })}
        >
          {value.requestId}
        </Link>
      ),
    },
    {
      title: Lang.LBL_REQUESTOR,
      index: 'requestor.firstName',
      render: (_, value) => value.requestor?.firstName,
    },
    {
      title: Lang.LBL_REQUESTOR_SUPERVISOR,
      index: 'supervisor.fullName',
      render: (_, value) => value?.supervisor?.fullName,
    },
    {
      title: Lang.LBL_CANCEL_REASON,
      index: 'cancelNote',
      width: '30%',
      render: (_, value) => (
        <MOLInfoText
          transparent
          limit={50}
          content={value.cancelNote?.noteTxt || ''}
        />
      ),
    },
    {
      title: Lang.LBL_CANCEL_TYPE,
      index: 'cancelType.cancelTypeDesc',
      render: (_, value) => (
        <MOLInfoText
          transparent
          limit={50}
          content={value.cancelType?.cancelTypeDesc || ''}
        />
      ),
    },
    {
      title: Lang.LBL_REASON,
      index: 'chngRsn',
      width: '30%',
      render: (_, value) => (
        <MOLInfoText transparent limit={50} content={value.chngRsn || ''} />
      ),
    },
    {
      title: Lang.LBL_CANCEL_BY,
      index: 'cancelor.fullName',
      render: (_, value) => value.cancelor?.fullName,
    },
    {
      title: Lang.LBL_CANCEL_DATE,
      index: 'cnclAt',
      render: (_, value) =>
        value.cnclAt ? Moment(value.cnclAt).format('M/DD/YYYY') : '-',
    },
  ];
  return (
    <>
      <ATMSegment className={style.bgColor}>
        <ATMGrid>
          <ATMGrid.Row columns={4} className={style.filters}>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_DATE_RANGE}
                className={style.filterLbl}
              />
              <ATMDatePicker
                type="range"
                name="dateRange"
                placeholder={Lang.LBL_SELECT}
                format="MM/DD/YYYY"
                date={new Date()}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'dateRange')
                    ? queryParams.dateRange
                    : undefined
                }
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    dateRange: value,
                  });
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_REQUESTOR}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                loading={!requestorOptions.length && statusEmployeeRole}
                clearable
                name="requestorId"
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'requestorId')
                    ? queryParams.requestorId
                    : null
                }
                options={[...requestorOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    requestorId: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_REQUESTOR_SUPERVISOR}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                clearable
                name="supvId"
                loading={!supervisorOptions.length && statusEmployeeRole}
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'supvId')
                    ? queryParams.supvId
                    : null
                }
                options={[...supervisorOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    supvId: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_CANCELLED_BY}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                clearable
                loading={!cancelledByOptions.length && statusEmployeeRole}
                name="cnclBy"
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'cnclBy')
                    ? queryParams.cnclBy
                    : null
                }
                options={[...cancelledByOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    cnclBy: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column />
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMButton
                primary
                content={Lang.LBL_SEARCH}
                onClick={() => {
                  setShowNoRecord(false);
                  handleFetch();
                }}
                disabled={Object.keys(queryParams).length === 0}
              />
              <ATMButton
                secondary
                type="button"
                content={Lang.LBL_CLEAR_SEARCH}
                onClick={() => {
                  actions?.clearList();
                  handleQueryParams({});
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
      {data.length > 0 && (
        <ATMButton
          secondary
          icon="download"
          loading={downloading}
          className={style.download}
          onClick={handleDownload}
        />
      )}
      <ORGDataTable
        sortable
        data={data}
        className={style.tableHeaders}
        celled={false}
        defaultRowsPerPage={50}
        loading={loading}
        columns={customColumns}
        noDataText={
          !showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={style.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'bottom',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      />
    </>
  );
};

export default CancelledReportListView;
