import React from 'react';
import classNames from 'classnames';
import { ATMLabel } from 'shared-it-appmod-ui';
import {
  FacilityStatus,
  EquipmentTypeStatus,
  SuspensionStatus,
  SubstationTimeStatus,
  OmsStatus,
  LERRequestStatus,
  LERNotificationStatus,
} from 'src/constants';
import Lang from 'src/libraries/language';
import styles from './status.module.scss';

type IProps = {
  language?: Record<string, string>;
  value?:
    | EquipmentTypeStatus
    | FacilityStatus
    | SuspensionStatus
    | SubstationTimeStatus
    | OmsStatus
    | LERRequestStatus
    | string
    | number
    | null;
  label?: boolean;
  bold?: boolean;
  timeStatus?: boolean;
  languageHours?: {
    language: string;
    hours: string;
  };
};

const Status: React.FC<IProps> = ({
  language = Lang.STATUS,
  value,
  label = false,
  bold = false,
  timeStatus = false,
  children,
  languageHours,
}) => {
  if (!value) {
    return null;
  }

  let type = 'danger';

  const checkValue = timeStatus
    ? languageHours && languageHours.language
    : value;

  switch (checkValue) {
    case OmsStatus.CHANGE_REQUEST: {
      type = 'warning';
      break;
    }

    case EquipmentTypeStatus.ACTIVE:
    case SuspensionStatus.AUTHORIZED:
    case LERNotificationStatus.APPROVED:
    case FacilityStatus.ACTIVE: {
      type = 'success';
      break;
    }

    case SubstationTimeStatus.LESS_THAN_A_DAY: {
      type = 'in';
      break;
    }
    case SubstationTimeStatus.OUT: {
      type = 'out';
      break;
    }
    case SubstationTimeStatus.NOT_CHECKOUT_WITHIN_THE_DAY: {
      type = 'in_more_24hs';
      break;
    }
    case SubstationTimeStatus.PAST_MIDNIGHT: {
      type = 'in_pass_midnight';
      break;
    }

    // case LERRequestStatus.Rejected:
    case FacilityStatus.INACTIVE:
    case SuspensionStatus.SUSPENDED:
    case SuspensionStatus.EXPIRED:
    case SuspensionStatus.NOT_AUTHORIZED:
    case LERNotificationStatus.REJECTED:
    case EquipmentTypeStatus.INACTIVE: {
      type = 'danger';
      break;
    }

    case OmsStatus.APPROVED:
    case FacilityStatus.FUTURE: {
      type = 'info';
      break;
    }

    case OmsStatus.OUT: {
      type = 'basic';
      break;
    }

    case LERNotificationStatus.PENDING:
    case OmsStatus.SUBMITTED: {
      type = 'warning-yellow';
      break;
    }

    case OmsStatus.SCHEDULED: {
      type = 'future';
      break;
    }

    case OmsStatus.IN: {
      type = 'future-purple';
      break;
    }

    case LERRequestStatus.Scheduled: {
      type = 'scheduled';
      break;
    }

    case LERRequestStatus.Approved: {
      type = 'approved';
      break;
    }

    case LERRequestStatus.Study: {
      type = 'study';
      break;
    }

    case LERRequestStatus.Submitted: {
      type = 'submitted';
      break;
    }

    case LERRequestStatus.Created: {
      type = 'created';
      break;
    }

    case LERRequestStatus.In: {
      type = 'in';
      break;
    }
    case LERRequestStatus.Out: {
      type = 'out';
      break;
    }

    case LERRequestStatus.Completed: {
      type = 'completed';
      break;
    }

    case LERRequestStatus.Cancelled: {
      type = 'cancelled';
      break;
    }
  }

  if (label) {
    return (
      <ATMLabel className={classNames(styles.label, styles[type])}>
        {language[value] ?? value}
      </ATMLabel>
    );
  }

  if (bold) {
    return (
      <span className={classNames(styles.status, styles.bold, styles[type])}>
        {children ?? language[value]}
      </span>
    );
  }

  if (timeStatus) {
    return (
      <div>
        <span style={{ color: '#b5ebd7 !important' }}>|</span>
        <span className={classNames(styles.timeStatus, styles[type])}>
          {languageHours?.language === SubstationTimeStatus.OUT
            ? children ?? 'Out'
            : children ?? 'In'}{' '}
        </span>
        <span className={styles.txtItalic}>
          {children ?? (languageHours && languageHours.hours)}
        </span>
      </div>
    );
  }

  return (
    <span className={classNames(styles.status, styles[type])}>
      {children ?? language[value]}
    </span>
  );
};

export default Status;
