/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { ATMButton, ATMDropdown, ATMIcon, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { IAuthorizedList } from 'src/models/authorized-list.model';
import { IEmployee } from 'src/models/employee.model';
import { ISubCategory } from 'src/models/sub-category.model';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { AuthListType } from 'src/constants';
import AuthorizedListForm from '../authorized-list-form/authorized-list-form.component';
import styles from '../authorized-list-details/auth-list-details.module.scss';

type IProps = {
  data?: Partial<IAuthorizedList>;
  entryText?: string;
  addEntryLink?: boolean;
  fromList?: boolean;
  employee?: IEmployee;
  subCategoryList?: ISubCategory[];
  subCategoryStatus: boolean;
};

const AuthorizedListAdd: React.FC<IProps> = ({
  data,
  entryText,
  addEntryLink,
  fromList = false,
  subCategoryList,
  subCategoryStatus,
}) => {
  const { state, actions } = useAuthorizedListContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [type, setType] = useState<string>();
  const [contractorShortName, setContractorShortName] = useState(null);

  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleOpen = useCallback(
    (actionType?: string) => {
      setType(actionType);

      if (!actionType) {
        actions.setData();
      }
    },
    [actions, setType]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      const response = await actions.createPOST({
        ...formData,
        contracShortNm: !fromList ? data?.contracShortNm : contractorShortName,
      });
      if (!response.error) {
        handleOpen();
        if (fromList) {
          history.push(`/auth-list?id=${formData.empId}`);
        }

        const empFullName =
          formData.fullName ?? response?.payload?.fullName ?? '<unavailable>';

        ToastSuccess(`Training Record successfully added for ${empFullName}`);
        actions.empDataGET(formData.empId);
      } else {
        ToastError(
          `Error in adding the training record. Error: ${response.error.message}`
        );
      }
    },
    [actions, data, contractorShortName, setContractorShortName, handleOpen]
  );

  if (!fromList && !data) {
    return null;
  }

  const optionList = (
    <ATMDropdown
      className={`${styles.sempraSmallMarginRight} ${styles.sempraSmallMarginBottom} ${styles.blueButton}`}
      button
      size="small"
      trigger={
        <span>
          <ATMIcon name="plus" />
          {Lang.LBL_ADD_TRAINING}
        </span>
      }
    >
      <ATMDropdown.Menu>
        {Object.entries(AuthListType).map(([key, value]) => (
          <ATMDropdown.Item
            key={key}
            content={value}
            onClick={() => handleOpen(value)}
          />
        ))}
      </ATMDropdown.Menu>
    </ATMDropdown>
  );

  const triggerBtn = (): React.ReactNode => {
    if (fromList === true) {
      return (
        <ATMButton
          primary
          icon="plus"
          content={Lang.LBL_ADD_AUTHORIZED_MEMBER}
          onClick={() => {
            handleOpen('add');
          }}
        />
      );
    }
    if (addEntryLink === true) {
      return (
        <a
          role="button"
          onClick={() => {
            handleOpen(entryText);
          }}
        >
          {Lang.LBL_ADD}
        </a>
      );
    }
    return optionList;
  };

  return (
    <ATMModal
      open={!!type}
      size="small"
      onClose={() => handleOpen()}
      trigger={triggerBtn()}
    >
      <ATMModal.Header>
        {fromList === true ? (
          Lang.LBL_ADD_AUTHORIZED_MEMBER
        ) : (
          <>
            {Lang.LBL_ADD} {type}
            {data?.fullName ? ` - ${data?.fullName}` : ''}
          </>
        )}
      </ATMModal.Header>
      <ATMModal.Content>
        <AuthorizedListForm
          fromList={fromList}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          authType={type}
          defaultValues={data}
          subCategoryList={subCategoryList}
          add
          subCategoryStatus={subCategoryStatus}
          entry={addEntryLink}
          contractorShortName={contractorShortName}
          setContractorShortName={setContractorShortName}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => {
            handleOpen();
            setContractorShortName(null);
          }}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || status.fetching}
          onClick={handleClick}
          loading={status.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default AuthorizedListAdd;
