import React, { useCallback, useState } from 'react';
import {
  ATMField,
  ATMInput,
  ATMCheckbox,
  ATMTable,
  useATMFormContext,
  ATMDatePicker,
  ATMDropdown,
  formatTime,
  REGEX_VALIDATION,
} from 'shared-it-appmod-ui';
import { debounce } from 'lodash';
import { TroubleJobSortStatus } from 'src/constants';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { ISortResponse } from 'src/models/switching.model';
import { ITroubleJobsForm } from 'src/models/trouble-jobs.model';
import { isSortStatusComplete } from 'src/components/pages/ler-request/ler-request-switching/ler-request-switching-form/ler-request-switching-form.component';
import style from '../trouble-jobs-form.module.scss';

type IProps = {
  data: Partial<ISortResponse>;
  index: number;
  setCancelAll: () => void;
  setSentAll: () => void;
  handleAllSentCancel: (type: string, check: boolean) => void;
  handleUpdateSortResponse: (
    id: number,
    value: Partial<ISortResponse>,
    sortTime?: Date | null | undefined,
    sent?: boolean | undefined,
    cancel?: boolean | undefined
  ) => void;
};

const ETSFormDispatchElement: React.FC<IProps> = ({
  data,
  index,
  setCancelAll,
  setSentAll,
  handleUpdateSortResponse,
}) => {
  const {
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useATMFormContext<ITroubleJobsForm>();

  const [date, setDate] = useState<Date | undefined>(
    data.sortTime ?? undefined
  );
  const [dateTime, setDateTime] = useState<Date | undefined>(
    data.sortTime ?? undefined
  );

  const handleDateTimeSelect = useCallback(
    (year, month, day, hour, min) => {
      const sortDateTime = new Date(year, month, day, hour, min);
      setDate(sortDateTime);
      setDateTime(sortDateTime);
      setValue(`sortResponses[${index}].sortTime` as any, sortDateTime);
      handleUpdateSortResponse(index, data, sortDateTime);
    },
    [control]
  );

  const handleCancelState = useCallback(
    (type: string, check: boolean) => {
      if (type === 'cancel' && check === true) {
        setValue('cancel' as any, true);
        setValue(`sortResponses[${index}].sent` as any, false);
        setSentAll();
      }
      if (type === 'cancel' && check === false) {
        setValue(`sortResponses[${index}].cancel` as any, false);
        setCancelAll();
      }
      if (type === 'sent' && check === true) {
        setValue(`sortResponses[${index}].cancel` as any, false);
        setValue(`sortResponses[${index}].sent` as any, true);
        setCancelAll();
      }
      if (type === 'sent' && check === false) {
        setValue(`sortResponses[${index}].sent` as any, false);
        setSentAll();
      }
    },
    [control, setCancelAll, setSentAll]
  );
  // useEffect(() => {
  //   if (!defaultValues?.logDtTm) {
  //     const day = date.getDate();
  //     const month = date.getMonth();
  //     const year = date.getFullYear();
  //     const hour = date.getHours();
  //     const min = date.getMinutes();
  //     handleDateSelect(year, month, day, hour, min);
  //   }
  // }, []);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  return (
    <>
      {/* hidden fields */}
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].tlSub`}
        defaultValue={data.tlSub}
        type="hidden"
        className="hidden"
      />
      {/* <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].byAt`}
        defaultValue={data?.byAt}
        type="hidden"
        className="hidden"
      /> */}
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].etmNbr`}
        defaultValue={data.etmNbr}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].jobKind`}
        defaultValue={data.jobKind}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].outBack`}
        defaultValue={data.outBack}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].sortId`}
        defaultValue={data.sortId}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].subDistId`}
        defaultValue={data.subDistId}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].subPole`}
        defaultValue={data.subPole}
        type="hidden"
        className="hidden"
      />
      <ATMField
        control={control}
        as={ATMInput}
        name={`sortResponses[${index}].sortTime`}
        defaultValue={data?.sortTime}
        type="hidden"
        className="hidden"
      />
      {/* {data.sortTime == null ? (
        <ATMTable.Cell>
          <ATMField
            as={ATMDatePicker}
            size="small"
            dateFormat="MM-DD-YYYY"
            isDateTimePicker
            dateTimePlaceHolder="Select"
            control={control}
            name={`sortResponses[${index}].sortTime`}
            // error={errors.date}
            onChange={([_, val]) => {
              const day = val?.value?.getDate();
              const month = val?.value?.getMonth();
              const year = val?.value?.getFullYear();
              const value = new Date(year, month, day);
              setDateSelected(value);
              return val.value;
            }}
          />
        </ATMTable.Cell>
      ) : ( */}
      <ATMTable.Cell className={style.outBackDateTime}>
        {data.sortStat === TroubleJobSortStatus.COMPLETE ? (
          formatDate(data?.sortTime)
        ) : (
          <ATMField
            as={ATMDatePicker}
            size="small"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            control={control}
            name={`tempDate[${index}]`}
            defaultValue={date ?? undefined}
            onChange={([_, { value }]) => {
              if (!value) {
                // Immediately set sortTime to undefined if date value is cleared.
                setDate(undefined);
                setImmediate(() => {
                  setValue(
                    `sortResponses[${index}].sortTime` as any,
                    undefined
                  );
                  setValue(`sortResponses[${index}].sent` as any, false);
                  setDate(undefined);
                  setDateTime(undefined);
                  clearErrors(`sortResponses[${index}].time` as any);
                  handleUpdateSortResponse(index, data, undefined);
                });
              } else {
                setImmediate(() => {
                  setDate(value);
                  const formattedTime = format24hTime(dateTime);
                  const dateSelected = new Date(value);
                  const day = dateSelected?.getDate();
                  const month = dateSelected?.getMonth();
                  const year = dateSelected?.getFullYear();
                  if (formattedTime) {
                    const hour = Number(formattedTime.split(':')[0]);
                    const min = Number(formattedTime.split(':')[1]);
                    handleDateTimeSelect(year, month, day, hour, min);
                  } else {
                    handleDateTimeSelect(year, month, day, 0, 0);
                  }
                });
              }
              return value ?? undefined;
            }}
            // value={undefined}
          />
        )}
      </ATMTable.Cell>
      <ATMTable.Cell>{data.outBack}</ATMTable.Cell>
      <ATMTable.Cell className={style.outBackDateTime}>
        {data.sortStat === TroubleJobSortStatus.COMPLETE ? (
          data.byAt
        ) : (
          <ATMField
            as={ATMDropdown}
            selection
            name={`sortResponses[${index}].byAt`}
            onChange={([_, { value }]) => value}
            options={[
              { key: 'AT', value: 'AT', text: 'AT' },
              { key: 'BY', value: 'BY', text: 'BY' },
            ]}
            control={control}
            defaultValue={data.byAt}
          />
        )}
      </ATMTable.Cell>
      <ATMTable.Cell textAlign="center">
        {isSortStatusComplete(data.sortStat as string) ? (
          format24hTime(dateTime)
        ) : (
          <>
            <ATMInput
              autoComplete="off"
              control={control}
              name={`sortResponses[${index}].time`}
              // defaultValue={format24hTime(data?.sortTime) ?? ''}
              value={dateTime ? format24hTime(dateTime) : undefined}
              maxLength={5}
              placeholder="hh:mm"
              className={style.fieldTimePicker}
              disabled={
                data?.sortStat === TroubleJobSortStatus.COMPLETE ||
                date === undefined
              }
              onChange={(_, { value }) => {
                const formattedTime = (value && formatTime(value)) ?? value;
                if (formattedTime) {
                  if (formattedTime.length > 4) {
                    handleTimeUpdate(
                      formattedTime,
                      `sortResponses[${index}].time`
                    );
                  }
                  const regArray = REGEX_VALIDATION.exec(formattedTime);
                  if (regArray) {
                    const hour = Number(formattedTime.split(':')[0]);
                    const min = Number(formattedTime.split(':')[1]);

                    if (!(hour > 23 || min > 59)) {
                      const day = date?.getDate();
                      const month = date?.getMonth();
                      const year = date?.getFullYear();
                      handleDateTimeSelect(year, month, day, hour, min);
                      clearErrors(`sortResponses[${index}].time` as any);
                    } else {
                      setError(`sortResponses[${index}].time` as any, {
                        message: 'Invalid Time',
                      });
                      setDateTime(undefined);
                      setValue(`sortResponses[${index}].sent` as any, false);
                      handleUpdateSortResponse(index, data, undefined);
                    }
                  }
                } else {
                  setDateTime(undefined);
                  clearErrors(`sortResponses[${index}].time` as any);
                  setValue(`sortResponses[${index}].sent` as any, false);
                  handleUpdateSortResponse(index, data, undefined);
                }
                const formattedTimeValue =
                  value && value.length >= 4 ? formatTime(value) : value;
                return formattedTimeValue;
              }}
              clearable
            />
            <ATMField
              control={control}
              as={ATMInput}
              name={`timeError[${index}]`}
              defaultValue=""
              type="hidden"
              error={errors?.sortResponses?.[index]?.time}
              className={style.timeError}
            />
            {/* <ATMField
          as={ATMTimePicker}
          name={`sortResponses[${index}].time`}
          control={control}
          defaultValue={format24hTime(data?.sortTime) ?? ''}
          value={dateTimeSelected ? format24hTime(dateTimeSelected) : undefined}
          is24hourformat
          className={style.fieldTimePicker}
          disabled={
            data?.sortStat === TroubleJobSortStatus.COMPLETE ||
            dateSelected === undefined
          }
          onChange={([_, { time }]) => {
            if (!time) {
              setDateTimeSelected(undefined);
            } else {
              const hour = Number(time.split(':')[0]);
              const min = Number(time.split(':')[1]);
              const day = dateSelected?.getDate();
              const month = dateSelected?.getMonth();
              const year = dateSelected?.getFullYear();

              handleDateTimeSelect(year, month, day, hour, min);
            }

            return time ?? undefined;
          }}
        /> */}
          </>
        )}
      </ATMTable.Cell>
      <ATMTable.Cell width="12">{data.tlSub}</ATMTable.Cell>
      <ATMTable.Cell>{data.subPole}</ATMTable.Cell>
      <ATMTable.Cell>{data.subDistId}</ATMTable.Cell>
      <ATMTable.Cell>
        <ATMField
          as={ATMInput}
          name={`sortResponses[${index}].dspchRem`}
          control={control}
          defaultValue=" "
          disabled={data.sortStat === TroubleJobSortStatus.COMPLETE}
          autoComplete="off"
        />
      </ATMTable.Cell>
      <ATMTable.Cell>{data.sortId}</ATMTable.Cell>
      <ATMTable.Cell>{data.sortStat}</ATMTable.Cell>
      <ATMTable.Cell className={style.centerCheckbox}>
        <ATMField
          key={`sortResponses_sent_${index}_${data.sent ? '1' : '0'}`}
          className={
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.COMPLETE ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELED ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELSENT ||
            !dateTime
              ? style.disableCenterCheckbox
              : style.centerCheckbox
          }
          as={ATMCheckbox}
          value={(getValues().sortResponses || [])[index].sent}
          name={`sortResponses[${index}].sent`}
          style={{ paddingLeft: 5, marginBottom: 0 }}
          readOnly={
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.COMPLETE ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELED ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELSENT ||
            !dateTime
          }
          control={control}
          onChange={([_, { checked }]) => {
            handleCancelState('sent', checked);
            // handleUpdateSortResponse(
            //   index,
            //   data,
            //   dateTime,
            //   checked,
            //   checked ? false : undefined
            // );
            return checked;
          }}
        />
      </ATMTable.Cell>
      <ATMTable.Cell className={style.centerCheckbox}>
        <ATMField
          key={`sortResponses_cancel_${index}_${data.cancel ? '1' : '0'}`}
          as={ATMCheckbox}
          className={
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.COMPLETE ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELED ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELSENT
              ? style.disableCenterCheckbox
              : style.centerCheckbox
          }
          style={{ paddingLeft: 9, marginBottom: 0 }}
          name={`sortResponses[${index}].cancel`}
          value={(getValues().sortResponses || [])[index].cancel}
          readOnly={
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.COMPLETE ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELED ||
            data.sortStat?.toUpperCase() === TroubleJobSortStatus.CANCELSENT
          }
          control={control}
          onChange={([_, { checked }]) => {
            handleCancelState('cancel', checked);
            // handleUpdateSortResponse(
            //   index,
            //   data,
            //   undefined,
            //   checked ? false : undefined,
            //   checked
            // );
            return checked;
          }}
        />
      </ATMTable.Cell>
    </>
  );
};

export default ETSFormDispatchElement;
