import React from 'react';
import { IssuedKeyStatus } from 'src/constants';
import Lang from 'src/libraries/language';

type IProps = {
  language?: Record<string, string>;
  value?: IssuedKeyStatus | string | null;
};

const DictionaryRename: React.FC<IProps> = ({
  language = Lang.ISSUED_KEY_STATUS,
  value,
}) => {
  if (!value) {
    return null;
  }

  return <span>{language[value]}</span>;
};

export default DictionaryRename;
