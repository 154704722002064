/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import Axios, { CancelTokenSource } from 'axios';
import {
  ATMButton,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMHeader,
  ATMInput,
  ATMLabel,
  ATMSegment,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import CalendarTimeline from 'src/components/organisms/calendar/calendar-timeline/calendar-timeline.component';
import Moment, { IMoment } from 'src/libraries/moment.library';
import { useCalendarOutageContext } from 'src/contexts/calendar-outage.context';
import {
  IOutageDateCalendar,
  IOutageDateFilter,
  OutageDateFilterSchema,
} from 'src/models/calendar-outage.model';
import { getCalendarOutageStatus } from 'src/selectors/calendar-outage.selector';
import { calendarOutageActionTypes } from 'src/ducks/calendar-outage.duck';
import { ICalendarEvent } from 'src/libraries/calendar.library';
import { ToastWarning } from 'src/components/atoms/toaster/toaster.component';
import CalendarTimelineDownload from 'src/components/organisms/calendar/calendar-timeline/calendar-timeline-download.component';
import { getLerOutageDateList } from 'src/helpers/ler-request.helper';
import {
  LocalStorageId,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/helpers/storage.helper';
import {
  AuthorizationType,
  LERRequestStatus,
  TYPE_DUPLICATE,
} from 'src/constants';
import OutageCalendarFilters, {
  SortByOptions,
  outageCalendarExcludeStatus,
} from './outage-calendar-filters/outage-calendar-filters.component';
import OutageCalendarEvent from './outage-calendar-event/outage-calendar-event.component';
import styles from './outage-calendar.module.scss';
import { OrderBy } from './outage-calendar-filters/outage-calendar-sort-radio-options.component';

const { CancelToken } = Axios;
const DEFAULT_DAYS = 30;

const completeListOutAuthType = Object.values(AuthorizationType).filter(
  (val) => typeof val === 'number'
) as number[];

const completeListOutReqStatus = (
  Object.values(LERRequestStatus).filter(
    (val) => typeof val === 'number'
  ) as number[]
).filter((val) => !outageCalendarExcludeStatus.includes(val));

const getDateRage = (value?: IMoment | Date, days?: number | string) => {
  const day = !days || days === '0' || days === '' ? 1 : Number(days);
  const date = !value ? Moment() : Moment(value);

  return [
    date.startOf('day'),
    date
      .clone()
      .add(day - 1, 'day')
      .endOf('day'),
  ];
};

const OutageCalendar: React.FC = () => {
  const {
    state: { resources, events, status: apiStatus },
    actions,
  } = useCalendarOutageContext();
  const [filters, setFilters] = useState<IOutageDateFilter>(() => {
    const params = getLocalStorageItem(LocalStorageId.OUTAGE_CALENDAR_FILTERS);
    const days = params?.days ?? DEFAULT_DAYS;

    return {
      ...params,
      days,
      date: params?.date || Moment().startOf('day'),
      outageTypeId: params?.outageTypeId || completeListOutAuthType,
      outgReqStatusId: params?.outgReqStatusId || completeListOutReqStatus,
      facTypId: params?.facTypId || [],
    };
  });

  const cacheFilter = (dataFilters) => {
    setLocalStorageItem(
      LocalStorageId.OUTAGE_CALENDAR_FILTERS,
      dataFilters,
      Moment().endOf('day').diff(Moment(), 'minute')
    );
  };

  const getCachedFilter = useCallback(() => {
    const cache: IOutageDateFilter = getLocalStorageItem(LocalStorageId.OUTAGE_CALENDAR_FILTERS);
    let cachedData: IOutageDateFilter = {
      ...filters,
      sortListBy: {
        sortBy: {
          by: SortByOptions.Voltage,
          orderBy: OrderBy.DESC
        },
        thenBy: {
          by: SortByOptions.FacilityName,
          orderBy: OrderBy.ASC
        }
      }
    };
    if (cache?.sortListBy) {
      cachedData = {
        ...filters,
        sortListBy: cache.sortListBy,
      };
    }
    return cachedData;
  }, [filters]);

  const [, setToken] = useState<CancelTokenSource | undefined>();
  const [initialized, setInitialized] = useState(false);
  const [showSortFilters, setShowSortFilters] = useState(false);
  const [sortFilters, setSortFilters] = useState<IOutageDateFilter>(getCachedFilter());

  const status = getCalendarOutageStatus(
    { status: apiStatus },
    calendarOutageActionTypes.CALENDAR_OUTAGE_LIST_READ
  );

  const handleFetch = useCallback(
    async (dataFilters) => {
      const source = CancelToken.source();
      setToken((value) => {
        if (value) {
          value.cancel(TYPE_DUPLICATE);
        }
        return source;
      });

      const params = Object.entries(dataFilters)
        .map(([name, value]) => ({ name, value }))
        .filter((v) =>
          Array.isArray(v.value) ? v.value.length > 0 : !!v.value
        );

      cacheFilter(dataFilters);
      await actions.listGET(
        {
          page: 1,
          limit: 0,
          filters: params,
        },
        {
          cancelToken: source.token,
        }
      );
      const cachedData = getCachedFilter();
      if (cachedData?.sortListBy) {
        actions.sortOutageCalendarList(cachedData);
      } else {
        const cachedFilter = getCachedFilter();
        cacheFilter(cachedFilter);
        actions.sortOutageCalendarList(cachedFilter);
      }
      setInitialized(true);
    },
    [actions, setToken, setInitialized, getCachedFilter]
  );

  const handleSorting = useCallback((dataFilters: IOutageDateFilter) => {
    cacheFilter({
      ...filters,
      sortListBy: dataFilters?.sortListBy,
    });
     actions.sortOutageCalendarList({
      ...filters,
      sortListBy: dataFilters?.sortListBy }
      );
  }, [actions, filters]);

  useEffect(() => {
    handleFetch(filters);
  }, [filters, handleFetch]);

  useEffect(() => {
    handleSorting(sortFilters);
  }, [sortFilters, filters, handleSorting]);

  const handleFilters = useCallback(
    debounce((data: React.SetStateAction<IOutageDateFilter>) => {
      setFilters(data);
    }, 300),
    [setFilters]
  );

  const handleIntervalChange = useCallback(
    (value) => {
      if (value === '0' || value === '') {
        ToastWarning('Please enter a number of days greater than 0');
      }

      const day = value === '0' || value === '' ? 1 : Number(value);

      handleFilters((values) => ({
        ...values,
        days: day,
      }));
    },
    [actions, handleFilters]
  );

  const handleDateChange = useCallback(
    (date: IMoment | Date) => {
      handleFilters((values) => {
        return {
          ...values,
          date: Moment(date).startOf('day').toDate(),
        };
      });

      return date;
    },
    [actions, handleFilters]
  );

  const eventList = useMemo(() => {
    return [...events].reduce((itemList, value) => {
      const items = getLerOutageDateList(value?.outageDates).map((val) => {
        return {
          data: val,
          title: (_, __, span) => (
            <OutageCalendarEvent
              data={val as IOutageDateCalendar}
              span={span}
            />
          ),
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          resourceId: val.requestId as number,
          start: Moment(val.startTm),
          end: Moment(val.stopTm),
        } as ICalendarEvent;
      });
      return [...itemList, ...(items || [])];
    }, [] as ICalendarEvent<IOutageDateCalendar>[]);
  }, [events]);

  return (
    <div className="admin-container">
      <div className={styles.header}>
        <ATMHeader>{Lang.TTL_OUTAGE_CALENDAR}</ATMHeader>
      </div>
      <>
        <ATMForm
          defaultValues={{
            ...filters,
            date: Moment(filters.date).toDate(),
          }}
          validationSchema={OutageDateFilterSchema}
        >
          {(props) => {
            const { control, reset } = props;

            return (
              <ATMFormProvider {...props}>
                <div className={styles.dateSelectors}>
                  <div>
                    <ATMLabel
                      className={styles.labelDatePicker}
                      style={{ paddingLeft: 0 }}
                    >
                      Start Date
                    </ATMLabel>
                    <ATMField
                      as={ATMDatePicker}
                      name="date"
                      control={control}
                      onChange={([_, { value }]) => {
                        if (value) {
                          return handleDateChange(value);
                        }
                        // Do nothing if date is cleared.
                        return value;
                      }}
                    />
                  </div>
                  <div>
                    <ATMLabel className={styles.labelDatePicker}>Days</ATMLabel>

                    <ATMField
                      as={ATMInput}
                      className={styles.inputDays}
                      name="days"
                      placeholder="Enter"
                      control={control}
                      maxLength={3}
                      onChange={([_, { value }]) => {
                        if (Number(value) > 0) {
                          handleIntervalChange(value);
                        }

                        return value;
                      }}
                    />
                  </div>

                  <div>
                    <ATMButton
                      type="button"
                      primary
                      loading={status.fetching}
                      disabled={status.fetching}
                      onClick={() => {
                        const defaultValues = {
                          date: new Date(),
                          days: DEFAULT_DAYS,
                          outageTypeId: completeListOutAuthType,
                          outgReqStatusId: completeListOutReqStatus,
                          facTypId: filters.facTypId,
                          outgFacNm: null,
                          substationId: '',
                          outgFacId: null,
                          voltId: [],
                        } as any;

                        setFilters(defaultValues);

                        reset(defaultValues);
                      }}
                    >
                      {Lang.LBL_RESET}
                    </ATMButton>
                  </div>
                </div>
                <ATMHeader attached="top" className={styles.noMarginTop}>
                  <OutageCalendarFilters
                    filters={filters}
                    showSortFilters={showSortFilters}
                    sortBy={sortFilters}
                    setFilters={handleFilters}
                    setShowSortFilters={setShowSortFilters}
                    setSortBy={setSortFilters}
                    downloadButton={
                      <CalendarTimelineDownload
                        date={getDateRage(filters.date, filters.days)}
                        resources={resources}
                        events={eventList}
                      />
                    }
                  />
                </ATMHeader>
              </ATMFormProvider>
            );
          }}
        </ATMForm>

        <ATMSegment
          attached
          style={{ padding: 0 }}
          loading={!(resources.length && !initialized) && status.fetching}
        >
          <CalendarTimeline
            date={filters.date}
            interval={[(filters?.days ?? 1) - 1, 'day']}
            resources={resources}
            onChange={handleDateChange}
            events={eventList}
          />
        </ATMSegment>
      </>
    </div>
  );
};

export default OutageCalendar;
