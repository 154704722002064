import { httpClient } from 'src/libraries/http.library';
import { OmsAuditReportListPayloadSchema } from 'src/models/oms-audit-report.model';

const client = httpClient();
const endpoint = 'oms-audit-report';

const services = {
  listGET: async (data?: Record<string, any>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    const result = client.get(
      `/${endpoint}`,
      params,
      OmsAuditReportListPayloadSchema
    );

    return result;
  },
};

export default services;
