import faker from 'faker';
import { IIssuedKey, IIssuedKeyDetail } from '../issued-key.model';
import { EmployeeMock } from './key-request.mock';

export const keyDetailMock = (
  data: Partial<IIssuedKeyDetail> = {}
): IIssuedKeyDetail => ({
  keyNbr: faker.datatype.number(),
  empId: faker.datatype.string(),
  recipContrId: faker.datatype.string(),
  sdgeContactId: faker.datatype.string(),
  contrShortNm: faker.commerce.department(),
  issDt: faker.date.future(),
  returnDt: faker.date.future(),
  reason: faker.lorem.sentence(),
  stat: faker.datatype.string(),
  updatedAt: faker.date.recent(),
  updatedBy: faker.name.findName(),
  keyRequestDetail: [
    {
      keyAdminMailLoc: faker.datatype.string(),
      noOfKeys: faker.datatype.number(50),
    },
  ],
  emp: {
    ...EmployeeMock(),
    title: faker.helpers.randomize(['Employee', 'Contractor']),
    supvr: EmployeeMock(),
  },
  ...data,
});

export const issuedKeyMock = (data: Partial<IIssuedKey> = {}): IIssuedKey => ({
  keyNbr: faker.datatype.number(),
  stat: faker.helpers.randomize([
    'active',
    'damaged',
    'lost',
    'pending',
    'returned',
    'not-returned',
  ]),
  keyTypId: faker.datatype.string(),
  keyDetails: Array(3)
    .fill(null)
    .map(() => keyDetailMock()),
  updatedAt: faker.date.recent(),
  updatedBy: faker.name.findName(),
  keyDetail: keyDetailMock(),
  ...data,
});
