import React from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { ITrainers } from 'src/models/trainers.model';
import { TrainerStatus } from '../trainers-table/trainers-table.component';
import style from '../trainers.module.scss';

type IProp = {
  data: ITrainers;
  isOpen: boolean;
  loading?: boolean;
  status: TrainerStatus;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const TrainerStatusUpdateView: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  status,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMButton
        className={style.actionBtn}
        onClick={() => handleOpen(true)}
        content={status === TrainerStatus.ACTIVE ? 'Deactivate' : 'Activate'}
      />
    }
  >
    <ATMModal.Header>
      {status === TrainerStatus.ACTIVE
        ? 'Deactivate Trainers'
        : 'Activate Trainers'}
    </ATMModal.Header>
    <ATMModal.Content>
      <p>
        {status === TrainerStatus.ACTIVE
          ? 'Are you sure you want to deactivate '
          : 'Are you sure you want to activate '}
        <strong>{data.trainer.firstName}</strong>?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary content="Cancel" onClick={() => handleOpen(false)} />
      {status === TrainerStatus.ACTIVE ? (
        <ATMButton
          negative
          content="Yes, Deactivate"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      ) : (
        <ATMButton
          negative
          content="Yes, Activate"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      )}
    </ATMModal.Actions>
  </ATMModal>
);

export default TrainerStatusUpdateView;
