import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { UserSchema } from 'src/models/user.model';

export const UserGrpMemberShape = {
  userGrpId: yup.number(),
  empId: yup.string().required(),
  firstName: yup.string(),
  lastName: yup.string(),
  emailAdd: yup.string(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const UserMemberSchema = yup.object(UserGrpMemberShape);

export const UserGroupShape = {
  userGrpId: yup.number().required(),
  userGrpNm: yup.string().required(),
  userGrpEmail: yup.string().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
  userGrpMembers: yup.array().of(UserMemberSchema),
};

export const UserGroupSchema = yup.object(UserGroupShape);

export const UserGroupFormSchema = yup.object({
  userGrpId: yup.number(),
  userGrpNm: yup.string().required(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
  userGrpMembers: yup
    .array()
    .of(
      yup.object({
        userGrpId: yup.number(),
        empId: yup.string().required(),
        firstName: yup.string(),
        lastName: yup.string(),
        emailAdd: yup.string(),
      })
    )
    .required()
    .default([]),
  userGrpEmail: yup
    .string()
    .nullable()
    .email()
    .when(['userGrpMembers'], {
      is: (val) => (val ? val.length === 0 : val === undefined),
      then: yup.string().email().required(Lang.MSG_USER_GROUP_EMAIL_ERROR),
      otherwise: yup.string().nullable().email(),
    }),
});

export const UserGroupListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(UserGroupSchema.clone()),
});

export const UserGroupPayloadSchema = UserGroupSchema.clone();

export const UserGroupDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IUser = yup.Asserts<typeof UserSchema>;
export type IUserGroup = yup.Asserts<typeof UserGroupSchema>;
export type IUserGroupForm = yup.Asserts<typeof UserGroupFormSchema>;
export type IUserGroupMembers = yup.Asserts<typeof UserMemberSchema>;
