import { SettingName } from 'src/constants';
import { settingActionTypes, ISettingState } from 'src/ducks/setting.duck';
import { ISetting } from 'src/models/setting.model';
import { IStatus } from 'src/libraries/thunk.library';

export const getSettingStatus = (
  state: ISettingState,
  action: keyof typeof settingActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getSetting = (name: SettingName, list: ISettingState['list']) => {
  return list.find((val) => val.name === name);
};

export const getSettingValue = (
  name: SettingName,
  list: ISettingState['list']
): string | null => {
  const result = getSetting(name, list);

  return (result && result.value) || null;
};

export const filterByCategory = (list: ISetting[], category: string) => {
  return list.filter((val) => val.category === category);
};
