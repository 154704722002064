import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import {
  SubstationFormSchema,
  ISubstation,
  ISubstationForm,
} from 'src/models/substation.model';
import Lang from 'src/libraries/language';
import SubstationEntryForm from '../substation-entry-form/substation-entry-form.component';
import SubstationDelete from '../substation-delete/substation-delete.component';
import SubstationForm from '../substation-form/substation-form.component';
import styles from './substation-edit.module.scss';

type IProps = {
  defaultValues: ISubstation;
  isOpen: boolean;
  loading: boolean;
  details: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISubstationForm) => void;
  openDelete: boolean;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubstationEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
  openDelete,
  setOpenDelete,
  details,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        details ? (
          <ATMButton icon="edit" onClick={() => handleOpen(true)} />
        ) : (
          <ATMButton icon="edit" onClick={() => handleOpen(true)} />
        )
      }
    >
      <ATMModal.Header>{Lang.TTL_SUBSTATAIONS_EDIT}</ATMModal.Header>
      <ATMModal.Content>
        {details ? (
          <SubstationEntryForm
            defaultValues={defaultValues}
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            details
          />
        ) : (
          <SubstationForm
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            defaultValues={SubstationFormSchema.cast(defaultValues)}
          />
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        {details ? (
          <span />
        ) : (
          <ATMButton
            negative
            content="Delete"
            id="delete"
            onClick={() => setOpenDelete(true)}
            className={styles.floatLeft}
          />
        )}
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          id="save"
          content={Lang.LBL_UPDATE}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
      <SubstationDelete
        isOpen={openDelete}
        handleOpen={setOpenDelete}
        data={defaultValues}
        handleOpenEdit={handleOpen}
      />
    </ATMModal>
  );
};

export default SubstationEditView;
