import faker from 'faker';
import { IAuthUser } from 'src/libraries/amplify.library';
import { IUser } from '../user.model';

export const userMock = (data: Partial<IUser> = {}): IUser => ({
  employeeId: faker.datatype.uuid().slice(0, 6),
  employeeName: faker.name.findName(),
  employeeEmail: faker.internet.email(),
  employeeRole: faker.name.jobArea(),
  employeeTitle: faker.name.jobTitle(),
  employeePhone: faker.phone.phoneNumber(),
  createdAt: faker.date.past(),
  createdBy: faker.name.findName(),
  updatedAt: faker.random.arrayElement([faker.date.recent(), undefined]),
  updatedBy: faker.random.arrayElement([faker.name.findName(), undefined]),
  ...data,
});

export const authUserMock = (): IAuthUser => ({
  e_mail_address: faker.internet.email(),
  emp_no: faker.datatype.uuid().slice(0, 6),
  first_name: faker.name.firstName(),
  last_name: faker.name.lastName(),
});
