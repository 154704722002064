import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/work-group.service';
import { IWorkGroup } from 'src/models/work-group.model';

export const workGroupActionTypes = {
  WORK_GROUP_DATA_READ: 'WORK_GROUP_DATA_READ',
  WORK_GROUP_LIST_READ: 'WORK_GROUP_LIST_READ',
  WORK_GROUP_DATA_CREATE: 'WORK_GROUP_DATA_CREATE',
  WORK_GROUP_DATA_UPDATE: 'WORK_GROUP_DATA_UPDATE',
  WORK_GROUP_DATA_DELETE: 'WORK_GROUP_DATA_DELETE',
  WORK_GROUP_DATA_SET: 'WORK_GROUP_DATA_SET',
  WORK_SUB_GROUP_VALIDATE: 'WORK_SUB_GROUP_VALIDATE',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: workGroupActionTypes.WORK_GROUP_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: workGroupActionTypes.WORK_GROUP_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: workGroupActionTypes.WORK_GROUP_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: workGroupActionTypes.WORK_GROUP_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: workGroupActionTypes.WORK_GROUP_DATA_DELETE,
    service: services.dataDELETE,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  validateSubGrp: {
    type: workGroupActionTypes.WORK_SUB_GROUP_VALIDATE,
    service: services.validateSubGrp,
  },

  // This is a sync action
  setData: (workGroup: IWorkGroup) => ({
    type: workGroupActionTypes.WORK_GROUP_DATA_SET,
    payload: workGroup,
  }),
};

export type IWorkGroupAsync = typeof duckActions;

export interface IWorkGroupState
  extends ICommonState<typeof workGroupActionTypes> {
  data?: IWorkGroup;
  list: IWorkGroup[];
  total: number;
}

export const defaultState: IWorkGroupState = {
  status: {},
  list: [],
  total: 0,
};

const WorkGroupReducer = (
  state: IWorkGroupState,
  action: IReducerAction<IWorkGroupAsync>
): IWorkGroupState => {
  switch (action.type) {
    case workGroupActionTypes.WORK_GROUP_DATA_SET:
    case workGroupActionTypes.WORK_GROUP_DATA_READ:
    case workGroupActionTypes.WORK_GROUP_DATA_UPDATE:
    case workGroupActionTypes.WORK_GROUP_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case workGroupActionTypes.WORK_GROUP_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case workGroupActionTypes.WORK_GROUP_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.trbGrpId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case workGroupActionTypes.WORK_SUB_GROUP_VALIDATE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default WorkGroupReducer;
