import React, { useCallback, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMGrid,
  ATMHeader,
  ATMLabel,
  ATMLoader,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import {
  LERRequestChangeStatus,
  LERRequestFormStep,
  LERRequestStatus,
} from 'src/constants';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
  getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import Lang from 'src/libraries/language';
import { ILerRequestItem } from 'src/models/ler-request.model';
import OutageStatus from 'src/components/atoms/outage-status/outage-status.component';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import styles from '../ler-request.module.scss';
import LerRequestVersionDetail from './ler-request-version-control-detail.component';

type IProps = {
  requestId: number;
};
const LerRequestVersion: React.FC<IProps> = ({ requestId }) => {
  const { state, actions } = useLerRequestContext();
  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_VERSION_READ
  );
  const loadingVersionSelected = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_VERSION_SELECTED_READ
  );
  const [isChecked, setIsChecked] = useState<Array<string>>();
  const [isCompare, setIsCompare] = useState(false);
  const [enable, setEnable] = useState(false);

  const handleChecked = useCallback(
    (version_no: string) => {
      if (isChecked && isChecked.length > 0) {
        if (isChecked.includes(version_no)) {
          const pastChecked = isChecked;
          pastChecked.splice(isChecked.indexOf(version_no), 1);
          setIsChecked(pastChecked);
        } else {
          const newChecked = isChecked;
          newChecked.push(version_no);
          setIsChecked(newChecked);
        }
        setEnable(isChecked.length >= 2);
      } else {
        setIsChecked([version_no]);
      }
    },
    [isChecked]
  );

  const handleCompare = useCallback(() => {
    if (isChecked && isChecked.length <= 5) {
      actions.versionSelectedGET(requestId, isChecked);
      setIsCompare(true);
    } else {
      ToastError(Lang.MSG_VERSION_CONTROL_MAX);
    }
  }, [actions, isChecked]);

  const columns: IORGDataTableColumn<ILerRequestItem>[] = [
    {
      index: 'version_no',
      title: Lang.LBL_VERSION_NO,
      render: (_, value) => (
        <ATMCheckbox
          name={value.version_no?.toString()}
          onChange={() => handleChecked(value.version_no.toString())}
          label={value.version_no?.toString()}
          style={{ marginTop: '1rem' }}
        />
      ),
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'requestStatus',
      title: 'Request Status',
      render: (_, value) => {
        if (
          value.requestStat === LERRequestStatus.Cancelled &&
          value.changeReqStat === LERRequestChangeStatus.Submitted
        ) {
          return (
            <ATMLabel customcolor="lightred">
              {Lang.LBL_PENDING_CANCEL}
            </ATMLabel>
          );
        }
        return <OutageStatus data={value} label />;
      },
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'crStatus',
      title: 'CR Status',
      render: (_, value) =>
        value.changeReqStat && (
          <ATMLabel className={styles.labelTransparent}>
            {value.changeReqStat}
          </ATMLabel>
        ),
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: `${LERRequestFormStep.OUTAGE_FACILITY}.facTypId`,
      title: Lang.LBL_OUTAGE_TYPE,
      render: (_, value) => value.outageFacility?.outageType?.outageTypeDesc,
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: `${LERRequestFormStep.OUTAGE_FACILITY}.outageFacility.authorization`,
      title: Lang.LBL_AUTHORIZATION_TYPE,
      render: (_, value) => (
        <span>{value.outageFacility.outgTypId ? 'OK' : 'NA'}</span>
      ),
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'requestorName',
      title: 'Request Name',
      render: (_, value) =>
        value.requestor
          ? `${value.requestor?.firstName} ${value.requestor?.lastName}`
          : null,
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'outageFacility',
      title: 'Facility Name',
      render: (_, value) => value.outageFacility?.facility?.outgFacNm,
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'substation',
      title: 'Substation',
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'startDate',
      title: 'Start Date',
      render: (_, value) => getLerRequestStartDate(value.outageDates),
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => getLerRequestEndDate(value.outageDates),
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'days',
      title: 'Days',
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
    {
      index: 'assignedTo',
      title: 'Assigned to',
      cellClass: (_, value) =>
        value.version_no === state.data?.version_no &&
        value.requestStat === state.data?.requestStat
          ? 'positive'
          : undefined,
    },
  ];
  return (
    <div
      className="admin-container"
      style={
        isCompare && isChecked && isChecked.length > 3
          ? { overflowX: 'scroll' }
          : {}
      }
    >
      {!isCompare ? (
        <ATMGrid>
          <ATMGrid.Row columns={2}>
            <ATMGrid.Column verticalAlign="middle">
              <ATMHeader as="h2"> {Lang.TTL_LER_REQUEST_VERSION}</ATMHeader>
            </ATMGrid.Column>
            <ATMGrid.Column textAlign="right" verticalAlign="middle">
              <ATMButton
                secondary
                content={Lang.LBL_COMPARE_SELECTED}
                type="button"
                disabled={!enable}
                onClick={() => {
                  handleCompare();
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              {state?.version && !loading.fetching ? (
                <ORGDataTable
                  columns={columns}
                  data={state.version}
                  celled={false}
                  isHistory
                  showPagination={false}
                />
              ) : (
                <ATMLoader active inline="centered" />
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      ) : (
        <LerRequestVersionDetail
          data={state.versionSelected}
          setIsCompare={setIsCompare}
          setIsChecked={setIsChecked}
          loading={loadingVersionSelected.fetching}
          handleEnable={setEnable}
        />
      )}
    </div>
  );
};

export default LerRequestVersion;
