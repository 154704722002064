import React, { useCallback, useEffect, useState } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useSubstationCountListContext } from 'src/contexts/substation-count-list.context';
import { substationCountListActionTypes } from 'src/ducks/substation-count-list.duck';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { ISubstationCountListForm } from 'src/models/substation-count-list.model';
import { getSubstationCountListStatus } from 'src/selectors/substation-count-list.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import SubstationCountListListView from './substation-count-list.view';

const SubstationCountListList: React.FC = () => {
  const { state, actions } = useSubstationCountListContext();
  const status = getSubstationCountListStatus(
    state,
    substationCountListActionTypes.SUBSTATION_COUNT_LIST_LIST_READ
  );
  const loading = getSubstationCountListStatus(
    state,
    substationCountListActionTypes.SUBSTATION_COUNT_LIST_DATA_UPDATE
  ).fetching;

  const [loadingDL, setLoadingDL] = useState(false);
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending' | undefined
  >();
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [params, setParams] = useState<Record<any, any>>({
    page: 1,
  });
  useEffect(() => {
    actions.listGET(params);
  }, [params, actions.listGET]);

  const handleFetch = useCallback(
    (data) => {
      actions.listGET(data);
    },
    [actions, setParams]
  );

  const handleSorting = useCallback(
    (clickedColumn: string) => {
      if (sortedColumn !== clickedColumn) {
        setSortedColumn(clickedColumn);
        setSortDirection('ascending');
        setParams((prevParams) => ({
          ...prevParams,
          page: 1,
          sort: clickedColumn,
          order: 'ascending',
        }));
      } else {
        const newSortDirection =
          sortDirection === 'ascending' ? 'descending' : 'ascending';
        setSortDirection(newSortDirection);
        setParams((prevParams) => ({
          ...prevParams,
          page: 1,
          sort: clickedColumn,
          order: newSortDirection,
        }));
      }
    },
    [
      params,
      sortedColumn,
      sortDirection,
      actions.listGET,
      setParams,
      setSortedColumn,
      setSortDirection,
    ]
  );

  const handleSave = useCallback(
    async (data: ISubstationCountListForm[]) => {
      const response = await actions.updatePUT(data);
      if (!response.error) {
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_SUB_COUNT_UPDATE_SUCCESS,
            data[0].substationId,
            data[0].year
          )
        );
      } else {
        ToastError(response.error);
      }
    },
    [actions]
  );

  const handleDownload = useCallback(
    (list: Record<any, any>[], years: string[]) => {
      if (list) {
        setImmediate(() => {
          setLoadingDL(true);
        });
        const items = list.map((value) => {
          const yearsVal = years.map((val) =>
            value[val]?.custCnt !== undefined ||
            value[val]?.custCnt !== null ||
            !Number.isNaN(value[val]?.custCnt)
              ? value[val]?.custCnt
              : '--'
          );
          const yearsLoad = years.map((val) =>
            value[val]?.substationLoad !== undefined ||
            value[val]?.substationLoad !== null ||
            !Number.isNaN(value[val]?.substationLoad)
              ? value[val]?.substationLoad
              : '--'
          );

          const builtData = [
            value.substation.split('-')[0],
            value.substation.split('-')[1],
          ];
          years.forEach((_, index) => {
            builtData.push(yearsVal[index] ?? '--');
            builtData.push(yearsLoad[index] ?? '--');
          });
          return builtData;
        });
        const { exportData, format } = convertToCSV(
          buildExportData(
            items,
            [Lang.LBL_ID, Lang.LBL_NAME, ...years],
            true,
            years.map(() => [Lang.LBL_COUNT, Lang.LBL_LOAD])
          )
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_SubstationCustomerCounts_${Moment().format(
            'YYYYMMDD'
          )}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
          setLoadingDL(false);
        }, 2000);
      }
    },
    [setLoadingDL]
  );

  return (
    <SubstationCountListListView
      data={state.list}
      loading={status.fetching}
      editLoading={loading}
      handleSave={handleSave}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
      loadingDL={loadingDL}
      params={params}
      sortDirection={sortDirection}
      handleSorting={handleSorting}
      sortedColumn={sortedColumn}
    />
  );
};

export default SubstationCountListList;
