import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import { IFacilityForm } from 'src/models/facility.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import ToasterContainer, {
  Toast,
  ToastError,
  TOASTER_TYPE,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { ATMLoader } from 'shared-it-appmod-ui';
import FacilityEditView from './facility-edit.view';

interface IProp {
  selectedFacility: number;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
}

const FacilityEdit: React.FC<IProp> = ({
  selectedFacility,
  setFacilityActions,
}) => {
  const { state, actions } = useFacilityContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInUse, setIsInUse] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_UPDATE
  );

  const status = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_READ
  );

  useEffect(() => {
    if (!state.data) {
      const fetchData = async () => {
        const data = await actions?.dataGET(selectedFacility);
        if (data.error) {
          ToastError(
            Lang.formatString(Lang.MSG_READ_FACILITY, selectedFacility)
          );
        } else if (data.payload?.inUse) {
          setIsInUse(true);
          Toast({
            id: 'in-use-notice',
            header: Lang.TTL_TOAST_NOTICE,
            type: TOASTER_TYPE.INFO,
            content: Lang.MSG_IN_USE_FACILITY,
          });
        }
      };

      fetchData();
    }
  }, [actions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IFacilityForm) => {
      if (selectedFacility) {
        const response = await actions.updatePUT(selectedFacility, {
          ...formData,
        });
        if (!response.error) {
          actions.listGET();
          setIsEditOpen(false);

          setFacilityActions({
            isNew: false,
            isDetails: false,
            isEdit: false,
          });

          actions.dataCLEAR();

          ToastSuccess(
            Lang.formatString(Lang.MSG_EDIT_FACILITY, formData.outgFacNm)
          );
        } else {
          ToastError('Error updating the Facility.');
        }
      }
    },
    [actions, setIsEditOpen]
  );

  const clearData = useCallback(() => {
    actions?.dataCLEAR();
  }, [actions]);

  if (!state.data || status.fetching) {
    return <ATMLoader active size="massive" inline="centered" />;
  }
  return (
    <>
      <ToasterContainer delay={isInUse ? 30000 : 5000} />
      <FacilityEditView
        formRef={formRef}
        defaultValues={state.data}
        loading={updateStatus.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        clearData={clearData}
        isOpen={isEditOpen}
        handleOpen={setIsEditOpen}
        setFacilityActions={setFacilityActions}
        isInUse={isInUse}
      />
    </>
  );
};

export default FacilityEdit;
