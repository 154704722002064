import React, { useCallback } from 'react';
import { ILerRequest } from 'src/models/ler-request.model';
import { LEREmailNotifyContent, LEREmailField } from 'src/constants';
import { ATMDropdown } from 'shared-it-appmod-ui';
import { addMinutes, format, isAfter, isToday, subMinutes } from 'date-fns';
import { getOutageDatePeriods } from 'src/helpers/outage-dates.helper';

type IProp = {
  data: ILerRequest;
};

type LEREmailNotify = {
  fieldTitle: string;
  fieldValue: string;
};

const LERRequestEmailNotify: React.FC<IProp> = ({ data }) => {
  const dateFormat = 'MMM dd, yyyy (EEE)';
  const dateTimeFormat = 'MMM dd, yyyy (EEE) HH:mm';
  const timeFormat = 'HH:mm';
  const newLine = '\n';
  const formatShortDescription = () => {
    let shortDesc = '';
    data.jobInformation.shortDescriptions.map((shortDescription) => {
      shortDesc += `${shortDescription.category?.shortDescCat} - ${shortDescription.description?.shortDesc}${newLine}`;
    });
    return shortDesc;
  };

  const getOutageDates = (isCrewTime = false) => {
    let outageDates = '';
    const filteredDates = data.outageDates.filter((val) => {
      return isToday(val.stopTm as Date) || isAfter(val.stopTm as Date, new Date());
    });
    const outageDatePeriods = getOutageDatePeriods(filteredDates);
    const outageDateList = outageDatePeriods?.length >= 10 ? outageDatePeriods.slice(0, 10) : outageDatePeriods;
    outageDateList?.map((val) => {
      if (isCrewTime) {
        const startDate = val.start;
        const endDate = val.end;
        if (val.start) {
          outageDates += `${format(startDate, dateTimeFormat)} - `;
        }
        if (
          val.end &&
          format(startDate, dateFormat) === format(endDate, dateFormat)
        ) {
          outageDates += format(endDate, timeFormat);
        } else {
          outageDates += format(endDate, dateTimeFormat);
        }
      } else {
        const startDate = data.jobInformation?.switchOutMin ?
        subMinutes(val.start, data.jobInformation.switchOutMin) : val.start;
        const stopDate = data.jobInformation?.switchInMin ?
        addMinutes(val.end, data.jobInformation.switchInMin) : val.end;
        if (val.start) {
          outageDates += `${format(startDate, dateTimeFormat)} - `;
        }
        if (
          val.end &&
          format(startDate, dateFormat) === format(stopDate, dateFormat)
        ) {
          outageDates += format(stopDate, timeFormat);
        } else {
          outageDates += format(stopDate, dateTimeFormat);
        }
      }
        outageDates += newLine;
    });
    if (outageDatePeriods?.length >= 10) {
          outageDates += `${newLine}${LEREmailNotifyContent.MoreDateDetails}${newLine}`;
    }
    return outageDates;
  };

  const getDateField = (isCrew = false) => {
    const filteredDates = data.outageDates.filter((val) => {
      return isToday(val.stopTm as Date) || isAfter(val.stopTm as Date, new Date());
    });
    const outageDatePeriods = getOutageDatePeriods(filteredDates);
    if (isCrew) {
      return outageDatePeriods.length >= 10
      ? `${LEREmailField.CREW_OUTAGE_DATES} ${LEREmailNotifyContent.DateOccurences}`
      : LEREmailField.CREW_OUTAGE_DATES;
    }
    return outageDatePeriods.length >= 10
    ? `${LEREmailField.OUTAGE_DATES} ${LEREmailNotifyContent.DateOccurences}`
    : LEREmailField.OUTAGE_DATES;
  };

  const getFacilityInfo = () => {
    let facilityInfo = '';
    const station = `${LEREmailNotifyContent.Station}: ${data.outageFacility.substation?.name}`;
    const equipment = `${LEREmailNotifyContent.Equipment}: ${data.outageFacility.facility?.outgFacNm}`;
    const voltage = `${LEREmailNotifyContent.Voltage}: ${data.outageFacility.voltage?.voltNm} kV`;
    const authorizationField = `${LEREmailNotifyContent.AuthorizationTypeField}: ${data.outageFacility.outageType?.outageTypeDesc}`;
    const line = `${LEREmailNotifyContent.Line}: ${data.outageFacility.facility?.outgFacNm}`;
    if (data.outageFacility.facTypId !== 2) {
      facilityInfo = `${station}${newLine}${equipment}${newLine}${voltage}${newLine}${authorizationField}${newLine}`;
    } else {
      facilityInfo = `${line}${newLine}${authorizationField}${newLine}`;
    }
    return facilityInfo;
  };
  const handleEmailNotify = useCallback(() => {
    let emailSubject = LEREmailNotifyContent.EmailSubject.replace(
      /requestId/g,
      `${data.requestId}`
    );
      // eslint-disable-next-line eqeqeq
      emailSubject += data?.outageFacility?.facility?.facTypId != 2 ? `${data?.outageFacility?.substation?.name}` : `${data?.outageFacility?.facility?.outgFacNm}`;
    const emailBody: Record<LEREmailField, LEREmailNotify> = {
      [LEREmailField.FacilityInfo]: {
        fieldTitle: LEREmailField.FacilityInfo,
        fieldValue: getFacilityInfo(),
      },
      [LEREmailField.CREW_OUTAGE_DATES]: {
        fieldTitle: getDateField(true),
        fieldValue: getOutageDates(true),
      },
      [LEREmailField.OUTAGE_DATES]: {
        fieldTitle: getDateField(),
        fieldValue: getOutageDates(),
      },
      [LEREmailField.ShortDescription]: {
        fieldTitle: LEREmailField.ShortDescription,
        fieldValue: formatShortDescription(),
      },
      [LEREmailField.WorkDescription]: {
        fieldTitle: LEREmailField.WorkDescription,
        fieldValue: data.jobInformation.wrkDesc ?? '',
      },
    };
    let content = '';
    let body = `${LEREmailNotifyContent.EmailHeader}${newLine}${newLine}`;
    const keys = Object.keys(emailBody);
    for (let i = 0; i < keys.length; ++i) {
      const field = keys[i];
      const value = emailBody[field] as LEREmailNotify;
      body += `${field}${newLine}${value.fieldValue}${newLine}`;
    }
    content += `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(body)}`;
    window.location.href = content;
  }, [data]);
  return (
    <ATMDropdown.Item
      content="Email Notify"
      icon="mail outline"
      onClick={handleEmailNotify}
    />
  );
};

export default LERRequestEmailNotify;
