import React, { useCallback, useState } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import { ISharepointRequest } from 'src/models/sharepoint-service.model';
import { useFileContext } from 'src/contexts/file.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { IFileSharePoint } from 'src/models/file.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { Progress } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import Confirm from '../confirm/confirm.component';

type IProps = {
  successMessage?: string;
  errorMessage?: string;
  icon?: string;
  displayConfirmation?: boolean;
  file: Partial<IFileSharePoint>;
  handleSuccess: () => void;
};

const FileDelete: React.FC<IProps> = ({
  file,
  displayConfirmation = false,
  successMessage = 'File deleted successfully',
  errorMessage = 'Failed to delete file',
  handleSuccess,
}) => {
  const { actions, state } = useFileContext();
  const [loading, setLoading] = useState(false);
  const { getCachedAccessToken, deleteDocument } = useSharepoint();
  const [percent, setPercent] = useState<number>();
  const [isConfirm, setIsConfirm] = useState<Partial<IFileSharePoint>>();

  const handleDelete = useCallback(async () => {
    if (displayConfirmation) {
      setIsConfirm(undefined);
    }
    setPercent(0);
    const accessToken = await getCachedAccessToken(new Date());
    if (file && accessToken.length) {
      setLoading(true);
      const reqData: ISharepointRequest = {
        accessToken: accessToken ?? '',
        parentFolder: file.parentFolderName ?? '',
        folderName: file.folderName ?? '',
        documentLibrary: file.documentLibraryName ?? '',
        documentId: file.listName ?? '',
        createdBy: file.createdBy ?? '',
        fileId: file.fileId ?? '',
        fileName: file.fileName ?? '',
      };
      const result = await deleteDocument(reqData);

      if (result?.deleteDocumentResponse) {
        setPercent(100);
        await handleSuccess();
        ToastSuccess(successMessage || '');
        setPercent(undefined);
      } else {
        setPercent(undefined);
        ToastError(errorMessage || '');
      }
      setLoading(false);
    }
  }, [file, actions, setLoading, state]);

  let content: React.ReactNode = (
    <ATMButton
      icon="trash"
      type="button"
      onClick={() =>
        !displayConfirmation ? handleDelete() : setIsConfirm(file)
      }
      loading={loading}
      disabled={loading}
    />
  );
  if (percent !== undefined) {
    content = <Progress percent={percent} size="small" progress indicating />;
  }

  return (
    <div>
      {content}
      <Confirm
        open={!!isConfirm}
        size="tiny"
        onCancel={() => setIsConfirm(undefined)}
        header={Lang.TTL_CONFIRM_DELETE}
        content={Lang.MSG_FILE_CONFIRM_DELETE}
        loading={loading}
        onConfirm={handleDelete}
        confirmButton={Lang.LBL_DELETE_DOCUMENT}
      />
    </div>
  );
};

export default FileDelete;
