import React, { useCallback } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMFormProvider,
  ATMToggle,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  IContractorFirmForm,
  ContractorFirmFormSchema,
  IContractorFirm,
} from 'src/models/contractor-firm.model';
import style from '../contractor-firm.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IContractorFirm>;
  isEdit: boolean;
  handleSubmit: (data: IContractorFirmForm) => void;
  handleEnable?: (value: boolean) => void;
};

const formatPhoneNumber = (value = '', limit = 0) => {
  let formattedValue = (value ?? '')
    .replace('+1', '')
    .replace(/[^\d]/g, '')
    .trim();

  if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
    return null;
  }

  if (limit) {
    formattedValue = formattedValue.slice(0, limit);
  }

  if (formattedValue.length > 3) {
    formattedValue = `${formattedValue.slice(3, 6)}-${formattedValue.slice(
      6,
      10
    )}`;
  }

  return formattedValue;
};

const ContractorFirmForm: React.FC<IProp> = ({
  formRef,
  isEdit,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  const handleChange = useCallback(
    (event, name, setValue) => {
      event.persist();
      let result = event.target.value;
      result = formatPhoneNumber(result ?? '', name === 'areaCode' ? 3 : 10);

      setValue(name, result, {
        shouldValidate: true,
        shouldDirty: true,
      });

      return event.target.value;
    },
    [formatPhoneNumber]
  );
  const hideToggle = true;
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues as IContractorFirmForm}
      validationSchema={ContractorFirmFormSchema}
    >
      {(formProps) => {
        const {
          control,
          formState: { isDirty, errors },
          setValue,
        } = formProps;
        if (handleEnable) {
          handleEnable(isDirty);
        }

        return (
          <ATMFormProvider {...formProps}>
            <ATMGrid>
              <ATMGrid.Row columns="equal">
                <ATMGrid.Column className={style.marginRightFirst}>
                  <ATMField
                    label={<LabelRequired>Short Firm Name</LabelRequired>}
                    as={ATMInput}
                    name="contracShortNm"
                    control={control}
                    error={errors.contracShortNm}
                    placeholder="Enter"
                    disabled={defaultValues?.contracShortNm}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column className={style.marginRight}>
                  <ATMField
                    label={<LabelRequired>Full Firm Name</LabelRequired>}
                    as={ATMInput}
                    name="fullName"
                    control={control}
                    error={errors.fullName}
                    placeholder="Enter"
                  />
                </ATMGrid.Column>
                <ATMGrid.Column className={style.marginRight}>
                  <ATMField
                    label="Contact Name"
                    as={ATMInput}
                    name="contactName"
                    control={control}
                    error={errors.contactName}
                    placeholder="Enter"
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>

              <ATMGrid.Row>
                <ATMGrid.Column width={6} className={style.marginRightFirst}>
                  <ATMField
                    as={ATMInput}
                    name="areaCode"
                    control={control}
                    type="hidden"
                    className="hidden"
                  />
                  <ATMField
                    as={ATMInput}
                    name="phoneNumber"
                    control={control}
                    type="hidden"
                    className="hidden"
                  />
                  <ATMField
                    label="Phone Number"
                    as={ATMInput}
                    isPhone
                    name="contactNumber"
                    placeholder="Enter"
                    value={
                      defaultValues?.areaCode &&
                      defaultValues?.phoneNumber &&
                      `(${
                        defaultValues?.areaCode
                      }) ${defaultValues?.phoneNumber.replace('-', ' - ')}`
                    }
                    onChange={(e) => {
                      handleChange(e, 'areaCode', setValue);
                      handleChange(e, 'phoneNumber', setValue);
                    }}
                    error={errors.contactNumber}
                  />
                </ATMGrid.Column>
                {isEdit && !hideToggle ? (
                  <ATMGrid.Column width={6}>
                    <div>
                      <p className={style.header}>Inactive</p>
                      <ATMToggle
                        fluid
                        style={{ marginTop: '.4em' }}
                        defaultChecked={false}
                        onChange={(e, data) => {
                          if (data.checked) {
                            setValue('endDate', new Date());
                            if (handleEnable) {
                              handleEnable(true);
                            }
                          }
                        }}
                      />
                    </div>
                  </ATMGrid.Column>
                ) : (
                  <ATMGrid.Column width={6} />
                )}
              </ATMGrid.Row>
            </ATMGrid>
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default ContractorFirmForm;
