import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/rejected-report.service';
import {
  IRejectedOverviewReport,
  IRejectedSummaryReport,
} from 'src/models/rejected-report.model';

export const rejectedReportActionTypes = {
  REJECTED_OVERVIEW_REPORT_LIST_READ: 'REJECTED_OVERVIEW_REPORT_LIST_READ',
  REJECTED_SUMMARY_REPORT_LIST_READ: 'REJECTED_SUMMARY_REPORT_LIST_READ',
  REJECTED_OVERVIEW_REPORT_LIST_CLEAR: 'REJECTED_OVERVIEW_REPORT_LIST_CLEAR',
  REJECTED_SUMMARY_REPORT_LIST_CLEAR: 'REJECTED_SUMMARY_REPORT_LIST_CLEAR',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  overviewListGET: {
    type: rejectedReportActionTypes.REJECTED_OVERVIEW_REPORT_LIST_READ,
    service: services.overviewListGET,
  },
  summaryListGET: {
    type: rejectedReportActionTypes.REJECTED_SUMMARY_REPORT_LIST_READ,
    service: services.summaryListGET,
  },
  overviewListClear: () => ({
    type: rejectedReportActionTypes.REJECTED_OVERVIEW_REPORT_LIST_CLEAR,
  }),
  summaryListClear: () => ({
    type: rejectedReportActionTypes.REJECTED_SUMMARY_REPORT_LIST_CLEAR,
  }),
};

export type IRejectedReportAsync = typeof duckActions;

export interface IRejectedReportState
  extends ICommonState<typeof rejectedReportActionTypes> {
  overviewList: IRejectedOverviewReport[];
  summaryList: IRejectedSummaryReport;
  overviewTotal: number;
  summaryTotal: number;
}

export const defaultState: IRejectedReportState = {
  status: {},
  overviewList: [],
  summaryList: {},
  overviewTotal: 0,
  summaryTotal: 0,
};

const RejectedReportReducer = (
  state: IRejectedReportState,
  action: IReducerAction<IRejectedReportAsync>
): IRejectedReportState => {
  switch (action.type) {
    case rejectedReportActionTypes.REJECTED_OVERVIEW_REPORT_LIST_READ: {
      return {
        ...state,
        overviewList: action.payload?.rows ?? [],
        overviewTotal: action.payload?.count ?? 0,
      };
    }
    case rejectedReportActionTypes.REJECTED_SUMMARY_REPORT_LIST_READ: {
      return {
        ...state,
        summaryList: action.payload ?? {},
      };
    }
    case rejectedReportActionTypes.REJECTED_OVERVIEW_REPORT_LIST_CLEAR: {
      return {
        ...state,
        overviewList: [],
        overviewTotal: 0,
      };
    }
    case rejectedReportActionTypes.REJECTED_SUMMARY_REPORT_LIST_CLEAR: {
      return {
        ...state,
        summaryList: {},
        summaryTotal: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default RejectedReportReducer;
