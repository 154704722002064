import React, { useCallback, useRef, useState } from 'react';
import { useContractorContext } from 'src/contexts/contractor.context';
import { contractorActionTypes } from 'src/ducks/contractor.duck';
import { IContractorForm } from 'src/models/contractor.model';
import { getContractorStatus } from 'src/selectors/contractor.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import ContractorAddView from './contractor-add.view';

const ContractorAdd: React.FC = () => {
  const { state, actions } = useContractorContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getContractorStatus(
    state,
    contractorActionTypes.CONTRACTOR_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IContractorForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listGET({
          action: 'search',
          'filters[]': { name: 'fullName', value: response.payload?.fullName },
          limit: 0,
        });
        // actions.listGET();
        setIsAddOpen(false);
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_CONTRACTOR_ADD,
            response.payload?.fullName ?? '00000'
          )
        );
      } else {
        ToastError('Error!');
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <ContractorAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default ContractorAdd;
