import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ATMButton, ATMGrid, ATMPopover, MOLInfoText } from 'shared-it-appmod-ui';
import Moment from 'src/libraries/moment.library';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { useSubstationContext } from 'src/contexts/substation.context';
import { AccessRole, AuthorizationType, LERRequestPage } from 'src/constants';
import { IOutageDateCalendar } from 'src/models/calendar-outage.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { Link } from 'react-router-dom';
import { outageTypeColors } from 'src/constants/outage-calendar.constant';
import { format } from 'date-fns';
import moment from 'moment';
import styles from './outage-calendar-event.component.module.scss';

type IProps = {
  data: IOutageDateCalendar;
};

const timeFormat = 'HH:mm';

export const OutageCalendarEventContent: React.FC<IProps> = ({ data }) => {
  const { outgReqStatusId: type } = data.status;
  const { outgTypId: authOutType } = data.requestFacility;
  const { state: substationState } = useSubstationContext();
  const { getUrl } = useLocationParams([
    LERRequestPage.View,
    LERRequestPage.Edit,
  ]);

  const findSubstationName = useCallback(
    (substationId: string) => {
      const subsName = substationState.list.find((value) => {
        return value.substationId === substationId;
      })?.name;
      return subsName;
    },
    [substationState]
  );

  return (
    <ATMPopover
      className={styles.popover}
      trigger={
        <div
          className={classNames(styles.authType, {
            [styles.invert]: authOutType === AuthorizationType.OkStartProgram,
          })}
          style={{
            backgroundColor: authOutType && outageTypeColors[authOutType],
          }}
        >
          <span>
            {Lang.LER_REQUEST_STATUS_CODE[type]}
            {data.significantCostInd === 'Y' ? <sup>$</sup> : <span />}
          </span>
        </div>
      }
      position="top left"
      // on="hover"
      hoverable
    >
      <ATMPopover.Header>
        <h2>
          {data?.requestFacility?.substationId}
          <small>
            {Lang.formatString(Lang.TTL_LER_TITLE, data.requestId ?? '')}
          </small>
        </h2>
        <div
          className={classNames(styles.label)}
          style={{
            backgroundColor: authOutType && outageTypeColors[authOutType],
          }}
        >
          <label>{data?.status?.statDescription}</label>
        </div>
      </ATMPopover.Header>
      <ATMPopover.Content>
      <ATMGrid>
          <ATMGrid.Row columns={3} className={styles.noMarginBottom}>
            <ATMGrid.Column className={styles.noMarginBottom}>
              <FieldReadOnly label={Lang.LBL_FACILITY}>
                {data?.requestFacility.facility.outgFacNm}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_KV}>
                {data?.requestFacility.facility.volt?.voltNm}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_OUTAGE_TIME}>
                {`${format(moment(data?.startTm).toDate(), timeFormat)}-${format(moment(data?.stopTm).toDate(), timeFormat)}`}
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row columns={3} className={styles.noMarginTop}>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_STATION}>
                {data?.requestFacility.substationId
                  ? findSubstationName(data?.requestFacility.substationId)
                  : ''}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_AUTH_TYPE}>
                {data?.requestFacility.outageType.outageTypeDesc}
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row columns={1} className={styles.viewPopupWorkDesc}>
            <ATMGrid.Column>
              <div className={styles.workDescription}>
              <MOLInfoText
                header={Lang.LBL_OUTAGES_WORK_DESCRIPTION}
                transparent
                content={data?.wrkDesc ?? ''}
                limit={150}
              />
              </div>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
        {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
          <ATMButton
            as={Link}
            secondary
            content={Lang.LBL_VIEW_DETAIL}
            to={getUrl({ [LERRequestPage.Edit]: data.requestId })}
          />
        ) : (
          <ATMButton
            as={Link}
            secondary
            content={Lang.LBL_VIEW_DETAIL}
            to={getUrl({ [LERRequestPage.View]: data.requestId })}
          />
        )}
      </ATMPopover.Content>
    </ATMPopover>
  );
};

const OutageCalendarEvent: React.FC<
  IProps & {
    span: number;
  }
> = ({ data, span }) => {
  if (
    !Moment(data.stopTm)
      .startOf('day')
      .isSame(Moment(data.startTm).startOf('day'), 'day')
  ) {
    return (
      <ul className={styles.eventGroup}>
        {Array(span)
          .fill(null)
          .map((_, key) => (
            <li key={`event_${key}`}>
              <OutageCalendarEventContent data={data} />
            </li>
          ))}
      </ul>
    );
  }

  return <OutageCalendarEventContent data={data} />;
};

export default OutageCalendarEvent;
