import React from 'react';
import { ATMCheckbox, ATMGrid, ATMHeader, ATMIcon } from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import styles from '../../ler-request.module.scss';

type IProps = {
  // Change to SysChg Interface once ready.
  data?: ILerRequest;
};

const LERRequestSystemChangeDetail: React.FC<IProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <div>
      <span>
        <ATMIcon circular name="sticky note outline" />
      </span>
      <ATMHeader
        as="h1"
        content={Lang.LBL_OC_LER_SYS_CHANGE}
        className={styles.headerDoc}
      />
      <ATMGrid columns={1}>
        <ATMGrid.Column>
          <FieldReadOnly label={Lang.LBL_COMMENTS}>
            {data.system?.sysChngSummary?.noteTxt}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMCheckbox
            name="jobInformation.inclmntWthrInd"
            label={Lang.LBL_OC_LER_SYS_CHANGE}
            checked={data.system?.sysChngInd ?? false}
            disabled
          />
        </ATMGrid.Column>
      </ATMGrid>
    </div>
  );
};

export default LERRequestSystemChangeDetail;
