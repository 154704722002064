export enum SubstationTimeStatus {
  NOT_CHECKOUT_WITHIN_THE_DAY = 'Not Checkout Within the Day',
  PAST_MIDNIGHT = 'Past Midnight',
  LESS_THAN_A_DAY = 'Less than a Day',
  OUT = 'Out',
}

export enum SubstationEntryTab {
  Today,
  Search,
}

export const substationTimeStatus = SubstationTimeStatus;
export const substationEntryTab = SubstationEntryTab;
