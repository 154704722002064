import React, { useCallback, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMModal,
  ATMSelect,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';

import {
  ILerRequest,
  ILerRequestPrintForm,
  LerRequestPrintFormSchema,
} from 'src/models/ler-request.model';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { getLerReportFormat } from 'src/selectors/daily-reports.selector';

type IProp = {
  data: ILerRequest;
  trigger: React.ReactNode;
  setPrintLoader?: (params: boolean) => void;
  printLoader?: boolean;
};

const LERRequestPrint: React.FC<IProp> = ({
  data,
  trigger,
  setPrintLoader,
  printLoader,
}) => {
  const { state: lerRequestState, actions: lerRequestAction } =
    useLerRequestContext();
  const { actions: reportAction } = useDailyReportsContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const outageDatesOptions = data.outageDates.map((value) => {
    return formatDate(value.startTm);
  });

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const printPDF = (elements, pdfOptions) => {
    let worker = html2pdf().set(pdfOptions).from(elements[0]);

    if (elements.length > 1) {
      worker = worker.toPdf(); // worker is now a jsPDF instance

      // add each element/page individually to the PDF render process
      elements.slice(1).forEach((element) => {
        worker = worker
          .get('pdf')
          .then((pdf) => {
            pdf.addPage();
          })
          .from(element)
          .toContainer()
          .toCanvas()
          .toPdf();
      });
    }

    worker = worker
      .outputPdf()
      .get('pdf')
      .then((pdfObj) => {
        pdfObj.autoPrint();
        window.open(pdfObj.output('bloburl'), '_blank');
      });
  };

  const handlePrint = useCallback(
    async (selectedDate: Date) => {
      if (setPrintLoader) {
        setPrintLoader(true);
      }

      if (data) {
        const responseGET = await reportAction.lerReportGET({
          filters: [
            {
              name: 'date',
              value: selectedDate,
            },
            {
              name: 'requestId',
              value: data.requestId,
            },
          ],
        });

        const dataArray = responseGET.payload?.data ?? [];

        const lerReports = dataArray.map((v) => v.lerReports).flat(1);

        lerRequestAction.setGrpIdList();
        const grpIds = Array.from(
          new Set(
            lerReports.filter((v) => v.grpId !== null)?.map((v) => v.grpId)
          )
        );

        if (grpIds && grpIds.length > 0) {
          await lerRequestAction.relatedOutagesListGET(
            (grpIds as number[]) ?? []
          );
        }

        const form = getLerReportFormat(
          lerReports,
          // outageDatesPeriods,
          selectedDate,
          lerRequestState.relatedOutagesList
        );

        const lerDetailsForm = new DOMParser().parseFromString(
          form,
          'text/html'
        );
        const element = Array.from(
          lerDetailsForm.querySelectorAll('div[id^="page"]')
        );
        const options = {
          margin: 0.5,
          filename: `MCC_Ler_Details_${Moment().format('YYYYMMDD')}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
          pagebreak: { mode: 'avoid-all' },
        };
        await printPDF(element, options);
      }

      if (setPrintLoader) {
        setPrintLoader(false);
      }

      setIsOpen(false);
    },
    [data, lerRequestState, reportAction, lerRequestAction]
  );

  const handleSubmit = useCallback(
    async (formData: ILerRequestPrintForm) => {
      await handlePrint(formData.outageDate);
    },
    [handlePrint]
  );

  return (
    <ATMModal
      open={isOpen}
      size="tiny"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={trigger}
    >
      <ATMModal.Header>{Lang.TTL_LER_REQUEST_PRINT}</ATMModal.Header>
      <ATMModal.Content>
        <ATMForm
          ref={formRef}
          onSubmit={handleSubmit}
          mode="onChange"
          validationSchema={LerRequestPrintFormSchema}
        >
          {({ control, formState: { errors } }) => (
            <ATMField
              as={ATMSelect}
              selection
              label={<LabelRequired>{Lang.LBL_OUTAGE_DATES}</LabelRequired>}
              name="outageDate"
              control={control}
              placeholder="Select"
              size="small"
              disabled={printLoader}
              selectOnBlur={false}
              options={outageDatesOptions.map((value) => ({
                key: value,
                value,
                text: value,
              }))}
              onChange={([_, { value }]) => value}
              clearable
              error={errors.outageDate}
            />
          )}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => setIsOpen(false)}
          loading={printLoader}
          type="button"
        />
        <ATMButton
          primary
          content={Lang.LBL_CONFIRM}
          onClick={handleClick}
          disabled={printLoader}
          loading={printLoader}
          type="button"
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LERRequestPrint;
