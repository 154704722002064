/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { List } from 'semantic-ui-react';
import {
  ATMButton,
  ATMCheckbox,
  ATMField,
  ATMForm,
  ATMHeader,
  ATMInput,
  ATMLoader,
  ATMMessage,
  ATMModal,
  ATMPopover,
  ATMSelect,
  ATMStepper,
  ATMTable,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import PopoverMore from 'src/components/atoms/popover-more/popover-more.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  AccessRole,
  LEREmailType,
  LERRequestAction,
  LERRequestPage,
  LERRequestStatus,
  LERNotificationStatus,
  TransmissionOutageStatusNonEditable,
  FAILED_CAISO_MSG,
} from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  ILerRequestEmailNotification,
  LerRequestEmailNotificationSchema,
} from 'src/models/ler-request.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { isCaisoPending } from '../ler-request-iso/ler-request-iso-send-to-caiso/ler-request-iso-send-to-caiso.component';
import styles from './ler-request-approval.module.scss';

enum Steps {
  Email = 'email',
  CAISO = 'caiso',
}

type IEmailContentProps = {
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: ILerRequestEmailNotification) => void;
  requestId: number;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSchedule: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  isFromReject: boolean;
};

const EmailContent: React.FC<IEmailContentProps> = ({
  formRef,
  handleSubmit,
  requestId,
  setIsDirty,
  setIsSchedule,
  loading,
  isFromReject,
}) => {
  const { state } = useFacilityContext();
  const { state: lerState } = useLerRequestContext();

  useEffect(() => {
    if (state.lerUserGrp && state.lerUserGrp.length === 0 && !isFromReject) {
      setIsSchedule(true);
    }
  }, []);

  const notification: ILerRequestEmailNotification = {
    requestId,
    version_no: lerState.data?.version_no ?? 1,
    emailBody: Lang.formatString(
      Lang.MSG_EMAIL_GROUP_EMAIL_BODY,
      requestId
    ).toString(),
    emailGroups:
      (state.lerUserGrp &&
        state.lerUserGrp.map((val) => {
          return {
            groupId: val.userGrpId,
            grpNm: val.userGrpNm,
            emailType: LEREmailType.NOTIFICATION,
            userGroup: {
              userGrpEmail: val.userGrpEmail,
              userGroupMembers:
                val.userGrpMembers?.map((item) => {
                  return {
                    empId: item.empId ?? '',
                    emailAdd: item.emailAdd ?? '',
                  };
                }) ?? [],
            },
            isSend: false,
          };
        })) ??
      [],
  };

  if (loading) {
    return <ATMLoader active inline="centered" />;
  }

  if (state.lerUserGrp && state.lerUserGrp.length === 0) {
    // setIsSchedule(true);
    return (
      <div>
        {/* <ATMCheckbox
          label={Lang.LBL_BYPASS_EMAIL_APPROVAL}
          disabled={lerState.data?.requestStat === LERRequestStatus.Scheduled}
          onChange={(_, { checked }) => setIsSchedule(checked || false)}
        /> */}
        {lerState.data?.requestStat !== LERRequestStatus.Scheduled
          ? Lang.LBL_NO_EMAIL_APPROVAL
          : Lang.LBL_NO_EMAIL_APPROVAL_SCHEDULED}
      </div>
    );
  }

  return (
    <div>
      {state.lerUserGrp && state.lerUserGrp.length !== 0 ? (
        lerState.data?.requestStat === LERRequestStatus.Scheduled ? (
          <ATMPopover
            content="The LER is already in scheduled state."
            size="mini"
            trigger={
              <div>
                <ATMCheckbox
                  label={Lang.LBL_BYPASS_EMAIL_APPROVAL}
                  disabled={
                    lerState.data?.requestStat === LERRequestStatus.Scheduled
                  }
                  onChange={(_, { checked }) => setIsSchedule(checked || false)}
                />
              </div>
            }
          />
        ) : (
          <ATMCheckbox
            label={Lang.LBL_BYPASS_EMAIL_APPROVAL}
            disabled={lerState.data?.requestStat === LERRequestStatus.Scheduled}
            onChange={(_, { checked }) => setIsSchedule(checked || false)}
          />
        )
      ) : null}
      <ATMForm
        ref={formRef}
        onSubmit={handleSubmit}
        defaultValues={notification}
        validationSchema={LerRequestEmailNotificationSchema}
      >
        {({ control, getValues }) => {
          return (
            <div>
              <ATMField
                label={Lang.LBL_EMAIL_BODY}
                as={ATMTextArea}
                control={control}
                name="emailBody"
              />
              <ATMField
                as={ATMInput}
                name="requestId"
                control={control}
                type="hidden"
                className="hidden"
              />
              <ATMField
                as={ATMInput}
                name="version_no"
                control={control}
                type="hidden"
                className="hidden"
              />

              <ATMTable>
                <ATMTable.Header>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_GROUP_NAME}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell>{Lang.LBL_EMAILS}</ATMTable.HeaderCell>
                    <ATMTable.HeaderCell className={styles.emailHeader}>
                      {Lang.LBL_SEND_EMAIL}
                    </ATMTable.HeaderCell>
                  </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                  {notification.emailGroups?.map((group, index) => (
                    <ATMTable.Row key={index}>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].groupId`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].grpNm`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        {group.grpNm}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].userGroup.userGroupMembers`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].userGroup.userGrpEmail`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <PopoverMore
                          list={group.userGroup?.userGroupMembers?.map(
                            (val) => val.emailAdd ?? ''
                          )}
                          content={
                            <List bulleted>
                              {group.userGroup?.userGroupMembers?.map(
                                (val, key) => (
                                  <List.Item key={key}>
                                    {val.emailAdd}
                                  </List.Item>
                                )
                              )}
                            </List>
                          }
                        />
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <div className={styles.emailAction}>
                          <ATMField
                            as={ATMCheckbox}
                            name={`emailGroups[${index}].isSend`}
                            control={control}
                            onChange={([_, { checked }]) => {
                              if (checked === true && getValues().emailGroups) {
                                setIsDirty(true);
                              } else if (
                                !(getValues().emailGroups || []).find(
                                  (val) => val.isSend
                                )
                              ) {
                                setIsDirty(false);
                              }
                              return checked;
                            }}
                          />
                          <ATMField
                            as={ATMSelect}
                            name={`emailGroups[${index}].emailType`}
                            control={control}
                            options={Object.entries(LEREmailType).map(
                              ([_, value]) => ({
                                key: value,
                                text: Lang.LER_EMAIL_TYPE[value],
                                value,
                              })
                            )}
                            clearable
                            onChange={([_, { value }]) => value}
                          />
                        </div>
                      </ATMTable.Cell>
                    </ATMTable.Row>
                  ))}
                </ATMTable.Body>
              </ATMTable>
            </div>
          );
        }}
      </ATMForm>
    </div>
  );
};

type IProps = {
  requestId: number;
  isOpen?: boolean;
  handleIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  trigger?: React.ReactNode;
  handleSuccess?: () => void;
  isFromUpdate?: boolean;
  isFromReject?: boolean;
  hasNoCaisoUpdate?: boolean | undefined;
  isFromPendingCaiso?: boolean;
};

const LERRequestApproval: React.FC<IProps> = ({
  requestId,
  isOpen = false,
  handleIsOpen,
  trigger,
  isFromUpdate = false,
  isFromReject = false,
  isFromPendingCaiso = false,
  hasNoCaisoUpdate = undefined,
}) => {
  const { handleChange } = useLocationParams([
    LERRequestPage.Edit,
    LERRequestPage.View,
    'active',
  ]);
  const { state, actions } = useLerRequestContext();
  const { state: facilityState } = useFacilityContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState(isOpen);
  const [skipOpen, setSkipOpen] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(
    isFromPendingCaiso ? Steps.CAISO : Steps.Email
  );
  const [errorTitle, setErrorTitle] = useState('');
  const [isDirtyEmail, setIsDirtyEmail] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [hasTrIds] = useState(
    !!(
      state.data &&
      state.data.isoTrs &&
      state.data.isoTrs.caisoTrs &&
      state.data.isoTrs.caisoTrs.length > 0
    )
  );

  // const hasTrIds = !!(
  //   state.data &&
  //   state.data.isoTrs &&
  //   state.data.isoTrs.caisoTrs &&
  //   state.data.isoTrs.caisoTrs.length > 0
  // );

  const nonEditableList = state.caisoList.filter(
    (item) =>
      !Object.values(TransmissionOutageStatusNonEditable).includes(
        item.outageStatus as TransmissionOutageStatusNonEditable
      )
  );

  const sendStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SEND_CAISO_CREATE
  );

  const sendUpdateStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CAISO_RECONCILE_CREATE
  );

  const getCaisoStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CAISO_READ
  );

  const sendEmailStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE
  );

  const loadingFacilityGroups = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ
  );

  const scheduleStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE
  );

  const handleSchedule = useCallback(async () => {
    const result = await actions.statusPUT(
      requestId,
      LERRequestAction.Schedule
    );

    if (result.payload) {
      ToastSuccess(Lang.MSG_LER_REQUEST_SCHEDULE_SUCCESS);
      if (result.payload?.outageEmail) {
        if (result.payload?.outageEmail?.successOutageEmail?.length) {
          result.payload?.outageEmail?.successOutageEmail.map((emailOutage) => {
            ToastSuccess(emailOutage?.message ?? 'Email sent successfully');
          });
        }
        if (result.payload?.outageEmail?.failedOutageEmail?.length) {
          result.payload?.outageEmail?.failedOutageEmail.map((emailOutage) => {
            ToastError(emailOutage?.message ?? 'Failed to send email');
          });
        }
      }
      setStep(Steps.CAISO);
    }
  }, [requestId, actions]);

  useEffect(() => {
    if (handleIsOpen) {
      handleIsOpen(open);
    }
    if (isFromReject) {
      setStep(Steps.CAISO);
    }
  }, [open, handleIsOpen, isFromReject]);

  useEffect(() => {
    if (!isFromReject) {
      const willBypassEmail =
        (!facilityState.lerUserGrp ||
          (facilityState.lerUserGrp &&
            facilityState.lerUserGrp.length === 0)) &&
        hasRole(AccessRole.MCC_OUTAGE_SCHEDULER);
      if (willBypassEmail) {
        setStep(Steps.CAISO);

        if (
          state.data?.requestStat !== LERRequestStatus.Scheduled &&
          state.data?.requestStat !== LERRequestStatus.Rejected &&
          state.data?.requestStat !== LERRequestStatus.Cancelled &&
          state.data?.requestStat === LERRequestStatus.Approved
        ) {
          (async () => {
            await handleSchedule();
          })();
        }
      }
    }
  }, [isFromReject]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSendEmail = useCallback(
    async (formData: ILerRequestEmailNotification) => {
      const isSendData = {
        requestId: formData.requestId,
        version_no: formData.version_no,
        emailBody: formData.emailBody,
        emailGroups: formData.emailGroups.filter(
          (value) => value.isSend === true
        ),
      };
      const response = await actions.sendMultipleEmailPOST(isSendData);

      if (!response.error) {
        ToastSuccess(Lang.MSG_EMAIL_GROUP_SEND_MULTIPLE_SUCCESS);
        actions.dataGET(formData.requestId);
        setStep(Steps.CAISO);
      } else {
        ToastError(response.error.message);
      }
    },
    [setStep, actions]
  );

  const handleResendEmail = useCallback(async () => {
    if (
      state.data &&
      state.data.authorizationStatement?.emailGroups &&
      state.data.authorizationStatement?.emailGroups.length > 0
    ) {
      const isSendData: ILerRequestEmailNotification = {
        requestId: state.data.requestId,
        version_no: state.data.version_no ?? 1,
        emailBody: state.data.authorizationStatement.emailGroups[0].emailBody,
        emailGroups: state.data.authorizationStatement.emailGroups
          .filter((value) => value.status === 'Pending')
          .map((v) => {
            return {
              emailType: v.emailType,
              groupId: v.groupId,
              userGroup: {
                userGroupMembers: v.userGroup.userGrpMembers ?? [],
                userGrpEmail: v.userGroup.userGrpEmail,
              },
              grpNm: v.userGroup.userGrpNm,
              isSend: true,
            };
          }),
      };
      if (
        isSendData &&
        isSendData.emailGroups &&
        isSendData.emailGroups.length > 0
      ) {
        await actions.resendMultipleEmailPOST(isSendData);
      }
    }
  }, [actions]);

  const handlePendingCaiso = useCallback(
    (pending: any) => {
      actions.updatePUT(requestId, { pendingCaiso: `${pending}` } as any);
    },
    [actions]
  );

  const caisoFailed = () => (
    <p>
      {FAILED_CAISO_MSG.slice(0, 45)}
      <span style={{ fontWeight: 'bold' }}>
        {FAILED_CAISO_MSG.slice(45, 63)}
      </span>
      {FAILED_CAISO_MSG.slice(63, FAILED_CAISO_MSG.length)}
    </p>
  );

  const handleCAISO = useCallback(async () => {
    // Cnd caisoList determine if already send.
    actions.setCaisoError(null);
    const response =
      nonEditableList.length > 0
        ? await actions.autoReconcilePOST(state.previousData as ILerRequest)
        : await actions.sendCaisoPOST(state.data as ILerRequest);

    if (!response.error) {
      setOpen(false);

      if (handleIsOpen) {
        handleIsOpen(false);
      }
      handlePendingCaiso('N');
      actions.setForApproval(false);
      actions.setIsFromUpdate(false);
      actions.setIsFromReject(false);
      actions.setIsFromPendingCaiso(false);
      ToastSuccess(Lang.MSG_SUBMIT_LER_TO_CAISO_SUCESS);
      handleChange({
        [LERRequestPage.View]: requestId,
        [LERRequestPage.Edit]: undefined,
        active: undefined,
      });
    } else if (
      response.error &&
      !response.error.length &&
      response.error.status === 500
    ) {
      actions.setCaisoError({
        message: 'Failed',
        description: <p>{FAILED_CAISO_MSG}</p>,
      });
      setError(FAILED_CAISO_MSG);
      setErrorTitle('Failed');
    } else if (response.error && response.error.length > 0) {
      actions.setCaisoError({
        message: LERNotificationStatus.REJECTED,
        description: response.error.map((v, i) => (
          <p key={i}>{v.description}</p>
        )),
      });
      setError(response.error.map((v, i) => <p key={i}>{v.description}</p>));
      setErrorTitle(LERNotificationStatus.REJECTED);
    } else {
      // If API returns 504
      handleChange({
        [LERRequestPage.View]: requestId,
        [LERRequestPage.Edit]: undefined,
        active: undefined,
      });
      actions.setForApproval(false);
      actions.setIsFromUpdate(false);
      actions.setIsFromReject(false);
      actions.setIsFromPendingCaiso(false);
      actions.dataCaisoGET(requestId);
    }
  }, [
    setOpen,
    setError,
    setErrorTitle,
    error,
    setErrorTitle,
    state,
    handlePendingCaiso,
  ]);

  if (!requestId) {
    return null;
  }

  const isBypass =
    facilityState.lerUserGrp &&
    facilityState.lerUserGrp.length === 0 &&
    hasRole(AccessRole.MCC_OUTAGE_SCHEDULER);

  const skipWithEmails =
    (isCaisoPending(state?.data?.pendingCaiso) || hasTrIds) &&
    (sendStatus.fetching || sendUpdateStatus.fetching);

  const skipWithoutEmails =
    isFromReject ||
    (hasTrIds && (sendStatus.fetching || sendUpdateStatus.fetching));

  const ConfirmSkip = ({ withEmails = false }: any) => {
    return (
      <ATMModal
        open={skipOpen}
        size="tiny"
        onClose={() => setSkipOpen(false)}
        onOpen={() => setSkipOpen(true)}
        trigger={
          <ATMButton
            type="button"
            secondary
            content={Lang.LBL_FLAG_FOR_FOLLOW_UP}
            disabled={sendStatus.fetching || sendUpdateStatus.fetching}
          />
        }
      >
        <ATMModal.Header>You are not submitting to CAISO</ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_PENDING_CAISO_ADD_TO_LIST}</p>
          <p>Please confirm that you will be submitting this at a later time</p>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setSkipOpen(false)}
            type="button"
          />
          <ATMButton
            type="button"
            primary
            onClick={() => {
              if (withEmails) {
                setStep(Steps.CAISO);
                if (
                  state.data &&
                  state.data.authorizationStatement &&
                  state.data.authorizationStatement.emailGroups &&
                  state.data.authorizationStatement.emailGroups.length > 0 &&
                  isFromUpdate
                ) {
                  handleResendEmail();
                }
                if (hasTrIds && !isCaisoPending(state?.data?.pendingCaiso)) {
                  handlePendingCaiso('Y');
                }
              } else {
                actions.setForApproval(false);
                actions.setIsFromUpdate(false);
                actions.setIsFromReject(false);
                actions.setIsFromPendingCaiso(false);
                setOpen(false);
                if (!isFromReject || isBypass) {
                  setStep(Steps.Email);
                }

                if (hasTrIds && !isCaisoPending(state?.data?.pendingCaiso)) {
                  handlePendingCaiso('Y');
                }
              }
            }}
            disabled={withEmails ? skipWithEmails : skipWithoutEmails}
          >
            {Lang.LBL_CONFIRM}
          </ATMButton>
        </ATMModal.Actions>
      </ATMModal>
    );
  };

  return (
    <ATMModal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={trigger}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <ATMModal.Header>
        {isFromReject
          ? Lang.TTL_LER_REQUEST_REJECTED
          : isFromPendingCaiso
          ? Lang.TTL_LER_REQUEST_SUBMIT_CAISO
          : Lang.TTL_LER_REQUEST_APPROVED}
      </ATMModal.Header>
      <ATMModal.Content>
        <>
          {isFromPendingCaiso ? (
            ''
          ) : (
            <ATMMessage icon="check" positive>
              {Lang.formatString(
                isFromReject
                  ? Lang.MSG_LER_REQUEST_REJECTED_SUCCESS
                  : Lang.MSG_LER_REQUEST_APPROVED_SUCCESS,
                requestId
              )}
            </ATMMessage>
          )}
        </>

        {isFromReject || isBypass || isFromPendingCaiso ? (
          !isFromPendingCaiso ? (
            <ATMStepper
              stepperData={[
                {
                  key: Steps.CAISO,
                  title: Lang.LBL_SEND_CAISO,
                },
              ]}
              activeItem={step}
              handleStepperClick={(_, __, key) => setStep(key)}
            />
          ) : (
            ''
          )
        ) : (
          <ATMStepper
            stepperData={[
              {
                key: Steps.Email,
                title: Lang.LBL_EMAIL_NOTIFICATIONS,
                ...(step === Steps.CAISO
                  ? { icon: 'check', color: 'green' }
                  : {}),
              },
              {
                key: Steps.CAISO,
                title: Lang.LBL_SEND_CAISO,
              },
            ]}
            activeItem={step}
            handleStepperClick={(_, __, key) => {
              if (key === Steps.Email) {
                setIsDirtyEmail(false);
              }
              setStep(key);
            }}
          />
        )}

        {step === Steps.Email ? (
          <EmailContent
            formRef={formRef}
            handleSubmit={handleSendEmail}
            requestId={requestId}
            setIsDirty={setIsDirtyEmail}
            loading={loadingFacilityGroups.fetching}
            setIsSchedule={setIsSchedule}
            isFromReject={isFromReject}
          />
        ) : (
          <div>
            <ATMHeader>
              {isFromReject
                ? Lang.TTL_LER_REQUEST_REJECT_CAISO
                : isFromPendingCaiso
                ? ''
                : Lang.TTL_LER_REQUEST_SUBMIT_CAISO}
            </ATMHeader>
            <p>
              {isFromReject
                ? Lang.MSG_SUBMIT_REJECT_LER_TO_CAISO
                : Lang.MSG_SUBMIT_LER_TO_CAISO}
            </p>

            {error && (
              <ATMMessage negative>
                <ATMHeader as="h3">{errorTitle}</ATMHeader>
                <span>
                  {error === FAILED_CAISO_MSG ? caisoFailed() : error}
                </span>
                {error === FAILED_CAISO_MSG ? null : (
                  <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <ATMButton
                      type="button"
                      secondary
                      content={Lang.LBL_GO_TO_ISO}
                      onClick={() => {
                        handleChange({
                          [LERRequestPage.Edit]: requestId,
                          [LERRequestPage.View]: undefined,
                          active: 'isoTrs',
                        });
                        actions.setForApproval(false);
                        actions.setIsFromUpdate(false);
                        actions.setIsFromReject(false);
                        actions.setIsFromPendingCaiso(false);
                        setOpen(false);
                      }}
                    />
                  </div>
                )}
              </ATMMessage>
            )}
          </div>
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        {step === Steps.Email ? (
          <>
            {!isBypass && (
              <>
                <ATMButton
                  type="button"
                  secondary
                  onClick={() => {
                    setStep(Steps.CAISO);
                    if (
                      state.data &&
                      state.data.authorizationStatement &&
                      state.data.authorizationStatement.emailGroups &&
                      state.data.authorizationStatement.emailGroups.length >
                        0 &&
                      isFromUpdate
                    ) {
                      handleResendEmail();
                    }
                  }}
                  disabled={skipWithEmails}
                >
                  {Lang.LBL_SKIP}
                </ATMButton>
              </>
            )}
            {(hasTrIds || isFromPendingCaiso) && <ConfirmSkip withEmails />}

            {!isBypass &&
            state.data &&
            state.data.requestStat !== LERRequestStatus.Scheduled ? (
              isSchedule ? (
                <ATMButton
                  type="button"
                  primary
                  onClick={handleSchedule}
                  disabled={!isSchedule || scheduleStatus.fetching}
                  loading={scheduleStatus.fetching}
                >
                  {Lang.LBL_SCHEDULE}
                </ATMButton>
              ) : (
                <ATMButton
                  type="button"
                  primary
                  onClick={handleClick}
                  disabled={!isDirtyEmail || sendEmailStatus.fetching}
                  loading={sendEmailStatus.fetching}
                >
                  {Lang.LBL_SEND_EMAIL_NEXT}
                </ATMButton>
              )
            ) : (
              <ATMButton
                type="button"
                primary
                onClick={handleClick}
                disabled={!isDirtyEmail || sendEmailStatus.fetching}
                loading={sendEmailStatus.fetching}
              >
                {Lang.LBL_SEND_EMAIL_NEXT}
              </ATMButton>
            )}
          </>
        ) : (
          <>
            <ATMButton
              type="button"
              secondary
              onClick={() => {
                actions.setForApproval(false);
                actions.setIsFromUpdate(false);
                actions.setIsFromReject(false);
                actions.setIsFromPendingCaiso(false);
                setOpen(false);
                if (!isFromReject || isBypass) {
                  setStep(Steps.Email);
                }
              }}
              // disabled={isFromReject}
              disabled={isFromReject || skipWithoutEmails}
            >
              {Lang.LBL_SKIP}
            </ATMButton>
            {(hasTrIds || isFromPendingCaiso) && (
              <ConfirmSkip withEmails={false} />
            )}

            <ATMButton
              type="button"
              primary
              onClick={handleCAISO}
              loading={
                sendStatus.fetching ||
                sendUpdateStatus.fetching ||
                getCaisoStatus.fetching ||
                scheduleStatus.fetching
              }
              disabled={
                (!!error && error !== FAILED_CAISO_MSG) ||
                sendStatus.fetching ||
                sendUpdateStatus.fetching ||
                !hasTrIds ||
                getCaisoStatus.fetching ||
                scheduleStatus.fetching ||
                (isFromUpdate && hasNoCaisoUpdate)
              }
            >
              {nonEditableList.length > 0
                ? Lang.LBL_SEND_UPDATE_CAISO_FINISH
                : Lang.LBL_SEND_CAISO_FINISH}
            </ATMButton>
          </>
        )}
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LERRequestApproval;
