import React, { useCallback, useRef } from 'react';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import { ITroubleJobsForm } from 'src/models/trouble-jobs.model';
import { getTroubleJobsStatus } from 'src/selectors/trouble-jobs.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
// import { useFacilityContext } from 'src/contexts/facility.context';
// import { useSubstationContext } from 'src/contexts/substation.context';
// import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { createDateTime } from 'src/libraries/moment.library';
import TroubleJobsAddView from './trouble-jobs-add.view';

type IProps = {
  openAddPanel: () => void;
  isOpenAdd: boolean;
  setIsOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
};

const TroubleJobsAdd: React.FC<IProps> = ({
  openAddPanel,
  isOpenAdd,
  setIsOpenAdd,
}) => {
  const { state, actions } = useTroubleJobsContext();
  // const { actions: facilityActions } = useFacilityContext();
  // const { actions: substationActions } = useSubstationContext();
  // const { actions: equipmentTypeActions } = useEquipmentTypeContext();

  const formRef = useRef<HTMLFormElement>(null);

  const status = getTroubleJobsStatus(
    state,
    troubleJobsActionTypes.TROUBLE_JOBS_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITroubleJobsForm) => {
      const beginDate = createDateTime(
        formData.beginDate,
        formData.startTime
      ).toDate();
      const response = beginDate
        ? await actions.createPOST({
            ...formData,
            beginDate,
          })
        : undefined;

      if (!response?.error) {
        actions.listGET({ limit: 50, page: 1 });
        openAddPanel();
        ToastSuccess(Lang.MSG_TROUBLE_JOB_ADD);
        history.push(`/trouble-jobs?id=${response?.payload?.troubleJobId}`);
      } else {
        ToastError('Error!');
      }
    },
    [actions, openAddPanel]
  );

  // useEffect(() => {
  //   facilityActions?.listGET({ limit: 0, page: 1 });
  //   substationActions?.listGET({ limit: 0, page: 1 });
  //   equipmentTypeActions?.listGET({ limit: 0, page: 1 });
  //   actions?.outageGET();
  // }, []);

  return (
    <TroubleJobsAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      openAddPanel={openAddPanel}
      isOpenAdd={isOpenAdd}
      setIsOpenAdd={setIsOpenAdd}
    />
  );
};

export default TroubleJobsAdd;
