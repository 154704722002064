import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ATMButton, ATMHeader, ATMPopover } from 'shared-it-appmod-ui';
import Moment, {
  dateInEventRange,
  hasEvent,
  IMoment,
} from 'src/libraries/moment.library';
import styles from './calender.module.scss';
import { ICalendarEvent } from './calendar-event/calendar-event.component';
import CalendarWeek from './calendar-week/calendar-week.component';

type IProps = {
  data: ICalendarEvent[];
  date?: IMoment;
  minDate?: IMoment;
  visible?: number;
  popup?: (data: { date: IMoment; setClose: () => void }) => React.ReactNode;
  render?: (data: { date: IMoment; setClose: () => void }) => React.ReactNode;
  handleCopyDay?: (
    selected: IMoment | undefined,
    date: IMoment,
    events: ICalendarEvent[]
  ) => void;
  handleCopyWeek?: (
    range: IMoment[] | undefined,
    events: ICalendarEvent[]
  ) => void;
};

export type { ICalendarEvent };

const Calendar: React.FC<IProps> = ({
  data,
  date,
  minDate,
  visible = 1,
  popup,
  render,
  handleCopyDay,
  handleCopyWeek,
}) => {
  const dayRef = useRef<HTMLDivElement>(null);
  const [month, setMonth] = useState(date || minDate || Moment());
  const [active, setActive] = useState<IMoment>();
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [selectedDay, setSelectedDay] = useState<IMoment>();
  const [selectedWeek, setSelectedWeek] = useState<IMoment[]>([]);

  const list = Array(Math.min(5 + visible, 13))
    .fill(null)
    .map((_, i) => month.clone().add(i, 'month').subtract(1, 'month'));

  useEffect(() => {
    if (selectedDay) {
      setEvents(
        data.filter((val) =>
          dateInEventRange({
            event: {
              start: Moment(val.start),
              end: Moment(val.end),
            },
            range: {
              start: selectedDay,
              end: selectedDay,
            },
          })
        )
      );
    } else {
      setEvents([]);
    }
  }, [data, selectedDay, setEvents]);

  const handleWeek = useCallback(
    (days, isChecked) => {
      if (handleCopyWeek) {
        if (selectedWeek.length > 0 && days[0].isSame(selectedWeek[0], 'day')) {
          setSelectedWeek(isChecked ? days : []);
          handleCopyWeek(undefined, []);
        } else if (selectedDay) {
          const dateValue = data.find((val) =>
            dateInEventRange({
              event: {
                start: Moment(val.start),
                end: Moment(val.end),
              },
              range: {
                start: selectedDay,
                end: selectedDay,
              },
            })
          );

          if (dateValue) {
            handleCopyWeek(
              days.filter(
                (v) =>
                  (!isChecked || (!hasEvent(v, data) && isChecked)) &&
                  (!minDate || (minDate && !v.isBefore(minDate)))
              ),
              isChecked
                ? days.map((v) => ({
                    ...dateValue,
                    start: dateValue?.start.clone().set({
                      date: v.date(),
                      month: v.month(),
                      year: v.year(),
                    }),
                    end: dateValue?.end.clone().set({
                      date: v.date(),
                      month: v.month(),
                      year: v.year(),
                    }),
                  }))
                : []
            );
          }
        } else if (selectedWeek.length) {
          handleCopyWeek(
            days.filter((v) => !minDate || (minDate && !v.isBefore(minDate))),
            isChecked
              ? data.filter((val) =>
                  dateInEventRange({
                    event: {
                      start: Moment(val.start),
                      end: Moment(val.end),
                    },
                    range: {
                      start: selectedWeek[0],
                      end: selectedWeek[selectedWeek.length - 1],
                    },
                  })
                )
              : []
          );
        } else {
          const weekDays: ICalendarEvent[] = days
            .reduce((items: ICalendarEvent[], item) => {
              const detail = data.find((val) =>
                dateInEventRange({
                  event: {
                    start: Moment(val.start),
                    end: Moment(val.end),
                  },
                  range: {
                    start: item,
                    end: item,
                  },
                })
              );

              if (detail) {
                return [...items, detail];
              }

              return items;
            }, [])
            .filter(Boolean);

          handleCopyWeek(
            days.filter((v) => !minDate || (minDate && !v.isBefore(minDate))),
            isChecked
              ? days
                  .filter(
                    (value) =>
                      !data.find((val) =>
                        dateInEventRange({
                          event: {
                            start: Moment(val.start),
                            end: Moment(val.end),
                          },
                          range: {
                            start: value,
                            end: value,
                          },
                        })
                      )
                  )
                  .map((v) => ({
                    start: v.clone().set({
                      hour:
                        weekDays.length === 1 ? weekDays[0].start.hours() : 7,
                      minute:
                        weekDays.length === 1 ? weekDays[0].start.minutes() : 0,
                    }),
                    end: v.clone().set({
                      hour:
                        weekDays.length === 1 ? weekDays[0].end.hours() : 15,
                      minute:
                        weekDays.length === 1 ? weekDays[0].end.minutes() : 0,
                    }),
                  }))
              : []
          );
        }
      }
    },
    [data, selectedDay, selectedWeek, setSelectedWeek, handleCopyWeek]
  );

  useEffect(() => {
    setActive(undefined);
  }, [month, setActive]);

  return (
    <div>
      <ul className={styles.navigation}>
        <li>
          <ATMButton
            type="button"
            icon="angle left"
            onClick={() => setMonth((val) => val.clone().subtract(1, 'month'))}
          />
        </li>
        {list.map((val, key) => (
          <li
            key={key}
            className={classNames({
              [styles.active]: Array.from(
                { length: visible },
                (_, i) => i + 1
              ).includes(key),
            })}
          >
            <a onClick={() => setMonth(val)} role="button">
              {val.format('MMM YYYY')}
            </a>
          </li>
        ))}
        <li>
          <ATMButton
            type="button"
            icon="angle right"
            onClick={() => setMonth((val) => val.clone().add(1, 'month'))}
          />
        </li>
      </ul>

      {popup && (
        <ATMPopover
          key={`popup_${active && active.format('YYYYMMDD')}`}
          context={dayRef}
          className={styles.popup}
          content={
            active &&
            popup &&
            popup({
              date: active || Moment(),
              setClose: () => setActive(undefined),
            })
          }
          position="top center"
          open={!!active}
          popperModifiers={[
            {
              name: 'preventOverflow',
              options: { boundariesElement: 'viewport' },
            },
            // {
            //   name: 'preventOverflow',
            //   options: {
            //     mainAxis: false, // true by default
            //     altAxis: true, // false by default
            //   },
            // },
          ]}
        />
      )}
      {list.slice(1, visible + 1).map((item, index) => {
        const startDay = item.clone().startOf('month').startOf('isoWeek');
        const endDay = item.clone().endOf('month').endOf('isoWeek');
        const value = startDay.clone().subtract(1, 'day');

        const calendar: IMoment[][] = [];

        while (value.isBefore(endDay, 'day')) {
          calendar.push(
            Array(7)
              .fill(0)
              .map(() => value.add(1, 'day').clone())
          );
        }

        return (
          <div key={`month_${index}`} className={styles.calendar}>
            <ATMHeader className={styles.title} as="h2">
              {item.format('MMMM YYYY')}
            </ATMHeader>
            <div className={styles.monthView}>
              <div
                className={classNames({
                  [styles.monthHeader]: true,
                  [styles.padded]: !!handleCopyWeek,
                })}
              >
                {calendar[0].map((val, key) => (
                  <div
                    key={key}
                    className={classNames(styles.header, {
                      [styles.highlight]:
                        val.format('ddd').toLowerCase() === 'sun',
                    })}
                  >
                    {val.format('ddd')}
                  </div>
                ))}
              </div>

              {calendar.map((week) => {
                return (
                  <CalendarWeek
                    setNo={index}
                    dayRef={dayRef}
                    key={`week_${week[0].isoWeek()}`}
                    week={week}
                    date={item}
                    minDate={minDate}
                    events={data}
                    active={active}
                    setActive={setActive}
                    selectedDay={selectedDay}
                    selectedWeek={selectedWeek}
                    handleSelectedDay={(day, isChecked) => {
                      if (handleCopyDay) {
                        if (
                          !selectedDay ||
                          (selectedDay && day.isSame(selectedDay, 'day'))
                        ) {
                          setSelectedDay(isChecked ? day : undefined);
                          handleCopyDay(undefined, day, []);
                        } else if (selectedDay) {
                          handleCopyDay(
                            selectedDay,
                            day,
                            isChecked ? events : []
                          );
                        }
                      }
                    }}
                    handleCopyWeek={handleWeek}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {render &&
        active &&
        render({
          date: active || Moment(),
          setClose: () => setActive(undefined),
        })}
    </div>
  );
};

export default Calendar;
