import React, { useCallback, useRef, useState } from 'react';
import { List, Popup } from 'semantic-ui-react';
import {
  ATMButton,
  ATMCheckbox,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMIcon,
  ATMInput,
  ATMSelect,
  ATMTable,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import PopoverMore from 'src/components/atoms/popover-more/popover-more.component';
import Status from 'src/components/atoms/status/status.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { LEREmailType, LERNotificationStatus } from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import { formatDate } from 'src/libraries/moment.library';
import {
  ILerRequest,
  ILerRequestEmailNotification,
  ILerRequestSingleEmail,
  LerRequestEmailNotificationSchema,
} from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import styles from '../../ler-request-authorization.module.scss';
import LERUserGroupDelete from '../user-group-email-delete/user-group-email-delete.component';

type IProps = {
  data?: ILerRequest;
  handleResendEmail: (data: ILerRequestSingleEmail) => void;
};

const UserGroupListNotificationView: React.FC<IProps> = ({
  data,
  handleResendEmail,
}) => {
  const { state } = useFacilityContext();
  const { state: lerState, actions } = useLerRequestContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isDirtyEmail, setIsDirtyEmail] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);

  const loading = getLerRequestStatus(
    lerState,
    lerRequestActionTypes.LER_REQUEST_SEND_MULTIPLE_EMAIL_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSendEmail = useCallback(
    async (formData: ILerRequestEmailNotification) => {
      const isSendData = {
        requestId: formData.requestId,
        version_no: formData.version_no,
        emailBody: formData.emailBody,
        emailGroups: formData.emailGroups.filter(
          (value) => value.isSend === true
        ),
      };
      const response = await actions.sendMultipleEmailPOST(isSendData);

      if (!response.error) {
        ToastSuccess(Lang.MSG_EMAIL_GROUP_SEND_MULTIPLE_SUCCESS);
        actions.dataGET(formData.requestId);
      } else {
        ToastError(response.error.message);
      }
    },
    [actions]
  );

  // Facility Default Table
  const notification: ILerRequestEmailNotification = {
    requestId: data?.requestId ?? 0,
    version_no: data?.version_no ?? 1,
    emailBody: Lang.formatString(
      Lang.MSG_EMAIL_GROUP_EMAIL_BODY,
      data?.requestId ?? 0
    ).toString(),
    emailGroups:
      (state.lerUserGrp &&
        state.lerUserGrp.map((val) => {
          return {
            groupId: val.userGrpId,
            grpNm: val.userGrpNm,
            emailType: LEREmailType.NOTIFICATION,
            userGroup: {
              userGrpEmail: val.userGrpEmail,
              userGroupMembers:
                val.userGrpMembers?.map((item) => {
                  return {
                    empId: item.empId ?? ' ',
                    emailAdd: item.emailAdd ?? ' ',
                  };
                }) ?? [],
            },
            isSend: false,
          };
        })) ??
      [],
  };

  const defaultFacilityUserGrpTable = (
    <div>
      <ATMForm
        ref={formRef}
        onSubmit={handleSendEmail}
        defaultValues={notification}
        validationSchema={LerRequestEmailNotificationSchema}
      >
        {({ control, getValues }) => {
          return (
            <div>
              <ATMField
                label={Lang.LBL_EMAIL_BODY}
                as={ATMTextArea}
                control={control}
                name="emailBody"
              />
              <ATMField
                as={ATMInput}
                name="requestId"
                control={control}
                type="hidden"
                className="hidden"
              />
              <ATMField
                as={ATMInput}
                name="version_no"
                control={control}
                type="hidden"
                className="hidden"
              />

              <ATMTable>
                <ATMTable.Header>
                  <ATMTable.Row>
                    <ATMTable.HeaderCell>
                      {Lang.LBL_GROUP_NAME}
                    </ATMTable.HeaderCell>
                    <ATMTable.HeaderCell>{Lang.LBL_EMAILS}</ATMTable.HeaderCell>
                    <ATMTable.HeaderCell className={styles.emailHeader}>
                      {Lang.LBL_SEND_EMAIL}
                    </ATMTable.HeaderCell>
                  </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                  {notification.emailGroups?.map((group, index) => (
                    <ATMTable.Row key={index}>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].groupId`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].grpNm`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        {group.grpNm}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].userGroup.userGroupMembers`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`emailGroups[${index}].userGroup.userGrpEmail`}
                          control={control}
                          type="hidden"
                          className="hidden"
                        />
                        <PopoverMore
                          list={group.userGroup?.userGroupMembers?.map(
                            (val) => val.emailAdd ?? ''
                          )}
                          content={
                            <List bulleted>
                              {group.userGroup?.userGroupMembers?.map(
                                (val, key) => (
                                  <List.Item key={key}>
                                    {val.emailAdd}
                                  </List.Item>
                                )
                              )}
                            </List>
                          }
                        />
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <div className={styles.emailAction}>
                          <ATMField
                            as={ATMCheckbox}
                            name={`emailGroups[${index}].isSend`}
                            control={control}
                            onChange={([_, { checked }]) => {
                              if (checked === true && getValues().emailGroups) {
                                setIsDirtyEmail(false);
                              } else if (
                                !(getValues()?.emailGroups || []).find(
                                  (val) => val.isSend
                                )
                              ) {
                                setIsDirtyEmail(true);
                              }
                              return checked;
                            }}
                          />
                          <ATMField
                            as={ATMSelect}
                            name={`emailGroups[${index}].emailType`}
                            control={control}
                            options={Object.entries(LEREmailType).map(
                              ([_, value]) => ({
                                key: value,
                                text: Lang.LER_EMAIL_TYPE[value],
                                value,
                              })
                            )}
                            clearable
                            onChange={([_, { value }]) => value}
                          />
                        </div>
                      </ATMTable.Cell>
                    </ATMTable.Row>
                  ))}
                </ATMTable.Body>
              </ATMTable>
            </div>
          );
        }}
      </ATMForm>
    </div>
  );

  // Sent Email Table
  const sentEmailTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_GROUP_NAME}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_EMAILS}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_EMAIL_TYPE}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_EMAIL_DATE_SENT}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>
            {Lang.LBL_EMAIL_APPROVAL_STAT}
          </ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_ACTIONS}</ATMTable.HeaderCell>
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {data?.authorizationStatement?.emailGroups?.map((val, index) => {
          return (
            <ATMTable.Row key={index}>
              <ATMTable.Cell>{val.userGroup.userGrpNm}</ATMTable.Cell>
              <ATMTable.Cell>
                <PopoverMore
                  list={val.userGroup?.userGrpMembers?.map(
                    (item) => item.emailAdd ?? ''
                  )}
                  content={
                    <List bulleted>
                      {val.userGroup.userGrpMembers?.map((item, key) => (
                        <List.Item key={key}>{item.emailAdd}</List.Item>
                      ))}
                    </List>
                  }
                />
              </ATMTable.Cell>
              <ATMTable.Cell>{val.emailType}</ATMTable.Cell>
              <ATMTable.Cell>{formatDate(val.emailSentAt)}</ATMTable.Cell>
              <ATMTable.Cell>
                {val.emailType === LEREmailType.APPROVAL && (
                  <>
                    <Status
                      value={val.status}
                      language={Lang.LER_NOTIFICATION_STATUS}
                    />
                    <p
                      className={
                        val.status !== LERNotificationStatus.APPROVED
                          ? 'hidden'
                          : ''
                      }
                    >
                      {`On ${formatDate(val.statusUpdatedOn)} by ${
                        val.statusEmp
                          ? `${val.statusEmp.firstName} ${val.statusEmp.lastName}`
                          : val.statusUpdatedBy
                      }`}
                      {val.comments && (
                        <Popup
                          content={val.comments}
                          key={val.groupId}
                          header={
                            val.statusEmp
                              ? `${val.status} Comments - ${val.statusEmp.firstName} ${val.statusEmp.lastName}`
                              : `${val.status} Comments - ${val.statusUpdatedBy} `
                          }
                          trigger={
                            <ATMIcon
                              name="comment alternate outline"
                              color="blue"
                            />
                          }
                          on="click"
                        />
                      )}
                    </p>
                  </>
                )}
              </ATMTable.Cell>
              <ATMTable.Cell>
                <ATMDropdown size="small" key="" icon="ellipsis horizontal">
                  <ATMDropdown.Menu>
                    <ATMDropdown.Item
                      content="Resend Email"
                      icon="envelope outline"
                      onClick={() => {
                        handleResendEmail({
                          requestId: val.requestId,
                          version_no: val.version_no,
                          emailBody: val.emailBody ?? '',
                          emailType: val.emailType,
                          groupId: val.groupId,
                          userGroup: {
                            userGroupMembers:
                              val?.userGroup?.userGrpMembers?.map((item) => {
                                return {
                                  emailAdd: item.emailAdd ?? '',
                                  empId: item.empId ?? '',
                                };
                              }) ?? [],
                            userGrpEmail: val.userGroup.userGrpEmail,
                          },
                        });
                      }}
                    />
                    <LERUserGroupDelete
                      data={val}
                      grpNm={val.userGroup.userGrpNm}
                      isOpen={isOpenRemove}
                      handleOpen={setIsOpenRemove}
                    />
                  </ATMDropdown.Menu>
                </ATMDropdown>
              </ATMTable.Cell>
            </ATMTable.Row>
          );
        })}
      </ATMTable.Body>
    </ATMTable>
  );
  return (
    <>
      {' '}
      {data?.authorizationStatement?.emailGroups &&
      data.authorizationStatement.emailGroups.length > 0 ? (
        <> {sentEmailTable}</>
      ) : (
        <ATMGrid columns="1">
          {state.lerUserGrp && state.lerUserGrp.length > 0 ? (
            <ATMGrid.Row>
              <ATMGrid.Column>{defaultFacilityUserGrpTable}</ATMGrid.Column>
              <ATMGrid.Column textAlign="right" style={{ marginTop: '1rem' }}>
                <ATMButton
                  secondary
                  content={Lang.LBL_SEND_EMAIL}
                  icon="paper plane outline"
                  disabled={isDirtyEmail || loading.fetching}
                  onClick={handleClick}
                  type="button"
                  loading={loading.fetching}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          ) : (
            <p> No email(s) set on the selected facility</p>
          )}
        </ATMGrid>
      )}
    </>
  );
};

export default UserGroupListNotificationView;
