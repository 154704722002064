import React, { useCallback } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { ILerRequestEmailGroup } from 'src/models/ler-request.model';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import LERUserGroupDeleteView from './user-group-email-delete.view';

type IProp = {
  data: ILerRequestEmailGroup;
  isOpen: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  grpNm: string;
};

const LERUserGroupDelete: React.FC<IProp> = ({
  data,
  isOpen,
  handleOpen,
  grpNm,
}) => {
  const { state, actions } = useLerRequestContext();

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_EMAIL_USER_GROUP_DELETE
  );

  const handleSubmit = useCallback(async () => {
    const response = await actions.emailUserGrpDELETE(
      data.requestId,
      Number(data.version_no),
      data.groupId
    );

    if (!response.error) {
      ToastSuccess(Lang.MSG_EMAIL_GROUP_REMOVE_SUCCESS);
      actions.dataGET(data.requestId);
      handleOpen(false);
    } else {
      ToastError(response.error.message);
    }
  }, [actions]);

  return (
    <LERUserGroupDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={handleOpen}
      handleSubmit={handleSubmit}
      grpNm={grpNm}
    />
  );
};

export default LERUserGroupDelete;
