import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMCheckbox,
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMSelect,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import {
  IAuthorizedListForm,
  AuthorizedListFormSchema,
  IAuthorizedListTraining,
} from 'src/models/authorized-list.model';
import { ISubCategory } from 'src/models/sub-category.model';
import { useContractorContext } from 'src/contexts/contractor.context';
import { getContractorStatus } from 'src/selectors/contractor.selector';
import { contractorActionTypes } from 'src/ducks/contractor.duck';
import { useTrainersContext } from 'src/contexts/trainers.context';
import { trainersActionTypes } from 'src/ducks/trainers.duck';
import { getTrainersStatus } from 'src/selectors/trainers.selector';
import Lang from 'src/libraries/language';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import { AuthListType, AuthType } from 'src/constants';
import { TrainerStatus } from '../../trainers/trainers-table/trainers-table.component';
import style from '../authorized-list.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IAuthorizedListForm>;
  authType?: string;
  fromList?: boolean;
  handleSubmit: (data: IAuthorizedListForm) => void;
  handleEnable: (value: boolean) => void;
  subCategoryList?: ISubCategory[];
  trainingData?: IAuthorizedListTraining;
  add?: boolean;
  entry?: boolean;
  subCategoryStatus: boolean;
  contractorShortName?: any;
  setContractorShortName?: (data: any) => void;
};

export const AuthTypeList = [
  AuthListType.FieldSwitching,
  AuthListType.SubEntry,
  AuthListType.SubSwitching,
  AuthListType.Training,
];

const AuthorizedListForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  authType,
  fromList = false,
  handleSubmit,
  handleEnable,
  subCategoryList,
  trainingData,
  subCategoryStatus,
  contractorShortName,
  setContractorShortName,
}) => {
  const { state: contractorState, actions: contractorActions } =
    useContractorContext();
  const { state: trainerState, actions: trainerActions } = useTrainersContext();
  const [isContractor, setIsContractor] = useState<boolean>(
    defaultValues?.empId?.slice(0, 2) === '99'
  );
  const [contractorOptions, setContractorOptions] = useState<
    Record<any, any>[]
  >([]);
  const contractorLoading = getContractorStatus(
    contractorState,
    contractorActionTypes.CONTRACTOR_LIST_READ
  ).fetching;
  const trainerStatus = getTrainersStatus(
    trainerState,
    trainersActionTypes.TRAINERS_LIST_READ
  ).fetching;

  const handleCall = useCallback(async () => {
    await contractorActions.listGET({
      limit: 0,
      filters: [
        {
          name: 'employeeStat',
          value: 'A',
        },
      ],
    });
  }, [contractorActions]);

  const handleContractorShortName = useCallback(
    (empID: any) => {
      contractorState?.list.map((val) => {
        if (setContractorShortName && val?.empId?.trim() === empID) {
          setContractorShortName(val.contracShortNm);
        }
      });
    },
    [
      setContractorShortName,
      contractorShortName,
      contractorActions,
      contractorState,
    ]
  );

  useEffect(() => {
    if (
      defaultValues?.empId?.slice(0, 2) === '99' &&
      contractorState.list.length === 0 &&
      !contractorLoading
    ) {
      handleCall();
    }
    if (!trainerState.list.length && !trainerStatus) {
      trainerActions.listGET({ page: 1, limit: 0 });
    }
  }, [
    defaultValues,
    contractorState,
    contractorActions,
    trainerActions,
    trainerState.list,
    trainerStatus,
    handleCall,
  ]);

  const handleContractors = useCallback(
    (value) => {
      const options = Number.isNaN(Number(String(value).slice(-4)))
        ? contractorState.list.filter((item) => item.fullName?.includes(value))
        : contractorState.list.filter((item) => item.empId?.includes(value));
      setContractorOptions(options);
      return value;
    },
    [contractorState, setContractorOptions]
  );
  const authTypeInputs = (type, errors, control, setValue) => {
    switch (type) {
      case AuthListType.Training:
        return (
          <>
            <ATMGrid.Column width={6}>
              <ATMField
                label="Sub Category"
                name="authSubcategoryId"
                placeholder="Select"
                as={ATMSelect}
                control={control}
                loading={subCategoryStatus}
                defaultValue={trainingData?.authSubcategoryId ?? 2}
                error={errors?.authSubcategoryId}
                required
                options={subCategoryList
                  ?.filter((value) => value.authType === type)
                  .map((value) => ({
                    key: value.authSubcategoryId,
                    value: value.authSubcategoryId,
                    text: value.description,
                  }))}
                onChange={([_, { value }]) => value}
                selectOnBlur={false}
                search
                clearable
              />
            </ATMGrid.Column>
          </>
        );
      case AuthListType.FieldSwitching:
        return <span />;
      case AuthListType.SubSwitching:
        return (
          <>
            <ATMGrid.Column width={6}>
              <ATMField
                label="Limited"
                as={ATMCheckbox}
                name="limited"
                control={control}
                loading={subCategoryStatus}
                defaultChecked={!!trainingData?.authSubcategoryId}
                onChange={([_, { checked }]) => {
                  if (checked === true) {
                    control?.register('authSubcategoryId');
                    setValue(
                      'authSubcategoryId',
                      subCategoryList?.filter(
                        (value) => value.authType === type
                      )[0].authSubcategoryId
                    );
                  }
                  if (checked === false) {
                    control?.register('authSubcategoryId');
                    setValue('authSubcategoryId', null);
                  }
                  return checked;
                }}
              />
            </ATMGrid.Column>
          </>
        );
      case AuthListType.SubEntry:
        return (
          <>
            <ATMGrid.Column width={6}>
              <ATMField
                label="Sub Category"
                name="authSubcategoryId"
                placeholder="Select"
                as={ATMSelect}
                control={control}
                required
                error={errors?.authSubcategoryId}
                defaultValue={trainingData?.authSubcategoryId ?? 4}
                options={subCategoryList
                  ?.filter((value) => value.authType === type)
                  .map((value) => ({
                    key: value.authSubcategoryId,
                    value: value.authSubcategoryId,
                    text: value.description,
                  }))}
                onChange={([_, { value }]) => value}
                selectOnBlur={false}
                search
                clearable
              />
            </ATMGrid.Column>
          </>
        );
      default:
        return <span />;
    }
  };

  let contractorData;
  const nameField = (control, errors, setValue) => {
    if (
      defaultValues?.empId?.slice(0, 2) === '99' &&
      contractorState.list.length > 0
    ) {
      contractorData = contractorState.list.find(
        ({ empId }) => empId === defaultValues.empId
      );
    }
    if (fromList && !defaultValues?.empId) {
      return (
        <ATMField
          key={`fromList_${fromList}_${isContractor ? 'contractor' : 'hr'}`}
          required
          selection
          clearable
          search={(options) => options}
          name="empId"
          control={control}
          error={errors.empId}
          icon="search"
          loading={contractorLoading}
          disabled={contractorLoading}
          searchQuery={undefined}
          placeholder={Lang.LBL_ENTER}
          as={isContractor ? ATMDropdown : DropdownEmployee}
          label={Lang.LBL_TSO_OSS_EMPLOYEE_NAME}
          options={
            isContractor
              ? contractorOptions.map((item) => {
                  return {
                    key: item.empId,
                    value: item.empId,
                    text: `${item.fullName} (${item.empId})`,
                  };
                })
              : undefined
          }
          onChange={([_, { value }]) => {
            if (isContractor) {
              const employee = contractorOptions.find((item) => {
                return item.empId === value;
              });
              handleContractorShortName(value);
              setValue('fullName', employee ? employee.fullName : null);
            }
            return value;
          }}
          onSearchChange={(e) => {
            const keyword = e.target.value.trim();
            if (keyword && isContractor) {
              handleContractors(keyword);
            }
          }}
          selectOnBlur={false}
        />
      );
    }

    return (
      <>
        <ATMField
          name="empId"
          as={ATMInput}
          defaultValue={
            contractorData ? contractorData.empId : defaultValues?.empId
          }
          control={control}
          error={errors.empId}
          type="hidden"
          className="hidden"
          onChange={([_, { value }]) => value}
        />
        <FieldReadOnly label={isContractor ? 'Contractor' : 'Employee'}>
          <p>
            {contractorData
              ? `${contractorData?.fullName} - ${contractorData?.contracShortNm}`
              : `${defaultValues?.fullName} - ${
                  isContractor
                    ? defaultValues?.contracShortNm
                    : defaultValues?.empId
                }`}
          </p>
        </FieldReadOnly>
      </>
    );
  };

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={
        defaultValues?.empId?.slice(0, 2) !== '99'
          ? defaultValues
          : contractorData
      }
      validationSchema={AuthorizedListFormSchema}
    >
      {({ control, formState: { errors, isDirty }, setValue, watch }) => {
        handleEnable(isDirty);
        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column width={6}>
                {nameField(control, errors, setValue)}
              </ATMGrid.Column>
              <ATMGrid.Column verticalAlign="bottom" width={6}>
                {fromList && !defaultValues?.empId ? (
                  <ATMField
                    control={control}
                    name="cntrctFlg"
                    as={ATMCheckbox}
                    defaultChecked={false}
                    label={Lang.LBL_CONTRACTOR}
                    onChange={([_, { checked }]) => {
                      setIsContractor(checked);
                      setValue('empId', '');
                      if (contractorState.list.length === 0) {
                        handleCall();
                      }
                      return checked;
                    }}
                  />
                ) : (
                  <span />
                )}
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column width={6}>
                {fromList ? (
                  <ATMField
                    required
                    selection
                    clearable
                    as={ATMDropdown}
                    name="authTypId"
                    control={control}
                    placeholder={Lang.LBL_SELECT}
                    options={Object.entries(AuthType).map(([key, value]) => {
                      return {
                        key,
                        value: value.toString(),
                        text: value,
                      };
                    })}
                    error={errors.authTypId}
                    label={Lang.LBL_AUTHORIZATION_TYPE}
                    onChange={([_, { value }]) => value}
                    selectOnBlur={false}
                    search
                  />
                ) : (
                  <>
                    <ATMField
                      name="authTypId"
                      as={ATMInput}
                      control={control}
                      defaultValue={authType}
                      value={authType}
                      type="hidden"
                      className="hidden"
                      onChange={([_, { value }]) => value}
                    />
                    <FieldReadOnly label={Lang.LBL_AUTHORIZATION_TYPE}>
                      <p>{authType}</p>
                    </FieldReadOnly>
                  </>
                )}
              </ATMGrid.Column>
              {authTypeInputs(watch('authTypId'), errors, control, setValue)}
              {trainingData ? (
                <ATMGrid.Column style={{ display: 'none' }}>
                  <ATMField
                    name="authListId"
                    as={ATMInput}
                    control={control}
                    error={errors.authListId}
                    type="hidden"
                    className="hidden"
                    defaultValue={trainingData?.authListId}
                  />
                </ATMGrid.Column>
              ) : (
                <span />
              )}
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column width={6}>
                <span className={style.dateRange}>
                  <ATMField
                    className={style.dateRange}
                    as={ATMDatePicker}
                    label="Training Date"
                    size="small"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    control={control}
                    required
                    defaultValue={trainingData?.trainingDate}
                    name="trainingDate"
                    maxDate={new Date()}
                    onChange={([_, val]) => {
                      return val.value;
                    }}
                  />
                </span>
              </ATMGrid.Column>
              <ATMGrid.Column width={6}>
                <ATMField
                  key={`trainer_${trainerState.list.length}`}
                  label="Trainer"
                  name="trainerEmpId"
                  placeholder="Select"
                  as={ATMDropdown}
                  search
                  selection
                  required
                  control={control}
                  error={errors.trainerEmpId}
                  defaultValue={formatToRawEmployeeId(
                    trainingData?.trainerEmpId || undefined
                  )}
                  loading={trainerStatus}
                  disabled={trainerStatus}
                  options={[
                    {
                      key: 'CBTRN',
                      value: 'CBTRN',
                      text: 'Computer-Based Training',
                    },
                    ...trainerState.list
                      .filter(
                        (val) =>
                          val.trainer.empId &&
                          val.trainerStat === TrainerStatus.ACTIVE
                      )
                      .map(({ trainer }) => ({
                        key: trainer.empId,
                        value: trainer.empId,
                        text: `${trainer.lastName}, ${trainer.firstName} (${trainer.empId})`,
                      }))
                      .sort((a, b) => (a.text < b.text ? -1 : 1)),
                  ]}
                  onChange={([_, { value }]) => {
                    return value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column width={6}>
                <ATMField
                  label="Failed Test"
                  as={ATMCheckbox}
                  name="testFailInd"
                  defaultChecked={
                    trainingData?.testFailInd !== undefined
                      ? trainingData.testFailInd
                      : false
                  }
                  control={control}
                  onChange={([_, { checked }]) => checked}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default AuthorizedListForm;
