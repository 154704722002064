import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import FileReducer, {
  IFileState,
  defaultState,
  duckActions,
} from 'src/ducks/file.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    FileReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IFileContext = ReturnType<typeof useReducer>;

const FileContext = createContext<Partial<IFileContext>>({
  state: defaultState,
}) as React.Context<IFileContext>;

type IProps = {
  state?: Partial<IFileState>;
};

const FileProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <FileContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

const useFileContext = () => useContext(FileContext);

export type IUseFileContext = ReturnType<typeof useFileContext>;

export { FileContext, FileProvider, useFileContext };
