import yup from 'src/libraries/validator.library';

export const VoltShape = {
  voltId: yup.number().required(),
  voltNm: yup.string().required(),
  dsplyOrd: yup.number(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const VoltSchema = yup.object(VoltShape);

export const VoltFormSchema = yup.object({
  voltNm: yup.string().required().default(''),
});

export const VoltListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(VoltSchema.clone()),
});

export const VoltPayloadSchema = yup.object({
  volt: VoltSchema.clone(),
});

export const VoltDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IVolt = yup.Asserts<typeof VoltSchema>;
export type IVoltForm = yup.Asserts<typeof VoltFormSchema>;
