import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMField,
  ATMTextArea,
  ATMHeader,
  ATMTable,
  ATMDropdown,
  ATMInput,
  useATMFormContext,
  // IORGDataTableQueryState
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import { useSubstationContext } from 'src/contexts/substation.context';
import Lang from 'src/libraries/language';
import { IRealTimeLogEntryForm } from 'src/models/real-time-log.model';
import styles from '../real-time-log-form.module.scss';

type IProp = {
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{ index; logSub; tripsTargets } | null>
  >;
};

const TripsAndTargetsForm: React.FC<IProp> = ({ setTripOnEdit }) => {
  const {
    formState: { errors },
    control,
    clearErrors,
    getValues,
    setValue,
  } = useATMFormContext<IRealTimeLogEntryForm>();
  const { state, actions: substationActions } = useSubstationContext();

  const [station, setStation] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [indexOnEdit, setIndexOnEdit] = useState<number | null>(null);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'forcedLog',
  });

  useEffect(() => {
    substationActions?.listGET({ limit: 0, page: 1 });
  }, [substationActions]);

  const handleFields = useCallback(() => {
    clearErrors('forcedLog');

    append({
      logSub: station,
      tripsTargets: description,
    });
  }, [append, control, description, station]);

  return (
    <>
      <ATMHeader content={Lang.LBL_TRIPS_TARGETS} as="h3" />
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <ATMField
            as={ATMDropdown}
            key={`${fields.length}`}
            selection
            clearable
            label={Lang.LBL_STATION}
            name="forceLog.logSub"
            value={station}
            placeholder="Select"
            size="small"
            options={state.list.map((value) => {
              return {
                key: value.substationId,
                value: value.substationId,
                text: `${value.substationId} - ${value.name}`,
              };
            })}
            error={errors.forcedLog && errors.forcedLog[0]?.logSub}
            onChange={
              ((_, { value }) => {
                setStation(value);
                return value;
              }) as any
            }
            search
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={8}>
          <ATMField
            as={ATMTextArea}
            key={`${fields.length}`}
            label={Lang.LBL_TRIPS_TARGETS}
            control={control}
            // name="forcedLog.tripsTargets"
            name="tripsTargetsEntry"
            placeholder="Enter"
            size="small"
            error={errors.forcedLog && errors.forcedLog[0]?.tripsTargets}
            onChange={([_, { value }]) => {
              setDescription(value);
              return value;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={2}>
          <ATMButton
            type="button"
            form="add-stations"
            secondary
            disabled={!station || !description}
            className={styles.bottomMargin}
            onClick={() => {
              const itemIndex = fields.findIndex(
                (item) => item.logSub === station
              );
              if (itemIndex > -1) {
                remove(itemIndex);
              }

              handleFields();
              setValue('tripsTargetsEntry', '');

              setStation(undefined);
              setDescription(undefined);
            }}
            content="Add"
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      {fields.length === 0 ? (
        ''
      ) : (
        <ATMGrid.Row columns={1}>
          <ATMGrid.Column fluid="true">
            <ATMTable>
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.HeaderCell>{Lang.LBL_STATION}</ATMTable.HeaderCell>
                  <ATMTable.HeaderCell>
                    {Lang.LBL_TRIPS_TARGETS}
                  </ATMTable.HeaderCell>
                  <ATMTable.HeaderCell width={2} colSpan={2} textAlign="center">
                    {Lang.LBL_ACTIONS}
                  </ATMTable.HeaderCell>
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {fields.map((item, i) => (
                  <ATMTable.Row key={item.id}>
                    {indexOnEdit === i ? (
                      <>
                        <ATMField
                          control={control}
                          as={ATMInput}
                          name={`forcedLog[${i}].logSub`}
                          defaultValue={item.logSub}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          control={control}
                          as={ATMInput}
                          name={`forcedLog[${i}].tripsTargets`}
                          defaultValue={item.tripsTargets}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMTable.Cell>
                          <ATMField
                            control={control}
                            name={`forcedLog[${i}].logSub`}
                            as={ATMDropdown}
                            selection
                            value={item.logSub}
                            options={state.list
                              .filter((value) => {
                                let found = false;
                                fields.forEach((subs, j) => {
                                  if (
                                    subs.logSub === value.substationId &&
                                    i !== j
                                  ) {
                                    found = true;
                                  }
                                });
                                return !found;
                              })
                              .map((value) => {
                                return {
                                  key: value.substationId,
                                  value: value.substationId,
                                  text: `${value.substationId} - ${value.name}`,
                                };
                              })}
                            onChange={([_, { value }]) => {
                              setTripOnEdit({
                                index: i,
                                logSub: value,
                                tripsTargets:
                                  getValues()?.forcedLog?.[i]?.tripsTargets,
                              });
                              return value;
                            }}
                            search
                          />
                        </ATMTable.Cell>
                        <ATMTable.Cell>
                          <ATMField
                            control={control}
                            as={ATMInput}
                            name={`forcedLog[${i}].tripsTargets`}
                            defaultValue={item.tripsTargets}
                            onChange={([_, { value }]) => {
                              setTripOnEdit({
                                index: i,
                                logSub: getValues()?.forcedLog?.[i]?.logSub,
                                tripsTargets: value,
                              });
                              return value;
                            }}
                          />
                        </ATMTable.Cell>
                      </>
                    ) : (
                      <>
                        <ATMTable.Cell>{item.logSub}</ATMTable.Cell>
                        <ATMTable.Cell>{item.tripsTargets}</ATMTable.Cell>
                      </>
                    )}
                    <ATMTable.Cell>
                      <ATMButton
                        icon={indexOnEdit === i ? 'save' : 'edit'}
                        type="button"
                        className={styles.trashIcon}
                        onClick={() => {
                          if (indexOnEdit === i) {
                            setIndexOnEdit(null);
                            update(i, {
                              logSub: getValues()?.forcedLog?.[i]?.logSub,
                              tripsTargets:
                                getValues()?.forcedLog?.[i]?.tripsTargets,
                            });
                            setTripOnEdit(null);
                          } else {
                            if (indexOnEdit !== null) {
                              setImmediate(() => {
                                update(indexOnEdit, {
                                  logSub:
                                    getValues()?.forcedLog?.[indexOnEdit]
                                      ?.logSub,
                                  tripsTargets:
                                    getValues()?.forcedLog?.[indexOnEdit]
                                      ?.tripsTargets,
                                });
                              });
                            }
                            setImmediate(() => {
                              setTripOnEdit({
                                index: i,
                                logSub: getValues()?.forcedLog?.[i]?.logSub,
                                tripsTargets:
                                  getValues()?.forcedLog?.[i]?.tripsTargets,
                              });
                              setIndexOnEdit(i);
                            });
                          }
                        }}
                      />
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMButton
                        icon="trash alternate outline"
                        type="button"
                        className={styles.trashIcon}
                        onClick={() => {
                          setIndexOnEdit(null);
                          setTripOnEdit(null);
                          remove(i);
                        }}
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))}
              </ATMTable.Body>
            </ATMTable>
          </ATMGrid.Column>
        </ATMGrid.Row>
      )}
    </>
  );
};

export default TripsAndTargetsForm;
