import React, { useState } from 'react';
import { ATMButton, ATMGrid, MOLRightPanel } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ITroubleJobsForm } from 'src/models/trouble-jobs.model';
import TroubleJobsForm from '../trouble-jobs-form/trouble-jobs-form.component';

type IProps = {
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  // handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ITroubleJobsForm) => void;
  openAddPanel: () => void;
  isOpenAdd: boolean;
  setIsOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
};

const TroubleJobsAddView: React.FC<IProps> = ({
  loading,
  formRef,
  // handleOpen,
  handleClick,
  handleSubmit,
  openAddPanel,
  isOpenAdd,
  setIsOpenAdd,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <MOLRightPanel
      header={Lang.TTL_TROUBLE_JOB_ADD}
      isOpen={isOpenAdd}
      onOpen={() => setIsOpenAdd(true)}
      onClose={() => setIsOpenAdd(false)}
      trigger={
        <ATMButton
          content={Lang.LBL_CREATE_TROUBLE_JOB}
          primary
          icon="plus"
          floated="right"
          onClick={openAddPanel}
        />
      }
      panelContent={
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column tablet={16} computer={16} mobile={16}>
              <TroubleJobsForm
                formRef={formRef}
                handleSubmit={handleSubmit}
                handleEnable={(value) => setIsDirty(value)}
                beginDateSelected={new Date()}
                endDateSelected={new Date()}
                setBeginDateSelected={() => undefined}
                setEndDateSelected={() => undefined}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      }
      panelFooter={
        <div>
          <div>
            <ATMButton
              secondary
              size="small"
              onClick={() => setIsOpenAdd(false)}
            >
              {Lang.LBL_CLOSE}
            </ATMButton>
            <ATMButton
              primary
              size="small"
              content="Save"
              disabled={!isDirty || loading}
              onClick={handleClick}
            >
              {Lang.LBL_CREATE_JOB}
            </ATMButton>
          </div>
        </div>
      }
    />
  );
};

export default TroubleJobsAddView;
