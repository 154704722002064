import {
  IOmsAuditReportState,
  omsAuditReportActionTypes,
} from 'src/ducks/oms-audit-report.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getOmsAuditReportStatus = (
  state: IOmsAuditReportState,
  action: keyof typeof omsAuditReportActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
