import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IKeyRequestValidationForm } from 'src/models/key-request.model';
import KeyRequestContractorForm from './key-request-contractor-form.component';

type IProps = {
  // defaultValues: IKeyRequest;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IKeyRequestValidationForm) => void;
};

const KeyRequestContractorView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          content="Request Key for Contractor"
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header content={Lang.TTL_KEY_CONTRACTOR_REQUEST} />
      <ATMModal.Content style={{ marginBottom: '100px' }}>
        <KeyRequestContractorForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
          id="cancel"
        />
        <ATMButton
          primary
          content="Submit"
          disabled={!isDirty || loading}
          loading={loading}
          onClick={handleClick}
          id="submit"
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default KeyRequestContractorView;
