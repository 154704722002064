import React, { useCallback, useState } from 'react';
import { useSickListContext } from 'src/contexts/sick-list.context';
import { ISickList } from 'src/models/sick-list.model';
import { getSickListStatus } from 'src/selectors/sick-list.selector';
import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import SickListDeleteView from './sick-list-delete.view';

type IProp = {
  data: ISickList;
};

const SickListDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useSickListContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getSickListStatus(
    state,
    sickListActionTypes.SICK_LIST_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.sickEmpEntryId) {
      const response = await actions.dataDELETE(data.sickEmpEntryId);

      if (!response.error) {
        actions.listGET();
        setIsOpen(false);

        ToastSuccess(`Sick Entry Deleted Successfully for Employee ${data.empId}`);
      } else {
        ToastError('Deleted Error!');
      }
    }
  }, [actions, data]);

  return (
    <SickListDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default SickListDelete;
