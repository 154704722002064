import { httpClient } from 'src/libraries/http.library';
import {
  IShortDescriptionCategoryForm,
  ShortDescriptionCategoryDeletePayloadSchema,
  ShortDescriptionCategoryListPayloadSchema,
  ShortDescriptionCategoryPayloadSchema,
  ShortDescriptionListPayloadSchema,
} from 'src/models/short-description.model';

const client = httpClient();
const endpoint = 'job-short-desc-and-cat';

const services = {
  listGET: async () => {
    return client.get(`/${endpoint}`, {}, ShortDescriptionListPayloadSchema);
  },

  listCategoriesAndDescGET: async () => {
    return client.get(
      `/${endpoint}?action=list`,
      {},
      ShortDescriptionCategoryListPayloadSchema
    );
  },

  createCategoryAndDescsPOST: async (data: IShortDescriptionCategoryForm) => {
    return client.post(
      `/${endpoint}`,
      data,
      ShortDescriptionCategoryPayloadSchema
    );
  },

  updateCategoryAndDescsPUT: async (data: IShortDescriptionCategoryForm) => {
    return client.put(
      `/${endpoint}`,
      data,
      ShortDescriptionCategoryPayloadSchema
    );
  },
  deleteShortDesc: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}?action=deleteDesc`,
      ShortDescriptionCategoryDeletePayloadSchema
    );
  },
  deleteShortDescCategory: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}`,
      ShortDescriptionCategoryDeletePayloadSchema
    );
  },
};

export default services;
