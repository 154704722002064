import { AxiosRequestConfig } from 'axios';
import {
  helpers,
  requestHeaderProperty,
  SharepointEnvironmentProperties,
} from 'src/helpers/sharepoint-service.helper';
import { getFileType } from 'src/libraries/file.library';
import {
  httpClient,
  commonClient,
  sharePointClient,
} from 'src/libraries/http.library';
import yup from 'src/libraries/validator.library';
import {
  FileSchema,
  FileDownloadPayloadSchema,
  IFile,
  FileUploadListPayload,
} from 'src/models/file.model';
import { AccessTokenPayloadSchema } from 'src/models/sharepoint-service.model';
import { SuccessSchema } from '../models';

const http = httpClient();
const common = commonClient();
const sharePoint = sharePointClient();

const services = {
  // eslint-disable-next-line @typescript-eslint/require-await
  uploadPOST: async (file: File, options: AxiosRequestConfig = {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return common.post(
      `/files/upload/stgyrd/${getFileType(file.name)}`,
      formData,
      FileSchema,
      options
    );
  },

  downloadGET: async (fileName: string, docName: string) => {
    return common.get(
      `/files/download/stgyrd/${fileName}?userFileName=${encodeURIComponent(
        docName
      )}`,
      {},
      FileDownloadPayloadSchema
    );
  },

  uploadDELETE: async (fileName: string) => {
    return common.delete(`/files/stgyrd/${fileName}`, FileSchema);
  },

  listByRequestIdGET: async (requestId: number) => {
    return http.get('/uploads', { requestId, limit: 0 }, FileUploadListPayload);
  },

  addPOST: async (data: IFile) => {
    return http.post('/uploads', data, FileSchema);
  },

  dataDELETE: async (uploadId: number) => {
    return http.delete(`/uploads/${uploadId}`, SuccessSchema);
  },

  tokenGET: async () => {
    return http.get('sharepoint-token', {}, AccessTokenPayloadSchema);
  },

  listSharepoint: async (data) => {
    try {
      return await sharePoint.post(
        'lists/2',
        helpers.mccListXmlRequestBuilder(data),
        yup.mixed(),
        {
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
            'Content-Type': `${requestHeaderProperty.sharepointRequestContentType}${SharepointEnvironmentProperties.SHAREPOINT_GET_LIST_ITEMS_ACTION}, ${SharepointEnvironmentProperties.SHAREPOINT_REQUEST_HOST}`,
          },
        }
      );
    } catch (e) {
      return null;
    }
  },

  deleteDocumentSharepoint: async (data) => {
    try {
      return await sharePoint.post(
        'lists/2',
        helpers.deleteDocumentXmlRequestBuilder(data),
        yup.mixed(),
        {
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
            'Content-Type': `${requestHeaderProperty.sharepointRequestContentType}${SharepointEnvironmentProperties.SHAREPOINT_DELETE_DOCUMENT_ACTION}, ${SharepointEnvironmentProperties.SHAREPOINT_REQUEST_HOST}`,
          },
        }
      );
    } catch (e) {
      return null;
    }
  },

  createFolderSharePoint: async (data) => {
    try {
      return await sharePoint.post(
        'dws/2',
        helpers.createFolderXmlRequestBuilder(data),
        yup.mixed(),
        {
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
            'Content-Type': `${requestHeaderProperty.sharepointRequestContentType}${SharepointEnvironmentProperties.SHAREPOINT_CREATE_FOLDER_ACTION}, ${SharepointEnvironmentProperties.SHAREPOINT_REQUEST_HOST}`,
          },
        }
      );
    } catch (error) {
      return null;
    }
  },

  uploadSharePoint: async (data) => {
    try {
      return await sharePoint.post(
        'copy/2',
        helpers.fileUploadXmlRequestBuilder(data),
        yup.mixed(),
        {
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
            'Content-Type': `${requestHeaderProperty.sharepointRequestContentType}${SharepointEnvironmentProperties.SHAREPOINT_COPY_INTO_ITEMS_ACTION}, ${SharepointEnvironmentProperties.SHAREPOINT_REQUEST_HOST}`,
          },
        }
      );
    } catch (error) {
      return null;
    }
  },
};

export default services;
