import React from 'react';
import {
  MOLNoData,
  ORGDataTable,
  IORGDataTableColumn,
} from 'shared-it-appmod-ui';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { getDailyReportsStatus } from 'src/selectors/daily-reports.selector';
import { dailyReportsActionTypes } from 'src/ducks/daily-reports.duck';
import { AccessRole, LERRequestFormStep, LERRequestPage } from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import { IETS } from 'src/models/daily-reports.model';
import { useLocationParams } from 'src/hooks/location-tab.hook';

const ETSValueSplit = ({ value }) => {
  return (
    <>
      <p>{value[0]}</p>
      <p>{value[1]}</p>
    </>
  );
};

// export enum AllowedStatus {
//   COMPLETED = 'COMPLETED',
//   OPEN = 'OPEN',
//   SENT = 'SENT',
//   ASSIGNED = 'ASSIGNED',
//   MESSAGERECEIVED = 'MESSAGERECEIVED',
// }

const ETSURLValueSplit = ({ value, requestId }) => {
  const { handleChange } = useLocationParams([LERRequestPage.Edit, 'active']);
  const rs: any[] = value ?? [];
  const links: any = [];
  rs.map((val) => {
    const urlVal = val.split('#');

    links.push(
      <a
        key={urlVal[0]}
        onClick={() =>
          handleChange({
            [hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
              ? LERRequestPage.Edit
              : LERRequestPage.View]: requestId,
            active: LERRequestFormStep.SWITCHING,
          })
        }
        rel="noreferrer noopener"
        role="button"
      >
        {urlVal.length > 0 ? urlVal[0] : ''}
      </a>
    );
  });
  return <>{links}</>;
};

export const dailyReportETSColumns: IORGDataTableColumn<IETS>[] = [
  {
    index: 'location',
    title: 'Location',
    render: (_, value) => {
      return hasRole([
        AccessRole.MCC_OUTAGE_SCHEDULER,
        AccessRole.MCC_REAL_TIME_OPERATOR,
      ]) ? (
        <ETSURLValueSplit value={value.location} requestId={value.requestId} />
      ) : (
        <ETSValueSplit value={value.location} />
      );
    },
  },
  {
    index: 'start_time',
    title: 'Start Time',
    render: (_, value) => <ETSValueSplit value={value.start_time} />,
  },
  {
    index: 'area',
    title: 'Area',
    render: (_, value) => <ETSValueSplit value={value.area} />,
  },
  {
    index: 'order_number',
    title: 'Order #',
    render: (_, value) => <ETSValueSplit value={value.order_number} />,
  },
  {
    index: 'status',
    title: 'Status',
    render: (_, value) => <ETSValueSplit value={value.status} />,
  },
  {
    index: 'ets',
    title: 'ETS',
    render: (_, value) => <ETSValueSplit value={value.ets} />,
  },
  {
    index: 'works',
    title: 'For Work On',
    render: (_, value) => <ETSValueSplit value={value.works} />,
  },
  {
    index: 'wo',
    title: 'W.O #',
    render: (_, value) => <ETSValueSplit value={value.wo} />,
  },
];

const ETSTable: React.FC = () => {
  const { state } = useDailyReportsContext();
  const { fetching } = getDailyReportsStatus(
    state,
    dailyReportsActionTypes.DAILY_REPORTS_ETS_LIST
  );

  // state?.etsList?.filter(
  //   (val) =>
  //     val?.status
  //       ?.toString()
  //       .toUpperCase()
  //       .includes(AllowedStatus.SENT) ||
  //     val?.status
  //       ?.toString()
  //       .toUpperCase()
  //       .includes(AllowedStatus.COMPLETED) ||
  //     val?.status
  //       ?.toString()
  //       .toUpperCase()
  //       .includes(AllowedStatus.OPEN) ||
  //     val?.status
  //       ?.toString()
  //       .toUpperCase()
  //       .includes(AllowedStatus.ASSIGNED) ||
  //     val?.status
  //       ?.toString()
  //       .toUpperCase()
  //       .includes(AllowedStatus.MESSAGERECEIVED)
  // )
  return (
    <div>
      <ORGDataTable
        columns={dailyReportETSColumns}
        data={state?.etsList}
        noDataText={
          <MOLNoData
            icon="search"
            header="Search to view records"
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
        loading={fetching}
        celled={false}
        sortable
        showPagination={false}
      />
    </div>
  );
};

export default ETSTable;
