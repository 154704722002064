import React, { useCallback, useRef } from 'react';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import { IFacilityForm } from 'src/models/facility.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import FacilityAddView from './facility-add.view';

type IProp = {
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
};

const FacilityAdd: React.FC<IProp> = ({ setFacilityActions }) => {
  const { state, actions } = useFacilityContext();
  const formRef = useRef<HTMLFormElement>(null);

  const status = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IFacilityForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions?.listGET();

        setFacilityActions({
          isNew: false,
          isDetails: false,
          isEdit: false,
        });

        ToastSuccess(
          Lang.formatString(Lang.MSG_ADD_FACILITY, formData.outgFacNm)
        );
      } else {
        ToastError(response.error.message);
      }
    },
    [actions]
  );

  return (
    <FacilityAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      setFacilityActions={setFacilityActions}
    />
  );
};

export default FacilityAdd;
