import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import CancelledReportReducer, {
  ICancelledReportState,
  defaultState,
  duckActions,
} from 'src/ducks/cancelled-report.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    CancelledReportReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ICancelledReportContext = ReturnType<typeof useReducer>;

const CancelledReportContext = createContext<Partial<ICancelledReportContext>>({
  state: defaultState,
}) as React.Context<ICancelledReportContext>;

type IProps = {
  state?: Partial<ICancelledReportState>;
};

const CancelledReportProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <CancelledReportContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </CancelledReportContext.Provider>
  );
};

const useCancelledReportContext = () => useContext(CancelledReportContext);

export type IUseCancelledReportContext = ReturnType<
  typeof useCancelledReportContext
>;

export {
  CancelledReportContext,
  CancelledReportProvider,
  useCancelledReportContext,
};
