import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMInput,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import Lang from 'src/libraries/language';
import Moment, {
  format24hTime,
  formatDate,
} from 'src/libraries/moment.library';
import {
  IAuthorizedItem,
  IAuthorizedListEmployee,
} from 'src/models/authorized-list.model';
import { checkValue } from 'src/libraries/common.library';
import { buildExportData, convertToExcel } from 'src/selectors/file.selector';
import styles from './authorized-list-employee.module.scss';

const cellContent = (value: IAuthorizedItem | null, isHTML = false) => {
  if (!value) {
    return isHTML ? (
      `<span style="color: red;">${Lang.LBL_NO}</span>`
    ) : (
      <span style={{ color: 'red' }}>{Lang.LBL_NO}</span>
    );
  }

  if (value.supspension_ind) {
    return isHTML ? (
      `<span style="color: red;">${Lang.LBL_SUSPENDED}</span>`
    ) : (
      <span style={{ color: 'red' }}>{Lang.LBL_SUSPENDED}</span>
    );
  }

  if (value.test_fail_ind) {
    return isHTML ? (
      `<span style="color: red;">${Lang.LBL_FAILED_TEST}</span>`
    ) : (
      <span style={{ color: 'red' }}>{Lang.LBL_FAILED_TEST}</span>
    );
  }

  // eslint-disable-next-line no-nested-ternary
  return Moment(value.expire_dt).isBefore(Moment()) ? (
    isHTML ? (
      `<span style="color: red;">${Lang.LBL_EXPIRED} (${formatDate(
        value.expire_dt,
        'YYYY-MM-DD'
      )})</span>`
    ) : (
      <span style={{ color: 'red' }}>{`${Lang.LBL_EXPIRED} (${formatDate(
        value.expire_dt,
        'YYYY-MM-DD'
      )})`}</span>
    )
  ) : (
    Lang.LBL_ACTIVE
  );
};

const columns: IORGDataTableColumn<IAuthorizedListEmployee>[] = [
  {
    index: 'nm_lt_frst',
    title: Lang.LBL_EMPLOYEE_NAME,
  },
  {
    index: 'auth_training',
    title: Lang.LBL_AUTHORIZATION_TRAINING,
    render: (value) => cellContent(value),
  },
  {
    index: 'sub_entry',
    title: Lang.LBL_SUBSTATION_ENTRY,
    render: (value) => cellContent(value),
  },
  {
    index: 'sub_switching',
    title: Lang.LBL_SUBSTATION_SWITCHING,
    render: (value) =>
      value && value.sub_auth_typ_desc === 'Limited'
        ? 'Limited'
        : cellContent(value),
  },
  // {
  //   index: 'field_switching',
  //   title: Lang.LBL_FIELD_LINE_SWITCHING,
  //   render: (value) => cellContent(value),
  // },
];

const INTERVAL_MINUTES = 15;

const AuthorizedListEmployee: React.FC = () => {
  const { state, actions } = useAuthorizedListContext();
  const timer = useRef<NodeJS.Timeout>();
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFetch = useCallback(
    async (hasLoading = true) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      setLoading(hasLoading);
      await actions.listAllGET();
      setLoading(false);

      timer.current = setTimeout(() => {
        handleFetch(false);
      }, INTERVAL_MINUTES * 60 * 1000);
    },
    [actions, setLoading]
  );

  useEffect(() => {
    document.title = `Full Authorized List - ${Lang.TTL_APPLICATION}`;

    handleFetch();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [handleFetch]);

  const list = useMemo(() => {
    return state.allList.filter((v) =>
      (v.nm_lt_frst || '').toLowerCase().includes(keyword.toLowerCase())
    );
  }, [state.allList, keyword]);

  const handleDownload = useCallback(() => {
    if (list.length) {
      const items = list.map((value) => [
        checkValue(
          value.contr_short_nm
            ? `${value.nm_lt_frst} / ${value.contr_short_nm}`
            : value.nm_lt_frst
        ),
        cellContent(value.auth_training, true),
        cellContent(value.sub_entry, true),
        value.sub_switching &&
        value.sub_switching.sub_auth_typ_desc === 'Limited'
          ? 'Limited'
          : cellContent(value.sub_switching, true),
        // cellContent(value.field_switching, true),
      ]);
      const { exportData, format } = convertToExcel(
        buildExportData(items, [
          Lang.LBL_EMPLOYEE_NAME,
          Lang.LBL_AUTHORIZATION_TRAINING,
          Lang.LBL_SUBSTATION_SWITCHING,
          Lang.LBL_SUBSTATION_ENTRY,
          // Lang.LBL_FIELD_LINE_SWITCHING,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Authorized_Employee_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [list]);

  const hasValue = !!keyword.trim().length;
  const [showExpired, setShowExpired] = useState(false);
  const handleExpired = useCallback(
    (showAll: boolean) => {
      if (showAll) {
        setShowExpired(showAll);
      } else {
        setShowExpired(false);
      }
    },
    [showExpired, setShowExpired]
  );
  const checkStatus = (status: any) => {
    if (
      status &&
      !Moment(status.expire_dt).isBefore(Moment()) &&
      !status.supspension_ind &&
      !status.test_fail_ind
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="admin-container">
      <p className={styles.headerTime}>
        {Lang.formatString(
          Lang.NTE_LIST_CURRENT_UPDATE,
          format24hTime(new Date()) ?? ''
        )}
      </p>
      <ORGDataTable
        className={styles.sticky}
        columns={columns}
        data={
          showExpired
            ? list
            : list.filter((val) => {
                let isValid = false;
                if (val?.auth_training) {
                  if (checkStatus(val.auth_training)) {
                    isValid = true;
                  }
                }
                if (val?.sub_entry) {
                  if (checkStatus(val.sub_entry)) {
                    isValid = true;
                  }
                }
                if (val?.sub_switching) {
                  if (checkStatus(val.sub_switching)) {
                    isValid = true;
                  }
                }
                // if (val?.field_switching) {
                //   if (checkStatus(val.field_switching)) {
                //     isValid = true;
                //   }
                // }
                return isValid;
              })
        }
        loading={loading}
        showPagination={false}
        counter
        sortable
        toolbars={[
          () => (
            <ATMInput
              placeholder="Search Name"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
              icon={{
                name: hasValue ? 'close' : 'search',
                link: hasValue,
                onClick: hasValue ? () => setKeyword('') : undefined,
              }}
              size="small"
            />
          ),
          () => (
            <ATMButton
              secondary
              type="button"
              content={Lang.LBL_REFRESH}
              size="tiny"
              onClick={handleFetch}
              loading={loading}
              disabled={loading}
            />
          ),
          () => (
            <ATMButton
              secondary
              icon="download"
              size="tiny"
              onClick={handleDownload}
            />
          ),
          () => (
            <ATMCheckbox
              toggle
              style={{ marginTop: '.4em' }}
              label="Show Expired"
              onChange={(__, { checked }) => {
                handleExpired(checked ?? false);
                return checked;
              }}
            />
          ),
        ]}
      />
    </div>
  );
};

export default AuthorizedListEmployee;
