import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal, ATMMessage } from 'shared-it-appmod-ui';
import { useFileContext } from 'src/contexts/file.context';
import Lang from 'src/libraries/language';
import { IStatus } from 'src/libraries/thunk.library';
import {
  ISubstationEntry,
  ISubstationEntryForm,
} from 'src/models/substation-entry.model';
import SubstationEntryLogForm from '../substation-entry-log-form/substation-entry-log-form.component';
import style from './substation-entry-log-edit.module.scss';

type IProps = {
  defaultValues: ISubstationEntry;
  isOpen: boolean;
  loading: boolean;
  isFileCached: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  errorHandling: IStatus;
  clearError: () => void;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISubstationEntryForm) => void;
  setIsFileCached: (value: boolean) => void;
};

const SubstationEntryLogEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  isFileCached,
  formRef,
  errorHandling,
  clearError,
  handleOpen,
  handleClick,
  handleSubmit,
  setIsFileCached,
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [noFiles, setNoFiles] = useState<boolean>(false);
  const { actions: fileAction } = useFileContext();

  const handleRemovedFiles = useCallback(
    (value) => {
      setNoFiles(value);
    },
    [setNoFiles]
  );

  return (
    <ATMModal
      open={isOpen}
      trigger={<ATMButton icon="edit" onClick={() => handleOpen(true)} />}
    >
      <ATMModal.Header>Edit Substation Entry Log</ATMModal.Header>
      {errorHandling.error && errorHandling.error.name === 'BadRequest' && (
        <ATMMessage negative>
          {errorHandling.error.message}{' '}
          <ATMButton
            className={style.closeErrorBtn}
            icon="close"
            onClick={clearError}
          />
        </ATMMessage>
      )}
      {noFiles && (
        <ATMMessage info>{Lang.MSG_EMPTY_VISITOR_UPLOADED_LIST}</ATMMessage>
      )}
      <ATMModal.Content scrolling>
        <SubstationEntryLogForm
          defaultValues={{
            ...defaultValues,
            visitors: defaultValues.visitors || '',
          }}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          isFileCached={isFileCached}
          setIsFileCached={setIsFileCached}
          handleRemovedFiles={handleRemovedFiles}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          disabled={noFiles}
          onClick={() => {
            handleOpen(false);
            setIsFileCached(false);
            fileAction.deleteAllStashedFiles();
          }}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SubstationEntryLogEditView;
