import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';

export const ShortDescriptionShape = {
  catId: yup.number().required(),
  descId: yup.number().required(),
  shortDesc: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const ShortDescriptionCategoryShape = {
  catId: yup.number().required(),
  shortDescCat: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const ShortDescriptionSchema = yup.object(ShortDescriptionShape);

export const ShortDescriptionCategorySchema = yup.object(
  ShortDescriptionCategoryShape
);

export const ShortDescriptionListPayloadSchema = yup.object({
  shortDescCategories: yup.array().of(ShortDescriptionCategorySchema.clone()),
  shortDescriptions: yup.array().of(ShortDescriptionSchema.clone()),
});

export const ShortDescriptionCategoryPayloadSchema = yup.object({
  ...ShortDescriptionCategoryShape,
  descriptions: yup.array().of(ShortDescriptionSchema).required(),
});

export const ShortDescriptionCategoryFormSchema = yup.object({
  ...ShortDescriptionCategoryShape,
  catId: yup.number(),
  shortDescCat: yup
    .string()
    .required(Lang.MSG_SHORT_DESCRIPTION_CATEGORY_REQUIRED),
  descriptions: yup
    .array()
    .of(
      yup.object({
        ...ShortDescriptionShape,
        descId: yup.number(),
        catId: yup.number(),
      })
    )
    .required(Lang.MSG_SHORT_DESCRIPTION_REQUIRED),
});

export const ShortDescriptionCategoryListPayloadSchema = yup.array().of(
  yup.object({
    ...ShortDescriptionCategoryShape,
    descriptions: yup.array().of(ShortDescriptionSchema).required(),
  })
);

export const ShortDescriptionCategoryDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IShortDescription = yup.Asserts<typeof ShortDescriptionSchema>;
export type IShortDescriptionCategory = yup.Asserts<
  typeof ShortDescriptionCategorySchema
>;
export type IShortDescriptionCategoryList = yup.Asserts<
  typeof ShortDescriptionCategoryPayloadSchema
>;
export type IShortDescriptionCategoryForm = yup.Asserts<
  typeof ShortDescriptionCategoryFormSchema
>;
