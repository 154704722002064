import PrivateTemplate from 'src/components/templates/private/private.component';
import routerFactory, { IRoute } from 'src/libraries/router.library';
import Error403 from 'src/components/pages/error/error-403/error-403.component';
import Error404 from 'src/components/pages/error/error-404/error-404.component';
import Home from 'src/components/pages/home/home.component';
import OutagePlanning from './components/pages/navigation/outage-planning/outage-planning.component';
import Keys from './components/pages/navigation/keys/keys.component';
import Lang from './libraries/language';
import RealTimeLog from './components/pages/real-time-log/real-time-log-list/real-time-log-list.component';
import TroubleTicketList from './components/pages/trouble-ticket/trouble-ticket-list/trouble-ticket-list.component';
import SubstationEntryLog from './components/pages/substation-entry-log/substation-entry-log-list/substation-entry-log-list.component';
import AuthList from './components/pages/navigation/auth-list/auth-list.component';
import TroubleJobsList from './components/pages/trouble-jobs/trouble-jobs-list/trouble-jobs-list.component';
import OutageCalendar from './components/pages/outage/outage-calendar/outage-calendar.component';
import RolesSettings from './components/pages/roles-settings/roles-settings.component';
import EmailLinkPage from './components/pages/email-link-page/email-link-page.component';
import EmailChangeRequestPage from './components/pages/email-change-request-link-page/email-change-request-link-page.component';
import { AccessType } from './constants';
import Unauthorized from './components/templates/unauthorized/unauthorized.component';
import Logout from './components/pages/logout/logout.component';
import SystemChangeDetail from './components/pages/system-change/system-change-detail/system-change-detail.component';
import DailyReportSystemChangeDetail from './components/pages/daily-reports/daily-report-system-changes/daily-report-system-changes-detail.component';
import TroubleTicketEdit from './components/pages/trouble-ticket/trouble-ticket-edit/trouble-ticket-edit.component';
import SubstationEntryLogStation from './components/pages/substation-entry-log/substation-entry-log-station/substation-entry-log-station.component';
import AuthorizedListEmployee from './components/pages/authorized-list/authorized-list-employee/authorized-list-employee.component';

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */
const MainRoute: IRoute = {
  label: 'Home',
  path: '/',
  layout: PrivateTemplate,
  component: Home,
  exact: true,
};

const KeysRoute: IRoute = {
  label: Lang.TTL_KEYS,
  path: '/keys',
  layout: PrivateTemplate,
  component: Keys,
  permission: AccessType.KEYS,
};

const AuthListRoute: IRoute = {
  label: 'Auth List',
  path: '/auth-list',
  layout: PrivateTemplate,
  component: AuthList,
  permission: AccessType.AUTH_LIST,
  childRoutes: [
    {
      path: '/auth-list/employees',
      component: AuthorizedListEmployee,
    },
  ],
};

const OutageRoute: IRoute = {
  label: Lang.TTL_OUTAGE_PLANNING,
  path: '/outage-planning',
  layout: PrivateTemplate,
  component: OutagePlanning,
  permission: AccessType.OUTAGE_REQUEST_READ,
  childRoutes: [
    {
      path: '/outage-planning/system/:id',
      component: SystemChangeDetail,
      exact: true,
    },
    {
      path: '/outage-planning/daily-report/:id',
      component: DailyReportSystemChangeDetail,
      exact: true,
    },
  ],
};

const OutageCalendarRoute: IRoute = {
  label: Lang.TTL_OUTAGE_CALENDAR,
  path: '/outage-calendar',
  layout: PrivateTemplate,
  component: OutageCalendar,
  permission: AccessType.OUTAGE_REQUEST_READ,
};

const RealTimeRoute: IRoute = {
  label: Lang.TTL_REAL_TIME_LOG,
  path: '/real-time-log',
  layout: PrivateTemplate,
  component: RealTimeLog,
  permission: AccessType.REAL_TIME_LOG,
};

const TroubleJobs: IRoute = {
  label: Lang.TTL_TROUBLE_JOBS,
  path: '/trouble-jobs',
  layout: PrivateTemplate,
  component: TroubleJobsList,
  permission: AccessType.TROUBLE_JOB,
};

const TroubleRoute: IRoute = {
  label: 'Trouble Ticket',
  path: '/trouble-ticket',
  layout: PrivateTemplate,
  component: TroubleTicketList,
  permission: AccessType.TROUBLE_TICKET,
  childRoutes: [
    {
      path: '/trouble-ticket/:id',
      component: TroubleTicketEdit,
    },
  ],
};

const SubstationEntryRoute: IRoute = {
  label: 'Substation Entry Log',
  path: '/substation-entry-log',
  layout: PrivateTemplate,
  component: SubstationEntryLog,
  permission: AccessType.SUBSTATION_ENTRY_LOG_READ,
  childRoutes: [
    {
      path: '/substation-entry-log/substation',
      component: SubstationEntryLogStation,
    },
  ],
};

const RoleRoute: IRoute = {
  label: 'Roles',
  path: '/roles',
  layout: PrivateTemplate,
  component: RolesSettings,
  permission: AccessType.ROLES,
};

const EmailLinkRoute: IRoute = {
  label: '',
  path: '/email',
  layout: PrivateTemplate,
  component: EmailLinkPage,
  permission: AccessType.OUTAGE_REQUEST_READ,
};

const EmailChangeRequestRoute: IRoute = {
  label: '',
  path: '/email-change-request',
  layout: PrivateTemplate,
  component: EmailChangeRequestPage,
};

const LogoutRoute: IRoute = {
  path: '/logout',
  layout: Unauthorized,
  component: Logout,
  childRoutes: [
    {
      path: '/logout/unauthorized',
      component: Error403,
    },
  ],
  exact: true,
};

export const routes = [
  LogoutRoute,
  MainRoute,
  KeysRoute,
  AuthListRoute,
  OutageRoute,
  OutageCalendarRoute,
  TroubleRoute,
  RealTimeRoute,
  TroubleJobs,
  SubstationEntryRoute,
  RoleRoute,
  EmailLinkRoute,
  EmailChangeRequestRoute,
];
export type IRouter = typeof routes[number];

export default routerFactory(routes, {
  Error404,
  Error403,
});
