import React, { useCallback, useRef, useState } from 'react';
import {
  ITroubleTicketForm,
  ITroubleTicketList,
} from 'src/models/trouble-ticket.model';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { useTroubleEquipmentTypeContext } from 'src/contexts/trouble-equipment-type.context';
import history from 'src/history';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import ForwardTicketView from './forward-ticket.view';

type IProp = {
  data: ITroubleTicketList;
};

const ForwardTicket: React.FC<IProp> = ({ data }) => {
  const { actions, state } = useTroubleTicketContext();
  const { state: troubleEquipmentTypeState } = useTroubleEquipmentTypeContext();
  const { state: workGroupState } = useWorkGroupContext();
  const [isForwardOpen, setIsForwardOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE_FORWARD
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITroubleTicketForm) => {
      const response = await actions.updateForwardPUT(data.troubleTicketId, {
        ...formData,
        assignedTo: null,
        etrDt: null,
      });
      if (!response.error) {
        setIsForwardOpen(false);

        ToastSuccess(
          `Trouble Ticket #${formData.troubleTicketId} forwarded successfully`,
          { delay: 20000 }
        );
        if (response.payload?.emailResult) {
          ToastSuccess(response.payload.emailResult);
        }
        if (formData.requestTyp === 'Scheduled') {
          setIsForwardOpen(false);
          history.push('/trouble-ticket?troubleTicketStatus=1');
        }
        if (formData.requestTyp === 'Trouble') {
          setIsForwardOpen(false);
          history.push('/trouble-ticket');
        }
      } else {
        ToastError('Error!');
      }
    },
    [actions, setIsForwardOpen]
  );

  return (
    <>
      <ForwardTicketView
        data={data}
        equipment={troubleEquipmentTypeState.list}
        workGroup={workGroupState.list}
        formRef={formRef}
        loading={status.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        isOpen={isForwardOpen}
        handleOpen={setIsForwardOpen}
      />
    </>
  );
};

export default ForwardTicket;
