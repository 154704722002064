import React, { useCallback } from 'react';
import {
  ATMTable,
  ATMButton,
  ATMField,
  ATMRadio,
  ATMInput,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useFieldArray } from 'react-hook-form';
import Lang from 'src/libraries/language';
import { IFacilityFormValidation } from 'src/models/facility.model';
import EmptyNotification from 'src/components/atoms/empty-notification/empty-notification.component';
import styles from './facility-notification-items.module.scss';

const NotificationTableSwitchTrs: React.FC = () => {
  const { control, watch } = useATMFormContext<IFacilityFormValidation>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'isoSwitchings',
  });

  const handleAddIso = useCallback(() => {
    append({ isoSwitchingId: '', defaultFlag: false });
  }, [append]);

  return (
    <div style={{ width: '100%' }}>
      {fields.length > 0 ? (
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell width={6}>
                {Lang.LBL_SWITCH_TR}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell textAlign="left" width={2}>
                {Lang.LBL_POSITION}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((item, i) => {
              return (
                <ATMTable.Row key={item.id}>
                  <ATMTable.Cell width={5}>
                    <ATMField
                      as={ATMInput}
                      name={`isoSwitchings[${i}].isoSwitchingId`}
                      control={control}
                      placeholder="Enter"
                      onChange={([_, { value }]) => value}
                    />
                  </ATMTable.Cell>
                  <ATMTable.Cell verticalAlign="middle">
                    <div className={styles.notificationToggle}>
                      <div className={styles.radioButton}>
                        <ATMField
                          name={`isoSwitchings[${i}].defaultFlag`}
                          as={ATMRadio}
                          defaultChecked={item.defaultFlag || false}
                          toggle
                          width={6}
                          control={control}
                          onChange={([_, { checked }]) => {
                            return checked;
                          }}
                        />
                        <div className={styles.labelCheck}>
                          {watch(`isoSwitchings[${i}].defaultFlag` as any)
                            ? 'Open'
                            : 'Close'}
                        </div>
                      </div>
                    </div>
                  </ATMTable.Cell>
                  <ATMTable.Cell textAlign="right">
                    <ATMButton
                      secondary
                      negative
                      icon="trash alternate outline"
                      type="button"
                      onClick={() => remove(i)}
                    />
                  </ATMTable.Cell>
                </ATMTable.Row>
              );
            })}
          </ATMTable.Body>
          <ATMTable.Footer>
            <ATMTable.Cell colSpan={3}>
              <ATMButton
                type="button"
                secondary
                className="borderless"
                icon="plus"
                content="Add More"
                onClick={handleAddIso}
              />
            </ATMTable.Cell>
          </ATMTable.Footer>
        </ATMTable>
      ) : (
        <EmptyNotification
          content={
            <ATMButton
              type="button"
              icon="plus"
              secondary
              content="Add"
              onClick={handleAddIso}
            />
          }
        />
      )}
    </div>
  );
};

export default NotificationTableSwitchTrs;
