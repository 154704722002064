import { substationCountListActionTypes, ISubstationCountListState } from 'src/ducks/substation-count-list.duck';
import { IStatus } from 'src/libraries/thunk.library';
import { ISubstationCountList, ISubstationCountListForm, ISubstationCountListFormArray } from 'src/models/substation-count-list.model';

export const getSubstationCountListStatus = (
  state: ISubstationCountListState,
  action: keyof typeof substationCountListActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getTransformedData = (
  data: ISubstationCountList[],
  yearSelected: string,
): ISubstationCountListFormArray => ({
  substationCounts: data.filter(({ year }) => year === yearSelected)[0]
    .substationCounts.map(({
      custCnt,
      substationLoad,
      substationId,
      year
    }) => ({
      custCnt,
      substationLoad,
      substationId,
      year
    })) as ISubstationCountListForm[]
});

export const getSubstations = (data: ISubstationCountList[]): string[] => (
  Array.from(new Set(([] as string[]).concat(
    ...data.map(({ substationCounts }) =>
      substationCounts.map(({ substationId, substation }) =>
        `${substationId} - ${substation?.name}`
      ))
  .filter((item) => item.length > 0))))
);

export const getSubstationCounts = (
  data: ISubstationCountList[],
  years: string[],
  substations: string[]
): Record<any, any>[] => {
  const subCounts = ([] as Record<any, any>[]).concat(
    ...data.map(({ substationCounts }) =>
      substationCounts.map((item) => item)
    ));
  const tableData = substations.map((item) => {
    const yearData = {};
    const subCountData = subCounts.filter(({ substationId, substation }) =>
      `${substationId} - ${substation?.name}` === item
    );
    years.forEach((year) => {
      subCountData.filter((sub) => sub.year === year)
        .forEach(({ custCnt, substationLoad }) => {
          yearData[year] = {
            custCnt,
            substationLoad: substationLoad ?? '--',
          };
        });
    });
    return {
      substation: item,
      ...yearData
    };
  });
  return tableData;
};
