import React from 'react';
import {
  IORGDataTableQueryState,
  IATMTabProps,
  ATMMenu,
  ATMTab,
  ATMGrid,
} from 'shared-it-appmod-ui';
import { ITrainers } from 'src/models/trainers.model';
import Lang from 'src/libraries/language';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import style from '../trainers.module.scss';
import TrainersListTable, {
  TrainerStatus,
} from '../trainers-table/trainers-table.component';
import TrainersAdd from '../trainers-add/trainers-add.component';

type IProps = {
  data: ITrainers[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (
    params: IORGDataTableQueryState,
    trainerStatus: string
  ) => void;
};

const TrainersListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  handleDownload,
}) => {
  const panes: IATMTabProps['panes'] = [
    {
      menuItem: <ATMMenu.Item key="0">{Lang.LBL_ACTIVE_TRAINER}</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <TrainersListTable
            status={TrainerStatus.ACTIVE}
            data={data}
            handleDownload={handleDownload}
            handleFetch={handleFetch}
            loading={loading}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">{Lang.LBL_INACTIVE_TRAINER}</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <TrainersListTable
            status={TrainerStatus.INACTIVE}
            data={data}
            handleDownload={handleDownload}
            handleFetch={handleFetch}
            loading={loading}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  return (
    <div className={style.mainContainerPosition}>
      <ATMGrid columns={2}>
        <ATMGrid.Column verticalAlign="middle">
          {/* <ATMHeader>Authorized List</ATMHeader> */}
        </ATMGrid.Column>
        <ATMGrid.Column textAlign="right">
          <TrainersAdd />
        </ATMGrid.Column>
      </ATMGrid>
      <div className={style.headerTabs}>
        <Tabs
          className={style.table}
          name="trainersStatus"
          menu={{ pointing: true }}
          panes={panes}
          activeIndex={0}
        />
      </div>
    </div>
  );
};

export default TrainersListView;
