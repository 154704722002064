/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMSegment,
  ATMGrid,
  ATMHeader,
  IATMTabProps,
  ATMMenu,
  ATMTab,
  ATMButton,
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
  ATMInput,
} from 'shared-it-appmod-ui';
import { Breadcrumb } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import { AccessRole } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import history from 'src/history';
import { getURLString } from 'src/helpers/location.helper';
import WorkGroupAdd from '../../work-group/work-group-add/work-group-add.component';
import WorkGroupList from '../../work-group/work-group-list/work-group-list.component';
import TroubleTicketAdd from '../trouble-ticket-add/trouble-ticket-add.component';
import styles from '../trouble-ticket.module.scss';
import TroubleTicketListAll from './trouble-ticket-list-all/trouble-ticket-all-list.component';
import TroubleTicketListTables from './trouble-ticket-list-tables.component';
import TroubleTicketAuditReportList from '../trouble-ticket-audit-report/trouble-ticket-audit-report-list.component';

type IProps = {
  data?: ITroubleTicketList[];
  scheduledListData?: ITroubleTicketList[];
  loading: boolean;
  handleFetch: (
    params: IORGDataTableQueryState,
    options: IORGDataTableUpdateOptions
  ) => void;
  total: number;
  troubleTotal: number;
  scheduledTotal: number;
  currentTab: number;
  completeWorkList: number[];
  setDateState?: React.Dispatch<any>;
  dateState?: any;
  counter: number;
  searchFilterHistory?: any;
};

const TroubleTicketListView: React.FC<IProps> = ({
  loading,
  handleFetch,
  total,
  troubleTotal,
  scheduledTotal,
  currentTab,
  completeWorkList,
  counter,
  searchFilterHistory,
}) => {
  const [view, setView] = useState('');
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dataEdit, setDataEdit] = useState<ITroubleTicketList>();
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [troubleTicketId, setTroubleTicketId] = useState<any>(undefined);
  const { state: troubleTicketState, actions } = useTroubleTicketContext();
  const [lineSubstation, setIsLineSubstation] = useState<string | undefined>();

  const handleTicketSearch = (ticketId: any) => {
    history.push(`/trouble-ticket/${ticketId}`);
  };

  useEffect(() => {
    setDataEdit(troubleTicketState?.data as any);
  }, [dataEdit, troubleTicketState, setDataEdit]);

  const clearSearchHistory = useCallback(() => {
    actions?.listCLEAR();
  }, [actions, troubleTicketState]);

  const sections =
    view === 'manageWorkgroups'
      ? [
          {
            key: 'Requests',
            content: 'Requests',
            link: true,
            onClick: () => setView(''),
          },
          { key: '', content: '' },
        ]
      : [
          {
            key: 'All Ticket Requests',
            content: 'All Ticket Requests',
            link: true,
            onClick: () => {
              setView('');
              setTroubleTicketId(undefined);
            },
          },
          { key: '', content: '' },
        ];

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: <ATMMenu.Item key="0">Trouble Tickets</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <TroubleTicketListTables
            data={troubleTicketState.list}
            loading={loading}
            handleFetch={handleFetch}
            setDataEdit={setDataEdit}
            setView={setView}
            total={total}
            isEditOpen={isEditOpen}
            setIsEditOpen={setIsEditOpen}
            troubleTotal={troubleTotal}
            scheduledTotal={scheduledTotal}
            currentTab={currentTab}
            counter={counter}
            lineSubstation={lineSubstation}
            setIsLineSubstation={setIsLineSubstation}
            searchFilterHistory={searchFilterHistory}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: <ATMMenu.Item key="1">Scheduled Tickets</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <TroubleTicketListTables
            data={troubleTicketState.listScheduled}
            loading={loading}
            handleFetch={handleFetch}
            setDataEdit={setDataEdit}
            setView={setView}
            total={total}
            isEditOpen={isEditOpen}
            setIsEditOpen={setIsEditOpen}
            troubleTotal={troubleTotal}
            scheduledTotal={scheduledTotal}
            currentTab={currentTab}
            counter={counter}
            lineSubstation={lineSubstation}
            setIsLineSubstation={setIsLineSubstation}
            searchFilterHistory={searchFilterHistory}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: <ATMMenu.Item key="2">Search</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <TroubleTicketListAll
            loading={loading}
            handleFetch={handleFetch}
            setDataEdit={setDataEdit}
            setView={setView}
            setIsFirstLoad={setIsFirstLoad}
            total={total}
            troubleTotal={troubleTotal}
            scheduledTotal={scheduledTotal}
            currentTab={currentTab}
            completeWorkList={completeWorkList}
            isFirstLoad={isFirstLoad}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  return (
    <div className="admin-container">
      <div className="admin-list-container">
        {(() => {
          switch (view) {
            case 'createTicket':
              return (
                <ATMGrid.Row columns="equal" style={{ margin: '1rem 0' }}>
                  <div className={styles.breadCrumbStyle}>
                    <Breadcrumb
                      icon="right angle"
                      size="tiny"
                      sections={sections}
                    />
                  </div>
                  <TroubleTicketAdd setView={setView} view={view} />
                </ATMGrid.Row>
              );

            case 'manageWorkgroups':
              return (
                <div style={{ margin: '1rem' }}>
                  <div className={styles.breadCrumbStyle}>
                    <Breadcrumb
                      icon="right angle"
                      size="tiny"
                      sections={sections}
                    />
                  </div>
                  <ATMSegment className="admin-list-container">
                    <ATMGrid columns={2}>
                      <ATMGrid.Column verticalAlign="middle">
                        <ATMHeader>{Lang.TTL_WORK_GROUP}</ATMHeader>
                      </ATMGrid.Column>
                      <ATMGrid.Column
                        textAlign="right"
                        className={styles.wrkGrpBtn}
                      >
                        <ATMButton
                          content={Lang.LBL_GO_BACK}
                          secondary
                          onClick={() => setView('')}
                          icon="arrow left"
                          size="small"
                        />
                        {getIsAdmin(AccessRole.TROUBLE_TICKET_REQUESTOR) && (
                          <WorkGroupAdd />
                        )}
                      </ATMGrid.Column>
                    </ATMGrid>
                    <WorkGroupList />
                  </ATMSegment>
                </div>
              );
            case 'auditReport':
              return (
                <div style={{ margin: '1rem' }}>
                  <div className={styles.breadCrumbStyle}>
                    <Breadcrumb
                      icon="right angle"
                      size="tiny"
                      sections={sections}
                    />
                  </div>
                  <ATMSegment className="admin-list-container">
                    <ATMGrid columns={2}>
                      <ATMGrid.Column verticalAlign="middle">
                        <ATMHeader>
                          {Lang.TTL_TROUBLE_TICKET_AUDIT_REPORT}
                        </ATMHeader>
                      </ATMGrid.Column>
                      <ATMGrid.Column
                        textAlign="right"
                        className={styles.wrkGrpBtn}
                      >
                        <ATMButton
                          content={Lang.LBL_GO_BACK}
                          secondary
                          onClick={() => setView('')}
                          icon="arrow left"
                          size="small"
                        />
                      </ATMGrid.Column>
                    </ATMGrid>

                    <TroubleTicketAuditReportList />
                  </ATMSegment>
                </div>
              );
            default:
              return (
                <>
                  <ATMGrid columns={2} style={{ marginBottom: '.5em' }}>
                    <ATMGrid.Column style={{ display: 'flex' }}>
                      {/* verticalAlign="middle"> */}
                      <span
                        style={{
                          width: '50%',
                        }}
                      >
                        <ATMHeader>{Lang.LBL_TROUBLE_TICKET}</ATMHeader>
                      </span>
                      <span
                        style={{
                          width: '60%',
                          marginLeft: '20%',
                        }}
                      >
                        <div className={styles.troubleTicketSearch}>
                          <ATMInput
                            name="troubleTicketId"
                            placeholder="Ticket ID (eg. 12314)"
                            value={troubleTicketId}
                            icon={troubleTicketId ? 'close' : 'search'}
                            fluid
                            onlyNumber
                            action={{
                              onClick:
                                troubleTicketId && troubleTicketId.trim().length
                                  ? () => setTroubleTicketId('')
                                  : undefined,
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode === 13) {
                                if (e.target.value) {
                                  handleTicketSearch(
                                    parseInt(e.target.value, 10)
                                  );
                                }
                              }
                            }}
                            onChange={(e) => {
                              setTroubleTicketId(e.target.value);
                              if (e.target.value === '' || null) {
                                setTroubleTicketId(undefined);
                              }
                            }}
                          />
                        </div>
                      </span>
                    </ATMGrid.Column>

                    <ATMGrid.Column
                      textAlign="right"
                      className={styles.listBtn}
                    >
                      <span
                        style={{
                          width: '100%',
                          marginLeft: '1em',
                        }}
                      >
                        {getIsAdmin(AccessRole.TROUBLE_TICKET_REQUESTOR) && (
                          <ATMButton
                            primary
                            size="small"
                            icon="plus"
                            floated="right"
                            content={Lang.TTL_ADD_TICKET}
                            onClick={() => setView('createTicket')}
                          />
                        )}
                      </span>
                      {getIsAdmin(AccessRole.TROUBLE_TICKET_RESOLVER) ||
                      getIsAdmin(AccessRole.TROUBLE_TICKET_REQUESTOR) ? (
                        <>
                          <span
                            style={{
                              width: '100%',
                              marginLeft: '.5em',
                            }}
                          >
                            <ATMButton
                              content={Lang.LBL_TROUBLE_TICKET_AUDIT_REPORT}
                              primary
                              onClick={() => setView('auditReport')}
                              floated="right"
                            />
                          </span>
                          <span
                            style={{
                              width: '100%',
                              marginLeft: '.5em',
                            }}
                          >
                            <ATMButton
                              content={Lang.LBL_WORK_GROUP_MANAGE}
                              secondary
                              onClick={() => setView('manageWorkgroups')}
                              floated="right"
                            />
                          </span>
                        </>
                      ) : null}
                    </ATMGrid.Column>
                  </ATMGrid>
                  <div className={styles.headerTabs}>
                    <Tabs
                      name="troubleTicketStatus"
                      menu={{ pointing: true }}
                      panes={panes}
                      isClean
                      renderActiveOnly
                      onTabChange={(_, { activeIndex }) => {
                        clearSearchHistory();
                        if (activeIndex === 0 || activeIndex === 1) {
                          setIsFirstLoad(false);
                          setIsLineSubstation(undefined);
                        }

                        if (
                          [0, 1].includes(activeIndex as number) &&
                          searchFilterHistory
                        ) {
                          const path = getURLString(history.location, {
                            ...searchFilterHistory,
                            troubleTicketStatus: activeIndex,
                          });
                          history.replace(path);
                        }

                        if (activeIndex === 2) {
                          setIsFirstLoad(true);
                          history.replace(
                            `/trouble-ticket?troubleTicketStatus=${activeIndex}`
                          );
                        }
                      }}
                    />
                  </div>
                </>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default TroubleTicketListView;
