import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/cancel-type.service';
import { ICancelType } from 'src/models/cancel-type.model';

export const cancelTypeActionTypes = {
  CANCEL_TYPE_LIST_READ: 'CANCEL_TYPE_LIST_READ',
} as const;

export const duckActions = {
  listGET: {
    type: cancelTypeActionTypes.CANCEL_TYPE_LIST_READ,
    service: services.listGET,
  },
};

export type ICancelTypeAsync = typeof duckActions;

export interface ICancelTypeState
  extends ICommonState<typeof cancelTypeActionTypes> {
  data?: ICancelType;
  list: ICancelType[];
  total: number;
}

export const defaultState: ICancelTypeState = {
  status: {},
  list: [],
  total: 0,
};

export enum CancelTypeList {
  CANCELLED_BY_CREW = 'Cancelled by crew',
  CANCELLED_BY_REQUESTOR = 'Cancelled by requestor',
  CANCELLED_BY_OSS = 'Cancelled by OSS',
  CANCELLED_BY_GOS_STUDIES = 'Cancelled by GOS Studies',
}

const CancelTypeReducer = (
  state: ICancelTypeState,
  action: IReducerAction<ICancelTypeAsync>
): ICancelTypeState => {
  switch (action.type) {
    case cancelTypeActionTypes.CANCEL_TYPE_LIST_READ: {
      return {
        ...state,
        list:
          action.payload?.rows?.filter((val) => {
            const cancelList = Object.values(CancelTypeList);
            return cancelList
              .toString()
              .toUpperCase()
              .includes(val?.cancelTypeDesc?.toUpperCase());
          }) ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default CancelTypeReducer;
