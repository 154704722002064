import React, { useCallback, useRef, useState } from 'react';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { tsoOssEmployeeActionTypes } from 'src/ducks/tso-oss-employee.duck';
import { ITsoOssEmployeeForm } from 'src/models/tso-oss-employee.model';
import { getTsoOssEmployeeStatus } from 'src/selectors/tso-oss-employee.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import TsoOssEmployeeAddView from './tso-oss-employee-add.view';

const TsoOssEmployeeAdd: React.FC = () => {
  const { state, actions } = useTsoOssEmployeeContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getTsoOssEmployeeStatus(
    state,
    tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITsoOssEmployeeForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsAddOpen(false);

        if (response.payload?.emp) {
          ToastSuccess(
            `User ${response.payload?.emp?.lastName}, ${response.payload?.emp?.firstName}/${response.payload?.emp.empId} has been added to the TSO/OSS list`
          );
        } else {
          ToastSuccess(
            `User ${response.payload?.empId} has been added to the TSO/OSS list`
          );
        }
      } else {
        ToastError(response.error?.message);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <TsoOssEmployeeAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default TsoOssEmployeeAdd;
