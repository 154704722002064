import {
  switchingActionTypes,
  ISwitchingState,
} from 'src/ducks/switching.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getSwitchingStatus = (
  state: ISwitchingState,
  action: keyof typeof switchingActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
