import React, { useCallback } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useIssuedKeyContext } from 'src/contexts/issued-key.context';
import { issuedKeyActionTypes } from 'src/ducks/issued-key.duck';
import {
  convertKeyNumberToFiveDigits,
  formatEmployeeName,
} from 'src/helpers/key-request.helper';
import useStatusTab from 'src/hooks/status-tab.hook';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import {
  getIssuedKeyParams,
  getIssuedKeyStatus,
} from 'src/selectors/issued-key.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import IssuedKeyListView from './issued-key-list.view';

const IssuedKeyList: React.FC = () => {
  const { state, actions } = useIssuedKeyContext();
  const { currentTab } = useStatusTab('issueStatus');
  const status = getIssuedKeyStatus(
    state,
    issuedKeyActionTypes.ISSUED_KEY_LIST_READ
  );

  const downloadStatus = getIssuedKeyStatus(
    state,
    issuedKeyActionTypes.ISSUED_KEY_LIST_ALL_READ
  );

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      const data = getIssuedKeyParams(params, currentTab);
      actions?.listGET(data);
    },
    [actions, currentTab]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const data = getIssuedKeyParams(params, currentTab);
      const result = await actions.listAllGET(data);
      if (result.payload?.rows) {
        const list = result.payload.rows;
        const items = list.map((value) => [
          checkValue(convertKeyNumberToFiveDigits(value.keyNbr)),
          checkValue(value.stat),
          // to be modified when employee details is integrated
          checkValue(value.keyDetail?.emp?.title), // Key Holder Type
          checkValue(
            value.keyDetail && value.keyDetail.emp
              ? `${formatEmployeeName(
                  `${value.keyDetail.emp?.lastName}`,
                  `${value.keyDetail.emp?.firstName}`,
                  '',
                  `${value.keyDetail.emp?.empId}`,
                  false,
                  Lang.LBL_UNAVAILABLE
                )}`
              : Lang.LBL_UNAVAILABLE
          ),
          checkValue(
            value.keyDetail && value.keyDetail.emp && value.keyDetail.emp?.supvr
              ? `${formatEmployeeName(
                  `${value.keyDetail.emp?.supvr?.lastName}`,
                  `${value.keyDetail.emp?.supvr?.firstName}`,
                  '',
                  `${value.keyDetail.emp?.supvr?.empId}`,
                  false,
                  Lang.LBL_UNAVAILABLE
                )}`
              : Lang.LBL_UNAVAILABLE
          ),
          checkValue(value.keyDetail ? formatDate(value.keyDetail.issDt) : '-'),
          checkValue(
            value.keyDetail ? formatDate(value.keyDetail.returnDt) : '-'
          ),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_ISSUED_KEY,
            Lang.LBL_ISSUED_KEY_STATUS,
            Lang.LBL_ISSUED_KEY_HOLDER_TYPE,
            Lang.LBL_KEY_ISSUED_TO,
            Lang.LBL_ISSUED_KEY_MANAGER,
            Lang.LBL_STATUS_KEY_DATE,
            Lang.LBL_ISSUED_KEY_END_DATE,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Issued_Keys_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  return (
    <IssuedKeyListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
      total={state.total}
      activeTotal={state.activeTotal}
      lostTotal={state.lostTotal}
      damagedTotal={state.damagedTotal}
      notReturnedTotal={state.notReturnedTotal}
      pendingTotal={state.pendingTotal}
      returnedTotal={state.returnedTotal}
      currentTab={currentTab}
      downloadLoading={downloadStatus.fetching}
    />
  );
};

export default IssuedKeyList;
