import React, { useCallback, useRef, useState } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMModal,
  ATMRadio,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { YesNo } from 'src/constants';
import { useOcPendingActionReportContext } from 'src/contexts/oc-pending-action-report.context';
import { ocPendingActionReportActionTypes } from 'src/ducks/oc-pending-action-report.duck';
import Lang from 'src/libraries/language';
import {
  IOcPendingActionReport,
  IOcPendingActionReportForm,
  OcPendingActionReportFormSchema,
} from 'src/models/oc-pending-action-report.model';
import { getOcPendingActionReportStatus } from 'src/selectors/oc-pending-action-report.selector';
import style from '../oc-pending-action-report.module.scss';

type IProps = {
  data: IOcPendingActionReport;
  queryParams: Record<any, any>;
  pagination: Record<any, any>;
};

const OcPendingActionReportEdit: React.FC<IProps> = ({
  data,
  queryParams,
  pagination,
}) => {
  const completed = data.completed === YesNo.YES;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCompleteFlag, setIsCompleteFlag] = useState(completed);
  const { state, actions } = useOcPendingActionReportContext();
  const status = getOcPendingActionReportStatus(
    state,
    ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_DATA_UPDATE
  );
  const formRef = useRef<HTMLFormElement>(null);
  const defaultValues: IOcPendingActionReportForm = {
    notes: data.dailyOutageReportNotes ?? '',
    isComplete: completed,
  };

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IOcPendingActionReportForm) => {
      const putResponse = await actions.updatePUT(data.notesId, {
        ...formData,
      });

      if (!putResponse.error && putResponse.payload) {
        setIsEditOpen(false);

        let toastMessage = '';

        if (putResponse.payload.ocPendingActionReport.completed === YesNo.YES) {
          toastMessage = `Notes Record successfully completed for LER ${data?.requestId}`;
        } else {
          toastMessage = `Notes Record successfully modified for LER ${data?.requestId}`;
        }

        ToastSuccess(toastMessage);

        const getResponse = await actions.listGET({
          ...pagination,
          ...queryParams,
        });
        if (
          getResponse.payload &&
          getResponse.payload.count > 0 &&
          getResponse.payload.rows?.length === 0
        ) {
          actions.listGET({
            ...pagination,
            ...queryParams,
            page: pagination.page - 1,
          });
        }
      } else {
        ToastError(`Error in updating Notes Record for LER ${data?.requestId}`);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <ATMModal
      open={isEditOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          content={Lang.LBL_EDIT_NOTE}
          icon="edit"
          onClick={() => setIsEditOpen(!isEditOpen)}
        />
      }
      header={Lang.formatString(
        Lang.TTL_EDIT_OUTAGE_REPORT_NOTE,
        String(data.requestId)
      )}
      content={
        <div className={style.editContent}>
          <ATMForm
            key={data.notesId}
            ref={formRef}
            mode="onChange"
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            validationSchema={OcPendingActionReportFormSchema}
          >
            {({ control, formState: { errors }, setValue }) => {
              return (
                <ATMGrid>
                  <ATMGrid.Row>
                    <ATMGrid.Column>
                      <ATMField
                        rows={6}
                        as={ATMTextArea}
                        control={control}
                        name="notes"
                        error={errors.notes}
                        onChange={([_, { value }]) => value}
                      />
                      <ATMField
                        as={ATMInput}
                        className="hidden"
                        type="hidden"
                        name="isComplete"
                        control={control}
                        error={errors.isComplete}
                      />
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row columns={5}>
                    <ATMGrid.Column>
                      <ATMRadio
                        label={Lang.LBL_COMPLETE}
                        value="true"
                        name="Yes"
                        checked={isCompleteFlag === true}
                        onClick={(_, { checked }) => {
                          setIsCompleteFlag(true);
                          setValue('isComplete', true);
                          return checked;
                        }}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column>
                      <ATMRadio
                        label={Lang.LBL_INCOMPLETE}
                        value="false"
                        name="No"
                        checked={isCompleteFlag === false}
                        onClick={(_, { checked }) => {
                          setIsCompleteFlag(false);
                          setValue('isComplete', false);
                          return checked;
                        }}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column />
                    <ATMGrid.Column />
                    <ATMGrid.Column />
                  </ATMGrid.Row>
                </ATMGrid>
              );
            }}
          </ATMForm>
        </div>
      }
      actions={
        <div className={style.actionBtns}>
          <ATMButton
            basic
            color="blue"
            content={Lang.LBL_CANCEL}
            onClick={() => {
              setIsCompleteFlag(completed);
              setIsEditOpen(false);
            }}
          />
          <ATMButton
            primary
            content={Lang.LBL_SAVE}
            onClick={() => {
              if (!status.fetching) {
                handleClick();
              }
            }}
            loading={status.fetching}
          />
        </div>
      }
    />
  );
};

export default OcPendingActionReportEdit;
