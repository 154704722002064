import {
  userGroupActionTypes,
  IUserGroupState,
} from 'src/ducks/user-group.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getUserGroupStatus = (
  state: IUserGroupState,
  action: keyof typeof userGroupActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
