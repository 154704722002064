import React from 'react';
import { ATMForm, ATMFormProvider } from 'shared-it-appmod-ui';
import {
  ISubstationCountListFormArray,
  SubstationCountListFormArraySchema,
} from 'src/models/substation-count-list.model';
import SubstationCountFormView from './substation-count-form.view';

type IProps = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues: ISubstationCountListFormArray;
  handleSubmit: (data: ISubstationCountListFormArray) => void;
  handleEnable: (value: boolean) => void;
  year: number;
  substations?: string[];
};

const SubstationCountForm: React.FC<IProps> = ({
  formRef,
  defaultValues,
  handleEnable,
  handleSubmit,
  substations = [],
}) => {
  return (
    <ATMForm
      ref={formRef}
      defaultValues={defaultValues}
      validationSchema={SubstationCountListFormArraySchema}
      onSubmit={handleSubmit}
      mode="onChange"
    >
      {(formProps) => {
        const {
          formState: { isDirty },
        } = formProps;

        handleEnable(isDirty);

        return (
          <ATMFormProvider {...formProps}>
            <SubstationCountFormView
              defaultValues={defaultValues}
              substations={substations}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default SubstationCountForm;
