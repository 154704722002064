import { NameFlag } from 'src/constants';
import yup from 'src/libraries/validator.library';
import {
  AdjustedOperationalRatingShape,
  EquipmentShape,
  NameShape,
  OperatingParticipantShape,
  OutageCauseShape,
  OutagedEquipmentShape,
  PeriodShape,
  SwitchingOperationShape,
  SwitchShape,
  WorkShape,
} from './ler-request.model';

export const OmsRequestShape = {
  id: yup.number().required(),
  kv: yup.number().required(),
  name: yup.string().required(),
  startTime: yup.date(),
  stopTime: yup.date(),
  LERstatus: yup.string().required(),
  OMSstatus: yup.string().required(),
  outageId: yup.number().required(),
  FARFAN: yup.string().required(),
  recStatus: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};

export const LerOmsDataShape = {
  planDateTime: yup.date(),
  transRegistryId: yup.string(),
  work: yup.string(),
  erReturn: yup.date(),
  description: yup.string(),
  notes: yup.string(),
  facModel: yup.boolean(),
  outOfService: yup.boolean(),
  redundancy: yup.boolean(),
  protectionZone: yup.boolean(),
  startTime: yup.date(),
  stopTime: yup.date(),
};

export const LerOmsComparisonShape = {
  transmissionRegistryIds: yup.array().of(yup.string().defined()).defined(),
  natureOfWork: yup.string().nullable().defined(),
  emergencyReturnTime: yup.number().nullable().defined(),
  emergencyReturnTimeType: yup.string().nullable().defined(),
  shortDescription: yup.string().nullable().defined(),
  notes: yup.string().nullable().defined(),
  rasspsOutOfService: yup.boolean().nullable().defined(),
  rasspsReducedRedundancy: yup.boolean().nullable().defined(),
  protectionZone: yup.boolean().nullable().defined(),
  emsIccp: yup.boolean().nullable().defined(),
  startTm: yup.date().nullable().defined(),
  stopTm: yup.date().nullable().defined(),
  useFacilityModel: yup.array().of(yup.boolean().defined()).defined(),
};

export const LerComparatorShape = {
  ...LerOmsComparisonShape,
};

export const OmsComparatorShape = {
  ...LerOmsComparisonShape,
};

export const OmsDataShape = {
  lerId: yup.number().defined(),
  lerStatus: yup.string().nullable().defined(),
  kv: yup.string().nullable().defined(),
  facilityName: yup.string().defined(),
  startTm: yup.date().nullable().defined(),
  stopTm: yup.date().nullable().defined(),
  omsStatus: yup.string().nullable().defined(),
  omsId: yup.string().nullable().defined(),
  farFan: yup.string().nullable().defined(),
  reconcileStatus: yup
    .object({
      isLerOmsMismatch: yup.boolean().defined(),
      isChangeRequestPending: yup.boolean().defined(),
      isOutageWarning: yup.boolean().defined(),
    })
    .defined(),
  allowedActions: yup
    .object({
      send: yup.boolean().required(),
      cancel: yup.boolean().required(),
      resubmit: yup.boolean().required(),
    })
    .defined(),
  outageWarnings: yup
    .array()
    .of(
      yup
        .object({
          mRID: yup.string(),
          description: yup.string(),
          type: yup.string(),
        })
        .defined()
    )
    .defined(),
  matchResult: yup
    .object({
      allMatched: yup.boolean().defined(),
      transmissionRegistryIdsMatched: yup.boolean().defined(),
      natureOfWorkMatched: yup.boolean().defined(),
      emergencyReturnTimeMatched: yup.boolean().defined(),
      emergencyReturnTimeTypeMatched: yup.boolean().defined(),
      shortDescriptionMatched: yup.boolean().defined(),
      notesMatched: yup.boolean().defined(),
      rasspsOutOfServiceMatched: yup.boolean().defined(),
      rasspsReducedRedundancyMatched: yup.boolean().defined(),
      protectionZoneMatched: yup.boolean().defined(),
      emsIccpMatched: yup.boolean().defined(),
      startTmMatched: yup.boolean().defined(),
      stopTmMatched: yup.boolean().defined(),
    })
    .nullable()
    .defined(),
  lerComparator: yup.object(LerComparatorShape).defined(),
  omsComparator: yup.object(OmsComparatorShape).defined(),
};

export const OmsRequestSchema = yup.object(OmsRequestShape);
export const LerOmsDataSchema = yup.object(LerOmsDataShape);
export const OmsDataSchema = yup.object(OmsDataShape);

export const OmsSubmitRequestSchema = yup.object({
  id: yup.number().required(),
  startTm: yup.date(),
  stopTm: yup.date(),
});

export const OmsRequestSubmitRefreshSchema = yup.object({
  cancellationOmsIds: yup.array().of(yup.number()),
  submissionLerIds: yup.array().of(OmsSubmitRequestSchema.clone()),
  withdrawalOmsIds: yup.array().of(yup.number()).nullable().default(null),
});

const OmsRequestSubmitRefreshResponseShape = {
  Service: yup.object({
    id: yup.string(),
    name: yup.string(),
  }),
  Outage: yup.object({
    OutageValidation: yup.object({
      OutageResult: yup.array().of(
        yup.object({
          mRID: yup.string(),
          description: yup.string(),
          type: yup.string(),
        })
      ),
      submitStatus: yup.string(),
    }),
    mktOrgOutageID: yup.string(),
    mRID: yup.string(),
  }),
  Event: yup.object({
    creationDateTime: yup.date(),
    id: yup.string(),
    result: yup.string(),
  }),
};

export const OmsRequestSubmitRefreshResponseSchema = yup.object(
  OmsRequestSubmitRefreshResponseShape
);

export const OmsRequestSubmitRefreshResponsePayloadSchema = yup
  .array()
  .of(OmsRequestSubmitRefreshResponseSchema.clone());

export const OmsListPageSchema = yup.object({
  ...OmsDataShape,
  caisoResponse: OmsRequestSubmitRefreshResponsePayloadSchema.clone(),
});

export const TransmissionOutageSubmitShape = {
  description: yup.string().defined(),
  comment: yup.string().defined(),
  discoveryDateTime: yup.date(),
  emergencyReturnTime: yup.number().defined(),
  emergencyReturnTimeType: yup.string().defined(),
  mktOrgOutageID: yup.string().defined(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectID: yup.string(),
  RIMSProjectPhase: yup.string(),
  Equipments: yup.array().of(yup.object(EquipmentShape).defined()).defined(),
  estimatedPeriod: yup.object(PeriodShape).defined(),
  OperatingParticipant: yup.object(OperatingParticipantShape).defined(),
  OutageCause: yup.array().of(yup.object(OutageCauseShape).defined()),
  Work: yup.object(WorkShape).defined(),
};

export const OmsRequestDataShape = {
  ...TransmissionOutageSubmitShape,
  createdBy: yup.string().defined(),
  createdDateTime: yup.date().defined(),
  lastModifiedDateTime: yup.date().defined(),
  lastUpdatedBy: yup.string().defined(),
  affectsRASSPS: yup.string(),
  confirmedFlagBATOP: yup.boolean().defined(),
  emergencyFlag: yup.boolean().defined(),
  isFinalApprovalReq: yup.string().defined(),
  operationalFlag: yup.boolean().defined(),
  outageCoordinationLongTermPlanning: yup.string().defined(),
  outageStatus: yup.string().defined(),
  outageStatusReason: yup.string().defined(),
  outageType: yup.string().defined(),
  RCOutageType: yup.string(),
  RCPriorityDate: yup.date(),
  RIMSProjectCommissionDate: yup.date(),
  RIMSProjectCompletion: yup.string().defined(),
  versionID: yup.number().defined(),
  actualPeriod: yup.object({
    ...PeriodShape,
    start: yup.date(),
    end: yup.date(),
  }),
  Equipments: yup
    .array()
    .of(
      yup
        .object({
          ...EquipmentShape,
          mRID: yup.string().defined(),
          name: yup.string().defined(),
          PSRType: yup
            .object({
              type: yup.string().defined(),
            })
            .defined(),
          OutagedEquipment: yup
            .object({
              ...OutagedEquipmentShape,
              Switch: yup
                .array()
                .of(
                  yup.object({
                    ...SwitchShape,
                    SwitchingOperations: yup
                      .array()
                      .of(yup.object(SwitchingOperationShape).defined())
                      .ensure(),
                    mRID: yup.string().defined(),
                    name: yup.string().defined(),
                  })
                )
                .ensure(),
            })
            .defined(),
          AdjustedOperationalRating: yup
            .array()
            .of(yup.object(AdjustedOperationalRatingShape).defined())
            .ensure(),
          VoltageLevel: yup
            .object({
              BaseVoltage: yup
                .object({
                  nominalVoltage: yup
                    .object({
                      multiplier: yup.string().defined(),
                      unit: yup.string().defined(),
                      value: yup.number().defined(),
                    })
                    .defined(),
                })
                .defined(),
            })
            .defined(),
        })
        .defined()
    )
    .ensure()
    .defined(),
  mRID: yup.string().defined(),
  OperatingParticipant: yup
    .object({
      ...OperatingParticipantShape,
      Person: yup
        .array()
        .of(
          yup.object({
            firstName: yup.string(),
            lastName: yup.string(),
            landlinePhone: yup.object({
              areaCode: yup.string(),
              localNumber: yup.string(),
            }),
          })
        )
        .ensure(),
    })
    .defined(),
  OutageChangeRequest: yup
    .object({
      mRID: yup.string().defined(),
      action: yup.string().defined(),
      actionReason: yup.string(),
      actionSubType: yup.string(),
      status: yup.string(),
      statusReason: yup.string(),
    })
    .defined(),
  OutageValidation: yup.object({
    OutageResult: yup
      .array(
        yup.object({
          mRID: yup.string(),
          description: yup.string(),
          type: yup.string(),
        })
      )
      .ensure(),
  }),
  congestionRevenueRightsDesignation: yup.string().defined(),
  isNewEquipmentEnergized: yup.string().defined(),
  trumpFlag: yup.string(),
};

export const TransmissionOutageChangeEditableShape = {
  mktOrgOutageID: yup.string().defined(),
  description: yup.string().defined(),
  comment: yup.string(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectID: yup.string(),
  RIMSProjectPhase: yup.string(),
  Work: yup.object(WorkShape).defined(),
  OutageCause: yup.array().of(yup.object(OutageCauseShape)),
  estimatedPeriod: yup.object(PeriodShape).defined(),
  emergencyReturnTime: yup.number(),
  emergencyReturnTimeType: yup.string(),
};

export const TransmissionOutageChangeShape = {
  mRID: yup.string().defined(),
  description: yup.string().defined(),
  comment: yup.string().defined(),
  confirmedFlagBATOP: yup.boolean(),
  emergencyFlag: yup.boolean(),
  emergencyReturnTime: yup.number().defined(),
  emergencyReturnTimeType: yup.string().defined(),
  mktOrgOutageID: yup.string().defined(),
  operationalFlag: yup.boolean().defined(),
  outageCoordinationLongTermPlanning: yup.string().defined(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectCommissionDate: yup.date(),
  RIMSProjectCompletion: yup.string(),
  RIMSProjectID: yup.string(),
  RIMSProjectPhase: yup.string(),
  actualPeriod: yup.object({
    end: yup.date(),
    start: yup.date(),
  }),
  Equipments: yup
    .array()
    .of(
      yup.object({
        mRID: yup.string(),
        Names: yup
          .array()
          .of(yup.object(NameShape).defined())
          .defined()
          .compact((item) => {
            return item.NameType.name === NameFlag.PARTICIPANTNAME;
          }),
        AdjustedOperationalRating: yup.array(
          yup.object(AdjustedOperationalRatingShape)
        ),
        OutagedEquipment: yup
          .object({
            ...OutagedEquipmentShape,
            Switch: yup.array().of(
              yup
                .object({
                  ...SwitchShape,
                  Names: yup
                    .array()
                    .of(yup.object(NameShape).defined())
                    .defined()
                    .compact((item) => {
                      return item.NameType.name === NameFlag.PARTICIPANTNAME;
                    }),
                })
                .defined()
            ),
          })
          .defined(),
        VoltageLevel: yup.object({
          BaseVoltage: yup.object({
            nominalVoltage: yup.object({
              multiplier: yup.string(),
              unit: yup.string(),
              value: yup.number(),
            }),
          }),
        }),
      })
    )
    .defined(),
  estimatedPeriod: yup.object(PeriodShape).defined(),
  OperatingParticipant: yup.object(OperatingParticipantShape).defined(),
  OutageCause: yup
    .array()
    .of(yup.object(OutageCauseShape).defined())
    .nullable(),
  OutageChangeRequest: yup
    .object({
      action: yup.string().defined(),
      actionSubType: yup.string(),
    })
    .defined(),
  Work: yup.object(WorkShape).defined(),
};

export const TransmissionOutageChangeFormShape = {
  mRID: yup.string().defined(),
  description: yup.string().defined(),
  comment: yup.string().defined(),
  confirmedFlagBATOP: yup.boolean(),
  emergencyFlag: yup.boolean(),
  emergencyReturnTime: yup.number().typeError('Must be a number').defined(),
  emergencyReturnTimeType: yup.string().defined(),
  mktOrgOutageID: yup.string().required('Participant Outage ID is required'),
  operationalFlag: yup.boolean().defined(),
  outageCoordinationLongTermPlanning: yup.string().defined(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectCommissionDate: yup.date(),
  RIMSProjectCompletion: yup.string(),
  RIMSProjectID: yup.string().defined(),
  RIMSProjectPhase: yup.string().defined(),
  actualPeriod: yup.object({
    end: yup.date(),
    start: yup.date(),
  }),
  Equipments: yup
    .array()
    .of(
      yup.object({
        mRID: yup.string().defined(),
        Names: yup
          .array()
          .of(yup.object(NameShape).defined())
          .defined()
          .compact((item) => {
            return item.NameType.name === NameFlag.PARTICIPANTNAME;
          }),
        AdjustedOperationalRating: yup.array(
          yup.object(AdjustedOperationalRatingShape)
        ),
        OutagedEquipment: yup
          .object({
            ...OutagedEquipmentShape,
            Switch: yup.array().of(
              yup
                .object({
                  ...SwitchShape,
                  Names: yup
                    .array()
                    .of(yup.object(NameShape).defined())
                    .defined()
                    .compact((item) => {
                      return item.NameType.name === NameFlag.PARTICIPANTNAME;
                    }),
                })
                .defined()
            ),
          })
          .defined(),
        VoltageLevel: yup.object({
          BaseVoltage: yup.object({
            nominalVoltage: yup.object({
              multiplier: yup.string(),
              unit: yup.string(),
              value: yup.number(),
            }),
          }),
        }),
      })
    )
    .defined(),
  estimatedPeriod: yup
    .object({
      ...PeriodShape,
      startTime: yup
        .string()
        .matches(
          /(2[0-3]|[01]?[0-9]):([0-5]?[0-9])/,
          'Invalid Time. Format: hh:mm'
        )
        .optional(),
      endTime: yup
        .string()
        .matches(
          /(2[0-3]|[01]?[0-9]):([0-5]?[0-9])/,
          'Invalid Time. Format: hh:mm'
        )
        .optional(),
    })
    .defined(),
  OperatingParticipant: yup.object(OperatingParticipantShape).defined(),
  OutageCause: yup.array().of(yup.object(OutageCauseShape)),
  OutageChangeRequest: yup
    .object({
      action: yup.string().defined(),
      actionSubType: yup.string(),
    })
    .defined(),
  Work: yup.object(WorkShape).defined(),
};

export const OmsRequestDataSchema = yup.object(OmsRequestDataShape);

export const OmsRequestFormSchema = yup.object(
  TransmissionOutageChangeFormShape
);

export const OmsRequestListPayloadSchema = yup.object({
  data: yup.array().of(OmsDataSchema.clone()),
  error: yup.string().nullable(),
});

export const OmsRequestPayloadSchema = yup.object({
  OutageResult: yup.array().of(
    yup.object({
      mRID: yup.string(),
      description: yup.string(),
      type: yup.string(),
    })
  ),
  submitStatus: yup.string(),
});

export const OmsRequestDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const OmsOutageWarningPayloadSchema = yup.object({
  message: yup.string(),
});

export const OmsRequestUpdatePayloadSchema = yup.object({
  transmissionOutageChangeNew: yup
    .object(TransmissionOutageChangeShape)
    .defined(),
  transmissionOutageChangePrevious: yup
    .object(TransmissionOutageChangeEditableShape)
    .defined(),
});

export type IOmsRequest = yup.Asserts<typeof OmsRequestDataSchema>;
export type IOmsRequestForm = yup.Asserts<typeof OmsRequestFormSchema>;
export type IOmsRequestUpdatePayload = yup.Asserts<
  typeof OmsRequestUpdatePayloadSchema
>;
export type IOmsData = yup.Asserts<typeof OmsDataSchema>;
export type ILerOmsData = yup.Asserts<typeof LerOmsDataSchema>;
export type IOmsRequestSubmitRefresh = yup.Asserts<
  typeof OmsRequestSubmitRefreshSchema
>;
export type IOmsRequestSubmitRefreshResponse = yup.Asserts<
  typeof OmsRequestSubmitRefreshResponseSchema
>;
export type IOmsListPage = yup.Asserts<typeof OmsListPageSchema>;
export type IOmsRequestPayload = yup.Asserts<typeof OmsRequestPayloadSchema>;
