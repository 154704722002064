import React from 'react';
import {
  ATMGrid,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import SettingsEdit from 'src/components/pages/auth-settings/auth-settings-edit/auth-settings-edit.component';
import { filterByCategory } from 'src/selectors/setting.selector';
import style from '../roles-settings.module.scss';

type IProps = {
  data: any[];
  loading: boolean;
  handleFetch: () => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const AlertSettingsView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
}) => {
  const columns: IORGDataTableColumn<any>[] = [
    {
      index: 'description',
      width: '50%',
      title: Lang.LBL_PROPERTY,
    },
    {
      index: 'value',
      title: Lang.LBL_VALUES,
    },
    {
      index: 'action',
      title: Lang.LBL_ACTION,
      sortable: false,
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, value) => (
        <SettingsEdit data={value} handleFetch={handleFetch} />
      ),
    },
  ];

  return (
    <div className={style.mainContainerPosition}>
      <ATMGrid columns={2}>
        <ATMGrid.Column verticalAlign="middle">{/** */}</ATMGrid.Column>
        <ATMGrid.Column textAlign="right">{/** */}</ATMGrid.Column>
      </ATMGrid>

      <div className={style.marginTop}>
        <ORGDataTable
          columns={columns}
          data={filterByCategory(data, 'Alert Settings')}
          celled={false}
          counter
          loading={loading}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sortable
        />
      </div>
    </div>
  );
};

export default AlertSettingsView;
