import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMIcon,
  ATMButton,
  IATMTabProps,
  ATMMenu,
  ATMTab,
  ATMForm,
  ATMDropdown,
  // ATMInput,
} from 'shared-it-appmod-ui';
import DictionaryRename from 'src/components/atoms/dictionary-rename/dictionary-rename.component';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import { KeyHolderType, RoleEmpHRStatus } from 'src/constants';
import history from 'src/history';
import Lang from 'src/libraries/language';
import { formatDateTz } from 'src/libraries/moment.library';
import { IIssuedKey } from 'src/models/issued-key.model';
import { getEmployeeName } from 'src/helpers/employee.helper';
import IssuedKeyCard from '../issued-key-card/issued-key-card.component';
import IssuedKeyEdit from '../issued-key-edit/issued-key-edit.component';
import style from '../issued-key.module.scss';

const columns: IORGDataTableColumn<IIssuedKey>[] = [
  {
    index: 'keyNbr',
    title: Lang.LBL_ISSUED_KEY,
    render: (_, value) => {
      return value.keyDetail && value.keyDetail?.emp?.title ? (
        <IssuedKeyCard
          issuedKey={value}
          holderType={value.keyDetail?.emp?.title}
        />
      ) : (
        <IssuedKeyCard issuedKey={value} holderType={KeyHolderType.EMPLOYEE} />
      );
    },
  },
  {
    index: 'stat',
    title: Lang.LBL_ISSUED_KEY_STATUS,
    render: (_, value) => <DictionaryRename value={value.stat} />,
  },
  {
    index: 'keyDetails.emp.title',
    title: Lang.LBL_ISSUED_KEY_HOLDER_TYPE,
    sortable: false,
    render: (_, value) =>
      value.keyDetail && value.keyDetail?.emp?.title ? (
        <>
          <ListStatus
            value={value.keyDetail && value.keyDetail?.emp?.title}
            label
          />
        </>
      ) : (
        ''
      ),
  },
  {
    index: 'keyDetails.emp.lastName',
    title: Lang.LBL_ISSUED_KEY_TO,
    render: (_, value) => (
      <>
        {value.keyDetail && value.keyDetail.emp && value.keyDetail.emp.empId ? (
          <>
            {value.keyDetail.emp?.title === KeyHolderType.CONTRACTOR ? (
              <ATMIcon name="building" className={style.iconFormat} />
            ) : (
              <ATMIcon name="user" className={style.iconFormat} />
            )}
            <span> {getEmployeeName(value.keyDetail.emp)}</span>
          </>
        ) : (
          Lang.LBL_UNAVAILABLE
        )}
      </>
    ),
  },
  {
    index: 'keyDetails.emp.supvr.lastName',
    title: Lang.LBL_ISSUED_KEY_MANAGER,
    render: (_, value) =>
      value.keyDetail &&
      value.keyDetail?.emp?.supvr &&
      value.keyDetail?.emp?.supvr?.empId
        ? `${value.keyDetail?.emp?.supvr?.lastName}, ${value.keyDetail?.emp?.supvr?.firstName}/${value.keyDetail?.emp?.supvr?.empId}`
        : '',
  },
  {
    index: 'keyDetails.issDt',
    title: Lang.LBL_STATUS_KEY_DATE,
    render: (_, value) =>
      value.keyDetail && value.keyDetail.issDt
        ? formatDateTz(value.keyDetail.issDt)
        : '',
  },
  {
    index: 'action',
    sortable: false,
    title: Lang.LBL_ACTION,
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <IssuedKeyEdit
        data={value.keyNbr}
        empId={
          value.keyDetail?.empId.slice(2, value.keyDetail?.empId.length) ?? '00'
        }
        isKeyRequest={false}
      />
    ),
  },
];

type IProps = {
  language?: Record<string, string>;
  data: IIssuedKey[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
  total: number;
  activeTotal: number;
  lostTotal: number;
  damagedTotal: number;
  notReturnedTotal: number;
  pendingTotal: number;
  returnedTotal: number;
  currentTab: number;
  downloadLoading: boolean;
};

const currentTabTotal = (
  total: number,
  activeTotal: number,
  lostTotal: number,
  damagedTotal: number,
  notReturnedTotal: number,
  pendingTotal: number,
  returnedTotal: number,
  currentTab
) => {
  switch (currentTab) {
    case 1:
      return activeTotal;
    case 2:
      return damagedTotal;
    case 3:
      return lostTotal;
    case 4:
      return returnedTotal;
    case 5:
      return pendingTotal;
    case 6:
      return notReturnedTotal;
    default:
      return total;
  }
};

const IssuedKeyListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  handleDownload,
  total,
  activeTotal,
  lostTotal,
  damagedTotal,
  notReturnedTotal,
  pendingTotal,
  returnedTotal,
  currentTab,
  downloadLoading,
}) => {
  const table = (
    <>
      <ORGDataTable
        celled={false}
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        total={currentTabTotal(
          total,
          activeTotal,
          lostTotal,
          damagedTotal,
          notReturnedTotal,
          pendingTotal,
          returnedTotal,
          currentTab
        )}
        rowsPerPageOptions={[10, 25, 50, 100]}
        counter
        location={history.location}
        handleLocation={history.push}
        sortable
      >
        {({ state, setState }) => ({
          toolbars: {
            keyNbr: () => (
              <TableSearch
                field="keyNbr"
                state={state}
                setState={setState}
                placeholder="Key Number"
              />
            ),
            name: () => (
              <TableSearch
                field="name"
                state={state}
                setState={setState}
                placeholder="Key Holder Name/ID"
              />
            ),
            download: () => (
              <ATMButton
                key="download"
                secondary
                size="small"
                icon="download"
                loading={downloadLoading}
                disabled={downloadLoading}
                content={Lang.LBL_DOWNLOAD}
                onClick={() => {
                  handleDownload({
                    ...state,
                    page: 1,
                    limit: 0,
                  });
                }}
              />
            ),
          },
          filters: ({ values, setValue, setError }) => {
            return (
              <div className={style.filters}>
                <ATMForm.Field>
                  <ATMDropdown
                    selection
                    clearable
                    placeholder="Key Type"
                    value={values.keyTypId}
                    options={[
                      ...Array.from(Object.keys(Lang.KEY_TYPE)).map((val) => {
                        return {
                          key: val,
                          value: val,
                          text: Lang.KEY_TYPE[val],
                        };
                      }),
                    ]}
                    onChange={(_, val) => {
                      setValue('keyTypId', val.value);
                      setError('keyTypId', !val.value);

                      return val.value;
                    }}
                    selectOnBlur={false}
                    search
                  />
                </ATMForm.Field>
                <ATMDropdown
                  selection
                  clearable
                  placeholder="Employee Status"
                  value={values.empStat}
                  options={Object.entries(RoleEmpHRStatus).map(
                    ([key, value]) => ({
                      key,
                      value,
                      text: key,
                    })
                  )}
                  onChange={(_, val) => {
                    setValue('empStat', val.value);
                    return val.value;
                  }}
                  selectOnBlur={false}
                  search
                />
              </div>
            );
          },
        })}
      </ORGDataTable>
    </>
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_ALL} ({total})
        </ATMMenu.Item>
      ),
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: <ATMMenu.Item key="1">Active ({activeTotal})</ATMMenu.Item>,
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: <ATMMenu.Item key="2">Damaged ({damagedTotal})</ATMMenu.Item>,
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: <ATMMenu.Item key="3">Lost ({lostTotal})</ATMMenu.Item>,
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: <ATMMenu.Item key="4">Returned ({returnedTotal})</ATMMenu.Item>,
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="5">Pending Return ({pendingTotal})</ATMMenu.Item>
      ),
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="6">Not Returned ({notReturnedTotal})</ATMMenu.Item>
      ),
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
  ];

  return (
    <div className={style.headerTabs}>
      <Tabs
        className={style.table}
        name="issueStatus"
        menu={{ pointing: true }}
        panes={panes}
      />
    </div>
  );
};

export default IssuedKeyListView;
