import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/substation.service';
import { orderBy } from 'lodash';
import { ISubstation } from 'src/models/substation.model';

export const substationActionTypes = {
  SUBSTATION_DATA_READ: 'SUBSTATION_DATA_READ',
  SUBSTATION_LIST_READ: 'SUBSTATION_LIST_READ',
  SUBSTATION_DATA_CREATE: 'SUBSTATION_DATA_CREATE',
  SUBSTATION_DATA_UPDATE: 'SUBSTATION_DATA_UPDATE',
  SUBSTATION_DATA_DELETE: 'SUBSTATION_DATA_DELETE',
  SUBSTATION_DATA_SET: 'SUBSTATION_DATA_SET',
  SUBSTATION_LIST_ALL_READ: 'SUBSTATION_LIST_ALL_READ',
  SUBSTATION_FILTER_ALL_READ: 'SUBSTATION_FILTER_ALL_READ',
  SUBSTATION_LIST_CREATE_READ: 'SUBSTATION_LIST_CREATE_READ',
  SUBSTATION_CLEAR: 'SUBSTATION_CLEAR,',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: substationActionTypes.SUBSTATION_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: substationActionTypes.SUBSTATION_LIST_READ,
    service: services.listGET,
  },

  listSubstationCreateGET: {
    type: substationActionTypes.SUBSTATION_LIST_CREATE_READ,
    service: services.listSubstationCreateGET,
  },

  listAllGET: {
    type: substationActionTypes.SUBSTATION_LIST_ALL_READ,
    service: services.listGET,
  },

  filterGET: {
    type: substationActionTypes.SUBSTATION_FILTER_ALL_READ,
    service: services.listGET,
  },

  createPOST: {
    type: substationActionTypes.SUBSTATION_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: substationActionTypes.SUBSTATION_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: substationActionTypes.SUBSTATION_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (substation: ISubstation) => ({
    type: substationActionTypes.SUBSTATION_DATA_SET,
    payload: substation,
  }),

  listCLEAR: () => ({
    type: substationActionTypes.SUBSTATION_CLEAR,
  }),
};

export type ISubstationAsync = typeof duckActions;

export interface ISubstationState
  extends ICommonState<typeof substationActionTypes> {
  data?: ISubstation;
  list: ISubstation[];
  listForCreate: ISubstation[];
  total: number;
  filter: ISubstation[];
}

export const defaultState: ISubstationState = {
  status: {},
  list: [],
  listForCreate: [],
  total: 0,
  filter: [],
};

const SubstationReducer = (
  state: ISubstationState,
  action: IReducerAction<ISubstationAsync>
): ISubstationState => {
  switch (action.type) {
    case substationActionTypes.SUBSTATION_DATA_SET:
    case substationActionTypes.SUBSTATION_DATA_READ:
    case substationActionTypes.SUBSTATION_DATA_UPDATE:
    case substationActionTypes.SUBSTATION_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case substationActionTypes.SUBSTATION_LIST_CREATE_READ: {
      return {
        ...state,
        listForCreate: orderBy(action.payload?.rows, ['name'], ['asc']) ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    case substationActionTypes.SUBSTATION_LIST_READ: {
      return {
        ...state,
        list: orderBy(action.payload?.rows, ['name'], ['asc']) ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case substationActionTypes.SUBSTATION_DATA_DELETE: {
      if (action.params) {
        const [substationId] = action.params;
        const list = state.list.filter(
          (value) => value.substationId !== substationId
        );

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }
    case substationActionTypes.SUBSTATION_FILTER_ALL_READ: {
      return {
        ...state,
        filter: action.payload?.rows ?? [],
      };
    }

    case substationActionTypes.SUBSTATION_CLEAR: {
      return {
        ...state,
        list: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default SubstationReducer;
