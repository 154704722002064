import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import WorkGroupReducer, {
  IWorkGroupState,
  defaultState,
  duckActions,
} from 'src/ducks/work-group.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    WorkGroupReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IWorkGroupContext = ReturnType<typeof useReducer>;

const WorkGroupContext = createContext<Partial<IWorkGroupContext>>({
  state: defaultState,
}) as React.Context<IWorkGroupContext>;

type IProps = {
  state?: Partial<IWorkGroupState>;
};

const WorkGroupProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <WorkGroupContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </WorkGroupContext.Provider>
  );
};

const useWorkGroupContext = () => useContext(WorkGroupContext);

export type IUseWorkGroupContext = ReturnType<typeof useWorkGroupContext>;

export { WorkGroupContext, WorkGroupProvider, useWorkGroupContext };
