import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  SickListFormSchema,
  ISickList,
  ISickListForm,
} from 'src/models/sick-list.model';
import SickListForm from '../sick-list-form/sick-list-form.component';
import style from '../sick-list.module.scss';

type IProps = {
  defaultValues: ISickList;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: ISickListForm) => void;
};

const SickListEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          icon="edit"
          onClick={() => handleOpen(true)}
          content={Lang.LBL_EDIT}
          compact="true"
          className={style.manageDropdown}
        />
      }
    >
      <ATMModal.Header>Edit Sick Entry</ATMModal.Header>
      <ATMModal.Content className={style.paddingBottom}>
        <SickListForm
          defaultValues={SickListFormSchema.cast(defaultValues)}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SUBMIT}
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default SickListEditView;
