import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ATMLoader } from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import EmailLinkPageView from './email-link-page.view';

function parseJwt(token) {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  return null;
}

const EmailLinkPage: React.FC = () => {
  const location = useLocation();
  const { state, actions } = useLerRequestContext();

  const groupDetailsStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_EMAIL_USER_GROUP_READ
  );

  const lerDetailStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_READ
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const decode = parseJwt(token);
    if (decode) {
      actions.groupDataGET(decode.requestId, decode.version_no, decode.groupId);
      actions.dataGET(decode.requestId);
    }
  }, [actions]);

  return lerDetailStatus.fetching || groupDetailsStatus.fetching ? (
    <ATMLoader active size="massive" inline="centered" />
  ) : (
    <EmailLinkPageView />
  );
};

export default EmailLinkPage;
