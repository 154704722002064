import React, { useMemo } from 'react';
import { ATMHeader, ATMLabel } from 'shared-it-appmod-ui';
import { LERNotificationStatus, LerRequestStatusOMS } from 'src/constants';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import OutageStatus from 'src/components/atoms/outage-status/outage-status.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import styles from './ler-request-header.module.scss';

type IProps = {
  data: ILerRequest;
};

const LerRequestHeader: React.FC<IProps> = ({ data }) => {
  const { state } = useLerRequestContext();

  const approval = useMemo(() => {
    const groups = data.authorizationStatement.emailGroups ?? [];

    return {
      isPending:
        groups.length > 0 &&
        groups?.some((v) => v.status === LERNotificationStatus.PENDING),
      pending: groups.filter((v) => v.status === LERNotificationStatus.PENDING)
        .length,
      approve: groups.filter((v) => v.status === LERNotificationStatus.APPROVED)
        .length,
      total: groups.length,
    };
  }, [data]);

  const hasPendingOMS = state.caisoList.some(
    (v) => v.OutageChangeRequest?.status === LerRequestStatusOMS.PENDING
  );

  return (
    <div className={styles.wrapper}>
      <ATMHeader as="h1" content={`${data.outgCategNm} ${data.requestId}`} />
      <ul>
        {data.outageFacility?.facility && (
          <li>{data.outageFacility?.facility.outgFacNm}</li>
        )}

        <li>
          <OutageStatus data={data} />
        </li>
        <li>{data.outageFacility?.outageType?.outageTypeDesc}</li>
        {data.jobInformation.testVoltageApplied && (
          <li>
            <ATMLabel>{Lang.LBL_JOB_INFO_TEST_VOLTAGE}</ATMLabel>
          </li>
        )}
        {hasPendingOMS && (
          <li>
            <ATMLabel color="red">{Lang.LBL_CAISO_PENDING}</ATMLabel>
          </li>
        )}
        {approval.isPending && (
          <li>
            <ATMLabel color="orange">
              {Lang.formatString(
                Lang.LBL_JOB_INFO_PENDING_APPROVAL,
                approval.approve,
                approval.total
              )}
            </ATMLabel>
          </li>
        )}
      </ul>
    </div>
  );
};

export default LerRequestHeader;
