import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { useRolesContext } from 'src/contexts/roles.context';
import { IRequestorReport } from '../../../../models/roles.model';

const columns: IORGDataTableColumn<IRequestorReport>[] = [
  {
    index: 'empId',
    title: Lang.LBL_EMPLOYEE_ID,
    render: (_, value) => `${value.empId}`,
  },
  {
    index: 'employee.fullName',
    title: Lang.LBL_EMPLOYEE_NAME,
    render: (_, value) =>
      value.employee.fullName ? `${value.employee.fullName}` : '-',
  },
  {
    index: 'role.roleName',
    title: Lang.LBL_EMPLOYEE_ROLE,
    render: (_, value) => value.role.roleName,
  },
  {
    index: 'employee.title',
    title: Lang.LBL_EMPLOYEE_TITLE,
    render: (_, value) => value.employee.title,
  },
  {
    index: 'employee.workPhoneNumber',
    sortable: false,
    title: Lang.LBL_EMPLOYEE_PHONE,
    render: (_, value) => value.employee.workPhoneNumber,
  },
  {
    index: 'employee.email',
    title: Lang.LBL_EMPLOYEE_EMAIL,
    render: (_, value) => value.employee.email,
  },
];

type IProps = {
  data: IRequestorReport[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
  dlLoading: boolean;
  total: number;
};

const KeyRequestorReportView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  handleDownload,
  dlLoading,
}) => {
  const { state: rolesState } = useRolesContext();
  return (
    <div className="admin-content">
      <ORGDataTable
        celled={false}
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        total={total}
        rowsPerPageOptions={[50, 100, 150]}
        counter
        sortable
        defaultRowsPerPage={50}
        showPagination
      >
        {({ state }) => ({
          toolbars: {
            roleId: ({ value, setValue }) => (
              <ATMDropdown
                placeholder={Lang.LBL_ROLES_TITLES}
                selection
                size="small"
                clearable
                value={value}
                options={[
                  ...Array.from(new Set(rolesState.roles))
                    .map((val) => {
                      return {
                        key: val.roleId,
                        value: val.roleId,
                        text: val.roleName,
                      };
                    })
                    .filter((val) => val.text && val.text.indexOf('Key') > -1),
                ]}
                onChange={(_, val) => {
                  setValue(val.value);
                  return val.value;
                }}
                selectOnBlur={false}
                search
              />
            ),
            buttons: () => (
              <ATMButton
                key="download"
                secondary
                size="tiny"
                icon="download"
                onClick={() => {
                  handleDownload({
                    ...state,
                    page: 1,
                    limit: total,
                  });
                }}
                loading={dlLoading}
              />
            ),
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default KeyRequestorReportView;
