import React from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import {
  IAuthorizedListSuspensionForm,
  AuthorizedListSuspensionFormSchema,
} from 'src/models/authorized-list.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IAuthorizedListSuspensionForm>;
  handleSubmit: (data: IAuthorizedListSuspensionForm) => void;
  handleEnable: (value: boolean) => void;
  authListId: number;
  liftSuspension?: boolean;
  suspend?: boolean;
  empName?: string | null;
};

const AuthorizedListSuspensionForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  authListId,
  suspend,
  empName,
}) => (
  <ATMForm
    ref={formRef}
    onSubmit={handleSubmit}
    mode="onChange"
    defaultValues={AuthorizedListSuspensionFormSchema.cast({
      ...defaultValues,
      event:
        !suspend || suspend === undefined ? 'Suspension Lifted' : 'Suspended',
      suspEventId: !suspend || suspend === undefined ? 2 : 1,
    })}
    validationSchema={AuthorizedListSuspensionFormSchema}
  >
    {({ control, formState: { errors, isDirty } }) => {
      handleEnable(isDirty);

      return (
        <ATMGrid>
          <ATMGrid.Row columns="equal">
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_GROUP_EMP_NAME}>
                {empName} / {defaultValues?.empId}
              </FieldReadOnly>
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                name="event"
                control={control}
              />
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                name="suspEventId"
                control={control}
              />
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                name="empId"
                control={control}
                error={errors.empId}
              />
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                defaultValue={new Date()}
                name="eventTime"
                control={control}
                error={errors.empId}
              />
              <ATMField
                style={{ display: 'none' }}
                as={ATMInput}
                type="hidden"
                className="hidden"
                name="authListId"
                defaultValue={authListId}
                control={control}
                error={errors.authListId}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <FieldReadOnly label={Lang.LBL_AUTHORIZATION_TYPE}>
                {defaultValues?.authTypId}
              </FieldReadOnly>
              <ATMField
                as={ATMInput}
                type="hidden"
                className="hidden"
                name="authTypId"
                control={control}
                error={errors.authTypId}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMField
                as={ATMTextArea}
                label={Lang.LBL_COMMENTS}
                name="comments"
                maxLength={4000}
                control={control}
                error={errors.comments}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      );
    }}
  </ATMForm>
);

export default AuthorizedListSuspensionForm;
