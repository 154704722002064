import { httpClient } from 'src/libraries/http.library';
import {
  ISubstationEntryForm,
  ISubstationEntryAddForm,
  SubstationEntryListPayloadSchema,
  SubstationEntryDeletePayloadSchema,
  SubstationEntrySchema,
  SubstationEntryEmployeeListPayloadSchema,
} from 'src/models/substation-entry.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

const client = httpClient();
const endpoint = 'substation-entry-log';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, SubstationEntrySchema);
  },

  listGET: async (params?: Partial<IORGDataTableQueryState>, today = false) => {
    return client.get(
      `/${endpoint}`,
      {
        ...params,
        today,
      },
      SubstationEntryListPayloadSchema
    );
  },

  userListGET: async (params?: Partial<IORGDataTableQueryState>) => {
    return client.get(
      `/${endpoint}`,
      {
        ...params,
        action: 'findAllUser',
      },
      SubstationEntryEmployeeListPayloadSchema
    );
  },

  createPOST: async (data: ISubstationEntryAddForm) => {
    return client.post(`/${endpoint}`, data, SubstationEntrySchema);
  },

  updatePUT: async (id: number, data: ISubstationEntryForm) => {
    return client.put(`/${endpoint}/${id}`, data, SubstationEntrySchema);
  },

  logoutPUT: async (id: number) => {
    return client.put(
      `/${endpoint}/${id}?action=logout`,
      {},
      SubstationEntrySchema
    );
  },

  dataDELETE: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}`,
      SubstationEntryDeletePayloadSchema
    );
  },
};

export default services;
