import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMField,
  ATMForm,
  ATMGrid,
  ATMSelect,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { FacilityType } from 'src/constants';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { outageTypeActionTypes } from 'src/ducks/outage-type.duck';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getOutageTypeStatus } from 'src/selectors/outage-type.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';

type IProps = {
  isEdit?: boolean;
};

const LERRequestOutageFacilityForm: React.FC<Partial<IProps>> = ({
  isEdit = false,
}) => {
  const {
    control,
    formState: { errors },
    resetField,
    register,
    getValues,
    setValue,
  } = useATMFormContext<ILerRequestForm>();

  const [outageFacilityId, setOutageFacilityId] = useState<number | undefined>(
    getValues('outageFacility.outgFacId')
  );

  const [facilityTypeId, setFacilityTypeId] = useState<number | undefined>(
    () => {
      const type = getValues('outageFacility.facilityType');
      if (type) {
        return type;
      }
      // eslint-disable-next-line no-nested-ternary
      return getValues('outageFacility.substationId')
        ? FacilityType.Station
        : outageFacilityId
        ? FacilityType.Line
        : undefined;
    }
  );

  const [equipmentType, setEquipmentType] = useState<number | undefined>(
    getValues('outageFacility.facTypId') as number
  );
  const [substationId, setSubstationId] = useState<string | undefined>(
    getValues('outageFacility.substationId') as string
  );
  const [voltId, setVoltId] = useState<number | undefined>(
    getValues('outageFacility.voltId') as number
  );

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { state: outageTypeState, actions: outageTypeActions } =
    useOutageTypeContext();

  const realTimeLogStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );

  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  const voltStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_VOLT_SUBS_READ
  );

  const equipmentStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_EQUIPMENT_READ
  );

  const outageTypeStatus = getOutageTypeStatus(
    outageTypeState,
    outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ
  );

  const handleDefaultValueISO = useCallback(
    async (outgFacId: number) => {
      if (outgFacId) {
        const result = await facilityActions.lerFacilityDetails(outgFacId);
        // CAISO Trs
        register('isoTrs.caisoTrs');
        resetField('isoTrs.caisoTrs', {
          defaultValue: result.payload?.isoTrs.map((v) => {
            return { caisoId: v.isoTrId, facilityModelInd: false };
          }) as any,
        });
        // Switch
        register('isoTrs.switchings');
        resetField('isoTrs.switchings', {
          defaultValue: result.payload?.isoSwitchings.map((v) => {
            return { switchId: v.isoSwitchingId, defaultPosInd: true };
          }) as any,
        });
        // EMS
        register('isoTrs.emsDatas');
        resetField('isoTrs.emsDatas', {
          defaultValue: result.payload?.emsFacs.map((v) => {
            return {
              emsId: v.emsFacId,
              top: v.top,
              station: v.station,
              busName: v.busNm,
              equipmentName: v.equipmentNm,
              equipmentType: v.equipmentTyp,
              position: v.defaultFlag,
            };
          }) as any,
        });
      } else {
        facilityActions.lerFacilityDetailsCLEAR();
      }
    },
    [facilityActions]
  );

  useEffect(() => {
    const lineItem = realtimeLogState.line.find(
      (item) => item.outgFacId === outageFacilityId
    );
    setValue('outageFacility.besInd', lineItem?.besInd ?? 'N');
  }, [outageFacilityId]);

  useEffect(() => {
    substationActions.listSubstationCreateGET({
      limit: 0,
      page: 1,
    });

    realtimeLogActions.lineGET();

    outageTypeActions.activeListGET();
  }, [substationActions, realtimeLogActions, outageTypeActions]);

  useEffect(() => {
    if (substationId) {
      equipmentTypeActions.listGET({
        limit: 0,
        page: 1,
        filters: [{ name: 'substationId', value: substationId }],
      });
    }
  }, [substationId, equipmentTypeActions]);

  useEffect(() => {
    if (equipmentType && substationId) {
      facilityActions.voltSubstationGET(substationId, equipmentType);
    }
  }, [equipmentType, substationId, facilityActions]);

  useEffect(() => {
    if (equipmentType && substationId && voltId) {
      facilityActions.equipmentGET(
        equipmentType,
        voltId,
        undefined,
        substationId
      );
    }
  }, [equipmentType, substationId, voltId, facilityActions]);

  useEffect(() => {
    // 18365 is Hotwashes
    if (
      facilityTypeId === FacilityType.Line &&
      outageFacilityId !== 18365 &&
      !isEdit
    ) {
      // If FacilityType = Line and not Hotwashes and voltNm is 69 or 138, then switchOutMin is 30
      const voltNm = facilityState.lerDetails?.volt?.voltNm;
      if (voltNm === '138' || voltNm === '69') {
        resetField('jobInformation.switchOutMin', {
          defaultValue: '30' as unknown as number,
        });
      } else {
        resetField('jobInformation.switchOutMin', {
          defaultValue: '60' as unknown as number,
        });
      }
      // SwitchInMin will always be 60
      resetField('jobInformation.switchInMin', {
        defaultValue: '60' as unknown as number,
      });
    } else if (
      outageFacilityId === 18365 ||
      (facilityTypeId === FacilityType.Station && !isEdit)
    ) {
      // Always reset if Hotwashes whether on edit mode or not. If Substation, reset only if we are not on edit mode.
      resetField('jobInformation.switchInMin', {
        defaultValue: '0' as unknown as number,
      });
      resetField('jobInformation.switchOutMin', {
        defaultValue: '0' as unknown as number,
      });
    }
  }, [facilityTypeId, outageFacilityId, facilityState, isEdit, resetField]);

  const equipment = equipmentTypeState.list.find(
    (val) => val.facTypId === equipmentType
  );

  return (
    <ATMGrid columns={2} style={{ maxWidth: 800 }}>
      <ATMGrid.Column>
        <ATMForm.Field>
          <LabelRequired>{Lang.LBL_FACILITY_TYPE}</LabelRequired>
          <ATMField
            as={ATMSelect}
            placeholder={Lang.LBL_SELECT}
            control={control}
            name="outageFacility.facilityType"
            error={errors.outageFacility?.facilityType}
            value={facilityTypeId}
            defaultValue={facilityTypeId}
            options={Object.values(FacilityType)
              .filter((val) => typeof val === 'number')
              .map((value, key) => ({
                key,
                value,
                text: Lang.FACILITY_TYPE[value],
              }))}
            onChange={
              (([_, { value }]) => {
                equipmentTypeActions.listCLEAR();
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();

                setFacilityTypeId(value);
                setSubstationId(undefined);
                setEquipmentType(undefined);
                setVoltId(undefined);
                setOutageFacilityId(undefined);

                setImmediate(() => {
                  resetField('outageFacility.outgFacId', {
                    defaultValue: null as any,
                  });
                  resetField('outageFacility.besInd', {
                    defaultValue: null as unknown as string,
                  });
                  // setImmediate(() => {
                  resetField('outageFacility.substationId', {
                    defaultValue: null as any,
                  });

                  resetField('outageFacility.voltId', {
                    defaultValue: null as unknown as number,
                  });

                  resetField('outageFacility.facTypId', {
                    defaultValue: null as unknown as number,
                  });
                  // });
                });

                return value;
              }) as any
            }
            selectOnBlur={false}
          />
        </ATMForm.Field>
      </ATMGrid.Column>
      <ATMGrid.Column>
        {facilityTypeId === FacilityType.Line ? (
          <ATMField
            key={`type_${facilityTypeId}`}
            name="outageFacility.outgFacId"
            label={<LabelRequired>{Lang.LBL_LINE}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.outageFacility?.outgFacId}
            options={realtimeLogState.line.map((value) => ({
              key: value.outgFacId,
              value: value.outgFacId,
              text: value.outgFacNm,
            }))}
            onChange={([_, { value }]) => {
              const lineItem = realtimeLogState.line.find(
                (item) => item.outgFacId === value
              );
              setValue('outageFacility.besInd', lineItem?.besInd ?? 'N');
              setOutageFacilityId(value);
              handleDefaultValueISO(value);
              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!realtimeLogState.line.length && realTimeLogStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !realtimeLogState.line.length && realTimeLogStatus.fetching
            }
          />
        ) : (
          <ATMField
            name="outageFacility.substationId"
            label={<LabelRequired>{Lang.LBL_SUBSTATION}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.outageFacility?.substationId}
            options={substationState.listForCreate.map((value) => ({
              key: value.substationId,
              value: value.substationId,
              text: value.name,
            }))}
            onChange={([_, { value }]) => {
              equipmentTypeActions.listCLEAR();
              facilityActions.voltCLEAR();
              facilityActions.equipmentCLEAR();

              setSubstationId(value);
              setEquipmentType(undefined);
              setVoltId(undefined);
              setOutageFacilityId(undefined);

              setImmediate(() => {
                resetField('outageFacility.outgFacId', {
                  defaultValue: null as unknown as number,
                });
                resetField('outageFacility.facTypId', {
                  defaultValue: null as unknown as number,
                });
                resetField('outageFacility.voltId', {
                  defaultValue: null as unknown as number,
                });
                resetField('outageFacility.besInd', {
                  defaultValue: null as unknown as string,
                });
              });
              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!substationState.listForCreate.length &&
                substationStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !substationState.listForCreate.length && substationStatus.fetching
            }
          />
        )}
      </ATMGrid.Column>
      {facilityTypeId === FacilityType.Station ? (
        <>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_type_${substationId}_${equipmentTypeState.list.length}`}
              name="outageFacility.facTypId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT_TYPE}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.outageFacility?.facTypId}
              options={equipmentTypeState.list.map((value) => ({
                key: value.facTypId,
                value: value.facTypId,
                text: value.facTypNm,
              }))}
              onChange={([_, { value, options }]) => {
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();

                setEquipmentType(value);
                setVoltId(undefined);
                setOutageFacilityId(undefined);

                setImmediate(() => {
                  resetField('outageFacility.outgTypId', {
                    defaultValue: (value &&
                    options.find((v) => v.value === value).text === 'Generator'
                      ? ''
                      : getValues('outageFacility.outgTypId')) as number,
                  });
                  resetField('outageFacility.voltId', {
                    defaultValue: null as unknown as number,
                  });

                  resetField('outageFacility.outgFacId', {
                    defaultValue: null as unknown as number,
                  });

                  resetField('outageFacility.besInd', {
                    defaultValue: null as unknown as string,
                  });
                });

                return value;
              }}
              clearable
              search
              selectOnBlur={false}
              disabled={
                equipmentTypeStatus.fetching || !facilityTypeId || !substationId
              }
              loading={equipmentTypeStatus.fetching}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`volt_${substationId}_${equipmentType}`}
              name="outageFacility.voltId"
              label={
                <LabelRequired>{Lang.LBL_EQUIPMENT_VOLTAGE}</LabelRequired>
              }
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.outageFacility?.voltId}
              options={facilityState.volt.map((value) => ({
                key: value.voltId,
                value: value.voltId,
                text: value.name,
              }))}
              onChange={([_, { value }]) => {
                facilityActions.equipmentCLEAR();
                setVoltId(value);
                setOutageFacilityId(undefined);

                setImmediate(() => {
                  resetField('outageFacility.besInd', {
                    defaultValue: null as unknown as string,
                  });

                  resetField('outageFacility.outgFacId', {
                    defaultValue: null as unknown as number,
                  });
                });

                return value;
              }}
              selectOnBlur={false}
              disabled={voltStatus.fetching || !equipmentType}
              loading={voltStatus.fetching}
              search
              clearable
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_${substationId}_${equipmentType}_${voltId}`}
              as={ATMSelect}
              name="outageFacility.outgFacId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              control={control}
              clearable
              error={errors.outageFacility?.outgFacId}
              options={facilityState.equipment?.map((val) => ({
                key: val.outgFacId,
                value: val.outgFacId,
                text: val.outgFacNm,
              }))}
              onChange={([_, { value }]) => {
                const facilityItem = facilityState.list.find(
                  (item) => item.outgFacId === value
                );
                setValue('outageFacility.besInd', facilityItem?.besInd ?? 'N');
                setOutageFacilityId(value);
                handleDefaultValueISO(value);
                return value;
              }}
              selectOnBlur={false}
              disabled={equipmentStatus.fetching || !voltId}
              loading={equipmentStatus.fetching}
              search
            />
          </ATMGrid.Column>
        </>
      ) : null}

      <ATMGrid.Column>
        <ATMField
          name="outageFacility.outgTypId"
          label={<LabelRequired>{Lang.LBL_AUTHORIZATION_TYPE}</LabelRequired>}
          placeholder={Lang.LBL_SELECT}
          as={ATMSelect}
          control={control}
          clearable
          error={errors.outageFacility?.outgTypId}
          options={outageTypeState.list
            .filter((v) => {
              if (['Restricted', 'Unavailable'].includes(v.outageTypeDesc)) {
                return equipment && equipment.facTypNm === 'Generator';
              }

              return (
                !equipment || (equipment && equipment.facTypNm !== 'Generator')
              );
            })
            .map((value) => ({
              key: value.outageTypeId,
              text: value.outageTypeDesc,
              value: value.outageTypeId,
            }))}
          onChange={([_, { value }]) => value}
          selectOnBlur={false}
          disabled={!outageTypeState.list.length && outageTypeStatus.fetching}
          loading={!outageTypeState.list.length && outageTypeStatus.fetching}
          search
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestOutageFacilityForm;
