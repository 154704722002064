import React from 'react';
import classNames from 'classnames';
import styles from './field-readonly.module.scss';

type IProps = {
  className?: string;
  label: string;
  invert?: boolean;
  inline?: boolean;
  value?: any;
};

const FieldReadOnly: React.FC<IProps> = ({
  label,
  children,
  value,
  className,
  invert = false,
  inline = false,
}) => (
  <div
    className={classNames(
      {
        [styles.container]: true,
        [styles.invert]: invert,
        [styles.inline]: inline,
      },
      className
    )}
  >
    <label className={styles.label}>{label}</label>
    {value || children || String.fromCharCode(8211)}
  </div>
);

export default FieldReadOnly;
