import React, { useState } from 'react';
import {
  ATMGrid,
  IATMTabProps,
  ATMButton,
  ATMMenu,
  ATMTab,
  ATMHeader,
  ATMDatePicker,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import { useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import useLocationTab from 'src/hooks/location-tab.hook';
import { formatDate } from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import { IAuthorizedExpiredListSchema } from 'src/models/authorized-list.model';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import AuthListDetails from 'src/components/pages/authorized-list/authorized-list-details/auth-list-details.component';
import AuthTrainingsTable from './auth-trainings-table/auth-trainings-table.component';
import SubstationEntryTable from './substation-entry-table.component.tsx/substation-entry-table.component';
import style from '../expiring-trainings.module.scss';

type IProps = {
  data?: IAuthorizedExpiredListSchema[];
  loading: boolean;
  selectedTrainingsTab: number;
  authorizationType: string;
  selectedDateSubsEntry: Date | Date[] | null | undefined;
  handleFetch: (authType: string, date: Date) => void;
  handleDownload: (authType: string, date: Date) => void;
  setSelectedDateSubsEntry: (data: Date | Date[] | null | undefined) => void;
};

const ExpiringTrainingsListView: React.FC<IProps> = ({
  data,
  loading,
  authorizationType,
  selectedTrainingsTab,
  selectedDateSubsEntry,
  handleFetch,
  handleDownload,
  setSelectedDateSubsEntry,
}) => {
  const { search } = useLocation();
  const { currentTab, handleTabChange } = useLocationTab('id');
  const [requestStatus, setRequestStatus] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateAuthTraining, setDateAuthTraining] = useState<Date>();
  const listOfYears = () => {
    const currentYear = new Date().getFullYear();
    const minYearRange: number = currentYear;
    const maxYearRange: number = Number(currentYear) + 3;

    return Array.from(
      { length: (maxYearRange - minYearRange) / 1 + 1 },
      (_, i) => minYearRange + i * 1
    );
  };

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">{Lang.LBL_AUTHORIZATION_TRAINING}</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <AuthTrainingsTable
            data={data}
            loading={loading}
            handleTabChange={handleTabChange}
            handleDownload={handleDownload}
            dateAuthTraining={dateAuthTraining}
            setRequestStatus={setRequestStatus}
          />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">{Lang.LBL_SUBSTATION_ENTRY}</ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane attached={false}>
          <SubstationEntryTable
            data={data}
            loading={loading}
            handleTabChange={handleTabChange}
            handleDownload={handleDownload}
            selectedDateSubsEntry={selectedDateSubsEntry as Date}
            setRequestStatus={setRequestStatus}
          />
        </ATMTab.Pane>
      ),
    },
  ];

  if (currentTab) {
    return (
      <>
        <AuthListDetails
          handleTabChange={handleTabChange}
          titleMainBreadcrumb="Expiring Trainings"
          breadcrumbPath={`/auth-list?tab=1&getPreviousSearch=true&requestStatus=${requestStatus}`}
        />
      </>
    );
  }

  return (
    <div className={style.mainContainerPosition}>
      <ATMGrid columns={2}>
        <ATMGrid.Column verticalAlign="middle">
          <ATMHeader className={style.marginTopBottom}>
            {selectedTrainingsTab === 0 ? (
              <div className={style.marginTopBottom}>
                12/31/
                <ATMDropdown
                  inline
                  icon="angle down"
                  className={`${style.noStyledButton}, ${style.marginDropdown}`}
                  placeholder="YYYY"
                  value={
                    search.includes('requestStatus')
                      ? Number(sessionStorage.getItem('authDate'))
                      : undefined
                  }
                  options={listOfYears().map((year) => {
                    return {
                      value: year,
                      text: year,
                      key: year,
                    };
                  })}
                  onChange={(_, { value }) => {
                    setDateAuthTraining(new Date(Number(value), 11, 31));
                    handleFetch(
                      authorizationType,
                      new Date(Number(value), 11, 31)
                    );
                    sessionStorage.setItem('authDate', String(value));
                    return value;
                  }}
                />
              </div>
            ) : (
              <>
                <ATMButton
                  type="button"
                  className={`${style.noStyledButton}`}
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                  }}
                >
                  {selectedTrainingsTab === 1 &&
                    (!selectedDateSubsEntry ? (
                      <>
                        {sessionStorage.getItem('subDate')
                          ? `${formatDate(sessionStorage.getItem('subDate'))} `
                          : 'MM/DD/YYYY '}
                      </>
                    ) : (
                      <>{formatDate(selectedDateSubsEntry as Date)} </>
                    ))}
                  <Icon name="angle down" />
                </ATMButton>
                {showDatePicker ? (
                  <span>
                    <ATMDatePicker
                      className={!showDatePicker ? style.hiddenDatePicker : ''}
                      inline
                      value={selectedDateSubsEntry}
                      clearable
                      clearOnSameDateClick={false}
                      onChange={(_, { value }) => {
                        setSelectedDateSubsEntry(value);
                        setShowDatePicker(!showDatePicker);
                        handleFetch(authorizationType, new Date(String(value)));
                        sessionStorage.setItem(
                          'subDate',
                          String(new Date(String(value)))
                        );
                      }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            )}
          </ATMHeader>
        </ATMGrid.Column>
      </ATMGrid>

      <div className={style.headerTabs}>
        <Tabs
          className={style.table}
          name="requestStatus"
          menu={{ pointing: true }}
          panes={panes}
          activeIndex={1}
        />
      </div>
    </div>
  );
};

export default ExpiringTrainingsListView;
