import React, { useEffect, useState } from 'react';
import { ATMMessage } from 'shared-it-appmod-ui';
import { SettingName } from 'src/constants';
import { useSettingContext } from 'src/contexts/setting.context';
import { getSetting } from 'src/selectors/setting.selector';
import styles from './ticker-message.module.scss';

const TickerMessage: React.FC = () => {
  const { state } = useSettingContext();
  const [outageAlert, setOutageAlert] = useState<string | null>(null);

  useEffect(() => {
    if (state.list.length > 0) {
      const flagForOutage = getSetting(SettingName.FLAG_FOR_OUTAGE, state.list);
      if (flagForOutage?.value === 'TRUE') {
        const textForOutage = getSetting(
          SettingName.TEXT_FOR_OUTAGE_INFORMATION,
          state.list
        );
        setOutageAlert(textForOutage?.value || null);
      } else {
        setOutageAlert(null);
      }
    }
  }, [state.list]);

  if (!outageAlert) {
    return null;
  }

  return (
    <ATMMessage className={styles.alertMessage} color="red">
      {outageAlert}
    </ATMMessage>
  );
};

export default TickerMessage;
