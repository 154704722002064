import React, { useState } from 'react';
import {
  ATMButton,
  ATMForm,
  ATMLabel,
  ATMGrid,
  ATMInput,
  ATMDatePicker,
  ATMField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  DailyOutageRptFilterSchema,
  // IDailyOutageRptFilter,
} from 'src/models/oc-pending-action-report.model';
import style from './oc-pending-action-report-filters.module.scss';

type IProps = {
  queryParams: Record<any, any>;
  handleQueryParams: (params: Record<any, any>) => void;
  handleSearch: () => void;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  // handleSubmit: (data: IDailyOutageRptFilter) => void;
  // handleClick: () => void;
};

const OCPendingActionsReportFilters: React.FC<IProps> = ({
  queryParams,
  handleQueryParams,
  handleSearch,
  setShowNoRecord,
  loading,
  // handleSubmit,
  // handleClick,
}) => {
  const [fromDateState, setFromDateState] = useState('');
  const [toDateState, setToDateState] = useState('');

  return (
    <div className={style.filterContainer}>
      <ATMForm
        mode="onChange"
        validationSchema={DailyOutageRptFilterSchema}
        onSubmit={handleSearch}
        defaultValues={{
          fromDate: '',
          requestId: '',
          toDate: '',
        }}
      >
        {({ control, formState: { errors }, formState, clearErrors }) => {
          return (
            <ATMGrid columns={5} className={style.filters}>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_LER}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMInput}
                    name="requestId"
                    placeholder={Lang.LBL_ENTER}
                    onChange={([_, { value }]) => {
                      if (!value || value === '') {
                        setImmediate(() => {
                          clearErrors();
                        });
                      }
                      handleQueryParams({
                        ...queryParams,
                        requestId: value,
                      });
                      return value;
                    }}
                    control={control}
                    autoComplete="off"
                    clearable
                    error={errors.requestId}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_DATE_FROM}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMDatePicker}
                    name="fromDate"
                    type="basic"
                    placeholder={Lang.LBL_SELECT}
                    format="MM/DD/YYYY"
                    maxDate={toDateState ? new Date(toDateState) : null}
                    onChange={([_, { value }]) => {
                      const from = value
                        ? value.toLocaleString().split(',')[0]
                        : '';
                      handleQueryParams({
                        ...queryParams,
                        fromDate: from,
                      });
                      setFromDateState(value);
                      return value;
                    }}
                    value={fromDateState || ''}
                    control={control}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMLabel
                    basic
                    content={Lang.LBL_DATE_TO}
                    className={style.filterLbl}
                  />
                  <ATMField
                    as={ATMDatePicker}
                    name="toDate"
                    type="basic"
                    placeholder={Lang.LBL_SELECT}
                    format="MM/DD/YYYY"
                    minDate={fromDateState ? new Date(fromDateState) : null}
                    onChange={([_, { value }]) => {
                      const to = value
                        ? value.toLocaleString().split(',')[0]
                        : '';
                      handleQueryParams({
                        ...queryParams,
                        toDate: to,
                      });
                      setToDateState(value);
                      return value;
                    }}
                    value={toDateState || ''}
                    control={control}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row style={{ paddingTop: '0' }}>
                <ATMGrid.Column width={5}>
                  <div>
                    <ATMButton
                      primary
                      content={Lang.LBL_SEARCH}
                      onClick={() => {
                        setShowNoRecord(false);
                        // handleSearch();
                        // handleClick();
                      }}
                      disabled={!formState.isDirty}
                      loading={loading}
                    />
                  </div>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default OCPendingActionsReportFilters;
