import React, { useCallback, useRef, useState } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useOcPendingActionReportContext } from 'src/contexts/oc-pending-action-report.context';
import { ocPendingActionReportActionTypes } from 'src/ducks/oc-pending-action-report.duck';
import {
  // getOcPendingActionReportParams,
  getOcPendingActionReportStatus,
} from 'src/selectors/oc-pending-action-report.selector';
import OcPendingActionReportListView from './oc-pending-action-report-list.view';

const OcPendingActionReportList: React.FC = () => {
  const { state, actions } = useOcPendingActionReportContext();
  const [queryParams, setQueryParams] = useState<Record<any, any>>({});
  const [pagination, setPagination] = useState<Record<any, any>>({});
  const queryParamRef = useRef<Record<any, any>>({});
  const [showNoRecord, setShowNoRecord] = useState<boolean>(false);
  // const formRef = useRef<HTMLFormElement>(null);

  const loading = getOcPendingActionReportStatus(
    state,
    ocPendingActionReportActionTypes.OC_PENDING_ACTION_REPORT_LIST_READ
  ).fetching;
  queryParamRef.current = queryParams;

  const handleSearch = useCallback(async () => {
    const response = await actions.listGET(queryParamRef.current);
    if (!response.error && response.payload?.rows?.length === 0) {
      setShowNoRecord(true);
    } else if (response.error) {
      ToastError('Error retrieving OC Pending Actions records.');
    }
  }, [actions]);

  const handleFetch = useCallback(
    async (params: IORGDataTableQueryState) => {
      setPagination({ ...params });
      const response = await actions.listGET({
        ...params,
        ...queryParamRef.current,
      });

      if (!response.error && response.payload?.rows?.length === 0) {
        setShowNoRecord(true);
      } else if (response.error) {
        ToastError('Error retrieving OC Pending Actions records.');
      }
    },
    [actions]
  );

  return (
    <OcPendingActionReportListView
      data={state.list}
      loading={loading}
      total={state.total}
      queryParams={queryParamRef.current}
      pagination={pagination}
      handleFetch={handleFetch}
      handleQueryParams={setQueryParams}
      handleSearch={handleSearch}
      showNoRecord={showNoRecord}
      setShowNoRecord={setShowNoRecord}
      // formRef={formRef}
      // handleSubmit={handleSubmit}
      // handleClick={handleClick}
    />
  );
};

export default OcPendingActionReportList;
