import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/substation-entry.service';
import {
  ISubstationEntry,
  ISubstationEntryEmployee,
} from 'src/models/substation-entry.model';

export const substationEntryActionTypes = {
  SUBSTATION_ENTRY_DATA_READ: 'SUBSTATION_ENTRY_DATA_READ',
  SUBSTATION_ENTRY_LIST_READ: 'SUBSTATION_ENTRY_LIST_READ',
  SUBSTATION_ENTRY_ALL_LIST_READ: 'SUBSTATION_ENTRY_ALL_LIST_READ',
  SUBSTATION_ENTRY_DATA_CREATE: 'SUBSTATION_ENTRY_DATA_CREATE',
  SUBSTATION_ENTRY_DATA_UPDATE: 'SUBSTATION_ENTRY_DATA_UPDATE',
  SUBSTATION_ENTRY_DATA_DELETE: 'SUBSTATION_ENTRY_DATA_DELETE',
  SUBSTATION_ENTRY_DATA_SET: 'SUBSTATION_ENTRY_DATA_SET',
  SUBSTATION_ENTRY_CLEAR_ERROR: 'SUBSTATION_ENTRY_CLEAR_ERROR',
  SUBSTATION_ENTRY_LOGOUT: 'SUBSTATION_ENTRY_LOGOUT',
  SUBSTATION_ENTRY_USER_LIST: 'SUBSTATION_ENTRY_USER_LIST',
  SUBSTATION_ENTRY_CLEAR_TOTAL: 'SUBSTATION_ENTRY_CLEAR_TOTAL',
  SUBSTATION_ENTRY_CLEAR_ALL_LIST: 'SUBSTATION_ENTRY_CLEAR_ALL_LIST',
  SUBSTATION_ENTRY_ORIGINAL_DATA_SET: 'SUBSTATION_ENTRY_ORIGINAL_DATA_SET',
  SUBSTATION_ENTRY_FILTERED_BY_SUBSTATION_DATA_SET:
    'SUBSTATION_ENTRY_FILTERED_BY_SUBSTATION_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_DATA_READ,
    service: services?.dataGET,
  },

  listGET: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_LIST_READ,
    service: services?.listGET,
  },

  listAllGET: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_ALL_LIST_READ,
    service: services?.listGET,
  },

  userListGET: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_USER_LIST,
    service: services?.userListGET,
  },

  createPOST: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_DATA_CREATE,
    service: services?.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_DATA_UPDATE,
    service: services?.updatePUT,
  },

  logoutPUT: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_LOGOUT,
    service: services?.logoutPUT,
  },

  dataDELETE: {
    type: substationEntryActionTypes.SUBSTATION_ENTRY_DATA_DELETE,
    service: services?.dataDELETE,
  },

  clearTotal: () => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_TOTAL,
  }),

  clearAllList: () => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_ALL_LIST,
  }),

  // This is a sync action
  setData: (substationEntry: ISubstationEntry) => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_DATA_SET,
    payload: substationEntry,
  }),

  clearError: () => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_ERROR,
  }),

  setOriginalData: (substationEntriesToday: ISubstationEntry[]) => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_ORIGINAL_DATA_SET,
    payload: substationEntriesToday,
  }),

  setFilteredBySubstation: (
    substationEntriesBySubstation: ISubstationEntry[]
  ) => ({
    type: substationEntryActionTypes.SUBSTATION_ENTRY_FILTERED_BY_SUBSTATION_DATA_SET,
    payload: substationEntriesBySubstation,
  }),
};

export type ISubstationEntryAsync = typeof duckActions;

export interface ISubstationEntryState
  extends ICommonState<typeof substationEntryActionTypes> {
  data?: ISubstationEntry;
  list: ISubstationEntry[];
  all: ISubstationEntry[];
  employeeList: ISubstationEntryEmployee[];
  total: number;
  today: number;
  originalList: ISubstationEntry[];
  filteredByStation: ISubstationEntry[];
}

export const defaultState: ISubstationEntryState = {
  status: {},
  list: [],
  all: [],
  employeeList: [],
  total: 0,
  today: 0,
  originalList: [],
  filteredByStation: [],
};

const SubstationEntryReducer = (
  state: ISubstationEntryState,
  action: IReducerAction<ISubstationEntryAsync>
): ISubstationEntryState => {
  switch (action.type) {
    case substationEntryActionTypes.SUBSTATION_ENTRY_DATA_SET:
    case substationEntryActionTypes.SUBSTATION_ENTRY_DATA_READ:
    case substationEntryActionTypes.SUBSTATION_ENTRY_DATA_UPDATE:
    case substationEntryActionTypes.SUBSTATION_ENTRY_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_LIST_READ: {
      const [, today] = action.params || [];

      return {
        ...state,
        list: action.payload?.rows ?? [],
        [today ? 'today' : 'total']: action.payload?.count ?? 0,
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_ALL_LIST_READ: {
      return {
        ...state,
        all: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_USER_LIST: {
      return {
        ...state,
        employeeList: action.payload?.rows ?? [],
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.entryReasonId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_LOGOUT: {
      if (action.params && action.payload) {
        const [id] = action.params;
        const list = [...state.list];
        const index = list.findIndex((value) => value.entryReasonId !== id);

        list.splice(index, 1, action.payload);

        return {
          ...state,
          list,
        };
      }

      return state;
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_ERROR: {
      return {
        ...state,
        status: {},
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_TOTAL: {
      return {
        ...state,
        total: 0,
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_CLEAR_ALL_LIST: {
      return {
        ...state,
        all: [],
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_ORIGINAL_DATA_SET: {
      return {
        ...state,
        originalList: action.payload ?? [],
      };
    }

    case substationEntryActionTypes.SUBSTATION_ENTRY_FILTERED_BY_SUBSTATION_DATA_SET: {
      return {
        ...state,
        filteredByStation: action.payload ?? [],
      };
    }

    default: {
      return state;
    }
  }
};

export default SubstationEntryReducer;
