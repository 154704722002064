import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMIcon,
  ATMLabel,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import {
  AccessRole,
  LERNotificationStatus,
  LERRequestFormStep,
  LERRequestPage,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import {
  getLerRequestPendingCaisoFilters,
  hasEditAccess,
} from 'src/helpers/ler-request.helper';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ILerRequestItem } from 'src/models/ler-request.model';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
  // getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import { getEmployeeName } from 'src/helpers/employee.helper';
import OutageStatus from 'src/components/atoms/outage-status/outage-status.component';
import OutageDay from 'src/components/atoms/outage-day/outage-day.component';
import QueueSummary from 'src/components/molecules/queue-summary/queue-summary.component';
import moment from 'moment';
import LERRequestAssignScheduler from '../../ler-request-assign-scheduler/ler-request-assign-scheduler.component';
import styles from '../../ler-request.module.scss';
import PendingCaisoRemoveFromList from './ler-request-list-pending-caiso-remove-from-list.component';

const LERRequestListPendingCaiso: React.FC = () => {
  const {
    params: { lerView, lerEdit },
    getUrl,
  } = useLocationParams([LERRequestPage.View, LERRequestPage.Edit, 'active']);
  const { state, actions } = useLerRequestContext();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<Partial<IORGDataTableQueryState>>({});

  const handleFetch = useCallback(
    async (
      query: Partial<IORGDataTableQueryState>,
      options?: IORGDataTableUpdateOptions
    ) => {
      if (
        !options ||
        (options && options.action) ||
        !state.pendingCaisoList.length
      ) {
        setLoading(true);
      }

      setParams(query);
      const data = {
        ...query,
        all: hasRole(AccessRole.MCC_OUTAGE_SCHEDULER),
        filters: getLerRequestPendingCaisoFilters(query.filters),
      };

      await actions.pendingCaisoGET(data);

      setLoading(false);
    },
    [actions, setParams, setLoading]
  );

  // This will refresh the list if the detail/edit page is closed
  useEffect(() => {
    if (refresh && !lerView && !lerEdit) {
      const trigger = async () => {
        await handleFetch(params);

        setRefresh(false);
      };

      trigger();
    }
  }, [refresh, lerView, lerEdit, params, setRefresh, handleFetch]);

  useEffect(() => {
    if (lerView || lerEdit) {
      setRefresh(true);
    }
  }, [lerView, lerEdit, setRefresh]);

  const columns: IORGDataTableColumn<ILerRequestItem>[] = [
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (value, values) => (
        <Link
          to={getUrl(
            hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && hasEditAccess(values)
              ? { lerEdit: value, active: LERRequestFormStep.ISO }
              : { lerView: value, active: LERRequestFormStep.ISO }
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      index: 'requestStatus',
      title: 'Request Status',
      render: (_, value) => <OutageStatus data={value} />,
    },
    {
      index: 'crStatus',
      title: 'CR Status',
      render: (_, value) =>
        value.changeReqStat && (
          <ATMLabel className={styles.labelTransparent}>
            {value.changeReqStat}
          </ATMLabel>
        ),
    },
    {
      index: `${LERRequestFormStep.OUTAGE_FACILITY}.facTypId`,
      title: Lang.LBL_AUTHORIZATION_TYPE,
      render: (_, value) => value.outageFacility?.outageType?.outageTypeDesc,
    },
    {
      index: 'requestorName',
      title: Lang.LBL_REQUESTOR_NAME,
      render: (_, value) =>
        value.requestor ? getEmployeeName(value.requestor) : null,
    },
    {
      index: 'outageFacility',
      title: 'Facility Name',
      render: (_, value) => value.outageFacility?.facility?.outgFacNm,
    },
    {
      index: 'substation',
      title: 'Substation',
      render: (_, value) =>
        value.outageFacility?.facility?.facTypId !==
          Lang.FACILITY_TYPE_ID_BACKEND.LINE && value.outageFacility?.substation
          ? value.outageFacility?.substation?.name
          : '',
    },
    {
      index: 'startDate',
      title: 'Start Date',
      render: (_, value) => getLerRequestStartDate(value.outageDates),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => getLerRequestEndDate(value.outageDates),
    },
    {
      index: 'days',
      title: 'Days',
      render: (value, values) => <OutageDay day={value} data={values} />,
    },
    {
      index: 'emailGroups',
      title: 'Pending Email',
      render: (_, value) => {
        if (value.emailGroups && value.emailGroups.length > 0) {
          return value.emailGroups.some(
            (v) => v.status === LERNotificationStatus.PENDING
          ) ? (
            <ATMIcon name="exclamation circle" color="orange" />
          ) : (
            <ATMIcon name="check circle" color="green" />
          );
        }

        return null;
      },
    },
    {
      index: 'assignedTo',
      title: 'Assigned to',
      render: (_, value) => {
        return (
          <>
            <LERRequestAssignScheduler
              requestId={value.requestId}
              employee={value.schedulerAssign || undefined}
              handleSuccess={() => {
                handleFetch(params);
              }}
            />
          </>
        );
      },
    },
    {
      index: 'pendingCaiso',
      title: 'Action',
      cellProps: {
        textAlign: 'center',
      },
      headerProps: {
        textAlign: 'center',
      },
      render: (_, value) => {
        return (
          <PendingCaisoRemoveFromList
            data={value as any}
            handleFetch={() => handleFetch(params)}
          />
        );
      },
    },
  ];

  return (
    <ORGDataTable
      celled={false}
      columns={columns}
      data={
        hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
          ? state.pendingCaisoList.filter(
              (val) =>
                !moment(getLerRequestEndDate(val.outageDates)).isBefore(
                  moment(new Date())
                )
            )
          : state.pendingCaisoList
      }
      onChange={handleFetch}
      loading={loading}
      total={
        hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
          ? state.pendingCaisoList.filter(
              (val) =>
                !moment(getLerRequestEndDate(val.outageDates)).isBefore(
                  moment(new Date())
                )
            ).length
          : state.pendingCaisoTotal
      }
      noDataText={<p>No Pending CAISO Submittal LERs.</p>}
      counter
    >
      {() => ({
        toolbars: {
          caisoLabel: () => <QueueSummary />,
        },
      })}
    </ORGDataTable>
  );
};

export default LERRequestListPendingCaiso;
