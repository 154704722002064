import React, { useState } from 'react';
import { ATMDropdown, ATMIcon } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { AccessRole, AccessType } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import LocalPanel from 'src/components/organisms/local-panel/local-panel.component';
import KeyRequestEmployee from '../../key-request/key-request-employee/key-request-employee.component';
import KeyRequestContractor from '../../key-request/key-request-contractor/key-request-contractor.component';
import KeyRequestList from '../../key-request/key-request-list/key-request-list.component';
import JobCodeRuleList from '../../job-code-rule/job-code-rule-list/job-code-rule-list.component';
import style from './keys.module.scss';
import KeyRequestReport from '../../key-request/key-request-report/key-request-report.component';
import KeyRequestDemand from '../../key-request/key-request-demand/key-request-demand.component';
import JobCodeRuleAdd from '../../job-code-rule/job-code-rule-add/job-code-rule-add.component';
import IssuedKeyList from '../../issued-key/issued-key-list/issued-key-list.component';
import MyKeyRequest from '../../key-request/key-request-requestor-view/key-request-requestor-view.component';

const Keys: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const panes = !getIsAdmin(AccessRole.GCC_KEY_ADMINISTRATOR)
    ? [
        {
          title: Lang.TTL_MY_KEY_REQUEST,
          render: <MyKeyRequest />,
          actions: [
            <KeyRequestEmployee key="1" isRequestor={Boolean('true')} />,
          ],
          permissions: [AccessType.KEYS],
        },
      ]
    : [
        {
          title: Lang.TTL_KEY_REQUESTS,
          render: <KeyRequestList />,
          actions: [
            <ATMDropdown
              open={isOpen}
              className={style.reqDropdown}
              size="small"
              key=""
              trigger={
                <span>
                  <ATMIcon name="key" /> {Lang.LBL_REQUEST_KEY}
                </span>
              }
              onClick={() => setIsOpen(!isOpen)}
            >
              <ATMDropdown.Menu>
                <KeyRequestEmployee
                  isRequestor={false}
                  handleDropdown={setIsOpen}
                />
                <KeyRequestContractor />
                <KeyRequestDemand
                  isDropdownOpen={isOpen}
                  handleDropdown={setIsOpen}
                />
              </ATMDropdown.Menu>
            </ATMDropdown>,
          ],
          permissions: [AccessType.KEYS],
        },
        {
          title: 'Issued Keys',
          render: <IssuedKeyList />,
          permissions: [AccessType.KEYS],
        },
        {
          title: Lang.TTL_KEY_REQUESTOR_REPORT,
          render: <KeyRequestReport />,
          permissions: [AccessType.KEYS],
        },
        {
          title: Lang.TTL_JOB_CODE_RULE,
          render: <JobCodeRuleList />,
          actions: [<JobCodeRuleAdd key="add" />],
          permissions: [AccessType.KEYS],
        },
      ];

  return <LocalPanel name="tab" panes={panes} />;
};

export default Keys;
