export enum RoleEmpHRStatus {
  INACTIVE = 'I',
  ACTIVE = 'A',
}

export enum RoleEmpRoleStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export enum RolesContentAccess {
  AUTH_LIST = 'Auth List',
  ROLES = 'Roles',
  KEYS = 'Keys',
  SUBSTATION_ENTRY_LOG = 'Substation Entry Log',
  OUTAGE_PLANNING = 'Outage Planning',
  REAL_TIME_LOG = 'Real-time Log',
  TROUBLE_TICKET = 'Trouble Ticket',
  TROUBLE_JOB = 'Trouble Job',
}

export enum RoleFilterLabel {
  EMPID = 'Employee',
  EMPLOYEESTAT = 'HR Status',
  ROLEID = 'Role',
  ROLESTATUS = 'Role Status'
}

export const roleEmpHRStatus = RoleEmpHRStatus;
export const roleEmpRoleStatus = RoleEmpRoleStatus;
