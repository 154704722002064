import ENV from 'src/constants/env.constants';

export const getFileType = (filename = '') => {
  const type = filename.split('.');

  return type[type.length - 1].toLowerCase();
};

export const getFileUrl = (filename = '') =>
  `${ENV.VITE_API_ENDPOINT_COMMON}/files/stgyrd/${filename}`;
