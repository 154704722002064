import React, { useCallback, useState } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import Access, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { LERRequestAction, LERRequestStatus } from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { isLerRequestExpired } from 'src/helpers/ler-request.helper';
import history from 'src/history';
import Lang from 'src/libraries/language';
import { ILerRequest } from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';

type IProps = {
  data: ILerRequest;
  trigger?: React.ReactNode;
  handleSuccess?: () => Promise<void> | void;
};

const LerRequestStudy: React.FC<IProps> = ({
  data,
  trigger = <ATMButton>{Lang.LBL_SET_STUDY}</ATMButton>,
  handleSuccess,
}) => {
  const { state, actions } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_STATUS_UPDATE
  );

  const handleSubmit = useCallback(async () => {
    const result = await actions.statusPUT(
      data.requestId,
      LERRequestAction.Study
    );

    if (result.payload) {
      ToastSuccess(Lang.MSG_LER_REQUEST_STUDY_SUCCESS);
      setIsOpen(false);
      history.push('/outage-planning');

      if (handleSuccess) {
        handleSuccess();
      }
    }
  }, [data, actions, setIsOpen, handleSuccess]);

  return (
    <Access
      type={AccessType.OUTAGE_REQUEST_STUDY}
      when={
        [LERRequestStatus.Approved, LERRequestStatus.Submitted].includes(
          data.requestStat as LERRequestStatus
        ) && !isLerRequestExpired(data.outageDates)
      }
    >
      <Confirm
        size="tiny"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        header={Lang.TTL_LER_REQUEST_STUDY}
        content={Lang.MSG_LER_REQUEST_STUDY_CONFIRM}
        trigger={trigger}
        loading={status.fetching}
        onConfirm={handleSubmit}
      />
    </Access>
  );
};

export default LerRequestStudy;
