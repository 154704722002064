import React from 'react';
import { ATMTab, IATMTabProps } from 'shared-it-appmod-ui';
import classNames from 'classnames';
import useStatusTab, { useStatusTabV2 } from 'src/hooks/status-tab.hook';
import styles from './tabs.module.scss';

type IProps = IATMTabProps & {
  name: string;
  isClean?: boolean;
};

const Tabs: React.FC<IProps> = ({ name, isClean = false, ...props }) => {
  const { currentTab, handleTabChange } = useStatusTabV2(name);
  const {
    currentTab: defaultCurrentTab,
    handleTabChange: defaultHandleTabChange,
  } = useStatusTab(name);

  if (!isClean) {
    return (
      <div className={styles.container}>
        <ATMTab
          {...props}
          activeIndex={defaultCurrentTab}
          className={classNames('tab-menu', props.className)}
          onTabChange={(event, data) => {
            defaultHandleTabChange(data.activeIndex);
            if (props.onTabChange) {
              props.onTabChange(event, data);
            }
          }}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <ATMTab
        {...props}
        activeIndex={currentTab}
        className={classNames('tab-menu', props.className)}
        onTabChange={(event, data) => {
          handleTabChange(data.activeIndex);
          if (props.onTabChange) {
            props.onTabChange(event, data);
          }
        }}
      />
    </div>
  );
};

export default Tabs;
