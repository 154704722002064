import React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect,
  Switch,
} from 'react-router-dom';
import { SemanticICONS } from 'semantic-ui-react';
import { hasAccess, IHasAccessType } from './access.library';
import Lang from './language';

export interface IRoute extends RouteProps {
  path?: string;
  component?: React.FC<RouteComponentProps<any> | any>;
  layout?: React.FunctionComponent;
  icon?: SemanticICONS;
  label?: string;
  to?: string;
  tooltip?: any;
  childRoutes?: Omit<IRoute, 'layout'>[];
  href?: string;
  permission?: IHasAccessType;
}

type IRouteNode = {
  layout: string;
  path?: string;
  component: React.FunctionComponent;
  children: React.ReactNode[];
};

type IDefaultPage = {
  Error404: React.FC;
  Error403?: React.FC;
};

const createRoute = (
  params: IRoute,
  DefaultPage?: React.FC,
  children?: (IRouteNode | JSX.Element)[]
) => {
  const {
    permission,
    childRoutes,
    layout,
    component: Component,
    ...route
  } = params;

  return (
    <Route
      key={route.path}
      {...route}
      render={(props) => {
        document.title = route.label
          ? `${route.label} - ${Lang.TTL_APPLICATION}`
          : Lang.TTL_APPLICATION;

        if (
          permission === undefined ||
          (permission !== undefined && hasAccess(permission))
        ) {
          return (
            Component && (
              <>
                <Component {...props} />
                {children}
              </>
            )
          );
        }

        if (DefaultPage) {
          return <DefaultPage />;
        }

        return null;
      }}
    />
  );
};

const renderRouter = (route: IRoute, defaultPage: IDefaultPage) => {
  if (route.layout) {
    const node: IRouteNode = {
      layout: route.layout.displayName ?? route.layout.name,
      path: route.path,
      component: route.layout,
      children: [] as any,
    };

    // Main component handler
    if (route.path && route.component && !route.to) {
      let children;

      if (route.childRoutes && route.exact === false) {
        children = route.childRoutes.map((value) =>
          renderRouter(
            {
              ...value,
              permission: [
                ...(Array.isArray(route?.permission) ? route?.permission : []),
                ...(Array.isArray(value?.permission) ? value?.permission : []),
              ],
            } as IRoute,
            defaultPage
          )
        );
      }
      node.children.push(createRoute(route, defaultPage.Error403, children));
    } else if (route.path && route.to) {
      node.children.push(
        <Redirect key={route.path} from={route.path} to={route.to} exact />
      );
    }

    if (route.childRoutes && route.exact !== false) {
      node.children = node.children.concat(
        route.childRoutes.map((value) =>
          renderRouter(
            {
              ...value,
              permission: [
                ...(Array.isArray(route?.permission) ? route?.permission : []),
                ...(Array.isArray(value?.permission) ? value?.permission : []),
              ],
            } as IRoute,
            defaultPage
          )
        )
      );
    }

    return node;
  }

  if (route.path && route.to) {
    return <Redirect key={route.path} from={route.path} to={route.to} exact />;
  }

  return createRoute(route, defaultPage.Error403);
};

const routerFactory = (
  routes: IRoute[],
  defaultPage: IDefaultPage
): React.ReactNode => {
  const result = routes.map((route) =>
    renderRouter(route, defaultPage)
  ) as IRouteNode[];

  const list: Record<string, Omit<IRouteNode, 'layout'>> = result.reduce(
    (items, item) => {
      if (!items[item.layout]) {
        return {
          ...items,
          [item.layout]: {
            ...item,
            exact: true,
            path: item.path ?? '/', // We will get the shortest path as the main for the group
          },
        };
      }

      let children: React.ReactNode[] = [];

      if (
        Array.isArray(items[item.layout].children) &&
        Array.isArray(item.children)
      ) {
        children = [...items[item.layout].children, ...item.children];
      } else {
        children.push(item.children);
      }

      return {
        ...items,
        [item.layout]: {
          ...items[item.layout],
          path:
            item.path && item.path.length < items[item.layout].path.length
              ? item.path
              : items[item.layout].path,
          children,
        },
      };
    },
    {}
  );

  return Object.entries(list).map(([key, value]: [string, any]) => {
    if (value.component) {
      return (
        <Route key={key} path={value.path}>
          <value.component>
            <Switch>
              {value.children.reverse()}
              <Route component={defaultPage.Error404} />
            </Switch>
          </value.component>
        </Route>
      );
    }

    return null;
  });
};

export default routerFactory;
