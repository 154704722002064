export enum SuspensionStatus {
  SUSPENDED = 'Suspended',
  NOT_AUTHORIZED = 'Not Authorized',
  AUTHORIZED = 'Authorized',
  EXPIRED = 'Expired',
}

export enum AuthListType {
  Training = 'Auth Training',
  FieldSwitching = 'Field Switching',
  SubEntry = 'Sub Entry',
  SubSwitching = 'Sub Switching',
}

export enum AuthType {
  AUTH_TRAINING = 'Auth Training',
  FIELD_SWITCHING = 'Field Switching',
  SUB_ENTRY = 'Sub Entry',
  SUB_SWITCHING = 'Sub Switching',
}

export enum EmployeeStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum SuspensionEvent {
  SUSPENDED = 'Suspended',
  SUSPENSION_LIFTED = 'Suspension Lifted',
}

export enum FailedTest {
  FAILED = 'Failed',
}

export const suspensionStatus = SuspensionStatus;
