import React, { useCallback } from 'react';
import { ATMField, ATMInput } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment, { format24hTime } from 'src/libraries/moment.library';
import { ISortResponse } from 'src/models/switching.model';
import { ILerRequestForm } from 'src/models/ler-request.model';
import styles from './input-format.module.scss';
import LabelRequired from '../label-required/label-required.component';

type IProp = {
  label?: any;
  name?: any;
  control: any;
  error?: any;
  icon?: any;
  pattern?: any;
  style?: any;
  index: number;
  withLabel?: any;
  maxLength?: any;
  inputValue: any;
  defaultValue?: any;
  errorMessage?: any;
  noMargin?: boolean;
  dateSelected?: any;
  resetValue?: any;
  contextType?: string;
  disabled?: boolean;
  isStartTime?: boolean;
  enableRequired?: boolean;
  readOnly?: boolean;
  emptyTimeFields?: any[];
  handleEmptyValue: (
    timeIndex: any,
    timeValue: any,
    record: ISortResponse
  ) => void;
  setEmptyTimeFields?: (param: any[]) => void;
  callback?: () => void;
  reset?: () => void | undefined;
  setValue: (name: any, value: any) => void;
  setError: (name: any, value: any) => void;
  getValues: () => ILerRequestForm;
  handleEnable?: (param: any) => void;
  update: (index: number, value: any) => void;
  register: React.Dispatch<React.SetStateAction<any>>;
  clearErrors: React.Dispatch<React.SetStateAction<any>>;
};

export enum HourTimePattern {
  HOUR_TIME_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ as any,
}

export enum HourPattern {
  HOUR_PATTERN = /^(0[0-9]|1[0-9]|2[0-3])$/ as any,
}

export const restrictAlphabetsAndSpecialChars = (value) => {
  const newValue = value
    ?.toString()
    .replace(/[a-zA-Z&,!@#$%^&*()_+=;""?/{}|]/g, '');
  return newValue;
};

export const formatTimeValue = (value = '', originalValue = '') => {
  let formattedValue = value;
  if (value.length > originalValue.length) {
    formattedValue = (value ?? '').replace(/[^\d]/g, '').trim();

    if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
      return '';
    }
  }
  return formattedValue;
};

export const TimeInput: React.FC<IProp> = ({
  name,
  index,
  disabled,
  noMargin,
  maxLength,
  readOnly,
  errorMessage,
  update,
  setValue,
  getValues,
  setError,
  clearErrors,
  handleEmptyValue,
}) => {
  const handleSwitchingTimeInput = useCallback(
    (param: any, indexValue: any) => {
      const timeVal: any = param;
      const sortRecord = getValues()?.sortResponses?.[indexValue];
      if (timeVal && timeVal.length > 4) {
        if (timeVal.match(HourTimePattern.HOUR_TIME_PATTERN)) {
          const [hour, minute] = timeVal && timeVal.split(':');
          update(indexValue, {
            ...sortRecord,
            time: timeVal,
            sortTime: Moment(sortRecord?.sortTime)
              .set({
                hour,
                minute,
              })
              .toDate(),
          });
        }
      }
      handleEmptyValue(indexValue, timeVal, sortRecord as any);
    },
    [handleEmptyValue, update, getValues]
  );

  const handleInput = useCallback(
    (param: any, indexValue: number) => {
      const value = param ? param.target.value : undefined;
      if (!value) {
        clearErrors(name);
      }
      const result = restrictAlphabetsAndSpecialChars(value);
      if (value) {
        if (result && result.length === 2) {
          if (result.match(HourPattern.HOUR_PATTERN)) {
            clearErrors(name);
          } else {
            setValue(name, '');
            handleSwitchingTimeInput(undefined, indexValue);
            setError(name, {
              message: errorMessage || 'Invalid Time',
            });
          }
        }
        if (result && result.length >= 4) {
          if (result && result.length) {
            let time = result;
            if (result.match(/\d{4}/g)) {
              time = result.match(/\d{2}/g).join(':');
            }
            if (time.match(HourTimePattern.HOUR_TIME_PATTERN)) {
              clearErrors(name);
              setValue(name, time);
              handleSwitchingTimeInput(time, indexValue);
            } else {
              setValue(name, '');
              handleSwitchingTimeInput(undefined, indexValue);
              setError(name, {
                message: errorMessage || 'Invalid Time',
              });
            }
          }
        }
      }
      if (param) {
        param?.currentTarget?.setSelectionRange(result.length, result.length);
      }
      return result;
    },
    [clearErrors, setError, setValue, name]
  );

  return (
    <>
      <span className={noMargin ? styles.noMargin : styles.defaultStyle}>
        <ATMInput
          key={name}
          value={format24hTime(getValues()?.sortResponses?.[index]?.sortTime)}
          onKeyUp={(e) => handleInput(e, index)}
          onChange={(e) => {
            e.preventDefault();
            const { value } = e.target;
            let result;
            if (!value) {
              setValue(name, undefined);
              handleInput(undefined, index);
              handleSwitchingTimeInput(undefined, index);
            }
            // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
            if (value.match(HourTimePattern.HOUR_TIME_PATTERN as any)) {
              handleSwitchingTimeInput(value, index);
            } else {
              result =
                value.length >= 4
                  ? (value as any).match(/\d{2}/g).join(':')
                  : value;
              handleSwitchingTimeInput(undefined, index);
            }
            const resultValue = result ?? value;
            e?.currentTarget?.setSelectionRange(
              resultValue.length,
              resultValue.length
            );
            return restrictAlphabetsAndSpecialChars(resultValue);
          }}
          placeholder="hh:mm"
          maxLength={maxLength}
          clearable
          name={name}
          readOnly={readOnly}
          disabled={disabled}
        />
      </span>
    </>
  );
};

export const TimeHourMinuteSwitchingInput: React.FC<IProp> = ({
  label,
  error,
  name,
  control,
  index,
  withLabel = true,
  contextType,
  inputValue,
  readOnly = false,
  noMargin = false,
  disabled = false,
  enableRequired = true,
  getValues,
  reset,
  update,
  setValue,
  setError,
  register,
  clearErrors,
  handleEmptyValue,
}) => {
  return (
    <ATMField
      as={TimeInput}
      name={name}
      className={noMargin ? styles.noMargin : styles.defaultStyle}
      label={
        // eslint-disable-next-line no-nested-ternary
        enableRequired ? (
          <span className={noMargin ? styles.noMargin : styles.defaultStyle}>
            <LabelRequired>{label || Lang.LBL_TIME}</LabelRequired>
          </span>
        ) : !withLabel ? (
          ''
        ) : (
          label || Lang.LBL_TIME
        )
      }
      clearable
      index={index}
      readOnly={readOnly}
      noMargin={noMargin}
      control={control}
      maxLength={5}
      error={error}
      value={inputValue}
      inputValue={inputValue}
      contextType={contextType}
      disabled={disabled}
      update={update}
      reset={reset}
      getValues={getValues}
      clearErrors={clearErrors}
      setValue={setValue}
      setError={setError}
      register={register}
      handleEmptyValue={handleEmptyValue}
    />
  );
};

export default TimeHourMinuteSwitchingInput;
