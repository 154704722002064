import { REGEX_24HR_TIME } from 'src/constants';
import yup from 'src/libraries/validator.library';
import { EmployeeShape } from './employee.model';
import { FacilityShape } from './facility.model';
import { TransmissionOutageShape } from './ler-request.model';
import { OutageTypeShape } from './outage-type.model';
import { VoltShape } from './volt.model';
import { SortResponseSchema } from './switching.model';
import { NoteSchema } from './note.model';

export const RadializedSubstationRequestShape = {
  date: yup.date().required(),
};

export const SystemChangesRequestShape = {
  dateRange: yup,
};

export const DailyReportsSelectedDateShape = {
  viewType: yup.string().required(),
  date: yup.date().required(),
};

export const OutageDateShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  reqStatId: yup.number().required(),
  startTm: yup.date().required(),
  stopTm: yup.date().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  significantCostInd: yup.string().nullable(),
  actualStrtTm: yup.date().nullable(),
  actualStopTm: yup.date().nullable(),
  actualSwitchInTm: yup.date().nullable(),
  actualSwitchOutTm: yup.date().nullable(),
};

export const DailyOutageRptNotesShape = {
  requestId: yup.number().required(),
  notesId: yup.number().required(),
  version_no: yup.string(),
  notes: yup.string().required(),
  isComplete: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const RequestFacilityShape = {
  requestId: yup.number().nullable(),
  version_no: yup.string().nullable(),
  outgFacId: yup.number().nullable(),
  relayNbrs: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
  voltId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  outgTypId: yup.number().nullable(),
};

export const OutageRequestShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  schedulerAssign: yup.string().nullable(),
  subGrpNm: yup.string().nullable(),
  requestStat: yup.number().nullable(),
  changeReqStat: yup.string().nullable(),
  crewId: yup.string().nullable(),
  requestorId: yup.string().required(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  requestorFax: yup.string().nullable(),
  inclmntWthrInd: yup.boolean().nullable(),
  clsdGrndDsconctInd: yup.boolean().nullable(),
  authStmnt: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  woNbr: yup.string().nullable(),
  phsReqInd: yup.boolean().nullable(),
  jobLoc: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  crewPagerNbr: yup.string().nullable(),
  chngRsn: yup.string().nullable(),
  sdgeNotes: yup.string().nullable(),
  cnclTypId: yup.number().nullable(),
  crewCellNbr: yup.string().nullable(),
  fldckBy: yup.string().nullable(),
  primIsoOutgTypId: yup.number().nullable(),
  secondIsoOutgTypId: yup.number().nullable(),
  crewTruckNbr: yup.string().nullable(),
  cnclBy: yup.string().nullable(),
  cnclAt: yup.date().nullable(),
  switchOutMin: yup.number().nullable(),
  switchInMin: yup.number().nullable(),
  outgCategNm: yup.string().default('LER'),
  plngNoteId: yup.number().nullable(),
  revwrNoteId: yup.number().nullable(),
  sysChngSummaryId: yup.number().nullable(),
  isoTermTyp: yup.string().nullable(),
  significantCostNoteId: yup.number().nullable(),
  cnclNoteId: yup.number().nullable(),
  acceptedBy: yup.string().nullable(),
  supvId: yup.string().nullable(),
  isolationPts: yup.string().nullable(),
  testVoltageApplied: yup.boolean().nullable(),
  isoWorkKindId: yup.string().nullable(),
  primIsoCauseCode: yup.string().nullable(),
  secondIsoCauseCode: yup.string().nullable(),
  omsUseFacilityModelInd: yup.string().nullable(),
  affectsRasSpsInd: yup.boolean().nullable(),
  rimsProjectId: yup.string().nullable(),
  rimsProjectPhase: yup.string().nullable(),
  splitSaidiCalcInd: yup.boolean().nullable(),
  outOfServiceRasSpsInd: yup.string().nullable(),
  redundancyRasSpsInd: yup.string().nullable(),
  protectionZoneInd: yup.string().nullable(),
  outageEmsIccpInd: yup.string().nullable(),
  grpId: yup.number().nullable(),
  sysChngInd: yup.string().nullable(),
  firstSubDate: yup.date().nullable(),
  lastSubDate: yup.date().nullable(),
  lastAccDate: yup.date().nullable(),
  jobNotes: yup.string().nullable(),
  contingencyPlanInd: yup.string().nullable(),
};

export const DailyReportShape = {
  requestId: yup.number().required(),
  omsId: yup.string().nullable(),
  voltNm: yup.string().nullable(),
  dsplyOrd: yup.number().nullable(),
  startTmTxt: yup.string().required(),
  stopTmTxt: yup.string().required(),
  startTm: yup.date().required(),
  stopTm: yup.date().required(),
  actualSwitchInTm: yup.date().nullable(),
  actualSwitchOutTm: yup.date().nullable(),
  actualStrtTm: yup.date().nullable(),
  actualStopTm: yup.date().nullable(),
  etr: yup.string().required(),
  outgFacNm: yup.string().required(),
  outageTypeDesc: yup.string().nullable(),
  crewName: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  significantCostInd: yup.string().nullable(),
  testVoltageApplied: yup.boolean().nullable(),
  farFan: yup.string().nullable(),
  outageStatus: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  dailyNotes: yup.array().of(yup.string().required()).required(),
  actions: yup.array().of(yup.string().required()).required(),
  requestStat: yup.number().nullable(),
  transmissionOutage: yup.object(TransmissionOutageShape).nullable(),
  isolationPoints: yup.string().nullable(),
  woNbr: yup.string().nullable(),
  jobLoc: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  authStmnt: yup.string().nullable(),
  crewCellNbr: yup.string().nullable(),
  requestorName: yup.string().nullable(),
  requestorCellNbr: yup.string().nullable(),
  fldckBy: yup.string().nullable(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  acceptorName: yup.string().nullable(),
  acceptorCellNbr: yup.string().nullable(),
  grpId: yup.number().nullable(),
  clsdGrndDsconctInd: yup.boolean().nullable(),
  sortResponses: yup.array().of(SortResponseSchema).nullable(),
  cancelNote: NoteSchema.nullable(),
  sysChngInd: yup.boolean().nullable(),
  sysChngLastDate: yup.date().nullable(),
  sysChngSummary: NoteSchema.nullable(),
  repeatJob: yup.boolean().nullable(),
};

export const LerReportShape = {
  requestId: yup.number().required(),
  omsId: yup.string().nullable(),
  voltNm: yup.string().nullable(),
  dsplyOrd: yup.number().nullable(),
  startTmTxt: yup.string().nullable(),
  stopTmTxt: yup.string().nullable(),
  startTm: yup.date().nullable(),
  stopTm: yup.date().nullable(),
  actualSwitchInTm: yup.date().nullable(),
  actualSwitchOutTm: yup.date().nullable(),
  actualStrtTm: yup.date().nullable(),
  actualStopTm: yup.date().nullable(),
  etr: yup.string().nullable(),
  outgFacNm: yup.string().required(),
  outageTypeDesc: yup.string().nullable(),
  crewName: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  significantCostInd: yup.string().nullable(),
  testVoltageApplied: yup.boolean().nullable(),
  farFan: yup.string().nullable(),
  outageStatus: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  dailyNotes: yup.array().of(yup.string().required()).required(),
  // actions: yup.array().of(yup.string().required()).required(),
  requestStat: yup.number().nullable(),
  transmissionOutage: yup.object(TransmissionOutageShape).nullable(),
  isolationPoints: yup.string().nullable(),
  woNbr: yup.string().nullable(),
  jobLoc: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  authStmnt: yup.string().nullable(),
  crewCellNbr: yup.string().nullable(),
  requestorName: yup.string().nullable(),
  requestorCellNbr: yup.string().nullable(),
  fldckBy: yup.string().nullable(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  acceptorName: yup.string().nullable(),
  acceptorCellNbr: yup.string().nullable(),
  grpId: yup.number().nullable(),
  clsdGrndDsconctInd: yup.boolean().nullable(),
  sortResponses: yup.array().of(SortResponseSchema).nullable(),
  cancelNote: NoteSchema.nullable(),
  sysChngInd: yup.boolean().nullable(),
  sysChngLastDate: yup.date().nullable(),
  sysChngSummary: NoteSchema.nullable(),
  repeatJob: yup.boolean().nullable(),
};

export const DailyReportUpdateShape = {
  requestId: yup.number().required(),
  omsId: yup.string().nullable(),
  inTm: yup.date(),
  outTm: yup.date(),
  inTime: yup.string().when('inTm', {
    is: (value) => value,
    then: yup.string().matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
  }),
  outTime: yup.string().when('outTm', {
    is: (value) => value,
    then: yup.string().matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
  }),
  startTm: yup.date().required(),
  stopTm: yup.date().required(),
  action: yup.string().required(),
  transmissionOutage: yup.mixed().nullable(),
};

export const OutageRequestDailyReportShape = {
  ...OutageRequestShape,
  outageFacility: yup
    .object({
      ...RequestFacilityShape,
      voltage: yup.object(VoltShape).nullable(),
      outageType: yup.object(OutageTypeShape).nullable(),
      facility: yup.object(FacilityShape).required(),
    })
    .required(),
  outageDateStop: yup.object(OutageDateShape).required(),
  dailyNotes: yup
    .array()
    .of(yup.object(DailyOutageRptNotesShape).required())
    .required(),
  crew: yup.object(EmployeeShape).nullable(),
};

export const OutageRequestDailyReportSimpleShape = {
  ...OutageRequestShape,
  outageDates: yup.array(yup.object(OutageDateShape).required()).required(),
  outageDateStop: yup.object(OutageDateShape).required(),
};

export const DailyReportFilterShape = {
  date: yup.date().required(),
};

export const DailyReportVoltageGroupShape = {
  voltNm: yup.string().nullable(),
  dsplyOrd: yup.number().nullable(),
  dailyReports: yup
    .array()
    .of(yup.object(DailyReportShape).required())
    .required(),
};

export const LerReportVoltageGroupShape = {
  voltNm: yup.string().nullable(),
  dsplyOrd: yup.number().nullable(),
  lerReports: yup.array().of(yup.object(LerReportShape).required()).required(),
};

export const RadializedOutageFacilityShape = {
  ...RequestFacilityShape,
  facility: yup
    .object({
      outgFacId: yup.number().nullable(),
      outgFacNm: yup.string().nullable(),
    })
    .nullable(),
  outageType: yup
    .object({ outageTypeDesc: yup.string().nullable() })
    .nullable(),
  voltage: yup
    .object({
      voltNm: yup.string().nullable(),
    })
    .nullable(),
};

export const ETSShape = {
  location: yup.array().of(yup.string()),
  start_time: yup.array().of(yup.string()),
  area: yup.array().of(yup.string()),
  order_number: yup.array().of(yup.number()),
  status: yup.array().of(yup.string()),
  ets: yup.array().of(yup.string()),
  works: yup.array().of(yup.string()),
  wo: yup.array().of(yup.string()),
  requestId: yup.number().required(),
  outBack: yup.string().required(),
};

export const ETTSPayloadShape = {
  sortId: yup.string().nullable(),
  outBack: yup.string().nullable(),
  sortTime: yup.date().nullable(),
  dspchRem: yup.string().nullable(),
  sortStat: yup.string().nullable(),
  etmNbr: yup.number().nullable(),
  byAt: yup.string().nullable(),
  tlSub: yup.string().nullable(),
  subPole: yup.string().nullable(),
  jobKind: yup.string().nullable(),
  jobNbr: yup.string().nullable(),
  jobCd: yup.string().nullable(),
  subDistId: yup.string().nullable(),
  outageFacility: yup.object(RadializedOutageFacilityShape).nullable(),
  outgFacId: yup.number().nullable(),
  switchId: yup.number().nullable(),
  poleId: yup.string().nullable(),
  substationId: yup.string().nullable(),
  troubleJobId: yup.number().nullable(),
  requestId: yup.number().nullable(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
};
export const ETTSPayloadSchema = yup.object(ETTSPayloadShape);

export const ETSListSchema = yup.lazy((items: any[]) =>
  yup
    .object(
      Object.keys(items).reduce(
        (newMap, key) => ({
          ...newMap,
          [key]: yup.array().of(ETTSPayloadSchema.clone().nullable()),
        }),
        {}
      )
    )
    .nullable()
);

export const DailyETSReportShape = {
  out: ETSListSchema,
  back: ETSListSchema,
};

export const RadializedShape = {
  subs: yup.array().of(yup.string()),
  start_time: yup.array().of(yup.string()),
  stop_time: yup.array().of(yup.string()),
  etr: yup.array().of(yup.string()),
  voltage: yup.array().of(yup.number()),
  request_facility: yup.array().of(yup.string()),
  work_description: yup.array().of(yup.string()),
  recall_time: yup.array().of(yup.number()),
  contingency_plan: yup.array().of(yup.string()),
  count: yup.array().of(yup.number()),
  tsaidi: yup.array().of(yup.number()),
  outageDate: yup.array().of(yup.string()),
  requestId: yup.array().of(yup.string()),
};

export const RadializedSubstationShape = {
  requestId: yup.number().required(),
  substationId: yup.string().required(),
  version_no: yup.string().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const RadializedResponsePayloadShape = {
  splitSaidiCalcInd: yup.boolean().nullable(),
  contingencyPlanInd: yup.boolean().nullable(),
  wrkDesc: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  switchOutMin: yup.number().nullable(),
  switchInMin: yup.number().nullable(),
  outageFacility: yup.object(RadializedOutageFacilityShape),
  outageDates: yup
    .array()
    .of(
      yup.object({
        requestId: yup.number().required(),
        version_no: yup.string().required(),
        reqStatId: yup.number().required(),
        startTm: yup.date().required(),
        stopTm: yup.date().required(),
        updatedAt: yup.date().required(),
        updatedBy: yup.string().required(),
        significantCostInd: yup.string().nullable(),
        actualStrtTm: yup.date().nullable(),
        actualStopTm: yup.date().nullable(),
        actualSwitchInTm: yup.date().nullable(),
        actualSwitchOutTm: yup.date().nullable(),
      })
    )
    .required(),
  outageDateStop: yup.object({
    ...OutageDateShape,
    startTmTxt: yup.string().nullable(),
    stopTmTxt: yup.string().nullable(),
  }),
  radializedSubs: yup.object({
    ...RadializedSubstationShape,
    saidImpact: yup.number().required(),
    substation: yup.object({
      substationId: yup.string().nullable(),
      name: yup.string().nullable(),
    }),
    substationCnt: yup.object({
      custCnt: yup.number().required(),
    }),
  }),
};

export const RadializedReportShape = {
  data: yup
    .array()
    .of(
      yup.object({
        data: yup.object().required(),
        length: yup.number().required(),
      })
    )
    .required(),
};

export const RadializedReportSchema = yup.object(RadializedReportShape);

export const DailyETSReportSchema = yup.object(DailyETSReportShape);

export const ETSSchema = yup.object(ETSShape);

export const ETSResponseSchema = yup.array().of(ETSSchema.clone());

export const ETSPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: ETSResponseSchema.clone(),
});

export const RadializedSubstationRequestSchema = yup.object(
  RadializedSubstationRequestShape
);

export const DailyReportsSelectedDateSchema = yup.object(
  DailyReportsSelectedDateShape
);

export const RadializedSchema = yup.object(RadializedShape);

export const RadializedResponsePayloadSchema = yup
  .array()
  .of(yup.object(RadializedResponsePayloadShape));

export const RadializedPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(RadializedResponsePayloadSchema.clone()),
});

export const DailyReportSchema = yup.object(DailyReportShape);
export const LerReportSchema = yup.object(LerReportShape);
export const DailyReportVoltageGroupSchema = yup.object(
  DailyReportVoltageGroupShape
);
export const LerReportVoltageGroupSchema = yup.object(
  LerReportVoltageGroupShape
);
export const DailyReportFilterSchema = yup.object(DailyReportFilterShape);
export const OutageRequestDailyReportSchema = yup.object(
  OutageRequestDailyReportShape
);
export const DailyReportUpdateSchema = yup.object(DailyReportUpdateShape);
export const OutageRequestDailyReportSimpleSchema = yup.object(
  OutageRequestDailyReportSimpleShape
);

export const OutageRequestDailyReportPayloadSchema = yup.object({
  data: yup.array().of(DailyReportVoltageGroupSchema.clone()),
  error: yup.string().nullable(),
});

export const OutageRequestLerReportPayloadSchema = yup.object({
  data: yup.array().of(LerReportVoltageGroupSchema.clone()),
  error: yup.string().nullable(),
});

export const DailyReportUpdatePayloadSchema = yup.object({
  message: yup.string().required(),
});

export type IETS = yup.Asserts<typeof ETSSchema>;
export type IETSPayload = yup.Asserts<typeof ETSResponseSchema>;
export type IETSResponsePayload = yup.Asserts<typeof DailyETSReportSchema>;
export type IRadialized = yup.Asserts<typeof RadializedSchema>;
export type IRadializedPayload = yup.Asserts<typeof RadializedPayloadSchema>;
export type IRadializedSubstationRequest = yup.Asserts<
  typeof RadializedSubstationRequestSchema
>;
export type IDailyReportsSelectedDate = yup.Asserts<
  typeof DailyReportsSelectedDateSchema
>;
export type IRadializedResponsePayload = yup.Asserts<
  typeof RadializedResponsePayloadSchema
>;

export type IDailyReport = yup.Asserts<typeof DailyReportSchema>;
export type ILerReport = yup.Asserts<typeof LerReportSchema>;
export type IDailyReportVoltageGroup = yup.Asserts<
  typeof DailyReportVoltageGroupSchema
>;
export type ILerReportVoltageGroup = yup.Asserts<
  typeof LerReportVoltageGroupSchema
>;
export type IDailyReportFilter = yup.Asserts<typeof DailyReportFilterSchema>;
export type IOutageRequestDailyReport = yup.Asserts<
  typeof OutageRequestDailyReportSchema
>;
export type IDailyReportUpdate = yup.Asserts<typeof DailyReportUpdateSchema>;
export type IOutageRequestDailyReportSimple = yup.Asserts<
  typeof OutageRequestDailyReportSimpleSchema
>;
export type IETTSPayload = yup.Asserts<typeof ETTSPayloadSchema>;
export type IETSList = yup.Asserts<typeof ETSListSchema>;
export type IRadializedReport = yup.Asserts<typeof RadializedReportSchema>;
