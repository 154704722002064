import React, { useCallback, useEffect, useState } from 'react';
import Lang from 'src/libraries/language';
import Moment, { formatDateTime } from 'src/libraries/moment.library';
import { useRejectedReportContext } from 'src/contexts/rejected-report.context';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { rejectedReportActionTypes } from 'src/ducks/rejected-report.duck';
import { getRejectedReportStatus } from 'src/selectors/rejected-report.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import {
  ToastError,
  ToastWarning,
} from 'src/components/atoms/toaster/toaster.component';
import { checkValue } from 'src/libraries/common.library';
import { formatSummaryData } from './rejected-summary-report/rejected-summary-report.component';
import RejectedReportView from './rejected-report.view';

const RejectedReport: React.FC = () => {
  const { state, actions } = useRejectedReportContext();
  const {
    state: { employeeList },
    actions: lerRequestActions,
  } = useLerRequestContext();
  const [overviewParams, setOverviewParams] = useState<Record<any, any>>({});
  const [summaryParams, setSummaryParams] = useState<Record<any, any>>({});
  const [downloading, setDownloading] = useState<boolean>(false);
  const [showNoRecord, setShowNoRecord] = useState<boolean>(false);
  const overviewLoading = getRejectedReportStatus(
    state,
    rejectedReportActionTypes.REJECTED_OVERVIEW_REPORT_LIST_READ
  ).fetching;
  const summaryLoading = getRejectedReportStatus(
    state,
    rejectedReportActionTypes.REJECTED_SUMMARY_REPORT_LIST_READ
  ).fetching;

  useEffect(() => {
    if (!employeeList.length) {
      lerRequestActions.employeeListGET();
    }
  }, [employeeList, lerRequestActions]);

  const handleOverviewFetch = useCallback(
    async (clear: boolean | undefined) => {
      if (clear) {
        setOverviewParams({});
        const response = await actions.overviewListGET({ page: 1, limit: 0 });
        if (!response.error && response.payload?.rows?.length === 0) {
          setShowNoRecord(true);
        } else if (response.error) {
          ToastError('Error retrieving Rejected Overview Report records.');
        }
      } else {
        if (overviewParams.rejectorId === '') {
          delete overviewParams.rejectorId;
        }
        if (overviewParams.supvId === '') {
          delete overviewParams.supvId;
        }
        if (overviewParams.requestorId === '') {
          delete overviewParams.requestorId;
        }
        if (overviewParams.dateRange == null) {
          delete overviewParams.dateRange;
        }
        const filters: any[] = Object.entries(overviewParams).map(
          ([key, value]) => {
            return { name: key, value };
          }
        );
        if (Object.keys(overviewParams).length === 0) {
          ToastWarning('Please, select some search criteria');
        } else {
          const params = { page: 1, limit: 0, filters };
          const response = await actions.overviewListGET(params);
          if (!response.error && response.payload?.rows?.length === 0) {
            setShowNoRecord(true);
          } else if (response.error) {
            ToastError('Error retrieving Rejected Overview Report records.');
          }
        }
      }
    },
    [actions, overviewParams]
  );

  const handleOverviewDownload = useCallback(() => {
    setDownloading(true);
    if (state.overviewList) {
      const { overviewList } = state;
      const items = overviewList.map((value) => [
        checkValue(value.requestId ?? '--'),
        checkValue(value?.outage?.requestor?.fullName ?? '--'),
        checkValue(value?.outage?.supervisor?.fullName ?? '--'),
        checkValue(value?.rejectionReason ?? '--'),
        checkValue(value?.rejector?.fullName ?? '--'),
        checkValue(
          value.rejectionDate ? formatDateTime(value.rejectionDate) : '--'
        ),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_LER,
          Lang.LBL_REQUESTOR,
          Lang.LBL_REQUESTOR_SUPERVISOR,
          Lang.LBL_REASON,
          Lang.LBL_REJECTOR,
          Lang.LBL_REJECTED_DATE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Rejected_Overview_Report_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  }, [actions, state, setDownloading]);

  const handleSummaryDownload = useCallback(() => {
    setDownloading(true);
    if (state.summaryList) {
      const { summaryList } = state;
      const items = formatSummaryData(summaryList).map((value) => [
        checkValue(value?.date ?? '--'),
        checkValue(value?.submitted ?? '--'),
        checkValue(
          `${value?.moreThanOneRej} (${
            Number.isNaN((value.moreThanOneRej / value.rejections) * 100)
              ? 0
              : ((value.moreThanOneRej / value.rejections) * 100).toFixed(0)
          }%)` ?? '--'
        ),
        checkValue(value?.rejections ?? '--'),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_DATE,
          Lang.LBL_NUM_LER_SUBMITTED,
          Lang.LBL_LER_WITH_MORE_ONE_REJECTION,
          Lang.LBL_NUM_REJECTION,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Rejected_Summary_Report_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  }, [actions, state, setDownloading]);

  const handleSummaryDetailsDownload = useCallback(
    (date: string) => {
      setDownloading(true);
      if (state.summaryList) {
        const { summaryList } = state;
        const items = formatSummaryData(summaryList)?.find(
          (value) => value.date === date
        )?.lers as Record<any, any>;
        const lersDetails = items
          ?.sort((a, b) => a.requestId - b.requestId)
          .map((value) => [
            checkValue(value?.requestId ?? '--'),
            checkValue(
              value?.outage?.outageFacility?.facility?.outgFacNm ?? '--'
            ),
            checkValue(
              value.rejectionDate ? formatDateTime(value.rejectionDate) : '--'
            ),
            checkValue(value?.rejectionReason ?? '--'),
          ]);
        const { exportData, format } = convertToCSV(
          buildExportData(lersDetails, [
            Lang.LBL_LER,
            Lang.LBL_FACILITY_NAME,
            Lang.LBL_REJECTED_DATE,
            Lang.LBL_REASON_OF_REJECTION,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Rejected_Summary_Report_Details_${date}_${Moment().format(
            'YYYYMMDD'
          )}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloading(false);
    },
    [actions, state, setDownloading]
  );

  const handleSummaryFetch = useCallback(
    async (clear: boolean | undefined) => {
      if (clear) {
        setSummaryParams({});
        const response = await actions.summaryListGET({ page: 1, limit: 0 });
        if (!response.error && JSON.stringify(response?.payload) === '{}') {
          setShowNoRecord(true);
        } else if (response.error) {
          ToastError('Error retrieving Rejected Summary Report records.');
        }
      } else {
        const filters: any[] = [];
        const params = { page: 1, limit: 0, filters };
        params.filters.push({
          name: 'dateRange',
          value: summaryParams.dateRange
            ? summaryParams.dateRange
            : [new Date()],
        });
        const response = await actions.summaryListGET(params);
        if (!response.error && JSON.stringify(response?.payload) === '{}') {
          setShowNoRecord(true);
        } else if (response.error) {
          ToastError('Error retrieving Rejected Summary Report records.');
        }
      }
    },
    [actions, summaryParams]
  );

  return (
    <RejectedReportView
      overview={state.overviewList}
      summary={state.summaryList}
      overviewLoading={overviewLoading}
      summaryLoading={summaryLoading}
      overviewParams={overviewParams}
      summaryParams={summaryParams}
      setOverviewParams={(value) => setOverviewParams(value)}
      setSummaryParams={(value) => setSummaryParams(value)}
      handleOverviewFetch={handleOverviewFetch}
      handleSummaryFetch={handleSummaryFetch}
      handleOverviewDownload={handleOverviewDownload}
      handleSummaryDownload={handleSummaryDownload}
      handleSummaryDetailsDownload={handleSummaryDetailsDownload}
      downloading={downloading}
      showNoRecord={showNoRecord}
      setShowNoRecord={setShowNoRecord}
    />
  );
};

export default RejectedReport;
