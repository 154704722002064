import React, { useCallback, useState } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import Lang from 'src/libraries/language';
import styles from './substation-entry-log-checkout.module.scss';

type IProps = {
  id: number;
  handleSuccess?: () => void;
};

const SubstationEntryLogCheckout: React.FC<IProps> = ({
  id,
  handleSuccess,
}) => {
  const { actions } = useSubstationEntryContext();
  const [loading, setIsLoading] = useState(false);

  const handleCheckout = useCallback(async () => {
    setIsLoading(true);
    const result = await actions.logoutPUT(id);

    if (!result.error) {
      if (handleSuccess) {
        handleSuccess();
        ToastSuccess(Lang.MSG_CHECK_OUT_SUCCESS);
      }
    }

    setIsLoading(false);
  }, [id, actions, setIsLoading, handleSuccess]);

  return (
    <AccessComponent type={AccessType.SUBSTATION_ENTRY_LOG_UPDATE}>
      <ATMButton
        className={styles.checkout}
        onClick={handleCheckout}
        loading={loading}
        disabled={loading}
      >
        {Lang.LBL_CHECK_OUT}
      </ATMButton>
    </AccessComponent>
  );
};

export default SubstationEntryLogCheckout;
