import { httpClient } from 'src/libraries/http.library';
import {
  IRolesForm,
  RolesListPayloadSchema,
  RolesDeletePayloadSchema,
  RolesOptionsPayloadSchema,
  RoleEmployeeDetailSchema,
  RoleEmployeeItemListSchema,
} from 'src/models/roles.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

const client = httpClient();
const endpoint = 'employee-role';
const keyRoleEndpoint = 'requestor-report';
const services = {
  dataByEmpIdGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, RoleEmployeeDetailSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, data, RolesListPayloadSchema);
  },

  listByEmpGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(
      `/${endpoint}`,
      {
        ...data,
        action: 'listByEmployee',
      },
      RoleEmployeeItemListSchema
    );
  },

  createPOST: async (data?: IRolesForm) => {
    return client.post(`/${endpoint}`, data, RoleEmployeeDetailSchema);
  },

  updatePUT: async (id?: string, data?: IRolesForm) => {
    return client.put(`/${endpoint}/${id}`, data, RoleEmployeeDetailSchema);
  },

  findAllOptionRolesGET: async () => {
    const params: Record<any, any> = {
      action: 'roleFilters',
    };
    return client.get(`/${endpoint}`, params, RolesOptionsPayloadSchema);
  },

  // eslint-disable-next-line @typescript-eslint/require-await
  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, RolesDeletePayloadSchema);
  },

  keyRequestorListGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${keyRoleEndpoint}`, params, RolesListPayloadSchema);
  },
};

export default services;
