import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TroubleEquipmentTypeReducer, {
  ITroubleEquipmentTypeState,
  defaultState,
  duckActions,
} from 'src/ducks/trouble-equipment-type.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TroubleEquipmentTypeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITroubleEquipmentTypeContext = ReturnType<typeof useReducer>;

const TroubleEquipmentTypeContext = createContext<
  Partial<ITroubleEquipmentTypeContext>
>({
  state: defaultState,
}) as React.Context<ITroubleEquipmentTypeContext>;

type IProps = {
  state?: Partial<ITroubleEquipmentTypeState>;
};

const TroubleEquipmentTypeProvider: React.FC<IProps> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <TroubleEquipmentTypeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TroubleEquipmentTypeContext.Provider>
  );
};

const useTroubleEquipmentTypeContext = () =>
  useContext(TroubleEquipmentTypeContext);

export type IUseTroubleEquipmentTypeContext = ReturnType<
  typeof useTroubleEquipmentTypeContext
>;

export {
  TroubleEquipmentTypeContext,
  TroubleEquipmentTypeProvider,
  useTroubleEquipmentTypeContext,
};
