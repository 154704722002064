import React, { useEffect } from 'react';
import {
  ATMMenu,
  ATMTab,
  IATMTabProps,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import { formatToZeroPaddedEmployeeId } from 'src/helpers/employee.helper';
import { hasRole } from 'src/libraries/access.library';
import {
  AccessRole,
  LERRequestChangeStatus,
  LERRequestStatus,
  Op,
} from 'src/constants';
import { getUser } from 'src/libraries/amplify.library';
import { getLerRequestNewFilters } from 'src/helpers/ler-request.helper';
import { getLerRequestEndDate } from 'src/selectors/ler-request.selector';
import moment from 'moment';
import LerRequestListNew from './ler-request-list-new/ler-request-list-new.component';
import LerRequestListChange from './ler-request-list-change/ler-request-list-change.component';
import LerRequestListDraft from './ler-request-list-draft/ler-request-list-draft.component';
import LERRequestListPendingCaiso from './ler-request-list-pending-caiso/ler-request-list-pending-caiso.component';
import styles from '../ler-request.module.scss';

const LERRequestList: React.FC = () => {
  const { state, actions } = useLerRequestContext();

  useEffect(() => {
    const user = getUser();

    let changeFilters: IORGDataTableQueryState['filters'] = [];
    let changeStatus: LERRequestChangeStatus[] = [];

    if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) {
      changeFilters = [
        ...changeFilters,
        {
          name: 'requestorId',
          value: user?.emp_no,
        },
      ];
    } else if (
      hasRole([
        AccessRole.MCC_SUBMITTING_SUPERVISOR,
        AccessRole.MCC_OPERATIONAL_ENGINEER,
      ])
    ) {
      const empId = formatToZeroPaddedEmployeeId(user?.emp_no ?? '');
      changeFilters = [
        ...changeFilters,
        {
          name: 'or',
          value: [{ requestorId: empId }, { supvId: empId }],
        },
      ];
    }

    // #US 681032 Include Scheduled-ChangeRequested on the list to allow Scheduler to Approve/Reject CR
    // This should skip the Supervisor flow for CR if already scheduled.
    // Added Submitted-ChangeRequested to show who's the next group/person to take action (Action Pending By)
    if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
      const allStates = Object.values(LERRequestStatus)
        .filter((val) => typeof val === 'number')
        .filter((val) => val !== LERRequestStatus.Submitted);

      changeFilters = [
        ...changeFilters,
        {
          name: 'or',
          value: [
            ...allStates.map((val) => {
              return {
                requestStat: val,
                changeReqStat: LERRequestChangeStatus.Submitted,
              };
            }),
            {
              requestStat: LERRequestStatus.Scheduled,
              changeReqStat: LERRequestChangeStatus.Requested,
            },
            {
              requestStat: LERRequestStatus.Approved,
              changeReqStat: LERRequestChangeStatus.Requested,
            },
          ],
        },
      ];
    } else if (
      hasRole([
        AccessRole.MCC_SUBMITTING_SUPERVISOR,
        AccessRole.MCC_OPERATIONAL_ENGINEER,
      ])
    ) {
      changeStatus.push(LERRequestChangeStatus.Requested);
      changeFilters = [
        ...changeFilters,
        {
          name: 'requestStatuses',
          op: Op.notIn,
          value: [LERRequestStatus.Scheduled, LERRequestStatus.Approved],
        },
      ];
    } else {
      changeStatus = [
        LERRequestChangeStatus.Requested,
        LERRequestChangeStatus.Submitted,
      ];
    }

    changeFilters =
      changeStatus && changeStatus.length > 0
        ? changeFilters.reduce(
            (items, item) => [...items, item],
            [
              {
                name: 'changeReqStat',
                value: changeStatus,
              },
            ]
          )
        : changeFilters;

    actions.countGET(getLerRequestNewFilters(), changeFilters);
  }, [actions]);

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          New LERs (
          {hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
            ? state.newList.filter(
                (val) =>
                  !moment(getLerRequestEndDate(val.outageDates)).isBefore(
                    moment(new Date())
                  )
              ).length
            : state.newTotal}
          )
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane className={styles.tableSection} attached={false}>
          <LerRequestListNew />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          Change Requests ({state.changeTotal})
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane className={styles.tableSection} attached={false}>
          <LerRequestListChange />
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: <ATMMenu.Item key="2">Draft ({state.draftTotal})</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane className={styles.tableSection} attached={false}>
          <LerRequestListDraft />
        </ATMTab.Pane>
      ),
    },
  ];

  if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
    panes.push({
      menuItem: (
        <ATMMenu.Item key="3">
          Pending CAISO ({state.pendingCaisoTotal})
        </ATMMenu.Item>
      ),
      render: () => (
        <ATMTab.Pane className={styles.tableSection} attached={false}>
          <LERRequestListPendingCaiso />
        </ATMTab.Pane>
      ),
    });
  }

  return (
    <div className="admin-content">
      <div className={styles.headerTabs}>
        <Tabs
          name="request_type"
          menu={{ pointing: true }}
          panes={panes}
          renderActiveOnly
        />
      </div>
    </div>
  );
};

export default LERRequestList;
