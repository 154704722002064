import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSubstationEntryContext } from 'src/contexts/substation-entry.context';
import { substationEntryActionTypes } from 'src/ducks/substation-entry.duck';
import useLocationTab from 'src/hooks/location-tab.hook';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { format24hDateTime } from 'src/libraries/moment.library';
import { getSubstationEntryStatus } from 'src/selectors/substation-entry.selector';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import SubstationEntryLogListView from './substation-entry-log-list.view';

const INTERVAL_SECONDS = 900;

const SubstationEntryLogList: React.FC = () => {
  const [downloading, setDownloading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const { state, actions } = useSubstationEntryContext();
  const { currentTab } = useLocationTab('substationEntry');
  const status = getSubstationEntryStatus(
    state,
    substationEntryActionTypes.SUBSTATION_ENTRY_LIST_READ
  );

  const allStatus = getSubstationEntryStatus(
    state,
    substationEntryActionTypes.SUBSTATION_ENTRY_ALL_LIST_READ
  );
  const timer = useRef<NodeJS.Timeout>();
  const queryParamsRef = useRef<Record<any, any>>({});
  const timedJobRef = useRef(false);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timedJobRef.current = false;
      }
    };
  }, []);

  const timedJob = () => {
    timedJobRef.current = true;
    actions?.listGET(queryParamsRef.current, true);
    timer.current = setTimeout(() => {
      timedJob();
    }, INTERVAL_SECONDS * 1000);
  };

  const handleFetch = useCallback(
    async (params, all) => {
      if (!Number(currentTab) || Number(currentTab) === 0) {
        if (timer.current) {
          clearTimeout(timer.current);
          timedJobRef.current = false;
        }
        const filters = [
          ...(all ? [] : params.filters || []),
          {
            name: 'startDate',
            value: Moment().format('YYYY-MM-DD'),
          },
          {
            name: 'endDate',
            value: Moment().format('YYYY-MM-DD'),
          },
        ];

        const data =
          params.limit === 0
            ? {
                ...params,
                filters,
              }
            : {
                page: 1,
                filters,
              };
        queryParamsRef.current = data;
        const result = await actions?.listGET(data, true);

        if (!Number(currentTab) || Number(currentTab) === 0) {
          // If length is 2, then it's only date filter and no other filter is applied. Then we know the result
          // is the overall result for the given day. Set it as originalData.
          if (filters.length === 2) {
            actions.setOriginalData(result.payload?.rows ?? []);
            actions.setFilteredBySubstation(result.payload?.rows ?? []);
          } else if (filters.length === 3) {
            const substationIdFilter = filters.find(
              (i) => i.name === 'substationId'
            );
            if (substationIdFilter) {
              actions.setFilteredBySubstation(result.payload?.rows ?? []);
            }
          }

          timer.current = setTimeout(() => {
            timedJob();
          }, INTERVAL_SECONDS * 1000);
        }
      } else {
        if (timer.current) {
          clearTimeout(timer.current);
          timedJobRef.current = false;
        }

        await actions?.listAllGET({
          ...params,
          // limit: 0,
          filters: [...(all ? [] : params.filters || [])],
        });
      }

      setIsInitialized(true);
    },
    [actions, currentTab, setIsInitialized]
  );

  const handleDownload = useCallback(
    (all: boolean) => {
      setDownloading(true);
      const substationEntryData = !all ? state?.list : state?.all;
      if (substationEntryData) {
        const items = substationEntryData.map((value) => [
          checkValue(value.substation.name ?? '--'),
          checkValue(
            `${value.employee?.lastName ?? '--'}, ${
              value.employee?.firstName ?? '--'
            }`
          ),
          checkValue(`${format24hDateTime(value.inDateTime)}`),
          checkValue(value.status ?? '--'),
          checkValue(`${format24hDateTime(value.outDateTime)}`).includes(
            'null'
          ) ||
          checkValue(`${format24hDateTime(value.outDateTime)}`).includes(
            'undefined'
          )
            ? ''
            : checkValue(`${format24hDateTime(value.outDateTime)}`),
          checkValue(value.entryReason.description ?? '--'),
          checkValue(value.comment ?? '--'),
          checkValue(value.visitors ?? '--'),
          checkValue(
            `${value.entryEmployee?.firstName ?? '--'} ${
              value.entryEmployee?.lastName ?? '--'
            }`
          ),
          checkValue(
            `${value.exitEmployee?.firstName ?? '--'} ${
              value.exitEmployee?.lastName ?? '--'
            }`
          ),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_SUBSTATION,
            Lang.LBL_EMPLOYEE_NAME_VISITORS,
            Lang.LBL_IN_DATE,
            Lang.LBL_STATUS,
            Lang.LBL_OUT_DATE,
            Lang.LBL_ACTIVITY,
            Lang.LBL_SUBSTATION_PURPOSE,
            Lang.LBL_VISITORS,
            Lang.LBL_CHECKED_IN_BY,
            Lang.LBL_CHECKED_OUT_BY,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', cleanSpecialCharacterData(exportData));
        link.setAttribute(
          'download',
          `MCC_Substation_Entry_Log_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloading(false);
    },
    [actions, state, setDownloading]
  );

  return (
    <SubstationEntryLogListView
      data={state.list}
      all={state.all}
      total={state.total}
      loading={
        !(state.list.length && !isInitialized) &&
        ((status.fetching && !timedJobRef.current) || allStatus.fetching)
      }
      handleFetch={handleFetch}
      today={state.today}
      currentTab={currentTab}
      handleDownload={handleDownload}
      downloading={downloading}
      originalData={state.originalList}
      filteredBySubstation={state.filteredByStation}
      timer={timer}
    />
  );
};

export default SubstationEntryLogList;
