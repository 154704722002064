import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SignificantCostReducer, {
  ISignificantCostState,
  defaultState,
  duckActions,
} from 'src/ducks/significant-cost.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SignificantCostReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISignificantCostContext = ReturnType<typeof useReducer>;

const SignificantCostContext = createContext<Partial<ISignificantCostContext>>({
  state: defaultState,
}) as React.Context<ISignificantCostContext>;

type IProps = {
  state?: Partial<ISignificantCostState>;
};

const SignificantCostProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SignificantCostContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SignificantCostContext.Provider>
  );
};

const useSignificantCostContext = () => useContext(SignificantCostContext);

export type IUseSignificantCostContext = ReturnType<
  typeof useSignificantCostContext
>;

export {
  SignificantCostContext,
  SignificantCostProvider,
  useSignificantCostContext,
};
