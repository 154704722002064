import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IJobCodeRuleForm } from 'src/models/job-code-rule.model';
import JobCodeRuleForm from '../job-code-rule-form/job-code-rule-form.component';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IJobCodeRuleForm) => void;
};

const JobCodeRuleAddView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMButton
          secondary
          size="small"
          floated="right"
          content={Lang.LBL_JOB_CODE_RULE_ADD}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>{Lang.TTL_JOB_CODE_RULE_ADD}</ATMModal.Header>
      <ATMModal.Content scrolling>
        <JobCodeRuleForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          setIsError={setIsError}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SAVE}
          disabled={isError || !isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default JobCodeRuleAddView;
