import { httpClient } from 'src/libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  IKeyRequestForm,
  KeyRequestEmployeePostPayloadSchema,
  KeyRequestListPayloadSchema,
  KeyRequestMinSchema,
  KeyRequestPayloadSchema,
  KeyRequestPostPayloadSchema,
  KeyRequestValidatePayloadSchema,
} from 'src/models/key-request.model';

const client = httpClient();
const endpoint = 'key-request';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, KeyRequestPayloadSchema);
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, KeyRequestListPayloadSchema);
  },

  createPOST: async (data: IKeyRequestForm) => {
    return client.post(`/${endpoint}`, data, KeyRequestPostPayloadSchema);
  },

  createEmployeePOST: async (data: IKeyRequestForm) => {
    return client.post(
      `/${endpoint}`,
      data,
      KeyRequestEmployeePostPayloadSchema
    );
  },

  updatePUT: async (id: number, data: IKeyRequestForm) => {
    return client.put(`/${endpoint}/${id}`, data, KeyRequestPayloadSchema);
  },

  rejectPUT: async (id: number) => {
    return client.put(
      `/${endpoint}/${id}?action=reject`,
      {},
      KeyRequestMinSchema
    );
  },

  approvePUT: async (id: number, data: IKeyRequestForm) => {
    return client.put(
      `/${endpoint}/${id}?action=approve`,
      data,
      KeyRequestMinSchema
    );
  },

  validateGET: async (keyNbr: number, noOfKeys: number) => {
    const params: Record<string, any> = {
      filters: [
        { name: 'keyNbr', value: keyNbr },
        { name: 'noOfKeys', value: noOfKeys },
      ],
    };
    return client.get(
      `/${endpoint}?action=validates`,
      params,
      KeyRequestValidatePayloadSchema
    );
  },

  listByEmpIdGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(
      `/${endpoint}?action=requestor`,
      params,
      KeyRequestListPayloadSchema
    );
  },
};

export default services;
