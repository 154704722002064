import { UseFormReturn } from 'react-hook-form';
import { RealTimeLogCategory, REGEX_24HR_TIME } from 'src/constants';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { FacilityMinShape, FacTypShape } from './facility.model';
import { SubstationSchema } from './substation.model';
import { VoltShape } from './volt.model';
import {
  RealTimeLogNotesShape,
  RealTimeLogNotesPayloadShape,
} from './real-time-log-notes.model';

export const FacilityLogShape = {
  outgFacId: yup.number().required(),
  outgFacNm: yup.string().required(),
  volt: yup.object(VoltShape),
  facTyp: yup.object(FacTypShape).nullable(),
};

export const ForcedLogSubsShape = {
  logId: yup.number().required(),
  substationId: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const ForcedLogShape = {
  logId: yup.number().required(),
  logSub: yup.string().required(),
  tripsTargets: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const RealTimeLogShape = {
  logId: yup.number().required(),
  logFacId: yup.number().nullable(),
  logDtTm: yup.date(),
  logUserId: yup.string(),
  logUserType: yup.string(),
  logEntry: yup.string(),
  logBporReport: yup.boolean().nullable(),
  logSubstation: yup.string().nullable(),
  logDtTmFrcdOutOcurred: yup.date().nullable(),
  logDtTmFrcdOutRestored: yup.date().nullable(),
  logCategory: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  emp: yup.object({
    lastName: yup.string(),
    firstName: yup.string(),
    empId: yup.string(),
  }),
  substation: yup
    .object({
      substationId: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  facility: yup.object(FacilityLogShape).nullable(),
  forcedLog: yup.array().of(
    yup.object({
      ...ForcedLogShape,
      substation: yup
        .object({
          substationId: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
    })
  ),
  forcedLogSubs: yup.array().of(
    yup.object({
      ...ForcedLogSubsShape,
      substation: yup
        .object({
          substationId: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
    })
  ),
  changeLog: yup.array().of(yup.object(RealTimeLogNotesShape)),
};

export const RealTimeLogSchema = yup.object(RealTimeLogShape);

export const EmployeesRealTimeLogSchema = yup.object({
  logUserId: yup.string(),
  emp: yup.object({
    lastName: yup.string(),
    firstName: yup.string(),
    empId: yup.string(),
  }),
});

export const RealTimeLogEntryFormSchema = yup.object({
  logId: yup.number(),
  logFacId: yup
    .number()
    .nullable()
    .when('lineSubstation', {
      is: 'line',
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    })
    .when(['lineSubstation', 'logSubstation', 'equipmentType', 'volt'], {
      is: (lineSubstation, logSubstation, equipmentType, volt) =>
        lineSubstation === 'substation' &&
        logSubstation &&
        equipmentType &&
        volt,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  logDtTm: yup.date().typeError('Invalid Date').required(),
  logTm: yup
    .string()
    .required()
    .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
  logUserId: yup.string().required(),
  logUserType: yup.string().required(),
  logEntry: yup.string().max(2500).required(),
  logBporReport: yup.boolean().required(),
  logSubstation: yup
    .string()
    .nullable()
    .when('lineSubstation', {
      is: 'substation',
      then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  logDtTmFrcdOutOcurred: yup
    .date()
    .nullable()
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.date().typeError('Invalid Date').nullable(),
    }),
  logTmFrcdOutOccurred: yup
    .string()
    .nullable()
    .when(['logCategory', 'logDtTmFrcdOutOcurred'], {
      is: (logCategory, logDtTmFrcdOutOcurred) =>
        logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
        logDtTmFrcdOutOcurred,
      then: yup
        .string()
        .required()
        .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
    }),
  logDtTmFrcdOutRestored: yup
    .date()
    .nullable()
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.date().typeError('Invalid Date').nullable(),
    }),
  logTmFrcdOutRestored: yup
    .string()
    .nullable()
    .when(['logCategory', 'logDtTmFrcdOutRestored'], {
      is: (logCategory, logDtTmFrcdOutRestored) =>
        logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
        logDtTmFrcdOutRestored,
      then: yup
        .string()
        .required()
        .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
    }),
  logCategory: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  forcedLog: yup
    .array()
    .of(
      yup.object({
        logSub: yup.string(),
        tripsTargets: yup.string(),
      })
    )
    .required()
    .default([])
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.array().of(
        yup.object({
          logSub: yup.string(),
          tripsTargets: yup.string(),
        })
      ),
    }),
  forcedLogSubs: yup
    .array()
    .of(yup.string().required())
    .default([])
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.array().of(yup.string()).required(),
    }),
  volt: yup
    .number()
    .nullable()
    .when(['lineSubstation', 'logSubstation', 'equipmentType'], {
      is: (lineSubstation, logSubstation, equipmentType) =>
        lineSubstation === 'substation' && logSubstation && equipmentType,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  equipmentType: yup
    .number()
    .nullable()
    .when(['lineSubstation', 'logSubstation'], {
      is: (lineSubstation, logSubstation) =>
        lineSubstation === 'substation' && logSubstation,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),

  lineSubstation: yup.string().nullable().when('logCategory', {
    is: RealTimeLogCategory.FORCEDOUTAGE,
    then: yup.string().required(),
  }),
  changeLogNote: yup
    .string()
    .nullable()
    .when(['logCategory', 'logId', 'changeLogForUpdate'], {
      is: (logCategory, logId, changeLogForUpdate) =>
        logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
        logId &&
        !changeLogForUpdate,
      then: yup.string().required(),
    }),
  changeLog: yup.array().of(yup.object(RealTimeLogNotesShape)).nullable(),
  changeLogForUpdate: yup
    .object(RealTimeLogNotesPayloadShape)
    .default(null)
    .nullable(),
  tripsTargetsEntry: yup.string().nullable(),
});

export const RealTimeEntryLogAllSchema = yup.object({
  logUserId: yup.string().nullable(),
  logDtTm: yup.array().of(yup.date()),
  logEntry: yup.string(),
  isMyEntries: yup.boolean(),
  isOssEntries: yup.boolean(),
  isTsoEntries: yup.boolean(),
  logBporReport: yup.boolean(),
  lineSubstation: yup.string(),
  logFacId: yup.string().when('lineSubstation', {
    is: 'line',
    then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
  }),
  logSubstation: yup.string().when('lineSubstation', {
    is: 'substation',
    then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
  }),
  logCategory: yup.string(),
});

export const RealTimeLogListPayloadSchema = yup.object({
  count: yup.object({
    all: yup.number(),
    today: yup.number(),
  }),
  // count: yup.number(),
  rows: yup.array().of(RealTimeLogSchema.clone()),
});

export const EmployeesRealTimeLogListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(EmployeesRealTimeLogSchema.clone()),
});

export const RealTimeLogMailerSchema = yup.object({
  message: yup.string(),
});

export const RealTimeLogPayloadSchema = RealTimeLogSchema.clone();

export const RealTimeLogDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const RealTimeLogLineSchema = yup.object({
  ...FacilityMinShape,
  substations: yup.array(SubstationSchema.clone()),
});

export const RealTimeLogAttachmentSchema = yup.object({
  base64String: yup.array().of(yup.string()).nullable(),
});

export const RealTimeLogLinePayloadSchema = yup
  .array()
  .of(RealTimeLogLineSchema.clone());

export const FacilityLogSchema = yup.object(FacilityLogShape);

export type IFacilityLog = yup.Asserts<typeof FacilityLogSchema>;
export type IRealTimeLog = yup.Asserts<typeof RealTimeLogSchema>;
export type IEmployeesRealTimeLog = yup.Asserts<
  typeof EmployeesRealTimeLogSchema
>;
export type IRealTimeLogLinePayload = yup.Asserts<
  typeof RealTimeLogLinePayloadSchema
>;
export type IRealTimeLogAll = yup.Asserts<typeof RealTimeEntryLogAllSchema>;
export type IRealTimeLogLine = yup.Asserts<typeof RealTimeLogLineSchema>;
export type IRealTimeLogEntryForm = yup.Asserts<
  typeof RealTimeLogEntryFormSchema
>;
export type IRealTimeLogMailer = yup.Asserts<typeof RealTimeLogMailerSchema>;
export type IRealTimeLogAttachment = yup.Asserts<typeof RealTimeLogAttachmentSchema>;

export type IEntryLogFormMethods = Pick<
  UseFormReturn<IRealTimeLogEntryForm>,
  'control' | 'formState'
> &
  Partial<
    Pick<
      UseFormReturn<IRealTimeLogEntryForm>,
      'setError' | 'clearErrors' | 'formState' | 'reset' | 'watch' | 'register'
    >
  >;
