import moment from 'moment';
import React, { useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  MOLInfoText,
  MOLNoData,
  ATMIcon,
  ATMButton,
  ATMPopover,
} from 'shared-it-appmod-ui';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import history from 'src/history';
import Lang from 'src/libraries/language';
import { formatDate } from 'src/libraries/moment.library';
import {
  ITroubleTicketAuditReportFilter,
  ITroubleTicketList,
} from 'src/models/trouble-ticket.model';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import styles from '../trouble-ticket.module.scss';
import CommentsModal from '../trouble-ticket-comments-modal/trouble-ticket-comments-modal.component';
import TroubleTicketAuditReportFilter from './trouble-ticket-audit-report-filter.component';
import TroubleTicketCard from '../trouble-ticket-card/trouble-ticket-card.component';

type IProps = {
  data: ITroubleTicketList[];
  loading: boolean;
  total: number;
  showNoRecord: boolean;
  handleSubmit: (data: ITroubleTicketAuditReportFilter) => void;
  handleNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownload: () => void;
};

const TroubleTicketAuditReportListView: React.FC<IProps> = ({
  data,
  loading,
  showNoRecord,
  handleSubmit,
  handleNoRecord,
  handleDownload,
}) => {
  const [isCommentsOpen, setIsCommentsOpen] = useState<number>();
  const [hasDateRange, setHasDateRange] = useState<boolean | undefined>(
    undefined
  );
  const { state: troubleTicketState } = useTroubleTicketContext();
  const downloadStatus = getTroubleTicketStatus(
    troubleTicketState,
    troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ
  );

  const columns: IORGDataTableColumn<ITroubleTicketList>[] = [
    {
      index: 'troubleTicketId',
      title: 'Ticket ID/Desc',
      width: '200px',
      render: (_, value) => (
        <>
          <TroubleTicketCard troubleTicket={value} fromAuditReport />
          <span className={styles.prioMargin}>
            <ListStatus value={value.ticketPrio} />
          </span>
          <MOLInfoText transparent limit={50} content={value.ticketDesc} />
        </>
      ),
    },
    {
      index: 'ticketStatus',
      title: Lang.LBL_TROUBLE_TICKET_STATUS,
      render: (_, value) => <ListStatus value={value.ticketStatus} label />,
    },
    {
      index: 'beginDt',
      title: Lang.LBL_BEGIN_DATE,
      render: (_, value) => (value.beginDt ? formatDate(value.beginDt) : '--'),
    },
    {
      index: 'dueDt',
      title: Lang.LBL_DUE_DATE,
      render: (_, value) => (
        <>
          {value.dueDt ? formatDate(value.dueDt) : '--'}
          {moment().diff(formatDate(value.dueDt), 'days') > 0 ? (
            // && value.ticketStatus === 'active'
            <p className={styles.overdueMsg}>
              {moment().diff(formatDate(value.dueDt), 'days')} day(s) overdue
            </p>
          ) : undefined}
        </>
      ),
    },
    {
      index: 'closeDt',
      title: Lang.LBL_CLOSED_DATE,
      render: (_, value) => (value.beginDt ? formatDate(value.closedDt) : '--'),
    },
    {
      index: 'comments',
      title: 'Comments and Resolution',
      sortable: false,
      width: '180px',
      render: (_, value) => {
        if (value.comments) {
          if (value.comments.length < 2) {
            return (
              <MOLInfoText
                transparent
                limit={40}
                content={value.comments[0]?.noteTxt ?? ''}
              />
            );
          }
          if (value.comments.length < 3) {
            return (
              <MOLInfoText
                transparent
                limit={40}
                content={
                  `${value.comments[0]?.noteTxt} • ${value.comments[1]?.noteTxt}` ??
                  ''
                }
              />
            );
          }
          return (
            <>
              <MOLInfoText
                transparent
                limit={40}
                content={
                  `${value.comments[0]?.noteTxt} • ${value.comments[1]?.noteTxt}...` ??
                  ''
                }
              />
              <CommentsModal
                data={value}
                isOpen={isCommentsOpen === value.troubleTicketId}
                handleOpen={setIsCommentsOpen}
              />
            </>
          );
        }
        return '--';
      },
    },
    {
      index: 'requestTyp',
      title: 'Ticket Type',
      render: (_, value) => value.requestTyp,
    },
    {
      index: 'workGroup.trbGrpNm',
      title: Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
    },
    {
      index: 'troubleEquipmentType.name',
      title: Lang.LBL_TROUBLE_TICKET_EQUIP_TYPE,
      render: (_, value) =>
        value.troubleEquipmentTypeId ? value.troubleEquipmentType?.name : '--',
    },
    {
      index: 'facilitySubstation',
      sortable: false,
      title: 'Line/Substation',
      render: (_, value) => {
        if (value.substationId || value.outgFacId) {
          return (
            <>
              {value.substationId && value.substation && (
                <>
                  <b>Substation</b>
                  <br />
                  <span>{value.substation.name}</span>
                </>
              )}
              {value.outgFacId && value.line && (
                <>
                  <b>Line</b>
                  <br />
                  <span>{value.line.outgFacNm}</span>
                </>
              )}
            </>
          );
        }
        return '--';
      },
    },
  ];

  return (
    <div className="admin-content">
      <TroubleTicketAuditReportFilter
        handleSubmit={handleSubmit}
        handleNoRecord={handleNoRecord}
        loading={loading}
        setHasDateRange={setHasDateRange}
        hasDateRange={hasDateRange}
      />
      <ORGDataTable
        location={history.location}
        handleLocation={history.push}
        celled={false}
        columns={columns}
        data={data}
        loading={loading}
        // total={total}
        showPagination={false}
        counter
        sortable
        noDataText={
          showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={styles.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'bottom',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      >
        {() => ({
          toolbars: {
            buttons: () =>
              showNoRecord ? (
                <ATMPopover
                  trigger={
                    <ATMButton
                      key="download"
                      secondary
                      size="tiny"
                      icon="download"
                    />
                  }
                  content="No Data to Download"
                  position="top left"
                />
              ) : (
                <ATMPopover
                  position="bottom left"
                  content="Download"
                  on="hover"
                  trigger={
                    <ATMButton
                      key="download"
                      secondary
                      size="tiny"
                      icon="download"
                      loading={downloadStatus.fetching}
                      style={{
                        width: 35,
                        height: 35,
                        marginRight: '.5em',
                      }}
                      onClick={() => {
                        handleDownload();
                      }}
                    />
                  }
                />
              ),
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default TroubleTicketAuditReportListView;
