import React, { useCallback, useEffect, useState } from 'react';
import { TroubleTicketTab } from 'src/constants/trouble-ticket.constant';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useRolesContext } from 'src/contexts/roles.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useTroubleEquipmentTypeContext } from 'src/contexts/trouble-equipment-type.context';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import useLocationTab from 'src/hooks/location-tab.hook';
import {
  LocalStorageId,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/helpers/storage.helper';
import {
  getTroubleTicketParams,
  getTroubleTicketStatus,
} from 'src/selectors/trouble-ticket.selector';
import TroubleTicketListView from './trouble-ticket-list.view';

const TroubleTicketList: React.FC = () => {
  const { state, actions } = useTroubleTicketContext();
  const { state: workGroupState, actions: workGroupActions } =
    useWorkGroupContext();
  const { actions: substationActions } = useSubstationContext();
  const { actions: realTimeLogActions } = useRealTimeLogContext();
  const { actions: tempEmployeeActions } = useEmployeeContext();
  const { actions: employeeRolesActions } = useRolesContext();
  const { actions: troubleEquipmentTypeActions } =
    useTroubleEquipmentTypeContext();
  const { currentTab } = useLocationTab('troubleTicketStatus');

  const [counter, setCounter] = useState<number>(0);
  const status = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_LIST_READ
  );

  const allStatus = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ
  );

  const scheduledStatus = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_SCHEDULED_LIST
  );

  const [completeWorkList, setCompleteWorkList] = useState<number[]>([]);
  const [dateState, setDateState] = useState<any>();
  const [searchFilterHistory, setSearchFilterHistory] = useState<
    Record<string, any>
  >(() => getLocalStorageItem(LocalStorageId.TROUBLE_TICKET));

  useEffect(() => {
    setLocalStorageItem(LocalStorageId.TROUBLE_TICKET, searchFilterHistory);
  }, [searchFilterHistory]);

  useEffect(() => {
    if (!workGroupState.list.length) {
      workGroupActions?.listGET({ limit: 0, page: 1 });
    }
  }, [workGroupState.list, workGroupActions]);

  useEffect(() => {
    troubleEquipmentTypeActions?.listGET();
    substationActions?.listGET({ limit: 0, page: 1 });
    realTimeLogActions?.lineGET();
    tempEmployeeActions?.listGET();
    employeeRolesActions?.listGET({
      page: 1,
      limit: 0,
      filters: [
        {
          name: 'roleIds',
          value: [14, 15],
        },
      ],
    });
  }, [
    troubleEquipmentTypeActions,
    substationActions,
    realTimeLogActions,
    employeeRolesActions,
  ]);

  useEffect(() => {
    setCompleteWorkList(workGroupState.list.map((item) => item.trbGrpId));
  }, [workGroupActions]);

  const handleFetch = useCallback(
    (params) => {
      let newFilters = params;
      const { page } = params;

      // For some reason, params has limit that is set to total records
      // from the preceding backend service call. Ensure that limit is 0.
      newFilters = {
        ...newFilters,
        limit: 0,
      };

      if (!newFilters.filters?.some((elem) => elem.name === 'ticketStatus')) {
        newFilters = {
          ...newFilters,
          page,
          limit: 0,
          filters: [
            ...(newFilters?.filters?.filter(
              (el) => el.name !== 'ticketStatus'
            ) ?? []),
            {
              name: 'ticketStatus',
              value: 'Both',
            },
          ],
        };
      }

      const ticketPrio = newFilters.filters?.find(
        (elem) => elem.name === 'ticketPrio'
      );

      const ticketStatus = params.filters?.find(
        (elem) => elem.name === 'ticketStatus'
      );
      if (ticketPrio) {
        const filters = (newFilters?.filters ?? []).filter(
          (el) => el.name !== 'ticketPrio'
        );

        if (ticketPrio.value !== 'all') {
          filters.push(ticketPrio);
        }
        if (!filters.includes('ticketStatus')) {
          if (!ticketStatus) {
            filters.push({ name: 'ticketStatus', value: 'Both' });
          }
        }

        newFilters = {
          ...newFilters,
          page,
          limit: 0,
          filters,
        };
      }

      if (!newFilters.filters) {
        newFilters = {
          ...newFilters,
          page,
          limit: 0,
          filters: [
            {
              name: 'ticketStatus',
              value: 'Both',
            },
          ],
        };
      }

      setSearchFilterHistory(params);

      const data = getTroubleTicketParams(newFilters, currentTab);
      switch (+currentTab) {
        case TroubleTicketTab.Scheduled: {
          actions?.listScheduledGET(data);
          break;
        }
        case TroubleTicketTab.Trouble: {
          actions?.listGET(data);
          break;
        }
      }
    },
    [actions, currentTab, setCounter, setSearchFilterHistory]
  );

  return (
    <TroubleTicketListView
      data={state.list}
      loading={
        status.fetching || scheduledStatus.fetching || allStatus.fetching
      }
      handleFetch={handleFetch}
      total={state.total}
      dateState={dateState}
      troubleTotal={state.troubleTotal}
      scheduledTotal={state.scheduledTotal}
      currentTab={currentTab}
      completeWorkList={completeWorkList}
      setDateState={setDateState}
      counter={counter}
      searchFilterHistory={searchFilterHistory}
    />
  );
};

export default TroubleTicketList;
