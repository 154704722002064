import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
} from 'shared-it-appmod-ui';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import Lang from 'src/libraries/language';
import { IFacType } from 'src/models/facility.model';
import EquipmentTypeEdit from '../equipment-type-edit/equipment-type-edit.component';

const columns: IORGDataTableColumn<IFacType>[] = [
  {
    index: 'facTypNm',
    title: Lang.LBL_EQUIPMENT_TYPE_NAME,
    width: '30%',
    render: (_, value) => <strong>{value.facTypNm}</strong>,
  },
  {
    index: 'facTypDesc',
    sortable: false,
    title: Lang.LBL_DESCRIPTION,
    width: '60%',
  },
  {
    index: 'action',
    sortable: false,
    title: Lang.LBL_ACTION,
    width: '10%',
    headerProps: {
      textAlign: 'center',
    },
    cellProps: {
      textAlign: 'center',
    },
    render: (_, value) => (
      <>
        <EquipmentTypeEdit data={value} />
      </>
    ),
  },
];

type IProps = {
  data: IFacType[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const EquipmentTypeListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  handleDownload,
}) => {
  return (
    <>
      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={data}
          onChange={handleFetch}
          loading={loading}
          counter
          celled={false}
          rowsPerPageOptions={[10, 25, 50, 100]}
          total={total}
          sortable
        >
          {({ state, setState }) => ({
            toolbars: {
              search: () => (
                <TableSearch
                  field="facTypNm"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_EQUIPMENT_TYPE_NAME}
                />
              ),
              buttons: () => (
                <ATMButton
                  key="download"
                  secondary
                  size="tiny"
                  icon="download"
                  onClick={() => {
                    handleDownload({
                      ...state,
                      page: 1,
                      limit: total,
                    });
                  }}
                />
              ),
            },
          })}
        </ORGDataTable>
      </div>
    </>
  );
};

export default EquipmentTypeListView;
