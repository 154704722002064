import { useEffect, useState } from 'react';
import { PAGE_LIMIT_THRESHOLD } from 'src/constants';

export const useLimitPageItems = (threshold = PAGE_LIMIT_THRESHOLD) => {
  const [limit, setLimit] = useState(threshold);

  useEffect(() => {
    const handleDisplay = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setLimit((value) => value + threshold);
      }
    };

    window.addEventListener('scroll', handleDisplay);

    return () => {
      window.removeEventListener('scroll', handleDisplay);
    };
  }, [setLimit]);

  return [limit, setLimit] as [
    number,
    React.Dispatch<React.SetStateAction<number>>
  ];
};
