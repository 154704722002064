import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { IssuedKeyStatus, IssuedKeyTab } from 'src/constants';
import {
  issuedKeyActionTypes,
  IIssuedKeyState,
} from 'src/ducks/issued-key.duck';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import Lang from 'src/libraries/language';
import { formatDate } from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import { IIssuedKey } from 'src/models/issued-key.model';

export const getIssuedKeyStatus = (
  state: IIssuedKeyState,
  action: keyof typeof issuedKeyActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getIssuedKeyParams = (
  params: IORGDataTableQueryState,
  currentTab?: IssuedKeyTab
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;

  if (keyword) {
    data.filters = [
      ...(data.filters ?? []),
      {
        name: 'id',
        value: keyword,
      },
    ];
  }

  switch (currentTab) {
    case IssuedKeyTab.All: {
      data.stat = undefined;
      break;
    }

    case IssuedKeyTab.Active: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.ACTIVE,
        },
      ];
      break;
    }

    case IssuedKeyTab.Damaged: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.DAMAGED,
        },
      ];
      break;
    }

    case IssuedKeyTab.Lost: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.LOST,
        },
      ];
      break;
    }

    case IssuedKeyTab.Returned: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.RETURNED,
        },
      ];
      break;
    }

    case IssuedKeyTab.PendingReturn: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.PENDINGRETURN,
        },
      ];
      break;
    }

    case IssuedKeyTab.NotReturned: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'stat',
          value: IssuedKeyStatus.NOTRETURNED,
        },
      ];
      break;
    }

    default: {
      break;
    }
  }

  return data;
};

export const getKeyDetailsForm = (data: IIssuedKey): string => {
  return `<div id="element-to-print">
          <div style="border:none;font-family: Calibri; font-size: 1.3rem; font-weight: bold; text-align: center; margin-bottom: 1rem; margin-top: 1rem; ">
            <span>${Lang.TTL_KEY_HISTORY} - ${convertKeyNumberToFiveDigits(data.keyNbr)} </span>
          </div>
          <table style="font-size: 14px;
          border-collapse: separate;
          font-size: 12px;
          line-height: 24px;
          margin: 30px auto;
          text-align: left;
          width: 600px;">
            <tbody>
                <tr style="border: 1px solid #000; font-weight: 800;">
                  <tr style="font-weight: 800;">
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ISSUED_KEY_STATUS
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ISSUED_KEY_TO
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_STATUS_KEY_DATE
                    }</td>
                    <td style="border: 1px solid #000;">${
                      Lang.LBL_ISSUED_KEY_END_DATE
                    }</td>
                  </tr> 
                  ${
                    data.keyDetails &&
                    data.keyDetails
                      .map(
                        (item) =>
                          `<tr>
                          <td style="border: 1px solid #000;">
                            <span> ${convertKeyNumberToFiveDigits(item.keyNbr)} </span>
                            <br>
                            <span> ${item.stat} <span>
                            <br>
                            <span>${item.reason}</span>
                          </td>
                          <td style="border: 1px solid #000;">
                            <span>${item.emp?.lastName}, ${
                            item.emp?.firstName
                          } /${item.emp?.empId}</span>
                            <br>
                            <span> ${item.emp?.title} </span>
                          </td>
                          <td style="border: 1px solid #000;">${formatDate(
                            item.issDt
                          )}</td>
                          <td style="border: 1px solid #000;">${
                            item.returnDt ? formatDate(item.returnDt) : '-'
                          }</td>
                        </tr>
                          `
                      )
                      .join('')
                  }
          </table>
          </div>`;
};
