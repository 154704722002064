import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import { IKeyRequestDemandIssueValidationForm } from 'src/models/key-request.model';
import Lang from 'src/libraries/language';
import KeyRequestDemandForm from './key-request-demand-form.component';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IKeyRequestDemandIssueValidationForm) => void;
  isDropdownOpen?: boolean;
  handleDropdown?: (data) => void;
};

const KeyRequestDemandView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
  isDropdownOpen,
  handleDropdown,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          content={Lang.LBL_KEY_REQUEST_DEMAND}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>{Lang.LBL_KEY_REQUEST_DEMAND}</ATMModal.Header>
      <ATMModal.Content style={{ marginBottom: '100px' }}>
        <KeyRequestDemandForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          isDropdownOpen={isDropdownOpen}
          handleDropdown={handleDropdown}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content={Lang.LBL_SUBMIT}
          disabled={!isDirty || loading}
          loading={loading}
          onClick={handleClick}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default KeyRequestDemandView;
