import React from 'react';
import { ISubstationEntry } from 'src/models/substation-entry.model';
import SubstationEntryEmployeeInfoView from './substation-entry-log-employee-info.view';

type IProps = {
  substationEntry: ISubstationEntry;
  isEmpOpen: boolean;
  setIsEmpOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubstationEntryEmployeeInfo: React.FC<IProps> = ({
  substationEntry,
  isEmpOpen,
  setIsEmpOpen,
}) => {
  return (
    <SubstationEntryEmployeeInfoView
      isEmpOpen={isEmpOpen}
      setIsEmpOpen={setIsEmpOpen}
      substationEntry={substationEntry}
    />
  );
};

export default SubstationEntryEmployeeInfo;
