import React, { useCallback, useState } from 'react';
import { ATMButton, ATMModal, ATMPopover } from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { LERRequestStatus } from 'src/constants';
import { ILerRequest } from 'src/models/ler-request.model';
import {
  hasChangeRequest,
  isLerRequestExpired,
} from 'src/helpers/ler-request.helper';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import styles from './ler-request-iso-send-to-caiso.module.scss';

type IProps = {
  requestStat: any;
  data: ILerRequest;
  removeFromList?: boolean;
  handleFetch?: () => void;
};

export enum CaisoPending {
  YES = 'Y',
  NO = 'N',
}

export const isCaisoPending = (status: any) => {
  let isPending = false;
  if (status) {
    if (status === CaisoPending.YES) {
      isPending = true;
    }
    if (status === CaisoPending.NO) {
      isPending = false;
    }
  }

  return isPending;
};

export const isValidForCaisoSubmission = (requestStatus: any) => {
  return [LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
    requestStatus as LERRequestStatus
  );
};

const LERRequestSendToCaiso: React.FC<IProps> = ({
  data,
  requestStat,
  removeFromList = false,
  handleFetch,
}) => {
  const { state, actions } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);

  const getCaisoStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SEND_CAISO_CREATE
  );

  const getCaisoLerReconcileStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CAISO_RECONCILE_CREATE
  );

  const handlePendingCaiso = useCallback(
    async (requestId: any, pending: any) => {
      setIsOpen(false);
      const result = await actions.updatePUT(requestId, {
        pendingCaiso: `${pending}`,
      } as any);
      if (result?.payload) {
        ToastSuccess(
          `LER ${data.requestId} has been successfully removed from Pending CAISO list`
        );
        if (handleFetch) {
          handleFetch();
        }
      }
      if (result?.error) {
        ToastError(
          `Failed to remove LER ${data.requestId} from pending CAISO list`
        );
      }
    },
    [actions, setIsOpen]
  );

  return (
    <>
      {removeFromList ? (
        <ATMModal
          open={isOpen}
          size="tiny"
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          trigger={<ATMButton className={styles.actionBtn} content="Remove" />}
        >
          <ATMModal.Header>Remove LER from Pending CAISO List</ATMModal.Header>
          <ATMModal.Content>
            <p>{Lang.MSG_PENDING_CAISO_REMOVED_FROM_LIST}</p>
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              secondary
              content={Lang.LBL_CANCEL}
              onClick={() => setIsOpen(false)}
              type="button"
            />
            <ATMButton
              type="button"
              primary
              content={Lang.LBL_CONFIRM}
              onClick={() => {
                handlePendingCaiso(data.requestId, 'N');
              }}
            />
          </ATMModal.Actions>
        </ATMModal>
      ) : (
        ''
      )}
      {!removeFromList &&
      isValidForCaisoSubmission(requestStat) &&
      !hasChangeRequest(data ?? undefined) ? (
        <ATMPopover
          position="bottom left"
          content="This LER is flagged for CAISO submission"
          trigger={
            <ATMButton
              type="button"
              primary
              className={styles.sendToCaisoBtn}
              content={Lang.LBL_SEND_CAISO}
              onClick={() => {
                actions.setForApproval(true);
                actions.setIsFromPendingCaiso(true);
                actions.setIsFromUpdate(false);
                actions.setIsFromReject(false);
              }}
              disabled={
                (state?.caisoError?.message &&
                  state?.caisoError?.message !== 'Failed') ||
                isLerRequestExpired(data.outageDates) ||
                getCaisoStatus.fetching ||
                getCaisoLerReconcileStatus.fetching
              }
              loading={
                getCaisoStatus.fetching || getCaisoLerReconcileStatus.fetching
              }
            />
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default LERRequestSendToCaiso;
