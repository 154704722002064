import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSegment,
  ATMTable,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
// import { formatDateTime } from 'src/libraries/moment.library';
import { ILerRequest } from 'src/models/ler-request.model';
import { AccessRole } from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
import LERRequestSendToCaiso, {
  isCaisoPending,
} from '../ler-request-iso-send-to-caiso/ler-request-iso-send-to-caiso.component';
import styles from '../../ler-request.module.scss';

type IProps = {
  // Change to ISO Interface once ready.
  data?: ILerRequest;
  handleEdit?: () => void;
};

const LERRequestIsoDetail: React.FC<IProps> = ({ data, handleEdit }) => {
  if (!data) {
    return null;
  }

  // const mockIds = [12345, 12345];

  const header = handleEdit ? (
    <ATMHeader as="h2" attached="top" block>
      {Lang.TTL_ISO}
      {handleEdit && (
        <a className={styles.edit} onClick={handleEdit} role="button">
          <ATMIcon name="edit" />
          {Lang.LBL_EDIT}
        </a>
      )}
    </ATMHeader>
  ) : (
    <>
      <span>
        <ATMIcon circular name="cogs" />
      </span>
      <ATMHeader as="h2" content={Lang.TTL_ISO} className={styles.headerDoc} />
    </>
  );

  // Table only that has mock data
  // const omsInformation = (
  //   <>
  //     <ATMHeader as="h2" content={Lang.TTL_OMS_INFORMATION} />
  //     <ATMTable>
  //       <ATMTable.Header>
  //         <ATMTable.Row>
  //           <ATMTable.HeaderCell>{Lang.LBL_OMS_TR_ID}</ATMTable.HeaderCell>
  //         </ATMTable.Row>
  //       </ATMTable.Header>
  //       <ATMTable.Body>
  //         {mockIds.length > 0 ? (
  //           mockIds.map((item, i) => (
  //             <ATMTable.Row key={i}>
  //               <ATMTable.Cell>{item}</ATMTable.Cell>
  //             </ATMTable.Row>
  //           ))
  //         ) : (
  //           <ATMTable.Row textAlign="center">
  //             <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
  //           </ATMTable.Row>
  //         )}
  //       </ATMTable.Body>
  //     </ATMTable>
  //   </>
  // );

  const caisoIds = (
    <>
      <ATMHeader
        as="h2"
        content={Lang.LBL_CAISO}
        className={styles.subHeader}
      />
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_EQUIPMENT}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data?.isoTrs?.caisoTrs && data.isoTrs.caisoTrs.length > 0 ? (
            data.isoTrs.caisoTrs.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{item.caisoId}</ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_SWITCH_ID}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_POSITION}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data.isoTrs?.switchings && data.isoTrs.switchings.length > 0 ? (
            data.isoTrs?.switchings.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{item.switchId}</ATMTable.Cell>
                <ATMTable.Cell>
                  {item.defaultPosInd ? Lang.LBL_OPEN : Lang.LBL_CLOSE}
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell colSpan="2">
                {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  // const equipmentRatingChange = (
  //   <>
  //     <ATMHeader as="h2" content={Lang.TTL_EQUIPMENT_RATING_CHANGE} />
  //     <ATMTable>
  //       <ATMTable.Header>
  //         <ATMTable.Row>
  //           <ATMTable.HeaderCell>{Lang.LBL_EQUIPMENT_ID}</ATMTable.HeaderCell>
  //           <ATMTable.HeaderCell>{Lang.LBL_NORMAL_RATING}</ATMTable.HeaderCell>
  //           <ATMTable.HeaderCell>
  //             {Lang.LBL_EMERGENCY_RATING}
  //           </ATMTable.HeaderCell>
  //           <ATMTable.HeaderCell>
  //             {Lang.LBL_HIGHEST_EMERGENCY_RATING}
  //           </ATMTable.HeaderCell>
  //           <ATMTable.HeaderCell>{Lang.LBL_START_TIME}</ATMTable.HeaderCell>
  //           <ATMTable.HeaderCell>{Lang.LBL_END_TIME}</ATMTable.HeaderCell>
  //         </ATMTable.Row>
  //       </ATMTable.Header>
  //       <ATMTable.Body>
  //         {data?.isoTrs?.equipRatings && data.isoTrs.equipRatings.length > 0 ? (
  //           data?.isoTrs?.equipRatings.map((item, i) => (
  //             <ATMTable.Row key={i}>
  //               <ATMTable.Cell>{item.equipmentId}</ATMTable.Cell>
  //               <ATMTable.Cell>{item.normalRating}</ATMTable.Cell>
  //               <ATMTable.Cell>{item.emergencyRating}</ATMTable.Cell>
  //               <ATMTable.Cell>{item.highEmergencyRating}</ATMTable.Cell>
  //               <ATMTable.Cell>{formatDateTime(item.startTime)}</ATMTable.Cell>
  //               <ATMTable.Cell>{formatDateTime(item.endTime)}</ATMTable.Cell>
  //             </ATMTable.Row>
  //           ))
  //         ) : (
  //           <ATMTable.Row textAlign="center">
  //             <ATMTable.Cell colSpan="6">
  //               {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
  //             </ATMTable.Cell>
  //           </ATMTable.Row>
  //         )}
  //       </ATMTable.Body>
  //     </ATMTable>
  //   </>
  // );

  const emsIds = (
    <>
      <ATMHeader as="h2" content={Lang.LBL_EMS} className={styles.subHeader} />
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_SWITCH_ID}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_TOP}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_STATION}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_BUS_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_EQUIPMENT_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_EQUIPMENT_TYPE}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_POSITION}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data?.isoTrs?.emsDatas && data?.isoTrs?.emsDatas.length > 0 ? (
            data?.isoTrs?.emsDatas.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{item.emsId}</ATMTable.Cell>
                <ATMTable.Cell>{item.top}</ATMTable.Cell>
                <ATMTable.Cell>{item.station}</ATMTable.Cell>
                <ATMTable.Cell>{item.busName}</ATMTable.Cell>
                <ATMTable.Cell>{item.equipmentName}</ATMTable.Cell>
                <ATMTable.Cell>{item.facTyp?.facTypNm}</ATMTable.Cell>
                <ATMTable.Cell>
                  {item.position ? Lang.LBL_OPEN : Lang.LBL_CLOSE}
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell colSpan="7">
                {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  const content = (
    <>
      <ATMGrid>
        {/* <ATMGrid.Row columns={3}>
          <ATMGrid.Column>{omsInformation}</ATMGrid.Column>
          <ATMGrid.Column />
          <ATMGrid.Column />
        </ATMGrid.Row> */}
        <ATMGrid.Row columns={3}>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_OC_LER_ISO_NATURE_WORK}>
              {data?.isoTrs?.isoWorkKindId?.replace(/_+/g, ' ')}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_OC_LER_ISO_PRIMARY_ISO_CAUSE_CODE}>
              {data?.isoTrs?.primIsoCauseCode?.replace(/_+/g, ' ')}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_OC_LER_ISO_SECONDARY_ISO_CAUSE_CODE}>
              {data?.isoTrs?.secondIsoCauseCode?.replace(/_+/g, ' ')}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns={3}>
          <ATMGrid.Column>{caisoIds}</ATMGrid.Column>
          <ATMGrid.Column />
          <ATMGrid.Column />
        </ATMGrid.Row>
        {/* <ATMGrid.Row columns={1}>
          <ATMGrid.Column>{equipmentRatingChange}</ATMGrid.Column>
        </ATMGrid.Row> */}
        <ATMGrid.Row columns={1}>
          <ATMGrid.Column>{emsIds}</ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <div>
      <div>
        {/* {header}2 */}
        <ATMGrid>
          <ATMGrid.Row columns={16}>
            <ATMGrid.Column width={8}>{header}</ATMGrid.Column>
            <ATMGrid.Column width={8}>
              {isCaisoPending(data?.pendingCaiso) &&
              hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
                <LERRequestSendToCaiso
                  requestStat={data?.requestStat}
                  data={data}
                />
              ) : (
                ''
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>

        {handleEdit ? (
          <ATMSegment attached="bottom">{content}</ATMSegment>
        ) : (
          content
        )}
      </div>
    </div>
  );
};

export default LERRequestIsoDetail;
