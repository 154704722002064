import React, { useCallback, useRef, useState } from 'react';
import { useTrainersContext } from 'src/contexts/trainers.context';
import { trainersActionTypes } from 'src/ducks/trainers.duck';
import { ITrainersForm } from 'src/models/trainers.model';
import { getTrainersStatus } from 'src/selectors/trainers.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import TrainersAddView from './trainers-add.view';
import { TrainerStatus } from '../trainers-table/trainers-table.component';

const TrainersAdd: React.FC = () => {
  const { state, actions } = useTrainersContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getTrainersStatus(
    state,
    trainersActionTypes.TRAINERS_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITrainersForm) => {
      const response = await actions.createPOST({
        ...formData,
        trainerStat: TrainerStatus.ACTIVE,
        updatedAt: new Date(),
      });

      if (!response.error) {
        actions.listGET({ page: 1, limit: 0 });
        setIsAddOpen(false);
        ToastSuccess('Trainer Added Successfully!');
      } else {
        setIsAddOpen(false);
        ToastError(`${response.error.message}`);
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <TrainersAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default TrainersAdd;
