import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/setting.service';
import { ISetting } from 'src/models/setting.model';

export const settingActionTypes = {
  SETTING_DATA_READ: 'SETTING_DATA_READ',
  SETTING_LIST_READ: 'SETTING_LIST_READ',
  SETTING_MODULE_LIST_READ: 'SETTING_MODULE_LIST_READ',
  SETTING_DATA_CREATE: 'SETTING_DATA_CREATE',
  SETTING_DATA_UPDATE: 'SETTING_DATA_UPDATE',
  SETTING_DATA_DELETE: 'SETTING_DATA_DELETE',
  SETTING_DATA_SET: 'SETTING_DATA_SET',
  SETTING_LIST_CLEAR: 'SETTING_LIST_CLEAR',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: settingActionTypes.SETTING_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: settingActionTypes.SETTING_LIST_READ,
    service: services.listGET,
  },

  listByModuleGET: {
    type: settingActionTypes.SETTING_MODULE_LIST_READ,
    service: services.listByModuleGET,
  },

  createPOST: {
    type: settingActionTypes.SETTING_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: settingActionTypes.SETTING_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: settingActionTypes.SETTING_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (setting: ISetting) => ({
    type: settingActionTypes.SETTING_DATA_SET,
    payload: {
      setting,
    },
  }),
  clearList: () => ({
    type: settingActionTypes.SETTING_LIST_CLEAR,
  }),
};

export type ISettingAsync = typeof duckActions;

export interface ISettingState extends ICommonState<typeof settingActionTypes> {
  data?: ISetting;
  list: ISetting[];
  moduleList: ISetting[];
  total: number;
  moduleTotal: number;
}

export const defaultState: ISettingState = {
  status: {},
  list: [],
  moduleList: [],
  total: 0,
  moduleTotal: 0,
};

const listUpdate = (oldList: ISetting[], newList: ISetting[] = []) => {
  if (newList && newList.length) {
    return oldList.map((item) => {
      const value = newList.find((v) => v.name === item.name);

      return value || item;
    });
  }

  return oldList;
};

const SettingReducer = (
  state: ISettingState,
  action: IReducerAction<ISettingAsync>
): ISettingState => {
  switch (action.type) {
    case settingActionTypes.SETTING_DATA_SET:
    case settingActionTypes.SETTING_DATA_READ:
    case settingActionTypes.SETTING_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.setting,
      };
    }

    case settingActionTypes.SETTING_DATA_UPDATE: {
      return {
        ...state,
        data: action.payload,
        list: listUpdate(state.list, action.payload && [action.payload]),
      };
    }

    case settingActionTypes.SETTING_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case settingActionTypes.SETTING_MODULE_LIST_READ: {
      return {
        ...state,
        list: listUpdate(state.list, action.payload?.rows),
        moduleList: action.payload?.rows ?? [],
        moduleTotal: action.payload?.count ?? 0,
      };
    }

    case settingActionTypes.SETTING_LIST_CLEAR: {
      return {
        ...state,
        moduleList: [],
        moduleTotal: 0,
      };
    }

    case settingActionTypes.SETTING_DATA_DELETE: {
      if (action.params) {
        const [name] = action.params;
        const list = state.list.filter((value) => value.name !== name);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default SettingReducer;
