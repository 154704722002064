import {
  isValidNumberField,
  ValidationErrorMessage,
} from 'src/helpers/field-validation.helper';
import yup from 'src/libraries/validator.library';

export const OcPendingActionReportShape = {
  completed: yup.string().required(),
  requestId: yup.number().required(),
  dailyOutageReportNotes: yup.string().required(),
  notesId: yup.number().required(),
  startDate: yup.date().nullable(),
  stopDate: yup.date().nullable(),
  outageTypeDesc: yup.string().nullable(),
  outgFacNm: yup.string().nullable(),
  subtationNm: yup.string().nullable(),
  voltNm: yup.string().nullable(),
};

export const DailyOutageRptFilterShape = {
  requestId: yup
    .string()
    .nullable()
    .test(
      'isValid',
      `Invalid LER. ${ValidationErrorMessage.NUMBER_ONLY}`,
      isValidNumberField
    ),
  fromDate: yup.string().nullable(),
  toDate: yup.string().nullable(),
};

export const OcPendingActionReportSchema = yup.object(
  OcPendingActionReportShape
);

export const OcPendingActionReportFormSchema = yup.object({
  notes: yup.string().nullable(),
  isComplete: yup.boolean(),
});

export const OcPendingActionReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(OcPendingActionReportSchema.clone()),
});

export const OcPendingActionReportPayloadSchema = yup.object({
  ocPendingActionReport: OcPendingActionReportSchema.clone(),
});

export const OcPendingActionReportDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IOcPendingActionReport = yup.Asserts<
  typeof OcPendingActionReportSchema
>;
export type IOcPendingActionReportForm = yup.Asserts<
  typeof OcPendingActionReportFormSchema
>;

export const DailyOutageRptFilterSchema = yup.object(DailyOutageRptFilterShape);
export type IDailyOutageRptFilter = yup.Asserts<
  typeof DailyOutageRptFilterSchema
>;
