import yup from 'src/libraries/validator.library';
import { AuthorizedListTrainingSchema } from './authorized-list.model';

export const ContractorShape = {
  fullName: yup.string(),
  lastName: yup.string(),
  firstName: yup.string(),
  middleName: yup.string().nullable(),
  empId: yup.string(),
  title: yup.string().nullable(),
  supervisorNumber: yup.string().nullable(),
  workAreaCode: yup.string().nullable(),
  workPhoneNumber: yup.string().nullable(),
  mailLoc: yup.string().nullable(),
  email: yup.string().nullable(),
  ccCd: yup.string().nullable(),
  roleId: yup.string().nullable(),
  modemUsrFlg: yup.string().nullable(),
  contracShortNm: yup.string().nullable(),
  employeeStat: yup.string().nullable(),
  cellAreaCode: yup.string().nullable(),
  cellPhoneNumber: yup.string().nullable(),
  orgCd: yup.string().nullable().nullable(),
  priorSupvrNbr: yup.string().nullable(),
  jobCd: yup.string().nullable(),
  tempActiveInd: yup.string().nullable(),
  employeeType: yup.string().nullable(),
  updatedAt: yup.string().nullable(),
  updatedBy: yup.string().nullable(),
  contractorFirm: yup
    .object({
      fullName: yup.string().nullable(),
    })
    .nullable(),
  trainings: yup.array().of(AuthorizedListTrainingSchema.clone()),
};

export const ContractorSchema = yup.object(ContractorShape);

export const ContractorFormSchema = yup.object({
  lastName: yup.string().min(2).max(30).required(),
  fullName: yup.string(),
  middleName: yup.string().max(30).nullable(),
  firstName: yup.string().min(2).max(30).required(),
  email: yup.string().email().nullable(),
  contracShortNm: yup.string().max(30).required(),
  employeeStat: yup.string().nullable(),
});

export const ContractorListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(ContractorSchema.clone()),
});

export const ContractorPayloadSchema = ContractorSchema;

export const ContractorDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IContractor = yup.Asserts<typeof ContractorSchema>;
export type IContractorForm = yup.Asserts<typeof ContractorFormSchema>;
