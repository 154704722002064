import React, { useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMDropdown,
  ATMTextArea,
  ATMHeader,
  ATMInput,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { checkUndefinedOrNullValue } from 'src/helpers/key-request.helper';
import Lang from 'src/libraries/language';
import {
  IIssuedKeyDetail,
  IIssuedKeyForm,
  IssuedKeyFormSchema,
} from 'src/models/issued-key.model';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IIssuedKeyForm>;
  keyDetails?: Partial<IIssuedKeyDetail>;
  handleSubmit: (data: IIssuedKeyForm) => void;
  handleEnable: (value: boolean) => void;
  isKeyRequest: boolean;
};

const IssuedKeyForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  keyDetails,
  isKeyRequest,
}) => {
  const [keyStatus, setKeyStatus] = useState();
  const { state: employeeState } = useEmployeeContext();
  enum EmployeeType {
    Supervisor = 'supervisor',
    Employee = 'employee',
  }
  const formatEmployeeName = (
    data: IIssuedKeyDetail,
    employeeType: EmployeeType
  ) => {
    let employeeName = '';
    if (data?.[0]?.emp) {
      switch (employeeType) {
        case EmployeeType.Employee: {
          employeeName = `${checkUndefinedOrNullValue(
            `${data?.[0]?.emp?.lastName}, ${data?.[0]?.emp?.firstName}`,
            Lang.LBL_UNAVAILABLE
          )}${checkUndefinedOrNullValue(`/${data?.[0]?.emp?.empId}`, '')}`;
          break;
        }
        case EmployeeType.Supervisor: {
          if (data?.[0].emp.supvr) {
            employeeName = `${checkUndefinedOrNullValue(
              `${data?.[0]?.emp.supvr?.lastName}, ${data?.[0].emp.supvr?.firstName}`,
              Lang.LBL_UNAVAILABLE
            )}${checkUndefinedOrNullValue(
              `/${data?.[0].emp.supvr?.empId}`,
              ''
            )}`;
          }
          break;
        }
      }
    }
    return employeeName;
  };
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues}
      validationSchema={IssuedKeyFormSchema}
    >
      {({ control, formState: { errors, isDirty } }) => {
        handleEnable(isDirty);

        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column columns="2">
                <ATMField
                  name="keyNbr"
                  control={control}
                  defaultValue={defaultValues?.keyNbr}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  name="keyTypId"
                  control={control}
                  defaultValue={defaultValues?.keyTypId}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  as={ATMDropdown}
                  selection
                  name="stat"
                  placeholder="Select"
                  label={Lang.LBL_KEY_STATUS}
                  defaultValues={defaultValues?.stat}
                  options={
                    isKeyRequest
                      ? [
                          ...Array.from(Object.keys(Lang.ISSUED_KEY_STATUS))
                            .filter(
                              (val) =>
                                Lang.ISSUED_KEY_STATUS[val] ===
                                  Lang.ISSUED_KEY_STATUS.Lost ||
                                Lang.ISSUED_KEY_STATUS[val] ===
                                  Lang.ISSUED_KEY_STATUS.Damaged ||
                                Lang.ISSUED_KEY_STATUS[val] ===
                                  Lang.ISSUED_KEY_STATUS[
                                    defaultValues?.stat ?? 'Active'
                                  ]
                            )
                            .map((val) => {
                              return {
                                key: val,
                                value: val,
                                text: Lang.ISSUED_KEY_STATUS[val],
                              };
                            }),
                        ]
                      : [
                          ...Array.from(
                            Object.keys(Lang.ISSUED_KEY_STATUS)
                          ).map((val) => {
                            return {
                              key: val,
                              value: val,
                              text: Lang.ISSUED_KEY_STATUS[val],
                            };
                          }),
                        ]
                  }
                  onChange={([_, { value }]) => {
                    setKeyStatus(value);
                    return value;
                  }}
                  control={control}
                  error={errors.stat}
                />
              </ATMGrid.Column>
              <ATMGrid.Column />
            </ATMGrid.Row>
            {keyStatus !== undefined && (
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <LabelRequired>
                    {Lang.LBL_KEY_REQUEST_INSSUANCE_REASON}
                  </LabelRequired>
                  {/* US #597770 Increase character limit to 500 */}
                  <ATMField
                    as={ATMTextArea}
                    name="reason"
                    size="large"
                    maxLength={500}
                    control={control}
                    error={errors.reason}
                    placeholder="Enter"
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            )}
            <ATMHeader as="h2">{Lang.LBL_KEY_DETAILS}</ATMHeader>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column columns="2">
                <FieldReadOnly label={Lang.LBL_KEY_HOLDER}>
                  {formatEmployeeName(
                    keyDetails as IIssuedKeyDetail,
                    EmployeeType.Employee
                  )}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_SUPERVISOR_MANAGER}>
                  {formatEmployeeName(
                    keyDetails as IIssuedKeyDetail,
                    EmployeeType.Supervisor
                  )}
                </FieldReadOnly>
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column columns="2">
                <FieldReadOnly label={Lang.LBL_KEY_ADMIN_lOCATION}>
                  {keyDetails && employeeState.list?.[0]?.mailstop_id}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_KEY_TYPE}>
                  {defaultValues?.keyTypId}
                </FieldReadOnly>
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column columns="2">
                <FieldReadOnly label={Lang.LBL_ISSUANCE_REASON}>
                  {keyDetails?.[0]?.reason}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column />
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default IssuedKeyForm;
