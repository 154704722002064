import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/facility.service';
import {
  IFacility,
  IFacilityEquipment,
  IFacilityFilter,
  IFacilityVolt,
} from 'src/models/facility.model';
import { IUserGroup } from 'src/models/user-group.model';

export const facilityActionTypes = {
  FACILITY_DATA_READ: 'FACILITY_DATA_READ',
  FACILITY_LIST_READ: 'FACILITY_LIST_READ',
  FACILITY_DATA_CREATE: 'FACILITY_DATA_CREATE',
  FACILITY_DATA_UPDATE: 'FACILITY_DATA_UPDATE',
  FACILITY_DATA_DELETE: 'FACILITY_DATA_DELETE',
  FACILITY_LIST_ALL_READ: 'FACILITY_LIST_ALL_READ',
  FACILITY_DATA_SET: 'FACILITY_DATA_SET',
  FACILITY_LIST_FILTER: 'FACILITY_LIST_FILTER',
  FACILITY_DATA_CLEAR: 'FACILITY_DATA_CLEAR',
  FACILITY_VOLT_SUBS_READ: 'FACILITY_VOLT_SUBS_READ',
  FACILITY_VOLT_LINE_READ: 'FACILITY_VOLT_LINE_READ',
  FACILITY_EQUIPMENT_READ: 'FACILITY_EQUIPMENT_READ',
  FACILITY_VOLT_CLEAR: 'FACILITY_VOLT_CLEAR',
  FACILITY_EQUIPMENT_CLEAR: 'FACILITY_EQUIPMENT_CLEAR',
  FACILITY_LER_DETAILS_READ: 'FACILITY_LER_DETAILS_READ',
  FACILITY_LER_DETAILS_CLEAR: 'FACILITY_LER_DETAILS_CLEAR',
  FACILITY_USER_GRP_DETAILS_READ: 'FACILITY_USER_GRP_DETAILS_READ',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: facilityActionTypes.FACILITY_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: facilityActionTypes.FACILITY_LIST_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: facilityActionTypes.FACILITY_LIST_ALL_READ,
    service: services.listGET,
  },

  filterGET: {
    type: facilityActionTypes.FACILITY_LIST_FILTER,
    service: services.filterGET,
  },

  createPOST: {
    type: facilityActionTypes.FACILITY_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: facilityActionTypes.FACILITY_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: facilityActionTypes.FACILITY_DATA_DELETE,
    service: services.dataDELETE,
  },

  lerFacilityDetails: {
    type: facilityActionTypes.FACILITY_LER_DETAILS_READ,
    service: services.dataGET,
  },

  userGrpDetails: {
    type: facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ,
    service: services.dataGET,
  },
  // This is a sync action
  setData: (facility: IFacility) => ({
    type: facilityActionTypes.FACILITY_DATA_SET,
    payload: facility,
  }),

  dataCLEAR: () => ({
    type: facilityActionTypes.FACILITY_DATA_CLEAR,
  }),

  voltSubstationGET: {
    type: facilityActionTypes.FACILITY_VOLT_SUBS_READ,
    service: services.voltSubstationGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  voltLineGET: {
    type: facilityActionTypes.FACILITY_VOLT_LINE_READ,
    service: services.voltLineGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  equipmentGET: {
    type: facilityActionTypes.FACILITY_EQUIPMENT_READ,
    service: services.equipmentGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  voltCLEAR: () => ({
    type: facilityActionTypes.FACILITY_VOLT_CLEAR,
  }),

  equipmentCLEAR: () => ({
    type: facilityActionTypes.FACILITY_EQUIPMENT_CLEAR,
  }),
  lerFacilityDetailsCLEAR: () => ({
    type: facilityActionTypes.FACILITY_LER_DETAILS_CLEAR,
  }),
};

export type IFacilityAsync = typeof duckActions;

export interface IFacilityState
  extends ICommonState<typeof facilityActionTypes> {
  data?: IFacility | null;
  list: IFacility[];
  total: number;
  filters: IFacilityFilter;
  volt: IFacilityVolt[];
  equipment: IFacilityEquipment[];
  lerDetails?: IFacility;
  lerUserGrp: IUserGroup[] | undefined;
  outgFacId: number | undefined;
}

export const defaultState: IFacilityState = {
  status: {},
  list: [],
  total: 0,
  filters: {} as IFacilityFilter,
  volt: [],
  equipment: [],
  lerUserGrp: undefined,
  outgFacId: 0,
};

const FacilityReducer = (
  state: IFacilityState,
  action: IReducerAction<IFacilityAsync>
): IFacilityState => {
  switch (action.type) {
    case facilityActionTypes.FACILITY_DATA_SET:
    case facilityActionTypes.FACILITY_DATA_READ:
    case facilityActionTypes.FACILITY_DATA_UPDATE:
    case facilityActionTypes.FACILITY_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case facilityActionTypes.FACILITY_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case facilityActionTypes.FACILITY_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.outgFacId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }
      return state;
    }

    case facilityActionTypes.FACILITY_LIST_FILTER: {
      return {
        ...state,
        filters: action.payload ?? ({} as IFacilityFilter),
      };
    }

    case facilityActionTypes.FACILITY_DATA_CLEAR: {
      return {
        ...state,
        data: null,
      };
    }

    case facilityActionTypes.FACILITY_VOLT_CLEAR: {
      return {
        ...state,
        volt: [],
      };
    }

    case facilityActionTypes.FACILITY_EQUIPMENT_CLEAR: {
      return {
        ...state,
        equipment: [],
      };
    }

    case facilityActionTypes.FACILITY_VOLT_SUBS_READ: {
      return {
        ...state,
        volt: action.payload ?? [],
      };
    }

    case facilityActionTypes.FACILITY_VOLT_LINE_READ: {
      return {
        ...state,
        volt: action.payload ?? [],
      };
    }

    case facilityActionTypes.FACILITY_EQUIPMENT_READ: {
      return {
        ...state,
        equipment: action.payload ?? [],
      };
    }

    case facilityActionTypes.FACILITY_LER_DETAILS_READ: {
      return {
        ...state,
        lerDetails: action.payload,
        lerUserGrp: action.payload?.userGrps ?? [],
        outgFacId: action.payload?.outgFacId,
      };
    }

    case facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ: {
      return {
        ...state,
        lerUserGrp: action.payload?.userGrps ?? [],
        outgFacId: action.payload?.outgFacId ?? 0,
      };
    }

    case facilityActionTypes.FACILITY_LER_DETAILS_CLEAR: {
      return {
        ...state,
        lerDetails: {} as IFacility,
      };
    }

    default: {
      return state;
    }
  }
};

export default FacilityReducer;
