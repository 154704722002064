import React, { useCallback, useEffect } from 'react';
import { ATMTab, IATMTabProps } from 'shared-it-appmod-ui';
import useStatusTab from 'src/hooks/status-tab.hook';
import classNames from 'classnames';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { DailyReportViewTab } from 'src/constants/daily-reports.constant';
import styles from './daily-reports-tabs.module.scss';

type IProps = IATMTabProps & {
  name: string;
};

const DailyReportTabs: React.FC<IProps> = ({ name, ...props }) => {
  const { currentTab, handleTabChange } = useStatusTab(name);
  const {
    state: { viewTab, ...dailyReportState },
    actions,
  } = useDailyReportsContext();
  const handleTabs = useCallback(
    (tab: string) => {
      switch (tab) {
        case DailyReportViewTab.Overview: {
          actions?.stashDailyReportDetails(
            dailyReportState.overviewDate,
            DailyReportViewTab.Overview
          );
          break;
        }
        case DailyReportViewTab.ETS: {
          actions.stashDailyReportDetails(
            dailyReportState?.etsSelectedDate,
            DailyReportViewTab.ETS
          );
          break;
        }
        case DailyReportViewTab.RadializedSubstations: {
          actions?.stashDailyReportDetails(
            dailyReportState.radializedSubSelectedDate,
            DailyReportViewTab.RadializedSubstations
          );
          break;
        }
        case DailyReportViewTab.SystemChanges: {
          actions?.stashDailyReportDetails(
            dailyReportState.systemChangesDate,
            DailyReportViewTab.SystemChanges
          );
          break;
        }
      }
    },
    [dailyReportState]
  );

  useEffect(() => {
    switch (viewTab) {
      case DailyReportViewTab.ETS: {
        handleTabChange(
          props.panes?.findIndex(
            (item) => item.menuItem.key === DailyReportViewTab.ETS
          )
        );
        break;
      }
      case DailyReportViewTab.RadializedSubstations: {
        handleTabChange(
          props.panes?.findIndex(
            (item) =>
              item.menuItem.key === DailyReportViewTab.RadializedSubstations
          )
        );
        break;
      }
      case DailyReportViewTab.SystemChanges: {
        handleTabChange(
          props.panes?.findIndex(
            (item) => item.menuItem.key === DailyReportViewTab.SystemChanges
          )
        );
        break;
      }
      case DailyReportViewTab.Overview: {
        handleTabChange(
          props.panes?.findIndex(
            (item) => item.menuItem.key === DailyReportViewTab.Overview
          )
        );
      }
    }
    // handleTabs();
  }, [viewTab]);

  return (
    <div className={styles.container}>
      <ATMTab
        {...props}
        activeIndex={currentTab}
        className={classNames('tab-menu', props.className)}
        onTabChange={(_, data) => {
          handleTabChange(data.activeIndex);
          const pane = data.panes?.find((_d, i) => i === data.activeIndex);
          handleTabs(pane?.menuItem.key);
        }}
      />
    </div>
  );
};

export default DailyReportTabs;
