import { httpClient } from 'src/libraries/http.library';
import { SuccessSchema } from 'src/models';
import {
  OcPendingActionReportListPayloadSchema,
  OcPendingActionReportPayloadSchema,
  IOcPendingActionReportForm,
} from 'src/models/oc-pending-action-report.model';

const client = httpClient();
const endpoint = 'oc-pending-actions-report';

const services = {
  dataGET: async (id: number) => {
    return client.get(
      `/${endpoint}/${id}`,
      {},
      OcPendingActionReportPayloadSchema
    );
  },

  listGET: async (data?: Record<string, any>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    const result = client.get(
      `/${endpoint}`,
      params,
      OcPendingActionReportListPayloadSchema
    );

    return result;
  },

  updatePUT: async (id: number, data: IOcPendingActionReportForm) => {
    const result = client.put(
      `/${endpoint}/${id}`,
      data,
      OcPendingActionReportPayloadSchema
    );

    return result;
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, SuccessSchema);
  },
};

export default services;
