import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IRealTimeLog,
  IRealTimeLogEntryForm,
} from 'src/models/real-time-log.model';
import RealTimeLogForm from '../real-time-log-form/real-time-log-form.component';
import style from './real-time-log-edit.module.scss';

type IProps = {
  defaultValues?: IRealTimeLog;
  isEditOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IRealTimeLogEntryForm) => void;
  setIsChangeLogUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  changeLogUpdateComplete: boolean;
  setChangeLogUpdateComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
  // handleForcedOutageEmail: () => void;
  // clearForcedOutageNotice: () => void;
  // // isForcedOutageSubmitted: boolean;
  // // isForcedOutageConfirmOpen: boolean;
  // // setIsForcedOutageConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RealTimeLogEditView: React.FC<IProps> = ({
  defaultValues,
  isEditOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
  setIsChangeLogUpdate,
  changeLogUpdateComplete,
  setChangeLogUpdateComplete,
  setTripOnEdit,
  // handleForcedOutageEmail,
  // clearForcedOutageNotice,
  // // isForcedOutageSubmitted,
  // // isForcedOutageConfirmOpen,
  // // setIsForcedOutageConfirmOpen,
  // // handleForcedOutageEmail,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [changeLogOnEditMode, setChangeLogOnEditMode] = useState(false);

  return (
    <ATMModal
      open={isEditOpen}
      size="small"
      trigger={
        <ATMButton
          icon="edit"
          onClick={() => handleOpen(true)}
          compact
          className={style.manageDropdown}
        />
      }
    >
      <ATMModal.Header>{Lang.TTL_EDIT_ENTRY_LOG}</ATMModal.Header>
      {/* <ATMModal.Header
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <>
          <span>{Lang.TTL_EDIT_ENTRY_LOG}</span>
          <span>
            <ATMButton
              type="button"
              secondary
              content={Lang.LBL_FORCED_OUTAGE_NOTICE}
              onClick={handleForcedOutageEmail}
            />
            <ATMButton
              type="button"
              icon="close"
              onClick={clearForcedOutageNotice}
            />
          </span>
        </>
      </ATMModal.Header> */}
      <ATMModal.Content style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <div>
          <RealTimeLogForm
            defaultValues={defaultValues}
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            handleClick={handleClick}
            setIsChangeLogUpdate={setIsChangeLogUpdate}
            changeLogUpdateComplete={changeLogUpdateComplete}
            setChangeLogUpdateComplete={setChangeLogUpdateComplete}
            setChangeLogOnEditMode={setChangeLogOnEditMode}
            loading={loading}
            setTripOnEdit={setTripOnEdit}
            // isForcedOutageSubmitted={isForcedOutageSubmitted}
            // isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
            // setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
            // handleForcedOutageEmail={handleForcedOutageEmail}
            // handleOpen={handleOpen}
          />
        </div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading || changeLogOnEditMode}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogEditView;
