/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  ATMAccordion,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  MOLReadonlyField,
} from 'shared-it-appmod-ui';
import SpecialConcern from 'src/components/molecules/special-concern/special-concern.component';
import { FacilityType } from 'src/constants';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { facilityActionTypes, IFacilityState } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import {
  ILerRequestOutageFacility,
  ILerRequest,
} from 'src/models/ler-request.model';
// import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
// import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { getOutageTypeStatus } from 'src/selectors/outage-type.selector';
import { outageTypeActionTypes } from 'src/ducks/outage-type.duck';
import styles from './ler-request-outage-facility.module.scss';

type IProps = {
  data?: ILerRequestOutageFacility;
  details?: ILerRequest;
  handleEdit?: () => void;
};

const LERRequestOutageFacility: React.FC<IProps> = ({
  data,
  details,
  handleEdit,
}) => {
  const [active, setActive] = useState(true);
  const { state: outageTypeState, actions: outageTypeActions } =
    useOutageTypeContext();
  const { list } = outageTypeState;
  const outageTypeStatus = getOutageTypeStatus(
    outageTypeState,
    outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ
  );

  const {
    state: { equipment, volt, lerDetails, ...facilityState },
    actions: facilityActions,
  } = useFacilityContext();

  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { line } = realtimeLogState;
  const realtimeLogStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { list: equipmentList } = equipmentTypeState;
  // const equipmentTypeStatus = getEquipmentTypeStatus(
  //   equipmentTypeState,
  //   equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  // );

  const {
    state: { list: substationList },
    actions: substationActions,
  } = useSubstationContext();

  useEffect(() => {
    if (!list.length && !outageTypeStatus.fetching && !outageTypeStatus.error) {
      outageTypeActions.activeListGET();
    }
  }, [outageTypeActions, list, outageTypeStatus]);

  useEffect(() => {
    if (
      !line.length &&
      !realtimeLogStatus.fetching &&
      !realtimeLogStatus.error
    ) {
      realtimeLogActions.lineGET();
    }
  }, [realtimeLogActions, line, realtimeLogStatus]);

  // useEffect(() => {
  //   if (
  //     !equipmentList.length &&
  //     !equipmentTypeStatus.fetching &&
  //     !equipmentTypeStatus.error
  //   ) {
  //     equipmentTypeActions.listGET({
  //       limit: 0,
  //       page: 1,
  //     });
  //   }
  // }, [equipmentTypeActions, equipmentList, equipmentTypeStatus]);

  useEffect(() => {
    // We only fetch these API if in view mode
    if (
      details &&
      details.outageFacility?.facTypId &&
      details.outageFacility?.substationId &&
      details.outageFacility?.voltId &&
      details.outageFacility?.outgFacId &&
      !volt.length &&
      !equipment.length
    ) {
      const equipmentStatus = getFacilityStatus(
        facilityState as IFacilityState,
        facilityActionTypes.FACILITY_EQUIPMENT_READ
      );

      const voltStatus = getFacilityStatus(
        facilityState as IFacilityState,
        facilityActionTypes.FACILITY_VOLT_SUBS_READ
      );

      if (!equipmentStatus.fetching && !voltStatus.fetching) {
        substationActions.listGET({
          limit: 0,
          page: 1,
        });

        facilityActions.voltSubstationGET(
          details.outageFacility?.substationId,
          details.outageFacility?.facTypId
        );
        facilityActions.equipmentGET(
          details.outageFacility?.facTypId,
          details.outageFacility?.voltId,
          undefined,
          details.outageFacility?.substationId
        );
      }
    }
  }, [
    details,
    volt,
    equipment,
    substationActions,
    facilityActions,
    equipmentTypeActions,
  ]);

  if (!data) {
    return null;
  }

  const facilityDetails = (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="building outline" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_FACILITY_INFORMATION}
        className={styles.header}
      />

      <SpecialConcern outgFacId={data?.outgFacId} />

      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FACILITY_TYPE}
              // value={details?.outageFacility.facility?.facTyp?.facTypNm ?? '-'}
              value={
                details?.outageFacility.substationId &&
                details.outageFacility.outgFacId
                  ? Lang.FACILITY_TYPE[FacilityType.Station]
                  : Lang.FACILITY_TYPE[FacilityType.Line]
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_KV}
              value={details?.outageFacility.facility?.volt?.voltNm ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_EQUIPMENT}
              value={
                details?.outageFacility.substation
                  ? details?.outageFacility?.facility?.outgFacNm
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FACILITY_INFO_LINE_STATION}
              value={
                details?.outageFacility.substationId
                  ? details.outageFacility.substation?.name
                  : details?.outageFacility.outgFacId
                  ? details.outageFacility.facility?.outgFacNm
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FACILITY_INFO_AUTHORIZATION}
              value={details?.outageFacility.outageType?.outageTypeDesc ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return details ? (
    facilityDetails
  ) : (
    <ATMAccordion style={{ marginBottom: '1rem' }} fluid>
      <ATMAccordion.Title
        active={active}
        index={0}
        onClick={() => setActive((val) => !val)}
      >
        <ATMIcon name="angle down" />
        {Lang.TTL_FACILITY_INFORMATION}

        {handleEdit && (
          <a className={styles.edit} onClick={handleEdit} role="button">
            <ATMIcon name="edit" />
            {Lang.LBL_EDIT}
          </a>
        )}
      </ATMAccordion.Title>
      <ATMAccordion.Content active={active}>
        <SpecialConcern outgFacId={data?.outgFacId} />

        <ATMGrid columns={2}>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_FACILITY_TYPE}
                value={
                  data.substationId
                    ? Lang.FACILITY_TYPE[FacilityType.Station]
                    : Lang.FACILITY_TYPE[FacilityType.Line]
                }
                inline={false}
              />
            </ATMGrid.Column>

            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_LINE_STATION}
                value={
                  data.substationId && substationList.length
                    ? substationList.find(
                        (v) => v.substationId === data.substationId
                      )?.name
                    : line.find((v) => v.outgFacId === data.outgFacId)
                        ?.outgFacNm
                }
                inline={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_EQUIPMENT_VOLTAGE}
                value={
                  data.voltId && volt.length
                    ? volt.find((v) => v.voltId === data.voltId)?.name
                    : lerDetails?.volt?.voltNm
                }
                inline={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_AUTHORIZATION_TYPE}
                value={
                  data.outgTypId && list.length
                    ? list.find((v) => v.outageTypeId === data.outgTypId)
                        ?.outageTypeDesc
                    : data.outgTypId
                }
                inline={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_EQUIPMENT_TYPE}
                value={
                  data.facTypId && equipmentList.length
                    ? equipmentList.find((v) => v.facTypId === data.facTypId)
                        ?.facTypNm
                    : data.facTypId
                    ? data.facTypId
                    : '-'
                }
                inline={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <MOLReadonlyField
                label={Lang.LBL_EQUIPMENT}
                value={
                  data.outgFacId && equipment.length
                    ? equipment.find((v) => v.outgFacId === data.outgFacId)
                        ?.outgFacNm
                    : '-'
                }
                inline={false}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMAccordion.Content>
    </ATMAccordion>
  );
};

export default LERRequestOutageFacility;
