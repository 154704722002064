import React from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMDropdown,
  ATMInput,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import Lang from 'src/libraries/language';
import {
  ITsoOssEmployee,
  ITsoOssEmployeeForm,
  TsoOssEmployeeFormSchema,
} from 'src/models/tso-oss-employee.model';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ITsoOssEmployee>;
  handleSubmit: (data: ITsoOssEmployeeForm) => void;
  handleEnable: (value: boolean) => void;
};

const TsoOssEmployeeForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => (
  <ATMForm
    ref={formRef}
    onSubmit={handleSubmit}
    mode="onChange"
    defaultValues={TsoOssEmployeeFormSchema.cast(defaultValues)}
    validationSchema={TsoOssEmployeeFormSchema}
  >
    {({ control, formState: { errors, isDirty } }) => {
      handleEnable(isDirty);
      return (
        <ATMGrid style={{ marginBottom: '1.5rem' }}>
          <ATMGrid.Row columns="2">
            <ATMGrid.Column>
              {!defaultValues ? (
                <ATMField
                  as={DropdownEmployee}
                  control={control}
                  name="empId"
                  error={errors.empId}
                  label={<LabelRequired>Employee Name / ID</LabelRequired>}
                  onChange={([_, { value }]) => value}
                />
              ) : (
                <>
                  <FieldReadOnly label={Lang.LBL_TSO_OSS_EMPLOYEE_NAME}>
                    {defaultValues.fullName ?? '--'}
                  </FieldReadOnly>
                  <ATMField
                    name="empId"
                    control={control}
                    defaultValue={defaultValues?.empId}
                    as={ATMInput}
                    type="hidden"
                    className="hidden"
                  />
                </>
              )}
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMField
                as={ATMDropdown}
                control={control}
                name="userType"
                label={
                  <LabelRequired>
                    {Lang.LBL_TSO_OSS_EMPLOYEE_ROLE_TITLE}
                  </LabelRequired>
                }
                error={errors.userType}
                selection
                placeholder="Select"
                options={[
                  {
                    key: 'tso',
                    value: 'tso',
                    text: 'TSO - Transmission System Operator',
                  },
                  {
                    key: 'oss',
                    value: 'oss',
                    text: 'OSS - Ops Shift Supervisor',
                  },
                ]}
                onChange={([_, { value }]) => value}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      );
    }}
  </ATMForm>
);

export default TsoOssEmployeeForm;
