import React from 'react';
import { Link } from 'react-router-dom';
import { ATMButton, ATMDropdown } from 'shared-it-appmod-ui';
import { hasRole } from 'src/libraries/access.library';
import { ILerRequest } from 'src/models/ler-request.model';
import { AccessRole, LERRequestPage, LERRequestStatus } from 'src/constants';
import {
  hasChangeRequest,
  isRequestNewAndCreatedBySPVorOPE,
} from 'src/helpers/ler-request.helper';
import Lang from 'src/libraries/language';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { IFileSharePoint } from 'src/models/file.model';
import LERRequestPrint from '../ler-request-print/ler-request-print.component';
import LERRequestDelete from '../ler-request-delete/ler-request-delete.component';
import LERRequestCancel from '../ler-request-cancel/ler-request-cancel.component';
import LerRequestStudy from '../ler-request-study/ler-request-study.component';
import LerRequestSchedule from '../ler-request-schedule/ler-request-schedule.component';
import LERRequestEmailNotify from '../ler-request-email/ler-request-email-notify/ler-request-email-notify.component';
import styles from './ler-request-action-list.module.scss';

type IProps = {
  data: ILerRequest;
  setClose: () => void;
  setPrintLoader?: (params: boolean) => void;
  printLoader?: boolean;
  docList?: IFileSharePoint[] | undefined;
  page?: string;
};

const LerRequestActionList: React.FC<IProps> = ({
  data,
  setClose,
  setPrintLoader,
  printLoader = false,
}) => {
  const { getUrl } = useLocationParams([
    LERRequestPage.Clone,
    LERRequestPage.View,
    LERRequestPage.Edit,
  ]);
  const isChangeRequest = hasChangeRequest(data);
  const isCompletedOrCancelled = [
    LERRequestStatus.Completed,
    LERRequestStatus.Cancelled,
  ].includes(data.requestStat as LERRequestStatus);

  return (
    <ATMDropdown
      className={styles.reqDropdown}
      size="small"
      key=""
      trigger={
        <span className={styles.ellipseButton}>
          <ATMButton
            secondary
            type="button"
            size="small"
            icon="ellipsis horizontal"
            // loading={printLoader}
            // disabled={printLoader}
          />
        </span>
      }
    >
      <ATMDropdown.Menu
        onChange={(e) => {
          e.preventDefault();
          document.body.click();
        }}
      >
        {isCompletedOrCancelled === false && (
          <>
            {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && (
              <LERRequestEmailNotify data={data} />
            )}

            <LERRequestDelete
              title={
                !isChangeRequest || isRequestNewAndCreatedBySPVorOPE(data)
                  ? Lang.LBL_LER_REQUEST_WITHDRAW
                  : Lang.LBL_LER_REQUEST_WITHDRAW_CHANGE
              }
              data={data}
              trigger={
                <ATMDropdown.Item
                  content={
                    !isChangeRequest || isRequestNewAndCreatedBySPVorOPE(data)
                      ? Lang.LBL_LER_REQUEST_WITHDRAW
                      : Lang.LBL_LER_REQUEST_WITHDRAW_CHANGE
                  }
                  icon="trash"
                />
              }
              handleSuccess={setClose}
            />

            <LERRequestCancel
              data={data}
              trigger={
                <ATMDropdown.Item
                  onClick={() => {
                    console.log('click -------------------------------------');
                  }}
                  content={
                    hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
                      ? Lang.LBL_CANCEL_LER
                      : Lang.LBL_CHANGE_REQUEST_CANCEL
                  }
                  icon="ban"
                />
              }
              handleSuccess={setClose}
            />
            <LerRequestSchedule
              data={data}
              trigger={
                <ATMDropdown.Item content={Lang.LBL_SCHEDULE} icon="calendar" />
              }
              handleSuccess={setClose}
            />
            <LerRequestStudy
              data={data}
              trigger={
                <ATMDropdown.Item content={Lang.LBL_SET_STUDY} icon="book" />
              }
              handleSuccess={setClose}
            />
          </>
        )}
        <LERRequestPrint
          data={data}
          trigger={<ATMDropdown.Item content={Lang.LBL_PRINT} icon="print" />}
          setPrintLoader={setPrintLoader}
          printLoader={printLoader}
        />
        <ATMDropdown.Item
          as={Link}
          content={Lang.LBL_CLONE}
          icon="copy outline"
          to={getUrl({
            [LERRequestPage.Edit]: undefined,
            [LERRequestPage.View]: undefined,
            [LERRequestPage.Clone]: data.requestId,
          })}
        />
      </ATMDropdown.Menu>
    </ATMDropdown>
  );
};

export default LerRequestActionList;
