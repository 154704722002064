import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/short-description.service';
import {
  IShortDescription,
  IShortDescriptionCategory,
  IShortDescriptionCategoryList,
} from 'src/models/short-description.model';

export const shortDescriptionActionTypes = {
  SHORT_DESCRIPTION_LIST_READ: 'SHORT_DESCRIPTION_LIST_READ',
  SHORT_DESCRIPTION_CATEGORY_LIST_READ: 'SHORT_DESCRIPTION_CATEGORY_LIST_READ',
  SHORT_DESCRIPTION_CATEGORY_CREATE: 'SHORT_DESCRIPTION_CATEGORY_CREATE',
  SHORT_DESCRIPTION_CATEGORY_UPDATE: 'SHORT_DESCRIPTION_CATEGORY_UPDATE',
  SHORT_DESCRIPTION_CATEGORY_DELETE: 'SHORT_DESCRIPTION_CATEGORY_DELETE',
  SHORT_DESCRIPTION_DELETE: 'SHORT_DESCRIPTION_DELETE',
} as const;

export const duckActions = {
  listGET: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_LIST_READ,
    service: services.listGET,
  },
  listCategoriesAndDescGET: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_LIST_READ,
    service: services.listCategoriesAndDescGET,
  },
  createCategoryAndDescsPOST: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_CREATE,
    service: services.createCategoryAndDescsPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },
  updateCategoryAndDescsPUT: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_UPDATE,
    service: services.updateCategoryAndDescsPUT,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },
  deleteShortDescCategory: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_DELETE,
    service: services.deleteShortDescCategory,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },
  deleteShortDesc: {
    type: shortDescriptionActionTypes.SHORT_DESCRIPTION_DELETE,
    service: services.deleteShortDesc,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },
};

export type IShortDescriptionAsync = typeof duckActions;

export interface IShortDescriptionState
  extends ICommonState<typeof shortDescriptionActionTypes> {
  list: IShortDescription[];
  categories: IShortDescriptionCategory[];
  listItems: IShortDescriptionCategoryList[];
  data?: IShortDescriptionCategory;
}

export const defaultState: IShortDescriptionState = {
  status: {},
  list: [],
  categories: [],
  listItems: [],
};

const ShortDescriptionReducer = (
  state: IShortDescriptionState,
  action: IReducerAction<IShortDescriptionAsync>
): IShortDescriptionState => {
  switch (action.type) {
    case shortDescriptionActionTypes.SHORT_DESCRIPTION_LIST_READ: {
      return {
        ...state,
        list: action.payload?.shortDescriptions ?? [],
        categories: action.payload?.shortDescCategories ?? [],
      };
    }
    case shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_LIST_READ: {
      return {
        ...state,
        listItems: action.payload ?? [],
      };
    }

    case shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_CREATE:
    case shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default ShortDescriptionReducer;
