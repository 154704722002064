import React from 'react';
import {
  MOLRightPanel,
  ATMHeader,
  ATMGrid,
  ATMIcon,
  ORGDataTable,
  ATMButton,
  IORGDataTableColumn,
} from 'shared-it-appmod-ui';
import DictionaryRename from 'src/components/atoms/dictionary-rename/dictionary-rename.component';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import {
  checkUndefinedOrNullValue,
  convertKeyNumberToFiveDigits,
} from 'src/helpers/key-request.helper';
import Lang from 'src/libraries/language';
import { formatDate, formatDateTz } from 'src/libraries/moment.library';
import { IIssuedKey, IIssuedKeyDetail } from 'src/models/issued-key.model';
import styles from './key-details.module.scss';

type IProp = {
  onClose: boolean;
  setOnClose: () => void;
  issuedKeyData: IIssuedKey;
  handleDownload: () => void;
};

const columns: IORGDataTableColumn<IIssuedKeyDetail>[] = [
  {
    title: Lang.LBL_KEY_STATUS,
    index: 'stat',
    render: (_, value) => (
      <div className={styles.tableCellFlex}>
        <ATMHeader
          as="h3"
          className={styles.capitalizeText}
          style={{ width: '40%', marginBottom: '0.5rem' }}
        >
          {value.stat}
        </ATMHeader>
        <div>{value ? value.reason : ''}</div>
      </div>
    ),
  },
  {
    title: Lang.LBL_KEY_ISSUED_TO,
    index: 'empId',
    render: (_, value) => (
      <div>
        <div>
          <>
            {value.emp && value.emp.title === 'Contractor' ? (
              <ATMIcon name="building" size="small" />
            ) : (
              <ATMIcon name="user" size="small" />
            )}
            <span>
              {' '}
              {`${checkUndefinedOrNullValue(
                `${value?.emp?.lastName}, ${value?.emp?.firstName}`,
                Lang.LBL_UNAVAILABLE
              )}${checkUndefinedOrNullValue(`/${value?.emp?.empId}`, '')}`}
            </span>
          </>
        </div>
        <div className={styles.keyHistoryFlex}>
          <ATMIcon
            name="circle"
            color="green"
            size="small"
            className={styles.marginTopSmall}
          />
          <ATMHeader as="h3" className={styles.keyHistoryTextMargin}>
            {value.emp ? value.emp.title : ''}{' '}
          </ATMHeader>
        </div>
      </div>
    ),
  },
  {
    title: Lang.LBL_KEY_ISSUE_DATE,
    index: 'issDt',
    render: (_, value) => (value ? formatDateTz(value.issDt) : ''),
  },
  {
    title: Lang.LBL_KEY_END_DATE,
    index: 'returnDt',
    render: (_, value) => (value ? formatDate(value.returnDt) : ''),
  },
];

const KeyDetailsView: React.FC<IProp> = ({
  setOnClose,
  onClose,
  issuedKeyData,
  handleDownload,
}) => {
  const { state: employeeState } = useEmployeeContext();
  enum EmployeeType {
    Supervisor = 'supervisor',
    Employee = 'employee',
  }
  const formatEmployeeName = (data: IIssuedKey, employeeType: EmployeeType) => {
    let employeeName = '';
    if (data.keyDetails && data.keyDetails.length && data.keyDetails[0].emp) {
      switch (employeeType) {
        case EmployeeType.Employee: {
          employeeName = `${checkUndefinedOrNullValue(
            `${data.keyDetails[0].emp?.lastName}, ${data.keyDetails[0].emp?.firstName}`,
            Lang.LBL_UNAVAILABLE
          )}${checkUndefinedOrNullValue(
            `/${data.keyDetails[0].emp?.empId}`,
            ''
          )}`;
          break;
        }
        case EmployeeType.Supervisor: {
          if (data.keyDetails[0].emp.supvr) {
            employeeName = `${checkUndefinedOrNullValue(
              `${data.keyDetails[0].emp.supvr?.lastName}, ${data.keyDetails[0].emp.supvr?.firstName}`,
              Lang.LBL_UNAVAILABLE
            )}${checkUndefinedOrNullValue(
              `/${data.keyDetails[0].emp.supvr?.empId}`,
              ''
            )}`;
          }
          break;
        }
      }
    }
    return employeeName;
  };
  return (
    <MOLRightPanel
      isOpen={onClose}
      onClose={setOnClose}
      header={`Key No. ${convertKeyNumberToFiveDigits(issuedKeyData.keyNbr)}`}
      subHeaderContent={
        <div className={styles.subHeaderName}>
          <ATMHeader as="h3" className={styles.textMargin}>
            {issuedKeyData.keyDetails && issuedKeyData.keyDetails.length > 0
              ? `${issuedKeyData.keyDetails[0].emp?.lastName}, ${issuedKeyData.keyDetails[0].emp?.firstName}/${issuedKeyData.keyDetails[0].emp?.empId}`
              : ''}
            {formatEmployeeName(issuedKeyData, EmployeeType.Employee)}
          </ATMHeader>
          <div className={styles.flex}>
            <ATMIcon
              name="circle"
              color="green"
              size="small"
              className={styles.marginTopSmall}
            />
            <ATMHeader as="h3" className={styles.textMargin}>
              <DictionaryRename value={issuedKeyData.stat} />
            </ATMHeader>
          </div>
        </div>
      }
      panelContent={
        <div style={{ marginTop: 0 }}>
          <ATMHeader as="h2" className={styles.marginBottomSmall}>
            {Lang.TTL_KEY_DETAILS}
          </ATMHeader>
          <ATMGrid divided="vertically">
            <ATMGrid.Row columns={2}>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_KEY_ISSUED_TO}>
                  {formatEmployeeName(issuedKeyData, EmployeeType.Employee)}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_KEY_SUPERVISOR_MANAGER}>
                  {formatEmployeeName(issuedKeyData, EmployeeType.Supervisor)}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_KEY_ADMIN_LOCATION}>
                  {issuedKeyData?.keyDetails &&
                    employeeState.list?.[0]?.mailstop_id}
                </FieldReadOnly>
              </ATMGrid.Column>
              <ATMGrid.Column>
                <FieldReadOnly label={Lang.LBL_KEY_ISSUANCE_REASON}>
                  {issuedKeyData.keyDetails &&
                  issuedKeyData.keyDetails.length > 0
                    ? issuedKeyData.keyDetails[0].reason
                    : ''}
                </FieldReadOnly>
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column>
                <div className={styles.titleKeyHistory}>
                  <ATMHeader as="h2" className={styles.marginBottomSmall}>
                    {Lang.TTL_KEY_HISTORY}
                  </ATMHeader>
                  <ATMButton
                    key="download"
                    secondary
                    size="small"
                    icon="download"
                    content="Download Report"
                    onClick={handleDownload}
                  />
                </div>
                <ORGDataTable
                  columns={columns}
                  data={
                    !issuedKeyData.keyDetails ? [] : issuedKeyData.keyDetails
                  }
                  showPagination={false}
                  celled={false}
                />
                <div />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      }
      panelFooter={
        <div className={styles.panelFooter}>
          <ATMButton secondary size="small" onClick={setOnClose}>
            Close
          </ATMButton>
        </div>
      }
    />
  );
};

export default KeyDetailsView;
