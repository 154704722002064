import React, { useEffect, useCallback } from 'react';
import { useSettingContext } from 'src/contexts/setting.context';
import { getSettingStatus } from 'src/selectors/setting.selector';
import { settingActionTypes } from 'src/ducks/setting.duck';
import { SettingModule } from 'src/constants';
import OutagePlanningSettingsView from './outage-planning-settings.view';

const OutagePlanningSettings: React.FC = () => {
  const { state, actions } = useSettingContext();
  const status = getSettingStatus(
    state,
    settingActionTypes.SETTING_MODULE_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listByModuleGET(SettingModule.OutagePlanning);
  }, [actions]);

  useEffect(() => {
    handleFetch();
  }, [actions]);

  const handleDownload = () => {};
  return (
    <OutagePlanningSettingsView
      data={state.moduleList}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
    />
  );
};

export default OutagePlanningSettings;
