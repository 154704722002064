import React, { useCallback, useState } from 'react';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { IKeyRequest } from 'src/models/key-request.model';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import history from 'src/history';
import KeyRequestDeleteView from './key-request-delete.view';

type IProp = {
  data: IKeyRequest;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const KeyRequestDelete: React.FC<IProp> = ({ data, handleOpen }) => {
  const { state, actions } = useKeyRequestContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_REJECT
  );

  const handleSubmit = useCallback(async () => {
    if (data.requestNbr) {
      const response = await actions.rejectPUT(data.requestNbr);

      if (!response.error) {
        history.push('/keys?stat=2');
        setIsOpen(false);
        handleOpen(false);

        ToastSuccess('Rejected Successfully!');
      } else {
        ToastError('Reject Error!');
      }
    }
  }, [actions, data]);

  return (
    <KeyRequestDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default KeyRequestDelete;
