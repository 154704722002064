import React, { useCallback, useEffect, useState } from 'react';
import { ATMSegment, ATMGrid, ATMButton, ATMLoader } from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { IDailyReport } from 'src/models/daily-reports.model';
import { getLerDetailsForm } from 'src/selectors/daily-reports.selector';
import html2pdf from 'html2pdf.js';
import Moment from 'src/libraries/moment.library';
import DailyReportsViewDetail from './daily-reports-view-detail/daily-report-view-detail.component';

type IProps = {
  data: IDailyReport[];
  setDailyReportsLERs: React.Dispatch<React.SetStateAction<IDailyReport[]>>;
  date: Date;
};

const printPDF = (elements, pdfOptions) => {
  let worker = html2pdf().set(pdfOptions).from(elements[0]);

  if (elements.length > 1) {
    worker = worker.toPdf(); // worker is now a jsPDF instance

    // add each element/page individually to the PDF render process
    elements.slice(1).forEach((element) => {
      worker = worker
        .get('pdf')
        .then((pdf) => {
          pdf.addPage();
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf();
    });
  }

  worker = worker
    .outputPdf()
    .get('pdf')
    .then((pdfObj) => {
      pdfObj.autoPrint();
      window.open(pdfObj.output('bloburl'), '_blank');
    });
};

const DailyReportsViewDetails: React.FC<IProps> = ({
  data,
  setDailyReportsLERs,
  date,
}) => {
  const { state, actions } = useLerRequestContext();
  const [printLoader, setPrintLoader] = useState(false);
  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_GRP_ID_LIST_READ
  ).fetching;

  useEffect(() => {
    actions.setGrpIdList();
    if (data) {
      const grpIds = Array.from(
        new Set(data.filter((v) => v.grpId !== null)?.map((v) => v.grpId))
      );
      if (grpIds && grpIds.length > 0) {
        // if (grpIds) {
        // actions.grpIdListGET({
        //   filters: [
        //     {
        //       name: 'grpIds',
        //       value: grpIds,
        //     },
        //   ],
        // });
        actions.relatedOutagesListGET((grpIds as number[]) ?? []);
      }
    }
  }, [data, actions]);

  const handlePrint = useCallback(async () => {
    if (data) {
      setPrintLoader(true);
      const form = getLerDetailsForm(data, date, state.relatedOutagesList);
      const lerDetailsForm = new DOMParser().parseFromString(form, 'text/html');
      const element = Array.from(
        lerDetailsForm.querySelectorAll('div[id^="page"]')
      );
      const options = {
        margin: 0.5,
        filename: `MCC_Ler_Details_${Moment().format('YYYYMMDD')}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all' },
      };
      await printPDF(element, options);
      setTimeout(() => setPrintLoader(false), data.length * 1000);
    }
  }, [data, state, setPrintLoader]);

  if (loading) {
    return <ATMLoader active inline="centered" />;
  }

  return (
    <div className="admin-container">
      <ATMSegment className="admin-list-container">
        <ATMGrid columns={1}>
          <ATMGrid.Column textAlign="right">
            <ATMButton
              content={Lang.LBL_PRINT}
              secondary
              onClick={handlePrint}
              loading={printLoader}
            />
            <ATMButton
              basic
              icon="close"
              style={{ width: '34px', height: '34px' }}
              onClick={() => {
                setDailyReportsLERs([]);
              }}
            />
          </ATMGrid.Column>
        </ATMGrid>
        {data.map((ler, i) => (
          <DailyReportsViewDetail key={i} data={ler} />
        ))}
      </ATMSegment>
    </div>
  );
};

export default DailyReportsViewDetails;
