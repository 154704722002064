import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/entry-reason.service';
import { IEntryReason } from 'src/models/entry-reason.model';

export const entryReasonActionTypes = {
  ENTRY_REASON_DATA_READ: 'ENTRY_REASON_DATA_READ',
  ENTRY_REASON_LIST_READ: 'ENTRY_REASON_LIST_READ',
  ENTRY_REASON_DATA_CREATE: 'ENTRY_REASON_DATA_CREATE',
  ENTRY_REASON_DATA_UPDATE: 'ENTRY_REASON_DATA_UPDATE',
  ENTRY_REASON_DATA_DELETE: 'ENTRY_REASON_DATA_DELETE',
  ENTRY_REASON_DATA_SET: 'ENTRY_REASON_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: entryReasonActionTypes.ENTRY_REASON_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: entryReasonActionTypes.ENTRY_REASON_LIST_READ,
    service: services.listGET,
  },

  // createPOST: {
  //   type: entryReasonActionTypes.ENTRY_REASON_DATA_CREATE,
  //   service: services.createPOST,
  //   meta: {
  //     error: false, // Overrides default error handler if you want to have custom error message
  //   },
  // },

  // updatePUT: {
  //   type: entryReasonActionTypes.ENTRY_REASON_DATA_UPDATE,
  //   service: services.updatePUT,
  // },

  // dataDELETE: {
  //   type: entryReasonActionTypes.ENTRY_REASON_DATA_DELETE,
  //   service: services.dataDELETE,
  // },

  // This is a sync action
  setData: (entryReason: IEntryReason) => ({
    type: entryReasonActionTypes.ENTRY_REASON_DATA_SET,
    payload: {
      entryReason,
    },
  }),
};

export type IEntryReasonAsync = typeof duckActions;

export interface IEntryReasonState
  extends ICommonState<typeof entryReasonActionTypes> {
  data?: IEntryReason;
  list: IEntryReason[];
  total: number;
}

export const defaultState: IEntryReasonState = {
  status: {},
  list: [],
  total: 0,
};

const EntryReasonReducer = (
  state: IEntryReasonState,
  action: IReducerAction<IEntryReasonAsync>
): IEntryReasonState => {
  switch (action.type) {
    // case entryReasonActionTypes.ENTRY_REASON_DATA_UPDATE:
    // case entryReasonActionTypes.ENTRY_REASON_DATA_CREATE: {
    case entryReasonActionTypes.ENTRY_REASON_DATA_SET:
    case entryReasonActionTypes.ENTRY_REASON_DATA_READ: {
      return {
        ...state,
        data: action.payload?.entryReason,
      };
    }

    case entryReasonActionTypes.ENTRY_REASON_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    // case entryReasonActionTypes.ENTRY_REASON_DATA_DELETE: {
    //   if (action.params) {
    //     const [id] = action.params;
    //     const list = state.list.filter((value) => value.id !== id);

    //     return {
    //       ...state,
    //       data: undefined,
    //       total: state.total - (state.list.length - list.length),
    //       list,
    //     };
    //   }
    //   return state;
    // }

    default: {
      return state;
    }
  }
};

export default EntryReasonReducer;
