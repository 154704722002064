import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ISickList } from 'src/models/sick-list.model';
import style from '../sick-list.module.scss';

type IProp = {
  data: ISickList;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const SickListDelete: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMDropdown.Item
        icon="trash alternate outline"
        onClick={() => handleOpen(true)}
        content={Lang.LBL_DELETE}
        compact="true"
        className={style.manageDropdown}
      />
    }
  >
    <ATMModal.Header>Delete Sick Entry</ATMModal.Header>
    <ATMModal.Content>
      <p>
        {'Are you sure you want to delete the entry for '}
        <strong>
          {data.empSick.emp?.lastName
            ? `${data.empSick.emp?.lastName}, ${data.empSick.emp?.firstName}`
            : data.empId}
        </strong>
        ?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton
        secondary
        content={Lang.LBL_CANCEL}
        onClick={() => handleOpen(false)}
      />
      <ATMButton
        negative
        content={Lang.LBL_CONFIRM}
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default SickListDelete;
