import faker from 'faker';
import { KeyHolderType } from 'src/constants';
import { IIssuedKey } from '../issued-key.model';
import {
  IEmployeeShapeKeyReq,
  IKeyDetail,
  IKeyRequest,
  IKeyRequestDetail,
  IKeyRequestEmployeePayload,
  IKeyRequestForm,
  IKeyRequestMin,
} from '../key-request.model';

export const KeyDetailMock = (data: Partial<IKeyDetail> = {}): IKeyDetail => ({
  keyNbr: faker.datatype.number(),
  empId: faker.datatype.string(),
  issDt: faker.date.recent(),
  contrShortNm: faker.datatype.string(),
  stat: faker.datatype.string(),
  returnDt: faker.date.recent(),
  sdgeContactId: faker.datatype.string(),
  updatedAt: faker.date.recent(),
  updatedBy: faker.datatype.string(),
  reason: faker.lorem.paragraphs(4),
  recipContrId: faker.datatype.string(),
  ...data,
});

export const EmployeeMock = (
  data: Partial<IEmployeeShapeKeyReq> = {}
): IEmployeeShapeKeyReq => ({
  empId: faker.datatype.string(),
  lastName: faker.name.lastName(),
  firstName: faker.name.firstName(),
  middleName: faker.name.middleName(),
  email: faker.internet.email(faker.name.firstName(), faker.name.lastName()),
  title: faker.helpers.randomize([
    KeyHolderType.EMPLOYEE,
    KeyHolderType.CONTRACTOR,
  ]),
  ...data,
});

export const KeyRequestDetailMock = (
  data: Partial<IKeyRequestDetail> = {}
): IKeyRequestDetail => ({
  keyNbr: faker.datatype.number(),
  keyTypId: faker.datatype.string(),
  supvrNbr: faker.datatype.string(),
  noOfKeys: faker.datatype.number(),
  approvalEmail: faker.datatype.boolean(),
  keyAdminMailLoc: faker.internet.email(),
  requestNbr: faker.datatype.number(),
  replId: faker.datatype.number(),
  changeDt: faker.date.recent(),
  updatedAt: faker.date.recent(),
  updatedBy: faker.datatype.string(),
  keyDetail: [KeyDetailMock()],
  supvr: EmployeeMock(),
  ...data,
});

export const keyRequestMock = (
  data: Partial<IIssuedKey> = {}
): IKeyRequest => ({
  requestNbr: faker.datatype.number(),
  requestorTyp: faker.datatype.string(),
  requestTyp: faker.datatype.string(),
  requestedBy: faker.datatype.string(),
  requestDt: faker.date.recent(),
  empId: faker.datatype.string(),
  stat: faker.datatype.string(),
  changeDt: faker.date.recent(),
  updatedAt: faker.date.recent(),
  updatedBy: faker.datatype.string(),
  keyRequestDetail: KeyRequestDetailMock(),
  emp: {
    ...EmployeeMock(),
    jobCd: faker.datatype.string(),
  },
  requestor: EmployeeMock(),
  ...data,
});

export const keyRequestMinMock = (
  data: Partial<IKeyRequestMin> = {}
): IKeyRequestMin => ({
  requestNbr: faker.datatype.number(),
  requestorTyp: faker.datatype.string(),
  requestTyp: faker.datatype.string(),
  requestedBy: faker.datatype.string(),
  requestDt: faker.date.recent(),
  empId: faker.datatype.string(),
  stat: faker.datatype.string(),
  changeDt: faker.date.recent(),
  updatedAt: faker.date.recent(),
  updatedBy: faker.datatype.string(),
  ...data,
});

export const keyRequestFormMock = (
  data: Partial<IKeyRequestForm> = {}
): IKeyRequestForm => ({
  ...keyRequestMock(),
  requestNbr: faker.datatype.number(),
  requestDt: faker.date.past(),
  updatedAt: faker.date.past(),
  updatedBy: faker.datatype.string(),
  stat: faker.datatype.string(),
  keyRequestDetail: {
    keyNbr: faker.datatype.number(),
    keyTypId: faker.datatype.string(),
    noOfKeys: faker.datatype.number(),
    approvalEmail: faker.datatype.boolean(),
    supvrNbr: faker.datatype.string(),
    keyAdminMailLoc: faker.datatype.string(),
    keyDetail: KeyDetailMock(),
  },
  emp: {
    jobCd: faker.datatype.string(),
  },
  ...data,
});

export const keyRequestEmployeeMock = (
  data: Partial<IKeyRequestMin> = {}
): IKeyRequestEmployeePayload => ({
  keyRequest: keyRequestMinMock(data),
  infoMessage: faker.datatype.string(),
});
