import { ICalendarResource } from 'src/components/organisms/calendar/calendar-timeline/calendar-timeline.component';
import { SortByOptions } from 'src/components/pages/outage/outage-calendar/outage-calendar-filters/outage-calendar-filters.component';
import { OrderBy, getOrderByValue } from 'src/components/pages/outage/outage-calendar/outage-calendar-filters/outage-calendar-sort-radio-options.component';
import { orderBy } from 'lodash';
import Lang from 'src/libraries/language';
import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import {
  IOutageDateCalendar,
  IOutageDateFilter,
} from 'src/models/calendar-outage.model';
import services from 'src/services/calendar-outage.service';

export const calendarOutageActionTypes = {
  CALENDAR_OUTAGE_LIST_READ: 'CALENDAR_OUTAGE_LIST_READ',
  CALENDAR_OUTAGE_FILTER_STATE: 'CALENDAR_OUTAGE_FILTER_STATE',
  CALENDAR_OUTAGE_FILTER_READ: 'CALENDAR_OUTAGE_FILTER_READ',
  CALENDAR_OUTAGE_SORT: 'CALENDAR_OUTAGE_SORT',
} as const;

export const duckActions = {
  listGET: {
    type: calendarOutageActionTypes.CALENDAR_OUTAGE_LIST_READ,
    service: services.listGET,
  },
  sortOutageCalendarList: (data: IOutageDateFilter) => ({
    type: calendarOutageActionTypes.CALENDAR_OUTAGE_SORT,
    payload: data,
  }),
};

export type ICalendarOutageAsync = typeof duckActions;

export interface ICalendarOutageState
  extends ICommonState<typeof calendarOutageActionTypes> {
  list: any;
  events: IOutageDateCalendar[];
  resources: ICalendarResource[];
}

export const defaultState: ICalendarOutageState = {
  status: {},
  list: {},
  events: [],
  resources: [],
};

const CalendarOutageReducer = (
  state: ICalendarOutageState,
  action: IReducerAction<ICalendarOutageAsync>
): ICalendarOutageState => {
  switch (action.type) {
    case calendarOutageActionTypes.CALENDAR_OUTAGE_LIST_READ: {
      const list = action.payload;

      if (list) {
        const events: IOutageDateCalendar[] = [];

        const transformedArray = Object.values(list)
          .flat()
          .map((elem: any) => {
            return {
              ...elem,
              outageDates: [],
            };
          });

        if (transformedArray.length > 0) {
          transformedArray.forEach((value) => {
            const index = events.findIndex(
              (elem) =>
                elem.requestFacility.outgFacId ===
                  value?.requestFacility.outgFacId &&
                elem.requestFacility.requestId ===
                  value?.requestFacility.requestId
            );
            if (index < 0) {
              events.push({
                ...value,
                outageDates: [value],
              } as IOutageDateCalendar);
            } else {
              events[index]?.outageDates?.push(value);
            }
          });
        }

        const resources: ICalendarResource[] = Object.keys(list)
          .reverse()
          .map((val) => {
            const items = events.filter(
              (v: any) =>
                v?.requestFacility.facility.volt?.voltNm === val.substring(1) ||
                (val.substring(1) === 'undefined' &&
                  v?.requestFacility.facility.outgFacNm === 'Hotwashes')
            );

            const children = items.map((v: any) => {
              const substationName =
                v?.requestFacility.facility.facTypId !==
                  Lang.FACILITY_TYPE_ID_BACKEND.LINE &&
                v?.requestFacility.substationId !== null
                  ? (
                      (v?.requestFacility.facility.substations as []).filter(
                        (s: any) =>
                          s?.substationId === v.requestFacility.substationId
                      )[0] as any
                    )?.name
                  : null;
              return {
                resourceId: v?.requestFacility.outgFacId,
                title:
                  (substationName
                    ? `${v?.requestFacility.facility.outgFacNm} (${substationName})`
                    : v?.requestFacility.facility.outgFacNm) ?? '',
                specialConcerns:
                  v?.requestFacility.facility.spclOpsConcern ?? '',
                significantCost: v?.significantCostInd ?? '',
                besInd: v?.requestFacility.facility.besInd ?? '',
              };
            });

            const uniqueChildren = children.filter((obj, index) => {
              return (
                index ===
                children.findIndex((o) => obj.resourceId === o.resourceId)
              );
            });

            return {
              resourceId:
                val.substring(1) === 'undefined' ? 'None' : val.substring(1),
              title:
                val.substring(1) === 'undefined' ? 'None' : val.substring(1),
              // eslint-disable-next-line radix
              voltage: val.substring(1) === 'undefined' ? undefined : parseInt(val.substring(1)),
              children: uniqueChildren,
            };
          });

        // BUmali #662493 Sort Facility alphabetically
        resources.map((item) => {
          item.children?.sort((a, b) => {
            const aTitle = a.title ?? 'ZZZZZ';
            const bTitle = b.title ?? 'ZZZZZ';
            return aTitle > bTitle ? 1 : -1;
          });
        });

        return {
          ...state,
          events,
          resources,
        };
      }

      return state;
    }

    case calendarOutageActionTypes.CALENDAR_OUTAGE_SORT: {
      const payload = action.payload as IOutageDateFilter;
      let newResources: any = {
        resources: state.resources,
      };
      const by = payload.sortListBy?.sortBy?.by === SortByOptions.Voltage ? 'voltage' : 'children.title';
      const thenBy = payload.sortListBy?.thenBy?.by === SortByOptions.Voltage ? 'voltage' : 'children.title';
      const sortResources = () => {
        if (payload?.sortListBy) {
          if (payload.sortListBy?.sortBy?.by && payload.sortListBy?.sortBy?.orderBy) {
            newResources = {
              resources: orderBy(state.resources, [by, thenBy], [payload.sortListBy?.sortBy?.orderBy as any, payload.sortListBy?.thenBy?.orderBy]),
            };
          }
        }
      };
      sortResources();
      const order = getOrderByValue(payload) ?? '';
      if (by !== 'voltage' || thenBy !== 'voltage') {
      newResources.resources.map((item) => {
        item.children?.sort((a, b) => {
          const aTitle = a.title ?? 'ZZZZZ';
          const bTitle = b.title ?? 'ZZZZZ';
          if (order === OrderBy.ASC) {
          return aTitle > bTitle ? 1 : -1;
          }
          return aTitle < bTitle ? 1 : -1;
        });
      });
    }
      return {
        ...state,
        resources: newResources.resources,
      };
    }

    default: {
      return state;
    }
  }
};

export default CalendarOutageReducer;
