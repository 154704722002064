/* eslint-disable object-shorthand */
import React, { useEffect } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import { getRolesStatus } from 'src/selectors/roles.selector';
import {
  EmployeeRoleFilterSchema,
  IEmployeeRoleFilter,
  IRolesEmployee,
} from 'src/models/roles.model';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import Lang from 'src/libraries/language';
import { useRolesContext } from 'src/contexts/roles.context';
import {
  RoleEmpHRStatus,
  RoleEmpRoleStatus,
} from 'src/constants/roles.constant';
import style from './roles-filters.module.scss';

type IProps = {
  data?: IRolesEmployee;
  handleSubmit: (data: IEmployeeRoleFilter) => void;
  handleShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

type IFormContent = {
  handleSubmit: (data: IEmployeeRoleFilter) => void;
  handleShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormContent: React.FC<IFormContent> = ({
  handleSubmit,
  handleShowSearch,
}) => {
  const {
    state: { adminList, ...state },
    actions,
  } = useRolesContext();
  const {
    control,
    formState: { errors, isDirty },
    reset,
    clearErrors,
    getValues,
  } = useATMFormContext<IEmployeeRoleFilter>();
  const rolesTypesLoading = getRolesStatus(
    { adminList, ...state },
    rolesActionTypes.ROLES_OPTIONS_LIST_READ
  ).fetching;

  useEffect(() => {
    actions?.findAllOptionRolesGET();
  }, [actions]);

  return (
    <ATMGrid columns={4}>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMField
            as={DropdownEmployee}
            name="empId"
            selectedValue={getValues('empId')}
            label="Employee Name/ID"
            placeholder="Enter"
            control={control}
            error={errors.empId}
            resourceStatus="All"
            onChange={([_, { value }]) => {
              return value || null;
            }}
            clearable
            search
            selectOnBlur={false}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            as={ATMDropdown}
            selection
            name="roleId"
            placeholder="Select"
            label={Lang.LBL_ROLES_FILTER}
            control={control}
            loading={!state.roles.length && rolesTypesLoading}
            style={{ display: 'block', marginRight: '1em' }}
            options={state.roles.map((value) => ({
              key: value.roleId,
              value: value.roleId,
              text: `${value.roleName} (${value.contentAccess})`,
            }))}
            onChange={([_, { value }]) => value || null}
            clearable
            selectOnBlur={false}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            as={ATMDropdown}
            selection
            name="employeeStat"
            placeholder="Select"
            label={Lang.LBL_ROLE_HR_STATUS}
            control={control}
            style={{ display: 'block', marginRight: '1em' }}
            options={Object.entries(RoleEmpHRStatus).map(([key, value]) => ({
              key,
              value,
              text: key,
            }))}
            onChange={([_, { value }]) => value || null}
            clearable
            selectOnBlur={false}
          />
        </ATMGrid.Column>
        <ATMGrid.Column class="hidden">
          <ATMField
            as={ATMDropdown}
            selection
            name="roleStatus"
            placeholder="Select"
            label={Lang.LBL_ROLE_STATUS}
            control={control}
            style={{ display: 'block', marginRight: '1em' }}
            options={Object.entries(RoleEmpRoleStatus).map(([key, value]) => ({
              key,
              value,
              text: key,
            }))}
            onChange={([_, { value }]) => value || null}
            clearable
            selectOnBlur={false}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row style={{ paddingTop: '0' }}>
        <ATMGrid.Column>
          <ATMButton
            primary
            content="Search"
            onClick={() => handleSubmit(getValues())}
            disabled={!isDirty}
          />
          <ATMButton
            secondary
            content="Clear"
            disabled={!isDirty}
            onClick={() => {
              reset({
                empId: '',
                roleId: null,
                roleStatus: null,
                employeeStat: null,
              });
              setImmediate(() => {
                clearErrors();
                handleShowSearch(false);
                actions.clearList();
              });
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const RolesFilters: React.FC<IProps> = ({ handleSubmit, handleShowSearch }) => {
  return (
    <div className={style.filterContainer}>
      <ATMForm mode="onChange" validationSchema={EmployeeRoleFilterSchema}>
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <FormContent
                handleSubmit={handleSubmit}
                handleShowSearch={handleShowSearch}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default RolesFilters;
