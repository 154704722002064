import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import TsoOssEmployeeReducer, {
  ITsoOssEmployeeState,
  defaultState,
  duckActions,
} from 'src/ducks/tso-oss-employee.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    TsoOssEmployeeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ITsoOssEmployeeContext = ReturnType<typeof useReducer>;

const TsoOssEmployeeContext = createContext<Partial<ITsoOssEmployeeContext>>({
  state: defaultState,
}) as React.Context<ITsoOssEmployeeContext>;

type IProps = {
  state?: Partial<ITsoOssEmployeeState>;
};

const TsoOssEmployeeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <TsoOssEmployeeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </TsoOssEmployeeContext.Provider>
  );
};

const useTsoOssEmployeeContext = () => useContext(TsoOssEmployeeContext);

export type IUseTsoOssEmployeeContext = ReturnType<
  typeof useTsoOssEmployeeContext
>;

export {
  TsoOssEmployeeContext,
  TsoOssEmployeeProvider,
  useTsoOssEmployeeContext,
};
