export enum Op {
  eq = 'eq',
  ne = 'ne',
  gte = 'gte',
  gt = 'gt',
  lte = 'lte',
  lt = 'lt',
  not = 'not',
  is = 'is',
  in = 'in',
  notIn = 'notIn',
  like = 'like',
  notLike = 'notLike',
  iLike = 'iLike',
  notILike = 'notILike',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  substring = 'substring',
  regexp = 'regexp',
  notRegexp = 'notRegexp',
  iRegexp = 'iRegexp',
  notIRegexp = 'notIRegexp',
  between = 'between',
  notBetween = 'notBetween',
  overlap = 'overlap',
  contains = 'contains',
  contained = 'contained',
  adjacent = 'adjacent',
  strictLeft = 'strictLeft',
  strictRight = 'strictRight',
  noExtendRight = 'noExtendRight',
  noExtendLeft = 'noExtendLeft',
}
