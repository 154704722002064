import React, { useEffect } from 'react';
import {
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSelect,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import SpecialConcern from 'src/components/molecules/special-concern/special-concern.component';
import { useIsoCauseContext } from 'src/contexts/iso-cause.context';
import { useIsoWorkKindContext } from 'src/contexts/iso-work-kind.context';
import { isoCauseActionTypes } from 'src/ducks/iso-cause.duck';
import { isoWorkKindActionTypes } from 'src/ducks/iso-work-kind.duck';
import Lang from 'src/libraries/language';
import { ILerRequest, ILerRequestForm } from 'src/models/ler-request.model';
import { getIsoCauseStatus } from 'src/selectors/iso-cause.selector';
import { getIsoWorkKindStatus } from 'src/selectors/iso-work-kind.selector';
import { hasRole } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants';
import ISOEMSATable from '../iso-tables/oc-ler-request-iso-ems-table.component';
import ISOEquipmentTable from '../iso-tables/oc-ler-request-iso-equipment-table.component';
import ISOSwitchTable from '../iso-tables/oc-ler-request-iso-switch-table.component';
import LERRequestSendToCaiso, {
  isCaisoPending,
} from '../ler-request-iso-send-to-caiso/ler-request-iso-send-to-caiso.component';
import styles from './ler-request-iso-form.module.scss';

type IProps = {
  data?: ILerRequest;
};

const LERRequestISO: React.FC<IProps> = ({ data }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useATMFormContext<ILerRequestForm>();

  const { state: isoWorkKindState, actions: isoWorkKindActions } =
    useIsoWorkKindContext();
  const { state: isoCauseState, actions: isoCauseActions } =
    useIsoCauseContext();

  const outgFacId = watch('outageFacility.outgFacId');

  const isoWorkKindStatus = getIsoWorkKindStatus(
    isoWorkKindState,
    isoWorkKindActionTypes.ISO_WORK_KIND_LIST_READ
  );
  const isoCauseStatus = getIsoCauseStatus(
    isoCauseState,
    isoCauseActionTypes.ISO_CAUSE_LIST_READ
  );

  useEffect(() => {
    isoWorkKindActions.listGET();
    isoCauseActions.listGET();
  }, [isoWorkKindActions, isoCauseActions]);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <SpecialConcern outgFacId={outgFacId} />
      <ATMGrid>
        <ATMGrid.Row columns={16}>
          <ATMGrid.Column width={13}>
            <ATMHeader as="h3"> {Lang.TTL_OMS_INFORMATION}</ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column width={3}>
            {isCaisoPending(data?.pendingCaiso) &&
            hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
              <LERRequestSendToCaiso
                requestStat={data?.requestStat}
                data={data as ILerRequest}
              />
            ) : (
              ''
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <ATMGrid>
              <ATMGrid.Row>
                <ATMGrid.Column width={5}>
                  <ATMField
                    label={
                      <LabelRequired>
                        {Lang.LBL_OC_LER_ISO_NATURE_WORK}
                      </LabelRequired>
                    }
                    as={ATMSelect}
                    fluid
                    name="isoTrs.isoWorkKindId"
                    control={control}
                    placeholder={Lang.LBL_SELECT}
                    error={errors.isoTrs?.isoWorkKindId}
                    onChange={([_, { value }]) => value}
                    options={isoWorkKindState.list.map((value) => ({
                      key: value.isoWorkKindId,
                      value: value.isoWorkKindId,
                      text: value.description
                        .split('_')
                        .map(
                          (string) =>
                            string.charAt(0) + string.slice(1).toLowerCase()
                        )
                        .join(' '),
                    }))}
                    loading={
                      !isoWorkKindState.list.length &&
                      isoWorkKindStatus.fetching
                    }
                    disabled={
                      !isoWorkKindState.list.length &&
                      isoWorkKindStatus.fetching
                    }
                    search
                    selectOnBlur={false}
                    clearable
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column width={5}>
                  <ATMField
                    fluid
                    // label={
                    //   watch('isoTrs.secondIsoCauseCode') ? (
                    //     <LabelRequired>
                    //       {Lang.LBL_OC_LER_ISO_PRIMARY_ISO_CAUSE_CODE}
                    //     </LabelRequired>
                    //   ) : (
                    //     Lang.LBL_OC_LER_ISO_PRIMARY_ISO_CAUSE_CODE
                    //   )
                    // }
                    label={Lang.LBL_OC_LER_ISO_PRIMARY_ISO_CAUSE_CODE}
                    name="isoTrs.primIsoCauseCode"
                    control={control}
                    as={ATMSelect}
                    placeholder={Lang.LBL_SELECT}
                    error={errors.isoTrs?.primIsoCauseCode}
                    onChange={([_, { value }]) => value}
                    options={isoCauseState.primaryList.map((value) => ({
                      key: value.isoCauseCode,
                      value: value.isoCauseCode,
                      text: value.description,
                    }))}
                    loading={
                      !isoCauseState.primaryList.length &&
                      isoCauseStatus.fetching
                    }
                    disabled={
                      !isoCauseState.primaryList.length &&
                      isoCauseStatus.fetching
                    }
                    clearable
                    search
                    selectOnBlur={false}
                  />
                  <div className={styles.underInputLabel}>
                    <ATMIcon name="attention" />
                    <span>
                      <small>{Lang.LBL_OC_LER_ISO_REQUIRED_ONLY_FORCED}</small>
                    </span>
                  </div>
                </ATMGrid.Column>
                <ATMGrid.Column width={5}>
                  <ATMField
                    fluid
                    // label={
                    //   watch('isoTrs.primIsoCauseCode') ? (
                    //     <LabelRequired>
                    //       {Lang.LBL_OC_LER_ISO_SECONDARY_ISO_CAUSE_CODE}
                    //     </LabelRequired>
                    //   ) : (
                    //     Lang.LBL_OC_LER_ISO_SECONDARY_ISO_CAUSE_CODE
                    //   )
                    // }
                    label={Lang.LBL_OC_LER_ISO_SECONDARY_ISO_CAUSE_CODE}
                    name="isoTrs.secondIsoCauseCode"
                    control={control}
                    as={ATMSelect}
                    placeholder={Lang.LBL_SELECT}
                    error={errors.isoTrs?.secondIsoCauseCode}
                    onChange={([_, { value }]) => value}
                    options={isoCauseState.secondaryList.map((value) => ({
                      key: value.isoCauseCode,
                      value: value.isoCauseCode,
                      text: value.description,
                    }))}
                    loading={
                      !isoCauseState.secondaryList.length &&
                      isoCauseStatus.fetching
                    }
                    disabled={
                      !isoCauseState.secondaryList.length &&
                      isoCauseStatus.fetching
                    }
                    clearable
                    search
                    selectOnBlur={false}
                  />
                  <div className={styles.underInputLabel}>
                    <ATMIcon name="attention" />
                    <span>
                      <small>{Lang.LBL_OC_LER_ISO_REQUIRED_ONLY_FORCED}</small>
                    </span>
                  </div>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={styles.noMarginRow}>
          <ATMGrid.Column>
            <ATMHeader as="h3" className={styles.subHeader}>
              {Lang.TTL_CAISO_TR_IDS}
            </ATMHeader>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="1">
          <ATMGrid.Column>
            <ISOEquipmentTable />
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={styles.noMarginRow}>
          <ATMGrid.Column>
            <ATMHeader as="h3" className={styles.subHeader}>
              {Lang.TTL_SWITCHING_LER}
            </ATMHeader>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="1">
          <ATMGrid.Column>
            <ISOSwitchTable />
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={styles.noMarginRow}>
          <ATMGrid.Column>
            <ATMHeader as="h3" className={styles.subHeader}>
              {Lang.TTL_EMS_IDS}
            </ATMHeader>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="1">
          <ATMGrid.Column>
            <ISOEMSATable />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </div>
  );
};

export default LERRequestISO;
