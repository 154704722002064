import { httpClient } from 'src/libraries/http.library';
import {
  ISubstationForm,
  SubstationListPayloadSchema,
  SubstationDeletePayloadSchema,
  SubstationPayloadSchema,
} from 'src/models/substation.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

const client = httpClient();
const endpoint = 'substation';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, SubstationPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, SubstationListPayloadSchema);
  },

  listSubstationCreateGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(
      `/${endpoint}?action=addedit`,
      params,
      SubstationListPayloadSchema
    );
  },

  createPOST: async (data: ISubstationForm) => {
    return client.post(`/${endpoint}`, data, SubstationPayloadSchema);
  },

  updatePUT: async (id: string, data: ISubstationForm) => {
    return client.put(`/${endpoint}/${id}`, data, SubstationPayloadSchema);
  },

  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, SubstationDeletePayloadSchema);
  },
};

export default services;
