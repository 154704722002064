import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/job-code-rule.service';
import {
  IJobCodeRule,
  IJobCodeRuleForm,
  IJobTitles,
} from 'src/models/job-code-rule.model';
import { getParsedJob } from 'src/selectors/job-code-rule.selector';

export const jobCodeRuleActionTypes = {
  JOB_CODE_RULE_DATA_READ: 'JOB_CODE_RULE_DATA_READ',
  JOB_CODE_RULE_LIST_READ: 'JOB_CODE_RULE_LIST_READ',
  JOB_CODE_RULE_LIST_ALL_READ: 'JOB_CODE_RULE_LIST_ALL_READ',
  JOB_CODE_RULE_DATA_CREATE: 'JOB_CODE_RULE_DATA_CREATE',
  JOB_CODE_RULE_DATA_UPDATE: 'JOB_CODE_RULE_DATA_UPDATE',
  JOB_CODE_RULE_DATA_DELETE: 'JOB_CODE_RULE_DATA_DELETE',
  JOB_CODE_RULE_DATA_SET: 'JOB_CODE_RULE_DATA_SET',
  JOB_TITLES_SEARCH_FETCH: 'JOB_TITLES_SEARCH_FETCH',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_LIST_READ,
    service: services.listGET,
  },

  listAllGET: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_LIST_ALL_READ,
    service: services.listGET,
  },

  searchGET: {
    type: jobCodeRuleActionTypes.JOB_TITLES_SEARCH_FETCH,
    service: services.searchGET,
  },

  createPOST: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (jobCodeRule: IJobCodeRule) => ({
    type: jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_SET,
    payload: jobCodeRule,
  }),
};

export type IJobCodeRuleAsync = typeof duckActions;

export interface IJobCodeRuleState
  extends ICommonState<typeof jobCodeRuleActionTypes> {
  data?: IJobCodeRuleForm;
  list: IJobCodeRule[];
  searchList: IJobTitles[];
  total: number;
}

export const defaultState: IJobCodeRuleState = {
  status: {},
  list: [],
  searchList: [],
  total: 0,
};

const JobCodeRuleReducer = (
  state: IJobCodeRuleState,
  action: IReducerAction<IJobCodeRuleAsync>
): IJobCodeRuleState => {
  switch (action.type) {
    case jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_SET:
    case jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_READ:
    case jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_UPDATE:
    case jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case jobCodeRuleActionTypes.JOB_TITLES_SEARCH_FETCH: {
      return {
        ...state,
        searchList: (action.payload?.data ?? []).map((job) =>
          getParsedJob(job)
        ),
      };
    }

    case jobCodeRuleActionTypes.JOB_CODE_RULE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case jobCodeRuleActionTypes.JOB_CODE_RULE_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.jobCd !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default JobCodeRuleReducer;
