import React, { useCallback, useRef, useState } from 'react';
import { ATMButton, ATMModal, useATMFormContext } from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import {
  IFacilityForm,
  IFacilityFormValidation,
  IUserGrpForm,
} from 'src/models/facility.model';
import { IUserGroup } from 'src/models/user-group.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import FacilityNotifApprovalsItemForm from '../facility-notif-approvals-item-form/facility-notif-approvals-item-form.component';
import styles from '../../facility-notification-items.module.scss';

type IProps = {
  addMore?: boolean;
  userGroup: IUserGroup[];
};

const FacilityNotifApprovalsItemAdd: React.FC<IProps> = ({
  addMore = false,
  userGroup,
}) => {
  const { control, getValues } = useATMFormContext<IFacilityFormValidation>();
  const { state, actions } = useFacilityContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const addStatus = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_UPDATE
  ).fetching;

  const handleSave = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IUserGrpForm) => {
      const values = getValues();
      const newFormData = {
        ...values,
        userGrps: [...(values.userGrps ?? []), formData],
      };
      const result = await actions.updatePUT(
        newFormData.outgFacId ?? 0,
        newFormData as IFacilityForm
      );
      if (result.payload) {
        ToastSuccess(
          Lang.formatString(Lang.MSG_USER_GROUP_ADD, formData.userGrpNm)
        );
        actions.dataGET(newFormData.outgFacId ?? 0);
      } else {
        ToastError(result.error.message);
      }
      setIsOpen(!isOpen);
    },
    [actions, isOpen, control, setIsOpen, getValues]
  );

  const triggerBtn = addMore ? (
    <ATMButton
      secondary
      icon="plus"
      type="button"
      className="borderless"
      content={Lang.LBL_ADD_MORE}
      onClick={() => setIsOpen(!isOpen)}
    />
  ) : (
    <ATMButton
      secondary
      icon="plus"
      type="button"
      content={Lang.LBL_ADD}
      onClick={() => setIsOpen(!isOpen)}
    />
  );
  const actionBtns = (
    <div className={styles.actionBtns}>
      <ATMButton
        secondary
        type="button"
        content={Lang.LBL_CANCEL}
        onClick={() => setIsOpen(!isOpen)}
      />
      <ATMButton
        primary
        type="button"
        content={Lang.LBL_SAVE}
        loading={addStatus}
        disabled={!isDirty || addStatus}
        onClick={handleSave}
      />
    </div>
  );
  return (
    <ATMModal
      size="small"
      open={isOpen}
      trigger={
        getValues().outgFacId === undefined ? (
          <span>{Lang.MSG_GROUP_NOT_ALLOWED}</span>
        ) : (
          triggerBtn
        )
      }
      header={Lang.LBL_ADD_GROUP}
      content={
        <div className={styles.content}>
          <FacilityNotifApprovalsItemForm
            formRef={formRef}
            userGroup={userGroup}
            handleEnable={setIsDirty}
            handleSubmit={handleSubmit}
          />
        </div>
      }
      actions={actionBtns}
    />
  );
};

export default FacilityNotifApprovalsItemAdd;
