import {
  tnaReportActionTypes,
  ITnaReportState,
} from 'src/ducks/tna-report.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getTnaReportStatus = (
  state: ITnaReportState,
  action: keyof typeof tnaReportActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
