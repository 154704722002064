import React, { useCallback, useRef, useState } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  IShortDescriptionCategoryForm,
  IShortDescriptionCategoryList,
} from 'src/models/short-description.model';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { getShortDescriptionStatus } from 'src/selectors/short-description.selector';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import Lang from 'src/libraries/language';
import CategoryEditView from './category-edit.view';

type IProp = {
  data: IShortDescriptionCategoryList;
};

const CategoryEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useShortDescriptionContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IShortDescriptionCategoryForm) => {
      const response = await actions.updateCategoryAndDescsPUT(formData);

      if (!response.error) {
        actions.listCategoriesAndDescGET();
        setIsEditOpen(false);

        ToastSuccess(Lang.MSG_SHORT_DESCRIPTION_CATEGORY_UPDATE_SUCCESS);
      } else {
        ToastError(response.error.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <CategoryEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
    />
  );
};

export default CategoryEdit;
