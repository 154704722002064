/* eslint-disable radix */
export const convertKeyNumberToSixDigits = (keyNumber: number) => {
  const keyNumberStr = keyNumber.toString();
  if (keyNumberStr.length === 5) {
    const keyNumberSixDigits = parseInt(
      `${keyNumberStr.slice(0, 2)}0${keyNumberStr.slice(2, 5)}`
    );
    return keyNumberSixDigits;
  }
  return keyNumber;
};

export const convertKeyNumberToFiveDigits = (keyNumber: number) => {
  const keyNumberStr = keyNumber.toString();
  if (keyNumberStr.length === 6) {
    const keyNumberFiveDigits = parseInt(
      `${keyNumberStr.slice(0, 2)}${keyNumberStr.slice(3, 6)}`
    );
    return keyNumberFiveDigits;
  }
  return keyNumber;
};

export const checkUndefinedOrNullValue = (
  originalValue: string,
  returnValue: string
) => {
  const value = originalValue
    .replace(/,/g, '')
    .replace(/\s/g, '')
    .replace(/\//g, '');

  if (value.includes('undefined') || value.includes('null') || !value.length) {
    return returnValue;
  }
  return originalValue;
};

export const formatEmployeeName = (
  firstName: string,
  lastName: string,
  fullName: string,
  empId: string,
  isFullName: boolean,
  returnValue: string
) => {
  let employeeName = '';
  if (isFullName) {
    employeeName = `${checkUndefinedOrNullValue(`${fullName}`, returnValue)}${
      empId.length ? checkUndefinedOrNullValue(`/${empId}`, '') : ''
    }`;
  } else {
    employeeName = `${checkUndefinedOrNullValue(
      `${lastName}, ${firstName}`,
      returnValue
    )}${empId.length ? checkUndefinedOrNullValue(`/${empId}`, '') : ''}`;
  }
  return employeeName;
};
