import React from 'react';
import moment from 'moment';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMModal,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
// import { formatDateTime } from 'src/libraries/moment.library';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import style from './trouble-ticket-comments-modal.module.scss';

type IProp = {
  data: ITroubleTicketList;
  isOpen: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const TroubleTicketCommentsModal: React.FC<IProp> = ({
  data,
  isOpen,
  handleOpen,
}) => {
  const remaining = (data.comments || []).length - 2;
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMButton
          className={style.commentsBtn}
          content={`+${remaining} More`}
          onClick={() => handleOpen(data.troubleTicketId)}
        />
      }
    >
      <ATMModal.Header>
        Ticket ID: {data.troubleTicketId} {Lang.LBL_COMMENTS_AND_RESOLUTION}
      </ATMModal.Header>
      <ATMModal.Content scrolling>
        <ATMGrid>
          {/* Ticket card */}
          {data.comments
            ? data.comments.map((comment, i) => (
                // eslint-disable-next-line react/jsx-indent
                <ATMGrid.Row
                  centered
                  className={style.ticketStatusCardSpace}
                  key={i}
                >
                  <ATMGrid columns={16} className={style.ticketStatusCard}>
                    <ATMGrid.Column width={1}>
                      <ATMHeader className={style.userIcon}>
                        <ATMIcon
                          circular
                          name="user"
                          size="small"
                          color="grey"
                          style={{
                            fontSize: '0.8em',
                          }}
                        />
                      </ATMHeader>
                    </ATMGrid.Column>
                    <ATMGrid.Column width={15}>
                      <ATMGrid className={style.userName}>
                        {comment.name}
                      </ATMGrid>
                      <ATMGrid className={style.mtopNone}>
                        {/* {comment.userType} User &#8226;{' '} */}
                        {moment(comment.updatedAt).format('MM/DD/YYYY HH:mm a')}
                      </ATMGrid>
                      <ATMGrid className={style.commentTxt}>
                        {comment.noteTxt}
                      </ATMGrid>
                    </ATMGrid.Column>
                  </ATMGrid>
                </ATMGrid.Row>
              ))
            : undefined}
        </ATMGrid>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content={Lang.LBL_CLOSE}
          onClick={() => handleOpen(undefined)}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default TroubleTicketCommentsModal;
