import React, { useCallback } from 'react';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import { getShortDescriptionStatus } from 'src/selectors/short-description.selector';
import CategoryListView from './category-list.view';

const CategoryList: React.FC = () => {
  const { state, actions } = useShortDescriptionContext();
  const status = getShortDescriptionStatus(
    state,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_CATEGORY_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listCategoriesAndDescGET();
  }, [actions]);

  return (
    <CategoryListView
      data={state.listItems}
      loading={status.fetching}
      handleFetch={handleFetch}
    />
  );
};

export default CategoryList;
