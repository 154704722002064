import React from 'react';
import { ATMForm, ATMGrid, ATMField, ATMInput } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  ISubstationForm,
  SubstationFormSchema,
} from 'src/models/substation.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<ISubstationForm>;
  handleSubmit: (data: ISubstationForm) => void;
  handleEnable: (value: boolean) => void;
};

const SubstationForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={SubstationFormSchema.cast(defaultValues)}
      validationSchema={SubstationFormSchema}
    >
      {({ control, formState: { errors, isDirty } }) => {
        handleEnable(isDirty);

        return (
          <ATMGrid>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                {defaultValues ? (
                  <>
                    <FieldReadOnly label={Lang.LBL_SUBSTATION_ID}>
                      {defaultValues.substationId}
                    </FieldReadOnly>
                    <ATMField
                      type="hidden"
                      className="hidden"
                      as={ATMInput}
                      name="substationId"
                      control={control}
                      error={errors.substationId}
                    />
                  </>
                ) : (
                  <ATMField
                    label={
                      <LabelRequired>{Lang.LBL_SUBSTATION_ID}</LabelRequired>
                    }
                    as={ATMInput}
                    name="substationId"
                    control={control}
                    error={errors.substationId}
                  />
                )}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label={
                    <LabelRequired>{Lang.LBL_SUBSTATION_NAME}</LabelRequired>
                  }
                  as={ATMInput}
                  name="name"
                  control={control}
                  error={errors.name}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_SUBSTATION_LINE_ADDRESS_1}
                  as={ATMInput}
                  name="addr1"
                  control={control}
                  error={errors.addr1}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_SUBSTATION_LINE_ADDRESS_2}
                  as={ATMInput}
                  name="addr2"
                  control={control}
                  error={errors.addr2}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_CITY}
                  as={ATMInput}
                  name="city"
                  control={control}
                  error={errors.city}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_STATE}
                  as={ATMInput}
                  name="state"
                  control={control}
                  error={errors.state}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_ZIPCODE}
                  as={ATMInput}
                  name="zipCd"
                  control={control}
                  error={errors.zipCd}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_GPS}
                  as={ATMInput}
                  name="gps"
                  control={control}
                  error={errors.gps}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <ATMField
                  as={ATMInput}
                  label={Lang.LBL_SUBSTATION_PHONE_CODE}
                  name="areaCd"
                  control={control}
                  error={errors.areaCd}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_SUBSTATION_PHONE}
                  as={ATMInput}
                  name="phnNbr"
                  control={control}
                  error={errors.phnNbr}
                  onChange={([_, { value }]) =>
                    value.replace(/^(\d{3})(\d{4})/, '$1-$2')
                  }
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns="equal">
              <ATMGrid.Column>
                <ATMField
                  label="Thomas Bros"
                  as={ATMInput}
                  name="tbros"
                  control={control}
                  error={errors.tbros}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  label={Lang.LBL_SUB_DIST_CODE}
                  as={ATMInput}
                  name="subDistId"
                  control={control}
                  error={errors.subDistId}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default SubstationForm;
