import { httpClient } from 'src/libraries/http.library';
import {
  IRadializedSubstationRequest,
  RadializedResponsePayloadSchema,
  OutageRequestDailyReportPayloadSchema,
  OutageRequestLerReportPayloadSchema,
  IDailyReportUpdate,
  DailyETSReportSchema,
  DailyReportUpdatePayloadSchema,
} from 'src/models/daily-reports.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { AxiosRequestConfig } from 'axios';

const client = httpClient();
const endpoint = 'daily-report';
const radializedSubEndpoint = 'radialized-substation';

const services = {
  listOverviewGET: async (
    data?: Partial<IORGDataTableQueryState>,
    config: AxiosRequestConfig = {}
  ) => {
    const params: Record<string, any> = {
      ...data,
    };
    return client.get(
      `/${endpoint}`,
      params,
      OutageRequestDailyReportPayloadSchema,
      config
    );
  },
  lerReportGET: async (
    data?: Partial<IORGDataTableQueryState>,
    config: AxiosRequestConfig = {}
  ) => {
    const params: Record<string, any> = {
      ...data,
    };
    return client.get(
      `/${endpoint}`,
      params,
      OutageRequestLerReportPayloadSchema,
      config
    );
  },
  listETSGET: async (date: Date) => {
    const params = {
      filters: [{ name: 'date', value: date }],
    };
    return client.get(`/${endpoint}?action=ETS`, params, DailyETSReportSchema);
  },
  listRadializedGET: async (data: IRadializedSubstationRequest) => {
    const params = {
      filters: [{ name: 'date', value: data.date }],
    };
    return client.get(
      `/${radializedSubEndpoint}`,
      params,
      RadializedResponsePayloadSchema
    );
  },

  updatePUT: async (id: number, data: IDailyReportUpdate) => {
    return client.put(
      `/${endpoint}/${id}`,
      data,
      DailyReportUpdatePayloadSchema
    );
  },
};

export default services;
