import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/oms-audit-report.service';
import { IOmsAuditReportItem } from 'src/models/oms-audit-report.model';

export const omsAuditReportActionTypes = {
  OMS_AUDIT_REPORT_LIST_READ: 'OMS_AUDIT_REPORT_LIST_READ',
  OMS_AUDIT_REPORT_DATA_SET: 'OMS_AUDIT_REPORT_DATA_SET',
  OMS_AUDIT_REPORT_LIST_CLEAR: 'OMS_AUDIT_REPORT_LIST_CLEAR'
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  listGET: {
    type: omsAuditReportActionTypes.OMS_AUDIT_REPORT_LIST_READ,
    service: services.listGET,
  },

  // This is a sync action
  setData: (omsAuditReport: IOmsAuditReportItem) => ({
    type: omsAuditReportActionTypes.OMS_AUDIT_REPORT_DATA_SET,
    payload: {
      omsAuditReport,
    },
  }),
  clearList: () => ({
    type: omsAuditReportActionTypes.OMS_AUDIT_REPORT_LIST_CLEAR
  })
};

export type IOmsAuditReportAsync = typeof duckActions;

export interface IOmsAuditReportState
  extends ICommonState<typeof omsAuditReportActionTypes> {
  data?: IOmsAuditReportItem;
  list: IOmsAuditReportItem[];
  total: number;
}

export const defaultState: IOmsAuditReportState = {
  status: {},
  list: [],
  total: 0,
};

const OmsAuditReportReducer = (
  state: IOmsAuditReportState,
  action: IReducerAction<IOmsAuditReportAsync>
): IOmsAuditReportState => {
  switch (action.type) {
    case omsAuditReportActionTypes.OMS_AUDIT_REPORT_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    case omsAuditReportActionTypes.OMS_AUDIT_REPORT_LIST_CLEAR: {
      return {
        ...state,
        list: [],
        total: 0
      };
    }
    case omsAuditReportActionTypes.OMS_AUDIT_REPORT_DATA_SET:
    default: {
      return state;
    }
  }
};

export default OmsAuditReportReducer;
