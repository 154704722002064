import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { ATMButton, ATMDatePicker } from 'shared-it-appmod-ui';
import { DailyReportViewTab } from 'src/constants/daily-reports.constant';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import {
  IDailyReportVoltageGroup,
  IETS,
  IRadialized,
  IRadializedSubstationRequest,
} from 'src/models/daily-reports.model';
import Axios, { CancelTokenSource } from 'axios';
import Moment from 'src/libraries/moment.library';
import SystemChangePDF, {
  SystemChangeActionType,
} from 'src/components/organisms/pdf-system-change/pdf-system-change.component';
import { ISystemChange } from 'src/models/system-change.model';
import {
  LocalStorageId,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/helpers/storage.helper';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import PDFETSSchedule from 'src/components/organisms/pdf-ets-schedule/pdf-ets-schedule.component';
import PDFOverview from 'src/components/organisms/pdf-overview/pdf-overview.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import styles from '../daily-reports.module.scss';

type IProps = {
  reportDate: Date;
  overviewEmail?: JSX.Element;
  setDailyReports: React.Dispatch<
    React.SetStateAction<
      | IDailyReportVoltageGroup[]
      | IRadialized[]
      | IETS[]
      | ISystemChange[]
      | undefined
    >
  >;
  setReportDate: React.Dispatch<React.SetStateAction<Date>>;
};

const { CancelToken } = Axios;

const TOKEN_EXPIRATION_MINUTES = Moment().endOf('day').diff(Moment(), 'minute');

const DailyReportsDateNavigation: React.FC<IProps> = ({
  reportDate,
  overviewEmail,
  setDailyReports,
  setReportDate,
}) => {
  const {
    state: { viewTab, ...dataState },
    actions,
  } = useDailyReportsContext();
  const { state: systemChangeState, actions: systemChangeActions } =
    useSystemChangeContext();

  const [, setToken] = useState<CancelTokenSource | undefined>();
  const [ready, setReady] = useState(false);

  const setSelectedDate = useCallback(
    () => {
      setLocalStorageItem(
        LocalStorageId.DAILY_REPORT,
        reportDate,
        TOKEN_EXPIRATION_MINUTES
      );
    },
    // (tab: string) => {
    //   switch (tab) {
    //     case DailyReportViewTab.ETS: {
    //       setLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_ETS,
    //         reportDate,
    //         TOKEN_EXPIRATION_MINUTES
    //       );
    //       break;
    //     }
    //     case DailyReportViewTab.RadializedSubstations: {
    //       setLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_RADIALIZED_SUBSTATION,
    //         reportDate,
    //         TOKEN_EXPIRATION_MINUTES
    //       );

    //       break;
    //     }
    //     case DailyReportViewTab.SystemChanges: {
    //       setLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_SYSTEM_CHANGES,
    //         reportDate,
    //         TOKEN_EXPIRATION_MINUTES
    //       );

    //       break;
    //     }
    //     case DailyReportViewTab.Overview: {
    //       setLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_OVERVIEW,
    //         reportDate,
    //         TOKEN_EXPIRATION_MINUTES
    //       );
    //       break;
    //     }
    //   }
    // },
    // [dataState, actions, reportDate]
    [reportDate]
  );

  const getPreviousDay = useCallback(() => {
    setReportDate(
      Moment(reportDate?.toString()).subtract(1, 'day').startOf('day').toDate()
    );
  }, [reportDate, setReportDate]);

  const getNextDay = useCallback(() => {
    setReportDate(
      Moment(reportDate?.toString()).add(1, 'day').startOf('day').toDate()
    );
  }, [reportDate, setReportDate]);

  const handleFetch = useCallback(() => {
    const source = CancelToken.source();
    setToken((value) => {
      if (value) {
        value.cancel();
      }
      return source;
    });

    const fetch = async () => {
      const filters = [
        {
          name: 'date',
          value: reportDate,
        },
      ];
      const { payload } = await actions.listOverviewGET(
        { filters },
        {
          cancelToken: source.token,
        }
      );
      if (payload) {
        setDailyReports(payload?.data ?? []);
        if (payload.error) {
          ToastError(payload.error);
        }
      } else {
        setDailyReports([]);
      }
    };

    setImmediate(() => {
      fetch();
    });
  }, [actions, setDailyReports, setReportDate, reportDate, setToken]);

  const getDailyReportListByType = useCallback(
    async (type: DailyReportViewTab) => {
      switch (type) {
        case DailyReportViewTab.ETS: {
          await actions.listETSGET(reportDate);
          setDailyReports(dataState.etsList);
          break;
        }
        case DailyReportViewTab.RadializedSubstations: {
          const request: IRadializedSubstationRequest = {
            date: reportDate,
          };
          await actions.listRadializedGET(request);
          setDailyReports(dataState.radializedList);
          break;
        }
        case DailyReportViewTab.SystemChanges: {
          await systemChangeActions.listGET({
            filters: [
              {
                name: 'dateRange',
                value: [
                  Moment(reportDate).startOf('day').toDate(),
                  Moment(reportDate).endOf('day').toDate(),
                ],
              },
            ],
          });
          setDailyReports(systemChangeState.list);
          break;
        }
        case DailyReportViewTab.Overview: {
          await handleFetch();
          break;
        }
      }
    },
    [actions, reportDate]
  );

  const handleSelectedDate = useCallback(
    debounce(async (date) => {
      await actions.stashDailyReportDetails(
        date,
        viewTab.length ? viewTab : DailyReportViewTab.Overview
      );
      // setSelectedDateByTab(viewTab);
      setSelectedDate();
      await getDailyReportListByType(viewTab);
    }, 300),
    [actions, setSelectedDate, getDailyReportListByType]
  );

  useEffect(() => {
    const date =
      (getLocalStorageItem(LocalStorageId.DAILY_REPORT) as Date) || new Date();
    setReportDate(new Date(date));
    // switch (viewTab) {
    //   case DailyReportViewTab.ETS: {
    //     const date =
    //       (getLocalStorageItem(LocalStorageId.DAILY_REPORT_ETS) as Date) ||
    //       new Date();
    //     setReportDate(new Date(date));
    //     break;
    //   }
    //   case DailyReportViewTab.RadializedSubstations: {
    //     const date =
    //       (getLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_RADIALIZED_SUBSTATION
    //       ) as Date) || new Date();
    //     setReportDate(new Date(date));
    //     break;
    //   }
    //   case DailyReportViewTab.SystemChanges: {
    //     const date =
    //       (getLocalStorageItem(
    //         LocalStorageId.DAILY_REPORT_SYSTEM_CHANGES
    //       ) as Date) || new Date();
    //     setReportDate(new Date(date));
    //     break;
    //   }
    //   case DailyReportViewTab.Overview: {
    //     const date =
    //       (getLocalStorageItem(LocalStorageId.DAILY_REPORT_OVERVIEW) as Date) ||
    //       new Date();
    //     setReportDate(new Date(date));
    //     break;
    //   }
    // }
    setReady(true);
  }, [viewTab]);

  useEffect(() => {
    if (ready) {
      handleSelectedDate(reportDate);
    }

    return () => {
      handleSelectedDate.cancel();
    };
  }, [reportDate, ready]);

  return (
    <div className={styles.dateNavigation}>
      {viewTab === DailyReportViewTab.Overview && (
        <>
          <div style={{ display: 'none' }}> {overviewEmail} </div>
          <PDFOverview date={reportDate} />
        </>
      )}
      {viewTab === DailyReportViewTab.SystemChanges && (
        <SystemChangePDF
          date={reportDate}
          type={SystemChangeActionType.Print}
        />
      )}
      {viewTab === DailyReportViewTab.ETS && (
        <PDFETSSchedule date={reportDate} />
      )}
      <ATMButton
        key="filter"
        secondary
        icon="left angle"
        content="Previous Day"
        onClick={getPreviousDay}
      />
      <ATMDatePicker
        format="MM/DD/YYYY"
        placeholder="Date"
        value={reportDate}
        onChange={(_, { value }) =>
          setReportDate((value as Date) ?? new Date())
        }
      />
      <ATMButton
        key="download"
        secondary
        labelPosition="right"
        icon="right angle"
        content="Next Day"
        onClick={getNextDay}
      />
      <ATMButton
        secondary
        content="Refresh"
        onClick={() => handleSelectedDate(reportDate)}
      />
    </div>
  );
};

export default DailyReportsDateNavigation;
