import React, { useCallback } from 'react';
import { ATMForm, ATMFormProvider } from 'shared-it-appmod-ui';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  ILerRequestForm,
  LerRequestSortResponseForm,
} from 'src/models/ler-request.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import LERRequestSwitchingForm, {
  isSortStatusComplete,
} from '../ler-request-switching-form/ler-request-switching-form.component';

type IProps = {
  data: ILerRequest;
};

const LerRequestSwitchingManager: React.FC<IProps> = ({ data }) => {
  const { state, actions } = useLerRequestContext();

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_UPDATE_SWITCHING
  );

  const handleSubmit = useCallback(
    async (formData) => {
      if (state.data?.requestId) {
        const filteredList = formData.sortResponses?.filter(
          (val) => !isSortStatusComplete(val?.sortStat as string)
        );
        const validData: ILerRequestForm = {
          ...formData,
          sortResponses: (filteredList || []).filter(
            (v) => v.sent || v.cancellation
          ),
        };

        const result = await actions.switchingUpdatePUT(
          state.data?.requestId,
          validData
        );

        if (result.payload) {
          ToastSuccess(Lang.MSG_LER_REQUEST_UPDATE_SWITCHING_SUCCESS);
        }
      }
    },
    [state.data, actions]
  );

  return (
    <ATMForm
      key={`switching_${state.data?.requestId}_${state.data?.sortResponses?.length}`}
      onSubmit={handleSubmit}
      validationSchema={LerRequestSortResponseForm}
      defaultValues={data}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <LERRequestSwitchingForm
              hasUpdate
              data={data.sortResponses}
              loading={status.fetching}
              isRealTimeOperator
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default LerRequestSwitchingManager;
