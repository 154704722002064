import React from 'react';
import { IRolesPreForm } from 'src/models/roles.model';
import { ATMButton, ATMLoader, MOLRightPanel } from 'shared-it-appmod-ui';
import { IEmployee } from 'src/models/employee.model';
import Lang from 'src/libraries/language';
import RolesForm from '../roles-form/roles-form.component';
import styles from './roles-add.module.scss';

type IProps = {
  loading: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleSubmit: (data: IRolesPreForm) => void;
  handleSubmitEdit: (data: IRolesPreForm) => void;
  onClose: boolean;
  setOnClose: () => void;
  employee?: IEmployee;
  handleSearch: (id) => void;
  setEmployee: React.Dispatch<React.SetStateAction<IEmployee | undefined>>;
  grantAccess?: boolean;
  defaultValues?: Partial<IRolesPreForm>;
  editExistingEmp?: boolean;
  setEditExistingEmp: React.Dispatch<React.SetStateAction<boolean>>;
  setExistingEmpId: React.Dispatch<
    React.SetStateAction<Record<any, any> | undefined>
  >;
  employeeData: boolean;
};

const RolesAddView: React.FC<IProps> = ({
  loading,
  handleClick,
  handleSubmit,
  employeeData,
  handleSubmitEdit,
  onClose,
  setOnClose,
  employee,
  setEmployee,
  handleSearch,
  formRef,
  defaultValues,
  setExistingEmpId,
  editExistingEmp,
}) => {
  return (
    <MOLRightPanel
      isOpen={onClose}
      onClose={setOnClose}
      trigger={
        <ATMButton
          content={Lang.LBL_GRANT_ACCESS}
          primary
          onClick={() => {
            setOnClose();
            setEmployee(undefined);
            setExistingEmpId(undefined);
          }}
        />
      }
      header="Grant Access"
      panelContent={
        employeeData === true ? (
          <ATMLoader size="large" />
        ) : (
          <RolesForm
            handleSubmit={handleSubmit}
            handleSubmitEdit={handleSubmitEdit}
            defaultValues={defaultValues}
            formRef={formRef}
            employee={employee}
            handleSearch={handleSearch}
            grantAccess
            editExistingEmp={editExistingEmp}
          />
        )
      }
      panelFooter={
        <div className={styles.panelFooter}>
          <ATMButton
            className={styles.marginRightSmall}
            secondary
            type="button"
            size="small"
            onClick={() => {
              setOnClose();
              setEmployee(undefined);
            }}
            content="Cancel"
          />
          <ATMButton
            primary
            size="small"
            loading={loading}
            onClick={() => {
              handleClick();
            }}
            content="Save"
          />
        </div>
      }
    />
  );
};
export default RolesAddView;
