import { TestContext } from 'yup';

export enum ValidationErrorMessage {
  ALPHA_NUMERIC_WITH_SPECIAL_CHAR = 'Allowed special characters: &-',
  ALPHA_NUMERIC = 'Please enter letters and numbers only',
  NUMBER_ONLY = 'Please enter numbers only.',
  INVALID_FILE_NAME_CHARACTERS = 'Please avoid using the following invalid characters in the file name: #, %, *, :, <, >, ?, /, |, "',
}

export const isValidAlphanumeric = (input: any) => {
  const usernamePattern = /^[a-zA-Z0-9]*$/;
  return usernamePattern.test(input);
};

export const isValidAlphanumericWithSpecialChar = (input: any) => {
  const usernamePattern = /^[a-zA-Z0-9&\-\s]*$/;
  return usernamePattern.test(input);
};

export const isValidNumber = (input: any) => {
  const usernamePattern = /^\d+$/;
  return usernamePattern.test(input);
};

export const invalidSharePointFileName = (input: any) => {
  const invalidFileNamePattern = /[#%*:<>?/"|;,]/;
  if (invalidFileNamePattern.test(input)) {
    return true;
  }
  return false;
};

export const isValidAlphanumericWithSpecialCharField = (
  value,
  _context: TestContext
) => {
  if (!isValidAlphanumericWithSpecialChar(value)) {
    return false;
  }
  return true;
};

export const isValidNumberField = (value, _context: TestContext) => {
  if (value.length) {
    if (!isValidNumber(value)) {
      return false;
    }
  }
  return true;
};

export const isValidAlphaNumericField = (value, _context: TestContext) => {
  if (!isValidAlphanumeric(value)) {
    return false;
  }
  return true;
};
