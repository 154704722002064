import React, { useCallback, useState, useEffect } from 'react';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import history from 'src/history';
import useLocationTab from 'src/hooks/location-tab.hook';
import Lang from 'src/libraries/language';
import {
  getTroubleJobParams,
  getTroubleJobsStatus,
} from 'src/selectors/trouble-jobs.selector';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { checkValue } from 'src/libraries/common.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useSwitchingContext } from 'src/contexts/switching.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import TroubleJobsListView from './trouble-jobs-list.view';

const TroubleJobsList: React.FC = () => {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [totalAll, setTotalAll] = useState(0);
  const { state, actions } = useTroubleJobsContext();
  const { actions: substationActions } = useSubstationContext();
  const { actions: equipmentTypeActions } = useEquipmentTypeContext();
  const { actions: switchingActions } = useSwitchingContext();
  const { actions: realTimeLogActions } = useRealTimeLogContext();
  const { currentTab } = useLocationTab('troubleJobsStatus');
  const status = getTroubleJobsStatus(
    state,
    troubleJobsActionTypes.TROUBLE_JOBS_LIST_READ
  );

  useEffect(() => {
    // substationActions?.listGET({ limit: 0, page: 1 });
    substationActions?.listSubstationCreateGET({ limit: 0, page: 1 });
    equipmentTypeActions?.listGET({ limit: 0, page: 1 });
    switchingActions?.listGET({ limit: 0, page: 1 });
    realTimeLogActions?.lineGET();
    actions?.outageGET();
  }, [
    substationActions,
    equipmentTypeActions,
    switchingActions,
    realTimeLogActions,
    actions,
  ]);

  const handleFetch = useCallback(
    async (params) => {
      let newFilters = params;
      if (!params.filters) {
        newFilters = {
          ...params,
          // page: 1,
          // limit: 0,
        };
      }
      const data = await getTroubleJobParams(newFilters, currentTab);
      await actions?.listGET(data);
    },
    [actions, currentTab]
  );

  const handleAddStationAlarm = useCallback(async () => {
    const response = await actions.createStationAlarmPOST();
    actions.listGET({ limit: 0, page: 1 });
    ToastSuccess(Lang.MSG_TROUBLE_JOB_STATION_ALARM);
    history.push(`/trouble-jobs?id=${response.payload?.troubleJobId}`);
  }, [actions]);

  const openAddPanel = useCallback(() => {
    setIsOpenAdd(!isOpenAdd);
  }, [isOpenAdd, setIsOpenAdd]);

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      let newFilters = params;
      if (!params.filters) {
        newFilters = {
          ...params,
        };
      }
      const data = await getTroubleJobParams(newFilters, currentTab);
      const result = await actions.listGET(data);
      if (result.payload?.rows) {
        const list = result.payload.rows;
        const items = list.map((value) => [
          checkValue(value.troubleJobId),
          checkValue(value.jobStatus),
          checkValue(formatDate(value.beginDate)),
          checkValue(formatDate(value.endDate)),
          checkValue(value.facility?.outgFacNm),
          checkValue(value.substation?.name),
          checkValue(value.primary?.isoOutgTypeDesc),
          checkValue(value.secondary?.isoOutgTypeDesc),
          checkValue(value.jobDescription),
          checkValue(
            value.submitted?.lastName && value.submitted?.firstName
              ? `${value.submitted?.lastName}, ${value.submitted?.firstName}`
              : value.submittedBy
          ),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            'Trouble Job#',
            'Status',
            'Begin Date',
            'End Date',
            'Facility Name',
            'Substation',
            'Primary Cause',
            'Secondary Cause',
            'Description',
            'Created By',
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_TroubleJobs_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state, currentTab]
  );

  useEffect(() => {
    sessionStorage.setItem('currentTabStatus', currentTab);
  }, [currentTab]);

  return (
    <TroubleJobsListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      total={state.total}
      openTotal={state.openTotal}
      currentTab={currentTab}
      openAddPanel={openAddPanel}
      isOpenAdd={isOpenAdd}
      setIsOpenAdd={setIsOpenAdd}
      showStatus={Number(currentTab) === 1}
      handleDownload={handleDownload}
      handleAddStationAlarm={handleAddStationAlarm}
      totalAll={totalAll}
      setTotalAll={setTotalAll}
    />
  );
};

export default TroubleJobsList;
