import {
  subCategoryActionTypes,
  ISubCategoryState,
} from 'src/ducks/sub-category.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getSubCategoryStatus = (
  state: ISubCategoryState,
  action: keyof typeof subCategoryActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
