import React, { useCallback, useState } from 'react';
import {
  ATMButton,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useOutageFacility } from 'src/hooks/outage-facility.hook';
import Lang from 'src/libraries/language';
import { formatDateTime } from 'src/libraries/moment.library';
import { ILerRequestDraft } from 'src/models/ler-request.model';
import LERRequestAdd from '../../ler-request-add/ler-request-add.component';
import LerRequestDeleteDraft from '../../ler-request-delete/ler-request-delete-draft/ler-request-delete-draft.component';

const LerRequestListDraft: React.FC = () => {
  const { state, actions } = useLerRequestContext();
  const { lines, outageTypes, substations } = useOutageFacility();
  const [loading, setLoading] = useState(false);

  const handleFetch = useCallback(
    async (
      query: Partial<IORGDataTableQueryState>,
      options?: IORGDataTableUpdateOptions
    ) => {
      if (!options || (options && options.action) || !state.draftList.length) {
        setLoading(true);
      }

      await actions.draftListGET(query);

      setLoading(false);
    },
    [actions, setLoading]
  );

  const columns: IORGDataTableColumn<ILerRequestDraft>[] = [
    {
      index: 'outageRequestDraftId',
      title: 'Draft ID',
    },
    {
      index: 'content.outageFacility.outgTypId',
      title: Lang.LBL_OUTAGE_TYPE,
      render: (value) => {
        if (value) {
          return outageTypes.find((v) => v.outageTypeId === value)
            ?.outageTypeDesc;
        }

        return null;
      },
    },
    {
      index: 'content.outageFacility',
      title: 'Facility Name',
      render: (value) => {
        if (value) {
          if (value.substationId) {
            return substations.find(
              (v) => v.substationId === value.substationId
            )?.name;
          }

          return lines.find((v) => v.outgFacId === value.outgFacId)?.outgFacNm;
        }

        return null;
      },
    },
    {
      index: 'updatedAt',
      title: Lang.LBL_DATE_TIME,
      render: (value) => formatDateTime(value),
    },
    {
      index: 'action',
      title: Lang.LBL_ACTION,
      headerProps: {
        textAlign: 'center',
      },
      width: '100px',
      render: (_, value) =>
        value.outageRequestDraftId && (
          <div className="action-buttons">
            <LERRequestAdd data={value} trigger={<ATMButton icon="edit" />} />
            <LerRequestDeleteDraft
              draftId={value.outageRequestDraftId}
              trigger={<ATMButton icon="trash" />}
            />
          </div>
        ),
    },
  ];

  return (
    <ORGDataTable
      celled={false}
      columns={columns}
      data={state.draftList}
      onChange={handleFetch}
      total={state.draftTotal}
      loading={loading}
    />
  );
};

export default LerRequestListDraft;
