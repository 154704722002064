import React, { useCallback, useState } from 'react';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { ITsoOssEmployee } from 'src/models/tso-oss-employee.model';
import { getTsoOssEmployeeStatus } from 'src/selectors/tso-oss-employee.selector';
import { tsoOssEmployeeActionTypes } from 'src/ducks/tso-oss-employee.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import TsoOssEmployeeDeleteView from './tso-oss-employee-delete.view';

type IProp = {
  data: ITsoOssEmployee;
};

const TsoOssEmployeeDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useTsoOssEmployeeContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getTsoOssEmployeeStatus(
    state,
    tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.empId) {
      const response = await actions.dataDELETE(data.empId);

      if (!response.error) {
        actions.listGET({ limit: 0, page: 1 });
        setIsOpen(false);

        ToastSuccess(
          `User ${data.fullName}/${data.empId} has been remove from the TSO/OSS list`
        );
      } else {
        ToastError('Cannot remove a user that is currently sick');
      }
    }
  }, [actions, data]);

  return (
    <TsoOssEmployeeDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default TsoOssEmployeeDelete;
