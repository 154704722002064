import yup from 'src/libraries/validator.library';
import { BlackStartSchema } from './facility.model';

export const TnaReportShape = {
  reqStatId: yup.number().nullable(),
  requestId: yup.string().nullable(),
  startTm: yup.string().nullable(),
  stopTm: yup.string().nullable(),
  status: yup
    .object({
      outgReqStatusId: yup.string().nullable(),
      statDescription: yup.string().nullable(),
    })
    .nullable(),
  requestFacility: yup
    .object({
      requestId: yup.number().nullable(),
      outgFacId: yup.number().nullable(),
      outgTypId: yup.string().nullable(),
      outageRequest: yup
        .object({
          recalTm: yup.string().nullable(),
          isoWorkKindId: yup.string().nullable(),
          wrkDesc: yup.string().nullable(),
        })
        .nullable(),
      outageDates: yup.array(),
      outageType: yup
        .object({
          outageTypeDesc: yup.string().nullable(),
        })
        .nullable(),
      facility: yup
        .object({
          voltId: yup.number().nullable(),
          facTypId: yup.number().nullable(),
          outgFacNm: yup.string().nullable(),
          facTyp: yup
            .object({
              facTypNm: yup.string().nullable(),
            })
            .nullable(),
          volt: yup
            .object({
              voltNm: yup.string().nullable(),
            })
            .nullable(),
          blackstart: BlackStartSchema.nullable(),
        })
        .nullable(),
      substationId: yup.string().nullable(),
      substation: yup
        .object({
          substationId: yup.string().nullable(),
          name: yup.string().nullable(),
        })
        .nullable(),
    })
    .nullable(),
  days: yup.number().nullable(),
  startTmTxt: yup.string().nullable(),
  stopTmText: yup.string().nullable(),
};

export const TnaReportSchema = yup.object(TnaReportShape);

export const TnaReportListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(TnaReportSchema.clone()),
});

export const TnaReportPayloadSchema = yup.object({
  TnaReport: TnaReportSchema.clone(),
});

export const TnaReportDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ITnaReport = yup.Asserts<typeof TnaReportSchema>;
