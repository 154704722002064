import React, { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import {
  ITroubleTicket,
  ITroubleTicketResponsePayload,
} from 'src/models/trouble-ticket.model';
import history from 'src/history';
import { ATMLoader } from 'shared-it-appmod-ui';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import { getRolesStatus } from 'src/selectors/roles.selector';
import { useRolesContext } from 'src/contexts/roles.context';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { getUser } from 'src/libraries/amplify.library';
import Error404 from '../../error/error-404/error-404.component';
import TroubleTicketEditView from './trouble-ticket-edit.view';

const TroubleTicketEdit: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { state, actions } = useTroubleTicketContext();
  const { data } = state;
  const formRef = useRef<HTMLFormElement>(null);

  const troubleTicketId = Number(id);

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.dataGET(troubleTicketId);
      if (result.error) {
        history.push('/trouble-ticket');
      }
    };
    fetchData();
  }, [actions]);

  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { state: employeeRolesState } = useRolesContext();
  const { submitUpload } = useSharepoint();
  const status = getTroubleTicketStatus(
    state,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_READ
  );

  const statusEmployeeRole = getRolesStatus(
    employeeRolesState,
    rolesActionTypes.ROLES_LIST_READ
  ).fetching;

  const handleFileUploadSubmit = useCallback(
    async (responseData: ITroubleTicketResponsePayload) => {
      // const requestor = responseData?.updatedByEmployee.fullName || '';
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.troubleTicketId) || '',
        parentFolder: JSON.stringify(responseData?.troubleTicketId) ?? '',
        requestId: responseData?.troubleTicketId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.TROUBLE_TICKET_TYPE,
        setting: settingState,
        withParentFolder: true,
      });
    },
    [fileState, settingState]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITroubleTicket) => {
      let response;

      if (data?.troubleTicketId) {
        if (
          !formData?.commentsResolution?.length ||
          !formData?.commentsResolution?.replace(/\s/g, '').length
        ) {
          // eslint-disable-next-line no-param-reassign
          const { commentsResolution, ...formInfo } = formData;
          response = await actions.updatePUT(
            data?.troubleTicketId,
            formInfo as ITroubleTicket
          );
        } else {
          response = await actions.updatePUT(data?.troubleTicketId, formData);
        }
      }

      if (response.payload) {
        await handleFileUploadSubmit(response.payload);
        fileAction.deleteAllStashedFiles();

        if (formData.ticketStatus === 'Closed') {
          ToastSuccess(
            `Trouble Ticket #${formData.troubleTicketId} closed successfully!`,
            { delay: 20000 }
          );
          if (response.payload?.emailResult) {
            ToastSuccess(response.payload.emailResult);
          }
        } else {
          ToastSuccess(
            `Trouble Ticket #${formData.troubleTicketId} updated successfully!`,
            { delay: 20000 }
          );
          if (response.payload?.emailResult) {
            ToastSuccess(response.payload.emailResult);
          }
        }
        if (history.length === 1) {
          await actions.dataGET(troubleTicketId);
        } else {
          history.goBack();
        }
      } else {
        fileAction.deleteAllStashedFiles();
        ToastError(
          response?.error?.message ?? 'Failed to update trouble ticket'
        );
        history.goBack();
      }
    },
    [data, handleFileUploadSubmit, fileAction]
  );

  if (
    (!state.data ||
      (state.data && state.data.troubleTicketId !== troubleTicketId)) &&
    status.fetching
  ) {
    return <ATMLoader active />;
  }

  if (
    !state.data ||
    (state.data && state.data.troubleTicketId !== troubleTicketId)
  ) {
    return <Error404 />;
  }

  return (
    <TroubleTicketEditView
      formRef={formRef}
      defaultValues={state.data}
      handleSubmit={handleSubmit}
      handleClick={handleClick}
      statusEmployeeRole={statusEmployeeRole}
      assignToEmp={employeeRolesState.list}
    />
  );
};

export default TroubleTicketEdit;
