import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMGrid,
  ATMField,
  ATMInput,
  ATMButton,
  ATMTable,
  useATMFormContext,
  ATMMessage,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { getEmployeeStatus } from 'src/selectors/employee.selector';
import Lang from 'src/libraries/language';
import { IUserGroupForm } from 'src/models/user-group.model';
import { employeeActionTypes } from 'src/ducks/employee.duck';
import { useFieldArray } from 'react-hook-form';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { IEmployee } from 'src/models/employee.model';

const UserGroupMemberForm: React.FC<{
  defaultValues?: Partial<IUserGroupForm>;
  handleEnable: (value: boolean) => void;
  errorMember: boolean;
  handleErrorMember: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ defaultValues, handleEnable, errorMember, handleErrorMember }) => {
  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    watch,
  } = useATMFormContext<IUserGroupForm>();

  const { fields, remove, append } = useFieldArray({
    name: 'userGrpMembers',
    control,
  });

  const { state } = useEmployeeContext();
  const [empId, setEmpId] = useState('');
  const [isExists, setIsExists] = useState(false);

  const status = getEmployeeStatus(
    state,
    employeeActionTypes.EMPLOYEE_SEARCH_FETCH
  );

  const handleAdd = useCallback(() => {
    const userExist = getValues().userGrpMembers?.find((user) => {
      return user.empId === empId.trim();
    });
    const list = getValues().userGrpMembers;
    if (userExist === undefined) {
      setIsExists(false);
      const result: IEmployee | undefined = state.list.find(
        (user) => user.emp_id === empId.trim()
      );
      if (result) {
        remove();
        list?.map((val) => {
          append(val);
        });
        append({
          empId: result.emp_id,
          firstName: `${result.first_name}`,
          lastName: `${result.last_name}`,
          emailAdd: result.e_mail_address || undefined,
          userGrpId: undefined,
        });
        clearErrors('userGrpMembers');
        clearErrors('userGrpEmail');
        handleErrorMember(false);
        watch('userGrpMembers', fields);
      }
      handleEnable(true);
    } else {
      setIsExists(true);
      ToastError(Lang.MSG_USER_GROUP_MEMBER_EXISTS);
    }
  }, [fields, append, empId, remove]);

  useEffect(() => {
    handleEnable(false);
  }, []);

  return (
    <>
      <ATMGrid.Row columns="2">
        <ATMGrid.Column width={8}>
          <ATMField
            label={Lang.LBL_GROUP_MEMBERS}
            key={
              isExists
                ? `empId_${fields.length}_1${isExists}`
                : `empId_${fields.length}`
            }
            name="userGrpMembers"
            error={errors.userGrpMembers}
            as={DropdownEmployee}
            placeholder="Enter"
            icon=""
            onChange={
              ((_, { value }) => {
                setEmpId(value);
                return value;
              }) as any
            }
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={2}>
          <ATMButton
            type="button"
            style={{ marginTop: '1.5em' }}
            size="small"
            primary
            content={Lang.LBL_ADD}
            disabled={empId ? status.fetching : true}
            onClick={handleAdd}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns="1">
        <ATMGrid.Column>
          <div style={{ marginBottom: '0.5rem' }}>
            <b> {Lang.LBL_GROUP_ADDED_MEMBERS} </b>{' '}
          </div>
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell>
                  {Lang.LBL_GROUP_EMP_NAME}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_GROUP_EMP_ID}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_GROUP_EMP_EMAIL}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell />
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {/* Local variables to simulate the add employee details */}
              {fields.length === 0
                ? ''
                : fields.map((item, i) => (
                    // eslint-disable-next-line react/jsx-indent
                    <ATMTable.Row key={item.id}>
                      <ATMTable.Cell>
                        {/* Add this hidden field to avoid delay on updating control values in form */}
                        <ATMField
                          as={ATMInput}
                          name={`userGrpMembers1[${i}].empId`}
                          control={control}
                          defaultValue={item.firstName}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`userGrpMembers[${i}].firstName`}
                          control={control}
                          defaultValue={item.firstName}
                          type="hidden"
                          className="hidden"
                        />
                        <ATMField
                          as={ATMInput}
                          name={`userGrpMembers[${i}].lastName`}
                          control={control}
                          defaultValue={item.lastName}
                          type="hidden"
                          className="hidden"
                        />
                        {`${item.firstName} ${item.lastName}`}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          name={`userGrpMembers[${i}].empId`}
                          control={control}
                          defaultValue={item.empId}
                          type="hidden"
                          className="hidden"
                        />
                        {item.empId}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <ATMField
                          as={ATMInput}
                          control={control}
                          name={`userGrpMembers[${i}].emailAdd`}
                          defaultValue={item.emailAdd}
                          type="hidden"
                          className="hidden"
                        />
                        {item.emailAdd}
                      </ATMTable.Cell>
                      <ATMTable.Cell textAlign="right">
                        <ATMButton
                          icon="trash alternate outline"
                          type="button"
                          style={{ color: 'gray' }}
                          onClick={() => {
                            if (
                              defaultValues
                                ? (getValues().userGrpEmail !== null &&
                                    getValues().userGrpEmail !== '') ||
                                  getValues().userGrpMembers?.[1] !== undefined
                                : true
                            ) {
                              handleEnable(true);
                              remove(i);
                            } else {
                              handleErrorMember(true);
                            }
                          }}
                        />
                      </ATMTable.Cell>
                    </ATMTable.Row>
                  ))}
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
        <ATMGrid.Column>
          {errorMember && (
            <ATMMessage negative>
              <b>{Lang.MSG_USER_GROUP_ONE_MEMBER_ERROR}</b>
            </ATMMessage>
          )}
        </ATMGrid.Column>
      </ATMGrid.Row>
    </>
  );
};

export default UserGroupMemberForm;
