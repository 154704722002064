import React from 'react';
import { AccessRole } from 'src/constants';
import { useRolesContext } from 'src/contexts/roles.context';
import { IRolesByEmpId } from 'src/models/roles.model';
import HomeView from './home.view';

const rolesValidation = (roles: IRolesByEmpId[]) => {
  const newRoles: IRolesByEmpId[] = [...roles];
  const hasOutage = roles.findIndex((rol: IRolesByEmpId) => {
    return [1, 2, 3, 4].includes(rol.roleId);
  });
  if (hasOutage > -1) {
    newRoles.push({
      roleId: AccessRole.CALENDAR_VIEWER,
      role: { contentAccess: 'Outage Calendar' },
      roleStatus: true,
    });
  }
  return newRoles;
};

const Home: React.FC = () => {
  const { state } = useRolesContext();

  return <HomeView roles={rolesValidation(state.rolesByEmpId || [])} />;
};

export default Home;
