import { httpClient } from 'src/libraries/http.library';
import {
  IUserForm,
  UserListPayloadSchema,
  UserDeletePayloadSchema,
  UserPayloadSchema,
} from 'src/models/user.model';
import { userMock } from 'src/models/mocks/user.mock';
import { Asserts } from 'yup';

const client = httpClient();
const endpoint = 'user';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, UserPayloadSchema);
  },

  listGET: async () => {
    // return client.get(`/${endpoint}`, {}, UserListPayloadSchema);
    return new Promise<Asserts<typeof UserListPayloadSchema>>(
      (resolve) => {
        const rows = Array(30)
          .fill(null)
          .map(() => userMock());
        resolve({
          count: rows.length,
          rows,
        });
      }
    );
  },

  createPOST: async (data: IUserForm) => {
    return client.post(`/${endpoint}`, data, UserPayloadSchema);
  },

  updatePUT: async (id: number, data: IUserForm) => {
    return client.put(`/${endpoint}/${id}`, data, UserPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, UserDeletePayloadSchema);
  },
};

export default services;
