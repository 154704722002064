import yup from 'src/libraries/validator.library';

export const NoteShape = {
  noteId: yup.number(),
  noteTxt: yup.string().required(),
  status: yup.string().nullable(),
  name: yup.string().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
};

export const NoteCreateShape = {
  noteTxt: yup.string().required(),
  status: yup.string().nullable(),
  name: yup.string().nullable(),
};

export const NoteSchema = yup.object(NoteShape);
export const NoteCreateSchema = yup.object(NoteCreateShape);

export type INote = yup.Asserts<typeof NoteSchema>;
export type INoteCreate = yup.Asserts<typeof NoteCreateSchema>;
