import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/trouble-ticket.service';
import {
  ITroubleTicketList,
  ITroubleTicketPayload,
} from 'src/models/trouble-ticket.model';

export const troubleTicketActionTypes = {
  TROUBLE_TICKET_DATA_READ: 'TROUBLE_TICKET_DATA_READ',
  TROUBLE_TICKET_LIST_READ: 'TROUBLE_TICKET_LIST_READ',
  TROUBLE_TICKET_AUDIT_LIST_READ: 'TROUBLE_TICKET_AUDIT_LIST_READ',
  TROUBLE_TICKET_AUDIT_LIST_CLEAR: 'TROUBLE_TICKET_AUDIT_LIST_CLEAR',
  TROUBLE_TICKET_DATA_CREATE: 'TROUBLE_TICKET_DATA_CREATE',
  TROUBLE_TICKET_DATA_UPDATE: 'TROUBLE_TICKET_DATA_UPDATE',
  TROUBLE_TICKET_DATA_DELETE: 'TROUBLE_TICKET_DATA_DELETE',
  TROUBLE_TICKET_DATA_CLEAR: 'TROUBLE_TICKET_DATA_CLEAR',
  TROUBLE_TICKET_DATA_SET: 'TROUBLE_TICKET_DATA_SET',
  TROUBLE_TICKET_DATA_UPDATE_FORWARD: 'TROUBLE_TICKET_DATA_UPDATE_FORWARD',
  TROUBLE_TICKET_LIST_ALL_READ: 'TROUBLE_TICKET_LIST_ALL_READ',
  TROUBLE_TICKET_LISTCLEAR: 'TROUBLE_TICKET_LISTCLEAR',
  TROUBLE_TICKET_SCHEDULED_LIST: 'TROUBLE_TICKET_SCHEDULED_LIST',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_LIST_READ,
    service: services.listGET,
  },

  listScheduledGET: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_SCHEDULED_LIST,
    service: services.listGET,
  },

  listAllGET: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ,
    service: services.listGET,
  },

  createPOST: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE,
    service: services.updatePUT,
  },

  updateForwardPUT: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE_FORWARD,
    service: services.updateForwardPUT,
  },

  dataDELETE: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_DELETE,
    service: services.dataDELETE,
  },

  auditReportListGET: {
    type: troubleTicketActionTypes.TROUBLE_TICKET_AUDIT_LIST_READ,
    service: services.auditReportListGET,
  },

  // This is a sync action
  setData: (troubleTicket: ITroubleTicketPayload) => ({
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_SET,
    payload: troubleTicket,
  }),

  dataCLEAR: () => ({
    type: troubleTicketActionTypes.TROUBLE_TICKET_DATA_CLEAR,
  }),

  listCLEAR: () => ({
    type: troubleTicketActionTypes.TROUBLE_TICKET_LISTCLEAR,
  }),

  auditReportListCLEAR: () => ({
    type: troubleTicketActionTypes.TROUBLE_TICKET_AUDIT_LIST_CLEAR,
  }),
};

export type ITroubleTicketAsync = typeof duckActions;

export interface ITroubleTicketState
  extends ICommonState<typeof troubleTicketActionTypes> {
  data?: ITroubleTicketPayload;
  list: ITroubleTicketList[];
  listScheduled: ITroubleTicketList[];
  listAll: ITroubleTicketList[];
  total: number;
  troubleTotal: number;
  scheduledTotal: number;
  auditList: ITroubleTicketList[];
  auditListTotal: number;
}

export const defaultState: ITroubleTicketState = {
  status: {},
  list: [],
  listScheduled: [],
  listAll: [],
  total: 0,
  troubleTotal: 0,
  scheduledTotal: 0,
  auditList: [],
  auditListTotal: 0,
};

const TroubleTicketReducer = (
  state: ITroubleTicketState,
  action: IReducerAction<ITroubleTicketAsync>
): ITroubleTicketState => {
  switch (action.type) {
    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_SET:
    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE:
    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_UPDATE_FORWARD:
    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_READ: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count.all ?? 0,
        troubleTotal: action.payload?.count.trouble ?? 0,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_SCHEDULED_LIST: {
      return {
        ...state,
        listScheduled: action.payload?.rows ?? [],
        scheduledTotal: action.payload?.count.scheduled ?? 0,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ: {
      return {
        ...state,
        listAll: action.payload?.rows ?? [],
        total: action.payload?.count.all ?? 0,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.troubleTicketId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }
    case troubleTicketActionTypes.TROUBLE_TICKET_AUDIT_LIST_READ: {
      return {
        ...state,
        auditList: action.payload?.rows ?? [],
        auditListTotal: action.payload?.count ?? 0,
      };
    }
    case troubleTicketActionTypes.TROUBLE_TICKET_AUDIT_LIST_CLEAR: {
      return {
        ...state,
        auditList: [],
        auditListTotal: 0,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_DATA_CLEAR: {
      return {
        ...state,
        data: undefined,
      };
    }

    case troubleTicketActionTypes.TROUBLE_TICKET_LISTCLEAR: {
      return {
        ...state,
        list: [],
        listAll: [],
        listScheduled: [],
        scheduledTotal: 0,
        troubleTotal: 0,
        total: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default TroubleTicketReducer;
