import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  IFacilityForm,
  FacilityListPayloadSchema,
  FacilityDeletePayloadSchema,
  FacilitySchema,
  FacilityFilterSchema,
  FacilityEquipmentPayloadSchema,
  FacilityVoltListPayloadSchema,
} from 'src/models/facility.model';

const client = httpClient();
const endpoint = 'facility';
const facilityFilterEndpoint = 'facility-filter';
const facilityDropdownValuesEndpoint = 'facility-dropdown-value';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, FacilitySchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, FacilityListPayloadSchema);
  },

  createPOST: async (data: IFacilityForm) => {
    return client.post(`/${endpoint}`, data, FacilitySchema);
  },

  updatePUT: async (id: number, data: IFacilityForm) => {
    return client.put(`/${endpoint}/${id}`, data, FacilitySchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, FacilityDeletePayloadSchema);
  },

  filterGET: async () => {
    return client.get(`/${facilityFilterEndpoint}`, {}, FacilityFilterSchema);
  },

  voltSubstationGET: async (substationId: string, facTypId: number) => {
    const params = {
      filters: [
        { name: 'substationId', value: substationId },
        { name: 'facTypId', value: facTypId },
      ],
    };
    return client.get(
      `/${facilityDropdownValuesEndpoint}?action=voltSubstation`,
      params,
      FacilityVoltListPayloadSchema
    );
  },
  voltLineGET: async (outgFacId: number, facTypId: number) => {
    const params = {
      filters: [
        { name: 'outgFacId', value: outgFacId },
        { name: 'facTypId', value: facTypId },
      ],
    };
    return client.get(
      `/${facilityDropdownValuesEndpoint}?action=voltLine`,
      params,
      FacilityVoltListPayloadSchema
    );
  },

  equipmentGET: async (
    facTypId?: number,
    voltId?: number,
    outgFacId?: number,
    substationId?: string
  ) => {
    const params = {
      filters: [
        { name: 'outgFacId', value: outgFacId },
        { name: 'facTypId', value: facTypId },
        { name: 'voltId', value: voltId },
        { name: 'substationId', value: substationId },
      ],
    };
    return client.get(
      `/${facilityDropdownValuesEndpoint}?action=equipment`,
      params,
      FacilityEquipmentPayloadSchema
    );
  },
};

export default services;
