export enum SettingModule {
  Roles = 'Roles',
  AuthList = 'Auth List',
  OutagePlanning = 'Outage Planning',
}

export enum SettingName {
  ALLOW_OMS_QUERY_FROM_DAILY_OUTAGE_REPORT = 'ALLOW_OMS_QUERY_FROM_DAILY_OUTAGE_REPORT',
  AUTH_TRNG_TRAINER = 'AUTH_TRNG_TRAINER',
  BES_SUBMITTAL_WINDOW_DAYS = 'BES_SUBMITTAL_WINDOW_DAYS',
  ENFORCE_BES_SUBMITTAL_WINDOW = 'ENFORCE_BES_SUBMITTAL_WINDOW',
  FLAG_FOR_OUTAGE = 'FLAG_FOR_OUTAGE',
  LOG_OMS_OUTAGE_DATA = 'LOG_OMS_OUTAGE_DATA',
  MORE_INFO_CONTACT = 'MORE_INFO_CONTACT',
  NRG_MANAGER_EMAILS = 'NRG_MANAGER_EMAILS',
  SHAREPOINT_LER_DOC_LIBRARY_GUID = 'SHAREPOINT_LER_DOC_LIBRARY_GUID',
  SHAREPOINT_TT_DOC_LIBRARY_GUID = 'SHAREPOINT_TT_DOC_LIBRARY_GUID',
  SHAREPOINT_URL = 'SHAREPOINT_URL',
  SUB_STN_TRAINER = 'SUB_STN_TRAINER',
  TEXT_FOR_OUTAGE_INFORMATION = 'TEXT_FOR_OUTAGE_INFORMATION',
  TEXT_FOR_TRAINING_CONTACT_INFO_PAGE = 'TEXT_FOR_TRAINING_CONTACT_INFO_PAGE',
  TEXT_FOR_UNAUTHORIZED_USERS_FOR_MCC_APP = 'TEXT_FOR_UNAUTHORIZED_USERS_FOR_MCC_APP',
  USE_NEW_SORT_SCHEMA = 'USE_NEW_SORT_SCHEMA',
  KEY_REQUEST_URL = 'KEY_REQUEST_URL',
  POPUP_LANDING_DISPLAY = 'POPUP_LANDING_DISPLAY',
  POPUP_LANDING_TEXT = 'POPUP_LANDING_TEXT',
}

export const PAGE_LIMIT_THRESHOLD = 50;
