import React, { useCallback, useState } from 'react';
import { ATMGrid, ATMInput, ATMLoader } from 'shared-it-appmod-ui';
import Empty from 'src/components/atoms/empty/empty.component';
import SystemChangeItem from 'src/components/organisms/system-change-item/system-change-item.component';
import { debounce } from 'lodash';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import { getSystemChangeStatus } from 'src/selectors/system-change.selector';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';
import style from '../daily-reports.module.scss';

const DailyReportSystemChangeDetail: React.FC = () => {
  const { actions, state } = useSystemChangeContext();
  const [workDesc, setWorkDesc] = useState('');
  const status = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_LIST_READ
  );

  const searchStatus = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_FIND_WORK_DESC
  );

  const handleWorkDescSearch = useCallback(
    debounce(async (keyword: string) => {
      await actions.findWorkDesc(keyword?.trim());
    }, 500),
    [state]
  );

  if (status.fetching || searchStatus.fetching) {
    return <ATMLoader active inline="centered" />;
  }

  if (!state.list.length) {
    return <Empty />;
  }

  return (
    <>
      <div style={{ marginBottom: '1em' }}>
        <ATMGrid>
          <ATMGrid.Row columns={12}>
            <ATMGrid.Column width={4} />
            <ATMGrid.Column width={4} />
            <ATMGrid.Column width={3} />
            <ATMGrid.Column width={5}>
              <div className={style.workDescSearch}>
                <ATMInput
                  icon={workDesc ? 'close' : 'search'}
                  placeholder="Search work description"
                  clearable
                  value={workDesc}
                  action={{
                    onClick:
                      workDesc && workDesc.trim().length
                        ? () => {
                            setWorkDesc('');
                            handleWorkDescSearch('');
                          }
                        : undefined,
                  }}
                  onChange={(_, { value }) => {
                    setWorkDesc(value);
                    handleWorkDescSearch(value);
                  }}
                />
              </div>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </div>
      {state.matchedWorkDescList.length ? (
        state.matchedWorkDescList?.map((value, key) => (
          <>
            <SystemChangeItem key={key} data={value} />
          </>
        ))
      ) : (
        <>
          <Empty />
        </>
      )}
    </>
  );
};

export default DailyReportSystemChangeDetail;
