import React, { useCallback, useRef, useEffect, useState } from 'react';
import { camelCase } from 'lodash';
import { ATMButton, MOLRightPanel } from 'shared-it-appmod-ui';
import { useRolesContext } from 'src/contexts/roles.context';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import { useEmployeeContext } from 'src/contexts/employee.context';
import {
  IRolesPreForm,
  IRolesForm,
  IEmployeeRoleFilter,
  IRoleEmployeeItem,
} from 'src/models/roles.model';
import { IEmployee } from 'src/models/employee.model';
import { getRolesParams, getRolesStatus } from 'src/selectors/roles.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import RolesForm from '../roles-form/roles-form.component';
import styles from './roles-edit.module.scss';

type IProp = {
  data: IRoleEmployeeItem;
  onClose: boolean;
  setOnClose: () => void;
  filterState: Partial<IEmployeeRoleFilter>;
  setIsOpenCard: React.Dispatch<React.SetStateAction<boolean>>;
};

const RolesEdit: React.FC<IProp> = ({
  filterState,
  data,
  onClose,
  setOnClose,
  setIsOpenCard,
}) => {
  const { state, actions } = useRolesContext();
  const { state: employeeState } = useEmployeeContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [employee, setEmployee] = useState<IEmployee | undefined>();
  const [rolesIdState, setRolesIdState] = useState<Record<any, any>>();
  const status = getRolesStatus(state, rolesActionTypes.ROLES_DATA_UPDATE);

  useEffect(() => {
    const user = employeeState.list.find(
      (value) => data && value.emp_id === data.empId
    );
    setEmployee(user);
  }, [data, employeeState, setEmployee]);

  useEffect(() => {
    if (state.data) {
      const newObject = state.data.roles
        .map((element) => {
          return {
            [camelCase(element.role.contentAccess)]: Number(element.roleId),
          };
        })
        .reduce((r, c) => Object.assign(r, c), {});
      setRolesIdState({
        empId: data.empId ?? '',
        roleStatus: data.role.roleStatus,
        rolesId: {
          ...newObject,
        },
      });
    }
  }, [state, setRolesIdState]);

  const handleSearch = useCallback(
    (id) => {
      const user = employeeState.list.find((value) => value.emp_id === id);
      setEmployee(user);
    },
    [employeeState, setEmployee]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IRolesPreForm) => {
      const newFormData = {
        ...formData,
        rolesId: Object.values(formData.rolesId)
          .map((value) => Number(value))
          .filter((value) => value > 0),
      } as IRolesForm;
      const response = await actions.updatePUT(newFormData?.empId, {
        ...newFormData,
      });
      setEmployee(undefined);
      setRolesIdState(undefined);
      if (!response.error) {
        ToastSuccess(
          `Roles have been updated successfully for employee ${data?.fullName}`
        );
        if (filterState) {
          const params = getRolesParams({ limit: 0, page: 1 }, filterState);
          actions.listByEmpGET(params);
        }
      } else {
        ToastError('Error!');
      }

      setIsOpenCard(false);
      setOnClose();
    },
    [data, actions, setIsOpenCard, setOnClose]
  );

  return (
    <MOLRightPanel
      isOpen={onClose}
      onClose={setOnClose}
      trigger={
        <ATMButton
          type="button"
          content="Manage Roles"
          primary
          onClick={setOnClose}
        />
      }
      header={data ? `${data?.fullName} / ${data.empId}` : ''}
      panelContent={
        <>
          <RolesForm
            defaultValues={rolesIdState}
            handleSubmitEdit={handleSubmit}
            formRef={formRef}
            employee={employee}
            handleSearch={handleSearch}
            editRole
            editExistingEmp
            empName={data ? data.fullName : null}
            setOnClose={setOnClose}
          />
        </>
      }
      panelFooter={
        <div className={styles.panelFooter}>
          <ATMButton
            className={styles.marginRightSmall}
            secondary
            type="button"
            size="small"
            onClick={() => {
              setOnClose();
              setEmployee(undefined);
              setRolesIdState(undefined);
              setIsOpenCard(false);
            }}
            content="Cancel"
          />
          <ATMButton
            primary
            size="small"
            type="button"
            onClick={() => {
              handleClick();
            }}
            disabled={status.fetching}
            loading={status.fetching}
            content="Save"
          />
        </div>
      }
    />
  );
};

export default RolesEdit;
