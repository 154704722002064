import React, { useCallback, useState } from 'react';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { IFacType } from 'src/models/facility.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import EquipmentTypeDeleteView from './equipment-type-delete.view';

type IProp = {
  data: IFacType;
  editData: (value: boolean) => void;
};

const EquipmentTypeDelete: React.FC<IProp> = ({ data, editData }) => {
  const { state, actions } = useEquipmentTypeContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getEquipmentTypeStatus(
    state,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.facTypId) {
      const response = await actions.dataDELETE(data.facTypId);

      if (!response.error) {
        actions.listGET({ page: 1, limit: 50 });
        setIsOpen(false);
        editData(false);

        // check on backend
        // if (data.facTypLerAssoc) {
        ToastSuccess(`Equipment Type ${data.facTypNm} has been deleted`);
        // } else {
        //   // eslint-disable-next-line @typescript-eslint/quotes
        //   ToastError(`Equipment Type has LER's associated - Cannot Delete`);
        // }
      } else {
        ToastError(response.error.message);
      }
    }
  }, [actions, data]);

  return (
    <EquipmentTypeDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default EquipmentTypeDelete;
