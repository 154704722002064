import { httpClient } from 'src/libraries/http.library';
import {
  ITrainersForm,
  TrainersListPayloadSchema,
  TrainersDeletePayloadSchema,
  TrainersPayloadSchema,
} from 'src/models/trainers.model';

const client = httpClient();
const endpoint = 'trainer';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, TrainersPayloadSchema);
  },

  listGET: async (params?: any) => {
    return client.get(`/${endpoint}`, params, TrainersListPayloadSchema);
  },

  createPOST: async (data: ITrainersForm) => {
    return client.post(`/${endpoint}`, data, TrainersPayloadSchema);
  },

  updatePUT: async (id: number, data: ITrainersForm) => {
    return client.put(`/${endpoint}/${id}`, data, TrainersPayloadSchema);
  },

  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, TrainersDeletePayloadSchema);
  },
};

export default services;
