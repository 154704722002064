import React, { useState } from 'react';
import { ATMButton, ATMHeader } from 'shared-it-appmod-ui';
import { Breadcrumb } from 'semantic-ui-react';
import { IFacilityForm } from 'src/models/facility.model';
import Lang from 'src/libraries/language';
import FacilityForm from '../facility-form/facility-form.component';
import styles from './facility-add.module.scss';

type IProps = {
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleSubmit: (data: IFacilityForm) => void;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
};

const FacilityAddView: React.FC<IProps> = ({
  loading,
  formRef,
  handleClick,
  handleSubmit,
  setFacilityActions,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const sections = [
    {
      key: 'All Facilities',
      content: 'All Facilities >',
      link: true,
      onClick: () =>
        setFacilityActions({
          isNew: false,
          isDetails: false,
          isEdit: false,
        }),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <div className={styles.breadCrumbStyle}>
          <Breadcrumb icon="right angle" size="tiny" sections={sections} />
          <ATMHeader as="h1" style={{ marginTop: '0.5rem', paddingTop: 0 }}>
            {Lang.TTL_FACILITIES_ADD}
          </ATMHeader>
        </div>
        <div className={styles.actionButtons}>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() =>
              setFacilityActions({
                isNew: false,
                isDetails: false,
                isEdit: false,
              })
            }
          />
          <ATMButton
            primary
            content={Lang.LBL_SAVE}
            disabled={!isDirty || loading}
            onClick={() => {
              handleClick();
            }}
            loading={loading}
          />
        </div>
      </div>
      <FacilityForm
        formRef={formRef}
        handleSubmit={handleSubmit}
        handleEnable={(value) => setIsDirty(value)}
      />
      <div className={styles.actionButtons} style={{ marginTop: 15 }}>
        <ATMButton
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() =>
            setFacilityActions({
              isNew: false,
              isDetails: false,
              isEdit: false,
            })
          }
        />
        <ATMButton
          primary
          content={Lang.LBL_SAVE}
          disabled={!isDirty || loading}
          onClick={() => {
            handleClick();
          }}
          loading={loading}
        />
      </div>
    </>
  );
};

export default FacilityAddView;
