import React from 'react';
import {
  ATMSegment,
  ATMHeader,
  ATMGrid,
  ATMButton,
  ATMIcon,
  ATMMessage,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { IRolesByEmpId } from 'src/models/roles.model';
import { getUser } from 'src/libraries/amplify.library';
import { AccessRole } from 'src/constants';
import style from './home.module.scss';

type IProps = {
  roles: IRolesByEmpId[];
};

type IProp = {
  data: IRolesByEmpId;
  roleData: any;
  isOutageCalendar?: boolean;
};
const Content: React.FC<IProp> = ({
  data,
  roleData,
  isOutageCalendar = false,
}) => {
  return (
    <ATMGrid.Column>
      <ATMSegment className={style.roleBox}>
        <ATMGrid.Row>
          <ATMHeader size="small" className={style.roleBoxHeader}>
            <ATMIcon name={roleData.icon} color="blue" bordered />
            <ATMHeader.Content>
              {!isOutageCalendar
                ? data.role.contentAccess
                : Lang.TTL_OUTAGE_CALENDAR}
            </ATMHeader.Content>
          </ATMHeader>
        </ATMGrid.Row>
        <ATMGrid.Row title={roleData.description}>
          <p className={style.roleBoxText}>{roleData.description}</p>
        </ATMGrid.Row>
        <ATMGrid.Row>
          <div className={style.roleBoxActions}>
            <ATMButton
              basic
              color="blue"
              labelPosition="right"
              icon="right angle"
              content={Lang.LBL_VIEW}
              onClick={() => history.push(roleData.path)}
            />
          </div>
        </ATMGrid.Row>
      </ATMSegment>
    </ATMGrid.Column>
  );
};

// const version = /^(\d+.?\d+)/.exec(process.env?.REACT_APP_VERSION ?? '10.0');

const HomeView: React.FC<IProps> = ({ roles }) => {
  const user = getUser();
  if (roles.find((v) => v.roleId === AccessRole.MCC_LER_APPROVERS)) {
    history.push('/logout/unauthorized');
  }
  return (
    <div className={style.wrapper}>
      <ATMSegment className={style.segment}>
        <ATMHeader
          as="h1"
          content={`Welcome, ${user?.first_name} ${user?.last_name}!`}
        />
        <ATMMessage color="red">
          <span>This website contains Non-Public Transmission Information (NPTI). NPTI MUST NOT be shared with Sempra Energy companies or departments who engage in the sales for resale of gas or electricity, including SDG&E’s Energy Supply and Dispatch department. If you have questions regarding this information, please contact Affiliate Compliance at (858) 654-1840 or AffiliateComplianceSDGE@semprautilities.com.</span>
        </ATMMessage>
        <ATMGrid stackable columns={4}>
          <ATMGrid.Row>
            {roles.map((data) => {
              const roleData = Lang.ROLES_HOME_DATA[data.roleId || 0];
              return (
                roleData && (
                  <Content roleData={roleData} data={data} key={data.roleId} />
                )
              );
            })}
            {/* Outage Calendar handle multiple roles that can view the page */}
            {roles
              .filter(
                (val) => val.role.contentAccess === Lang.TTL_OUTAGE_PLANNING
              )
              .map((data) => {
                return (
                  <Content
                    roleData={Lang.ROLES_HOME_CALENDAR_DATA}
                    data={data}
                    key={data.roleId}
                    isOutageCalendar
                  />
                );
              })}
          </ATMGrid.Row>
        </ATMGrid>
        <div className={style.version}>
          <ATMHeader
            as="h3"
            size="small"
            // content={`v${version ? version[0] : '10'}`}
            content="v10.29"
          />
          <ATMHeader as="h3" size="small" content={Lang.MSG_HOME_PAGE_MANAGE} />
          <ATMHeader as="p" size="small" content={Lang.MSG_HOME_PAGE_NOTE} />
        </div>
      </ATMSegment>
    </div>
  );
};

export default HomeView;
