/* eslint-disable no-unneeded-ternary */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';
import { ATMCheckbox } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './filter-checkbox.component.module.scss';

type IProps = {
  name: string;
  content: string;
  outageAuthTypeList: (workGrpNm: number, checked: boolean) => void;
  valueId: number;
  value: boolean;
};

const OutageCheckbox: React.FC<IProps> = ({
  valueId,
  outageAuthTypeList,
  content,
  name,
  value,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value, setIsChecked]);

  return (
    <List.Item className={styles.checkboxListStatus}>
      <ATMCheckbox
        name={name}
        label={content}
        className={classNames(
          styles.checkboxStatus,
          styles[
            Lang.OUTAGE_TYPE_DESCRIPTION[valueId]
              ?.toLowerCase()
              .replace(/[\s-]+/g, '')
          ]
        )}
        checked={isChecked}
        onChange={(_, { checked }) => {
          setIsChecked(checked || false);
          if (checked !== undefined) {
            outageAuthTypeList(valueId, checked);
          }
          return checked;
        }}
      />
    </List.Item>
  );
};

export default React.memo(OutageCheckbox);
