import Lang from 'src/libraries/language';
import { createDateTime } from 'src/libraries/moment.library';
import yup from 'src/libraries/validator.library';
import { TestContext } from 'yup';
import moment from 'moment';
import { EquipmentTypeShape } from './equipment-type.model';
import { VoltShape } from './volt.model';
import { SortResponseSchema } from './switching.model';

export const IsoOutageTypeShape = {
  isoOutageTypeId: yup.number().required(),
  isoOutgTypeDesc: yup.string().required(),
  isoOutgTypeLevel: yup.string().required(),
  updatedBy: yup.string().required(),
  updatedAt: yup.date().required(),
  displayOrder: yup.number().required(),
};

export const EmployeeShapeTroubleJobShape = {
  empId: yup.string().nullable(),
  lastName: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  email: yup.string().nullable(),
};

export const TroubleJobsShape = {
  troubleJobId: yup.number().required(),
  outgFacId: yup.number().nullable(),
  voltId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  jobStatus: yup.string().required(),
  beginDate: yup.date().required(),
  endDate: yup.date().nullable(),
  jobDescription: yup.string().required(),
  primIsoOutgTypeId: yup.number().nullable(),
  secondIsoOutgTypeId: yup.number().nullable(),
  submittedBy: yup.string().required(),
  closedBy: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  primary: yup.object(IsoOutageTypeShape).nullable().default(null),
  secondary: yup.object(IsoOutageTypeShape).nullable().default(null),
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable()
    .default(null),
  facility: yup
    .object({
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
      volt: yup.object(VoltShape).nullable().default(null),
      facTyp: yup.object(EquipmentTypeShape).nullable().default(null),
    })
    .nullable()
    .default(null),
  sortResponses: yup.array().of(SortResponseSchema).nullable(),
  submitted: yup.object(EmployeeShapeTroubleJobShape).nullable(),
  closed: yup.object(EmployeeShapeTroubleJobShape).nullable(),
};

export const TroubleJobsSchema = yup.object(TroubleJobsShape);

export const IsoOutageTypeSchema = yup
  .array()
  .of(yup.object(IsoOutageTypeShape));

export const checkDate = (_value, context: TestContext) => {
  const {
    path,
    parent: {
      startTime,
      endTime,
      allDay,
      beginDate,
      endDate,
      onEdit,
      jobStatus,
    },
  } = context;
  let start;
  let end;
  if (beginDate && startTime) {
    start = createDateTime(beginDate, startTime);
  }
  if (endDate && endTime) {
    end = createDateTime(endDate, endTime);
  }
  if (allDay) {
    return true;
  }

  if (onEdit && jobStatus === 'Closed' && !endDate) {
    if (path.includes('endDate')) {
      return context.createError({
        message: 'This field is required',
      });
    }
  }

  if (onEdit && endDate && jobStatus === 'Closed' && !endTime) {
    if (path.includes('endTime')) {
      return context.createError({
        message: 'This field is required',
      });
    }
  }

  if (!endDate && endTime) {
    if (path.includes('endDate')) {
      return context.createError({
        message: 'Please select a date',
      });
    }
  }

  if (endDate && !endTime) {
    if (path.includes('endTime')) {
      return context.createError({
        message: 'Please enter time',
      });
    }
  }

  if (end && start) {
    if (path.includes('endDate') && start.isAfter(end)) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        return true;
      }
      return context.createError({
        message: 'Must be greater than the start date',
      });
    }
  }

  if (end && start) {
    if (path.includes('endTime') && end.isBefore(start)) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        if (moment(end).toDate().getTime() < moment(start).toDate().getTime()) {
          return context.createError({
            message: 'Must be greater than the start time',
          });
        }
      }
      return true;
    }
  }
  if (end && start) {
    if (start.isValid() && end.isValid()) {
      if (moment(start).toDate().getDate() === moment(end).toDate().getDate()) {
        if (
          moment(start).toDate().getTime() === moment(end).toDate().getTime()
        ) {
          if (path.includes('endTime')) {
            return context.createError({
              message: 'Must be greater than the start time',
            });
          }
        }
        return true;
      }
      return start.isBefore(end);
    }
  }

  return true;
};

export const TroubleJobsFormSchema = yup.object({
  troubleJobId: yup.number(),
  outgFacId: yup.number().required().typeError('This field is required'),
  equipmentType: yup.number().nullable().typeError('This field is required'),
  voltId: yup.number().nullable().typeError('This field is required'),
  substationId: yup
    .string()
    .nullable()
    .when('outgFacId', {
      is: (value) => !value,
      then: yup.string().required().typeError('This field is required'),
    }),
  jobStatus: yup.string().required(),
  beginDate: yup.date().required().typeError('This field is required'),
  endDate: yup
    .date()
    .nullable()
    .default(null)
    .when('jobStatus', {
      is: 'Closed',
      then: yup.date().required().typeError('This field is required'),
    })
    // .when('beginDate', (start, schema) =>
    //   start
    //     ? schema.min(
    //         Moment(start).startOf('day').toDate(),
    //         Lang.MSG_TROUBLE_JOB_END_DATE_ERROR
    //       )
    //     : undefined
    // ),
    .test('isValid', 'Invalid End Date', checkDate),
  startTime: yup
    .string()
    .required()
    // .when('onEdit', {
    //   is: 'true',
    //   then: yup
    //     .string()
    //     .required()
    //     .typeError(Lang.MSG_TROUBLE_JOB_END_DATE_REQUIRED),
    // })
    // .test('isValid', 'Invalid Start Time', checkDate)
    .typeError(Lang.MSG_ERROR_REQUIRED),
  endTime: yup
    .string()
    .nullable()
    .default(undefined)
    // .when('endDate', {
    //   is: (endDate) => endDate,
    //   then: yup.string().required(),
    // })
    .test('isValid', 'Invalid End Time', checkDate),
  jobDescription: yup.string().required().typeError('This field is required'),
  primIsoOutgTypeId: yup.number().nullable(),
  secondIsoOutgTypeId: yup.number().nullable(),
  submittedBy: yup.string().required(),
  closedBy: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  sortResponses: yup.array().of(SortResponseSchema).nullable(),
  submitted: yup.object(EmployeeShapeTroubleJobShape).nullable(),
  closed: yup.object(EmployeeShapeTroubleJobShape).nullable(),
  primary: yup.object(IsoOutageTypeShape).nullable().default(null),
  secondary: yup.object(IsoOutageTypeShape).nullable().default(null),
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable()
    .default(null),
  facility: yup
    .object({
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
      volt: yup.object(VoltShape).nullable().default(null),
      facTyp: yup.object(EquipmentTypeShape).nullable().default(null),
    })
    .nullable()
    .default(null),
  onEdit: yup.string().nullable(),
});

export const TroubleJobsListPayloadSchema = yup.object({
  count: yup.object({
    open: yup.number(),
    closed: yup.number(),
    all: yup.number(),
  }),
  rows: yup.array().of(TroubleJobsSchema.clone()),
});

export const TroubleJobsPayloadSchema = TroubleJobsSchema;

export const TroubleJobsDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IIsoOutageType = yup.Asserts<typeof IsoOutageTypeSchema>;
export type ITroubleJobs = yup.Asserts<typeof TroubleJobsSchema>;
export type ITroubleJobsForm = yup.Asserts<typeof TroubleJobsFormSchema>;
