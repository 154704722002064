import React, { useCallback, useState, useEffect } from 'react';
import { IATMDropdownProps } from 'shared-it-appmod-ui';
import { debounce } from 'lodash';
import { useEmployeeContext } from 'src/contexts/employee.context';
import {
  getEmployeeOptions,
  getEmployeeStatus,
  getParsedEmployee,
} from 'src/selectors/employee.selector';
import { employeeActionTypes } from 'src/ducks/employee.duck';
import { IEmployee } from 'src/models/employee.model';
import { getEmployeeSort } from 'src/helpers/employee.helper';
import DropdownEmployeeView from './dropdown-employee.view';

export type IProps = Omit<IATMDropdownProps, 'value'> & {
  value?: Required<IEmployee['emp_id'] | IEmployee['emp_id'][]>;
  internal?: boolean;
  valueField?: keyof IEmployee;
  onLoad?: (data: IEmployee | IEmployee[]) => void;
  filter?: (data: IEmployee[]) => IEmployee[];
  vendorName?: string;
  resourceStatus?: string;
  selectedValue?: string;
};

const DropdownEmployee: React.FC<IProps> = ({
  onChange,
  valueField = 'emp_id', // This will allow you to select what field you want to be the value
  onLoad,
  filter,
  vendorName,
  resourceStatus,
  selectedValue,
  ...props
}) => {
  const { multiple, value: propsValue } = props;
  const { state, actions } = useEmployeeContext();

  const [options, setOptions] = useState<Partial<IEmployee>[]>([]);
  const [selected, setSelected] = useState<Partial<IEmployee>[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<IProps['value']>(
    propsValue || multiple ? [] : undefined
  );

  const status = getEmployeeStatus(
    state,
    employeeActionTypes.EMPLOYEE_USER_FETCH
  );

  // Clear all options if new vendor
  useEffect(() => {
    setOptions([]);
    setSelected([]);
  }, [vendorName, setOptions, setSelected]);

  // Clear all selected if no selectedValue
  useEffect(() => {
    if (selectedValue === undefined || selectedValue === '') {
      setOptions([]);
      setSelected([]);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (propsValue) {
      const fetchEmployee = async (values) => {
        setLoading(true);

        const result = await actions.usersPOST(
          {
            employeeNos: values,
            eMailIds: [],
            firstNames: [],
          },
          !!vendorName,
          vendorName
        );

        if (result && result.payload) {
          let list = getEmployeeSort(
            (result.payload ?? []).map((employee) =>
              getParsedEmployee(employee)
            ),
            'last_name'
          );

          if (typeof filter === 'function') {
            list = filter(list);
          }

          setSelected(list);
          setOptions(list);

          if (onLoad) {
            onLoad(multiple ? list : list[0]);
          }
        }

        setLoading(false);
      };

      setValue(propsValue);

      if (!loading && !selected.length && propsValue.length && !status.error) {
        fetchEmployee(multiple ? propsValue : [propsValue]);
      }
    }
  }, [
    vendorName,
    propsValue,
    multiple,
    actions,
    selected,
    setLoading,
    setValue,
    setSelected,
    setOptions,
    onLoad,
  ]);

  const handleSearch = useCallback(
    debounce(async (keyword: string) => {
      setLoading(true);
      let list: IEmployee[] = [];

      const result = await actions?.searchGET(
        keyword,
        !!vendorName,
        vendorName,
        resourceStatus
      );

      if (result && result.payload) {
        list = getEmployeeSort(
          (result.payload ?? []).map((employee) => getParsedEmployee(employee)),
          'last_name'
        );

        if (typeof filter === 'function') {
          list = filter(list);
        }
      }

      if (list.length) {
        setSelected((items) => {
          setOptions([...items, ...list]);

          return items;
        });
      }

      setLoading(false);

      return list;
    }, 300),
    [
      vendorName,
      resourceStatus,
      actions,
      filter,
      setLoading,
      setSelected,
      setOptions,
    ]
  );

  const handleChange = useCallback(
    (e, data) => {
      let values;

      if (multiple) {
        values = data.value.map((id) =>
          options.find((v) => v[valueField] === id)
        );

        setSelected(values);
      } else {
        values = options.find((v) => v[valueField] === data.value);

        if (values) {
          setSelected([values]);
        }
      }

      setValue(data.value);

      if (onChange) {
        onChange(e, data, values);
      }
    },
    [setValue, setSelected, onChange, multiple, options, valueField]
  );

  const optionList = [...selected, ...options].filter(Boolean);

  return (
    <DropdownEmployeeView
      {...props}
      loading={loading}
      onChange={handleChange}
      defaultValue={value}
      value={value}
      onSearchChange={(e) => {
        const keyword = e.target.value.trim();
        if (keyword) {
          handleSearch(keyword);
        }
      }}
      options={getEmployeeOptions(
        Array.from(new Set(optionList.map((a) => a.emp_id))).map((id) =>
          optionList.find((a) => a.emp_id === id)
        ) as Partial<IEmployee>[],
        valueField
      )}
    />
  );
};

export default DropdownEmployee;
