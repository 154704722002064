import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'src/history';

// For change page only
const useLocationTab = (name, path?: string) => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const currentTab: any = params.get(name) ?? 0;
  const pathname = path || location.pathname;

  const handleTabChange = useCallback(
    (index?: any, callback?: (data: URLSearchParams) => URLSearchParams) => {
      // Delete table URL params
      // params.delete('page');
      // params.delete('limit');
      // params.delete('filters');

      if (callback) {
        params = callback(params);
      }

      // if (index !== undefined) {
      //   params.set(name, index);
      // } else {
      //   params.delete(name);
      // }

      const url = index ? `${pathname}?${name}=${index}` : `${pathname}`;
      history.push(url);

      setImmediate(() => {
        window.scrollTo(0, 0);
      });
    },
    [location]
  );

  return { currentTab, pathname, params, handleTabChange };
};

export const useLocationParams = <T extends string>(
  list: T[],
  path?: string
) => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const params = useMemo(
    () =>
      list.reduce(
        (a, i) => ({ ...a, [i]: searchParams.get(i) }),
        {} as Record<T, any>
      ),
    [searchParams]
  );
  const pathname = path || location.pathname;
  const handleChange = useCallback(
    (
      values?: Partial<Record<T, any>>,
      callback?: (data: URLSearchParams) => void,
      replace = false
    ) => {
      searchParams.delete('page');

      if (values !== undefined) {
        Object.entries(values).forEach(([key, value]) => {
          if (value !== undefined) {
            searchParams.set(key, value as any);
          } else {
            searchParams.delete(key);
          }
        });
      } else {
        list.map((key) => searchParams.delete(key));
      }

      const url = `${pathname}?${searchParams.toString()}`;

      if (replace) {
        history.replace(url);
      } else {
        history.push(url);
      }

      if (callback) {
        callback(searchParams);
      }
    },
    [searchParams, pathname, list]
  );

  const getUrl = useCallback(
    (values?: Partial<Record<T, any>>) => {
      const search = new URLSearchParams(location.search);
      search.delete('page');

      if (values !== undefined) {
        Object.entries(values).forEach(([key, value]) => {
          if (value !== undefined) {
            search.set(key, value as any);
          } else {
            search.delete(key);
          }
        });
      } else {
        list.map((key) => search.delete(key));
      }

      return `${pathname}?${search.toString()}`;
    },
    [location, pathname, list]
  );

  return { params, pathname, searchParams, getUrl, handleChange };
};

export default useLocationTab;
