import { isEqual } from 'date-fns';
import Moment, {
  IMoment,
  dateInEventRange,
} from 'src/libraries/moment.library';
import { ILerRequestOutageDate, IPeriod } from 'src/models/ler-request.model';
import { IOutageDateCalendar } from 'src/models/calendar-outage.model';
import {
  ICalendarEvent,
  IEventSegment,
  eventLevels,
  eventSegments,
  sortEvents,
} from 'src/libraries/calendar.library';

export const isContinuousOutageDates = (
  date: Partial<ILerRequestOutageDate>,
  nextDate: Partial<ILerRequestOutageDate>
) => {
  return isEqual(date.stopTm as Date, nextDate.startTm as Date);
};

export const sortOutageDates = (
  outageDates: Partial<ILerRequestOutageDate>[]
): Partial<ILerRequestOutageDate>[] => {
  // sort outage dates by start tm in ascending order
  outageDates.sort((outageDate1, outageDate2) => {
    return (
      new Date(outageDate1.startTm as Date).getTime() -
      new Date(outageDate2.startTm as Date).getTime()
    );
  });

  return outageDates;
};

export const getOutageDatePeriods = (
  outageDates: Partial<ILerRequestOutageDate>[]
): IPeriod[] => {
  const periods: IPeriod[] = [];

  // eslint-disable-next-line no-param-reassign
  outageDates = sortOutageDates(outageDates);

  // early return a empty period list if outage dates is empty
  if (outageDates.length === 0) {
    return [];
  }

  // set start tm and stop tm initial values
  const outageDate = outageDates[0];
  let { startTm } = outageDate;
  let { stopTm } = outageDate;

  // if the outage dates list has a single item
  // push the start and stop tm to periods list and then early return
  if (outageDates.length === 1) {
    periods.push({
      start: startTm as Date,
      end: stopTm as Date,
    });
    return periods;
  }

  // if outage dates list size is greater than 1
  // eslint-disable-next-line @typescript-eslint/no-shadow
  outageDates.forEach((outageDate, index) => {
    // check if has next outage date
    const hasNextOutageDate = index < outageDates.length - 1;
    if (hasNextOutageDate) {
      const nextOutageDate = outageDates[index + 1];
      // check if the next outage date is continous
      const continousOutageDates = isContinuousOutageDates(
        outageDate,
        nextOutageDate
      );

      if (!continousOutageDates) {
        // set the stop tm with the value of outage date's stop tm
        stopTm = outageDate.stopTm;
        // push the start and stop tm to periods list
        periods.push({
          start: startTm as Date,
          end: stopTm as Date,
        });
        // set the start tm with the value of next outage date's start tm
        startTm = nextOutageDate.startTm;
      }
    } else {
      // when last index of outage dates is reached,
      // set the stop tm with the value of outage date's stop tm
      stopTm = outageDate.stopTm;
      // then push the item to the period list
      periods.push({
        start: startTm as Date,
        end: stopTm as Date,
      });
    }
  });

  return periods;
};

export const isOutageDateContinuous = (data: ILerRequestOutageDate[]) => {
  if (!data.length) {
    return false;
  }

  const list = sortOutageDates(data);
  let isContinuations = false;

  for (let i = 0; i < list.length; i++) {
    if (
      list[i + 1] &&
      Moment(list[i].stopTm).isSame(Moment(list[i + 1].startTm))
    ) {
      isContinuations = true;
      break;
    }

    i++;
  }

  return isContinuations;
};

export type ICalendarResource = {
  title:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
  subTitle?:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
  resourceId: any;
  children?: ICalendarResource[];
  besInd?: string;
  parentId?: any;
  levels?: IEventSegment<ICalendarEvent<IOutageDateCalendar>>[][];
  significantCost?: string;
  specialConcerns?:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
};

export const getOutageCalendarEvents = (
  resources: ICalendarResource[],
  events: ICalendarEvent<IOutageDateCalendar>[],
  calendar: IMoment[]
) => {
  return resources.reduce((items: ICalendarResource[], value) => {
    return [
      ...items,
      {
        resourceId: value.resourceId,
        title: value.title,
        subTitle: value,
      },
      ...(value.children || []).map((val) => {
        return {
          parentId: value.resourceId,
          resourceId: val.resourceId,
          title: val.title,
          subTitle: value.title || value.subTitle,
          significantCost: value.significantCost,
          specialConcerns: val.specialConcerns,
          besInd: val.besInd,
          levels: (() => {
            const data = events.filter(
              (v) => v.data?.requestFacility.outgFacId === val.resourceId
            );

            const weekEvents = [...data].filter((v) =>
              dateInEventRange({
                event: {
                  start: v.start,
                  end: v.end,
                },
                range: {
                  start: calendar[0],
                  end: calendar[calendar.length - 1],
                },
              })
            );

            const segments = weekEvents
              .sort((a, b) => sortEvents(a, b))
              .map((evt) => eventSegments(evt, calendar));

            const { levels } = eventLevels(
              [...segments],
              Math.max(Infinity, 1)
            );

            return levels;
          })(),
        };
      }),
    ];
  }, []);
};
