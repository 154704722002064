import yup from 'src/libraries/validator.library';

export const EntryReasonShape = {
  entryReasonId: yup.number().required(),
  description: yup.string(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const EntryReasonSchema = yup.object(EntryReasonShape);

export const EntryReasonFormSchema = yup.object({
  name: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const EntryReasonListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(EntryReasonSchema.clone()),
});

export const EntryReasonPayloadSchema = yup.object({
  entryReason: EntryReasonSchema.clone(),
});

export const EntryReasonDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IEntryReason = yup.Asserts<typeof EntryReasonSchema>;
export type IEntryReasonForm = yup.Asserts<typeof EntryReasonFormSchema>;
