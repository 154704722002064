import { httpClient } from 'src/libraries/http.library';
import {
  // IEntryReasonForm,
  EntryReasonListPayloadSchema,
  // EntryReasonDeletePayloadSchema,
  EntryReasonPayloadSchema,
} from 'src/models/entry-reason.model';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

const client = httpClient();
const endpoint = 'entry-reason';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, EntryReasonPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    return client.get(`/${endpoint}`, params, EntryReasonListPayloadSchema);
  },
};

export default services;
