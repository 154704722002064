import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IWorkGroupForm } from 'src/models/work-group.model';
import WorkGroupForm from '../work-group-form/work-group-form.component';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IWorkGroupForm) => void;
};

const WorkGroupAddView: React.FC<IProps> = ({
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      onClose={() => handleOpen(false)}
      trigger={
        <ATMButton
          primary
          size="small"
          icon="plus"
          floated="right"
          content={Lang.LBL_NEW}
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>{Lang.LBL_ADD_WORK_GROUP}</ATMModal.Header>
      <ATMModal.Content>
        <WorkGroupForm
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default WorkGroupAddView;
