import React, { useCallback, useEffect } from 'react';
import {
  ATMCheckbox,
  ATMDivider,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSegment,
  ATMTable,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import { ILerRequestJobInformation } from 'src/models/ler-request.model';
import { AccessRole, LERRequestFormStep } from 'src/constants';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { hasRole } from 'src/libraries/access.library';
import LerRequestAddDocumentsDetail from '../ler-request-documents/ler-request-add-documents-detail.component';
import styles from './ler-request-job-information.module.scss';

type IProps = {
  data?: ILerRequestJobInformation;
  handleEdit?: () => void;
  isView?: boolean;
};

enum ShortDescription {
  Category = 'category',
  Description = 'description',
}

const LERRequestJobInformation: React.FC<IProps> = ({
  isView = false,
  data,
  handleEdit,
}) => {
  const { state, actions } = useShortDescriptionContext();
  const getShortDescriptionValue = useCallback(
    (shortDesc: ShortDescription, id: number) => {
      let desc = '';
      if (shortDesc === ShortDescription.Category) {
        state?.categories.filter((val) => {
          if (id === val.catId) {
            desc = val.shortDescCat;
          }
        });
      } else {
        state?.list.filter((val) => {
          if (id === val.descId) {
            desc = val.shortDesc;
          }
        });
      }
      return desc;
    },
    [state, actions]
  );

  useEffect(() => {
    const getDescriptionList = async () => {
      if (!(state.categories.length || state.list.length)) {
        await actions?.listGET();
      }
    };
    getDescriptionList();
  }, [data]);

  if (!data) {
    return null;
  }

  const header = handleEdit ? (
    <ATMHeader as="h2" attached="top" block>
      {Lang.TTL_JOB_INFO}
      <a className={styles.edit} onClick={handleEdit} role="button">
        <ATMIcon name="edit" size="small" />
        {Lang.LBL_EDIT}
      </a>
    </ATMHeader>
  ) : (
    <>
      <span>
        <ATMIcon circular name="clipboard outline" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_JOB_INFO}
        className={styles.header}
      />
    </>
  );

  const jobInfoDetails = (
    <>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_DOCUMENT_NO}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_SHORT_DESCRIPTION_CATEGORY}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_SHORT_DESCRIPTION}
            </ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data?.shortDescriptions && data?.shortDescriptions.length > 0 ? (
            data?.shortDescriptions?.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{i + 1}</ATMTable.Cell>
                <ATMTable.Cell>
                  {getShortDescriptionValue(
                    ShortDescription.Category,
                    item.lerShortDescCatId
                  ) ?? ''}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {getShortDescriptionValue(
                    ShortDescription.Description,
                    item.lerShortDescId
                  ) ?? ''}
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell />
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
      {isView ? (
        <>
          <ATMHeader as="h2" content={Lang.LBL_LER_DOCUMENTS} />
          <LerRequestAddDocumentsDetail tab={LERRequestFormStep.REVIEW} />
          <ATMDivider />
        </>
      ) : (
        ''
      )}
    </>
  );

  const content = (
    <>
      {!hasRole([
        AccessRole.MCC_SUBMITTING_SUPERVISOR,
        AccessRole.MCC_OUTAGE_REQUESTOR,
        AccessRole.MCC_OPERATIONAL_ENGINEER,
      ]) && (
        <>
          <ATMHeader
            as="h2"
            content={Lang.TTL_SWITCH_IN_OUT_INFORMATION}
            style={{ color: 'rgba(0,0,0,0.6)' }}
          />
          <ATMGrid columns="2">
            <ATMGrid.Column width={4}>
              <FieldReadOnly label={Lang.LBL_SWITCH_OUT}>
                {data.switchOutMin}
              </FieldReadOnly>
            </ATMGrid.Column>
            <ATMGrid.Column width={4}>
              <FieldReadOnly label={Lang.LBL_SWITCH_IN}>
                {data.switchInMin}
              </FieldReadOnly>
            </ATMGrid.Column>
          </ATMGrid>
        </>
      )}

      <ATMHeader as="h2" content={Lang.TTL_JOB_DETAILS} />
      {jobInfoDetails}

      <ATMGrid columns={3}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_WORK_ORD_NO}>
              {data.woNbr}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_PROJECT_ID}>
              {data.projectId}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column className={styles.workDescWordWrap}>
            <FieldReadOnly label={Lang.LBL_WORK_DESC}>
              {data.wrkDesc}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_ISOLATION_POINTS}>
              {data.isolationPoints}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_JOB_LOC}>
              {data.jobLoc}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_JOB_NOTES}>
              {data.jobNotes}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_RADIALIZED_SUBS}>
              {data.radializedSubs?.map((v) => v.substationId).join(',')}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_RIMS_PROJECT_ID}>
              {data.rimsProjectId}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_RIMS_PROJECT_PHASE}>
              {data.rimsProjectPhase}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_TB_COORDINATE}>
              {data.facTbsCoord}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_RECALL_TIME}>
              {data.recalTm}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_CONTINGENCY_PLAN_DEVELOPED}>
              {data.contingencyPlanInd ? Lang.LBL_YES : Lang.LBL_NO}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_GROUND_STATEMENT}>
              {data.clsdGrndDsconctInd ? Lang.LBL_YES : Lang.LBL_NO}
            </FieldReadOnly>
          </ATMGrid.Column>

          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_TEST_VOLTAGE}>
              {data.testVoltageApplied ? Lang.LBL_YES : Lang.LBL_NO}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>

      <ATMGrid columns="2">
        <ATMGrid.Column width={8}>
          <ATMCheckbox
            name="jobInformation.inclmntWthrInd"
            label={Lang.LBL_WORK_PERMISSION}
            checked={data.inclmntWthrInd ?? false}
            disabled
          />
          <ATMCheckbox
            name="jobInformation.phsReqInd"
            label={Lang.LBL_PHASING_REQ}
            checked={data.phsReqInd ?? false}
            disabled
          />
          <ATMCheckbox
            name="jobInformation.affectsRasSpsInd"
            label={Lang.LBL_RAS_SPS}
            checked={data.affectsRasSpsInd ?? false}
            disabled
          />
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );

  return (
    <div>
      {header}
      {handleEdit ? (
        <ATMSegment attached="bottom">{content}</ATMSegment>
      ) : (
        content
      )}
    </div>
  );
};

export default React.memo(LERRequestJobInformation);
