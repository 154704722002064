import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  JobCodeRuleTab,
  JobCodeStatus,
} from 'src/constants/job-code-rule.contants';
import {
  jobCodeRuleActionTypes,
  IJobCodeRuleState,
} from 'src/ducks/job-code-rule.duck';
import { IStatus } from 'src/libraries/thunk.library';
import { IJobTitles, IJobTitlesSearch } from 'src/models/job-code-rule.model';

export const getJobCodeRuleStatus = (
  state: IJobCodeRuleState,
  action: keyof typeof jobCodeRuleActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getJobCodeOptions = (
  data: Partial<IJobTitles>[],
  valueField: keyof IJobTitles = 'jobCd'
) =>
  data.map((value) => ({
    key: value.jobCd,
    text: `${value.jobTitle} (${value.jobCd}) `,
    value: value[valueField],
  }));

export const getParsedJob = (job: IJobTitlesSearch): IJobTitles => ({
  jobCd: job.JobCode,
  jobTitle: job.JobTitle,
  jobDescription: job.JobDescription,
  jobStatus: job.JobStatus,
  companyID: job.CompanyID,
  companyName: job.CompanyName,
  // salaryGradeCd: job.SalaryGradeCode,
  // safetySensitive: job.SafetySensitive,
});

export const getJobCodeRuleParams = (
  params: IORGDataTableQueryState,
  currentTab?: JobCodeRuleTab
): Partial<IORGDataTableQueryState> => {
  const data = params;

  switch (currentTab) {
    case JobCodeRuleTab.Active: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'jobCdStat',
          value: JobCodeStatus.ACTIVE,
        },
      ];
      break;
    }

    case JobCodeRuleTab.Inactive: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'jobCdStat',
          value: JobCodeStatus.INACTIVE,
        },
      ];
      break;
    }

    default: {
      break;
    }
  }

  return data;
};
