import React, { useCallback } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useTrainersContext } from 'src/contexts/trainers.context';
import { trainersActionTypes } from 'src/ducks/trainers.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { getTrainersStatus } from 'src/selectors/trainers.selector';
import TrainersListView from './trainers-list.view';

const TrainersList: React.FC = () => {
  const { state, actions } = useTrainersContext();
  const status = getTrainersStatus(
    state,
    trainersActionTypes.TRAINERS_LIST_READ
  );

  const handleFetch = useCallback(
    (params) => {
      actions.listGET({ ...params, limit: 0 });
    },
    [actions]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState, trainerStatus: string) => {
      const result = await actions.listGET({ ...params, limit: 0 });
      if (result.payload?.rows) {
        const list = result.payload.rows.filter(
          (val) => val.trainerStat === trainerStatus
        );
        const items = list.map((value) => [
          checkValue(value.trainer.fullName),
          checkValue(value.empId),
          checkValue(value.trainer.email),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_TRAINER_NAME,
            Lang.LBL_EMPLOYEE_NUMBER,
            Lang.LBL_EMPLOYEE_EMAIL,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_Trainers_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  return (
    <TrainersListView
      data={state.list.filter((elem) => elem.trainer.lastName !== null)}
      // loading={!state.list.length && status.fetching}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
    />
  );
};

export default TrainersList;
