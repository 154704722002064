import React, { useCallback } from 'react';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { workGroupActionTypes } from 'src/ducks/work-group.duck';
import { getWorkGroupStatus } from 'src/selectors/work-group.selector';
import WorkGroupListView from './work-group-list.view';

const WorkGroupList: React.FC = () => {
  const { state, actions } = useWorkGroupContext();
  const status = getWorkGroupStatus(
    state,
    workGroupActionTypes.WORK_GROUP_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listGET({ limit: 0, page: 1 });
  }, [actions]);

  return (
    <WorkGroupListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
    />
  );
};

export default WorkGroupList;
