import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import DailyReportsReducer, {
  IDailyReportsState,
  defaultState,
  duckActions,
} from 'src/ducks/daily-reports.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    DailyReportsReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IContractorContext = ReturnType<typeof useReducer>;

const DailyReportsContext = createContext<Partial<IContractorContext>>({
  state: defaultState,
}) as React.Context<IContractorContext>;

type IProps = {
  state?: Partial<IDailyReportsState>;
};

const DailyReportsProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <DailyReportsContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </DailyReportsContext.Provider>
  );
};

const useDailyReportsContext = () => useContext(DailyReportsContext);

export type IUseContractorContext = ReturnType<typeof useDailyReportsContext>;

export { DailyReportsContext, DailyReportsProvider, useDailyReportsContext };
