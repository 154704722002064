import React from 'react';
import classNames from 'classnames';
import { ICalendarResource } from 'src/helpers/outage-dates.helper';
import { IMoment } from 'src/libraries/moment.library';
import CalendarEvent from '../calendar-event/calendar-event.component';
import styles from './calendar-timeline.module.scss';

type IProps = {
  value: ICalendarResource;
  calendar: IMoment[];
};

export const CELL_SIZE = 25;

const CalendarTimelineCell: React.FC<IProps> = ({ value, calendar }) => {
  return (
    <tr>
      <td
        className={classNames({
          [styles.cellEmpty]: !value.parentId,
        })}
      >
        <div style={{ minHeight: CELL_SIZE }} className={styles.laneFrame}>
          {(value.levels || []).map((val, i) => {
            return (
              <CalendarEvent
                key={`${value.resourceId}_${calendar[0].isoWeek()}_${i}`}
                events={val}
                dayRange={calendar}
                className={styles.event}
              />
            );
          })}
        </div>
      </td>
    </tr>
  );
};

export default React.memo(CalendarTimelineCell);
