import React from 'react';
import {
  MOLNoData,
  ORGDataTable,
  IORGDataTableColumn,
  ATMHeader,
} from 'shared-it-appmod-ui';
import { dailyReportsActionTypes } from 'src/ducks/daily-reports.duck';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import { IRadialized } from 'src/models/daily-reports.model';
import { getDailyReportsStatus } from 'src/selectors/daily-reports.selector';
import Lang from 'src/libraries/language';
import { Link } from 'react-router-dom';
import { LERRequestPage } from 'src/constants';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import style from '../daily-reports.module.scss';
import DailyReportRadializedDownloader from '../daily-report-export/daily-report-radialized-downloader.component';

type IProps = {
  data: IRadialized[];
  handleFetch?: (type: string) => void;
};

const RadializedSubstationsValueSplit = ({ value }) => {
  const rs: any[] = value ?? [];
  const details: any = [];
  rs.map((val) => {
    details.push(<p>{val}</p>);
  });

  return <>{details}</>;
};

const RadializedSubstationsSplit = ({ value }) => {
  const rs: any[] = value ?? [];
  const substationIdLinks: any = [];
  rs.map((val) => {
    const urlVal = val.split('#');
    substationIdLinks.push(
      <p key={urlVal[0]}>{urlVal.length > 0 ? urlVal[0] : ''}</p>
    );
  });
  return <>{substationIdLinks}</>;
};

const RadializedSubstationsTable: React.FC<IProps> = () => {
  const { state } = useDailyReportsContext();
  const { getUrl } = useLocationParams([
    LERRequestPage.View,
    LERRequestPage.Edit,
  ]);
  const { fetching } = getDailyReportsStatus(
    state,
    dailyReportsActionTypes.DAILY_REPORTS_RADIALIZED_LIST
  );
  const columns: IORGDataTableColumn<any>[] = [
    {
      index: 'subs',
      title: 'Subs Radialized',
      render: (_, value) => <RadializedSubstationsSplit value={value.subs} />,
    },
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (value) => (
        <Link to={getUrl({ [LERRequestPage.View]: value })}>{value}</Link>
      ),
    },
    {
      index: 'start_time',
      title: 'Start Time',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.start_time} />
      ),
    },
    {
      index: 'stop_time',
      title: 'Stop Time',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.stop_time} />
      ),
    },
    {
      index: 'etr',
      title: 'ETR',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.etr} />
      ),
    },
    {
      index: 'voltage',
      title: 'Voltage',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.voltage} />
      ),
    },
    {
      index: 'request_facility',
      title: 'Request Facility',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.request_facility} />
      ),
    },
    {
      index: 'work_description',
      title: 'Work Description',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.work_description} />
      ),
    },
    {
      index: 'recall_time',
      title: 'Recall Time (Hr)',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.recall_time} />
      ),
    },
    {
      index: 'contingency_plan',
      title: 'Contingency Plan',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.contingency_plan} />
      ),
    },
    {
      index: 'count',
      title: 'Cust. Count',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.count} />
      ),
    },
    {
      index: 'tsaidi',
      title: 'Pos. T.SAIDI Imp.',
      render: (_, value) => (
        <RadializedSubstationsValueSplit value={value.tsaidi} />
      ),
    },
  ];
  return (
    <div>
      <div className={style.gridBorder}>
        <DailyReportRadializedDownloader fileData={state.radializedList} />
        <br />
        <br />
      </div>

      <ORGDataTable
        columns={columns}
        data={state.radializedList}
        noDataText={
          <MOLNoData
            icon="search"
            header="Search to view records"
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
        loading={fetching}
        celled={false}
        showPagination={false}
      />
      <br />
      <ATMHeader as="h3" floated="right">
        {state.totalSaidiImpact
          ? Lang.formatString(
              Lang.MSG_RADIALIZED_SUB_TOTAL_SAIDI,
              state.totalSaidiImpact
            )
          : Lang.formatString(Lang.MSG_RADIALIZED_SUB_TOTAL_SAIDI, 0)}
      </ATMHeader>
    </div>
  );
};

export default RadializedSubstationsTable;
