import yup from 'src/libraries/validator.library';

export const EmployeeSchema = yup
  .object({
    lan_user_id: yup.string(),
    emp_id: yup.string().required(),
    contactType_id: yup.number(),
    contactType: yup.string(),
    cell_phone: yup.string().nullable(),
    first_name: yup.string(),
    middle_name: yup.string().nullable(),
    last_name: yup.string(),
    company_id: yup.string(),
    company_name: yup.string(),
    job_title: yup.string(),
    e_mail_address: yup.string().email().nullable(),
    dept_org_desc: yup.string(),
    work_phone_1: yup.string().nullable(),
    vendor_name: yup.string().optional(),
    mgr_first_name: yup.string(),
    mgr_empno: yup.string(),
    mgr_last_name: yup.string(),
    mailstop_id: yup.string(),
    job_code: yup.string().nullable(),
  })
  .defined();

export const EmployeeShape = {
  empId: yup.string().required(),
  fullName: yup.string().nullable(),
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  title: yup.string().required(),
  supervisorNumber: yup.string().nullable(),
  workAreaCode: yup.string().nullable(),
  workPhoneNumber: yup.string().nullable(),
  mailLoc: yup.string().nullable(),
  email: yup.string().nullable(),
  ccCd: yup.string().nullable(),
  roleId: yup.number().nullable(),
  modemUsrFlg: yup.string().nullable(),
  contracShortNm: yup.string().nullable(),
  employeeStat: yup.string().required(),
  cellAreaCode: yup.string().nullable(),
  cellPhoneNumber: yup.string().nullable(),
  orgCd: yup.string().nullable(),
  priorSupvrNbr: yup.string().nullable(),
  jobCd: yup.string().nullable(),
  tempActiveInd: yup.string().nullable(),
  employeeType: yup.string().nullable(),
  mgrFirstname: yup.string(),
  mgrEmpno: yup.string(),
  mgrLastName: yup.string(),
  mailstopId: yup.string(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const EmployeeSimpleShape = {
  empId: yup.string().required(),
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  email: yup.string().required(),
};

export const EmployeePayloadShape = {
  ...EmployeeShape,
  supvr: yup.object(EmployeeSimpleShape).nullable(),
};

export const EmployeeListSchema = yup.array().of(EmployeeSchema).defined();

export const EmployeeSearchSchema = yup
  .object({
    EMPNO: yup.string(),
    FIRST_NAME: yup.string(),
    LAST_NAME: yup.string(),
    E_MAIL_ADDRESS: yup.string().nullable(),
    JOB_TITLE: yup.string().nullable(),
    COMPANY_NAME: yup.string().nullable(),
    DEPT_ORG_DESC: yup.string().nullable(),
    WORK_PHONE_1: yup.string().nullable(),
    CELL_PHONE: yup.string().nullable(),
    COMPANY_ID: yup.string(),
    VENDOR_NAME: yup.string().optional(),
    MGR_FIRST_NAME: yup.string(),
    MGR_EMPNO: yup.string(),
    MGR_LAST_NAME: yup.string(),
    MAILSTOP_ID: yup.string(),
    JOB_CODE: yup.string().nullable(),
  })
  .defined();

export const EmployeeSearchPayloadSchema = yup
  .array()
  .of(EmployeeSearchSchema)
  .defined();

export const EmployeeSearchFormSchema = yup
  .object({
    employeeNos: yup.array().of(yup.string()),
    eMailIds: yup.array().of(yup.string()),
    firstNames: yup.array().of(yup.string()),
  })
  .defined();

export const EmployeeUsersPayloadSchema = yup
  .object({
    count: yup.number(),
    rows: yup.array().of(EmployeeSearchSchema).defined(),
  })
  .defined();

export const EmployeeInternalSchema = yup
  .object({
    empId: yup.string(),
    empno: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    middleName: yup.string().nullable(),
    emailAddress: yup.string().nullable(),
    companyName: yup.string().nullable(),
    departmentName: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    cellPhone: yup.string().nullable(),
    mgrFirstName: yup.string().nullable(),
    mgrEmpno: yup.string().nullable(),
    mgrLastName: yup.string().nullable(),
    mailstopId: yup.string().nullable(),
    jobCode: yup.string().nullable(),
  })
  .defined();

export const EmployeeSearchInternalPayloadSchema = yup
  .object({
    employees: yup.array().of(EmployeeInternalSchema.clone()).defined(),
  })
  .defined();

export const EmployeesDetailsSchema = yup
  .object({
    count: yup.number().optional(),
    rows: yup
      .array()
      .of(
        yup
          .object({
            EMPNO: yup.string(),
            FIRST_NAME: yup.string(),
            MIDDLE_NAME: yup.string().nullable(),
            LAST_NAME: yup.string(),
            COMPANY_NAME: yup.string(),
            CELL_PHONE: yup.string().nullable(),
            PERS_STATUS: yup.string().nullable(),
            ORG_CODE: yup.string(),
            JOB_CODE: yup.string().nullable(),
            JOB_TITLE: yup.string(),
            MAILSTOP_ID: yup.string(),
            LOCATION_ID: yup.string(),
            LOCATION_NAME: yup.string(),
            E_MAIL_ADDRESS: yup.string().nullable(),
            DEPT_ORG_CODE: yup.string(),
            DEPT_ORG_DESC: yup.string(),
            DIV_ORG_CODE: yup.string(),
            MGR_FIRST_NAME: yup.string(),
            MGR_EMPNO: yup.string(),
            MGR_LAST_NAME: yup.string(),
            MGR_WORK_PHONE_1: yup.string().nullable(),
            VENDOR_NAME: yup.string().optional(),
          })
          .defined()
      )
      .defined(),
  })
  .defined();

export const EmployeeDetailsSchema = yup
  .object({
    Employee: yup
      .object({
        lan_user_id: yup.string(),
        emp_no: yup.string(),
        first_name: yup.string(),
        middle_name: yup.string().nullable(),
        last_name: yup.string(),
        cell_phone: yup.string().nullable(),
        company_name: yup.string(),
        pers_status: yup.string().nullable(),
        ord_code: yup.string(),
        job_code: yup.string().nullable(),
        job_title: yup.string(),
        mailstop_id: yup.string(),
        location_id: yup.string(),
        location_name: yup.string(),
        e_mail_address: yup.string().nullable(),
        dept_org_code: yup.string(),
        dept_org_desc: yup.string(),
        div_org_code: yup.string(),
        mgr_first_name: yup.string(),
        mgr_empno: yup.string(),
        mgr_last_name: yup.string(),
        mgr_work_phone_1: yup.string().nullable(),
        work_phone_1: yup.string().nullable(),
        work_phone_2: yup.string().nullable(),
        vendor_name: yup.string().optional(),
      })
      .defined(),
  })
  .defined();

export const EmployeeAddonSchema = yup.object({
  fullName: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  pagerNumber: yup.string().nullable(),
  title: yup.string().nullable(),
  email: yup.string().email().nullable(),
  contracShortNm: yup.string().nullable(),
  workAreaCode: yup.string().nullable(),
  workPhoneNumber: yup.string().nullable(),
  cellAreaCode: yup.string().nullable(),
  cellPhoneNumber: yup.string().nullable(),
  empId: yup.string(),
  employeeStat: yup.string().nullable(),
});

export const EmployeeStateUpdateSchema = yup.object({
  tempActiveInd: yup.string(),
});

export const VendorSearchPayloadSchema = yup.array().of(yup.string()).defined();

export const TempEmployeeSchema = yup.object(EmployeePayloadShape);

export const EmployeePayloadSchema = yup.object({
  rows: yup.array().of(TempEmployeeSchema.clone()),
});
export const EmployeeFilterShape = {
  roleId: yup.array().of(yup.number()).nullable(),
};

export const EmployeeFilterSchema = yup.object(EmployeeFilterShape);

export type IEmployee = yup.InferType<typeof EmployeeSchema>;
export type IVendor = yup.InferType<typeof VendorSearchPayloadSchema>;
export type IEmployeeSearch = yup.InferType<typeof EmployeeSearchSchema>;
export type IEmployeeSearchForm = yup.InferType<
  typeof EmployeeSearchFormSchema
>;
export type IEmployeeInternal = yup.InferType<typeof EmployeeInternalSchema>;
export type IEmployeeStateUpdate = yup.InferType<
  typeof EmployeeStateUpdateSchema
>;
export type IEmployeesDetails = yup.InferType<typeof EmployeesDetailsSchema>;
export type IEmployeeDetails = yup.InferType<typeof EmployeeDetailsSchema>;
export type ITempEmployee = yup.Asserts<typeof TempEmployeeSchema>;
export type IEmployeeAddon = yup.Asserts<typeof EmployeeAddonSchema>;
export type IEmployeePayload = yup.Asserts<typeof EmployeePayloadSchema>;
export type IEmployeeFilter = Partial<yup.Asserts<typeof EmployeeFilterSchema>>;
