import React, { useCallback } from 'react';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { tsoOssEmployeeActionTypes } from 'src/ducks/tso-oss-employee.duck';
import { getTsoOssEmployeeStatus } from 'src/selectors/tso-oss-employee.selector';
import TsoOssEmployeeListView from './tso-oss-employee-list.view';

const TsoOssEmployeeList: React.FC = () => {
  const { state, actions } = useTsoOssEmployeeContext();
  const status = getTsoOssEmployeeStatus(
    state,
    tsoOssEmployeeActionTypes.TSO_OSS_EMPLOYEE_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listGET({ limit: 0, page: 1 });
  }, [actions]);

  return (
    <TsoOssEmployeeListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
    />
  );
};

export default TsoOssEmployeeList;
