import React, { useEffect } from 'react';
import { ATMLoader } from 'shared-it-appmod-ui';
import { useFacilityContext } from 'src/contexts/facility.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import FacilityDetailsView from './facility-details.view';

interface IProp {
  selectedFacility: number;
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
}

const FacilityDetails: React.FC<IProp> = ({
  selectedFacility,
  setFacilityActions,
}) => {
  const { state, actions } = useFacilityContext();

  const status = getFacilityStatus(
    state,
    facilityActionTypes.FACILITY_DATA_READ
  );

  useEffect(() => {
    actions?.dataGET(selectedFacility);
  }, [actions]);

  if (status.fetching) {
    return <ATMLoader active size="massive" inline="centered" />;
  }

  return (
    <FacilityDetailsView
      loading={status.fetching}
      setFacilityActions={setFacilityActions}
    />
  );
};

export default FacilityDetails;
