import React, { useCallback, useEffect, useRef } from 'react';
import { Ref } from 'semantic-ui-react';
import { ATMMessage } from 'shared-it-appmod-ui';
import { FieldErrors } from 'react-hook-form';
import Lang from 'src/libraries/language';
import styles from './form-error.module.scss';

type IProps = {
  scrollToSelf?: boolean;
  errors: FieldErrors;
  dictionary?: string;
  handleItemClick?: (value: IError) => void;
  render?: (field: string, error: string, title: string) => React.ReactNode;
};

type IError = {
  name: string;
  label: string;
  message: string;
};

const getErrors = (errors, parent = '') => {
  let list: IError[] = [];

  if (!errors) {
    return list;
  }

  Object.entries(errors).forEach(([key, value]: any) => {
    const name = `${parent}${
      /^\d+$/.test(key) ? `[${key}]` : `${parent ? '.' : ''}${key}`
    }`;

    if (typeof value.message === 'string') {
      list = [
        ...list,
        {
          name,
          label: (Lang.FORM_VALIDATION[key] ?? key).replace('?', ''),
          message: value.message,
        },
      ];
    } else if (typeof value === 'object') {
      list = [...list, ...getErrors(value, name)];
    }
  });

  return list;
};

const FormError: React.FC<IProps> = ({
  scrollToSelf = false,
  errors,
  dictionary = 'FORM_LABEL',
  handleItemClick,
  render,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const list = getErrors(errors);

  useEffect(() => {
    if (list.length) {
      if (scrollToSelf && divRef && divRef.current) {
        divRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else {
        window.scrollTo({
          behavior: 'smooth',
          top: 0,
        });
      }
    }
  }, [list, scrollToSelf, divRef]);

  const handleClick = useCallback(
    (param: IError) => {
      if (handleItemClick) {
        handleItemClick(param);
      }

      const elementById = document.getElementById(param.name);

      if (elementById) {
        elementById.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        document.querySelector(`[name="${param.name}"]`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
    [handleItemClick]
  );

  return (
    <Ref innerRef={divRef}>
      <ATMMessage className={styles.wrapper} visible={!!list.length} error>
        <ATMMessage.Header>{Lang.TTL_FORM_VALIDATION_ERROR}</ATMMessage.Header>
        <ATMMessage.List>
          {list.map((value, key) => (
            <ATMMessage.Item key={key} onClick={() => handleClick(value)}>
              {render ? (
                render(
                  value.label,
                  value.message,
                  Lang[dictionary][value.label] ?? value.label
                )
              ) : (
                <>
                  <strong>{`${
                    Lang[dictionary][value.label] ?? value.label
                  }: `}</strong>
                  {value.message ?? ''}
                </>
              )}
            </ATMMessage.Item>
          ))}
        </ATMMessage.List>
      </ATMMessage>
    </Ref>
  );
};

export default FormError;
