import React, { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMButton,
  ATMTable,
  ATMFormProvider,
  useATMFormContext,
  ATMMessage,
} from 'shared-it-appmod-ui';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import Lang from 'src/libraries/language';
import {
  IWorkGroupForm,
  WorkGroupFormSchema,
} from 'src/models/work-group.model';
import { REGEX_EMAIL_ADDRESS } from 'src/constants';

const FormContent: React.FC<{
  defaultValues?: Partial<IWorkGroupForm>;
  handleEnable: (value: boolean) => void;
}> = ({ defaultValues, handleEnable }) => {
  const { actions } = useWorkGroupContext();
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useATMFormContext<IWorkGroupForm>();

  control.register('emailDistribution');
  const [emailDL, setEmailDL] = useState<any>(
    defaultValues ? defaultValues.emailDistribution : []
  );
  const [emailSG, setEmailSG] = useState<string>('');
  const [email, setEmail] = useState('');
  const [errorMail, setErrorMail] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subGrp',
  });

  const handleDeleteSubGrp = useCallback(
    async (subGrpId: number) => {
      watch('subGrp', fields);
      const retainList = fields.filter((val) => val.subGrpId !== subGrpId);
      if (defaultValues?.trbGrpId) {
        const response = await actions.validateSubGrp(
          subGrpId,
          defaultValues.trbGrpId
        );
        if (response.payload) {
          if (response.payload.validate === false) {
            ToastError(Lang.MSG_WORK_GROUP_SUB_GRP_CANNOT_DELETE);
          } else {
            remove();
            append(retainList);
            handleEnable(true);
          }
        }
      } else {
        remove();
        append(retainList);
        handleEnable(true);
      }
    },
    [fields, setEmailSG, handleEnable, append, remove]
  );

  const handleAddEmail = useCallback(
    (val: string) => {
      const checkEmail = REGEX_EMAIL_ADDRESS.test(val);
      if (checkEmail) {
        const checkEmailExists = getValues().emailDistribution?.find(
          (item) => item === val
        );
        if (checkEmailExists !== undefined) {
          ToastError(Lang.MSG_WORK_GROUP_EMAIL_DUPLICATE);
        } else {
          if (getValues().emailDistribution) {
            setEmailDL([...getValues().emailDistribution, val]);
            setValue('emailDistribution', [
              ...getValues().emailDistribution,
              val,
            ]);
          } else {
            setEmailDL([val]);
            setValue('emailDistribution', [val]);
          }
          setErrorMail(false);
          setEmail('');
          handleEnable(true);
        }
      } else {
        ToastError(Lang.MSG_WORK_GROUP_EMAIL_NOT_VALID);
      }
    },
    [control, setEmailDL, handleEnable]
  );

  const handleAddSubGrp = useCallback(() => {
    const checkSubGrp = getValues().subGrp?.find((grp) => {
      return grp.subGrpNm === emailSG;
    });
    if (checkSubGrp !== undefined) {
      ToastError(Lang.MSG_WORK_GROUP_SUB_GRP_DUPLICATE);
    } else {
      append({
        subGrpId: fields.length + 1,
        subGrpNm: emailSG,
        trbGrpId: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
      });
      setEmailSG('');
      watch('subGrp', fields);
      handleEnable(true);
    }
  }, [append, emailSG, watch]);

  return (
    <ATMGrid>
      <ATMGrid.Row columns="equal">
        <ATMGrid.Column width={8}>
          <ATMField
            as={ATMInput}
            label={Lang.LBL_GROUP_NAME}
            name="trbGrpNm"
            control={control}
            error={errors.trbGrpNm}
            disabled={defaultValues}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column width={8}>
          {/* <ATMField
            key={`${
              getValues().emailDistribution &&
              getValues().emailDistribution.toString()
            }`}
            as={ATMInput}
            label={Lang.LBL_EMAIL_DISTRIBUTION_LIST}
            name="emailDistribution"
            error={errors.emailDistribution}
            onChange={
              ((_, { value }) => {
                setEmail(value);
                return value;
              }) as any
            }
          /> */}
          <Form>
            <Form.Field>
              <label>{Lang.LBL_EMAIL_DISTRIBUTION_LIST}</label>
              <Input
                key={
                  getValues('emailDistribution')
                    ? getValues('emailDistribution').length
                    : emailDL.length
                }
                onChange={
                  ((_, { value }) => {
                    setEmail(value);
                    return value;
                  }) as any
                }
              />
            </Form.Field>
          </Form>
        </ATMGrid.Column>
        <ATMGrid.Column width={4} style={{ marginTop: '1.5em' }}>
          <ATMButton
            type="button"
            primary
            content={Lang.LBL_ADD}
            onClick={() => handleAddEmail(email)}
            disabled={email.length === 0}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns="1">
        <ATMGrid.Column>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell width={14}>
                  {Lang.LBL_EMAIL_DISTRIBUTION_LIST}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_ACTIONS}</ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {/* Local variables to simulate the add Emails Distribution */}
              {emailDL.length === 0 ? (
                <ATMTable.Row>
                  <ATMTable.Cell>
                    {Lang.LBL_EMAIL_NO_EMAIL_DISTRIBUTION_ADDED}
                  </ATMTable.Cell>
                </ATMTable.Row>
              ) : (
                emailDL.map((item, i) => (
                  // eslint-disable-next-line react/jsx-indent
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell>{item}</ATMTable.Cell>
                    <ATMTable.Cell textAlign="right">
                      <ATMButton
                        icon="trash alternate outline"
                        type="button"
                        style={{ color: 'gray' }}
                        onClick={() => {
                          if (
                            defaultValues
                              ? getValues().emailDistribution !== null &&
                                getValues().emailDistribution?.[1] !== undefined
                              : true
                          ) {
                            setEmailDL(
                              emailDL.filter((_, index) => i !== index)
                            );
                            setValue(
                              'emailDistribution',
                              getValues().emailDistribution.filter(
                                (_, index) => i !== index
                              )
                            );
                            handleEnable(true);
                          } else {
                            setErrorMail(true);
                            setTimeout(() => {
                              setErrorMail(false);
                            }, 5000);
                          }
                        }}
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              )}
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
        <ATMGrid.Column>
          {errorMail && (
            <ATMMessage negative>
              <b>{Lang.MSG_WORK_GROUP_EMAIL_ERROR_EDIT}</b>
            </ATMMessage>
          )}
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <h3>{Lang.LBL_WORK_GROUP_SUBGROUPS}</h3>
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column width={8}>
          <Form>
            <Form.Field>
              <label>{Lang.LBL_WORK_GROUP_SUBGROUP}</label>
              <Input
                key={fields.length}
                onChange={
                  ((_, { value }) => {
                    setEmailSG(value);
                    return value;
                  }) as any
                }
              />
            </Form.Field>
          </Form>
        </ATMGrid.Column>
        <ATMGrid.Column width={4} style={{ marginTop: '1.5em' }}>
          <ATMButton
            type="button"
            primary
            content={Lang.LBL_ADD}
            onClick={handleAddSubGrp}
            disabled={!emailSG}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell width={14}>
                  {Lang.LBL_WORK_GROUP_SUBGROUP_NAME}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_ACTIONS}</ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {/* Local variables to simulate the add subgroups */}
              {fields.length === 0 && !defaultValues ? (
                <ATMTable.Row>
                  <ATMTable.Cell>
                    {Lang.LBL_EMAIL_NO_SUBGROUP_ADDED}
                  </ATMTable.Cell>
                </ATMTable.Row>
              ) : (
                fields.map((item, i) => (
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell>
                      <ATMField
                        control={control}
                        as={ATMInput}
                        name={`subGrp1[${i}].subGrpId`}
                        defaultValue={item.subGrpId}
                        type="hidden"
                        className="hidden"
                      />
                      <ATMField
                        control={control}
                        as={ATMInput}
                        name={`subGrp1[${i}].subGrpNm`}
                        defaultValue={item.subGrpNm}
                        type="hidden"
                        className="hidden"
                      />
                      <ATMField
                        control={control}
                        as={ATMInput}
                        name={`subGrp[${i}].subGrpId`}
                        defaultValue={item.subGrpId}
                        type="hidden"
                        className="hidden"
                      />
                      <ATMField
                        control={control}
                        as={ATMInput}
                        name={`subGrp[${i}].subGrpNm`}
                        defaultValue={item.subGrpNm}
                        type="hidden"
                        className="hidden"
                      />
                      {item.subGrpNm}
                    </ATMTable.Cell>
                    <ATMTable.Cell textAlign="right">
                      <ATMButton
                        icon="trash alternate outline"
                        type="button"
                        style={{ color: 'gray' }}
                        onClick={() => handleDeleteSubGrp(item.subGrpId)}
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              )}
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IWorkGroupForm>;
  handleSubmit: (data: IWorkGroupForm) => void;
  handleEnable: (value: boolean) => void;
};

const WorkGroupForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={defaultValues}
      validationSchema={WorkGroupFormSchema}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <FormContent
              defaultValues={defaultValues}
              handleEnable={handleEnable}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default WorkGroupForm;
