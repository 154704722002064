import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/outages.service';
import { IOutageRequestListItem } from 'src/models/outages.model';

export const outagesActionTypes = {
  OUTAGES_LIST_READ: 'OUTAGES_LIST_READ',
  OUTAGE_LIST_CLEAR_LIST: 'OUTAGE_LIST_CLEAR_LIST'
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue

  listGET: {
    type: outagesActionTypes.OUTAGES_LIST_READ,
    service: services.listGET,
  },

  // This is a sync action
  clearList: () => ({
    type: outagesActionTypes.OUTAGE_LIST_CLEAR_LIST,
  }),
};

export type IOutagesAsync = typeof duckActions;

export interface IOutagesState extends ICommonState<typeof outagesActionTypes> {
  data?: IOutageRequestListItem;
  list: IOutageRequestListItem[];
  total: number;
}

export const defaultState: IOutagesState = {
  status: {},
  list: [],
  total: 0,
};

const OutagesReducer = (
  state: IOutagesState,
  action: IReducerAction<IOutagesAsync>
): IOutagesState => {
  switch (action.type) {
    case outagesActionTypes.OUTAGES_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default OutagesReducer;
