import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import CancelTypeReducer, {
  ICancelTypeState,
  defaultState,
  duckActions,
} from 'src/ducks/cancel-type.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    CancelTypeReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ICancelTypeContext = ReturnType<typeof useReducer>;

const CancelTypeContext = createContext<Partial<ICancelTypeContext>>({
  state: defaultState,
}) as React.Context<ICancelTypeContext>;

type IProps = {
  state?: Partial<ICancelTypeState>;
};

const CancelTypeProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <CancelTypeContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </CancelTypeContext.Provider>
  );
};

const useCancelTypeContext = () => useContext(CancelTypeContext);

export type IUseCancelTypeContext = ReturnType<typeof useCancelTypeContext>;

export { CancelTypeContext, CancelTypeProvider, useCancelTypeContext };
