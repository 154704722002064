import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import OcPendingActionReportReducer, {
  IOcPendingActionReportState,
  defaultState,
  duckActions,
} from 'src/ducks/oc-pending-action-report.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    OcPendingActionReportReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IOcPendingActionReportContext = ReturnType<typeof useReducer>;

const OcPendingActionReportContext = createContext<
  Partial<IOcPendingActionReportContext>
>({
  state: defaultState,
}) as React.Context<IOcPendingActionReportContext>;

type IProps = {
  state?: Partial<IOcPendingActionReportState>;
};

const OcPendingActionReportProvider: React.FC<IProps> = ({
  children,
  state,
}) => {
  const reducer = useReducer(state);

  return (
    <OcPendingActionReportContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </OcPendingActionReportContext.Provider>
  );
};

const useOcPendingActionReportContext = () =>
  useContext(OcPendingActionReportContext);

export type IUseOcPendingActionReportContext = ReturnType<
  typeof useOcPendingActionReportContext
>;

export {
  OcPendingActionReportContext,
  OcPendingActionReportProvider,
  useOcPendingActionReportContext,
};
