import { Dispatch } from 'react';
import Axios from 'axios';
import { v4 as uuid } from 'uuid';
import {
  TOASTER_TYPE,
  Toast,
} from 'src/components/atoms/toaster/toaster.component';
import {
  TYPE_FETCHING,
  TYPE_ERROR,
  TYPE_FETCHED,
  TYPE_CANCELLED,
  TYPE_DUPLICATE,
} from 'src/constants';
import Lang from './language';
import { redirect, refreshToken } from './amplify.library';

export const thunkCreator = async <C extends string, T>(
  actionType: C,
  service: (dispatch: Dispatch<any>) => Promise<T>,
  dispatch: Dispatch<any>,
  meta?: IMeta,
  params?: any[],
  retry = true
) => {
  const id = uuid();
  dispatch({
    type: actionType,
    status: TYPE_FETCHING,
    id,
  });

  try {
    const response = await service(dispatch);

    dispatch({
      type: actionType,
      status: TYPE_FETCHED,
      id,
      payload: response,
      params,
    });

    return { id, payload: response };
  } catch (error: any) {
    dispatch({
      type: actionType,
      status:
        // eslint-disable-next-line no-nested-ternary
        error instanceof Axios.Cancel
          ? error.message === TYPE_DUPLICATE
            ? TYPE_DUPLICATE
            : TYPE_CANCELLED
          : TYPE_ERROR,
      id,
      payload: error.data || error,
      params,
    });

    // This will renew the access token automatically
    if (error.status === 401) {
      await refreshToken();

      if (!retry) {
        redirect();
      } else {
        // This will retry the request once more
        return thunkCreator(actionType, service, dispatch, meta, params, false);
      }
    } else if (!meta && error.status === 400) {
      Toast({
        id: 'http-error', // This will avoid multiple instance of http error
        header: Lang.TTL_TOAST_ERROR,
        // Prioritize error messages
        content: (
          error?.data?.message ||
          error?.data?.Error ||
          error?.statusText ||
          error?.message ||
          error
        ).toString(),
      });
    } else if (meta && typeof meta.error === 'function') {
      const content = meta.error(error);

      if (content) {
        Toast({
          id: 'http-error',
          header: Lang.TTL_TOAST_ERROR,
          content,
        });
      }
    } else if (meta && typeof meta.info === 'function') {
      const content = meta.info(error);

      if (content) {
        Toast({
          id: 'http-info',
          header: Lang.TTL_TOAST_INFO,
          type: TOASTER_TYPE.INFO,
          content,
        });
      }
    } else if (!meta || (meta && meta.error !== false)) {
      if (error instanceof Axios.Cancel) {
        // ToastWarning(error.message || Lang.MSG_HTTP_ERROR_REQUEST_CANCELLED);

        return { id, error: null };
      }

      Toast({
        id: 'http-error', // This will avoid multiple instance of http error
        header: error?.data?.name || Lang.TTL_TOAST_ERROR,
        content:
          error?.data?.message ||
          Lang.formatString(
            Lang.MSG_HTTP_ERROR_BAD_REQUEST,
            error.status ?? 400
          ),
      });
    }

    return { id, error: error.data || error };
  }
};

export type IStatus = {
  error: any;
  fetching: boolean;
};

interface ICustomAction<C, P = never, S = never, A = never> {
  type: C;
  payload?: P;
  status?: S;
  params?: A;
}

export type ICommonState<T> = {
  status: {
    [K in keyof T]?: IStatus;
  };
};

export type IReturnPromise<T> = T extends Promise<infer U> ? U : T;

type IThunkReturn<T> =
  | { payload: T; error?: never }
  | { payload?: never; error: any };

type IMeta = {
  error?: boolean | ((error) => string | undefined);
  info?: boolean | ((data) => string | undefined);
};

export type IAsyncThunk = {
  type: string;
  service: (...args: any[]) => any;
  meta?: IMeta;
};

type IAsyncAction<T extends IAsyncThunk> = ICustomAction<
  T['type'],
  IReturnPromise<ReturnType<T['service']>>,
  never,
  Parameters<T['service']>
>;

export type ISyncThunk = (...args: any[]) => {
  type: string;
  payload?: any;
};

type ISyncAction<T extends ISyncThunk> = ICustomAction<
  ReturnType<T>['type'],
  ReturnType<T>['payload']
>;

// This will auto create reducer actions response
export type IReducerAction<T> = {
  [K in keyof T]: T[K] extends IAsyncThunk
    ? IAsyncAction<T[K]>
    : T[K] extends ISyncThunk
    ? ISyncAction<T[K]>
    : never;
}[keyof T];

export type IReturnActions<T> = {
  [K in keyof T]: T[K] extends IAsyncThunk
    ? (
        ...args: Parameters<T[K]['service']>
      ) => Promise<IThunkReturn<IReturnPromise<ReturnType<T[K]['service']>>>>
    : T[K] extends ISyncThunk
    ? (...args: Parameters<T[K]>) => ReturnType<T[K]>
    : never;
};

const thunkFactory = <A, R>(actions: A, dispatch: Dispatch<R>) => {
  return Object.keys(actions as any).reduce((thunks, key) => {
    const action = actions[key];

    return {
      ...thunks,
      [key]: (...args) => {
        if (typeof action === 'function') {
          return dispatch(action(...args));
        }
        return thunkCreator(
          action.type,
          () => action.service(...args),
          dispatch,
          action.meta,
          args
        );
      },
    };
  }, {} as IReturnActions<A>);
};

export default thunkFactory;
