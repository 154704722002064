/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ATMButton, ATMDivider, ATMPopover } from 'shared-it-appmod-ui';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import Lang from 'src/libraries/language';
import { MCCSectionType, getEmployeeName } from 'src/helpers/employee.helper';
import styles from './trouble-ticket-card.module.scss';

type IProps = {
  troubleTicket: ITroubleTicketList;
  fromSearch?: boolean;
  fromAuditReport?: boolean;
};

const TroubleTicketCard: React.FC<IProps> = ({
  troubleTicket,
  fromSearch,
  fromAuditReport,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ATMPopover
        style={{ width: '250px', zIndex: isOpen ? 100 : 0 }}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          !fromAuditReport ? (
            <Link to={`/trouble-ticket/${troubleTicket.troubleTicketId}`}>
              <strong>{troubleTicket.troubleTicketId}</strong>
            </Link>
          ) : (
            <strong>{troubleTicket.troubleTicketId}</strong>
          )
        }
        hoverable
        position="right center"
      >
        <ATMPopover.Header>
          <span className={styles.overlayHeader}>
            {Lang.LBL_TICKET_INFORMATION}
          </span>
        </ATMPopover.Header>
        <ATMDivider />
        <ATMPopover.Content>
          <>
            <div className={styles.overlayContent}>
              <FieldReadOnly label="Created by">
                {troubleTicket?.submittedBy &&
                troubleTicket?.submittedByEmployee
                  ? getEmployeeName(
                      troubleTicket?.submittedByEmployee,
                      true,
                      MCCSectionType.TROUBLE_TICKET
                    )
                  : ''}
              </FieldReadOnly>
              {fromAuditReport && (
                <FieldReadOnly label="Activated by">
                  {troubleTicket?.activatedBy &&
                  troubleTicket?.activatedByEmployee
                    ? getEmployeeName(
                        troubleTicket?.activatedByEmployee,
                        true,
                        MCCSectionType.TROUBLE_TICKET
                      )
                    : ''}
                </FieldReadOnly>
              )}
              {!fromAuditReport && (
                <FieldReadOnly label="Assigned to">
                  {troubleTicket?.assignedTo &&
                  troubleTicket?.assignedToEmployee
                    ? getEmployeeName(
                        troubleTicket?.assignedToEmployee,
                        true,
                        MCCSectionType.TROUBLE_TICKET
                      )
                    : ''}
                </FieldReadOnly>
              )}
              {(fromSearch || fromAuditReport) && (
                <FieldReadOnly label="Closed by">
                  {troubleTicket?.closedBy && troubleTicket?.closedByEmployee
                    ? getEmployeeName(
                        troubleTicket?.closedByEmployee,
                        true,
                        MCCSectionType.TROUBLE_TICKET
                      )
                    : ''}
                </FieldReadOnly>
              )}
              {!fromSearch && !fromAuditReport && (
                <FieldReadOnly label="Comments/Resolution">
                  {troubleTicket.comments && troubleTicket.comments.length > 0
                    ? troubleTicket.comments[0].noteTxt
                    : ''}
                </FieldReadOnly>
              )}
            </div>
            {!fromAuditReport && (
              <div className={styles.actionButtons}>
                <ATMButton
                  as={Link}
                  secondary
                  content="Edit"
                  size="mini"
                  to={`/trouble-ticket/${troubleTicket.troubleTicketId}`}
                />
              </div>
            )}
          </>
        </ATMPopover.Content>
      </ATMPopover>
    </>
  );
};

export default TroubleTicketCard;
