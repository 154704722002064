import React, { useEffect } from 'react';
import { LERRequestFormStep, LERRequestPage } from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import LERRequestAdd from '../ler-request-add/ler-request-add.component';

const LERRequestClone: React.FC = () => {
  const { state, actions } = useLerRequestContext();
  const { params: currentTab, handleChange } = useLocationParams([
    LERRequestPage.Clone,
  ]);

  const { data } = state;

  const id = Number(currentTab.lerClone);

  useEffect(() => {
    const fetchData = async () => {
      actions.setCaisoList();
      const result = await actions.dataGET(id);

      if (result.error) {
        handleChange();
      }
    };

    if (id) {
      fetchData();
    }
  }, []);

  if (!id || !data) {
    return null;
  }

  const cloneData = {
    content: {
      [LERRequestFormStep.OUTAGE_FACILITY]:
        data[LERRequestFormStep.OUTAGE_FACILITY],
      [LERRequestFormStep.CREW_INFORMATION]: {
        ...data[LERRequestFormStep.CREW_INFORMATION],
        // #730209 - Do not clone the supervisor field.
        supvId: undefined,
      },
      [LERRequestFormStep.JOB_INFORMATION]: {
        ...data[LERRequestFormStep.JOB_INFORMATION],
        testVoltageApplied: undefined,
        clsdGrndDsconctInd: undefined,
      },
      // [LERRequestFormStep.ISO]: data[LERRequestFormStep.ISO],
      // Bug #: 619782 - should not clone Nature of Work, Primary ISO Cause Code and Secondary ISO Cause Code fields, the rest of the details should be cloned.
      [LERRequestFormStep.ISO]: {
        ...data[LERRequestFormStep.ISO],
        isoWorkKindId: undefined,
        primIsoCauseCode: undefined,
        secondIsoCauseCode: undefined,
      },
      step: LERRequestFormStep.OUTAGE_DATE,
    },
  };

  return (
    <LERRequestAdd
      data={cloneData}
      open={!!id}
      onClose={() => handleChange()}
    />
  );
};

export default LERRequestClone;
