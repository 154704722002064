import React, { useState, useRef, useCallback, useEffect } from 'react';
import { ATMModal, ATMButton } from 'shared-it-appmod-ui';
import { IKeyRequest } from 'src/models/key-request.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { useSharepoint } from 'src/hooks/file.hook';
import { getSharepointEnvironmentProperty } from 'src/helpers/sharepoint-service.helper';
import {
  ISharepointProperty,
  ISharepointRequestData,
  IUploadedFileData,
} from 'src/models/sharepoint-service.model';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import Lang from 'src/libraries/language';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import { getUser } from 'src/libraries/amplify.library';
import styles from './key-request-card.module.scss';
import { statusLabel } from '../../trouble-jobs/trouble-jobs-list/trouble-jobs.module.scss';

type IProps = {
  keyRequest: IKeyRequest;
};

const KeyRequestCard: React.FC<IProps> = ({ keyRequest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isListComplete, setIsListComplete] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const fileAttachments = useRef<string>('');
  const displayUploadButton = useRef(false);
  const { state: settingState } = useSettingContext();
  const { state } = useFileContext();
  const { mccList, submitUpload, getCachedAccessToken } = useSharepoint();

  const fetchList = useCallback(async () => {
    let setting: ISharepointProperty = {} as any;
    const fetchSharepointProp = async () => {
      const sharepointProp = await getSharepointEnvironmentProperty(
        settingState.list,
        SharePointApplicationType.KEY_REQUEST_TYPE
      );
      setting = sharepointProp;
    };
    await fetchSharepointProp();
    const accessToken = await getCachedAccessToken(new Date());
    const list = await mccList({
      parentFolder: '',
      folderName:
        JSON.stringify(keyRequest?.keyRequestDetail?.requestNbr) || '',
      documentLibrary: setting?.documentLibraryName || '',
      accessToken: accessToken || '',
      createdBy: '',
      documentId: setting?.documentLibraryId || '',
      fileId: '',
      fileName: '',
    });

    fileAttachments.current = list?.mccListResponse || '';
    setIsListComplete(true);
  }, [isOpen, isUploaded]);

  useEffect(() => {
    if (keyRequest.requestorTyp === 'C' || keyRequest.requestorTyp === 'D') {
      if (keyRequest?.keyRequestDetail?.requestNbr && (isOpen || isUploaded)) {
        fetchList();
      }
    }
  }, [isOpen, isUploaded]);

  function FileAttachment() {
    const [items, setItems] = useState<any[]>([]);
    const attachments: any = [];
    useEffect(() => {
      if (fileAttachments.current) {
        Object.entries(JSON.parse(fileAttachments.current)).forEach(
          (urlEntry) => {
            const [, url] = urlEntry as unknown as IUploadedFileData[];
            const fileLocation = JSON.stringify(url.fileName).replace(/"/g, '');
            const fileName = JSON.stringify(url.fileName)
              .replace(/"/g, '')
              .split('/');
            attachments.push(
              <>
                <a
                  key={fileLocation}
                  href={fileLocation}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {fileName.length ? fileName[fileName.length - 1] : ''}
                </a>
                <br />
              </>
            );
            setItems(attachments);
          }
        );
      }
    }, [isListComplete]);
    return <div>{items.length ? items : '-'}</div>;
  }

  const handleFileUploadSubmit = useCallback(
    async (responseData: IKeyRequest) => {
      const accessToken = await getCachedAccessToken(new Date());
      // const requestor = `${keyRequest.requestor.firstName} ${keyRequest.requestor.lastName}`;
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: accessToken || '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName:
          JSON.stringify(responseData?.keyRequestDetail.requestNbr) || '',
        parentFolder: responseData?.keyRequestDetail.requestNbr as any,
        requestId: responseData?.keyRequestDetail.requestNbr,
        requestor: uploader,
        documentLibrary: '',
      };
      if (accessToken.length) {
        await submitUpload({
          requestData,
          files: state.stash,
          applicationType: SharePointApplicationType.KEY_REQUEST_TYPE,
          setting: settingState,
          withParentFolder: false,
          setIsUpdated: setIsUploaded,
        });
      }
      setIsUploaded(false);
    },
    [statusLabel, settingState]
  );

  return (
    <>
      <ATMModal
        trigger={
          <u>
            <a
              className={styles.popLink}
              role="button"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {keyRequest.requestNbr}
            </a>
          </u>
        }
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        position="right center"
        on="click"
      >
        <ATMModal.Header>
          <span className={styles.overlayHeader}>Key Information</span>
        </ATMModal.Header>
        <ATMModal.Content style={{ marginBottom: '20px' }}>
          <>
            <div>
              <div style={{ width: '40%', float: 'left' }}>
                <FieldReadOnly label="Key Number">
                  {keyRequest.keyRequestDetail.keyNbr
                    ? convertKeyNumberToFiveDigits(
                        keyRequest.keyRequestDetail.keyNbr
                      )
                    : '-'}
                </FieldReadOnly>
              </div>
              <div style={{ width: '60%', float: 'right', top: '0px' }}>
                <FieldReadOnly label="Issuance Reason">
                  {keyRequest.keyRequestDetail.keyDetail &&
                  keyRequest.keyRequestDetail.keyDetail.length > 0 &&
                  keyRequest.keyRequestDetail.keyDetail[0].reason
                    ? keyRequest.keyRequestDetail.keyDetail[0].reason
                    : '-'}
                </FieldReadOnly>
              </div>
            </div>
            <div>
              {keyRequest.requestorTyp === 'C' ||
              keyRequest.requestorTyp === 'D' ? (
                <FieldReadOnly label="Attached Approver Email">
                  {isListComplete ? <FileAttachment /> : ''}
                  <br />
                  {displayUploadButton.current ? (
                    <FileUploader
                      icon="upload"
                      onSuccess={() => handleFileUploadSubmit(keyRequest)}
                      displayRemoveButton={false}
                      text={Lang.LBL_UPLOAD}
                      successMessage=""
                      errorMessage=""
                      hasSharePointError={state?.hasSharepointError}
                      popOverPostition="right center"
                      popOverStyle={{ color: 'red', width: 250 }}
                    />
                  ) : (
                    ''
                  )}
                </FieldReadOnly>
              ) : undefined}
            </div>
          </>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            primary
            onClick={() => {
              setIsOpen(false);
              fileAttachments.current = '';
            }}
          >
            Close
          </ATMButton>
        </ATMModal.Actions>
      </ATMModal>
    </>
  );
};

export default KeyRequestCard;
