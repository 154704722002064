import React, { useCallback, useRef, useState } from 'react';
import { useSubstationContext } from 'src/contexts/substation.context';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { ISubstation, ISubstationForm } from 'src/models/substation.model';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import history from 'src/history';
import SubstationEditView from './substation-edit.view';

type IProp = {
  data: ISubstation;
  details: boolean;
};

const SubstationEdit: React.FC<IProp> = ({ data, details }) => {
  const { state, actions } = useSubstationContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [openDelete, setOpenDelete] = useState(false);

  const status = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_DATA_UPDATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ISubstationForm) => {
      const response = await actions.updatePUT(data.substationId, {
        ...formData,
      });

      if (!response.error && response.payload) {
        setIsEditOpen(false);

        ToastSuccess(
          Lang.formatString(Lang.MSG_SUCCESS_EDIT_SUBSTATION, data.substationId)
        );
        if (details) {
          history.push('/substation-entry-log?list=substation');
        } else {
          actions.listGET({ page: 1, limit: 100 });
          history.push(history.location.search);
        }
      } else {
        ToastError(response.error.message);
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <SubstationEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      openDelete={openDelete}
      setOpenDelete={setOpenDelete}
      details={details}
    />
  );
};

export default SubstationEdit;
