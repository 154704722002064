import {
  getParsedEmployee,
  getParsedEmployeeInternal,
} from 'src/selectors/employee.selector';
import {
  IEmployee,
  IEmployeeDetails,
  IVendor,
  ITempEmployee,
} from 'src/models/employee.model';
import services from 'src/services/employee.service';
import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';

export const employeeActionTypes = {
  EMPLOYEE_SEARCH_FETCH: 'EMPLOYEE_SEARCH_FETCH',
  VENDORS_SEARCH_FETCH: 'VENDORS_SEARCH_FETCH',
  EMPLOYEE_USER_LIST_FETCH: 'EMPLOYEE_USER_LIST_FETCH',
  EMPLOYEE_USER_FETCH: 'EMPLOYEE_USER_FETCH',
  EMPLOYEE_SEARCH_INTERNAL_FETCH: 'EMPLOYEE_SEARCH_INTERNAL_FETCH',
  EMPLOYEE_DEFAULT: 'EMPLOYEE_DEFAULT',
  EMPLOYEE_LIST_GET: 'EMPLOYEE_LIST_GET',
  EMPLOYEE_STAT_UPDATE: 'EMPLOYEE_STAT_UPDATE',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const duckActions = {
  defaultGET: {
    type: employeeActionTypes.EMPLOYEE_DEFAULT,
    service: services.searchGET,
  },
  searchGET: {
    type: employeeActionTypes.EMPLOYEE_SEARCH_FETCH,
    service: services.searchGET,
  },
  searchVendorsGET: {
    type: employeeActionTypes.VENDORS_SEARCH_FETCH,
    service: services.searchVendorsGET,
  },
  searchInternalGET: {
    type: employeeActionTypes.EMPLOYEE_SEARCH_INTERNAL_FETCH,
    service: services.searchInternalGET,
  },
  userGET: {
    type: employeeActionTypes.EMPLOYEE_USER_FETCH,
    service: services.userGET,
  },
  usersPOST: {
    type: employeeActionTypes.EMPLOYEE_USER_LIST_FETCH,
    service: services.usersPOST,
  },
  listGET: {
    type: employeeActionTypes.EMPLOYEE_LIST_GET,
    service: services.listGET,
  },
  employeeStatePUT: {
    type: employeeActionTypes.EMPLOYEE_STAT_UPDATE,
    service: services.employeeStatePUT,
  },
};

export type IEmployeeActions = typeof duckActions;

export interface IEmployeeState
  extends ICommonState<typeof employeeActionTypes> {
  list: IEmployee[];
  tempEmpList: ITempEmployee[];
  searchList: IEmployee[];
  loggedInUser?: IEmployeeDetails;
  listVendors?: IVendor;
}

export const defaultState: IEmployeeState = {
  status: {},
  list: [],
  tempEmpList: [],
  searchList: [],
  listVendors: [],
};

const EmployeeReducer = (
  state: IEmployeeState = defaultState,
  action: IReducerAction<IEmployeeActions>
): IEmployeeState => {
  switch (action.type) {
    case employeeActionTypes.EMPLOYEE_SEARCH_FETCH: {
      return {
        ...state,
        list: (action.payload ?? []).map((employee) =>
          getParsedEmployee(employee)
        ),
      };
    }
    case employeeActionTypes.VENDORS_SEARCH_FETCH: {
      return {
        ...state,
        listVendors: (action.payload ?? []).map((vendor) => vendor),
      };
    }
    case employeeActionTypes.EMPLOYEE_USER_LIST_FETCH: {
      return {
        ...state,
        list: (action.payload ?? []).map((employee) =>
          getParsedEmployee(employee)
        ),
      };
    }

    case employeeActionTypes.EMPLOYEE_STAT_UPDATE:
    case employeeActionTypes.EMPLOYEE_USER_FETCH: {
      return {
        ...state,
        loggedInUser: action.payload,
      };
    }
    case employeeActionTypes.EMPLOYEE_SEARCH_INTERNAL_FETCH: {
      return {
        ...state,
        searchList: (action.payload?.employees ?? []).map((employee) =>
          getParsedEmployeeInternal(employee)
        ),
      };
    }
    case employeeActionTypes.EMPLOYEE_LIST_GET: {
      return {
        ...state,
        tempEmpList: action.payload?.rows ?? [],
      };
    }
    default: {
      return state;
    }
  }
};

export default EmployeeReducer;
