/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMDivider,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSegment,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { useRolesContext } from 'src/contexts/roles.context';
import {
  formatToRawEmployeeId,
  getEmployeeName,
} from 'src/helpers/employee.helper';
import Lang from 'src/libraries/language';
import { ICrewInfo } from 'src/models/crew-info.model';
import styles from './ler-request-crew-information.module.scss';

type IProps = {
  data?: ICrewInfo;
  handleEdit?: () => void;
};

const LERRequestCrewInformation: React.FC<IProps> = ({ data, handleEdit }) => {
  const {
    state: { outageList },
  } = useRolesContext();

  const { state } = useAuthorizedListContext();

  if (!data) {
    return null;
  }

  const header = handleEdit ? (
    <ATMHeader as="h2" attached="top" block>
      {Lang.TTL_CREW_INFO}
      {handleEdit && (
        <a className={styles.edit} onClick={handleEdit} role="button">
          <ATMIcon name="edit" />
          {Lang.LBL_EDIT}
        </a>
      )}
    </ATMHeader>
  ) : (
    <>
      <span>
        <ATMIcon circular name="user outline" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_CREW_INFO}
        className={styles.header}
      />
    </>
  );

  const divider = handleEdit && <ATMDivider />;

  const content = (
    <>
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_CREW_INFO_NAME}>
              {data.crew && data.crewId
                ? getEmployeeName(data.crew)
                : data.crewId
                ? state.crewList.find(
                    (v) =>
                      formatToRawEmployeeId(v.empId) ===
                      formatToRawEmployeeId(data?.crewId)
                  )?.employee?.fullName ?? data.crewId
                : Lang.LBL_UNAVAILABLE}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_INFO_CELLPHONE_NO}>
              {data.crewCellNbr}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_CREW_INFO_PAGER}>
              {data.crewPagerNbr}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_CREW_INFO_TRUCK}>
              {data.crewTruckNbr}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>

      {divider}

      <ATMHeader as="h2" className={styles.subHeader}>
        {Lang.TTL_REQUESTOR_INFO}
      </ATMHeader>

      <ATMGrid columns={3}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_REQUESTOR_NAME}>
              {data.requestor && data.requestorId
                ? getEmployeeName(data.requestor)
                : data.requestorId
                ? outageList.find((v) => v.empId === data.requestorId)?.employee
                    .fullName ?? data.requestorId
                : Lang.LBL_UNAVAILABLE}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_REQUESTOR_EMAIL}>
              {data.requestorEmail ?? Lang.LBL_UNAVAILABLE}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_AUTHORIZED}>
              {handleEdit
                ? Lang.LBL_YES
                : data.authorized
                ? Lang.LBL_YES
                : Lang.LBL_NO}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_INFO_CELLPHONE_NO}>
              {data.requestorContact}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_REQUESTOR_SUPERVISOR}>
              {data.supervisor && data.supvId
                ? `${data.supervisor.lastName}, ${data.supervisor.firstName}`
                : data.supvId
                ? outageList.find((v) => v.empId === data.supvId)?.employee
                    .fullName ?? data.supvId
                : '-'}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>

      {divider}
      <ATMHeader as="h2" className={styles.subHeader}>
        {Lang.TTL_GF_CA_INFO}
      </ATMHeader>

      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_GF_CA}>
              {data.genlFormnCtrctAdmin}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_GF_CA_CHECK_BY}>
              {data.fldckBy}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <div>
      {header}
      {handleEdit ? (
        <ATMSegment attached="bottom">{content}</ATMSegment>
      ) : (
        content
      )}
    </div>
  );
};

export default LERRequestCrewInformation;
