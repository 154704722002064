import React, { useCallback } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
// import history from 'src/history';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  ILerRequestSingleEmail,
} from 'src/models/ler-request.model';
import UserGroupListNotificationView from './user-group-list.view';

type IProps = {
  data?: ILerRequest;
};
const UserGroupListNotification: React.FC<IProps> = ({ data }) => {
  const { actions } = useLerRequestContext();

  const handleResendEmail = useCallback(
    async (formData: ILerRequestSingleEmail) => {
      const response = await actions.resendSingleEmailPOST(formData);

      if (!response.error) {
        ToastSuccess(Lang.MSG_EMAIL_GROUP_RESEND_SUCCESS);
        // history.go(0);
        actions.dataGET(response.payload?.requestId ?? 0);
      } else {
        ToastError(response.error.message);
      }
    },
    [actions]
  );

  return (
    <UserGroupListNotificationView
      data={data}
      handleResendEmail={handleResendEmail}
    />
  );
};

export default UserGroupListNotification;
