import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/forward-ticket.service';
import { IForwardTicket } from 'src/models/forward-ticket.model';

export const forwardTicketActionTypes = {
  FORWARD_TICKET_DATA_READ: 'FORWARD_TICKET_DATA_READ',
  FORWARD_TICKET_LIST_READ: 'FORWARD_TICKET_LIST_READ',
  FORWARD_TICKET_DATA_CREATE: 'FORWARD_TICKET_DATA_CREATE',
  FORWARD_TICKET_DATA_UPDATE: 'FORWARD_TICKET_DATA_UPDATE',
  FORWARD_TICKET_DATA_DELETE: 'FORWARD_TICKET_DATA_DELETE',
  FORWARD_TICKET_DATA_SET: 'FORWARD_TICKET_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: forwardTicketActionTypes.FORWARD_TICKET_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: forwardTicketActionTypes.FORWARD_TICKET_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: forwardTicketActionTypes.FORWARD_TICKET_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: forwardTicketActionTypes.FORWARD_TICKET_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: forwardTicketActionTypes.FORWARD_TICKET_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (forwardTicket: IForwardTicket) => ({
    type: forwardTicketActionTypes.FORWARD_TICKET_DATA_SET,
    payload: {
      forwardTicket,
    },
  }),
};

export type IForwardTicketAsync = typeof duckActions;

export interface IForwardTicketState
  extends ICommonState<typeof forwardTicketActionTypes> {
  data?: IForwardTicket;
  list: IForwardTicket[];
  total: number;
}

export const defaultState: IForwardTicketState = {
  status: {},
  list: [],
  total: 0,
};

const ForwardTicketReducer = (
  state: IForwardTicketState,
  action: IReducerAction<IForwardTicketAsync>
): IForwardTicketState => {
  switch (action.type) {
    case forwardTicketActionTypes.FORWARD_TICKET_DATA_SET:
    case forwardTicketActionTypes.FORWARD_TICKET_DATA_READ:
    case forwardTicketActionTypes.FORWARD_TICKET_DATA_UPDATE:
    case forwardTicketActionTypes.FORWARD_TICKET_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.forwardTicket,
      };
    }

    case forwardTicketActionTypes.FORWARD_TICKET_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case forwardTicketActionTypes.FORWARD_TICKET_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.id !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default ForwardTicketReducer;
