import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import React, { useCallback, useEffect, useState } from 'react';
import { checkValue } from 'src/libraries/common.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import Moment from 'src/libraries/moment.library';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import Lang from 'src/libraries/language';
import EquipmentTypeListView from './equipment-type-list.view';

const EquipmentTypeList: React.FC = () => {
  const [total, setTotal] = useState(0);
  const { state, actions } = useEquipmentTypeContext();
  const status = getEquipmentTypeStatus(
    state,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  useEffect(() => {
    if (total === 0 || state.total > total) {
      setTotal(state.total);
    }
  }, [state.total]);

  const handleFetch = useCallback(
    (params: IORGDataTableQueryState) => {
      actions?.listGET(params);
    },
    [actions]
  );

  const handleDownload = useCallback(
    async (params: IORGDataTableQueryState) => {
      const result = await actions.listAllGET(params);
      if (result.payload?.rows) {
        const list = result.payload.rows;
        const items = list.map((value) => [
          checkValue(value.facTypId),
          checkValue(value.facTypNm),
          checkValue(value.facTypDesc),
        ]);
        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_DOWNLOAD_TABLE_ID,
            Lang.LBL_CSV_EMPLOYEE_NAME,
            Lang.LBL_CSV_EMPLOYEE_DESCRIPTION,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', exportData);
        link.setAttribute(
          'download',
          `MCC_EquipmentType_${Moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions, state]
  );

  return (
    <EquipmentTypeListView
      data={state.list}
      total={state.total}
      loading={status.fetching}
      handleFetch={handleFetch}
      handleDownload={handleDownload}
    />
  );
};

export default EquipmentTypeList;
