/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import { List } from 'semantic-ui-react';
import { debounce } from 'lodash';
import {
  ATMGrid,
  ATMButton,
  ATMDropdown,
  ATMPopover,
  ATMInput,
  ATMLabel,
  ATMIcon,
  useATMFormContext,
  ATMField,
} from 'shared-it-appmod-ui';
import { FacilityType, LERRequestStatus } from 'src/constants';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import Lang from 'src/libraries/language';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRequestStatusContext } from 'src/contexts/request-status.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { getOutageTypeStatus } from 'src/selectors/outage-type.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { outageTypeActionTypes } from 'src/ducks/outage-type.duck';
import {
  IOutageDateFilter,
} from 'src/models/calendar-outage.model';
import RequestStatusCheckbox from '../filter-checkbox/checkbox-request.component';
import OutageCheckbox from '../filter-checkbox/checkbox-outage.component';
import styles from '../outage-calendar.module.scss';
import FacilityTypeIdCheckbox from '../filter-checkbox/checkbox-facility-type-id.component';
import OutageCalendarSortByOptions from './outage-calendar-sort-radio-options.component';

type IProps = {
  sortBy?: IOutageDateFilter;
  filters: IOutageDateFilter;
  setFilters: React.Dispatch<React.SetStateAction<IOutageDateFilter>>;
  setSortBy?: React.Dispatch<React.SetStateAction<IOutageDateFilter>>;
  showSortFilters: boolean;
  setShowSortFilters: (show: boolean) => void;
  downloadButton: React.ReactNode;
};

export enum SortByOptions {
  Voltage = 'Voltage',
  FacilityName = 'Facility Name',
}

export const outageCalendarExcludeStatus = [
  LERRequestStatus.Pending,
  LERRequestStatus.Reviewed,
  LERRequestStatus.Rejected,
];

const OutageCalendarFilters: React.FC<IProps> = ({
  filters,
  sortBy,
  showSortFilters,
  setFilters,
  setSortBy,
  setShowSortFilters,
  downloadButton,
}) => {
  const {
    control,
    formState: { errors },
  } = useATMFormContext<IOutageDateFilter>();
  const [showFilters, setShowFilters] = useState(false);
  const { state: outageAuthTypeState, actions: outageAuthTypeActions } =
    useOutageTypeContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: requestStatusState, actions: requestStatusActions } =
    useRequestStatusContext();
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();

  const [facilityType, setFacilityType] = useState<string | number | undefined>(
    filters.outgFacId ? 1 : filters?.substationId ? 2 : undefined
  );

  const requestStatusList = requestStatusState.list
    .filter((val) => val.name !== undefined)
    .filter(
      (val) => !outageCalendarExcludeStatus.includes(val.requestStatusId)
    );

  // We only have one filter value for facilityTypeId criteria. This is a special filter for Generator
  const facilityTypeIdList = [
    { facTypId: Lang.FACILITY_TYPE_ID_BACKEND.GENERATOR },
  ];

  const handleFacilityTypeId = useCallback(
    (id: number, checked = false) => {
      let arr: number[] = filters.facTypId ?? [];

      if (checked === false) {
        arr = arr.filter((v) => v !== id);
      }
      if (checked === true) {
        arr.push(id);
      }

      setFilters((values) => ({
        ...values,
        facTypId: arr,
      }));
    },
    [filters, setFilters, facilityTypeIdList]
  );

  const handleOutageType = useCallback(
    (id: number, checked = false) => {
      let arr: number[] = filters.outageTypeId ?? [];

      if (checked === false) {
        arr = arr.filter((v) => v !== id);
      }
      if (checked === true) {
        arr.push(id);
      }

      setFilters((values) => ({
        ...values,
        outageTypeId: arr,
      }));
    },
    [filters, setFilters, outageAuthTypeState.list]
  );

  const handleRequestStatus = useCallback(
    (id, checked) => {
      let arr: number[] = filters.outgReqStatusId ?? [];

      if (checked === false) {
        arr = arr.filter((v) => v !== id);
      }
      if (checked === true) {
        arr.push(id);
      }

      setFilters((values) => ({
        ...values,
        outgReqStatusId: arr,
      }));
    },
    [filters, requestStatusList, setFilters]
  );

  useEffect(() => {
    substationActions.listGET({ limit: 0, page: 1 });
    outageAuthTypeActions.activeListGET();
    realtimeLogActions.lineGET();
    requestStatusActions.listGET({ limit: 0, page: 1 });
    facilityActions.filterGET();
  }, [
    substationActions,
    outageAuthTypeActions,
    realtimeLogActions,
    facilityActions,
  ]);

  const substationListStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_READ
  );
  const lineListStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const outageAuthTypeStatus = getOutageTypeStatus(
    outageAuthTypeState,
    outageTypeActionTypes.OUTAGE_TYPE_LIST_READ
  );

  const handleFacilityName = useCallback(
    debounce((value) => {
      if (value.trim().length >= 4) {
        setFilters((values) => ({
          ...values,
          outgFacNm: value,
        }));
      }
    }, 500),
    [setFilters]
  );

  const filterLength = Object.keys(filters).filter(
    (v) =>
      ['outgFacNm', 'outgFacId', 'voltId', 'substationId'].includes(v) &&
      (Array.isArray(filters[v]) ? filters[v].length > 0 : !!filters[v])
  ).length;

  return (
    <>
      <ul className={styles.filterList}>
        {requestStatusList.map((value, key) => {
          return (
            <li key={key}>
              <RequestStatusCheckbox
                valueId={value.requestStatusId as unknown as number}
                name={`outageReq-${key}`}
                outageReqStatusList={handleRequestStatus}
                content={`${
                  Lang.LER_REQUEST_STATUS_CODE[value.requestStatusId]
                } - ${Lang.LER_REQUEST_STATUS[value.requestStatusId]}`}
                value={(
                  filters.outgReqStatusId || [value.requestStatusId]
                ).includes(value.requestStatusId)}
              />
            </li>
          );
        })}
      </ul>
      <hr className={styles.lineSeparator} />
      <div className={styles.calendarFilters}>
        <List className={styles.authTypeList} horizontal>
          {outageAuthTypeState.list.map((value, key) => {
            return (
              <OutageCheckbox
                key={key}
                valueId={value.outageTypeId as unknown as number}
                name={`outageReq-${key}`}
                outageAuthTypeList={handleOutageType}
                value={(filters.outageTypeId || [value.outageTypeId]).includes(
                  value.outageTypeId
                )}
                content={value.outageTypeDesc}
              />
            );
          })}
          {!outageAuthTypeStatus.fetching &&
            outageAuthTypeState.list.length > 0 &&
            facilityTypeIdList.map((value, key) => {
              return (
                <FacilityTypeIdCheckbox
                  key={key}
                  valueId={value.facTypId}
                  name={`outageReq-${key}`}
                  handleFacilityTypeId={handleFacilityTypeId}
                  filters={filters}
                  content="Show Only Generators"
                />
              );
            })}{' '}
        </List>
        <div>
          {downloadButton}
          <ATMPopover
            position="bottom left"
            content="Sort"
            on="hover"
            trigger={
              <ATMButton
                size="small"
                type="button"
                icon
                onClick={() => {
                  setShowSortFilters(!showSortFilters);
                }}
                secondary
                className={styles.filterBtn}
              >
                <ATMIcon name="sort" />
                {filterLength > 0 && (
                  <ATMLabel
                    color="blue"
                    className={styles.filterCount}
                    floating
                  >
                    {filterLength}
                  </ATMLabel>
                )}
              </ATMButton>
            }
          />
          <ATMPopover
            position="bottom left"
            content="Filters"
            on="hover"
            trigger={
              <ATMButton
                size="small"
                type="button"
                icon
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
                secondary
                className={styles.filterBtn}
              >
                <ATMIcon name="filter" />
                {filterLength > 0 && (
                  <ATMLabel
                    color="blue"
                    className={styles.filterCount}
                    floating
                  >
                    {filterLength}
                  </ATMLabel>
                )}
              </ATMButton>
            }
          />
        </div>
      </div>
      {showSortFilters ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <ATMGrid columns={4} textAlign="left">
              <ATMGrid.Column width={10} stretched>
                <span className={styles.sortBy}>
                  <OutageCalendarSortByOptions
                    name="sortBy"
                    label="Sort By"
                    setFilters={setSortBy as any}
                    defaultValue={filters?.sortListBy?.sortBy?.by ?? SortByOptions.Voltage}
                    filters={sortBy}
                  />
                </span>
                <span className={styles.sortBy}>
                  <OutageCalendarSortByOptions
                    name="thenBy"
                    label="Then By"
                    setFilters={setSortBy as any}
                    defaultValue={filters?.sortListBy?.thenBy?.by ?? SortByOptions.FacilityName}
                    filters={sortBy}
                  />
                </span>
              </ATMGrid.Column>
            </ATMGrid>
          </div>
        </div>
      ) : (
        <span />
      )}
      {showFilters ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <ATMGrid columns={4} textAlign="left">
              <ATMGrid.Column width={4} stretched>
                <span className={styles.selectables}>
                  <label className={styles.filterLbl}>
                    {Lang.LBL_FACILITY_NAME}
                  </label>
                  <ATMField
                    as={ATMInput}
                    control={control}
                    error={errors.outgFacNm}
                    size="small"
                    name="outgFacNm"
                    clearable
                    onChange={([_, { value }]) => {
                      handleFacilityName(value);

                      return value;
                    }}
                    placeholder="Enter"
                  />
                </span>
              </ATMGrid.Column>
              <ATMGrid.Column width={4} stretched>
                <span className={styles.selectables}>
                  <label className={styles.filterLbl}>
                    {Lang.LBL_FACILITY_TYPE}
                  </label>
                  <ATMDropdown
                    value={facilityType}
                    size="small"
                    selection
                    name="facility"
                    selectOnBlur={false}
                    placeholder="Facility Type"
                    clearable
                    options={Object.values(FacilityType)
                      .filter((val) => typeof val === 'number')
                      .map((value, key) => ({
                        key,
                        value,
                        text: Lang.FACILITY_TYPE[value],
                      }))}
                    onChange={(_, val) => {
                      if (
                        val.value === '' ||
                        val.value === undefined ||
                        val.value === null
                      ) {
                        setFacilityType('');
                      }
                      setFacilityType(val.value as number);
                      return val.value;
                    }}
                  />
                </span>
              </ATMGrid.Column>
              <ATMGrid.Column width={4} stretched>
                <span className={styles.selectables}>
                  {facilityType === 1 ? (
                    <>
                      <ATMField
                        as={ATMDropdown}
                        control={control}
                        selection
                        name="outgFacId"
                        label={<>&nbsp;</>}
                        loading={lineListStatus.fetching}
                        placeholder="Select"
                        size="small"
                        search
                        selectOnBlur={false}
                        disabled={
                          !facilityType ||
                          facilityType === undefined ||
                          facilityType === null
                        }
                        options={realtimeLogState.line?.map((elem) => {
                          return {
                            key: elem.outgFacId,
                            value: elem.outgFacId,
                            text: elem.outgFacNm,
                          };
                        })}
                        onChange={([_, { value }]) => {
                          setFilters((values) => ({
                            ...values,
                            substationId: undefined,
                            outgFacId: value ? Number(value) : undefined,
                          }));

                          return value;
                        }}
                        clearable
                      />
                    </>
                  ) : (
                    <>
                      <ATMField
                        as={ATMDropdown}
                        control={control}
                        selection
                        search
                        loading={substationListStatus.fetching}
                        name="substationId"
                        label={<>&nbsp;</>}
                        placeholder="Select"
                        selectOnBlur={false}
                        disabled={
                          facilityType === '' ||
                          facilityType === undefined ||
                          facilityType === null
                        }
                        size="small"
                        options={substationState.list?.map((elem) => ({
                          key: elem.substationId,
                          value: elem.substationId,
                          text: elem.name,
                        }))}
                        onChange={([_, { value }]) => {
                          setFilters((values) => ({
                            ...values,
                            outgFacId: undefined,
                            substationId: value || undefined,
                          }));

                          return value;
                        }}
                        clearable
                      />
                    </>
                  )}
                </span>
              </ATMGrid.Column>
              <ATMGrid.Column width={4} stretched>
                <span className={styles.selectables}>
                  <ATMField
                    as={ATMDropdown}
                    name="voltId"
                    control={control}
                    label={Lang.LBL_KV}
                    search
                    selection
                    selectOnBlur={false}
                    multiple
                    options={[
                      ...Array.from(new Set(facilityState.filters.volts)).map(
                        (val) => {
                          return {
                            key: val.voltId,
                            value: val.voltId,
                            text: val.name,
                          };
                        }
                      ),
                    ]}
                    onChange={([_, { value }]) => {
                      const voltIds = value as any[];
                      if (!voltIds.length) {
                        setFilters((values) => ({
                          ...values,
                          voltId: undefined,
                        }));
                      } else {
                        setFilters((values) => ({
                          ...values,
                          voltId: value,
                        }));
                      }
                      return value;
                    }}
                    clearable
                  />
                </span>
              </ATMGrid.Column>
            </ATMGrid>
          </div>
        </div>
      ) : (
        <span />
      )}
    </>
  );
};

export default OutageCalendarFilters;
