import {
  cancelledReportActionTypes,
  ICancelledReportState,
} from 'src/ducks/cancelled-report.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getCancelledReportStatus = (
  state: ICancelledReportState,
  action: keyof typeof cancelledReportActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
