import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SubstationReducer, {
  ISubstationState,
  defaultState,
  duckActions,
} from 'src/ducks/substation.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    SubstationReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type ISubstationContext = ReturnType<typeof useReducer>;

const SubstationContext = createContext<Partial<ISubstationContext>>({
  state: defaultState,
}) as React.Context<ISubstationContext>;

type IProps = {
  state?: Partial<ISubstationState>;
};

const SubstationProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <SubstationContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SubstationContext.Provider>
  );
};

const useSubstationContext = () => useContext(SubstationContext);

export type IUseSubstationContext = ReturnType<typeof useSubstationContext>;

export { SubstationContext, SubstationProvider, useSubstationContext };
