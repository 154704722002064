import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import FacilityReducer, {
  IFacilityState,
  defaultState,
  duckActions,
} from 'src/ducks/facility.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    FacilityReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IFacilityContext = ReturnType<typeof useReducer>;

const FacilityContext = createContext<Partial<IFacilityContext>>({
  state: defaultState,
}) as React.Context<IFacilityContext>;

type IProps = {
  state?: Partial<IFacilityState>;
};

const FacilityProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <FacilityContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </FacilityContext.Provider>
  );
};

const useFacilityContext = () => useContext(FacilityContext);

export type IUseFacilitiesContext = ReturnType<typeof useFacilityContext>;

export { FacilityContext, FacilityProvider, useFacilityContext };
