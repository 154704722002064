import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/iso-work-kind.service';
import { IIsoWorkKind } from 'src/models/iso-work-kind.model';

export const isoWorkKindActionTypes = {
  ISO_WORK_KIND_DATA_READ: 'ISO_WORK_KIND_DATA_READ',
  ISO_WORK_KIND_LIST_READ: 'ISO_WORK_KIND_LIST_READ',
  ISO_WORK_KIND_DATA_CREATE: 'ISO_WORK_KIND_DATA_CREATE',
  ISO_WORK_KIND_DATA_UPDATE: 'ISO_WORK_KIND_DATA_UPDATE',
  ISO_WORK_KIND_DATA_DELETE: 'ISO_WORK_KIND_DATA_DELETE',
  ISO_WORK_KIND_DATA_SET: 'ISO_WORK_KIND_DATA_SET',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: isoWorkKindActionTypes.ISO_WORK_KIND_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: isoWorkKindActionTypes.ISO_WORK_KIND_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: isoWorkKindActionTypes.ISO_WORK_KIND_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: isoWorkKindActionTypes.ISO_WORK_KIND_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: isoWorkKindActionTypes.ISO_WORK_KIND_DATA_DELETE,
    service: services.dataDELETE,
  },

  // This is a sync action
  setData: (isoWorkKind: IIsoWorkKind) => ({
    type: isoWorkKindActionTypes.ISO_WORK_KIND_DATA_SET,
    payload: {
      isoWorkKind,
    },
  }),
};

export type IIsoWorkKindAsync = typeof duckActions;

export interface IIsoWorkKindState
  extends ICommonState<typeof isoWorkKindActionTypes> {
  data?: IIsoWorkKind;
  list: IIsoWorkKind[];
  total: number;
}

export const defaultState: IIsoWorkKindState = {
  status: {},
  list: [],
  total: 0,
};

const IsoWorkKindReducer = (
  state: IIsoWorkKindState,
  action: IReducerAction<IIsoWorkKindAsync>
): IIsoWorkKindState => {
  switch (action.type) {
    case isoWorkKindActionTypes.ISO_WORK_KIND_DATA_SET:
    case isoWorkKindActionTypes.ISO_WORK_KIND_DATA_READ:
    case isoWorkKindActionTypes.ISO_WORK_KIND_DATA_UPDATE:
    case isoWorkKindActionTypes.ISO_WORK_KIND_DATA_CREATE: {
      return {
        ...state,
        data: action.payload?.isoWorkKind,
      };
    }

    case isoWorkKindActionTypes.ISO_WORK_KIND_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case isoWorkKindActionTypes.ISO_WORK_KIND_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.isoWorkKindId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default IsoWorkKindReducer;
