import {
  cancelTypeActionTypes,
  ICancelTypeState,
} from 'src/ducks/cancel-type.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getCancelTypeStatus = (
  state: ICancelTypeState,
  action: keyof typeof cancelTypeActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
