import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IUserGrpForm } from 'src/models/facility.model';
import style from '../../facility-notification-items.module.scss';

type IProps = {
  isOpen: boolean;
  loading: boolean;
  defaultValues?: Partial<IUserGrpForm>;
  handleSave: () => void;
  handleOpen: (data) => void;
};

const FacilityNotifApprovalsItemEditView: React.FC<IProps> = ({
  isOpen,
  loading,
  defaultValues,
  handleOpen,
  handleSave,
}) => {
  const actionBtns = (
    <div className={style.actionBtns}>
      <ATMButton
        secondary
        type="button"
        content={Lang.LBL_CANCEL}
        onClick={() => handleOpen(!isOpen)}
      />
      <ATMButton
        negative
        type="button"
        content={Lang.LBL_REMOVE}
        loading={loading}
        disabled={loading}
        onClick={handleSave}
      />
    </div>
  );
  return (
    <ATMModal
      size="small"
      open={isOpen}
      trigger={
        <ATMDropdown.Item
          icon="trash alternate outline"
          text={Lang.LBL_REMOVE}
          onClick={() => handleOpen(!isOpen)}
        />
      }
      header={Lang.formatString(
        Lang.LBL_DELETE_GROUP,
        defaultValues?.userGrpNm ?? '--'
      )}
      content={
        <div className={style.content}>
          {Lang.formatString(
            Lang.MSG_CONFIRM_USER_GROUP_DELETE,
            defaultValues?.userGrpNm ?? '--'
          )}
        </div>
      }
      actions={actionBtns}
    />
  );
};

export default FacilityNotifApprovalsItemEditView;
