import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import RolesReducer, {
  IRolesState,
  defaultState,
  duckActions,
} from 'src/ducks/roles.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    RolesReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IRolesContext = ReturnType<typeof useReducer>;

const RolesContext = createContext<Partial<IRolesContext>>({
  state: defaultState,
}) as React.Context<IRolesContext>;

type IProps = {
  state?: Partial<IRolesState>;
};

const RolesProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <RolesContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

const useRolesContext = () => useContext(RolesContext);

export type IUseRolesContext = ReturnType<typeof useRolesContext>;

export { RolesContext, RolesProvider, useRolesContext };
