import React, { useCallback, useState } from 'react';
import {
  ATMSegment,
  ATMGrid,
  ATMTab,
  ATMMenu,
  IATMTabProps,
  ATMMessage,
  ATMIcon,
  ATMHeader,
  ATMTable,
  ATMCheckbox,
  ATMField,
  ATMLabel,
  ATMTextArea,
  ATMButton,
  MOLInfoText,
} from 'shared-it-appmod-ui';
import { Breadcrumb } from 'semantic-ui-react';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { LEREmailType, LERNotificationStatus } from 'src/constants';
import { ILerRequestApproveRejectEmail } from 'src/models/ler-request.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { getUser } from 'src/libraries/amplify.library';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import style from './email-link-page.module.scss';
import LerRequestDocumentsDetail from '../ler-request/ler-request-documents/ler-request-documents-detail.component';

const EmailLinkPageView: React.FC = () => {
  const { state, actions } = useLerRequestContext();
  const [comment, setComment] = useState();

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_EMAIL_STATUS_UPDATE
  );
  const dataEmpIds = state.data?.authorizationStatement.emailGroups
    ?.filter((val) => val.groupId === state.emailGroup?.groupId)
    .map((val) =>
      val.userGroup.userGrpMembers?.map((item) =>
        item.empId.substring(item.empId.length - 5)
      )
    )[0] ?? [''];

  const handleApprove = useCallback(async () => {
    const formData: ILerRequestApproveRejectEmail = {
      requestId: state.emailGroup?.requestId ?? 0,
      groupId: state.emailGroup?.groupId ?? 0,
      version_no: state.emailGroup?.version_no ?? '',
      comments: comment,
      status: LERNotificationStatus.APPROVED,
    };

    const response = await actions.approveRejectEmailPUT(formData);

    if (!response.error) {
      ToastSuccess(Lang.MSG_EMAIL_GROUP_APPROVE_SUCCESS);
    } else {
      ToastError(response.error.message);
    }
  }, [actions, comment]);

  const handleReject = useCallback(async () => {
    const formData: ILerRequestApproveRejectEmail = {
      requestId: state.emailGroup?.requestId ?? 0,
      groupId: state.emailGroup?.groupId ?? 0,
      version_no: state.emailGroup?.version_no ?? '',
      comments: comment,
      status: LERNotificationStatus.REJECTED,
    };

    const response = await actions.approveRejectEmailPUT(formData);

    if (!response.error) {
      ToastSuccess(Lang.MSG_EMAIL_GROUP_APPROVE_SUCCESS);
    } else {
      ToastError(response.error.message);
    }
  }, [actions, comment]);

  const emailSubheaderSections = [
    {
      key: 'modify',
      content: state.data?.outageFacility.facility?.outgFacNm,
      link: false,
    },
    {
      key: 'add',
      content: (
        <ATMLabel color="blue">
          {Lang.LER_REQUEST_STATUS[state.data?.requestStat ?? 14]}
        </ATMLabel>
      ),
      link: false,
    },
    {
      key: 'modify',
      content: state.data?.outageFacility?.outageType?.outageTypeDesc,
      link: false,
    },
  ];

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: <ATMMenu.Item key="0">{Lang.TTL_JOB_INFO}</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false} className={style.paneJobInfo}>
          <ATMMessage color="blue">
            {' '}
            {state.emailGroup?.emailType === LEREmailType.NOTIFICATION ? (
              <span> Please review the LER details </span>
            ) : (
              <span>
                Please review the LER and <b>Approve</b> or <b>Reject</b> in the
                section below{' '}
              </span>
            )}
          </ATMMessage>
          <ATMHeader className={style.headerIcon}>
            <ATMIcon
              circular
              size="small"
              color="blue"
              name="clipboard outline"
              className={style.iconSize}
            />
            <span className={style.titleMargin}>{Lang.TTL_JOB_INFO}</span>
          </ATMHeader>
          <div className={style.switchInOut}>
            <p className={style.margin}>{Lang.TTL_SWITCH_IN_OUT_INFORMATION}</p>
            <div className={style.minutesSection}>
              <span>{Lang.LBL_SWITCH_OUT}</span>
              <span>{Lang.LBL_SWITCH_IN}</span>
            </div>
            <div className={style.minutesSection}>
              <span className={style.minute}>
                {state.data?.jobInformation.switchOutMin ?? '-'}
              </span>
              <span className={style.bolder}>
                {state.data?.jobInformation.switchInMin ?? '-'}
              </span>
            </div>
            <ATMTable className={style.tableJobInfo}>
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.HeaderCell width={2}>
                    {Lang.LBL_NO}
                  </ATMTable.HeaderCell>
                  <ATMTable.HeaderCell textAlign="left" width={5}>
                    {Lang.LBL_SHORT_DESCRIPTION_CATEGORY}
                  </ATMTable.HeaderCell>
                  <ATMTable.HeaderCell textAlign="left" width={5}>
                    {Lang.LBL_SHORT_DESCRIPTION}
                  </ATMTable.HeaderCell>
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {state.data?.jobInformation.shortDescriptions &&
                state.data?.jobInformation.shortDescriptions.length > 0 ? (
                  state.data?.jobInformation.shortDescriptions?.map(
                    (item, i) => (
                      <ATMTable.Row key={i}>
                        <ATMTable.Cell>{item.seqNo}</ATMTable.Cell>
                        <ATMTable.Cell>
                          {item.category?.shortDescCat}
                        </ATMTable.Cell>
                        <ATMTable.Cell>
                          {item.description?.shortDesc}
                        </ATMTable.Cell>
                      </ATMTable.Row>
                    )
                  )
                ) : (
                  <ATMTable.Row textAlign="center">
                    <ATMTable.Cell />
                    <ATMTable.Cell>
                      {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                    </ATMTable.Cell>
                    <ATMTable.Cell />
                  </ATMTable.Row>
                )}
              </ATMTable.Body>
            </ATMTable>
            <div>
              <ATMGrid columns={3}>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_WORK_ORDER}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.woNbr ?? '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>{Lang.LBL_PROJECT_ID}</p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.projectId ?? '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_WORK}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.wrkDesc ?? '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_NOTES}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.jobNotes ?? '-'}
                    </span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_RADIALIZED}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.radializedSubs &&
                      state.data.jobInformation.radializedSubs.length > 0
                        ? state.data?.jobInformation.radializedSubs?.map(
                            (val, i) => (
                              <ATMLabel key={i}>{val.substationId}</ATMLabel>
                            )
                          )
                        : '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_RIMS_ID}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.rimsProjectId ?? '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_RIMS_PHASE}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.rimsProjectPhase ?? '-'}
                    </span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>{Lang.LBL_JOB_INFO_TB}</p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.facTbsCoord ?? '-'}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_GROUNDED}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.clsdGrndDsconctInd
                        ? Lang.LBL_YES
                        : Lang.LBL_NO}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_RECALL}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.recalTm ?? '-'}
                    </span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_TEST_VOLTAGE}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.testVoltageApplied
                        ? Lang.LBL_YES
                        : Lang.LBL_NO}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <span className={style.bolder}>
                      <ATMField
                        as={ATMCheckbox}
                        name="splitCalculation"
                        label="Split SA/DI Calculation"
                        inline
                        checked={
                          state.data?.jobInformation.splitSaidiCalcInd ?? false
                        }
                      />
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column width={4}>
                    <p className={style.columnPSpace}>
                      {Lang.LBL_JOB_INFO_ISOLATION}
                    </p>
                    <span className={style.bolder}>
                      {state.data?.jobInformation.isolationPoints ?? '-'}
                    </span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={8}>
                    <span className={style.bolder}>
                      <ATMCheckbox
                        name="workAllowed"
                        label="If allowed to proceed work during inciement weather"
                        inline
                        disabled
                        checked={
                          state.data?.jobInformation.inclmntWthrInd ?? false
                        }
                      />
                    </span>
                    <span className={style.bolder}>
                      <ATMCheckbox
                        name="phsReqIndRequired"
                        label="Phasing requierd"
                        inline
                        disabled
                        checked={state.data?.jobInformation.phsReqInd ?? false}
                      />
                    </span>
                    <span className={style.bolder}>
                      <ATMCheckbox
                        name="affectsRASSPS"
                        label="Affects RAS/SPS"
                        inline
                        disabled
                        checked={
                          state.data?.jobInformation.affectsRasSpsInd ?? false
                        }
                      />
                    </span>
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row
                  style={
                    state.emailGroup?.emailType === LEREmailType.NOTIFICATION
                      ? { display: 'none' }
                      : {}
                  }
                >
                  <ATMGrid.Column width={10}>
                    <ATMField
                      as={ATMTextArea}
                      label={Lang.LBL_COMMENTS}
                      placeholder="Enter"
                      name="comment"
                      onChange={
                        ((_, { value }) => {
                          setComment(value);
                          return value;
                        }) as any
                      }
                      disabled={
                        state.emailGroup?.emailType ===
                          LEREmailType.NOTIFICATION ||
                        state.emailGroup?.status ===
                          LERNotificationStatus.APPROVED
                      }
                    />
                    <ATMButton
                      secondary
                      negative
                      content="Reject Request"
                      onClick={handleReject}
                      disabled={
                        state.emailGroup?.emailType ===
                          LEREmailType.NOTIFICATION ||
                        state.emailGroup?.status ===
                          LERNotificationStatus.APPROVED
                      }
                      loading={loading.fetching}
                    />
                    <ATMButton
                      color="green"
                      content="Accept Request"
                      disabled={
                        state.emailGroup?.emailType ===
                          LEREmailType.NOTIFICATION ||
                        state.emailGroup?.status ===
                          LERNotificationStatus.APPROVED
                      }
                      onClick={handleApprove}
                      loading={loading.fetching}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </ATMGrid>
            </div>
          </div>
        </ATMTab.Pane>
      ),
    },
    {
      menuItem: <ATMMenu.Item key="1">{Lang.TTL_OUTAGES_DATES}</ATMMenu.Item>,
      render: () => (
        <ATMTab.Pane attached={false}>
          <ATMHeader className={style.headerIcon}>
            <ATMIcon
              circular
              size="small"
              color="blue"
              name="calendar outline"
              className={style.iconSize}
            />
            <span className={style.titleMargin}>{Lang.TTL_OUTAGES_DATES}</span>
          </ATMHeader>

          <ATMGrid columns={3} className={style.outagesDates}>
            <ATMGrid.Row>
              <ATMGrid.Column width={4} className={style.semibolder}>
                {Lang.LBL_DATE}
              </ATMGrid.Column>
              <ATMGrid.Column width={4} className={style.semibolder}>
                {Lang.LBL_STARTS}
              </ATMGrid.Column>
              <ATMGrid.Column width={4} className={style.semibolder}>
                {Lang.LBL_ENDS}
              </ATMGrid.Column>
            </ATMGrid.Row>
            {state.data?.outageDates.map((val, i) => (
              <ATMGrid.Row key={i}>
                <ATMGrid.Column width={4} className={style.semibolder}>
                  <ATMLabel
                    circular
                    color="orange"
                    inline
                    empty
                    className={val.allDay ? style.dotIconSecond : style.dotIcon}
                  />
                  {formatDate(val.startTm)}
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  {format24hTime(val.startTm)}
                </ATMGrid.Column>
                <ATMGrid.Column width={4}>
                  {format24hTime(val.stopTm)}
                </ATMGrid.Column>
              </ATMGrid.Row>
            ))}
          </ATMGrid>
        </ATMTab.Pane>
      ),
    },
  ];

  return !dataEmpIds.includes(getUser()?.emp_no ?? '') ? (
    <div>
      <div className={style.noYards}>
        <ATMIcon
          circular
          color="grey"
          inverted
          name="info"
          size="large"
          style={{
            display: 'inline-block',
            marginBottom: '0.8em',
            marginTop: '3em',
            verticalAlign: 'center',
          }}
        />
        <p>{Lang.MSG_EMAIL_GROUP_NO_ACCESS}</p>
      </div>
    </div>
  ) : (
    <>
      <ATMSegment className={style.emailHeaderTitle}>
        <h4>LER {state.data?.requestId}</h4>
        <Breadcrumb
          divider=" | "
          size="small"
          sections={emailSubheaderSections}
        />
      </ATMSegment>
      <ATMSegment className={style.mainSegment}>
        <ATMGrid columns={2}>
          <ATMGrid.Row>
            <ATMGrid.Column width={10} className={style.divider}>
              <Tabs
                name="emailPage"
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                activeIndex={1}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={6}>
              <div className={style.facilitySectionMargin}>
                <ATMHeader className={style.headerIcon}>
                  <ATMIcon
                    circular
                    size="small"
                    color="blue"
                    name="building outline"
                    className={style.iconSize}
                  />
                  <span className={style.titleMargin}>
                    {Lang.TTL_FACILITY_INFORMATION}
                  </span>
                </ATMHeader>
                <ATMGrid columns={2} className={style.gridMargin}>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_FACILITY_TYPE}
                      </p>
                      <span className={style.bolder}>
                        {state.data?.outageFacility.facility?.facTyp?.facTypNm}
                      </span>
                    </ATMGrid.Column>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_KV}
                      </p>
                      <span className={style.bolder}>
                        {state.data?.outageFacility.facility?.volt?.voltNm}
                      </span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_EQUIPMENT}
                      </p>
                      <span className={style.bolder}>
                        {state.data?.outageFacility.outgFacId &&
                        state.data.outageFacility.substationId
                          ? state.data?.outageFacility.facility?.outgFacNm
                          : '-'}
                      </span>
                    </ATMGrid.Column>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_LINE_STATION}
                      </p>
                      <span className={style.bolder}>
                        {state.data?.outageFacility.outgFacId &&
                        state.data.outageFacility.substationId
                          ? state.data.outageFacility.substation?.name
                          : state.data?.outageFacility.facility?.outgFacNm}
                      </span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row>
                    <ATMGrid.Column width={8}>
                      <p className={style.columnPSpace}>
                        {Lang.LBL_FACILITY_INFO_AUTHORIZATION}
                      </p>
                      <span className={style.bolder}>
                        {state.data?.outageFacility.outageType?.outageTypeDesc}
                      </span>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                </ATMGrid>
                <hr />
              </div>
              <div>
                <ATMHeader className={style.headerIcon}>
                  <ATMIcon
                    circular
                    size="small"
                    color="blue"
                    name="file outline"
                    className={style.iconSize}
                  />
                  <span className={style.titleMargin}>
                    {Lang.TTL_DOCUMENTS}
                  </span>
                </ATMHeader>
                <LerRequestDocumentsDetail
                  isSubmitted
                  id={state.data?.requestId}
                  viewType="view"
                  noHeader
                />
                <MOLInfoText transparent content={Lang.NTE_DOWNLOAD_FILE_LER} />
                <hr />
              </div>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
    </>
  );
};

export default EmailLinkPageView;
