import {
  rejectedReportActionTypes,
  IRejectedReportState,
} from 'src/ducks/rejected-report.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getRejectedReportStatus = (
  state: IRejectedReportState,
  action: keyof typeof rejectedReportActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
