import yup from 'src/libraries/validator.library';

export const JobCodeRuleShape = {
  jobCd: yup.string().required(),
  jobTitle: yup.string().nullable(),
  key30: yup.boolean().required(),
  key40: yup.boolean().required(),
  auth: yup.boolean().required(),
  subEntry: yup.boolean().required(),
  jobCdStat: yup.string(),
};

export const JobTitlesShape = {
  jobCd: yup.string().required(),
  jobTitle: yup.string().required(),
  jobDescription: yup.string().required(),
  jobStatus: yup.string().required(),
  companyID: yup.string().required(),
  companyName: yup.string().required(),
};

export const JobTitlesSchema = yup.object(JobTitlesShape).defined();

export const JobTitlesSearchShape = {
  JobCode: yup.string().required(),
  JobTitle: yup.string().required(),
  JobDescription: yup.string().required(),
  JobStatus: yup.string().required(),
  CompanyID: yup.string().required(),
  CompanyName: yup.string().required(),
};

export const JobTitlesSearchSchema = yup.object(JobTitlesSearchShape).defined();

export const JobTitlesSearchPayloadShape = {
  recordCount: yup.number().required(),
  pageNumber: yup.number().required(),
  pageSize: yup.number().required(),
  data: yup.array().of(JobTitlesSearchSchema).defined(),
};

export const JobTitlesSearchPayloadSchema = yup.object(
  JobTitlesSearchPayloadShape
);

export const JobCodeRuleSchema = yup.object(JobCodeRuleShape);

export const JobCodeRuleFormSchema = yup.object({
  jobCd: yup.string().required(),
  key30: yup.boolean().required(),
  key40: yup.boolean().required(),
  auth: yup.boolean().required(),
  subEntry: yup.boolean().required(),
  jobCdStat: yup.string(),
});

export const JobCodeRuleListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(JobCodeRuleSchema.clone()),
});

export const JobCodeRulePayloadSchema = JobCodeRuleFormSchema.clone();

export const JobCodeRuleDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IJobCodeRule = yup.Asserts<typeof JobCodeRuleSchema>;
export type IJobCodeRuleForm = yup.Asserts<typeof JobCodeRuleFormSchema>;

export type IJobTitles = yup.Asserts<typeof JobTitlesSchema>;
export type IJobTitlesSearch = yup.Asserts<typeof JobTitlesSearchSchema>;
export type IJobTitlesSearchPayload = yup.Asserts<
  typeof JobTitlesSearchPayloadSchema
>;
