import React, { useState } from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import { AccessRole } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import { ITroubleJobs, ITroubleJobsForm } from 'src/models/trouble-jobs.model';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import Lang from 'src/libraries/language';
import TroubleJobsForm from '../trouble-jobs-form/trouble-jobs-form.component';
import styles from './trouble-jobs-edit.module.scss';

type IProps = {
  defaultValues: ITroubleJobs;
  isOpenEdit: boolean;
  loading: boolean;
  loadingElement: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  beginDateSelected: Date;
  endDateSelected: Date;
  handleClick: () => void;
  handleSubmit: (data: ITroubleJobsForm) => void;
  setIsOpenEdit: (id?: number) => void;
  setBeginDateSelected: (beginDate: Date | undefined) => void;
  setEndDateSelected: (endDate: Date | undefined) => void;
};

const TroubleJobsEditView: React.FC<IProps> = ({
  defaultValues,
  loading,
  loadingElement,
  formRef,
  beginDateSelected,
  endDateSelected,
  handleClick,
  handleSubmit,
  setIsOpenEdit,
  setBeginDateSelected,
  setEndDateSelected,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <MOLRightPanel
      header={null}
      subHeaderContent={
        <div>
          <ATMGrid>
            <ATMGrid.Row>
              <ATMGrid.Column width={13}>
                <ATMHeader as="h3" style={{ display: 'inline-block' }}>
                  {Lang.TTL_TROUBLE_JOB_EDIT} {defaultValues.troubleJobId}
                </ATMHeader>
                <span style={{ marginLeft: '0.833em' }}>|</span>
                <ListStatus label value={defaultValues.jobStatus} />
              </ATMGrid.Column>
              <ATMGrid.Column floated="right">
                <ATMButton
                  type="button"
                  color="grey"
                  basic
                  icon="close"
                  onClick={() => setIsOpenEdit()}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      }
      isOpen={!!defaultValues.troubleJobId}
      onOpen={() => setIsOpenEdit(defaultValues.troubleJobId)}
      onClose={() => setIsOpenEdit()}
      className={styles.molRightPanel}
      panelFooter={
        <div>
          <div>
            <ATMButton secondary size="small" onClick={() => setIsOpenEdit()}>
              {Lang.LBL_CLOSE}
            </ATMButton>
            {getIsAdmin(AccessRole.TROUBLE_JOB_UPDATER) && (
              <ATMButton
                primary
                size="small"
                loading={loadingElement}
                disabled={!isDirty || loading}
                onClick={handleClick}
              >
                {Lang.LBL_UPDATE_TROUBLE_JOB}
              </ATMButton>
            )}
          </div>
        </div>
      }
    >
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <TroubleJobsForm
              defaultValues={defaultValues}
              formRef={formRef}
              handleSubmit={handleSubmit}
              handleEnable={(value) => setIsDirty(value)}
              beginDateSelected={beginDateSelected}
              endDateSelected={endDateSelected}
              setBeginDateSelected={setBeginDateSelected}
              setEndDateSelected={setEndDateSelected}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </MOLRightPanel>
  );
};

export default TroubleJobsEditView;
