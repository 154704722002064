/* eslint-disable quote-props */
import * as yup from 'yup';
import { FacilityShape } from './facility.model';
import { LerRequestOutageDateShape } from './ler-request.model';
import { VoltShape } from './volt.model';

export const OutageRequestStatusShape = {
  outgReqStatusId: yup.number().required(),
  statDescription: yup.string().required(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const RequestFacilityShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  outgFacId: yup.number().required(),
  relayNbrs: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
  voltId: yup.number().nullable().when('substationId', {
    is: true,
    then: yup.number().required(),
  }),
  substationId: yup.string().nullable(),
  outgTypId: yup.number().nullable().when('substationId', {
    is: true,
    then: yup.number().required(),
  }),
};

export const OutageTypeShape = {
  outageTypeId: yup.number().required(),
  outageTypeDesc: yup.string().required(),
  updatedBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  displayOrder: yup.number().required(),
  outageStatus: yup.string().required(),
};

export const OutageDateSchema = yup.object(LerRequestOutageDateShape);

export const OutageDateCreateShape = {
  ...LerRequestOutageDateShape,
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
  requestId: yup.number(),
  version_no: yup.string(),
};

export const SortBySchema = yup.object({
  sortBy: yup
    .object({
      by: yup.string().nullable(),
      orderBy: yup.string().nullable(),
    })
    .nullable(),
  thenBy: yup
    .object({
      by: yup.string().nullable(),
      orderBy: yup.string().nullable(),
    })
    .nullable(),
});

export const OutageDateFilterShape = {
  requestId: yup.number(),
  outgFacNm: yup.string().min(4, 'Enter a minimum of four characters'),
  days: yup.number(),
  date: yup.date(),
  outageTypeId: yup.array().of(yup.number().defined()),
  outgReqStatusId: yup.array().of(yup.number().defined()),
  outgFacId: yup.number(),
  voltId: yup.array().of(yup.number().defined()),
  facTypId: yup.array().of(yup.number().defined()),
  substationId: yup.string(),
  sortListBy: SortBySchema.clone().nullable(),
};

export const OutageDateFullPayloadShape = {
  ...LerRequestOutageDateShape,
  status: yup.object({
    ...OutageRequestStatusShape,
  }),
  requestFacility: yup.object({
    ...RequestFacilityShape,
    facility: yup.object({
      ...FacilityShape,
      volt: yup
        .object({
          ...VoltShape,
        })
        .nullable(),
    }),
    outageType: yup.object({
      ...OutageTypeShape,
    }),
  }),
  wrkDesc: yup.string(),
};

export const MapOfOutageByVoltShape = {
  '0500': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0500/230': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0500/17.5': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230/138': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230/69': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230/18': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230/16/13.8': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0230/13.8': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0138': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0138/69': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0138/12': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0138/3.2': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '088': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '088/69': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '088/69/12': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '069': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '069/12': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '069/4': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '012': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '012/3.2': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '03.2': yup.array().of(yup.object(OutageDateFullPayloadShape)),
  '0undefined': yup.array().of(yup.object(OutageDateFullPayloadShape)), // Mapping for 0-volt records
};

export const OutageDateCalendarPayloadShape = {
  ...LerRequestOutageDateShape,
  outageDates: yup.array().required().of(OutageDateSchema.clone()),
  status: yup.object({
    ...OutageRequestStatusShape,
  }),
  requestFacility: yup
    .object({
      ...RequestFacilityShape,
      facility: yup.object({
        ...FacilityShape,
        volt: yup.object({
          ...VoltShape,
        }),
      }),
      outageType: yup.object({
        ...OutageTypeShape,
      }),
    })
    .required(),
  wrkDesc: yup.string(),
};

export const OutageRequestStatusSchema = yup.object(OutageRequestStatusShape);
export const OutageDateCalendarPayloadSchema = yup.object(
  OutageDateCalendarPayloadShape
);
export const OutageTypeSchema = yup.object(OutageTypeShape);
export const RequestFacilitySchema = yup.object(RequestFacilityShape);
export const OutageDateCreateSchema = yup.object(OutageDateCreateShape);
export const OutageDateFilterSchema = yup.object(OutageDateFilterShape);
export const OutageDateFullPayloadSchema = yup.object(
  OutageDateFullPayloadShape
);
export const MapOfOutageByVoltSchema = yup.object(MapOfOutageByVoltShape);

export type IOutageDate = yup.Asserts<typeof OutageDateSchema>;
export type IOutageDateCreate = yup.Asserts<typeof OutageDateCreateSchema>;
export type IOutageDateFilter = Partial<
  yup.Asserts<typeof OutageDateFilterSchema>
>;
export type IOutageDateCalendar = yup.Asserts<
  typeof OutageDateCalendarPayloadSchema
>;
export type IRequestFacility = yup.Asserts<typeof RequestFacilitySchema>;
export type IOutageRequestStatus = yup.Asserts<
  typeof OutageRequestStatusSchema
>;
export type IOutageDateFullPayload = Partial<
  yup.Asserts<typeof OutageDateFullPayloadSchema>
>;
export type IOutageType = yup.Asserts<typeof OutageTypeSchema>;
export type IMapOfOutageByVoltSchema = Partial<
  yup.Asserts<typeof MapOfOutageByVoltSchema>
>;
