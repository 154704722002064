/* eslint-disable react/no-danger */
import React from 'react';
import {
  ATMGrid,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import SettingsEdit from './auth-settings-edit/auth-settings-edit.component';

type IProps = {
  data: any[];
  loading: boolean;
  handleFetch: () => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const AuthSettingsView: React.FC<IProps> = ({ data, loading, handleFetch }) => {
  const columns: IORGDataTableColumn<any>[] = [
    {
      index: 'description',
      width: '50%',
      title: Lang.LBL_PROPERTY,
    },
    {
      index: 'value',
      title: Lang.LBL_VALUES,
      render: (_, { value }) => {
        const textValue = value.split('\n').join('<br/>');
        return <div dangerouslySetInnerHTML={{ __html: textValue }} />;
      },
    },
    {
      index: 'action',
      title: Lang.LBL_ACTION,
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, value) => (
        <SettingsEdit data={value} handleFetch={handleFetch} />
      ),
    },
  ];

  return (
    <div>
      <ATMGrid columns={2}>
        <ATMGrid.Column verticalAlign="middle">{/** */}</ATMGrid.Column>
        <ATMGrid.Column textAlign="right">{/** */}</ATMGrid.Column>
      </ATMGrid>

      <div>
        <ORGDataTable
          columns={columns}
          data={data}
          celled={false}
          counter
          loading={loading}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};

export default AuthSettingsView;
