import React, { useEffect, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMSelect,
  ATMTextArea,
  ATMDatePicker,
  ATMFormProvider,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment, { format24hDateTime } from 'src/libraries/moment.library';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  IEntryReason,
  ISubstationEntry,
  ISubstationEntryForm,
  SubstationEntryFormSchema,
} from 'src/models/substation-entry.model';
import { useEntryReasonContext } from 'src/contexts/entry-reason.context';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { getEmployeeName } from 'src/helpers/employee.helper';
import moment from 'moment';
import { TimeHourMinuteInput } from 'src/components/atoms/input/time-input-format.component';
import { SubstationTimeStatus } from 'src/constants/substation-entry.constant';
import { useFileContext } from 'src/contexts/file.context';
import SubstationEntryLogDocumentsDetail from '../substation-entry-log-document/substation-entry-log-document-details.component';
import { handleTimeStatus } from '../substation-entry-log-list/substation-entry-log-list-today/substation-entry-log-list-today.component';
import style from './substation-entry-log-form.module.scss';

const DateFormat = 'YYYY/MM/DD';
const TimeFormat = 'HH:mm';

enum TimeType {
  In = 'inDateTime',
  Out = 'outDateTime',
}

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: ISubstationEntry;
  isFileCached: boolean;
  handleSubmit: (data: ISubstationEntryForm) => void;
  handleEnable: (value: boolean) => void;
  setIsFileCached: (value: boolean) => void;
  handleRemovedFiles: (value: boolean) => void;
};

const FormContent: React.FC<{
  defaultValues?: ISubstationEntry;
  entryReasonList: IEntryReason[];
  setIsFileCached: (value: boolean) => void;
  handleRemovedFiles: (value: boolean) => void;
  setIsUpdated: (value: boolean) => void;
}> = ({
  defaultValues,
  entryReasonList,
  setIsFileCached,
  setIsUpdated,
  handleRemovedFiles,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
    register,
  } = useATMFormContext<ISubstationEntryForm>();
  const { state: fileState } = useFileContext();

  const data = watch([
    TimeType.In,
    TimeType.Out,
  ]) as unknown as ISubstationEntryForm;

  const [timeIn, setTimeIn] = useState(
    data ? Moment(data[0]).format(TimeFormat) : Moment().format(TimeFormat)
  );

  const [timeOut, setTimeOut] = useState(
    data && data[1] ? Moment(data[1]).format(TimeFormat) : ''
  );

  const [inDateTime, setInDateTime] = useState<string | Date | undefined>(
    (defaultValues?.inDateTime &&
      format24hDateTime(defaultValues?.inDateTime)) ??
      new Date()
  );

  const [outDateTime, setOutDateTime] = useState<string | Date | undefined>(
    (defaultValues?.outDateTime &&
      format24hDateTime(defaultValues?.outDateTime)) ??
      undefined
  );

  return (
    <ATMGrid>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <ATMField
            name="substationEntryLogId"
            control={control}
            type="hidden"
            className="hidden"
          />
          <ATMField
            name="empId"
            control={control}
            type="hidden"
            className="hidden"
          />
          <FieldReadOnly label={Lang.LBL_FULLNAME}>
            <p>
              {defaultValues && `${getEmployeeName(defaultValues.employee)}`}
            </p>
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={5}>
          <ATMField
            name="substationId"
            control={control}
            type="hidden"
            className="hidden"
          />

          <FieldReadOnly label={Lang.LBL_SUBSTATION}>
            <p>{defaultValues && defaultValues.substationId}</p>
          </FieldReadOnly>
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <ATMField
            name="status"
            control={control}
            type="hidden"
            className="hidden"
          />

          <ATMField
            key={`reason_${entryReasonList.length}`}
            name="entryReasonId"
            placeholder={Lang.LBL_ACTIVITY}
            label={Lang.LBL_ACTIVITY}
            as={ATMSelect}
            control={control}
            error={errors.entryReasonId}
            style={{ display: 'block', marginRight: '1em' }}
            options={entryReasonList.map((val) => {
              return {
                value: val.entryReasonId,
                text: val.description,
                key: val.entryReasonId,
              };
            })}
            onChange={([_, { value }]) => value}
            search
            selection
            clearable
            selectOnBlur={false}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row className={style.timePicker}>
        <ATMGrid.Column width={5}>
          <ATMField
            label={<LabelRequired>Date In</LabelRequired>}
            as={ATMDatePicker}
            size="small"
            type="basic"
            name={TimeType.In}
            format={DateFormat}
            defaultValue={moment(defaultValues?.inDateTime, DateFormat)
              .utc(false)
              .toDate()}
            control={control}
            value={inDateTime}
            minDate={
              (defaultValues?.inDateTime &&
                moment(defaultValues?.inDateTime).startOf('day').toDate()) ??
              moment(new Date(), DateFormat).startOf('day').toDate()
            }
            error={errors[TimeType.In]}
            onChange={([_, { value }]) => {
              if (!value) {
                setInDateTime(undefined);
              }
              if (value) {
                setInDateTime(
                  Moment(
                    `${Moment(value).format(DateFormat)}`,
                    `${DateFormat} ${TimeFormat}`
                  ).toDate()
                );
                return Moment(
                  `${Moment(value).format(DateFormat)}`,
                  `${DateFormat} ${TimeFormat}`
                ).toDate();
              }
              return undefined;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={5}>
          <TimeHourMinuteInput
            name="inTime"
            control={control}
            setInputValue={setTimeIn}
            inputValue={timeIn}
            enableRequired
            error={errors.inTime}
            noMargin
            label={Lang.LBL_TIME_IN_HOUR}
            callback={setIsUpdated}
            setValue={setValue}
            setError={setError}
            register={register}
            clearErrors={clearErrors}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      {handleTimeStatus(
        defaultValues?.inDateTime ?? new Date(),
        defaultValues?.outDateTime
      ).language === SubstationTimeStatus.OUT ? (
        <ATMGrid.Row className={style.timePicker}>
          <ATMGrid.Column width={5}>
            <ATMField
              label="Date Out"
              as={ATMDatePicker}
              size="small"
              type="basic"
              name={TimeType.Out}
              format={DateFormat}
              defaultValue={
                defaultValues?.outDateTime &&
                moment(defaultValues?.outDateTime, DateFormat)
                  .utc(false)
                  .toDate()
              }
              control={control}
              value={outDateTime}
              minDate={moment(defaultValues?.inDateTime, DateFormat)
                .startOf('day')
                .toDate()}
              error={errors[TimeType.Out]}
              onChange={([_, { value }]) => {
                if (!value) {
                  setOutDateTime(undefined);
                }
                if (value) {
                  setOutDateTime(
                    Moment(
                      `${Moment(value).format(DateFormat)}`,
                      `${DateFormat} ${TimeFormat}`
                    ).toDate()
                  );
                  return Moment(
                    `${Moment(value).format(DateFormat)}`,
                    `${DateFormat} ${TimeFormat}`
                  ).toDate();
                }
                return undefined;
              }}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            <TimeHourMinuteInput
              name="outTime"
              control={control}
              setInputValue={setTimeOut}
              inputValue={timeOut}
              enableRequired={false}
              error={errors.outTime}
              noMargin
              label={Lang.LBL_TIME_OUT_HOUR}
              callback={setIsUpdated}
              setValue={setValue}
              setError={setError}
              register={register}
              clearErrors={clearErrors}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      ) : (
        ''
      )}
      <ATMGrid.Row>
        <ATMGrid.Column width={10}>
          <ATMField
            as={ATMTextArea}
            name="comment"
            label="Purpose"
            size="large"
            placeholder="Description"
            control={control}
            error={errors.comment}
            maxLength={40}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column width={10}>
          <ATMField
            as={ATMTextArea}
            name="visitors"
            label="Visitors (Separated by commas)"
            size="large"
            placeholder="Visitors"
            maxLength={300}
            control={control}
            error={errors.visitors}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column width={10}>
          <SubstationEntryLogDocumentsDetail
            id={defaultValues?.substationEntryLogId}
            isEdit
            viewType="edit"
            onDelete={(file) => {
              if (file.length === 1) {
                setValue('fileAttachedFlag', false);
                handleRemovedFiles(true);
              }
            }}
          />
          <FileUploader
            text="Add"
            icon="plus"
            displayRemoveButton={false}
            errorMessage="Failed to cache file(s)"
            successMessage="Your file(s) has been cached, but will not be uploaded until the Substation Entry is saved"
            onSuccess={() => {
              setIsFileCached(true);
              setValue('fileAttachedFlag', true);
            }}
            hasSharePointError={fileState?.hasSharepointError}
            popOverPostition="right center"
            popOverStyle={{ color: 'red', width: 250 }}
          />
          <div style={{ marginTop: 8 }}>
            <SubstationEntryLogDocumentsDetail
              viewType="review"
              isEdit={false}
              enableRemoveButton
              onDelete={(file) => {
                if (file.length === 1) {
                  setValue('fileAttachedFlag', false);
                }
              }}
            />
          </div>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const SubstationEntryLogForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  isFileCached,
  handleSubmit,
  handleEnable,
  setIsFileCached,
  handleRemovedFiles,
}) => {
  const { state: entryReasonState, actions: entryReasonActions } =
    useEntryReasonContext();

  useEffect(() => {
    handleRemovedFiles(false);
    if (!entryReasonState.list.length) {
      entryReasonActions.listGET({ page: 0, limit: 1 });
    }
  }, [entryReasonState.list, entryReasonActions, handleRemovedFiles]);
  const [isUpdated, setIsUpdated] = useState(false);
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={SubstationEntryFormSchema.cast({
        ...defaultValues,
        comment: defaultValues?.comment ?? '',
      })}
      validationSchema={SubstationEntryFormSchema}
    >
      {(props) => {
        const fileAttachedFlag =
          props.watch('fileAttachedFlag') !==
          props.formState.defaultValues?.fileAttachedFlag;
        handleEnable(
          props.formState.isDirty ||
            isFileCached ||
            fileAttachedFlag ||
            isUpdated
        );

        return (
          <ATMFormProvider {...props}>
            <FormContent
              defaultValues={defaultValues}
              entryReasonList={entryReasonState.list}
              setIsFileCached={setIsFileCached}
              handleRemovedFiles={handleRemovedFiles}
              setIsUpdated={setIsUpdated}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default SubstationEntryLogForm;
