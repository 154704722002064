import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import { ITsoOssEmployee } from 'src/models/tso-oss-employee.model';
import Lang from 'src/libraries/language';
import TsoOssEmployeeEdit from '../tso-oss-employee-edit/tso-oss-employee-edit.component';
import TsoOssEmployeeDelete from '../tso-oss-employee-delete/tso-oss-employee-delete.component';

const columns: IORGDataTableColumn<ITsoOssEmployee>[] = [
  {
    index: 'emp',
    title: Lang.LBL_TSO_OSS_EMPLOYEE_NAME,
    width: '400px',
    render: (_, value) => {
      if (value.fullName) {
        return `${value.fullName} / ${value.empId}`;
      }
      return '--';
    },
  },
  {
    index: 'userType',
    title: Lang.LBL_TSO_OSS,
    render: (_, value) => {
      return value.userType.toUpperCase();
    },
  },
  {
    index: 'title',
    title: Lang.LBL_TSO_OSS_EMPLOYEE_TITLE,
    render: (_, value) => {
      if (value.userType.toUpperCase() === 'TSO') {
        return 'Transmission System Operator';
      }
      return 'Operations Shift Supervisor';
    },
  },
  {
    index: 'action',
    title: 'Action',
    width: '120px',
    cellProps: {
      textAlign: 'left',
    },
    render: (_, value) => (
      <>
        <ATMDropdown
          pointing
          size="small"
          key=""
          icon="ellipsis horizontal"
          className="link item right top"
        >
          <ATMDropdown.Menu>
            <TsoOssEmployeeEdit data={value} />
            <TsoOssEmployeeDelete data={value} />
          </ATMDropdown.Menu>
        </ATMDropdown>
      </>
    ),
  },
];

type IProps = {
  data: ITsoOssEmployee[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
};

const TsoOssEmployeeListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
}) => (
  <div>
    <ORGDataTable
      columns={columns}
      data={data}
      onChange={handleFetch}
      loading={loading}
      celled={false}
      counter
      showPagination={false}
    />
  </div>
);

export default TsoOssEmployeeListView;
