import yup from 'src/libraries/validator.library';

export const UserShape = {
  employeeId: yup.string().required(),
  employeeName: yup.string().required(),
  employeeEmail: yup.string().nullable(),
  employeeRole: yup.string().nullable(),
  employeeTitle: yup.string().nullable(),
  employeePhone: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  createdAt: yup.date(),
  createdBy: yup.string(),
};

export const UserSchema = yup.object(UserShape);

export const UserFormSchema = yup.object({
  name: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export const UserListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(UserSchema.clone()),
});

export const UserPayloadSchema = yup.object({
  user: UserSchema.clone(),
});

export const UserDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IUser = yup.Asserts<typeof UserSchema>;
export type IUserForm = yup.Asserts<typeof UserFormSchema>;
