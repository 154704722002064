import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMSegment,
  ATMGrid,
  ATMLabel,
  ATMDatePicker,
  ATMDropdown,
  ATMButton,
  IORGDataTableColumn,
  MOLInfoText,
  ORGDataTable,
  MOLNoData,
  ATMIcon,
} from 'shared-it-appmod-ui';
import { useRejectedReportContext } from 'src/contexts/rejected-report.context';
import { LERRequestPage } from 'src/constants';
import Lang from 'src/libraries/language';
import { formatDateTime } from 'src/libraries/moment.library';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { IRejectedOverviewReport } from 'src/models/rejected-report.model';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { getEmployeeName } from 'src/helpers/employee.helper';
import style from '../rejected-report.module.scss';

type IProps = {
  loading: boolean;
  data: IRejectedOverviewReport[];
  queryParams: Record<any, any>;
  handleFetch: (clear?: boolean) => void;
  handleQueryParams: (params: Record<any, any>) => void;
  handleDownload: () => void;
  downloading: boolean;
  showNoRecord: boolean;
  setShowNoRecord: React.Dispatch<React.SetStateAction<boolean>>;
};

const RejectedReportOverview: React.FC<IProps> = ({
  data,
  loading,
  queryParams,
  handleFetch,
  handleQueryParams,
  handleDownload,
  downloading,
  showNoRecord,
  setShowNoRecord,
}) => {
  const { getUrl } = useLocationParams([LERRequestPage.View]);
  const { actions } = useRejectedReportContext();
  const { state: lerRequestState } = useLerRequestContext();
  const statusEmployeeRole = getLerRequestStatus(
    lerRequestState,
    lerRequestActionTypes.LER_REQUEST_EMPLOYEE_LIST_READ
  ).fetching;

  const [requestorOptions, supervisorOptions, rejectedByOptions] =
    useMemo(() => {
      const requestors = lerRequestState.employeeList
        .filter((v) => v.type === 'requestor')
        .map((value) => ({
          key: `requestor_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      const supervisors = lerRequestState.employeeList
        .filter((v) => v.type === 'supervisor')
        .map((value) => ({
          key: `supervisor_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      const rejectors = lerRequestState.employeeList
        .filter((v) => v.type === 'rejector')
        .map((value) => ({
          key: `rejector_${value.empId}`,
          value: value.empId,
          text: getEmployeeName(value),
        }));

      return [requestors, supervisors, rejectors];
    }, [lerRequestState.employeeList]);

  const customColumns: IORGDataTableColumn<IRejectedOverviewReport>[] = [
    {
      title: Lang.LBL_LER,
      index: 'requestId',
      render: (_, value) => (
        <Link to={getUrl({ lerView: value.requestId })}>{value.requestId}</Link>
      ),
    },
    {
      title: Lang.LBL_REQUESTOR,
      index: 'outage.requestor.fullName',
    },
    {
      title: Lang.LBL_REQUESTOR_SUPERVISOR,
      index: 'outage.supervisor.fullName',
    },
    {
      title: Lang.LBL_REASON,
      index: 'rejectionReason',
      width: '30%',
      render: (_, value) => (
        <MOLInfoText
          transparent
          limit={50}
          content={value.rejectionReason || ''}
        />
      ),
    },
    {
      title: Lang.LBL_REJECTOR,
      index: 'rejector.fullName',
    },
    {
      title: Lang.LBL_REJECTED_DATE,
      index: 'rejectionDate',
      render: (_, value) => formatDateTime(value.rejectionDate),
    },
  ];
  return (
    <>
      <ATMSegment className={style.bgColor}>
        <ATMGrid>
          <ATMGrid.Row columns={4} className={style.filters}>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_DATE_RANGE}
                className={style.filterLbl}
              />
              <ATMDatePicker
                type="range"
                name="dateRange"
                placeholder={Lang.LBL_SELECT}
                format="MM/DD/YYYY"
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'dateRange')
                    ? queryParams.dateRange
                    : undefined
                }
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    dateRange: value,
                  });
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_REQUESTOR}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                name="requestorId"
                clearable
                loading={!requestorOptions.length && statusEmployeeRole}
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'requestorId')
                    ? queryParams.requestorId
                    : null
                }
                options={[...requestorOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    requestorId: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_REQUESTOR_SUPERVISOR}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                clearable
                loading={!supervisorOptions.length && statusEmployeeRole}
                name="supvId"
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'supvId')
                    ? queryParams.supvId
                    : null
                }
                options={[...supervisorOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    supvId: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMLabel
                basic
                content={Lang.LBL_REJECTED_BY}
                className={style.filterLbl}
              />
              <ATMDropdown
                search
                selection
                clearable
                name="rejectorId"
                loading={!rejectedByOptions.length && statusEmployeeRole}
                placeholder={Lang.LBL_SELECT}
                value={
                  Object.entries(queryParams)
                    .map(([key, value]) => {
                      return { name: key, value };
                    })
                    .some((elem) => elem.name === 'rejectorId')
                    ? queryParams.rejectorId
                    : null
                }
                options={[...rejectedByOptions]}
                onChange={(_, { value }) => {
                  handleQueryParams({
                    ...queryParams,
                    rejectorId: value,
                  });
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
            <ATMGrid.Column />
          </ATMGrid.Row>
          <ATMGrid.Row>
            <ATMGrid.Column>
              <ATMButton
                primary
                content={Lang.LBL_SEARCH}
                onClick={() => {
                  setShowNoRecord(false);
                  handleFetch();
                }}
                disabled={Object.keys(queryParams).length === 0}
              />
              <ATMButton
                secondary
                content={Lang.LBL_CLEAR_SEARCH}
                onClick={() => {
                  setShowNoRecord(false);
                  actions?.overviewListClear();
                  handleQueryParams({});
                }}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMSegment>
      {data.length > 0 && (
        <ATMButton
          secondary
          icon="download"
          className={style.download}
          onClick={handleDownload}
          loading={downloading}
        />
      )}
      <ORGDataTable
        sortable
        data={data}
        celled={false}
        defaultRowsPerPage={50}
        loading={loading}
        className={style.tableHeaders}
        columns={customColumns}
        noDataText={
          !showNoRecord ? (
            <MOLNoData
              icon="search"
              header="Search to view records"
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          ) : (
            <div className={style.noData}>
              <p>
                <ATMIcon
                  circular
                  color="grey"
                  inverted
                  name="info"
                  size="small"
                  style={{
                    display: 'inline-block',
                    marginRight: '0.8em',
                    verticalAlign: 'top',
                  }}
                />
                {Lang.MSG_EMPTY_FILTER}
              </p>
            </div>
          )
        }
      />
    </>
  );
};

export default RejectedReportOverview;
