import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import {
  ITroubleTicket,
  ITroubleTicketResponsePayload,
} from 'src/models/trouble-ticket.model';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useRolesContext } from 'src/contexts/roles.context';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import { getRolesParams, getRolesStatus } from 'src/selectors/roles.selector';
import { IEmployeeRoleFilter } from 'src/models/roles.model';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { getUser } from 'src/libraries/amplify.library';
import TroubleTicketAddView from './trouble-ticket-add.view';

type IProp = {
  setView: React.Dispatch<React.SetStateAction<string>>;
  view: any;
};

const TroubleTicketAdd: React.FC<IProp> = ({ setView, view }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { actions } = useTroubleTicketContext();
  const { state: settingState } = useSettingContext();
  const { state: employeeRolesState, actions: employeeRolesActions } =
    useRolesContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();
  const statusEmployeeRole = getRolesStatus(
    employeeRolesState,
    rolesActionTypes.ROLES_LIST_READ
  ).fetching;
  useEffect(() => {
    const filterState: Partial<IEmployeeRoleFilter> = { roleIds: [14, 15] };
    const params = getRolesParams({ page: 1, limit: 0 }, filterState);
    employeeRolesActions.listGET(params);
  }, [employeeRolesActions]);

  useEffect(() => {
    actions.dataCLEAR();
  }, []);

  const handleFileUploadSubmit = useCallback(
    async (responseData: ITroubleTicketResponsePayload) => {
      // const requestor = responseData?.updatedByEmployee.fullName || '';
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.troubleTicketId) || '',
        parentFolder: responseData?.troubleTicketId as any,
        requestId: responseData?.troubleTicketId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.TROUBLE_TICKET_TYPE,
        setting: settingState,
        withParentFolder: true,
      });
    },
    [fileState, settingState]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ITroubleTicket) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (response?.payload) {
        await handleFileUploadSubmit(response.payload);
        setIsAddOpen(false);
        if (formData.requestTyp === 'Scheduled') {
          setIsAddOpen(false);
          // history.push('/trouble-ticket?troubleTicketStatus=1');
        }
        if (formData.requestTyp === 'Trouble') {
          setIsAddOpen(false);
          // history.push('/trouble-ticket');
        }
        if (formData.ticketStatus === 'Closed') {
          setIsAddOpen(false);
          // history.push('/trouble-ticket');
          ToastSuccess(
            `Trouble Ticket #${response?.payload?.troubleTicketId} has been closed successfully!`
          );
        } else {
          ToastSuccess(
            `Trouble Ticket: #${response.payload?.troubleTicketId} created successfully!`,
            { delay: 20000 }
          );
          if (response.payload?.emailResult) {
            ToastSuccess(response.payload.emailResult);
          }
        }

        setView('');
      } else {
        fileAction.deleteAllStashedFiles();
        ToastError(
          response?.error?.message ?? 'Failed to create new trouble ticket'
        );
        setView('');
      }
    },
    [actions, fileState, fileAction, setIsAddOpen]
  );
  return (
    <TroubleTicketAddView
      setView={setView}
      formRef={formRef}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      statusEmployeeRole={statusEmployeeRole}
      isOpen={isAddOpen}
      handleOpen={() => setIsAddOpen(true)}
      assignToEmp={employeeRolesState.list}
      view={view}
    />
  );
};

export default TroubleTicketAdd;
