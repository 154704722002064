import React, { useCallback, useState } from 'react';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import history from 'src/history';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import AuthorizedListDeleteView from './authorized-list-delete.view';

type IProp = {
  id?: string;
  empName?: string | null;
  empId?: string;
};

const AuthorizedListDelete: React.FC<IProp> = ({ id, empName, empId }) => {
  const { params: currentTab } = useLocationParams(['id']);
  const { state, actions } = useAuthorizedListContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (id) {
      const response = await actions.dataDELETE(id);

      if (!response.error) {
        // actions.listGET();
        setIsOpen(false);
        ToastSuccess(
          `Training record successfully deleted for user ${empName} / ${empId}`
        );
        history.push(`/auth-list?id=${currentTab.id}`);
        actions.empDataGET(currentTab.id);
      } else {
        ToastError('Deleted Error!');
      }
    }
  }, [actions, id]);

  return (
    <AuthorizedListDeleteView
      empName={empName}
      empId={empId}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default AuthorizedListDelete;
