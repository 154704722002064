/* eslint-disable no-console */
import axios, { AxiosRequestConfig, AxiosInstance, Canceler } from 'axios';
import { InferType, BaseSchema } from 'yup';
import { SignJWT } from 'jose';
import { AnyObject, ValidateOptions } from 'yup/lib/types';
import { SharepointEnvironmentProperties } from 'src/helpers/sharepoint-service.helper';
import Lazy from 'yup/lib/Lazy';
import { TYPE_DUPLICATE } from 'src/constants';
import ENV from 'src/constants/env.constants';
import { getUser, getToken } from './amplify.library';

const { CancelToken } = axios;

const cancelToken: Record<string, Canceler> = {};

export const httpCancelAll = () => {
  Object.entries({ ...cancelToken }).forEach(([key, cancel]) => {
    cancel();
    delete cancelToken[key];
  });
};

const createMethod = (xhr: AxiosInstance, allowCancel = true) => {
  return {
    instance: xhr,

    post: async <D, S extends BaseSchema<any>, C extends AnyObject>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {}
    ): Promise<InferType<S>> => {
      const response = await xhr.post(url, data, axiosConfig);

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    get: async <D, S extends BaseSchema<any> | Lazy<any>, C extends AnyObject>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {}
    ): Promise<InferType<S>> => {
      const key = `${url}${JSON.stringify(data)}`;
      // Prevent duplicate request in API queue
      if (cancelToken[key]) {
        cancelToken[key](TYPE_DUPLICATE);
      }

      const response = await xhr.get(url, {
        cancelToken: new CancelToken((cancel) => {
          if (allowCancel) {
            cancelToken[key] = cancel;
          }
        }),
        ...axiosConfig,
        params: data,
      });

      if (allowCancel) {
        delete cancelToken[key];
      }

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    put: async <D, S extends BaseSchema<any>, C extends AnyObject>(
      url: string,
      data: D,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {}
    ): Promise<InferType<S>> => {
      const response = await xhr.put(url, data, axiosConfig);

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },

    delete: async <S extends BaseSchema<any>, C extends AnyObject>(
      url: string,
      schema: S,
      axiosConfig: AxiosRequestConfig = {},
      validateConfig: ValidateOptions<C> = {}
    ): Promise<InferType<S>> => {
      const response = await xhr.delete(url, { ...axiosConfig });

      return schema.validate(response.data, {
        stripUnknown: true,
        ...validateConfig,
      });
    },
  };
};

export const httpClient = () => {
  const baseURL = ENV.VITE_API_ENDPOINT;

  const xhr = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      (data) => {
        // This is for uploading files
        if (data instanceof FormData) {
          return data;
        }

        return JSON.stringify(data);
      },
    ],
    baseURL,
  });

  // Set the AUTH token for any request
  xhr.interceptors.request.use(
    async (oldConfig) => {
      const config = {
        ...oldConfig,
      };

      const user = getUser();

      if (config.headers) {
        if (user) {
          const alg = 'HS256';
          const key = ENV.VITE_API_SUBSCRIPTION_KEY || 'secret';
          config.headers['x-user'] = await new SignJWT({
            ...user,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
            .setProtectedHeader({ alg, typ: 'JWT' })
            .setIssuedAt()
            .sign(new TextEncoder().encode(key));
        }

        config.headers['Ocp-Apim-Subscription-Key'] =
          ENV.VITE_API_SUBSCRIPTION_KEY || '';

        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    (error) => {
      console.error('Request Error:', JSON.stringify(error));

      return Promise.reject(error.response || error);
    }
  );

  // We'll override Axios default error handler
  xhr.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // const { config, message } = error;

      // if (
      //   (config.retry && message.includes('timeout')) ||
      //   message.includes('Network Error')
      // ) {
      //   config.retry -= 1;

      //   return axios(config);
      // }

      const errObj = JSON.parse(JSON.stringify(error, null, 2));
      console.error(
        'Response Error:',
        JSON.parse(JSON.stringify(error, null, 2))
      );
      // Because AWS is not using `Access-Control-Allow-Origin: *`, axios cannot detect the status error.
      // So we assumed that the status is 401 as AWS is not allowing the request to be processed.

      // API returns 504
      if (
        errObj.status === 504 &&
        errObj.config?.url.includes('daily-report')
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...error, status: 504 });
      }

      return Promise.reject(
        error.response?.data?.error
          ? {
              data: error.response?.data?.error,
            }
          : error.response || error
      );
    }
  );

  return createMethod(xhr);
};

export const commonClient = () => {
  const commonURL = ENV.VITE_API_ENDPOINT_COMMON;

  const xhr = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      (data) => {
        // This is for uploading files
        if (data instanceof FormData) {
          return data;
        }

        return JSON.stringify(data);
      },
    ],
    baseURL: commonURL,
  });

  // Set the AUTH token for any request
  xhr.interceptors.request.use((oldConfig) => {
    const config = { ...oldConfig };

    const token = getToken();
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  // We'll override Axios default error handler
  xhr.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Because AWS is not using `Access-Control-Allow-Origin: *`, axios cannot detect the status error.
      // So we assumed that the status is 401 as AWS is not allowing the request to be processed.
      if (!error.status && error.message === 'Network Error') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ status: 401, ...error });
      }

      return Promise.reject(error.response || error);
    }
  );

  return createMethod(xhr, false);
};

export const sharePointClient = () => {
  const xhr = axios.create({
    baseURL: SharepointEnvironmentProperties.SHAREPOINT_LAYER7_BASE_URL,
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
  });

  // Set the AUTH token for any request
  xhr.interceptors.request.use((oldConfig) => {
    const config = { ...oldConfig };
    return config;
  });

  // We'll override Axios default error handler
  xhr.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error.response || error);
    }
  );

  return createMethod(xhr, false);
};
