import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMCheckbox,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMTable,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useSignificantCostContext } from 'src/contexts/significant-cost.context';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';

const LERRequestCostForm: React.FC = () => {
  const { control, watch, setValue } = useATMFormContext<ILerRequestForm>();
  const { state, actions } = useSignificantCostContext();

  const { fields } = useFieldArray({
    control,
    name: 'significantCost.significantCostTasks',
  });

  useEffect(() => {
    actions.listGET();
  }, [actions]);

  return (
    <ATMGrid>
      <ATMGrid.Column width={11}>
        <ATMHeader>{Lang.TTL_COSTS}</ATMHeader>
        <ATMField
          control={control}
          name="significantCost.significantCostNote.noteId"
          as={ATMInput}
          type="hidden"
          className="hidden"
        />
        <ATMField
          control={control}
          label={Lang.LBL_COMMENTS}
          name="significantCost.significantCostNote.noteTxt"
          as={ATMTextArea}
        />

        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>
                {Lang.LBL_DISTRIBUTION_LIST}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_SAVE}</ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_SEND_EMAIL}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {state.list.map((value, index) => (
              <ATMTable.Row key={value.significantCostId}>
                <ATMTable.Cell>
                  <ATMField
                    control={control}
                    name={`significantCost.significantCostTasks[${index}].significantCostId`}
                    as={ATMInput}
                    type="hidden"
                    className="hidden"
                    defaultValue={value.significantCostId}
                  />
                  {value.description}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    control={control}
                    name={`significantCost.significantCostTasks[${index}].save`}
                    as={ATMCheckbox}
                    onChange={([_, { checked }]) => checked}
                    defaultChecked={
                      fields.findIndex(
                        (v) => v.significantCostId === value.significantCostId
                      ) >= 0
                    }
                    readOnly
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMField
                    control={control}
                    name={`significantCost.significantCostTasks[${index}].email`}
                    as={ATMCheckbox}
                    onChange={([_, { checked }]) => {
                      setValue(
                        `significantCost.significantCostTasks[${index}].save` as any,
                        checked
                      );

                      return checked;
                    }}
                    defaultChecked={watch(
                      `significantCost.significantCostTasks[${index}].save` as any
                    )}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
        </ATMTable>
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default LERRequestCostForm;
